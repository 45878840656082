import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { forwardRef, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { setValue, FormBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { DropDownList, VirtualScroll, ComboBox, AutoComplete, MultiSelect, CheckBoxSelection, ListBox, DropDownTree, Mention } from '@syncfusion/ej2-dropdowns';
const _c0 = ["footerTemplate"];
const _c1 = ["headerTemplate"];
const _c2 = ["valueTemplate"];
const _c3 = ["groupTemplate"];
const _c4 = ["itemTemplate"];
const _c5 = ["noRecordsTemplate"];
const _c6 = ["actionFailureTemplate"];
const _c7 = ["displayTemplate"];
const _c8 = ["spinnerTemplate"];
const _c9 = ["*"];
export * from '@syncfusion/ej2-dropdowns';
import { CommonModule } from '@angular/common';
var DropDownListComponent_1;
const inputs$6 = ['actionFailureTemplate', 'allowFiltering', 'allowObjectBinding', 'allowResize', 'cssClass', 'dataSource', 'enablePersistence', 'enableRtl', 'enableVirtualization', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'floatLabelType', 'footerTemplate', 'groupTemplate', 'headerTemplate', 'htmlAttributes', 'ignoreAccent', 'ignoreCase', 'index', 'isDeviceFullScreen', 'itemTemplate', 'locale', 'noRecordsTemplate', 'placeholder', 'popupHeight', 'popupWidth', 'query', 'readonly', 'showClearButton', 'sortOrder', 'text', 'value', 'valueTemplate', 'width', 'zIndex'];
const outputs$6 = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeOpen', 'blur', 'change', 'close', 'created', 'dataBound', 'destroyed', 'filtering', 'focus', 'open', 'resizeStart', 'resizeStop', 'resizing', 'select', 'valueChange'];
const twoWays$6 = ['value'];
/**
*The DropDownList component contains a list of predefined values, from which the user can choose a single value.
*```html
*<ejs-dropdownlist></ejs-dropdownlist>
*```
*/
let DropDownListComponent = DropDownListComponent_1 = class DropDownListComponent extends DropDownList {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DropDownsVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$6);
    this.addTwoWay.call(this, twoWays$6);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
DropDownListComponent.ɵfac = function DropDownListComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownListComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
DropDownListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DropDownListComponent,
  selectors: [["ejs-dropdownlist"]],
  contentQueries: function DropDownListComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.valueTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionFailureTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    allowFiltering: "allowFiltering",
    allowObjectBinding: "allowObjectBinding",
    allowResize: "allowResize",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableVirtualization: "enableVirtualization",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    floatLabelType: "floatLabelType",
    footerTemplate: "footerTemplate",
    groupTemplate: "groupTemplate",
    headerTemplate: "headerTemplate",
    htmlAttributes: "htmlAttributes",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    index: "index",
    isDeviceFullScreen: "isDeviceFullScreen",
    itemTemplate: "itemTemplate",
    locale: "locale",
    noRecordsTemplate: "noRecordsTemplate",
    placeholder: "placeholder",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    query: "query",
    readonly: "readonly",
    showClearButton: "showClearButton",
    sortOrder: "sortOrder",
    text: "text",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    blur: "blur",
    change: "change",
    close: "close",
    created: "created",
    dataBound: "dataBound",
    destroyed: "destroyed",
    filtering: "filtering",
    focus: "focus",
    open: "open",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropDownListComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DropDownListComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], DropDownListComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], DropDownListComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], DropDownListComponent.prototype, "valueTemplate", void 0);
__decorate([Template()], DropDownListComponent.prototype, "groupTemplate", void 0);
__decorate([Template()], DropDownListComponent.prototype, "itemTemplate", void 0);
__decorate([Template('No records found')], DropDownListComponent.prototype, "noRecordsTemplate", void 0);
__decorate([Template('Request failed')], DropDownListComponent.prototype, "actionFailureTemplate", void 0);
DropDownListComponent = DropDownListComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], DropDownListComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownListComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-dropdownlist',
      inputs: inputs$6,
      outputs: outputs$6,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DropDownListComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    valueTemplate: [{
      type: ContentChild,
      args: ['valueTemplate']
    }],
    groupTemplate: [{
      type: ContentChild,
      args: ['groupTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }],
    actionFailureTemplate: [{
      type: ContentChild,
      args: ['actionFailureTemplate']
    }]
  });
})();

/**
 * NgModule definition for the DropDownList component.
 */
class DropDownListModule {}
DropDownListModule.ɵfac = function DropDownListModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownListModule)();
};
DropDownListModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownListModule
});
DropDownListModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownListModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DropDownListComponent],
      exports: [DropDownListComponent]
    }]
  }], null, null);
})();
const VirtualScrollService = {
  provide: 'DropDownsVirtualScroll',
  useValue: VirtualScroll
};
/**
 * NgModule definition for the DropDownList component with providers.
 */
class DropDownListAllModule {}
DropDownListAllModule.ɵfac = function DropDownListAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownListAllModule)();
};
DropDownListAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownListAllModule
});
DropDownListAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [VirtualScrollService],
  imports: [[CommonModule, DropDownListModule], DropDownListModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownListAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DropDownListModule],
      exports: [DropDownListModule],
      providers: [VirtualScrollService]
    }]
  }], null, null);
})();
var ComboBoxComponent_1;
const inputs$5 = ['actionFailureTemplate', 'allowCustom', 'allowFiltering', 'allowObjectBinding', 'allowResize', 'autofill', 'cssClass', 'dataSource', 'enablePersistence', 'enableRtl', 'enableVirtualization', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'floatLabelType', 'footerTemplate', 'groupTemplate', 'headerTemplate', 'htmlAttributes', 'ignoreAccent', 'ignoreCase', 'index', 'isDeviceFullScreen', 'itemTemplate', 'locale', 'noRecordsTemplate', 'placeholder', 'popupHeight', 'popupWidth', 'query', 'readonly', 'showClearButton', 'sortOrder', 'text', 'value', 'valueTemplate', 'width', 'zIndex'];
const outputs$5 = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeOpen', 'blur', 'change', 'close', 'created', 'customValueSpecifier', 'dataBound', 'destroyed', 'filtering', 'focus', 'open', 'resizeStart', 'resizeStop', 'resizing', 'select', 'valueChange'];
const twoWays$5 = ['value'];
/**
*The ComboBox component allows the user to type a value or choose an option from the list of predefined options.
*```html
*<ejs-combobox></ejs-combobox>
*```
*/
let ComboBoxComponent = ComboBoxComponent_1 = class ComboBoxComponent extends ComboBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DropDownsVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$5);
    this.addTwoWay.call(this, twoWays$5);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
ComboBoxComponent.ɵfac = function ComboBoxComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ComboBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
ComboBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ComboBoxComponent,
  selectors: [["ejs-combobox"]],
  contentQueries: function ComboBoxComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionFailureTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    allowCustom: "allowCustom",
    allowFiltering: "allowFiltering",
    allowObjectBinding: "allowObjectBinding",
    allowResize: "allowResize",
    autofill: "autofill",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableVirtualization: "enableVirtualization",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    floatLabelType: "floatLabelType",
    footerTemplate: "footerTemplate",
    groupTemplate: "groupTemplate",
    headerTemplate: "headerTemplate",
    htmlAttributes: "htmlAttributes",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    index: "index",
    isDeviceFullScreen: "isDeviceFullScreen",
    itemTemplate: "itemTemplate",
    locale: "locale",
    noRecordsTemplate: "noRecordsTemplate",
    placeholder: "placeholder",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    query: "query",
    readonly: "readonly",
    showClearButton: "showClearButton",
    sortOrder: "sortOrder",
    text: "text",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    blur: "blur",
    change: "change",
    close: "close",
    created: "created",
    customValueSpecifier: "customValueSpecifier",
    dataBound: "dataBound",
    destroyed: "destroyed",
    filtering: "filtering",
    focus: "focus",
    open: "open",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ComboBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], ComboBoxComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], ComboBoxComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], ComboBoxComponent.prototype, "groupTemplate", void 0);
__decorate([Template()], ComboBoxComponent.prototype, "itemTemplate", void 0);
__decorate([Template('No records found')], ComboBoxComponent.prototype, "noRecordsTemplate", void 0);
__decorate([Template('Request failed')], ComboBoxComponent.prototype, "actionFailureTemplate", void 0);
ComboBoxComponent = ComboBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], ComboBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComboBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-combobox',
      inputs: inputs$5,
      outputs: outputs$5,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ComboBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    groupTemplate: [{
      type: ContentChild,
      args: ['groupTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }],
    actionFailureTemplate: [{
      type: ContentChild,
      args: ['actionFailureTemplate']
    }]
  });
})();

/**
 * NgModule definition for the ComboBox component.
 */
class ComboBoxModule {}
ComboBoxModule.ɵfac = function ComboBoxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ComboBoxModule)();
};
ComboBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ComboBoxModule
});
ComboBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComboBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ComboBoxComponent],
      exports: [ComboBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ComboBox component with providers.
 */
class ComboBoxAllModule {}
ComboBoxAllModule.ɵfac = function ComboBoxAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ComboBoxAllModule)();
};
ComboBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ComboBoxAllModule
});
ComboBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ComboBoxModule], ComboBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComboBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ComboBoxModule],
      exports: [ComboBoxModule],
      providers: []
    }]
  }], null, null);
})();
var AutoCompleteComponent_1;
const inputs$4 = ['actionFailureTemplate', 'allowCustom', 'allowFiltering', 'allowObjectBinding', 'allowResize', 'autofill', 'cssClass', 'dataSource', 'enablePersistence', 'enableRtl', 'enableVirtualization', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'floatLabelType', 'footerTemplate', 'groupTemplate', 'headerTemplate', 'highlight', 'htmlAttributes', 'ignoreAccent', 'ignoreCase', 'index', 'isDeviceFullScreen', 'itemTemplate', 'locale', 'minLength', 'noRecordsTemplate', 'placeholder', 'popupHeight', 'popupWidth', 'query', 'readonly', 'showClearButton', 'showPopupButton', 'sortOrder', 'suggestionCount', 'text', 'value', 'valueTemplate', 'width', 'zIndex'];
const outputs$4 = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeOpen', 'blur', 'change', 'close', 'created', 'customValueSpecifier', 'dataBound', 'destroyed', 'filtering', 'focus', 'open', 'resizeStart', 'resizeStop', 'resizing', 'select', 'valueChange'];
const twoWays$4 = ['value'];
/**
 *The AutoComplete component provides the matched suggestion list when type into the input, from which the user can select one.
*```html
*<ejs-autocomplete></ejs-autocomplete>
*```
*/
let AutoCompleteComponent = AutoCompleteComponent_1 = class AutoCompleteComponent extends AutoComplete {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DropDownsVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$4);
    this.addTwoWay.call(this, twoWays$4);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
AutoCompleteComponent.ɵfac = function AutoCompleteComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AutoCompleteComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
AutoCompleteComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AutoCompleteComponent,
  selectors: [["ejs-autocomplete"]],
  contentQueries: function AutoCompleteComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionFailureTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    allowCustom: "allowCustom",
    allowFiltering: "allowFiltering",
    allowObjectBinding: "allowObjectBinding",
    allowResize: "allowResize",
    autofill: "autofill",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableVirtualization: "enableVirtualization",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    floatLabelType: "floatLabelType",
    footerTemplate: "footerTemplate",
    groupTemplate: "groupTemplate",
    headerTemplate: "headerTemplate",
    highlight: "highlight",
    htmlAttributes: "htmlAttributes",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    index: "index",
    isDeviceFullScreen: "isDeviceFullScreen",
    itemTemplate: "itemTemplate",
    locale: "locale",
    minLength: "minLength",
    noRecordsTemplate: "noRecordsTemplate",
    placeholder: "placeholder",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    query: "query",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showPopupButton: "showPopupButton",
    sortOrder: "sortOrder",
    suggestionCount: "suggestionCount",
    text: "text",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    blur: "blur",
    change: "change",
    close: "close",
    created: "created",
    customValueSpecifier: "customValueSpecifier",
    dataBound: "dataBound",
    destroyed: "destroyed",
    filtering: "filtering",
    focus: "focus",
    open: "open",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutoCompleteComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function AutoCompleteComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], AutoCompleteComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], AutoCompleteComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], AutoCompleteComponent.prototype, "groupTemplate", void 0);
__decorate([Template()], AutoCompleteComponent.prototype, "itemTemplate", void 0);
__decorate([Template('No records found')], AutoCompleteComponent.prototype, "noRecordsTemplate", void 0);
__decorate([Template('Request failed')], AutoCompleteComponent.prototype, "actionFailureTemplate", void 0);
AutoCompleteComponent = AutoCompleteComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], AutoCompleteComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoCompleteComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-autocomplete',
      inputs: inputs$4,
      outputs: outputs$4,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AutoCompleteComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    groupTemplate: [{
      type: ContentChild,
      args: ['groupTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }],
    actionFailureTemplate: [{
      type: ContentChild,
      args: ['actionFailureTemplate']
    }]
  });
})();

/**
 * NgModule definition for the AutoComplete component.
 */
class AutoCompleteModule {}
AutoCompleteModule.ɵfac = function AutoCompleteModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AutoCompleteModule)();
};
AutoCompleteModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AutoCompleteModule
});
AutoCompleteModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoCompleteModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [AutoCompleteComponent],
      exports: [AutoCompleteComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the AutoComplete component with providers.
 */
class AutoCompleteAllModule {}
AutoCompleteAllModule.ɵfac = function AutoCompleteAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AutoCompleteAllModule)();
};
AutoCompleteAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AutoCompleteAllModule
});
AutoCompleteAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, AutoCompleteModule], AutoCompleteModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoCompleteAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, AutoCompleteModule],
      exports: [AutoCompleteModule],
      providers: []
    }]
  }], null, null);
})();
var MultiSelectComponent_1;
const inputs$3 = ['actionFailureTemplate', 'addTagOnBlur', 'allowCustomValue', 'allowFiltering', 'allowObjectBinding', 'allowResize', 'changeOnBlur', 'closePopupOnSelect', 'cssClass', 'dataSource', 'delimiterChar', 'enableGroupCheckBox', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enableSelectionOrder', 'enableVirtualization', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'floatLabelType', 'footerTemplate', 'groupTemplate', 'headerTemplate', 'hideSelectedItem', 'htmlAttributes', 'ignoreAccent', 'ignoreCase', 'isDeviceFullScreen', 'itemTemplate', 'locale', 'maximumSelectionLength', 'mode', 'noRecordsTemplate', 'openOnClick', 'placeholder', 'popupHeight', 'popupWidth', 'query', 'readonly', 'selectAllText', 'showClearButton', 'showDropDownIcon', 'showSelectAll', 'sortOrder', 'text', 'unSelectAllText', 'value', 'valueTemplate', 'width', 'zIndex'];
const outputs$3 = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeOpen', 'beforeSelectAll', 'blur', 'change', 'chipSelection', 'close', 'created', 'customValueSelection', 'dataBound', 'destroyed', 'filtering', 'focus', 'open', 'removed', 'removing', 'resizeStart', 'resizeStop', 'resizing', 'select', 'selectedAll', 'tagging', 'valueChange'];
const twoWays$3 = ['value'];
/**
* The MultiSelect allows the user to pick a values from the predefined list of values.
*```html
*<ejs-multiselect></ejs-multiselect>
*```
*/
let MultiSelectComponent = MultiSelectComponent_1 = class MultiSelectComponent extends MultiSelect {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DropDownsCheckBoxSelection');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DropDownsVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$3);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
MultiSelectComponent.ɵfac = function MultiSelectComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MultiSelectComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
MultiSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MultiSelectComponent,
  selectors: [["ejs-multiselect"]],
  contentQueries: function MultiSelectComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.valueTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionFailureTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    addTagOnBlur: "addTagOnBlur",
    allowCustomValue: "allowCustomValue",
    allowFiltering: "allowFiltering",
    allowObjectBinding: "allowObjectBinding",
    allowResize: "allowResize",
    changeOnBlur: "changeOnBlur",
    closePopupOnSelect: "closePopupOnSelect",
    cssClass: "cssClass",
    dataSource: "dataSource",
    delimiterChar: "delimiterChar",
    enableGroupCheckBox: "enableGroupCheckBox",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableSelectionOrder: "enableSelectionOrder",
    enableVirtualization: "enableVirtualization",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    floatLabelType: "floatLabelType",
    footerTemplate: "footerTemplate",
    groupTemplate: "groupTemplate",
    headerTemplate: "headerTemplate",
    hideSelectedItem: "hideSelectedItem",
    htmlAttributes: "htmlAttributes",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    isDeviceFullScreen: "isDeviceFullScreen",
    itemTemplate: "itemTemplate",
    locale: "locale",
    maximumSelectionLength: "maximumSelectionLength",
    mode: "mode",
    noRecordsTemplate: "noRecordsTemplate",
    openOnClick: "openOnClick",
    placeholder: "placeholder",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    query: "query",
    readonly: "readonly",
    selectAllText: "selectAllText",
    showClearButton: "showClearButton",
    showDropDownIcon: "showDropDownIcon",
    showSelectAll: "showSelectAll",
    sortOrder: "sortOrder",
    text: "text",
    unSelectAllText: "unSelectAllText",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    beforeSelectAll: "beforeSelectAll",
    blur: "blur",
    change: "change",
    chipSelection: "chipSelection",
    close: "close",
    created: "created",
    customValueSelection: "customValueSelection",
    dataBound: "dataBound",
    destroyed: "destroyed",
    filtering: "filtering",
    focus: "focus",
    open: "open",
    removed: "removed",
    removing: "removing",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    select: "select",
    selectedAll: "selectedAll",
    tagging: "tagging",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function MultiSelectComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], MultiSelectComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], MultiSelectComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], MultiSelectComponent.prototype, "valueTemplate", void 0);
__decorate([Template()], MultiSelectComponent.prototype, "itemTemplate", void 0);
__decorate([Template()], MultiSelectComponent.prototype, "groupTemplate", void 0);
__decorate([Template('No records found')], MultiSelectComponent.prototype, "noRecordsTemplate", void 0);
__decorate([Template('Request failed')], MultiSelectComponent.prototype, "actionFailureTemplate", void 0);
MultiSelectComponent = MultiSelectComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], MultiSelectComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiSelectComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-multiselect',
      inputs: inputs$3,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MultiSelectComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    valueTemplate: [{
      type: ContentChild,
      args: ['valueTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    groupTemplate: [{
      type: ContentChild,
      args: ['groupTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }],
    actionFailureTemplate: [{
      type: ContentChild,
      args: ['actionFailureTemplate']
    }]
  });
})();

/**
 * NgModule definition for the MultiSelect component.
 */
class MultiSelectModule {}
MultiSelectModule.ɵfac = function MultiSelectModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MultiSelectModule)();
};
MultiSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MultiSelectModule
});
MultiSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiSelectModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MultiSelectComponent],
      exports: [MultiSelectComponent]
    }]
  }], null, null);
})();
const CheckBoxSelectionService = {
  provide: 'DropDownsCheckBoxSelection',
  useValue: CheckBoxSelection
};
/**
 * NgModule definition for the MultiSelect component with providers.
 */
class MultiSelectAllModule {}
MultiSelectAllModule.ɵfac = function MultiSelectAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MultiSelectAllModule)();
};
MultiSelectAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MultiSelectAllModule
});
MultiSelectAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [CheckBoxSelectionService],
  imports: [[CommonModule, MultiSelectModule], MultiSelectModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiSelectAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MultiSelectModule],
      exports: [MultiSelectModule],
      providers: [CheckBoxSelectionService]
    }]
  }], null, null);
})();
var ListBoxComponent_1;
const inputs$2 = ['actionFailureTemplate', 'allowDragAndDrop', 'allowFiltering', 'cssClass', 'dataSource', 'enablePersistence', 'enableRtl', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'groupTemplate', 'height', 'ignoreAccent', 'ignoreCase', 'itemTemplate', 'locale', 'maximumSelectionLength', 'noRecordsTemplate', 'query', 'scope', 'selectionSettings', 'sortOrder', 'toolbarSettings', 'value', 'zIndex'];
const outputs$2 = ['focus', 'blur', 'actionBegin', 'actionComplete', 'actionFailure', 'beforeDrop', 'beforeItemRender', 'change', 'created', 'dataBound', 'destroyed', 'drag', 'dragStart', 'drop', 'filtering', 'select', 'valueChange'];
const twoWays$2 = ['value'];
/**
* The ListBox allows the user to select values from the predefined list of values.
*```html
*<ejs-listbox [dataSource]='data'></ejs-listbox>
*```
*/
let ListBoxComponent = ListBoxComponent_1 = class ListBoxComponent extends ListBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DropDownsCheckBoxSelection');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
ListBoxComponent.ɵfac = function ListBoxComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
ListBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ListBoxComponent,
  selectors: [["ejs-listbox"]],
  contentQueries: function ListBoxComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    allowDragAndDrop: "allowDragAndDrop",
    allowFiltering: "allowFiltering",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    groupTemplate: "groupTemplate",
    height: "height",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    itemTemplate: "itemTemplate",
    locale: "locale",
    maximumSelectionLength: "maximumSelectionLength",
    noRecordsTemplate: "noRecordsTemplate",
    query: "query",
    scope: "scope",
    selectionSettings: "selectionSettings",
    sortOrder: "sortOrder",
    toolbarSettings: "toolbarSettings",
    value: "value",
    zIndex: "zIndex"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeDrop: "beforeDrop",
    beforeItemRender: "beforeItemRender",
    change: "change",
    created: "created",
    dataBound: "dataBound",
    destroyed: "destroyed",
    drag: "drag",
    dragStart: "dragStart",
    drop: "drop",
    filtering: "filtering",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ListBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ListBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], ListBoxComponent.prototype, "itemTemplate", void 0);
__decorate([Template('No records found')], ListBoxComponent.prototype, "noRecordsTemplate", void 0);
ListBoxComponent = ListBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], ListBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-listbox',
      inputs: inputs$2,
      outputs: outputs$2,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ListBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }]
  });
})();

/**
 * NgModule definition for the ListBox component.
 */
class ListBoxModule {}
ListBoxModule.ɵfac = function ListBoxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListBoxModule)();
};
ListBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListBoxModule
});
ListBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ListBoxComponent],
      exports: [ListBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ListBox component with providers.
 */
class ListBoxAllModule {}
ListBoxAllModule.ɵfac = function ListBoxAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListBoxAllModule)();
};
ListBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListBoxAllModule
});
ListBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ListBoxModule], ListBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ListBoxModule],
      exports: [ListBoxModule],
      providers: []
    }]
  }], null, null);
})();
var DropDownTreeComponent_1;
const inputs$1 = ['actionFailureTemplate', 'allowFiltering', 'allowMultiSelection', 'changeOnBlur', 'cssClass', 'customTemplate', 'delimiterChar', 'destroyPopupOnHide', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enabled', 'fields', 'filterBarPlaceholder', 'filterType', 'floatLabelType', 'footerTemplate', 'headerTemplate', 'htmlAttributes', 'ignoreAccent', 'ignoreCase', 'itemTemplate', 'locale', 'mode', 'noRecordsTemplate', 'placeholder', 'popupHeight', 'popupWidth', 'readonly', 'selectAllText', 'showCheckBox', 'showClearButton', 'showDropDownIcon', 'showSelectAll', 'sortOrder', 'text', 'treeSettings', 'unSelectAllText', 'value', 'valueTemplate', 'width', 'wrapText', 'zIndex'];
const outputs$1 = ['actionFailure', 'beforeOpen', 'blur', 'change', 'close', 'created', 'dataBound', 'destroyed', 'filtering', 'focus', 'keyPress', 'open', 'select', 'valueChange'];
const twoWays$1 = ['value'];
/**
*The DropDownTree component contains a list of predefined values from which you can choose a single or multiple values.
*```html
*<ejs-dropdowntree></ejs-dropdowntree>
*```
*/
let DropDownTreeComponent = DropDownTreeComponent_1 = class DropDownTreeComponent extends DropDownTree {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
DropDownTreeComponent.ɵfac = function DropDownTreeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownTreeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
DropDownTreeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DropDownTreeComponent,
  selectors: [["ejs-dropdowntree"]],
  contentQueries: function DropDownTreeComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.valueTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionFailureTemplate = _t.first);
    }
  },
  inputs: {
    actionFailureTemplate: "actionFailureTemplate",
    allowFiltering: "allowFiltering",
    allowMultiSelection: "allowMultiSelection",
    changeOnBlur: "changeOnBlur",
    cssClass: "cssClass",
    customTemplate: "customTemplate",
    delimiterChar: "delimiterChar",
    destroyPopupOnHide: "destroyPopupOnHide",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    fields: "fields",
    filterBarPlaceholder: "filterBarPlaceholder",
    filterType: "filterType",
    floatLabelType: "floatLabelType",
    footerTemplate: "footerTemplate",
    headerTemplate: "headerTemplate",
    htmlAttributes: "htmlAttributes",
    ignoreAccent: "ignoreAccent",
    ignoreCase: "ignoreCase",
    itemTemplate: "itemTemplate",
    locale: "locale",
    mode: "mode",
    noRecordsTemplate: "noRecordsTemplate",
    placeholder: "placeholder",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    readonly: "readonly",
    selectAllText: "selectAllText",
    showCheckBox: "showCheckBox",
    showClearButton: "showClearButton",
    showDropDownIcon: "showDropDownIcon",
    showSelectAll: "showSelectAll",
    sortOrder: "sortOrder",
    text: "text",
    treeSettings: "treeSettings",
    unSelectAllText: "unSelectAllText",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width",
    wrapText: "wrapText",
    zIndex: "zIndex"
  },
  outputs: {
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    blur: "blur",
    change: "change",
    close: "close",
    created: "created",
    dataBound: "dataBound",
    destroyed: "destroyed",
    filtering: "filtering",
    focus: "focus",
    keyPress: "keyPress",
    open: "open",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropDownTreeComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DropDownTreeComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], DropDownTreeComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], DropDownTreeComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], DropDownTreeComponent.prototype, "valueTemplate", void 0);
__decorate([Template()], DropDownTreeComponent.prototype, "itemTemplate", void 0);
__decorate([Template('No Records Found')], DropDownTreeComponent.prototype, "noRecordsTemplate", void 0);
__decorate([Template('The Request Failed')], DropDownTreeComponent.prototype, "actionFailureTemplate", void 0);
DropDownTreeComponent = DropDownTreeComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], DropDownTreeComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownTreeComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-dropdowntree',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DropDownTreeComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    valueTemplate: [{
      type: ContentChild,
      args: ['valueTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }],
    actionFailureTemplate: [{
      type: ContentChild,
      args: ['actionFailureTemplate']
    }]
  });
})();

/**
 * NgModule definition for the DropDownTree component.
 */
class DropDownTreeModule {}
DropDownTreeModule.ɵfac = function DropDownTreeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownTreeModule)();
};
DropDownTreeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownTreeModule
});
DropDownTreeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownTreeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DropDownTreeComponent],
      exports: [DropDownTreeComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the DropDownTree component with providers.
 */
class DropDownTreeAllModule {}
DropDownTreeAllModule.ɵfac = function DropDownTreeAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DropDownTreeAllModule)();
};
DropDownTreeAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownTreeAllModule
});
DropDownTreeAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DropDownTreeModule], DropDownTreeModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownTreeAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DropDownTreeModule],
      exports: [DropDownTreeModule],
      providers: []
    }]
  }], null, null);
})();
const inputs = ['allowSpaces', 'cssClass', 'dataSource', 'displayTemplate', 'fields', 'filterType', 'highlight', 'ignoreCase', 'itemTemplate', 'locale', 'mentionChar', 'minLength', 'noRecordsTemplate', 'popupHeight', 'popupWidth', 'query', 'showMentionChar', 'sortOrder', 'spinnerTemplate', 'suffixText', 'suggestionCount', 'target'];
const outputs = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeOpen', 'change', 'closed', 'created', 'destroyed', 'filtering', 'opened', 'select'];
const twoWays = [''];
/**
*The Mention component contains a list of predefined values, from which the user can choose a single value.
*```html
*<ejs-mention></ejs-mention>
*```
*/
let MentionComponent = class MentionComponent extends Mention {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
MentionComponent.ɵfac = function MentionComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MentionComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
MentionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MentionComponent,
  selectors: [["ejs-mention"]],
  contentQueries: function MentionComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.displayTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.spinnerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.noRecordsTemplate = _t.first);
    }
  },
  inputs: {
    allowSpaces: "allowSpaces",
    cssClass: "cssClass",
    dataSource: "dataSource",
    displayTemplate: "displayTemplate",
    fields: "fields",
    filterType: "filterType",
    highlight: "highlight",
    ignoreCase: "ignoreCase",
    itemTemplate: "itemTemplate",
    locale: "locale",
    mentionChar: "mentionChar",
    minLength: "minLength",
    noRecordsTemplate: "noRecordsTemplate",
    popupHeight: "popupHeight",
    popupWidth: "popupWidth",
    query: "query",
    showMentionChar: "showMentionChar",
    sortOrder: "sortOrder",
    spinnerTemplate: "spinnerTemplate",
    suffixText: "suffixText",
    suggestionCount: "suggestionCount",
    target: "target"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeOpen: "beforeOpen",
    change: "change",
    closed: "closed",
    created: "created",
    destroyed: "destroyed",
    filtering: "filtering",
    opened: "opened",
    select: "select"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c9,
  decls: 1,
  vars: 0,
  template: function MentionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], MentionComponent.prototype, "displayTemplate", void 0);
__decorate([Template()], MentionComponent.prototype, "itemTemplate", void 0);
__decorate([Template()], MentionComponent.prototype, "spinnerTemplate", void 0);
__decorate([Template('No records found')], MentionComponent.prototype, "noRecordsTemplate", void 0);
MentionComponent = __decorate([ComponentMixins([ComponentBase])], MentionComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MentionComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-mention',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    displayTemplate: [{
      type: ContentChild,
      args: ['displayTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    spinnerTemplate: [{
      type: ContentChild,
      args: ['spinnerTemplate']
    }],
    noRecordsTemplate: [{
      type: ContentChild,
      args: ['noRecordsTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Mention component.
 */
class MentionModule {}
MentionModule.ɵfac = function MentionModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MentionModule)();
};
MentionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MentionModule
});
MentionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MentionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MentionComponent],
      exports: [MentionComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Mention component with providers.
 */
class MentionAllModule {}
MentionAllModule.ɵfac = function MentionAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MentionAllModule)();
};
MentionAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MentionAllModule
});
MentionAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, MentionModule], MentionModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MentionAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MentionModule],
      exports: [MentionModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutoCompleteAllModule, AutoCompleteComponent, AutoCompleteModule, CheckBoxSelectionService, ComboBoxAllModule, ComboBoxComponent, ComboBoxModule, DropDownListAllModule, DropDownListComponent, DropDownListModule, DropDownTreeAllModule, DropDownTreeComponent, DropDownTreeModule, ListBoxAllModule, ListBoxComponent, ListBoxModule, MentionAllModule, MentionComponent, MentionModule, MultiSelectAllModule, MultiSelectComponent, MultiSelectModule, VirtualScrollService };
