import { isNullOrUndefined, extend, getValue, setValue, EventHandler, formatUnit, createElement, removeClass, addClass, closest, Browser, append, merge, remove, deleteObject, Property, ChildProperty, Collection, Complex, SanitizeHtmlHelper, compile, isObject, initializeCSPTemplate, Internationalization, L10n, KeyboardEvents, isUndefined, isObjectArray, Event, NotifyPropertyChanges, Component, classList } from '@syncfusion/ej2-base';
import { Tooltip as Tooltip$1, createSpinner, showSpinner, hideSpinner, Dialog } from '@syncfusion/ej2-popups';
import { getUid as getUid$1, click, Predicate, getActualProperties, getObject, ExcelExport as ExcelExport$1, PdfExport as PdfExport$1, Print, Search, Toolbar as Toolbar$1, RowDD as RowDD$1, DetailRow, Reorder as Reorder$1, Resize as Resize$1, ContextMenu as ContextMenu$1, ColumnMenu as ColumnMenu$1, ColumnChooser, Aggregate, Edit as Edit$2, Group, Page, Sort as Sort$1, Filter as Filter$1, Grid, ForeignKey, filterAfterOpen, getFilterMenuPostion, getCustomDateFormat, setCssInGridPopUp, parentsUntil as parentsUntil$1, ValueFormatter, getForeignData } from '@syncfusion/ej2-grids';
import { DataManager, ODataAdaptor, ODataV4Adaptor, WebApiAdaptor, WebMethodAdaptor, CacheAdaptor, RemoteSaveAdaptor, UrlAdaptor, Query, DataUtil, Deferred } from '@syncfusion/ej2-data';
import { TreeGrid, Edit as Edit$1, VirtualScroll as VirtualScroll$1, Toolbar as Toolbar$2, Selection as Selection$2, RowDD as RowDD$2, Reorder as Reorder$2, Resize as Resize$2, Aggregate as Aggregate$1, Page as Page$1, Sort as Sort$2, Filter as Filter$2, ContextMenu as ContextMenu$3, ExcelExport as ExcelExport$2, ColumnMenu as ColumnMenu$2 } from '@syncfusion/ej2-treegrid';
import { AutoComplete, DropDownList, ComboBox, MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
import { SvgRenderer } from '@syncfusion/ej2-svg-base';
import { Splitter as Splitter$1 } from '@syncfusion/ej2-layouts';
import { Tab, Toolbar as Toolbar$4, ContextMenu as ContextMenu$2 } from '@syncfusion/ej2-navigations';
import { RichTextEditor, MarkdownEditor, FormatPainter, FileManager, EmojiPicker, Table, Image, Toolbar as Toolbar$3, Link, HtmlEditor, QuickToolbar, Count } from '@syncfusion/ej2-richtexteditor';
import { MaskedTextBox, NumericTextBox, TextBox, FormValidator } from '@syncfusion/ej2-inputs';
import { CheckBox } from '@syncfusion/ej2-buttons';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-calendars';
import { PdfColor, PdfFontFamily, PdfStringFormat, PdfPen, PdfDashStyle, PdfBorderOverlapStyle, RectangleF, PdfLayoutBreakType, PointF, SizeF, PdfLayoutType, RowLayoutResult, ElementLayouter, PdfLayoutResult, PdfLayoutFormat, PdfLayoutElement, PdfStringLayouter, PdfStandardFont, PdfFontStyle, PdfSolidBrush, PdfImage, PdfBitmap, PdfTextWebLink, PdfTextAlignment, PdfLineCap, PdfVerticalAlignment, PdfPageTemplateElement, PdfPageNumberField, PdfPageCountField, PdfCompositeField, PdfLinearGradientBrush, PdfPath, PdfBrushes, PdfWordWrapType, PdfPage, PdfDocument, PdfPageSettings, PdfPageOrientation } from '@syncfusion/ej2-pdf-export';

/**
 * Common methods used in Gantt
 */
/**
 * @param {Element} elem .
 * @param {string} selector .
 * @param {boolean} isID .
 * @returns {Element} .
 * @hidden
 */
function parentsUntil(elem, selector, isID) {
  var parent = elem;
  while (parent) {
    if (isID ? parent.id === selector : parent.classList.contains(selector)) {
      break;
    }
    parent = parent.parentElement;
  }
  return parent;
}
/**
 * @param {ITaskData} ganttProp .
 * @returns {boolean} .
 * @hidden
 */
function isScheduledTask(ganttProp) {
  if (isNullOrUndefined(ganttProp.startDate) && isNullOrUndefined(ganttProp.endDate) && isNullOrUndefined(ganttProp.duration)) {
    return null;
  } else if (isNullOrUndefined(ganttProp.startDate) || isNullOrUndefined(ganttProp.endDate) || isNullOrUndefined(ganttProp.duration)) {
    return false;
  } else {
    return true;
  }
}
/**
 * @param {Gantt} parent .
 * @returns {boolean} .
 * @hidden
 */
function isCountRequired(parent) {
  if (parent.dataSource && !(parent.dataSource instanceof DataManager) && 'result' in parent.dataSource) {
    return true;
  }
  return false;
}
/**
 * @param {object} obj .
 * @returns {object} .
 * @hidden
 */
function getSwapKey(obj) {
  var temp = {};
  for (var _i = 0, _a = Object.keys(obj); _i < _a.length; _i++) {
    var key = _a[_i];
    temp[obj[key]] = key;
  }
  return temp;
}
/**
 * @param {object} dataSource .
 * @returns {boolean} .
 * @hidden
 */
function isRemoteData(dataSource) {
  if (dataSource instanceof DataManager) {
    var adaptor = dataSource.adaptor;
    return adaptor instanceof ODataAdaptor || adaptor instanceof ODataV4Adaptor || adaptor instanceof WebApiAdaptor || adaptor instanceof WebMethodAdaptor || adaptor instanceof CacheAdaptor || adaptor instanceof RemoteSaveAdaptor || adaptor instanceof UrlAdaptor;
  }
  return false;
}
/**
 * @param {IGanttData[]} records .
 * @param {boolean} isNotExtend .
 * @param {ITaskAddedEventArgs} eventArgs .
 * @param {Gantt} parent .
 * @returns {object[]} .
 * @hidden
 */
function getTaskData(records, isNotExtend, eventArgs, parent) {
  if (eventArgs) {
    var result = void 0;
    for (var i = 0; i < records.length; i++) {
      var data = isNotExtend ? records[parseInt(i.toString(), 10)].taskData : extend({}, records[parseInt(i.toString(), 10)].taskData, {}, true);
      result = data;
    }
    return result;
  } else {
    var result = [];
    for (var i = 0; i < records.length; i++) {
      if (!isNullOrUndefined(parent) && parent.timezone) {
        updateDates(records[i], parent);
      }
      var data = isNotExtend ? records[parseInt(i.toString(), 10)].taskData : extend({}, records[parseInt(i.toString(), 10)].taskData, {}, true);
      result.push(data);
    }
    return result;
  }
}
/**
 * @param {IGanttData} record .
 * @param {Gantt} parent .
 * @returns {null} .
 * @hidden
 */
function updateDates(record, parent) {
  // let startDate: Date = (record as IGanttData).taskData[parent.taskFields.startDate];
  if (record && !isNullOrUndefined(record.ganttProperties)) {
    record.taskData[parent.taskFields.startDate] = parent.dateValidationModule.remove(record.ganttProperties.startDate, parent.timezone);
    if (parent.taskFields.endDate !== null) {
      record.taskData[parent.taskFields.endDate] = parent.dateValidationModule.remove(record.ganttProperties.endDate, parent.timezone);
    }
    if (parent.taskFields.baselineEndDate || parent.taskFields.baselineStartDate) {
      record.taskData[parent.taskFields.baselineStartDate] = parent.dateValidationModule.remove(record.ganttProperties.baselineStartDate, parent.timezone);
      record.taskData[parent.taskFields.baselineEndDate] = parent.dateValidationModule.remove(record.ganttProperties.baselineEndDate, parent.timezone);
    }
  }
  return null;
}
/**
 * @param {string} str .
 * @param {string[]} args .
 * @returns {string} .
 * @hidden
 */
function formatString(str, args) {
  var regx;
  for (var i = 0; i < args.length; i++) {
    // eslint-disable-next-line security/detect-non-literal-regexp
    regx = new RegExp('\\{' + i + '\\}', 'gm');
    str = str.replace(regx, args[i].toString());
  }
  return str;
}
/**
 * @param {any} value .
 * @param {string} key1 .
 * @param {any} collection .
 * @param {string} key2
 * @returns {number} .
 * @hidden
 */
/* eslint-disable-next-line */
function getIndex(value, key1, collection, key2) {
  var index = -1;
  for (var i = 0; i < collection.length; i++) {
    if (getValue(key1, collection[i]) === getValue(key1, value) && isNullOrUndefined(key2) || !isNullOrUndefined(key2) && getValue(key1, collection[i]) === getValue(key1, value) && getValue(key2, collection[i]) === getValue(key2, value)) {
      index = i;
      break;
    }
  }
  return index;
}
/**
 * @param {number} value .
 * @returns {number} .
 * @hidden
 */
function pixelToPoint(value) {
  return value * 76 / 92;
}
/**
 * @param {number} value .
 * @returns {number} .
 * @hidden
 */
function pointToPixel(value) {
  return value * 92 / 76;
}
var uid = 0;
/**
 * @returns {number} .
 * @hidden
 */
function getUid() {
  return uid++;
}

/**
 *  Date processor is used to handle date of task data.
 */
var DateProcessor = /** @__PURE__ @class */function () {
  function DateProcessor(parent) {
    this.fromSegments = false;
    this.mondayTimeRangeLength = 0;
    this.tuesdayTimeRangeLength = 0;
    this.wednesdayTimeRangeLength = 0;
    this.thursdayTimeRangeLength = 0;
    this.fridayTimeRangeLength = 0;
    this.saturdayTimeRangeLength = 0;
    this.sundayTimeRangeLength = 0;
    this.parent = parent;
  }
  /**
   * @param {ITaskData} ganttProp .
   * @returns {boolean} .
   */
  DateProcessor.prototype.isValidateNonWorkDays = function (ganttProp) {
    return !isNullOrUndefined(ganttProp) && ganttProp.isAutoSchedule && (!this.parent.includeWeekend || this.parent.totalHolidayDates.length > 0) || isNullOrUndefined(ganttProp) && (!this.parent.includeWeekend || this.parent.totalHolidayDates.length > 0);
  };
  /**
   * Method to convert given date value as valid start date
   *
   * @param {Date} date .
   * @param {ITaskData} ganttProp .
   * @param {boolean} validateAsMilestone .
   * @param {boolean} isLoad .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.checkStartDate = function (date, ganttProp, validateAsMilestone, isLoad) {
    if (isNullOrUndefined(date)) {
      return null;
    }
    var currentDay = new Date(date.getTime());
    var dayStartTime = this.parent['getCurrentDayStartTime'](currentDay);
    var dayEndTime = this.parent['getCurrentDayEndTime'](currentDay);
    var cloneStartDate = new Date(date.getTime());
    var hour = this.getSecondsInDecimal(cloneStartDate);
    validateAsMilestone = isNullOrUndefined(validateAsMilestone) ? !isNullOrUndefined(ganttProp) ? ganttProp.isMilestone : false : validateAsMilestone;
    if (hour < dayStartTime && (!validateAsMilestone || isLoad)) {
      this.setTime(dayStartTime, cloneStartDate);
    } else if (hour < dayStartTime && validateAsMilestone) {
      this.setTime(dayStartTime, cloneStartDate);
    } else if (hour === dayEndTime && (!ganttProp || !validateAsMilestone) || hour > dayEndTime) {
      cloneStartDate.setDate(cloneStartDate.getDate() + 1);
      dayStartTime = this.parent['getCurrentDayStartTime'](cloneStartDate);
      this.setTime(dayStartTime, cloneStartDate);
    } else if (hour > dayStartTime && hour < dayEndTime) {
      var workingRange = this.parent.workingTimeRanges;
      if (this.parent.weekWorkingTime.length > 0) {
        workingRange = this.parent['getWorkingRange'](cloneStartDate);
      }
      for (var index = 0; index < workingRange.length; index++) {
        var value = workingRange[index];
        if (hour >= value.to && workingRange[index + 1] && hour < workingRange[index + 1].from) {
          // milestone can fall at end any interval time
          if (hour === value.to && (!ganttProp || !validateAsMilestone) || hour !== value.to) {
            this.setTime(workingRange[index + 1].from, cloneStartDate);
          }
          break;
        }
      }
    }
    var tStartDate;
    if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      do {
        tStartDate = new Date(cloneStartDate.getTime());
        var holidayLength = this.parent.totalHolidayDates.length;
        // check holidays and weekends
        if (this.isValidateNonWorkDays(ganttProp)) {
          dayStartTime = this.parent['getCurrentDayStartTime'](tStartDate);
          if (ganttProp) {
            dayEndTime = this.parent['getCurrentDayEndTime'](ganttProp.endDate ? ganttProp.isAutoSchedule ? ganttProp.endDate : ganttProp.autoEndDate : tStartDate);
          }
          var startTime = !validateAsMilestone || isLoad ? dayStartTime : dayEndTime;
          if (!this.parent.includeWeekend) {
            var tempDate = new Date(cloneStartDate.getTime());
            cloneStartDate = this.getNextWorkingDay(cloneStartDate);
            startTime = this.parent['getCurrentDayStartTime'](cloneStartDate);
            if (tempDate.getTime() !== cloneStartDate.getTime() && !validateAsMilestone) {
              this.setTime(startTime, cloneStartDate);
            }
          }
          for (var count = 0; count < holidayLength; count++) {
            var holidayFrom = this.getDateFromFormat(new Date(this.parent.totalHolidayDates[count]));
            var holidayTo = new Date(holidayFrom.getTime());
            holidayFrom.setHours(0, 0, 0, 0);
            holidayTo.setHours(23, 59, 59, 59);
            if (cloneStartDate.getTime() >= holidayFrom.getTime() && cloneStartDate.getTime() < holidayTo.getTime()) {
              cloneStartDate.setDate(cloneStartDate.getDate() + 1);
              startTime = this.parent['getCurrentDayStartTime'](cloneStartDate);
              this.setTime(startTime, cloneStartDate);
            }
          }
        }
      } while (tStartDate.getTime() !== cloneStartDate.getTime());
      return new Date(cloneStartDate.getTime());
    } else {
      return new Date(cloneStartDate.getTime());
    }
  };
  /**
   * To update given date value to valid end date
   *
   * @param {Date} date .
   * @param {ITaskData} ganttProp .
   * @param {boolean} validateAsMilestone .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.checkEndDate = function (date, ganttProp, validateAsMilestone) {
    if (isNullOrUndefined(date)) {
      return null;
    }
    var dayStartTime;
    var dayEndTime;
    if (this.parent.weekWorkingTime.length > 0) {
      var currentDay = date;
      if (!this.parent.includeWeekend && ganttProp && ganttProp.isAutoSchedule || this.parent.editModule && this.parent.editModule.taskbarEditModule && this.parent.editModule.taskbarEditModule.taskBarEditRecord && !this.parent.editModule.taskbarEditModule.taskBarEditRecord.ganttProperties.isAutoSchedule) {
        currentDay = this.getNextWorkingDay(date);
      }
      dayStartTime = this.parent['getStartTime'](currentDay);
      dayEndTime = this.parent['getEndTime'](currentDay);
    } else {
      dayStartTime = this.parent.defaultStartTime;
      dayEndTime = this.parent.defaultEndTime;
    }
    var cloneEndDate = new Date(date.getTime());
    var hour = this.getSecondsInDecimal(cloneEndDate);
    if (hour > dayEndTime) {
      this.setTime(dayEndTime, cloneEndDate);
    } else if (hour <= dayStartTime && !validateAsMilestone) {
      var taskfields = this.parent.taskFields;
      if (this.parent.editModule && this.parent.editModule['editedRecord'] && !this.parent.editModule['editedRecord'][taskfields.startDate] && this.parent.editModule['editedRecord'][taskfields.endDate]) {
        cloneEndDate.setDate(cloneEndDate.getDate());
      } else {
        cloneEndDate.setDate(cloneEndDate.getDate() - 1);
      }
      dayEndTime = this.parent['getCurrentDayEndTime'](cloneEndDate);
      this.setTime(dayEndTime, cloneEndDate);
    } else if (hour > dayStartTime && hour < dayEndTime) {
      for (var index = 0; index < this.parent.workingTimeRanges.length; index++) {
        var value = this.parent.workingTimeRanges[index];
        if (hour > value.to && this.parent.workingTimeRanges[index + 1] && hour <= this.parent.workingTimeRanges[index + 1].from) {
          this.setTime(this.parent.workingTimeRanges[index].to, cloneEndDate);
          break;
        }
      }
    }
    var tempCheckDate;
    if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      do {
        tempCheckDate = new Date(cloneEndDate.getTime());
        var holidayLength = this.parent.totalHolidayDates.length;
        if (this.isValidateNonWorkDays(ganttProp)) {
          if (!this.parent.includeWeekend) {
            var tempDate = new Date(cloneEndDate.getTime());
            cloneEndDate = this.getPreviousWorkingDay(cloneEndDate);
            dayEndTime = this.parent['getCurrentDayEndTime'](cloneEndDate);
            if (tempDate.getTime() !== cloneEndDate.getTime()) {
              this.setTime(dayEndTime, cloneEndDate);
            }
          }
          for (var count = 0; count < holidayLength; count++) {
            var holidayFrom = this.getDateFromFormat(new Date(this.parent.totalHolidayDates[count]));
            var holidayTo = new Date(holidayFrom.getTime());
            var tempHoliday = new Date(cloneEndDate.getTime());
            tempHoliday.setMinutes(cloneEndDate.getMilliseconds() - 2);
            holidayFrom.setHours(0, 0, 0, 0);
            holidayTo.setHours(23, 59, 59, 59);
            if (cloneEndDate.getTime() >= holidayFrom.getTime() && cloneEndDate.getTime() < holidayTo.getTime() || tempHoliday.getTime() >= holidayFrom.getTime() && tempHoliday.getTime() < holidayTo.getTime()) {
              cloneEndDate.setDate(cloneEndDate.getDate() - 1);
              dayEndTime = this.parent['getCurrentDayEndTime'](cloneEndDate);
              if (!(cloneEndDate.getTime() === holidayFrom.getTime() && dayEndTime === 86400 && this.getSecondsInDecimal(cloneEndDate) === 0)) {
                this.setTime(dayEndTime, cloneEndDate);
              }
            }
          }
        }
      } while (tempCheckDate.getTime() !== cloneEndDate.getTime());
      return new Date(cloneEndDate.getTime());
    } else {
      if (!isNullOrUndefined(cloneEndDate)) {
        dayEndTime = this.parent['getCurrentDayEndTime'](date);
        this.setTime(dayEndTime, cloneEndDate);
      }
      return new Date(cloneEndDate.getTime());
    }
  };
  /**
   * To validate the baseline start date
   *
   * @param {Date} date .
   * @param {ITaskData} ganttProp .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.checkBaselineStartDate = function (date, ganttProp) {
    if (isNullOrUndefined(date)) {
      return null;
    } else {
      var dayStartTime = this.parent['getCurrentDayStartTime'](date);
      var dayEndTime = this.parent['getCurrentDayEndTime'](ganttProp ? ganttProp.endDate ? ganttProp.isAutoSchedule ? ganttProp.endDate : ganttProp.autoEndDate : date : date);
      var cloneDate = new Date(date.getTime());
      var hour = this.getSecondsInDecimal(cloneDate);
      if (hour < dayStartTime) {
        this.setTime(dayStartTime, cloneDate);
      } else if (hour > dayEndTime) {
        cloneDate.setDate(cloneDate.getDate() + 1);
        if (this.parent.weekWorkingTime.length > 0) {
          dayStartTime = this.parent['getStartTime'](cloneDate);
        } else {
          dayStartTime = this.parent.defaultStartTime;
        }
        this.setTime(dayStartTime, cloneDate);
      } else if (hour > dayStartTime && hour < dayEndTime) {
        for (var i = 0; i < this.parent.workingTimeRanges.length; i++) {
          var value = this.parent.workingTimeRanges[i];
          if (hour > value.to && this.parent.workingTimeRanges[i + 1] && hour < this.parent.workingTimeRanges[i + 1].from) {
            this.setTime(this.parent.workingTimeRanges[i + 1].from, cloneDate);
            break;
          }
        }
      }
      return cloneDate;
    }
  };
  /**
   * To validate baseline end date
   *
   * @param {Date} date .
   * @param {ITaskData} ganttProp .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.checkBaselineEndDate = function (date, ganttProp) {
    if (isNullOrUndefined(date)) {
      return null;
    } else {
      var dayEndTime = this.parent['getCurrentDayEndTime'](date);
      var dayStartTime = this.parent['getCurrentDayStartTime'](ganttProp ? ganttProp.startDate ? ganttProp.isAutoSchedule ? ganttProp.startDate : ganttProp.autoStartDate : date : date);
      var cloneDate = new Date(date.getTime());
      var hour = this.getSecondsInDecimal(cloneDate);
      if (hour > dayEndTime) {
        this.setTime(dayEndTime, cloneDate);
      } else if (hour < dayStartTime && !isNullOrUndefined(ganttProp) && !ganttProp.isMilestone) {
        cloneDate.setDate(cloneDate.getDate() - 1);
        dayEndTime = this.parent['getCurrentDayEndTime'](cloneDate);
        this.setTime(dayEndTime, cloneDate);
      } else if (hour > dayStartTime && hour < dayEndTime) {
        for (var i = 0; i < this.parent.workingTimeRanges.length; i++) {
          var value = this.parent.workingTimeRanges[i];
          if (hour > value.to && this.parent.workingTimeRanges[i + 1] && hour <= this.parent.workingTimeRanges[i + 1].from) {
            this.setTime(this.parent.workingTimeRanges[i].to, cloneDate);
            break;
          }
        }
      }
      if (ganttProp && ganttProp.baselineStartDate && cloneDate && ganttProp.baselineStartDate.getTime() > cloneDate.getTime()) {
        cloneDate.setDate(cloneDate.getDate() + 1);
      }
      return cloneDate;
    }
  };
  /**
   * To calculate start date value from duration and end date
   *
   * @param {IGanttData} ganttData - Defines the gantt data.
   * @returns {void} .
   * @private
   */
  DateProcessor.prototype.calculateStartDate = function (ganttData) {
    var ganttProp = ganttData.ganttProperties;
    var tempStartDate = null;
    if (!isNullOrUndefined(ganttProp.endDate) && !isNullOrUndefined(ganttProp.duration)) {
      tempStartDate = this.getStartDate(ganttProp.endDate, ganttProp.duration, ganttProp.durationUnit, ganttProp);
    }
    this.parent.setRecordValue('startDate', tempStartDate, ganttProp, true);
    if (this.parent.taskFields.startDate) {
      this.parent.dataOperation.updateMappingData(ganttData, 'startDate');
    }
  };
  /**
   *
   * @param {IGanttData} ganttData - Defines the gantt data.
   * @returns {void} .
   * @private
   */
  DateProcessor.prototype.calculateEndDate = function (ganttData) {
    var ganttProp = ganttData.ganttProperties;
    var tempEndDate = null;
    var dayStartTime;
    var dayEndTime;
    if (!isNullOrUndefined(ganttProp.startDate)) {
      if (!isNullOrUndefined(ganttProp.endDate) && isNullOrUndefined(ganttProp.duration)) {
        if (this.compareDates(ganttProp.startDate, ganttProp.endDate) === 1) {
          this.parent.setRecordValue('startDate', new Date(ganttProp.endDate.getTime()), ganttProp, true);
          dayStartTime = this.parent['getCurrentDayStartTime'](ganttProp.isAutoSchedule ? ganttProp.autoStartDate : ganttProp.startDate);
          dayEndTime = this.parent['getCurrentDayEndTime'](ganttProp.isAutoSchedule ? ganttProp.autoEndDate : ganttProp.endDate);
          this.setTime(dayStartTime, ganttProp.startDate);
        }
        this.calculateDuration(ganttData);
      }
      if (!isNullOrUndefined(ganttProp.duration)) {
        var duration = !isNullOrUndefined(ganttProp.segments) && ganttProp.segments.length > 1 ? this.totalDuration(ganttProp.segments) : ganttProp.duration;
        tempEndDate = this.getEndDate(ganttProp.startDate, duration, ganttProp.durationUnit, ganttProp, false);
      }
      this.parent.setRecordValue('endDate', tempEndDate, ganttProp, true);
    } else {
      tempEndDate = ganttData[this.parent.taskFields.endDate];
      if (!isNullOrUndefined(tempEndDate)) {
        dayEndTime = this.parent['getCurrentDayEndTime'](tempEndDate);
        this.setTime(dayEndTime, tempEndDate);
      }
      this.parent.setRecordValue('endDate', tempEndDate, ganttProp, true);
    }
    if (this.parent.taskFields.endDate) {
      this.parent.dataOperation.updateMappingData(ganttData, 'endDate');
    }
  };
  DateProcessor.prototype.totalDuration = function (segments) {
    var duration = 0;
    for (var i = 0; i < segments.length; i++) {
      duration += segments[i].duration + segments[i].offsetDuration;
    }
    return duration;
  };
  /**
   * To calculate duration from start date and end date
   *
   * @param {IGanttData} ganttData - Defines the gantt data.
   * @returns {void} .
   */
  DateProcessor.prototype.calculateDuration = function (ganttData) {
    var ganttProperties = ganttData.ganttProperties;
    var tDuration;
    if (!isNullOrUndefined(ganttProperties.segments) && ganttProperties.segments.length > 0 && !isNullOrUndefined(this.parent.editModule.taskbarEditModule)) {
      tDuration = this.parent.editModule.taskbarEditModule.sumOfDuration(ganttProperties.segments);
    } else {
      if (!isNullOrUndefined(this.parent.taskFields.milestone) && !isNullOrUndefined(ganttProperties.startDate) && !isNullOrUndefined(ganttProperties.endDate) && ganttProperties.startDate.getTime() === ganttProperties.endDate.getTime() && ganttData.taskData[this.parent.taskFields.milestone] === false) {
        tDuration = 1;
      } else {
        tDuration = this.getDuration(ganttProperties.startDate, ganttProperties.endDate, ganttProperties.durationUnit, ganttProperties.isAutoSchedule, ganttProperties.isMilestone);
      }
    }
    this.parent.setRecordValue('duration', tDuration, ganttProperties, true);
    var col = this.parent.columnByField[this.parent.columnMapping.duration];
    if (!isNullOrUndefined(this.parent.editModule) && !isNullOrUndefined(this.parent.editModule.cellEditModule) && !this.parent.editModule.cellEditModule.isCellEdit && !isNullOrUndefined(col)) {
      if (!isNullOrUndefined(col.edit) && !isNullOrUndefined(col.edit.read)) {
        var dialog = this.parent.editModule.dialogModule.dialog;
        if (!isNullOrUndefined(dialog)) {
          var textBox = dialog.querySelector('#' + this.parent.element.id + 'Duration').ej2_instances[0];
          if (!isNullOrUndefined(textBox) && textBox.value !== tDuration.toString()) {
            textBox.value = tDuration.toString();
            textBox.dataBind();
          }
        }
      }
      if (this.parent.taskFields.duration) {
        this.parent.dataOperation.updateMappingData(ganttData, 'duration');
        if (this.parent.taskFields.durationUnit) {
          this.parent.dataOperation.updateMappingData(ganttData, 'durationUnit');
        }
      }
    }
  };
  /**
   *
   * @param {Date} sDate Method to get total nonworking time between two date values
   * @param {Date} eDate .
   * @param {boolean} isAutoSchedule .
   * @param {boolean} isCheckTimeZone .
   * @returns {number} .
   */
  DateProcessor.prototype.getNonworkingTime = function (sDate, eDate, isAutoSchedule, isCheckTimeZone) {
    isCheckTimeZone = isNullOrUndefined(isCheckTimeZone) ? true : isCheckTimeZone;
    var weekendCount = !this.parent.includeWeekend && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) && isAutoSchedule ? this.getWeekendCount(sDate, eDate) : 0;
    var totalHours = this.getNumberOfSeconds(sDate, eDate, isCheckTimeZone);
    var holidaysCount = isAutoSchedule && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) ? this.getHolidaysCount(sDate, eDate) : 0;
    var totWorkDays = (totalHours - weekendCount * 86400 - holidaysCount * 86400) / 86400; // working days between two dates
    var nonWorkHours = this.getNonWorkingSecondsOnDate(sDate, eDate, isAutoSchedule);
    var totalNonWorkTime = (this.parent.weekWorkingTime.length > 0 ? this.nonWorkingSeconds(sDate, eDate, isAutoSchedule, totWorkDays) : totWorkDays * (86400 - this.parent.secondsPerDay)) + weekendCount * 86400 + holidaysCount * 86400 + nonWorkHours;
    return totalNonWorkTime;
  };
  DateProcessor.prototype.nonWorkingSeconds = function (sDate, eDate, isAutoSchedule, workDays, fromDuration) {
    var newStartDate = sDate.getTime() > eDate.getTime() ? new Date(eDate.getTime()) : new Date(sDate.getTime());
    var newEndDate = sDate.getTime() > eDate.getTime() ? new Date(sDate.getTime()) : new Date(eDate.getTime());
    var timeDiff = 0;
    var count = 0;
    if (fromDuration) {
      var dayStartTime = this.parent['getCurrentDayStartTime'](newStartDate);
      var dayEndTime = this.parent['getCurrentDayEndTime'](newStartDate);
      if (!(newStartDate.getHours() < dayEndTime / 3600 && newStartDate.getHours() >= dayStartTime / 3600)) {
        newStartDate.setDate(newStartDate.getDate() + 1);
      }
    } else {
      newStartDate.setDate(newStartDate.getDate() + 1);
      newStartDate.setHours(0, 0, 0, 0);
      newEndDate.setHours(0, 0, 0, 0);
    }
    if (workDays > 0 || isNullOrUndefined(workDays)) {
      while (fromDuration && newStartDate.getTime() <= newEndDate.getTime() || !fromDuration && newStartDate.getTime() < newEndDate.getTime()) {
        if (isAutoSchedule) {
          if (this.isOnHolidayOrWeekEnd(newStartDate, true)) {
            do {
              newStartDate.setDate(newStartDate.getDate() + 1);
            } while (this.isOnHolidayOrWeekEnd(newStartDate, true));
          }
          if (!this.parent.includeWeekend) {
            this.getNextWorkingDay(newStartDate);
          }
        }
        if (newStartDate.getTime() <= newEndDate.getTime()) {
          count++;
          var currentDaySeconds = this.parent['getSecondsPerDay'](newStartDate);
          if (fromDuration) {
            timeDiff += currentDaySeconds;
          } else {
            timeDiff += 86400 - currentDaySeconds;
          }
          newStartDate.setDate(newStartDate.getDate() + 1);
          if (isAutoSchedule) {
            if (this.isOnHolidayOrWeekEnd(newStartDate, true)) {
              do {
                newStartDate.setDate(newStartDate.getDate() + 1);
              } while (this.isOnHolidayOrWeekEnd(newStartDate, true));
            }
            if (!this.parent.includeWeekend) {
              this.getNextWorkingDay(newStartDate);
            }
          }
        }
      }
    } else {
      return 0;
    }
    if (fromDuration) {
      if (timeDiff > 0) {
        timeDiff = timeDiff / count;
      } else {
        timeDiff = this.parent.secondsPerDay;
      }
    }
    return timeDiff;
  };
  /**
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {string} durationUnit .
   * @param {boolean} isAutoSchedule .
   * @param {boolean} isMilestone .
   * @param {boolean} isCheckTimeZone .
   * @returns {number} .
   * @private
   */
  DateProcessor.prototype.getDuration = function (startDate, endDate, durationUnit, isAutoSchedule, isMilestone, isCheckTimeZone) {
    if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
      return null;
    }
    isCheckTimeZone = isNullOrUndefined(isCheckTimeZone) ? true : isCheckTimeZone;
    var durationValue = 0;
    var timeDiff = this.getTimeDifference(startDate, endDate, isCheckTimeZone) / 1000;
    var nonWorkHours = this.getNonworkingTime(startDate, endDate, isAutoSchedule, isCheckTimeZone);
    var durationHours = timeDiff - nonWorkHours;
    var totSeconds;
    if (this.parent.weekWorkingTime.length > 0) {
      totSeconds = this.nonWorkingSeconds(startDate, endDate, isAutoSchedule, undefined, true);
    } else {
      totSeconds = this.parent.secondsPerDay;
    }
    if (isMilestone && this.parent.getFormatedDate(startDate) === this.parent.getFormatedDate(endDate)) {
      durationValue = 0;
    } else {
      if (!durationUnit || durationUnit === 'day') {
        durationValue = durationHours / totSeconds;
      } else if (durationUnit === 'minute') {
        durationValue = durationHours / 60;
      } else {
        durationValue = durationHours / 3600;
      }
    }
    return parseFloat(durationValue.toString());
  };
  /**
   *
   * @param {number} duration .
   * @param {string} durationUnit .
   * @param {Date} date .
   *  @returns {number} .
   */
  DateProcessor.prototype.getDurationAsSeconds = function (duration, durationUnit, date) {
    var value = 0;
    var totSeconds;
    if (this.parent.weekWorkingTime.length > 0) {
      totSeconds = this.parent['getSecondsPerDay'](date);
    } else {
      totSeconds = this.parent.secondsPerDay;
    }
    if (!durationUnit || durationUnit.toLocaleLowerCase() === 'day') {
      value = totSeconds * duration;
    } else if (durationUnit.toLocaleLowerCase() === 'hour') {
      value = duration * 3600;
    } else {
      value = duration * 60;
    }
    return value;
  };
  /**
   * To get date from start date and duration
   *
   * @param {Date} startDate .
   * @param {number} duration .
   * @param {string} durationUnit .
   * @param {ITaskData} ganttProp .
   * @param {boolean} validateAsMilestone .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.getEndDate = function (startDate, duration, durationUnit, ganttProp, validateAsMilestone) {
    var tempStart = new Date(startDate.getTime());
    var endDate = new Date(startDate.getTime());
    var sDate = new Date(startDate.getTime());
    var secondDuration;
    if (this.parent.weekWorkingTime.length > 0 && (!durationUnit || durationUnit.toLocaleLowerCase() === 'day')) {
      secondDuration = this.calculateSecondDuration(duration, sDate, secondDuration, startDate, true);
    } else {
      secondDuration = this.getDurationAsSeconds(duration, durationUnit, startDate);
    }
    var nonWork = 0;
    var workHours = 0;
    while (secondDuration > 0) {
      endDate.setSeconds(endDate.getSeconds() + secondDuration);
      nonWork = this.getNonworkingTime(tempStart, endDate, ganttProp.isAutoSchedule, true);
      workHours = secondDuration - nonWork;
      secondDuration = secondDuration - workHours;
      if (secondDuration > 0) {
        endDate = this.checkStartDate(endDate, ganttProp, validateAsMilestone);
      }
      tempStart = new Date(endDate.getTime());
    }
    return endDate;
  };
  /**
   * Calculate start date based on end date and duration.
   *
   * @param {Date} endDate - To calculate start date value from end date and duration.
   * @param {number} duration - The duration value.
   * @param {string} durationUnit - The unit of duration.
   * @param {ITaskData} ganttProp - The Gantt task properties.
   * @param {boolean} fromValidation - A flag indicating if the calculation is from validation.
   * @returns {Date} The calculated start date.
   * @private
   */
  DateProcessor.prototype.getStartDate = function (endDate, duration, durationUnit, ganttProp, fromValidation) {
    var tempEnd = new Date(endDate.getTime());
    var startDate = new Date(endDate.getTime());
    var secondDuration;
    var eDate = new Date(tempEnd.getTime());
    if (this.parent.weekWorkingTime.length > 0) {
      secondDuration = this.calculateSecondDuration(duration, eDate, secondDuration, tempEnd, false);
    } else {
      secondDuration = this.getDurationAsSeconds(duration, durationUnit, tempEnd);
    }
    var nonWork = 0;
    var workHours = 0;
    while (secondDuration > 0) {
      startDate.setSeconds(startDate.getSeconds() - secondDuration);
      nonWork = this.getNonworkingTime(startDate, tempEnd, ganttProp.isAutoSchedule, true);
      workHours = secondDuration - nonWork;
      secondDuration = secondDuration - workHours;
      if (secondDuration > 0) {
        tempEnd = this.checkEndDate(startDate, ganttProp);
      }
      tempEnd = new Date(startDate.getTime());
    }
    /* To render the milestone in proper date while loading */
    if (fromValidation && ganttProp.isMilestone) {
      startDate.setDate(startDate.getDate() - 1);
      var dayEndTime = this.parent['getCurrentDayEndTime'](ganttProp.endDate ? ganttProp.isAutoSchedule ? ganttProp.endDate : ganttProp.autoEndDate : startDate);
      this.parent.dateValidationModule.setTime(dayEndTime, startDate);
      startDate = this.parent.dateValidationModule.checkStartDate(startDate, ganttProp, true);
    }
    return startDate;
  };
  DateProcessor.prototype.calculateSecondDuration = function (duration, sDate, secondDuration, startDate, fromEndDate) {
    if (duration < 1) {
      secondDuration = this.parent['getSecondsPerDay'](sDate) * duration;
    } else {
      secondDuration = 0;
      var durationValue = duration;
      var dayStartTime = this.parent['getCurrentDayStartTime'](sDate);
      var dayEndTime = this.parent['getCurrentDayEndTime'](sDate);
      if (!(sDate.getHours() < dayEndTime / 3600 && sDate.getHours() > dayStartTime / 3600) && this.fromSegments) {
        if (fromEndDate) {
          sDate.setDate(sDate.getDate() + 1);
        } else {
          sDate.setDate(sDate.getDate() - 1);
        }
      }
      while (durationValue > 0) {
        if (this.isOnHolidayOrWeekEnd(sDate, true)) {
          do {
            if (fromEndDate) {
              sDate.setDate(sDate.getDate() + 1);
            } else {
              sDate.setDate(sDate.getDate() - 1);
            }
          } while (this.isOnHolidayOrWeekEnd(sDate, true));
        }
        if (!this.parent.includeWeekend) {
          sDate = fromEndDate ? this.getNextWorkingDay(sDate) : this.getPreviousWorkingDay(sDate);
        }
        var totSeconds = this.parent['getSecondsPerDay'](sDate);
        var num = 0;
        if (this.getSecondsInDecimal(startDate) !== this.parent['getStartTime'](startDate) && !Number.isInteger(durationValue)) {
          var deciNumber = duration.toString().split('.');
          num = parseFloat('.' + deciNumber[1]);
          totSeconds = totSeconds * num;
          durationValue = durationValue - num;
        }
        if (durationValue < 1) {
          totSeconds = totSeconds * durationValue;
        }
        secondDuration = secondDuration + totSeconds;
        if (fromEndDate) {
          sDate.setDate(sDate.getDate() + 1);
        } else {
          sDate.setDate(sDate.getDate() - 1);
        }
        if (!num) {
          durationValue--;
        }
      }
    }
    return secondDuration;
  };
  /**
   * @param {ITaskData} ganttProp .
   * @param {boolean} isLoad .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.getProjectStartDate = function (ganttProp, isLoad) {
    if (!isNullOrUndefined(this.parent.cloneProjectStartDate)) {
      if (typeof this.parent.cloneProjectStartDate === 'string') {
        this.parent.cloneProjectStartDate = this.getDateFromFormat(this.parent.cloneProjectStartDate);
      }
      var cloneStartDate = this.checkStartDate(this.parent.cloneProjectStartDate);
      this.parent.cloneProjectStartDate = cloneStartDate;
      return new Date(cloneStartDate.getTime());
    } else if (!isNullOrUndefined(this.parent.projectStartDate)) {
      var cloneStartDate = this.getDateFromFormat(this.parent.projectStartDate);
      this.parent.cloneProjectStartDate = this.checkStartDate(cloneStartDate);
    } else if (!isNullOrUndefined(isLoad)) {
      var flatData = this.parent.flatData;
      var minStartDate = void 0;
      if (flatData.length > 0) {
        minStartDate = flatData[0].ganttProperties.startDate;
      } else {
        minStartDate = new Date();
        minStartDate.setHours(0, 0, 0, 0);
      }
      for (var index = 1; index < flatData.length; index++) {
        var startDate = flatData[index].ganttProperties.startDate;
        if (!isNullOrUndefined(startDate) && this.compareDates(startDate, minStartDate) === -1) {
          minStartDate = startDate;
        }
      }
      this.parent.cloneProjectStartDate = this.checkStartDate(minStartDate, ganttProp);
    } else {
      return null;
    }
    return new Date(this.parent.cloneProjectStartDate.getTime());
  };
  /**
   * @param {ITaskData} ganttProp .
   * @param {boolean} isAuto .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.getValidStartDate = function (ganttProp, isAuto) {
    var sDate = null;
    var startDate = isAuto ? ganttProp.autoStartDate : ganttProp.startDate;
    var endDate = isAuto ? ganttProp.autoEndDate : ganttProp.endDate;
    var duration = !ganttProp.isAutoSchedule && ganttProp.autoDuration ? ganttProp.autoDuration : ganttProp.duration;
    if (isNullOrUndefined(startDate)) {
      if (!isNullOrUndefined(endDate)) {
        sDate = new Date(endDate.getTime());
        var dayStartTime = this.parent['getCurrentDayStartTime'](sDate);
        this.setTime(dayStartTime, sDate);
      } else if (!isNullOrUndefined(duration)) {
        var ganttTask = this.parent.getTaskByUniqueID(ganttProp.uniqueID);
        if (this.parent.allowUnscheduledTasks && ganttTask && ganttTask.parentItem && isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
          var parentTask = this.parent.getParentTask(ganttTask.parentItem);
          while (parentTask && !parentTask.ganttProperties.startDate) {
            parentTask = this.parent.getParentTask(parentTask.parentItem);
          }
          sDate = !parentTask || !parentTask.ganttProperties.startDate ? this.parent.cloneProjectStartDate : parentTask.ganttProperties.startDate;
        } else {
          sDate = this.getProjectStartDate(ganttProp);
        }
      }
    } else {
      sDate = new Date(startDate.getTime());
    }
    return sDate;
  };
  /**
   *
   * @param {ITaskData} ganttProp .
   * @param {boolean} isAuto .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.getValidEndDate = function (ganttProp, isAuto) {
    var eDate = null;
    var startDate = isAuto ? ganttProp.autoStartDate : ganttProp.startDate;
    var endDate = isAuto ? ganttProp.autoEndDate : ganttProp.endDate;
    var duration = isAuto ? ganttProp.autoDuration : ganttProp.duration;
    if (isNullOrUndefined(endDate)) {
      if (!isNullOrUndefined(startDate)) {
        if (ganttProp.isMilestone) {
          eDate = this.checkStartDate(startDate);
        } else {
          eDate = new Date(startDate.getTime());
          var dayEndTime = this.parent['getCurrentDayEndTime'](endDate ? endDate : eDate);
          this.setTime(dayEndTime, eDate);
        }
      } else if (!isNullOrUndefined(duration)) {
        var sDate = this.getValidStartDate(ganttProp);
        if (sDate) {
          eDate = this.getEndDate(sDate, duration, ganttProp.durationUnit, ganttProp, false);
        }
      }
    } else {
      eDate = new Date(endDate.getTime());
    }
    return eDate;
  };
  DateProcessor.prototype.getWorkingTime = function (day, currentRange, startDate, totalSeconds, count, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges) {
    if (!isNullOrUndefined(currentRange.from) && !isNullOrUndefined(currentRange.to)) {
      startDate.setHours(0, 0, 0, 0);
      var tempDate = new Date(startDate.getTime());
      startDate.setTime(startDate.getTime() + currentRange.from * 3600000);
      var startHour = new Date(startDate.getTime());
      if (currentRange.to === 24) {
        var currentRangeTo = 24 * 60 * 60 * 1000;
        tempDate.setTime(tempDate.getTime() + currentRangeTo);
      } else {
        tempDate.setTime(tempDate.getTime() + currentRange.to * 3600000);
      }
      var endHour = new Date(tempDate.getTime());
      var timeDiff = endHour.getTime() - startHour.getTime();
      var sdSeconds = this.getSecondsInDecimal(startHour);
      var edSeconds = this.getSecondsInDecimal(endHour);
      if (edSeconds === 0) {
        edSeconds = 86400;
      }
      totalSeconds += timeDiff / 1000;
      if (count === 0) {
        this.parent.defaultStartTime = sdSeconds;
        if (this.parent.weekWorkingTime.length > 0) {
          this.assignStartTime(day, sdSeconds);
        }
      }
      if (count === this[day.toLowerCase() + 'TimeRangeLength'] - 1 || day === '') {
        this.parent.defaultEndTime = edSeconds;
        if (this.parent.weekWorkingTime.length > 0) {
          this.assignEndTime(day, edSeconds);
        }
      }
      if (count > 0) {
        if (day === '') {
          nonWorkingHours.push(nonWorkingHours[nonWorkingHours.length - 1] + sdSeconds - workingTimeRanges[count - 1].to);
          if (workingTimeRanges[count - 1].to < sdSeconds) {
            nonWorkingTimeRanges.push({
              from: workingTimeRanges[count - 1].to,
              to: sdSeconds,
              isWorking: false,
              interval: sdSeconds - workingTimeRanges[count - 1].to
            });
          }
        } else {
          this.parent[day.toLowerCase() + 'NonWorkingHours'].push(this.parent[day.toLowerCase() + 'NonWorkingHours'][this.parent[day.toLowerCase() + 'NonWorkingHours'].length - 1] + sdSeconds - this.parent[day.toLowerCase() + 'NonWorkingTimeRanges'][count - 1].to);
          if (this.parent[day.toLowerCase() + 'WorkingTimeRanges'][count - 1].to < sdSeconds) {
            this.parent[day.toLowerCase() + 'NonWorkingTimeRanges'].push({
              from: this.parent[day.toLowerCase() + 'WorkingTimeRanges'][count - 1].to,
              to: sdSeconds,
              isWorking: false,
              interval: sdSeconds - this.parent[day.toLowerCase() + 'WorkingTimeRanges'][count - 1].to
            });
          }
        }
      } else {
        if (day === '') {
          nonWorkingHours.push(0);
          nonWorkingTimeRanges.push({
            from: 0,
            to: sdSeconds,
            isWorking: false,
            interval: sdSeconds
          });
        } else {
          this.parent[day.toLowerCase() + 'NonWorkingHours'].push(0);
          this.parent[day.toLowerCase() + 'NonWorkingTimeRanges'].push({
            from: 0,
            to: sdSeconds,
            isWorking: false,
            interval: sdSeconds
          });
        }
      }
      if (day === '') {
        workingTimeRanges.push({
          from: sdSeconds,
          to: edSeconds
        });
        nonWorkingTimeRanges.push({
          from: sdSeconds,
          to: edSeconds,
          isWorking: true,
          interval: edSeconds - sdSeconds
        });
      } else {
        this.parent[day.toLowerCase() + 'WorkingTimeRanges'].push({
          from: sdSeconds,
          to: edSeconds
        });
        this.parent[day.toLowerCase() + 'NonWorkingTimeRanges'].push({
          from: sdSeconds,
          to: edSeconds,
          isWorking: true,
          interval: edSeconds - sdSeconds
        });
      }
    }
    return totalSeconds;
  };
  DateProcessor.prototype.assignStartTime = function (day, sdSeconds) {
    switch (day) {
      case 'Monday':
        this.parent.mondayDefaultStartTime = sdSeconds;
        break;
      case 'Tuesday':
        this.parent.tuesdayDefaultStartTime = sdSeconds;
        break;
      case 'Wednesday':
        this.parent.wednesdayDefaultStartTime = sdSeconds;
        break;
      case 'Thursday':
        this.parent.thursdayDefaultStartTime = sdSeconds;
        break;
      case 'Friday':
        this.parent.fridayDefaultStartTime = sdSeconds;
        break;
      case 'Saturday':
        this.parent.saturdayDefaultStartTime = sdSeconds;
        break;
      case 'Sunday':
        this.parent.sundayDefaultStartTime = sdSeconds;
        break;
    }
  };
  DateProcessor.prototype.assignEndTime = function (day, edSeconds) {
    switch (day) {
      case 'Monday':
        this.parent.mondayDefaultEndTime = edSeconds;
        break;
      case 'Tuesday':
        this.parent.tuesdayDefaultEndTime = edSeconds;
        break;
      case 'Wednesday':
        this.parent.wednesdayDefaultEndTime = edSeconds;
        break;
      case 'Thursday':
        this.parent.thursdayDefaultEndTime = edSeconds;
        break;
      case 'Friday':
        this.parent.fridayDefaultEndTime = edSeconds;
        break;
      case 'Saturday':
        this.parent.saturdayDefaultEndTime = edSeconds;
        break;
      case 'Sunday':
        this.parent.sundayDefaultEndTime = edSeconds;
        break;
    }
  };
  DateProcessor.prototype.calculateWeekWorkTime = function (day, currentDay, startDate, totalSeconds, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges) {
    var seconds = 0;
    for (var j = 0; j < currentDay.length; j++) {
      var currentRange = currentDay[j];
      seconds = seconds + this.getWorkingTime(day, currentRange, startDate, totalSeconds, j, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges);
    }
    if (this.parent[day.toLowerCase() + 'DefaultStartTime'] / 3600 !== 24) {
      this.parent[day.toLowerCase() + 'NonWorkingTimeRanges'].push({
        from: this.parent[day.toLowerCase() + 'DefaultEndTime'],
        to: 86400,
        isWorking: false,
        interval: 86400 - this.parent[day.toLowerCase() + 'DefaultEndTime']
      });
    }
    return seconds;
  };
  /**
   * @returns {number} .
   * @private
   */
  DateProcessor.prototype.getSecondsPerDay = function () {
    var totalSeconds = 0;
    var startDate = new Date('10/11/2018');
    this.parent.nonWorkingHours = [];
    var nonWorkingHours = this.parent.nonWorkingHours;
    this.parent.workingTimeRanges = [];
    this.parent.workingTimeRanges = [];
    var workingTimeRanges = this.parent.workingTimeRanges;
    this.parent.nonWorkingTimeRanges = [];
    var nonWorkingTimeRanges = this.parent.nonWorkingTimeRanges;
    for (var _i = 0, _a = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; _i < _a.length; _i++) {
      var day = _a[_i];
      this[day.toLowerCase() + "TimeRangeLength"] = this.parent.dayWorkingTime.length;
    }
    if (this.parent.weekWorkingTime.length > 0) {
      for (var i = 0; i < this.parent.weekWorkingTime.length; i++) {
        this[this.parent.weekWorkingTime[i].dayOfWeek.toLowerCase() + 'TimeRangeLength'] = this.parent.weekWorkingTime[i].timeRange.length;
      }
      var weekWorkingTime = this.parent.weekWorkingTime;
      var dayWorkingTime_1 = this.parent.dayWorkingTime;
      for (var _b = 0, weekWorkingTime_1 = weekWorkingTime; _b < weekWorkingTime_1.length; _b++) {
        var weekDay = weekWorkingTime_1[_b];
        if (weekDay.timeRange && weekDay.timeRange.length > 0) {
          var seconds_1 = this.calculateWeekWorkTime(weekDay.dayOfWeek, weekDay.timeRange, startDate, totalSeconds, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges);
          this.parent[weekDay.dayOfWeek.toLowerCase() + "Seconds"] = seconds_1;
        }
      }
      for (var _c = 0, _d = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; _c < _d.length; _c++) {
        var day = _d[_c];
        var dayDefaultStartTime = this.parent[day.toLowerCase() + "DefaultStartTime"];
        if (isNullOrUndefined(dayDefaultStartTime)) {
          var seconds_2 = this.calculateWeekWorkTime(day, dayWorkingTime_1, startDate, totalSeconds, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges);
          this.parent[day.toLowerCase() + "Seconds"] = seconds_2;
        }
      }
    }
    var dayWorkingTime = this.parent.dayWorkingTime;
    var length = dayWorkingTime.length;
    var seconds = 0;
    for (var count = 0; count < length; count++) {
      var currentRange = dayWorkingTime[count];
      seconds = seconds + this.getWorkingTime('', currentRange, startDate, totalSeconds, count, nonWorkingHours, workingTimeRanges, nonWorkingTimeRanges);
    }
    if (this.parent.defaultEndTime / 3600 !== 24) {
      nonWorkingTimeRanges.push({
        from: this.parent.defaultEndTime,
        to: 86400,
        isWorking: false,
        interval: 86400 - this.parent.defaultEndTime
      });
    }
    totalSeconds = seconds;
    return totalSeconds;
  };
  /**
   *
   * @param {string} value .
   * @param {boolean} isFromDialog .
   * @returns {object} .
   * @private
   */
  // eslint-disable-next-line
  DateProcessor.prototype.getDurationValue = function (value, isFromDialog) {
    var durationUnit = null;
    var duration = null;
    if (typeof value === 'string') {
      var values = value.match(/(\d*\.*\d+|.+$)/g);
      if (values && values.length <= 2) {
        duration = parseFloat(values[0].toString().trim());
        var unit = values[1] ? values[1].toString().trim().toLowerCase() : null;
        if (getValue('minute', this.parent.durationUnitEditText).indexOf(unit) !== -1) {
          durationUnit = 'minute';
        } else if (getValue('hour', this.parent.durationUnitEditText).indexOf(unit) !== -1) {
          durationUnit = 'hour';
        } else if (getValue('day', this.parent.durationUnitEditText).indexOf(unit) !== -1) {
          durationUnit = 'day';
        }
      }
    } else {
      duration = value;
      durationUnit = null;
    }
    var output = {
      duration: duration,
      durationUnit: durationUnit
    };
    return output;
  };
  /**
   *
   * @param {Date} date .
   * @returns {Date} .
   */
  DateProcessor.prototype.getNextWorkingDay = function (date) {
    var dayIndex = date.getDay();
    if (this.parent.nonWorkingDayIndex.indexOf(dayIndex) !== -1) {
      date.setDate(date.getDate() + 1);
      date = this.getNextWorkingDay(date);
      return date;
    } else {
      return date;
    }
  };
  /**
   * get weekend days between two dates without including args dates
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @returns {number} .
   */
  DateProcessor.prototype.getWeekendCount = function (startDate, endDate) {
    var weekendCount = 0;
    var sDate = new Date(startDate.getTime());
    var eDate = new Date(endDate.getTime());
    sDate.setHours(0, 0, 0, 0);
    sDate.setDate(sDate.getDate() + 1);
    eDate.setHours(0, 0, 0, 0);
    while (sDate.getTime() < eDate.getTime()) {
      if (this.parent.nonWorkingDayIndex.indexOf(sDate.getDay()) !== -1) {
        weekendCount += 1;
      }
      sDate.setDate(sDate.getDate() + 1);
    }
    return weekendCount;
  };
  /**
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {boolean} isCheckTimeZone .
   * @returns {number} .
   */
  DateProcessor.prototype.getNumberOfSeconds = function (startDate, endDate, isCheckTimeZone) {
    var sDate = new Date(startDate.getTime());
    var eDate = new Date(endDate.getTime());
    var timeDiff = 0;
    sDate.setDate(sDate.getDate() + 1);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    if (sDate.getTime() < eDate.getTime()) {
      timeDiff = this.getTimeDifference(sDate, eDate, isCheckTimeZone) / 1000;
    }
    if (timeDiff % 86400 !== 0) {
      timeDiff = timeDiff - timeDiff % 86400 + 86400;
    }
    return timeDiff;
  };
  /**
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @returns {number} .
   */
  DateProcessor.prototype.getHolidaysCount = function (startDate, endDate) {
    var holidaysCount = 0;
    var holidays = this.parent.totalHolidayDates;
    var sDate = new Date(startDate.getTime());
    var eDate = new Date(endDate.getTime());
    sDate.setDate(sDate.getDate() + 1);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    if (sDate.getTime() < eDate.getTime()) {
      for (var i = 0; i < holidays.length; i++) {
        var currentHoliday = this.getDateFromFormat(new Date(holidays[i]));
        if (sDate.getTime() <= currentHoliday.getTime() && eDate.getTime() > currentHoliday.getTime()) {
          if (!this.parent.includeWeekend && this.parent.nonWorkingDayIndex.indexOf(currentHoliday.getDay()) === -1 || this.parent.includeWeekend) {
            holidaysCount += 1;
          }
        }
      }
    }
    return holidaysCount;
  };
  /**
   * @returns {number[]} .
   * @private
   */
  DateProcessor.prototype.getHolidayDates = function () {
    var holidays = this.parent.holidays;
    var holidayDates = [];
    for (var i = 0; i < holidays.length; i++) {
      var from = this.getDateFromFormat(holidays[i].from);
      var to = this.getDateFromFormat(holidays[i].to);
      if (isNullOrUndefined(from) && isNullOrUndefined(to)) {
        continue;
      } else if (isNullOrUndefined(from) || isNullOrUndefined(to)) {
        var tempDate = from ? from : to;
        tempDate.setHours(0, 0, 0, 0);
        if (holidayDates.indexOf(tempDate.getTime()) === -1) {
          holidayDates.push(tempDate.getTime());
        }
      } else {
        while (from <= to) {
          from.setHours(0, 0, 0, 0);
          if (holidayDates.indexOf(from.getTime()) === -1) {
            holidayDates.push(from.getTime());
          }
          from.setDate(from.getDate() + 1);
        }
      }
    }
    return holidayDates;
  };
  /**
   * @param {Date} date .
   * @param {boolean} checkWeekEnd .
   * @returns {boolean} .
   * @private
   */
  /*Check given date is on holidays*/
  DateProcessor.prototype.isOnHolidayOrWeekEnd = function (date, checkWeekEnd) {
    checkWeekEnd = !isNullOrUndefined(checkWeekEnd) ? checkWeekEnd : this.parent.includeWeekend;
    if (!this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      checkWeekEnd = true;
    }
    if (!checkWeekEnd && this.parent.nonWorkingDayIndex.indexOf(date.getDay()) !== -1) {
      return true;
    }
    var holidays = this.parent.totalHolidayDates;
    for (var count = 0; count < holidays.length; count++) {
      var holidayFrom = this.getDateFromFormat(new Date(holidays[count]));
      var holidayTo = new Date(holidayFrom.getTime());
      holidayFrom.setHours(0, 0, 0, 0);
      holidayTo.setHours(23, 59, 59, 59);
      if (date.getTime() >= holidayFrom.getTime() && date.getTime() < holidayTo.getTime()) {
        return true;
      }
    }
    return false;
  };
  /**
   * To calculate non working times in given date
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {boolean} isAutoSchedule .
   * @returns {number} .
   */
  DateProcessor.prototype.getNonWorkingSecondsOnDate = function (startDate, endDate, isAutoSchedule) {
    var sHour = this.getSecondsInDecimal(startDate);
    var eHour = this.getSecondsInDecimal(endDate);
    var startRangeIndex = -1;
    var endRangeIndex = -1;
    var totNonWrkSecs = 0;
    var startOnHoliday = isAutoSchedule && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) ? this.isOnHolidayOrWeekEnd(startDate, null) : false;
    var endOnHoliday = isAutoSchedule && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) ? this.isOnHolidayOrWeekEnd(endDate, null) : false;
    var startnonWorkingTimeRange;
    var endnonWorkingTimeRange;
    if (this.parent.weekWorkingTime.length > 0) {
      startnonWorkingTimeRange = this.parent['getNonWorkingRange'](startDate);
      for (var i = 0; i < startnonWorkingTimeRange.length; i++) {
        var val = startnonWorkingTimeRange[i];
        if (sHour >= val.from && sHour <= val.to) {
          startRangeIndex = i;
        }
      }
      endnonWorkingTimeRange = this.parent['getNonWorkingRange'](endDate);
      for (var i = 0; i < endnonWorkingTimeRange.length; i++) {
        var val = endnonWorkingTimeRange[i];
        if (eHour >= val.from && eHour <= val.to) {
          endRangeIndex = i;
        }
      }
    } else {
      startnonWorkingTimeRange = this.parent.nonWorkingTimeRanges;
      endnonWorkingTimeRange = this.parent.nonWorkingTimeRanges;
      for (var i = 0; i < startnonWorkingTimeRange.length; i++) {
        var val = startnonWorkingTimeRange[i];
        if (sHour >= val.from && sHour <= val.to) {
          startRangeIndex = i;
        }
        if (eHour >= val.from && eHour <= val.to) {
          endRangeIndex = i;
        }
      }
    }
    if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
      if (!startOnHoliday) {
        for (var i = startRangeIndex; i < startnonWorkingTimeRange.length; i++) {
          if (!isNullOrUndefined(startnonWorkingTimeRange[i]) && !startnonWorkingTimeRange[i].isWorking) {
            if (i === startRangeIndex) {
              totNonWrkSecs += startnonWorkingTimeRange[i].to - sHour;
            } else {
              totNonWrkSecs += startnonWorkingTimeRange[i].interval;
            }
          }
        }
      } else {
        totNonWrkSecs += 86400 - sHour;
      }
      if (!endOnHoliday) {
        for (var i = 0; i <= endRangeIndex; i++) {
          if (!endnonWorkingTimeRange[i].isWorking) {
            if (i === endRangeIndex) {
              totNonWrkSecs += eHour - endnonWorkingTimeRange[i].from;
            } else {
              totNonWrkSecs += endnonWorkingTimeRange[i].interval;
            }
          }
        }
      } else {
        totNonWrkSecs += eHour;
      }
    } else {
      if (startRangeIndex !== endRangeIndex) {
        if (!endOnHoliday) {
          for (var i = startRangeIndex; i <= endRangeIndex; i++) {
            if (!isNullOrUndefined(startnonWorkingTimeRange[i]) && !startnonWorkingTimeRange[i].isWorking) {
              if (i === startRangeIndex) {
                totNonWrkSecs += startnonWorkingTimeRange[i].to - sHour;
              } else if (i === endRangeIndex) {
                totNonWrkSecs += eHour - startnonWorkingTimeRange[i].from;
              } else {
                totNonWrkSecs += startnonWorkingTimeRange[i].interval;
              }
            }
          }
        } else {
          totNonWrkSecs += eHour - sHour;
        }
      } else {
        if (!endOnHoliday) {
          var range = startnonWorkingTimeRange[startRangeIndex];
          if (!range.isWorking) {
            totNonWrkSecs = eHour - sHour;
          }
        } else {
          totNonWrkSecs += eHour - sHour;
        }
      }
    }
    return totNonWrkSecs;
  };
  /**
   *
   * @param {Date} date .
   * @returns {Date} .
   */
  DateProcessor.prototype.getPreviousWorkingDay = function (date) {
    var dayIndex = date.getDay();
    var previousIndex = dayIndex === 0 ? 6 : dayIndex - 1;
    var dayEndTime = this.parent['getCurrentDayEndTime'](date);
    if (this.parent.nonWorkingDayIndex.indexOf(dayIndex) !== -1 || this.parent.nonWorkingDayIndex.indexOf(previousIndex) !== -1 && dayEndTime === 86400 && this.getSecondsInDecimal(date) === 0) {
      date.setDate(date.getDate() - 1);
      if (this.parent.nonWorkingDayIndex.indexOf(date.getDay()) !== -1) {
        date = this.getPreviousWorkingDay(date);
      }
      return date;
    } else {
      return date;
    }
  };
  /**
   * To get non-working day indexes.
   *
   * @returns {void} .
   * @private
   */
  DateProcessor.prototype.getNonWorkingDayIndex = function () {
    var weekDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var weekDayLength = weekDay.length;
    if (this.parent.workWeek.length === 0) {
      this.parent.workWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }
    var workWeek = this.parent.workWeek.slice();
    var length = workWeek.length;
    for (var i = 0; i < length; i++) {
      workWeek[i] = workWeek[i].toLowerCase();
    }
    this.parent.nonWorkingDayIndex = [];
    for (var i = 0; i < weekDayLength; i++) {
      if (workWeek.indexOf(weekDay[i]) === -1) {
        this.parent.nonWorkingDayIndex.push(i);
      }
    }
  };
  /**
   *
   * @param {number} seconds .
   * @param {Date} date .
   * @returns {void} .
   * @private
   */
  DateProcessor.prototype.setTime = function (seconds, date) {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    var hour = seconds / 3600;
    hour = parseInt(hour, 10);
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    var min = (seconds - hour * 3600) / 60;
    min = parseInt(min, 10);
    var sec = seconds - hour * 3600 - min * 60;
    date.setHours(hour, min, sec);
  };
  /**
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {boolean} isCheckTimeZone .
   * @returns {number} .
   */
  DateProcessor.prototype.getTimeDifference = function (startDate, endDate, isCheckTimeZone) {
    var sDate = new Date(startDate.getTime());
    var eDate = new Date(endDate.getTime());
    if (isCheckTimeZone) {
      this.updateDateWithTimeZone(sDate, eDate);
    }
    return eDate.getTime() - sDate.getTime();
  };
  /**
   * @param {Date} sDate .
   * @param {Date} eDate .
   * @returns {void} .
   */
  DateProcessor.prototype.updateDateWithTimeZone = function (sDate, eDate) {
    var sTZ = sDate.getTimezoneOffset();
    var eTZ = eDate.getTimezoneOffset();
    var uTZ;
    var uDate;
    if (sTZ !== eTZ) {
      var standardTZ = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset();
      if (standardTZ !== sTZ) {
        uDate = sDate;
        uTZ = sTZ;
      } else if (standardTZ !== eTZ) {
        uDate = eDate;
        uTZ = eTZ;
      }
      if (standardTZ < 0) {
        var tzDiff = standardTZ - uTZ;
        uDate.setTime(uDate.getTime() + tzDiff * 60 * 1000);
      } else if (standardTZ >= 0) {
        var tzDiff = uTZ - standardTZ;
        uDate.setTime(uDate.getTime() - tzDiff * 60 * 1000);
      }
    }
  };
  /**
   *
   * @param {Date} date .
   * @returns {number} .
   */
  DateProcessor.prototype.getSecondsInDecimal = function (date) {
    return date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds() + date.getMilliseconds() / 1000;
  };
  /**
   * @param {Date} date .
   * @param {number} localOffset .
   * @param {string} timezone .
   * @returns {number} .
   * @private
   */
  DateProcessor.prototype.offset = function (date, timezone) {
    var localOffset = date.getTimezoneOffset();
    try {
      var convertedDate = new Date(date.toLocaleString('en-US', {
        timeZone: timezone
      }));
      if (!isNaN(convertedDate.getTime())) {
        return (date.getTime() - convertedDate.getTime()) / 60000 + localOffset;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };
  DateProcessor.prototype.remove = function (date, timezone) {
    if (!isNullOrUndefined(date)) {
      date = this.reverse(date, timezone, date.getTimezoneOffset());
    }
    return date;
  };
  DateProcessor.prototype.reverse = function (date, fromOffset, toOffset) {
    if (typeof fromOffset === 'string') {
      fromOffset = this.offset(date, fromOffset);
    }
    if (typeof toOffset === 'string') {
      toOffset = this.offset(date, toOffset);
    }
    var fromLocalOffset = date.getTimezoneOffset();
    date = new Date(date.getTime() + (fromOffset - toOffset) * 60000);
    var toLocalOffset = date.getTimezoneOffset();
    return new Date(date.getTime() + (toLocalOffset - fromLocalOffset) * 60000);
  };
  /**
   * @param {Date} date .
   * @param {string} timezone .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.convert = function (date, timezone) {
    var fromOffset = date.getTimezoneOffset();
    var toOffset = this.offset(date, timezone);
    if (typeof fromOffset === 'string') {
      fromOffset = this.offset(date, fromOffset);
    }
    if (typeof toOffset === 'string') {
      toOffset = this.offset(date, toOffset);
    }
    var fromLocalOffset = date.getTimezoneOffset();
    date = new Date(date.getTime() + (fromOffset - toOffset) * 60000);
    var toLocalOffset = date.getTimezoneOffset();
    return new Date(date.getTime() + (toLocalOffset - fromLocalOffset) * 60000);
  };
  /**
   * @param {string | Date} date .
   * @param {boolean} toConvert .
   * @returns {Date} .
   * @private
   */
  DateProcessor.prototype.getDateFromFormat = function (date, toConvert) {
    var updatedDate;
    if (isNullOrUndefined(date)) {
      return null;
    } else if (date instanceof Date) {
      updatedDate = new Date(date.getTime());
    } else {
      var dateObject = this.parent.globalize.parseDate(date, {
        format: this.parent.getDateFormat(),
        type: 'dateTime'
      });
      updatedDate = isNullOrUndefined(dateObject) && !isNaN(new Date(date).getTime()) ? new Date(date) : dateObject;
    }
    if (!isNullOrUndefined(this.parent.timezone) && toConvert) {
      var convertedDate = this.convert(updatedDate, this.parent.timezone);
      return convertedDate;
    } else {
      return updatedDate;
    }
  };
  /**
   * @param {Date} date1 .
   * @param {Date} date2 .
   * @returns {number} .
   * @private
   */
  DateProcessor.prototype.compareDates = function (date1, date2) {
    if (!isNullOrUndefined(date1) && !isNullOrUndefined(date2)) {
      return date1.getTime() > date2.getTime() ? 1 : date1.getTime() < date2.getTime() ? -1 : 0;
    } else if (!isNullOrUndefined(date1) && isNullOrUndefined(date2)) {
      return 1;
    } else if (isNullOrUndefined(date1) && !isNullOrUndefined(date2)) {
      return -1;
    } else {
      return null;
    }
  };
  /**
   *
   * @param {number} duration .
   * @param {string} durationUnit .
   * @returns {string} .
   * @private
   */
  DateProcessor.prototype.getDurationString = function (duration, durationUnit) {
    var value = '';
    if (!isNullOrUndefined(duration)) {
      value += parseFloat(duration.toFixed(2)) + ' ';
      if (!isNullOrUndefined(durationUnit)) {
        var plural = duration !== 1;
        if (durationUnit === 'day') {
          value += plural ? this.parent.localeObj.getConstant('days') : this.parent.localeObj.getConstant('day');
        } else if (durationUnit === 'hour') {
          value += plural ? this.parent.localeObj.getConstant('hours') : this.parent.localeObj.getConstant('hour');
        } else if (durationUnit === 'minute') {
          value += plural ? this.parent.localeObj.getConstant('minutes') : this.parent.localeObj.getConstant('minute');
        }
      }
    }
    return value;
  };
  /**
   * Method to get work with value and unit.
   *
   * @param {number} work .
   * @param {string} workUnit .
   * @returns {string} .
   * @private
   */
  DateProcessor.prototype.getWorkString = function (work, workUnit) {
    var value = '';
    if (!isNullOrUndefined(work)) {
      value += parseFloat(work).toFixed(2) + ' ';
      if (!isNullOrUndefined(workUnit)) {
        var plural = work !== 1;
        if (workUnit === 'day') {
          value += plural ? this.parent.localeObj.getConstant('days') : this.parent.localeObj.getConstant('day');
        } else if (workUnit === 'hour') {
          value += plural ? this.parent.localeObj.getConstant('hours') : this.parent.localeObj.getConstant('hour');
        } else if (workUnit === 'minute') {
          value += plural ? this.parent.localeObj.getConstant('minutes') : this.parent.localeObj.getConstant('minute');
        }
      }
    }
    return value;
  };
  /**
   *
   * @param {object} editArgs .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  DateProcessor.prototype.calculateProjectDatesForValidatedTasks = function (editArgs) {
    var _this = this;
    var projectStartDate = typeof this.parent.projectStartDate === 'string' ? new Date(this.parent.projectStartDate) : this.parent.projectStartDate;
    var projectEndDate = typeof this.parent.projectEndDate === 'string' ? new Date(this.parent.projectEndDate) : this.parent.projectEndDate;
    var minStartDate = null;
    var maxEndDate = null;
    var flatData = getValue('dataOperation.dataArray', this.parent);
    if ((!projectStartDate || !projectEndDate) && flatData && flatData.length === 0) {
      minStartDate = this.getDateFromFormat(new Date());
      maxEndDate = this.getDateFromFormat(new Date(minStartDate.getTime()));
    } else if (flatData.length > 0) {
      var sortedStartDate = flatData.slice().sort(function (a, b) {
        return new Date(a[_this.parent.taskFields.startDate]).getTime() - new Date(b[_this.parent.taskFields.startDate]).getTime();
      });
      var sortedEndDate = flatData.slice().sort(function (a, b) {
        return new Date(b[_this.parent.taskFields.endDate]).getTime() - new Date(a[_this.parent.taskFields.endDate]).getTime();
      });
      minStartDate = sortedStartDate[0][this.parent.taskFields.startDate];
      maxEndDate = sortedEndDate[sortedEndDate.length - 1][this.parent.taskFields.endDate];
    }
    this.parent.cloneProjectStartDate = projectStartDate ? new Date(projectStartDate.getTime()) : typeof minStartDate === 'string' ? new Date(minStartDate) : minStartDate;
    this.parent.cloneProjectEndDate = projectEndDate ? new Date(projectEndDate.getTime()) : typeof maxEndDate === 'string' ? new Date(maxEndDate) : maxEndDate;
  };
  /**
   *
   * @param {object} editArgs .
   * @returns {void} .
   * @private
   */
  DateProcessor.prototype.calculateProjectDates = function (editArgs) {
    var _this = this;
    if (this.parent.isLoad && this.parent.enablePersistence && this.parent.cloneProjectStartDate && this.parent.cloneProjectEndDate) {
      this.parent.cloneProjectStartDate = this.getDateFromFormat(this.parent.cloneProjectStartDate);
      this.parent.cloneProjectEndDate = this.getDateFromFormat(this.parent.cloneProjectEndDate);
      return;
    }
    var sDate = typeof this.parent.projectStartDate === 'string' ? new Date(this.parent.projectStartDate) : this.parent.projectStartDate;
    var eDate = typeof this.parent.projectEndDate === 'string' ? new Date(this.parent.projectEndDate) : this.parent.projectEndDate;
    var projectStartDate = this.parent.timelineModule.isZooming && this.parent.cloneProjectStartDate ? this.getDateFromFormat(this.parent.cloneProjectStartDate) : this.getDateFromFormat(sDate);
    var projectEndDate = this.parent.timelineModule.isZooming && this.parent.cloneProjectEndDate ? this.getDateFromFormat(this.parent.cloneProjectEndDate) : this.getDateFromFormat(eDate);
    var minStartDate = null;
    var maxEndDate = null;
    var flatData = this.parent.flatData;
    var currentViewData = this.parent.currentViewData;
    var taskRange = [];
    var addDateToList = function (date) {
      if (!isNullOrUndefined(date)) {
        taskRange.push(date);
      }
    };
    var sortDates = function (dates) {
      if (dates.length > 0) {
        dates.sort(function (a, b) {
          return a.getTime() - b.getTime();
        });
        minStartDate = new Date(dates[0].getTime());
        maxEndDate = dates.length > 1 ? new Date(dates[dates.length - 1].getTime()) : null;
      }
    };
    if ((!projectStartDate || !projectEndDate) && flatData.length > 0 || editArgs || this.parent.timelineModule.isZoomToFit) {
      var viewData = void 0;
      if (currentViewData.length > 0 && this.parent.timelineModule.isZoomToFit && this.parent.treeGrid.filterModule && this.parent.treeGrid.filterModule.filteredResult.length > 0) {
        viewData = currentViewData;
      } else {
        viewData = flatData;
      }
      viewData.forEach(function (data) {
        taskRange = [];
        var task = data.ganttProperties;
        var tempStartDate;
        var tempEndDate;
        if (isNullOrUndefined(task.startDate) && isNullOrUndefined(task.endDate)) {
          tempStartDate = null;
          tempEndDate = null;
        } else {
          tempStartDate = _this.getValidStartDate(task);
          tempEndDate = _this.getValidEndDate(task);
        }
        addDateToList(minStartDate);
        addDateToList(maxEndDate);
        addDateToList(tempStartDate);
        addDateToList(tempEndDate);
        if (_this.parent.renderBaseline && !_this.parent.timelineModule.isZoomToFit) {
          addDateToList(task.baselineStartDate);
          addDateToList(task.baselineEndDate);
        }
        if (task.indicators && task.indicators.length > 0 && !_this.parent.timelineModule.isZoomToFit) {
          task.indicators.forEach(function (item) {
            addDateToList(_this.getDateFromFormat(item.date));
          });
        }
        sortDates(taskRange);
      });
      taskRange = [];
      addDateToList(minStartDate);
      addDateToList(maxEndDate);
      //update schedule dates as per holiday and strip line collection
      if (this.parent.eventMarkers.length > 0 && !this.parent.timelineModule.isZoomToFit) {
        var eventMarkers = this.parent.eventMarkers;
        // eslint-disable-next-line
        eventMarkers.forEach(function (marker, index) {
          addDateToList(_this.getDateFromFormat(marker.day));
        });
      }
      if (this.parent.totalHolidayDates.length > 0 && !this.parent.timelineModule.isZoomToFit) {
        var holidays = this.parent.totalHolidayDates;
        // eslint-disable-next-line
        holidays.forEach(function (holiday, index) {
          addDateToList(new Date(holiday));
        });
      }
      sortDates(taskRange);
      if (!minStartDate || !maxEndDate) {
        if (!minStartDate) {
          minStartDate = isNullOrUndefined(minStartDate) ? this.getDateFromFormat(new Date()) : minStartDate;
          minStartDate.setHours(0, 0, 0, 0);
        } else {
          minStartDate = isNullOrUndefined(minStartDate) ? this.getDateFromFormat(new Date()) : minStartDate;
        }
        maxEndDate = this.getDateFromFormat(new Date(minStartDate.getTime()));
        maxEndDate.setDate(maxEndDate.getDate() + 20);
      }
    } else if ((!projectStartDate || !projectEndDate) && flatData.length === 0) {
      minStartDate = this.getDateFromFormat(new Date());
      maxEndDate = this.getDateFromFormat(new Date(minStartDate.getTime()));
    }
    if (!editArgs) {
      this.prevProjectStartDate = this.parent.cloneProjectStartDate;
      this.parent.cloneProjectStartDate = minStartDate ? !this.parent.timelineModule.isZoomToFit ? !isNullOrUndefined(projectStartDate) ? new Date(projectStartDate.getTime()) : minStartDate : minStartDate : new Date(projectStartDate.getTime());
      this.parent.cloneProjectEndDate = maxEndDate ? !this.parent.timelineModule.isZoomToFit ? !isNullOrUndefined(projectEndDate) ? new Date(projectEndDate.getTime()) : maxEndDate : maxEndDate : new Date(projectEndDate.getTime());
    } else {
      setValue('minStartDate', minStartDate, editArgs);
      setValue('maxEndDate', maxEndDate, editArgs);
    }
    this.parent['isProjectDateUpdated'] = true;
  };
  /**
   *
   * @param {ITaskSegment} segments .
   * @returns {number} .
   * @private
   */
  DateProcessor.prototype.splitTasksDuration = function (segments) {
    var duration = 0;
    var sDate = segments[0].startDate;
    var eDate = segments[segments.length - 1].endDate;
    if (this.parent.timelineModule.bottomTier === 'Hour') {
      duration += Math.ceil(this.getTimeDifference(sDate, eDate) / (1000 * 60 * 60));
    } else if (this.parent.timelineModule.bottomTier === 'Minutes') {
      duration += Math.ceil(this.getTimeDifference(sDate, eDate) / (1000 * 60));
    } else {
      duration += Math.ceil(this.getTimeDifference(sDate, eDate) / (1000 * 60 * 60 * 24));
    }
    return duration;
  };
  return DateProcessor;
}();
var __extends = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * To calculate and update task related values
 */
var TaskProcessor = /** @__PURE__ @class */function (_super) {
  __extends(TaskProcessor, _super);
  function TaskProcessor(parent) {
    var _this = _super.call(this, parent) || this;
    _this.customSegmentProperties = [];
    _this.recordIndex = 0;
    _this.taskIds = [];
    _this.hierarchyData = [];
    _this.addEventListener();
    return _this;
  }
  TaskProcessor.prototype.addEventListener = function () {
    this.parent.on('beforeDataManipulate', this.checkDataBinding.bind(this));
  };
  /**
   * @param {boolean} isChange .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.checkDataBinding = function (isChange) {
    var _this = this;
    if (isChange) {
      this.parent.flatData = [];
      this.parent.currentViewData = [];
      this.parent.updatedRecords = [];
      this.dataArray = [];
      this.taskIds = [];
      this.parent.ids = [];
      this.recordIndex = 0;
      this.hierarchyData = [];
      this.parent.predecessorsCollection = [];
      this.parent.treeGrid.parentData = [];
      this.parent.taskIds = [];
    }
    if (isNullOrUndefined(this.parent.dataSource)) {
      this.parent.dataSource = [];
      this.parent.processTimeline();
      this.parent.renderGantt(isChange);
    } else if (this.parent.dataSource instanceof DataManager) {
      this.initDataSource(isChange);
    } else {
      if (this.parent.dataSource instanceof Object && isCountRequired(this.parent)) {
        var ganttdata = getValue('result', this.parent.dataSource);
        this.dataArray = ganttdata;
      } else {
        this.dataArray = this.parent.dataSource;
      }
      this.processTimeline();
      this.cloneDataSource();
      this.parent.renderGantt(isChange);
      this.parent.flatData.map(function (data) {
        _this.parent.chartRowsModule.updateSegment(data.ganttProperties.segments, data.ganttProperties.taskId);
      });
    }
    this.parent.flatData.map(function (data) {
      _this.parent.chartRowsModule.updateSegment(data.ganttProperties.segments, data.ganttProperties.taskId);
    });
  };
  TaskProcessor.prototype.processTimeline = function () {
    this.parent.processTimeline();
    if (!this.parent.enableValidation) {
      this.parent.dataOperation.calculateProjectDatesForValidatedTasks();
      this.parent.timelineModule.validateTimelineProp();
    }
  };
  TaskProcessor.prototype.initDataSource = function (isChange) {
    var _this = this;
    var queryManager = this.parent.query instanceof Query ? this.parent.query : new Query();
    queryManager.requiresCount();
    var dataManager = this.parent.dataSource;
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      this.processTimeline();
      this.parent.renderGantt(isChange);
    } else {
      dataManager.executeQuery(queryManager).then(function (e) {
        _this.dataArray = e.result;
        _this.processTimeline();
        if (!_this.parent.loadChildOnDemand || _this.parent.loadChildOnDemand && !_this.parent.taskFields.hasChildMapping) {
          _this.cloneDataSource();
        }
        _this.parent.renderGantt(isChange);
      }).catch(function (e) {
        // Trigger action failure event
        _this.parent.processTimeline();
        _this.parent.renderGantt(isChange);
        _this.parent.trigger('actionFailure', {
          error: e
        });
      });
    }
  };
  TaskProcessor.prototype.constructDataSource = function (dataSource) {
    var mappingData = new DataManager(dataSource).executeLocal(new Query().group(this.parent.taskFields.parentID));
    var rootData = [];
    var index;
    for (var i = 0; i < mappingData.length; i++) {
      var groupData = mappingData[i];
      if (!isNullOrUndefined(groupData.key)) {
        index = this.taskIds.indexOf(groupData.key.toString());
        if (index > -1) {
          if (!isNullOrUndefined(groupData.key)) {
            dataSource[index][this.parent.taskFields.child] = groupData.items;
            continue;
          }
        }
      }
      if (index !== -1) {
        rootData.push.apply(rootData, groupData.items); // eslint-disable-line
      }
    }
    this.hierarchyData = this.dataReorder(dataSource, rootData);
  };
  TaskProcessor.prototype.cloneDataSource = function () {
    var taskIdMapping = this.parent.taskFields.id;
    var parentIdMapping = this.parent.taskFields.parentID;
    var hierarchicalData = [];
    if (!isNullOrUndefined(taskIdMapping) && !isNullOrUndefined(parentIdMapping)) {
      var data = [];
      for (var i = 0; i < this.dataArray.length; i++) {
        var tempData = this.dataArray[i];
        if (tempData['parentItem']) {
          delete tempData['parentItem'];
        }
        data.push(extend({}, {}, tempData, true));
        if (!isNullOrUndefined(tempData[taskIdMapping])) {
          this.taskIds.push(tempData[taskIdMapping].toString());
        }
      }
      if (!this.parent.taskFields.child) {
        this.parent.setProperties({
          taskFields: {
            child: 'Children'
          }
        }, true);
      }
      this.constructDataSource(data);
      if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
        hierarchicalData = this.dataArray;
      } else {
        hierarchicalData = this.hierarchyData;
      }
    } else {
      hierarchicalData = this.dataArray;
    }
    if (this.parent.taskFields.segmentId) {
      this.segmentCollection = new DataManager(this.parent.segmentData).executeLocal(new Query().group(this.parent.taskFields.segmentId));
      if (!this.parent.taskFields.segments) {
        this.parent.taskFields.segments = 'Segments';
      }
    }
    if (this.parent.viewType !== 'ProjectView') {
      var resources = extend([], [], this.parent.resources, true);
      var unassignedTasks = [];
      this.constructResourceViewDataSource(resources, hierarchicalData, unassignedTasks);
      if (unassignedTasks.length > 0) {
        var record = {};
        var resourceName = this.parent.resourceFields.name || 'resourceName';
        record[this.parent.resourceFields.id] = 0;
        record[resourceName] = this.parent.localeObj.getConstant('unassignedTask');
        record[this.parent.taskFields.child] = unassignedTasks;
        resources.push(record);
      }
      hierarchicalData = resources;
    }
    this.prepareDataSource(hierarchicalData);
  };
  /**
   * @param {object[]} resources .
   * @param {object[]} data .
   * @param {object[]} unassignedTasks .
   * @returns {void} .
   *
   */
  TaskProcessor.prototype.constructResourceViewDataSource = function (resources, data, unassignedTasks) {
    var _loop_1 = function (i) {
      var tempData = data[i];
      var child = this_1.parent.taskFields.child !== null ? this_1.parent.taskFields.child : this_1.parent.taskFields.child = 'Children';
      var resourceData = tempData && tempData[this_1.parent.taskFields.resourceInfo];
      var resourceIdMapping = this_1.parent.resourceFields.id;
      if ((!tempData[child] || tempData[child].length === 0) && resourceData && resourceData.length) {
        if (typeof resourceData === 'string') {
          this_1.isResourceString = true;
          var id = resourceData;
          for (var j = 0; j < resources.length; j++) {
            if (resources[j][this_1.parent.resourceFields.name].toString() === id.toString()) {
              if (resources[j][child]) {
                resources[j][child].push(tempData);
              } else {
                resources[j][child] = [tempData];
              }
              break;
            }
          }
        } else {
          resourceData.forEach(function (resource) {
            var id = typeof resource === 'object' ? resource[resourceIdMapping] : resource;
            for (var j = 0; j < resources.length; j++) {
              if (resources[j][resourceIdMapping].toString() === id.toString()) {
                if (resources[j][child]) {
                  resources[j][child].push(tempData);
                } else {
                  resources[j][child] = [tempData];
                }
                break;
              }
            }
          });
        }
      } else if (!tempData[child] || tempData[child] && tempData[child].length === 0) {
        unassignedTasks.push(tempData);
      }
      if (tempData[this_1.parent.taskFields.child] && tempData[this_1.parent.taskFields.child].length) {
        this_1.constructResourceViewDataSource(resources, tempData[this_1.parent.taskFields.child], unassignedTasks);
      }
    };
    var this_1 = this;
    for (var i = 0; i < data.length; i++) {
      _loop_1(i);
    }
  };
  /**
   * Function to manipulate data-source
   *
   * @param {object[]} data .
   * @returns {void} .
   * @hidden
   */
  TaskProcessor.prototype.prepareDataSource = function (data) {
    this.prepareRecordCollection(data, 0);
    this.parent.initialLoadData = extend({}, {}, this.parent.flatData, true);
    if (!this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      this.parent.dataMap = this.parent.flatData.reduce(function (map, val) {
        map.set(val.uniqueID, val);
        return map;
      }, new Map());
    }
    // Method to maintain the shared task uniqueIds
    if (this.parent.viewType === 'ResourceView') {
      this.calculateSharedTaskUniqueIds();
    }
    if (this.parent.taskFields.dependency && this.parent.predecessorModule) {
      this.parent.predecessorModule.ensurePredecessorCollection();
    }
  };
  TaskProcessor.prototype.calculateSharedTaskUniqueIds = function () {
    var _loop_2 = function (i) {
      var value = [];
      value[0] = this_2.parent.getTaskIds()[i][0];
      value[1] = this_2.parent.getTaskIds()[i].slice(1);
      if (value[0] !== 'R') {
        var sharedRecords_1 = [];
        var ids_1 = [];
        this_2.parent.flatData.filter(function (data) {
          if (data.ganttProperties.taskId.toString() === value[1] && data.level !== 0) {
            ids_1.push(data.ganttProperties.rowUniqueID);
            sharedRecords_1.push(data);
          }
        });
        for (var j = 0; j < sharedRecords_1.length; j++) {
          sharedRecords_1[j].ganttProperties.sharedTaskUniqueIds = ids_1;
        }
      }
    };
    var this_2 = this;
    for (var i = 0; i < this.parent.getTaskIds().length; i++) {
      _loop_2(i);
    }
  };
  TaskProcessor.prototype.prepareRecordCollection = function (data, level, parentItem) {
    var _this = this;
    var length = data.length;
    var _loop_3 = function (i) {
      var tempData = data[i];
      if (!isNullOrUndefined(this_3.parent.taskFields.segmentId)) {
        var segmentData = this_3.segmentCollection.filter(function (x) {
          return x.key === tempData[_this.parent.taskFields.id];
        });
        if (segmentData.length > 0) {
          tempData[this_3.parent.taskFields.segments] = segmentData[0].items;
        }
      }
      if (this_3.parent.taskFields.hasChildMapping && tempData['parentUniqueID']) {
        parentItem = this_3.parent.getTaskByUniqueID(tempData['parentUniqueID']);
      }
      var ganttData = this_3.createRecord(tempData, level, parentItem, true);
      if (!this_3.parent.enableValidation || !this_3.parent.autoCalculateDateScheduling || this_3.parent.isLoad && this_3.parent.treeGrid.loadChildOnDemand && this_3.parent.taskFields.hasChildMapping) {
        this_3.updateTaskLeftWidth(ganttData);
      }
      ganttData.index = this_3.recordIndex++;
      this_3.parent.ids[ganttData.index] = ganttData.ganttProperties.rowUniqueID;
      this_3.parent.flatData.push(ganttData);
      this_3.parent.setTaskIds(ganttData);
      var childData = tempData[this_3.parent.taskFields.child] || tempData['taskData'] && tempData['taskData'][this_3.parent.taskFields.child];
      if (this_3.parent.viewType === 'ResourceView' && isNullOrUndefined(childData) && isNullOrUndefined(ganttData.parentItem) && ganttData.level === 0) {
        var ganttProp = ganttData.ganttProperties;
        var parentData = ganttData;
        this_3.parent.setRecordValue(ganttProp.isAutoSchedule ? 'startDate' : 'autoStartDate', null, parentData.ganttProperties, true);
        this_3.parent.setRecordValue(ganttProp.isAutoSchedule ? 'endDate' : 'autoEndDate', null, parentData.ganttProperties, true);
        var parentProgress = 0;
        var parentProp = parentData.ganttProperties;
        this_3.parent.setRecordValue('isMilestone', false, parentProp, true);
        if (parentProp.isAutoSchedule) {
          this_3.calculateDuration(parentData);
        }
        this_3.updateWorkWithDuration(parentData);
        var parentWork = parentProp.work;
        this_3.parent.setRecordValue('work', parentWork, parentProp, true);
        this_3.parent.setRecordValue('taskType', 'FixedDuration', parentProp, true);
        if (!isNullOrUndefined(this_3.parent.taskFields.type)) {
          this_3.updateMappingData(parentData, 'type');
        }
        this_3.parent.setRecordValue('progress', Math.floor(parentProgress), parentProp, true);
        this_3.parent.setRecordValue('totalProgress', 0, parentProp, true);
        this_3.parent.setRecordValue('totalDuration', 0, parentProp, true);
        if (!parentProp.isAutoSchedule) {
          this_3.parent.setRecordValue('autoDuration', this_3.calculateAutoDuration(parentProp), parentProp, true);
          this_3.updateAutoWidthLeft(parentData);
        }
        if (!this_3.parent.allowParentDependency) {
          this_3.resetDependency(parentData);
        }
        this_3.updateWidthLeft(parentData);
        this_3.updateTaskData(parentData);
      }
      if (this_3.parent.taskFields.hasChildMapping) {
        parentItem = null;
      }
      if (!isNullOrUndefined(childData) && childData.length > 0) {
        this_3.prepareRecordCollection(childData, ganttData.level + 1, ganttData);
      }
    };
    var this_3 = this;
    for (var i = 0; i < length; i++) {
      _loop_3(i);
    }
  };
  /**
   * Method to update custom field values in gantt record
   *
   * @param {object} data .
   * @param {IGanttData} ganttRecord .
   * @returns {void} .
   */
  TaskProcessor.prototype.addCustomFieldValue = function (data, ganttRecord) {
    var columns = this.parent.ganttColumns;
    var length = columns.length;
    if (length) {
      for (var i = 0; i < length; i++) {
        var fieldName = ganttRecord[columns[i].field];
        if (fieldName === undefined) {
          this.parent.setRecordValue(columns[i].field, data[columns[i].field], ganttRecord);
        }
      }
    }
  };
  /**
   * To populate Gantt record
   *
   * @param {object} data .
   * @param {number} level .
   * @param {IGanttData} parentItem .
   * @param {boolean} isLoad .
   * @returns {IGanttData} .
   * @private
   */
  TaskProcessor.prototype.createRecord = function (data, level, parentItem, isLoad) {
    var taskSettings = this.parent.taskFields;
    var resourceFields = this.parent.resourceFields;
    var progress = data[taskSettings.progress];
    var id = null;
    var name = null;
    var notes = data[taskSettings.notes];
    progress = progress ? parseFloat(progress.toString()) ? parseFloat(progress.toString()) : 0 : 0;
    progress = 100 < progress ? 100 : progress;
    var predecessors = data[taskSettings.dependency];
    var baselineStartDate = this.getDateFromFormat(data[taskSettings.baselineStartDate], true);
    var baselineEndDate = this.getDateFromFormat(data[taskSettings.baselineEndDate], true);
    var ganttData = {};
    var ganttProperties = {};
    var autoSchedule = this.parent.taskMode === 'Auto' ? true : this.parent.taskMode === 'Manual' ? false : data[taskSettings.manual] === true ? false : true;
    this.parent.setRecordValue('ganttProperties', ganttProperties, ganttData);
    if (data['ganttProperties'] && data['ganttProperties'].predecessor && data['ganttProperties'].predecessor.length > 0 && this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
      this.parent.setRecordValue('predecessor', data['ganttProperties'].predecessor, ganttProperties, true);
    }
    if (!isNullOrUndefined(data[taskSettings.id])) {
      id = data[taskSettings.id];
      name = data[taskSettings.name];
      var shouldLoadChild = this.parent.loadChildOnDemand && taskSettings.hasChildMapping && data['taskData'] ? true : false;
      if (shouldLoadChild) {
        ganttData['taskData'] = data['taskData'];
      } else {
        if (data['taskData'] && data['ganttProperties']) {
          this.addTaskData(ganttData, data['taskData'], isLoad);
        } else {
          this.addTaskData(ganttData, data, isLoad);
        }
      }
    } else if (!isNullOrUndefined(data[resourceFields.id])) {
      id = data[resourceFields.id];
      if (isNullOrUndefined(data[resourceFields.name]) && data['resourceName'] === 'Unassigned Task') {
        name = data['resourceName'];
      } else {
        name = data[resourceFields.name];
      }
      this.addTaskData(ganttData, data, false);
    }
    this.parent.setRecordValue('taskId', id, ganttProperties, true);
    this.parent.setRecordValue('taskName', name, ganttProperties, true);
    // eslint-disable-next-line
    this.parent && taskSettings.parentID && this.parent.setRecordValue('parentId', data[taskSettings.parentID], ganttProperties, true);
    this.addCustomFieldValue(data, ganttData);
    this.parent.setRecordValue('isAutoSchedule', autoSchedule, ganttProperties, true);
    if (!this.parent.undoRedoModule || this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
      this.parent.setRecordValue('resourceInfo', this.setResourceInfo(data), ganttProperties, true);
    } else if (data['ganttProperties']) {
      this.parent.setRecordValue('resourceInfo', data['ganttProperties'].resourceInfo, ganttProperties, true);
    }
    this.parent.setRecordValue('isMilestone', false, ganttProperties, true);
    this.parent.setRecordValue('indicators', data[taskSettings.indicators], ganttProperties, true);
    this.updateResourceName(ganttData);
    this.calculateScheduledValues(ganttData, data, isLoad);
    this.parent.setRecordValue('baselineStartDate', this.checkBaselineStartDate(baselineStartDate, ganttProperties), ganttProperties, true);
    // set default end time, if hour is 0
    var dayEndTime;
    if (this.parent.weekWorkingTime.length > 0 && baselineEndDate) {
      dayEndTime = this.parent['getEndTime'](baselineEndDate);
    } else {
      dayEndTime = this.parent.defaultEndTime;
    }
    if (baselineEndDate && baselineEndDate.getHours() === 0 && dayEndTime !== 86400) {
      this.setTime(dayEndTime, baselineEndDate);
    }
    if (ganttProperties.baselineStartDate && baselineEndDate && ganttProperties.baselineStartDate.getTime() > baselineEndDate.getTime() || !isNullOrUndefined(ganttProperties.baselineStartDate) && !isNullOrUndefined(ganttProperties.startDate) && ganttProperties.baselineStartDate.getTime() === ganttProperties.startDate.getTime() && !isNullOrUndefined(baselineEndDate) && !isNullOrUndefined(ganttProperties.endDate) && baselineEndDate.toLocaleDateString() === ganttProperties.endDate.toLocaleDateString() && ganttProperties.isMilestone) {
      baselineEndDate = ganttProperties.baselineStartDate;
    }
    this.parent.setRecordValue('baselineEndDate', this.checkBaselineEndDate(baselineEndDate, ganttProperties), ganttProperties, true);
    this.parent.setRecordValue('progress', progress, ganttProperties, true);
    this.parent.setRecordValue('totalProgress', progress, ganttProperties, true);
    if (data['ganttProperties'] && data['ganttProperties'].predecessorsName && this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
      this.parent.setRecordValue('predecessorsName', data['ganttProperties'].predecessorsName, ganttProperties, true);
    } else {
      this.parent.setRecordValue('predecessorsName', predecessors, ganttProperties, true);
    }
    this.parent.setRecordValue('notes', notes, ganttProperties, true);
    this.parent.setRecordValue('cssClass', data[taskSettings.cssClass], ganttProperties, true);
    this.parent.setRecordValue('parentItem', this.getCloneParent(parentItem), ganttData);
    if (this.parent.loadChildOnDemand && taskSettings.hasChildMapping && this.parent.currentViewData.length > 0) {
      this.parent.setRecordValue('parentItem', ganttData.parentItem, this.parent.currentViewData[this.taskIds.indexOf(data[taskSettings.id].toString())]);
    }
    var parentUniqId = ganttData.parentItem ? ganttData.parentItem.uniqueID : null;
    this.parent.setRecordValue('parentUniqueID', parentUniqId, ganttData);
    if (this.parent.viewType === 'ResourceView' && !isNullOrUndefined(taskSettings.parentID) && !isNullOrUndefined(ganttData.parentItem)) {
      this.parent.setRecordValue('parentId', ganttData.parentItem.taskId, ganttProperties, true);
    }
    this.parent.setRecordValue('level', data['level'] ? data['level'] : level, ganttData);
    if (data['ganttProperties'] && this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
      this.parent.setRecordValue('uniqueID', data['uniqueID'], ganttData);
    } else {
      if (this.parent.loadChildOnDemand && taskSettings.hasChildMapping && data['uniqueID']) {
        this.parent.setRecordValue('uniqueID', data['uniqueID'], ganttData);
      } else {
        this.parent.setRecordValue('uniqueID', getUid$1(this.parent.element.id + '_data_'), ganttData);
      }
    }
    this.parent.setRecordValue('uniqueID', ganttData.uniqueID, ganttProperties, true);
    this.parent.setRecordValue('childRecords', [], ganttData);
    if (this.parent.dataSource instanceof Object && isCountRequired(this.parent) && !isNullOrUndefined(taskSettings.child)) {
      this.parent.setRecordValue(taskSettings.child, [], ganttData);
    }
    if (!isNullOrUndefined(data[taskSettings.child]) && data[taskSettings.child].length > 0 || data['taskData'] && data['taskData'][taskSettings.child] && data['taskData'][taskSettings.child].length > 0) {
      this.parent.setRecordValue('hasChildRecords', true, ganttData);
      this.parent.setRecordValue('isMilestone', false, ganttProperties, true);
      if (!this.parent.allowParentDependency) {
        this.resetDependency(ganttData);
      }
    } else {
      if (this.parent.loadChildOnDemand && taskSettings.hasChildMapping && ganttData.taskData[taskSettings.hasChildMapping]) {
        this.parent.setRecordValue('hasChildRecords', true, ganttData);
      } else {
        this.parent.setRecordValue('hasChildRecords', false, ganttData);
      }
    }
    if (ganttData.hasChildRecords) {
      this.parent.setRecordValue('autoStartDate', ganttData.ganttProperties.startDate, ganttProperties);
      this.parent.setRecordValue('autoEndDate', ganttData.ganttProperties.endDate, ganttProperties);
      this.parent.setRecordValue('autoDuration', ganttData.ganttProperties.duration, ganttProperties);
    }
    this.parent.setRecordValue('expanded', ganttData.hasChildRecords && this.parent.collapseAllParentTasks ? false : true, ganttData);
    this.updateExpandStateMappingValue(ganttData, data);
    if (!isLoad) {
      this.parent.setRecordValue('width', this.calculateWidth(ganttData), ganttProperties, true);
      this.parent.setRecordValue('left', this.calculateLeft(ganttProperties), ganttProperties, true);
      this.parent.setRecordValue('progressWidth', this.getProgressWidth(ganttProperties.width, progress), ganttProperties, true);
      if (ganttProperties.baselineEndDate && ganttProperties.baselineStartDate) {
        this.parent.setRecordValue('baselineLeft', this.calculateBaselineLeft(ganttProperties), ganttProperties, true);
        this.parent.setRecordValue('baselineWidth', this.calculateBaselineWidth(ganttProperties), ganttProperties, true);
      }
    }
    if (isNullOrUndefined(taskSettings.work)) {
      this.updateWorkWithDuration(ganttData);
    }
    if (!isNullOrUndefined(taskSettings.manual)) {
      this.parent.dataOperation.updateMappingData(ganttData, 'manual');
    }
    this.updateTaskData(ganttData);
    if (predecessors) {
      this.parent.predecessorsCollection.push(ganttData);
    }
    if (!isNullOrUndefined(parentItem)) {
      parentItem.childRecords.push(ganttData);
      if (this.parent.dataSource instanceof Object && isCountRequired(this.parent) && !isNullOrUndefined(this.parent.taskFields.child)) {
        parentItem[this.parent.taskFields.child].push(ganttData.taskData);
      }
    }
    if (this.parent.viewType === 'ProjectView') {
      this.parent.setRecordValue('rowUniqueID', ganttProperties.taskId.toString(), ganttProperties, true);
    } else {
      var uniqueId = ganttData.uniqueID.replace(this.parent.element.id + '_data_', '');
      // if (this.parent.viewType === 'ResourceView' && typeof(ganttData.ganttProperties.taskId) === 'string') {
      //     uniqueId = ganttProperties.taskId.toString();
      // }
      this.parent.setRecordValue('rowUniqueID', uniqueId, ganttData);
      this.parent.setRecordValue('rowUniqueID', uniqueId, ganttProperties, true);
      this.parent.setRecordValue('sharedTaskUniqueIds', [], ganttProperties, true);
    }
    if (this.parent.allowUnscheduledTasks && ganttData.ganttProperties.startDate && (ganttData.ganttProperties.endDate || ganttData.ganttProperties.duration)) {
      this.parent.setRecordValue('segments', this.setSegmentsInfo(ganttData, true), ganttProperties, true);
      this.parent.dataOperation.updateMappingData(ganttData, 'segments');
      if (!isLoad) {
        this.updateWidthLeft(ganttData);
      }
    }
    this.parent.chartRowsModule.updateSegment(ganttData.ganttProperties.segments, ganttData.ganttProperties.taskId);
    return ganttData;
  };
  TaskProcessor.prototype.sortSegmentsData = function (segments, onLoad, ganttProp) {
    var _this = this;
    if (onLoad) {
      segments.sort(function (a, b) {
        var startDate = _this.parent.taskFields.startDate;
        return _this.getDateFromFormat(a[startDate]).getTime() - _this.getDateFromFormat(b[startDate]).getTime();
      });
    } else {
      segments.sort(function (a, b) {
        return a.startDate.getTime() - b.startDate.getTime();
      });
    }
    return segments;
  };
  TaskProcessor.prototype.setSegmentsInfo = function (data, onLoad) {
    var _this = this;
    var taskSettings = this.parent.taskFields;
    var ganttSegments = [];
    var segments;
    var sumOfDuration = 0;
    var remainingDuration = 0;
    var predefinedProperties = [this.parent.taskFields.duration, this.parent.taskFields.endDate, this.parent.taskFields.startDate, this.parent.taskFields.id];
    var taskData = [];
    if (!isNullOrUndefined(this.parent.taskFields.segments)) {
      segments = onLoad ? data.taskData[this.parent.taskFields.segments] : data.ganttProperties.segments;
      if (!onLoad) {
        if (data.taskData[this.parent.taskFields.segments] && data.taskData[this.parent.taskFields.segments].length > 0) {
          data.taskData[this.parent.taskFields.segments].forEach(function (segment) {
            var cleanedObject = {};
            var extraProperties = {};
            for (var key in segment) {
              if (predefinedProperties.indexOf(key) !== -1) {
                cleanedObject[key] = segment[key];
              } else {
                extraProperties[key] = segment[key];
              }
            }
            _this.customSegmentProperties.push(extraProperties);
          });
        }
      }
      if (!isNullOrUndefined(segments) && segments.length > 1) {
        this.sortSegmentsData(segments, onLoad, data.ganttProperties);
        for (var i = 0; i < segments.length; i++) {
          var segment = segments[i];
          var startDate = onLoad ? segment[taskSettings.startDate] : segment.startDate;
          var endDate = onLoad ? segment[taskSettings.endDate] : segment.endDate;
          var duration = onLoad ? segment[taskSettings.duration] : segment.duration;
          startDate = this.getDateFromFormat(startDate);
          startDate = this.checkStartDate(startDate, data.ganttProperties, false);
          if (!isNullOrUndefined(duration)) {
            endDate = this.getEndDate(startDate, duration, data.ganttProperties.durationUnit, data.ganttProperties, false);
            if (taskSettings.duration) {
              remainingDuration = data.ganttProperties.duration - sumOfDuration;
              if (remainingDuration <= 0) {
                continue;
              }
              duration = i === segments.length - 1 ? remainingDuration : remainingDuration > 0 && duration > remainingDuration ? remainingDuration : duration;
              endDate = this.getEndDate(startDate, duration, data.ganttProperties.durationUnit, data.ganttProperties, false);
            } else if (!taskSettings.duration && taskSettings.endDate && endDate) {
              endDate = !isNullOrUndefined(data.ganttProperties.endDate) && endDate.getTime() >= data.ganttProperties.endDate.getTime() && i === segments.length - 1 ? data.ganttProperties.endDate : endDate;
              duration = this.getDuration(startDate, endDate, data.ganttProperties.durationUnit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
              if (ganttSegments.length > 0 && endDate.getTime() < startDate.getTime() && endDate.getTime() <= data.ganttProperties.endDate.getTime()) {
                ganttSegments[i - 1].duration = this.getDuration(ganttSegments[i - 1].startDate, data.ganttProperties.endDate, data.ganttProperties.durationUnit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
                continue;
              }
            }
          } else {
            endDate = this.getDateFromFormat(endDate);
            if (endDate && (isNullOrUndefined(duration) || String(duration) === '')) {
              var dayEndTime = void 0;
              if (this.parent.weekWorkingTime.length > 0) {
                dayEndTime = this.parent['getEndTime'](endDate);
              } else {
                dayEndTime = this.parent.defaultEndTime;
              }
              if (endDate.getHours() === 0 && dayEndTime !== 86400) {
                this.setTime(dayEndTime, endDate);
              }
            }
            endDate = this.checkEndDate(endDate, data.ganttProperties, false);
            duration = this.getDuration(startDate, endDate, data.ganttProperties.durationUnit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
            if (taskSettings.duration) {
              remainingDuration = data.ganttProperties.duration - sumOfDuration - 1;
              if (remainingDuration <= 0) {
                continue;
              }
              duration = i === segments.length - 1 ? remainingDuration : remainingDuration > 0 && duration > remainingDuration ? remainingDuration : duration;
              endDate = this.getEndDate(startDate, duration, data.ganttProperties.durationUnit, data.ganttProperties, false);
            } else if (!taskSettings.duration && taskSettings.endDate && endDate) {
              endDate = !isNullOrUndefined(data.ganttProperties.endDate) && endDate.getTime() >= data.ganttProperties.endDate.getTime() && i === segments.length - 1 ? data.ganttProperties.endDate : endDate;
              duration = this.getDuration(startDate, endDate, data.ganttProperties.durationUnit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
              if (ganttSegments.length > 0 && endDate.getTime() < startDate.getTime() && endDate.getTime() <= data.ganttProperties.endDate.getTime()) {
                ganttSegments[i - 1].duration = this.getDuration(ganttSegments[i - 1].startDate, data.ganttProperties.endDate, data.ganttProperties.durationUnit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
                continue;
              }
            }
          }
          segment = {};
          if (!(startDate && endDate) || !(startDate && duration)) {
            break;
          }
          sumOfDuration += Number(duration);
          segment.startDate = startDate;
          segment.endDate = endDate;
          segment.duration = Number(duration);
          segment.width = 0;
          segment.left = 0;
          segment.segmentIndex = i;
          ganttSegments.push(segment);
          if (!isNullOrUndefined(ganttSegments[i - 1])) {
            var unit = void 0;
            if (!isNullOrUndefined(this.parent.timelineSettings.bottomTier)) {
              if (this.parent.timelineSettings.bottomTier.unit === 'Minutes') {
                unit = 'minute';
              } else if (this.parent.timelineSettings.bottomTier.unit === 'Hour') {
                unit = 'hour';
              } else {
                unit = data.ganttProperties.durationUnit;
              }
            } else {
              unit = data.ganttProperties.durationUnit;
            }
            var offsetDuration = this.getDuration(ganttSegments[i - 1].endDate, ganttSegments[i].startDate, unit, data.ganttProperties.isAutoSchedule, data.ganttProperties.isMilestone);
            segment.offsetDuration = offsetDuration;
            if (offsetDuration < 1) {
              if (this.parent.weekWorkingTime.length === 0) {
                segment.startDate = this.getEndDate(ganttSegments[i - 1].endDate, 1, data.ganttProperties.durationUnit, data.ganttProperties, false);
              } else {
                var prevSegmentDate = new Date(ganttSegments[i - 1].endDate.getTime());
                segment.startDate = new Date(prevSegmentDate.setHours(prevSegmentDate.getHours() + 24));
                if (this.isOnHolidayOrWeekEnd(segment.startDate, true)) {
                  do {
                    segment.startDate.setDate(segment.startDate.getDate() + 1);
                  } while (this.isOnHolidayOrWeekEnd(segment.startDate, true));
                }
                if (!this.parent.includeWeekend) {
                  segment.startDate = this.getNextWorkingDay(segment.startDate);
                }
                var dayEndTime = this.parent['getEndTime'](ganttSegments[i - 1].endDate);
                if (this.getSecondsInDecimal(ganttSegments[i - 1].endDate) === dayEndTime) {
                  this.setTime(this.parent['getEndTime'](segment.startDate), segment.startDate);
                }
              }
              segment.startDate = this.checkStartDate(segment.startDate, data.ganttProperties, false);
              segment.endDate = this.getEndDate(segment.startDate, segment.duration, data.ganttProperties.durationUnit, data.ganttProperties, false);
              segment.endDate = !taskSettings.duration && taskSettings.endDate && segment.endDate > data.ganttProperties.endDate ? data.ganttProperties.endDate : segment.endDate;
              segment.offsetDuration = 1;
            }
          } else {
            segment.offsetDuration = 0;
          }
          taskData.push(this.setSegmentTaskData(segment, segments[i]));
        }
        this.parent.setRecordValue('duration', sumOfDuration, data.ganttProperties, true);
        if (!isNullOrUndefined(ganttSegments[ganttSegments.length - 1])) {
          this.parent.setRecordValue('endDate', ganttSegments[ganttSegments.length - 1].endDate, data.ganttProperties, true);
        }
        if (!isNullOrUndefined(taskSettings.endDate) && !isNullOrUndefined(ganttSegments[ganttSegments.length - 1])) {
          this.parent.setRecordValue(this.parent.taskFields.endDate, ganttSegments[ganttSegments.length - 1].endDate, data, true);
        }
        if (!onLoad && taskData && taskData.length > 0) {
          taskData.forEach(function (task, index) {
            var mergedObject = Object.assign({}, task, _this.customSegmentProperties[index]);
            taskData[index] = mergedObject;
          });
        }
        this.parent.setRecordValue('taskData.' + this.parent.taskFields.segments, taskData, data);
      }
    }
    if (ganttSegments.length > 1) {
      this.parent.setRecordValue('segments', ganttSegments, data.ganttProperties, true);
      this.parent.setRecordValue(this.parent.taskFields.segments, data.taskData[this.parent.taskFields.segments], data, true);
    } else {
      ganttSegments = null;
    }
    return ganttSegments;
  };
  TaskProcessor.prototype.setSegmentTaskData = function (segments, segmenttaskData) {
    var taskSettings = this.parent.taskFields;
    var taskData = extend({}, {}, segmenttaskData, true);
    if (!isNullOrUndefined(taskSettings.startDate)) {
      taskData[this.parent.taskFields.startDate] = segments.startDate;
    }
    if (!isNullOrUndefined(taskSettings.endDate)) {
      taskData[this.parent.taskFields.endDate] = segments.endDate;
    }
    if (!isNullOrUndefined(taskSettings.duration)) {
      taskData[this.parent.taskFields.duration] = Number(segments.duration);
    }
    return taskData;
  };
  /**
   * Method to calculate work based on resource unit and duration.
   *
   * @param {IGanttData} ganttData .
   * @returns {void} .
   */
  TaskProcessor.prototype.updateWorkWithDuration = function (ganttData) {
    if (this.parent['triggeredColumnName'] === this.parent.taskFields.work && ganttData.ganttProperties.duration !== 0 && (isNullOrUndefined(ganttData.ganttProperties.resourceInfo) || ganttData.ganttProperties.resourceInfo.length !== 0)) {
      return;
    }
    var resources = this.parent.editModule && this.parent.editModule.dialogModule && this.parent.editModule.dialogModule['currentResources'] ? this.parent.editModule.dialogModule['currentResources'] : ganttData.ganttProperties.resourceInfo;
    var work = 0;
    var resourceOneDayWork;
    if (!isNullOrUndefined(resources) && resources.length > 0 && !ganttData.hasChildRecords) {
      var resourcesLength = resources.length;
      var index = void 0;
      var resourceUnit = void 0;
      var totSeconds = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        totSeconds = this.parent['getSecondsPerDay'](ganttData.ganttProperties.startDate ? ganttData.ganttProperties.startDate : ganttData.ganttProperties.endDate);
      } else {
        totSeconds = this.parent.secondsPerDay;
      }
      var actualOneDayWork = totSeconds / 3600;
      var durationInDay = this.getDurationInDay(ganttData.ganttProperties.duration, ganttData.ganttProperties.durationUnit);
      for (index = 0; index < resourcesLength; index++) {
        // const resource: any = ganttData.ganttProperties.resourceInfo ? ganttData.ganttProperties.resourceInfo : resources;
        resourceUnit = resources[index][this.parent.resourceFields.unit]; //in percentage
        resourceOneDayWork = resourceUnit > 0 ? actualOneDayWork * resourceUnit / 100 : ganttData.ganttProperties.taskType !== 'FixedUnit' ? actualOneDayWork : 0;
        work += resourceOneDayWork * durationInDay;
      }
      //Update work as per defined unit.
      if (ganttData.ganttProperties.workUnit === 'minute') {
        work = work * 60;
      }
      if (ganttData.ganttProperties.workUnit === 'day') {
        work = work / actualOneDayWork;
      }
      //To check the decimal places.
      if (work % 1 !== 0) {
        work = parseFloat(work.toFixed(2));
      }
    } else if (!ganttData.hasChildRecords && !isNullOrUndefined(ganttData.ganttProperties.work) && !isNullOrUndefined(ganttData.ganttProperties.duration) && ganttData.ganttProperties.duration > 0) {
      work = ganttData.ganttProperties.work;
    }
    if (ganttData.childRecords) {
      if (ganttData.childRecords.length > 0 && this.parent.isOnEdit) {
        var childCompletedWorks = 0;
        for (var i = 0; i < ganttData.childRecords.length; i++) {
          childCompletedWorks += ganttData.childRecords[i].ganttProperties.work;
        }
        work += childCompletedWorks;
      }
    }
    if (ganttData.ganttProperties.taskType === 'FixedUnit' && resourceOneDayWork === 0) {
      work = 0;
      this.parent.setRecordValue('duration', 0, ganttData.ganttProperties, true);
      if (!isNullOrUndefined(this.parent.taskFields.duration)) {
        this.parent.setRecordValue(this.parent.taskFields.duration, 0, ganttData, true);
      }
    }
    this.parent.setRecordValue('work', work, ganttData.ganttProperties, true);
    if (!isNullOrUndefined(this.parent.taskFields.work)) {
      this.parent.dataOperation.updateMappingData(ganttData, 'work');
    }
  };
  /**
   *
   * @param {IGanttData} parent .
   * @returns {IParent} .
   * @private
   */
  TaskProcessor.prototype.getCloneParent = function (parent) {
    if (!isNullOrUndefined(parent)) {
      var cloneParent = {};
      cloneParent.uniqueID = parent.uniqueID;
      cloneParent.expanded = parent.expanded;
      cloneParent.level = parent.level;
      cloneParent.index = parent.index;
      cloneParent.taskId = parent.ganttProperties.rowUniqueID;
      return cloneParent;
    } else {
      return null;
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.reUpdateResources = function () {
    if (this.parent.flatData.length > 0) {
      var data = void 0;
      var ganttProperties = void 0;
      var ganttData = void 0;
      for (var index = 0; index < this.parent.flatData.length; index++) {
        data = this.parent.flatData[index].taskData;
        ganttProperties = this.parent.flatData[index].ganttProperties;
        ganttData = this.parent.flatData[index];
        this.parent.setRecordValue('resourceInfo', this.setResourceInfo(data), ganttProperties, true);
        this.updateResourceName(ganttData);
      }
    }
  };
  TaskProcessor.prototype.addTaskData = function (ganttData, data, isLoad) {
    var _this = this;
    var taskSettings = this.parent.taskFields;
    var dataManager = this.parent.dataSource;
    if (isLoad) {
      if (taskSettings.parentID || dataManager instanceof DataManager && dataManager.dataSource.json && dataManager.dataSource.offline) {
        if (taskSettings.parentID) {
          var id = data[taskSettings.id];
          var index = this.taskIds.indexOf(id.toString());
          var tempData_1 = index > -1 ? this.dataArray[index] : {};
          if (!isNullOrUndefined(this.parent.taskFields.segmentId)) {
            var segmentDataCollection = this.segmentCollection.filter(function (x) {
              return x.key === tempData_1[_this.parent.taskFields.id];
            });
            if (segmentDataCollection.length > 0) {
              tempData_1[this.parent.taskFields.segments] = segmentDataCollection[0].items;
            }
          }
          this.parent.setRecordValue('taskData', tempData_1, ganttData);
        } else {
          this.parent.setRecordValue('taskData', data, ganttData);
        }
      } else {
        this.parent.setRecordValue('taskData', data, ganttData);
      }
    } else {
      this.parent.setRecordValue('taskData', data, ganttData);
    }
  };
  TaskProcessor.prototype.updateExpandStateMappingValue = function (ganttData, data) {
    var expandStateMapping = this.parent.taskFields.expandState;
    var mappingValue = data[expandStateMapping];
    var updatableValue;
    if (expandStateMapping && ganttData.hasChildRecords) {
      if (!isNullOrUndefined(mappingValue)) {
        updatableValue = mappingValue.toString() === 'true' ? true : false;
      } else if (isNullOrUndefined(mappingValue) && !this.parent.collapseAllParentTasks) {
        updatableValue = true;
      } else if (isNullOrUndefined(mappingValue) && this.parent.collapseAllParentTasks) {
        updatableValue = false;
      }
      this.parent.setRecordValue('taskData.' + expandStateMapping, updatableValue, ganttData);
      this.parent.setRecordValue(expandStateMapping, updatableValue, ganttData);
      this.parent.setRecordValue('expanded', updatableValue, ganttData);
    }
  };
  /**
   * @param {IGanttData} ganttData .
   * @param {object} data .
   * @returns {void} .
   */
  TaskProcessor.prototype.setValidatedDates = function (ganttData, data) {
    var ganttProperties = ganttData.ganttProperties;
    var taskSettings = this.parent.taskFields;
    var duration = data[taskSettings.duration];
    var startDate = this.getDateFromFormat(data[taskSettings.startDate], true);
    var endDate = this.getDateFromFormat(data[taskSettings.endDate], true);
    duration = isNullOrUndefined(duration) || duration === '' ? null : duration;
    this.parent.setRecordValue('startDate', new Date(startDate.getTime()), ganttProperties, true);
    if (!isNullOrUndefined(duration) && duration !== '') {
      this.updateDurationValue(duration, ganttProperties);
    } else {
      this.calculateDuration(ganttData);
    }
    this.parent.setRecordValue('endDate', new Date(endDate.getTime()), ganttProperties, true);
  };
  /**
   *
   * @param {IGanttData} ganttData .
   * @param {object} data .
   * @param {boolean} isLoad .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.calculateScheduledValues = function (ganttData, data, isLoad) {
    var taskSettings = this.parent.taskFields;
    var ganttProperties = ganttData.ganttProperties;
    var duration = data[taskSettings.duration];
    duration = isNullOrUndefined(duration) || duration === '' ? null : duration;
    var startDate;
    var endDate;
    if (ganttProperties.startDate && ganttProperties.endDate) {
      startDate = this.getDateFromFormat(ganttProperties.startDate, true);
      endDate = this.getDateFromFormat(ganttProperties.endDate, true);
    } else {
      startDate = this.getDateFromFormat(data[taskSettings.startDate], true);
      endDate = this.getDateFromFormat(data[taskSettings.endDate], true);
    }
    var segments = taskSettings.segments ? data[taskSettings.segments] || ganttData.taskData[taskSettings.segments] : null;
    var isMileStone = taskSettings.milestone ? data[taskSettings.milestone] ? true : false : false;
    var durationMapping = data[taskSettings.durationUnit] ? data[taskSettings.durationUnit] : '';
    this.parent.setRecordValue('durationUnit', this.validateDurationUnitMapping(durationMapping), ganttProperties, true);
    var work = !isNullOrUndefined(data[taskSettings.work]) ? parseFloat(data[taskSettings.work]) : 0;
    this.parent.setRecordValue('workUnit', this.validateWorkUnitMapping(this.parent.workUnit), ganttProperties, true);
    var taskTypeMapping = data[taskSettings.type] ? data[taskSettings.type] : '';
    var tType = this.validateTaskTypeMapping(taskTypeMapping);
    this.parent.setRecordValue('taskType', tType, ganttProperties, true);
    var isUnassignedTask = data[this.parent.resourceFields.name] === this.parent.localeObj.getConstant('unassignedTask');
    if (isUnassignedTask) {
      this.parent.setRecordValue('taskType', 'FixedDuration', ganttProperties, true);
    }
    if (isLoad && !this.parent.enableValidation && startDate && endDate) {
      this.setValidatedDates(ganttData, data);
    } else {
      if (!endDate && !startDate && (isNullOrUndefined(duration) || duration === '')) {
        if (this.parent.allowUnscheduledTasks) {
          return;
        } else {
          this.parent.setRecordValue('duration', 1, ganttProperties, true);
          this.parent.setRecordValue('startDate', this.getProjectStartDate(ganttProperties, isLoad), ganttProperties, true);
          this.calculateEndDate(ganttData);
        }
      } else if (startDate) {
        this.calculateDateFromStartDate(startDate, endDate, duration, ganttData, isLoad);
      } else if (endDate) {
        this.calculateDateFromEndDate(endDate, duration, ganttData);
      } else if (!isNullOrUndefined(duration) && duration !== '') {
        this.updateDurationValue(duration, ganttProperties);
        if (this.parent.allowUnscheduledTasks) {
          this.parent.setRecordValue('startDate', null, ganttProperties, true);
          this.parent.setRecordValue('endDate', null, ganttProperties, true);
        } else {
          this.parent.setRecordValue('startDate', this.getProjectStartDate(ganttProperties, isLoad), ganttProperties, true);
          this.calculateEndDate(ganttData);
        }
      }
    }
    if (!this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      if (!isNullOrUndefined(ganttData.ganttProperties.startDate)) {
        ganttData[this.parent.taskFields.startDate] = ganttData.ganttProperties.startDate;
        ganttData.taskData[this.parent.taskFields.startDate] = ganttData.ganttProperties.startDate;
      }
      if (!isNullOrUndefined(ganttData.ganttProperties.endDate)) {
        ganttData[this.parent.taskFields.endDate] = ganttData.ganttProperties.endDate;
        ganttData.taskData[this.parent.taskFields.endDate] = ganttData.ganttProperties.endDate;
      }
    }
    if (!isNullOrUndefined(segments)) {
      this.parent.setRecordValue('segments', this.setSegmentsInfo(ganttData, true), ganttProperties, true);
    }
    if (ganttProperties.duration === 0) {
      this.parent.setRecordValue('isMilestone', true, ganttProperties, true);
      if (!isNullOrUndefined(this.parent.taskFields) && !isNullOrUndefined(this.parent.taskFields.milestone)) {
        this.parent.setRecordValue(this.parent.taskFields.milestone, true, ganttData, true);
      }
      this.parent.setRecordValue('endDate', ganttProperties.startDate, ganttProperties, true);
    }
    if (!isNullOrUndefined(isMileStone) && isMileStone) {
      this.parent.setRecordValue('duration', 0, ganttProperties, true);
      this.parent.setRecordValue('isMilestone', true, ganttProperties, true);
      if (!isNullOrUndefined(this.parent.taskFields) && !isNullOrUndefined(this.parent.taskFields.milestone)) {
        this.parent.setRecordValue(this.parent.taskFields.milestone, true, ganttData, true);
      }
      this.parent.setRecordValue('endDate', ganttProperties.startDate, ganttProperties, true);
    }
    if (!isNullOrUndefined(taskSettings.work)) {
      this.parent.setRecordValue('durationUnit', this.parent.durationUnit, ganttProperties, true);
      if (isNaN(work) || isNullOrUndefined(work)) {
        this.parent.setRecordValue('work', 0, ganttProperties, true);
        this.parent.setRecordValue('duration', 0, ganttProperties, true);
        this.parent.setRecordValue('isMilestone', true, ganttProperties, true);
        if (!isNullOrUndefined(this.parent.taskFields) && !isNullOrUndefined(this.parent.taskFields.milestone)) {
          this.parent.setRecordValue(this.parent.taskFields.milestone, true, ganttData, true);
        }
        this.parent.setRecordValue('endDate', ganttProperties.startDate, ganttProperties, true);
      } else {
        this.parent.setRecordValue('work', work, ganttProperties, true);
        switch (tType) {
          case 'FixedDuration':
            // To validate the work column as well,when initial dataset have 0 duration
            if (!isNullOrUndefined(ganttData[this.parent.taskFields.resourceInfo]) && ganttData.ganttProperties.resourceInfo.length !== 0 || ganttProperties.duration === 0) {
              this.updateWorkWithDuration(ganttData);
            }
            break;
          case 'FixedWork':
            if (!isNullOrUndefined(ganttData[this.parent.taskFields.resourceInfo]) && ganttData.ganttProperties.resourceInfo.length !== 0) {
              this.updateDurationWithWork(ganttData);
            }
            this.updateUnitWithWork(ganttData);
            break;
          case 'FixedUnit':
            this.updateWorkWithDuration(ganttData);
            break;
        }
        if (!isNullOrUndefined(taskSettings.type)) {
          this.parent.dataOperation.updateMappingData(ganttData, 'type');
        }
        if (ganttProperties.duration === 0) {
          this.parent.setRecordValue('isMilestone', true, ganttProperties, true);
          if (!isNullOrUndefined(this.parent.taskFields) && !isNullOrUndefined(this.parent.taskFields.milestone)) {
            this.parent.setRecordValue(this.parent.taskFields.milestone, true, ganttData, true);
          }
          this.parent.setRecordValue('endDate', ganttProperties.startDate, ganttProperties, true);
        } else if (!isNullOrUndefined(ganttProperties.startDate) && !isNullOrUndefined(ganttProperties.duration)) {
          this.parent.setRecordValue('isMilestone', false, ganttProperties, true);
          if (!isNullOrUndefined(this.parent.taskFields) && !isNullOrUndefined(this.parent.taskFields.milestone)) {
            this.parent.setRecordValue(this.parent.taskFields.milestone, false, ganttData, true);
          }
          this.calculateEndDate(ganttData);
        }
      }
      this.parent.dataOperation.updateMappingData(ganttData, 'work');
    } else if (taskSettings.type && ganttProperties.taskType) {
      this.parent.dataOperation.updateMappingData(ganttData, 'type');
    }
  };
  /**
   * Method to update duration with work value.
   *
   * @param {IGanttData} ganttData .
   * @returns {void} .
   */
  TaskProcessor.prototype.updateDurationWithWork = function (ganttData) {
    var ganttProperties = ganttData.ganttProperties;
    var resources = this.parent.editModule && this.parent.editModule.dialogModule && this.parent.editModule.dialogModule['currentResources'] ? this.parent.editModule.dialogModule['currentResources'] : ganttProperties.resourceInfo;
    if (!isNullOrUndefined(resources)) {
      var resourcesLength = !isNullOrUndefined(resources) ? resources.length : 0;
      var totalResourceOneDayWork = 0;
      var totSeconds = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        totSeconds = this.parent['getSecondsPerDay'](ganttData.ganttProperties.startDate ? ganttData.ganttProperties.startDate : ganttData.ganttProperties.endDate);
      } else {
        totSeconds = this.parent.secondsPerDay;
      }
      var actualOneDayWork = totSeconds / 3600;
      var updatedDuration = 0;
      var resourceUnit = void 0;
      var index = void 0;
      for (index = 0; index < resourcesLength; index++) {
        resourceUnit = resources[index][this.parent.resourceFields.unit]; //in percentage
        totalResourceOneDayWork += resourceUnit > 0 ? actualOneDayWork * resourceUnit / 100 : ganttData.ganttProperties.taskType !== 'FixedUnit' ? ganttProperties.taskType !== 'FixedWork' && ganttProperties.duration !== 0 ? actualOneDayWork : 0 : 0; //in hours
      }
      if (ganttProperties.taskType === 'FixedWork' && totalResourceOneDayWork === 0 && this.parent['updateDuration']) {
        totalResourceOneDayWork = actualOneDayWork;
      }
      var totalHours = this.getWorkInHour(ganttProperties.work, ganttProperties.workUnit);
      if (resourcesLength > 0 && totalResourceOneDayWork > 0) {
        updatedDuration += totalHours / totalResourceOneDayWork;
      }
      if (ganttProperties.taskType === 'FixedUnit' && totalResourceOneDayWork === 0) {
        this.parent.setRecordValue('work', 0, ganttProperties, true);
        this.parent.setRecordValue(this.parent.taskFields.work, 0, ganttData, true);
      }
      //Update duration as per defined unit.
      if (ganttProperties.durationUnit === 'minute') {
        updatedDuration = updatedDuration * actualOneDayWork * 60;
      }
      if (ganttProperties.durationUnit === 'hour') {
        updatedDuration = updatedDuration * actualOneDayWork;
      }
      //To check the decimal places.
      if (updatedDuration % 1 !== 0) {
        updatedDuration = parseFloat(updatedDuration.toFixed(2));
      }
      if (!isNullOrUndefined(ganttProperties.duration)) {
        this.parent.setRecordValue('duration', updatedDuration, ganttProperties, true);
      }
      this.parent.dataOperation.updateMappingData(ganttData, 'duration');
    }
  };
  /**
   * Update units of resources with respect to duration and work of a task.
   *
   * @param {IGanttData} ganttData .
   * @returns {void} .
   */
  TaskProcessor.prototype.updateUnitWithWork = function (ganttData) {
    var ganttProperties = ganttData.ganttProperties;
    var resources = !this.parent.isLoad && !isNullOrUndefined(this.parent.editModule) && !isNullOrUndefined(this.parent.editModule.dialogModule) && !this.parent.editModule.dialogModule['isEdit'] && !isNullOrUndefined(this.parent.editModule.cellEditModule) && !this.parent.editModule.cellEditModule.isCellEdit ? this.parent.editModule.dialogModule.ganttResources : ganttProperties.resourceInfo;
    if (this.parent.editModule && this.parent.editModule.taskbarEditModule && (this.parent.editModule.taskbarEditModule.taskBarEditAction === 'LeftResizing' || this.parent.editModule.taskbarEditModule.taskBarEditAction === 'RightResizing')) {
      resources = ganttData.ganttProperties.resourceInfo;
    }
    var resourcesLength = !isNullOrUndefined(resources) ? resources.length : 0;
    var totSeconds;
    if (this.parent.weekWorkingTime.length > 0) {
      totSeconds = this.parent['getSecondsPerDay'](ganttData.ganttProperties.startDate ? ganttData.ganttProperties.startDate : ganttData.ganttProperties.endDate);
    } else {
      totSeconds = this.parent.secondsPerDay;
    }
    var actualOneDayWork = totSeconds / 3600;
    if (resourcesLength === 0) {
      return;
    }
    var durationInDay = this.getDurationInDay(ganttData.ganttProperties.duration, ganttData.ganttProperties.durationUnit);
    var totalHours = this.getWorkInHour(ganttProperties.work, ganttProperties.workUnit);
    var totalUnitInPercentage = durationInDay > 0 ? totalHours / (durationInDay * actualOneDayWork) * 100 : 0;
    var individualUnit = totalUnitInPercentage === 0 ? 0 : totalUnitInPercentage > 0 ? totalUnitInPercentage / resourcesLength : 100;
    //To check the decimal places.
    if (individualUnit % 1 !== 0) {
      individualUnit = parseFloat(individualUnit.toFixed(2));
    }
    for (var index = 0; index < resourcesLength; index++) {
      resources[index][this.parent.resourceFields.unit] = individualUnit;
      if (!this.parent.isLoad && !isNullOrUndefined(this.parent.editModule) && !isNullOrUndefined(this.parent.editModule.dialogModule) && !this.parent.editModule.dialogModule['isEdit'] && !isNullOrUndefined(this.parent.editModule.cellEditModule) && !this.parent.editModule.cellEditModule.isCellEdit) {
        if (ganttProperties.resourceInfo) {
          ganttProperties.resourceInfo[index][this.parent.resourceFields.unit] = individualUnit;
        }
      }
      if (this.parent.editModule && this.parent.editModule.dialogModule && !isNullOrUndefined(this.parent.editModule.dialogModule.ganttResources[index])) {
        this.parent.editModule.dialogModule.ganttResources[index][this.parent.resourceFields.unit] = individualUnit;
      }
    }
    //To update the unit value in data source
    this.updateResourceName(ganttData);
  };
  TaskProcessor.prototype.calculateDateFromEndDate = function (endDate, duration, ganttData) {
    var ganttProperties = ganttData.ganttProperties;
    var dayEndTime;
    if (this.parent.weekWorkingTime.length > 0) {
      dayEndTime = this.parent['getEndTime'](endDate);
    } else {
      dayEndTime = this.parent.defaultEndTime;
    }
    if (endDate.getHours() === 0 && dayEndTime !== 86400) {
      this.setTime(dayEndTime, endDate);
    }
    var validateAsMilestone = parseInt(duration, 10) === 0 ? true : null;
    this.parent.setRecordValue('endDate', this.checkEndDate(endDate, ganttData.ganttProperties, validateAsMilestone), ganttProperties, true);
    if (isNullOrUndefined(duration) || duration === '') {
      if (this.parent.allowUnscheduledTasks) {
        this.parent.setRecordValue('startDate', null, ganttProperties, true);
        this.parent.setRecordValue('duration', null, ganttProperties, true);
      } else {
        this.parent.setRecordValue('duration', 1, ganttProperties, true);
        this.parent.setRecordValue('startDate', this.getStartDate(ganttProperties.endDate, ganttProperties.duration, ganttProperties.durationUnit, ganttProperties), ganttProperties, true);
      }
    } else if (!isNullOrUndefined(duration) && duration !== '') {
      this.updateDurationValue(duration, ganttProperties);
      this.parent.setRecordValue('startDate', this.getStartDate(ganttProperties.endDate, ganttProperties.duration, ganttProperties.durationUnit, ganttProperties), ganttProperties, true);
    }
  };
  TaskProcessor.prototype.calculateDateFromStartDate = function (startDate, endDate, duration, ganttData, isLoad) {
    var ganttProperties = ganttData.ganttProperties;
    var validateAsMilestone = parseInt(duration, 10) === 0 || startDate && endDate && new Date(startDate.getTime()) === new Date(endDate.getTime()) ? true : null;
    this.parent.setRecordValue('startDate', this.checkStartDate(startDate, ganttProperties, validateAsMilestone, isLoad), ganttProperties, true);
    if (this.parent.isTreeGridRendered && ganttData) {
      this.updateTaskData(ganttData);
    }
    if (!endDate && (isNullOrUndefined(duration) || duration === '')) {
      if (this.parent.allowUnscheduledTasks) {
        this.parent.setRecordValue('endDate', null, ganttProperties, true);
        this.parent.setRecordValue('duration', null, ganttProperties, true);
      } else {
        this.parent.setRecordValue('duration', 1, ganttProperties, true);
        this.calculateEndDate(ganttData);
      }
    } else if (!isNullOrUndefined(duration) && !endDate) {
      this.updateDurationValue(duration, ganttProperties);
      this.calculateEndDate(ganttData);
    } else if (endDate && (isNullOrUndefined(duration) || duration === '')) {
      var dayEndTime = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        dayEndTime = this.parent['getEndTime'](endDate);
      } else {
        dayEndTime = this.parent.defaultEndTime;
      }
      if (endDate.getHours() === 0 && dayEndTime !== 86400) {
        this.setTime(dayEndTime, endDate);
      }
      this.parent.setRecordValue('endDate', this.checkEndDate(endDate, ganttData.ganttProperties), ganttProperties, true);
      if (this.compareDates(ganttProperties.startDate, ganttProperties.endDate) === 1) {
        this.parent.setRecordValue('endDate', ganttProperties.startDate, ganttProperties, true);
        this.parent.setRecordValue('isMilestone', true, ganttProperties, true);
        this.parent.setRecordValue('duration', 0, ganttProperties, true);
      } else {
        this.calculateDuration(ganttData);
      }
    } else {
      this.updateDurationValue(duration, ganttProperties);
      if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
        this.calculateEndDate(ganttData);
      } else {
        this.parent.setRecordValue('endDate', endDate, ganttProperties, true);
      }
    }
  };
  /**
   *
   * @param {number} parentWidth .
   * @param {number} percent .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.getProgressWidth = function (parentWidth, percent) {
    return parentWidth * percent / 100;
  };
  /**
   *
   * @param {IGanttData} ganttData .
   * @param {boolean} isAuto .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateWidth = function (ganttData, isAuto) {
    var ganttProp = ganttData.ganttProperties;
    var sDate = isAuto ? ganttProp.autoStartDate : ganttProp.startDate;
    var eDate = isAuto ? ganttProp.autoEndDate : ganttProp.endDate;
    var unscheduledTaskWidth = 3;
    if (isNullOrUndefined(sDate) && isNullOrUndefined(eDate)) {
      sDate = this.getValidStartDate(ganttProp, isAuto);
      eDate = this.getValidEndDate(ganttProp, isAuto);
    }
    if (isNullOrUndefined(sDate) || isNullOrUndefined(eDate)) {
      return unscheduledTaskWidth;
    } else if (ganttProp.isMilestone && (!ganttData.hasChildRecords || ganttProp.isAutoSchedule)) {
      //let taskBarHeight: number = this.getTaskbarHeight();
      return 0;
    } else {
      return this.getTaskWidth(sDate, eDate, ganttProp);
    }
  };
  TaskProcessor.prototype.getTaskbarHeight = function () {
    var rowHeight = this.parent.rowHeight;
    var taskBarHeight = this.parent.taskbarHeight;
    if (taskBarHeight < rowHeight) {
      return taskBarHeight;
    } else {
      return rowHeight;
    }
  };
  /**
   * Method to calculate left
   *
   * @param {ITaskData} ganttProp .
   * @param {boolean} isAuto .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateLeft = function (ganttProp, isAuto) {
    var sDate = null;
    var left = -300;
    var startDate = isAuto ? ganttProp.autoStartDate : ganttProp.startDate;
    var endDate = isAuto ? ganttProp.autoEndDate : ganttProp.endDate;
    var duration = isAuto ? ganttProp.autoDuration : ganttProp.duration; // eslint-disable-line
    var milestone = ganttProp.isMilestone;
    var ganttRecord = this.parent.getTaskByUniqueID(ganttProp.uniqueID);
    if (ganttRecord && this.parent.allowUnscheduledTasks && isNullOrUndefined(startDate) && isNullOrUndefined(endDate) && !isNullOrUndefined(duration) && !ganttRecord.hasChildRecords && ganttRecord.parentItem && (this.parent.taskMode === 'Manual' || this.parent.taskMode === 'Custom' && ganttRecord[this.parent.taskFields.manual])) {
      var parentRec = this.parent.getParentTask(ganttRecord.parentItem);
      if (parentRec.ganttProperties.startDate) {
        startDate = parentRec.ganttProperties.startDate;
      }
    }
    if (startDate) {
      sDate = new Date(startDate.getTime());
    } else if (endDate) {
      sDate = new Date(endDate.getTime());
      milestone = isNullOrUndefined(startDate) && this.parent.allowUnscheduledTasks ? false : true;
    } else {
      sDate = this.getValidStartDate(ganttProp);
    }
    if (!isNullOrUndefined(sDate)) {
      left = this.getTaskLeft(sDate, milestone);
    }
    return left;
  };
  /**
   * calculate the left position of the auto scheduled taskbar
   *
   * @param {ITaskData} ganttProperties - Defines the gantt data.
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateAutoLeft = function (ganttProperties) {
    return this.getTaskLeft(ganttProperties.autoStartDate, ganttProperties.isMilestone);
  };
  /**
   * To calculate duration of Gantt record with auto scheduled start date and auto scheduled end date
   *
   * @param {ITaskData} ganttProperties - Defines the gantt data.
   * @returns {number} .
   */
  TaskProcessor.prototype.calculateAutoDuration = function (ganttProperties) {
    return this.getDuration(ganttProperties.autoStartDate, ganttProperties.autoEndDate, ganttProperties.durationUnit, false, ganttProperties.isMilestone);
  };
  /**
   * calculate the with between auto scheduled start date and auto scheduled end date
   *
   * @param {ITaskData} ganttProperties - Defines the gantt data.
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateAutoWidth = function (ganttProperties) {
    return this.getTaskWidth(ganttProperties.autoStartDate, ganttProperties.autoEndDate);
  };
  /**
   * calculate the left margin of the baseline element
   *
   * @param {ITaskData} ganttProperties .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateBaselineLeft = function (ganttProperties) {
    var baselineStartDate = this.getDateFromFormat(ganttProperties.baselineStartDate);
    var baselineEndDate = this.getDateFromFormat(ganttProperties.baselineEndDate);
    if (baselineStartDate && baselineEndDate) {
      return this.getTaskLeft(baselineStartDate, ganttProperties.isMilestone);
    } else {
      return 0;
    }
  };
  /**
   * calculate the width between baseline start date and baseline end date.
   *
   * @param {ITaskData} ganttProperties .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.calculateBaselineWidth = function (ganttProperties) {
    var baselineStartDate = this.getDateFromFormat(ganttProperties.baselineStartDate);
    var baselineEndDate = this.getDateFromFormat(ganttProperties.baselineEndDate);
    if (baselineStartDate && baselineEndDate && baselineStartDate.getTime() !== baselineEndDate.getTime()) {
      return this.getTaskWidth(baselineStartDate, baselineEndDate);
    } else {
      return 0;
    }
  };
  /**
   * To get tasks width value
   *
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {ITaskData} [ganttData] .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.getTaskWidth = function (startDate, endDate, ganttData) {
    var sDate = new Date(startDate.getTime());
    var eDate = new Date(endDate.getTime());
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    var zoomOrTimeline = this.parent.currentZoomingLevel ? this.parent.currentZoomingLevel : this.parent.timelineSettings;
    var countValue = zoomOrTimeline['bottomTier'] !== null ? zoomOrTimeline['bottomTier'].count : zoomOrTimeline['topTier'].count;
    var isValid = false;
    var modifiedsDate = new Date(startDate.getTime());
    var hour = 0;
    if (!isNullOrUndefined(ganttData) && ganttData.durationUnit === 'hour') {
      modifiedsDate = new Date(modifiedsDate.getTime() + ganttData.duration * 60 * 60 * 1000);
    }
    if (!isNullOrUndefined(ganttData) && ganttData.durationUnit === 'minute' || !isNullOrUndefined(ganttData) && ganttData.durationUnit === 'day' && ganttData.duration < 1) {
      modifiedsDate = new Date(modifiedsDate.getTime() + ganttData.duration * 60 * 1000);
    }
    if (this.parent.weekWorkingTime.length > 0) {
      var date = new Date(startDate.getTime());
      for (var count = startDate.getDate(); count <= endDate.getDate(); count++) {
        var day = date.getDay();
        var currentDay = void 0;
        var weekWorkingTime = this.parent.weekWorkingTime;
        switch (day) {
          case 0:
            currentDay = 'Sunday';
            break;
          case 1:
            currentDay = 'Monday';
            break;
          case 2:
            currentDay = 'Tuesday';
            break;
          case 3:
            currentDay = 'Wednesday';
            break;
          case 4:
            currentDay = 'Thursday';
            break;
          case 5:
            currentDay = 'Friday';
            break;
          case 6:
            currentDay = 'Saturday';
            break;
          default:
            currentDay = '';
        }
        var isValid_1 = true;
        for (var i = 0; i < weekWorkingTime.length; i++) {
          if (weekWorkingTime[i][currentDay] && weekWorkingTime[i][currentDay].length > 0) {
            isValid_1 = false;
            for (var j = 0; j < weekWorkingTime[i][currentDay].length; j++) {
              hour = hour + weekWorkingTime[i][currentDay][j].to - weekWorkingTime[i][currentDay][j].from;
            }
          }
        }
        if (isValid_1) {
          for (var k = 0; k < this.parent.dayWorkingTime.length; k++) {
            hour = hour + this.parent.dayWorkingTime[k].to - this.parent.dayWorkingTime[k].from;
          }
        }
        date = new Date(date.setDate(date.getDate() + 1));
        if (!this.parent.includeWeekend) {
          date = this.parent.dataOperation.getNextWorkingDay(date);
        }
      }
    } else {
      for (var i = 0; i < this.parent.dayWorkingTime.length; i++) {
        hour = hour + this.parent.dayWorkingTime[i].to - this.parent.dayWorkingTime[i].from;
      }
    }
    var dateDiff = modifiedsDate.getTime() - sDate.getTime();
    var dayStartTime;
    var dayEndTime;
    if (!isNullOrUndefined(ganttData) && ganttData.durationUnit === 'minute' && ganttData.duration < hour * 60 || !isNullOrUndefined(ganttData) && ganttData.durationUnit === 'day' && /^\d+\.\d+$/.test(ganttData.duration.toString())) {
      if (tierMode === 'Day') {
        if (this.parent.weekWorkingTime.length > 0) {
          dayStartTime = this.parent['getStartTime'](sDate);
          dayEndTime = this.parent['getEndTime'](eDate);
        } else {
          dayStartTime = this.parent.defaultStartTime;
          dayEndTime = this.parent.defaultEndTime;
        }
        if (Math.floor(dateDiff / (1000 * 60 * 60) % 24) >= hour || dateDiff === 0) {
          isValid = true;
        }
        if (this.getSecondsInDecimal(sDate) === dayStartTime && isValid) {
          sDate.setHours(0, 0, 0, 0);
        }
        if (this.getSecondsInDecimal(eDate) === dayEndTime && isValid) {
          eDate.setHours(24);
        }
        if (this.getSecondsInDecimal(eDate) === dayStartTime) {
          eDate.setHours(0, 0, 0, 0);
        }
      } else {
        isValid = true;
      }
      if (sDate.getTime() === eDate.getTime()) {
        return this.parent.perDayWidth;
      } else {
        if (isValid) {
          if (ganttData.durationUnit === 'day' && /^\d+\.\d+$/.test(ganttData.duration.toString()) && this.parent.timelineModule.bottomTier === 'Day' && isNullOrUndefined(ganttData.segments)) {
            var holidaysCount = this.parent.holidays && this.parent.holidays.length > 0 ? this.getHolidaysCount(sDate, eDate) : 0;
            var weekendCount = !this.parent.includeWeekend ? this.getWeekendCount(sDate, eDate) : 0;
            return (holidaysCount + weekendCount + ganttData.duration) * this.parent.perDayWidth;
          } else {
            return this.getTimeDifference(sDate, eDate, true) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
          }
        } else {
          if (ganttData.durationUnit === 'day' && ganttData.duration < 1) {
            return ganttData.duration * this.parent.perDayWidth;
          } else {
            return this.getTimeDifference(sDate, eDate) / (1000 * 60 * 60 * hour) * this.parent.perDayWidth;
          }
        }
      }
    } else {
      if (tierMode === 'Day') {
        if (this.parent.weekWorkingTime.length > 0) {
          dayStartTime = this.parent['getStartTime'](sDate);
          dayEndTime = this.parent['getEndTime'](eDate);
        } else {
          dayStartTime = this.parent.defaultStartTime;
          dayEndTime = this.parent.defaultEndTime;
        }
        if (this.getSecondsInDecimal(sDate) === dayStartTime) {
          sDate.setHours(0, 0, 0, 0);
        }
        if (this.getSecondsInDecimal(eDate) === dayEndTime) {
          eDate.setHours(24);
          eDate.setHours(0, 0, 0, 0);
        }
        if (this.getSecondsInDecimal(eDate) === dayStartTime) {
          eDate.setHours(0, 0, 0, 0);
        }
      }
      if (sDate.getTime() === eDate.getTime()) {
        return this.parent.perDayWidth;
      } else {
        if (this.parent.isInDst(sDate) || this.parent.isInDst(eDate)) {
          var timeDifference = this.getTimeDifference(sDate, eDate, true);
          var totalWidth = timeDifference / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
          var sOffset = sDate.getTimezoneOffset();
          var eOffset = eDate.getTimezoneOffset();
          var topTier = this.parent.timelineModule.customTimelineSettings.topTier;
          if (topTier && topTier['unit'] === 'Hour' && topTier['count'] === 1) {
            tierMode = topTier['unit'];
            countValue = topTier['count'];
          }
          if (tierMode === 'Hour' && countValue === 1 && sOffset !== eOffset && sOffset > eOffset) {
            totalWidth = totalWidth - this.parent.perDayWidth / 24;
          }
          return totalWidth;
        } else {
          return this.getTimeDifference(sDate, eDate) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
        }
      }
    }
  };
  TaskProcessor.prototype.getDSTTransitions = function (year, timeZone) {
    function findNthWeekday(year, month, dayOfWeek, n) {
      var firstDayOfMonth = new Date(Date.UTC(year, month, 1));
      var firstDayOfWeek = firstDayOfMonth.getUTCDay();
      var offset = (dayOfWeek - firstDayOfWeek + 7) % 7;
      return new Date(Date.UTC(year, month, 1 + offset + (n - 1) * 7, 2, 0, 0));
    }
    function convertToTimezone(date, timeZone) {
      var formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
      var formatted = formatter.format(date);
      var _a = formatted.match(/\d+/g).map(Number),
        month = _a[0],
        day = _a[1],
        year = _a[2],
        hour = _a[3],
        minute = _a[4],
        second = _a[5];
      return new Date(year, month - 1, day, hour, minute, second);
    }
    var dstStartDate = findNthWeekday(year, 2, 0, 5);
    var dstEndDate = findNthWeekday(year, 9, 0, 5);
    return {
      dstStart: convertToTimezone(dstStartDate, timeZone),
      dstEnd: convertToTimezone(dstEndDate, timeZone)
    };
  };
  TaskProcessor.prototype.hasDSTTransition = function (year) {
    var january = new Date(year, 0, 1);
    var july = new Date(year, 6, 1);
    var janOffset = january.getTimezoneOffset();
    var julOffset = july.getTimezoneOffset();
    // If the offsets are different, it means there is a DST transition
    return janOffset !== julOffset;
  };
  /**
   * Get task left value
   *
   * @param {Date} startDate .
   * @param {boolean} isMilestone .
   * @param {boolean} isFromTimelineVirtulization .
   * @returns {number} .
   * @private
   */
  TaskProcessor.prototype.getTaskLeft = function (startDate, isMilestone, isFromTimelineVirtulization) {
    var isTimeSet = false;
    var date = new Date(startDate.getTime());
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    var zoomOrTimeline = this.parent.currentZoomingLevel ? this.parent.currentZoomingLevel : this.parent.timelineSettings;
    var countValue = zoomOrTimeline['bottomTier'] !== null ? zoomOrTimeline['bottomTier'].count : zoomOrTimeline['topTier'].count;
    if (tierMode === 'Day') {
      var dayStartTime = void 0;
      var dayEndTime = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        dayStartTime = this.parent['getStartTime'](date);
        dayEndTime = this.parent['getEndTime'](date);
      } else {
        dayStartTime = this.parent.defaultStartTime;
        dayEndTime = this.parent.defaultEndTime;
      }
      if (this.getSecondsInDecimal(date) === dayStartTime) {
        date.setHours(0, 0, 0, 0);
      } else if (isMilestone && this.getSecondsInDecimal(date) === dayEndTime) {
        date.setHours(24);
        isTimeSet = true;
      } else if (this.getSecondsInDecimal(date) === dayEndTime && this.parent.allowUnscheduledTasks) {
        date.setHours(22);
        isTimeSet = true;
      }
    }
    var leftValueForStartDate;
    var isValid = true;
    if (this.parent.editModule && (this.parent.editModule.taskbarEditModule && this.parent.editModule.taskbarEditModule.taskBarEditAction || this.parent.editModule.dialogModule && this.parent.editModule.dialogModule['isEdit'] || this.parent.editModule.cellEditModule && this.parent.editModule.cellEditModule.isCellEdit || this.parent.ganttChartModule.scrollObject['isSetScrollLeft']) && !isFromTimelineVirtulization) {
      isValid = false;
    }
    if (this.parent.enableTimelineVirtualization && isValid && !this.parent.timelineModule['performedTimeSpanAction']) {
      leftValueForStartDate = this.parent.enableTimelineVirtualization && this.parent.ganttChartModule.scrollObject.element.scrollLeft !== 0 ? this.parent.ganttChartModule.scrollObject.getTimelineLeft() : null;
    }
    var timelineStartDate = this.parent.enableTimelineVirtualization && !isNullOrUndefined(leftValueForStartDate) ? new Date(this.parent.timelineModule['dateByLeftValue'](leftValueForStartDate).toString()) : new Date(this.parent.timelineModule.timelineStartDate);
    if (timelineStartDate) {
      var leftValue = void 0;
      var hasDST = this.hasDSTTransition(startDate.getFullYear());
      var transitions = void 0;
      if (hasDST) {
        transitions = this.getDSTTransitions(startDate.getFullYear(), this.systemTimeZone);
      }
      if (this.parent.isInDst(startDate) && !this.parent.isInDst(timelineStartDate)) {
        var newTimelineStartDate = void 0;
        if (this.parent.isInDst(date)) {
          newTimelineStartDate = new Date(timelineStartDate.getTime() - 60 * 60 * 1000);
        } else {
          newTimelineStartDate = new Date(timelineStartDate.getTime());
        }
        leftValue = (date.getTime() - newTimelineStartDate.getTime()) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
      } else {
        var width = void 0;
        if (this.parent.timelineModule.bottomTier === 'Day' && this.getSecondsInDecimal(date) !== this.parent.defaultStartTime && this.getSecondsInDecimal(date) !== 0 && !isTimeSet) {
          var newDate = new Date(startDate.getTime());
          var setStartDate = new Date(newDate.setHours(0, 0, 0, 0));
          var duration = this.getDuration(setStartDate, startDate, 'day', true, false);
          width = duration * this.parent.perDayWidth;
          date.setHours(0, 0, 0, 0);
          leftValue = (date.getTime() - timelineStartDate.getTime()) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
          if (this.getSecondsInDecimal(date) !== this.parent.defaultStartTime && this.parent.timelineModule.bottomTier === 'Day') {
            leftValue += width;
          }
        } else {
          var newTimelineStartDate = void 0;
          if (date.getTimezoneOffset() < timelineStartDate.getTimezoneOffset()) {
            newTimelineStartDate = new Date(timelineStartDate.getTime() - 60 * 60 * 1000);
          } else {
            newTimelineStartDate = new Date(timelineStartDate.getTime());
          }
          leftValue = (date.getTime() - timelineStartDate.getTime()) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
        }
      }
      var timelineStartTime = timelineStartDate.getTime();
      var dstStartTime = void 0;
      if (transitions && transitions['dstStart']) {
        dstStartTime = transitions['dstStart'].getTime();
      }
      var isBeforeOrAtDSTStart = timelineStartTime <= dstStartTime;
      if (hasDST && this.parent.dayWorkingTime[0]['properties'].from > transitions['dstStart'].getHours() && isBeforeOrAtDSTStart && tierMode === 'Day' && this.getSecondsInDecimal(date) === this.parent.defaultStartTime) {
        if (leftValue % this.parent.perDayWidth !== 0) {
          var leftDifference = this.parent.perDayWidth - leftValue % this.parent.perDayWidth;
          leftValue = leftValue + leftDifference;
        }
      }
      if (this.parent.isInDst(timelineStartDate) && !this.parent.isInDst(startDate) && (this.parent.timelineModule.topTier === 'Hour' || this.parent.timelineModule.bottomTier === 'Hour')) {
        leftValue = leftValue - this.parent.timelineSettings.timelineUnitSize;
      }
      var topTier = this.parent.timelineModule.customTimelineSettings.topTier;
      if (topTier && topTier['unit'] === 'Hour' && topTier['count'] === 1) {
        tierMode = topTier['unit'];
        countValue = topTier['count'];
      }
      if (hasDST && countValue === 1 && tierMode === 'Hour' && startDate >= transitions['dstStart'] && isBeforeOrAtDSTStart) {
        leftValue = leftValue - this.parent.perDayWidth / 24;
      }
      return leftValue;
    } else {
      return 0;
    }
  };
  TaskProcessor.prototype.getSplitTaskWidth = function (sDate, duration, data) {
    var startDate = new Date(sDate.getTime());
    var endDate = new Date(this.getEndDate(startDate, duration, data.ganttProperties.durationUnit, data.ganttProperties, false).getTime());
    var tierViewMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    if (tierViewMode === 'Day') {
      var dayStartTime = void 0;
      var dayEndTime = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        dayStartTime = this.parent['getStartTime'](startDate);
        dayEndTime = this.parent['getEndTime'](endDate);
      } else {
        dayStartTime = this.parent.defaultStartTime;
        dayEndTime = this.parent.defaultEndTime;
      }
      if (this.getSecondsInDecimal(startDate) === dayStartTime) {
        startDate.setHours(0, 0, 0, 0);
      }
      if (this.getSecondsInDecimal(endDate) === dayEndTime) {
        endDate.setHours(24);
      }
      if (this.getSecondsInDecimal(endDate) === dayStartTime) {
        endDate.setHours(0, 0, 0, 0);
      }
    }
    return this.getTimeDifference(startDate, endDate) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
  };
  TaskProcessor.prototype.getSplitTaskLeft = function (sDate, segmentTaskStartDate) {
    var stDate = new Date(sDate.getTime());
    var tierViewMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    if (tierViewMode === 'Day') {
      var dayStartTime = void 0;
      var segmentStartTime = void 0;
      if (this.parent.weekWorkingTime.length > 0) {
        dayStartTime = this.parent['getStartTime'](stDate);
        segmentStartTime = this.parent['getStartTime'](segmentTaskStartDate);
      } else {
        segmentStartTime = dayStartTime = this.parent.defaultStartTime;
      }
      if (this.getSecondsInDecimal(stDate) === dayStartTime) {
        stDate.setHours(0, 0, 0, 0);
      }
      if (this.getSecondsInDecimal(segmentTaskStartDate) === segmentStartTime) {
        segmentTaskStartDate.setHours(0, 0, 0, 0);
      }
    }
    if (segmentTaskStartDate) {
      return (stDate.getTime() - segmentTaskStartDate.getTime()) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
    } else {
      return 0;
    }
  };
  /**
   *
   * @param {IGanttData} ganttData .
   * @param {string} fieldName .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateMappingData = function (ganttData, fieldName) {
    var columnMapping = this.parent.columnMapping;
    var ganttProp = ganttData.ganttProperties;
    if (isNullOrUndefined(columnMapping[fieldName]) && fieldName !== 'taskType' && fieldName !== 'segments') {
      return;
    }
    if (fieldName === 'predecessorName') ;else if (fieldName === 'resourceInfo') {
      var resourceData = ganttProp.resourceInfo;
      var resourceSettings = this.parent.resourceFields;
      // eslint-disable-next-line
      var resourcesId = [];
      var resourcesName = [];
      if (!isNullOrUndefined(resourceData)) {
        for (var i = 0; i < resourceData.length; i++) {
          resourcesId.push(resourceData[i][resourceSettings.id]);
          var resName = resourceData[i][resourceSettings.name];
          var resourceUnit = resourceData[i][resourceSettings.unit];
          if (resourceUnit !== 100) {
            resName += '[' + resourceUnit + '%' + ']';
          }
          if (resName) {
            resourcesName.push(resName);
          }
        }
      }
      this.parent.setRecordValue('resourceNames', resourcesName.join(','), ganttProp, true);
      if (this.isResourceString) {
        ganttData.taskData[this.parent.taskFields.resourceInfo] = ganttData.taskData[this.parent.taskFields.resourceInfo][0][resourceSettings.name];
        this.updateTaskDataResource(ganttData);
      } else {
        this.updateTaskDataResource(ganttData);
      }
      this.parent.setRecordValue(columnMapping[fieldName], resourcesName.join(','), ganttData);
    } else if (fieldName === 'startDate' || fieldName === 'endDate') {
      this.setRecordDate(ganttData, ganttProp[fieldName], columnMapping[fieldName]);
    } else if (fieldName === 'duration') {
      this.setRecordDuration(ganttData, columnMapping[fieldName]);
    } else if (fieldName === 'work') {
      this.parent.setRecordValue('taskData.' + columnMapping[fieldName], ganttProp.work, ganttData);
      this.parent.setRecordValue(columnMapping[fieldName], ganttProp[fieldName], ganttData);
    } else if (fieldName === 'type') {
      this.parent.setRecordValue('taskData.' + columnMapping[fieldName], ganttProp.taskType, ganttData);
      this.parent.setRecordValue(columnMapping[fieldName], ganttProp.taskType, ganttData);
    } else if (fieldName === 'manual') {
      this.parent.setRecordValue('taskData.' + columnMapping[fieldName], !ganttProp.isAutoSchedule, ganttData);
      this.parent.setRecordValue(columnMapping[fieldName], !ganttProp.isAutoSchedule, ganttData);
    } else if (fieldName === 'segments') {
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.segments, this.segmentTaskData(ganttData), ganttData);
    } else {
      this.parent.setRecordValue('taskData.' + columnMapping[fieldName], ganttProp[fieldName], ganttData);
      this.parent.setRecordValue(columnMapping[fieldName], ganttProp[fieldName], ganttData);
    }
  };
  TaskProcessor.prototype.segmentTaskData = function (data) {
    var _this = this;
    var segments = data.ganttProperties.segments;
    var taskSettings = this.parent.taskFields;
    if (isNullOrUndefined(segments)) {
      return null;
    }
    var taskData = extend([], [], data.taskData[taskSettings.segments], true);
    for (var i = 0; i < segments.length; i++) {
      if (this.parent.isEdit || this.parent.editModule && this.parent.editModule.dialogModule && getValue('isEdit', this.parent.editModule.dialogModule) || this.parent.contextMenuModule && getValue('isEdit', this.parent.contextMenuModule)) {
        taskData[i] = {};
      }
      if (!isNullOrUndefined(taskSettings.startDate)) {
        taskData[i][this.parent.taskFields.startDate] = segments[i].startDate;
      }
      if (!isNullOrUndefined(taskSettings.endDate)) {
        taskData[i][this.parent.taskFields.endDate] = segments[i].endDate;
      }
      if (!isNullOrUndefined(taskSettings.duration)) {
        taskData[i][this.parent.taskFields.duration] = Number(segments[i].duration);
      }
    }
    if (this.customSegmentProperties.length > 0 && taskData && taskData.length > 0) {
      taskData.forEach(function (task, index) {
        var mergedObject = Object.assign({}, task, _this.customSegmentProperties[index]);
        taskData[index] = mergedObject;
      });
    }
    return taskData;
  };
  /**
   * Method to update the task data resource values
   *
   * @param {IGanttData} ganttData .
   * @returns {void} .
   */
  TaskProcessor.prototype.updateTaskDataResource = function (ganttData) {
    var resourceData = ganttData.ganttProperties.resourceInfo;
    var preTaskResources = ganttData.taskData[this.parent.taskFields.resourceInfo];
    var resourceSettings = this.parent.resourceFields;
    if (isNullOrUndefined(preTaskResources)) {
      ganttData.taskData[this.parent.taskFields.resourceInfo] = resourceData;
    } else if (resourceData && resourceData.length) {
      for (var i = 0; i < resourceData.length; i++) {
        var isAdded = false;
        if (typeof preTaskResources === 'string') {
          if (typeof preTaskResources === 'string') {
            if (preTaskResources === resourceData[i][resourceSettings.name]) {
              preTaskResources = resourceData[i][resourceSettings.name];
              isAdded = true;
              break;
            }
          }
        } else {
          for (var j = 0; j < preTaskResources.length; j++) {
            if (typeof preTaskResources[j] === 'number' || typeof preTaskResources[j] === 'string') {
              if (parseInt(preTaskResources[j], 10) === parseInt(resourceData[i][resourceSettings.id], 10)) {
                preTaskResources[j] = resourceData[i];
                isAdded = true;
                break;
              }
            } else if (preTaskResources[j][resourceSettings.id] === resourceData[i][resourceSettings.id] && typeof preTaskResources[j] !== 'number') {
              preTaskResources[j] = extend({}, preTaskResources[j], resourceData[i], true);
              isAdded = true;
              break;
            }
          }
        }
        if (!isAdded) {
          if (typeof preTaskResources === 'string') {
            preTaskResources = resourceData[i][resourceSettings.name];
          } else {
            preTaskResources.push(resourceData[i]);
          }
        }
      }
      var data_1 = [];
      if (!isNullOrUndefined(preTaskResources)) {
        if (typeof preTaskResources !== 'string') {
          var _loop_4 = function (k) {
            resourceData.filter(function (resourceInfo) {
              if (resourceInfo[resourceSettings.id] === preTaskResources[k][resourceSettings.id] && data_1.indexOf(preTaskResources[k]) === -1) {
                data_1.push(preTaskResources[k]);
              }
            });
          };
          for (var k = 0; k < preTaskResources.length; k++) {
            _loop_4(k);
          }
          this.parent.setRecordValue('taskData.' + this.parent.taskFields.resourceInfo, data_1, ganttData);
        } else {
          this.parent.setRecordValue('taskData.' + this.parent.taskFields.resourceInfo, preTaskResources, ganttData);
        }
      }
    } else {
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.resourceInfo, [], ganttData);
    }
  };
  TaskProcessor.prototype.setRecordDate = function (task, value, mapping) {
    var tempDate = typeof value === 'string' ? new Date(value) : value;
    if (!isNullOrUndefined(value)) {
      value = new Date(tempDate.getTime());
    }
    this.parent.setRecordValue(mapping, value, task);
    if (!isNullOrUndefined(value)) {
      value = new Date(tempDate.getTime());
    }
    if (!this.parent.isLoad && !this.parent.isDynamicData) {
      this.parent.setRecordValue('taskData.' + mapping, value, task);
    }
    this.parent.isDynamicData = false;
  };
  TaskProcessor.prototype.getDurationInDay = function (duration, durationUnit) {
    if (durationUnit === 'day') {
      return duration;
    } else if (durationUnit === 'hour') {
      return duration / (this.parent.secondsPerDay / 3600);
    } else {
      return duration / (this.parent.secondsPerDay / 60);
    }
  };
  TaskProcessor.prototype.setRecordDuration = function (task, mapping) {
    var duration = task.ganttProperties.duration;
    var durationUnit = task.ganttProperties.durationUnit;
    if (!isNullOrUndefined(duration)) {
      this.parent.setRecordValue(mapping, task.ganttProperties.duration, task);
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      var durationValue = getValue(mapping, task.taskData);
      if (isNaN(durationValue) && isNullOrUndefined(this.parent.taskFields.durationUnit) && !isNullOrUndefined(durationValue)) {
        this.parent.setRecordValue('taskData.' + mapping, this.getDurationString(duration, durationUnit), task);
      } else {
        if (typeof durationValue === 'string') {
          this.parent.setRecordValue('taskData.' + mapping, duration.toString(), task);
        } else {
          this.parent.setRecordValue('taskData.' + mapping, duration, task);
        }
      }
    } else {
      this.parent.setRecordValue(mapping, duration, task);
      this.parent.setRecordValue('taskData.' + mapping, duration, task);
    }
    if (this.parent.taskFields.durationUnit) {
      task.taskData[this.parent.taskFields.durationUnit] = task.ganttProperties.durationUnit;
    }
  };
  TaskProcessor.prototype.setDataSource = function (data) {
    var dataArray;
    var createData = [];
    if (Array.isArray(data)) {
      dataArray = data;
    }
    var length = dataArray.length;
    for (var i = 0; i < length; i++) {
      var record = data[i];
      createData.push(record);
      if (!isNullOrUndefined(data[i][this.parent.taskFields.child])) {
        this.setDataSource(data[i][this.parent.taskFields.child]);
      }
    }
    return createData;
  };
  TaskProcessor.prototype.setStartDate = function (task) {
    var _this = this;
    var hierarchicalData = [];
    if (!isNullOrUndefined(this.parent.taskFields.parentID) && !isNullOrUndefined(this.parent.taskFields.id)) {
      hierarchicalData = this.setDataSource(this.parent.dataSource);
    } else {
      hierarchicalData = this.parent.dataSource;
    }
    hierarchicalData.map(function (record) {
      if (task.ganttProperties.taskId === record[_this.parent.taskFields.id]) {
        if (!isNullOrUndefined(_this.parent.taskFields.startDate)) {
          task[_this.parent.taskFields.startDate] = record[_this.parent.taskFields.startDate];
        }
        if (!isNullOrUndefined(_this.parent.taskFields.endDate)) {
          task[_this.parent.taskFields.endDate] = record[_this.parent.taskFields.endDate];
        }
      }
    });
  };
  TaskProcessor.prototype.getWorkInHour = function (work, workUnit) {
    if (workUnit === 'day') {
      return work * (this.parent.secondsPerDay / 3600);
    } else if (workUnit === 'minute') {
      return work / 60;
    } else {
      return work;
    }
  };
  /**
   *
   * @param {IGanttData} ganttData .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateTaskData = function (ganttData) {
    var dataMapping = this.parent.taskFields;
    var ganttProperties = ganttData.ganttProperties;
    if (!isNullOrUndefined(ganttData.taskData)) {
      var data = ganttData.taskData;
      if (dataMapping.id) {
        this.parent.setRecordValue('taskData.' + dataMapping.id, ganttProperties.taskId, ganttData);
        this.parent.setRecordValue(dataMapping.id, ganttProperties.taskId, ganttData);
      }
      if (dataMapping.name) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.name, ganttProperties.taskName, ganttData);
        }
        this.parent.setRecordValue(dataMapping.name, ganttProperties.taskName, ganttData);
      }
      if (dataMapping.startDate) {
        this.setRecordDate(ganttData, ganttProperties.startDate, dataMapping.startDate);
      }
      if (dataMapping.endDate) {
        this.setRecordDate(ganttData, ganttProperties.endDate, dataMapping.endDate);
      }
      if (dataMapping.duration) {
        if (isNullOrUndefined(dataMapping.milestone) || !isNullOrUndefined(dataMapping.milestone) && !ganttData.taskData[dataMapping.milestone]) {
          this.setRecordDuration(ganttData, dataMapping.duration);
        }
      }
      if (dataMapping.durationUnit) {
        data[dataMapping.durationUnit] = ganttProperties.durationUnit;
      }
      if (dataMapping.progress) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.progress, ganttProperties.progress, ganttData);
        }
        this.parent.setRecordValue(dataMapping.progress, ganttProperties.progress, ganttData);
      }
      if (dataMapping.baselineStartDate) {
        this.setRecordDate(ganttData, ganttProperties.baselineStartDate, dataMapping.baselineStartDate);
      }
      if (dataMapping.baselineEndDate) {
        this.setRecordDate(ganttData, ganttProperties.baselineEndDate, dataMapping.baselineEndDate);
      }
      if (dataMapping.notes) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.notes, ganttProperties.notes, ganttData);
        }
        this.parent.setRecordValue(dataMapping.notes, ganttProperties.notes, ganttData);
      }
      if (dataMapping.cssClass) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.cssClass, ganttProperties.cssClass, ganttData);
        }
        this.parent.setRecordValue(dataMapping.cssClass, ganttProperties.cssClass, ganttData);
      }
      if (dataMapping.indicators) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.indicators, ganttProperties.indicators, ganttData);
        }
        this.parent.setRecordValue(dataMapping.indicators, ganttProperties.indicators, ganttData);
      }
      if (dataMapping.parentID) {
        var parentID = this.parent.viewType === 'ProjectView' ? ganttProperties.parentId : data[dataMapping.parentID];
        this.parent.setRecordValue('taskData.' + dataMapping.parentID, parentID, ganttData);
        this.parent.setRecordValue(dataMapping.parentID, ganttProperties.parentId, ganttData);
      }
      if (dataMapping.work) {
        if (!this.parent.isLoad) {
          this.parent.setRecordValue('taskData.' + dataMapping.work, ganttProperties.work, ganttData);
        }
        this.parent.setRecordValue(dataMapping.work, ganttProperties.work, ganttData);
      }
      if (dataMapping.type) {
        this.parent.setRecordValue('taskData.' + dataMapping.type, ganttProperties.taskType, ganttData);
        this.parent.setRecordValue(dataMapping.type, ganttProperties.taskType, ganttData);
      }
    }
  };
  /**
   * To set resource value in Gantt record
   *
   * @param {object} data .
   * @returns {object[]} .
   * @private
   */
  TaskProcessor.prototype.setResourceInfo = function (data) {
    var _this = this;
    // eslint-disable-next-line
    var resourceIdCollection;
    if (isNullOrUndefined(data[this.parent.taskFields.resourceInfo])) {
      return resourceIdCollection;
    }
    if (this.parent.isLoad) {
      resourceIdCollection = data[this.parent.taskFields.resourceInfo];
    } else {
      resourceIdCollection = data['taskData'] && this.parent.taskFields.resourceInfo in data['taskData'] ? data['taskData'][this.parent.taskFields.resourceInfo] : data[this.parent.taskFields.resourceInfo];
    }
    var resourceData;
    if (!isNullOrUndefined(this.parent.editModule) && !isNullOrUndefined(this.parent.editModule.dialogModule) && this.parent.editModule.dialogModule.isAddNewResource) {
      resourceData = this.parent.editModule.dialogModule.ganttResources;
    } else {
      resourceData = this.parent.resources;
    }
    var resourceIDMapping = this.parent.resourceFields.id;
    var resourceUnitMapping = this.parent.resourceFields.unit;
    var resourceGroup = this.parent.resourceFields.group;
    var resources = [];
    if (typeof resourceIdCollection === 'string' && resourceIdCollection !== '') {
      var resource_1 = [];
      var resourceIds_1 = data[this.parent.taskFields.resourceInfo].split(',');
      if (resourceIds_1) {
        resourceData.forEach(function (resourceInfo) {
          if (resourceIds_1.includes(resourceInfo[_this.parent.resourceFields.name])) {
            resource_1.push(resourceInfo);
          }
        });
        var ganttDataResource = extend({}, resource_1[0]);
        resources.push(ganttDataResource);
        if (!isNullOrUndefined(resourceUnitMapping) && !isNullOrUndefined(resourceIdCollection[resourceUnitMapping])) {
          ganttDataResource[resourceUnitMapping] = resourceIdCollection[resourceUnitMapping];
        }
        if (!isNullOrUndefined(resourceGroup) && !isNullOrUndefined(resourceIdCollection[resourceGroup])) {
          ganttDataResource[resourceGroup] = resourceIdCollection[resourceGroup];
        }
      }
    } else {
      var _loop_5 = function (count) {
        var resource = resourceData.filter(function (resourceInfo) {
          if (typeof resourceIdCollection[count] === 'object' && resourceIdCollection[count][resourceIDMapping] === resourceInfo[resourceIDMapping]) {
            return true;
          } else {
            return resourceIdCollection[count] === resourceInfo[resourceIDMapping];
          }
        });
        var ganttDataResource = extend({}, resource[0]);
        resources.push(ganttDataResource);
        if (!isNullOrUndefined(resourceUnitMapping) && !isNullOrUndefined(resourceIdCollection[count][resourceUnitMapping])) {
          ganttDataResource[resourceUnitMapping] = resourceIdCollection[count][resourceUnitMapping];
        }
        if (!isNullOrUndefined(resourceGroup) && !isNullOrUndefined(resourceIdCollection[count][resourceGroup])) {
          ganttDataResource[resourceGroup] = resourceIdCollection[count][resourceGroup];
        }
      };
      for (var count = 0; count < resourceIdCollection.length; count++) {
        _loop_5(count);
      }
    }
    this.updateResourceUnit(resources);
    return resources;
  };
  /**
   * To set resource unit in Gantt record
   *
   * @param {object[]} resourceData .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateResourceUnit = function (resourceData) {
    var resourceUnit = this.parent.resourceFields.unit;
    if (!isNullOrUndefined(resourceUnit)) {
      var length_1 = resourceData.length;
      var index = void 0;
      for (index = 0; index < length_1; index++) {
        if (isNullOrUndefined(resourceData[index][resourceUnit])) {
          resourceData[index][resourceUnit] = 100;
        }
      }
    }
  };
  /**
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateResourceName = function (data) {
    var resourceInfo = data.ganttProperties.resourceInfo;
    var resourceName = [];
    var taskMapping = this.parent.taskFields;
    if (resourceInfo && resourceInfo.length > 0) {
      var resourceLength = resourceInfo.length;
      if (typeof data.taskData[this.parent.taskFields.resourceInfo] === 'string') {
        var taskResources = data.taskData[this.parent.taskFields.resourceInfo];
        var stringResourceName = taskResources;
        this.parent.setRecordValue('resourceNames', stringResourceName, data.ganttProperties, true);
        this.parent.setRecordValue(this.parent.taskFields.resourceInfo, stringResourceName, data, true);
        this.updateTaskDataResource(data);
      } else {
        var taskResources = extend([], [], data.taskData[this.parent.taskFields.resourceInfo], true);
        this.parent.setRecordValue('taskData.' + this.parent.taskFields.resourceInfo, [], data);
        for (var i = 0; i < resourceLength; i++) {
          var resource = resourceInfo[i];
          var resName = resource[this.parent.resourceFields.name];
          var resourceUnit = resource[this.parent.resourceFields.unit];
          if (resourceUnit !== 100) {
            resName += '[' + resourceUnit + '%' + ']';
          }
          if (!isNullOrUndefined(resName)) {
            resourceName.push(resName);
          }
          if (data.taskData) {
            var mapping = taskMapping.resourceInfo;
            if (taskResources[i] && typeof (taskResources[parseInt(i.toString(), 10)] === 'object')) {
              data.taskData[mapping].push(taskResources[i]);
            } else {
              data.taskData[mapping].push(resource[this.parent.resourceFields.id]);
            }
          }
        }
        this.parent.setRecordValue('resourceNames', resourceName.join(','), data.ganttProperties, true);
        this.parent.setRecordValue(this.parent.taskFields.resourceInfo, resourceName.join(','), data, true);
        this.updateTaskDataResource(data);
      }
    }
  };
  TaskProcessor.prototype.dataReorder = function (flatCollection, rootCollection) {
    var result = [];
    while (flatCollection.length > 0 && rootCollection.length > 0) {
      var index = rootCollection.indexOf(flatCollection[0]);
      if (index === -1) {
        flatCollection.shift();
      } else {
        result.push(flatCollection.shift());
        rootCollection.splice(index, 1);
      }
    }
    return result;
  };
  TaskProcessor.prototype.validateDurationUnitMapping = function (durationUnit) {
    var unit = durationUnit;
    if (unit === 'minute' || unit === 'minutes' || unit === 'm' || unit === 'min') {
      unit = 'minute';
    } else if (unit === 'hour' || unit === 'hours' || unit === 'h' || unit === 'hr') {
      unit = 'hour';
    } else if (unit === 'day' || unit === 'days' || unit === 'd') {
      unit = 'day';
    } else {
      unit = this.parent.durationUnit.toLocaleLowerCase();
    }
    return unit;
  };
  TaskProcessor.prototype.validateTaskTypeMapping = function (taskType) {
    var type = taskType;
    if (type === 'FixedDuration') {
      type = 'FixedDuration';
    } else if (type === 'FixedUnit') {
      type = 'FixedUnit';
    } else if (type === 'FixedWork') {
      type = 'FixedWork';
    } else {
      type = this.parent.taskType;
    }
    return type;
  };
  TaskProcessor.prototype.validateWorkUnitMapping = function (workUnit) {
    var unit = workUnit;
    if (unit === 'minute') {
      unit = 'minute';
    } else if (unit === 'hour') {
      unit = 'hour';
    } else if (unit === 'day') {
      unit = 'day';
    } else {
      unit = this.parent.workUnit.toLocaleLowerCase();
    }
    return unit;
  };
  /**
   * To update duration value in Task
   *
   * @param {string} duration .
   * @param {ITaskData} ganttProperties .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateDurationValue = function (duration, ganttProperties) {
    var tempDuration = this.getDurationValue(duration);
    if (!isNaN(getValue('duration', tempDuration))) {
      this.parent.setRecordValue('duration', getValue('duration', tempDuration), ganttProperties, true);
    }
    if (!isNullOrUndefined(getValue('durationUnit', tempDuration))) {
      this.parent.setRecordValue('durationUnit', getValue('durationUnit', tempDuration), ganttProperties, true);
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.reUpdateGanttData = function () {
    if (this.parent.flatData.length > 0) {
      var data = void 0;
      var ganttData = void 0;
      this.parent.secondsPerDay = this.getSecondsPerDay();
      for (var index = 0; index < this.parent.flatData.length; index++) {
        data = this.parent.flatData[index].taskData;
        ganttData = this.parent.flatData[index];
        if (!isNullOrUndefined(this.parent.taskFields.duration)) {
          this.setRecordDuration(ganttData, this.parent.taskFields.duration);
        }
        if (this.parent.isLoad) {
          this.setStartDate(ganttData);
        }
        this.calculateScheduledValues(ganttData, data, false);
      }
      this.updateGanttData();
    }
  };
  //check day is fall between from and to date range
  TaskProcessor.prototype._isInStartDateRange = function (day, from, to) {
    var isInRange = false;
    if (day.getTime() >= from.getTime() && day.getTime() < to.getTime()) {
      isInRange = true;
    }
    return isInRange;
  };
  //check day is fall between from and to date range
  TaskProcessor.prototype._isInEndDateRange = function (day, from, to) {
    var isInRange = false;
    if (day.getTime() > from.getTime() && day.getTime() <= to.getTime()) {
      isInRange = true;
    }
    return isInRange;
  };
  /**
   * Method to find overlapping value of the parent task
   *
   * @param {IGanttData} resourceTask .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateOverlappingValues = function (resourceTask) {
    var tasks = resourceTask.childRecords;
    var currentTask;
    var ranges = [];
    if (tasks.length <= 1) {
      resourceTask.ganttProperties.workTimelineRanges = [];
      return;
    }
    tasks = this.setSortedChildTasks(resourceTask);
    this.updateOverlappingIndex(tasks);
    for (var count = 1; count < tasks.length; count++) {
      currentTask = tasks[count];
      var cStartDate = void 0;
      var cEndDate = void 0;
      if (currentTask.ganttProperties.startDate) {
        cStartDate = new Date(currentTask.ganttProperties.startDate.getTime());
      }
      if (currentTask.ganttProperties.endDate) {
        cEndDate = new Date(currentTask.ganttProperties.endDate.getTime()); //task 2
      }
      var range = [];
      for (var index = 0; index < count; index++) {
        var tStartDate = tasks[index].ganttProperties.startDate;
        var tEndDate = tasks[index].ganttProperties.endDate; // task 1
        var rangeObj_1 = {};
        if (cStartDate && cEndDate && tEndDate && (this._isInStartDateRange(cStartDate, tStartDate, tEndDate) || this._isInEndDateRange(cEndDate, tStartDate, tEndDate))) {
          if (tStartDate.getTime() > cStartDate.getTime() && tStartDate.getTime() < cEndDate.getTime() && tEndDate.getTime() > cStartDate.getTime() && tEndDate.getTime() >= cEndDate.getTime() || cStartDate.getTime() === tStartDate.getTime() && cEndDate.getTime() <= tEndDate.getTime()) {
            rangeObj_1.from = tStartDate;
            rangeObj_1.to = cEndDate;
          } else if (cStartDate.getTime() === tStartDate.getTime() && cEndDate.getTime() > tEndDate.getTime()) {
            rangeObj_1.from = tStartDate;
            rangeObj_1.to = tEndDate;
          } else if (cStartDate.getTime() > tStartDate.getTime() && cEndDate.getTime() >= tEndDate.getTime()) {
            rangeObj_1.from = cStartDate;
            rangeObj_1.to = tEndDate;
          } else if (cStartDate.getTime() > tStartDate.getTime() && cEndDate.getTime() < tEndDate.getTime()) {
            rangeObj_1.from = cStartDate;
            rangeObj_1.to = cEndDate;
          }
          range.push(rangeObj_1);
        }
      }
      // eslint-disable-next-line
      ranges.push.apply(ranges, this.mergeRangeCollections(range));
    }
    this.parent.setRecordValue('workTimelineRanges', this.mergeRangeCollections(ranges, true), resourceTask.ganttProperties, true);
    this.calculateRangeLeftWidth(resourceTask.ganttProperties.workTimelineRanges);
  };
  /**
   * @param {IGanttData[]} tasks .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateOverlappingIndex = function (tasks) {
    for (var i = 0; i < tasks.length; i++) {
      tasks[i].ganttProperties.eOverlapIndex = i;
    }
  };
  /**
   * Method to calculate the left and width value of oarlapping ranges
   *
   * @param {IWorkTimelineRanges[]} ranges .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.calculateRangeLeftWidth = function (ranges) {
    for (var count = 0; count < ranges.length; count++) {
      ranges[count].left = this.getTaskLeft(ranges[count].from, false);
      ranges[count].width = this.getTaskWidth(ranges[count].from, ranges[count].to);
    }
  };
  /**
   * @param {IWorkTimelineRanges[]} ranges .
   * @param {boolean} isSplit .
   * @returns {IWorkTimelineRanges[]} .
   * @private
   */
  TaskProcessor.prototype.mergeRangeCollections = function (ranges, isSplit) {
    var finalRange = [];
    var currentTopRange = {};
    var cCompareRange = {};
    var cStartDate;
    var cEndDate;
    var tStartDate;
    var tEndDate;
    var sortedRanges = new DataManager(ranges).executeLocal(new Query().sortBy(this.parent.taskFields.startDate, 'Ascending'));
    for (var i = 0; i < sortedRanges.length; i++) {
      if (finalRange.length === 0 && i === 0) {
        finalRange.push(sortedRanges[i]);
        continue;
      }
      currentTopRange = finalRange[finalRange.length - 1];
      cStartDate = currentTopRange.from;
      cEndDate = currentTopRange.to;
      cCompareRange = sortedRanges[i];
      tStartDate = cCompareRange.from;
      tEndDate = cCompareRange.to;
      if (cStartDate.getTime() === tStartDate.getTime() && cEndDate.getTime() >= tEndDate.getTime() || cStartDate.getTime() < tStartDate.getTime() && cEndDate.getTime() >= tEndDate.getTime()) {
        continue;
      }
      /* eslint-disable-next-line */else if (cStartDate.getTime() <= tStartDate.getTime() && cEndDate.getTime() >= tStartDate.getTime() && cEndDate.getTime() < tEndDate.getTime() || cEndDate.getTime() < tStartDate.getTime() && this.checkStartDate(cEndDate).getTime() === tStartDate.getTime()) {
        currentTopRange.to = tEndDate;
      } else if (cEndDate.getTime() < tStartDate.getTime() && this.checkStartDate(cEndDate).getTime() !== tStartDate.getTime()) {
        finalRange.push(sortedRanges[i]);
      }
    }
    if (isSplit) {
      finalRange = this.splitRangeCollection(finalRange);
    }
    return finalRange;
  };
  /**
   * Sort resource child records based on start date
   *
   * @param {IGanttData} resourceTask .
   * @returns {IGanttData} .
   * @private
   */
  TaskProcessor.prototype.setSortedChildTasks = function (resourceTask) {
    var sortedRecords = [];
    sortedRecords = new DataManager(resourceTask.childRecords).executeLocal(new Query().sortBy(this.parent.taskFields.startDate, 'Ascending'));
    return sortedRecords;
  };
  TaskProcessor.prototype.splitRangeCollection = function (rangeCollection, fromField, toField) {
    var splitArray = [];
    var unit;
    if (this.parent.timelineModule.isSingleTier) {
      unit = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    } else {
      unit = this.parent.timelineModule.bottomTier;
    }
    if (unit === 'Week' || unit === 'Month' || unit === 'Year') {
      splitArray = rangeCollection;
    } else if (unit === 'Day') {
      splitArray = this.getRangeWithWeek(rangeCollection, fromField, toField);
    } else {
      if (this.parent.workingTimeRanges[0].from === 0 && this.parent.workingTimeRanges[0].to === 86400) {
        splitArray = this.getRangeWithWeek(rangeCollection, fromField, toField);
      } else {
        splitArray = this.getRangeWithDay(rangeCollection, fromField, toField);
      }
    }
    return splitArray;
  };
  TaskProcessor.prototype.getRangeWithDay = function (ranges, fromField, toField) {
    var splitArray = [];
    for (var i = 0; i < ranges.length; i++) {
      splitArray.push.apply(splitArray, this.splitRangeForDayMode(ranges[parseInt(i.toString(), 10)], fromField, toField));
    }
    return splitArray;
  };
  TaskProcessor.prototype.splitRangeForDayMode = function (range, fromField, toField) {
    var fromString = fromField ? fromField : 'from';
    var toString = toField ? toField : 'to';
    var tempStart = new Date(range[fromString]);
    var end = new Date(range[toString]);
    var ranges = [];
    var rangeObject = {};
    if (tempStart.getTime() < end.getTime()) {
      do {
        var nStart = new Date(tempStart.getTime());
        var nEndDate = new Date(tempStart.getTime());
        var nextAvailDuration = 0;
        var sHour = this.parent.dataOperation.getSecondsInDecimal(tempStart);
        var startRangeIndex = -1;
        for (var i = 0; i < this.parent.workingTimeRanges.length; i++) {
          var val = this.parent.workingTimeRanges[i];
          if (sHour >= val.from && sHour <= val.to) {
            startRangeIndex = i;
            break;
          }
        }
        if (startRangeIndex !== -1) {
          nextAvailDuration = Math.round(this.parent.workingTimeRanges[startRangeIndex].to - sHour);
          nEndDate.setSeconds(nEndDate.getSeconds() + nextAvailDuration);
        }
        var taskName = 'task';
        if (nEndDate.getTime() < end.getTime()) {
          rangeObject = {};
          if (range.task) {
            rangeObject[taskName] = extend([], range.task);
          }
          rangeObject[fromString] = nStart;
          rangeObject[toString] = nEndDate;
          rangeObject.isSplit = true;
          ranges.push(rangeObject);
        } else {
          rangeObject = {};
          if (range.task) {
            rangeObject[taskName] = extend([], range.task);
          }
          rangeObject[fromString] = nStart;
          rangeObject[toString] = end;
          rangeObject.isSplit = true;
          ranges.push(rangeObject);
        }
        tempStart = this.checkStartDate(nEndDate);
      } while (tempStart.getTime() < end.getTime());
    } else {
      ranges.push(range);
    }
    return ranges;
  };
  TaskProcessor.prototype.getRangeWithWeek = function (ranges, fromField, toField) {
    var splitArray = [];
    for (var i = 0; i < ranges.length; i++) {
      // eslint-disable-next-line
      splitArray.push.apply(splitArray, this.splitRangeForWeekMode(ranges[i], fromField, toField));
    }
    return splitArray;
  };
  TaskProcessor.prototype.splitRangeForWeekMode = function (range, fromField, toField) {
    var from = fromField ? fromField : 'from';
    var to = toField ? toField : 'to';
    var start = new Date(range[from]);
    var tempStart = new Date(range[from]);
    var end = new Date(range[to]);
    var isInSplit = false;
    var ranges = [];
    var rangeObj = {};
    tempStart.setDate(tempStart.getDate() + 1);
    if (tempStart.getTime() < end.getTime()) {
      do {
        if (this.parent.dataOperation.isOnHolidayOrWeekEnd(tempStart, null)) {
          var tempEndDate = new Date(tempStart.getTime());
          tempEndDate.setDate(tempStart.getDate() - 1);
          var dayEndTime = void 0;
          if (this.parent.weekWorkingTime.length > 0) {
            dayEndTime = this.parent['getEndTime'](tempEndDate);
          } else {
            dayEndTime = this.parent.defaultEndTime;
          }
          this.setTime(dayEndTime, tempEndDate);
          rangeObj = {};
          rangeObj[from] = start;
          rangeObj.isSplit = true;
          rangeObj[to] = new Date(tempEndDate);
          if (range.task) {
            rangeObj.task = extend([], range.task, true);
          }
          var isStartNotHolidayOrWeekend = !this.parent.dataOperation.isOnHolidayOrWeekEnd(start, null);
          var isTempEndDateNotHolidayOrWeekend = !this.parent.dataOperation.isOnHolidayOrWeekEnd(tempEndDate, null);
          var isDifferentDates = start.getTime() !== tempEndDate.getTime();
          if (isStartNotHolidayOrWeekend && isTempEndDateNotHolidayOrWeekend && isDifferentDates) {
            ranges.push(rangeObj);
          }
          tempEndDate.setDate(tempEndDate.getDate() + 1);
          start = this.checkStartDate(tempEndDate);
          tempStart = new Date(start.getTime());
          isInSplit = true;
        } else {
          tempStart.setDate(tempStart.getDate() + 1);
        }
      } while (tempStart.getTime() < end.getTime());
      if (isInSplit) {
        if (start.getTime() !== end.getTime()) {
          rangeObj = {};
          if (range.task) {
            rangeObj.task = extend([], range.task, true);
          }
          rangeObj[from] = start;
          rangeObj[to] = end;
          rangeObj.isSplit = true;
          ranges.push(rangeObj);
        }
      } else {
        ranges.push(range);
      }
    } else {
      ranges.push(range);
    }
    return ranges;
  };
  /**
   * Update all gantt data collection width, progress width and left value
   *
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateGanttData = function () {
    var flatData = this.parent.flatData;
    var length = flatData.length;
    this.systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    for (var i = 0; i < length; i++) {
      var data = flatData[i];
      this.updateTaskLeftWidth(data);
    }
  };
  /**
   * Update all gantt data collection width, progress width and left value
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @public
   */
  TaskProcessor.prototype.updateTaskLeftWidth = function (data) {
    var task = data.ganttProperties;
    if (!data.hasChildRecords || !this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      this.updateWidthLeft(data);
    }
    this.parent.setRecordValue('baselineLeft', this.calculateBaselineLeft(task), task, true);
    this.parent.setRecordValue('baselineWidth', this.calculateBaselineWidth(task), task, true);
    var childData = [];
    var parentItem;
    if (data.parentItem) {
      parentItem = this.parent.getParentTask(data.parentItem);
      childData = parentItem.childRecords;
    }
    if (parentItem && childData.indexOf(data) === childData.length - 1 && !data.hasChildRecords && this.parent.enableValidation) {
      if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) || this.parent.viewType === 'ResourceView') {
        this.updateParentItems(parentItem);
      }
      if (!this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
        this.updateWidthLeft(parentItem);
      }
    } else if (parentItem && !this.parent.enableValidation) {
      this.updateWidthLeft(parentItem);
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.reUpdateGanttDataPosition = function () {
    var flatData;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      flatData = this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData'];
    } else {
      flatData = this.parent.flatData;
    }
    var length = flatData.length;
    for (var i = 0; i < length; i++) {
      var data = flatData[i];
      var task = data.ganttProperties;
      this.updateWidthLeft(data);
      if (this.parent.taskMode !== 'Auto' && data.hasChildRecords) {
        this.updateAutoWidthLeft(data);
      }
      this.parent.setRecordValue('baselineLeft', this.calculateBaselineLeft(task), task, true);
      this.parent.setRecordValue('baselineWidth', this.calculateBaselineWidth(task), task, true);
      this.parent.dataOperation.updateTaskData(data);
    }
  };
  /**
   * method to update left, width, progress width in record
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateWidthLeft = function (data) {
    var ganttRecord = data.ganttProperties;
    var totalSegmentsProgressWidth = 0;
    // task endDate may be changed in segment calculation so this must be calculated first.
    // task width calculating was based on endDate
    var progressResizer = 0;
    if (!isNullOrUndefined(ganttRecord.segments) && ganttRecord.segments.length > 0) {
      var segments = ganttRecord.segments;
      for (var i = 0; i < segments.length; i++) {
        var segment = segments[i];
        if (i === 0 && !isNullOrUndefined(ganttRecord.startDate) && !isNullOrUndefined(segment.startDate) && !isNullOrUndefined(segment.startDate.getTime()) && segment.startDate.getTime() !== ganttRecord.startDate.getTime()) {
          segment.startDate = ganttRecord.startDate;
          var endDate = this.parent.dataOperation.getEndDate(segment.startDate, segment.duration, ganttRecord.durationUnit, ganttRecord, false);
          segment.endDate = this.parent.dataOperation.checkEndDate(endDate, ganttRecord, false);
          this.parent.chartRowsModule.incrementSegments(segments, 0, data);
        }
        segment.width = this.getSplitTaskWidth(segment.startDate, segment.duration, data);
        totalSegmentsProgressWidth = totalSegmentsProgressWidth + segment.width;
        segment.showProgress = false;
        segment.progressWidth = -1;
        if (i !== 0) {
          var pStartDate = new Date(ganttRecord.startDate.getTime());
          segment.left = this.getSplitTaskLeft(segment.startDate, pStartDate);
        }
      }
      var setProgress = this.parent.dataOperation.getProgressWidth(totalSegmentsProgressWidth, ganttRecord.progress);
      var isValid = true;
      for (var i = 0; i < segments.length; i++) {
        if (isValid) {
          if (setProgress <= segments[i].width) {
            segments[i].progressWidth = setProgress;
            segments[i].showProgress = true;
            isValid = false;
          } else {
            segments[i].progressWidth = segments[i].width;
            setProgress = setProgress - segments[i].progressWidth;
          }
          if (segments[i].showProgress) {
            progressResizer = progressResizer + segments[i].left + segments[i].progressWidth;
          }
        }
      }
      this.parent.setRecordValue('segments', ganttRecord.segments, ganttRecord, true);
      this.parent.dataOperation.updateMappingData(data, 'segments');
    }
    this.parent.setRecordValue('width', this.parent.dataOperation.calculateWidth(data), ganttRecord, true);
    this.parent.setRecordValue('left', this.parent.dataOperation.calculateLeft(ganttRecord), ganttRecord, true);
    if (!isNullOrUndefined(ganttRecord.segments) && ganttRecord.segments.length > 0) {
      this.parent.setRecordValue('progressWidth', progressResizer, ganttRecord, true);
    } else {
      this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(ganttRecord.isAutoSchedule || !data.hasChildRecords ? ganttRecord.width : ganttRecord.autoWidth, ganttRecord.progress), ganttRecord, true);
    }
  };
  /**
   * method to update left, width, progress width in record
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateAutoWidthLeft = function (data) {
    var ganttRecord = data.ganttProperties;
    this.parent.setRecordValue('autoWidth', this.calculateWidth(data, true), ganttRecord, true);
    this.parent.setRecordValue('autoLeft', this.calculateLeft(ganttRecord, true), ganttRecord, true);
    this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(ganttRecord.isAutoSchedule || !data.hasChildRecords ? ganttRecord.width : ganttRecord.autoWidth, ganttRecord.progress), ganttRecord, true);
  };
  /**
   * To calculate parent progress value
   *
   * @param {IGanttData} childGanttRecord .
   * @returns {object} .
   * @private
   */
  TaskProcessor.prototype.getParentProgress = function (childGanttRecord) {
    var durationInDay = 0;
    var progressValues = {};
    var totSeconds;
    if (this.parent.weekWorkingTime.length > 0) {
      totSeconds = this.parent['getSecondsPerDay'](childGanttRecord.ganttProperties.startDate ? childGanttRecord.ganttProperties.startDate : childGanttRecord.ganttProperties.endDate);
    } else {
      totSeconds = this.parent.secondsPerDay;
    }
    switch (childGanttRecord.ganttProperties.durationUnit) {
      case 'hour':
        durationInDay = childGanttRecord.ganttProperties.duration / (totSeconds / 3600);
        break;
      case 'minute':
        durationInDay = childGanttRecord.ganttProperties.duration / (totSeconds / 60);
        break;
      default:
        durationInDay = childGanttRecord.ganttProperties.duration;
    }
    if (childGanttRecord.hasChildRecords) {
      setValue('totalProgress', childGanttRecord.ganttProperties.totalProgress, progressValues);
      setValue('totalDuration', childGanttRecord.ganttProperties.totalDuration ? childGanttRecord.ganttProperties.totalDuration : 0, progressValues);
    } else {
      setValue('totalProgress', childGanttRecord.ganttProperties.progress * durationInDay, progressValues);
      setValue('totalDuration', durationInDay, progressValues);
    }
    return progressValues;
  };
  TaskProcessor.prototype.resetDependency = function (record) {
    var dependency = this.parent.taskFields.dependency;
    if (!isNullOrUndefined(dependency)) {
      var recordProp = record.ganttProperties;
      this.parent.setRecordValue('predecessor', [], recordProp, true);
      this.parent.setRecordValue('predecessorsName', null, recordProp, true);
      this.parent.setRecordValue('taskData.' + dependency, null, record);
      this.parent.setRecordValue(dependency, null, record);
    }
  };
  TaskProcessor.prototype.isUnscheduledTask = function (ganttProperties, parantData) {
    var properties = ['startDate', 'endDate', 'duration'];
    var count = 0;
    var filledProperty = null;
    for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
      var prop = properties_1[_i];
      if (ganttProperties && ganttProperties[prop]) {
        count++;
        filledProperty = prop;
      }
    }
    if (count === 1 || count === 0 && !isNullOrUndefined(parantData) && parantData.hasChildRecords) {
      return [true, filledProperty];
    } else {
      return [false, null];
    }
  };
  TaskProcessor.prototype.isFromManual = function (childData) {
    if (this.parent.allowUnscheduledTasks && this.parent.editModule && this.parent['oldRecords'] && this.parent['oldRecords'].length > 0 && isNullOrUndefined(childData.ganttProperties.startDate) && isNullOrUndefined(childData.ganttProperties.endDate) && !isNullOrUndefined(childData.ganttProperties.duration) && !childData.hasChildRecords && childData.parentItem && (this.parent.taskMode === 'Manual' || this.parent.taskMode === 'Custom' && childData[this.parent.taskFields.manual])) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * @param {IParent | IGanttData} cloneParent .
   * @param {boolean} isParent .
   * @returns {void} .
   * @private
   */
  TaskProcessor.prototype.updateParentItems = function (cloneParent, isParent) {
    var _this = this;
    var parentData = isParent ? cloneParent : this.parent.getParentTask(cloneParent);
    parentData = parentData ? parentData : cloneParent;
    var deleteUpdate = false;
    var ganttProp = !isNullOrUndefined(parentData) ? parentData.ganttProperties : null;
    /* eslint-disable-next-line */
    var _a = this.isUnscheduledTask(ganttProp, parentData),
      isParentUnschecule = _a[0],
      propertyWithValue = _a[1];
    if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) || this.parent.viewType === 'ResourceView') {
      if (parentData && parentData.childRecords && parentData.childRecords.length > 0) {
        var previousStartDate = ganttProp.isAutoSchedule ? ganttProp.startDate : ganttProp.autoStartDate;
        var previousEndDate = ganttProp.isAutoSchedule ? ganttProp.endDate : ganttProp.autoEndDate;
        var childRecords_1 = parentData.childRecords;
        var childLength = childRecords_1.length;
        var totalDuration = 0;
        var progressValues = {};
        var minStartDate = null;
        var maxEndDate = null;
        var milestoneCount = 0;
        var totalProgress = 0;
        var childCompletedWorks = 0;
        var childData_1;
        var countOfScheduled_1 = 0;
        var countOfUnScheduled_1 = 0;
        childRecords_1.some(function (childRecord) {
          var _a = _this.isUnscheduledTask(childRecord['ganttProperties']),
            isUnscheduled = _a[0],
            propertyWithValue = _a[1];
          if (isUnscheduled && propertyWithValue === 'duration') {
            ++countOfUnScheduled_1;
          } else if (!isUnscheduled) {
            ++countOfScheduled_1;
          }
          if (countOfScheduled_1 > 0 && countOfUnScheduled_1 > 0) {
            return true;
          }
          return false;
        });
        var isChildBoth = countOfScheduled_1 > 0 && countOfUnScheduled_1 > 0 ? true : undefined;
        var _loop_6 = function (count) {
          childData_1 = this_4.parent.loadChildOnDemand && this_4.parent.taskFields.hasChildMapping ? this_4.parent.currentViewData.filter(function (item) {
            return item.ganttProperties.taskId === childRecords_1[count][_this.parent.taskFields.id];
          })[0] : childRecords_1[count];
          if (this_4.parent.isOnDelete && childData_1.isDelete) {
            if (childLength === 1 && this_4.parent.viewType === 'ProjectView') {
              deleteUpdate = true;
            }
            return "continue";
          }
          var startDate = void 0;
          var endDate = void 0;
          var _a = this_4.isUnscheduledTask(childData_1.ganttProperties),
            isUnscheduled = _a[0],
            propertyWithValue_1 = _a[1];
          var parentRec = void 0;
          if (this_4.isFromManual(childData_1)) {
            var ganttRec = this_4.parent['oldRecords'].filter(function (record) {
              return record.ganttProperties.uniqueID === childData_1.ganttProperties.uniqueID;
            })[0];
            if (ganttRec) {
              parentRec = this_4.parent.getParentTask(ganttRec.parentItem);
            }
          }
          var rec = parentRec ? parentRec : childData_1;
          var prop = parentRec ? parentRec.ganttProperties : ganttProp;
          startDate = isUnscheduled && propertyWithValue_1 !== 'startDate' && propertyWithValue_1 !== 'endDate' && !isParentUnschecule ? ganttProp.startDate : this_4.getValidStartDate(rec.ganttProperties);
          if (parentData.hasChildRecords && !prop.isAutoSchedule && !isNullOrUndefined(rec.ganttProperties.autoStartDate)) {
            startDate = rec.ganttProperties.autoStartDate;
          }
          endDate = isUnscheduled && propertyWithValue_1 !== 'startDate' && propertyWithValue_1 !== 'endDate' && !isParentUnschecule ? ganttProp.endDate : this_4.getValidEndDate(rec.ganttProperties);
          if (parentData.hasChildRecords && !prop.isAutoSchedule && !isNullOrUndefined(rec.ganttProperties.autoEndDate)) {
            endDate = rec.ganttProperties.autoEndDate;
          }
          if (isNullOrUndefined(minStartDate) && (isChildBoth && !isUnscheduled || isNullOrUndefined(isChildBoth))) {
            minStartDate = this_4.getDateFromFormat(startDate);
          }
          if (isNullOrUndefined(maxEndDate) && (isChildBoth && !isUnscheduled || isNullOrUndefined(isChildBoth))) {
            maxEndDate = this_4.getDateFromFormat(endDate);
          }
          if (!isNullOrUndefined(endDate) && maxEndDate && this_4.compareDates(endDate, maxEndDate) === 1) {
            maxEndDate = this_4.getDateFromFormat(endDate);
          }
          if (!isNullOrUndefined(startDate) && minStartDate && this_4.compareDates(startDate, minStartDate) === -1) {
            minStartDate = this_4.getDateFromFormat(startDate);
          }
          var condition = !childData_1.ganttProperties.isMilestone && isScheduledTask(childData_1.ganttProperties) ? true : false;
          if (condition) {
            progressValues = this_4.getParentProgress(childData_1);
            totalProgress += getValue('totalProgress', progressValues);
            if (childData_1[this_4.parent.taskFields.duration] < 1) {
              totalDuration += getValue('totalDuration', progressValues);
              totalDuration = Number(totalDuration.toFixed(4));
            } else {
              totalDuration += getValue('totalDuration', progressValues);
            }
          } else {
            milestoneCount++;
          }
          var work = childData_1.ganttProperties.work;
          if (typeof work === 'string') {
            // If it's a string, convert it to a number
            var numericValue = parseFloat(work);
            if (!isNaN(numericValue)) {
              childCompletedWorks += numericValue;
            }
          } else if (typeof work === 'number') {
            // If it's already a number, simply add it to childCompletedWorks
            childCompletedWorks += work;
          }
        };
        var this_4 = this;
        for (var count = 0; count < childLength; count++) {
          _loop_6(count);
        }
        if (!deleteUpdate) {
          var taskCount = void 0;
          if (this.parent.isOnDelete && childData_1.isDelete) {
            taskCount = childLength - milestoneCount - 1;
          } else {
            taskCount = childLength - milestoneCount;
          }
          var parentProgress = taskCount > 0 && totalDuration > 0 ? Number((totalProgress / totalDuration).toFixed(2)) : 0;
          var parentProp = parentData.ganttProperties;
          var milestone = taskCount === 0 && minStartDate && maxEndDate && minStartDate.getTime() === maxEndDate.getTime() ? true : false;
          if (this.compareDates(previousStartDate, minStartDate) !== 0) {
            this.parent.setRecordValue(ganttProp.isAutoSchedule ? 'startDate' : 'autoStartDate', minStartDate, parentData.ganttProperties, true);
            if ((!isNullOrUndefined(ganttProp.autoDuration) ? ganttProp.autoDuration === 0 : ganttProp.duration === 0) && parentData['isManual'] && milestone && parentData.hasChildRecords && parentData.ganttProperties.isAutoSchedule && this.parent.editModule.taskbarEditModule.taskbarEditedArgs.action !== 'TaskbarEditing') {
              this.parent.setRecordValue('startDate', minStartDate, parentData.ganttProperties, true);
            }
          }
          if (this.compareDates(previousEndDate, maxEndDate) !== 0) {
            this.parent.setRecordValue(ganttProp.isAutoSchedule ? 'endDate' : 'autoEndDate', maxEndDate, parentData.ganttProperties, true);
            if ((!isNullOrUndefined(ganttProp.autoDuration) ? ganttProp.autoDuration === 0 : ganttProp.duration === 0) && parentData['isManual'] && milestone && parentData.hasChildRecords && parentData.ganttProperties.isAutoSchedule && this.parent.editModule.taskbarEditModule.taskbarEditedArgs.action !== 'TaskbarEditing') {
              this.parent.setRecordValue('endDate', maxEndDate, parentData.ganttProperties, true);
            }
          }
          this.parent.setRecordValue('isMilestone', milestone, parentProp, true);
          if (!isNullOrUndefined(this.parent.taskFields.milestone)) {
            this.updateMappingData(parentData, 'milestone');
            this.parent.setRecordValue(this.parent.taskFields.milestone, milestone, parentData, true);
            this.parent.setRecordValue('taskData.' + this.parent.taskFields.milestone, milestone, parentData, true);
          }
          if (parentProp.isAutoSchedule) {
            if (this.isFromManual(childData_1)) {
              if (parentData.childRecords.length === 1) {
                parentData.ganttProperties.duration = childData_1.ganttProperties.duration;
              }
              this.calculateEndDate(parentData);
            } else {
              this.calculateDuration(parentData);
            }
          }
          this.updateWorkWithDuration(parentData);
          var parentWork = parentProp.work;
          parentWork = this.parent.isOnEdit ? parentWork : parentWork + childCompletedWorks;
          this.parent.setRecordValue('work', parentWork, parentProp, true);
          this.parent.setRecordValue('taskType', 'FixedDuration', parentProp, true);
          if (!isNullOrUndefined(this.parent.taskFields.type)) {
            this.updateMappingData(parentData, 'type');
          }
          this.parent.setRecordValue('progress', Math.floor(parentProgress), parentProp, true);
          this.parent.setRecordValue('totalProgress', totalProgress, parentProp, true);
          this.parent.setRecordValue('totalDuration', totalDuration, parentProp, true);
          this.parent.setRecordValue('autoDuration', parentProp.duration, parentProp, true);
          if (!parentProp.isAutoSchedule) {
            this.parent.setRecordValue('autoDuration', this.calculateAutoDuration(parentProp), parentProp, true);
            this.updateAutoWidthLeft(parentData);
          }
          if (!this.parent.allowParentDependency) {
            this.resetDependency(parentData);
          }
          this.updateWidthLeft(parentData);
          this.updateTaskData(parentData);
        }
      }
    } else {
      this.parent.setRecordValue('endDate', parentData.taskData[this.parent.taskFields.endDate], parentData.ganttProperties, true);
    }
    if (deleteUpdate && parentData.childRecords.length === 1 && parentData.ganttProperties.duration === 0) {
      this.parent.setRecordValue('isMilestone', true, parentData.ganttProperties, true);
      this.updateWidthLeft(parentData);
      this.updateTaskData(parentData);
    }
    var parentItem = !isNullOrUndefined(parentData) ? this.parent.getParentTask(parentData.parentItem) : null;
    if (parentItem) {
      if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) || this.parent.viewType === 'ResourceView') {
        this.updateParentItems(parentItem);
      }
    }
    deleteUpdate = false;
  };
  return TaskProcessor;
}(DateProcessor);

/**
 * CSS Constants
 */
/** @hidden */
var root = 'e-gantt';
var ganttChartPane = 'e-gantt-chart-pane';
var treeGridPane = 'e-gantt-tree-grid-pane';
var splitter = 'e-gantt-splitter';
var ganttChart = 'e-gantt-chart';
var ganttSingleTimeline = 'e-gantt-single-timeline';
var chartBodyContainer = 'e-chart-root-container';
var toolbar = 'e-gantt-toolbar';
var chartScrollElement = 'e-chart-scroll-container';
var chartBodyContent = 'e-chart-rows-container';
var scrollContent = 'e-content';
var adaptive = 'e-device';
var focusCell = 'e-grid';
// Timeline-Class
var taskTable = 'e-task-table';
var zeroSpacing = 'e-zero-spacing';
var timelineHeaderContainer = 'e-timeline-header-container';
var timelineHeaderTableContainer = 'e-timeline-header-table-container';
var timelineHeaderTableBody = 'e-timeline-header-table-body';
var timelineTopHeaderCell = 'e-timeline-top-header-cell';
var timelineHeaderCellLabel = 'e-header-cell-label';
var weekendHeaderCell = 'e-weekend-header-cell';
var timelineSingleHeaderCell = 'e-timeline-single-header-cell';
var virtualTable = 'e-virtualtable';
var virtualTrack = 'e-virtualtrack';
// Chart Rows-Class
var leftLabelContainer = 'e-left-label-container';
var leftLabelTempContainer = 'e-left-label-container e-left-label-temp-container';
var leftLabelInnerDiv = 'e-left-label-inner-div';
var rightLabelContainer = 'e-right-label-container';
var rightLabelTempContainer = 'e-right-label-container e-right-label-temp-container';
var rightLabelInnerDiv = 'e-right-label-inner-div';
var taskBarMainContainer = 'e-taskbar-main-container';
var parentTaskBarInnerDiv = 'e-gantt-parent-taskbar-inner-div';
var parentProgressBarInnerDiv = 'e-gantt-parent-progressbar-inner-div';
var taskLabel = 'e-task-label';
var childTaskBarInnerDiv = 'e-gantt-child-taskbar-inner-div';
var childProgressBarInnerDiv = 'e-gantt-child-progressbar-inner-div';
var criticalChildTaskBarInnerDiv = 'e-gantt-child-critical-taskbar-inner-div';
var criticalChildProgressBarInnerDiv = 'e-gantt-child-critical-progressbar-inner-div';
var criticalMilestone = 'e-critical-milestone';
var baselineBar = 'e-baseline-bar';
var baselineMilestoneContainer = 'e-baseline-gantt-milestone-container';
var chartRowCell = 'e-chart-row-cell';
var chartRow = 'e-chart-row';
var rowExpand = 'e-row-expand';
var rowCollapse = 'e-row-collapse';
var taskBarLeftResizer = 'e-taskbar-left-resizer';
var taskBarRightResizer = 'e-taskbar-right-resizer';
var childProgressResizer = 'e-child-progress-resizer';
var progressBarHandler = 'e-progressbar-handler';
var progressHandlerElement = 'e-progressbar-handler-element';
var progressBarHandlerAfter = 'e-progressbar-handler-after';
var icon = 'e-icon';
var traceMilestone = 'e-gantt-milestone';
var parentMilestone = 'e-gantt-parent-milestone';
var traceChildTaskBar = 'e-gantt-child-taskbar';
var traceChildProgressBar = 'e-gantt-child-progressbar';
var manualParentMainContainer = 'e-manualparent-main-container';
var manualParentTaskBar = 'e-gantt-manualparenttaskbar';
var manualParentMilestone = 'e-gantt-manualparent-milestone';
var manualChildTaskBar = 'e-gantt-child-manualtaskbar';
var manualChildProgressBar = 'e-gantt-child-manualprogressbar';
var manualParentRightResizer = 'e-gantt-manualparenttaskbar-right';
var manualParentLeftResizer = 'e-gantt-manualparenttaskbar-left';
var traceManualUnscheduledTask = 'e-gantt-unscheduled-manualtask';
var traceParentTaskBar = 'e-gantt-parent-taskbar';
var traceParentProgressBar = 'e-gantt-parent-progressbar';
var traceUnscheduledTask = 'e-gantt-unscheduled-task';
var criticalUnscheduledTask = 'e-gantt-critical-unscheduled-taskbar';
var taskIndicatorDiv = 'e-indicator-span';
var leftResizeGripper = 'e-left-resize-gripper';
var rightResizeGripper = 'e-right-resize-gripper';
var progressResizeGripper = 'e-progress-resize-gripper';
var label = 'e-label';
//event Markers classes
var eventMarkersContainer = 'e-event-markers-container';
var eventMarkersChild = 'e-event-markers';
var eventMarkersSpan = 'e-span-label';
//holiday classes
var nonworkingContainer = 'e-nonworking-day-container';
var holidayContainer = 'e-holiday-container';
var holidayElement = 'e-holiday';
var holidayLabel = 'e-span';
//weekend classes
var weekendContainer = 'e-weekend-container';
var weekend = 'e-weekend';
//Unscheduled Taskbar
var unscheduledTaskbarLeft = 'e-gantt-unscheduled-taskbar-left';
var unscheduledTaskbarRight = 'e-gantt-unscheduled-taskbar-right';
var unscheduledTaskbar = 'e-gantt-unscheduled-taskbar';
//Connector Line
var dependencyViewContainer = 'e-gantt-dependency-view-container';
var connectorLineContainer = 'e-connector-line-container';
var connectorLineSVG = 'e-connector-line';
var criticalConnectorLineSVG = 'e-critical-line';
var criticalConnectorArrowSVG = 'e-critical-line-arrow';
var connectorLineArrow = 'e-connector-line-arrow';
var connectorLineZIndex = 'e-connector-line-z-index';
var connectorPointLeft = 'e-connectorpoint-left';
var connectorPointRight = 'e-connectorpoint-right';
var connectorPointLeftHover = 'e-connectorpoint-left-hover';
var connectorPointRightHover = 'e-connectorpoint-right-hover';
var falseLine = 'e-gantt-false-line';
var rightConnectorPointOuterDiv = 'e-right-connectorpoint-outer-div';
var leftConnectorPointOuterDiv = 'e-left-connectorpoint-outer-div';
var connectorPointAllowBlock = 'e-connectorpoint-allow-block';
var ganttTooltip = 'e-gantt-tooltip';
// Context Menu
var columnHeader = '.e-gridheader';
var content = '.e-content';
var editForm = '.e-gridform';
var deleteIcon = 'e-delete';
var saveIcon = 'e-save';
var cancelIcon = 'e-cancel';
var editIcon = 'e-edit';
var indentIcon = 'e-indent';
var outdentIcon = 'e-outdent';
var addIcon = 'e-add';
var addAboveIcon = 'e-add-above';
var addBelowIcon = 'e-add-below';
//Predecessor touch mode
var activeParentTask = 'e-active-parent-task';
var activeChildTask = 'e-active-child-task';
var activeConnectedTask = 'e-active-connected-task';
var touchMode = 'e-predecessor-touch-mode';
//Over allocation range container
var rangeContainer = 'e-rangecontainer';
var rangeChildContainer = 'e-rg-rangdiv';

/**
 * To handle scroll event on chart and from TreeGrid
 *
 * @hidden
 */
var ChartScroll = /** @__PURE__ @class */function () {
  /**
   * Constructor for the scrolling.
   *
   * @param {Gantt} parent .
   * @hidden
   */
  function ChartScroll(parent) {
    this.previousCount = -1;
    this.isSetScrollLeft = false;
    this.previousScroll = {
      top: 0,
      left: 0
    };
    this.parent = parent;
    this.element = this.parent.ganttChartModule.scrollElement;
    this.addEventListeners();
  }
  /**
   * Bind event
   *
   * @returns {void} .
   */
  ChartScroll.prototype.addEventListeners = function () {
    this.parent.on('grid-scroll', this.gridScrollHandler, this);
    EventHandler.add(this.element, 'scroll', this.onScroll, this);
    this.parent.treeGrid.grid.on('showGanttShimmer', this.updateShimmer, this);
    this.parent.treeGrid.grid.on('removeGanttShimmer', this.removeShimmer, this);
    this.parent.treeGrid.grid.on('virtualTransform', this.transformChange, this);
  };
  /**
   * Unbind events
   *
   * @returns {void} .
   */
  ChartScroll.prototype.removeEventListeners = function () {
    EventHandler.remove(this.element, 'scroll', this.onScroll);
    this.parent.off('grid-scroll', this.gridScrollHandler);
    this.parent.treeGrid.grid.off('showGanttShimmer', this.updateShimmer);
    this.parent.treeGrid.grid.off('removeGanttShimmer', this.removeShimmer);
    this.parent.treeGrid.grid.off('virtualTransform', this.transformChange);
  };
  /**
   *
   * @param {object} args .
   * @returns {void} .
   */
  ChartScroll.prototype.gridScrollHandler = function (args) {
    this.element.scrollTop = getValue('top', args);
    this.isFromTreeGrid = true;
  };
  /**
   * Method to update vertical grid line, holiday, event markers and weekend container's top position on scroll action
   *
   * @returns {void} .
   * @private
   */
  ChartScroll.prototype.updateContent = function () {
    var ganttElement = this.parent.element;
    var currentCount = Math.round(this.element.scrollLeft / ganttElement.offsetWidth);
    if (this.previousCount !== currentCount || this.parent.timelineModule['performedTimeSpanAction']) {
      this.deleteTableElements();
      this.parent.timelineModule.createTimelineSeries();
      if (this.parent.gridLines === 'Vertical' || this.parent.gridLines === 'Both') {
        this.parent['renderChartVerticalLines']();
      }
      if (this.parent.dayMarkersModule) {
        this.parent.dayMarkersModule['eventMarkerRender'].renderEventMarkers();
      }
      this.parent.timelineModule['performedTimeSpanAction'] = false;
      if (this.parent.dayMarkersModule) {
        this.parent.dayMarkersModule.nonworkingDayRender.renderWeekends();
        this.parent.dayMarkersModule.nonworkingDayRender.renderHolidays();
      }
      this.updateChartElementStyles();
      this.previousCount = currentCount;
      if (this.isSetScrollLeft) {
        this.parent.ganttChartModule.chartTimelineContainer.scrollLeft = this.element.scrollLeft;
      }
    }
  };
  ChartScroll.prototype.getTimelineLeft = function () {
    var tLeft;
    var ganttElement = this.parent.element;
    var resultantWidth = this.parent.timelineModule.wholeTimelineWidth > ganttElement.offsetWidth * 3 ? this.parent.timelineModule.wholeTimelineWidth - ganttElement.offsetWidth * 3 : 0;
    if (this.element.scrollLeft === (this.parent.enableRtl ? -resultantWidth : resultantWidth)) {
      tLeft = this.element.scrollLeft;
    } else {
      var left = this.parent.enableRtl ? -this.element.scrollLeft : this.element.scrollLeft;
      tLeft = left > ganttElement.offsetWidth ? left - ganttElement.offsetWidth : 0;
    }
    if (tLeft >= resultantWidth) {
      tLeft = resultantWidth;
    }
    if (tLeft <= ganttElement.offsetWidth && this.isBackwardScrolled) {
      tLeft = 0;
    }
    if (this.parent.timelineModule.isZoomToFit || this.parent.timelineModule.isZooming) {
      tLeft = 0;
    }
    return tLeft;
  };
  ChartScroll.prototype.deleteTableElements = function () {
    var tableContainer = this.parent.element.getElementsByClassName('e-timeline-header-table-container');
    do {
      tableContainer[0].remove();
    } while (tableContainer.length > 0);
    if (this.parent.element.querySelector('#ganttContainerline-container')) {
      this.parent.element.querySelector('#ganttContainerline-container').innerHTML = '';
    }
    if (this.parent.element.querySelector('.e-nonworking-day-container')) {
      this.parent.element.querySelector('.e-nonworking-day-container').outerHTML = null;
    }
  };
  ChartScroll.prototype.updateChartElementStyles = function () {
    var translateXValue = this.getTimelineLeft();
    if (this.parent.enableTimelineVirtualization) {
      // updating connector line & task table styles
      var dependencyViewer = this.parent.connectorLineModule.dependencyViewContainer;
      var taskTable = this.parent.chartRowsModule.taskTable;
      if (!this.parent.enableRtl) {
        dependencyViewer.style.left = -translateXValue + 'px';
        taskTable.style.left = -translateXValue + 'px';
      } else {
        dependencyViewer.style.left = translateXValue + 'px';
        taskTable.style.right = -translateXValue + 'px';
      }
      taskTable.style.width = this.parent.timelineModule.wholeTimelineWidth + 'px';
    }
  };
  ChartScroll.prototype.updateTopPosition = function () {
    var content = this.parent.treeGrid.element.querySelector('.e-content');
    var contentScrollTop = content.scrollTop;
    var scrollTop;
    if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
      var top_1 = this.parent.virtualScrollModule.getTopPosition();
      scrollTop = contentScrollTop - top_1;
    } else {
      scrollTop = contentScrollTop;
    }
    if (!isNullOrUndefined(this.parent.dayMarkersModule)) {
      var holidayContainer = getValue('nonworkingDayRender.holidayContainer', this.parent.dayMarkersModule);
      var weekendContainer = getValue('nonworkingDayRender.weekendContainer', this.parent.dayMarkersModule);
      var eventMarkersContainer = getValue('eventMarkerRender.eventMarkersContainer', this.parent.dayMarkersModule);
      if (holidayContainer) {
        holidayContainer.style.top = formatUnit(scrollTop);
      }
      if (weekendContainer) {
        weekendContainer.style.top = formatUnit(scrollTop);
      }
      if (eventMarkersContainer) {
        eventMarkersContainer.style.top = formatUnit(scrollTop);
      }
    }
    if (this.parent.chartVerticalLineContainer) {
      this.parent.chartVerticalLineContainer.style.top = formatUnit(scrollTop);
    }
  };
  ChartScroll.prototype.removeShimmer = function () {
    var parent = this.parent;
    setTimeout(function () {
      parent.hideMaskRow();
      if (!parent.allowTaskbarOverlap && parent.showOverAllocation) {
        for (var i = 0; i < parent.currentViewData.length; i++) {
          var tr = parent.chartRowsModule.ganttChartTableBody.childNodes[i];
          if (tr['style'].display !== 'none' && parent.currentViewData[i].hasChildRecords && !parent.currentViewData[i].expanded) {
            if (parent.ganttChartModule.isExpandAll || parent.ganttChartModule.isCollapseAll) {
              parent.treeGrid.getRowByIndex(i)['style'].height = tr['style'].height;
            } else {
              parent.treeGrid.getRows()[i]['style'].height = tr['style'].height;
            }
          }
        }
        parent.contentHeight = parent.enableRtl ? parent['element'].getElementsByClassName('e-content')[2].children[0]['offsetHeight'] : parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
        parent.element.getElementsByClassName('e-chart-rows-container')[0]['style'].height = parent.contentHeight + 'px';
      }
    }, 0);
  };
  ChartScroll.prototype.transformChange = function () {
    this.parent.ganttChartModule.virtualRender.adjustTable();
    this.parent.ganttChartModule.scrollObject.updateTopPosition();
  };
  ChartScroll.prototype.updateShimmer = function () {
    var parent = this.parent;
    setTimeout(function () {
      parent.showMaskRow();
    }, 0);
  };
  ChartScroll.prototype.updateSpinner = function () {
    var parent = this.parent;
    this.parent.showSpinner();
    window.clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(function () {
      parent.hideSpinner();
    }, 200);
  };
  /**
   * Scroll event handler
   *
   * @returns {void} .
   */
  ChartScroll.prototype.onScroll = function () {
    var _this = this;
    var scrollArgs = {};
    if (this.element.scrollTop !== this.previousScroll.top) {
      // eslint-disable-next-line
      !this.isFromTreeGrid ? this.parent.notify('chartScroll', {
        top: this.element.scrollTop
      }) : this.isFromTreeGrid = false;
      scrollArgs.previousScrollTop = this.previousScroll.top;
      this.previousScroll.top = this.element.scrollTop;
      scrollArgs.scrollTop = this.element.scrollTop;
      scrollArgs.scrollDirection = 'Vertical';
      scrollArgs.action = 'VerticalScroll';
      this.updateTopPosition();
    }
    if (this.element.scrollLeft !== this.previousScroll.left) {
      this.isBackwardScrolled = this.element.scrollLeft < this.previousScroll.left && !this.parent.enableRtl;
      this.parent.ganttChartModule.chartTimelineContainer.scrollLeft = this.element.scrollLeft;
      scrollArgs.previousScrollLeft = this.previousScroll.left;
      this.previousScroll.left = this.element.scrollLeft;
      scrollArgs.scrollLeft = this.element.scrollLeft;
      scrollArgs.scrollDirection = 'Horizontal';
      scrollArgs.action = 'HorizontalScroll';
      if (this.parent.enableTimelineVirtualization && this.parent.timelineModule.wholeTimelineWidth > this.parent.element.offsetWidth * 3) {
        this.isSetScrollLeft = true;
        if (this.parent.timelineModule.totalTimelineWidth > this.parent.element.offsetWidth * 3) {
          this.updateContent();
        }
        this.parent.ganttChartModule.updateWidthAndHeight();
        if (this.parent.element.getElementsByClassName('e-weekend-container')[0]) {
          this.parent.element.getElementsByClassName('e-weekend-container')[0]['style'].height = '100%';
        }
        if (this.parent.element.getElementsByClassName('e-holiday-container')[0]) {
          this.parent.element.getElementsByClassName('e-holiday-container')[0]['style'].height = '100%';
        }
      } else if (this.parent.enableTimelineVirtualization && this.parent.timelineModule.wholeTimelineWidth < this.parent.element.offsetWidth * 3) {
        this.parent.connectorLineModule.svgObject.setAttribute('width', '100%');
      }
    }
    this.parent.timelineModule['performedTimeSpanAction'] = false;
    if (!isNullOrUndefined(scrollArgs.scrollDirection) && (this.parent.enableVirtualization === true || this.parent.enableTimelineVirtualization === true) && (this.parent.isToolBarClick || isNullOrUndefined(this.parent.isToolBarClick))) {
      this.parent.isVirtualScroll = true;
      if (this.parent.showIndicator || isNullOrUndefined(this.parent.showIndicator)) {
        if (!this.parent.enableVirtualMaskRow && this.parent.enableVirtualization && this.parent.loadingIndicator.indicatorType === 'Spinner') {
          this.updateSpinner();
        } else if (this.parent.enableTimelineVirtualization && !this.parent['isRowSelected'] && Math.abs(this.element.scrollLeft - scrollArgs.previousScrollLeft) > 1000) {
          if (!this.parent.enableVirtualMaskRow && this.parent.loadingIndicator.indicatorType === 'Spinner') {
            this.updateSpinner();
          } else {
            this.parent.showMaskRow();
            setTimeout(function () {
              _this.removeShimmer();
            }, 0);
          }
        }
        this.parent['isRowSelected'] = false;
      }
    }
    this.isSetScrollLeft = false;
    this.parent.isToolBarClick = true;
    scrollArgs.requestType = 'scroll';
    this.parent.trigger('actionComplete', scrollArgs);
  };
  /**
   * To set height for chart scroll container
   *
   * @param {string | number} height - To set height for scroll container in chart side
   * @returns {void} .
   * @private
   */
  ChartScroll.prototype.setHeight = function (height) {
    this.element.style.height = formatUnit(height);
  };
  /**
   * To set width for chart scroll container
   *
   * @param {string | number} width - To set width to scroll container
   * @returns {void} .
   * @private
   */
  ChartScroll.prototype.setWidth = function (width) {
    this.element.style.width = formatUnit(width);
  };
  /**
   * To set scroll top for chart scroll container
   *
   * @param {number} scrollTop - To set scroll top for scroll container
   * @returns {void} .
   * @private
   */
  ChartScroll.prototype.setScrollTop = function (scrollTop) {
    this.element.scrollTop = scrollTop;
    this.parent.treeGrid.element.querySelector('.e-content').scrollTop = scrollTop;
  };
  /**
   * To set scroll left for chart scroll container
   *
   * @param {number} scrollLeft  - To set scroll left for scroll container
   * @param {number} leftSign - specifies left sign
   * @returns {void} .
   */
  ChartScroll.prototype.setScrollLeft = function (scrollLeft, leftSign) {
    scrollLeft = leftSign === -1 && this.parent.enableRtl ? -scrollLeft : scrollLeft;
    this.isSetScrollLeft = true;
    this.element.scrollLeft = scrollLeft;
    this.parent.ganttChartModule.chartTimelineContainer.scrollLeft = this.element.scrollLeft;
    if (!this.parent.enableTimelineVirtualization) {
      this.previousScroll.left = this.element.scrollLeft;
    }
  };
  /**
   * Destroy scroll related elements and unbind the events
   *
   * @returns {void} .
   * @private
   */
  ChartScroll.prototype.destroy = function () {
    this.removeEventListeners();
  };
  return ChartScroll;
}();

/**
 * virtual Content renderer for Gantt
 */
var VirtualContentRenderer = /** @__PURE__ @class */function () {
  function VirtualContentRenderer(parent) {
    this.parent = parent;
  }
  /**
   * To render a wrapper for chart body content when virtualization is enabled.
   *
   * @returns {void} .
   * @hidden
   */
  VirtualContentRenderer.prototype.renderWrapper = function () {
    this.wrapper = createElement('div', {
      className: 'e-virtualtable',
      styles: 'position: absolute; transform: translate(0px, 0px);'
    });
    this.parent.ganttChartModule.scrollElement.appendChild(this.wrapper);
    this.virtualTrack = createElement('div', {
      className: 'e-virtualtrack',
      styles: 'position: relative; pointer-events: none; width: 100%;'
    });
    this.parent.ganttChartModule.scrollElement.appendChild(this.virtualTrack);
    this.wrapper.appendChild(this.parent.ganttChartModule.chartBodyContent);
  };
  /**
   * To append child elements for wrappered element when virtualization is enabled.
   *
   * @param {HTMLElement} element .
   * @returns {void} .
   * @hidden
   */
  VirtualContentRenderer.prototype.appendChildElements = function (element) {
    this.wrapper.appendChild(element);
  };
  /**
   * To adjust gantt content table's style when virtualization is enabled
   *
   * @returns {void} .
   * @hidden
   */
  VirtualContentRenderer.prototype.adjustTable = function () {
    var content = this.parent.treeGrid.getContent().querySelector('.e-content').querySelector('.e-virtualtable');
    if (this.parent.enableTimelineVirtualization) {
      var virtualTable = document.getElementsByClassName('e-virtualtable')[1].style.transform;
      var treegridVirtualHeight = this.parent.treeGrid.element.getElementsByClassName('e-virtualtable')[0].style.transform;
      var translateXValue = void 0;
      if (virtualTable !== '') {
        translateXValue = virtualTable.match(/translate.*\((.+)\)/)[1].split(', ')[0];
      } else {
        var chartTransform = this.parent.ganttChartModule.scrollElement.getElementsByClassName('e-virtualtable')[0].style.transform;
        translateXValue = chartTransform.match(/translate.*\((.+)\)/)[1].split(', ')[0];
      }
      var translateYValue = treegridVirtualHeight.match(/translate.*\((.+)\)/)[1].split(', ')[1];
      this.parent.ganttChartModule.virtualRender.wrapper.style.transform = "translate(" + translateXValue + ", " + translateYValue + ")";
    } else {
      this.parent.ganttChartModule.virtualRender.wrapper.style.transform = content.style.transform;
    }
  };
  return VirtualContentRenderer;
}();

/**
 * module to render gantt chart - project view
 */
var GanttChart = /** @__PURE__ @class */function () {
  function GanttChart(parent) {
    this.isExpandCollapseFromChart = false;
    this.isExpandAll = false;
    this.isCollapseAll = false;
    this.debounceTimeoutNext = 0;
    this.debounceTimeout = 0;
    this.isGanttElement = false;
    this.previousPinchDistance = 0;
    this.currentToolbarIndex = -1;
    this.isPinching = false;
    this.parent = parent;
    this.chartTimelineContainer = null;
    this.rangeViewContainer = createElement('div', {
      className: rangeContainer
    });
    this.rangeViewContainer.setAttribute('role', 'button');
    this.rangeViewContainer.setAttribute('aria-label', 'RangeContainer');
    this.virtualRender = new VirtualContentRenderer(this.parent);
    this.addEventListener();
  }
  GanttChart.prototype.addEventListener = function () {
    this.parent.on('renderPanels', this.renderChartContainer, this);
    this.parent.on('recordsUpdated', this.renderChartElements, this);
    this.parent.on('dataReady', this.renderInitialContents, this);
    this.parent.on('tree-grid-created', this.renderChartContents, this);
    this.parent.on('destroy', this.destroy, this);
  };
  GanttChart.prototype.renderChartContents = function () {
    this.parent.notify('refreshDayMarkers', {});
    this.wireEvents();
  };
  /**
   * Method to render top level containers in Gantt chart
   *
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.renderChartContainer = function () {
    this.chartElement = createElement('div', {
      id: this.parent.element.id + 'GanttChart',
      className: ganttChart
    });
    this.parent.chartPane.appendChild(this.chartElement);
    this.renderTimelineContainer();
    this.renderBodyContainers();
    // render top level div header and content
    // Get timeline header from timeline class file and append to header div
    // render content div
    // Render scroll able div
    // Render container for all element like, table, weekend and holidays
    // Get rows element from rows renderer class
    // Get label related info label renderer class
    // Get baseline from baseline renderer class
    // Get weekend elements from weekend-holidays renderer class
  };
  /**
   * method to render timeline, holidays, weekends at load time
   *
   * @returns {void} .
   */
  GanttChart.prototype.renderInitialContents = function () {
    this.parent.timelineModule.createTimelineSeries();
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.renderOverAllocationContainer = function () {
    for (var i = 0; i < this.parent.flatData.length; i++) {
      var data = this.parent.flatData[i];
      if (data.childRecords.length > 0) {
        this.parent.dataOperation.updateOverlappingValues(data);
      }
    }
    var rangeContainer$1 = this.parent.element.querySelector('.' + rangeContainer);
    if (rangeContainer$1) {
      rangeContainer$1.innerHTML = '';
    }
    if (this.parent.treeGrid.grid.filterSettings.columns.length === 0) {
      this.renderRangeContainer(this.parent.currentViewData);
    }
  };
  GanttChart.prototype.renderChartElements = function () {
    if (this.parent.isFromOnPropertyChange) {
      this.rangeViewContainer.innerHTML = '';
      this.parent.updateProjectDates(this.parent.cloneProjectStartDate, this.parent.cloneProjectEndDate, this.parent.isTimelineRoundOff);
      this.parent.isFromOnPropertyChange = false;
    } else {
      this.parent.chartRowsModule.renderChartRows();
      if (this.parent.predecessorModule && this.parent.taskFields.dependency) {
        this.parent.connectorLineIds = [];
        this.parent.updatedConnectorLineCollection = [];
        this.parent.predecessorModule.createConnectorLinesCollection();
      }
      this.parent.connectorLineModule.renderConnectorLines(this.parent.updatedConnectorLineCollection);
      for (var i = 0; i < this.parent.chartRowsModule.ganttChartTableBody.children.length; i++) {
        if (this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[4]) {
          this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[1].setAttribute('tabindex', '-1');
          this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[2].setAttribute('tabindex', '-1');
          this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[4].setAttribute('tabindex', '-1');
        } else {
          if (this.parent.viewType === 'ProjectView') {
            var node = this.parent.chartRowsModule.ganttChartTableBody.children[parseInt(i.toString(), 10)].children[0].children[1].children[1];
            if (!isNullOrUndefined(node)) {
              this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[1].setAttribute('tabindex', '-1');
            }
          } else if (this.parent.chartRowsModule.ganttChartTableBody.children[parseInt(i.toString(), 10)].children[0].children[1].children[0]) {
            this.parent.chartRowsModule.ganttChartTableBody.children[i].children[0].children[1].children[0].setAttribute('tabindex', '-1');
          }
        }
      }
      var criticalModule = this.parent.criticalPathModule;
      if (this.parent.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
        this.parent.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, this.parent.enableCriticalPath, criticalModule.predecessorCollectionTaskIds);
      }
      if (this.parent.showOverAllocation) {
        this.renderOverAllocationContainer();
      }
    }
    this.updateWidthAndHeight();
    if (this.parent.isLoad) {
      this.parent.notify('selectRowByIndex', {});
    }
    if (this.parent.timelineModule.isZoomToFit) {
      this.parent.timelineModule.processZoomToFit();
    }
  };
  /**
   * @param {IGanttData[]} records .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.renderRangeContainer = function (records) {
    var recordLength = records.length;
    var count;
    var ganttRecord;
    var rangeCollection;
    if (this.parent.treeGrid.grid.filterSettings.columns.length === 0) {
      for (count = 0; count < recordLength; count++) {
        ganttRecord = records[count];
        rangeCollection = ganttRecord.ganttProperties.workTimelineRanges;
        if (rangeCollection) {
          this.renderRange(rangeCollection, ganttRecord);
        }
      }
    }
  };
  GanttChart.prototype.getTopValue = function (currentRecord) {
    var updatedRecords = this.parent.getExpandedRecords(this.parent.currentViewData);
    var recordIndex = updatedRecords.indexOf(currentRecord);
    if (currentRecord.parentItem && recordIndex === -1) {
      var nestedParent = this.parent.getRecordByID(currentRecord.parentItem.taskId);
      recordIndex = updatedRecords.indexOf(nestedParent);
    }
    if (!currentRecord.expanded) {
      return recordIndex * this.parent.rowHeight;
    }
    return (recordIndex + 1) * this.parent.rowHeight;
  };
  /*get height for range bar*/
  GanttChart.prototype.getRangeHeight = function (data) {
    if (!data.expanded && data.hasChildRecords) {
      return this.parent.rowHeight - Math.floor(this.parent.rowHeight - this.parent.chartRowsModule.taskBarHeight);
    }
    return data.childRecords.length * this.parent.rowHeight - Math.floor(this.parent.rowHeight - this.parent.chartRowsModule.taskBarHeight);
  };
  GanttChart.prototype.renderRange = function (rangeCollection, currentRecord) {
    var topValue = 0;
    var rowIndex = this.parent.currentViewData.indexOf(currentRecord);
    if (!this.parent.allowTaskbarOverlap && this.parent.enableMultiTaskbar) {
      topValue = !currentRecord.expanded ? this.parent.getRowByIndex(rowIndex).offsetTop : this.parent.getRowByIndex(rowIndex).offsetTop + this.parent.rowHeight;
    } else {
      topValue = this.getTopValue(currentRecord);
    }
    var sameIDElement = this.rangeViewContainer.querySelector('.' + 'rangeContainer' + currentRecord.ganttProperties.rowUniqueID);
    if (sameIDElement) {
      sameIDElement.remove();
    }
    var parentDiv = createElement('div', {
      className: 'rangeContainer' + currentRecord.ganttProperties.rowUniqueID,
      styles: "top:" + topValue + "px; position: absolute;"
    });
    if (currentRecord.level === 0 && !currentRecord.expanded && isNullOrUndefined(currentRecord.parentItem) && !this.parent.enableMultiTaskbar) {
      return;
    }
    if (currentRecord.level > 0 && currentRecord.expanded && !this.parent.getRecordByID(currentRecord.parentItem.taskId).expanded) {
      return;
    }
    for (var i = 0; i < rangeCollection.length; i++) {
      var height = void 0;
      var node = this.parent.chartRowsModule.ganttChartTableBody.childNodes;
      if (!this.parent.allowTaskbarOverlap && !currentRecord.expanded && this.parent.enableMultiTaskbar && !this.isCollapseAll) {
        height = parseInt(node[rowIndex].style.height, 10) - (this.parent.rowHeight - this.parent.chartRowsModule.taskBarHeight);
      } else {
        height = this.getRangeHeight(currentRecord);
      }
      var leftDiv = createElement('div', {
        className: rangeChildContainer + ' ' + 'e-leftarc',
        styles: (this.parent.enableRtl ? 'right:' : 'left:') + ((this.parent.enableRtl ? rangeCollection[i].left + rangeCollection[i].width - 5 : rangeCollection[i].left) + "px;\n                top: " + Math.floor((this.parent.rowHeight - this.parent.chartRowsModule.taskBarHeight) / 2) + "px;\n                height: " + (height + 1) + "px; border-right: 0px;\n                z-index: " + (this.parent.viewType === 'ProjectView' ? currentRecord.childRecords.length > 1 ? currentRecord.childRecords.length + 1 : currentRecord.childRecords.length : 6))
      });
      var rightDiv = createElement('div', {
        className: rangeChildContainer + ' ' + 'e-rightarc',
        styles: (this.parent.enableRtl ? 'right:' : 'left:') + ((this.parent.enableRtl ? rangeCollection[i].left : rangeCollection[i].left + rangeCollection[i].width - 5) + "px;\n                top: " + Math.floor((this.parent.rowHeight - this.parent.chartRowsModule.taskBarHeight) / 2) + "px; height: " + (height + 1) + "px;\n                border-left: 0px;\n                z-index: " + (this.parent.viewType === 'ProjectView' ? currentRecord.childRecords.length > 1 ? currentRecord.childRecords.length + 1 : currentRecord.childRecords.length : 6))
      });
      parentDiv.appendChild(leftDiv);
      parentDiv.appendChild(rightDiv);
      this.rangeViewContainer.appendChild(parentDiv);
    }
    this.parent.ganttChartModule.chartBodyContent.appendChild(this.rangeViewContainer);
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.renderTimelineContainer = function () {
    this.chartTimelineContainer = createElement('div', {
      className: timelineHeaderContainer
    });
    if (this.parent.enableRtl) {
      this.chartTimelineContainer.style.borderLeftWidth = '1px';
      this.chartTimelineContainer.style.borderRightWidth = '0px';
    }
    this.chartTimelineContainer.setAttribute('role', 'presentation');
    this.chartElement.appendChild(this.chartTimelineContainer);
  };
  /**
   * initiate chart container
   *
   * @returns {void} .
   */
  GanttChart.prototype.renderBodyContainers = function () {
    this.chartBodyContainer = createElement('div', {
      className: chartBodyContainer
    });
    this.chartElement.appendChild(this.chartBodyContainer);
    this.scrollElement = createElement('div', {
      className: chartScrollElement + ' ' + scrollContent,
      styles: 'position:relative;'
    });
    this.chartBodyContainer.appendChild(this.scrollElement);
    this.chartBodyContent = createElement('div', {
      className: chartBodyContent,
      styles: 'position:relative; overflow:hidden '
    });
    if (this.parent.virtualScrollModule && this.parent.enableVirtualization || this.parent.enableTimelineVirtualization) {
      this.parent.ganttChartModule.virtualRender.renderWrapper();
    } else {
      this.scrollElement.appendChild(this.chartBodyContent);
    }
    // this.parent.chartRowsModule.createChartTable();
    this.scrollObject = new ChartScroll(this.parent);
    //this.scrollObject.setWidth(this.chartProperties.width);
    var toolbarHeight = 0;
    if (!isNullOrUndefined(this.parent.toolbarModule) && !isNullOrUndefined(this.parent.toolbarModule.element)) {
      toolbarHeight = this.parent.toolbarModule.element.offsetHeight;
    }
    this.scrollObject.setHeight(this.parent.ganttHeight - this.chartTimelineContainer.offsetHeight - toolbarHeight);
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.updateWidthAndHeight = function () {
    //empty row height
    var emptydivHeight = 36;
    var emptyHeight = this.parent.contentHeight === 0 ? this.parent.flatData.length > 1 ? emptydivHeight : 0 : this.parent.contentHeight;
    var contentElement = this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0];
    if (emptyHeight >= contentElement['offsetHeight'] || this.parent.height === 'auto' || contentElement['offsetHeight'] - emptyHeight < emptydivHeight) {
      this.chartBodyContent.style.height = formatUnit(emptyHeight);
    } else {
      var scrollHeight = this.parent.element.getElementsByClassName('e-chart-rows-container')[0]['offsetHeight'];
      if (contentElement['offsetHeight'] >= scrollHeight) {
        this.chartBodyContent.style.height = contentElement['offsetHeight'] - 17 + 'px';
      } else {
        this.chartBodyContent.style.height = contentElement['offsetHeight'] + 'px';
      }
    } //let element: HTMLElement = this.chartTimelineContainer.querySelector('.' + cls.timelineHeaderTableContainer);
    // Handled zoomtofit horizontal scrollbar hide while performing different zooming levels in browser at virtualtimeline mode-Task(919516)
    if (this.parent.timelineModule.isZoomToFit && this.parent.enableTimelineVirtualization) {
      this.chartBodyContent.style.width = this.parent.enableTimelineVirtualization && this.parent.timelineModule.totalTimelineWidth > this.parent.element.offsetWidth * 3 ? formatUnit(this.parent.element.offsetWidth * 3) : formatUnit(this.parent.timelineModule.totalTimelineWidth - this.parent.timelineModule['clientWidthDifference']);
    } else {
      this.chartBodyContent.style.width = this.parent.enableTimelineVirtualization && this.parent.timelineModule.totalTimelineWidth > this.parent.element.offsetWidth * 3 ? formatUnit(this.parent.element.offsetWidth * 3) : formatUnit(this.parent.timelineModule.totalTimelineWidth);
    }
    // To handle the width of chartbody element after zoomtofit action followed by vertical scroll actions
    if (this.parent.timelineModule.isZoomedToFit && this.parent.enableVirtualization) {
      var clientWidth = Math.abs(this.parent.timelineModule.totalTimelineWidth - this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0].clientWidth);
      this.parent.ganttChartModule.chartBodyContent.style.width = formatUnit(this.parent.timelineModule.totalTimelineWidth - clientWidth);
    }
    this.setVirtualHeight();
    this.parent.notify('updateHeight', {});
    this.parent.updateGridLineContainerHeight();
    this.updateLastRowBottomWidth();
  };
  GanttChart.prototype.setVirtualHeight = function () {
    if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
      var wrapper = getValue('virtualTrack', this.parent.ganttChartModule.virtualRender);
      wrapper.style.height = this.parent.treeGrid.element.getElementsByClassName('e-virtualtrack')[0].style.height;
      var wrapper1 = getValue('wrapper', this.parent.ganttChartModule.virtualRender);
      var treegridVirtualHeight = this.parent.treeGrid.element.getElementsByClassName('e-virtualtable')[0].style.transform;
      var virtualTable = document.getElementsByClassName('e-virtualtable')[1].style.transform;
      if (this.parent.enableTimelineVirtualization) {
        var translateXValue = void 0;
        // Firefox whitespace issue, Firefox omit y axis value if both axis values are 0 and returns translate(0px)-Task(888356)
        if (treegridVirtualHeight === 'translate(0px)' && navigator.userAgent.includes('Firefox')) {
          treegridVirtualHeight = 'translate(0px, 0px)';
        }
        if (virtualTable !== '') {
          translateXValue = virtualTable.match(/translate.*\((.+)\)/)[1].split(', ')[0];
        } else {
          var chartTransform = this.parent.ganttChartModule.scrollElement.getElementsByClassName('e-virtualtable')[0].style.transform;
          translateXValue = chartTransform.match(/translate.*\((.+)\)/)[1].split(', ')[0];
        }
        var translateYValue = treegridVirtualHeight.match(/translate.*\((.+)\)/)[1].split(', ')[1];
        wrapper1.style.transform = "translate(" + translateXValue + ", " + translateYValue + ")";
      } else {
        wrapper1.style.transform = treegridVirtualHeight;
      }
    }
  };
  /**
   * Method to update bottom border for chart rows
   *
   * @returns {void} .
   */
  GanttChart.prototype.updateLastRowBottomWidth = function () {
    if (this.parent.currentViewData.length > 0 && this.parent.height !== 'auto') {
      var expandedRecords = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.currentViewData : this.parent.expandedRecords;
      var lastExpandedRow = expandedRecords[expandedRecords.length - 1];
      var lastExpandedRowIndex = this.parent.currentViewData.indexOf(lastExpandedRow);
      var lastRow = this.parent.getRowByIndex(lastExpandedRowIndex);
      var table = this.parent.chartRowsModule.ganttChartTableBody;
      if (table.querySelectorAll('.e-chart-row-cell.e-chart-row-border.e-lastrow')) {
        removeClass(table.querySelectorAll('.e-chart-row-cell.e-chart-row-border.e-lastrow'), 'e-lastrow');
      }
      if (this.chartBodyContent.clientHeight < this.chartBodyContainer.clientHeight) {
        if (lastRow) {
          addClass(lastRow.querySelectorAll('td'), 'e-lastrow');
          var emptydivHeight = 36;
          var emptyHeight = this.parent.contentHeight === 0 ? this.parent.flatData.length > 1 ? emptydivHeight : 0 : this.parent.contentHeight;
          var contentElement = this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0];
          if (emptyHeight >= contentElement['offsetHeight'] || contentElement['offsetHeight'] - emptyHeight < emptydivHeight) {
            this.chartBodyContent.style.height = formatUnit(emptyHeight);
          } else {
            var scrollHeight = this.parent.element.getElementsByClassName('e-chart-rows-container')[0]['offsetHeight'];
            if (contentElement['offsetHeight'] >= scrollHeight) {
              this.chartBodyContent.style.height = contentElement['offsetHeight'] - 17 + 'px';
            } else {
              this.chartBodyContent.style.height = contentElement['offsetHeight'] + 'px';
            }
          }
        }
      }
    }
  };
  GanttChart.prototype.removeEventListener = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('renderPanels', this.renderChartContainer);
    this.parent.off('recordsUpdated', this.renderChartElements);
    this.parent.off('dataReady', this.renderInitialContents);
    this.parent.off('tree-grid-created', this.renderChartContents);
    this.parent.off('destroy', this.destroy);
  };
  /**
   * Click event handler in chart side
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   */
  GanttChart.prototype.ganttChartMouseDown = function (e) {
    var cancel = false;
    if (e.type === 'touchstart' && e instanceof TouchEvent && e.touches && e.touches.length === 2) {
      // Calculate initial distance between two Pinch touch points
      this.initPinchDistance = this.calculatePinchDistance(e.touches[0], e.touches[1]);
      this.isPinching = true;
    }
    if (this.parent.allowTaskbarDragAndDrop && this.parent.editModule && this.parent.editSettings.allowTaskbarEditing && !this.isPinching) {
      var editAction = this.parent.editModule.taskbarEditModule['getTaskBarAction'](e);
      if (editAction === 'ChildDrag' || editAction === 'ParentDrag' || editAction === 'MilestoneDrag' || editAction === 'ManualParentDrag') {
        var args = {
          cancel: cancel,
          data: this.getRecordByTaskBar(e.target),
          target: e.target,
          chartRow: closest(e.target, 'tr')
        };
        this.parent.trigger('rowDragStartHelper', args);
        cancel = args['cancel'];
      }
    }
    if (!cancel && !this.isPinching) {
      if (e.which !== 3 && this.parent.editSettings.allowTaskbarEditing) {
        this.parent.notify('chartMouseDown', e);
        this.parent.element.tabIndex = 0;
      }
      var isTaskbarEdited = false;
      if (this.parent.editSettings.allowTaskbarEditing && (this.parent.element.querySelector('.e-left-resize-gripper') || this.parent.element.querySelector('.e-left-connectorpoint-outer-div'))) {
        isTaskbarEdited = true;
      }
      if (!isTaskbarEdited || e.button === 2) {
        if (this.parent.editSettings.allowEditing && this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0) {
          this.parent.treeGrid.endEdit();
        }
      }
    }
  };
  GanttChart.prototype.calculatePinchDistance = function (touch1, touch2) {
    var dx = touch2.clientX - touch1.clientX;
    var dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };
  GanttChart.prototype.ganttChartMouseClick = function (e) {
    if (this.parent.autoFocusTasks) {
      this.scrollToTarget(e); /** Scroll to task */
    }
    this.parent.notify('chartMouseClick', e);
  };
  GanttChart.prototype.ganttChartMouseUp = function (e) {
    if (e.type === 'touchend') {
      this.initPinchDistance = null;
      this.isPinching = false;
      var resizeCheck = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-taskbar-resize-div');
      if (!isNullOrUndefined(resizeCheck)) {
        resizeCheck.remove();
      }
      var Check = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-clone-taskbar') || this.parent.chartPane.querySelector('.e-clone-taskbar');
      if (!isNullOrUndefined(Check)) {
        var clonetbody = Check.parentElement;
        var cloneTable = clonetbody.parentElement;
        cloneTable.remove();
      }
      var falseline = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-gantt-false-line');
      if (!isNullOrUndefined(falseline)) {
        this.parent.editModule.taskbarEditModule.removeFalseLine(true);
      }
    }
    if (this.parent.editSettings.allowTaskbarEditing) {
      this.parent.notify('chartMouseUp', e);
    }
    if (!this.parent.editSettings.allowEditing) {
      var isTaskbarEdited = false;
      if (this.parent.editSettings.allowTaskbarEditing && getValue('editModule.taskbarEditModule.isMouseDragged', this.parent) && getValue('editModule.taskbarEditModule.taskBarEditAction', this.parent)) {
        isTaskbarEdited = true;
      }
      if (!isTaskbarEdited) {
        /** Expand/collapse action */
        var target = e.target;
        var isOnTaskbarElement = e.target.classList.contains(taskBarMainContainer) || closest(e.target, '.' + taskBarMainContainer);
        if (closest(target, '.e-gantt-parent-taskbar') && !this.parent.editSettings.allowEditing) {
          this.chartExpandCollapseRequest(e);
        } else if (!isOnTaskbarElement && this.parent.autoFocusTasks) {
          this.scrollToTarget(e); /** Scroll to task */
        }
      }
    }
  };
  /**
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   */
  GanttChart.prototype.scrollToTarget = function (e) {
    var row = closest(e.target, 'tr');
    if (row && this.parent.element.contains(row) && (this.parent.element.querySelectorAll('.e-chart-rows-container')[0].contains(e.target) || this.parent.element.querySelectorAll('.e-gridcontent')[0].contains(e.target)) && this.parent.currentViewData.length > 0) {
      var rowIndex = getValue('rowIndex', closest(e.target, 'tr'));
      var dateObject = this.parent.currentViewData[rowIndex].ganttProperties.startDate;
      var dateObjLeft = this.parent.currentViewData[rowIndex].ganttProperties.left;
      if (!isNullOrUndefined(dateObject)) {
        var left = !this.parent.enableTimelineVirtualization ? this.parent.dataOperation.getTaskLeft(dateObject, false) : {};
        if (this.parent.autoFocusTasks) {
          if (this.parent.enableTimelineVirtualization) {
            this.updateScrollLeft(dateObjLeft);
          } else {
            this.updateScrollLeft(left);
          }
        }
      }
    }
  };
  /**
   * To focus selected task in chart side
   *
   * @param {number} scrollLeft .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.updateScrollLeft = function (scrollLeft) {
    scrollLeft = scrollLeft > 0 ? scrollLeft : 0;
    scrollLeft = this.scrollElement.scrollWidth <= scrollLeft ? this.scrollElement.scrollWidth : scrollLeft;
    if (this.scrollElement.offsetWidth + this.parent.ganttChartModule.scrollElement.scrollLeft < scrollLeft || this.scrollElement.scrollLeft > scrollLeft) {
      this.scrollObject.setScrollLeft(scrollLeft - 50, this.parent.enableRtl ? -1 : 0);
    }
    //  this.parent.ganttChartModule.scrollObject.updateLeftPosition();
  };
  /**
   *  Method trigger while perform mouse up action.
   *
   * @param {PointerEvent} e .
   * @returns {void}
   * @private
   */
  GanttChart.prototype.mouseUp = function (e) {
    if (e.type === 'touchend') {
      this.initPinchDistance = null;
      this.isPinching = false;
    }
    if (!isNullOrUndefined(this.parent.editModule) && !isNullOrUndefined(this.parent.editModule.taskbarEditModule)) {
      this.parent.editModule.taskbarEditModule.removeFalseLine(false);
    }
    var resizeCheck = this.parent.element.querySelector('.e-taskbar-resize-div');
    if (!isNullOrUndefined(resizeCheck)) {
      resizeCheck.remove();
    }
    if (this.parent.allowTaskbarDragAndDrop && this.parent.editModule && this.parent.editModule.taskbarEditModule) {
      this.parent.editModule.taskbarEditModule['previousLeftValue'] = 0;
    }
    if (this.parent.allowRowDragAndDrop) {
      var ganttDragElemet = this.parent.element.querySelector('.e-ganttdrag');
      if (ganttDragElemet) {
        ganttDragElemet.remove();
      }
    }
    if (!this.isGanttElement) {
      this.parent.notify('chartMouseUp', e);
    }
    this.isGanttElement = false;
  };
  /**
   *  Method trigger while perform mouse up action.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.documentMouseUp = function (e) {
    if (e.type === 'touchend') {
      this.initPinchDistance = null;
      this.isPinching = false;
      this.previousPinchDistance = 0;
    }
    this.isGanttElement = true;
    if (e.target.classList.contains('e-treegridexpand') || e.target.classList.contains('e-treegridcollapse')) {
      if (getValue('isEditCollapse', this.parent.treeGrid) === true) {
        setValue('isEditCollapse', false, this.parent.treeGrid);
      }
    }
    if (this.parent.allowRowDragAndDrop) {
      var ganttDragElemet = this.parent.element.querySelector('.e-ganttdrag');
      if (ganttDragElemet) {
        ganttDragElemet.remove();
      }
    }
    if (this.parent.isDestroyed || e.which === 3) {
      return;
    }
    var resizeCheck = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-taskbar-resize-div');
    if (!isNullOrUndefined(resizeCheck)) {
      resizeCheck.remove();
    }
    var Check = this.parent.element.getElementsByClassName('e-clone-taskbar')[0];
    if (!isNullOrUndefined(Check)) {
      var clonetbody = Check.parentElement;
      var cloneTable = clonetbody.parentElement;
      cloneTable.remove();
    }
    var isTaskbarEdited = false;
    if (this.parent.editSettings.allowTaskbarEditing && getValue('editModule.taskbarEditModule.isMouseDragged', this.parent) && getValue('editModule.taskbarEditModule.taskBarEditAction', this.parent)) {
      isTaskbarEdited = true;
    }
    this.parent.notify('chartMouseUp', e);
    if (this.parent.showActiveElement) {
      this.parent.showIndicator = true;
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.hideMaskRow();
      } else {
        this.parent.hideSpinner();
      }
      if (this.focusedElement && !e.target.classList.contains('e-split-bar')) {
        this.focusedElement.tabIndex = this.focusedElement.tabIndex === 0 ? -1 : this.focusedElement.tabIndex;
        removeClass([this.focusedElement], 'e-active-container');
      }
    }
    if (!isTaskbarEdited) {
      /** Expand/collapse action */
      var target = e.target;
      var isOnTaskbarElement = e.target.classList.contains(taskBarMainContainer) || closest(e.target, '.' + taskBarMainContainer);
      if (closest(target, '.e-gantt-parent-taskbar') && !this.parent.editSettings.allowEditing) {
        this.chartExpandCollapseRequest(e);
      } else if (!isOnTaskbarElement && this.parent.autoFocusTasks) {
        this.scrollToTarget(e); /** Scroll to task */
      }
    }
    if (this.parent.editModule && this.parent.editModule.taskbarEditModule) {
      this.parent.editModule.taskbarEditModule.removeFalseLine(true);
    }
    if (!isNullOrUndefined(this.parent.onTaskbarClick) && !isTaskbarEdited) {
      var target = e.target;
      var taskbarElement = closest(target, '.e-gantt-parent-taskbar,.e-gantt-child-taskbar,.e-gantt-milestone');
      if (taskbarElement) {
        this.onTaskbarClick(e, target, taskbarElement);
      }
    }
  };
  /**
   * This event triggered when click on taskbar element
   *
   * @param {PointerEvent | KeyboardEventArgs} e .
   * @param {EventTarget} target .
   * @param {Element} taskbarElement .
   * @returns {void}
   */
  GanttChart.prototype.onTaskbarClick = function (e, target, taskbarElement) {
    var rowIndex;
    var chartRow = closest(target, 'tr');
    if (!isNullOrUndefined(chartRow)) {
      rowIndex = getValue('rowIndex', chartRow);
    }
    var data = this.getRecordByTarget(e);
    var args = {
      data: data,
      taskbarElement: taskbarElement,
      rowIndex: rowIndex,
      target: target
    };
    this.parent.trigger('onTaskbarClick', args);
  };
  /**
   *  Method trigger while perform mouse leave action.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.ganttChartLeave = function (e) {
    if (this.parent.editSettings.allowTaskbarEditing) {
      this.parent.notify('chartMouseLeave', e);
    }
  };
  /**
   *  Method trigger while perform mouse move action.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.ganttChartMove = function (e) {
    if (e.type === 'touchmove' && this.isPinching === true && e instanceof TouchEvent && e.touches && e.touches.length === 2) {
      // Calculate current distance between two touch points
      var currentPinchDistance = this.calculatePinchDistance(e.touches[0], e.touches[1]);
      if (Math.abs(this.previousPinchDistance - currentPinchDistance) > 15) {
        if (currentPinchDistance > this.previousPinchDistance) {
          // Pinch out detected - Perform Zoom in
          this.parent.timelineModule.processZooming(true);
        } else if (currentPinchDistance < this.previousPinchDistance) {
          // Pinch in detected - Perform Zoom out
          this.parent.timelineModule.processZooming(false);
        }
        this.previousPinchDistance = currentPinchDistance;
      }
    }
    if (this.parent.editSettings.allowTaskbarEditing && this.isPinching === false) {
      if (this.parent.element.getElementsByClassName('e-clone-taskbar').length > 0 && !this.parent.enableRtl) {
        var xValue = void 0;
        if (e.type === 'touchmove' || e.type === 'touchstart' || e.type === 'touchend') {
          xValue = e['changedTouches'][0].pageX;
        } else {
          xValue = e.pageX;
        }
        if (xValue <= this.parent.getOffsetRect(this.parent.ganttChartModule.chartElement).left) {
          return;
        }
      }
      this.parent.notify('chartMouseMove', e);
      if (!isNullOrUndefined(this.parent.taskFields.dependency) && this.parent.connectorLineEditModule) {
        this.parent.connectorLineEditModule.updateConnectorLineEditElement(e);
      }
    }
  };
  /**
   *  Method trigger while perform right click action.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.contextClick = function (e) {
    if (this.parent.allowFiltering && this.parent.filterModule) {
      this.parent.filterModule.closeFilterOnContextClick(e.srcElement);
    }
    if (this.parent.allowTaskbarDragAndDrop) {
      var Check = this.parent.chartPane.querySelector('.e-clone-taskbar');
      if (!isNullOrUndefined(Check)) {
        var clonetbody = Check.parentElement;
        var cloneTable = clonetbody.parentElement;
        cloneTable.remove();
      }
    }
  };
  /**
   * Method to trigger while perform mouse move on Gantt.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.mouseMoveHandler = function (e) {
    if (!isNullOrUndefined(this.parent.onMouseMove) && (this.parent.flatData.length || e.target.classList.contains('e-header-cell-label') || e.target.classList.contains('e-headercell'))) {
      var target = e.target;
      var args = {
        originalEvent: e
      };
      var element = closest(target, '.e-chart-row-cell,.e-connector-line-container,' + '.e-event-markers,.e-header-cell-label,.e-rowcell,.e-headercell,.e-indicator-span');
      if (element) {
        var rowData = void 0;
        var rowElement = closest(target, '.e-rowcell,.e-chart-row-cell');
        var columnElement = closest(target, '.e-rowcell,.e-headercell');
        if (rowElement) {
          rowData = this.parent.ganttChartModule.getRecordByTarget(e);
          args.data = rowData;
        }
        if (columnElement) {
          var cellIndex = getValue('cellIndex', columnElement);
          args.column = this.parent.treeGrid.columns[cellIndex];
        }
        if (closest(target, '.e-indicator-span')) {
          var index = 0;
          var indicators = rowData.ganttProperties.indicators;
          if (indicators.length > 1) {
            for (index = 0; index < indicators.length; index++) {
              if (indicators[index].name === element.innerText.trim()) {
                break;
              }
            }
          }
          args.indicator = indicators[index];
        }
        if (closest(target, '.e-connector-line-container')) {
          var obj = {};
          obj.target = element;
          args.predecessor = this.parent.tooltipModule.getPredecessorTooltipData(obj);
        }
        if (closest(target, '.e-event-markers')) {
          var obj = {};
          obj.target = element;
          args.eventMarkers = this.parent.tooltipModule.getMarkerTooltipData(obj);
        }
        if (target.classList.contains('e-header-cell-label')) {
          args.date = new Date(target.dataset.content);
        }
      }
      this.parent.trigger('onMouseMove', args);
    }
  };
  /**
   * Double click handler for chart
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   */
  GanttChart.prototype.doubleClickHandler = function (e) {
    var target = e.target;
    var row = closest(target, 'tr');
    var rowIndex = getValue('rowIndex', row);
    var rowData = this.parent.ganttChartModule.getRecordByTarget(e);
    if (this.parent.editSettings.allowEditing && this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0) {
      this.parent.treeGrid.endEdit();
    }
    this.parent.notify('chartDblClick', e);
    var args = {
      row: row,
      rowData: rowData,
      rowIndex: rowIndex,
      target: target
    };
    this.recordDoubleClick(args);
  };
  /**
   * To trigger record double click event.
   *
   * @param {RecordDoubleClickEventArgs} args .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.recordDoubleClick = function (args) {
    this.parent.trigger('recordDoubleClick', args);
  };
  /**
   * @param {PointerEvent | KeyboardEventArgs} e .
   * @returns {IGanttData} .
   * @private
   */
  GanttChart.prototype.getRecordByTarget = function (e) {
    var ganttData;
    var row = closest(e.target, 'div.' + taskBarMainContainer);
    if (!isNullOrUndefined(row)) {
      var id = row.getAttribute('rowUniqueId');
      ganttData = this.parent.getRecordByID(id);
    } else {
      row = closest(e.target, 'tr');
      if (row) {
        var rowIndex = getValue('rowIndex', closest(e.target, 'tr'));
        ganttData = this.parent.currentViewData[rowIndex];
      }
    }
    return ganttData;
  };
  /**
   * To get gantt chart row elements
   *
   * @returns {NodeListOf<Element>} .
   * @private
   */
  GanttChart.prototype.getChartRows = function () {
    if (document.getElementById(this.parent.element.id + 'GanttTaskTableBody') !== null) {
      return document.getElementById(this.parent.element.id + 'GanttTaskTableBody').querySelectorAll('.e-chart-row');
    } else {
      return null;
    }
  };
  /**
   * Expand Collapse operations from gantt chart side
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.chartExpandCollapseRequest = function (e) {
    if (this.parent.enableMultiTaskbar) {
      return;
    }
    var target = e.target;
    var parentElement = closest(target, '.e-gantt-parent-taskbar');
    var record = this.getRecordByTarget(e);
    var chartRow = closest(target, 'tr');
    var rowIndex = getValue('rowIndex', chartRow);
    var gridRow = this.parent.treeGrid.getRows()[rowIndex];
    var args = {
      data: record,
      gridRow: gridRow,
      chartRow: chartRow,
      cancel: false
    };
    this.isExpandCollapseFromChart = true;
    if (parentElement.classList.contains('e-row-expand')) {
      this.collapseGanttRow(args);
    } else if (parentElement.classList.contains('e-row-collapse')) {
      this.expandGanttRow(args);
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.reRenderConnectorLines = function () {
    this.parent.connectorLineModule.dependencyViewContainer.innerHTML = '';
    this.parent.connectorLineIds = [];
    this.parent.updatedConnectorLineCollection = [];
    this.parent.predecessorModule.createConnectorLinesCollection();
    this.parent.connectorLineModule.renderConnectorLines(this.parent.updatedConnectorLineCollection);
    var criticalModule = this.parent.criticalPathModule;
    if (this.parent.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
      criticalModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
    }
  };
  /**
   * To collapse gantt rows
   *
   * @param {object} args .
   * @param {boolean} isCancel .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.collapseGanttRow = function (args) {
    var _this = this;
    this.parent.trigger('collapsing', args, function (arg) {
      if (_this.isExpandCollapseFromChart && !getValue('cancel', arg)) {
        _this.collapsedGanttRow(arg);
      }
      _this.isExpandCollapseFromChart = false;
    });
  };
  /**
   * @returns {void} .
   * @param {object} args .
   * @private
   */
  GanttChart.prototype.collapsedGanttRow = function (args) {
    var _this = this;
    var record;
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      record = this.parent.currentViewData.filter(function (item) {
        return item.ganttProperties[_this.parent.taskFields.id] === args['data'][_this.parent.taskFields.id];
      })[0];
    } else {
      record = getValue('data', args);
    }
    if (isNullOrUndefined(args['gridRow']) && this.parent.enableVirtualization || isNullOrUndefined(args['chartRow'])) {
      if (record) {
        record.expanded = false;
      }
      return;
    }
    if (this.isExpandCollapseFromChart) {
      this.expandCollapseChartRows('collapse', getValue('chartRow', args), record, null);
      var idField_1 = this.parent.taskFields.id;
      if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
        var gridRec = this.parent.treeGrid.getCurrentViewRecords().filter(function (item) {
          return item[idField_1] === args['data'][idField_1];
        })[0];
        this.parent.treeGrid.collapseRow(getValue('gridRow', args), gridRec);
      } else {
        this.parent.treeGrid.collapseRow(getValue('gridRow', args), record);
      }
      this.isExpandCollapseFromChart = false;
    } else {
      this.expandCollapseChartRows('collapse', getValue('chartRow', args), record, null);
    }
    // To render the child record on parent row after collapsing
    if (this.parent.viewType === 'ResourceView' || this.parent.viewType === 'ProjectView') {
      this.renderMultiTaskbar(record);
    }
    if (!this.parent.enableVirtualization) {
      this.parent.updateContentHeight();
    }
    this.updateWidthAndHeight();
    this.reRenderConnectorLines();
    getValue('chartRow', args).setAttribute('aria-expanded', 'false');
  };
  /**
   * To expand gantt rows
   *
   * @returns {void} .
   * @param {object} args .
   * @param {boolean} isCancel .
   * @private
   */
  GanttChart.prototype.expandGanttRow = function (args) {
    var _this = this;
    this.parent.trigger('expanding', args, function (arg) {
      if (_this.isExpandCollapseFromChart && !getValue('cancel', arg)) {
        _this.expandedGanttRow(arg);
      }
      _this.isExpandCollapseFromChart = false;
    });
  };
  /**
   * @returns {void} .
   * @param {object} args .
   * @private
   */
  GanttChart.prototype.expandedGanttRow = function (args) {
    var _this = this;
    var record;
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      record = this.parent.currentViewData.filter(function (item) {
        return item.ganttProperties.taskId === args['data'][_this.parent.taskFields.id];
      })[0];
    } else {
      record = getValue('data', args);
    }
    if (isNullOrUndefined(args['gridRow']) && this.parent.enableVirtualization || isNullOrUndefined(args['chartRow'])) {
      if (record) {
        record.expanded = true;
      }
      return;
    }
    if (this.isExpandCollapseFromChart) {
      this.expandCollapseChartRows('expand', getValue('chartRow', args), record, null);
      var idField_2 = this.parent.taskFields.id;
      if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
        var gridRec = this.parent.treeGrid.getCurrentViewRecords().filter(function (item) {
          return item[idField_2] === args['data'][idField_2];
        })[0];
        this.parent.treeGrid.expandRow(getValue('gridRow', args), gridRec);
      } else {
        this.parent.treeGrid.expandRow(getValue('gridRow', args), record);
      }
      this.isExpandCollapseFromChart = false;
    } else {
      if (!this.parent.isExpandCollapseLevelMethod) {
        this.expandCollapseChartRows('expand', getValue('chartRow', args), record, null);
      }
      this.parent.isExpandCollapseLevelMethod = false;
    }
    // To render the child record on parent row after expanding.
    if (this.parent.viewType === 'ResourceView' || this.parent.viewType === 'ProjectView') {
      this.renderMultiTaskbar(record);
    }
    if (!this.parent.enableVirtualization) {
      this.parent.updateContentHeight();
    }
    this.updateWidthAndHeight();
    this.reRenderConnectorLines();
    getValue('chartRow', args).setAttribute('aria-expanded', 'true');
  };
  GanttChart.prototype.renderMultiTaskbar = function (record) {
    if (this.parent.enableMultiTaskbar) {
      this.parent.chartRowsModule.refreshRecords([record], true);
    } else if (this.parent.showOverAllocation) {
      this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
    }
  };
  /**
   * On expand collapse operation row properties will be updated here.
   *
   * @param {string} action .
   * @param {Node} rowElement .
   * @param {IGanttData} record .
   * @param {boolean} isChild .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.expandCollapseChartRows = function (action, rowElement, record, isChild) {
    var displayType;
    if (action === 'expand') {
      displayType = 'table-row';
      if (!isChild) {
        record.expanded = true;
      }
      var targetElement = rowElement.querySelectorAll('.e-row-collapse');
      for (var t = 0; t < targetElement.length; t++) {
        addClass([targetElement[t]], 'e-row-expand');
        removeClass([targetElement[t]], 'e-row-collapse');
      }
    } else if (action === 'collapse') {
      displayType = 'none';
      if (!isChild) {
        record.expanded = false;
      }
      var targetElement = rowElement.querySelectorAll('.e-row-expand');
      for (var t = 0; t < targetElement.length; t++) {
        addClass([targetElement[t]], 'e-row-collapse');
        removeClass([targetElement[t]], 'e-row-expand');
      }
    }
    if (!this.parent.enableVirtualization) {
      var childRecords = record.childRecords;
      var chartRows = this.getChartRows();
      var rows = [];
      for (var i = 0; i < chartRows.length; i++) {
        if (chartRows[i].classList.contains('gridrowtaskId' + record.ganttProperties.rowUniqueID + 'level' + (record.level + 1))) {
          rows.push(chartRows[i]);
        }
      }
      for (var i = 0; i < rows.length; i++) {
        rows[i].style.display = displayType;
        if (childRecords[i].childRecords && childRecords[i].childRecords.length && (action === 'collapse' || childRecords[i].expanded || this.isExpandAll)) {
          this.expandCollapseChartRows(action, rows[i], childRecords[i], true);
        }
      }
    }
  };
  /**
   * Public method to expand or collapse all the rows of Gantt
   *
   * @returns {void}
   * @param {string} action .
   * @private
   */
  GanttChart.prototype.expandCollapseAll = function (action) {
    if (action === 'expand') {
      this.isExpandAll = true;
      this.parent.treeGrid.expandAll();
    } else {
      this.isCollapseAll = true;
      this.parent.treeGrid.collapseAll();
      if (this.isCollapseAll && !this.parent.allowTaskbarOverlap) {
        var treeGridContentHeight = this.parent.enableRtl ? this.parent['element'].getElementsByClassName('e-content')[2].children[0]['offsetHeight'] : this.parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
        this.parent.contentHeight = treeGridContentHeight;
        document.getElementsByClassName('e-chart-rows-container')[0]['style'].height = this.parent.contentHeight + 'px';
      }
    }
    this.isExpandAll = false;
    this.isCollapseAll = false;
  };
  /**
   * Public method to expand particular level of rows.
   *
   * @returns {void} .
   * @param {number} level .
   * @private
   */
  GanttChart.prototype.expandAtLevel = function (level) {
    this.parent.treeGrid.expandAtLevel(level);
  };
  /**
   * Public method to collapse particular level of rows.
   *
   * @returns {void} .
   * @param {number} level .
   * @private
   */
  GanttChart.prototype.collapseAtLevel = function (level) {
    if (this.parent.enableVirtualization) {
      this.parent.isExpandCollapseLevelMethod = true;
    }
    this.parent.treeGrid.collapseAtLevel(level);
  };
  /**
   * Event Binding for gantt chart click
   *
   * @returns {void} .
   */
  GanttChart.prototype.wireEvents = function () {
    var isIE11Pointer = Browser.isPointer; // eslint-disable-line
    var mouseDown = Browser.touchStartEvent;
    var mouseUp = Browser.touchEndEvent;
    var mouseMove = Browser.touchMoveEvent;
    var cancel = isIE11Pointer ? 'pointerleave' : 'mouseleave';
    EventHandler.add(this.parent.chartPane, mouseDown, this.ganttChartMouseDown, this);
    EventHandler.add(this.parent.chartPane, cancel, this.ganttChartLeave, this);
    EventHandler.add(this.parent.chartPane, mouseMove, this.ganttChartMove, this);
    EventHandler.add(this.parent.chartPane, 'wheel', this.onWheelZoom, this);
    if (this.parent.isAdaptive) {
      EventHandler.add(this.parent.chartPane, click, this.ganttChartMouseClick, this);
      EventHandler.add(this.parent.chartPane, mouseUp, this.ganttChartMouseUp, this);
    }
    if (!this.parent.isAdaptive) {
      EventHandler.add(this.parent.element, mouseUp, this.documentMouseUp, this);
      EventHandler.add(document, mouseUp, this.mouseUp, this);
    }
    EventHandler.add(this.parent.element, 'mousemove', this.mouseMoveHandler, this);
    EventHandler.add(document.body, 'contextmenu', this.contextClick, this);
    EventHandler.add(document, 'mouseup', this.contextClick, this);
    EventHandler.add(this.parent.chartRowsModule.ganttChartTableBody, 'dblclick', this.doubleClickHandler, this);
  };
  GanttChart.prototype.unWireEvents = function () {
    var isIE11Pointer = Browser.isPointer; // eslint-disable-line
    var mouseDown = Browser.touchStartEvent;
    var mouseUp = Browser.touchEndEvent;
    var mouseMove = Browser.touchMoveEvent;
    var cancel = isIE11Pointer ? 'pointerleave' : 'mouseleave';
    if (!isNullOrUndefined(this.parent.chartRowsModule.ganttChartTableBody)) {
      EventHandler.remove(this.parent.chartRowsModule.ganttChartTableBody, mouseDown, this.ganttChartMouseDown);
    }
    if (!isNullOrUndefined(this.parent.chartPane)) {
      EventHandler.remove(this.parent.chartPane, cancel, this.ganttChartLeave);
      EventHandler.remove(this.parent.chartPane, mouseMove, this.ganttChartMove);
      EventHandler.remove(this.parent.chartPane, 'wheel', this.onWheelZoom);
      EventHandler.remove(this.parent.chartPane, mouseDown, this.ganttChartMouseDown);
    }
    if (this.parent.isAdaptive) {
      if (!isNullOrUndefined(this.parent.chartPane)) {
        EventHandler.remove(this.parent.chartPane, click, this.ganttChartMouseClick);
        EventHandler.remove(this.parent.chartPane, mouseUp, this.ganttChartMouseUp);
      }
    }
    if (!this.parent.isAdaptive) {
      if (!isNullOrUndefined(this.parent.element)) {
        EventHandler.remove(this.parent.element, mouseUp, this.documentMouseUp);
      }
      if (!isNullOrUndefined(document)) {
        EventHandler.remove(document, mouseUp, this.mouseUp);
      }
    }
    if (!isNullOrUndefined(this.parent.element)) {
      EventHandler.remove(this.parent.element, 'mousemove', this.mouseMoveHandler);
    }
    if (!isNullOrUndefined(document)) {
      EventHandler.remove(document, 'mouseup', this.contextClick);
      if (!isNullOrUndefined(document.body)) {
        EventHandler.remove(document.body, 'contextmenu', this.contextClick);
      }
    }
    if (!isNullOrUndefined(this.parent.chartRowsModule.ganttChartTableBody)) {
      EventHandler.remove(this.parent.chartRowsModule.ganttChartTableBody, 'dblclick', this.doubleClickHandler);
    }
  };
  // Triggers while perform ctrl+mouse wheel Pinch IN/OUT actions
  GanttChart.prototype.onWheelZoom = function (e) {
    if (e.ctrlKey) {
      e.preventDefault();
      var zoomIn1_1 = e.deltaY < 0;
      // Differentiating between touchpad and mouse wheel
      var isTouchpad_1 = false;
      if (Math.abs(e.deltaY) < 75) {
        // Smaller deltaY typically indicates touchpad
        isTouchpad_1 = true;
      }
      if (this.debounceTimeout) {
        if (this.debounceTimeoutNext + 20 > this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }
        if (this.debounceTimeoutNext + 20 <= this.debounceTimeout || !this.debounceTimeoutNext) {
          this.debounceTimeoutNext = this.debounceTimeout;
        }
      }
      this.debounceTimeout = setTimeout(function () {
        var verticalScrollDelta = Math.abs(e.deltaY);
        // Adjust threshold based on the input method
        var isValidScrollDelta = isTouchpad_1 ? verticalScrollDelta > 0.5 && verticalScrollDelta < 15 : verticalScrollDelta > 5 && verticalScrollDelta <= 200;
        if (isValidScrollDelta) {
          this.parent.timelineModule.processZooming(zoomIn1_1);
        }
      }.bind(this), 100);
    }
  };
  /**
   * To get record by taskbar element.
   *
   * @param {Element} target .
   * @returns {IGanttData} .
   * @private
   */
  GanttChart.prototype.getRecordByTaskBar = function (target) {
    var item;
    if (this.parent.enableVirtualization && this.parent.enableMultiTaskbar) {
      item = this.parent.flatData[this.getIndexByTaskBar(target)];
    } else {
      item = this.parent.currentViewData[this.getIndexByTaskBar(target)];
    }
    return item;
  };
  GanttChart.prototype.updateElement = function (next, currentColumn, isTab, isInEditedState, row) {
    if (this.parent.ganttColumns[next.getAttribute('data-colindex')].field === this.parent.taskFields.progress) {
      var rowIndex = row.index;
      do {
        if (row.hasChildRecords) {
          next = this.getNextElement(next, isTab, isInEditedState);
        }
        currentColumn = this.parent.ganttColumns[next.getAttribute('data-colindex')];
        rowIndex = this.parent.treeGrid.getRows().indexOf(next.parentElement);
      } while (!currentColumn.allowEditing);
      this.parent.treeGrid.saveCell();
      this.parent.treeGrid.editCell(rowIndex, this.parent.ganttColumns[next.getAttribute('data-colindex')].field);
    }
    return next;
  };
  /**
   * Trigger Tab & Shift + Tab keypress to highlight active element.
   *
   * @param {KeyboardEventArgs} e .
   * @returns {void} .
   * @private
   */
  GanttChart.prototype.onTabAction = function (e) {
    this.parent.treeGrid.grid.enableHeaderFocus = this.parent.enableHeaderFocus;
    var toolbarItems = document.getElementsByClassName('e-toolbar-item');
    var isInEditedState = this.parent.editModule && this.parent.editModule.cellEditModule && this.parent.editModule.cellEditModule.isCellEdit;
    if (!this.parent.showActiveElement && !isInEditedState) {
      return;
    }
    var $target = isInEditedState ? e.target.closest('.e-rowcell') : e.target;
    if (this.parent.element.querySelectorAll('.e-focused').length > 0) {
      $target = this.parent.element.querySelectorAll('.e-focused')[0];
    }
    if ($target && !($target.classList.contains('e-toolbar-item') || $target.classList.contains('e-input') || $target.classList.contains('e-btn'))) {
      this.currentToolbarIndex = -1;
    }
    if ($target.closest('.e-rowcell') || $target.closest('.e-chart-row')) {
      this.parent.focusModule.setActiveElement($target);
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.focusedRowIndex = $target.closest('.e-rowcell') ? $target.parentElement.rowIndex : /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    $target.closest('.e-chart-row') ? $target.closest('.e-chart-row').rowIndex : -1;
    var isTab = e.action === 'tab' ? true : false;
    var nextElement = this.getNextElement($target, isTab, isInEditedState);
    if (nextElement && (nextElement === 'noNextRow' || nextElement.classList.contains('e-rowdragheader'))) {
      // eslint-disable-next-line
      nextElement === 'noNextRow' && this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0 ? this.parent.treeGrid.saveCell() : '';
      nextElement = null;
    }
    if (nextElement && $target.classList.contains('e-headercell')) {
      var colIndex = parseInt(nextElement.getAttribute('data-colindex'), 10);
      if (e.action === 'shiftTab') {
        while (colIndex !== -1 && !this.parent.treeGrid.columns[colIndex]['visible']) {
          colIndex = colIndex - 1;
        }
        if (colIndex !== -1) {
          colIndex = this.parent.allowRowDragAndDrop ? colIndex + 1 : colIndex;
          nextElement = document.getElementsByClassName('e-columnheader')[0].childNodes[colIndex];
        } else {
          var toolbarItems_1 = document.getElementsByClassName('e-toolbar-item');
          for (var i = toolbarItems_1.length - 1; i > 0; i--) {
            if (!document.getElementsByClassName('e-toolbar-item')[i].classList.contains('e-hidden')) {
              nextElement = document.getElementsByClassName('e-toolbar-item')[i];
              this.currentToolbarIndex = i;
              break;
            }
          }
        }
      } else {
        while (!this.parent.treeGrid.columns[colIndex]['visible']) {
          colIndex = colIndex + 1;
        }
        colIndex = this.parent.allowRowDragAndDrop ? colIndex + 1 : colIndex;
        nextElement = document.getElementsByClassName('e-columnheader')[0].childNodes[colIndex];
      }
    }
    if (!nextElement && $target.classList.contains('e-headercell') && e.action === 'tab') {
      nextElement = document.getElementsByClassName('e-timeline-header-container')[0];
    }
    if (!nextElement && ($target.classList.contains('e-headercell') || $target.classList.contains('e-toolbar-item') || $target.classList.contains('e-treegrid') || $target.classList.contains('e-input') || $target.classList.contains('e-btn')) && this.parent.toolbarModule && this.parent.toolbar.length > 0) {
      var itemIndex = this.currentToolbarIndex !== -1 ? e.action === 'tab' ? this.currentToolbarIndex + 1 : this.currentToolbarIndex - 1 : e.action === 'shiftTab' ? toolbarItems.length - 1 : 1;
      var isUpdated = false;
      if (itemIndex !== -1 && (e.action === 'shiftTab' || e.action === 'tab' && itemIndex < toolbarItems.length)) {
        do {
          if (!toolbarItems[itemIndex].classList.contains('e-hidden')) {
            nextElement = toolbarItems[itemIndex];
            nextElement.setAttribute('tabindex', '-1');
            if (nextElement.querySelector('.e-btn') === $target) {
              // eslint-disable-next-line
              e.action === 'tab' ? itemIndex++ : itemIndex--;
              nextElement = toolbarItems[itemIndex];
            }
            if (nextElement.querySelector('.e-btn')) {
              nextElement.querySelector('.e-btn').setAttribute('tabindex', '0');
            }
            isUpdated = true;
            this.currentToolbarIndex = itemIndex;
          } else {
            // eslint-disable-next-line
            e.action === 'tab' ? itemIndex++ : itemIndex--;
          }
        } while (!isUpdated);
      }
    }
    if (e.action === 'tab' && !nextElement && this.currentToolbarIndex === toolbarItems.length - 1 && ($target.classList.contains('e-toolbar-item') || $target.classList.contains('e-input') || $target.classList.contains('e-btn'))) {
      for (var i = 0; i < this.parent.treeGrid.columns.length; i++) {
        if (this.parent.treeGrid.columns[i]['visible']) {
          nextElement = document.getElementsByClassName('e-columnheader')[0].childNodes[i];
          break;
        }
      }
    }
    if (e.action === 'shiftTab' && !nextElement && !$target.classList.contains('e-headercell')) {
      nextElement = document.getElementsByClassName('e-timeline-header-container')[0];
    }
    if (e.action === 'shiftTab' && $target.classList.contains('e-timeline-header-container')) {
      for (var i = this.parent.treeGrid.columns.length; i > 0; i--) {
        if (this.parent.treeGrid.columns[i - 1]['visible']) {
          nextElement = document.getElementsByClassName('e-columnheader')[0].childNodes[i - 1];
          break;
        }
      }
    }
    this.tempNextElement = nextElement;
    if (!isNullOrUndefined(nextElement) && !isNullOrUndefined(nextElement['cellIndex'])) {
      if (this.parent.allowRowDragAndDrop) {
        this.childrenIndex = nextElement['cellIndex'];
        this.nextElementIndex = nextElement['cellIndex'] - 1;
      } else {
        this.childrenIndex = nextElement['cellIndex'];
        this.nextElementIndex = nextElement['cellIndex'];
      }
      if (this.nextElementIndex !== -1 && !this.parent.ganttColumns[this.nextElementIndex]['allowEditing'] && this.parent.ganttColumns[this.nextElementIndex]['field'] !== this.parent.taskFields.id) {
        this.isEditableElement = true;
      } else {
        this.isEditableElement = false;
      }
    }
    if (nextElement === 'noNextRow') {
      this.manageFocus($target, 'remove', true);
      return;
    }
    if (typeof nextElement !== 'string') {
      if ($target.classList.contains('e-rowcell') || $target.closest('.e-chart-row-cell') || $target.classList.contains('e-headercell') || $target.closest('.e-segmented-taskbar') || $target.classList.contains('e-timeline-header-container')) {
        e.preventDefault();
      }
      if (isTab && $target.classList.contains('e-rowdragdrop')) {
        this.parent.treeGrid.grid.notify('key-pressed', e);
        return;
      }
      if ($target.classList.contains('e-rowcell') && nextElement && nextElement.classList.contains('e-rowcell') || $target.classList.contains('e-headercell')) {
        // eslint-disable-line                                                                                                                                                                                                                                    
        if (isTab) {
          if (this.parent.editSettings.allowNextRowEdit) {
            var rowData = this.parent.currentViewData[this.focusedRowIndex];
            var columnName = this.parent.ganttColumns[nextElement.getAttribute('data-colindex')].field;
            if (rowData.hasChildRecords) {
              if (columnName === this.parent.taskFields.endDate || columnName === this.parent.taskFields.duration || columnName === this.parent.taskFields.dependency || columnName === this.parent.taskFields.progress || columnName === this.parent.taskFields.work || columnName === this.parent.taskFields.type || columnName === 'taskType') {
                this.parent.treeGrid.grid.endEdit();
                this.parent.treeGrid.grid.notify('key-pressed', e);
              } else if (columnName === this.parent.taskFields.name || columnName === this.parent.taskFields.startDate) {
                this.parent.treeGrid.grid.notify('key-pressed', e);
              } else {
                this.parent.treeGrid.grid.notify('key-pressed', e);
                if (isInEditedState) {
                  this.parent.treeGrid.editCell(this.focusedRowIndex, columnName);
                }
              }
            } else {
              this.parent.treeGrid.grid.notify('key-pressed', e);
            }
          } else {
            if (!nextElement || nextElement && !nextElement.classList.contains('e-headercell') && !nextElement.classList.contains('e-timeline-header-container')) {
              if ($target.classList.contains('e-headercell')) {
                this.manageFocus($target, 'remove', false);
              }
              /* eslint-disable-next-line */
              var row = this.parent.currentViewData[$target.parentElement.rowIndex];
              var next = nextElement;
              if (row.hasChildRecords && (this.parent.ganttColumns[next.getAttribute('data-colindex')].field === this.parent.taskFields.progress || !this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing) && this.parent.ganttColumns[next.getAttribute('data-colindex')].field !== this.parent.taskFields.id && $target.classList.contains('e-editedbatchcell')) {
                var currentColumn = void 0;
                next = this.updateElement(next, currentColumn, isTab, isInEditedState, row);
                while (!this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing) {
                  next = this.getNextElement(next, isTab, isInEditedState);
                }
                next = this.updateElement(next, currentColumn, isTab, isInEditedState, row);
              } else if (!nextElement || $target.classList.contains('e-editedbatchcell')) {
                this.parent.treeGrid.grid.notify('key-pressed', e);
              }
            }
          }
        } else {
          if (nextElement && !nextElement.classList.contains('e-headercell') && nextElement.classList.contains('e-rowcell') && !nextElement.classList.contains('e-toolbar-item')) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            var row = this.parent.currentViewData[$target.parentElement.rowIndex];
            var next = nextElement;
            if (row.hasChildRecords && (this.parent.ganttColumns[next.getAttribute('data-colindex')].field === this.parent.taskFields.progress || !this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing) && this.parent.ganttColumns[next.getAttribute('data-colindex')].field !== this.parent.taskFields.id && $target.classList.contains('e-editedbatchcell')) {
              var currentColumn = void 0;
              next = this.updateElement(next, currentColumn, isTab, isInEditedState, row);
              while (!this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing) {
                next = this.getNextElement(next, isTab, isInEditedState);
              }
              next = this.updateElement(next, currentColumn, isTab, isInEditedState, row);
            } else if (parseInt(next.parentElement.getAttribute('data-rowindex'), 10) !== 0 && parseInt(next.getAttribute('data-colindex'), 10) === 0 && this.parent.ganttColumns[next.getAttribute('data-colindex')].field === this.parent.taskFields.id && $target.classList.contains('e-editedbatchcell')) {
              /* eslint-disable-next-line */
              var rowIndex = $target.parentElement.rowIndex;
              var rowElement = this.getNextRowElement(rowIndex, isTab, true);
              next = this.getChildElement(rowElement, isTab);
              var rowData = this.parent.flatData[parseInt(rowElement.getAttribute('data-rowindex'), 10)];
              if (rowData.hasChildRecords && (!this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing || this.parent.ganttColumns[next.getAttribute('data-colindex')].field === this.parent.taskFields.progress)) {
                var currentColumn = void 0;
                next = this.updateElement(next, currentColumn, isTab, isInEditedState, rowData);
                while (!this.parent.ganttColumns[next.getAttribute('data-colindex')].allowEditing) {
                  next = this.getNextElement(next, isTab, isInEditedState);
                }
                next = this.updateElement(next, currentColumn, isTab, isInEditedState, rowData);
              } else {
                this.parent.treeGrid.grid.notify('key-pressed', e);
              }
            } else {
              this.parent.treeGrid.grid.notify('key-pressed', e);
            }
          }
        }
      }
      if (this.parent.element.querySelectorAll('.e-focused').length > 0) {
        this.manageFocus(this.parent.element.querySelectorAll('.e-focused')[0], 'remove', false);
      }
      if (!(this.parent.editModule && this.parent.editModule.cellEditModule && !isNullOrUndefined(this.parent.editModule.cellEditModule.editedColumn))) {
        if (nextElement) {
          if ($target.classList.contains('e-rowcell')) {
            this.manageFocus($target, 'remove', false);
          } else {
            this.manageFocus($target, 'remove', true);
          }
          if (nextElement.classList.contains('e-rowcell') && $target.nextElementSibling && !$target.classList.contains('e-timeline-header-container') || $target.classList.contains('e-right-label-container')) {
            if (!$target.classList.contains('e-rowcell')) {
              this.parent.treeGrid.grid.notify('key-pressed', e);
              var fmodule = getValue('focusModule', this.parent.treeGrid.grid);
              fmodule.currentInfo.element = nextElement;
              fmodule.currentInfo.elementToFocus = nextElement;
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              fmodule.content.matrix.current = [nextElement.parentElement.rowIndex, nextElement.cellIndex];
            }
            this.manageFocus(nextElement, 'add', false);
          } else {
            if (nextElement && (nextElement.classList.contains('e-toolbar-item') || nextElement.classList.contains('e-headercell') || nextElement.classList.contains('e-rowcell'))) {
              this.manageFocus($target, 'remove', false);
              if (!nextElement.classList.contains('e-toolbar-item')) {
                this.manageFocus(nextElement, 'add', false);
              }
              if ($target.classList.contains('e-treegrid')) {
                e.preventDefault();
              }
            } else {
              this.manageFocus(nextElement, 'add', true);
            }
          }
          this.parent.focusModule.setActiveElement(nextElement);
        }
      }
    }
  };
  /**
   * Get next/previous sibling element.
   *
   * @param {Element} $target .
   * @param {boolean} isTab .
   * @param {boolean} isInEditedState .
   * @returns {Element | string} .
   */
  GanttChart.prototype.getNextElement = function ($target, isTab, isInEditedState) {
    if ($target.classList.contains('e-timeline-header-container') && isTab) {
      var rowElement = this.getNextRowElement(-1, isTab, true);
      return this.getChildElement(rowElement, isTab);
    }
    var nextElement = isTab ? $target.nextElementSibling : $target.previousElementSibling;
    if ($target.parentElement.classList.contains('e-taskbar-main-container')) {
      if (this.parent.labelSettings.rightLabel && isTab) {
        return $target.parentElement.nextElementSibling;
      } else if (!isTab && this.parent.labelSettings.leftLabel) {
        return $target.parentElement.previousElementSibling;
      }
    }
    while (nextElement && nextElement.parentElement.classList.contains('e-row')) {
      if (!nextElement.matches('.e-hide') && !nextElement.matches('.e-rowdragdrop')) {
        return nextElement;
      }
      nextElement = isTab ? nextElement.nextElementSibling : nextElement.previousElementSibling;
    }
    if (!isNullOrUndefined(nextElement) && (nextElement.classList.contains('e-taskbar-main-container') || nextElement.classList.contains('e-right-connectorpoint-outer-div'))) {
      var record = this.parent.currentViewData[this.focusedRowIndex];
      if (!isNullOrUndefined(record.ganttProperties.segments) && record.ganttProperties.segments.length > 0) {
        nextElement = nextElement.classList.contains('e-right-connectorpoint-outer-div') ? nextElement.parentElement.nextElementSibling : nextElement.getElementsByClassName('e-gantt-child-taskbar-inner-div')[0];
      }
    }
    if (this.validateNextElement(nextElement)) {
      return nextElement;
    } else {
      var rowIndex = -1;
      var rowElement = null;
      var childElement = void 0;
      if ($target.classList.contains('e-rowcell') && isInEditedState && this.parent.editSettings.allowNextRowEdit) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        rowIndex = $target.parentElement.rowIndex;
        rowElement = this.getNextRowElement(rowIndex, isTab, true);
        childElement = this.getChildElement(rowElement, isTab);
        return childElement;
      } else if ($target.classList.contains('e-rowcell')) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        rowIndex = $target.parentElement.rowIndex;
        if (isTab) {
          rowElement = this.parent.getRowByIndex(rowIndex);
          if (this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0) {
            rowElement = this.getNextRowElement(rowIndex, isTab, true);
            var childElement_1 = this.getChildElement(rowElement, isTab);
            return childElement_1;
          } else {
            if (this.validateNextElement(rowElement, 'e-left-label-container')) {
              return rowElement.getElementsByClassName('e-left-label-container')[0];
            } else if (this.validateNextElement(rowElement, 'e-taskbar-main-container')) {
              return rowElement.getElementsByClassName('e-taskbar-main-container')[0];
            } else if (this.validateNextElement(rowElement, 'e-right-label-container')) {
              return rowElement.getElementsByClassName('e-right-label-container')[0];
            }
          }
        } else {
          rowElement = this.getNextRowElement(rowIndex, isTab, false);
          if (this.validateNextElement(rowElement, 'e-right-label-container')) {
            return rowElement.getElementsByClassName('e-right-label-container')[0];
          } else if (this.validateNextElement(rowElement, 'e-taskbar-main-container')) {
            return rowElement.getElementsByClassName('e-taskbar-main-container')[0];
          } else if (this.validateNextElement(rowElement, 'e-left-label-container')) {
            return rowElement.getElementsByClassName('e-left-label-container')[0];
          }
        }
      } else if ($target.parentElement.classList.contains('e-chart-row-cell') || $target.parentElement.parentElement.classList.contains('e-chart-row-cell')) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        rowIndex = closest($target, '.e-chart-row').rowIndex;
        if (isTab) {
          if (!isTab && this.parent.virtualScrollModule && this.parent.enableVirtualization) {
            /* eslint-disable-next-line */
            var rowRecord = this.parent.currentViewData[rowIndex];
            rowIndex = this.parent.flatData.indexOf(rowRecord);
          }
          rowElement = this.getNextRowElement(rowIndex, isTab, true);
        } else {
          rowElement = this.parent.treeGrid.getRows()[rowIndex];
        }
        var childElement_2 = this.getChildElement(rowElement, isTab);
        return childElement_2;
      }
      nextElement = $target;
    }
    return null;
  };
  /**
   * Get next/previous row element.
   *
   * @param {number} rowIndex .
   * @param {boolean} isTab .
   * @param {boolean} isChartRow .
   * @returns {Element} .
   */
  GanttChart.prototype.getNextRowElement = function (rowIndex, isTab, isChartRow) {
    var expandedRecords = this.parent.getExpandedRecords(this.parent.currentViewData);
    var currentItem = this.parent.currentViewData[rowIndex];
    var expandedRecordIndex = expandedRecords.indexOf(currentItem);
    var nextRecord = isTab ? expandedRecords[expandedRecordIndex + 1] : expandedRecords[expandedRecordIndex - 1];
    var nextRowIndex = this.parent.currentViewData.indexOf(nextRecord);
    if (nextRecord) {
      return isChartRow ? this.parent.treeGrid.getRows()[nextRowIndex] : this.parent.getRowByIndex(nextRowIndex);
    } else {
      return null;
    }
  };
  /**
   * Validate next/previous sibling element haschilds.
   *
   * @param {Element} $target .
   * @param {string} className .
   * @returns {boolean} .
   */
  GanttChart.prototype.validateNextElement = function ($target, className) {
    if ($target && $target.classList.contains('e-rowcell')) {
      return true;
    }
    if ($target && className) {
      var elementByClass = $target.getElementsByClassName(className)[0];
      return elementByClass && elementByClass.hasChildNodes() ? true : false;
    } else if ($target) {
      return !isNullOrUndefined($target) && $target.hasChildNodes() ? true : false;
    }
    return false;
  };
  /**
   * Getting child element based on row element.
   *
   * @param {Element} rowElement .
   * @param {boolean} isTab .
   * @returns {Element | string} .
   */
  GanttChart.prototype.getChildElement = function (rowElement, isTab) {
    var childElement;
    if (rowElement) {
      childElement = isTab ? rowElement.children[0] : rowElement.children[rowElement.children.length - 1];
      while (childElement) {
        if (!childElement.matches('.e-hide') && !childElement.matches('.e-rowdragdrop')) {
          return childElement;
        }
        childElement = isTab ? childElement.nextElementSibling : childElement.previousElementSibling;
      }
    } else {
      return 'noNextRow';
    }
    return childElement;
  };
  /**
   * Add/Remove active element.
   *
   * @private
   * @param {HTMLElement} element .
   * @param {string} focus .
   * @param {boolean} isChartElement .
   * @param {string} keyAction .
   * @returns {void} .
   */
  GanttChart.prototype.manageFocus = function (element, focus, isChartElement, keyAction) {
    if (isChartElement) {
      var childElement = null;
      if (element.classList.contains('e-left-label-container') || element.classList.contains('e-right-label-container')) {
        childElement = element.getElementsByTagName('span')[0];
      } else if (element.classList.contains('e-taskbar-main-container') || element.classList.contains('e-gantt-child-taskbar-inner-div')) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        var rowIndex = closest(element, '.e-chart-row').rowIndex;
        var data = this.parent.currentViewData[rowIndex];
        var className = data.hasChildRecords ? data.ganttProperties.isAutoSchedule ? 'e-gantt-parent-taskbar' : 'e-manualparent-main-container' : data.ganttProperties.isMilestone ? 'e-gantt-milestone' : !isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length > 0 ? 'e-segmented-taskbar' : 'e-gantt-child-taskbar';
        childElement = element.getElementsByClassName(className)[0];
        if (isNullOrUndefined(childElement)) {
          childElement = element;
        }
      }
      if (element.classList.contains('e-right-label-temp-container') || element.classList.contains('e-left-label-temp-container') || element.classList.contains('e-indicator-span') || element.classList.contains('e-timeline-header-container')) {
        if (focus === 'add') {
          element.setAttribute('tabIndex', '0');
          addClass([element], 'e-active-container');
          element.focus();
        } else {
          if (keyAction !== 'downArrow' && keyAction !== 'upArrow') {
            removeClass([element], 'e-active-container');
            element.setAttribute('tabIndex', '-1');
            element.blur();
          }
        }
      }
      if (focus === 'add' && !isNullOrUndefined(childElement)) {
        element.setAttribute('tabIndex', '0');
        addClass([childElement], 'e-active-container');
        element.focus();
        this.focusedElement = childElement;
      } else if (!isNullOrUndefined(childElement) && keyAction !== 'downArrow' && keyAction !== 'upArrow') {
        removeClass([childElement], 'e-active-container');
        element.setAttribute('tabIndex', '-1');
        element.blur();
      }
    } else {
      if (focus === 'add') {
        element.setAttribute('tabIndex', '0');
        addClass([element], ['e-focused', 'e-focus']);
        element.focus();
      } else {
        element.setAttribute('tabIndex', '-1');
        removeClass([element], ['e-focused', 'e-focus']);
        element.blur();
      }
    }
  };
  /**
   * To get index by taskbar element.
   *
   * @param {Element} target .
   * @returns {number} .
   * @private
   */
  GanttChart.prototype.getIndexByTaskBar = function (target) {
    var row;
    var recordIndex;
    if (!target.classList.contains(taskBarMainContainer)) {
      row = closest(target, 'div.' + taskBarMainContainer);
    } else {
      row = target;
    }
    if (isNullOrUndefined(row)) {
      row = closest(target, 'tr.' + chartRow);
      recordIndex = [].slice.call(this.parent.chartRowsModule.ganttChartTableBody.childNodes).indexOf(row);
    } else {
      var id = row.getAttribute('rowUniqueId');
      var record = this.parent.getRecordByID(id);
      if (this.parent.enableVirtualization && this.parent.enableMultiTaskbar) {
        recordIndex = this.parent.flatData.indexOf(record);
      } else {
        if (this.parent.pdfExportModule && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth && this.parent.pdfExportModule.isPdfExport) {
          recordIndex = this.parent.ids.indexOf(record.ganttProperties.taskId.toString());
        } else {
          recordIndex = this.parent.currentViewData.indexOf(record);
        }
      }
    }
    return recordIndex;
  };
  GanttChart.prototype.destroy = function () {
    this.removeEventListener();
    this.unWireEvents();
    this.chartElement = null;
    this.rangeViewContainer = null;
    this.chartBodyContent = null;
    this.scrollElement = null;
    this.chartTimelineContainer = null;
    this.chartBodyContainer = null;
    if (!isNullOrUndefined(this.scrollObject)) {
      this.scrollObject.destroy();
      this.scrollObject = null;
    }
  };
  return GanttChart;
}();
var __assign = undefined && undefined.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
/**
 * Configures the `Timeline` of the gantt.
 */
var Timeline = /** @__PURE__ @class */function () {
  function Timeline(ganttObj) {
    this.isZoomIn = false;
    this.isZooming = false;
    this.isZoomToFit = false;
    this.topTierCollection = [];
    this.bottomTierCollection = [];
    this.pdfExportTopTierCollection = [];
    this.pdfExportBottomTierCollection = [];
    this.restrictRender = true;
    this.performedTimeSpanAction = false;
    this.isZoomedToFit = false;
    this.parent = ganttObj;
    this.initProperties();
  }
  /**
   * To initialize the public property.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.initProperties = function () {
    this.timelineStartDate = null;
    this.timelineEndDate = null;
    this.totalTimelineWidth = 0;
    this.customTimelineSettings = null;
    this.parent.isTimelineRoundOff = this.isZoomToFit ? false : isNullOrUndefined(this.parent.projectStartDate) ? true : false;
    if (this.parent.enablePersistence && this.parent.isLoad) {
      this.parent.timelineSettings = this.parent.currentZoomingLevel;
    }
  };
  /**
   * To render timeline header series.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.validateTimelineProp = function () {
    this.roundOffDays();
    this.processTimelineProperty();
    this.timelineWidthCalculation();
  };
  /**
   * Function used to refresh Gantt rows.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.refreshTimeline = function () {
    this.initProperties();
    this.processTimelineUnit();
    this.parent.dataOperation.calculateProjectDates();
    if (!this.parent.isFromOnPropertyChange) {
      this.parent.updateProjectDates(this.parent.cloneProjectStartDate, this.parent.cloneProjectEndDate, this.parent.isTimelineRoundOff);
    }
    var timelineContainer = this.parent.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'];
    this.parent.element.getElementsByClassName('e-gridcontent')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
    this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
  };
  /**
   * Function used to refresh Gantt rows.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.refreshTimelineByTimeSpan = function () {
    this.validateTimelineProp();
    if (!this.parent.pdfExportModule || this.parent.pdfExportModule && !this.parent.pdfExportModule.isPdfExport || this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && !this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      this.parent.ganttChartModule.chartTimelineContainer.innerHTML = '';
    }
    this.createTimelineSeries();
  };
  /**
   * Function used to refresh Gantt rows.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.updateChartByNewTimeline = function () {
    this.parent.chartRowsModule.refreshChartByTimeline();
    var currentScrollLeft = this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0].scrollLeft;
    this.parent.element.getElementsByClassName('e-timeline-header-container')[0].scrollLeft = currentScrollLeft;
    this.parent.notify('refreshDayMarkers', {});
  };
  /**
   * Function used to perform Zoomin and Zoomout actions in Gantt control.
   *
   * @param {boolean} isZoomIn .
   * @private
   * @returns {void}
   */
  Timeline.prototype.processZooming = function (isZoomIn) {
    this.isZoomToFit = false;
    this.isZoomedToFit = false;
    var action = isZoomIn ? 'ZoomIn' : 'ZoomOut';
    if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent'](action)) {
      if (this.parent.undoRedoModule['redoEnabled']) {
        this.parent.undoRedoModule['disableRedo']();
      }
      this.parent.undoRedoModule['createUndoCollection']();
      var previousTimeline = {};
      previousTimeline['action'] = action;
      previousTimeline['previousZoomingLevel'] = extend({}, {}, this.parent.currentZoomingLevel, true);
      this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = previousTimeline;
    }
    if (!this.parent['isProjectDateUpdated']) {
      this.parent.dateValidationModule.calculateProjectDates();
    }
    if (!isNullOrUndefined(this.parent.zoomingProjectStartDate)) {
      this.parent.cloneProjectStartDate = this.parent.zoomingProjectStartDate;
      this.parent.cloneProjectEndDate = this.parent.zoomingProjectEndDate;
    }
    this.parent.zoomingProjectStartDate = null;
    this.parent.zoomingProjectEndDate = null;
    var currentZoomingLevel = this.checkCurrentZoomingLevel();
    this.isZoomIn = isZoomIn;
    this.isZooming = true;
    var currentLevel;
    var level = isZoomIn ? currentZoomingLevel + 1 : currentZoomingLevel - 1;
    var foundLevel = this.parent.zoomingLevels.find(function (tempLevel) {
      return tempLevel.level === level;
    });
    if (foundLevel) {
      currentLevel = level;
    } else {
      currentLevel = currentZoomingLevel;
    }
    if (this.parent.toolbarModule) {
      if (isZoomIn) {
        if (currentLevel === this.parent.zoomingLevels[this.parent.zoomingLevels.length - 1].level) {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin'], false); // disable toolbar items.
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomout'], true);
        } else {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomout'], true); // disable toolbar items.
        }
      } else {
        if (currentLevel === this.parent.zoomingLevels[0].level) {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomout'], false); // disable toolbar items.
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin'], true);
        } else {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin'], true); // enable toolbar items.
        }
      }
    }
    currentLevel = this.parent.zoomingLevels.findIndex(function (tempLevel) {
      return tempLevel.level === currentLevel;
    });
    var newTimeline = this.parent.zoomingLevels[currentLevel];
    var args = {
      requestType: isZoomIn ? 'beforeZoomIn' : 'beforeZoomOut',
      timeline: newTimeline,
      cancel: false
    };
    this.parent.trigger('actionBegin', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.showMaskRow();
    } else {
      this.parent.showSpinner();
    }
    if (!args.cancel) {
      newTimeline = args.timeline;
      this.changeTimelineSettings(newTimeline);
    }
  };
  /**
   * To change the timeline settings property values based upon the Zooming levels.
   *
   * @param {ZoomTimelineSettings} newTimeline .
   * @returns {void}
   * @private
   */
  Timeline.prototype.changeTimelineSettings = function (newTimeline) {
    var _this = this;
    if (this.isZoomToFit) {
      this.isSingleTier = this.customTimelineSettings.topTier.unit === 'None' || this.customTimelineSettings.bottomTier.unit === 'None' ? true : false;
    } else if (!this.isZoomIn) {
      this.isSingleTier = newTimeline.topTier.unit === 'None' || newTimeline.bottomTier.unit === 'None' ? true : false;
    }
    var skipProperty = this.isSingleTier ? this.customTimelineSettings.topTier.unit === 'None' ? 'topTier' : 'bottomTier' : null;
    Object.keys(this.customTimelineSettings).forEach(function (property) {
      if (property !== skipProperty) {
        _this.customTimelineSettings[property] = typeof newTimeline[property] === 'object' && !isNullOrUndefined(newTimeline[property]) ? __assign({}, newTimeline[property]) : newTimeline[property];
      } else {
        var value = property === 'topTier' ? 'bottomTier' : 'topTier';
        var assignValue = 'bottomTier';
        if (newTimeline["" + assignValue].unit !== 'None') {
          _this.customTimelineSettings[value] = __assign({}, newTimeline[assignValue]);
        }
      }
    });
    this.parent.isTimelineRoundOff = this.isZoomToFit ? false : isNullOrUndefined(this.parent.projectStartDate) ? true : false;
    this.processTimelineUnit();
    this.parent.updateProjectDates(this.parent.cloneProjectStartDate, this.parent.cloneProjectEndDate, this.parent.isTimelineRoundOff);
    var criticalModule = this.parent.criticalPathModule;
    if (this.parent.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
      criticalModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
    }
    if (this.isZooming || this.isZoomToFit) {
      var args = {
        requestType: this.isZoomIn ? 'AfterZoomIn' : this.isZoomToFit ? 'AfterZoomToProject' : 'AfterZoomOut',
        timeline: this.parent.currentZoomingLevel
      };
      this.parent.trigger('actionComplete', args);
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.hideMaskRow();
      } else {
        this.parent.hideSpinner();
      }
    }
    var tier = this.topTier === 'None' ? 'bottomTier' : 'topTier';
    if (this.parent.enableTimelineVirtualization && (!this.parent.pdfExportModule || this.parent.pdfExportModule && !this.parent.pdfExportModule.isPdfExport)) {
      this.wholeTimelineWidth = this.calculateWidthBetweenTwoDate(tier, this.parent.timelineModule.timelineStartDate, this.parent.timelineModule.timelineEndDate);
      if (this.wholeTimelineWidth <= this.totalTimelineWidth) {
        this.wholeTimelineWidth = this.totalTimelineWidth;
      }
      // Handled zoomtofit horizontal scrollbar hide while performing different zooming levels in browser at virtualtimeline mode-Task(919516)
      if (this.isZoomToFit) {
        this.clientWidthDifference = Math.abs(this.wholeTimelineWidth - this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0].clientWidth) + 1;
        this.parent.element.querySelectorAll('.e-chart-scroll-container')[0].querySelector('.e-virtualtrack')['style'].width = this.wholeTimelineWidth - this.clientWidthDifference + 'px';
        if (!isNullOrUndefined(this.parent.element.querySelectorAll('.e-timeline-header-container')[0].querySelector('.e-virtualtrack'))) {
          this.parent.element.querySelectorAll('.e-timeline-header-container')[0].querySelector('.e-virtualtrack')['style'].width = this.wholeTimelineWidth - this.clientWidthDifference + 'px';
        }
      } else {
        this.parent.element.querySelectorAll('.e-chart-scroll-container')[0].querySelector('.e-virtualtrack')['style'].width = this.wholeTimelineWidth + 'px';
        if (!isNullOrUndefined(this.parent.element.querySelectorAll('.e-timeline-header-container')[0].querySelector('.e-virtualtrack'))) {
          this.parent.element.querySelectorAll('.e-timeline-header-container')[0].querySelector('.e-virtualtrack')['style'].width = this.wholeTimelineWidth + 'px';
        }
      }
      this.parent.ganttChartModule.updateWidthAndHeight();
    }
  };
  /**
   * To perform the zoom to fit operation in Gantt.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.processZoomToFit = function () {
    this.isZoomToFit = true;
    this.isZooming = false;
    this.isZoomedToFit = true;
    var previousTimeline = {};
    if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent['isUndoRedoItemPresent']('ZoomToFit')) {
      if (this.parent.undoRedoModule['redoEnabled']) {
        this.parent.undoRedoModule['disableRedo']();
      }
      this.parent.undoRedoModule['createUndoCollection']();
      previousTimeline['action'] = 'ZoomToFit';
      previousTimeline['previousTimelineStartDate'] = extend([], [], [this.parent.cloneProjectStartDate], true)[0];
      previousTimeline['previousTimelineEndDate'] = extend([], [], [this.parent.cloneProjectEndDate], true)[0];
      previousTimeline['previousZoomingLevel'] = extend({}, {}, this.parent.currentZoomingLevel, true);
      this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = previousTimeline;
    }
    if (!this.parent.zoomingProjectStartDate) {
      this.parent.zoomingProjectStartDate = this.parent.cloneProjectStartDate;
      this.parent.zoomingProjectEndDate = this.parent.cloneProjectEndDate;
    }
    if (this.parent.zoomingProjectStartDate > this.parent.cloneProjectStartDate) {
      this.parent.cloneProjectStartDate = new Date(this.parent.allowUnscheduledTasks ? this.parent.zoomingProjectStartDate : this.parent.cloneProjectStartDate);
    }
    this.parent.dataOperation.calculateProjectDates();
    var timeDifference = this.parent.cloneProjectEndDate.getTime() - this.parent.cloneProjectStartDate.getTime();
    var totalDays = timeDifference / (1000 * 3600 * 24);
    var chartWidth = this.parent.ganttChartModule.chartElement.offsetWidth;
    var perDayWidth = chartWidth / totalDays;
    var zoomingLevel;
    var firstValue;
    var secondValue;
    var zoomingCollections = this.parent.zoomingLevels.slice();
    var sortedCollectons = zoomingCollections.sort(function (a, b) {
      return !a.perDayWidth && !b.perDayWidth ? 0 : a.perDayWidth < b.perDayWidth ? 1 : -1;
    });
    if (perDayWidth === 0) {
      // return when the Gantt chart is not in viewable state.
      return;
    }
    for (var i = 0; i < sortedCollectons.length; i++) {
      firstValue = sortedCollectons[i];
      if (i === sortedCollectons.length - 1) {
        zoomingLevel = sortedCollectons[i];
        break;
      } else {
        secondValue = sortedCollectons[i + 1];
      }
      if (perDayWidth >= firstValue.perDayWidth) {
        zoomingLevel = sortedCollectons[i];
        break;
      }
      if (perDayWidth < firstValue.perDayWidth && perDayWidth > secondValue.perDayWidth) {
        zoomingLevel = sortedCollectons[i + 1];
        break;
      }
    }
    var newTimeline = extend({}, {}, zoomingLevel, true);
    this.roundOffDateToZoom(this.parent.cloneProjectStartDate, true, perDayWidth, newTimeline.bottomTier.unit, zoomingLevel);
    this.roundOffDateToZoom(this.parent.cloneProjectEndDate, false, perDayWidth, newTimeline.bottomTier.unit, zoomingLevel);
    var numberOfCells = this.calculateNumberOfTimelineCells(newTimeline);
    var scrollHeight = this.parent.ganttChartModule.scrollElement.offsetHeight - 17; //17 is horizontal scrollbar width
    var contentHeight = this.parent.ganttChartModule.chartBodyContent.offsetHeight;
    var emptySpace = contentHeight <= scrollHeight ? 0 : 17;
    newTimeline.timelineUnitSize = Math.abs(chartWidth - emptySpace) / numberOfCells;
    var args = {
      requestType: 'beforeZoomToProject',
      timeline: newTimeline
    };
    if (this.parent.toolbarModule) {
      this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin', this.parent.controlId + '_zoomout'], true);
    }
    this.parent.trigger('actionBegin', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.showMaskRow();
    } else {
      this.parent.showSpinner();
    }
    this.changeTimelineSettings(newTimeline);
    this.parent.isTimelineRoundOff = isNullOrUndefined(this.parent.projectStartDate) ? true : false;
    this.isZoomToFit = false;
  };
  Timeline.prototype.bottomTierCellWidthCalc = function (mode, zoomLevel, date) {
    var convertedMilliSeconds;
    switch (mode) {
      case 'Minutes':
        convertedMilliSeconds = zoomLevel.bottomTier.count * (60 * 1000);
        break;
      case 'Hour':
        convertedMilliSeconds = zoomLevel.bottomTier.count * (60 * 60 * 1000);
        break;
      case 'Week':
        convertedMilliSeconds = zoomLevel.bottomTier.count * (7 * 24 * 60 * 60 * 1000);
        break;
      case 'Day':
        convertedMilliSeconds = zoomLevel.bottomTier.count * (24 * 60 * 60 * 1000);
        break;
      case 'Month':
        {
          var daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
          convertedMilliSeconds = zoomLevel.bottomTier.count * (60 * 60 * 24 * daysInMonth * 1000);
          break;
        }
      case 'Year':
        {
          var daysInYear = date.getFullYear() % 400 === 0 || date.getFullYear() % 100 !== 0 && date.getFullYear() % 4 === 0 ? 366 : 365;
          convertedMilliSeconds = zoomLevel.bottomTier.count * (60 * 60 * 24 * daysInYear * 1000);
          break;
        }
    }
    return convertedMilliSeconds;
  };
  Timeline.prototype.roundOffDateToZoom = function (date, isStartDate, perDayWidth, tierMode, zoomingLevel) {
    var roundOffTime = this.bottomTierCellWidthCalc(tierMode, zoomingLevel, date);
    if (isStartDate) {
      date.setTime(date.getTime() - roundOffTime);
    } else {
      date.setTime(date.getTime() + roundOffTime);
    }
  };
  Timeline.prototype.calculateNumberOfTimelineCells = function (newTimeline) {
    var sDate = new Date(this.parent.cloneProjectStartDate.getTime());
    var eDate = new Date(this.parent.cloneProjectEndDate.getTime());
    this.parent.dateValidationModule['updateDateWithTimeZone'](sDate, eDate);
    var numberOfDays = Math.abs((eDate.getTime() - sDate.getTime()) / (24 * 60 * 60 * 1000));
    var count = newTimeline.bottomTier.count;
    var unit = newTimeline.bottomTier.unit;
    if (unit === 'Day') {
      return numberOfDays / count;
    } else if (unit === 'Week') {
      return numberOfDays / count / 7;
    } else if (unit === 'Month') {
      return numberOfDays / count / 28;
    } else if (unit === 'Year') {
      return numberOfDays / count / (12 * 28);
    } else if (unit === 'Hour') {
      return numberOfDays * (24 / count);
    } else {
      return numberOfDays * (60 * 24 / count);
    }
  };
  /**
   * To validate time line unit.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.processTimelineUnit = function () {
    var directProperty = ['timelineViewMode', 'timelineUnitSize', 'weekStartDay', 'weekendBackground'];
    var innerProperty = {
      'topTier': ['unit', 'format', 'count', 'formatter'],
      'bottomTier': ['unit', 'format', 'count', 'formatter']
    };
    var tierUnits = ['Year', 'Month', 'Week', 'Day', 'Hour', 'Minutes'];
    this.customTimelineSettings = this.customTimelineSettings ? this.customTimelineSettings : this.extendFunction(this.parent.timelineSettings, directProperty, innerProperty);
    if (tierUnits.indexOf(this.customTimelineSettings.topTier.unit) === -1 && tierUnits.indexOf(this.customTimelineSettings.bottomTier.unit) === -1) {
      this.customTimelineSettings.topTier.unit = tierUnits.indexOf(this.customTimelineSettings.timelineViewMode) !== -1 ? this.customTimelineSettings.timelineViewMode : 'Week';
      this.customTimelineSettings.bottomTier.unit = tierUnits.indexOf(this.customTimelineSettings.topTier.unit) !== 5 ? tierUnits[tierUnits.indexOf(this.customTimelineSettings.topTier.unit) + 1] : 'None';
    } else if (tierUnits.indexOf(this.customTimelineSettings.topTier.unit) !== -1 && tierUnits.indexOf(this.customTimelineSettings.bottomTier.unit) !== -1 && tierUnits.indexOf(this.customTimelineSettings.topTier.unit) > tierUnits.indexOf(this.customTimelineSettings.bottomTier.unit)) {
      this.customTimelineSettings.bottomTier.unit = this.customTimelineSettings.topTier.unit;
    } else {
      this.customTimelineSettings.topTier.unit = tierUnits.indexOf(this.customTimelineSettings.topTier.unit) === -1 ? 'None' : this.customTimelineSettings.topTier.unit;
      this.customTimelineSettings.bottomTier.unit = tierUnits.indexOf(this.customTimelineSettings.bottomTier.unit) === -1 ? 'None' : this.customTimelineSettings.bottomTier.unit;
    }
    this.topTier = this.customTimelineSettings.topTier.unit;
    this.bottomTier = this.customTimelineSettings.bottomTier.unit;
    this.previousIsSingleTier = this.isSingleTier;
    this.isSingleTier = this.topTier === 'None' || this.bottomTier === 'None' ? true : false;
  };
  /**
   * To validate timeline properties.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.processTimelineProperty = function () {
    this.customTimelineSettings.topTier.count = this.topTier === 'None' ? 1 : this.validateCount(this.customTimelineSettings.topTier.unit, this.customTimelineSettings.topTier.count, 'topTier');
    this.customTimelineSettings.bottomTier.count = this.customTimelineSettings.bottomTier.unit === 'None' ? 1 : this.validateCount(this.customTimelineSettings.bottomTier.unit, this.customTimelineSettings.bottomTier.count, 'bottomTier');
    this.customTimelineSettings.bottomTier.format = this.validateFormat(this.customTimelineSettings.bottomTier.unit, this.customTimelineSettings.bottomTier.format);
    this.customTimelineSettings.topTier.format = this.validateFormat(this.topTier, this.customTimelineSettings.topTier.format);
    this.customTimelineSettings.weekStartDay = this.customTimelineSettings.weekStartDay >= 0 && this.customTimelineSettings.weekStartDay <= 6 ? this.customTimelineSettings.weekStartDay : 0;
    if (!(this.parent.pdfExportModule && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth)) {
      this.checkCurrentZoomingLevel();
    }
  };
  /**
   * To find the current zooming level of the Gantt control.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.calculateZoomingLevelsPerDayWidth = function () {
    var collections = this.parent.zoomingLevels;
    for (var i = 0; i < collections.length; i++) {
      var perDayWidth = this.getPerDayWidth(collections[i].timelineUnitSize, collections[i].bottomTier.count, collections[i].bottomTier.unit);
      collections[i].perDayWidth = perDayWidth;
    }
  };
  /**
   * To find the current zooming level of the Gantt control.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.checkCurrentZoomingLevel = function () {
    var count = this.customTimelineSettings.bottomTier.unit !== 'None' ? this.customTimelineSettings.bottomTier.count : this.customTimelineSettings.topTier.count;
    var unit = this.customTimelineSettings.bottomTier.unit !== 'None' ? this.customTimelineSettings.bottomTier.unit : this.customTimelineSettings.topTier.unit;
    var tier = this.customTimelineSettings.bottomTier.unit !== 'None' ? 'bottomTier' : 'topTier';
    var zoomLevel = this.getCurrentZoomingLevel(unit, count, tier);
    if (this.parent.toolbarModule) {
      if (zoomLevel === this.parent.zoomingLevels[this.parent.zoomingLevels.length - 1].level) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin'], false);
      } else if (zoomLevel === this.parent.zoomingLevels[0].level) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomout'], false);
      }
    }
    this.parent.currentZoomingLevel = this.parent.zoomingLevels[zoomLevel];
    return zoomLevel;
  };
  /**
   * @param {string} unit .
   * @param {number} count .
   * @param {string} tier .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.getCurrentZoomingLevel = function (unit, count, tier) {
    var level;
    var currentZoomCollection;
    var checkSameCountLevels;
    var secondValue;
    var firstValue;
    if (!this.parent.zoomingLevels.length) {
      this.parent.zoomingLevels = this.parent.getZoomingLevels();
    }
    var sameUnitLevels = this.parent.zoomingLevels.filter(function (tempLevel) {
      if (tier === 'bottomTier') {
        return tempLevel.bottomTier.unit === unit;
      } else {
        return tempLevel.topTier.unit === unit;
      }
    });
    if (sameUnitLevels.length === 0) {
      var closestUnit_1 = this.getClosestUnit(unit, '', false);
      sameUnitLevels = this.parent.zoomingLevels.filter(function (tempLevel) {
        if (tier === 'bottomTier') {
          return tempLevel.bottomTier.unit === closestUnit_1;
        } else {
          return tempLevel.topTier.unit === closestUnit_1;
        }
      });
    }
    var sortedUnitLevels = sameUnitLevels.sort(function (a, b) {
      if (tier === 'bottomTier') {
        return !a.bottomTier.count || !b.bottomTier.count ? 0 : a.bottomTier.count < b.bottomTier.count ? 1 : -1;
      } else {
        return !a.topTier.count || !b.topTier.count ? 0 : a.topTier.count < b.topTier.count ? 1 : -1;
      }
    });
    for (var i = 0; i < sortedUnitLevels.length; i++) {
      firstValue = sortedUnitLevels[i];
      if (i === sortedUnitLevels.length - 1) {
        level = sortedUnitLevels[i].level;
        break;
      } else {
        secondValue = sortedUnitLevels[i + 1];
      }
      if (count >= firstValue["" + tier].count) {
        currentZoomCollection = sortedUnitLevels[i];
        checkSameCountLevels = sortedUnitLevels.filter(function (tempLevel) {
          if (tier === 'bottomTier') {
            return tempLevel.bottomTier.count === currentZoomCollection.bottomTier.count;
          } else {
            return tempLevel.topTier.count === currentZoomCollection.topTier.count;
          }
        });
        if (checkSameCountLevels.length > 1) {
          level = this.checkCollectionsWidth(checkSameCountLevels);
        } else {
          level = checkSameCountLevels[0].level;
        }
        break;
      } else if (count < firstValue["" + tier].count && count > secondValue["" + tier].count) {
        currentZoomCollection = sortedUnitLevels[i + 1];
        checkSameCountLevels = sortedUnitLevels.filter(function (tempLevel) {
          if (tier === 'bottomTier') {
            return tempLevel.bottomTier.count === currentZoomCollection.bottomTier.count;
          } else {
            return tempLevel.topTier.count === currentZoomCollection.topTier.count;
          }
        });
        if (checkSameCountLevels.length > 1) {
          level = this.checkCollectionsWidth(checkSameCountLevels);
        } else {
          level = checkSameCountLevels[0].level;
        }
        break;
      }
    }
    return level;
  };
  /**
   * Getting closest zooimg level.
   *
   * @param {string} unit .
   * @param {string} closetUnit .
   * @param {boolean} isCont .
   * @returns {string} .
   * @private
   */
  Timeline.prototype.getClosestUnit = function (unit, closetUnit, isCont) {
    var bottomTierUnits = ['Year', 'Month', 'Week', 'Day', 'Hour', 'Minutes'];
    var index = bottomTierUnits.indexOf(unit);
    if (index === 0) {
      isCont = true;
    }
    if (this.isZoomIn || isCont) {
      unit = bottomTierUnits[index + 1];
    } else {
      unit = bottomTierUnits[index - 1];
    }
    var sameUnitLevels = this.parent.zoomingLevels.filter(function (tempLevel) {
      return tempLevel.bottomTier.unit === unit;
    });
    if (sameUnitLevels.length === 0) {
      if (unit === 'Year') {
        isCont = true;
      }
      closetUnit = unit;
      return this.getClosestUnit(unit, closetUnit, isCont);
    } else {
      return unit;
    }
  };
  Timeline.prototype.checkCollectionsWidth = function (checkSameLevels) {
    var zoomLevels = checkSameLevels;
    var width = this.customTimelineSettings.timelineUnitSize;
    var level;
    var secondValue;
    var firstValue;
    var sortedZoomLevels = zoomLevels.sort(function (a, b) {
      return a.timelineUnitSize < b.timelineUnitSize ? 1 : -1;
    });
    for (var i = 0; i < sortedZoomLevels.length; i++) {
      firstValue = sortedZoomLevels[i];
      if (i === sortedZoomLevels.length - 1) {
        level = sortedZoomLevels[i].level;
        break;
      } else {
        secondValue = sortedZoomLevels[i + 1];
      }
      if (width >= firstValue.timelineUnitSize) {
        level = sortedZoomLevels[i].level;
        break;
      } else if (width < firstValue.timelineUnitSize && width > secondValue.timelineUnitSize) {
        level = sortedZoomLevels[i + 1].level;
        break;
      }
    }
    return level;
  };
  /**
   * To create timeline header template.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.updateTimelineHeaderHeight = function () {
    if (this.parent.timelineModule.isSingleTier) {
      this.parent.element.classList.add(ganttSingleTimeline);
    } else {
      this.parent.element.classList.remove(ganttSingleTimeline);
    }
    if (this.previousIsSingleTier !== this.isSingleTier) {
      var toolbarHeight = 0;
      if (!isNullOrUndefined(this.parent.toolbarModule) && !isNullOrUndefined(this.parent.toolbarModule.element)) {
        toolbarHeight = this.parent.toolbarModule.element.offsetHeight;
      }
      this.parent.ganttChartModule.scrollObject.setHeight(this.parent.ganttHeight - this.parent.ganttChartModule.chartTimelineContainer.offsetHeight - toolbarHeight);
      this.parent.treeGrid.height = this.parent.ganttHeight - toolbarHeight - this.parent.ganttChartModule.chartTimelineContainer.offsetHeight;
    }
  };
  Timeline.prototype.dateByLeftValue = function (left, isMilestone, property) {
    var pStartDate = new Date(this.parent.timelineModule.timelineStartDate.toString());
    var milliSecondsPerPixel = 24 * 60 * 60 * 1000 / this.parent.perDayWidth;
    pStartDate.setTime(pStartDate.getTime() + left * milliSecondsPerPixel);
    /* To render the milestone in proper date while editing */
    if (isMilestone && !isNullOrUndefined(property.predecessorsName) && property.predecessorsName !== '') {
      pStartDate.setDate(pStartDate.getDate() - 1);
      var dayEndTime = this.parent['getCurrentDayEndTime'](property.isAutoSchedule ? property.autoEndDate : property.endDate);
      this.parent.dateValidationModule.setTime(dayEndTime, pStartDate);
      pStartDate = this.parent.dateValidationModule.checkStartDate(pStartDate, property, true);
    }
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.topTier : this.parent.timelineModule.bottomTier;
    if (tierMode !== 'Hour' && tierMode !== 'Minutes') {
      if (this.parent.isInDst(new Date(this.parent.timelineModule.timelineStartDate.toString())) && !this.parent.isInDst(pStartDate)) {
        pStartDate.setTime(pStartDate.getTime() + 60 * 60 * 1000);
      } else if (!this.parent.isInDst(new Date(this.parent.timelineModule.timelineStartDate.toString())) && this.parent.isInDst(pStartDate)) {
        pStartDate.setTime(pStartDate.getTime() - 60 * 60 * 1000);
      }
    }
    return pStartDate;
  };
  /**
   * To create timeline header template.
   *
   * @returns {void}
   * @private
   */
  Timeline.prototype.createTimelineSeries = function () {
    var tr;
    var td;
    var div;
    var table;
    var thead;
    var virtualTableDiv;
    var virtualTrackDiv;
    var loopCount = this.isSingleTier ? 1 : 2;
    var tier = this.topTier === 'None' ? 'bottomTier' : 'topTier';
    this.topTierCollection = [];
    this.bottomTierCollection = [];
    if (this.restrictRender === true) {
      this.updateTimelineHeaderHeight();
      this.wholeTimelineWidth = this.calculateWidthBetweenTwoDate(tier, this.parent.timelineModule.timelineStartDate, this.parent.timelineModule.timelineEndDate);
    }
    if (this.parent.enableTimelineVirtualization && this.wholeTimelineWidth > this.parent.element.offsetWidth * 3) {
      for (var count = 0; count < loopCount; count++) {
        table = createElement('table', {
          className: timelineHeaderTableContainer,
          styles: 'display: block;'
        });
        table.setAttribute('role', 'none');
        thead = createElement('thead', {
          className: timelineHeaderTableBody,
          styles: 'display:block; border-collapse:collapse'
        });
        var trTemplate = this.createTimelineTemplate(tier);
        tr = createElement('tr');
        Array.from(trTemplate.childNodes).forEach(function (child) {
          tr.appendChild(child);
        });
        td = createElement('td');
        div = createElement('div', {
          styles: 'width: 20px'
        });
        virtualTableDiv = createElement('div', {
          className: virtualTable
        });
        virtualTrackDiv = createElement('div', {
          className: virtualTrack
        });
        td.appendChild(div);
        tr.appendChild(td);
        virtualTableDiv.appendChild(tr);
        thead.appendChild(virtualTableDiv);
        thead.appendChild(virtualTrackDiv);
        table.appendChild(thead);
        this.parent.ganttChartModule.chartTimelineContainer.appendChild(table);
        tier = 'bottomTier';
        tr = null;
        this.restrictRender = false;
      }
      if (this.parent.height === 'auto' || this.parent.timelineModule.isSingleTier) {
        var timelineContainer = this.parent.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'];
        this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
        if (!isNullOrUndefined(this.parent.element.getElementsByClassName('e-gridcontent')[0])) {
          this.parent.treeGrid.element.getElementsByClassName('e-gridcontent')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
        }
      }
      this.timelineVirtualizationStyles();
    } else {
      for (var count = 0; count < loopCount; count++) {
        table = createElement('table', {
          className: timelineHeaderTableContainer,
          styles: 'display: block;'
        });
        table.setAttribute('role', 'none');
        thead = createElement('thead', {
          className: timelineHeaderTableBody,
          styles: 'display:block; border-collapse:collapse'
        });
        var trTemplate = this.createTimelineTemplate(tier);
        tr = createElement('tr');
        Array.from(trTemplate.childNodes).forEach(function (child) {
          tr.appendChild(child);
        });
        td = createElement('td');
        div = createElement('div', {
          styles: 'width: 20px'
        });
        td.appendChild(div);
        tr.appendChild(td);
        thead.appendChild(tr);
        table.appendChild(thead);
        this.parent.ganttChartModule.chartTimelineContainer.appendChild(table);
        tier = 'bottomTier';
        tr = null;
      }
      this.wholeTimelineWidth = this.totalTimelineWidth;
      if (this.parent.height === 'auto' || this.parent.timelineModule.isSingleTier) {
        var timelineContainer = this.parent.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'];
        this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
        if (!isNullOrUndefined(this.parent.element.getElementsByClassName('e-gridcontent')[0])) {
          this.parent.treeGrid.element.getElementsByClassName('e-gridcontent')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
        }
      }
    }
  };
  Timeline.prototype.timelineVirtualizationStyles = function () {
    var translateXValue = 0;
    var translateYValue = 0;
    var trackWidth = this.wholeTimelineWidth;
    if (this.parent.enableTimelineVirtualization) {
      //e-content styles updating
      translateXValue = this.parent.enableTimelineVirtualization && !isNullOrUndefined(this.parent.ganttChartModule.scrollObject.element.scrollLeft) && this.parent.ganttChartModule.scrollObject.element.scrollLeft !== 0 ? this.parent.ganttChartModule.scrollObject.getTimelineLeft() : 0;
      if (this.parent.enableRtl) {
        translateXValue = -translateXValue;
      }
      var contentVirtualTable = this.parent.element.querySelectorAll('.e-chart-scroll-container')[0].querySelector('.e-virtualtable');
      contentVirtualTable.style.transform = "translate(" + translateXValue + "px, " + translateYValue + "px)";
      var contentVirtualTrack = this.parent.element.querySelectorAll('.e-chart-scroll-container')[0].querySelector('.e-virtualtrack');
      contentVirtualTrack.style.position = 'relative';
      contentVirtualTrack.style.width = trackWidth + 'px';
      //timeline styles updating
      if (this.parent.ganttChartModule.scrollObject['isSetScrollLeft']) {
        var virtualTableStylesT = this.parent.element.querySelectorAll('.e-timeline-header-table-container')[0].querySelector('.e-virtualtable');
        var virtualTableStylesB = void 0;
        if (!isNullOrUndefined(this.parent.element.querySelectorAll('.e-timeline-header-table-container')[1])) {
          virtualTableStylesB = this.parent.element.querySelectorAll('.e-timeline-header-table-container')[1].querySelector('.e-virtualtable');
        }
        virtualTableStylesT.style.transform = "translate(" + translateXValue + "px, " + translateYValue + "px)";
        if (!isNullOrUndefined(virtualTableStylesB)) {
          virtualTableStylesB.style.transform = "translate(" + translateXValue + "px, " + translateYValue + "px)";
        }
      }
      var virtualTrackStylesT = this.parent.element.querySelectorAll('.e-timeline-header-table-container')[0].querySelector('.e-virtualtrack');
      var virtualTrackStylesB = void 0;
      if (!isNullOrUndefined(this.parent.element.querySelectorAll('.e-timeline-header-table-container')[1])) {
        virtualTrackStylesB = this.parent.element.querySelectorAll('.e-timeline-header-table-container')[1].querySelector('.e-virtualtrack');
      }
      if (!isNullOrUndefined(virtualTrackStylesB)) {
        virtualTrackStylesB.style.position = 'relative';
        virtualTrackStylesB.style.width = trackWidth + 'px';
      }
      virtualTrackStylesT.style.position = 'relative';
      virtualTrackStylesT.style.width = trackWidth + 'px';
      //dependency viewer styles updating
      var dependencyViewer = this.parent.connectorLineModule.svgObject;
      dependencyViewer['style'].width = trackWidth + 'px';
      // timeline header container width updating
      var timelineHeader = this.parent.element.querySelector('.' + timelineHeaderContainer);
      timelineHeader['style'].width = 'calc(100% - ' + 17 + 'px)';
      if (this.parent.timelineModule.isZooming || this.parent.timelineModule.isZoomToFit) {
        this.parent.ganttChartModule.scrollElement.scrollLeft = 0;
        this.parent.ganttChartModule.scrollObject.updateChartElementStyles();
      }
    }
  };
  /**
   * To validate timeline tier count.
   *
   * @param {string} mode .
   * @param {number} count .
   * @param {string} tier .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.validateCount = function (mode, count, tier) {
    var tierCount = !isNullOrUndefined(count) && parseInt(count.toString(), 10) > 0 ? parseInt(count.toString(), 10) : 1;
    var timeDifference = Math.abs(this.timelineRoundOffEndDate.getTime() - this.timelineStartDate.getTime());
    var difference;
    switch (mode) {
      case 'Year':
        difference = Math.round(timeDifference / (1000 * 3600 * 24) / (12 * 28));
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
      case 'Month':
        difference = Math.round(timeDifference / (1000 * 3600 * 24) / 28);
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference + 1 : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
      case 'Week':
        difference = Math.round(timeDifference / (1000 * 3600 * 24) / 7);
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
      case 'Day':
        difference = Math.round(timeDifference / (1000 * 3600 * 24));
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
      case 'Hour':
        difference = Math.round(timeDifference / (1000 * 3600));
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
      case 'Minutes':
        difference = Math.round(timeDifference / (1000 * 60));
        tierCount = tierCount <= difference ? tierCount : difference > 0 ? difference : 1;
        if (this.topTier !== 'None' && tier === 'bottomTier') {
          tierCount = this.validateBottomTierCount(mode, tierCount);
        }
        break;
    }
    if (count !== tierCount && this.isZooming && this.parent.toolbarModule && (tier === 'bottomTier' || this.isSingleTier)) {
      if (this.isZoomIn) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomin'], false);
      } else {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_zoomout'], false);
      }
    }
    return tierCount;
  };
  /**
   * To validate bottom tier count.
   *
   * @param {string} mode .
   * @param {number} tierCount .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.validateBottomTierCount = function (mode, tierCount) {
    var count;
    switch (mode) {
      case 'Year':
        count = tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
      case 'Month':
        count = this.topTier === 'Year' ? tierCount <= this.customTimelineSettings.topTier.count * 12 ? tierCount : this.customTimelineSettings.topTier.count * 12 : tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
      case 'Week':
        count = this.topTier === 'Year' ? tierCount <= this.customTimelineSettings.topTier.count * (12 * 4) ? tierCount : this.customTimelineSettings.topTier.count * (12 * 4) : this.topTier === 'Month' ? tierCount <= this.customTimelineSettings.topTier.count * 4 ? tierCount : this.customTimelineSettings.topTier.count * 4 : tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
      case 'Day':
        count = this.topTier === 'Year' ? tierCount <= this.customTimelineSettings.topTier.count * (12 * 28) ? tierCount : this.customTimelineSettings.topTier.count * (12 * 28) : this.topTier === 'Month' ? tierCount <= this.customTimelineSettings.topTier.count * 28 ? tierCount : this.customTimelineSettings.topTier.count * 28 : this.topTier === 'Week' ? tierCount <= this.customTimelineSettings.topTier.count * 7 ? tierCount : this.customTimelineSettings.topTier.count * 7 : tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
      case 'Hour':
        count = this.topTier === 'Year' ? tierCount <= this.customTimelineSettings.topTier.count * (12 * 28 * 24) ? tierCount : this.customTimelineSettings.topTier.count * (12 * 28 * 24) : this.topTier === 'Month' ? tierCount <= this.customTimelineSettings.topTier.count * (28 * 24) ? tierCount : this.customTimelineSettings.topTier.count * (28 * 24) : this.topTier === 'Week' ? tierCount <= this.customTimelineSettings.topTier.count * 7 * 24 ? tierCount : this.customTimelineSettings.topTier.count * 7 * 24 : this.topTier === 'Day' ? tierCount <= this.customTimelineSettings.topTier.count * 24 ? tierCount : this.customTimelineSettings.topTier.count * 24 : tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
      case 'Minutes':
        count = this.topTier === 'Year' ? tierCount <= this.customTimelineSettings.topTier.count * (12 * 28 * 24 * 60) ? tierCount : this.customTimelineSettings.topTier.count * (12 * 28 * 24 * 60) : this.topTier === 'Month' ? tierCount <= this.customTimelineSettings.topTier.count * (28 * 24 * 60) ? tierCount : this.customTimelineSettings.topTier.count * (28 * 24 * 60) : this.topTier === 'Week' ? tierCount <= this.customTimelineSettings.topTier.count * 7 * 24 * 60 ? tierCount : this.customTimelineSettings.topTier.count * 7 * 24 * 60 : this.topTier === 'Day' ? tierCount <= this.customTimelineSettings.topTier.count * 24 * 60 ? tierCount : this.customTimelineSettings.topTier.count * 24 * 60 : this.topTier === 'Hour' ? tierCount <= this.customTimelineSettings.topTier.count * 60 ? tierCount : this.customTimelineSettings.topTier.count * 60 : tierCount <= this.customTimelineSettings.topTier.count ? tierCount : this.customTimelineSettings.topTier.count;
        break;
    }
    return count;
  };
  /**
   * To validate timeline tier format.
   *
   * @param {string} mode .
   * @param {string} format .
   * @returns {string} .
   * @private
   */
  Timeline.prototype.validateFormat = function (mode, format) {
    var tierFormat;
    switch (mode) {
      case 'Week':
        tierFormat = !format ? 'MMM dd, yyyy' : format;
        break;
      case 'Day':
      case 'None':
        tierFormat = !format ? '' : format;
        break;
      case 'Hour':
        tierFormat = !format ? 'H' : format;
        break;
      case 'Month':
        tierFormat = !format ? 'MMM yyyy' : format;
        break;
      case 'Year':
        tierFormat = !format ? 'yyyy' : format;
        break;
      case 'Minutes':
        tierFormat = !format ? 'm' : format;
        break;
    }
    return tierFormat;
  };
  /**
   * To perform extend operation.
   *
   * @param {object} cloneObj .
   * @param {string[]} propertyCollection .
   * @param {object} innerProperty .
   * @returns {object} .
   * @private
   */
  Timeline.prototype.extendFunction = function (cloneObj, propertyCollection, innerProperty) {
    var _this = this;
    var tempObj = {};
    for (var index = 0; index < propertyCollection.length; index++) {
      tempObj[propertyCollection[index]] = cloneObj[propertyCollection[index]];
    }
    if (innerProperty) {
      Object.keys(innerProperty).forEach(function (key) {
        tempObj[key] = _this.extendFunction(cloneObj[key], innerProperty[key], null);
      });
    }
    return tempObj;
  };
  /**
   * To format date.
   *
   * @param {string} dayFormat .
   * @param {Date} data .
   * @returns {string} .
   * @private
   */
  Timeline.prototype.formatDateHeader = function (dayFormat, data) {
    var date = new Date(data.getTime());
    var dateString;
    if (dayFormat === '') {
      dateString = this.parent.globalize.formatDate(date, {
        format: 'E'
      });
      if (this.parent.locale === 'zh') {
        dateString = dateString.slice(1);
      } else {
        if (this.parent.locale === 'ar') {
          var dateStringValue = dateString;
          dateString = dateStringValue;
        } else {
          dateString = dateString.slice(0, 1);
        }
      }
    } else {
      dateString = this.parent.globalize.formatDate(date, {
        format: dayFormat
      });
    }
    return dateString;
  };
  /**
   * Custom Formatting.
   *
   * @param {Date} date .
   * @param {string} format .
   * @param {string} tier .
   * @param {string} mode .
   * @param {string | ITimelineFormatter} formatter .
   * @returns {string} .
   * @private
   */
  Timeline.prototype.customFormat = function (date, format, tier, mode, formatter) {
    formatter = typeof formatter === 'string' ? getValue(formatter, window) : formatter;
    return formatter(date, format, tier, mode);
  };
  /**
   * To create timeline template .
   *
   * @param {string} tier .
   * @returns {string} .
   * @private
   */
  Timeline.prototype.createTimelineTemplate = function (tier) {
    var isFirstCell = false;
    var parent = this.parent;
    var parentTh = createElement('th');
    var parentTr;
    var mode = tier === 'topTier' ? parent.timelineModule.customTimelineSettings.topTier.unit : parent.timelineModule.customTimelineSettings.bottomTier.unit;
    var count = tier === 'topTier' ? parent.timelineModule.customTimelineSettings.topTier.count : parent.timelineModule.customTimelineSettings.bottomTier.count;
    var increment;
    var newTime;
    var leftValueForStartDate = this.parent.enableTimelineVirtualization && this.parent.ganttChartModule.scrollObject.element.scrollLeft !== 0 ? this.parent.ganttChartModule.scrollObject.getTimelineLeft() : null;
    var startDate = this.parent.enableTimelineVirtualization && !isNullOrUndefined(leftValueForStartDate) ? new Date(this.dateByLeftValue(leftValueForStartDate).toString()) : new Date(this.parent.timelineModule.timelineStartDate.toString());
    var endDate = new Date(this.timelineRoundOffEndDate.toString());
    var scheduleDateCollection = [];
    var width = 0;
    var WidthForVirtualTable = this.parent.element.offsetWidth * 3;
    do {
      // PDf export collection
      var timelineCell = {};
      timelineCell.startDate = new Date(startDate.getTime());
      if ((mode === 'Month' || mode === 'Hour') && tier === 'bottomTier' && count !== 1 && scheduleDateCollection.length === 0) {
        isFirstCell = true;
      }
      parentTr = this.getHeaterTemplateString(new Date(startDate.toString()), mode, tier, false, count, timelineCell, isFirstCell);
      scheduleDateCollection.push(new Date(startDate.toString()));
      if (isFirstCell && mode === 'Month') {
        newTime = this.calculateQuarterEndDate(startDate, count).getTime();
      } else {
        increment = Math.abs(this.getIncrement(startDate, count, mode));
        newTime = startDate.getTime() + increment;
      }
      var dubStartDate = new Date(startDate.getTime());
      isFirstCell = false;
      startDate.setTime(newTime);
      var dubStartHour = dubStartDate.getHours();
      var startHour = startDate.getHours();
      var difference = startHour - dubStartHour;
      if (difference !== count && difference > 1) {
        if (!this.parent.isInDst(startDate) && this.parent.isInDst(dubStartDate) || this.parent.isInDst(startDate) && !this.parent.isInDst(dubStartDate)) {
          if (startDate.getTimezoneOffset() > dubStartDate.getTimezoneOffset()) {
            startDate.setTime(startDate.getTime() - 1000 * 60 * 60 * (difference - count));
          }
        }
      }
      if (startDate.getHours() === 5 && count === 2 && tier === 'bottomTier' && parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Hour') {
        startDate.setTime(startDate.getTime() - 1000 * 60 * 60);
      }
      if (startDate.getHours() === 8 && count === 12 && tier === 'bottomTier' && this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Hour') {
        startDate.setTime(startDate.getTime() - 1000 * 60 * 60 * 8);
      }
      if (startDate >= endDate) {
        /* eslint-disable-next-line */
        parentTr = this.getHeaterTemplateString(scheduleDateCollection[scheduleDateCollection.length - 1], mode, tier, true, count, timelineCell);
      }
      parentTh.appendChild(parentTr);
      var tierCollection = tier === 'topTier' ? this.topTierCollection : this.bottomTierCollection;
      timelineCell.endDate = new Date(startDate.getTime());
      if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.enableTimelineVirtualization) {
        if (tier === 'topTier') {
          this.pdfExportTopTierCollection.push(timelineCell);
        } else {
          this.pdfExportBottomTierCollection.push(timelineCell);
        }
      } else {
        tierCollection.push(timelineCell);
      }
      width += timelineCell.width;
      this.weekendEndDate = timelineCell.endDate >= endDate ? endDate : timelineCell.endDate;
    } while (this.parent.enableTimelineVirtualization && (!this.parent.pdfExportModule || this.parent.pdfExportModule && !this.parent.pdfExportModule.isPdfExport) ? width < WidthForVirtualTable && startDate < endDate : startDate < endDate);
    return parentTh;
  };
  Timeline.prototype.updateTimelineAfterZooming = function (endDate, resized) {
    var timeDiff;
    var perDayWidth;
    var totWidth;
    var contentElement = document.getElementsByClassName('e-chart-scroll-container e-content')[0];
    if (!isNullOrUndefined(contentElement)) {
      var contentWidth = contentElement['offsetWidth'];
      var contentHeight = contentElement['offsetHeight'];
      var scrollHeight = document.getElementsByClassName('e-chart-rows-container')[0]['offsetHeight'];
      timeDiff = Math.abs(this.timelineStartDate.getTime() - endDate.getTime());
      timeDiff = timeDiff / (1000 * 3600 * 24);
      if (this.bottomTier === 'None') {
        perDayWidth = this.getPerDayWidth(this.customTimelineSettings.timelineUnitSize, this.customTimelineSettings.topTier.count, this.topTier);
      } else {
        perDayWidth = this.getPerDayWidth(this.customTimelineSettings.timelineUnitSize, this.customTimelineSettings.bottomTier.count, this.bottomTier);
      }
      if (contentHeight < scrollHeight) {
        totWidth = perDayWidth * timeDiff + 17;
      } else {
        totWidth = perDayWidth * timeDiff;
      }
      if (contentWidth >= totWidth) {
        var widthDiff = contentWidth - totWidth;
        widthDiff = Math.round(widthDiff / perDayWidth);
        endDate.setDate(endDate.getDate() + widthDiff);
        this.parent.timelineModule.timelineEndDate = endDate;
        if (resized) {
          this.parent.updateProjectDates(this.timelineStartDate, this.timelineEndDate, this.parent.isTimelineRoundOff);
        }
      }
    }
  };
  Timeline.prototype.getTimelineRoundOffEndDate = function (date) {
    var tierMode = this.topTier === 'None' ? this.bottomTier : this.topTier;
    var endDate = new Date(date.toString());
    if (this.parent.isTimelineRoundOff) {
      if (tierMode === 'Hour') {
        endDate.setMinutes(60);
      } else if (tierMode === 'Minutes') {
        endDate.setSeconds(60);
      } else {
        endDate.setHours(24, 0, 0, 0);
      }
    }
    if (isNullOrUndefined(this.parent.projectEndDate)) {
      this.updateTimelineAfterZooming(endDate, false);
    }
    return endDate;
  };
  /**
   *
   * @param {Date} startDate .
   * @param {number} count .
   * @param {string} mode .
   * @param {boolean} isFirstCell .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.getIncrement = function (startDate, count, mode, isFirstCell) {
    var firstDay = new Date(startDate.getTime());
    var lastDay = new Date(startDate.getTime());
    var increment;
    switch (mode) {
      case 'Year':
        firstDay = startDate;
        lastDay = new Date(startDate.getFullYear() + (count - 1), 11, 31);
        increment = lastDay.getTime() - firstDay.getTime() + 1000 * 60 * 60 * 24;
        break;
      case 'Month':
        firstDay = startDate;
        lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + count, 1);
        increment = lastDay.getTime() - firstDay.getTime();
        break;
      case 'Week':
        {
          var dayIndex = this.parent.timelineModule.customTimelineSettings.weekStartDay;
          var dayIntervel = startDate.getDay() < dayIndex ? dayIndex - startDate.getDay() : 6 - startDate.getDay() + dayIndex;
          count = dayIntervel > 0 ? count - 1 : 0;
          lastDay.setHours(24, 0, 0, 0);
          dayIntervel = startDate.getDay() < dayIndex ? dayIntervel > 0 ? dayIntervel - 1 : dayIntervel : dayIntervel;
          lastDay.setDate(lastDay.getDate() + (dayIntervel + 7 * count));
          increment = lastDay.getTime() - firstDay.getTime();
          break;
        }
      case 'Day':
        lastDay.setHours(24, 0, 0, 0);
        increment = lastDay.getTime() - firstDay.getTime() + 1000 * 60 * 60 * 24 * (count - 1);
        increment = this.checkDate(firstDay, lastDay, increment, count, mode);
        break;
      case 'Hour':
        {
          lastDay.setMinutes(60);
          lastDay.setSeconds(0);
          increment = lastDay.getTime() - firstDay.getTime() + 1000 * 60 * 60 * (count - 1);
          var date = new Date(lastDay);
          date.setTime(date.getTime() + increment);
          if (isFirstCell && count === 12) {
            if (firstDay.getHours() !== 0) {
              date.setHours(0, 0, 0, 0);
              increment = date.getTime() - firstDay.getTime();
            }
          }
          if (isFirstCell && count === 6) {
            if (firstDay.getHours() !== 0) {
              date.setHours(6, 0, 0, 0);
              increment = date.getTime() - firstDay.getTime();
            }
          }
          increment = this.checkDate(firstDay, lastDay, increment, count, mode);
          break;
        }
      case 'Minutes':
        lastDay.setSeconds(60);
        increment = lastDay.getTime() - firstDay.getTime() + 1000 * 60 * (count - 1);
        increment = this.checkDate(firstDay, lastDay, increment, count, mode);
        break;
    }
    return increment;
  };
  Timeline.prototype.checkDate = function (firstDay, lastDay, increment, count, mode) {
    var date = new Date(firstDay.getTime());
    date.setTime(date.getTime() + increment);
    if (mode === 'Day' && count !== 1 && (date.getTime() - lastDay.getTime()) / (1000 * 60 * 60 * 24) !== count && firstDay.getTimezoneOffset() !== date.getTimezoneOffset()) {
      var diffCount = count - (date.getTime() - lastDay.getTime()) / (1000 * 60 * 60 * 24);
      if (!this.parent.isInDst(date)) {
        increment += 1000 * 60 * 60 * diffCount;
      } else if (this.parent.isInDst(date) && count !== 2) {
        increment -= 1000 * 60 * 60 * diffCount;
      }
    } else if (mode === 'Hour' && count !== 1 && (date.getTime() - lastDay.getTime()) / (1000 * 60 * 60) !== count && firstDay.getTimezoneOffset() !== date.getTimezoneOffset()) {
      var diffCount = count - (date.getTime() - lastDay.getTime()) / (1000 * 60 * 60);
      if (!this.parent.isInDst(date)) {
        increment += 1000 * 60 * 60 * diffCount;
      } else if (this.parent.isInDst(date) && count !== 2) {
        increment -= 1000 * 60 * 60 * diffCount;
      }
    } else if (mode === 'Minutes' && count !== 1 && (date.getTime() - lastDay.getTime()) / (1000 * 60) !== count && firstDay.getTimezoneOffset() !== date.getTimezoneOffset()) {
      var diffCount = count - (date.getTime() - lastDay.getTime()) / (1000 * 60);
      if (!this.parent.isInDst(date)) {
        increment += 1000 * 60 * 60 * diffCount;
      } else if (this.parent.isInDst(date) && count !== 2 && count !== 15) {
        increment -= 1000 * 60 * 60 * diffCount;
      }
    }
    return increment;
  };
  /**
   * Method to find header cell was weekend or not
   *
   * @param {string} mode .
   * @param {string} tier .
   * @param {Date} day .
   * @returns {boolean} .
   */
  Timeline.prototype.isWeekendHeaderCell = function (mode, tier, day) {
    return (mode === 'Day' || mode === 'Hour' || mode === 'Minutes') && (this.customTimelineSettings[tier].count === 1 || mode === 'Hour' || mode === 'Minutes') && this.parent.nonWorkingDayIndex.indexOf(day.getDay()) !== -1;
  };
  Timeline.prototype.calculateQuarterEndDate = function (date, count) {
    var month = date.getMonth();
    if (count === 3) {
      if (month >= 0 && month <= 2) {
        return new Date(date.getFullYear(), 3, 1);
      } else if (month >= 3 && month <= 5) {
        return new Date(date.getFullYear(), 6, 1);
      } else if (month >= 6 && month <= 8) {
        return new Date(date.getFullYear(), 9, 1);
      } else {
        return new Date(date.getFullYear() + 1, 0, 1);
      }
    } else {
      if (month >= 0 && month <= 5) {
        return new Date(date.getFullYear(), 6, 1);
      } else {
        return new Date(date.getFullYear() + 1, 0, 1);
      }
    }
  };
  Timeline.prototype.calculateTotalHours = function (mode, count) {
    var totalHour = 0;
    switch (mode) {
      case 'Hour':
        totalHour = 1 * count;
        break;
      case 'Day':
        totalHour = 24 * count;
        break;
      case 'Week':
        totalHour = 7 * 24 * count;
        break;
      case 'Minutes':
        totalHour = count / 60;
        break;
    }
    return totalHour;
  };
  /**
   * To construct template string.
   *
   * @param {Date} scheduleWeeks .
   * @param {string} mode .
   * @param {string} tier .
   * @param {boolean} isLast .
   * @param {number} count .
   * @param {TimelineFormat} timelineCell .
   * @returns {string} .
   * @private
   */
  /* eslint-disable-next-line */
  Timeline.prototype.getHeaterTemplateString = function (scheduleWeeks, mode, tier, isLast, count, timelineCell, isFirstCell) {
    var parentTr = '';
    var template;
    var timelineTemplate = null;
    if (!isNullOrUndefined(this.parent.timelineTemplate)) {
      timelineTemplate = this.parent.chartRowsModule.templateCompiler(this.parent.timelineTemplate);
    }
    var format = tier === 'topTier' ? this.parent.timelineModule.customTimelineSettings.topTier.format : this.parent.timelineModule.customTimelineSettings.bottomTier.format;
    var formatter = tier === 'topTier' ? this.parent.timelineModule.customTimelineSettings.topTier.formatter : this.parent.timelineModule.customTimelineSettings.bottomTier.formatter;
    var thWidth;
    var date = isNullOrUndefined(formatter) ? this.parent.globalize.formatDate(scheduleWeeks, {
      format: this.parent.getDateFormat()
    }) : this.customFormat(scheduleWeeks, format, tier, mode, formatter);
    thWidth = Math.abs(this.getIncrement(scheduleWeeks, count, mode, isFirstCell) / (1000 * 60 * 60 * 24) * this.parent.perDayWidth);
    var incrementValue = this.getIncrement(scheduleWeeks, count, mode);
    var newDate = new Date(scheduleWeeks.getTime() + incrementValue);
    var dubNewDate = new Date(scheduleWeeks.getTime() + 60 * 60 * 1000 * count);
    var newDateOffset = newDate.getTime();
    var dubNewDateOffset = dubNewDate.getTime();
    var timelineStartDate = this.parent.timelineModule.timelineStartDate;
    var timelineStartTime = timelineStartDate.getTime();
    var hasDST = this.parent.dataOperation.hasDSTTransition(scheduleWeeks.getFullYear());
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var transitions;
    var dstStartTime;
    if (hasDST) {
      transitions = this.parent.dataOperation.getDSTTransitions(scheduleWeeks.getFullYear(), timeZone);
      dstStartTime = transitions['dstStart'].getTime();
    }
    if (!this.parent.isInDst(newDate) && this.parent.isInDst(scheduleWeeks) || this.parent.isInDst(newDate) && !this.parent.isInDst(scheduleWeeks) || hasDST && newDateOffset !== dubNewDateOffset && dubNewDateOffset > newDateOffset && timelineStartTime <= dstStartTime) {
      var temp = void 0;
      var totalHour = 0;
      var incrementHour = incrementValue / (1000 * 60 * 60);
      if (!this.parent.isInDst(newDate) && this.parent.isInDst(scheduleWeeks)) {
        totalHour = this.calculateTotalHours(mode, count);
        if (incrementHour !== totalHour && totalHour !== 0 && incrementHour > totalHour) {
          temp = this.getIncrement(scheduleWeeks, count, mode) - 1000 * 60 * 60 * (incrementHour - totalHour);
          thWidth = temp / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
          if (thWidth === 0 && mode === 'Minutes') {
            var perMinuteWidth = this.parent.perDayWidth / 1440;
            thWidth = perMinuteWidth * count;
          }
        }
      } else {
        var zoomOrTimeline = this.parent.currentZoomingLevel ? this.parent.currentZoomingLevel : this.parent.timelineSettings;
        var bottomTierSettings = zoomOrTimeline['bottomTier'] !== null ? zoomOrTimeline['bottomTier'] : zoomOrTimeline['topTier'];
        var bottomTierCountIsOneAndUnitIsHour = bottomTierSettings['count'] === 1 && bottomTierSettings['unit'] === 'Hour';
        totalHour = this.calculateTotalHours(mode, count);
        if (incrementHour !== totalHour && incrementHour < totalHour && !(tier === 'topTier' && bottomTierCountIsOneAndUnitIsHour)) {
          temp = this.getIncrement(scheduleWeeks, count, mode) + 1000 * 60 * 60;
          thWidth = temp / (1000 * 60 * 60 * 24) * this.parent.perDayWidth;
          if (thWidth === 0 && mode === 'Minutes') {
            var perMinuteWidth = this.parent.perDayWidth / 1440;
            thWidth = perMinuteWidth * count;
          }
        }
      }
    }
    var cellWidth = thWidth;
    thWidth = isLast || isFirstCell && mode !== 'Hour' ? isLast ? this.calculateWidthBetweenTwoDate(mode, scheduleWeeks, this.timelineRoundOffEndDate) : this.calculateWidthBetweenTwoDate(mode, scheduleWeeks, this.calculateQuarterEndDate(scheduleWeeks, count)) : thWidth;
    var isWeekendCell = this.isWeekendHeaderCell(mode, tier, scheduleWeeks);
    var textClassName = tier === 'topTier' ? ' e-gantt-top-cell-text' : '';
    if (isFirstCell && scheduleWeeks.getHours() === 20 && count === 12 && tier === 'bottomTier' && this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Hour') {
      scheduleWeeks.setTime(scheduleWeeks.getTime() - 1000 * 60 * 60 * 20);
    }
    var value = isNullOrUndefined(formatter) ? this.formatDateHeader(format, scheduleWeeks) : this.customFormat(scheduleWeeks, format, tier, mode, formatter);
    if (!isNullOrUndefined(timelineTemplate)) {
      var args = {
        date: date,
        tier: tier,
        value: value
      };
      template = timelineTemplate(extend({}, args), this.parent, 'TimelineTemplate', this.parent.chartRowsModule.getTemplateID('TimelineTemplate'), false, undefined, null, this.parent['root']);
    }
    var className = this.parent.timelineModule.isSingleTier ? timelineSingleHeaderCell : timelineTopHeaderCell;
    var weekendClass = isWeekendCell ? ' ' + weekendHeaderCell : '';
    var th = createElement('th', {
      className: "" + className + weekendClass,
      styles: "width:" + thWidth + "px;" + (isWeekendCell && this.customTimelineSettings.weekendBackground ? 'background-color:' + this.customTimelineSettings.weekendBackground + ';' : '')
    });
    th.tabIndex = -1;
    th.setAttribute('aria-label', this.parent.localeObj.getConstant('timelineCell') + " " + date);
    var div = createElement('div', {
      className: "" + timelineHeaderCellLabel + textClassName,
      styles: "width:" + (thWidth - 1) + "px;"
    });
    div.title = this.parent.timelineSettings.showTooltip ? date : '';
    div.textContent = value;
    if (this.parent.isReact && !isNullOrUndefined(template) && template.length > 0) {
      template[0]['style'].width = '100%';
      template[0]['style'].height = '100%';
    }
    if (!isNullOrUndefined(timelineTemplate) && !isNullOrUndefined(template) && template.length > 0) {
      append(template, th);
    } else {
      th.append(div);
    }
    /* eslint-disable-next-line */
    parentTr += th.outerHTML;
    if ((this.isSingleTier || tier === 'topTier') && !isLast) {
      this.totalTimelineWidth = this.totalTimelineWidth + thWidth;
    } else if ((this.isSingleTier || tier === 'topTier') && isLast) {
      this.totalTimelineWidth = this.totalTimelineWidth - cellWidth + thWidth;
    }
    // PDf export collection
    timelineCell.value = value;
    timelineCell.isWeekend = isWeekendCell;
    timelineCell.width = thWidth;
    return th;
  };
  /**
   * To calculate last 'th' width.
   *
   * @param {string} mode .
   * @param {Date} scheduleWeeks .
   * @param {Date} endDate .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.calculateWidthBetweenTwoDate = function (mode, scheduleWeeks, endDate) {
    var sDate = new Date(scheduleWeeks.getTime());
    var eDate = new Date(endDate.getTime());
    this.parent.dateValidationModule['updateDateWithTimeZone'](sDate, eDate);
    var timeDifference = eDate.getTime() - sDate.getTime();
    var balanceDay = timeDifference / (1000 * 60 * 60 * 24);
    return balanceDay * this.parent.perDayWidth;
  };
  /**
   * To calculate timeline width.
   *
   * @returns {void} .
   * @private
   */
  Timeline.prototype.timelineWidthCalculation = function () {
    var timelineUnitSize = this.customTimelineSettings.timelineUnitSize;
    var bottomTierCount = this.customTimelineSettings.bottomTier.count;
    var topTierCount = this.customTimelineSettings.topTier.count;
    this.bottomTierCellWidth = timelineUnitSize;
    if (this.bottomTier === 'None') {
      this.parent.perDayWidth = this.getPerDayWidth(timelineUnitSize, topTierCount, this.topTier);
    } else {
      this.parent.perDayWidth = this.getPerDayWidth(timelineUnitSize, bottomTierCount, this.bottomTier);
    }
    this.topTierCellWidth = this.bottomTier !== 'None' ? this.topTier === 'Week' ? this.parent.perDayWidth * 7 : this.topTier === 'Hour' ? this.parent.perDayWidth / 24 : this.topTier === 'Minutes' ? this.parent.perDayWidth / (24 * 60) : this.parent.perDayWidth : timelineUnitSize;
    this.topTierCellWidth = this.isSingleTier ? this.topTierCellWidth : this.topTierCellWidth * topTierCount;
  };
  /**
   * To validate per day width.
   *
   * @param {number} timelineUnitSize .
   * @param {number} bottomTierCount .
   * @param {string} mode .
   * @returns {number} .
   * @private
   */
  Timeline.prototype.getPerDayWidth = function (timelineUnitSize, bottomTierCount, mode) {
    var perDayWidth;
    switch (mode) {
      case 'Year':
        perDayWidth = timelineUnitSize / bottomTierCount / (12 * 28);
        break;
      case 'Month':
        perDayWidth = timelineUnitSize / bottomTierCount / 28;
        break;
      case 'Week':
        perDayWidth = timelineUnitSize / bottomTierCount / 7;
        break;
      case 'Day':
        perDayWidth = timelineUnitSize / bottomTierCount;
        break;
      case 'Hour':
        perDayWidth = 24 / bottomTierCount * timelineUnitSize;
        break;
      case 'Minutes':
        perDayWidth = 60 * 24 / bottomTierCount * timelineUnitSize;
        break;
    }
    return perDayWidth;
  };
  /**
   * To validate project start date and end date.
   *
   * @returns {void} .
   * @private
   */
  Timeline.prototype.roundOffDays = function () {
    var startDate = this.parent.cloneProjectStartDate;
    var endDate = this.parent.cloneProjectEndDate;
    var tierMode = this.topTier === 'None' ? this.bottomTier : this.topTier;
    if (this.parent.isTimelineRoundOff) {
      if (tierMode === 'Year') {
        startDate = new Date(startDate.getFullYear(), 0, 1);
        endDate = new Date(endDate.getFullYear(), 11, 31);
      } else if (tierMode === 'Month') {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
      } else if (tierMode === 'Week') {
        var dayIndex = !isNullOrUndefined(this.customTimelineSettings.weekStartDay) ? this.parent.timelineModule.customTimelineSettings.weekStartDay : 0;
        var roundOffStartDate = startDate.getDay() < dayIndex ? startDate.getDate() - (7 - dayIndex + startDate.getDay()) : startDate.getDate() - startDate.getDay() + dayIndex;
        startDate.setDate(roundOffStartDate);
        var first = endDate.getDate() - endDate.getDay();
        var last = first + 6 + dayIndex;
        endDate.setDate(last);
      }
      if (tierMode === 'Hour') {
        startDate.setMinutes(0);
      } else if (tierMode === 'Minutes') {
        startDate.setSeconds(0);
      } else {
        startDate.setHours(0, 0, 0, 0);
      }
    }
    this.timelineStartDate = startDate;
    this.timelineEndDate = endDate;
    this.timelineRoundOffEndDate = this.getTimelineRoundOffEndDate(this.timelineEndDate);
  };
  /**
   * To validate project start date and end date.
   *
   * @param {string} mode .
   * @param {string} span .
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @returns {void} .
   * @private
   */
  Timeline.prototype.updateScheduleDatesByToolBar = function (mode, span, startDate, endDate) {
    if (mode === 'Year') {
      if (span === 'prevTimeSpan') {
        if (startDate.getMonth() === 0) {
          startDate = new Date(startDate.getFullYear() - 1, 0, 1);
        } else {
          startDate = new Date(startDate.getFullYear(), 0, 1);
        }
      } else {
        if (endDate.getMonth() === 11) {
          endDate = new Date(endDate.getFullYear() + 1, 0, 1);
        } else {
          endDate = new Date(endDate.getFullYear(), 12, 1);
        }
      }
    }
    if (mode === 'Month') {
      if (span === 'prevTimeSpan') {
        if (startDate.getDate() === 1) {
          startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        } else {
          startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        }
      } else {
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1);
      }
    }
    if (mode === 'Week') {
      var dayIndex = this.parent.timelineModule.customTimelineSettings.weekStartDay;
      var dayIntervel = void 0;
      if (span === 'prevTimeSpan') {
        dayIntervel = startDate.getDay() < dayIndex ? 7 - (dayIndex - startDate.getDay()) : startDate.getDay() - dayIndex;
        startDate.setHours(0, 0, 0, 0);
        if (dayIntervel === 0) {
          startDate.setDate(startDate.getDate() - 7);
        } else {
          startDate.setDate(startDate.getDate() - dayIntervel);
        }
      } else {
        dayIntervel = endDate.getDay() < dayIndex ? dayIndex - endDate.getDay() : 7 - endDate.getDay() + dayIndex;
        endDate.setHours(0, 0, 0, 0);
        if (dayIntervel === 0) {
          endDate.setDate(endDate.getDate() + 6);
        } else {
          endDate.setDate(endDate.getDate() + dayIntervel);
        }
      }
    }
    if (mode === 'Day') {
      if (span === 'prevTimeSpan') {
        if (startDate.getHours() === 0) {
          startDate.setTime(startDate.getTime() - 1000 * 60 * 60 * 24);
        } else {
          startDate.setHours(0);
        }
      } else {
        if (endDate.getHours() === 0) {
          endDate.setTime(endDate.getTime() + 1000 * 60 * 60 * 24);
        } else {
          endDate.setHours(24);
        }
      }
    }
    if (mode === 'Hour') {
      if (span === 'prevTimeSpan') {
        if (startDate.getMinutes() === 0) {
          startDate.setTime(startDate.getTime() - 1000 * 60 * 60);
        } else {
          startDate.setMinutes(0);
        }
      } else {
        if (endDate.getMinutes() === 0) {
          endDate.setTime(endDate.getTime() + 1000 * 60 * 60);
        } else {
          endDate.setMinutes(60);
        }
      }
    }
    if (mode === 'Minutes') {
      if (span === 'prevTimeSpan') {
        if (startDate.getSeconds() === 0) {
          startDate.setTime(startDate.getTime() - 1000 * 60);
        } else {
          startDate.setSeconds(0);
        }
      } else {
        if (endDate.getSeconds() === 0) {
          endDate.setTime(endDate.getTime() + 1000 * 60);
        } else {
          endDate.setSeconds(60);
        }
      }
    }
    this.parent.cloneProjectStartDate = startDate;
    this.parent.cloneProjectEndDate = endDate;
  };
  /**
   * To validate project start date and end date.
   *
   * @param {IGanttData[]} tempArray .
   * @param {string} action .
   * @returns {void} .
   * @private
   */
  Timeline.prototype.updateTimeLineOnEditing = function (tempArray, action) {
    if (tempArray[0].length >= 1) {
      for (var i = 0; i < tempArray.length; i++) {
        var temp = tempArray[parseInt(i.toString(), 10)];
        var filteredStartDateRecord = temp.filter(function (pdc) {
          return !isNullOrUndefined(pdc.ganttProperties.startDate);
        });
        var filteredEndDateRecord = temp.filter(function (pdc) {
          return !isNullOrUndefined(pdc.ganttProperties.endDate);
        });
        var minStartDate = filteredStartDateRecord.length > 0 ? new Date(DataUtil.aggregates.min(filteredStartDateRecord, 'ganttProperties.startDate')) : null;
        var minEndDate = filteredStartDateRecord.length > 0 ? new Date(DataUtil.aggregates.min(filteredEndDateRecord, 'ganttProperties.endDate')) : null;
        var maxEndDate = filteredEndDateRecord.length > 0 ? new Date(DataUtil.aggregates.max(filteredEndDateRecord, 'ganttProperties.endDate')) : null;
        var validStartDate = new Date(this.parent.dataOperation.checkStartDate(this.timelineStartDate).getTime());
        var validEndDate = new Date(this.parent.dataOperation.checkEndDate(this.timelineEndDate).getTime());
        var maxStartLeft = isNullOrUndefined(minStartDate) ? null : this.parent.dataOperation.getTaskLeft(minStartDate, false);
        var maxEndLeft = isNullOrUndefined(maxEndDate) ? null : this.parent.dataOperation.getTaskLeft(maxEndDate, false);
        var validStartLeft = this.parent.dataOperation.getTaskLeft(validStartDate, false);
        var validEndLeft = this.parent.dataOperation.getTaskLeft(validEndDate, false);
        var isChanged = void 0;
        var taskbarModule = this.parent.editModule.taskbarEditModule;
        var startDate = filteredStartDateRecord.length > 0 ? new Date(DataUtil.aggregates.min(filteredStartDateRecord, 'ganttProperties.startDate')).setHours(0, 0, 0, 0) : null;
        var timelineStartDate = new Date(this.timelineStartDate.getTime()).setHours(0, 0, 0, 0);
        if (!isNullOrUndefined(maxStartLeft) && !isNullOrUndefined(taskbarModule) && (taskbarModule.taskBarEditAction && taskbarModule.taskBarEditAction !== 'RightResizing' || isNullOrUndefined(taskbarModule.taskBarEditAction)) && startDate <= timelineStartDate && (maxStartLeft < this.bottomTierCellWidth || maxStartLeft <= validStartLeft)) {
          isChanged = 'prevTimeSpan';
          minStartDate = minStartDate > this.timelineStartDate ? this.timelineStartDate : minStartDate;
        } else {
          minStartDate = this.timelineStartDate;
        }
        if (!isNullOrUndefined(maxEndLeft) && maxEndLeft >= (this.parent.enableTimelineVirtualization ? this.wholeTimelineWidth : this.totalTimelineWidth) - this.bottomTierCellWidth && maxEndLeft > validEndLeft) {
          isChanged = isChanged === 'prevTimeSpan' ? 'both' : 'nextTimeSpan';
          maxEndDate = maxEndDate < this.timelineEndDate ? this.timelineEndDate : maxEndDate;
        } else {
          maxEndDate = this.timelineEndDate;
        }
        if (isChanged) {
          this.performTimeSpanAction(isChanged, action, minStartDate, maxEndDate);
        } else if (!isNullOrUndefined(temp[0].ganttProperties.segments)) {
          this.parent.dataOperation.updateWidthLeft(temp[0]);
        }
        if (!isNullOrUndefined(minStartDate) && !isNullOrUndefined(minEndDate) && minEndDate <= minStartDate && (action === 'CellEditing' || action === 'DialogEditing') && this.parent.allowUnscheduledTasks) {
          minStartDate = new Date(Math.min(minStartDate.getTime(), minEndDate.getTime()));
          minStartDate = new Date(Math.min(minStartDate.getTime(), this.timelineStartDate.getTime()));
          this.performTimeSpanAction('prevTimeSpan', action, minStartDate, maxEndDate);
        } else if (isNullOrUndefined(minEndDate) && minEndDate <= minStartDate && (action === 'CellEditing' || action === 'DialogEditing') && this.parent.allowUnscheduledTasks && tempArray[0].length === 1 && !isNullOrUndefined(tempArray[0][0].ganttProperties.endDate) && isNullOrUndefined(temp[0].ganttProperties.startDate) && temp[0].ganttProperties.endDate <= this.timelineStartDate) {
          minStartDate = new Date(Math.min(tempArray[0][0].ganttProperties.endDate.getTime(), minStartDate.getTime()));
          this.performTimeSpanAction('prevTimeSpan', action, minStartDate, maxEndDate);
        }
        break;
      }
    }
  };
  /**
   * To validate project start date and end date on editing action
   *
   * @param {string} type .
   * @param {string} isFrom .
   * @param {Date} startDate .
   * @param {Date} endDate .
   * @param {string} mode .
   * @returns {void} .
   * @private
   */
  Timeline.prototype.performTimeSpanAction = function (type, isFrom, startDate, endDate, mode) {
    mode = !isNullOrUndefined(mode) ? mode : this.parent.timelineModule.topTier === 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    var projectStartDate = new Date(this.parent.cloneProjectStartDate.getTime());
    var projectEndDate = new Date(this.parent.cloneProjectEndDate.getTime());
    if (isFrom !== 'publicMethod' && type === 'both') {
      this.updateScheduleDatesByToolBar(mode, 'prevTimeSpan', startDate, endDate);
      this.updateScheduleDatesByToolBar(mode, 'nextTimeSpan', new Date(this.parent.cloneProjectStartDate.getTime()), endDate);
    } else {
      this.updateScheduleDatesByToolBar(mode, type, startDate, endDate);
    }
    var args = this.timeSpanActionEvent('actionBegin', type, isFrom);
    if (!args.cancel) {
      this.restrictRender = true;
      this.performedTimeSpanAction = true;
      var previousScrollLeft = this.parent.ganttChartModule.scrollElement.scrollLeft;
      this.parent.updateProjectDates(args.projectStartDate, args.ProjectEndDate, args.isTimelineRoundOff, isFrom);
      if (type === 'prevTimeSpan' && isFrom === 'publicMethod') {
        this.parent.ganttChartModule.updateScrollLeft(0);
        this.parent.timelineModule.isZoomToFit = false;
      } else if (type === 'nextTimeSpan' && isFrom === 'publicMethod') {
        this.parent.ganttChartModule.updateScrollLeft(this.parent.enableTimelineVirtualization ? this.wholeTimelineWidth : this.totalTimelineWidth);
        this.parent.timelineModule.isZoomToFit = false;
      } else if (type === 'nextTimeSpan' && isFrom === 'TaskbarEditing') {
        var currentScrollLeft = document.getElementsByClassName('e-chart-scroll-container e-content')[0].scrollLeft;
        this.parent.element.querySelector('.e-timeline-header-container').scrollLeft = currentScrollLeft;
        this.parent.timelineModule.isZoomToFit = false;
      }
      if (isFrom === 'TaskbarEditing' && this.parent.enableTimelineVirtualization && (this.wholeTimelineWidth > this.parent.element.offsetWidth * 3 || isNullOrUndefined(this.parent.projectStartDate) && isNullOrUndefined(this.parent.projectEndDate))) {
        this.parent.ganttChartModule.scrollObject.setScrollLeft(previousScrollLeft);
        this.parent.ganttChartModule.scrollObject.updateContent();
      }
      this.parent.timelineModule.timeSpanActionEvent('actionComplete', type, isFrom);
    } else {
      this.parent.cloneProjectStartDate = projectStartDate;
      this.parent.cloneProjectEndDate = projectEndDate;
    }
  };
  /**
   * To validate project start date and end date.
   *
   * @param {string} eventType .
   * @param {string} requestType .
   * @param {string} isFrom .
   * @returns {void}
   * @private
   */
  Timeline.prototype.timeSpanActionEvent = function (eventType, requestType, isFrom) {
    var args = {};
    args.projectStartDate = new Date(this.parent.cloneProjectStartDate.getTime());
    args.ProjectEndDate = new Date(this.parent.cloneProjectEndDate.getTime());
    args.requestType = isFrom === 'publicMethod' ? requestType : isFrom === 'beforeAdd' ? 'TimelineRefreshOnAdd' : isFrom === 'TaskbarEditing' ? 'TimelineRefreshOnEdit' : requestType;
    if (eventType === 'actionBegin') {
      args.isTimelineRoundOff = this.parent.isTimelineRoundOff;
      args.cancel = false;
    }
    args.action = 'TimescaleUpdate';
    this.parent.trigger(eventType, args);
    return args;
  };
  return Timeline;
}();

/**
 * Configures column collection in Gantt.
 */
var Column = /** @__PURE__ @class */function () {
  function Column(options) {
    /**
     * If `allowEditing` set to false, then it disables editing of a particular column.
     * By default, all columns are editable.
     *
     * @default true
     */
    this.allowEditing = true;
    /**
     * If `allowReordering` set to false, then it disables reorder of a particular column.
     * By default, all columns can be reorder.
     *
     * @default true
     */
    this.allowReordering = true;
    /**
     * If `allowResizing` is set to false, it disables resize option of a particular column.
     * By default, all the columns can be resized.
     *
     * @default true
     */
    this.allowResizing = true;
    /**
     * If `allowSorting` set to false, then it disables sorting option of a particular column.
     * By default, all columns are sortable.
     *
     * @default true
     */
    this.allowSorting = true;
    /**
     * If `allowFiltering` set to false, then it disables filtering option and filter bar element of a particular column.
     * By default, all columns are filterable.
     *
     * @default true
     */
    this.allowFiltering = true;
    /**
     * Defines the `IEditCell` object to customize default edit cell.
     *
     * @default {}
     */
    this.edit = {};
    merge(this, options);
  }
  return Column;
}();

/** @hidden */
var GanttTreeGrid = /** @__PURE__ @class */function () {
  function GanttTreeGrid(parent) {
    var _this = this;
    this.isPersist = false;
    this.previousScroll = {
      top: 0,
      left: 0
    };
    this.queryCellInfo = function (args) {
      _this.parent.trigger('queryCellInfo', args);
    };
    this.headerCellInfo = function (args) {
      _this.parent.trigger('headerCellInfo', args);
    };
    this.rowDataBound = function (args) {
      _this.parent.trigger('rowDataBound', args);
    };
    this.columnMenuOpen = function (args) {
      _this.parent.notify('columnMenuOpen', args);
      _this.parent.trigger('columnMenuOpen', args);
      document.querySelector('.e-colmenu').addEventListener('mousemove', function () {
        var filPopOptions = document.querySelector('.e-filter-popup');
        var filOptions = document.querySelector('.e-filter-item');
        if (!isNullOrUndefined(filPopOptions)) {
          if (!filOptions.classList.contains('e-focused')) {
            remove(_this.parent.filterModule.filterMenuElement);
          }
        }
      });
    };
    this.columnMenuClick = function (args) {
      _this.parent.trigger('columnMenuClick', args);
    };
    this.objectEqualityChecker = function (old, current) {
      if (old) {
        var keys = Object.keys(old);
        var isEqual = true;
        var excludeKeys = ['Children', 'childRecords', 'taskData', 'uniqueID', 'parentItem', 'parentUniqueID', 'ganttProperties'];
        for (var i = 0; i < keys.length; i++) {
          var oldKey = old[keys[parseInt(i.toString(), 10)]] instanceof Date ? new Date(old[keys[parseInt(i.toString(), 10)]]).getTime() : old[keys[parseInt(i.toString(), 10)]];
          var currentKey = current[keys[parseInt(i.toString(), 10)]] instanceof Date ? new Date(current[keys[parseInt(i.toString(), 10)]]).getTime() : current[keys[parseInt(i.toString(), 10)]];
          if (oldKey !== currentKey && excludeKeys.indexOf(keys[i]) === -1) {
            _this.parent.modifiedRecords.push(current);
            isEqual = false;
            break;
          }
        }
        return isEqual;
      } else {
        return false;
      }
    };
    this.parent = parent;
    this.parent.treeGrid = new TreeGrid();
    this.parent.treeGrid.allowSelection = false;
    this.parent.treeGrid.allowKeyboard = this.parent.allowKeyboard;
    this.parent.treeGrid['${enableHtmlSanitizer}'] = this.parent.enableHtmlSanitizer;
    this.parent.treeGrid.enableImmutableMode = this.parent.enableImmutableMode;
    this.treeGridColumns = [];
    if (!this.parent.isLocaleChanged && this.parent.isLoad) {
      this.parent.previousGanttColumns = extend([], [], this.parent.columns, true);
    }
    this.validateGanttColumns();
    if (this.parent.isLocaleChanged) {
      this.parent.isLocaleChanged = false;
    }
    this.addEventListener();
  }
  GanttTreeGrid.prototype.addEventListener = function () {
    this.parent.on('renderPanels', this.createContainer, this);
    this.parent.on('chartScroll', this.updateScrollTop, this);
    this.parent.on('destroy', this.destroy, this);
    this.parent.treeGrid.on('renderReactTemplate', this.renderReactTemplate, this);
  };
  GanttTreeGrid.prototype.renderReactTemplate = function (args) {
    var portals = 'portals';
    for (var _i = 0, args_1 = args; _i < args_1.length; _i++) {
      var portal = args_1[_i];
      if (this.parent[portals].indexOf(portal) === -1) {
        this.parent[portals].push(portal);
      }
    }
    this.parent.renderTemplates();
  };
  GanttTreeGrid.prototype.createContainer = function () {
    //let height: number = this.parent.ganttHeight - this.parent.toolbarModule.element.offsetHeight - 46;
    this.treeGridElement = createElement('div', {
      id: 'treeGrid' + this.parent.element.id,
      className: 'e-gantt-tree-grid'
      //  styles: 'height:' + height + 'px;'
    });
    var tempHeader = createElement('div', {
      className: 'e-gantt-temp-header'
    });
    this.parent.treeGridPane.appendChild(this.treeGridElement);
    this.treeGridElement.appendChild(tempHeader);
    this.parent.treeGridPane.classList.add('e-temp-content');
  };
  /**
   * Method to initiate TreeGrid
   *
   * @returns {void} .
   */
  GanttTreeGrid.prototype.renderTreeGrid = function () {
    this.composeProperties();
    this.bindEvents();
    var root = 'root';
    this.parent.treeGrid[root] = this.parent[root] ? this.parent[root] : this.parent;
    setValue('registeredTemplate', this.registeredTemplate, this.parent.treeGrid);
    var ref = 'viewContainerRef';
    setValue('viewContainerRef', this.parent["" + ref], this.parent.treeGrid);
    this.parent.treeGrid.appendTo(this.treeGridElement);
    if (this.parent.treeGrid.grid && this.parent.toolbarModule && this.parent.isReact) {
      this.parent.treeGrid.grid.portals = this.parent.portals;
    }
    this.wireEvents();
  };
  GanttTreeGrid.prototype.composeProperties = function () {
    this.parent.treeGrid.enableAdaptiveUI = this.parent.enableAdaptiveUI;
    this.parent.treeGrid.hasChildMapping = this.parent.taskFields.hasChildMapping;
    this.parent.treeGrid.query = this.parent.query;
    this.parent.treeGrid.loadChildOnDemand = this.parent.loadChildOnDemand;
    this.parent.treeGrid['isFromGantt'] = true;
    this.parent.treeGrid.parentIdMapping = this.parent.taskFields.parentID;
    if (this.parent.taskFields.parentID) {
      this.parent.treeGrid.idMapping = this.parent.taskFields.id;
    }
    this.parent.treeGrid.showColumnMenu = this.parent.showColumnMenu;
    this.parent.treeGrid.enableCollapseAll = this.parent.collapseAllParentTasks;
    this.parent.treeGrid.columnMenuItems = this.parent.columnMenuItems;
    this.parent.treeGrid.enableRtl = this.parent.enableRtl;
    this.parent.treeGrid.childMapping = isNullOrUndefined(this.parent.taskFields.child) ? '' : this.parent.taskFields.child;
    this.parent.treeGrid.treeColumnIndex = this.parent.treeColumnIndex;
    this.parent.treeGrid.columns = this.treeGridColumns;
    this.parent.treeGrid.loadingIndicator = this.parent.loadingIndicator;
    this.parent.treeGrid.enableVirtualMaskRow = this.parent.enableVirtualMaskRow;
    if (this.parent.dataSource instanceof Object && isCountRequired(this.parent)) {
      // In order to bind the observable data at load time, hasChildMapping is necessary to be mapped.
      this.parent.treeGrid.hasChildMapping = 'isParent';
      var count = getValue('count', this.parent.dataSource);
      this.parent.treeGrid.dataSource = {
        result: this.parent.flatData,
        count: count
      };
    } else {
      if (this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
        this.parent.treeGrid.dataSource = this.parent.dataSource;
      } else {
        this.parent.treeGrid.hasChildMapping = null;
        this.parent.treeGrid.dataSource = this.parent.flatData;
      }
    }
    this.parent.treeGrid.expandStateMapping = this.parent.taskFields.expandState;
    var isGantt = 'isGantt';
    this.parent.treeGrid[isGantt] = true;
    this.parent.treeGrid.rowHeight = this.parent.rowHeight;
    this.parent.treeGrid.gridLines = this.parent.gridLines;
    if (this.parent.searchSettings.fields.length !== 0 || this.parent.searchSettings.key !== '') {
      this.parent.treeGrid.searchSettings = this.parent.searchSettings;
    }
    var isJsComponent = 'isJsComponent';
    this.parent.treeGrid[isJsComponent] = true;
    this.parent.treeGrid.height = this.parent.element.getElementsByClassName('e-chart-scroll-container e-content')[0]['offsetHeight'] - (this.parent.flatData.length === 0 ? 0 : 19);
  };
  GanttTreeGrid.prototype.getContentDiv = function () {
    return this.treeGridElement.querySelector('.e-content');
  };
  GanttTreeGrid.prototype.getHeaderDiv = function () {
    return this.treeGridElement.querySelector('.e-headercontent');
  };
  GanttTreeGrid.prototype.getScrollbarWidth = function () {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    var inner = document.createElement('div');
    outer.appendChild(inner);
    this.parent.element.appendChild(outer);
    var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return scrollbarWidth;
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttTreeGrid.prototype.ensureScrollBar = function () {
    var content = this.getContentDiv();
    var headerDiv = this.getHeaderDiv();
    var scrollWidth = this.getScrollbarWidth();
    var isMobile = /Android|Mac|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // For Firefox scrollWidth value return 0, causes the grid side column misalign- Task(888356)
    if (scrollWidth !== 0 || navigator.userAgent.includes('Firefox')) {
      content.style.cssText += 'width: calc(100% + ' + (scrollWidth + 1) + 'px);'; //actual scrollbar width 17 px but here scrollbar width set to 16px hence adding increment of 1
    } else {
      content.classList.add('e-gantt-scroll-padding');
    }
    if (scrollWidth === 0 && isMobile) {
      headerDiv.style.cssText += 'width: calc(100% + 17px);';
    }
  };
  GanttTreeGrid.prototype.bindEvents = function () {
    this.parent.treeGrid.dataBound = this.dataBound.bind(this);
    this.parent.treeGrid.collapsing = this.collapsing.bind(this);
    this.parent.treeGrid.collapsed = this.collapsed.bind(this);
    this.parent.treeGrid.expanding = this.expanding.bind(this);
    this.parent.treeGrid.expanded = this.expanded.bind(this);
    this.parent.treeGrid.actionBegin = this.actionBegin.bind(this);
    this.parent.treeGrid.actionComplete = this.treeActionComplete.bind(this);
    this.parent.treeGrid.created = this.created.bind(this);
    this.parent.treeGrid.actionFailure = this.actionFailure.bind(this);
    this.parent.treeGrid.queryCellInfo = this.queryCellInfo.bind(this);
    this.parent.treeGrid.headerCellInfo = this.headerCellInfo.bind(this);
    this.parent.treeGrid.rowDataBound = this.rowDataBound.bind(this);
    this.parent.treeGrid.columnMenuOpen = this.columnMenuOpen.bind(this);
    this.parent.treeGrid.columnMenuClick = this.columnMenuClick.bind(this);
    this.parent.treeGrid.beforeDataBound = this.beforeDataBound.bind(this);
    this.parent.treeGrid.dataStateChange = this.dataStateChange.bind(this);
  };
  GanttTreeGrid.prototype.beforeDataBound = function (args) {
    if (!isNullOrUndefined(this.parent.selectionModule) && this.parent.selectionSettings && this.parent.selectionSettings.persistSelection && this.parent.selectionModule.getSelectedRowIndexes().length > 0 && args['actionArgs']['requestType'] === 'sorting') {
      this.isPersist = true;
    }
    this.parent.updatedRecords = this.parent.virtualScrollModule && this.parent.enableVirtualization ? getValue('virtualScrollModule.visualData', this.parent.treeGrid) : getValue('result', args);
    if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
      this.parent.updateContentHeight(args);
    }
    setValue('contentModule.objectEqualityChecker', this.objectEqualityChecker, this.parent.treeGrid.grid);
  };
  GanttTreeGrid.prototype.dataBound = function (args) {
    this.ensureScrollBar();
    this.parent.treeDataBound(args);
    if (this.parent.isVirtualScroll) {
      if (this.parent.enableVirtualMaskRow && this.parent.enableVirtualization || this.parent.enableVirtualization && !this.parent.enableVirtualMaskRow && this.parent.loadingIndicator.indicatorType === 'Shimmer' || this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.hideMaskRow();
      }
      if (this.parent.editModule && this.parent.editModule.cellEditModule) {
        this.parent.editModule.cellEditModule.isCellEdit = false;
      }
      this.parent.isVirtualScroll = false;
      if (!isNullOrUndefined(this.parent.selectionModule) && !isNullOrUndefined(this.parent.toolbarModule)) {
        this.parent.toolbarModule.refreshToolbarItems();
      }
    }
    this.prevCurrentView = extend([], [], this.parent.currentViewData, true);
  };
  GanttTreeGrid.prototype.dataStateChange = function (args) {
    if (args.action && args.action.requestType === 'refresh') {
      this.parent.treeGrid.dataSource = {
        result: getValue('result', this.parent.treeGrid.dataSource),
        count: getValue('count', this.parent.treeGrid.dataSource)
      };
    }
    this.parent.trigger('dataStateChange', args);
  };
  GanttTreeGrid.prototype.collapsing = function (args) {
    // Collapsing event
    var collapsingArgs;
    var record = getValue('data', args);
    var recordLength = record.length;
    if (!this.parent.ganttChartModule.isExpandCollapseFromChart) {
      if (!isNullOrUndefined(recordLength)) {
        for (var i = 0; i < recordLength; i++) {
          collapsingArgs = this.createExpandCollapseArgs(args, record[i]);
          this.parent.ganttChartModule.collapseGanttRow(collapsingArgs);
        }
        setValue('cancel', getValue('cancel', collapsingArgs), args);
      } else {
        collapsingArgs = this.createExpandCollapseArgs(args, null);
        this.parent.ganttChartModule.collapseGanttRow(collapsingArgs);
        setValue('cancel', getValue('cancel', collapsingArgs), args);
      }
    }
  };
  GanttTreeGrid.prototype.expanding = function (args) {
    // Expanding event
    var expandingArgs;
    var record = getValue('data', args);
    var recordLength = record.length;
    if (!this.parent.ganttChartModule.isExpandCollapseFromChart) {
      if (!isNullOrUndefined(recordLength)) {
        for (var i = 0; i < recordLength; i++) {
          expandingArgs = this.createExpandCollapseArgs(args, record[i]);
          this.parent.ganttChartModule.expandGanttRow(expandingArgs);
        }
        setValue('cancel', getValue('cancel', expandingArgs), args);
      } else {
        expandingArgs = this.createExpandCollapseArgs(args, null);
        this.parent.ganttChartModule.expandGanttRow(expandingArgs);
        setValue('cancel', getValue('cancel', expandingArgs), args);
      }
    }
  };
  GanttTreeGrid.prototype.collapsed = function (args) {
    if (!this.parent.ganttChartModule.isExpandCollapseFromChart && !this.parent.isExpandCollapseLevelMethod) {
      var collapsedArgs = void 0;
      var record = getValue('data', args);
      var recordLength = record.length;
      if (!isNullOrUndefined(recordLength)) {
        for (var i = 0; i < recordLength; i++) {
          collapsedArgs = this.createExpandCollapseArgs(args, record[i]);
          this.parent.ganttChartModule.collapsedGanttRow(collapsedArgs);
        }
      } else {
        collapsedArgs = this.createExpandCollapseArgs(args, null);
        this.parent.ganttChartModule.collapsedGanttRow(collapsedArgs);
      }
      if (!this.parent.ganttChartModule.isCollapseAll && collapsedArgs['gridRow'] && !this.parent.allowTaskbarOverlap) {
        collapsedArgs['gridRow'].style.height = collapsedArgs['chartRow'].style.height;
        this.parent.contentHeight = this.parent.enableRtl ? this.parent['element'].getElementsByClassName('e-content')[2].children[0]['offsetHeight'] : this.parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
        document.getElementsByClassName('e-chart-rows-container')[0]['style'].height = this.parent.contentHeight + 'px';
      }
    }
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
    this.parent.trigger('collapsed', args);
  };
  GanttTreeGrid.prototype.expanded = function (args) {
    if (!this.parent.ganttChartModule.isExpandCollapseFromChart && !this.parent.isExpandCollapseLevelMethod) {
      var expandedArgs = void 0;
      var record = getValue('data', args);
      var recordLength = record.length;
      if (!isNullOrUndefined(recordLength)) {
        for (var i = 0; i < recordLength; i++) {
          expandedArgs = this.createExpandCollapseArgs(args, record[i]);
          this.parent.ganttChartModule.expandedGanttRow(expandedArgs);
        }
      } else {
        expandedArgs = this.createExpandCollapseArgs(args, null);
        this.parent.ganttChartModule.expandedGanttRow(expandedArgs);
      }
      if (!this.parent.ganttChartModule.isExpandAll && args['row'] && !this.parent.allowTaskbarOverlap) {
        args['row'].style.height = this.parent.rowHeight + 'px';
        this.parent.contentHeight = this.parent.enableRtl ? this.parent['element'].getElementsByClassName('e-content')[2].children[0]['offsetHeight'] : this.parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
        document.getElementsByClassName('e-chart-rows-container')[0]['style'].height = this.parent.contentHeight + 'px';
      }
    }
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
    this.parent.isCollapseAll = false;
    this.parent.trigger('expanded', args);
  };
  GanttTreeGrid.prototype.actionBegin = function (args) {
    if (this.parent.undoRedoModule && (args.requestType === 'filtering' || args.requestType === 'searching' || args.requestType === 'sorting' || args.requestType === 'filterAfterOpen')) {
      this.parent.undoRedoModule['canUpdateIndex'] = false;
    }
    this.parent.notify('actionBegin', args);
    var flag = getValue('doubleClickTarget', this.parent.treeGrid.editModule);
    if (flag !== null) {
      setValue('doubleClickTarget', null, this.parent.treeGrid.editModule);
    }
    this.parent.trigger('actionBegin', args);
    if (args.requestType !== 'virtualscroll' && args.type !== 'edit' && args.requestType !== 'beginEdit' && !args.cancel) {
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.showMaskRow();
      } else {
        this.parent.showSpinner();
      }
    }
  }; // eslint-disable-next-line
  GanttTreeGrid.prototype.created = function (args) {
    this.updateKeyConfigSettings();
  };
  GanttTreeGrid.prototype.actionFailure = function (args) {
    this.parent.trigger('actionFailure', args);
  };
  GanttTreeGrid.prototype.createExpandCollapseArgs = function (args, currentRecord) {
    var chartRow;
    var record = getValue('data', args);
    var recordLength = record.length;
    if (!isNullOrUndefined(recordLength)) {
      var gridRow = getValue('row', args);
      chartRow = this.parent.ganttChartModule.getChartRows()[this.parent.currentViewData.indexOf(currentRecord)];
      var eventArgs = {
        data: currentRecord,
        gridRow: gridRow,
        chartRow: chartRow,
        cancel: false
      };
      return eventArgs;
    } else {
      var recordOne = getValue('data', args);
      var gridRow = getValue('row', args);
      chartRow = this.parent.ganttChartModule.getChartRows()[this.parent.currentViewData.indexOf(recordOne)];
      var eventArgs = {
        data: recordOne,
        gridRow: gridRow,
        chartRow: chartRow,
        cancel: false
      };
      return eventArgs;
    }
  };
  GanttTreeGrid.prototype.treeActionComplete = function (args) {
    var _this = this;
    var updatedArgs = extend({}, args);
    if (getValue('requestType', args) === 'reorder') {
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isFromUndoRedo'] && this.parent['isUndoRedoItemPresent']('ColumnReorder')) {
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['disableRedo']();
        }
        this.parent.undoRedoModule['createUndoCollection']();
        var record = {};
        record['action'] = 'ColumnReorder';
        record['fromIndex'] = extend([], [], [args['fromIndex']], true)[0];
        record['toIndex'] = extend([], [], [args['toIndex']], true)[0];
        record['toColumn'] = extend([], [], [this.parent.treeGrid.columns[args['toIndex']]['field']], true)[0];
        record['fromColumn'] = extend([], [], [this.parent.treeGrid.columns[args['fromIndex']]['field']], true)[0];
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
      }
    }
    if (getValue('requestType', args) === 'columnstate') {
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isFromUndoRedo'] && this.parent['isUndoRedoItemPresent']('ColumnState')) {
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['disableRedo']();
        }
        this.parent.undoRedoModule['createUndoCollection']();
        var record = {
          action: 'ColumnState'
        };
        record['showhideColumns'] = extend([], [], args['columns'], true);
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
      }
    }
    if (getValue('requestType', args) === 'sorting') {
      if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent']('Sorting')) {
        if (this.parent.undoRedoModule['currentAction'] && this.parent.undoRedoModule['currentAction']['sortColumns'] && this.parent.undoRedoModule['currentAction']['sortColumns'].length > 1) {
          this.parent.undoRedoModule['sortedColumnsLength']++;
        }
        if ((!this.parent.undoRedoModule['currentAction'] || this.parent.undoRedoModule['currentAction']['sortColumns'] && this.parent.undoRedoModule['sortedColumnsLength'] !== this.parent.undoRedoModule['currentAction']['sortColumns'].length) && !this.parent.undoRedoModule['isFromUndoRedo']) {
          if (this.parent.undoRedoModule['redoEnabled']) {
            this.parent.undoRedoModule['disableRedo']();
          }
          this.parent.undoRedoModule['createUndoCollection']();
          var record = {
            action: 'Sorting'
          };
          record['sortColumns'] = [];
          record['sortColumns'] = this.parent.undoRedoModule['previousSortedColumns'];
          this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
        }
        this.parent.undoRedoModule['previousSortedColumns'] = this.parent.treeGrid.sortSettings.columns;
      }
      this.parent.notify('updateModel', {});
      deleteObject(updatedArgs, 'isFrozen');
    } else if (getValue('requestType', args) === 'filtering') {
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isFromUndoRedo'] && this.parent['isUndoRedoItemPresent']('Filtering')) {
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['disableRedo']();
        }
        this.parent.undoRedoModule['createUndoCollection']();
        var record = {
          action: 'Filtering'
        };
        record['filteredColumns'] = extend([], [], args['columns'], true);
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
      }
      this.parent.notify('updateModel', {});
      var focussedElement = this.parent.element.querySelector('.e-treegrid');
      focussedElement.focus();
    } else if (getValue('type', args) === 'save') {
      if (this.parent.editModule && this.parent.editModule.cellEditModule) {
        var data = getValue('data', args);
        if (!isNullOrUndefined(data) && !isNullOrUndefined(this.parent.getTaskByUniqueID(data.uniqueID))) {
          /* eslint-disable-next-line */
          this.parent.getTaskByUniqueID(data.uniqueID).taskData[this.parent.taskFields.duration] = data.taskData[this.parent.taskFields.duration];
          if (!isNullOrUndefined(data.taskData[this.parent.taskFields.resourceInfo])) {
            /* eslint-disable-next-line */
            this.parent.getTaskByUniqueID(data.uniqueID).taskData[this.parent.taskFields.resourceInfo] = data.taskData[this.parent.taskFields.resourceInfo];
          }
        }
        this.parent.editModule.cellEditModule.initiateCellEdit(args, this.currentEditRow);
        this.parent.editModule.cellEditModule.isCellEdit = false;
        this.currentEditRow = {};
      }
    }
    if (getValue('requestType', args) === 'filterAfterOpen') {
      this.parent.notify('actionComplete', args);
    }
    if (getValue('requestType', args) === 'searching') {
      if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent']('Search')) {
        if (!this.parent.undoRedoModule['isFromUndoRedo']) {
          if (this.parent.undoRedoModule['redoEnabled']) {
            this.parent.undoRedoModule['disableRedo']();
          }
          this.parent.undoRedoModule['createUndoCollection']();
          var record = {
            'action': 'Search'
          };
          record['searchString'] = this.parent.undoRedoModule['searchString'];
          this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
        }
        this.parent.undoRedoModule['searchString'] = this.parent.treeGrid.searchSettings.key;
      }
      this.parent.notify('actionComplete', args);
    }
    if (!isNullOrUndefined(this.parent.toolbarModule) && !isNullOrUndefined(getValue('batchChanges', args))) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
    if (this.parent.isCancelled) {
      setValue('requestType', 'cancel', updatedArgs);
      setValue('action', 'CellEditing', updatedArgs);
      this.parent.isCancelled = false;
    }
    if (getValue('requestType', args) === 'refresh' && isNullOrUndefined(getValue('type', args)) && this.parent.addDeleteRecord) {
      if (this.parent.selectedRowIndex !== -1) {
        if (!isNullOrUndefined(this.parent.selectionModule) && this.parent.selectionSettings && this.parent.selectionSettings.persistSelection && this.parent.editModule.isAdded) {
          var selectedIndexes = this.parent.selectionModule.selectedRowIndexes;
          if (selectedIndexes.length > 1 && this.parent.selectionSettings.persistSelection) {
            for (var i = 0; i < selectedIndexes.length; i++) {
              var records = this.parent.flatData[selectedIndexes[i].toString()];
              this.parent.selectRows(records);
            }
          }
        } else {
          this.parent.selectRow(this.parent.selectedRowIndex);
          if (this.parent.selectedRowIndex > this.parent.currentViewData.length - 1) {
            this.parent.selectedRowIndex = -1;
          }
        }
      } else {
        if (!isNullOrUndefined(this.parent.selectionModule) && this.parent.selectionSettings && this.parent.selectionSettings.persistSelection && this.parent.editModule && !this.parent.editModule.isAdded) {
          var selectedRecords = this.parent.selectionModule.getSelectedRecords();
          var _loop_1 = function (i) {
            var selectedTaskId = selectedRecords[i][this_1.parent.taskFields.id];
            var existIndeletedRecords = this_1.parent.editModule.deletedRecord.some(function (item) {
              return item.ganttProperties.taskId === selectedTaskId;
            });
            if (existIndeletedRecords) {
              selectedRecords.splice(i, 1);
            }
          };
          var this_1 = this;
          for (var i = selectedRecords.length - 1; i >= 0; i--) {
            _loop_1(i);
          }
        }
        var indexvalue_1 = 0;
        var dataCollection = this.parent.enableVirtualization ? this.parent.flatData : this.parent.currentViewData;
        // To maintain 1st record selection, while deleting the last parent record at Virtual mode
        dataCollection.map(function (data, index) {
          if (!isNullOrUndefined(_this.parent.currentSelection) && data['ganttProperties'].taskId === _this.parent.currentSelection[_this.parent.taskFields.id]) {
            indexvalue_1 = index;
          }
        });
        this.addedRecord = true;
        this.parent.selectRow(isNullOrUndefined(indexvalue_1) ? 0 : indexvalue_1);
      }
      if (!this.parent['isExpandPerformed']) {
        this.parent.addDeleteRecord = false;
      }
      this.parent['isExpandPerformed'] = false;
    }
    if (this.parent.undoRedoModule) {
      this.parent.undoRedoModule['isFromUndoRedo'] = false;
    }
    this.parent.trigger('actionComplete', updatedArgs);
    if (this.parent.showOverAllocation && !this.parent.allowTaskbarOverlap) {
      for (var i = 0; i < this.parent.currentViewData.length; i++) {
        if (this.parent.currentViewData[i].hasChildRecords && !this.parent.currentViewData[i].expanded) {
          this.parent.chartRowsModule.updateDragDropRecords(this.parent.currentViewData[i]);
        }
      }
      this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
    }
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
  };
  GanttTreeGrid.prototype.updateKeyConfigSettings = function () {
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.delete;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.insert;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.upArrow;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.downArrow;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.ctrlHome;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.ctrlEnd;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.enter;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.tab;
    delete this.parent.treeGrid.grid.keyboardModule.keyConfigs.shiftTab;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.enter;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.upArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.downArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.ctrlShiftUpArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.ctrlShiftDownArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.ctrlUpArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.ctrlDownArrow;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.tab;
    delete this.parent.treeGrid.keyboardModule.keyConfigs.shiftTab;
  };
  /**
   * Method to bind internal events on TreeGrid element
   *
   * @returns {void} .
   */
  GanttTreeGrid.prototype.wireEvents = function () {
    var content = this.parent.treeGrid.element.querySelector('.e-content');
    if (content) {
      EventHandler.add(content, 'scroll', this.scrollHandler, this);
    }
    if (this.parent.isAdaptive) {
      EventHandler.add(this.parent.treeGridPane, 'click', this.treeGridClickHandler, this);
    }
  };
  GanttTreeGrid.prototype.unWireEvents = function () {
    var content = this.parent.treeGrid.element && this.parent.treeGrid.element.querySelector('.e-content');
    if (content) {
      EventHandler.remove(content, 'scroll', this.scrollHandler);
    }
    if (this.parent.isAdaptive) {
      EventHandler.remove(this.parent.treeGridPane, 'click', this.treeGridClickHandler);
    }
  };
  // eslint-disable-next-line
  GanttTreeGrid.prototype.scrollHandler = function (e) {
    var content = this.parent.treeGrid.element.querySelector('.e-content');
    if (content.scrollTop !== this.previousScroll.top) {
      this.parent.notify('grid-scroll', {
        top: content.scrollTop
      });
    }
    this.previousScroll.top = content.scrollTop;
    if (this.parent.contextMenuModule && this.parent.contextMenuModule.isOpen) {
      this.parent.contextMenuModule.contextMenu.close();
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  GanttTreeGrid.prototype.validateGanttColumns = function () {
    var ganttObj = this.parent;
    var length = ganttObj.columns.length;
    var tasks = this.parent.taskFields;
    this.parent.columnMapping = {};
    this.parent.columnByField = {};
    this.parent.customColumns = [];
    var tasksMapping = ['id', 'name', 'startDate', 'endDate', 'duration', 'dependency', 'progress', 'baselineStartDate', 'baselineEndDate', 'resourceInfo', 'notes', 'work', 'manual', 'type', 'milestone', 'segments'];
    var _loop_2 = function (i) {
      var column = {};
      if (typeof ganttObj.columns[i] === 'string') {
        column.field = ganttObj.columns[i];
      } else {
        column = ganttObj.columns[i];
      }
      var columnName = [];
      if (tasksMapping.length > 0) {
        columnName = tasksMapping.filter(function (name) {
          return column.field === tasks[name];
        });
      }
      if (columnName.length === 0) {
        if (column.field === this_2.parent.resourceFields.group) {
          return "continue";
        }
        this_2.parent.customColumns.push(column.field);
        column.headerText = column.headerText ? column.headerText : column.field;
        column.width = column.width ? column.width : 150;
        column.editType = column.editType ? column.editType : 'stringedit';
        column.type = column.type ? column.type : 'string';
        this_2.bindTreeGridColumnProperties(column, true);
        return "continue";
      } else {
        var index = tasksMapping.indexOf(columnName[0]);
        tasksMapping.splice(index, 1);
        this_2.createTreeGridColumn(column, true);
        this_2.parent.columnMapping[columnName[0]] = column.field;
      }
    };
    var this_2 = this;
    for (var i = 0; i < length; i++) {
      _loop_2(i);
    }
    /** Create default columns with task settings property */
    for (var j = 0; j < tasksMapping.length; j++) {
      var column = {};
      if (!isNullOrUndefined(tasks[tasksMapping[j]])) {
        column.field = tasks[tasksMapping[j]];
        this.createTreeGridColumn(column, length === 0);
        this.parent.columnMapping[tasksMapping[j]] = column.field;
      }
    }
    if (this.parent.viewType !== 'ProjectView') {
      var column = {};
      this.composeUniqueIDColumn(column);
      this.createTreeGridColumn(column, true);
    }
  };
  /**
   *
   * @param {GanttColumnModel} column .
   * @param {boolean} isDefined .
   * @returns {void} .
   */
  GanttTreeGrid.prototype.createTreeGridColumn = function (column, isDefined) {
    var _this = this;
    var taskSettings = this.parent.taskFields;
    var previousColumn = this.parent.previousGanttColumns.filter(function (prevcolumn) {
      return column.field === prevcolumn.field;
    })[0];
    column.disableHtmlEncode = !isNullOrUndefined(column.disableHtmlEncode) ? column.disableHtmlEncode : this.parent.disableHtmlEncode;
    if (taskSettings.id !== column.field) {
      column.clipMode = column.clipMode ? column.clipMode : 'EllipsisWithTooltip';
    }
    if (taskSettings.id === column.field) {
      /** Id column */
      this.composeIDColumn(column);
    } else if (taskSettings.name === column.field) {
      /** Name column */
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('name');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('name');
      }
      column.width = column.width ? column.width : 150;
      column.editType = column.editType ? column.editType : 'stringedit';
      column.type = column.type ? column.type : 'string';
    } else if (taskSettings.startDate === column.field) {
      /** Name column */
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('startDate');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('startDate');
      }
      column.editType = column.editType ? column.editType : this.parent.getDateFormat().toLowerCase().indexOf('hh') !== -1 ? 'datetimepickeredit' : 'datepickeredit';
      column.format = column.format ? column.format : {
        type: 'date',
        format: this.parent.getDateFormat()
      };
      column.width = column.width ? column.width : 150;
      if (column.edit && column.edit.params) {
        column.edit.params['renderDayCell'] = this.parent.renderWorkingDayCell.bind(this.parent);
      } else {
        if (column.edit) {
          column.edit.params = {
            renderDayCell: this.parent.renderWorkingDayCell.bind(this.parent)
          };
        } else {
          column.edit = {
            params: {
              renderDayCell: this.parent.renderWorkingDayCell.bind(this.parent)
            }
          };
        }
      }
    } else if (taskSettings.endDate === column.field) {
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('endDate');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('endDate');
      }
      column.format = column.format ? column.format : {
        type: 'date',
        format: this.parent.getDateFormat()
      };
      column.editType = column.editType ? column.editType : this.parent.getDateFormat().toLowerCase().indexOf('hh') !== -1 ? 'datetimepickeredit' : 'datepickeredit';
      column.width = column.width ? column.width : 150;
      if (column.edit && column.edit.params) {
        column.edit.params['renderDayCell'] = this.parent.renderWorkingDayCell.bind(this.parent);
      } else {
        if (column.edit) {
          column.edit.params = {
            renderDayCell: this.parent.renderWorkingDayCell.bind(this.parent)
          };
        } else {
          column.edit = {
            params: {
              renderDayCell: this.parent.renderWorkingDayCell.bind(this.parent)
            }
          };
        }
      }
    } else if (taskSettings.duration === column.field) {
      column.width = column.width ? column.width : 150;
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('duration');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('duration');
      }
      column.valueAccessor = column.valueAccessor ? column.valueAccessor : !isNullOrUndefined(column.edit) && !isNullOrUndefined(column.edit.read) ? null : this.durationValueAccessor.bind(this);
      column.editType = column.editType ? column.editType : 'stringedit';
      column.type = column.type ? column.type : 'string';
    } else if (taskSettings.progress === column.field) {
      this.composeProgressColumn(column);
    } else if (taskSettings.dependency === column.field) {
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('dependency');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('dependency');
      }
      column.width = column.width ? column.width : 150;
      column.editType = column.editType ? column.editType : 'stringedit';
      column.type = 'string';
      column.allowFiltering = column.allowFiltering === false ? false : true;
      column.valueAccessor = column.valueAccessor ? column.valueAccessor : this.dependencyValueAccessor.bind(this);
      if (isNullOrUndefined(column.filter) && this.parent.locale !== 'en-US') {
        column.filter = {
          'ui': {
            create: function (args) {
              var flValInput = createElement('input', {
                className: 'flm-input'
              });
              args.target.appendChild(flValInput);
              _this.dropInstance = new AutoComplete({
                dataSource: _this.changeLocale(_this.parent.treeGrid.grid.dataSource),
                fields: {
                  text: _this.parent.taskFields.dependency,
                  value: _this.parent.taskFields.dependency
                },
                placeholder: _this.parent.localeObj.getConstant('enterValue'),
                popupHeight: '200px'
              });
              _this.dropInstance.appendTo(flValInput);
            },
            write: function (args) {
              _this.dropInstance.value = args.filteredValue;
            },
            read: function (args) {
              args.fltrObj.filterByColumn(args.column.field, args.operator, _this.changeDelocale(_this.dropInstance.value));
            }
          }
        };
      }
    } else if (taskSettings.resourceInfo === column.field) {
      this.composeResourceColumn(column);
    } else if (taskSettings.notes === column.field) {
      if (previousColumn && this.parent.isLocaleChanged) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('notes');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('notes');
      }
      column.width = column.width ? column.width : 150;
      column.editType = column.editType ? column.editType : 'stringedit';
      if (!this.parent.showInlineNotes) {
        if (!column.template) {
          column.template = '<div class="e-ganttnotes-info">' + '<span class="e-icons e-notes-info"></span></div>';
        }
      }
    } else if (taskSettings.baselineStartDate === column.field || taskSettings.baselineEndDate === column.field) {
      var colName = taskSettings.baselineEndDate === column.field ? 'baselineEndDate' : 'baselineStartDate';
      column.width = column.width ? column.width : 150;
      if (this.parent.isLocaleChanged && previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant(colName);
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant(colName);
      }
      column.format = column.format ? column.format : {
        type: 'date',
        format: this.parent.getDateFormat()
      };
      column.editType = column.editType ? column.editType : this.parent.getDateFormat().toLowerCase().indexOf('hh') !== -1 ? 'datetimepickeredit' : 'datepickeredit';
    } else if (taskSettings.work === column.field) {
      if (previousColumn && this.parent.isLocaleChanged) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('work');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('work');
      }
      column.width = column.width ? column.width : 150;
      column.valueAccessor = column.valueAccessor ? column.valueAccessor : this.workValueAccessor.bind(this);
      column.editType = column.editType ? column.editType : 'numericedit';
    } else if (taskSettings.type === column.field) {
      if (previousColumn && this.parent.isLocaleChanged) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('taskType');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('taskType');
      }
      column.width = column.width ? column.width : 150;
      //column.type = 'string';
      column.editType = 'dropdownedit';
      column.valueAccessor = column.valueAccessor ? column.valueAccessor : this.taskTypeValueAccessor.bind(this);
    } else if (taskSettings.manual === column.field && this.parent.taskMode === 'Custom') {
      if (previousColumn && this.parent.isLocaleChanged) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('taskMode');
      } else {
        column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('taskMode');
      }
      column.width = column.width ? column.width : 120;
      column.editType = column.editType ? column.editType : 'dropdownedit';
      column.valueAccessor = column.valueAccessor ? column.valueAccessor : this.modeValueAccessor.bind(this);
      column.edit = {
        params: {
          query: new Query(),
          dataSource: [{
            id: 1,
            text: this.parent.localeObj.getConstant('manual'),
            value: true
          }, {
            id: 2,
            text: this.parent.localeObj.getConstant('auto'),
            value: false
          }],
          fields: {
            text: 'text',
            value: 'value'
          }
        }
      };
    }
    this.bindTreeGridColumnProperties(column, isDefined);
  };
  GanttTreeGrid.prototype.changeLocale = function (data) {
    var _this = this;
    var filter = [];
    var flatdatas = data.map(function (task) {
      return task.ganttProperties.predecessorsName;
    });
    flatdatas.map(function (predecessorsName) {
      if (!isNullOrUndefined(predecessorsName)) {
        var value_1 = '';
        var splitString_1 = predecessorsName.split(',');
        splitString_1.map(function (splitvalue, index) {
          if (splitvalue.includes('FS')) {
            value_1 += splitvalue.replace('FS', _this.parent.localeObj.getConstant('FS'));
            value_1 += splitString_1.length !== index + 1 ? ',' : '';
          } else if (splitvalue.includes('FF')) {
            value_1 += splitvalue.replace('FF', _this.parent.localeObj.getConstant('FF'));
            value_1 += splitString_1.length !== index + 1 ? ',' : '';
          } else if (splitvalue.includes('SS')) {
            value_1 += splitvalue.replace('SS', _this.parent.localeObj.getConstant('SS'));
            value_1 += splitString_1.length !== index + 1 ? ',' : '';
          } else if (splitvalue.includes('SF')) {
            value_1 += splitvalue.replace('SF', _this.parent.localeObj.getConstant('SF'));
            value_1 += splitString_1.length !== index + 1 ? ',' : '';
          }
        });
        filter.push(value_1);
      }
    });
    return filter;
  };
  GanttTreeGrid.prototype.changeDelocale = function (dependency) {
    var FF = this.parent.localeObj.getConstant('FF');
    var FS = this.parent.localeObj.getConstant('FS');
    var SS = this.parent.localeObj.getConstant('SS');
    var SF = this.parent.localeObj.getConstant('SF');
    if (!isNullOrUndefined(dependency)) {
      var splitString_2 = dependency.split(',');
      var value_2 = '';
      splitString_2.map(function (splitvalue, index) {
        if (splitvalue.includes(FF)) {
          value_2 += splitvalue.replace(FF, 'FF');
          value_2 += splitString_2.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(FS)) {
          value_2 += splitvalue.replace(FS, 'FS');
          value_2 += splitString_2.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(SS)) {
          value_2 += splitvalue.replace(SS, 'SS');
          value_2 += splitString_2.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(SF)) {
          value_2 += splitvalue.replace(SF, 'SF');
          value_2 += splitString_2.length !== index + 1 ? ',' : '';
        } else {
          value_2 += splitvalue;
          value_2 += splitString_2.length !== index + 1 ? ',' : '';
        }
      });
      return value_2;
    }
    return null;
  };
  /**
   * Compose Resource columns
   *
   * @param {GanttColumnModel} column .
   * @returns {void} .
   */
  GanttTreeGrid.prototype.composeResourceColumn = function (column) {
    var previousColumn = this.parent.previousGanttColumns.filter(function (prevcolumn) {
      return column.field === prevcolumn.field;
    })[0];
    if (previousColumn && this.parent.isLocaleChanged) {
      column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('resourceName');
    } else {
      column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('resourceName');
    }
    column.width = column.width ? column.width : 150;
    column.type = 'string';
    column.valueAccessor = column.valueAccessor ? column.valueAccessor : this.resourceValueAccessor.bind(this);
    column.allowFiltering = column.allowFiltering === false ? false : true;
  };
  /**
   * @param {IGanttData} data .
   * @returns {object} .
   * @private
   */
  GanttTreeGrid.prototype.getResourceIds = function (data) {
    var value = getValue(this.parent.taskFields.resourceInfo, data.taskData);
    var id = [];
    if (!isNullOrUndefined(value)) {
      for (var i = 0; i < value.length; i++) {
        id.push(typeof value[i] === 'object' ? value[i][this.parent.resourceFields.id] : value[i]);
      }
      return id;
    } else {
      return value;
    }
  };
  /**
   * Create Id column
   *
   * @param {GanttColumnModel} column .
   * @returns {void} .
   */
  GanttTreeGrid.prototype.composeIDColumn = function (column) {
    var isProjectView = this.parent.viewType === 'ProjectView';
    var lengthDataSource = this.parent.dataSource ? this.parent.dataSource['length'] : 0;
    var taskIDName;
    column.isPrimaryKey = isProjectView ? true : false;
    if (this.parent.isLocaleChanged) {
      var previousColumn = this.parent.previousGanttColumns.filter(function (prevcolumn) {
        return column.field === prevcolumn.field;
      })[0];
      if (previousColumn) {
        column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('id');
      }
    } else {
      column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('id');
    }
    column.width = column.width ? column.width : 100;
    for (var i = 0; i < lengthDataSource; i++) {
      if (!isNullOrUndefined(this.parent.dataSource[i][this.parent.taskFields.id])) {
        taskIDName = this.parent.dataSource[i][this.parent.taskFields.id];
        break;
      }
    }
    if (typeof taskIDName === 'string' || isNullOrUndefined(taskIDName)) {
      if (this.parent.viewType === 'ResourceView') {
        column.allowEditing = column.allowEditing ? column.allowEditing : false;
      } else {
        column.allowEditing = column.allowEditing ? column.allowEditing : true;
      }
      column.editType = column.editType ? column.editType : 'stringedit';
    } else {
      column.allowEditing = column.allowEditing ? column.allowEditing : false;
      column.editType = column.editType ? column.editType : 'numericedit';
    }
    column.valueAccessor = isProjectView ? null : this.idValueAccessor.bind(this);
  };
  GanttTreeGrid.prototype.composeUniqueIDColumn = function (column) {
    column.field = 'rowUniqueID';
    column.isPrimaryKey = true;
    column.headerText = 'UniqueID';
    column.allowEditing = false;
    column.visible = false;
  };
  /**
   * Create progress column
   *
   * @param {GanttColumnModel} column .
   * @returns {void} .
   */
  GanttTreeGrid.prototype.composeProgressColumn = function (column) {
    var previousColumn = this.parent.previousGanttColumns.filter(function (prevcolumn) {
      return column.field === prevcolumn.field;
    })[0];
    if (this.parent.isLocaleChanged && previousColumn) {
      column.headerText = previousColumn.headerText ? previousColumn.headerText : this.parent.localeObj.getConstant('progress');
    } else {
      column.headerText = column.headerText ? column.headerText : this.parent.localeObj.getConstant('progress');
    }
    column.width = column.width ? column.width : 150;
    column.editType = column.editType ? column.editType : 'numericedit';
  };
  /**
   * @param {GanttColumnModel} newGanttColumn .
   * @param {boolean} isDefined .
   * @returns {void} .
   */
  GanttTreeGrid.prototype.bindTreeGridColumnProperties = function (newGanttColumn, isDefined) {
    var treeGridColumn = {};
    var ganttColumn = {};
    for (var _i = 0, _a = Object.keys(newGanttColumn); _i < _a.length; _i++) {
      var prop = _a[_i];
      treeGridColumn[prop] = ganttColumn[prop] = newGanttColumn[prop];
    }
    this.parent.columnByField[ganttColumn.field] = ganttColumn;
    this.parent.ganttColumns.push(new Column(ganttColumn));
    if (isDefined) {
      this.treeGridColumns.push(treeGridColumn);
    }
  }; // eslint-disable-next-line
  GanttTreeGrid.prototype.durationValueAccessor = function (field, data, column) {
    if (!isNullOrUndefined(data) && !isNullOrUndefined(data.ganttProperties)) {
      var ganttProp = data.ganttProperties;
      return this.parent.dataOperation.getDurationString(ganttProp.duration, ganttProp.durationUnit);
    } else if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      return this.parent.dataOperation.getDurationString(parseInt(data[this.parent.taskFields.duration], 10), this.parent.durationUnit);
    }
    return '';
  }; // eslint-disable-next-line
  GanttTreeGrid.prototype.dependencyValueAccessor = function (field, data, column) {
    var _this = this;
    if (data && data.ganttProperties && !isNullOrUndefined(data.ganttProperties.predecessorsName)) {
      var value_3 = '';
      var predecessorsName = data.ganttProperties.predecessorsName;
      var splitString_3 = predecessorsName.split(',');
      splitString_3.map(function (splitvalue, index) {
        if (splitvalue.includes('FS')) {
          value_3 += splitvalue.replace('FS', _this.parent.localeObj.getConstant('FS'));
          value_3 += splitString_3.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes('FF')) {
          value_3 += splitvalue.replace('FF', _this.parent.localeObj.getConstant('FF'));
          value_3 += splitString_3.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes('SS')) {
          value_3 += splitvalue.replace('SS', _this.parent.localeObj.getConstant('SS'));
          value_3 += splitString_3.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes('SF')) {
          value_3 += splitvalue.replace('SF', _this.parent.localeObj.getConstant('SF'));
          value_3 += splitString_3.length !== index + 1 ? ',' : '';
        }
      });
      return value_3;
    }
    return '';
  };
  GanttTreeGrid.prototype.resourceValueAccessor = function (field, data, column) {
    var ganttProp = data.ganttProperties;
    if (!isNullOrUndefined(ganttProp)) {
      return ganttProp.resourceNames;
    }
    return '';
  };
  GanttTreeGrid.prototype.workValueAccessor = function (field, data, column) {
    var ganttProp = data.ganttProperties;
    if (!isNullOrUndefined(ganttProp)) {
      return this.parent.dataOperation.getWorkString(ganttProp.work, ganttProp.workUnit);
    }
    return '';
  };
  GanttTreeGrid.prototype.taskTypeValueAccessor = function (field, data, column) {
    var ganttProp = data.ganttProperties;
    if (!isNullOrUndefined(ganttProp)) {
      return ganttProp.taskType;
    }
    return '';
  };
  GanttTreeGrid.prototype.modeValueAccessor = function (field, data, column) {
    if (data[field]) {
      return 'Manual';
    } else {
      return 'Auto';
    }
  };
  GanttTreeGrid.prototype.idValueAccessor = function (field, data, column) {
    return data.level === 0 ? 'R-' + data.ganttProperties.taskId : 'T-' + data.ganttProperties.taskId;
  };
  GanttTreeGrid.prototype.updateScrollTop = function (args) {
    var newScrollTop = getValue('top', args);
    this.treeGridElement.querySelector('.e-content').scrollTop = newScrollTop;
    this.previousScroll.top = this.treeGridElement.querySelector('.e-content').scrollTop;
  };
  GanttTreeGrid.prototype.treeGridClickHandler = function (e) {
    this.parent.notify('treeGridClick', e);
  };
  GanttTreeGrid.prototype.removeEventListener = function () {
    this.parent.off('renderPanels', this.createContainer);
    this.parent.off('chartScroll', this.updateScrollTop);
    this.parent.off('destroy', this.destroy);
    this.parent.treeGrid.off('reactTemplateRender', this.renderReactTemplate);
  };
  GanttTreeGrid.prototype.destroy = function () {
    this.removeEventListener();
    this.unWireEvents();
    if (this.parent.treeGrid.element) {
      this.parent.treeGrid.destroy();
    }
  };
  return GanttTreeGrid;
}();
var __extends$1 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines the working time of the day in the project.
 */
var DayWorkingTime = /** @__PURE__ @class */function (_super) {
  __extends$1(DayWorkingTime, _super);
  function DayWorkingTime() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property(null)], DayWorkingTime.prototype, "from", void 0);
  __decorate([Property(null)], DayWorkingTime.prototype, "to", void 0);
  return DayWorkingTime;
}(ChildProperty);
var __extends$2 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$1 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines dialog fields of add dialog.
 */
var AddDialogFieldSettings = /** @__PURE__ @class */function (_super) {
  __extends$2(AddDialogFieldSettings, _super);
  function AddDialogFieldSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$1([Property(null)], AddDialogFieldSettings.prototype, "type", void 0);
  __decorate$1([Property(null)], AddDialogFieldSettings.prototype, "headerText", void 0);
  __decorate$1([Property([])], AddDialogFieldSettings.prototype, "fields", void 0);
  __decorate$1([Property({})], AddDialogFieldSettings.prototype, "additionalParams", void 0);
  return AddDialogFieldSettings;
}(ChildProperty);
var __extends$3 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$2 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines dialog fields of edit dialog.
 */
var EditDialogFieldSettings = /** @__PURE__ @class */function (_super) {
  __extends$3(EditDialogFieldSettings, _super);
  function EditDialogFieldSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$2([Property(null)], EditDialogFieldSettings.prototype, "type", void 0);
  __decorate$2([Property(null)], EditDialogFieldSettings.prototype, "headerText", void 0);
  __decorate$2([Property([])], EditDialogFieldSettings.prototype, "fields", void 0);
  __decorate$2([Property({})], EditDialogFieldSettings.prototype, "additionalParams", void 0);
  return EditDialogFieldSettings;
}(ChildProperty);
var __extends$4 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$3 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures edit settings of Gantt.
 */
var EditSettings = /** @__PURE__ @class */function (_super) {
  __extends$4(EditSettings, _super);
  function EditSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$3([Property(false)], EditSettings.prototype, "allowEditing", void 0);
  __decorate$3([Property(false)], EditSettings.prototype, "allowAdding", void 0);
  __decorate$3([Property(false)], EditSettings.prototype, "allowDeleting", void 0);
  __decorate$3([Property('Auto')], EditSettings.prototype, "mode", void 0);
  __decorate$3([Property('Top')], EditSettings.prototype, "newRowPosition", void 0);
  __decorate$3([Property(false)], EditSettings.prototype, "showDeleteConfirmDialog", void 0);
  __decorate$3([Property(false)], EditSettings.prototype, "allowTaskbarEditing", void 0);
  __decorate$3([Property(false)], EditSettings.prototype, "allowNextRowEdit", void 0);
  return EditSettings;
}(ChildProperty);
var __extends$5 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$4 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines event marker collection in Gantt.
 */
var EventMarker = /** @__PURE__ @class */function (_super) {
  __extends$5(EventMarker, _super);
  function EventMarker() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$4([Property(null)], EventMarker.prototype, "day", void 0);
  __decorate$4([Property(null)], EventMarker.prototype, "label", void 0);
  __decorate$4([Property(null)], EventMarker.prototype, "cssClass", void 0);
  return EventMarker;
}(ChildProperty);
var __extends$6 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$5 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures the filtering behavior of the Gantt.
 */
var FilterSettings = /** @__PURE__ @class */function (_super) {
  __extends$6(FilterSettings, _super);
  function FilterSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$5([Collection([], Predicate)], FilterSettings.prototype, "columns", void 0);
  __decorate$5([Property('Menu')], FilterSettings.prototype, "type", void 0);
  __decorate$5([Property()], FilterSettings.prototype, "operators", void 0);
  __decorate$5([Property(false)], FilterSettings.prototype, "ignoreAccent", void 0);
  __decorate$5([Property('Parent')], FilterSettings.prototype, "hierarchyMode", void 0);
  return FilterSettings;
}(ChildProperty);
var __extends$7 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$6 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures the searching behavior of the Gantt.
 */
var SearchSettings = /** @__PURE__ @class */function (_super) {
  __extends$7(SearchSettings, _super);
  function SearchSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$6([Property([])], SearchSettings.prototype, "fields", void 0);
  __decorate$6([Property(false)], SearchSettings.prototype, "ignoreCase", void 0);
  __decorate$6([Property('contains')], SearchSettings.prototype, "operator", void 0);
  __decorate$6([Property('')], SearchSettings.prototype, "key", void 0);
  __decorate$6([Property('Parent')], SearchSettings.prototype, "hierarchyMode", void 0);
  return SearchSettings;
}(ChildProperty);
var __extends$8 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$7 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines holidays of project.
 */
var Holiday = /** @__PURE__ @class */function (_super) {
  __extends$8(Holiday, _super);
  function Holiday() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$7([Property(null)], Holiday.prototype, "from", void 0);
  __decorate$7([Property(null)], Holiday.prototype, "to", void 0);
  __decorate$7([Property(null)], Holiday.prototype, "label", void 0);
  __decorate$7([Property(null)], Holiday.prototype, "cssClass", void 0);
  return Holiday;
}(ChildProperty);
var __extends$9 = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$8 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines labels for task, this will be placed right, left and inner side of taskbar.
 */
var LabelSettings = /** @__PURE__ @class */function (_super) {
  __extends$9(LabelSettings, _super);
  function LabelSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$8([Property(null)], LabelSettings.prototype, "rightLabel", void 0);
  __decorate$8([Property(null)], LabelSettings.prototype, "leftLabel", void 0);
  __decorate$8([Property(null)], LabelSettings.prototype, "taskLabel", void 0);
  return LabelSettings;
}(ChildProperty);
var __extends$a = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$9 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures the selection behavior of the Gantt.
 */
var SelectionSettings = /** @__PURE__ @class */function (_super) {
  __extends$a(SelectionSettings, _super);
  function SelectionSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$9([Property('Row')], SelectionSettings.prototype, "mode", void 0);
  __decorate$9([Property('Flow')], SelectionSettings.prototype, "cellSelectionMode", void 0);
  __decorate$9([Property('Single')], SelectionSettings.prototype, "type", void 0);
  __decorate$9([Property(false)], SelectionSettings.prototype, "persistSelection", void 0);
  __decorate$9([Property(false)], SelectionSettings.prototype, "enableToggle", void 0);
  return SelectionSettings;
}(ChildProperty);
var __extends$b = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$a = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures splitter position and splitter bar.
 */
var SplitterSettings = /** @__PURE__ @class */function (_super) {
  __extends$b(SplitterSettings, _super);
  function SplitterSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$a([Property(null)], SplitterSettings.prototype, "position", void 0);
  __decorate$a([Property(-1)], SplitterSettings.prototype, "columnIndex", void 0);
  __decorate$a([Property(4)], SplitterSettings.prototype, "separatorSize", void 0);
  __decorate$a([Property(null)], SplitterSettings.prototype, "minimum", void 0);
  __decorate$a([Property('Default')], SplitterSettings.prototype, "view", void 0);
  return SplitterSettings;
}(ChildProperty);
var __extends$c = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$b = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines mapping property to get task details from data source.
 */
var TaskFields = /** @__PURE__ @class */function (_super) {
  __extends$c(TaskFields, _super);
  function TaskFields() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$b([Property(null)], TaskFields.prototype, "id", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "name", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "parentID", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "hasChildMapping", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "startDate", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "endDate", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "dependency", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "progress", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "child", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "milestone", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "duration", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "durationUnit", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "cssClass", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "baselineStartDate", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "baselineEndDate", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "resourceInfo", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "expandState", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "indicators", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "notes", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "work", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "manual", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "type", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "segments", void 0);
  __decorate$b([Property(null)], TaskFields.prototype, "segmentId", void 0);
  return TaskFields;
}(ChildProperty);
var __extends$d = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$c = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures timeline settings of Gantt.
 */
var TimelineTierSettings = /** @__PURE__ @class */function (_super) {
  __extends$d(TimelineTierSettings, _super);
  function TimelineTierSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$c([Property('')], TimelineTierSettings.prototype, "format", void 0);
  __decorate$c([Property('None')], TimelineTierSettings.prototype, "unit", void 0);
  __decorate$c([Property(1)], TimelineTierSettings.prototype, "count", void 0);
  __decorate$c([Property(null)], TimelineTierSettings.prototype, "formatter", void 0);
  return TimelineTierSettings;
}(ChildProperty);
/**
 * Configures the timeline settings property in the Gantt.
 */
var TimelineSettings = /** @__PURE__ @class */function (_super) {
  __extends$d(TimelineSettings, _super);
  function TimelineSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$c([Property('Week')], TimelineSettings.prototype, "timelineViewMode", void 0);
  __decorate$c([Complex({}, TimelineTierSettings)], TimelineSettings.prototype, "topTier", void 0);
  __decorate$c([Complex({}, TimelineTierSettings)], TimelineSettings.prototype, "bottomTier", void 0);
  __decorate$c([Property(33)], TimelineSettings.prototype, "timelineUnitSize", void 0);
  __decorate$c([Property(0)], TimelineSettings.prototype, "weekStartDay", void 0);
  __decorate$c([Property(null)], TimelineSettings.prototype, "weekendBackground", void 0);
  __decorate$c([Property(true)], TimelineSettings.prototype, "showTooltip", void 0);
  __decorate$c([Property(true)], TimelineSettings.prototype, "updateTimescaleView", void 0);
  return TimelineSettings;
}(ChildProperty);
var __extends$e = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$d = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures tooltip settings for Gantt.
 */
var TooltipSettings = /** @__PURE__ @class */function (_super) {
  __extends$e(TooltipSettings, _super);
  function TooltipSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$d([Property(true)], TooltipSettings.prototype, "showTooltip", void 0);
  __decorate$d([Property()], TooltipSettings.prototype, "taskbar", void 0);
  __decorate$d([Property()], TooltipSettings.prototype, "baseline", void 0);
  __decorate$d([Property()], TooltipSettings.prototype, "connectorLine", void 0);
  __decorate$d([Property()], TooltipSettings.prototype, "editing", void 0);
  return TooltipSettings;
}(ChildProperty);
var __extends$f = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$e = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Represents the field name and direction of sort column.
 */
var SortDescriptor = /** @__PURE__ @class */function (_super) {
  __extends$f(SortDescriptor, _super);
  function SortDescriptor() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$e([Property()], SortDescriptor.prototype, "field", void 0);
  __decorate$e([Property()], SortDescriptor.prototype, "direction", void 0);
  return SortDescriptor;
}(ChildProperty);
/**
 * Configures the sorting behavior of Gantt.
 */
var SortSettings = /** @__PURE__ @class */function (_super) {
  __extends$f(SortSettings, _super);
  function SortSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$e([Collection([], SortDescriptor)], SortSettings.prototype, "columns", void 0);
  __decorate$e([Property(true)], SortSettings.prototype, "allowUnsort", void 0);
  return SortSettings;
}(ChildProperty);
var __extends$g = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$f = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines the mapping properties to extract resource details from the resource collection.
 */
var ResourceFields = /** @__PURE__ @class */function (_super) {
  __extends$g(ResourceFields, _super);
  function ResourceFields() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$f([Property(null)], ResourceFields.prototype, "id", void 0);
  __decorate$f([Property(null)], ResourceFields.prototype, "name", void 0);
  __decorate$f([Property(null)], ResourceFields.prototype, "unit", void 0);
  __decorate$f([Property(null)], ResourceFields.prototype, "group", void 0);
  return ResourceFields;
}(ChildProperty);
var __extends$h = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$g = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Configures the Loading Indicator of the Gantt.
 */
var LoadingIndicator = /** @__PURE__ @class */function (_super) {
  __extends$h(LoadingIndicator, _super);
  function LoadingIndicator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$g([Property('Spinner')], LoadingIndicator.prototype, "indicatorType", void 0);
  return LoadingIndicator;
}(ChildProperty);
var __extends$i = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$h = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * Defines the working time of the day in the project.
 */
var WeekWorkingTime = /** @__PURE__ @class */function (_super) {
  __extends$i(WeekWorkingTime, _super);
  function WeekWorkingTime() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate$h([Property()], WeekWorkingTime.prototype, "dayOfWeek", void 0);
  __decorate$h([Collection([], DayWorkingTime)], WeekWorkingTime.prototype, "timeRange", void 0);
  return WeekWorkingTime;
}(ChildProperty);
var __extends$j = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * To render the chart rows in Gantt
 */
var ChartRows = /** @__PURE__ @class */function (_super) {
  __extends$j(ChartRows, _super);
  function ChartRows(ganttObj) {
    var _this = _super.call(this, ganttObj) || this;
    _this.taskBarHeight = 0;
    _this.milestoneHeight = 0;
    _this.milesStoneRadius = 0;
    _this.baselineTop = 0;
    _this.baselineHeight = 8;
    _this.touchLeftConnectorpoint = '';
    _this.touchRightConnectorpoint = '';
    _this.dropSplit = false;
    _this.refreshedTr = [];
    _this.refreshedData = [];
    _this.isUpdated = true;
    _this.taskBaselineTemplateNode = null;
    /**
     * To trigger the touchmove.
     *
     * @param {TouchEvent} event .
     * @returns {void}
     * @private
     */
    _this.handleTouchMove = function (event) {
      _this.parent.ganttChartModule['ganttChartMove'](event);
    };
    /**
     * To trigger the touchend.
     *
     * @param {TouchEvent} event .
     * @returns {void}
     * @private
     */
    _this.handleTouchEnd = function (event) {
      _this.parent.ganttChartModule['documentMouseUp'](event);
    };
    _this.parent = ganttObj;
    _this.initPublicProp();
    _this.addEventListener();
    return _this;
  }
  /**
   * To initialize the public property.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.initPublicProp = function () {
    this.ganttChartTableBody = null;
  };
  ChartRows.prototype.addEventListener = function () {
    this.parent.on('renderPanels', this.createChartTable, this);
    this.parent.on('dataReady', this.initiateTemplates, this);
    this.parent.on('destroy', this.destroy, this);
  };
  ChartRows.prototype.refreshChartByTimeline = function () {
    this.taskTable.style.width = formatUnit(this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : this.parent.timelineModule.totalTimelineWidth);
    var prevDate = getValue('prevProjectStartDate', this.parent.dataOperation);
    var isUpdated = false;
    if (prevDate) {
      isUpdated = prevDate.getTime() === this.parent.cloneProjectStartDate.getTime();
    }
    this.isUpdated = this.parent.isFromOnPropertyChange && isUpdated && getValue('mutableData', this.parent.treeGrid.grid.contentModule) ? true : false;
    this.refreshGanttRows();
    this.isUpdated = true;
  };
  /**
   * To render chart rows.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.createChartTable = function () {
    this.taskTable = createElement('table', {
      className: taskTable + ' ' + zeroSpacing,
      id: 'GanttTaskTable' + this.parent.element.id,
      styles: 'position: absolute;width:' + (this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : this.parent.timelineModule.totalTimelineWidth) + 'px;',
      attrs: {
        cellspacing: '0.25px'
      }
    });
    var colgroup = createElement('colgroup');
    var column = createElement('col', {
      styles: 'width:' + this.parent.timelineModule.totalTimelineWidth + 'px;'
    });
    colgroup.appendChild(column);
    this.taskTable.appendChild(colgroup);
    this.ganttChartTableBody = createElement('tbody', {
      id: this.parent.element.id + 'GanttTaskTableBody'
    });
    this.taskTable.appendChild(this.ganttChartTableBody);
    this.parent.ganttChartModule.chartBodyContent.appendChild(this.taskTable);
  };
  ChartRows.prototype.initiateTemplates = function () {
    this.taskTable.style.width = formatUnit(this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : this.parent.timelineModule.totalTimelineWidth);
    this.initChartHelperPrivateVariable();
    this.initializeChartTemplate();
  };
  /**
   * To render chart rows.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.renderChartRows = function () {
    this.createTaskbarTemplate();
    this.parent.isGanttChartRendered = true;
  };
  /**
   * To get gantt Indicator.
   *
   * @param {IIndicator} indicator .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getIndicatorNode = function (indicator) {
    var templateString = '<label class="' + label + ' ' + taskIndicatorDiv + '" style="display: inline-flex; align-items: center; margin-top: 0; line-height:' + this.parent.rowHeight + 'px;' + (this.parent.enableRtl ? 'right:' : 'left:') + this.getIndicatorleft(indicator.date) + 'px;"><i class="' + indicator.iconClass + '" style="margin-right: 3px;"></i> </label>';
    return this.createDivElement(templateString);
  };
  /**
   * To get gantt Indicator.
   *
   * @param {Date | string} date .
   * @returns {number} .
   * @private
   */
  ChartRows.prototype.getIndicatorleft = function (date) {
    date = this.parent.dateValidationModule.getDateFromFormat(date);
    var left = this.parent.dataOperation.getTaskLeft(date, false);
    return left;
  };
  /**
   * To get child taskbar Node.
   *
   *  @param {number} i .
   * @param {NodeList} rootElement .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getChildTaskbarNode = function (i, rootElement) {
    var childTaskbarNode = null;
    var data = this.templateData;
    var direction;
    if (this.parent.enableRtl) {
      direction = 'right:';
    } else {
      direction = 'left:';
    }
    if (this.childTaskbarTemplateFunction) {
      childTaskbarNode = this.childTaskbarTemplateFunction(extend({
        index: i
      }, data), this.parent, 'TaskbarTemplate', this.getTemplateID('TaskbarTemplate'), false, undefined, rootElement[0], this.parent.treeGrid['root']);
    } else {
      var labelString = '';
      var taskLabel$1 = '';
      var taskbarInnerDiv = void 0;
      var progressDiv = void 0;
      if (data.ganttProperties.startDate && data.ganttProperties.endDate && data.ganttProperties.duration) {
        taskbarInnerDiv = this.createDivElement('<div class="' + childTaskBarInnerDiv + ' ' + traceChildTaskBar + ' ' + (data.ganttProperties.isAutoSchedule ? '' : manualChildTaskBar) + '"' + 'style="width:' + data.ganttProperties.width + 'px;height:' + this.taskBarHeight + 'px; margin-top :-1px"></div>');
        progressDiv = this.createDivElement('<div class="' + childProgressBarInnerDiv + ' ' + traceChildProgressBar + ' ' + (data.ganttProperties.isAutoSchedule ? '' : manualChildProgressBar) + '"' + ' style="border-style:' + (data.ganttProperties.progressWidth ? 'solid;' : 'none;') + 'width:' + data.ganttProperties.progressWidth + 'px;height:100%;' + 'border-top-right-radius:' + this.getBorderRadius(data.ganttProperties) + 'px;' + 'border-bottom-right-radius:' + this.getBorderRadius(data.ganttProperties) + 'px;">' + '</div>');
      }
      var tempDiv = createElement('div');
      if (this.taskLabelTemplateFunction && !isNullOrUndefined(progressDiv) && progressDiv.length > 0) {
        var taskLabelTemplateNode = this.taskLabelTemplateFunction(extend({
          index: i
        }, data), this.parent, 'TaskLabelTemplate', this.getTemplateID('TaskLabelTemplate'), false, undefined, progressDiv[0]);
        if (taskLabelTemplateNode && taskLabelTemplateNode.length > 0) {
          append(taskLabelTemplateNode, tempDiv);
          labelString = tempDiv.innerHTML;
        }
      } else {
        var field = this.parent.labelSettings.taskLabel;
        labelString = this.getTaskLabel(field);
        labelString = labelString === 'isCustomTemplate' ? this.parent.labelSettings.taskLabel : labelString;
        if (this.parent.enableHtmlSanitizer && typeof labelString === 'string') {
          labelString = SanitizeHtmlHelper.sanitize(labelString);
        }
      }
      if (labelString.indexOf('null') === -1) {
        if (this.getTaskLabel(this.parent.labelSettings.taskLabel) === 'isCustomTemplate' && !this.isTemplate(this.parent.labelSettings.taskLabel)) {
          labelString = '';
        }
        if (isNaN(parseInt(labelString, 10))) {
          taskLabel$1 = '<span class="' + taskLabel + '" style="line-height:' + (this.taskBarHeight - 1) + 'px; text-align:' + (this.parent.enableRtl ? 'right;' : 'left;') + 'display:' + 'inline-block;' + 'width:' + (data.ganttProperties.width - 10) + 'px; height:' + this.taskBarHeight + 'px;"></span>';
        } else {
          taskLabel$1 = '<span class="' + taskLabel + '" style="line-height:' + (this.taskBarHeight - 1) + 'px;' + (this.parent.viewType === 'ResourceView' ? 'text-align:' + (this.parent.enableRtl ? 'right;' : 'left;') : '') + +(this.parent.viewType === 'ResourceView' ? 'display:inline-flex;' : '') + +(this.parent.viewType === 'ResourceView' ? data.ganttProperties.width - 10 : '') + 'px; height:' + this.taskBarHeight + 'px;"></span>';
        }
      }
      var template = !isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length > 0 ? this.splitTaskbar(data, labelString) : data.ganttProperties.startDate && data.ganttProperties.endDate && data.ganttProperties.duration ? taskLabel$1 : data.ganttProperties.startDate && !data.ganttProperties.endDate && !data.ganttProperties.duration ? '<div class="' + childProgressBarInnerDiv + ' ' + traceChildTaskBar + ' ' + unscheduledTaskbarLeft + ' ' + (data.ganttProperties.isAutoSchedule ? '' : manualChildTaskBar) + '"' + 'style="' + direction + data.ganttProperties.left + 'px; height:' + this.taskBarHeight + 'px;"></div>' : data.ganttProperties.endDate && !data.ganttProperties.startDate && !data.ganttProperties.duration ? '<div class="' + childProgressBarInnerDiv + ' ' + traceChildTaskBar + ' ' + unscheduledTaskbarRight + ' ' + (data.ganttProperties.isAutoSchedule ? '' : manualChildTaskBar) + '"' + 'style="' + direction + data.ganttProperties.left + 'px; height:' + this.taskBarHeight + 'px;"></div>' : data.ganttProperties.duration && !data.ganttProperties.startDate && !data.ganttProperties.endDate ? '<div class="' + childProgressBarInnerDiv + ' ' + traceChildTaskBar + ' ' + unscheduledTaskbar + ' ' + (data.ganttProperties.isAutoSchedule ? '' : manualChildTaskBar) + '"' + 'style="' + direction + data.ganttProperties.left + 'px; width:' + data.ganttProperties.width + 'px;' + ' height:' + this.taskBarHeight + 'px;"></div>' : '';
      if (data.ganttProperties.segments && data.ganttProperties.segments.length > 0) {
        var progress = this.getSplitProgressResizerNode();
        template = template + progress;
      }
      if (data.ganttProperties.startDate && data.ganttProperties.endDate && data.ganttProperties.duration && (isNullOrUndefined(data.ganttProperties.segments) || !isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length === 0)) {
        if (template !== '' && !isNullOrUndefined(progressDiv) && progressDiv.length > 0) {
          /* eslint-disable-next-line */
          var templateElement = this.createDivElement(template)[0];
          if (this.parent.disableHtmlEncode) {
            templateElement.innerText = labelString;
          } else {
            templateElement.innerHTML = labelString;
          }
          var childLabel = this.parent.labelSettings.taskLabel;
          if (childLabel && childLabel['elementRef']) {
            templateElement.appendChild(tempDiv);
          }
          progressDiv[0].appendChild(templateElement);
          if (progressDiv[0].querySelectorAll('.e-task-label')[0].textContent !== '' && !this.isTemplate(childLabel) && progressDiv[0].querySelectorAll('.e-task-label')[0].children[0]) {
            progressDiv[0].querySelectorAll('.e-task-label')[0].children[0].remove();
          }
          if (progressDiv[0].querySelectorAll('.e-task-label')[0].textContent === '' && childLabel && !childLabel['elementRef'] && tempDiv.innerHTML !== '') {
            progressDiv[0].querySelectorAll('.e-task-label')[0].textContent = childLabel;
          }
        }
        if (!isNullOrUndefined(taskbarInnerDiv) && taskbarInnerDiv.length > 0) {
          taskbarInnerDiv[0].appendChild([].slice.call(progressDiv)[0]);
        }
        childTaskbarNode = taskbarInnerDiv;
      } else {
        childTaskbarNode = this.createDivElement(template);
      }
    }
    if (this.parent.enableRtl && !isNullOrUndefined(childTaskbarNode) && childTaskbarNode[0] && childTaskbarNode[0].querySelector('.e-task-label')) {
      childTaskbarNode[0].querySelector('.e-task-label').style.marginLeft = '15px';
      childTaskbarNode[0].querySelector('.e-task-label').style.marginRight = '8px';
      if (childTaskbarNode[0].querySelector('.e-gantt-child-progressbar')) {
        childTaskbarNode[0].querySelector('.e-gantt-child-progressbar').style.textAlign = 'left';
      }
    }
    return childTaskbarNode;
  };
  ChartRows.prototype.splitTaskbar = function (data, labelString) {
    var splitTasks = '';
    for (var i = 0; i < data.ganttProperties.segments.length; i++) {
      var segment = data.ganttProperties.segments[i];
      var progressBarVisible = void 0;
      if (!segment.showProgress) {
        progressBarVisible = 'hidden';
      } else {
        progressBarVisible = 'initial';
      }
      var segmentPosition = i === 0 ? 'e-segment-first' : i === data.ganttProperties.segments.length - 1 ? 'e-segment-last' : 'e-segment-inprogress';
      splitTasks +=
      //split taskbar
      '<div class="' + childTaskBarInnerDiv + ' ' + segmentPosition + ' ' + traceChildTaskBar + ' ' + ' e-segmented-taskbar' + '"style="width:' + segment.width + 'px;position: absolute;' + (this.parent.enableRtl ? 'right:' : 'left:') + segment.left + 'px;height:' + this.taskBarHeight + 'px; overflow:' + progressBarVisible + ';" data-segment-index = "' + i + '" aria-label = "' + this.generateSpiltTaskAriaLabel(segment, data.ganttProperties) + '"> ' + this.getSplitTaskbarLeftResizerNode() +
      //split progress bar
      '<div class="' + childProgressBarInnerDiv + ' ' + traceChildProgressBar + ' ' + '" style="border-style:' + (segment.progressWidth ? 'solid;' : 'none;') + 'display:' + (segment.progressWidth >= 0 ? 'block;' : 'none;') + 'width:' + segment.progressWidth + 'px;height:100%;' + 'text-align:' + (this.parent.enableRtl ? 'left;' : 'right;') + 'border-top-right-radius:' + this.getSplitTaskBorderRadius(segment) + 'px;' + 'border-bottom-right-radius:' + this.getSplitTaskBorderRadius(segment) + 'px;">' +
      // progress label
      '<span class="' + taskLabel + '" style="line-height:' + (this.taskBarHeight - 1) + 'px;display:' + (segment.showProgress ? 'inline;' : 'none;') + 'height:' + this.taskBarHeight + 'px;">' + labelString + '</span>' + '</div>' + this.getSplitTaskbarRightResizerNode(segment) + '</div></div>';
    }
    return splitTasks;
  };
  ChartRows.prototype.getSplitTaskbarLeftResizerNode = function () {
    var lResizerLeft = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 5 : -2;
    var template = '<div class="' + taskBarLeftResizer + ' ' + icon + '"' + ' style="' + (this.parent.enableRtl ? 'right:' : 'left:') + lResizerLeft + 'px;height:' + this.taskBarHeight + 'px;z-index:1"></div>';
    return template;
  };
  ChartRows.prototype.getSplitTaskbarRightResizerNode = function (segment) {
    var rResizerLeft = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? -17 : -10;
    var template = '<div class="' + taskBarRightResizer + ' ' + icon + '"' + ' style="' + (this.parent.enableRtl ? 'right:' : 'left:') + (segment.width + rResizerLeft) + 'px;' + 'height:' + this.taskBarHeight + 'px;z-index:1"></div>';
    return template;
  };
  ChartRows.prototype.getSplitProgressResizerNode = function () {
    var width = this.parent.enableRtl ? this.templateData.ganttProperties.progressWidth + 8 : this.templateData.ganttProperties.progressWidth - 6;
    var template = '<div class="' + childProgressResizer + '"' + ' style="' + (this.parent.enableRtl ? 'right:' : 'left:') + width + 'px;margin-top:' + (this.taskBarHeight - 4) + 'px;"><div class="' + progressBarHandler + '"' + '><div class="' + progressHandlerElement + '"></div>' + '<div class="' + progressBarHandlerAfter + '"></div></div>';
    return template;
  };
  ChartRows.prototype.getSegmentIndex = function (splitStartDate, record) {
    var segmentIndex = -1;
    var ganttProp = record.ganttProperties;
    var segments = ganttProp.segments;
    if (!isNullOrUndefined(segments)) {
      segments.sort(function (a, b) {
        return a.startDate.getTime() - b.startDate.getTime();
      });
      var length_1 = segments.length;
      for (var i = 0; i < length_1; i++) {
        var segment = segments[i];
        // To find if user tend to split the start date of a main taskbar
        // purpose of this to restrict the split action
        if (splitStartDate.getTime() === ganttProp.startDate.getTime()) {
          this.dropSplit = true;
          segmentIndex = 0;
          // To find the if user tend to split the first date of already segmented task.
          // purpose of this to move on day of a segment
        } else if (splitStartDate.getTime() === segment.startDate.getTime()) {
          this.dropSplit = true;
          var sDate = segment.startDate;
          sDate.setDate(sDate.getDate() + 1);
          sDate = segment.startDate = this.parent.dataOperation.checkStartDate(sDate, ganttProp, false);
          segment.startDate = sDate;
          var eDate = segment.endDate;
          eDate = this.parent.dataOperation.getEndDate(sDate, segment.duration, ganttProp.durationUnit, ganttProp, false);
          segment.endDate = eDate;
          if (i === segments.length - 1) {
            this.parent.setRecordValue('endDate', eDate, ganttProp, true);
          }
          this.incrementSegments(segments, i, record);
          segmentIndex = segment.segmentIndex;
          // To find if the user tend to split the segment and find the segment index
        } else {
          segment.endDate = this.parent.dataOperation.getEndDate(segment.startDate, segment.duration, ganttProp.durationUnit, ganttProp, false);
          if (splitStartDate.getTime() >= segment.startDate.getTime() && splitStartDate.getTime() <= segment.endDate.getTime()) {
            segmentIndex = segment.segmentIndex;
          }
        }
        this.parent.setRecordValue('segments', ganttProp.segments, ganttProp, true);
      }
    }
    if (segmentIndex === -1) {
      this.dropSplit = true;
    }
    return segmentIndex;
  };
  ChartRows.prototype.mergeTask = function (taskId, segmentIndexes) {
    var mergeArrayLength = segmentIndexes.length;
    var taskFields = this.parent.taskFields;
    var mergeData = this.parent.flatData.filter(function (x) {
      if (x[taskFields.id] === taskId) {
        return x;
      } else {
        return null;
      }
    })[0];
    if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
      var details = {};
      details['action'] = 'MergeTaskbar';
      if (this.parent['isUndoRedoItemPresent']('Edit')) {
        if (this.parent.editModule && this.parent.editModule.taskbarEditModule['isDragged'] && this.parent.getUndoActions().length > 0) {
          this.parent.undoRedoModule['getUndoCollection'].splice(this.parent.undoRedoModule['getUndoCollection'].length - 1, 1);
        }
        this.parent.undoRedoModule['createUndoCollection']();
        var rec = this.parent.previousFlatData[mergeData.index];
        details['modifiedRecords'] = extend([], [rec], [], true);
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = details;
      }
    }
    var segments = mergeData.ganttProperties.segments;
    segmentIndexes = segmentIndexes.sort(function (a, b) {
      return b.firstSegmentIndex - a.firstSegmentIndex;
    });
    for (var arrayLength = 0; arrayLength < mergeArrayLength; arrayLength++) {
      var firstSegment = segments[segmentIndexes[arrayLength].firstSegmentIndex];
      var secondSegment = segments[segmentIndexes[arrayLength].secondSegmentIndex];
      var duration = firstSegment.duration + secondSegment.duration;
      var endDate = this.parent.dataOperation.getEndDate(firstSegment.startDate, duration, mergeData.ganttProperties.durationUnit, mergeData.ganttProperties, false);
      var segment = {
        startDate: firstSegment.startDate,
        endDate: endDate,
        duration: duration
      };
      var insertIndex = segmentIndexes[arrayLength].firstSegmentIndex;
      segments.splice(insertIndex, 2, segment);
      this.parent.setRecordValue('segments', segments, mergeData.ganttProperties, true);
      this.parent.dataOperation.updateMappingData(mergeData, 'segments');
      if (segments.length === 1) {
        this.parent.setRecordValue('endDate', endDate, mergeData.ganttProperties, true);
        this.parent.setRecordValue('EndDate', endDate, mergeData, true);
        this.parent.setRecordValue('segments', null, mergeData.ganttProperties, true);
        this.parent.dataOperation.updateMappingData(mergeData, 'segments');
      } else if (mergeData.ganttProperties.endDate !== segments[segments.length - 1].endDate) {
        this.parent.setRecordValue('endDate', segments[segments.length - 1].endDate, mergeData.ganttProperties, true);
      }
    }
    var segmentFields;
    if (!isNullOrUndefined(mergeData[taskFields.segments]) && !isNullOrUndefined(mergeData[taskFields.segments][0])) {
      segmentFields = Object.keys(mergeData[taskFields.segments][0]);
    }
    var modifiedSegments = [];
    for (var i = 0; i < segments.length; i++) {
      if (!isNullOrUndefined(segmentFields) && !modifiedSegments[i]) {
        modifiedSegments[i] = {};
      }
      if (!isNullOrUndefined(segmentFields) && segmentFields.indexOf('StartDate') !== -1) {
        modifiedSegments[i][taskFields.startDate] = segments[i].startDate;
      }
      if (!isNullOrUndefined(segmentFields) && segmentFields.indexOf('EndDate') !== -1) {
        modifiedSegments[i][taskFields.endDate] = segments[i].endDate;
      }
      if (!isNullOrUndefined(segmentFields) && segmentFields.indexOf('Duration') !== -1) {
        modifiedSegments[i][taskFields.duration] = segments[i].duration;
      }
    }
    mergeData[taskFields.segments] = modifiedSegments;
    this.updateSegment(mergeData.ganttProperties.segments, taskId);
    this.refreshChartAfterSegment(mergeData, 'mergeSegment');
  };
  ChartRows.prototype.updateSegment = function (segmentData, taskId) {
    var _this = this;
    if (!isNullOrUndefined(this.parent.taskFields.segmentId) && this.parent.segmentData.length > 0) {
      if (!isNullOrUndefined(segmentData)) {
        var segmentsArray = [];
        for (var i = 0; i < segmentData.length; i++) {
          var segmentObj = {};
          var segment = segmentData[i];
          segmentObj[this.parent.taskFields.segmentId] = taskId;
          if (!isNullOrUndefined(this.parent.taskFields.startDate)) {
            segmentObj[this.parent.taskFields.startDate] = segment.startDate;
          }
          if (!isNullOrUndefined(this.parent.taskFields.duration)) {
            segmentObj[this.parent.taskFields.duration] = segment.duration;
          }
          if (!isNullOrUndefined(this.parent.taskFields.endDate)) {
            segmentObj[this.parent.taskFields.endDate] = segment.endDate;
          }
          segmentsArray.push(segmentObj);
        }
        var filterData = this.parent.segmentData.filter(function (data) {
          return !(taskId === data[_this.parent.taskFields.segmentId]);
        });
        for (var i = 0; i < segmentsArray.length; i++) {
          filterData.push(segmentsArray[i]);
        }
        this.parent.segmentData = filterData;
      } else {
        var filterData = this.parent.segmentData.filter(function (data) {
          return !(taskId === data[_this.parent.taskFields.segmentId]);
        });
        this.parent.segmentData = filterData;
      }
    }
  };
  ChartRows.prototype.refreshChartAfterSegment = function (data, requestType) {
    this.parent.setRecordValue('segments', this.parent.dataOperation.setSegmentsInfo(data, false), data.ganttProperties, true);
    this.parent.dataOperation.updateMappingData(data, 'segments');
    this.parent.dataOperation.updateWidthLeft(data);
    this.parent.dataOperation.updateParentItems(data);
    if (this.parent.predecessorModule && this.parent.taskFields.dependency) {
      this.parent.predecessorModule.updatedRecordsDateByPredecessor();
      this.parent.connectorLineModule.removePreviousConnectorLines(this.parent.flatData);
      this.parent.connectorLineEditModule.refreshEditedRecordConnectorLine(this.parent.flatData);
      if (data.parentItem && this.parent.getParentTask(data.parentItem).ganttProperties.isAutoSchedule && this.parent.isInPredecessorValidation) {
        this.parent.dataOperation.updateParentItems(data.parentItem);
      }
      this.refreshRecords(this.parent.currentViewData);
    } else {
      this.refreshRecords(this.parent.currentViewData);
    }
    var tr = this.ganttChartTableBody.querySelectorAll('tr')[this.parent.currentViewData.indexOf(data)];
    var args = {
      requestType: requestType,
      rowData: data,
      modifiedRecords: this.parent.editedRecords,
      modifiedTaskData: getTaskData(this.parent.editedRecords, true)
    };
    this.triggerQueryTaskbarInfoByIndex(tr, data);
    if (this.parent.selectionModule) {
      this.parent.selectionModule.clearSelection();
    }
    var segments = args.rowData.taskData[this.parent.taskFields.segments];
    if (this.parent.timezone && segments != null) {
      for (var i = 0; i < segments.length; i++) {
        segments[i][this.parent.taskFields.startDate] = this.parent.dateValidationModule.remove(args.rowData.ganttProperties.segments[i].startDate, this.parent.timezone);
        if (this.parent.taskFields.endDate) {
          segments[i][this.parent.taskFields.endDate] = this.parent.dateValidationModule.remove(args.rowData.ganttProperties.segments[i].endDate, this.parent.timezone);
        }
      }
    }
    this.parent.trigger('actionComplete', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
    setValue('isEdit', false, this.parent.contextMenuModule);
    setValue('isEdit', false, this.parent);
  };
  /**
   * public method to split task bar.
   *
   * @public
   */
  ChartRows.prototype.splitTask = function (taskId, splitDates) {
    var taskFields = this.parent.taskFields;
    var splitDate = splitDates;
    var splitRecord = this.parent.flatData.filter(function (x) {
      if (x[taskFields.id] === taskId) {
        return x;
      } else {
        return null;
      }
    })[0];
    if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
      var details = {};
      details['action'] = 'MergeTaskbar';
      if (this.parent['isUndoRedoItemPresent']('Edit')) {
        this.parent.undoRedoModule['createUndoCollection']();
        details['modifiedRecords'] = extend([], [splitRecord], [], true);
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = details;
      }
    }
    var ganttProp = splitRecord.ganttProperties;
    this.dropSplit = false;
    var segmentIndex = -1;
    var segments = ganttProp.segments;
    if (isNullOrUndefined(splitDates.length) || splitDates.length < 0) {
      var splitStartDate = this.parent.dataOperation.checkStartDate(splitDate, ganttProp, false);
      if (splitStartDate.getTime() !== ganttProp.startDate.getTime()) {
        if (ganttProp.isAutoSchedule) {
          if (!isNullOrUndefined(segments) && segments.length > 0) {
            segmentIndex = this.getSegmentIndex(splitStartDate, splitRecord);
          }
          //check atleast one day difference is there to split
          if (this.dropSplit === false && splitDate.getTime() > ganttProp.startDate.getTime() && splitDate.getTime() < ganttProp.endDate.getTime()) {
            segments = segmentIndex !== -1 ? segments : [];
            var startDate = segmentIndex !== -1 ? segments[segmentIndex].startDate : new Date(ganttProp.startDate.getTime());
            var endDate = segmentIndex !== -1 ? segments[segmentIndex].endDate : new Date(ganttProp.endDate.getTime());
            var segmentDuration = this.parent.dataOperation.getDuration(startDate, endDate, ganttProp.durationUnit, ganttProp.isAutoSchedule, ganttProp.isMilestone);
            this.parent.setRecordValue('segments', this.splitSegmentedTaskbar(startDate, endDate, splitDate, segmentIndex, segments, splitRecord, segmentDuration), ganttProp, true);
            var modifiedSegments = [];
            for (var i = 0; i < segments.length; i++) {
              if (!modifiedSegments[i]) {
                modifiedSegments[i] = {};
              }
              modifiedSegments[i][taskFields.startDate] = segments[i].startDate;
              modifiedSegments[i][taskFields.endDate] = segments[i].endDate;
              modifiedSegments[i][taskFields.duration] = segments[i].duration;
            }
            splitRecord[taskFields.segments] = modifiedSegments;
            if (segmentIndex !== -1) {
              this.incrementSegments(segments, segmentIndex + 1, splitRecord);
            }
            this.parent.setRecordValue('endDate', segments[segments.length - 1].endDate, ganttProp, true);
            if (this.parent.taskFields.endDate) {
              this.parent.dataOperation.updateMappingData(splitRecord, 'endDate');
            }
          }
          this.updateSegment(splitRecord.ganttProperties.segments, taskId);
          this.refreshChartAfterSegment(splitRecord, 'splitTaskbar');
        }
      }
    } else {
      splitDates.sort(function (a, b) {
        return a.getTime() - b.getTime();
      });
      this.parent.setRecordValue('segments', this.constructSegments(splitDates, splitRecord.ganttProperties), splitRecord.ganttProperties, true);
      this.updateSegment(splitRecord.ganttProperties.segments, taskId);
      this.refreshChartAfterSegment(splitRecord, 'splitTask');
    }
  };
  ChartRows.prototype.constructSegments = function (dates, taskData) {
    var segmentsArray = [];
    var segment;
    var startDate = new Date();
    var endDate;
    var duration;
    for (var i = 0; i < dates.length + 1; i++) {
      startDate = i === 0 ? taskData.startDate : startDate;
      startDate = this.parent.dataOperation.checkStartDate(startDate, taskData, false);
      endDate = i !== dates.length ? new Date(dates[i].getTime()) > taskData.endDate ? taskData.endDate : new Date(dates[i].getTime()) : taskData.endDate;
      endDate = this.parent.dataOperation.checkEndDate(endDate, taskData, false);
      duration = this.parent.dataOperation.getDuration(startDate, endDate, taskData.durationUnit, taskData.isAutoSchedule, taskData.isMilestone);
      if (endDate.getTime() >= startDate.getTime()) {
        segment = {
          startDate: startDate,
          endDate: endDate,
          duration: duration
        };
        segmentsArray.push(segment);
      }
      if (i === dates.length) {
        break;
      }
      startDate = new Date(dates[i].getTime());
      startDate.setDate(dates[i].getDate() + 1);
    }
    return segmentsArray;
  };
  ChartRows.prototype.splitSegmentedTaskbar = function (startDate, endDate, splitDate, segmentIndex, segments, ganttData, segmentDuration) {
    var ganttProp = ganttData.ganttProperties;
    var checkClickState;
    var endDateState;
    if (this.parent.includeWeekend) {
      checkClickState = -1;
    } else {
      checkClickState = this.parent.nonWorkingDayIndex.indexOf(splitDate.getDay());
    }
    var increment = checkClickState === -1 ? 0 : checkClickState === 0 ? 1 : checkClickState === 1 ? 1 : 2;
    startDate = this.parent.dataOperation.checkStartDate(startDate, ganttProp, false);
    var segmentEndDate = new Date(splitDate.getTime());
    segmentEndDate = this.parent.dataOperation.checkEndDate(segmentEndDate, ganttProp, false);
    for (var i = 0; i < 2; i++) {
      if (this.parent.weekWorkingTime.length > 0) {
        var dayEndTime = this.parent['getCurrentDayEndTime'](segmentEndDate);
        this.setTime(dayEndTime, segmentEndDate);
      }
      var segment = {
        startDate: startDate,
        endDate: segmentEndDate,
        duration: this.parent.dataOperation.getDuration(startDate, segmentEndDate, ganttProp.durationUnit, ganttProp.isAutoSchedule, ganttProp.isMilestone),
        offsetDuration: 1
      };
      if (this.parent.includeWeekend) {
        endDateState = -1;
      } else {
        endDateState = this.parent.nonWorkingDayIndex.indexOf(segmentEndDate.getDay());
      }
      if (segmentIndex !== -1) {
        segments.splice(segmentIndex, 1);
        segmentIndex = -1;
      }
      segments.push(segment);
      var mode = this.parent.timelineModule.customTimelineSettings.bottomTier.unit;
      if (mode === 'Hour' || mode === 'Minutes') {
        startDate = new Date(splitDate.getTime());
        startDate = this.parent.dataOperation.checkStartDate(startDate, ganttProp, false);
        var count = this.parent.timelineModule.customTimelineSettings.bottomTier.count;
        var mode_1 = this.parent.timelineModule.customTimelineSettings.bottomTier.unit;
        var timeIncrement = this.parent.timelineModule.getIncrement(startDate, count, mode_1);
        var newTime = startDate.getTime() + timeIncrement;
        startDate.setTime(newTime + increment);
        segmentEndDate = new Date(endDate.getTime());
        timeIncrement = this.parent.timelineModule.getIncrement(segmentEndDate, count, mode_1);
        newTime = segmentEndDate.getTime() + timeIncrement;
        segmentEndDate.setTime(newTime + increment);
      } else {
        startDate = new Date(splitDate.getTime());
        startDate.setDate(startDate.getDate() + 1 + increment);
        var dayStartTime = this.parent['getCurrentDayStartTime'](startDate);
        this.setTime(dayStartTime, startDate);
        startDate = this.parent.dataOperation.checkStartDate(startDate, ganttProp, false);
        if (!this.parent.taskFields.duration && increment <= 0) {
          startDate.setDate(startDate.getDate() + 1);
        }
        segmentEndDate = new Date(endDate.getTime());
        if (this.isOnHolidayOrWeekEnd(segmentEndDate, true)) {
          do {
            segmentEndDate.setDate(segmentEndDate.getDate() + 1);
          } while (this.isOnHolidayOrWeekEnd(segmentEndDate, true));
        }
        if (!this.parent.includeWeekend) {
          segmentEndDate = this.getNextWorkingDay(segmentEndDate);
        }
      }
      if (endDateState !== -1) {
        var diff = segmentDuration - segment.duration;
        segmentEndDate = this.parent.dataOperation.getEndDate(startDate, diff, ganttProp.durationUnit, ganttProp, false);
      } else {
        segmentEndDate = this.parent.dataOperation.checkEndDate(segmentEndDate, ganttProp, false);
      }
    }
    segments.sort(function (a, b) {
      return a.startDate.getTime() - b.startDate.getTime();
    });
    return segments;
  };
  ChartRows.prototype.incrementSegments = function (segments, segmentIndex, ganttData) {
    var ganttProp = ganttData.ganttProperties;
    for (var i = segmentIndex + 1; i < segments.length; i++) {
      var segment = segments[i];
      var startDate = i !== 0 ? new Date(segments[i - 1].endDate.getTime()) : new Date(segment.startDate.getTime());
      this.parent.dataOperation['fromSegments'] = true;
      startDate = this.parent.dataOperation.getEndDate(startDate, segment.offsetDuration, ganttProp.durationUnit, ganttProp, false);
      this.parent.dataOperation['fromSegments'] = false;
      startDate = this.parent.dataOperation.checkStartDate(startDate, ganttProp, false);
      segment.startDate = startDate;
      var endDate = segment.endDate = this.parent.dataOperation.getEndDate(startDate, segment.duration, ganttProp.durationUnit, ganttProp, false);
      segment.endDate = endDate;
      if (i === segments.length - 1) {
        this.parent.setRecordValue('endDate', endDate, ganttProp, true);
        if (this.parent.taskFields.endDate) {
          this.parent.dataOperation.updateMappingData(ganttData, 'endDate');
        }
      }
    }
    segments.sort(function (a, b) {
      return a.startDate.getTime() - b.startDate.getTime();
    });
    this.parent.setRecordValue('segments', segments, ganttProp, true);
    this.parent.dataOperation.updateMappingData(ganttData, 'segments');
  };
  ChartRows.prototype.calculateLeftValue = function (rowHeight) {
    var taskbarHeightValue = this.parent.renderBaseline ? 0.45 : !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 0.7 : 0.62;
    var defaultTaskbarHeight = Math.floor(this.parent.rowHeight * taskbarHeightValue);
    if (!isNullOrUndefined(this.parent.taskbarHeight) && this.parent.taskbarHeight <= defaultTaskbarHeight || isNullOrUndefined(this.parent.taskbarHeight) && rowHeight <= 36) {
      return 1;
    } else {
      if (rowHeight <= 36) {
        return 1;
      }
      return -1 / 12 * (rowHeight - 36) + 1;
    }
  };
  /**
   * To get milestone node.
   *
   * @param {number} i .
   * @param {NodeList} rootElement .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getMilestoneNode = function (i, rootElement) {
    var milestoneNode = null;
    var data = this.templateData;
    var rowHeight = this.parent.rowHeight;
    var leftValue = this.calculateLeftValue(rowHeight);
    if (this.milestoneTemplateFunction) {
      milestoneNode = this.milestoneTemplateFunction(extend({
        index: i
      }, data), this.parent, 'MilestoneTemplate', this.getTemplateID('MilestoneTemplate'), false, undefined, rootElement[0], this.parent.treeGrid['root']);
    } else {
      var template = '<div class="' + traceMilestone + '" style="width:' + (this.parent.renderBaseline ? this.taskBarHeight : this.taskBarHeight - 6) + 'px;height:' + (this.parent.renderBaseline ? this.taskBarHeight : this.taskBarHeight - 6) + 'px;position:absolute;transform: rotate(45deg);left:' + leftValue + 'px;"> </div>';
      milestoneNode = this.createDivElement(template);
    }
    return milestoneNode;
  };
  /**
   * To get task baseline Node.
   *
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getTaskBaselineNode = function () {
    var data = this.templateData;
    var template = '<div class="' + baselineBar + ' ' + '" role="term" style="margin-top:' + this.baselineTop + 'px;' + (this.parent.enableRtl ? 'right:' : 'left:') + data.ganttProperties.baselineLeft + 'px;' + 'width:' + data.ganttProperties.baselineWidth + 'px;height:' + this.baselineHeight + 'px;' + (this.baselineColor ? 'background-color: ' + this.baselineColor + ';' : '') + '"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.updateTaskBaselineNode = function (childData) {
    var template = '<div class="' + baselineBar + ' ' + '" role="term" style="margin-top:' + this.baselineTop + 'px;' + (this.parent.enableRtl ? 'right:' : 'left:') + childData.ganttProperties.baselineLeft + 'px;' + 'width:' + childData.ganttProperties.baselineWidth + 'px;height:' + this.baselineHeight + 'px;' + (this.baselineColor ? 'background-color: ' + this.baselineColor + ';' : '') + '"></div>';
    return this.createDivElement(template);
  };
  /**
   * To get milestone baseline node.
   *
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getMilestoneBaselineNode = function () {
    var data = this.templateData;
    var baselineMilestoneHeight = this.parent.renderBaseline ? 5 : 2;
    var template = '<div class="' + baselineMilestoneContainer + '" style="width:' + (this.parent.renderBaseline ? this.taskBarHeight : this.taskBarHeight - 10) + 'px;height:' + (this.parent.renderBaseline ? this.taskBarHeight : this.taskBarHeight - 10) + 'px;position:absolute;transform:rotate(45deg);' + (this.parent.enableRtl ? 'right:' : 'left:') + (this.parent.enableRtl ? data.ganttProperties.left - this.milestoneHeight / 2 + 3 : data.ganttProperties.baselineLeft - this.milestoneHeight / 2 + 1) + 'px;' + (this.baselineColor ? 'background-color: ' + this.baselineColor + ';' : '') + 'margin-top:' + (-Math.floor(this.parent.rowHeight - this.milestoneMarginTop) + baselineMilestoneHeight + 2) + 'px"> </div>';
    return this.createDivElement(template);
  };
  /**
   * To get left label node.
   *
   * @param {number} i .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getLeftLabelNode = function (i) {
    var leftLabelNode = this.leftLabelContainer();
    if (this.generateTaskLabelAriaLabel('left') !== '') {
      leftLabelNode[0].setAttribute('aria-label', this.generateTaskLabelAriaLabel('left'));
    }
    var leftLabelTemplateNode = null;
    if (this.leftTaskLabelTemplateFunction) {
      leftLabelTemplateNode = this.leftTaskLabelTemplateFunction(extend({
        index: i
      }, this.templateData), this.parent, 'LeftLabelTemplate', this.getTemplateID('LeftLabelTemplate'), false, undefined, leftLabelNode[0], this.parent.treeGrid['root']);
    } else {
      var field = this.parent.labelSettings.leftLabel;
      var labelString = this.getTaskLabel(field);
      if (labelString) {
        labelString = labelString === 'isCustomTemplate' ? field : labelString;
        leftLabelTemplateNode = this.getLableText(labelString, leftLabelInnerDiv);
        if (this.parent.enableHtmlSanitizer && typeof labelString === 'string') {
          labelString = SanitizeHtmlHelper.sanitize(labelString);
          labelString = labelString === 'isCustomTemplate' ? field : labelString;
          leftLabelTemplateNode = this.getLableText(labelString, leftLabelInnerDiv);
        }
      }
    }
    if (leftLabelTemplateNode && leftLabelTemplateNode.length > 0) {
      if (leftLabelTemplateNode[0]['data'] === 'null') {
        leftLabelTemplateNode[0]['data'] = '';
      }
      append(leftLabelTemplateNode, leftLabelNode[0]);
    }
    if (this.parent.enableRtl) {
      leftLabelNode[0].style.paddingLeft = '25px';
      leftLabelNode[0].style.paddingRight = '0px';
    }
    return leftLabelNode;
  };
  ChartRows.prototype.getLableText = function (labelString, labelDiv) {
    var leftLabelHeight = this.parent.renderBaseline ? (this.parent.rowHeight - this.taskBarHeight) / 2 : this.taskBarMarginTop;
    var templateString = createElement('div', {
      className: labelDiv,
      styles: 'height:' + this.taskBarHeight + 'px;' + 'margin-top:' + leftLabelHeight + 'px;'
    });
    var spanElem = createElement('span', {
      className: label
    });
    var property = this.parent.disableHtmlEncode ? 'textContent' : 'innerHTML';
    spanElem[property] = labelString;
    templateString.appendChild(spanElem);
    var div = createElement('div');
    div.appendChild(templateString);
    return div.childNodes;
  };
  /**
   * To get right label node.
   *
   * @param {number} i .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getRightLabelNode = function (i) {
    var rightLabelNode = this.rightLabelContainer();
    if (this.generateTaskLabelAriaLabel('right') !== '') {
      rightLabelNode[0].setAttribute('aria-label', this.generateTaskLabelAriaLabel('right'));
    }
    var rightLabelTemplateNode = null;
    if (this.rightTaskLabelTemplateFunction) {
      rightLabelTemplateNode = this.rightTaskLabelTemplateFunction(extend({
        index: i
      }, this.templateData), this.parent, 'RightLabelTemplate', this.getTemplateID('RightLabelTemplate'), false, undefined, rightLabelNode[0], this.parent.treeGrid['root']);
    } else {
      var field = this.parent.labelSettings.rightLabel;
      var labelString = this.getTaskLabel(field);
      if (labelString) {
        labelString = labelString === 'isCustomTemplate' ? field : labelString;
        rightLabelTemplateNode = this.getLableText(labelString, rightLabelInnerDiv);
        if (this.parent.enableHtmlSanitizer && typeof labelString === 'string') {
          labelString = SanitizeHtmlHelper.sanitize(labelString);
          labelString = labelString === 'isCustomTemplate' ? field : labelString;
          rightLabelTemplateNode = this.getLableText(labelString, rightLabelInnerDiv);
        }
      }
    }
    if (rightLabelTemplateNode && rightLabelTemplateNode.length > 0) {
      if (rightLabelTemplateNode[0]['data'] === 'null') {
        rightLabelTemplateNode[0]['data'] = '';
      }
      append(rightLabelTemplateNode, rightLabelNode[0]);
    }
    if (this.parent.enableRtl) {
      rightLabelNode[0].style.marginLeft = '0px';
      rightLabelNode[0].style.paddingRight = '25px';
    }
    return rightLabelNode;
  };
  ChartRows.prototype.getManualTaskbar = function () {
    var data = this.templateData;
    var taskbarHeight = this.taskBarHeight / 2 - 1;
    var innerDiv = data.ganttProperties.startDate && data.ganttProperties.endDate && (data.ganttProperties.duration || data.hasChildRecords) ? '<div class="' + manualParentTaskBar + '" style="width:' + data.ganttProperties.width + 'px;' + 'height:' + taskbarHeight / 5 + 'px;border-left-width:' + taskbarHeight / 5 + 'px; border-bottom:' + taskbarHeight / 5 + 'px solid transparent;"></div>' : !data.ganttProperties.startDate && !data.ganttProperties.endDate && data.ganttProperties.duration ? '<div class="' + manualParentTaskBar + ' ' + traceManualUnscheduledTask + '" style="width:' + data.ganttProperties.width + 'px;' + 'height:' + (taskbarHeight / 5 + 1) + 'px;border-left-width:' + taskbarHeight / 5 + 'px; border-bottom:' + taskbarHeight / 5 + 'px solid transparent;"></div>' : '<div class="' + manualParentTaskBar + ' ' + (data.ganttProperties.startDate ? unscheduledTaskbarLeft : unscheduledTaskbarRight) + '" style="width:' + data.ganttProperties.width + 'px;' + 'height:' + taskbarHeight * 2 + 'px;border-left-width:' + taskbarHeight / 5 + 'px; border-bottom:' + taskbarHeight / 5 + 'px solid transparent;"></div>';
    var childEle = innerDiv + (data.ganttProperties.startDate && data.ganttProperties.endDate && (data.ganttProperties.duration || data.hasChildRecords) || data.ganttProperties.duration ? '<div class="e-gantt-manualparenttaskbar-left" style=' + (this.parent.enableRtl ? 'margin-right:0px;' : '') + '"height:' + (taskbarHeight / 5 + 8) + 'px;border-left-width:' + taskbarHeight / 5 + 'px; border-bottom:' + taskbarHeight / 5 + 'px solid transparent;"></div>' + '<div class="e-gantt-manualparenttaskbar-right" style=' + (this.parent.enableRtl ? 'margin-right:-8px;' : '') + (this.parent.enableRtl ? 'right:' : 'left:') + (data.ganttProperties.width - Math.floor((taskbarHeight / 5 + 8) / 5)) + 'px;height:' + (taskbarHeight / 5 + 8) + 'px;border-right-width:' + taskbarHeight / 5 + 'px;border-bottom:' + taskbarHeight / 5 + 'px solid transparent;>' + '</div></div>' : '');
    var template = '<div class="' + manualParentMainContainer + '"' + 'style=' + (this.parent.enableRtl ? 'right:' : 'left:') + (data.ganttProperties.left - data.ganttProperties.autoLeft) + 'px;' + 'width:' + data.ganttProperties.width + 'px;' + 'height:' + taskbarHeight + 'px;cursor:' + (this.parent.editSettings.allowTaskbarEditing ? 'move;' : 'default;') + '</div>';
    var milestoneTemplate = '<div class="' + manualParentMilestone + '" style="width:' + (this.parent.renderBaseline ? this.taskBarHeight - 3 : this.taskBarHeight - 7) + 'px;height:' + (this.parent.renderBaseline ? this.taskBarHeight - 3 : this.taskBarHeight - 7) + 'px;position:absolute;transform: rotate(45deg);top:' + (this.parent.rowHeight > 40 ? 0 : 2) + 'px;left:' + (this.parent.renderBaseline ? 2 : 1) + 'px;"> </div>';
    return this.createDivElement(data.ganttProperties.duration === 0 && data.hasChildRecords && !data.ganttProperties.isAutoSchedule ? milestoneTemplate + childEle : template + childEle);
  };
  /**
   * To get parent taskbar node.
   *
   * @param {number} i .
   * @param {NodeList} rootElement .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getParentTaskbarNode = function (i, rootElement) {
    var parentTaskbarNode = null;
    var data = this.templateData;
    if (this.parentTaskbarTemplateFunction) {
      parentTaskbarNode = this.parentTaskbarTemplateFunction(extend({
        index: i
      }, data), this.parent, 'ParentTaskbarTemplate', this.getTemplateID('ParentTaskbarTemplate'), false, undefined, rootElement[0], this.parent.treeGrid['root']);
    } else {
      var labelString = '';
      var labelDiv = void 0;
      var tHeight = this.taskBarHeight / 5;
      var template = this.createDivElement('<div class="' + parentTaskBarInnerDiv + ' ' + this.getExpandClass(data) + ' ' + traceParentTaskBar + '"' + ' style="width:' + (data.ganttProperties.isAutoSchedule ? data.ganttProperties.width : data.ganttProperties.autoWidth) + 'px;height:' + (data.ganttProperties.isAutoSchedule ? this.taskBarHeight : tHeight * 3) + 'px;margin-top:' + (data.ganttProperties.isAutoSchedule ? -1 : tHeight * 2) + 'px; ">' + '</div>');
      var progressBarInnerDiv = this.createDivElement('<div class="' + parentProgressBarInnerDiv + ' ' + this.getExpandClass(data) + ' ' + traceParentProgressBar + '"' + ' style="border-style:' + (data.ganttProperties.progressWidth ? 'solid;' : 'none;') + 'width:' + data.ganttProperties.progressWidth + 'px;' + 'border-top-right-radius:' + this.getBorderRadius(data) + 'px;' + 'border-bottom-right-radius:' + this.getBorderRadius(data) + 'px;height:100%;"></div>');
      var div = createElement('div');
      if (this.taskLabelTemplateFunction) {
        var parentTaskLabelNode = this.taskLabelTemplateFunction(extend({
          index: i
        }, data), this.parent, 'TaskLabelTemplate', this.getTemplateID('TaskLabelTemplate'), false, undefined, progressBarInnerDiv[0]);
        if (parentTaskLabelNode && parentTaskLabelNode.length > 0) {
          append(parentTaskLabelNode, div);
          labelString = div.innerHTML;
        }
      } else {
        labelString = this.getTaskLabel(this.parent.labelSettings.taskLabel);
        labelString = labelString === 'isCustomTemplate' ? this.parent.labelSettings.taskLabel : labelString;
        if (this.parent.enableHtmlSanitizer && typeof labelString === 'string') {
          labelString = SanitizeHtmlHelper.sanitize(labelString);
        }
      }
      if (labelString.indexOf('null') === -1) {
        if (this.getTaskLabel(this.parent.labelSettings.taskLabel) === 'isCustomTemplate' && !this.isTemplate(this.parent.labelSettings.taskLabel)) {
          labelString = '';
        }
        if (isNaN(parseInt(labelString, 10))) {
          labelDiv = '<span class="' + taskLabel + '" style="line-height:' + (data['isManual'] && data.hasChildRecords ? Math.floor(60 / 100 * this.taskBarHeight) : this.taskBarHeight - 1) + 'px; text-align:' + (this.parent.enableRtl ? 'right;' : 'left;') + 'display:' + 'inline-block;' + 'width:' + (data.ganttProperties.width - 10) + 'px; height:' + this.taskBarHeight + 'px;"></span>';
        } else {
          labelDiv = '<span class="' + taskLabel + '" style="line-height:' + (data['isManual'] && data.hasChildRecords ? Math.floor(60 / 100 * this.taskBarHeight) : this.taskBarHeight - 1) + 'px;' + (this.parent.viewType === 'ResourceView' ? 'display:inline-flex;' : '') + (this.parent.viewType === 'ResourceView' ? 'width:' + (data.ganttProperties.width - 10) : '') + 'px; height:' + (this.taskBarHeight - 1) + 'px;' + (this.parent.viewType === 'ResourceView' ? 'display: inline-flex;' : '') + (this.parent.viewType === 'ResourceView' ? 'width:' + (data.ganttProperties.width - 10) : '') + 'px; height:' + this.taskBarHeight + 'px;"></span>';
        }
        var labelElement = this.createDivElement(labelDiv)[0];
        if (this.parent.disableHtmlEncode) {
          labelElement.innerText = labelString;
        } else {
          labelElement.innerHTML = labelString;
        }
        var parentLabel = this.parent.labelSettings.taskLabel;
        if (parentLabel && parentLabel['elementRef']) {
          labelElement.appendChild(div);
        }
        progressBarInnerDiv[0].appendChild(labelElement);
        if (progressBarInnerDiv[0].querySelectorAll('.e-task-label')[0].textContent !== '' && !this.isTemplate(parentLabel) && progressBarInnerDiv[0].querySelectorAll('.e-task-label')[0].children[0]) {
          progressBarInnerDiv[0].querySelectorAll('.e-task-label')[0].children[0].remove();
        }
        if (progressBarInnerDiv[0].querySelectorAll('.e-task-label')[0].textContent === '' && parentLabel && !parentLabel['elementRef'] && div.innerHTML !== '') {
          progressBarInnerDiv[0].querySelectorAll('.e-task-label')[0].textContent = parentLabel;
        }
      }
      var milestoneTemplate = '<div class="' + parentMilestone + '" style="width:' + (this.parent.renderBaseline ? this.taskBarHeight - 3 : this.taskBarHeight - 7) + 'px;height:' + (this.parent.renderBaseline ? this.taskBarHeight - 3 : this.taskBarHeight - 7) + 'px;position:absolute;transform: rotate(45deg);top:' + (this.parent.rowHeight > 40 ? 0 : 2) + 'px;left:' + (this.parent.renderBaseline ? 2 : 1) + 'px;"> </div>';
      template[0].appendChild([].slice.call(progressBarInnerDiv)[0]);
      parentTaskbarNode = data.ganttProperties.isMilestone ? this.createDivElement(data.ganttProperties.isAutoSchedule ? milestoneTemplate : '') : template;
    }
    if (this.parent.enableRtl && parentTaskbarNode[0] && parentTaskbarNode[0].querySelector('.e-task-label')) {
      parentTaskbarNode[0].querySelector('.e-task-label').style.marginLeft = '15px';
      parentTaskbarNode[0].querySelector('.e-task-label').style.marginRight = '8px';
      if (parentTaskbarNode[0].querySelector('.e-gantt-parent-progressbar')) {
        parentTaskbarNode[0].querySelector('.e-gantt-parent-progressbar').style.textAlign = 'left';
      }
    }
    return parentTaskbarNode;
  };
  /**
   * To get taskbar row('TR') node
   *
   * @param {number} i .
   * @returns {NodeList} .
   * @private
   */
  ChartRows.prototype.getTableTrNode = function (i) {
    var _this = this;
    var table = createElement('table');
    var className = this.parent.gridLines === 'Horizontal' || this.parent.gridLines === 'Both' ? 'e-chart-row-border' : '';
    /* eslint-disable-next-line */
    var activecls;
    var rows;
    if (this.parent.treeGridModule.isPersist) {
      setTimeout(function () {
        if (!isNullOrUndefined(_this.parent.treeGrid.grid) && !isNullOrUndefined(_this.parent.treeGrid.grid.contentModule) && !isNullOrUndefined(_this.parent.treeGrid.grid.contentModule.getRows())) {
          rows = _this.parent.treeGrid.grid.contentModule.getRows()[i];
          if (rows && rows.isSelected) {
            activecls = 'e-active';
          } else {
            activecls = '';
          }
        }
      }, 0);
    } else {
      rows = this.parent.treeGrid.grid.contentModule.getRows()[i];
      if (rows && rows.isSelected) {
        activecls = 'e-active';
      } else {
        activecls = '';
      }
    }
    table.innerHTML = '<tr class="' + this.getRowClassName(this.templateData) + ' ' + chartRow + ' ' + activecls + '"' + 'style="display:' + this.getExpandDisplayProp(this.templateData) + ';height:' + this.parent.rowHeight + 'px;">' + '<td class="' + chartRowCell + ' ' + className + '"style="width:' + this.parent.timelineModule.totalTimelineWidth + 'px;"></td></tr>';
    return table.childNodes;
  };
  /**
   * To initialize chart templates.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.initializeChartTemplate = function () {
    if (!isNullOrUndefined(this.parent.parentTaskbarTemplate)) {
      this.parentTaskbarTemplateFunction = this.templateCompiler(this.parent.parentTaskbarTemplate);
    }
    if (!isNullOrUndefined(this.parent.labelSettings.leftLabel) && this.isTemplate(this.parent.labelSettings.leftLabel)) {
      this.leftTaskLabelTemplateFunction = this.templateCompiler(this.parent.labelSettings.leftLabel);
    }
    if (!isNullOrUndefined(this.parent.labelSettings.rightLabel) && this.isTemplate(this.parent.labelSettings.rightLabel)) {
      this.rightTaskLabelTemplateFunction = this.templateCompiler(this.parent.labelSettings.rightLabel);
    }
    if (!isNullOrUndefined(this.parent.labelSettings.taskLabel) && this.isTemplate(this.parent.labelSettings.taskLabel)) {
      this.taskLabelTemplateFunction = this.templateCompiler(this.parent.labelSettings.taskLabel);
    }
    if (!isNullOrUndefined(this.parent.taskbarTemplate)) {
      this.childTaskbarTemplateFunction = this.templateCompiler(this.parent.taskbarTemplate);
    }
    if (!isNullOrUndefined(this.parent.milestoneTemplate)) {
      this.milestoneTemplateFunction = this.templateCompiler(this.parent.milestoneTemplate);
    }
  };
  ChartRows.prototype.createDivElement = function (template) {
    var div = createElement('div');
    div.innerHTML = template;
    return div.childNodes;
  };
  ChartRows.prototype.isTemplate = function (template) {
    var result = false;
    for (var i = 0; i < this.parent.ganttColumns.length; i++) {
      if (template === this.parent.ganttColumns[i].field) {
        result = true;
        break;
      }
    }
    if (typeof template !== 'string' || template.indexOf('#') === 0 || template.indexOf('<') > -1 || template.indexOf('$') > -1 || !result) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };
  /**
   * @param {string} templateName .
   * @returns {string} .
   * @private
   */
  ChartRows.prototype.getTemplateID = function (templateName) {
    var ganttID = this.parent.element.id;
    return ganttID + templateName;
  };
  ChartRows.prototype.leftLabelContainer = function () {
    var template = '<div class="' + (this.leftTaskLabelTemplateFunction ? leftLabelTempContainer : leftLabelContainer) + ' ' + '" tabindex="-1" role="term" style="height:' + (this.parent.rowHeight - 2) + 'px;width:' + this.taskNameWidth(this.templateData) + '"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.taskbarContainer = function () {
    var data = this.templateData;
    var manualParent = this.parent.editModule && this.parent.editSettings.allowTaskbarEditing && this.parent.editModule.taskbarEditModule.taskBarEditAction === 'ParentResizing' ? true : false;
    var template = '<div class="' + taskBarMainContainer + ' ' + this.parent.getUnscheduledTaskClass(data.ganttProperties) + ' ' + (data.ganttProperties.cssClass ? data.ganttProperties.cssClass : '') + '" ' + ' tabindex="-1" role="term" style="' + (data.ganttProperties.isMilestone && !manualParent && !(data.hasChildRecords && !data.ganttProperties.isAutoSchedule) ? 'width:' + this.milestoneHeight + 'px;height:' + this.milestoneHeight + 'px;margin-top:' + this.milestoneMarginTop + 'px;' + (this.parent.enableRtl ? 'right:' : 'left:') + (data.ganttProperties.left - this.milestoneHeight / 2) + 'px;cursor:' + (this.parent.editSettings.allowTaskbarEditing ? 'move;' : 'default;') : 'width:' + data.ganttProperties.width + 'px;margin-top:' + this.taskBarMarginTop + 'px;' + (this.parent.enableRtl ? 'right:' : 'left:') + (!data.hasChildRecords || data.ganttProperties.isAutoSchedule ? data.ganttProperties.left : data.ganttProperties.autoLeft) + 'px;height:' + this.taskBarHeight + 'px;cursor:' + (this.parent.editSettings.allowTaskbarEditing ? 'move;' : 'default;')) + '"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.rightLabelContainer = function () {
    var template = '<div class="' + (this.rightTaskLabelTemplateFunction ? rightLabelTempContainer : rightLabelContainer) + '" ' + ' tabindex="-1" role="term" style="' + (this.parent.enableRtl ? 'right:' : 'left:') + this.getRightLabelLeft(this.templateData) + 'px; height:' + (this.parent.rowHeight - 2) + 'px;"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.childTaskbarLeftResizer = function () {
    var lResizerLeft = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 5 : -2;
    var template = '<div class="' + taskBarLeftResizer + ' ' + icon + '"' + 'style="' + (this.parent.enableRtl ? 'right:' : 'left:') + lResizerLeft + 'px;height:' + this.taskBarHeight + 'px;z-index:1"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.childTaskbarRightResizer = function () {
    var rResizerLeft = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? -17 : -11;
    var template = '<div class="' + taskBarRightResizer + ' ' + icon + '"' + 'style="' + (this.parent.enableRtl ? 'right:' : 'left:') + (this.templateData.ganttProperties.width + rResizerLeft) + 'px;' + 'height:' + this.taskBarHeight + 'px;z-index:1"></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.childTaskbarProgressResizer = function () {
    var width = this.parent.enableRtl ? this.templateData.ganttProperties.progressWidth + 8 : this.templateData.ganttProperties.progressWidth - 6;
    var template = '<div class="' + childProgressResizer + '"' + 'style="' + (this.parent.enableRtl ? 'right:' : 'left:') + width + 'px;margin-top:' + (this.taskBarHeight - 4) + 'px;"><div class="' + progressBarHandler + '"' + '><div class="' + progressHandlerElement + '"></div>' + '<div class="' + progressBarHandlerAfter + '"></div></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.getLeftPointNode = function () {
    var data = this.templateData;
    var left = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 12 : 0;
    var mileStoneLeftValue = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 6 : 3;
    var pointerLeft = -(2 + this.connectorPointWidth + left);
    var mileStoneLeft = -(this.connectorPointWidth + mileStoneLeftValue);
    var pointerTop = Math.floor(this.milesStoneRadius - this.connectorPointWidth / 2);
    var marginTop;
    if (!this.templateData.ganttProperties.isAutoSchedule && this.templateData.hasChildRecords && this.parent.allowParentDependency) {
      marginTop = '';
    } else {
      marginTop = 'margin-top:' + this.connectorPointMargin + 'px';
    }
    var canAdd = true;
    if (data.hasChildRecords && !this.parent.allowParentDependency) {
      canAdd = false;
    }
    var template = '<div class="' + leftConnectorPointOuterDiv + '" style="' + (data.ganttProperties.isMilestone ? 'margin-top:' + pointerTop + 'px;left:' + mileStoneLeft + 'px;' : marginTop + ';left:' + pointerLeft + 'px;') + '">' + '<div class="' + (canAdd ? connectorPointLeft : '') + ' ' + this.parent.getUnscheduledTaskClass(data.ganttProperties) + '" style="width: ' + this.connectorPointWidth + 'px;' + (this.parent.enableRtl ? 'margin-right:2px;' : '') + 'height: ' + this.connectorPointWidth + 'px;">' + this.touchLeftConnectorpoint + '</div></div>';
    return this.createDivElement(template);
  };
  ChartRows.prototype.getRightPointNode = function () {
    var data = this.templateData;
    var right = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? -12 : 0;
    var pointerRight = -(3 + right);
    var pointerTop = Math.floor(this.milesStoneRadius - this.connectorPointWidth / 2);
    var marginTop;
    if (!this.templateData.ganttProperties.isAutoSchedule && this.templateData.hasChildRecords && this.parent.allowParentDependency) {
      marginTop = '';
    } else {
      marginTop = 'margin-top:' + this.connectorPointMargin + 'px';
    }
    var canAdd = true;
    if (data.hasChildRecords && !this.parent.allowParentDependency) {
      canAdd = false;
    }
    var template = '<div class="' + rightConnectorPointOuterDiv + '" style="' + (data.ganttProperties.isMilestone ? 'left:' + (!isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? this.milestoneHeight + 5 : this.milestoneHeight - 2) + 'px;margin-top:' + pointerTop + 'px;' : 'left:' + (data.ganttProperties.width + pointerRight) + 'px;' + marginTop + ';') + '">' + '<div class="' + (canAdd ? connectorPointRight : '') + ' ' + this.parent.getUnscheduledTaskClass(data.ganttProperties) + '" style="width:' + this.connectorPointWidth + 'px;height:' + this.connectorPointWidth + 'px;">' + this.touchRightConnectorpoint + '</div></div>';
    return this.createDivElement(template);
  };
  /**
   * To get task label.
   *
   * @param {string} field .
   * @returns {string} .
   * @private
   */
  ChartRows.prototype.getTaskLabel = function (field) {
    var length = this.parent.ganttColumns.length;
    var resultString = null;
    if (!isNullOrUndefined(field) && field !== '') {
      if (field === this.parent.taskFields.resourceInfo) {
        resultString = this.getResourceName(this.templateData);
      } else {
        for (var i = 0; i < length; i++) {
          if (field === this.parent.ganttColumns[i].field) {
            resultString = this.getFieldValue(this.templateData[field]).toString();
            break;
          }
        }
        if (isNullOrUndefined(resultString)) {
          return 'isCustomTemplate';
        }
      }
    } else {
      resultString = '';
    }
    return resultString;
  };
  ChartRows.prototype.getExpandDisplayProp = function (data) {
    data = this.templateData;
    if (this.parent.getExpandStatus(data)) {
      return 'table-row';
    }
    return 'none';
  };
  ChartRows.prototype.getRowClassName = function (data) {
    data = this.templateData;
    var rowClass = 'gridrowtaskId';
    var parentItem = data.parentItem;
    if (parentItem) {
      rowClass += parentItem.taskId.toString();
    }
    rowClass += 'level';
    rowClass += data.level.toString();
    return rowClass;
  };
  ChartRows.prototype.getBorderRadius = function (data) {
    data = this.templateData;
    var diff = data.ganttProperties.width - data.ganttProperties.progressWidth;
    if (diff <= 4) {
      return 4 - diff;
    } else {
      return 0;
    }
  };
  ChartRows.prototype.getSplitTaskBorderRadius = function (data) {
    var diff = data.width - data.progressWidth;
    if (diff <= 4) {
      return 4 - diff;
    } else {
      return 0;
    }
  };
  ChartRows.prototype.taskNameWidth = function (ganttData) {
    ganttData = this.templateData;
    var ganttProp = ganttData.ganttProperties;
    var width;
    if (ganttData.ganttProperties.isMilestone) {
      width = ganttData.ganttProperties.left - this.parent.getTaskbarHeight() / 2;
    } else if (ganttData.hasChildRecords && !ganttProp.isAutoSchedule) {
      if (!this.parent.allowUnscheduledTasks) {
        width = ganttProp.autoStartDate.getTime() < ganttProp.startDate.getTime() ? ganttProp.autoLeft : ganttProp.left;
      } else {
        width = ganttProp.left < ganttProp.autoLeft ? ganttProp.left : ganttProp.autoLeft;
      }
    } else {
      width = ganttData.ganttProperties.left;
    }
    if (width < 0) {
      width = 0;
    }
    return width + 'px';
  };
  ChartRows.prototype.getRightLabelLeft = function (ganttData) {
    ganttData = this.templateData;
    var ganttProp = ganttData.ganttProperties;
    var left;
    var endLeft;
    var width;
    if (ganttData.ganttProperties.isMilestone) {
      return ganttData.ganttProperties.left + this.parent.getTaskbarHeight() / 2;
    } else if (ganttData.hasChildRecords && !ganttProp.isAutoSchedule) {
      if (!this.parent.allowUnscheduledTasks) {
        left = ganttProp.autoStartDate.getTime() < ganttProp.startDate.getTime() ? ganttProp.autoLeft : ganttProp.left;
        endLeft = ganttProp.autoEndDate.getTime() < ganttProp.endDate.getTime() ? this.parent.dataOperation.getTaskLeft(ganttProp.endDate, ganttProp.isMilestone) : this.parent.dataOperation.getTaskLeft(ganttProp.autoEndDate, ganttProp.isMilestone);
        width = endLeft - left;
      } else {
        left = ganttProp.left < ganttProp.autoLeft ? ganttProp.autoLeft : ganttProp.left;
        width = ganttProp.autoWidth;
      }
      return left + width;
    } else {
      return ganttData.ganttProperties.left + ganttData.ganttProperties.width;
    }
  };
  ChartRows.prototype.getExpandClass = function (data) {
    data = this.templateData;
    if (data.expanded) {
      return rowExpand;
    } else if (!data.expanded && data.hasChildRecords) {
      return rowCollapse;
    }
    return '';
  };
  ChartRows.prototype.getFieldValue = function (field) {
    return isNullOrUndefined(field) ? '' : field;
  };
  ChartRows.prototype.getResourceName = function (ganttData) {
    ganttData = this.templateData;
    var resource = null;
    if (!isNullOrUndefined(ganttData.ganttProperties.resourceInfo)) {
      var length_2 = ganttData.ganttProperties.resourceInfo.length;
      if (length_2 > 0) {
        for (var i = 0; i < length_2; i++) {
          var resourceName = ganttData.ganttProperties.resourceInfo[i][this.parent.resourceFields.name];
          var resourceUnit = ganttData.ganttProperties.resourceInfo[i][this.parent.resourceFields.unit];
          if (resourceUnit !== 100) {
            resourceName += '[' + resourceUnit + '%' + ']';
          }
          if (isNullOrUndefined(resource)) {
            resource = resourceName;
          } else {
            resource += ' , ' + resourceName;
          }
        }
        return resource;
      } else {
        return '';
      }
    }
    return '';
  };
  /**
   * To initialize private variable help to render task bars.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.initChartHelperPrivateVariable = function () {
    var taskbarHeightValue = this.parent.renderBaseline ? 0.45 : !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 0.7 : 0.62;
    var taskBarMarginTopValue = this.parent.renderBaseline ? 4 : 2;
    var milestoneHeightValue = this.parent.renderBaseline ? 1.13 : 0.82;
    this.parent.rowHeight = isNullOrUndefined(this.parent.rowHeight) ? 36 : this.parent.rowHeight;
    this.baselineColor = !isNullOrUndefined(this.parent.baselineColor) && this.parent.baselineColor !== '' ? this.parent.baselineColor : null;
    this.taskBarHeight = isNullOrUndefined(this.parent.taskbarHeight) || this.parent.taskbarHeight >= this.parent.rowHeight ? Math.floor(this.parent.rowHeight * taskbarHeightValue) : this.parent.taskbarHeight; // 0.62 -- Standard Ratio.
    if (this.parent.renderBaseline) {
      var height = void 0;
      if (this.taskBarHeight + this.baselineHeight <= this.parent.rowHeight) {
        height = this.taskBarHeight;
      } else {
        height = this.taskBarHeight - (this.baselineHeight + 1);
      }
      this.taskBarHeight = height;
    }
    this.milestoneHeight = Math.floor(this.taskBarHeight * milestoneHeightValue); // 0.82 -- Standard Ratio.
    this.taskBarMarginTop = Math.floor((this.parent.rowHeight - this.taskBarHeight) / taskBarMarginTopValue);
    this.milestoneMarginTop = Math.floor((this.parent.rowHeight - this.milestoneHeight) / 2);
    this.milesStoneRadius = Math.floor(this.milestoneHeight / 2);
    this.baselineTop = -(Math.floor(this.parent.rowHeight - (this.taskBarHeight + this.taskBarMarginTop)) - 4);
    this.connectorPointWidth = this.parent.isAdaptive ? Math.round(this.taskBarHeight / 2) : 9;
    this.connectorPointMargin = Math.floor(this.taskBarHeight / 2 - this.connectorPointWidth / 1.5);
  };
  /**
   * Function used to refresh Gantt rows.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.refreshGanttRows = function () {
    this.parent.currentViewData = this.parent.treeGrid.getCurrentViewRecords().slice();
    this.createTaskbarTemplate();
    if (this.parent.showOverAllocation) {
      for (var i = 0; i < this.parent.currentViewData.length; i++) {
        var data = this.parent.currentViewData[i];
        if (data.childRecords.length > 0) {
          this.parent.setRecordValue('workTimelineRanges', this.parent.dataOperation.mergeRangeCollections(data.ganttProperties.workTimelineRanges, true), data.ganttProperties, true);
          this.parent.dataOperation.calculateRangeLeftWidth(data.ganttProperties.workTimelineRanges);
        }
      }
      this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
    }
    this.parent.ganttChartModule.updateLastRowBottomWidth();
  };
  /**
   * To render taskbars.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.createTaskbarTemplate = function () {
    var _this = this;
    var _a;
    var trs = [].slice.call(this.ganttChartTableBody.querySelectorAll('tr'));
    this.ganttChartTableBody.innerHTML = '';
    var collapsedResourceRecord = [];
    var prevCurrentView = this.parent.treeGridModule.prevCurrentView;
    this.refreshedTr = [];
    this.refreshedData = [];
    if (this.parent.enableImmutableMode && prevCurrentView && prevCurrentView.length > 0 && this.isUpdated) {
      var oldKeys = {};
      var oldRowElements = [];
      var key = this.parent.treeGrid.getPrimaryKeyFieldNames()[0];
      for (var i = 0; i < prevCurrentView.length; i++) {
        oldRowElements[i] = trs[i];
        oldKeys[prevCurrentView[i][key]] = i;
      }
      for (var index = 0; index < this.parent.currentViewData.length; index++) {
        var oldIndex = oldKeys[this.parent.currentViewData[index][key]];
        var modifiedRecIndex = this.parent.modifiedRecords.indexOf(this.parent.currentViewData[index]);
        if (isNullOrUndefined(oldIndex) || modifiedRecIndex !== -1 || this.parent.isFromRenderBaseline) {
          var tRow = this.getGanttChartRow(index, this.parent.currentViewData[index]);
          this.ganttChartTableBody.appendChild(tRow);
          this.refreshedTr.push(this.ganttChartTableBody.querySelectorAll('tr')[index]);
          this.refreshedData.push(this.parent.currentViewData[index]);
        } else {
          this.ganttChartTableBody.appendChild(oldRowElements[oldIndex]);
        }
        this.ganttChartTableBody.querySelectorAll('tr')[index].setAttribute('data-rowindex', index.toString());
      }
    } else {
      var dupChartBody = createElement('tbody', {
        id: this.parent.element.id + 'GanttTaskTableBody'
      });
      for (var i = 0; i < this.parent.currentViewData.length; i++) {
        var tempTemplateData = this.parent.currentViewData[i];
        if (!tempTemplateData.expanded && this.parent.enableMultiTaskbar) {
          collapsedResourceRecord.push(tempTemplateData);
        }
        var tRow = this.getGanttChartRow(i, tempTemplateData);
        if (tempTemplateData.hasChildRecords && !tempTemplateData.expanded && this.parent.enableMultiTaskbar && !this.parent.allowTaskbarOverlap) {
          this.updateDragDropRecords(tempTemplateData, tRow);
        }
        dupChartBody.appendChild(tRow);
        if (this.parent.enableImmutableMode) {
          this.refreshedTr.push(dupChartBody.querySelectorAll('tr')[i]);
          this.refreshedData.push(this.parent.currentViewData[i]);
        }
        // To maintain selection when virtualization is enabled
        if (this.parent.selectionModule && this.parent.allowSelection) {
          this.parent.selectionModule.maintainSelectedRecords(parseInt(tRow.getAttribute('data-rowindex'), 10));
        }
      }
      /* eslint-disable-next-line */
      (_a = this.ganttChartTableBody).replaceChildren.apply(_a, dupChartBody.childNodes);
      // To trigger the touchend event while perform touch Pinch In/Out action
      this.ganttChartTableBody.childNodes.forEach(function (tr) {
        if (tr instanceof Element) {
          tr.addEventListener('touchmove', _this.handleTouchMove);
          tr.addEventListener('touchend', _this.handleTouchEnd);
        }
      });
      this.parent.initialChartRowElements = this.parent.ganttChartModule.getChartRows();
    }
    if (this.parent.enableCriticalPath && this.parent.criticalPathModule) {
      var criticalModule = this.parent.criticalPathModule;
      if (criticalModule.criticalPathCollection) {
        this.parent.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
      }
    }
    this.parent.renderTemplates();
    this.triggerQueryTaskbarInfo();
    this.parent.modifiedRecords = [];
    if (this.parent.showOverAllocation) {
      this.updateOverlapped();
    }
    if (collapsedResourceRecord.length) {
      for (var j = 0; j < collapsedResourceRecord.length; j++) {
        if (collapsedResourceRecord[j].hasChildRecords) {
          this.parent.isGanttChartRendered = true;
          this.parent.chartRowsModule.refreshRecords([collapsedResourceRecord[j]]);
        }
      }
    }
    this.parent.isGanttChartRendered = true;
    this.parent.renderTemplates();
  };
  /**
   * To render taskbars.
   *
   * @param {number} i .
   * @param {IGanttData} tempTemplateData .
   * @returns {Node} .
   * @private
   */
  ChartRows.prototype.getGanttChartRow = function (i, tempTemplateData) {
    this.templateData = tempTemplateData;
    var parentTrNode = this.getTableTrNode(i);
    var leftLabelNode = this.getLeftLabelNode(i);
    var taskbarContainerNode = this.taskbarContainer();
    taskbarContainerNode[0].setAttribute('aria-label', this.generateAriaLabel(this.templateData));
    taskbarContainerNode[0].setAttribute('rowUniqueId', this.templateData.ganttProperties.rowUniqueID);
    var connectorLineLeftNode;
    var connectorLineRightNode;
    connectorLineLeftNode = this.getLeftPointNode();
    if (this.templateData.ganttProperties.isAutoSchedule && this.parent.viewType === 'ProjectView' || !this.templateData.hasChildRecords) {
      taskbarContainerNode[0].appendChild([].slice.call(connectorLineLeftNode)[0]);
    }
    if (this.templateData.hasChildRecords) {
      var parentTaskbarTemplateNode = void 0;
      if (!this.parent.enableMultiTaskbar || this.parent.enableMultiTaskbar && this.templateData.expanded) {
        parentTaskbarTemplateNode = this.getParentTaskbarNode(i, taskbarContainerNode);
      } else {
        taskbarContainerNode = [];
        for (var j = 0; j < this.templateData.childRecords.length; j++) {
          this.templateData = this.templateData.childRecords[j];
          var taskbarContainerNode1 = this.taskbarContainer();
          taskbarContainerNode1[0].setAttribute('aria-label', this.generateAriaLabel(this.templateData));
          taskbarContainerNode1[0].setAttribute('rowUniqueId', this.templateData.ganttProperties.rowUniqueID);
          if (!this.parent.allowParentDependency) {
            connectorLineLeftNode = this.getLeftPointNode();
            taskbarContainerNode1[0].appendChild([].slice.call(connectorLineLeftNode)[0]);
          } else {
            connectorLineLeftNode = this.getLeftPointNode();
            if (this.templateData.ganttProperties.isAutoSchedule || !this.templateData.hasChildRecords) {
              taskbarContainerNode1[0].appendChild([].slice.call(connectorLineLeftNode)[0]);
            }
          }
          this.appendChildTaskbars(tempTemplateData, i, taskbarContainerNode1, connectorLineRightNode, taskbarContainerNode);
        }
      }
      if (!this.templateData.ganttProperties.isAutoSchedule) {
        var manualTaskbar = this.getManualTaskbar();
        if (!isNullOrUndefined(manualTaskbar[0])) {
          if (this.parent.allowParentDependency) {
            manualTaskbar[0].appendChild([].slice.call(connectorLineLeftNode)[0]);
            var connectorLineRightNode_1 = this.getRightPointNode();
            manualTaskbar[0].appendChild([].slice.call(connectorLineRightNode_1)[0]);
          }
          /* eslint-disable-next-line */
          taskbarContainerNode[0].appendChild([].slice.call(manualTaskbar)[0]);
        }
      }
      if (this.templateData.ganttProperties.autoDuration !== 0 && !this.templateData.ganttProperties.isMilestone && parentTaskbarTemplateNode && parentTaskbarTemplateNode.length > 0) {
        append(parentTaskbarTemplateNode, taskbarContainerNode[0]);
      } else if (this.templateData.ganttProperties.duration === 0 && this.templateData.ganttProperties.isMilestone && this.templateData.ganttProperties.isAutoSchedule) {
        var milestoneTemplateNode = this.getMilestoneNode(i, taskbarContainerNode);
        if (milestoneTemplateNode && milestoneTemplateNode.length > 0) {
          append(milestoneTemplateNode, taskbarContainerNode[0]);
        }
      }
      if (this.parent.renderBaseline && this.templateData.ganttProperties.baselineStartDate && this.templateData.ganttProperties.baselineEndDate) {
        this.taskBaselineTemplateNode = this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.baselineEndDate.getTime() || !isNullOrUndefined(this.templateData.ganttProperties.baselineStartDate) && !isNullOrUndefined(this.templateData.ganttProperties.startDate) && this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.startDate.getTime() && !isNullOrUndefined(this.templateData.ganttProperties.baselineEndDate) && !isNullOrUndefined(this.templateData.ganttProperties.endDate) && this.templateData.ganttProperties.baselineEndDate.getTime() === this.templateData.ganttProperties.endDate.getTime() && this.templateData.ganttProperties.isMilestone ? this.getMilestoneBaselineNode() : this.getTaskBaselineNode();
      }
      if (!this.parent.enableMultiTaskbar || this.parent.enableMultiTaskbar && this.templateData.expanded) {
        if (this.parent.allowParentDependency && (this.templateData.ganttProperties.isAutoSchedule && this.parent.viewType === 'ProjectView' || !this.templateData.hasChildRecords)) {
          connectorLineRightNode = this.getRightPointNode();
          /* eslint-disable-next-line */
          taskbarContainerNode[0].appendChild([].slice.call(connectorLineRightNode)[0]);
        } else if (!this.parent.allowParentDependency) {
          connectorLineRightNode = this.getRightPointNode();
          /* eslint-disable-next-line */
          taskbarContainerNode[0].appendChild([].slice.call(connectorLineRightNode)[0]);
        }
      }
    } else {
      this.appendChildTaskbars(tempTemplateData, i, taskbarContainerNode, connectorLineRightNode);
    }
    var rightLabelNode = this.getRightLabelNode(i);
    if (this.parent.enableMultiTaskbar && this.templateData.hasChildRecords && !this.templateData.expanded) {
      var collapseParent = createElement('div', {
        className: 'e-collapse-parent'
      });
      parentTrNode[0].childNodes[0].childNodes[0].appendChild(collapseParent);
      for (var j = 0; j < taskbarContainerNode.length; j++) {
        addClass([taskbarContainerNode[j]], 'collpse-parent-border');
        parentTrNode[0].childNodes[0].childNodes[0].childNodes[0].appendChild([].slice.call(taskbarContainerNode)[j]);
      }
      parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(leftLabelNode)[0]);
      if (this.templateData.ganttProperties.indicators && this.templateData.ganttProperties.indicators.length > 0) {
        this.appendIndicators(i, parentTrNode);
      }
    } else {
      parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(leftLabelNode)[0]);
      parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(taskbarContainerNode)[0]);
      if (this.templateData.ganttProperties.indicators && this.templateData.ganttProperties.indicators.length > 0) {
        this.appendIndicators(i, parentTrNode);
      }
      if (rightLabelNode && rightLabelNode.length > 0) {
        parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(rightLabelNode)[0]);
      }
    }
    if (!isNullOrUndefined(this.taskBaselineTemplateNode)) {
      parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(this.taskBaselineTemplateNode)[0]);
    }
    this.taskBaselineTemplateNode = null;
    var tRow = parentTrNode[0].childNodes[0];
    this.setAriaRowIndex(tempTemplateData, tRow);
    return tRow;
  };
  /**
   * To set data-rowindex for chart rows
   *
   * @returns {void} .
   * @private
   */
  ChartRows.prototype.setAriaRowIndex = function (tempTemplateData, tRow) {
    var _this = this;
    var dataSource = this.parent.treeGrid.getCurrentViewRecords();
    var visualData = this.parent.virtualScrollModule && this.parent.enableVirtualization ? getValue('virtualScrollModule.visualData', this.parent.treeGrid) : dataSource;
    var index;
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      /* eslint-disable-next-line */
      var gridData = this.parent.treeGrid.grid.contentModule['rows'];
      /* eslint-disable-next-line */
      var data = gridData.filter(function (x) {
        if (x['data'][_this.parent.taskFields.id] === tempTemplateData.ganttProperties.taskId) {
          return x;
        }
      })[0];
      tRow.setAttribute('data-rowindex', data['index'].toString());
    } else {
      index = visualData.indexOf(tempTemplateData);
      tRow.setAttribute('aria-rowindex', (index + 1).toString());
      tRow.setAttribute('data-rowindex', index.toString());
    }
  };
  /**
   * To trigger query taskbar info event.
   *
   * @returns {void}
   * @private
   */
  ChartRows.prototype.triggerQueryTaskbarInfo = function () {
    if (!this.parent.queryTaskbarInfo) {
      return;
    }
    var length = this.parent.enableImmutableMode ? this.refreshedTr.length : this.ganttChartTableBody.querySelectorAll('tr').length;
    var trElement;
    var data;
    for (var index = 0; index < length; index++) {
      trElement = this.parent.enableImmutableMode ? this.refreshedTr[index] : this.ganttChartTableBody.querySelectorAll('tr')[index];
      data = this.refreshedData.length > 0 ? this.refreshedData[index] : this.parent.currentViewData[index];
      var segmentLength = !isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length;
      if (segmentLength > 0) {
        for (var i = 0; i < segmentLength; i++) {
          var segmentedTasks = trElement.getElementsByClassName('e-segmented-taskbar');
          var segmentElement = segmentedTasks[i];
          this.triggerQueryTaskbarInfoByIndex(segmentElement, data);
        }
      } else if (trElement) {
        this.triggerQueryTaskbarInfoByIndex(trElement, data);
      }
    }
  };
  ChartRows.prototype.appendIndicators = function (i, parentTrNode) {
    var taskIndicatorNode;
    var taskIndicatorTextFunction;
    var taskIndicatorTextNode;
    var indicators = this.templateData.ganttProperties.indicators;
    for (var indicatorIndex = 0; indicatorIndex < indicators.length; indicatorIndex++) {
      taskIndicatorNode = this.getIndicatorNode(indicators[indicatorIndex]);
      taskIndicatorNode[0].setAttribute('aria-label', indicators[indicatorIndex].name);
      if (indicators[indicatorIndex].name.indexOf('$') > -1 || indicators[indicatorIndex].name.indexOf('#') > -1) {
        taskIndicatorTextFunction = this.templateCompiler(indicators[indicatorIndex].name);
        taskIndicatorTextNode = taskIndicatorTextFunction(extend({
          index: i
        }, this.templateData), this.parent, 'indicatorLabelText');
      } else {
        var text = createElement('Text');
        text.innerHTML = indicators[indicatorIndex].name;
        if (this.parent.enableHtmlSanitizer && typeof indicators[indicatorIndex].name === 'string') {
          indicators[indicatorIndex].name = SanitizeHtmlHelper.sanitize(indicators[indicatorIndex].name);
        }
        taskIndicatorTextNode = text.childNodes;
      }
      taskIndicatorNode[0].appendChild([].slice.call(taskIndicatorTextNode)[0]);
      taskIndicatorNode[0].title = !isNullOrUndefined(indicators[indicatorIndex].tooltip) ? indicators[indicatorIndex].tooltip : '';
      parentTrNode[0].childNodes[0].childNodes[0].appendChild([].slice.call(taskIndicatorNode)[0]);
    }
  };
  ChartRows.prototype.appendChildTaskbars = function (tempTemplateData, i, taskbarContainerNode, connectorLineRightNode, taskbarCollection) {
    if (this.templateData.ganttProperties.isMilestone) {
      var milestoneTemplateNode = this.getMilestoneNode(i, taskbarContainerNode);
      if (milestoneTemplateNode && milestoneTemplateNode.length > 0) {
        append(milestoneTemplateNode, taskbarContainerNode[0]);
      }
      if (this.parent.renderBaseline && this.templateData.ganttProperties.baselineStartDate && this.templateData.ganttProperties.baselineEndDate) {
        this.taskBaselineTemplateNode = this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.baselineEndDate.getTime() || !isNullOrUndefined(this.templateData.ganttProperties.baselineStartDate) && !isNullOrUndefined(this.templateData.ganttProperties.startDate) && this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.startDate.getTime() && !isNullOrUndefined(this.templateData.ganttProperties.baselineEndDate) && !isNullOrUndefined(this.templateData.ganttProperties.endDate) && this.templateData.ganttProperties.baselineEndDate.getTime() === this.templateData.ganttProperties.endDate.getTime() && this.templateData.ganttProperties.isMilestone ? this.getMilestoneBaselineNode() : this.getTaskBaselineNode();
      }
      if (taskbarCollection) {
        /* eslint-disable-next-line */
        taskbarCollection.push(taskbarContainerNode[0]);
        this.templateData = tempTemplateData;
      }
    } else {
      var scheduledTask = isScheduledTask(this.templateData.ganttProperties); // eslint-disable-line
      var childTaskbarProgressResizeNode = null;
      var childTaskbarRightResizeNode = null;
      var childTaskbarLeftResizeNode = null;
      if (!isNullOrUndefined(scheduledTask)) {
        if (scheduledTask || this.templateData.ganttProperties.duration) {
          if (scheduledTask && (isNullOrUndefined(this.templateData.ganttProperties.segments) || this.templateData.ganttProperties.segments.length <= 0)) {
            childTaskbarProgressResizeNode = this.childTaskbarProgressResizer();
            childTaskbarLeftResizeNode = this.childTaskbarLeftResizer();
            childTaskbarRightResizeNode = this.childTaskbarRightResizer();
          }
        }
        var childTaskbarTemplateNode = this.getChildTaskbarNode(i, taskbarContainerNode);
        if (childTaskbarLeftResizeNode) {
          taskbarContainerNode[0].appendChild([].slice.call(childTaskbarLeftResizeNode)[0]);
        }
        if (childTaskbarTemplateNode && childTaskbarTemplateNode.length > 0) {
          if (this.templateData.ganttProperties.segments && this.templateData.ganttProperties.segments.length > 0) {
            var length_3 = this.templateData.ganttProperties.segments.length;
            var connector = '<div class="e-gantt-split-container-line"></div>';
            var segmentConnector = null;
            segmentConnector = this.createDivElement(connector);
            taskbarContainerNode[0].appendChild([].slice.call(segmentConnector)[0]);
            for (var i_1 = 0; i_1 < length_3; i_1++) {
              append(childTaskbarTemplateNode, taskbarContainerNode[0]);
            }
          } else {
            append(childTaskbarTemplateNode, taskbarContainerNode[0]);
          }
        }
        if (childTaskbarProgressResizeNode) {
          taskbarContainerNode[0].appendChild([].slice.call(childTaskbarProgressResizeNode)[0]);
        }
        if (childTaskbarRightResizeNode) {
          taskbarContainerNode[0].appendChild([].slice.call(childTaskbarRightResizeNode)[0]);
        }
      }
      if (this.parent.renderBaseline && this.templateData.ganttProperties.baselineStartDate && this.templateData.ganttProperties.baselineEndDate) {
        this.taskBaselineTemplateNode = this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.baselineEndDate.getTime() || !isNullOrUndefined(this.templateData.ganttProperties.baselineStartDate) && !isNullOrUndefined(this.templateData.ganttProperties.startDate) && this.templateData.ganttProperties.baselineStartDate.getTime() === this.templateData.ganttProperties.startDate.getTime() && !isNullOrUndefined(this.templateData.ganttProperties.baselineEndDate) && !isNullOrUndefined(this.templateData.ganttProperties.endDate) && this.templateData.ganttProperties.baselineEndDate.getTime() === this.templateData.ganttProperties.endDate.getTime() && this.templateData.ganttProperties.isMilestone ? this.getMilestoneBaselineNode() : this.getTaskBaselineNode();
      }
    }
    if (this.parent.allowParentDependency && (this.templateData.ganttProperties.isAutoSchedule && this.parent.viewType === 'ProjectView' || !this.templateData.hasChildRecords)) {
      connectorLineRightNode = this.getRightPointNode();
      /* eslint-disable-next-line */
      taskbarContainerNode[0].appendChild([].slice.call(connectorLineRightNode)[0]);
    } else if (!this.parent.allowParentDependency) {
      connectorLineRightNode = this.getRightPointNode();
      /* eslint-disable-next-line */
      taskbarContainerNode[0].appendChild([].slice.call(connectorLineRightNode)[0]);
    }
    if (taskbarCollection) {
      /* eslint-disable-next-line */
      taskbarCollection.push(taskbarContainerNode[0]);
      this.templateData = tempTemplateData;
    }
  };
  ChartRows.prototype.customizeTaskbars = function (data, trElement, taskbarElement) {
    var _this = this;
    var rowElement;
    var segmentRowElement;
    if (data.ganttProperties.segments && data.ganttProperties.segments.length > 0 && trElement && trElement.parentElement && trElement.parentElement.parentElement && trElement.parentElement.parentElement.parentElement) {
      segmentRowElement = trElement.parentElement.parentElement.parentElement;
    }
    var triggerTaskbarElement;
    var args = {
      data: data,
      rowElement: trElement,
      taskbarElement: taskbarElement,
      taskbarType: data.hasChildRecords ? 'ParentTask' : data.ganttProperties.isMilestone ? 'Milestone' : 'ChildTask'
    };
    var classCollections = this.getClassName(args);
    if (args.taskbarType === 'Milestone') {
      args.milestoneColor = taskbarElement.querySelector(classCollections[0]) ? getComputedStyle(taskbarElement.querySelector(classCollections[0])).backgroundColor : null;
      args.baselineColor = trElement.querySelector(classCollections[1]) ? getComputedStyle(trElement.querySelector(classCollections[1])).backgroundColor : trElement.querySelector('.' + baselineBar) ? getComputedStyle(trElement.querySelector('.' + baselineBar)).backgroundColor : null;
    } else if (taskbarElement) {
      var childTask = taskbarElement.querySelector(classCollections[0]);
      var progressTask = taskbarElement.querySelector(classCollections[1]);
      args.taskbarBgColor = isNullOrUndefined(childTask) ? null : taskbarElement.classList.contains(traceChildTaskBar) ? getComputedStyle(taskbarElement).backgroundColor : getComputedStyle(taskbarElement.querySelector(classCollections[0])).backgroundColor;
      args.taskbarBorderColor = isNullOrUndefined(childTask) ? null : taskbarElement.classList.contains(traceChildTaskBar) ? getComputedStyle(taskbarElement).backgroundColor : getComputedStyle(taskbarElement.querySelector(classCollections[0])).outlineColor;
      args.progressBarBgColor = isNullOrUndefined(progressTask) ? null : taskbarElement.classList.contains(traceChildProgressBar) ? getComputedStyle(taskbarElement).backgroundColor : getComputedStyle(taskbarElement.querySelector(classCollections[1])).backgroundColor;
      // args.progressBarBorderColor = taskbarElement.querySelector(progressBarClass) ?
      //     getComputedStyle(taskbarElement.querySelector(progressBarClass)).borderColor : null;
      if (segmentRowElement) {
        args.baselineColor = segmentRowElement.querySelector('.' + baselineBar) ? getComputedStyle(segmentRowElement.querySelector('.' + baselineBar)).backgroundColor : null;
      } else {
        args.baselineColor = trElement.querySelector('.' + baselineBar) ? getComputedStyle(trElement.querySelector('.' + baselineBar)).backgroundColor : null;
      }
      args.taskLabelColor = taskbarElement.querySelector('.' + taskLabel) ? getComputedStyle(taskbarElement.querySelector('.' + taskLabel)).color : null;
    }
    if (segmentRowElement) {
      args.rightLabelColor = segmentRowElement.querySelector('.' + rightLabelContainer) && segmentRowElement.querySelector('.' + rightLabelContainer).querySelector('.' + label) ? getComputedStyle(segmentRowElement.querySelector('.' + rightLabelContainer).querySelector('.' + label)).color : null;
      args.leftLabelColor = segmentRowElement.querySelector('.' + leftLabelContainer) && segmentRowElement.querySelector('.' + leftLabelContainer).querySelector('.' + label) ? getComputedStyle(segmentRowElement.querySelector('.' + leftLabelContainer).querySelector('.' + label)).color : null;
    } else {
      args.rightLabelColor = trElement.querySelector('.' + rightLabelContainer) && trElement.querySelector('.' + rightLabelContainer).querySelector('.' + label) ? getComputedStyle(trElement.querySelector('.' + rightLabelContainer).querySelector('.' + label)).color : null;
      args.leftLabelColor = trElement.querySelector('.' + leftLabelContainer) && trElement.querySelector('.' + leftLabelContainer).querySelector('.' + label) ? getComputedStyle(trElement.querySelector('.' + leftLabelContainer).querySelector('.' + label)).color : null;
    }
    this.parent.trigger('queryTaskbarInfo', args, function (taskbarArgs) {
      _this.updateQueryTaskbarInfoArgs(taskbarArgs, rowElement, triggerTaskbarElement);
    });
  };
  /**
   *
   * @param {Element} trElement .
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  ChartRows.prototype.triggerQueryTaskbarInfoByIndex = function (trElement, data) {
    // eslint-disable-next-line
    if (isNullOrUndefined(trElement)) {
      return;
    }
    var taskbarElement;
    var currentData = data;
    if (!(!isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length > 0)) {
      if (this.parent.enableMultiTaskbar) {
        var taskbarElements = trElement.querySelectorAll('.' + taskBarMainContainer);
        for (var i = 0; i < taskbarElements.length; i++) {
          taskbarElement = taskbarElements[i];
          currentData = !data.expanded && data.hasChildRecords ? data.childRecords[i] : currentData;
          var id = this.parent.viewType === 'ResourceView' ? taskbarElement.getAttribute('rowUniqueId') : currentData.ganttProperties.taskId.toString();
          if (currentData.ganttProperties.segments && currentData.ganttProperties.segments.length > 0 && currentData.parentItem && !data.expanded) {
            id = this.parent.viewType === 'ResourceView' ? data.ganttProperties.rowUniqueID : data.ganttProperties.taskId.toString();
          }
          trElement = this.parent.getRowByID(id);
          trElement = trElement ? trElement : taskbarElement.querySelector('.e-gantt-child-taskbar');
          if (isNullOrUndefined(trElement) && currentData.ganttProperties.isMilestone) {
            trElement = taskbarElement;
          }
          if (trElement) {
            var segmentElements = trElement.querySelectorAll('.e-segmented-taskbar');
            if (segmentElements && segmentElements.length > 0) {
              /* eslint-disable-next-line */
              var taskContainer = trElement.querySelector(".e-taskbar-main-container[rowuniqueid=\"" + currentData.ganttProperties.rowUniqueID + "\"]") || trElement;
              var segmentedTasks = taskContainer.querySelectorAll('.e-segmented-taskbar');
              for (var i_2 = 0; i_2 < segmentedTasks.length; i_2++) {
                taskbarElement = segmentedTasks[i_2];
                this.customizeTaskbars(currentData, segmentedTasks[i_2], taskbarElement);
              }
            } else {
              this.customizeTaskbars(currentData, trElement, taskbarElement);
            }
          }
        }
      } else {
        var taskbarElement_1 = trElement.querySelector('.' + taskBarMainContainer);
        if (trElement) {
          this.customizeTaskbars(currentData, trElement, taskbarElement_1);
        }
      }
    } else {
      taskbarElement = trElement;
      if (trElement) {
        this.customizeTaskbars(data, trElement, taskbarElement);
      }
    }
  };
  /**
   * To update query taskbar info args.
   *
   * @param {IQueryTaskbarInfoEventArgs} args .
   * @param {Element} rowElement .
   * @param {Element} taskBarElement .
   * @returns {void}
   * @private
   */
  /* eslint-disable-next-line */
  ChartRows.prototype.updateQueryTaskbarInfoArgs = function (args, rowElement, taskBarElement) {
    var trElement = args.rowElement;
    var taskbarElement = this.parent.enableVirtualization ? args.rowElement : args.taskbarElement;
    var classCollections = this.getClassName(args);
    var segmentRowElement;
    if (args.data.ganttProperties.segments && args.data.ganttProperties.segments.length > 0) {
      segmentRowElement = trElement.parentElement.parentElement.parentElement;
    }
    if (args.taskbarType === 'Milestone') {
      if (taskbarElement.querySelector(classCollections[0]) && getComputedStyle(taskbarElement.querySelector(classCollections[0])).backgroundColor !== args.milestoneColor) {
        taskbarElement.querySelector(classCollections[0]).style.backgroundColor = args.milestoneColor;
      }
      if (trElement.querySelector(classCollections[1]) && getComputedStyle(trElement.querySelector(classCollections[1])).backgroundColor !== args.baselineColor) {
        trElement.querySelector(classCollections[1]).style.backgroundColor = args.baselineColor;
      }
      if (trElement.querySelector('.' + baselineBar) && getComputedStyle(trElement.querySelector('.' + baselineBar)).borderTopColor !== args.baselineColor) {
        trElement.querySelector('.' + baselineBar).style.backgroundColor = args.baselineColor;
      }
    } else if (taskbarElement) {
      if (taskbarElement.querySelector(classCollections[0]) && getComputedStyle(taskbarElement.querySelector(classCollections[0])).backgroundColor !== args.taskbarBgColor) {
        taskbarElement.querySelector(classCollections[0]).style.backgroundColor = args.taskbarBgColor;
      }
      if (taskbarElement.querySelector(classCollections[0]) && getComputedStyle(taskbarElement.querySelector(classCollections[0])).outlineColor !== args.taskbarBorderColor) {
        taskbarElement.querySelector(classCollections[0]).style.outlineColor = args.taskbarBorderColor;
      }
      if (taskbarElement.querySelector(classCollections[1]) && getComputedStyle(taskbarElement.querySelector(classCollections[1])).backgroundColor !== args.progressBarBgColor) {
        taskbarElement.querySelector(classCollections[1]).style.backgroundColor = args.progressBarBgColor;
      }
      if (taskbarElement.classList.contains(traceChildTaskBar) && getComputedStyle(taskbarElement).backgroundColor !== args.taskbarBgColor) {
        taskbarElement.style.backgroundColor = args.taskbarBgColor;
      }
      if (taskbarElement.classList.contains(traceChildTaskBar) && getComputedStyle(taskbarElement).outlineColor !== args.taskbarBorderColor) {
        taskbarElement.style.outlineColor = args.taskbarBorderColor;
      }
      if (taskbarElement.classList.contains(traceChildProgressBar) && getComputedStyle(taskbarElement).backgroundColor !== args.progressBarBgColor) {
        taskbarElement.style.backgroundColor = args.progressBarBgColor;
      }
      // if (taskbarElement.querySelector(progressBarClass) &&
      //     getComputedStyle(taskbarElement.querySelector(progressBarClass)).borderColor !== args.progressBarBorderColor) {
      //     (taskbarElement.querySelector(progressBarClass) as HTMLElement).style.borderColor = args.progressBarBorderColor;
      // }
      if (taskbarElement.querySelector('.' + taskLabel) && getComputedStyle(taskbarElement.querySelector('.' + taskLabel)).color !== args.taskLabelColor) {
        taskbarElement.querySelector('.' + taskLabel).style.color = args.taskLabelColor;
      }
      if (segmentRowElement) {
        if (segmentRowElement.querySelector('.' + baselineBar) && getComputedStyle(segmentRowElement.querySelector('.' + baselineBar)).backgroundColor !== args.baselineColor) {
          segmentRowElement.querySelector('.' + baselineBar).style.backgroundColor = args.baselineColor;
        }
      } else {
        if (trElement.querySelector('.' + baselineBar) && getComputedStyle(trElement.querySelector('.' + baselineBar)).backgroundColor !== args.baselineColor) {
          trElement.querySelector('.' + baselineBar).style.backgroundColor = args.baselineColor;
        }
      }
    }
    if (segmentRowElement) {
      if (segmentRowElement.querySelector('.' + leftLabelContainer) && segmentRowElement.querySelector('.' + leftLabelContainer).querySelector('.' + label) && getComputedStyle(segmentRowElement.querySelector('.' + leftLabelContainer).querySelector('.' + label)).color !== args.leftLabelColor) {
        segmentRowElement.querySelector('.' + leftLabelContainer).querySelector('.' + label).style.color = args.leftLabelColor;
      }
      if (segmentRowElement.querySelector('.' + rightLabelContainer) && segmentRowElement.querySelector('.' + rightLabelContainer).querySelector('.' + label) && getComputedStyle(segmentRowElement.querySelector('.' + rightLabelContainer).querySelector('.' + label)).color !== args.rightLabelColor) {
        segmentRowElement.querySelector('.' + rightLabelContainer).querySelector('.' + label).style.color = args.rightLabelColor;
      }
    } else {
      if (trElement.querySelector('.' + leftLabelContainer) && trElement.querySelector('.' + leftLabelContainer).querySelector('.' + label) && getComputedStyle(trElement.querySelector('.' + leftLabelContainer).querySelector('.' + label)).color !== args.leftLabelColor) {
        trElement.querySelector('.' + leftLabelContainer).querySelector('.' + label).style.color = args.leftLabelColor;
      }
      if (trElement.querySelector('.' + rightLabelContainer) && trElement.querySelector('.' + rightLabelContainer).querySelector('.' + label) && getComputedStyle(trElement.querySelector('.' + rightLabelContainer).querySelector('.' + label)).color !== args.rightLabelColor) {
        trElement.querySelector('.' + rightLabelContainer).querySelector('.' + label).style.color = args.rightLabelColor;
      }
    }
  };
  ChartRows.prototype.getClassName = function (args) {
    var classCollection = [];
    classCollection.push('.' + (args.taskbarType === 'ParentTask' ? traceParentTaskBar : args.taskbarType === 'ChildTask' ? traceChildTaskBar : traceMilestone));
    classCollection.push('.' + (args.taskbarType === 'ParentTask' ? traceParentProgressBar : args.taskbarType === 'ChildTask' ? traceChildProgressBar : baselineMilestoneContainer));
    return classCollection;
  };
  /**
   * To compile template string.
   *
   * @param {string} template .
   * @returns {Function} .
   * @private
   */
  ChartRows.prototype.templateCompiler = function (template) {
    if (!isNullOrUndefined(template) && template !== '') {
      try {
        if (typeof template !== 'function' && document.querySelectorAll(template).length) {
          return compile(document.querySelector(template).innerHTML.trim(), this.parent);
        } else {
          return compile(template, this.parent);
        }
      } catch (e) {
        return compile(template, this.parent);
      }
    }
    return null;
  };
  // to update the eOverlapped property
  ChartRows.prototype.updateOverlapped = function () {
    for (var k = 0; k < this.parent.treeGrid.parentData.length; k++) {
      var childRecords = this.parent.treeGrid.parentData[k].childRecords;
      for (var i = 0; i < childRecords.length; i++) {
        if (childRecords[i + 1]) {
          childRecords[i].ganttProperties.eOverlapped = undefined;
        }
        for (var j = i + 1; j < childRecords.length; j++) {
          childRecords[j].ganttProperties.eOverlapped = undefined;
          if (childRecords[i].ganttProperties.startDate.getTime() < childRecords[j].ganttProperties.endDate.getTime() && childRecords[i].ganttProperties.endDate.getTime() > childRecords[j].ganttProperties.startDate.getTime()) {
            childRecords[j].ganttProperties.eOverlapped = true;
            childRecords[i].ganttProperties.eOverlapped = true;
          } else {
            if (isNullOrUndefined(childRecords[j].ganttProperties.eOverlapped)) {
              childRecords[j].ganttProperties.eOverlapped = false;
            }
            if (isNullOrUndefined(childRecords[i].ganttProperties.eOverlapped)) {
              childRecords[i].ganttProperties.eOverlapped = false;
            }
          }
        }
      }
    }
  };
  // To update the row height when allow overallocation set to false
  ChartRows.prototype.updateDragDropRecords = function (data, tr) {
    var childRecords = data.childRecords;
    var rowIndex = this.parent.currentViewData.indexOf(data);
    var treeGridContentHeight = this.parent.enableRtl ? this.parent['element'].getElementsByClassName('e-content')[2].children[0]['offsetHeight'] : this.parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
    if (!tr) {
      tr = this.ganttChartTableBody.childNodes[rowIndex];
    }
    if (this.parent.ganttChartModule.isExpandAll || this.parent.ganttChartModule.isCollapseAll) {
      tr['style'].height = this.parent.treeGrid.getRowByIndex(rowIndex)['style'].height = this.parent.rowHeight + 'px';
    } else {
      tr['style'].height = this.parent.treeGrid.getRows()[rowIndex]['style'].height = this.parent.rowHeight + 'px';
    }
    this.parent.contentHeight = treeGridContentHeight;
    var rowIDs = [];
    var rowCounts = 0;
    if (data) {
      for (var i = 0; i < childRecords.length; i++) {
        for (var j = i + 1; j < childRecords.length; j++) {
          var taskbarContainer = tr.getElementsByClassName('e-taskbar-main-container');
          for (var k = 0; k < taskbarContainer.length; k++) {
            var rowuniqueid = this.parent.viewType === 'ResourceView' ? childRecords[j]['rowUniqueID'] : childRecords[j].ganttProperties.rowUniqueID;
            if (childRecords[i].ganttProperties.startDate.getTime() < childRecords[j].ganttProperties.endDate.getTime() && childRecords[i].ganttProperties.endDate.getTime() > childRecords[j].ganttProperties.startDate.getTime()) {
              if (taskbarContainer[k].getAttribute('rowuniqueid') === rowuniqueid && rowIDs.indexOf(rowuniqueid) === -1) {
                rowIDs.push(rowuniqueid);
                rowCounts++;
                tr.children[0]['style'].verticalAlign = 'baseline';
                tr.getElementsByClassName('e-taskbar-main-container')[k]['style'].marginTop = rowCounts * this.parent.rowHeight + this.taskBarMarginTop + 'px';
                if (this.parent.ganttChartModule.isExpandAll || this.parent.ganttChartModule.isCollapseAll) {
                  tr['style'].height = this.parent.treeGrid.getRowByIndex(rowIndex)['style'].height = parseInt(tr['style'].height, 10) + this.parent.rowHeight + 'px';
                } else {
                  tr['style'].height = this.parent.treeGrid.getRows()[rowIndex]['style'].height = parseInt(tr['style'].height, 10) + this.parent.rowHeight + 'px';
                }
              }
            } else {
              if (taskbarContainer[k].getAttribute('rowuniqueid') === rowuniqueid && rowIDs.indexOf(rowuniqueid) === -1 && this.parent.rowDragAndDropModule && this.parent.rowDragAndDropModule['draggedRecord'] && taskbarContainer[k].getAttribute('rowuniqueid') === this.parent.rowDragAndDropModule['draggedRecord']['rowUniqueID'] && this.parent.rowDragAndDropModule['draggedRecord']['rowUniqueID'] === childRecords[j]['rowUniqueID']) {
                tr.getElementsByClassName('e-taskbar-main-container')[k]['style'].marginTop = this.parent.editModule.taskbarEditModule.draggedRecordMarginTop;
              }
            }
          }
        }
      }
      this.parent.contentHeight = treeGridContentHeight;
      document.getElementsByClassName('e-chart-rows-container')[0]['style'].height = this.parent.contentHeight + 'px';
    }
  };
  /**
   * To refresh edited TR
   *
   * @param {number} index .
   * @param {boolean} isValidateRange .
   * @param {boolean} isUndoRedo .
   * @returns {void} .
   * @private
   */
  ChartRows.prototype.refreshRow = function (index, isValidateRange, isUndoRedo) {
    var _this = this;
    var tr = this.ganttChartTableBody.childNodes[index];
    var selectedItem;
    if (isUndoRedo) {
      selectedItem = this.parent.previousFlatData[index];
    } else {
      selectedItem = this.parent.currentViewData[index];
    }
    if (index !== -1 && selectedItem) {
      var data = selectedItem;
      if (!this.parent.allowTaskbarOverlap && data.expanded) {
        if (this.parent.ganttChartModule.isExpandAll || this.parent.ganttChartModule.isCollapseAll) {
          tr['style'].height = this.parent.treeGrid.getRowByIndex(index)['style'].height = this.parent.rowHeight + 'px';
        } else {
          tr['style'].height = this.parent.treeGrid.getRows()[index]['style'].height = this.parent.rowHeight + 'px';
        }
      }
      if (data.hasChildRecords && !data.expanded && this.parent.enableMultiTaskbar) {
        tr.replaceChild(this.getGanttChartRow(index, data).childNodes[0], tr.childNodes[0]);
        if (this.parent.renderBaseline) {
          data.childRecords.forEach(function (childRecord) {
            if (!isNullOrUndefined(childRecord.ganttProperties.baselineStartDate && childRecord.ganttProperties.baselineEndDate)) {
              tr.childNodes[0].appendChild(_this.updateTaskBaselineNode(childRecord)[0]);
            }
          });
        }
      } else {
        if (this.parent.allowTaskbarDragAndDrop && !data.expanded) {
          tr.replaceWith(this.getGanttChartRow(index, data));
        } else {
          tr.replaceChild(this.getGanttChartRow(index, data).childNodes[0], tr.childNodes[0]);
        }
      }
      this.parent.renderTemplates();
      if (data.hasChildRecords && this.parent.showOverAllocation && this.parent.allowTaskbarOverlap) {
        if (isValidateRange) {
          this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
        } else {
          this.parent.dataOperation.updateOverlappingValues(data);
          this.parent.ganttChartModule.renderRangeContainer([data]);
        }
      }
      var segmentLength = !isNullOrUndefined(data.ganttProperties.segments) && data.ganttProperties.segments.length;
      if (segmentLength > 0) {
        for (var i = 0; i < segmentLength; i++) {
          var segmentedTasks = tr.getElementsByClassName('e-segmented-taskbar');
          var segmentElement = segmentedTasks[i];
          this.triggerQueryTaskbarInfoByIndex(segmentElement, data);
        }
      } else {
        this.triggerQueryTaskbarInfoByIndex(tr, data);
      }
      var dataId = this.parent.viewType === 'ProjectView' ? data.ganttProperties.taskId : data.ganttProperties.rowUniqueID;
      if (!this.parent.ganttChartModule.isExpandAll && !this.parent.ganttChartModule.isCollapseAll) {
        this.parent.treeGrid.grid.setRowData(dataId, data);
      }
      if (data.hasChildRecords && !data.expanded && this.parent.enableMultiTaskbar && !this.parent.allowTaskbarOverlap) {
        this.updateDragDropRecords(selectedItem, tr);
      }
      if (data.hasChildRecords && this.parent.showOverAllocation && !this.parent.allowTaskbarOverlap) {
        this.parent.dataOperation.updateOverlappingValues(data);
        this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
      }
      var nextEditableElement = this.parent.ganttChartModule.tempNextElement;
      if (this.parent.ganttChartModule.isEditableElement && nextEditableElement) {
        this.parent.treeGrid.grid.focusModule.focus();
        addClass([this.parent.treeGrid.getRows()[tr.getAttribute('data-rowindex')].children[this.parent.ganttChartModule.childrenIndex]], 'e-focused');
        this.parent.ganttChartModule.tempNextElement = null;
      }
      var row = this.parent.treeGrid.grid.getRowObjectFromUID(this.parent.treeGrid.grid.getDataRows()[index].getAttribute('data-uid'));
      if (!isNullOrUndefined(row)) {
        row.data = data;
      }
    }
  };
  ChartRows.prototype.updateResourceTaskbarElement = function (tRow, parentTr) {
    var cloneElement = tRow.querySelector('.e-taskbar-main-container');
    if (this.parent.viewType === 'ProjectView' && tRow.querySelector('.e-collapse-parent')) {
      cloneElement = tRow.querySelector('.e-collapse-parent');
    }
    if (tRow.querySelector('.e-collapse-parent') === null) {
      addClass([cloneElement], 'collpse-parent-border');
    }
    var id = tRow.querySelector('.' + taskBarMainContainer).getAttribute('rowUniqueId');
    var ganttData = this.parent.getRecordByID(id);
    if (!isNullOrUndefined(ganttData) && ganttData.ganttProperties.segments && ganttData.ganttProperties.segments.length > 0) {
      var segmentedTasks = cloneElement.getElementsByClassName('e-segmented-taskbar');
      for (var i = 0; i < segmentedTasks.length; i++) {
        this.triggerQueryTaskbarInfoByIndex(segmentedTasks[i], ganttData);
      }
    } else if (this.parent.queryTaskbarInfo) {
      var mainTaskbar = cloneElement.querySelector('.e-gantt-child-taskbar');
      if (!isNullOrUndefined(mainTaskbar)) {
        this.triggerQueryTaskbarInfoByIndex(mainTaskbar, ganttData);
      }
    }
    var zIndex = '';
    if (ganttData && !isNullOrUndefined(ganttData.ganttProperties.eOverlapIndex)) {
      zIndex = ganttData.ganttProperties.eOverlapIndex.toString();
    }
    var cloneChildElement = cloneElement.cloneNode(true);
    cloneChildElement.style.zIndex = zIndex;
    parentTr[0].childNodes[0].childNodes[0].childNodes[0].appendChild(cloneChildElement);
  };
  /**
   * To refresh all edited records
   *
   * @param {IGanttData} items .
   * @param {boolean} isValidateRange .
   * @param {boolean} isUndoRedo .
   * @returns {void} .
   * @private
   */
  ChartRows.prototype.refreshRecords = function (items, isValidateRange, isUndoRedo) {
    if (this.parent.isGanttChartRendered) {
      this.parent.renderTemplates();
      if (this.parent.enableMultiTaskbar) {
        var sortedRecords = [];
        sortedRecords = new DataManager(items).executeLocal(new Query().sortBy('expanded', 'Descending'));
        items = sortedRecords;
      }
      for (var i = 0; i < items.length; i++) {
        var index = void 0;
        if (isUndoRedo) {
          index = this.parent.ids.indexOf(items[i].ganttProperties.taskId.toString());
        } else {
          index = this.parent.currentViewData.indexOf(items[i]);
        }
        if (!this.parent.enableMultiTaskbar || this.parent.enableMultiTaskbar && (items[i].expanded || !this.parent.isLoad)) {
          this.refreshRow(index, isValidateRange, isUndoRedo);
        }
      }
      this.parent.ganttChartModule.updateLastRowBottomWidth();
    }
  };
  ChartRows.prototype.removeEventListener = function () {
    var _this = this;
    if (this.parent.isDestroyed) {
      return;
    }
    this.ganttChartTableBody.childNodes.forEach(function (tr) {
      if (tr instanceof Element) {
        tr.removeEventListener('touchmove', _this.handleTouchMove);
        tr.removeEventListener('touchend', _this.handleTouchEnd);
      }
    });
    this.parent.off('renderPanels', this.createChartTable);
    this.parent.off('dataReady', this.initiateTemplates);
    this.parent.off('destroy', this.destroy);
  };
  ChartRows.prototype.destroy = function () {
    this.removeEventListener();
  };
  ChartRows.prototype.generateAriaLabel = function (data) {
    data = this.templateData;
    var defaultValue = '';
    var nameConstant = this.parent.localeObj.getConstant('name');
    var startDateConstant = this.parent.localeObj.getConstant('startDate');
    var endDateConstant = this.parent.localeObj.getConstant('endDate');
    var durationConstant = this.parent.localeObj.getConstant('duration');
    var taskNameVal = data.ganttProperties.taskName;
    var startDateVal = data.ganttProperties.startDate;
    var endDateVal = data.ganttProperties.endDate;
    var durationVal = data.ganttProperties.duration;
    if (data.ganttProperties.isMilestone) {
      defaultValue = nameConstant + ' ' + taskNameVal + ' ' + startDateConstant + ' ' + this.parent.getFormatedDate(startDateVal);
    } else {
      if (taskNameVal) {
        defaultValue += nameConstant + ' ' + taskNameVal + ' ';
      }
      if (startDateVal) {
        defaultValue += startDateConstant + ' ' + this.parent.getFormatedDate(startDateVal) + ' ';
      }
      if (endDateVal) {
        defaultValue += endDateConstant + ' ' + this.parent.getFormatedDate(endDateVal) + ' ';
      }
      if (durationVal) {
        defaultValue += durationConstant + ' ' + this.parent.getDurationString(durationVal, data.ganttProperties.durationUnit);
      }
    }
    return defaultValue;
  };
  ChartRows.prototype.generateBaselineAriaLabel = function (data) {
    data = this.templateData;
    var defaultValue = '';
    var nameConstant = this.parent.localeObj.getConstant('name');
    var startDateConstant = this.parent.localeObj.getConstant('startDate');
    var endDateConstant = this.parent.localeObj.getConstant('endDate');
    var taskNameVal = data.ganttProperties.taskName;
    var startDateVal = data.ganttProperties.baselineStartDate;
    var endDateVal = data.ganttProperties.baselineEndDate;
    defaultValue += 'Baseline' + ' ';
    defaultValue += nameConstant + ' ' + taskNameVal + ' ';
    defaultValue += startDateConstant + ' ' + this.parent.getFormatedDate(startDateVal) + ' ';
    defaultValue += endDateConstant + ' ' + this.parent.getFormatedDate(endDateVal) + ' ';
    return defaultValue;
  };
  ChartRows.prototype.generateSpiltTaskAriaLabel = function (data, ganttProp) {
    var defaultValue = '';
    var startDateConstant = this.parent.localeObj.getConstant('startDate');
    var endDateConstant = this.parent.localeObj.getConstant('endDate');
    var durationConstant = this.parent.localeObj.getConstant('duration');
    var startDateVal = data.startDate;
    var endDateVal = data.endDate;
    var durationVal = data.duration;
    if (startDateVal) {
      defaultValue += startDateConstant + ' ' + this.parent.getFormatedDate(startDateVal) + ' ';
    }
    if (endDateVal) {
      defaultValue += endDateConstant + ' ' + this.parent.getFormatedDate(endDateVal) + ' ';
    }
    if (durationVal) {
      defaultValue += durationConstant + ' ' + this.parent.getDurationString(durationVal, ganttProp.durationUnit);
    }
    return defaultValue;
  };
  ChartRows.prototype.generateTaskLabelAriaLabel = function (type) {
    var label = '';
    if (type === 'left' && this.parent.labelSettings.leftLabel && !this.leftTaskLabelTemplateFunction) {
      label += this.parent.localeObj.getConstant('leftTaskLabel') + ' ' + this.getTaskLabel(this.parent.labelSettings.leftLabel);
    } else if (type === 'right' && this.parent.labelSettings.rightLabel && !this.rightTaskLabelTemplateFunction) {
      label += this.parent.localeObj.getConstant('rightTaskLabel') + ' ' + this.getTaskLabel(this.parent.labelSettings.rightLabel);
    }
    return label;
  };
  return ChartRows;
}(DateProcessor);
var Dependency = /** @__PURE__ @class */function () {
  function Dependency(gantt) {
    this.parentRecord = [];
    this.parentIds = [];
    this.parentPredecessors = [];
    this.validatedParentIds = [];
    this.storeId = null;
    this.isChildRecordValidated = [];
    this.parent = gantt;
    this.dateValidateModule = this.parent.dateValidationModule;
  }
  /**
   * Method to populate predecessor collections in records
   *
   * @returns {void} .
   * @private
   */
  Dependency.prototype.ensurePredecessorCollection = function () {
    var predecessorTasks = this.parent.predecessorsCollection;
    var length = predecessorTasks.length - 1;
    for (var count = length; count >= 0; count--) {
      var ganttData = predecessorTasks[count];
      var ganttProp = ganttData.ganttProperties;
      if (!ganttData.hasChildRecords && !this.parent.allowParentDependency || this.parent.allowParentDependency) {
        this.ensurePredecessorCollectionHelper(ganttData, ganttProp);
      }
    }
  };
  /**
   *
   * @param {IGanttData} ganttData .
   * @param {ITaskData} ganttProp .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.ensurePredecessorCollectionHelper = function (ganttData, ganttProp) {
    var predecessorVal = ganttProp.predecessorsName;
    if (predecessorVal && (typeof predecessorVal === 'string' || typeof predecessorVal === 'number')) {
      this.parent.setRecordValue('predecessor', this.calculatePredecessor(predecessorVal, ganttData), ganttProp, true);
    } else if (predecessorVal && typeof predecessorVal === 'object' && predecessorVal.length) {
      var preValues = [];
      for (var c = 0; c < predecessorVal.length; c++) {
        var predecessorItem = predecessorVal[c];
        var preValue = {};
        preValue.from = getValue('from', predecessorItem) ? getValue('from', predecessorItem) : predecessorVal[c];
        preValue.to = getValue('to', predecessorItem) ? getValue('to', predecessorItem) : ganttProp.rowUniqueID;
        preValue.type = getValue('type', predecessorItem) ? getValue('type', predecessorItem) : 'FS';
        var offsetUnits = getValue('offset', predecessorItem);
        if (isNullOrUndefined(offsetUnits)) {
          preValue.offset = 0;
          preValue.offsetUnit = this.parent.durationUnit.toLocaleLowerCase();
        } else if (typeof offsetUnits === 'string') {
          var tempOffsetUnits = this.getOffsetDurationUnit(getValue('offset', predecessorItem));
          preValue.offset = tempOffsetUnits.duration;
          preValue.offsetUnit = tempOffsetUnits.durationUnit;
        } else {
          preValue.offset = parseFloat(offsetUnits.toString());
          preValue.offsetUnit = this.parent.durationUnit.toLocaleLowerCase();
        }
        var isOwnParent = this.checkIsParent(preValue.from.toString());
        if (!isOwnParent) {
          preValues.push(preValue);
        }
      }
      this.parent.setRecordValue('predecessor', preValues, ganttProp, true);
    }
    this.parent.setRecordValue('predecessorsName', this.getPredecessorStringValue(ganttData), ganttProp, true);
    this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, ganttProp.predecessorsName, ganttData);
    this.parent.setRecordValue(this.parent.taskFields.dependency, ganttProp.predecessorsName, ganttData);
  };
  /**
   * To render unscheduled empty task with 1 day duration during predecessor map
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.updateUnscheduledDependency = function (data) {
    var task = this.parent.taskFields;
    var prdList = !isNullOrUndefined(data[task.dependency]) ? data[task.dependency].toString().split(',') : [];
    for (var i = 0; i < prdList.length; i++) {
      var predId = parseInt(prdList[i], 10);
      if (!isNaN(predId)) {
        var predData = this.parent.getRecordByID(predId.toString());
        var record = !isNullOrUndefined(predData) ? extend({}, {}, predData.taskData, true) : null;
        if (!isNullOrUndefined(record) && isNullOrUndefined(record[task.startDate]) && isNullOrUndefined(record[task.duration]) && isNullOrUndefined(record[task.endDate])) {
          record[task.duration] = 1;
          var startDate = void 0;
          var parentItem = predData.parentItem;
          if (parentItem) {
            var parentTask = this.parent.getParentTask(predData.parentItem);
            while (parentTask && !parentTask.ganttProperties.startDate) {
              parentTask = this.parent.getParentTask(parentTask.parentItem);
            }
            startDate = parentTask ? parentTask.ganttProperties.startDate : this.parent.cloneProjectStartDate;
          } else {
            startDate = this.parent.cloneProjectStartDate;
          }
          record[task.startDate] = startDate;
          this.parent.updateRecordByID(record);
        }
      }
    }
  };
  /**
   *
   * @param {string} fromId .
   * @returns {boolean} .
   */
  Dependency.prototype.checkIsParent = function (fromId) {
    var boolValue = false;
    var task = this.parent.connectorLineModule.getRecordByID(fromId);
    if (task.hasChildRecords) {
      boolValue = true;
    }
    return boolValue;
  };
  /**
   * Get predecessor collection object from predecessor string value
   *
   * @param {string | number} predecessorValue .
   * @param {IGanttData} ganttRecord .
   * @returns {IPredecessor[]} .
   * @private
   */
  Dependency.prototype.calculatePredecessor = function (predecessorValue, ganttRecord) {
    var _this = this;
    var predecessor = predecessorValue.toString();
    var collection = [];
    var match;
    var values = [];
    var offsetValue;
    var predecessorText;
    var parentRecords = [];
    predecessor.split(',').forEach(function (el) {
      var isGUId = false;
      var firstPart;
      var predecessorName;
      var isAlpha = false;
      var regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      var elSplit = el.split('-');
      var id;
      if (elSplit.length === 6) {
        elSplit[4] = elSplit[4] + '-' + elSplit[5];
        elSplit.pop();
      }
      if (elSplit.length === 5 && elSplit[4].length >= 12) {
        id = el.substring(0, 36);
        if (regex.test(id)) {
          isGUId = true;
        }
      }
      if (el.includes('-')) {
        var lastIndex = el.lastIndexOf('-');
        var lastPart = el.substring(lastIndex + 1);
        var baseString = el.replace(lastPart, '').trim();
        var match_1 = baseString.match(/(FS|SS|SF|FF)-$/);
        var processedResult = (match_1 ? match_1[0] : '') + lastPart;
        if (!/^(FS|SS|SF|FF)/.test(processedResult)) {
          var prefixMatch = processedResult.match(/(FS|SS|SF|FF)/);
          processedResult = prefixMatch ? prefixMatch[0] + processedResult.slice(processedResult.indexOf(prefixMatch[0]) + prefixMatch[0].length) : el;
        }
        predecessorName = processedResult;
        if (el.includes('-') && /[A-Za-z]/.test(predecessorName)) {
          var indexFS = el.indexOf(predecessorName);
          if (indexFS !== -1) {
            firstPart = el.substring(0, indexFS);
            if (firstPart.includes('-')) {
              isAlpha = true;
            }
          }
        }
      }
      if (isGUId) {
        var split = void 0;
        split = elSplit[4].split('+');
        var spliceLength = void 0;
        if (split.length === 1) {
          values[0] = el;
        } else {
          spliceLength = split[1].length;
          values[0] = el.slice(0, -(spliceLength + 1));
          values[1] = split[1];
        }
        offsetValue = '+';
        if (elSplit[4].indexOf('-') >= 0) {
          split = elSplit[4].split('-');
          if (split.length === 1) {
            values[0] = el;
          } else {
            spliceLength = split[1].length;
            values[0] = el.slice(0, -(spliceLength + 1));
            values[1] = split[1];
          }
          offsetValue = '-';
        }
      } else {
        if (isAlpha && firstPart.includes('-')) {
          values[0] = firstPart;
        } else {
          values = el.split('+');
          offsetValue = '+';
          if (el.indexOf('-') >= 0) {
            values = el.split('-');
            offsetValue = '-';
          }
        }
      }
      match = [];
      var ids = _this.parent.viewType === 'ResourceView' ? _this.parent.getTaskIds() : _this.parent.ids;
      var isExist1 = _this.parent.viewType === 'ResourceView' ? ids.indexOf('T' + values[0]) : ids.indexOf(values[0]);
      if (isExist1 !== -1) {
        match[0] = values[0];
      } else {
        if (ids.indexOf(values[0]) === -1) {
          match = values[0].split(' ');
          if (match.length === 1) {
            if (match[0].indexOf(' ') !== -1) {
              match = values[0].match(/(\d+|[A-z]+)/g);
            } else {
              match[0] = values[0].slice(0, -2);
              match[1] = values[0].slice(-2);
            }
          }
        } else {
          match[0] = values[0];
        }
      }
      var isExist = _this.parent.viewType === 'ResourceView' ? ids.indexOf('T' + match[0]) : ids.indexOf(match[0]);
      /*Validate for appropriate predecessor*/
      if (match[0] && isExist !== -1) {
        if (match.length > 1) {
          var type = match[1].toUpperCase();
          if (type === 'FS' || type === 'FF' || type === 'SF' || type === 'SS') {
            predecessorText = type;
          } else {
            var e = "The provided dependency type, " + type + ", is invalid. Please ensure that the Dependency Type is FS or FF or SS or SF";
            _this.parent.trigger('actionFailure', {
              error: e
            });
            predecessorText = 'FS';
          }
        } else if (el.includes('-') && /[A-Za-z]/.test(predecessorName) && firstPart.includes('-')) {
          var type = el.slice(-2).toString();
          type.toUpperCase();
          if (type === 'FS' || type === 'FF' || type === 'SF' || type === 'SS') {
            predecessorText = type;
          } else {
            predecessorText = 'FS';
          }
        } else {
          predecessorText = 'FS';
        }
      } else {
        return; // exit current loop for invalid id (match[0])
      }
      var tempOffset = values.length > 1 ? offsetValue + '' + values[1] : '0';
      var offsetUnits = _this.getOffsetDurationUnit(tempOffset);
      var obj = {
        from: match[0],
        type: predecessorText,
        isDrawn: false,
        to: _this.parent.viewType === 'ResourceView' ? ganttRecord.ganttProperties.taskId.toString() : ganttRecord.ganttProperties.rowUniqueID.toString(),
        offsetUnit: offsetUnits.durationUnit,
        offset: offsetUnits.duration
      };
      var isOwnParent = _this.checkIsParent(match[0]);
      if (!_this.parent.allowParentDependency) {
        if (!isOwnParent) {
          collection.push(obj);
        }
      } else {
        var fromData = _this.parent.connectorLineModule.getRecordByID(obj.to);
        var toData = _this.parent.connectorLineModule.getRecordByID(obj.from);
        var isValid = void 0;
        if (_this.parent.connectorLineEditModule && toData && fromData) {
          isValid = _this.parent.connectorLineEditModule.validateParentPredecessor(toData, fromData);
          if (isValid) {
            collection.push(obj);
            if (parentRecords.indexOf(toData) === -1 && toData.hasChildRecords && _this.parent.editModule.cellEditModule && _this.parent.editModule.cellEditModule.isCellEdit) {
              parentRecords.push(extend([], [], [toData], true)[0]);
            }
          }
        } else {
          collection.push(obj);
        }
        match.splice(0);
      }
    });
    if (parentRecords.length > 0 && this.parent.undoRedoModule && this.parent.editModule && this.parent.editModule.cellEditModule && this.parent.editModule.cellEditModule.isCellEdit) {
      this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1]['connectedRecords'] = parentRecords;
    }
    var creatCollection = [];
    collection.map(function (data) {
      var from = data.from;
      var to = data.to;
      var checkColloction = [];
      checkColloction = collection.filter(function (fdata) {
        return fdata.from === from && fdata.to === to;
      });
      if (creatCollection.indexOf(checkColloction[checkColloction.length - 1]) === -1) {
        creatCollection.push(checkColloction[checkColloction.length - 1]);
      }
    });
    return creatCollection;
  };
  /**
   * Get predecessor value as string with offset values
   *
   * @param {IGanttData} data .
   * @returns {string} .
   * @private
   */
  Dependency.prototype.getPredecessorStringValue = function (data) {
    var predecessors = data.ganttProperties.predecessor;
    var resultString = '';
    var temp1;
    var match = [];
    if (predecessors) {
      var length_1 = predecessors.length;
      for (var i = 0; i < length_1; i++) {
        var currentValue = predecessors[i];
        var temp = '';
        var id = this.parent.viewType === 'ResourceView' ? data.ganttProperties.taskId : data.ganttProperties.rowUniqueID;
        if (currentValue.from !== id.toString()) {
          temp = currentValue.from + currentValue.type;
          if (typeof data.ganttProperties.taskId === 'string') {
            match[0] = temp.slice(0, -2);
            match[1] = temp.slice(-2);
            temp1 = match[0] + ' ' + match[1];
          } else {
            temp1 = temp;
          }
          temp = temp1;
          if (currentValue.offset !== 0) {
            temp += currentValue.offset > 0 ? '+' + currentValue.offset + ' ' : currentValue.offset + ' ';
            var multiple = currentValue.offset !== 1;
            if (currentValue.offsetUnit === 'day') {
              temp += multiple ? this.parent.localeObj.getConstant('days') : this.parent.localeObj.getConstant('day');
            } else if (currentValue.offsetUnit === 'hour') {
              temp += multiple ? this.parent.localeObj.getConstant('hours') : this.parent.localeObj.getConstant('hour');
            } else {
              temp += multiple ? this.parent.localeObj.getConstant('minutes') : this.parent.localeObj.getConstant('minute');
            }
          }
          if (resultString.length > 0) {
            resultString = resultString + ',' + temp;
          } else {
            resultString = temp;
          }
        }
      }
    }
    return resultString;
  };
  /*Get duration and duration unit value from tasks*/
  Dependency.prototype.getOffsetDurationUnit = function (val) {
    var duration = 0;
    var durationUnit = this.parent.durationUnit.toLocaleLowerCase();
    var durationUnitLabels = this.parent.durationUnitEditText;
    if (typeof val === 'string') {
      var values = val.match(/[^0-9]+|[0-9]+/g);
      for (var x = 0; x < values.length; x++) {
        values[x] = values[x].trim();
      }
      if (values[0] === '-' && values[1]) {
        values[1] = values[0] + values[1];
        values.shift();
      } else if (values[0] === '+') {
        values.shift();
      }
      if (values[1] === '.' && !isNaN(parseInt(values[2], 10))) {
        values[0] += values[1] + values[2];
        values.splice(1, 2);
      }
      if (values && values.length <= 2) {
        duration = parseFloat(values[0]);
        durationUnit = values[1] ? values[1].toLowerCase().trim() : '';
        if (getValue('minute', durationUnitLabels).indexOf(durationUnit) !== -1) {
          durationUnit = 'minute';
        } else if (getValue('hour', durationUnitLabels).indexOf(durationUnit) !== -1) {
          durationUnit = 'hour';
        } else if (getValue('day', durationUnitLabels).indexOf(durationUnit) !== -1) {
          durationUnit = 'day';
        } else {
          durationUnit = this.parent.durationUnit.toLocaleLowerCase();
        }
      }
    } else {
      duration = val;
      durationUnit = this.parent.durationUnit.toLocaleLowerCase();
    }
    if (isNaN(duration)) {
      var err = 'The provided value for the offset field is invalid.Please ensure the offset field contains only valid numeric values';
      this.parent.trigger('actionFailure', {
        error: err
      });
      duration = 0;
      durationUnit = this.parent.durationUnit.toLocaleLowerCase();
    }
    return {
      duration: duration,
      durationUnit: durationUnit
    };
  };
  /**
   * Update predecessor object in both from and to tasks collection
   *
   * @returns {void} .
   * @private
   */
  Dependency.prototype.updatePredecessors = function () {
    var predecessorsCollection = this.parent.predecessorsCollection;
    var ganttRecord;
    var length = predecessorsCollection.length;
    for (var count = 0; count < length; count++) {
      ganttRecord = predecessorsCollection[count];
      if (!ganttRecord.hasChildRecords && !this.parent.allowParentDependency || this.parent.allowParentDependency) {
        this.updatePredecessorHelper(ganttRecord, predecessorsCollection);
        if (!ganttRecord.ganttProperties.isAutoSchedule && this.parent.editSettings.allowEditing) {
          this.parent.connectorLineEditModule['validatedOffsetIds'] = [];
          this.parent.connectorLineEditModule['calculateOffset'](ganttRecord);
        }
      }
    }
  };
  /**
   * To update predecessor collection to successor tasks
   *
   * @param {IGanttData} ganttRecord .
   * @param {IGanttData[]} predecessorsCollection .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.updatePredecessorHelper = function (ganttRecord, predecessorsCollection) {
    var connectorsCollection = ganttRecord.ganttProperties.predecessor;
    var successorGanttRecord;
    var connectorCount = connectorsCollection.length;
    predecessorsCollection = isNullOrUndefined(predecessorsCollection) ? [] : predecessorsCollection;
    for (var i = 0; i < connectorCount; i++) {
      var connector = connectorsCollection[i];
      successorGanttRecord = this.parent.connectorLineModule.getRecordByID(connector.from);
      var id = this.parent.viewType === 'ResourceView' ? ganttRecord.ganttProperties.taskId : ganttRecord.ganttProperties.rowUniqueID;
      if (connector.from !== id.toString()) {
        if (successorGanttRecord) {
          var predecessorCollection = void 0;
          if (successorGanttRecord.ganttProperties.predecessor) {
            predecessorCollection = extend([], successorGanttRecord.ganttProperties.predecessor, [], true);
            predecessorCollection.push(connector);
            this.parent.setRecordValue('predecessor', predecessorCollection, successorGanttRecord.ganttProperties, true);
            //  successorGanttRecord.ganttProperties.predecessor.push(connector);
          } else {
            predecessorCollection = [];
            predecessorCollection.push(connector);
            this.parent.setRecordValue('predecessor', predecessorCollection, successorGanttRecord.ganttProperties, true);
            // this.parent.setRecordValue('predecessor', [], successorGanttRecord.ganttProperties, true);
            // successorGanttRecord.ganttProperties.predecessor.push(connector);
            predecessorsCollection.push(successorGanttRecord);
          }
        }
      }
    }
  };
  Dependency.prototype.traverseParents = function (record) {
    this.parent.dataOperation.updateParentItems(record);
  };
  /**
   * Method to validate date of tasks with predecessor values for all records
   *
   * @returns {void} .
   * @private
   */
  Dependency.prototype.updatedRecordsDateByPredecessor = function () {
    if (!this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      return;
    }
    var flatData = this.parent.flatData;
    var totLength = this.parent.flatData.length;
    for (var count = 0; count < totLength; count++) {
      if (flatData[count].ganttProperties.predecessorsName) {
        this.validatePredecessorDates(flatData[count]);
        var predecessorCollection = flatData[count].ganttProperties.predecessor;
        if (predecessorCollection && predecessorCollection.length > 1) {
          for (var i = 0; i < predecessorCollection.length; i++) {
            var validateRecord = this.parent.getRecordByID(predecessorCollection[i].to);
            if (validateRecord) {
              this.validatePredecessorDates(validateRecord);
            }
          }
        }
        if (flatData[count].hasChildRecords && this.parent.editModule && flatData[count].ganttProperties.startDate && this.parent.allowParentDependency) {
          this.updateChildItems(flatData[count]);
        }
        if (flatData[count].parentItem) {
          var recordId = flatData[count].parentItem.taskId;
          this.traverseParents(this.parent.getRecordByID(recordId));
        }
      }
    }
  };
  Dependency.prototype.updateParentPredecessor = function () {
    if (this.parent.enablePredecessorValidation) {
      var parentPredecessorLength = this.parentPredecessors.length;
      for (var i = parentPredecessorLength - 1; i >= 0; i--) {
        var item = this.parentPredecessors[i];
        this.validatePredecessorDates(item);
        if (item.ganttProperties.startDate) {
          this.updateChildItems(item);
        }
      }
    }
  };
  /**
   * To validate task date values with dependency
   *
   * @param {IGanttData} ganttRecord .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.validatePredecessorDates = function (ganttRecord) {
    if (ganttRecord.ganttProperties.predecessor) {
      var predecessorsCollection = ganttRecord.ganttProperties.predecessor;
      var count = void 0;
      var parentGanttRecord = void 0;
      var record = null;
      var currentTaskId_1 = this.parent.viewType === 'ResourceView' ? ganttRecord.ganttProperties.taskId.toString() : ganttRecord.ganttProperties.rowUniqueID.toString();
      var predecessors = predecessorsCollection.filter(function (data) {
        if (data.to === currentTaskId_1) {
          return data;
        } else {
          return null;
        }
      });
      for (count = 0; count < predecessors.length; count++) {
        var predecessor = predecessors[count];
        parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor.from);
        if (this.parent.allowParentDependency && parentGanttRecord.hasChildRecords) {
          this.parent.dataOperation.updateParentItems(parentGanttRecord);
        }
        record = this.parent.connectorLineModule.getRecordByID(predecessor.to);
        if (this.parent.viewType === 'ProjectView' && this.parent.allowTaskbarDragAndDrop) {
          var index = void 0;
          if (isNullOrUndefined(record)) {
            index = this.parent.editModule.taskbarEditModule.previousIds.indexOf(predecessor.to);
            record = this.parent.editModule.taskbarEditModule.previousFlatData[index];
          } else if (isNullOrUndefined(parentGanttRecord)) {
            index = this.parent.editModule.taskbarEditModule.previousIds.indexOf(predecessor.from);
            parentGanttRecord = this.parent.editModule.taskbarEditModule.previousFlatData[index];
          }
        }
        if (this.parent.allowParentDependency && this.parent.isLoad && this.parentPredecessors.indexOf(ganttRecord) === -1 && (ganttRecord.hasChildRecords || record.hasChildRecords)) {
          this.parentPredecessors.push(ganttRecord);
        }
        if (record.ganttProperties.isAutoSchedule || this.parent.validateManualTasksOnLinking) {
          this.validateChildGanttRecord(parentGanttRecord, record);
        }
      }
    }
  };
  /**
   * Method to validate task with predecessor
   *
   * @param {IGanttData} parentGanttRecord .
   * @param {IGanttData} childGanttRecord .
   * @returns {void} .
   */
  Dependency.prototype.validateChildGanttRecord = function (parentGanttRecord, childGanttRecord) {
    if (this.parent.editedTaskBarItem === childGanttRecord || parentGanttRecord && isNullOrUndefined(isScheduledTask(parentGanttRecord.ganttProperties)) || childGanttRecord && isNullOrUndefined(isScheduledTask(childGanttRecord.ganttProperties))) {
      return;
    }
    if (this.parent.isInPredecessorValidation && (childGanttRecord.ganttProperties.isAutoSchedule || this.parent.validateManualTasksOnLinking)) {
      var childRecordProperty = childGanttRecord.ganttProperties;
      var currentTaskId_2 = this.parent.viewType === 'ResourceView' ? childRecordProperty.taskId.toString() : childRecordProperty.rowUniqueID.toString();
      var predecessorsCollection = childRecordProperty.predecessor;
      var childPredecessor = predecessorsCollection.filter(function (data) {
        if (data.to === currentTaskId_2) {
          return data;
        } else {
          return null;
        }
      });
      var startDate = this.getPredecessorDate(childGanttRecord, childPredecessor);
      this.parent.setRecordValue('startDate', startDate, childRecordProperty, true);
      this.parent.dataOperation.updateMappingData(childGanttRecord, 'startDate');
      var segments = childGanttRecord.ganttProperties.segments;
      if (isNullOrUndefined(segments) || !isNullOrUndefined(segments) && segments.length === 0) {
        this.dateValidateModule.calculateEndDate(childGanttRecord);
      }
      this.parent.dataOperation.updateWidthLeft(childGanttRecord);
      if (!this.parent.isLoad && !this.parent.isFromOnPropertyChange && childGanttRecord.parentItem && this.parent.isInPredecessorValidation && this.parent.getParentTask(childGanttRecord.parentItem).ganttProperties.isAutoSchedule) {
        if (this.parentIds.indexOf(childGanttRecord.parentItem.uniqueID) === -1) {
          this.parentIds.push(childGanttRecord.parentItem.uniqueID);
          this.parentRecord.push(childGanttRecord.parentItem);
        }
      }
    }
  };
  /**
   *
   * @param {IGanttData} ganttRecord .
   * @param {IPredecessor[]} predecessorsCollection .
   * @returns {Date} .
   * @private
   */
  Dependency.prototype.getPredecessorDate = function (ganttRecord, predecessorsCollection) {
    var _this = this;
    var maxStartDate;
    var tempStartDate;
    var parentGanttRecord;
    var childGanttRecord;
    var validatedPredecessor = predecessorsCollection.filter(function (data) {
      var id = _this.parent.viewType === 'ResourceView' ? ganttRecord.ganttProperties.taskId : ganttRecord.ganttProperties.rowUniqueID;
      if (data.to === id.toString()) {
        return data;
      } else {
        return null;
      }
    });
    if (validatedPredecessor) {
      var length_2 = validatedPredecessor.length;
      for (var i = 0; i < length_2; i++) {
        var predecessor = validatedPredecessor[i];
        parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor.from);
        childGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor.to);
        if (this.parent.viewType === 'ProjectView' && this.parent.allowTaskbarDragAndDrop && !(isNullOrUndefined(childGanttRecord) && isNullOrUndefined(parentGanttRecord))) {
          childGanttRecord = isNullOrUndefined(childGanttRecord) ? this.getRecord(parentGanttRecord, childGanttRecord, predecessor) : childGanttRecord;
          parentGanttRecord = isNullOrUndefined(parentGanttRecord) ? this.getRecord(parentGanttRecord, childGanttRecord, predecessor) : parentGanttRecord;
        }
        if (childGanttRecord && parentGanttRecord) {
          tempStartDate = this.getValidatedStartDate(childGanttRecord.ganttProperties, parentGanttRecord.ganttProperties, predecessor);
        }
        if (maxStartDate === null || this.dateValidateModule.compareDates(tempStartDate, maxStartDate) === 1) {
          maxStartDate = tempStartDate;
        }
      }
    }
    return maxStartDate;
  };
  /**
   * Get validated start date as per predecessor type
   *
   * @param {ITaskData} ganttProperty .
   * @param {ITaskData} parentRecordProperty .
   * @param {IPredecessor} predecessor .
   * @returns {Date} .
   */
  Dependency.prototype.getValidatedStartDate = function (ganttProperty, parentRecordProperty, predecessor) {
    var type = predecessor.type;
    var offset = predecessor.offset;
    var tempDate;
    var returnStartDate;
    switch (type) {
      case 'FS':
        tempDate = this.dateValidateModule.getValidEndDate(parentRecordProperty);
        if (!ganttProperty.isMilestone || offset !== 0) {
          tempDate = this.dateValidateModule.checkStartDate(tempDate, ganttProperty);
        }
        if (offset !== 0) {
          tempDate = this.updateDateByOffset(tempDate, predecessor, ganttProperty);
        }
        if (!ganttProperty.isMilestone) {
          returnStartDate = this.dateValidateModule.checkStartDate(tempDate, ganttProperty);
        } else {
          returnStartDate = tempDate;
        }
        break;
      case 'FF':
      case 'SF':
        tempDate = type === 'FF' ? this.dateValidateModule.getValidEndDate(parentRecordProperty) : this.dateValidateModule.getValidStartDate(parentRecordProperty);
        if (offset !== 0) {
          tempDate = this.updateDateByOffset(tempDate, predecessor, ganttProperty);
        }
        if (!ganttProperty.isMilestone) {
          var date = new Date(tempDate);
          date.setDate(date.getDate() - 1);
          tempDate = this.dateValidateModule.checkEndDate(tempDate, ganttProperty);
        }
        if (ganttProperty.segments && ganttProperty.segments.length !== 0) {
          var duration = this.dateValidateModule.getDuration(ganttProperty.startDate, ganttProperty.endDate, ganttProperty.durationUnit, ganttProperty.isAutoSchedule, ganttProperty.isMilestone);
          returnStartDate = this.dateValidateModule.getStartDate(tempDate, duration, ganttProperty.durationUnit, ganttProperty);
        } else {
          returnStartDate = this.dateValidateModule.getStartDate(tempDate, ganttProperty.duration, ganttProperty.durationUnit, ganttProperty);
        }
        break;
      case 'SS':
        tempDate = this.dateValidateModule.getValidStartDate(parentRecordProperty);
        if (offset !== 0) {
          tempDate = this.updateDateByOffset(tempDate, predecessor, ganttProperty);
        }
        if (!ganttProperty.isMilestone) {
          returnStartDate = this.dateValidateModule.checkStartDate(tempDate, ganttProperty);
        } else {
          returnStartDate = tempDate;
        }
        break;
    }
    return returnStartDate;
  };
  /**
   *
   * @param {Date} date .
   * @param {IPredecessor} predecessor .
   * @param {ITaskData} record .
   * @returns {void} .
   */
  Dependency.prototype.updateDateByOffset = function (date, predecessor, record) {
    var resultDate;
    var offsetValue = predecessor.offset;
    var durationUnit = predecessor.offsetUnit;
    if (offsetValue < 0 && !isNullOrUndefined(date)) {
      resultDate = this.dateValidateModule.getStartDate(this.dateValidateModule.checkEndDate(date, record), offsetValue * -1, durationUnit, record, true);
    } else {
      if (!isNullOrUndefined(date)) {
        resultDate = this.dateValidateModule.getEndDate(date, offsetValue, durationUnit, record, false);
      }
      if (!record.isMilestone) {
        resultDate = this.dateValidateModule.checkStartDate(resultDate, record);
      }
    }
    return resultDate;
  };
  /**
   *
   * @param {IGanttData} records .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.createConnectorLinesCollection = function (records) {
    var ganttRecords = records ? records : this.parent.currentViewData;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth && this.parent.pdfExportModule.isPdfExport) {
      ganttRecords = this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneCurrentViewData'];
    }
    var recordLength = ganttRecords.length;
    var count;
    var ganttRecord;
    var predecessorsCollection;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth && this.parent.pdfExportModule.isPdfExport) {
      this.parent.connectorLineModule.expandedRecords = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData'] : this.parent.getExpandedRecords(this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData']);
    } else {
      this.parent.connectorLineModule.expandedRecords = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.updatedRecords : this.parent.getExpandedRecords(this.parent.updatedRecords);
    }
    for (count = 0; count < recordLength; count++) {
      if (this.parent.editModule && this.parent.editModule.deletedTaskDetails.length > 0) {
        if (ganttRecords[count].parentItem) {
          var parentItem = this.parent.getRecordByID(ganttRecords[count].parentItem.taskId.toString());
          this.parent.setRecordValue('parentItem', this.parent.dataOperation.getCloneParent(parentItem), ganttRecords[count]);
        }
      }
      if (this.parent.undoRedoModule && this.parent.undoRedoModule['canUpdateIndex']) {
        ganttRecords[count].index = count;
        if (ganttRecords[count].parentItem && this.parent.getParentTask(ganttRecords[count].parentItem)) {
          ganttRecords[count].parentItem.index = this.parent.getParentTask(ganttRecords[count].parentItem).index;
        }
      }
      ganttRecord = ganttRecords[count];
      predecessorsCollection = ganttRecord.ganttProperties.predecessor;
      if (predecessorsCollection) {
        this.addPredecessorsCollection(predecessorsCollection);
      }
    }
  };
  /**
   *
   * @param {object[]} predecessorsCollection .
   * @returns {void} .
   */
  Dependency.prototype.addPredecessorsCollection = function (predecessorsCollection) {
    var predecessorsLength;
    var predecessorCount;
    var predecessor;
    var parentGanttRecord;
    var childGanttRecord;
    if (predecessorsCollection) {
      predecessorsLength = predecessorsCollection.length;
      for (predecessorCount = 0; predecessorCount < predecessorsLength; predecessorCount++) {
        predecessor = predecessorsCollection[predecessorCount];
        var from = 'from';
        var to = 'to';
        parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor[from]);
        childGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor[to]);
        var isValid = true;
        if ((parentGanttRecord && parentGanttRecord.hasChildRecords && !parentGanttRecord.expanded || childGanttRecord && childGanttRecord.hasChildRecords && !childGanttRecord.expanded) && !this.parent.allowTaskbarOverlap && this.parent.viewType === 'ProjectView') {
          isValid = false;
        }
        if (isValid && this.parent.connectorLineModule.expandedRecords && this.parent.connectorLineModule.expandedRecords.indexOf(parentGanttRecord) !== -1 && this.parent.connectorLineModule.expandedRecords.indexOf(childGanttRecord) !== -1) {
          this.updateConnectorLineObject(parentGanttRecord, childGanttRecord, predecessor);
        }
      }
    }
  };
  /**
   * To refresh connector line object collections
   *
   * @param {IGanttData} parentGanttRecord .
   * @param {IGanttData} childGanttRecord .
   * @param {IPredecessor} predecessor .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.updateConnectorLineObject = function (parentGanttRecord, childGanttRecord, predecessor) {
    var connectorObj = this.parent.connectorLineModule.createConnectorLineObject(parentGanttRecord, childGanttRecord, predecessor);
    if (connectorObj) {
      if (childGanttRecord.isCritical && parentGanttRecord.isCritical) {
        connectorObj.isCritical = true;
      }
      if (this.parent.connectorLineIds.length > 0 && this.parent.connectorLineIds.indexOf(connectorObj.connectorLineId) === -1 || this.parent.connectorLineIds.length === 0) {
        this.parent.updatedConnectorLineCollection.push(connectorObj);
        this.parent.connectorLineIds.push(connectorObj.connectorLineId);
      } else if (this.parent.connectorLineIds.indexOf(connectorObj.connectorLineId) !== -1) {
        var index = this.parent.connectorLineIds.indexOf(connectorObj.connectorLineId);
        this.parent.updatedConnectorLineCollection[index] = connectorObj;
      }
      predecessor.isDrawn = true;
    }
    return connectorObj;
  };
  /**
   *
   * @param {IGanttData} childGanttRecord .
   * @param {IPredecessor[]} previousValue .
   * @param {string} validationOn .
   * @returns {void} .
   * @private
   */
  Dependency.prototype.validatePredecessor = function (childGanttRecord, previousValue, validationOn) {
    if (!this.parent.isInPredecessorValidation) {
      return;
    }
    if (childGanttRecord.ganttProperties.predecessor) {
      var taskBarModule = this.parent.editModule.taskbarEditModule;
      var ganttProp = void 0;
      if (taskBarModule) {
        ganttProp = taskBarModule.taskBarEditRecord;
      }
      var predecessorsCollection = childGanttRecord.ganttProperties.predecessor;
      var parentGanttRecord = void 0;
      var record = null;
      var predecessor = void 0;
      var successor = void 0;
      var currentTaskId_3 = this.parent.viewType === 'ResourceView' ? childGanttRecord.ganttProperties.taskId.toString() : childGanttRecord.ganttProperties.rowUniqueID.toString();
      var predecessors = predecessorsCollection.filter(function (data) {
        if (data.to === currentTaskId_3) {
          return data;
        } else {
          return null;
        }
      });
      var successors = predecessorsCollection.filter(function (data) {
        if (data.from === currentTaskId_3) {
          return data;
        } else {
          return null;
        }
      });
      var parentRec = void 0;
      for (var count = 0; count < predecessors.length; count++) {
        predecessor = predecessors[count];
        parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor.from);
        record = this.parent.connectorLineModule.getRecordByID(predecessor.to);
        if (record.parentItem) {
          parentRec = this.parent.getTaskByUniqueID(record.parentItem.uniqueID);
          if (this.parent.editModule['updateParentRecords'].indexOf(parentRec) === -1) {
            this.parent.editModule['updateParentRecords'].push(parentRec);
          }
        }
        if (parentGanttRecord.parentItem) {
          parentRec = this.parent.getTaskByUniqueID(parentGanttRecord.parentItem.uniqueID);
          if (this.parent.editModule['updateParentRecords'].indexOf(parentRec) === -1) {
            this.parent.editModule['updateParentRecords'].push(parentRec);
          }
        }
        if (this.parent.viewType === 'ProjectView' && this.parent.allowTaskbarDragAndDrop && !(isNullOrUndefined(record) && isNullOrUndefined(parentGanttRecord))) {
          record = isNullOrUndefined(record) ? this.getRecord(parentGanttRecord, record, predecessor) : record;
          parentGanttRecord = isNullOrUndefined(parentGanttRecord) ? this.getRecord(parentGanttRecord, record, predecessor) : parentGanttRecord;
        }
        if (this.parent.isInPredecessorValidation && record.ganttProperties.isAutoSchedule) {
          this.parent.isValidationEnabled = true;
        } else {
          this.parent.isValidationEnabled = false;
        }
        var id = this.parent.viewType === 'ResourceView' ? childGanttRecord.ganttProperties.taskId : childGanttRecord.ganttProperties.rowUniqueID;
        if ((id.toString() === predecessor.to || id.toString() === predecessor.from) && (!validationOn || validationOn === 'predecessor')) {
          this.validateChildGanttRecord(parentGanttRecord, record);
          if (this.parent.editModule['editedRecord'] && this.parent.editModule['editedRecord'].hasChildRecords && !this.parent.editModule['editedRecord'].parentItem) {
            this.isValidatedParentTaskID = record.ganttProperties.taskId;
          }
        }
      }
      for (var count = 0; count < successors.length; count++) {
        successor = successors[count];
        parentGanttRecord = this.parent.connectorLineModule.getRecordByID(successor.from);
        record = this.parent.connectorLineModule.getRecordByID(successor.to);
        if (record.parentItem) {
          parentRec = this.parent.getTaskByUniqueID(record.parentItem.uniqueID);
          if (this.parent.editModule['updateParentRecords'].indexOf(parentRec) === -1) {
            this.parent.editModule['updateParentRecords'].push(parentRec);
          }
        }
        if (parentGanttRecord.parentItem) {
          parentRec = this.parent.getTaskByUniqueID(parentGanttRecord.parentItem.uniqueID);
          if (this.parent.editModule['updateParentRecords'].indexOf(parentRec) === -1) {
            this.parent.editModule['updateParentRecords'].push(parentRec);
          }
        }
        if (this.parent.viewType === 'ProjectView' && this.parent.allowTaskbarDragAndDrop && !(isNullOrUndefined(record) && isNullOrUndefined(parentGanttRecord))) {
          record = isNullOrUndefined(record) ? this.getRecord(parentGanttRecord, record, successor) : record;
          parentGanttRecord = isNullOrUndefined(parentGanttRecord) ? this.getRecord(parentGanttRecord, record, successor) : parentGanttRecord;
        }
        if (this.parent.isInPredecessorValidation && record.ganttProperties.isAutoSchedule) {
          this.parent.isValidationEnabled = true;
        } else {
          this.parent.isValidationEnabled = false;
        }
        if (validationOn !== 'predecessor' && this.parent.isValidationEnabled) {
          this.validateChildGanttRecord(parentGanttRecord, record);
          if (this.parent.editModule['editedRecord'] && record) {
            var rootParent = parentGanttRecord.parentItem ? this.parent.connectorLineEditModule.getRootParent(parentGanttRecord) : null;
            if (record.hasChildRecords && (!this.parent.editModule['editedRecord'].hasChildRecords || !record.parentItem && (!rootParent || rootParent && rootParent.ganttProperties.taskId === this.parent.editModule['editedRecord'].ganttProperties.taskId)) && this.isValidatedParentTaskID !== record.ganttProperties.taskId) {
              this.updateChildItems(record);
              for (var i = 0; i < record.childRecords.length; i++) {
                var ganttProp_1 = record.childRecords[i].ganttProperties;
                if (this.isChildRecordValidated.indexOf(ganttProp_1.taskId) !== -1) {
                  return;
                }
                this.isChildRecordValidated.push(ganttProp_1.taskId);
                if (ganttProp_1.predecessor && ganttProp_1.predecessor.length > 0) {
                  for (var j = 0; j < ganttProp_1.predecessor.length; j++) {
                    var childRec = void 0;
                    if (ganttProp_1.predecessor[j].to !== record.ganttProperties.taskId.toString()) {
                      childRec = this.parent.flatData[this.parent.ids.indexOf(ganttProp_1.predecessor[j].to)];
                    } else {
                      childRec = this.parent.flatData[this.parent.ids.indexOf(ganttProp_1.predecessor[j].from)];
                    }
                    if (childRec) {
                      this.validatePredecessor(childRec, [], '');
                      if (childRec.hasChildRecords && this.parent.editModule['editedRecord'].hasChildRecords) {
                        this.updateChildItems(childRec);
                      }
                      this.isValidatedParentTaskID = childRec.ganttProperties.taskId;
                    }
                  }
                }
              }
              this.isValidatedParentTaskID = record.ganttProperties.taskId;
            }
            if (this.parent.editModule['editedRecord'].hasChildRecords && !this.parent.editModule['editedRecord'].parentItem) {
              this.isValidatedParentTaskID = record.ganttProperties.taskId;
            }
          }
        } else if (!record.ganttProperties.isAutoSchedule && this.parent.updateOffsetOnTaskbarEdit) {
          this.parent.connectorLineEditModule['validatedOffsetIds'] = [];
          this.parent.connectorLineEditModule['calculateOffset'](record);
        }
        if (parentGanttRecord.expanded === false || record.expanded === false) {
          if (record) {
            this.validatePredecessor(record, undefined, 'successor');
          }
          continue;
        }
        if (record) {
          if (this.parent.editModule.isFirstCall) {
            var taskIdsForView = this.parent.viewType === 'ResourceView' ? this.parent.getTaskIds() : this.parent.ids;
            this.storeId = JSON.parse(JSON.stringify(taskIdsForView));
            this.parent.editModule.isFirstCall = false;
          }
          if (this.storeId) {
            var index = void 0;
            var idAsString = record[this.parent.taskFields.id].toString();
            if (this.parent.viewType === 'ResourceView') {
              var taskId = "T" + idAsString;
              index = this.storeId.includes(taskId) ? this.storeId.indexOf(taskId) : -1;
            } else {
              index = this.storeId.indexOf(idAsString);
            }
            if (index !== -1) {
              this.storeId = this.storeId.slice(0, index).concat(this.storeId.slice(index + 1));
              this.validatePredecessor(record, undefined, 'successor');
            }
          } else {
            this.validatePredecessor(record, undefined, 'successor');
          }
        }
      }
      if (record && !record.hasChildRecords && record.parentItem && this.validatedParentIds.indexOf(record.parentItem.taskId) === -1) {
        this.validatedParentIds.push(record.parentItem.taskId);
      }
      var validUpdate = true;
      if (record && record.hasChildRecords && this.validatedParentIds.indexOf(record.ganttProperties.taskId.toString()) !== -1) {
        validUpdate = false;
      }
      if (validUpdate) {
        if (record && record.ganttProperties.taskId !== this.isValidatedParentTaskID && ganttProp) {
          if (taskBarModule.taskBarEditAction !== 'ParentDrag' && taskBarModule.taskBarEditAction !== 'ChildDrag') {
            if (!ganttProp.hasChildRecords && record.hasChildRecords) {
              this.updateChildItems(record);
              this.isValidatedParentTaskID = record.ganttProperties.taskId;
            }
          }
          if (record.parentItem) {
            this.parent.dataOperation.updateParentItems(record, true);
            var parentData = this.parent.getParentTask(record.parentItem);
            var index = this.storeId && this.storeId.indexOf(parentData[this.parent.taskFields.id].toString()) !== -1 ? this.storeId.indexOf(parentData[this.parent.taskFields.id].toString()) : -1;
            if (parentData.ganttProperties.predecessor && parentData.ganttProperties.predecessor.length > 0 && index !== -1) {
              for (var i = 0; i < parentData.ganttProperties.predecessor.length; i++) {
                if (parentData.ganttProperties.predecessor[i].to !== parentData.ganttProperties.taskId.toString()) {
                  var childRec = this.parent.flatData[this.parent.ids.indexOf(parentData.ganttProperties.predecessor[i].to)];
                  if (childRec && childRec.parentItem) {
                    this.validateChildGanttRecord(record, childRec);
                  }
                }
              }
            }
          }
        } else if (record && record.hasChildRecords && this.isValidatedParentTaskID !== record.ganttProperties.taskId && !ganttProp) {
          this.updateChildItems(record);
          this.isValidatedParentTaskID = record.ganttProperties.taskId;
        }
      }
    }
  };
  /**
   *
   * @param {IGanttData} ganttRecord .
   * @returns {void} .
   */
  Dependency.prototype.updateChildItems = function (ganttRecord) {
    var _this = this;
    if (ganttRecord.childRecords.length > 0 && this.validatedChildItems.length > 0) {
      var isPresent = true;
      isPresent = !ganttRecord.childRecords.some(function (record) {
        return _this.validatedChildItems['includes'](record);
      });
      if (!isPresent) {
        return;
      }
    }
    var previousData = this.parent.previousRecords[ganttRecord.uniqueID];
    var previousStartDate;
    if (isNullOrUndefined(previousData) || isNullOrUndefined(previousData) && !isNullOrUndefined(previousData.ganttProperties)) {
      previousStartDate = new Date(ganttRecord.ganttProperties.startDate.getTime());
    } else {
      if (!isNullOrUndefined(previousData.ganttProperties.startDate)) {
        previousStartDate = new Date(previousData.ganttProperties.startDate.getTime());
      }
    }
    var currentStartDate = ganttRecord.ganttProperties.startDate;
    var childRecords = [];
    var validStartDate;
    var validEndDate;
    var calcEndDate;
    var isRightMove;
    var durationDiff;
    this.getUpdatableChildRecords(ganttRecord, childRecords);
    if (childRecords.length === 0) {
      return;
    }
    if (!isNullOrUndefined(previousStartDate) && !isNullOrUndefined(currentStartDate) && previousStartDate.getTime() > currentStartDate.getTime()) {
      validStartDate = this.parent.dateValidationModule.checkStartDate(currentStartDate);
      validEndDate = this.parent.dateValidationModule.checkEndDate(previousStartDate, ganttRecord.ganttProperties);
      isRightMove = false;
    } else {
      validStartDate = this.parent.dateValidationModule.checkStartDate(previousStartDate);
      validEndDate = this.parent.dateValidationModule.checkEndDate(currentStartDate, ganttRecord.ganttProperties);
      isRightMove = true;
    }
    //Get Duration
    if (!isNullOrUndefined(validStartDate) && !isNullOrUndefined(validEndDate) && validStartDate.getTime() >= validEndDate.getTime()) {
      durationDiff = 0;
    } else {
      durationDiff = this.parent.dateValidationModule.getDuration(validStartDate, validEndDate, 'minute', true, false);
    }
    for (var i = 0; i < childRecords.length; i++) {
      if (childRecords[i].ganttProperties.isAutoSchedule) {
        if (durationDiff > 0) {
          var startDate = isScheduledTask(childRecords[i].ganttProperties) ? childRecords[i].ganttProperties.startDate : childRecords[i].ganttProperties.startDate ? childRecords[i].ganttProperties.startDate : childRecords[i].ganttProperties.endDate ? childRecords[i].ganttProperties.endDate : new Date(previousStartDate.toString());
          if (isRightMove) {
            calcEndDate = this.parent.dateValidationModule.getEndDate(this.parent.dateValidationModule.checkStartDate(startDate, childRecords[i].ganttProperties, childRecords[i].ganttProperties.isMilestone), durationDiff, 'minute', childRecords[i].ganttProperties, false);
          } else {
            calcEndDate = this.parent.dateValidationModule.getStartDate(this.parent.dateValidationModule.checkEndDate(startDate, childRecords[i].ganttProperties), durationDiff, 'minute', childRecords[i].ganttProperties);
          }
          this.calculateDateByRoundOffDuration(childRecords[i], calcEndDate);
          if (this.parent.isOnEdit && this.validatedChildItems.indexOf(childRecords[i]) === -1) {
            this.validatedChildItems.push(childRecords[i]);
          }
        } else if (isNullOrUndefined(previousData)) {
          calcEndDate = previousStartDate;
          if (childRecords[i].ganttProperties.startDate.getTime() <= calcEndDate.getTime()) {
            this.calculateDateByRoundOffDuration(childRecords[i], calcEndDate);
          }
          if (this.parent.isOnEdit && this.validatedChildItems.indexOf(childRecords[i]) === -1) {
            this.validatedChildItems.push(childRecords[i]);
          }
        }
      }
    }
    if (childRecords.length) {
      this.parent.dataOperation.updateParentItems(ganttRecord, true);
    }
  };
  /**
   * To get updated child records.
   *
   * @param {IGanttData} parentRecord .
   * @param {IGanttData} childLists .
   * @returns {void} .
   */
  Dependency.prototype.getUpdatableChildRecords = function (parentRecord, childLists) {
    var childRecords = parentRecord.childRecords;
    for (var i = 0; i < childRecords.length; i++) {
      if (childRecords[i].ganttProperties.isAutoSchedule) {
        childLists.push(childRecords[i]);
        if (childRecords[i].hasChildRecords) {
          this.getUpdatableChildRecords(childRecords[i], childLists);
        }
      }
    }
  };
  /**
   *
   * @param {IGanttData} data .
   * @param {Date} newStartDate .
   * @returns {void} .
   */
  Dependency.prototype.calculateDateByRoundOffDuration = function (data, newStartDate) {
    var ganttRecord = data;
    var taskData = ganttRecord.ganttProperties;
    var projectStartDate = new Date(newStartDate.getTime());
    if (!isNullOrUndefined(taskData.endDate) && isNullOrUndefined(taskData.startDate)) {
      var endDate = this.parent.dateValidationModule.checkStartDate(projectStartDate, taskData, null);
      this.parent.setRecordValue('endDate', this.parent.dateValidationModule.checkEndDate(endDate, ganttRecord.ganttProperties), taskData, true);
    } else {
      this.parent.setRecordValue('startDate', this.parent.dateValidationModule.checkStartDate(projectStartDate, taskData, false), taskData, true);
      if (!isNullOrUndefined(taskData.duration)) {
        this.parent.dateValidationModule.calculateEndDate(ganttRecord);
      }
    }
    this.parent.dataOperation.updateWidthLeft(data);
    this.parent.dataOperation.updateTaskData(ganttRecord);
  };
  Dependency.prototype.getRecord = function (parentGanttRecord, record, predecessor) {
    var index;
    var data;
    if (isNullOrUndefined(record)) {
      index = this.parent.editModule.taskbarEditModule.previousIds.indexOf(predecessor.to);
      data = this.parent.editModule.taskbarEditModule.previousFlatData[index];
    } else if (isNullOrUndefined(parentGanttRecord)) {
      index = this.parent.editModule.taskbarEditModule.previousIds.indexOf(predecessor.from);
      data = this.parent.editModule.taskbarEditModule.previousFlatData[index];
    }
    return data;
  };
  /**
   * Method to get validate able predecessor alone from record
   *
   * @param {IGanttData} record .
   * @returns {IPredecessor[]} .
   * @private
   */
  Dependency.prototype.getValidPredecessor = function (record) {
    var _this = this;
    var validPredecessor = [];
    if (!isNullOrUndefined(record)) {
      var recPredecessor = record.ganttProperties.predecessor;
      if (recPredecessor && recPredecessor.length > 0) {
        validPredecessor = recPredecessor.filter(function (value) {
          var id = _this.parent.viewType === 'ResourceView' ? record.ganttProperties.taskId : record.ganttProperties.rowUniqueID;
          return value.from !== id.toString();
        });
      }
    }
    return validPredecessor;
  };
  return Dependency;
}();

/**
 * To render the connector line in Gantt
 */
var ConnectorLine = /** @__PURE__ @class */function () {
  function ConnectorLine(ganttObj) {
    this.transform = '';
    this.connectorLinePath = '';
    this.arrowPath = '';
    this.taskLineValue = 0;
    this.expandedRecords = [];
    this.parent = ganttObj;
    this.dependencyViewContainer = createElement('div', {
      className: dependencyViewContainer
    });
    Object.assign(this.dependencyViewContainer.style, {
      width: '100%',
      height: '100%',
      zIndex: 2,
      position: 'absolute',
      pointerEvents: 'none'
    });
    this.renderer = new SvgRenderer(this.parent.element.id);
    this.initPublicProp();
    this.svgObject = this.renderer.createSvg({
      id: this.parent.element.id + '_svg'
    });
    this.svgObject.setAttribute('height', '100%');
    this.svgObject.setAttribute('width', '100%');
  }
  /**
   * To get connector line gap.
   *
   * @param {IConnectorLineObject} data .
   * @returns {number} .
   * @private
   */
  ConnectorLine.prototype.getconnectorLineGap = function (data) {
    var width = 0;
    width = data.milestoneChild ? this.parent.chartRowsModule.milestoneMarginTop / 2 + this.parent.chartRowsModule.milestoneHeight / 2 : this.parent.chartRowsModule.taskBarMarginTop / 2 + this.parent.chartRowsModule.taskBarHeight / 2;
    return width;
  };
  /**
   * To initialize the public property.
   *
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.initPublicProp = function () {
    this.lineColor = this.parent.connectorLineBackground;
    this.lineStroke = this.parent.connectorLineWidth > 4 ? 4 : this.parent.connectorLineWidth;
    this.createConnectorLineTooltipTable();
  };
  ConnectorLine.prototype.getTaskbarMidpoint = function (isMilestone) {
    return Math.floor(isMilestone ? this.parent.chartRowsModule.milestoneMarginTop + this.parent.chartRowsModule.milestoneHeight / 2 : this.parent.chartRowsModule.taskBarMarginTop + this.parent.chartRowsModule.taskBarHeight / 2) + 1;
  };
  /**
   * To connector line object collection.
   *
   * @param {IGanttData} parentGanttData .
   * @param {IGanttData} childGanttData .
   * @param {IPredecessor}  predecessor .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.createConnectorLineObject = function (parentGanttData, childGanttData, predecessor) {
    var connectorObj = {};
    var updatedRecords;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      updatedRecords = this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData'];
    } else {
      updatedRecords = this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport ? this.parent.flatData : this.expandedRecords;
    }
    var parentIndex;
    var childIndex;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      var parentData = this.parent.flatData.filter(function (data) {
        return data.ganttProperties.taskId.toString() === parentGanttData.ganttProperties.taskId.toString();
      })[0];
      var childData = this.parent.flatData.filter(function (data) {
        return data.ganttProperties.taskId.toString() === childGanttData.ganttProperties.taskId.toString();
      })[0];
      parentIndex = parentData.index;
      childIndex = childData.index;
    } else {
      parentIndex = updatedRecords.indexOf(parentGanttData);
      childIndex = updatedRecords.indexOf(childGanttData);
    }
    var parentGanttRecord = parentGanttData.ganttProperties;
    var childGanttRecord = childGanttData.ganttProperties;
    var currentData;
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      currentData = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData'] : this.parent.getExpandedRecords(this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData']);
    } else {
      currentData = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.currentViewData : this.parent.expandedRecords;
    }
    connectorObj.parentIndexInCurrentView = currentData.indexOf(parentGanttData);
    connectorObj.childIndexInCurrentView = currentData.indexOf(childGanttData);
    var isVirtualScroll = this.parent.virtualScrollModule && this.parent.enableVirtualization;
    if (!isVirtualScroll && (connectorObj.parentIndexInCurrentView === -1 || connectorObj.childIndexInCurrentView === -1) || connectorObj.parentIndexInCurrentView === -1 && connectorObj.childIndexInCurrentView === -1) {
      return null;
    } else {
      connectorObj.parentLeft = parentGanttRecord.isMilestone ? parentGanttRecord.left - this.parent.chartRowsModule.milestoneHeight / 2 : parentGanttRecord.left;
      connectorObj.childLeft = childGanttRecord.isMilestone ? childGanttRecord.left - this.parent.chartRowsModule.milestoneHeight / 2 : childGanttRecord.left;
      connectorObj.parentWidth = parentGanttRecord.width === 0 || parentGanttRecord.isMilestone ? Math.floor(this.parent.chartRowsModule.milestoneHeight) : parentGanttRecord.width;
      connectorObj.childWidth = childGanttRecord.width === 0 || childGanttRecord.isMilestone ? Math.floor(this.parent.chartRowsModule.milestoneHeight) : childGanttRecord.width;
      connectorObj.parentIndex = parentIndex;
      connectorObj.childIndex = childIndex;
      connectorObj.rowHeight = !isNullOrUndefined(this.parent.ganttChartModule.getChartRows()) && this.parent.ganttChartModule.getChartRows()[0] && this.parent.ganttChartModule.getChartRows()[0].offsetHeight;
      connectorObj.type = predecessor.type;
      var parentId = this.parent.viewType === 'ResourceView' ? parentGanttRecord.taskId : parentGanttRecord.rowUniqueID;
      var childId = this.parent.viewType === 'ResourceView' ? childGanttRecord.taskId : childGanttRecord.rowUniqueID;
      connectorObj.connectorLineId = 'parent' + parentId + 'child' + childId;
      connectorObj.milestoneParent = parentGanttRecord.isMilestone ? true : false;
      connectorObj.milestoneChild = childGanttRecord.isMilestone ? true : false;
      connectorObj.parentEndPoint = connectorObj.parentLeft + connectorObj.parentWidth;
      connectorObj.childEndPoint = connectorObj.childLeft + connectorObj.childWidth;
      if (isNullOrUndefined(isScheduledTask(parentGanttRecord)) || isNullOrUndefined(isScheduledTask(childGanttRecord))) {
        return null;
      } else {
        return connectorObj;
      }
    }
  };
  /**
   * To render connector line.
   *
   * @param {IConnectorLineObject} connectorLinesCollection .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.renderConnectorLines = function (connectorLinesCollection) {
    var connectorLine = '';
    var ariaConnector = [];
    for (var index = 0; index < connectorLinesCollection.length; index++) {
      connectorLine = connectorLine + this.getConnectorLineTemplate(connectorLinesCollection[index]);
      ariaConnector.push(connectorLinesCollection[index]);
    }
    this.svgObject.innerHTML = connectorLine;
    var childNodes = this.parent.connectorLineModule.svgObject.childNodes;
    for (var i = 0; i < childNodes.length; i++) {
      var innerChild = childNodes[i].childNodes;
      for (var j = 0; j < innerChild.length; j++) {
        var ariaString = 'Connector Line ' + this.parent.connectorLineModule.generateAriaLabel(ariaConnector[i]);
        innerChild[j].setAttribute('aria-label', ariaString);
        innerChild[j].setAttribute('role', 'img');
      }
    }
    this.parent.ganttChartModule.chartBodyContent.insertBefore(this.dependencyViewContainer, this.parent.ganttChartModule.chartBodyContent.lastChild);
    this.dependencyViewContainer.appendChild(this.svgObject);
    for (var i = 0; i < this.svgObject.children.length; i++) {
      this.svgObject.children[i].children[0].setAttribute('tabindex', '-1');
    }
  };
  /**
   * To get parent position.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getParentPosition = function (data) {
    if (data.parentIndex < data.childIndex) {
      if (data.type === 'FF') {
        if (data.childLeft + data.childWidth >= data.parentLeft + data.parentWidth) {
          return 'FFType2';
        } else {
          return 'FFType1';
        }
      } else if (data.parentLeft < data.childLeft && data.childLeft > data.parentLeft + data.parentWidth + 25) {
        if (data.type === 'FS') {
          return 'FSType1';
        }
        if (data.type === 'SF') {
          return 'SFType1';
        } else if (data.type === 'SS') {
          return 'SSType2';
        }
      } else if (data.parentLeft < data.childLeft && data.childLeft < data.parentLeft + data.parentWidth || data.parentLeft === data.childLeft || data.parentLeft > data.childLeft) {
        if (data.parentLeft > data.childLeft + data.childWidth + 25) {
          if (data.type === 'SF') {
            return 'SFType2';
          }
        }
        if (data.parentLeft > data.childLeft) {
          if (data.type === 'SS') {
            return 'SSType1';
          }
          if (data.type === 'SF') {
            return 'SFType1';
          }
        }
        if (data.type === 'FS') {
          return 'FSType2';
        } else if (data.type === 'SS') {
          return 'SSType2';
        } else if (data.type === 'SF') {
          return 'SFType1';
        }
      } else if (data.parentLeft < data.childLeft) {
        if (data.type === 'FS') {
          return 'FSType2';
        } else if (data.type === 'SS') {
          return 'SSType2';
        } else if (data.type === 'SF') {
          return 'SFType1';
        }
      }
    } else if (data.parentIndex > data.childIndex) {
      if (data.parentLeft < data.childLeft && data.childLeft > data.parentLeft + data.parentWidth) {
        if (data.type === 'FS') {
          if (30 >= data.childLeft - (data.milestoneParent ? data.parentLeft + data.parentWidth + 4 : data.parentLeft + data.parentWidth)) {
            return 'FSType3';
          } else {
            return 'FSType4';
          }
        }
        if (data.childLeft + data.childWidth > data.parentLeft + data.parentWidth) {
          if (data.type === 'SS') {
            return 'SSType4';
          }
          if (data.type === 'FF') {
            return 'FFType4';
          }
          if (data.type === 'SF') {
            return 'SFType4';
          }
        }
      } else if (data.parentLeft < data.childLeft && data.childLeft < data.parentLeft + data.parentWidth || data.parentLeft === data.childLeft || data.parentLeft > data.childLeft) {
        if (data.childLeft + data.childWidth <= data.parentLeft + data.parentWidth) {
          if (data.type === 'FF') {
            return 'FFType3';
          }
          if (data.type === 'SF') {
            if (data.childLeft + data.childWidth + 25 < data.parentLeft) {
              return 'SFType3';
            } else {
              return 'SFType4';
            }
          }
          if (data.type === 'SS') {
            if (data.childLeft <= data.parentLeft) {
              return 'SSType3';
            } else {
              return 'SSType4';
            }
          }
        } else if (data.childLeft + data.childWidth > data.parentLeft + data.parentWidth) {
          if (data.type === 'FF') {
            return 'FFType4';
          }
          if (data.type === 'SF') {
            return 'SFType4';
          }
          if (data.type === 'SS') {
            if (data.childLeft <= data.parentLeft) {
              return 'SSType3';
            } else {
              return 'SSType4';
            }
          }
        }
        if (data.type === 'FS') {
          return 'FSType3';
        }
      } else if (data.parentLeft < data.childLeft) {
        if (data.type === 'FS') {
          return 'FSType3';
        }
        if (data.type === 'SS') {
          return 'SSType4';
        }
        if (data.type === 'FF') {
          return 'FFType4';
        }
        if (data.type === 'SF') {
          return 'SFType4';
        }
      }
    }
    return null;
  };
  /**
   * To get line height.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getHeightValue = function (data) {
    if (this.parent.showOverAllocation && !this.parent.allowTaskbarOverlap) {
      return data.parentIndex * this.parent.rowHeight > data.childIndex * this.parent.rowHeight ? data.parentIndex * this.parent.rowHeight - data.childIndex * this.parent.rowHeight : data.childIndex * this.parent.rowHeight - data.parentIndex * this.parent.rowHeight;
    } else {
      return data.parentIndex * data.rowHeight > data.childIndex * data.rowHeight ? data.parentIndex * data.rowHeight - data.childIndex * data.rowHeight : data.childIndex * data.rowHeight - data.parentIndex * data.rowHeight;
    }
  };
  /**
   * To get sstype2 inner element width.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getInnerElementWidthSSType2 = function (data) {
    if (data.parentLeft === data.childLeft) {
      return 10;
    }
    return data.childLeft - data.parentLeft;
  };
  /**
   * To get sstype2 inner element left.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getInnerElementLeftSSType2 = function (data) {
    if (data.parentLeft === data.childLeft) {
      return data.parentLeft - 20;
    }
    return data.parentLeft - 10;
  };
  /**
   * To get sstype2 inner child element width.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getInnerChildWidthSSType2 = function (data) {
    if (data.parentLeft + data.parentWidth < data.childLeft) {
      return 10;
    }
    if (data.parentLeft === data.childLeft) {
      return 20;
    }
    if (data.parentLeft + data.parentWidth >= data.childLeft) {
      return 10;
    }
    return data.childLeft - data.parentLeft;
  };
  // private getBorderStyles(cssType: string, unit: number): string {
  //     const borderWidth: string = 'border-' + cssType + '-width:' + unit + 'px;';
  //     const borderStyle: string = 'border-' + cssType + '-style:solid;';
  //     const borderColor: string = !isNullOrUndefined(this.lineColor) ? 'border-' + cssType + '-color:' + this.lineColor + ';' : '';
  //     return (borderWidth + borderStyle + borderColor);
  // }
  ConnectorLine.prototype.calculateAdjustments = function (rowHeight, isMilestone, type) {
    var taskbarHeightValue = this.parent.renderBaseline ? 0.45 : !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 0.7 : 0.62;
    var defaultTaskbarHeight = Math.floor(this.parent.rowHeight * taskbarHeightValue);
    if (isNullOrUndefined(this.parent.taskbarHeight) && rowHeight <= 36 || !isNullOrUndefined(this.parent.taskbarHeight) && this.parent.taskbarHeight <= defaultTaskbarHeight || !isMilestone) {
      return {
        adjustY1: 0,
        adjustX: 0,
        adjustY2: 0,
        adjustZ: 0
      };
    } else {
      // Calculate adjustments dynamically for rowHeight greater than 36
      var divisorY1 = type === 'FSType2' || type === 'FSType3' || type === 'FSType4' || type === 'SSType1' || type === 'SSType2' || type === 'SSType4' || type === 'SFType2' ? 12 : 24;
      var adjustY1 = (rowHeight - 36) / divisorY1;
      var adjustX = -(rowHeight - 36) / 4;
      var adjustZ = -2 * (rowHeight - 36) / 24;
      if (rowHeight <= 36) {
        return {
          adjustY1: 0,
          adjustX: 0,
          adjustY2: 0,
          adjustZ: 0
        };
      }
      return {
        adjustY1: adjustY1,
        adjustX: adjustX,
        adjustZ: adjustZ
      };
    }
  };
  /**
   * To get connector line template.
   *
   * @param {IConnectorLineObject} data .
   * @returns {void}
   * @private
   */
  ConnectorLine.prototype.getConnectorLineTemplate = function (data) {
    var setInnerChildWidthSSType2 = this.getInnerChildWidthSSType2(data);
    var setInnerElementWidthSSType2 = this.getInnerElementWidthSSType2(data);
    var setInnerElementLeftSSType2 = this.getInnerElementLeftSSType2(data);
    var height = this.getHeightValue(data);
    var isMilestoneParent = data.milestoneParent ? true : false;
    var isMilestone = data.milestoneChild ? true : false;
    var isVirtual = this.parent.virtualScrollModule && this.parent.enableVirtualization;
    var connectorLine = this.getPosition(data, this.getParentPosition(data), height);
    var rowPosition = this.getPosition(data, this.getParentPosition(data), height);
    var rowPositionHeight = rowPosition.top;
    var isMilestoneValue = 0;
    if (this.parent.renderBaseline) {
      var modifiedValue = this.parent.rowHeight / 36 * 5;
      isMilestoneValue = data.milestoneParent && data.milestoneChild ? 0 : data.milestoneParent ? -modifiedValue : data.milestoneChild ? modifiedValue : 0;
    }
    var heightValue = isVirtual ? connectorLine.height : height + isMilestoneValue;
    var borderTopWidth = 0;
    var addTop = 0;
    var parentOverlapTopValue = 0;
    var childOverlapTopValue = 0;
    var count = 0;
    if (this.parent.showOverAllocation && !this.parent.allowTaskbarOverlap) {
      for (var i = 0; i < this.parent.currentViewData.length; i++) {
        if (this.parent.getRowByIndex(i).style.display !== 'none') {
          if (count < data.parentIndex) {
            count++;
            parentOverlapTopValue = parentOverlapTopValue + this.parent.getRowByIndex(i).offsetHeight;
          }
        }
      }
      count = 0;
      for (var j = 0; j < this.parent.currentViewData.length; j++) {
        if (this.parent.getRowByIndex(j).style.display !== 'none') {
          if (count < data.childIndex) {
            count++;
            childOverlapTopValue = childOverlapTopValue + this.parent.getRowByIndex(j).offsetHeight;
          }
        }
      }
      if (!this.parent.enableVirtualization) {
        heightValue = Math.abs(parentOverlapTopValue - childOverlapTopValue);
      }
    }
    if (this.parent.currentViewData[data.parentIndex] && this.parent.currentViewData[data.childIndex] && this.parent.allowParentDependency) {
      var fromRecordIsParent = this.parent.currentViewData[data.parentIndex].hasChildRecords;
      var toRecordIsParent = this.parent.currentViewData[data.childIndex].hasChildRecords;
      var fromRecordIsManual = this.parent.currentViewData[data.parentIndex].ganttProperties.isAutoSchedule;
      var toRecordIsManual = this.parent.currentViewData[data.childIndex].ganttProperties.isAutoSchedule;
      var isValid = true;
      if (fromRecordIsParent && fromRecordIsManual && !toRecordIsParent || toRecordIsParent && toRecordIsManual && !fromRecordIsParent || fromRecordIsParent && fromRecordIsManual && toRecordIsManual && toRecordIsParent || !fromRecordIsParent && !toRecordIsParent) {
        isValid = false;
      }
      if (isValid) {
        if (fromRecordIsParent && !fromRecordIsManual && toRecordIsParent && !toRecordIsManual) {
          addTop = -11;
        } else if (!(fromRecordIsParent && !fromRecordIsManual && toRecordIsParent && !toRecordIsManual)) {
          if (data.childIndex > data.parentIndex) {
            if (!fromRecordIsParent && toRecordIsParent) {
              borderTopWidth = -11;
            } else {
              borderTopWidth = 11;
              addTop = -11;
            }
          } else {
            if (fromRecordIsParent && !toRecordIsParent) {
              borderTopWidth = -11;
            } else {
              borderTopWidth = 11;
              addTop = -11;
            }
          }
        }
        if (this.parent.currentViewData[data.parentIndex].ganttProperties.isMilestone) {
          if (data.parentIndex > data.childIndex) {
            addTop = -11;
            borderTopWidth = 12;
          } else if (data.type === 'SS' || data.type === 'FF') {
            addTop = -5;
          }
        } else if (this.parent.currentViewData[data.childIndex].ganttProperties.isMilestone) {
          if (data.parentIndex > data.childIndex) {
            addTop = 5;
            borderTopWidth = -10;
          } else if (data.parentIndex < data.childIndex) {
            if (data.type === 'SS' || data.type === 'FF') {
              addTop = -10;
            }
          }
        } else {
          if (data.parentIndex < data.childIndex && fromRecordIsManual && !toRecordIsManual) {
            addTop = 0;
            borderTopWidth = -11;
          } else if (data.childIndex < data.parentIndex && !fromRecordIsManual && toRecordIsManual) {
            addTop = 0;
            borderTopWidth = -11;
          }
        }
      }
    }
    if (this.getParentPosition(data)) {
      // Create the group element
      var type = this.getParentPosition(data);
      var rowHeight1 = this.parent.rowHeight;
      var adjustments = this.calculateAdjustments(rowHeight1, isMilestone, type);
      this.transform = this.parent.enableRtl ? "translate(" + (this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : this.parent.timelineModule.totalTimelineWidth) + ", 0) scale(-1, 1)" : '';
      this.connectorId = 'ConnectorLine' + data.connectorLineId;
      this.groupObject = this.renderer.createGroup({
        id: this.connectorId,
        transform: this.transform,
        style: 'pointer-events: stroke',
        class: connectorLineContainer
      });
      //  Create the path element for the connector line
      this.connectorPath = this.renderer.drawPath({
        class: connectorLineSVG,
        d: this.connectorLinePath,
        fill: 'transparent',
        'stroke-width': this.lineStroke
      });
      // Create the path element for the arrow
      this.arrowlinePath = this.renderer.drawPath({
        d: this.arrowPath,
        class: connectorLineArrow
      });
      var outlineColor = this.lineColor && !this.parent.enableCriticalPath ? this.lineColor : '';
      this.connectorPath.setAttribute('style', "stroke: " + outlineColor);
      this.arrowlinePath.setAttribute('style', "fill: " + outlineColor);
      // Append the path element to the group element
      this.groupObject.appendChild(this.connectorPath);
      this.groupObject.appendChild(this.arrowlinePath);
      if (this.getParentPosition(data) === 'FSType1') {
        this.taskLineValue = data.milestoneChild ? 1 : 0;
        this.x1 = data.parentEndPoint + (data.milestoneParent ? -1 : data.milestoneChild ? -1 : 0);
        this.x2 = data.milestoneParent ? data.childLeft - (data.parentLeft + data.parentWidth + 10) + this.lineStroke - 10 + 1 : data.childLeft - (data.parentLeft + data.parentWidth + 10) + this.lineStroke - 10;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1) - isMilestoneValue;
        this.y2 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        this.connectorLinePath = 'M ' + this.x1 + ' ' + this.y1 + ' L ' + (this.x1 + this.x2 + adjustments['adjustZ']) + ' ' + this.y1 + ' L ' + (this.x1 + this.x2 + adjustments['adjustZ']) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + this.x2 + 20 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + 4 + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FSType2') {
        this.x1 = data.parentLeft;
        this.x2 = data.parentWidth + (data.milestoneParent ? -1 : 0);
        this.x3 = this.x2 + (data.milestoneParent ? 11 : 10);
        this.x4 = data.parentWidth - (data.parentEndPoint - data.childLeft + 20);
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1) - isMilestoneValue;
        this.y2 = heightValue + borderTopWidth - this.getconnectorLineGap(data) - this.lineStroke;
        this.y3 = this.getconnectorLineGap(data);
        this.y4 = !this.parent.allowTaskbarOverlap ? childOverlapTopValue : this.y1 + this.y2 - (this.y1 + this.y2) % data.rowHeight;
        this.connectorLinePath = 'M ' + (this.x1 + this.x2) + ' ' + this.y1 + ' ' + ' L ' + (this.x1 + this.x3) + ' ' + this.y1 + ' L ' + (this.x1 + this.x3) + ' ' + this.y4 + ' L ' + (this.x1 + this.x4 + adjustments['adjustZ']) + ' ' + this.y4 + ' L ' + (this.x1 + this.x4 + adjustments['adjustZ']) + ' ' + (this.y1 + this.y2 + this.y3 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x4 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + this.y3 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + this.x4 + 20 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + this.y3 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x4 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + this.y3 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x4 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + this.y2 + this.y3 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FSType3') {
        this.taskLineValue = data.milestoneChild ? 1 : 0;
        this.point1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1) - isMilestoneValue;
        this.x1 = data.childLeft + (data.milestoneChild ? -1 : 0) + (data.milestoneParent ? 1 : 0) - 20;
        this.x2 = data.parentEndPoint - data.childLeft + 30;
        this.y1 = this.point1 + (this.parent.renderBaseline ? data.milestoneChild && !data.milestoneParent ? 11 : data.milestoneParent && !data.milestoneChild ? -12 : 0 : 0);
        this.y2 = this.point1 + heightValue + borderTopWidth - this.getconnectorLineGap(data) - this.lineStroke + this.taskLineValue;
        this.y3 = this.getconnectorLineGap(data);
        this.y4 = this.y2 - this.y2 % data.rowHeight;
        this.connectorLinePath = 'M ' + (this.x1 + 12) + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + this.x1 + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + this.x1 + ' ' + this.y4 + ' L ' + (this.x1 + this.x2) + ' ' + this.y4 + ' L ' + (this.x1 + this.x2) + ' ' + (this.y2 + this.y3) + ' L ' + (this.x1 + this.x2 - 12) + ' ' + (this.y2 + this.y3);
        this.arrowPath = 'M ' + (this.x1 + 20 + adjustments['adjustX']) + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y1 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FSType4') {
        this.point1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.x1 = data.parentEndPoint + (data.milestoneChild ? -1 : 0) + (data.milestoneParent ? 1 : 0);
        this.x2 = data.childLeft - data.parentEndPoint - 20;
        this.y1 = this.point1 + (data.milestoneChild ? -1 : 0);
        this.y2 = this.point1 + heightValue + borderTopWidth - this.lineStroke + 1 + this.taskLineValue + (this.parent.renderBaseline ? data.milestoneChild && !data.milestoneParent ? -12 : data.milestoneParent && !data.milestoneChild ? 11 : 0 : 0);
        this.connectorLinePath = 'M ' + (this.x1 + this.x2 + 12) + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2) + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2) + ' ' + this.y2 + ' L ' + this.x1 + ' ' + this.y2;
        this.arrowPath = 'M ' + (this.x1 + this.x2 + 20 + adjustments['adjustX']) + ' ' + (this.y1 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2 + 12 + adjustments['adjustX']) + ' ' + (this.y1 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x2 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'SSType4') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 2 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.point1 = heightValue + this.taskLineValue + borderTopWidth;
        this.point2 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
        this.x1 = data.parentLeft - 10;
        this.x2 = data.childLeft - data.parentLeft;
        this.y1 = this.point2 + (data.milestoneChild ? 1 : 0);
        this.y2 = this.y1 + this.point1 + (this.parent.renderBaseline ? data.milestoneParent && !data.milestoneChild ? 10 : data.milestoneChild && !data.milestoneParent ? -13 : 0 : 0);
        this.connectorLinePath = 'M ' + (this.x1 + this.x2 + adjustedX) + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y2 + ' L ' + (this.x1 + 10) + ' ' + this.y2;
        this.arrowPath = 'M ' + (this.x1 + this.x2 + 8 + adjustedX) + ' ' + this.y1 + ' L ' + (this.x1 + this.x2 + adjustedX) + ' ' + (this.y1 - (4 + this.lineStroke)) + ' L ' + (this.x1 + this.x2 + adjustedX) + ' ' + (this.y1 + 4 + this.lineStroke) + ' Z';
      }
      if (this.getParentPosition(data) === 'SSType3') {
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.point1 = heightValue + this.taskLineValue + borderTopWidth - (this.lineStroke - 1);
        this.x1 = data.childLeft - 20;
        this.y1 = (data.milestoneChild ? 1 : 0) + (this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1));
        this.x2 = data.parentLeft - data.childLeft + 21;
        this.y2 = this.y1 + this.point1 + (this.parent.renderBaseline ? data.milestoneChild && !data.milestoneParent ? -11 : data.milestoneParent && !data.milestoneChild ? 10 : 0 : 0);
        this.connectorLinePath = 'M ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y2 + ' L ' + (this.x1 + this.x2) + ' ' + this.y2;
        this.arrowPath = 'M ' + (this.x1 + 20 + adjustments['adjustX']) + ' ' + this.y1 + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y1 - (4 + this.lineStroke)) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y1 + 4 + this.lineStroke) + ' Z';
      }
      if (this.getParentPosition(data) === 'SSType2') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 2 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.point1 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        this.x1 = setInnerElementLeftSSType2;
        this.x2 = setInnerChildWidthSSType2 + 1;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1);
        this.y2 = this.y1 + this.point1;
        this.connectorLinePath = 'M ' + (this.x1 + this.x2) + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y1 + ' L ' + this.x1 + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + setInnerElementWidthSSType2 + adjustedX) + ' ' + (this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + setInnerElementWidthSSType2 + 8 + adjustedX) + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + setInnerElementWidthSSType2 + adjustedX) + ' ' + (this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + setInnerElementWidthSSType2 + adjustedX) + ' ' + (this.y2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'SSType1') {
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.point1 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        this.x1 = data.childLeft - 20;
        this.x2 = data.parentLeft - data.childLeft + 21;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1);
        this.y2 = this.y1 + this.point1;
        this.connectorLinePath = 'M ' + (this.x1 + this.x2) + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y1 + ' L ' + this.x1 + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + 20 + adjustments['adjustX']) + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + 12 + adjustments['adjustX']) + ' ' + (this.y2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FFType1') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.x1 = data.childEndPoint;
        this.x2 = data.parentEndPoint + (data.milestoneParent ? -1 : 0);
        this.x3 = data.milestoneParent ? 22 : 21;
        this.x4 = data.milestoneChild ? 4 : 8;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1);
        this.y2 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        this.connectorLinePath = 'M ' + this.x2 + ' ' + this.y1 + ' L ' + (this.x2 + this.x3) + ' ' + this.y1 + ' L ' + (this.x2 + this.x3) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x4) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + adjustedX) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y1 + this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y1 + this.y2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FFType2') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.x1 = data.parentEndPoint;
        this.x2 = data.childEndPoint + (data.milestoneParent ? 22 : 21);
        this.x3 = data.childEndPoint + (data.milestoneChild ? 9 : 8);
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(data.milestoneParent) - (this.lineStroke - 1);
        this.y2 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        var arrowX = this.parent.renderBaseline ? 0 : adjustedX;
        this.connectorLinePath = 'M ' + this.x1 + ' ' + this.y1 + ' L ' + this.x2 + ' ' + this.y1 + ' L ' + this.x2 + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + this.x3 + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x3 - 8 + arrowX) + ' ' + (this.y1 + this.y2 + adjustments['adjustY1']) + ' L ' + (this.x3 + arrowX) + ' ' + (this.y1 + this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x3 + arrowX) + ' ' + (this.y1 + this.y2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'FFType3') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.x1 = data.childEndPoint;
        this.x2 = this.x1 + (data.milestoneChild ? 4 : 8);
        this.x3 = data.parentEndPoint - data.childEndPoint + (data.milestoneChild ? 16 : 10);
        this.x4 = data.parentEndPoint + (data.milestoneParent ? -1 : 0);
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
        this.y2 = heightValue + this.taskLineValue + borderTopWidth - this.lineStroke + (this.parent.renderBaseline ? data.milestoneParent && !data.milestoneChild ? 10 : data.milestoneChild && !data.milestoneParent ? -11 : 0 : 0);
        this.connectorLinePath = 'M ' + this.x2 + ' ' + this.y1 + ' L ' + (this.x2 + this.x3) + ' ' + this.y1 + ' L ' + (this.x2 + this.x3) + ' ' + (this.y1 + this.y2) + ' L ' + this.x4 + ' ' + (this.y1 + this.y2);
        this.arrowPath = 'M ' + (this.x1 + adjustedX) + ' ' + this.y1 + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y1 - (4 + this.lineStroke)) + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y1 + 4 + this.lineStroke) + ' Z';
      }
      if (this.getParentPosition(data) === 'FFType4') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.x1 = data.parentEndPoint;
        this.x2 = data.childEndPoint + (data.milestoneChild ? 7 : 8);
        this.x3 = this.x2 + (data.milestoneChild ? 12 : 11);
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
        this.y2 = heightValue + this.taskLineValue + borderTopWidth + (this.parent.renderBaseline ? data.milestoneParent && !data.milestoneChild ? 10 : data.milestoneChild && !data.milestoneParent ? -12 : 0 : 0) - this.lineStroke + 1;
        var arrowX = this.parent.renderBaseline ? 0 : adjustedX;
        this.connectorLinePath = 'M ' + this.x2 + ' ' + this.y1 + ' L ' + this.x3 + ' ' + this.y1 + ' L ' + this.x3 + ' ' + (this.y1 + this.y2) + ' L ' + this.x1 + ' ' + (this.y1 + this.y2);
        this.arrowPath = 'M ' + (this.x2 - 8 + arrowX) + ' ' + this.y1 + ' L ' + (this.x2 + arrowX) + ' ' + (this.y1 - (4 + this.lineStroke)) + ' L ' + (this.x2 + arrowX) + ' ' + (this.y1 + 4 + this.lineStroke) + ' Z';
      }
      if (this.getParentPosition(data) === 'SFType4') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? -1 : 0;
        this.point1 = this.taskLineValue + heightValue + borderTopWidth - this.getconnectorLineGap(data) - (this.lineStroke - 1);
        this.point2 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1) - isMilestoneValue;
        this.x1 = data.parentLeft - 10;
        this.x2 = this.x1 + (data.childEndPoint - data.parentLeft + 18);
        this.x3 = this.x2 + (data.milestoneChild ? 16 : 11);
        this.y1 = this.point2 + (data.milestoneChild ? 2 : 0) + (this.parent.renderBaseline ? data.milestoneParent ? -5 : 0 : 0);
        this.y2 = this.y1 + this.point1 + (this.parent.renderBaseline ? data.milestoneChild && !data.milestoneParent ? -9 : data.milestoneParent && !data.milestoneChild ? 9 : 0 : 0);
        this.y3 = this.getconnectorLineGap(data);
        this.y4 = this.y2 - this.y2 % data.rowHeight;
        var arrowX = this.parent.renderBaseline ? 0 : adjustedX;
        this.connectorLinePath = 'M ' + this.x2 + ' ' + (this.y1 - adjustments['adjustY1']) + ' L ' + this.x3 + ' ' + (this.y1 - adjustments['adjustY1']) + ' L ' + this.x3 + ' ' + this.y4 + ' L ' + this.x1 + ' ' + this.y4 + ' L ' + this.x1 + ' ' + (this.y2 + this.y3) + ' L ' + (this.x1 + 11) + ' ' + (this.y2 + this.y3);
        this.arrowPath = 'M ' + (this.x2 - 8 + arrowX) + ' ' + (this.y1 - adjustments['adjustY1']) + ' L ' + (this.x2 + arrowX) + ' ' + (this.y1 - (4 + this.lineStroke) - adjustments['adjustY1']) + ' L ' + (this.x2 + arrowX) + ' ' + (this.y1 + 4 + this.lineStroke - adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'SFType3') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.point1 = data.parentLeft - (data.childEndPoint + (data.milestoneParent ? 23 : 20)) + 1;
        this.point2 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? childOverlapTopValue : data.childIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
        this.x1 = data.childEndPoint;
        this.x2 = this.x1 + (data.milestoneChild ? 9 : 8);
        this.x3 = this.x2 + (data.milestoneChild ? 17 : 11);
        this.y1 = this.point2;
        this.y2 = this.y1 + heightValue + borderTopWidth - (this.lineStroke - 1) + this.taskLineValue + (this.parent.renderBaseline ? data.milestoneChild && !data.milestoneParent ? -12 : data.milestoneParent && !data.milestoneChild ? 10 : 0 : 0);
        this.connectorLinePath = 'M ' + this.x2 + ' ' + this.y1 + ' L ' + this.x3 + ' ' + this.y1 + ' L ' + this.x3 + ' ' + this.y2 + ' L ' + (this.x3 + this.point1) + ' ' + this.y2;
        this.arrowPath = 'M ' + (this.x2 - 8 + adjustedX) + ' ' + this.y1 + ' L ' + (this.x2 + adjustedX) + ' ' + (this.y1 - (4 + this.lineStroke)) + ' L ' + (this.x2 + adjustedX) + ' ' + (this.y1 + 4 + this.lineStroke) + ' Z';
      }
      if (this.getParentPosition(data) === 'SFType1') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : data.milestoneChild ? 1 : 0;
        this.point1 = heightValue + borderTopWidth - this.getconnectorLineGap(data) + this.taskLineValue - this.lineStroke;
        this.point2 = this.getconnectorLineGap(data);
        this.x1 = data.parentLeft - 10;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1) - isMilestoneValue;
        this.x2 = data.childEndPoint - data.parentLeft + 31;
        this.y2 = this.y1 + this.point1;
        this.x3 = data.childEndPoint - data.parentLeft + 18;
        this.y3 = this.y2 - this.y2 % data.rowHeight;
        var arrowX = this.parent.renderBaseline ? 0 : adjustedX;
        this.connectorLinePath = 'M ' + (this.x1 + 11) + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y1 + ' L ' + this.x1 + ' ' + this.y3 + ' L ' + (this.x1 + this.x2) + ' ' + this.y3 + ' L ' + (this.x1 + this.x2) + ' ' + (this.y2 + this.point2 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x3) + ' ' + (this.y2 + this.point2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + this.x3 - 8 + arrowX) + ' ' + (this.y2 + this.point2 + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x3 + arrowX) + ' ' + (this.y2 + this.point2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + this.x3 + arrowX) + ' ' + (this.y2 + this.point2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      if (this.getParentPosition(data) === 'SFType2') {
        var adjustedX = adjustments['adjustX'] !== 0 ? adjustments['adjustX'] + 11 : adjustments['adjustX'];
        this.taskLineValue = this.parent.renderBaseline ? this.taskLineValue : 0;
        this.x1 = data.childEndPoint;
        this.y1 = this.parent.enableVirtualization ? rowPositionHeight : (!this.parent.allowTaskbarOverlap ? parentOverlapTopValue : data.parentIndex * data.rowHeight) + addTop + this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1);
        this.x2 = data.parentLeft - data.childEndPoint;
        this.y2 = this.y1 + heightValue + this.taskLineValue + borderTopWidth - this.lineStroke;
        this.connectorLinePath = 'M ' + (this.x1 + this.x2 + 1) + ' ' + this.y1 + ' L ' + (this.x1 + this.x2 - 10) + ' ' + this.y1 + ' L ' + (this.x1 + this.x2 - 10) + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + 8) + ' ' + (this.y2 + adjustments['adjustY1']);
        this.arrowPath = 'M ' + (this.x1 + adjustedX) + ' ' + (this.y2 + adjustments['adjustY1']) + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y2 - (4 + this.lineStroke) + adjustments['adjustY1']) + ' L ' + (this.x1 + 8 + adjustedX) + ' ' + (this.y2 + 4 + this.lineStroke + adjustments['adjustY1']) + ' Z';
      }
      this.connectorPath.setAttribute('d', this.connectorLinePath);
      this.arrowlinePath.setAttribute('d', this.arrowPath);
    }
    return this.groupObject.outerHTML;
  };
  /**
   * @param {IConnectorLineObject} data .
   * @param {string} type .
   * @param {number} heightValue .
   * @returns {number} .
   * @private
   */
  ConnectorLine.prototype.getPosition = function (data, type, heightValue) {
    var topPosition = 0;
    var lineHeight = 0;
    if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
      var isMilestoneParent = data.milestoneParent ? true : false;
      var isMilestone = data.milestoneChild ? true : false;
      var midPointParent = this.getTaskbarMidpoint(isMilestoneParent) - (this.lineStroke - 1);
      var midPoint = this.getTaskbarMidpoint(isMilestone) - (this.lineStroke - 1);
      var isParentIndex = data.parentIndexInCurrentView !== -1;
      var isChildIndex = data.childIndexInCurrentView !== -1;
      var lastRowIndex = this.parent.currentViewData.length - 1;
      if (type === 'SSType1' || type === 'SSType2' || type === 'FFType1' || type === 'FFType2' || type === 'SFType2') {
        topPosition = isParentIndex ? data.parentIndexInCurrentView * data.rowHeight + midPointParent : 0;
        lineHeight = isParentIndex && isChildIndex ? heightValue : isChildIndex ? data.childIndexInCurrentView * data.rowHeight + midPointParent : lastRowIndex * data.rowHeight + midPointParent;
      } else if (type === 'SSType3' || type === 'SSType4' || type === 'FSType4' || type === 'FFType3' || type === 'FFType4' || type === 'SFType4' || type === 'SFType3') {
        topPosition = isChildIndex ? data.childIndexInCurrentView * data.rowHeight + midPoint : 0;
        lineHeight = isParentIndex && isChildIndex ? heightValue : isParentIndex ? data.parentIndexInCurrentView * data.rowHeight + midPoint : lastRowIndex * data.rowHeight + midPoint;
      } else if (type === 'FSType3') {
        topPosition = isChildIndex ? data.childIndexInCurrentView * data.rowHeight + midPointParent : 0;
        lineHeight = isParentIndex && isChildIndex ? heightValue : isParentIndex ? data.parentIndexInCurrentView * data.rowHeight + midPoint : lastRowIndex * data.rowHeight + midPointParent;
      } else if (type === 'SFType1' || type === 'FSType1' || type === 'FSType2') {
        topPosition = isParentIndex ? data.parentIndexInCurrentView * data.rowHeight + midPoint : 0;
        lineHeight = isParentIndex && isChildIndex ? heightValue : isChildIndex ? data.childIndexInCurrentView * data.rowHeight + midPoint : lastRowIndex * data.rowHeight + midPoint;
      }
    }
    return {
      top: topPosition,
      height: lineHeight
    };
  };
  /**
   * @returns {void} .
   * @private
   */
  ConnectorLine.prototype.createConnectorLineTooltipTable = function () {
    this.tooltipTable = createElement('table', {
      className: '.e-tooltiptable',
      styles: 'margin-top:0px',
      attrs: {
        'cellspacing': '2px',
        'cellpadding': '2px'
      }
    });
    var tooltipBody = createElement('tbody');
    tooltipBody.innerHTML = '';
    this.tooltipTable.appendChild(tooltipBody);
  };
  /**
   * @param {string} fromTaskName .
   * @param {string} fromPredecessorText .
   * @param {string} toTaskName .
   * @param {string} toPredecessorText .
   * @returns {string} .
   * @private
   */
  ConnectorLine.prototype.getConnectorLineTooltipInnerTd = function (fromTaskName, fromPredecessorText, toTaskName, toPredecessorText) {
    var innerTd = '<tr  id="fromPredecessor"><td style="padding: 2px;">' + this.parent.localeObj.getConstant('from') + '</td><td> ';
    innerTd = innerTd + fromTaskName + ' </td><td style="padding: 2px;"> ' + this.parent.localeObj.getConstant(fromPredecessorText) + ' </td> </tr>';
    innerTd = innerTd + '<tr id="toPredecessor"><td style="padding: 2px;">' + this.parent.localeObj.getConstant('to') + '</td><td> ' + toTaskName;
    innerTd = innerTd + ' </td><td style="padding: 2px;"> ' + this.parent.localeObj.getConstant(toPredecessorText) + ' </td></tr></tbody><table>';
    return innerTd;
  };
  /**
   * Generate aria-label for connectorline
   *
   * @param {IConnectorLineObject} data .
   * @returns {string} .
   * @private
   */
  ConnectorLine.prototype.generateAriaLabel = function (data) {
    var type = data.type;
    var updatedRecords = this.expandedRecords;
    var fromName = updatedRecords[data.parentIndex].ganttProperties.taskName;
    var toName = updatedRecords[data.childIndex].ganttProperties.taskName;
    var start = this.parent.localeObj.getConstant('start');
    var finish = this.parent.localeObj.getConstant('finish');
    var value = '';
    if (type === 'FS') {
      value = fromName + ' ' + finish + ' to ' + toName + ' ' + start;
    } else if (type === 'FF') {
      value = fromName + ' ' + finish + ' to ' + toName + ' ' + finish;
    } else if (type === 'SS') {
      value = fromName + ' ' + start + ' to ' + toName + ' ' + start;
    } else {
      value = fromName + ' ' + start + ' to ' + toName + ' ' + finish;
    }
    return value;
  };
  /**
   * To get the record based on the predecessor value
   *
   * @param {string} id .
   * @returns {IGanttData} .
   * @private
   */
  ConnectorLine.prototype.getRecordByID = function (id) {
    if (isNullOrUndefined(id)) {
      return null;
    }
    if (this.parent.pdfExportModule && this.parent.pdfExportModule.isPdfExport && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      var a = this.parent.pdfExportModule.helper.beforeSinglePageExport['cloneFlatData'].filter(function (data) {
        return data.ganttProperties.taskId.toString() === id.toString();
      })[0];
      return a;
    } else {
      return this.parent.viewType === 'ResourceView' ? this.parent.flatData[this.parent.getTaskIds().indexOf('T' + id.toString())] : this.parent.flatData[this.parent.ids.indexOf(id.toString())];
    }
  };
  /**
   * Method to remove connector line from DOM
   *
   * @param {IGanttData[] | object} records .
   * @returns {void} .
   * @private
   */
  ConnectorLine.prototype.removePreviousConnectorLines = function (records) {
    var isObjectType;
    if (isObject(records) === true) {
      isObjectType = true;
    } else {
      isObjectType = false;
    }
    var length = isObjectType ? Object.keys(records).length : records.length;
    var keys = Object.keys(records);
    for (var i = 0; i < length; i++) {
      var data = void 0;
      if (isObjectType) {
        var uniqueId = keys[i];
        data = records[uniqueId];
      } else {
        data = records[i];
      }
      var predecessors = data.ganttProperties && data.ganttProperties.predecessor;
      if (predecessors && predecessors.length > 0) {
        for (var pre = 0; pre < predecessors.length; pre++) {
          var lineId = 'parent' + predecessors[pre].from + 'child' + predecessors[pre].to;
          this.removeConnectorLineById(lineId);
        }
      }
    }
  };
  /**
   * @param {string} id .
   * @returns {void} .
   * @private
   */
  ConnectorLine.prototype.removeConnectorLineById = function (id) {
    var element = this.parent.connectorLineModule.dependencyViewContainer.querySelector('#ConnectorLine' + id.replace(/([.])/g, '\\$1'));
    if (!isNullOrUndefined(element)) {
      remove(element);
    }
  };
  return ConnectorLine;
}();

/**
 * Splitter module is used to define the splitter position in Gantt layout.
 */
var Splitter = /** @__PURE__ @class */function () {
  function Splitter(ganttObj) {
    this.parent = ganttObj;
    this.parent.on('destroy', this.destroy, this);
  }
  /**
   * @returns {void} .
   * @private
   */
  Splitter.prototype.renderSplitter = function () {
    var _this = this;
    var splitterPosition = this.calculateSplitterPosition(this.parent.splitterSettings);
    this.parent.splitterElement = createElement('div', {
      className: splitter
    });
    this.parent.treeGridPane = createElement('div', {
      className: treeGridPane
    });
    this.parent.chartPane = createElement('div', {
      className: ganttChartPane
    });
    addClass([this.parent.chartPane], 'e-droppable');
    if (this.parent.enableRtl) {
      this.parent.splitterElement.appendChild(this.parent.chartPane);
      this.parent.splitterElement.appendChild(this.parent.treeGridPane);
    } else {
      this.parent.splitterElement.appendChild(this.parent.treeGridPane);
      this.parent.splitterElement.appendChild(this.parent.chartPane);
    }
    this.splitterObject = new Splitter$1({
      height: null,
      width: this.parent.ganttWidth.toString(),
      enableRtl: this.parent.enableRtl,
      separatorSize: this.parent.splitterSettings.separatorSize,
      paneSettings: [{
        resizable: true,
        size: splitterPosition,
        min: this.getSpliterPositionInPercentage(this.parent.splitterSettings.minimum)
      }, {
        resizable: true
      }],
      orientation: 'Horizontal',
      resizeStart: function (args) {
        if (_this.parent.contextMenuModule && _this.parent.contextMenuModule.isOpen) {
          _this.parent.contextMenuModule.contextMenu.close();
        }
        var leftPane = args.pane[0];
        var rightPane = args.pane[1];
        _this.splitterPreviousPositionGrid = leftPane.scrollWidth + 1 + 'px';
        _this.splitterPreviousPositionChart = rightPane.scrollWidth + 1 + 'px';
        var callBackPromise = new Deferred();
        _this.parent.trigger('splitterResizeStart', args, function (resizeStartArgs) {
          callBackPromise.resolve(resizeStartArgs);
        });
        return callBackPromise;
      },
      resizing: function (args) {
        _this.parent.trigger('splitterResizing', args);
        if (_this.parent.timelineModule.isZoomToFit) {
          _this.parent.timelineModule.updateTimelineAfterZooming(_this.parent.timelineModule.timelineEndDate, true);
        }
      },
      resizeStop: function (args) {
        _this.parent['calculateDimensions']();
        var leftPane = args.pane[0];
        _this.splitterPreviousPositionGrid = leftPane.scrollWidth + 1 + 'px';
        _this.splitterObject.paneSettings[0].size = _this.getSpliterPositionInPercentage(_this.splitterPreviousPositionGrid);
        _this.parent.splitterSettings.position = _this.splitterObject.paneSettings[0].size;
        _this.splitterObject.paneSettings[1].size = _this.parent.ganttWidth - parseInt(_this.splitterPreviousPositionGrid, 10) - 4 + 'px';
        var callBackPromise = new Deferred();
        _this.parent.trigger('splitterResized', args, function (splitterResizedArgs) {
          if (splitterResizedArgs.cancel === true) {
            _this.splitterObject.paneSettings[0].size = null;
            _this.splitterObject.paneSettings[0].size = _this.getSpliterPositionInPercentage(_this.splitterPreviousPositionGrid);
            _this.parent.splitterSettings.position = _this.splitterObject.paneSettings[0].size;
            _this.splitterObject.paneSettings[1].size = null;
            _this.splitterObject.paneSettings[1].size = _this.getSpliterPositionInPercentage(_this.splitterPreviousPositionChart);
          }
          if (isNullOrUndefined(_this.parent.projectEndDate)) {
            _this.parent.timelineModule.updateTimelineAfterZooming(_this.parent.timelineModule.timelineEndDate, true);
          }
          callBackPromise.resolve(splitterResizedArgs);
        });
        return callBackPromise;
      }
    });
    this.parent.element.appendChild(this.parent.splitterElement);
    this.splitterObject.appendTo(this.parent.splitterElement);
    var splitterLeftPane = this.splitterObject.element.querySelector('.e-split-bar');
    if (splitterLeftPane) {
      var numericValue = parseFloat(splitterPosition.replace('%', ''));
      var ariaValueNow = Math.min(100, Math.max(0, numericValue));
      splitterLeftPane.setAttribute('aria-valuenow', ariaValueNow.toString());
    }
  };
  /**
   * @param {SplitterSettingsModel} splitter .
   * @returns {string} .
   * @private
   */
  Splitter.prototype.calculateSplitterPosition = function (splitter) {
    if (!isNullOrUndefined(this.splitterObject) && this.parent.enablePersistence) {
      return this.splitterObject.paneSettings[0].size;
    }
    if (splitter.view === 'Grid') {
      return this.parent.enableRtl ? '0%' : '100%';
    } else if (splitter.view === 'Chart') {
      return this.parent.enableRtl ? '100%' : '0%';
    } else {
      if (!isNullOrUndefined(splitter.position) && splitter.position !== '') {
        return this.getSpliterPositionInPercentage(splitter.position);
      } else if (!isNullOrUndefined(splitter.columnIndex) && splitter.columnIndex >= 0) {
        if (splitter.columnIndex * 150 < this.parent.ganttWidth || !this.parent.element.classList.contains('e-device')) {
          return this.getSpliterPositionInPercentage(this.getTotalColumnWidthByIndex(splitter.columnIndex).toString() + 'px');
        } else {
          return this.getSpliterPositionInPercentage(splitter.columnIndex * 130 + 'px');
        }
      } else {
        var ganttWidth = this.parent.ganttWidth;
        var width = ganttWidth - 250;
        var widthAsString = width + 'px';
        return this.parent.enableRtl ? this.getSpliterPositionInPercentage(widthAsString) : this.getSpliterPositionInPercentage('250px');
      }
    }
  };
  /**
   * @param {string} position .
   * @returns {string} .
   */
  Splitter.prototype.getSpliterPositionInPercentage = function (position) {
    var value = !isNullOrUndefined(position) && position !== '' ? position : null;
    if (!isNullOrUndefined(value)) {
      if (position.indexOf('px') !== -1) {
        var intValue = parseInt(position, 10);
        value = intValue / this.parent.ganttWidth * 100 <= 100 ? intValue / this.parent.ganttWidth * 100 + '%' : '25%';
      } else {
        if (this.parent.enableRtl) {
          var newPosition = 100 - parseFloat(position);
          value = newPosition + '%';
        } else {
          value = position.indexOf('%') === -1 ? position + '%' : position;
        }
      }
    }
    return value;
  };
  /**
   * @param {number} index .
   * @returns {number} .
   */
  Splitter.prototype.getTotalColumnWidthByIndex = function (index) {
    var width = 0;
    var ganttWidth = this.parent.ganttWidth;
    var originalArray = this.parent.ganttColumns;
    var newArray = originalArray.filter(function (obj) {
      return obj.visible === undefined || obj.visible === true;
    });
    var deepCopiedArray = newArray.map(function (obj) {
      return Object.assign({}, obj);
    });
    var tr = deepCopiedArray;
    index = tr.length > index ? index : tr.length;
    if (this.parent.enableRtl) {
      for (var column = 0; column < index; column++) {
        width += parseInt(tr[column].width, 10);
      }
      width = ganttWidth - width;
    } else {
      for (var column = 0; column < index; column++) {
        width += parseInt(tr[column].width, 10);
      }
    }
    return width;
  };
  /**
   * @returns {void} .
   * @private
   */
  Splitter.prototype.updateSplitterPosition = function () {
    this.splitterObject.separatorSize = this.parent.splitterSettings.separatorSize;
    var splitterPosition = this.calculateSplitterPosition(this.parent.splitterSettings);
    this.splitterObject.paneSettings[0].min = this.getSpliterPositionInPercentage(this.parent.splitterSettings.minimum);
    this.splitterObject.dataBind();
    this.splitterObject.paneSettings[0].size = splitterPosition;
    if (isNullOrUndefined(this.parent.projectEndDate)) {
      this.parent.timelineModule.updateTimelineAfterZooming(this.parent.timelineModule.timelineEndDate, true);
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  Splitter.prototype.triggerCustomResizedEvent = function () {
    var pane1 = this.splitterObject.element.querySelectorAll('.e-pane')[0];
    var pane2 = this.splitterObject.element.querySelectorAll('.e-pane')[1];
    var eventArgs = {
      event: null,
      element: this.splitterObject.element,
      pane: [pane1, pane2],
      index: [0, 1],
      separator: this.splitterObject.element.querySelector('.e-split-bar'),
      paneSize: [pane1.offsetWidth, pane2.offsetWidth]
    };
    this.parent.trigger('splitterResized', eventArgs);
    if (eventArgs.cancel === true) {
      this.splitterObject.paneSettings[0].size = this.getSpliterPositionInPercentage(this.splitterPreviousPositionGrid);
      this.splitterObject.paneSettings[1].size = this.getSpliterPositionInPercentage(this.splitterPreviousPositionChart);
    }
  };
  Splitter.prototype.destroy = function () {
    this.splitterObject.destroy();
    this.parent.off('destroy', this.destroy);
  };
  return Splitter;
}();

/**
 * File for handling tooltip in Gantt.
 */
var Tooltip = /** @__PURE__ @class */function () {
  function Tooltip(gantt) {
    this.parent = gantt;
    this.createTooltip();
    this.parent.on('destroy', this.destroy, this);
  }
  /**
   * To create tooltip.
   *
   * @returns {void} .
   * @private
   */
  Tooltip.prototype.createTooltip = function () {
    this.toolTipObj = new Tooltip$1();
    this.toolTipObj.target = '.e-header-cell-label, .e-gantt-child-taskbar,' + '.e-gantt-parent-taskbar, .e-gantt-milestone, .e-gantt-unscheduled-taskbar' + '.e-event-markers, .e-baseline-bar, .e-event-markers,' + '.e-connector-line-container, .e-indicator-span, .e-notes-info, .e-gantt-manualparent-milestone,' + '.e-taskbar-left-resizer, .e-taskbar-right-resizer, .e-baseline-gantt-milestone-container, .e-gantt-manualparenttaskbar';
    this.toolTipObj.position = 'BottomCenter';
    this.toolTipObj.openDelay = 700;
    this.toolTipObj.enableRtl = this.parent.enableRtl;
    this.toolTipObj.enableHtmlSanitizer = this.parent.enableHtmlSanitizer;
    this.toolTipObj.cssClass = ganttTooltip;
    this.toolTipObj.animation = {
      open: {
        effect: 'None',
        delay: 0
      },
      close: {
        effect: 'None',
        delay: 0
      }
    };
    this.toolTipObj.afterOpen = this.updateTooltipPosition.bind(this);
    this.toolTipObj.showTipPointer = false;
    this.toolTipObj.beforeRender = this.tooltipBeforeRender.bind(this);
    this.toolTipObj.afterClose = this.tooltipCloseHandler.bind(this);
    this.toolTipObj.isStringTemplate = true;
    this.toolTipObj.appendTo(this.parent.element);
  };
  Tooltip.prototype.tooltipBeforeRender = function (args) {
    var parent = this.parent;
    if (parent.isOnEdit) {
      args.cancel = true;
      return;
    }
    var element;
    var row = closest(args.target, 'div.' + taskBarMainContainer);
    if (!isNullOrUndefined(row)) {
      element = args.target;
    } else {
      element = parentsUntil(args.target, chartRowCell);
    }
    var data;
    var argsData = {
      data: {},
      args: args,
      cancel: false,
      content: ''
    };
    if (args.target.classList.contains('e-header-cell-label')) {
      if (parent.timelineSettings.showTooltip) {
        argsData.content = this.toolTipObj.content = parent.tooltipModule.getTooltipContent('timeline', data, parent, args);
      } else {
        args.cancel = true;
      }
    } else {
      if (parent.tooltipSettings.showTooltip) {
        if (element) {
          argsData.data = parent.ganttChartModule.getRecordByTaskBar(element);
          data = argsData.data;
        }
        if (args.target.classList.contains('e-gantt-child-taskbar') || args.target.classList.contains('e-gantt-parent-taskbar') || args.target.classList.contains('e-gantt-milestone') || args.target.classList.contains('e-gantt-unscheduled-taskbar') || args.target.classList.contains('e-taskbar-left-resizer') || args.target.classList.contains('e-taskbar-right-resizer') || args.target.classList.contains('e-gantt-manualparenttaskbar') && parent.tooltipSettings.taskbar) {
          var taskbarTemplateNode = void 0;
          if (parent.tooltipSettings.taskbar) {
            taskbarTemplateNode = parent.tooltipModule.templateCompiler(parent.tooltipSettings.taskbar, parent, data, 'TooltipTaskbarTemplate');
          }
          var tooltipTemplate = document.createElement('div');
          if (taskbarTemplateNode) {
            append(taskbarTemplateNode, tooltipTemplate);
          }
          argsData.content = this.toolTipObj.content = taskbarTemplateNode ? tooltipTemplate : data ? parent.tooltipModule.getTooltipContent(data.ganttProperties.isMilestone ? 'milestone' : 'taskbar', data, parent, args) : '';
        } else if (args.target.classList.contains('e-baseline-bar') || args.target.classList.contains('e-baseline-gantt-milestone-container')) {
          var baseLineTemplateNode = void 0;
          if (parent.tooltipSettings.baseline) {
            baseLineTemplateNode = parent.tooltipModule.templateCompiler(parent.tooltipSettings.baseline, parent, data, 'TooltipBaselineTemplate');
          }
          var baselineTemplate = document.createElement('div');
          if (baseLineTemplateNode) {
            append(baseLineTemplateNode, baselineTemplate);
          }
          argsData.content = this.toolTipObj.content = baseLineTemplateNode ? baselineTemplate : data ? parent.tooltipModule.getTooltipContent(data.ganttProperties.isMilestone ? 'milestone' : 'baseline', data, parent, args) : '';
        } else if (args.target.classList.contains('e-event-markers')) {
          argsData.content = this.toolTipObj.content = parent.tooltipModule.getTooltipContent('marker', data, parent, args);
        } else if (args.target.classList.contains('e-connector-line-container')) {
          var dependencyLineTemplateNode = void 0;
          parent.tooltipModule.predecessorTooltipData = parent.tooltipModule.getPredecessorTooltipData(args);
          argsData.data = this.predecessorTooltipData;
          if (parent.tooltipSettings.connectorLine) {
            dependencyLineTemplateNode = parent.tooltipModule.templateCompiler(parent.tooltipSettings.connectorLine, parent, parent.tooltipModule.predecessorTooltipData, 'TooltipConnectorLineTemplate');
          }
          argsData.content = this.toolTipObj.content = dependencyLineTemplateNode ? dependencyLineTemplateNode[0] : parent.tooltipModule.getTooltipContent('connectorLine', data, parent, args);
        } else if (args.target.classList.contains('e-indicator-span')) {
          argsData.content = this.toolTipObj.content = parent.tooltipModule.getTooltipContent('indicator', data, parent, args);
          if (isNullOrUndefined(argsData.content)) {
            args.cancel = true;
          }
        } else if (args.target.classList.contains('e-notes-info')) {
          var ganttData = this.parent.ganttChartModule.getRecordByTarget(args.event);
          argsData.content = this.toolTipObj.content = ganttData.ganttProperties.notes;
          if (isNullOrUndefined(argsData.content)) {
            args.cancel = true;
          }
        } else if (args.target.classList.contains('e-gantt-manualparenttaskbar')) {
          argsData.content = this.toolTipObj.content = parent.tooltipModule.getTooltipContent('manualtaskbar', data, parent, args);
          if (isNullOrUndefined(argsData.content)) {
            args.cancel = true;
          }
        } else if (args.target.classList.contains('e-gantt-manualparent-milestone')) {
          argsData.content = this.toolTipObj.content = parent.tooltipModule.getTooltipContent('manualmilestone', data, parent, args);
          if (isNullOrUndefined(argsData.content)) {
            args.cancel = true;
          }
        }
      } else {
        args.cancel = true;
      }
    }
    if (args.cancel === false) {
      var callBackPromise_1 = new Deferred();
      parent.trigger('beforeTooltipRender', argsData, function (argData) {
        callBackPromise_1.resolve(argData);
        if (argData.cancel) {
          args.cancel = true;
        }
      });
      this.toolTipObj.content = argsData.content;
      if (!this.parent.isAdaptive && args.event.type === 'mouseover') {
        this.currentTarget = args.target;
        EventHandler.add(this.currentTarget, 'mousemove', this.mouseMoveHandler.bind(this));
      }
      this.parent.renderTemplates();
      return callBackPromise_1;
    }
  };
  // eslint-disable-next-line
  Tooltip.prototype.tooltipCloseHandler = function (args) {
    this.tooltipMouseEvent = null;
    if (!this.parent.isAdaptive && !isNullOrUndefined(this.currentTarget)) {
      EventHandler.remove(this.currentTarget, 'mousemove', this.mouseMoveHandler);
    }
    this.currentTarget = null;
  };
  Tooltip.prototype.mouseMoveHandler = function (e) {
    this.tooltipMouseEvent = e;
  };
  /**
   * Method to update tooltip position
   *
   * @param {TooltipEventArgs} args .
   * @returns {void} .
   */
  Tooltip.prototype.updateTooltipPosition = function (args) {
    args.element.style.visibility = 'visible';
    var parentWithZoomStyle = this.parent.element.closest('[style*="zoom"]');
    if (isNullOrUndefined(parentWithZoomStyle)) {
      if (isNullOrUndefined(this.tooltipMouseEvent) || args.target.classList.contains('e-notes-info')) {
        return;
      }
      var postion = this.getPointorPosition(this.tooltipMouseEvent);
      var containerPosition = this.parent.getOffsetRect(this.parent.chartPane);
      var topEnd = containerPosition.top + this.parent.chartPane.offsetHeight;
      var leftEnd = containerPosition.left + this.parent.chartPane.offsetWidth;
      var tooltipPositionX = postion.x;
      var tooltipPositionY = postion.y;
      if (leftEnd < tooltipPositionX + args.element.offsetWidth + 10) {
        while (leftEnd < tooltipPositionX + args.element.offsetWidth + 10) {
          tooltipPositionX = leftEnd - args.element.offsetWidth - 10;
          args.element.style.left = tooltipPositionX + 'px';
        }
      } else {
        tooltipPositionX = tooltipPositionX + 10;
        args.element.style.left = tooltipPositionX + 'px';
      }
      if (window.innerHeight < args.element.offsetHeight + tooltipPositionY) {
        tooltipPositionY = tooltipPositionY - args.element.offsetHeight - 10;
      }
      if (topEnd < tooltipPositionY + args.element.offsetHeight + 20) {
        tooltipPositionY = tooltipPositionY - args.element.offsetHeight - 10;
      } else {
        tooltipPositionY = tooltipPositionY + 10;
      }
      args.element.style.top = tooltipPositionY + 'px';
    }
  };
  /**
   * Method to get mouse pointor position
   *
   * @param {Event} e .
   * @returns {number} .
   */
  Tooltip.prototype.getPointorPosition = function (e) {
    var posX;
    var posY;
    if (!isNullOrUndefined(getValue('pageX', e)) || !isNullOrUndefined(getValue('pageY', e))) {
      posX = getValue('pageX', e);
      posY = getValue('pageY', e);
    } else if (!isNullOrUndefined(getValue('clientX', e)) || !isNullOrUndefined(getValue('clientY', e))) {
      posX = getValue('clientX', e) + document.body.scrollLeft + document.documentElement.scrollLeft;
      posY = getValue('clientY', e) + document.body.scrollTop + document.documentElement.scrollTop;
    }
    return {
      x: posX,
      y: posY
    };
  };
  /**
   *  Getting tooltip content for different elements
   *
   * @param {string} elementType .
   * @param {IGanttData} ganttData .
   * @param {Gantt} parent .
   * @param {TooltipEventArgs} args .
   * @returns {string | Function} .
   */
  Tooltip.prototype.getTooltipContent = function (elementType, ganttData, parent, args) {
    var content;
    var data;
    var taskName;
    if (ganttData) {
      data = ganttData.ganttProperties;
      var taskNameValue = data.taskName;
      if (this.parent.enableHtmlSanitizer && typeof taskNameValue === 'string') {
        taskNameValue = SanitizeHtmlHelper.sanitize(taskNameValue);
      }
      taskName = !isNullOrUndefined(taskNameValue) ? '<tr class = "e-gantt-tooltip-rowcell"><td colspan="3">' + (this.parent.disableHtmlEncode ? taskNameValue.replace(/</g, '&lt;').replace(/>/g, '&gt;') : taskNameValue) + '</td></tr>' : '';
    }
    switch (elementType) {
      case 'milestone':
        {
          var milestoneStartDate = void 0;
          if (args.target.className.includes('e-baseline-gantt-milestone-container') && !isNullOrUndefined(data.baselineStartDate)) {
            milestoneStartDate = data.baselineStartDate;
          } else if (!isNullOrUndefined(data.startDate)) {
            milestoneStartDate = data.startDate;
          }
          var sDateValue = this.parent.getFormatedDate(milestoneStartDate, this.parent.getDateFormat());
          if (this.parent.enableHtmlSanitizer && typeof sDateValue === 'string') {
            sDateValue = SanitizeHtmlHelper.sanitize(sDateValue);
          }
          var sDate_1 = !isNullOrUndefined(milestoneStartDate) ? '<tr><td class = "e-gantt-tooltip-label"> Date</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + sDateValue + '</td></tr>' : '';
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody>' + taskName + sDate_1 + '</tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
          break;
        }
      case 'taskbar':
        {
          var scheduledTask = !ganttData.hasChildRecords || data.isAutoSchedule ? true : false;
          var startDateValue = this.parent.getFormatedDate(scheduledTask ? data.startDate : data.autoStartDate, this.parent.getDateFormat());
          var endDateValue = this.parent.getFormatedDate(scheduledTask ? data.endDate : data.autoEndDate, this.parent.getDateFormat());
          var durationValue = this.parent.getDurationString(scheduledTask ? data.duration : data.autoDuration, data.durationUnit);
          var progressValue = data.progress;
          if (this.parent.enableHtmlSanitizer) {
            startDateValue = typeof startDateValue === 'string' ? SanitizeHtmlHelper.sanitize(startDateValue) : startDateValue;
            endDateValue = typeof endDateValue === 'string' ? SanitizeHtmlHelper.sanitize(endDateValue) : endDateValue;
            durationValue = SanitizeHtmlHelper.sanitize(durationValue);
          }
          var startDate_1 = data.startDate ? '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant(scheduledTask ? 'startDate' : 'subTasksStartDate') + '</td><td style="padding: 2px;">:</td>' + '<td class = "e-gantt-tooltip-value"> ' + startDateValue + '</td></tr>' : '';
          var endDate_1 = data.endDate ? '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant(scheduledTask ? 'endDate' : 'subTasksEndDate') + '</td><td style="padding: 2px;">:</td>' + '<td class = "e-gantt-tooltip-value">' + endDateValue + '</td></tr>' : '';
          var duration_1 = !isNullOrUndefined(data.duration) ? '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('duration') + '</td><td style="padding: 2px;">:</td>' + '<td class = "e-gantt-tooltip-value"> ' + durationValue + '</td></tr>' : '';
          var progress_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('progress') + '</td><td style="padding: 2px;">:</td><td>' + progressValue + '</td></tr>';
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody>' + taskName + startDate_1 + endDate_1 + duration_1 + progress_1 + '</tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
          break;
        }
      case 'baseline':
        {
          var baselineStartDateValue_1 = this.parent.getFormatedDate(data.baselineStartDate, this.parent.getDateFormat());
          var baselineEndDateValue_1 = this.parent.getFormatedDate(data.baselineEndDate, this.parent.getDateFormat());
          if (this.parent.enableHtmlSanitizer) {
            baselineStartDateValue_1 = SanitizeHtmlHelper.sanitize(baselineStartDateValue_1);
            baselineEndDateValue_1 = SanitizeHtmlHelper.sanitize(baselineEndDateValue_1);
          }
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody>' + taskName + '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('baselineStartDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + baselineStartDateValue_1 + '</td></tr><tr>' + '<td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('baselineEndDate') + '</td><td>:</td><td class = "e-gantt-tooltip-value">' + baselineEndDateValue_1 + '</td></tr></tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp, this);
          break;
        }
      case 'marker':
        {
          var markerTooltipElement = parent.tooltipModule.getMarkerTooltipData(args);
          var markerTooltipElementValue_1 = this.parent.getFormatedDate(this.parent.dateValidationModule.getDateFromFormat(markerTooltipElement.day), this.parent.getDateFormat());
          var markerLabel_1 = markerTooltipElement.label ? markerTooltipElement.label : '';
          if (this.parent.enableHtmlSanitizer) {
            markerLabel_1 = SanitizeHtmlHelper.sanitize(markerLabel_1);
            markerTooltipElementValue_1 = SanitizeHtmlHelper.sanitize(markerTooltipElementValue_1);
          }
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody><tr><td>' + markerTooltipElementValue_1 + '</td></tr><tr><td>' + (this.parent.disableHtmlEncode ? markerLabel_1.replace(/</g, '&lt;').replace(/>/g, '&gt;') : markerLabel_1) + '</td></tr></tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp, this);
          break;
        }
      case 'connectorLine':
        {
          var fromNameValue_1 = parent.tooltipModule.predecessorTooltipData.fromName;
          var fromIdValue_1 = parent.tooltipModule.predecessorTooltipData.fromId;
          var toNameValue_1 = parent.tooltipModule.predecessorTooltipData.toName;
          var toIdValue_1 = parent.tooltipModule.predecessorTooltipData.toId;
          var linkTextValue_1 = parent.tooltipModule.predecessorTooltipData.linkText;
          var offsetStringValue_1 = parent.tooltipModule.predecessorTooltipData.offsetString;
          if (this.parent.enableHtmlSanitizer) {
            fromNameValue_1 = SanitizeHtmlHelper.sanitize(fromNameValue_1);
            fromIdValue_1 = SanitizeHtmlHelper.sanitize(fromIdValue_1);
            toNameValue_1 = SanitizeHtmlHelper.sanitize(toNameValue_1);
            toIdValue_1 = SanitizeHtmlHelper.sanitize(toIdValue_1);
            linkTextValue_1 = SanitizeHtmlHelper.sanitize(linkTextValue_1);
            offsetStringValue_1 = SanitizeHtmlHelper.sanitize(offsetStringValue_1);
          }
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody><tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('from') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + (this.parent.disableHtmlEncode ? fromNameValue_1.replace(/</g, '&lt;').replace(/>/g, '&gt;') : fromNameValue_1) + ' (' + (this.parent.disableHtmlEncode ? typeof fromIdValue_1 === 'string' ? fromIdValue_1.replace(/</g, '&lt;').replace(/>/g, '&gt;') : fromIdValue_1 : fromIdValue_1) + ')' + '</td></tr><tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('to') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + (this.parent.disableHtmlEncode ? toNameValue_1.replace(/</g, '&lt;').replace(/>/g, '&gt;') : toNameValue_1) + ' (' + toIdValue_1 + ')' + '</td></tr><tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('taskLink') + '</td><td>:</td><td class = "e-gantt-tooltip-value"> ' + linkTextValue_1 + '</td></tr><tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('lag') + '</td><td>:</td><td class = "e-gantt-tooltip-value">' + offsetStringValue_1 + '</td></tr></tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp, this);
          break;
        }
      case 'indicator':
        if (args.target.title.length) {
          var titleValue_1 = args.target.title;
          if (this.parent.enableHtmlSanitizer && typeof titleValue_1 === 'string') {
            titleValue_1 = SanitizeHtmlHelper.sanitize(titleValue_1);
          }
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody><tr>' + titleValue_1 + '</tr></tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
        }
        break;
      case 'timeline':
        {
          var timlineTitleValue_1 = args.target.title;
          if (this.parent.enableHtmlSanitizer && typeof timlineTitleValue_1 === 'string') {
            timlineTitleValue_1 = SanitizeHtmlHelper.sanitize(timlineTitleValue_1);
          }
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody><tr>' + timlineTitleValue_1 + '</tr></tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
          break;
        }
      case 'manualtaskbar':
        {
          var autoStartDateValue = this.parent.getFormatedDate(data.autoStartDate, this.parent.getDateFormat());
          var autoEndDateValue = this.parent.getFormatedDate(data.autoEndDate, this.parent.getDateFormat());
          var durationUnitValue = this.parent.getDurationString(data.duration, data.durationUnit);
          var manualStartDateValue = this.parent.getFormatedDate(data.startDate, this.parent.getDateFormat());
          var manualEndDateValue = this.parent.getFormatedDate(data.endDate, this.parent.getDateFormat());
          if (this.parent.enableHtmlSanitizer) {
            autoStartDateValue = SanitizeHtmlHelper.sanitize(autoStartDateValue);
            autoEndDateValue = SanitizeHtmlHelper.sanitize(autoEndDateValue);
            durationUnitValue = SanitizeHtmlHelper.sanitize(durationUnitValue);
            manualStartDateValue = SanitizeHtmlHelper.sanitize(manualStartDateValue);
            manualEndDateValue = SanitizeHtmlHelper.sanitize(manualEndDateValue);
          }
          var autoStartDate_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('subTasksStartDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value"> ' + autoStartDateValue + '</td></tr>';
          var autoEndDate_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('subTasksEndDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + autoEndDateValue + '</td></tr>';
          var durationValue_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('duration') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value"> ' + durationUnitValue + '</td></tr>';
          var manualStartDate_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('startDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value"> ' + manualStartDateValue + '</td></tr>';
          var manualEndDate_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('endDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + manualEndDateValue + '</td></tr>';
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody>' + taskName + manualStartDate_1 + autoStartDate_1 + manualEndDate_1 + autoEndDate_1 + durationValue_1 + '</tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
          break;
        }
      case 'manualmilestone':
        {
          var autoStartValue = this.parent.getFormatedDate(data.autoStartDate, this.parent.getDateFormat());
          var autoEndValue = this.parent.getFormatedDate(data.autoEndDate, this.parent.getDateFormat());
          var dateValue = this.parent.getFormatedDate(data.startDate, this.parent.getDateFormat());
          if (this.parent.enableHtmlSanitizer) {
            autoStartValue = SanitizeHtmlHelper.sanitize(autoStartValue);
            autoEndValue = SanitizeHtmlHelper.sanitize(autoEndValue);
            dateValue = SanitizeHtmlHelper.sanitize(dateValue);
          }
          var autoStart_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('subTasksStartDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value"> ' + autoStartValue + '</td></tr>';
          var autoEnd_1 = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('subTasksEndDate') + '</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + autoEndValue + '</td></tr>';
          var date_1 = '<tr><td class = "e-gantt-tooltip-label"> Date</td><td>:</td>' + '<td class = "e-gantt-tooltip-value">' + dateValue + '</tr>';
          var contentTemp = function () {
            return '<table class = "e-gantt-tooltiptable"><tbody>' + taskName + date_1 + autoStart_1 + autoEnd_1 + '</tbody></table>';
          };
          content = initializeCSPTemplate(contentTemp);
          break;
        }
    }
    return content;
  };
  /**
   * To get the details of an event marker.
   *
   * @param {TooltipEventArgs} args .
   * @returns {EventMarkerModel} .
   * @private
   */
  Tooltip.prototype.getMarkerTooltipData = function (args) {
    var markerTooltipId = args.target.id.match(/\d+/g);
    var markerTooltipElement = this.parent.eventMarkers[Number(markerTooltipId)];
    return markerTooltipElement;
  };
  /**
   * To get the details of a connector line.
   *
   * @param {TooltipEventArgs} args .
   * @returns {PredecessorTooltip} .
   * @private
   */
  Tooltip.prototype.getPredecessorTooltipData = function (args) {
    var predeceesorParent = args.target.id;
    if (this.parent.enableHtmlSanitizer && typeof predeceesorParent === 'string') {
      predeceesorParent = SanitizeHtmlHelper.sanitize(predeceesorParent);
    }
    var taskIds = predeceesorParent.match(/ConnectorLineparent(.*)child(.*)/);
    taskIds.shift();
    var fromTask;
    var toTask;
    if (this.parent.viewType === 'ResourceView') {
      fromTask = this.parent.flatData[this.parent.getTaskIds().indexOf('T' + taskIds[0])];
      toTask = this.parent.flatData[this.parent.getTaskIds().indexOf('T' + taskIds[1])];
    } else {
      fromTask = this.parent.flatData[this.parent.ids.indexOf(taskIds[0])];
      toTask = this.parent.flatData[this.parent.ids.indexOf(taskIds[1])];
    }
    var predecessor = fromTask.ganttProperties.predecessor.filter(function (pdc) {
      return pdc.to === taskIds[1];
    });
    var predecessorTooltipData = {
      fromId: this.parent.viewType === 'ResourceView' ? fromTask.ganttProperties.taskId : fromTask.ganttProperties.rowUniqueID,
      toId: this.parent.viewType === 'ResourceView' ? toTask.ganttProperties.taskId : toTask.ganttProperties.rowUniqueID,
      fromName: fromTask.ganttProperties.taskName,
      toName: toTask.ganttProperties.taskName,
      linkType: predecessor[0].type,
      linkText: this.parent.getPredecessorTextValue(predecessor[0].type),
      offset: predecessor[0].offset,
      offsetUnit: predecessor[0].offsetUnit,
      offsetString: this.parent.getDurationString(predecessor[0].offset, predecessor[0].offsetUnit)
    };
    return predecessorTooltipData;
  };
  /**
   * To compile template string.
   *
   * @param {string | Function} template .
   * @param {Gantt} parent .
   * @param {IGanttData|PredecessorTooltip} data .
   * @param {string} propName .
   * @returns {NodeList} .
   * @private
   */
  Tooltip.prototype.templateCompiler = function (template, parent, data, propName) {
    var tooltipFunction = parent.chartRowsModule.templateCompiler(template);
    var templateID = parent.chartRowsModule.getTemplateID(propName);
    var templateNode = tooltipFunction(extend({
      index: 0
    }, data), parent, propName, templateID, true);
    return templateNode;
  };
  Tooltip.prototype.destroy = function () {
    this.toolTipObj.destroy();
  };
  return Tooltip;
}();

/**
 * Focus module is used to handle certain action on focus elements in keyboard navigations.
 */
var FocusModule = /** @__PURE__ @class */function () {
  function FocusModule(parent) {
    this.parent = parent;
    this.activeElement = null;
    this.previousActiveElement = null;
  }
  FocusModule.prototype.getActiveElement = function (isPreviousActiveElement) {
    return isPreviousActiveElement ? this.previousActiveElement : this.activeElement;
  };
  FocusModule.prototype.setActiveElement = function (element) {
    this.previousActiveElement = this.activeElement;
    this.activeElement = element;
  };
  /**
   * To perform key interaction in Gantt
   *
   * @param {KeyboardEventArgs} e .
   * @returns {void} .
   * @private
   */
  FocusModule.prototype.onKeyPress = function (e) {
    var ganttObj = this.parent;
    var ele = e.target;
    var expandedRecords = ganttObj.getExpandedRecords(ganttObj.currentViewData);
    if (isNullOrUndefined(this.parent.focusModule.getActiveElement()) && (e.action === 'expandAll' || e.action === 'collapseAll')) {
      var focussedElement = this.parent.element.querySelector('.e-treegrid');
      focussedElement.focus();
    }
    if (!this.parent.isEdit && !isNullOrUndefined(ele) && ele.closest('.e-headercell') && e.key === 'Enter' && this.parent.sortModule && this.parent.allowSorting) {
      e.action = 'enter';
      this.parent.treeGrid.grid.notify('key-pressed', e);
    }
    var targetElement = this.parent.focusModule.getActiveElement();
    if (e.action === 'home' || e.action === 'end' || e.action === 'downArrow' || e.action === 'upArrow' || e.action === 'delete' || e.action === 'rightArrow' || e.action === 'leftArrow' || e.action === 'focusTask' || e.action === 'focusSearch' || e.action === 'expandAll' || e.action === 'collapseAll' || e.action === 'undo' || e.action === 'redo' || e.action === 'selectAll') {
      if (!isNullOrUndefined(ganttObj.editModule) && !isNullOrUndefined(ganttObj.editModule.cellEditModule) && ganttObj.editModule.cellEditModule.isCellEdit === true) {
        return;
      }
    }
    if (ganttObj.isAdaptive) {
      if (e.action === 'addRowDialog' || e.action === 'editRowDialog' || e.action === 'delete' || e.action === 'addRow') {
        if (ganttObj.selectionModule && ganttObj.selectionSettings.type === 'Multiple') {
          ganttObj.selectionModule.hidePopUp();
          document.getElementsByClassName('e-gridpopup')[0].style.display = 'none';
        }
      }
    }
    switch (e.action) {
      case 'home':
        if (ganttObj.selectionModule && ganttObj.selectionSettings.mode !== 'Cell') {
          if (ganttObj.selectedRowIndex === 0) {
            return;
          }
          if (ganttObj.selectionModule && ganttObj.selectionModule.getCellSelectedRecords().length === 0) {
            ganttObj.selectionModule.selectRow(0, false, true);
          }
        }
        break;
      case 'end':
        if (ganttObj.selectionModule && ganttObj.selectionSettings.mode !== 'Cell') {
          var currentSelectingRecord = expandedRecords[expandedRecords.length - 1];
          if (ganttObj.selectedRowIndex === ganttObj.flatData.indexOf(currentSelectingRecord)) {
            return;
          }
          if (ganttObj.selectionModule && ganttObj.selectionModule.getCellSelectedRecords().length === 0) {
            ganttObj.selectionModule.selectRow(ganttObj.flatData.indexOf(currentSelectingRecord), false, true);
          }
        }
        break;
      case 'downArrow':
      case 'upArrow':
        {
          var searchElement = ganttObj.element.querySelector('#' + ganttObj.element.id + '_searchbar');
          if (searchElement && searchElement.parentElement.classList.contains('e-input-focus')) {
            ganttObj.selectionModule.clearSelection();
          }
          if (!ganttObj.element.classList.contains('e-scroll-disabled')) {
            this.upDownKeyNavigate(e);
            if (!isNullOrUndefined(targetElement) && !isNullOrUndefined(targetElement.closest('.e-chart-row'))) {
              ganttObj.ganttChartModule.manageFocus(this.getActiveElement(), 'remove', true, e.action);
            }
            if (ganttObj.selectionSettings && ganttObj.selectionSettings.mode === 'Both' && e.target instanceof HTMLElement && e.target.classList.contains('e-cellselectionbackground')) {
              e.target.classList.remove('e-cellselectionbackground');
            }
          }
          break;
        }
      case 'expandAll':
        ganttObj.ganttChartModule.expandCollapseAll('expand');
        break;
      case 'collapseAll':
        ganttObj.ganttChartModule.expandCollapseAll('collapse');
        break;
      case 'expandRow':
      case 'collapseRow':
        this.expandCollapseKey(e);
        break;
      case 'saveRequest':
        if (!isNullOrUndefined(ganttObj.editModule) && !isNullOrUndefined(ganttObj.editModule.cellEditModule) && ganttObj.editModule.cellEditModule.isCellEdit) {
          var col = ganttObj.editModule.cellEditModule.editedColumn;
          if (col.field === ganttObj.columnMapping.duration && !isNullOrUndefined(col.edit) && !isNullOrUndefined(col.edit.read)) {
            var textBox = e.target.ej2_instances[0];
            var textValue = e.target.value;
            // const ganttProp: ITaskData = ganttObj.currentViewData[ganttObj.selectedRowIndex].ganttProperties;
            var tempValue = void 0;
            if (col.field === ganttObj.columnMapping.duration) {
              // tempValue = !isNullOrUndefined(col.edit) && !isNullOrUndefined(col.edit.read) ? (col.edit.read as Function)() :
              //     // eslint-disable-next-line
              //     !isNullOrUndefined(col.valueAccessor) ? (col.valueAccessor as Function)(ganttObj.columnMapping.duration, ganttObj.editedRecords, col) :
              //         ganttObj.dataOperation.getDurationString(ganttProp.duration, ganttProp.durationUnit);
              if (!isNullOrUndefined(col.edit) && !isNullOrUndefined(col.edit.read)) {
                tempValue = col.edit.read();
              }
              if (textValue !== tempValue.toString()) {
                textBox.value = textValue;
                textBox.dataBind();
              }
            }
          }
          if (ganttObj.editModule.dialogModule.dialogObj && getValue('dialogOpen', ganttObj.editModule.dialogModule.dialogObj)) {
            return;
          }
          ganttObj.treeGrid.grid.saveCell();
          var focussedElement = ganttObj.element.querySelector('.e-treegrid');
          focussedElement.focus();
        }
        // if (!isNullOrUndefined(this.parent.onTaskbarClick) && !isNullOrUndefined(targetElement)
        //     && !isNullOrUndefined(targetElement.closest('.e-chart-row'))) {
        //     const target: EventTarget = e.target;
        //     const taskbarElement: Element = targetElement.querySelector('.e-gantt-parent-taskbar,' +
        //             '.e-gantt-child-taskbar,.e-gantt-milestone');
        //     if (taskbarElement) {
        //         this.parent.ganttChartModule.onTaskbarClick(e, target, taskbarElement);
        //     }
        // }
        if (!isNullOrUndefined(targetElement) && !isNullOrUndefined(targetElement.closest('.e-chart-row'))) {
          var target = e.target;
          var taskbarElement = targetElement.querySelector('.e-gantt-parent-taskbar,' + '.e-gantt-child-taskbar,.e-gantt-milestone');
          if (taskbarElement) {
            this.parent.ganttChartModule.onTaskbarClick(e, target, taskbarElement);
          }
        }
        break;
      case 'cancelRequest':
        if (!isNullOrUndefined(ganttObj.editModule) && !isNullOrUndefined(ganttObj.editModule.cellEditModule)) {
          ganttObj.editModule.cellEditModule.isCellEdit = false;
          if (!isNullOrUndefined(ganttObj.toolbarModule)) {
            ganttObj.toolbarModule.refreshToolbarItems();
          }
        }
        break;
      case 'addRow':
        {
          if (ganttObj.editModule && ganttObj.editModule.cellEditModule && ganttObj.editModule.cellEditModule.isCellEdit) {
            e.stopPropagation();
          } else if (isNullOrUndefined(document.getElementById(this.parent.element.id + '_dialog'))) {
            e.preventDefault();
            ganttObj.addRecord(undefined, this.parent.editSettings.newRowPosition, this.parent.selectedRowIndex);
            var focussedElement = ganttObj.element;
            focussedElement.focus();
          }
          break;
        }
      case 'addRowDialog':
        e.preventDefault();
        if (ganttObj.editModule && ganttObj.editModule.dialogModule && ganttObj.editSettings.allowAdding) {
          if (ganttObj.editModule.dialogModule.dialogObj && getValue('dialogOpen', ganttObj.editModule.dialogModule.dialogObj)) {
            return;
          }
          ganttObj.editModule.dialogModule.openAddDialog();
        }
        break;
      case 'editRowDialog':
        {
          e.preventDefault();
          var focussedTreeElement = ganttObj.element.querySelector('.e-treegrid');
          focussedTreeElement.focus();
          if (ganttObj.editModule && ganttObj.editModule.dialogModule && ganttObj.editSettings.allowEditing) {
            if (ganttObj.editModule.dialogModule.dialogObj && getValue('dialogOpen', ganttObj.editModule.dialogModule.dialogObj)) {
              return;
            }
            ganttObj.editModule.dialogModule.openToolbarEditDialog();
          }
          break;
        }
      case 'delete':
        if (ganttObj.selectionModule && ganttObj.editModule && ganttObj.editModule.dialogModule && (!ganttObj.editModule.dialogModule.dialogObj || ganttObj.editModule.dialogModule.dialogObj && !ganttObj.editModule.dialogModule.dialogObj.visible) && (!ganttObj.editSettings.allowTaskbarEditing || ganttObj.editSettings.allowTaskbarEditing && !ganttObj.editModule.taskbarEditModule.touchEdit)) {
          if (ganttObj.selectionSettings.mode !== 'Cell' && ganttObj.selectionModule.selectedRowIndexes.length || ganttObj.selectionSettings.mode === 'Cell' && ganttObj.selectionModule.getSelectedRowCellIndexes().length) {
            if (!isNullOrUndefined(e.target)) {
              if (e.target['tagName'] !== 'INPUT') {
                ganttObj.editModule.startDeleteAction();
              }
            } else {
              ganttObj.editModule.startDeleteAction();
            }
          }
        }
        break;
      case 'focusTask':
        {
          e.preventDefault();
          var selectedId = void 0;
          if (ganttObj.selectionModule) {
            var currentViewData = ganttObj.currentViewData;
            if (ganttObj.selectionSettings.mode !== 'Cell' && !isNullOrUndefined(currentViewData[ganttObj.selectedRowIndex])) {
              selectedId = ganttObj.currentViewData[ganttObj.selectedRowIndex].ganttProperties.rowUniqueID;
            } else if (ganttObj.selectionSettings.mode === 'Cell' && ganttObj.selectionModule.getSelectedRowCellIndexes().length > 0) {
              var selectCellIndex = ganttObj.selectionModule.getSelectedRowCellIndexes();
              selectedId = currentViewData[selectCellIndex[selectCellIndex.length - 1].rowIndex].ganttProperties.rowUniqueID;
            }
          }
          if (selectedId) {
            ganttObj.scrollToTask(selectedId.toString());
          }
          break;
        }
      case 'focusSearch':
        {
          if (ganttObj.element.querySelector('#' + ganttObj.element.id + '_searchbar')) {
            var searchElement = ganttObj.element.querySelector('#' + ganttObj.element.id + '_searchbar');
            searchElement.setAttribute('tabIndex', '-1');
            searchElement.focus();
          }
          break;
        }
      case 'tab':
      case 'shiftTab':
        if (!ganttObj.element.classList.contains('e-scroll-disabled')) {
          ganttObj.ganttChartModule.onTabAction(e);
        }
        break;
      case 'contextMenu':
        {
          var contextMenu = document.getElementById(this.parent.element.id + '_contextmenu').ej2_instances[0];
          var containerPosition = this.parent.getOffsetRect(e.target);
          var top_1 = containerPosition.top + containerPosition.height / 2;
          var left = containerPosition.left + containerPosition.width / 2;
          this.setActiveElement(e.target);
          contextMenu.open(top_1, left);
          e.preventDefault();
          break;
        }
      case 'undo':
        {
          if (this.parent.undoRedoModule && this.parent.undoRedoModule['getUndoCollection'].length > 0) {
            this.parent.undo();
          }
          break;
        }
      case 'redo':
        {
          if (this.parent.undoRedoModule && this.parent.undoRedoModule['getRedoCollection'].length > 0) {
            this.parent.redo();
          }
          break;
        }
      case 'selectAll':
        {
          e.preventDefault();
          var ganttRow = [].slice.call(this.parent.ganttChartModule.chartBodyContent.querySelector('tbody').children);
          if (ganttRow.length > 0) {
            var firstRowIndex = ganttRow[0].getAttribute('data-rowindex');
            var lastRowIndex = ganttRow[ganttRow.length - 1].getAttribute('data-rowindex');
            if (!isNullOrUndefined(firstRowIndex)) {
              firstRowIndex = Number(firstRowIndex);
            }
            if (!isNullOrUndefined(lastRowIndex)) {
              lastRowIndex = Number(lastRowIndex);
            }
            if (!isNullOrUndefined(firstRowIndex) && !isNullOrUndefined(lastRowIndex)) {
              this.parent.selectionModule.selectRowsByRange(firstRowIndex, lastRowIndex);
            }
          }
          break;
        }
      default:
        {
          var eventArgs = {
            requestType: 'keyPressed',
            action: e.action,
            keyEvent: e
          };
          ganttObj.trigger('actionComplete', eventArgs);
          break;
        }
    }
  };
  FocusModule.prototype.upDownKeyNavigate = function (e) {
    e.preventDefault();
    var ganttObj = this.parent;
    var expandedRecords;
    if ((e.action === 'downArrow' || e.action === 'upArrow') && this.parent.selectionModule && this.parent.allowSelection && this.parent.virtualScrollModule && this.parent.enableVirtualization) {
      expandedRecords = ganttObj.getExpandedRecords(ganttObj.flatData);
    } else {
      expandedRecords = ganttObj.getExpandedRecords(ganttObj.currentViewData);
    }
    if (ganttObj.selectionModule) {
      if (ganttObj.selectionSettings.mode !== 'Cell' && ganttObj.selectedRowIndex !== -1) {
        var selectedItem = void 0;
        if ((e.action === 'downArrow' || e.action === 'upArrow') && this.parent.selectionModule && this.parent.allowSelection && this.parent.virtualScrollModule && this.parent.enableVirtualization) {
          selectedItem = ganttObj.flatData[ganttObj.selectedRowIndex];
        } else {
          selectedItem = ganttObj.currentViewData[ganttObj.selectedRowIndex];
        }
        var focussedElement = ganttObj.element.querySelector('.e-focused');
        if (focussedElement) {
          removeClass([focussedElement], 'e-focused');
        }
        var selectingRowIndex = expandedRecords.indexOf(selectedItem);
        var currentSelectingRecord = e.action === 'downArrow' ? expandedRecords[selectingRowIndex + 1] : expandedRecords[selectingRowIndex - 1];
        var activeElement = this.parent['args'];
        if (document.activeElement !== activeElement) {
          if ((e.action === 'downArrow' || e.action === 'upArrow') && this.parent.selectionModule && this.parent.allowSelection && this.parent.virtualScrollModule && this.parent.enableVirtualization) {
            ganttObj.selectionModule.selectRow(ganttObj.flatData.indexOf(currentSelectingRecord), false, true);
          } else {
            ganttObj.selectionModule.selectRow(ganttObj.currentViewData.indexOf(currentSelectingRecord), false, true);
          }
        }
      } else if (ganttObj.selectionSettings.mode === 'Cell' && ganttObj.selectionModule.getSelectedRowCellIndexes().length > 0) {
        var selectCellIndex = ganttObj.selectionModule.getSelectedRowCellIndexes();
        var selectedCellItem = selectCellIndex[selectCellIndex.length - 1];
        var currentCellIndex = selectedCellItem.cellIndexes[selectedCellItem.cellIndexes.length - 1];
        var selectedItem = ganttObj.currentViewData[selectedCellItem.rowIndex];
        var selectingRowIndex = expandedRecords.indexOf(selectedItem);
        var currentSelectingRecord = e.action === 'downArrow' ? expandedRecords[selectingRowIndex + 1] : expandedRecords[selectingRowIndex - 1];
        var cellInfo = {
          rowIndex: ganttObj.currentViewData.indexOf(currentSelectingRecord),
          cellIndex: currentCellIndex
        };
        ganttObj.selectionModule.selectCell(cellInfo);
      }
      this.parent.ganttChartModule.focusedRowIndex = this.parent.selectedRowIndex;
    }
  };
  FocusModule.prototype.expandCollapseKey = function (e) {
    var ganttObj = this.parent;
    if (ganttObj.selectionModule && ganttObj.selectedRowIndex !== -1) {
      var selectedRowIndex_1;
      if (ganttObj.selectionSettings.mode !== 'Cell') {
        selectedRowIndex_1 = ganttObj.selectedRowIndex;
      } else if (ganttObj.selectionSettings.mode === 'Cell' && ganttObj.selectionModule.getSelectedRowCellIndexes().length > 0) {
        var selectCellIndex = ganttObj.selectionModule.getSelectedRowCellIndexes();
        selectedRowIndex_1 = selectCellIndex[selectCellIndex.length - 1].rowIndex;
      }
      if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
        selectedRowIndex_1 = this.parent.currentViewData.findIndex(function (obj) {
          return obj.ganttProperties.rowUniqueID === selectedRowIndex_1.toString();
        }) + 1;
      }
      if (e.action === 'expandRow') {
        ganttObj.expandByIndex(selectedRowIndex_1);
      } else {
        ganttObj.collapseByIndex(selectedRowIndex_1);
      }
    }
  };
  return FocusModule;
}();
var __extends$k = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate$i = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 *
 * Represents the Gantt chart component.
 * ```html
 * <div id='gantt'></div>
 * <script>
 *  var ganttObject = new Gantt({
 *      taskFields: { id: 'taskId', name: 'taskName', startDate: 'startDate', duration: 'duration' }
 *  });
 *  ganttObject.appendTo('#gantt');
 * </script>
 * ```
 */
var Gantt = /** @__PURE__ @class */function (_super) {
  __extends$k(Gantt, _super);
  function Gantt(options, element) {
    var _this = _super.call(this, options, element) || this;
    _this.isRowSelected = false;
    _this.showIndicator = true;
    _this.singleTier = 0;
    _this.expandedRecords = [];
    _this.isLocaleChanged = false;
    _this.triggeredColumnName = '';
    _this.totalUndoAction = 0;
    _this.previousFlatData = [];
    _this.isExpandPerformed = false;
    _this.oldRecords = [];
    _this.updateDuration = false;
    _this.isConvertedMilestone = false;
    /** @hidden */
    _this.isCancelled = false;
    /** @hidden */
    _this.isCollapseAll = false;
    /** @hidden */
    _this.previousRecords = {};
    /** @hidden */
    _this.editedRecords = [];
    /** @hidden */
    _this.modifiedRecords = [];
    /** @hidden */
    _this.isOnEdit = false;
    /** @hidden */
    _this.isOnDelete = false;
    /** @hidden */
    _this.isOnAdded = false;
    /** @hidden */
    _this.isExpandCollapseLevelMethod = false;
    /** @hidden */
    _this.isDynamicData = false;
    /** @hidden */
    _this.isConnectorLineUpdate = false;
    /** @hidden */
    _this.staticSelectedRowIndex = -1;
    _this.needsID = true;
    /** @hidden */
    _this.showActiveElement = true;
    /** @hidden */
    _this.addDeleteRecord = false;
    /** @hidden */
    _this.enableHeaderFocus = true;
    /** @hidden */
    _this.enableValidation = true;
    /**
     * @private
     */
    _this.isTreeGridRendered = false;
    /**
     * @private
     */
    _this.isFromOnPropertyChange = false;
    /**
     * @private
     */
    _this.isFromRenderBaseline = false;
    /**
     * @private
     */
    _this.isGanttChartRendered = false;
    /**
     * @private
     */
    _this.isEdit = false;
    /**
     * To get the module name
     *
     * @returns {string} .
     * @private
     */
    _this.eventMarkerColloction = [];
    setValue('mergePersistData', _this.mergePersistGanttData, _this);
    return _this;
  }
  Gantt.prototype.getModuleName = function () {
    return 'gantt';
  };
  /**
   * For internal use only - Initialize the event handler
   *
   * @returns {void} .
   * @private
   */
  Gantt.prototype.preRender = function () {
    this.initProperties();
  };
  Gantt.prototype.getCurrentDayStartTime = function (date) {
    var dayStartTime;
    if (this.weekWorkingTime.length > 0) {
      dayStartTime = this['getStartTime'](date);
    } else {
      dayStartTime = this.defaultStartTime;
    }
    return dayStartTime;
  };
  Gantt.prototype.getCurrentDayEndTime = function (date) {
    var dayEndTime;
    if (this.weekWorkingTime.length > 0) {
      dayEndTime = this['getEndTime'](date);
    } else {
      dayEndTime = this.defaultEndTime;
    }
    return dayEndTime;
  };
  Gantt.prototype.getStartTime = function (date) {
    var day = date.getDay();
    var startTime;
    switch (day) {
      case 0:
        startTime = this.sundayDefaultStartTime;
        break;
      case 1:
        startTime = this.mondayDefaultStartTime;
        break;
      case 2:
        startTime = this.tuesdayDefaultStartTime;
        break;
      case 3:
        startTime = this.wednesdayDefaultStartTime;
        break;
      case 4:
        startTime = this.thursdayDefaultStartTime;
        break;
      case 5:
        startTime = this.fridayDefaultStartTime;
        break;
      default:
        startTime = this.saturdayDefaultStartTime;
        break;
    }
    return startTime;
  };
  Gantt.prototype.getEndTime = function (date) {
    var day = date.getDay();
    var endTime;
    switch (day) {
      case 0:
        endTime = this.sundayDefaultEndTime;
        break;
      case 1:
        endTime = this.mondayDefaultEndTime;
        break;
      case 2:
        endTime = this.tuesdayDefaultEndTime;
        break;
      case 3:
        endTime = this.wednesdayDefaultEndTime;
        break;
      case 4:
        endTime = this.thursdayDefaultEndTime;
        break;
      case 5:
        endTime = this.fridayDefaultEndTime;
        break;
      default:
        endTime = this.saturdayDefaultEndTime;
        break;
    }
    return endTime;
  };
  Gantt.prototype.getNonWorkingRange = function (date) {
    var day = date.getDay();
    var nonWorkingTimeRange;
    switch (day) {
      case 0:
        nonWorkingTimeRange = this.sundayNonWorkingTimeRanges;
        break;
      case 1:
        nonWorkingTimeRange = this.mondayNonWorkingTimeRanges;
        break;
      case 2:
        nonWorkingTimeRange = this.tuesdayNonWorkingTimeRanges;
        break;
      case 3:
        nonWorkingTimeRange = this.wednesdayNonWorkingTimeRanges;
        break;
      case 4:
        nonWorkingTimeRange = this.thursdayNonWorkingTimeRanges;
        break;
      case 5:
        nonWorkingTimeRange = this.fridayNonWorkingTimeRanges;
        break;
      default:
        nonWorkingTimeRange = this.saturdayNonWorkingTimeRanges;
        break;
    }
    return nonWorkingTimeRange;
  };
  Gantt.prototype.getWorkingRange = function (date) {
    var day = date.getDay();
    var workingTimeRanges;
    switch (day) {
      case 0:
        workingTimeRanges = this.sundayWorkingTimeRanges;
        break;
      case 1:
        workingTimeRanges = this.mondayWorkingTimeRanges;
        break;
      case 2:
        workingTimeRanges = this.tuesdayWorkingTimeRanges;
        break;
      case 3:
        workingTimeRanges = this.wednesdayWorkingTimeRanges;
        break;
      case 4:
        workingTimeRanges = this.thursdayWorkingTimeRanges;
        break;
      case 5:
        workingTimeRanges = this.fridayWorkingTimeRanges;
        break;
      default:
        workingTimeRanges = this.saturdayWorkingTimeRanges;
        break;
    }
    return workingTimeRanges;
  };
  Gantt.prototype.getSecondsPerDay = function (date) {
    var day = date.getDay();
    var totalSeconds;
    switch (day) {
      case 0:
        totalSeconds = this.sundaySeconds;
        break;
      case 1:
        totalSeconds = this.mondaySeconds;
        break;
      case 2:
        totalSeconds = this.tuesdaySeconds;
        break;
      case 3:
        totalSeconds = this.wednesdaySeconds;
        break;
      case 4:
        totalSeconds = this.thursdaySeconds;
        break;
      case 5:
        totalSeconds = this.fridaySeconds;
        break;
      case 6:
        totalSeconds = this.saturdaySeconds;
        break;
      default:
        totalSeconds = this.secondsPerDay;
        break;
    }
    return totalSeconds;
  };
  Gantt.prototype.initProperties = function () {
    this.globalize = new Internationalization(this.locale);
    this.isAdaptive = Browser.isDevice;
    this.flatData = [];
    this.currentViewData = [];
    this.updatedRecords = [];
    this.ids = [];
    this.ganttColumns = [];
    this.updateOffsetOnTaskbarEdit = this.UpdateOffsetOnTaskbarEdit === false ? this.UpdateOffsetOnTaskbarEdit : this.updateOffsetOnTaskbarEdit;
    this.localeObj = new L10n(this.getModuleName(), this.getDefaultLocale(), this.locale);
    this.dataOperation = new TaskProcessor(this);
    this.nonWorkingHours = [];
    this.mondayNonWorkingHours = [];
    this.tuesdayNonWorkingHours = [];
    this.wednesdayNonWorkingHours = [];
    this.thursdayNonWorkingHours = [];
    this.fridayNonWorkingHours = [];
    this.saturdayNonWorkingHours = [];
    this.sundayNonWorkingHours = [];
    this.nonWorkingTimeRanges = [];
    this.mondayNonWorkingTimeRanges = [];
    this.tuesdayNonWorkingTimeRanges = [];
    this.wednesdayNonWorkingTimeRanges = [];
    this.thursdayNonWorkingTimeRanges = [];
    this.fridayNonWorkingTimeRanges = [];
    this.saturdayNonWorkingTimeRanges = [];
    this.sundayNonWorkingTimeRanges = [];
    this.workingTimeRanges = [];
    this.mondayWorkingTimeRanges = [];
    this.tuesdayWorkingTimeRanges = [];
    this.wednesdayWorkingTimeRanges = [];
    this.thursdayWorkingTimeRanges = [];
    this.fridayWorkingTimeRanges = [];
    this.saturdayWorkingTimeRanges = [];
    this.sundayWorkingTimeRanges = [];
    this.defaultEndTime = null;
    this.defaultStartTime = null;
    this.mondayDefaultStartTime = null;
    this.mondayDefaultEndTime = null;
    this.tuesdayDefaultStartTime = null;
    this.tuesdayDefaultEndTime = null;
    this.wednesdayDefaultStartTime = null;
    this.wednesdayDefaultEndTime = null;
    this.thursdayDefaultStartTime = null;
    this.thursdayDefaultEndTime = null;
    this.fridayDefaultStartTime = null;
    this.fridayDefaultEndTime = null;
    this.saturdayDefaultStartTime = null;
    this.saturdayDefaultEndTime = null;
    this.sundayDefaultStartTime = null;
    this.sundayDefaultEndTime = null;
    this.durationUnitTexts = {
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      day: 'day',
      hour: 'hour',
      minute: 'minute'
    };
    this.durationUnitEditText = {
      minute: ['m', 'min', 'minute', 'minutes'],
      hour: ['h', 'hr', 'hour', 'hours'],
      day: ['d', 'dy', 'day', 'days']
    };
    this.perDayWidth = null;
    this.isMileStoneEdited = false;
    this.chartVerticalLineContainer = null;
    this.updatedConnectorLineCollection = [];
    this.connectorLineIds = [];
    this.predecessorsCollection = [];
    this.isInPredecessorValidation = this.enablePredecessorValidation;
    this.isValidationEnabled = true;
    this.isLoad = true;
    this.editedTaskBarItem = null;
    this.validationDialogElement = null;
    this.currentEditedArgs = {};
    this.dialogValidateMode = {
      respectLink: false,
      removeLink: false,
      preserveLinkWithEditing: true
    };
    this.secondsPerDay = this.dataOperation.getSecondsPerDay();
    this.nonWorkingDayIndex = [];
    this.dataOperation.getNonWorkingDayIndex();
    this.columnMapping = {};
    this.controlId = this.element.id;
    this.cloneProjectStartDate = this.enablePersistence && this.cloneProjectStartDate ? this.cloneProjectStartDate : null;
    this.cloneProjectEndDate = this.enablePersistence && this.cloneProjectEndDate ? this.cloneProjectEndDate : null;
    this.totalHolidayDates = this.dataOperation.getHolidayDates();
    this.ganttChartModule = new GanttChart(this);
    this.timelineModule = new Timeline(this);
    this.chartRowsModule = new ChartRows(this);
    this.treeGridModule = new GanttTreeGrid(this);
    this.dateValidationModule = new DateProcessor(this);
    this.predecessorModule = new Dependency(this);
    this.connectorLineModule = new ConnectorLine(this);
    this.splitterModule = new Splitter(this);
    this.tooltipModule = new Tooltip(this);
    this.keyConfig = {
      home: 'home',
      end: 'end',
      downArrow: 'downarrow',
      upArrow: 'uparrow',
      collapseAll: 'ctrl+uparrow',
      expandAll: 'ctrl+downarrow',
      collapseRow: 'ctrl+shift+uparrow',
      expandRow: 'ctrl+shift+downarrow',
      saveRequest: '13',
      cancelRequest: '27',
      addRow: 'insert',
      addRowDialog: 'ctrl+insert',
      editRowDialog: 'ctrl+f2',
      delete: 'delete',
      tab: 'tab',
      shiftTab: 'shift+tab',
      focusTask: 'shift+f5',
      indentLevel: 'shift+leftarrow',
      outdentLevel: 'shift+rightarrow',
      focusSearch: 'ctrl+shift+70',
      contextMenu: 'shift+F10',
      undo: 'ctrl+z',
      redo: 'ctrl+y',
      selectAll: 'ctrl+a'
    };
    this.focusModule = new FocusModule(this);
    if (this.zoomingLevels.length === 0) {
      this.setProperties({
        zoomingLevels: this.getZoomingLevels()
      }, true);
    }
    this.resourceFieldsMapping();
    if (isNullOrUndefined(this.resourceFields.unit)) {
      //set resourceUnit as unit if not mapping
      this.setProperties({
        resourceFields: {
          unit: 'unit'
        }
      }, true);
    }
    this.taskIds = [];
  };
  Gantt.prototype.isUndoRedoItemPresent = function (action) {
    if (this.undoRedoModule && this.undoRedoActions && this.undoRedoActions.indexOf(action) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * @returns {string} .
   * @private
   */
  Gantt.prototype.getDateFormat = function () {
    if (!isNullOrUndefined(this.dateFormat)) {
      return this.dateFormat;
    } else {
      var ganttDateFormat = this.globalize.getDatePattern({
        skeleton: 'yMd'
      });
      return ganttDateFormat;
    }
  };
  /**
   * To get timezone offset.
   *
   * @returns {number} .
   * @private
   */
  Gantt.prototype.getDefaultTZOffset = function () {
    var janMonth = new Date(new Date().getFullYear(), 0, 1);
    var julMonth = new Date(new Date().getFullYear(), 6, 1); //Because there is no reagions DST inbetwwen this range
    return Math.max(janMonth.getTimezoneOffset(), julMonth.getTimezoneOffset());
  };
  /**
   * To check whether the date is in DST.
   *
   * @param {Date} date - Defines the date to check whether it is DST.
   * @returns {boolean} .
   * @private
   */
  Gantt.prototype.isInDst = function (date) {
    return date.getTimezoneOffset() < this.getDefaultTZOffset();
  };
  /**
   * Method to map resource fields.
   *
   * @returns {void} .
   */
  Gantt.prototype.resourceFieldsMapping = function () {
    var resourceSettings = this.resourceFields;
    resourceSettings.id = !isNullOrUndefined(resourceSettings.id) ? resourceSettings.id : this.resourceIDMapping;
    resourceSettings.name = !isNullOrUndefined(resourceSettings.name) ? resourceSettings.name : this.resourceNameMapping;
  };
  /**
   * To validate height and width
   *
   * @param {string | number} value .
   * @returns {string} .
   */
  Gantt.prototype.validateDimentionValue = function (value) {
    if (!isNullOrUndefined(value)) {
      if (typeof value === 'string' && value !== 'auto' && value.indexOf('%') === -1) {
        return value.indexOf('px') === -1 ? value + 'px' : value;
      } else if (typeof value === 'number') {
        return value + 'px';
      } else {
        return value.toString();
      }
    } else {
      return null;
    }
  };
  /**
   * To calculate dimensions of Gantt control
   *
   * @returns {void} .
   */
  Gantt.prototype.calculateDimensions = function () {
    var settingsHeight = this.validateDimentionValue(this.height);
    var settingsWidth = this.validateDimentionValue(this.width);
    if (!isNullOrUndefined(this.width) && typeof this.width === 'string' && this.width.indexOf('%') !== -1) {
      settingsWidth = this.width;
    }
    var elementStyleHeight = this.element.style.height;
    var elementStyleWidth = this.element.style.width;
    if (settingsWidth) {
      this.element.style.width = settingsWidth;
    }
    if (settingsHeight) {
      this.element.style.height = settingsHeight;
    }
    if (!settingsHeight && !elementStyleHeight) {
      this.element.style.height = 'auto'; // old 450px
    }
    if (!settingsWidth && !elementStyleWidth) {
      this.element.style.width = 'auto';
    }
    this.ganttHeight = this.element.offsetHeight;
    this.ganttWidth = this.element.offsetWidth;
  };
  /**
   * @returns {void} .
   * @private
   */
  Gantt.prototype.render = function () {
    if (this.isReact) {
      this.treeGrid.isReact = true;
      this.treeGrid.grid.isReact = true;
    }
    if (this.isVue) {
      this.treeGrid.isVue = true;
      this.treeGrid.grid.isVue = true;
      /* eslint-disable-next-line */
      this.treeGrid.vueInstance = this.vueInstance;
      /* eslint-disable-next-line */
      this.treeGrid.grid.vueInstance = this.vueInstance;
    }
    this.element.setAttribute('role', 'application');
    createSpinner({
      target: this.element
    }, this.createElement);
    this.trigger('load', {});
    this.element.classList.add(root);
    var height = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? this.rowHeight === 36 ? 46 : this.rowHeight : this.rowHeight;
    this.rowHeight = isNullOrUndefined(this.rowHeight) ? 36 : height;
    if (this.isAdaptive) {
      this.element.classList.add(adaptive);
    } else {
      this.element.classList.remove(adaptive);
    }
    this.calculateDimensions();
    if (!isNullOrUndefined(this.toolbarModule)) {
      this.renderToolbar();
    }
    this.splitterModule.renderSplitter();
    this.notify('renderPanels', null);
    this.actionFailures();
    if (!isNullOrUndefined(this.loadingIndicator) && this.loadingIndicator.indicatorType === 'Shimmer') {
      this.showMaskRow();
    } else {
      this.showSpinner();
    }
    if (this.dataMap) {
      this.dataMap.clear();
    }
    this.dataOperation.checkDataBinding();
  };
  Gantt.prototype.actionFailures = function () {
    var failureCases = [];
    var lettersRegex = /^[a-zA-Z\s/]+$/;
    var allPropertiesNull = true;
    var fields = this.taskFields['properties'];
    Object.keys(fields).forEach(function (key) {
      if (fields[key] !== null) {
        allPropertiesNull = false;
      }
    });
    var isPrimary = true;
    for (var i = 0; i < this.columns.length; i++) {
      if (this.columns[i]['isPrimaryKey']) {
        isPrimary = false;
        break;
      }
    }
    if (allPropertiesNull) {
      failureCases.push('Gantt Task Fields are not configured properly. The task fields are crucial for both the project and resource views to function properly. Please ensure that the gantt task fields are properly configured!');
    }
    if (this.viewType === 'ResourceView') {
      if (!this.resourceFields.id) {
        failureCases.push('Gantt Resource Fields are not properly configured, which is crucial for the Resource View. Please ensure that the resource fields are configured correctly!');
      }
    }
    if (this.dataSource instanceof DataManager && isNullOrUndefined(this.taskFields.hasChildMapping)) {
      failureCases.push('hasChildMapping property is not configured for load-on-demand. Please ensure its properly configured in the Gantt Task Fields!');
    }
    if (!isNullOrUndefined(this.timelineSettings.topTier.format) && this.timelineSettings.topTier.format !== '' && !lettersRegex.test(this.timelineSettings.topTier.format)) {
      failureCases.push('The provided top tier format is invalid. Please ensure that you provide a valid format for these tier. Make sure to use only letters and avoid numbers or special characters!');
    }
    if (!isNullOrUndefined(this.timelineSettings.bottomTier.format) && this.timelineSettings.bottomTier.format !== '' && !lettersRegex.test(this.timelineSettings.bottomTier.format)) {
      failureCases.push('The provided bottom  tier format is invalid. Please ensure that you provide a valid format for these tier. Make sure to use only letters and avoid numbers or special characters!');
    }
    if (isPrimary && this.viewType !== 'ResourceView') {
      failureCases.push('Primarykey is not configured properly. The primarykey is crucial for doing CRUD operations or map taskId in column field of gantt!');
    }
    if (this.eventMarkers.length > 0) {
      this.eventMarkers.forEach(function (markers) {
        if (!markers.day) {
          failureCases.push('Day is not configured properly in event markers.Please ensure that the day in event markers is properly configured!');
        }
      });
    }
    /* eslint-disable-next-line */
    function checkModule(moduleName, settings, injectedModules, failureCases) {
      /* eslint-disable-next-line */
      var modules;
      if (!isNullOrUndefined(injectedModules)) {
        modules = injectedModules.filter(function (e) {
          return e.prototype.getModuleName() === moduleName;
        });
      }
      if (settings && !isNullOrUndefined(modules) && modules.length === 0) {
        failureCases.push("Module " + moduleName + " is not available in Gantt component! You either misspelled the module name or forgot to load it!");
      }
    }
    checkModule('sort', this.allowSorting, this.injectedModules, failureCases);
    var editSettings;
    if (this.editSettings.allowAdding || this.editSettings.allowDeleting || this.editSettings.allowEditing || this.editSettings.allowNextRowEdit || this.editSettings.allowTaskbarEditing || this.editSettings.mode || this.editSettings.newRowPosition || this.editSettings.showDeleteConfirmDialog) {
      editSettings = true;
    }
    checkModule('edit', editSettings, this.injectedModules, failureCases);
    checkModule('reorder', this.allowReordering, this.injectedModules, failureCases);
    checkModule('excelExport', this.allowExcelExport, this.injectedModules, failureCases);
    var dragAndDrop;
    if (this.allowRowDragAndDrop || this.allowTaskbarDragAndDrop) {
      dragAndDrop = true;
    }
    checkModule('rowDragAndDrop', dragAndDrop, this.injectedModules, failureCases);
    checkModule('criticalPath', this.enableCriticalPath, this.injectedModules, failureCases);
    checkModule('undoRedo', this.enableUndoRedo, this.injectedModules, failureCases);
    checkModule('resize', this.allowResizing, this.injectedModules, failureCases);
    var toolbar;
    if (this.toolbar && this.toolbar.length > 0) {
      toolbar = true;
    }
    checkModule('toolbar', toolbar, this.injectedModules, failureCases);
    var filter;
    if (this.allowFiltering || this.toolbar && this.toolbar.indexOf('Search') !== -1) {
      filter = true;
    }
    checkModule('filter', filter, this.injectedModules, failureCases);
    checkModule('selection', this.allowSelection, this.injectedModules, failureCases);
    var dayMarkers;
    if (this.highlightWeekends || this.holidays && this.holidays.length > 0 || this.eventMarkers && this.eventMarkers.length > 0) {
      dayMarkers = true;
    }
    checkModule('dayMarkers', dayMarkers, this.injectedModules, failureCases);
    checkModule('contextMenu', this.enableContextMenu, this.injectedModules, failureCases);
    checkModule('columnMenu', this.showColumnMenu, this.injectedModules, failureCases);
    checkModule('pdfExport', this.allowPdfExport, this.injectedModules, failureCases);
    checkModule('virtualScroll', this.enableVirtualization, this.injectedModules, failureCases);
    if (failureCases.length > 0) {
      /* eslint-disable-next-line */
      var failureEventArgs_1 = {
        error: {}
      };
      /* eslint-disable-next-line */
      failureCases.forEach(function (err, index) {
        failureEventArgs_1.error[parseInt(index, 10)] = err;
      });
      this.trigger('actionFailure', failureEventArgs_1);
    }
  };
  Gantt.prototype.hideMaskRow = function () {
    var isTablePresent = this.element.querySelectorAll('.e-masked-table').length;
    if (!isNullOrUndefined(this.contentMaskTable) && (isTablePresent !== 0 || this.contentMaskTable)) {
      var maskTable = this.contentMaskTable;
      remove(maskTable);
      this.contentMaskTable = null;
    }
    isTablePresent = this.element.querySelectorAll('.e-masked-table').length;
    if (!isNullOrUndefined(this.headerMaskTable) && (isTablePresent !== 0 || this.headerMaskTable)) {
      var maskTable = this.headerMaskTable;
      remove(maskTable);
      this.headerMaskTable = null;
    }
    while (this.element.querySelectorAll('.e-table-background').length !== 0) {
      this.element.querySelectorAll('.e-table-background')[0].remove();
    }
    while (this.element.querySelectorAll('.e-temp-timeline').length !== 0) {
      this.element.querySelectorAll('.e-temp-timeline')[0].remove();
    }
    if (this.element.querySelectorAll('.' + timelineHeaderTableContainer).length !== 0) {
      for (var i = 0; i < this.singleTier; i++) {
        if (!isNullOrUndefined(this.element.querySelectorAll('.' + timelineHeaderTableContainer)[parseInt(i.toString(), 10)])) {
          this.element.querySelectorAll('.' + timelineHeaderTableContainer)[parseInt(i.toString(), 10)]['style'].visibility = 'visible';
        }
      }
    }
    if (!isNullOrUndefined(this.element.querySelector('.' + timelineHeaderContainer))) {
      this.element.querySelector('.' + timelineHeaderContainer)['style'].position = 'relative';
    }
    if (!isNullOrUndefined(this.element.getElementsByClassName(chartBodyContent)[0])) {
      this.element.getElementsByClassName(chartBodyContent)[0]['style'].visibility = 'visible';
    }
  };
  Gantt.prototype.showMaskRow = function () {
    var ganttHeader = this.chartPane.childNodes[0].childNodes[0];
    this.scrollLeftValue = this.chartPane.childNodes[0].childNodes[0]['scrollLeft'];
    var ganttContent = this.chartPane.childNodes[0].childNodes[1];
    if (this.treeGrid.element) {
      this.ganttChartModule['setVirtualHeight']();
    }
    if (!this.contentMaskTable) {
      if (ganttContent) {
        var content = ganttContent;
        this.renderBackGround(content);
        if (this.element.querySelectorAll('.' + timelineHeaderTableContainer).length !== 0) {
          this.singleTier = this.timelineModule.isSingleTier ? 1 : 2;
          for (var i = 0; i < this.singleTier; i++) {
            this.element.querySelectorAll('.' + timelineHeaderTableContainer)[parseInt(i.toString(), 10)]['style'].visibility = 'hidden';
          }
        }
        if (this.singleTier === 0) {
          this.singleTier = 2;
        }
        this.element.getElementsByClassName(chartBodyContent)[0]['style'].visibility = 'hidden';
        this.contentMaskTable = this.contentMaskTable = this.createMaskTable(content);
      }
      if (ganttHeader) {
        this.element.querySelector('.' + timelineHeaderContainer)['style'].position = 'static';
        var content = ganttHeader;
        this.renderHeaderBackground(content);
        this.headerMaskTable = this.headerMaskTable = this.createMaskTable(content);
      }
    }
  };
  Gantt.prototype.renderHeaderBackground = function (element) {
    var parentElement = element;
    // const timelineHeight: any = element.getBoundingClientRect().height;
    var header = closest(parentElement, '.' + timelineHeaderContainer) ? true : false;
    if (header) {
      var div = this.createElement('div', {
        className: 'e-table-background'
      });
      // const tempRow: Element = this.createElement('tr', {
      //     className: 'e-masked-row e-row', attrs: {
      //         style: 'height: ' + timelineHeight + 'px;'
      //     }
      // });
      var backgroundLines = 0;
      var containerWidth = Math.round(element.getBoundingClientRect().width);
      for (var i = 0; i < 3; i++) {
        if (this.enableRtl) {
          div.appendChild(this.createElement('div', {
            className: 'e-div-background',
            attrs: {
              style: 'left: ' + (containerWidth -= 160) + 'px; top:0px;'
            }
          }));
        } else {
          div.appendChild(this.createElement('div', {
            className: 'e-div-background',
            attrs: {
              style: 'left: ' + (backgroundLines += 160) + 'px; top:0px;'
            }
          }));
        }
      }
      parentElement.insertBefore(div, parentElement.firstChild);
    }
  };
  Gantt.prototype.renderBackGround = function (element) {
    var parentElement = element;
    // const timelineHeight: number = element.getBoundingClientRect().height;
    var content = closest(parentElement, '.' + chartBodyContainer) ? true : false;
    if (content) {
      var div = this.createElement('div', {
        className: 'e-table-background'
      });
      // const tempRow: Element = this.createElement('tr', {
      //     className: 'e-masked-row e-row', attrs: {
      //         style: 'height: ' + timelineHeight + 'px;'
      //     }
      // });
      var backgroundLines = 0;
      var containerWidth = Math.round(element.getBoundingClientRect().width);
      for (var i = 0; i < 3; i++) {
        if (this.enableRtl) {
          div.appendChild(this.createElement('div', {
            className: 'e-div-background',
            attrs: {
              style: 'left: ' + (containerWidth -= 160) + 'px;z-index:1;'
            }
          }));
        } else {
          div.appendChild(this.createElement('div', {
            className: 'e-div-background',
            attrs: {
              style: 'left: ' + (backgroundLines += 160) + 'px;z-index:1;'
            }
          }));
        }
      }
      parentElement.insertBefore(div, parentElement.firstChild);
    }
  };
  /* eslint-disable-next-line */
  Gantt.prototype.createMaskTable = function (element) {
    var parentElement = element;
    var shimmerContainerHeight = element.getBoundingClientRect().height;
    var header = closest(parentElement, '.' + timelineHeaderContainer) ? true : false;
    /* eslint-disable-next-line */
    var maskTable;
    if (header) {
      maskTable = this.createEmptyTimeLineTable(shimmerContainerHeight);
      maskTable.style.position = 'sticky';
      maskTable.style.left = 0 + 'px';
      if (this.enableRtl) {
        maskTable.style.removeProperty('left');
      }
    } else {
      maskTable = this.createEmptyMaskTable(shimmerContainerHeight);
      maskTable.style.position = 'absolute';
      maskTable.style.zIndex = 1;
    }
    if (!header) {
      maskTable.style.height = element.getBoundingClientRect().height + 'px';
      parentElement.insertBefore(maskTable, parentElement.firstChild);
    } else {
      maskTable.style.height = element.getBoundingClientRect().height + 'px';
      var div = this.createElement('div', {
        className: 'e-temp-timeline'
      });
      div.style.width = this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetWidth'] + 'px';
      div.style.position = 'sticky';
      if (this.enableRtl) {
        div.style['margin-right'] = Math.abs(this.scrollLeftValue) + 'px';
      } else {
        div.style['margin-left'] = this.scrollLeftValue + 'px';
      }
      div.appendChild(maskTable);
      parentElement.insertBefore(div, parentElement.firstChild);
    }
    return maskTable;
  };
  Gantt.prototype.createEmptyTimeLineTable = function (timelineHeight) {
    var table = this.createElement('table', {
      className: 'e-table e-masked-table'
    });
    var tbody = this.createElement('tbody', {
      className: 'e-masked-tbody'
    });
    /* eslint-disable-next-line */
    var row = [];
    var duplicateRow = this.createElement('tr', {
      className: 'e-masked-row e-row',
      attrs: {
        style: 'height: ' + timelineHeight / 2 + 'px;'
      }
    });
    for (var i = 0; i < this.singleTier; i++) {
      row.push(duplicateRow.cloneNode(true));
    }
    this.topBottomHeader = 0;
    for (var i = 0; i < row.length; i++) {
      tbody.appendChild(this.applyTimelineMaskRow(row[parseInt(i.toString(), 10)]));
      this.topBottomHeader = this.topBottomHeader + 1;
    }
    table.appendChild(tbody);
    table.style.width = 100 + '%';
    return table;
  };
  Gantt.prototype.applyTimelineMaskRow = function (row) {
    var maskRow = row;
    var num = 4;
    if (this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetWidth'] / 166 > 4) {
      num = this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetWidth'] / 166;
    }
    for (var i = 0; i < num; i++) {
      maskRow.appendChild(this.createElement('td', {
        className: 'e-timeline-masked-top-header-cell'
      }));
    }
    for (var i = 0; i < maskRow.childNodes.length - 1; i++) {
      maskRow.childNodes[parseInt(i.toString(), 10)]['style']['width'] = 166 + 'px';
    }
    var maskCells = [].slice.call(maskRow.childNodes);
    for (var i = 0; i < maskCells.length; i++) {
      var maskCell = maskCells[parseInt(i.toString(), 10)];
      switch (this.topBottomHeader) {
        case 0:
          {
            if (this.enableRtl) {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-timelineHeader"></span>';
              maskCell.children[0]['style'].left = -20 + 'px';
            } else {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-timelineHeader"></span>';
            }
            break;
          }
        case 1:
          {
            maskCell.appendChild(this.createElement('td', {
              className: 'e-timeline-masked-top-header-cell'
            }));
            maskCell.appendChild(this.createElement('td', {
              className: 'e-timeline-masked-top-header-cell'
            }));
            maskCell.appendChild(this.createElement('td', {
              className: 'e-timeline-masked-top-header-cell'
            }));
            var innerMaskCells = [].slice.call(maskCell.childNodes);
            for (var i_1 = 0; i_1 < innerMaskCells.length; i_1++) {
              var htmlInner = innerMaskCells[parseInt(i_1.toString(), 10)];
              if (i_1 === 0) {
                if (this.enableRtl) {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML"></span>';
                  htmlInner.children[0]['style'].left = -14 + 'px';
                } else {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML"></span>';
                }
              } else if (i_1 === 1) {
                if (this.enableRtl) {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML1"></span>';
                  htmlInner.children[0]['style'].left = -30 + 'px';
                } else {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML1"></span>';
                }
              } else {
                if (this.enableRtl) {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML2"></span>';
                  htmlInner.children[0]['style'].left = -60 + 'px';
                } else {
                  htmlInner.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-innerHTML2"></span>';
                }
              }
            }
            break;
          }
      }
    }
    return maskRow;
  };
  Gantt.prototype.createEmptyMaskTable = function (timelineHeight) {
    var table = this.createElement('table', {
      className: 'e-table e-masked-table'
    });
    var tbody = this.createElement('tbody', {
      className: 'e-masked-tbody'
    });
    /* eslint-disable-next-line */
    var row = [];
    var duplicateRow = this.createElement('tr', {
      className: 'e-masked-row e-row',
      attrs: {
        style: 'height: ' + timelineHeight / 7 + 'px;'
      }
    });
    this.columnLoop = 0;
    for (var i = 0; i < 6; i++) {
      row.push(duplicateRow.cloneNode(true));
    }
    for (var j = 0; j < row.length; j++) {
      if (this.columnLoop < 4) {
        this.columnLoop = this.columnLoop + 1;
      } else if (this.columnLoop === 4) {
        this.columnLoop = 1;
      }
      tbody.appendChild(this.applyMaskRow(row[parseInt(j.toString(), 10)]));
    }
    table.appendChild(tbody);
    table.style.width = 100 + '%';
    var div = this.createElement('div', {
      className: 'e-temp-container'
    });
    div.style.width = 'calc(100% - ' + 17 + 'px)';
    div.style.overflow = 'hidden';
    div.appendChild(table);
    return div;
  };
  Gantt.prototype.applyMaskRow = function (row) {
    var maskRow = row;
    if (this.columnLoop < 4) {
      var num = 2;
      if (this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetWidth'] / 300 > 2) {
        num = this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetWidth'] / 300;
      }
      for (var i = 0; i < num; i++) {
        maskRow.appendChild(this.createElement('td', {
          className: 'e-masked-cell e-rowcell'
        }));
      }
    } else {
      maskRow.appendChild(this.createElement('td', {
        className: 'e-masked-cell e-rowcell'
      }));
    }
    var maskCells = [].slice.call(maskRow.childNodes);
    for (var i = 0; i < maskCells.length; i++) {
      var maskCell = maskCells[parseInt(i.toString(), 10)];
      switch (this.columnLoop) {
        case 1:
          if (i === 0) {
            if (this.enableRtl) {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell01"></span>';
              maskCell.children[0]['style'].left = -14 + 'px';
            } else {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell01"></span>';
            }
          } else {
            maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell02"></span>';
          }
          break;
        case 2:
          if (i === 0) {
            if (this.enableRtl) {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell03"></span>';
              maskCell.children[0]['style'].left = -14 + 'px';
            } else {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell03"></span>';
            }
          } else {
            maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell04"></span>';
          }
          break;
        case 3:
          if (i === 0) {
            if (this.enableRtl) {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell05"></span>';
              maskCell.children[0]['style'].left = -64 + 'px';
            } else {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell05"></span>';
            }
          } else {
            if (this.enableRtl) {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell06"></span>';
              maskCell.children[0]['style'].left = -192 + 'px';
            } else {
              maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell06"></span>';
            }
          }
          break;
        case 4:
          if (this.enableRtl) {
            maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell07"></span>';
            maskCell.children[0]['style'].left = -388 + 'px';
          } else {
            maskCell.innerHTML = '<span class="e-mask e-skeleton e-skeleton-text e-shimmer-wave e-maskcell07"></span>';
          }
          break;
      }
    }
    return maskRow;
  };
  /**
   * Method used to show spinner.
   *
   * @returns {void} .
   */
  Gantt.prototype.showSpinner = function () {
    showSpinner(this.element);
  };
  /**
   * Method used to hide spinner.
   *
   * @returns {void} .
   */
  Gantt.prototype.hideSpinner = function () {
    hideSpinner(this.element);
  };
  /**
   * @returns {void} .
   * @private
   */
  Gantt.prototype.processTimeline = function () {
    this.timelineModule.processTimelineUnit();
    this.timelineModule.calculateZoomingLevelsPerDayWidth(); // To calculate the perDaywidth
  };
  /**
   * @param {boolean} isChange -Defines whether task data is changed.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.renderGantt = function (isChange) {
    if (isChange) {
      this.isFromOnPropertyChange = isChange;
    }
    if (this.enableValidation) {
      this.dataOperation.calculateProjectDates();
      this.timelineModule.validateTimelineProp();
    }
    if (this.allowParentDependency) {
      this.predecessorModule.updateParentPredecessor();
    }
    if (this.predecessorModule && this.taskFields.dependency) {
      this.predecessorModule['parentIds'] = [];
      this.predecessorModule['parentRecord'] = [];
      this.predecessorModule.updatePredecessors();
      if (this.isInPredecessorValidation && this.enableValidation && this.autoCalculateDateScheduling && !(this.isLoad && !this.treeGrid.loadChildOnDemand && this.taskFields.hasChildMapping)) {
        this.predecessorModule.updatedRecordsDateByPredecessor();
      }
    }
    if (this.enableValidation) {
      this.dataOperation.calculateProjectDates();
      this.timelineModule.validateTimelineProp();
      this.dataOperation.updateGanttData();
    }
    if (isChange) {
      if (this.dataSource instanceof Object && isCountRequired(this)) {
        var count = getValue('count', this.dataSource);
        this.treeGrid.dataSource = {
          result: this.flatData,
          count: count
        };
      } else {
        this.treeGrid.setProperties({
          dataSource: this.flatData
        }, false);
      }
      if (!isNullOrUndefined(this.selectionModule)) {
        this.treeGrid.setProperties({
          selectedRowIndex: this.selectedRowIndex
        }, false);
      }
    } else {
      this.treeGridPane.classList.remove('e-temp-content');
      remove(this.treeGridPane.querySelector('.e-gantt-temp-header'));
      this.notify('dataReady', {});
      if (this.enableContextMenu) {
        this.notify('initiate-contextMenu', {});
      }
      this.renderTreeGrid();
      this.wireEvents();
      this.notify('initPredessorDialog', {});
    }
    if (!this.isFromOnPropertyChange) {
      this.splitterModule.updateSplitterPosition();
    }
    if ((this.gridLines === 'Vertical' || this.gridLines === 'Both') && !this.dayMarkersModule) {
      this.renderChartVerticalLines();
    }
  };
  Gantt.prototype.removeCriticalPathStyles = function () {
    var ganttChartElement = this.ganttChartModule.chartElement;
    removeClass(ganttChartElement.querySelectorAll('.e-gantt-child-taskbar-inner-div'), criticalChildTaskBarInnerDiv);
    removeClass(ganttChartElement.querySelectorAll('.e-gantt-child-progressbar-inner-div'), criticalChildProgressBarInnerDiv);
    removeClass(ganttChartElement.querySelectorAll('.e-critical-milestone'), criticalMilestone);
    removeClass(this.element.querySelectorAll('.e-connector-line'), criticalConnectorLineSVG);
    removeClass(this.element.querySelectorAll('.e-connector-line-arrow'), criticalConnectorArrowSVG);
  };
  Gantt.prototype.wireEvents = function () {
    if (this.allowKeyboard) {
      this.keyboardModule = new KeyboardEvents(this.element, {
        keyAction: this.keyActionHandler.bind(this),
        keyConfigs: this.keyConfig,
        eventName: 'keydown'
      });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    EventHandler.add(window, 'resize', this.windowResize, this);
    EventHandler.add(document.body, 'keydown', this.keyDownHandler, this);
  };
  Gantt.prototype.unwireEvents = function () {
    if (this.keyboardModule) {
      this.keyboardModule.destroy();
      this.keyboardModule = null;
    }
    /* eslint-disable-next-line */
    EventHandler.remove(window, 'resize', this.windowResize);
    EventHandler.remove(document.body, 'keydown', this.keyDownHandler);
  };
  Gantt.prototype.keyDownHandler = function (e) {
    if (e.altKey) {
      if (e.keyCode === 74) {
        //alt j
        this.ganttChartModule.manageFocus(this.treeGrid.element.childNodes[1], 'remove', false);
        this.ganttChartModule.manageFocus(this.element, 'add', false);
      }
    }
  };
  /**
   * Method trigger while user perform window resize.
   *
   * @returns {void} .
   * @private
   */
  Gantt.prototype.windowResize = function () {
    var _this = this;
    if (!isNullOrUndefined(this.element)) {
      this.updateContentHeight();
      this.ganttChartModule.updateWidthAndHeight(); // Updating chart scroll conatiner height for row mismatch
      this.treeGridModule.ensureScrollBar();
      if (this.predecessorModule && this.taskFields.dependency) {
        this.updateRowHeightInConnectorLine(this.updatedConnectorLineCollection);
        this.connectorLineModule.renderConnectorLines(this.updatedConnectorLineCollection);
      }
      var criticalModule = this.criticalPathModule;
      if (this.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
        this.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
      }
      this.calculateDimensions();
      var pane1 = this.splitterModule.splitterObject.element.querySelectorAll('.e-pane')[0];
      var pane2 = this.splitterModule.splitterObject.element.querySelectorAll('.e-pane')[1];
      this.splitterModule.splitterPreviousPositionGrid = pane1.scrollWidth + 1 + 'px';
      this.splitterModule.splitterPreviousPositionChart = pane2.scrollWidth + 1 + 'px';
      this.splitterModule.splitterObject.paneSettings[1].size = this.ganttWidth - parseInt(this.splitterModule.splitterPreviousPositionGrid, 10) - 4 + 'px';
      if (this.timelineModule.isZoomedToFit) {
        setTimeout(function () {
          _this.timelineModule.processZoomToFit();
        }, 0);
      }
    }
    if (this.dayMarkersModule && this.dayMarkersModule['eventMarkerRender']) {
      this.dayMarkersModule['eventMarkerRender'].updateContainerHeight();
    }
    if (this.enableTimelineVirtualization) {
      this.timelineModule.refreshTimeline();
    }
  };
  Gantt.prototype.keyActionHandler = function (e) {
    if (this.enableContextMenu && this.contextMenuModule && (e.action === 'downArrow' || e.action === 'upArrow') && document.getElementById(this.element.id + '_contextmenu') && this['args']) {
      var firstMenuItem = this['args'];
      if (!isNullOrUndefined(firstMenuItem)) {
        firstMenuItem.focus();
      }
    }
    if (e.target && (e.action === 'downArrow' || e.action === 'upArrow') && e.target === this.element.querySelector('.e-rowcell')) {
      this.treeGrid.grid.notify('key-pressed', e);
    } else {
      this.focusModule.onKeyPress(e);
    }
  };
  /**
   * Method for updating row height value in connector line collections
   *
   * @param {IConnectorLineObject[]} collection  -Defines the CollectorLine collection.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.updateRowHeightInConnectorLine = function (collection) {
    if (collection && collection.length) {
      var rowHeight = this.ganttChartModule.getChartRows()[0] && this.ganttChartModule.getChartRows()[0].getBoundingClientRect().height;
      if (rowHeight && !isNaN(rowHeight)) {
        for (var count = 0; count < collection.length; count++) {
          collection[count].rowHeight = rowHeight;
        }
      }
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  Gantt.prototype.renderToolbar = function () {
    if (!isNullOrUndefined(this.toolbarModule)) {
      this.toolbarModule.renderToolbar();
      this.toolbarModule.refreshToolbarItems();
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  Gantt.prototype.renderTreeGrid = function () {
    this.treeGridModule.renderTreeGrid();
  };
  Gantt.prototype.updateCurrentViewData = function () {
    var _this = this;
    this.currentViewData = this.treeGrid.getCurrentViewRecords().slice();
    if (this.currentViewData.length > 0 && this.loadChildOnDemand && this.taskFields.hasChildMapping) {
      this.isLoad = true;
      this.flatData = [];
      this.dataOperation.taskIds = [];
      this.ids = [];
      this.dataOperation.recordIndex = 0;
      this.dataOperation.dataArray = this.currentViewData;
      this.dataOperation.cloneDataSource();
      if (this.predecessorModule && this.taskFields.dependency) {
        this.predecessorModule['parentIds'] = [];
        this.predecessorModule['parentRecord'] = [];
        this.predecessorModule.updatePredecessors();
      }
      /* eslint-disable-next-line */
      var gridData = this.treeGrid.grid.contentModule['rows'];
      /* eslint-disable-next-line */
      var data = gridData.filter(function (x) {
        if (x['data'][_this.taskFields.id] === _this.flatData[0].ganttProperties.taskId) {
          return x;
        }
      })[0];
      var index = data['index'];
      for (var i = 0; i < this.flatData.length; i++) {
        this.flatData[i].index = index;
        index++;
      }
      this.currentViewData = this.flatData;
      this.treeGrid.grid.currentViewData = this.flatData;
      if (!isNullOrUndefined(this.treeGrid['virtualScrollModule'])) {
        this.treeGrid['virtualScrollModule'].visualData = this.flatData;
        this.updatedRecords = this.flatData;
      }
    }
  };
  /**
   * @param {IGanttData} records -Defines the delete record collections.
   * @returns {IGanttData} .
   * @private
   */
  Gantt.prototype.getRecordFromFlatdata = function (records) {
    var updatedRecord = [];
    for (var i = 0; i < records.length; i++) {
      updatedRecord.push(this.getTaskByUniqueID(records[i].uniqueID));
    }
    return updatedRecord;
  };
  /**
   * @param {object} args -Update the gantt element content height.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.updateContentHeight = function (args) {
    if (!this.allowTaskbarOverlap && !this.ganttChartModule.isCollapseAll && !this.ganttChartModule.isExpandAll && !this.isLoad) {
      return;
    } else {
      if (this.virtualScrollModule && this.enableVirtualization && !isNullOrUndefined(args)) {
        var length_1 = getValue('result.length', args);
        this.contentHeight = length_1 * this.rowHeight;
      } else {
        var expandedRecords = this.virtualScrollModule && this.enableVirtualization ? this.currentViewData : this.getExpandedRecords(this.currentViewData);
        this.expandedRecords = expandedRecords;
        var height = void 0;
        var chartRow = !isNullOrUndefined(this.ganttChartModule.getChartRows()) ? this.ganttChartModule.getChartRows()[0] : null;
        if (!isNullOrUndefined(chartRow) && chartRow.offsetHeight > 0) {
          height = chartRow.offsetHeight;
        } else {
          height = this.rowHeight;
        }
        this.contentHeight = expandedRecords.length * height;
      }
    }
  };
  /**
   * To get expand status.
   *
   * @param {IGanttData} data .
   * @returns {boolean} .
   * @private
   */
  Gantt.prototype.getExpandStatus = function (data) {
    var parentRecord = this.getParentTask(data.parentItem);
    if (!isNullOrUndefined(parentRecord)) {
      if (parentRecord.expanded === false) {
        return false;
      } else if (parentRecord.parentItem) {
        var parentData = this.getParentTask(parentRecord.parentItem);
        if (parentData.expanded === false) {
          return false;
        } else {
          return this.getExpandStatus(this.getParentTask(parentRecord.parentItem));
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  /**
   * Get expanded records from given record collection.
   *
   * @param {IGanttData[]} records - Defines record collection.
   * @returns {IGanttData[]} .
   * @deprecated
   */
  Gantt.prototype.getExpandedRecords = function (records) {
    var _this = this;
    if (isNullOrUndefined(records)) {
      return [];
    }
    var expandedRecords = records.filter(function (record) {
      return _this.getExpandStatus(record) === true;
    });
    this.expandedRecords = expandedRecords;
    return expandedRecords;
  };
  /**
   * Getting the Zooming collections of the Gantt control
   *
   * @returns {ZoomTimelineSettings} .
   * @private
   */
  Gantt.prototype.getZoomingLevels = function () {
    var _WeekStartDay = this.timelineSettings.weekStartDay;
    var zoomingLevels = [{
      topTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 50
      },
      bottomTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 10
      },
      timelineUnitSize: 99,
      level: 0,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 20
      },
      bottomTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 5
      },
      timelineUnitSize: 99,
      level: 1,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 5
      },
      bottomTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 1
      },
      timelineUnitSize: 99,
      level: 2,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'MMM, yy',
        count: 1
      },
      bottomTier: {
        unit: 'Month',
        formatter: this.displayHalfValue,
        count: 6
      },
      timelineUnitSize: 66,
      level: 3,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'MMM, yy',
        count: 1
      },
      bottomTier: {
        unit: 'Month',
        formatter: this.displayHalfValue,
        count: 6
      },
      timelineUnitSize: 99,
      level: 4,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'MMM, yy',
        count: 1
      },
      bottomTier: {
        unit: 'Month',
        formatter: this.displayQuarterValue,
        count: 3
      },
      timelineUnitSize: 66,
      level: 5,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Month',
        formatter: this.displayQuarterValue,
        count: 3
      },
      timelineUnitSize: 99,
      level: 6,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Year',
        format: 'yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Month',
        format: 'MMM yyyy',
        count: 1
      },
      timelineUnitSize: 99,
      level: 7,
      timelineViewMode: 'Year',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Month',
        format: 'MMM, yy',
        count: 1
      },
      bottomTier: {
        unit: 'Week',
        format: 'dd',
        count: 1
      },
      timelineUnitSize: 33,
      level: 8,
      timelineViewMode: 'Month',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Month',
        format: 'MMM, yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Week',
        format: 'dd MMM',
        count: 1
      },
      timelineUnitSize: 66,
      level: 9,
      timelineViewMode: 'Month',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Month',
        format: 'MMM, yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Week',
        format: 'dd MMM',
        count: 1
      },
      timelineUnitSize: 99,
      level: 10,
      timelineViewMode: 'Month',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Week',
        format: 'MMM dd, yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Day',
        format: 'd',
        count: 1
      },
      timelineUnitSize: 33,
      level: 11,
      timelineViewMode: 'Week',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Week',
        format: 'MMM dd, yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Day',
        format: 'd',
        count: 1
      },
      timelineUnitSize: 66,
      level: 12,
      timelineViewMode: 'Week',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Week',
        format: 'MMM dd, yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Day',
        format: 'd',
        count: 1
      },
      timelineUnitSize: 99,
      level: 13,
      timelineViewMode: 'Week',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 12
      },
      timelineUnitSize: 66,
      level: 14,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 12
      },
      timelineUnitSize: 99,
      level: 15,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 6
      },
      timelineUnitSize: 66,
      level: 16,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 6
      },
      timelineUnitSize: 99,
      level: 17,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 2
      },
      timelineUnitSize: 66,
      level: 18,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 2
      },
      timelineUnitSize: 99,
      level: 19,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 1
      },
      timelineUnitSize: 66,
      level: 20,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Day',
        format: 'E dd yyyy',
        count: 1
      },
      bottomTier: {
        unit: 'Hour',
        format: 'hh a',
        count: 1
      },
      timelineUnitSize: 99,
      level: 21,
      timelineViewMode: 'Day',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Hour',
        format: 'ddd MMM, h a',
        count: 1
      },
      bottomTier: {
        unit: 'Minutes',
        format: 'mm',
        count: 30
      },
      timelineUnitSize: 66,
      level: 22,
      timelineViewMode: 'Hour',
      weekStartDay: 0,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Hour',
        format: 'ddd MMM, h a',
        count: 1
      },
      bottomTier: {
        unit: 'Minutes',
        format: 'mm',
        count: 15
      },
      timelineUnitSize: 66,
      level: 23,
      timelineViewMode: 'Hour',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }, {
      topTier: {
        unit: 'Hour',
        format: 'ddd MMM, h a',
        count: 1
      },
      bottomTier: {
        unit: 'Minutes',
        format: 'mm',
        count: 1
      },
      timelineUnitSize: 66,
      level: 24,
      timelineViewMode: 'Hour',
      weekStartDay: _WeekStartDay,
      updateTimescaleView: true,
      weekendBackground: null,
      showTooltip: true
    }];
    return zoomingLevels;
  };
  Gantt.prototype.displayQuarterValue = function (date) {
    var month = date.getMonth();
    if (month >= 0 && month <= 2) {
      return 'Q1';
    } else if (month >= 3 && month <= 5) {
      return 'Q2';
    } else if (month >= 6 && month <= 8) {
      return 'Q3';
    } else {
      return 'Q4';
    }
  };
  Gantt.prototype.displayHalfValue = function (date) {
    var month = date.getMonth();
    if (month >= 0 && month <= 5) {
      return 'H1';
    } else {
      return 'H2';
    }
  };
  /**
   *
   * @param {Date} date .
   * @param {string} format .
   * @returns {string} .
   */
  Gantt.prototype.getFormatedDate = function (date, format) {
    if (isNullOrUndefined(date)) {
      return null;
    }
    if (isNullOrUndefined(format)) {
      format = this.getDateFormat();
    }
    return this.globalize.formatDate(date, {
      format: format
    });
  };
  /**
   * Get duration value as string combined with duration and unit values.
   *
   * @param {number} duration - Defines the duration.
   * @param {string} durationUnit - Defines the duration unit.
   * @returns {string} .
   */
  Gantt.prototype.getDurationString = function (duration, durationUnit) {
    var value = this.dateValidationModule.getDurationString(duration, durationUnit);
    return value;
  };
  /**
   * Get work value as string combined with work and unit values.
   *
   * @param {number} work - Defines the work value.
   * @param {string} workUnit - Defines the work unit.
   * @returns {string} .
   */
  Gantt.prototype.getWorkString = function (work, workUnit) {
    var value = this.dateValidationModule.getWorkString(work, workUnit);
    return value;
  };
  Gantt.prototype.updateTreeColumns = function () {
    var temp;
    // let field: string;
    var gridColumns = this.treeGrid.grid.getColumns();
    if (this.treeColumnIndex !== -1 && this.columns[this.treeColumnIndex] && !isNullOrUndefined(this.columns[this.treeColumnIndex]['template'])) {
      temp = this.columns[this.treeColumnIndex]['template'];
      // field = this.columns[this.treeColumnIndex]['field'];
    }
    var gridColumn;
    for (var i = 0; i < gridColumns.length; i++) {
      gridColumn = {};
      for (var j = 0; j < this.columns.length; j++) {
        if (this.columns[j]['field'] === gridColumns[i].field) {
          for (var _i = 0, _a = Object.keys(this.columns[j]); _i < _a.length; _i++) {
            var prop = _a[_i];
            if (!isUndefined(this.columns[j][prop])) {
              gridColumn[prop] = gridColumns[i][prop];
            }
            gridColumn.visible = gridColumns[i].visible;
            gridColumn.width = gridColumns[i].width;
          }
          this.columns[j] = gridColumn;
          if (this.columns[j]['type'] !== 'checkbox' && !isNullOrUndefined(temp) && temp !== '') {
            this.columns[j]['template'] = temp;
          }
        }
      }
    }
    if (this.columns.length > 0) {
      this.treeGrid.setProperties({
        columns: this.columns
      }, true);
    }
  };
  /**
   *
   * @param {object} args .
   * @returns {void} .
   * @private
   */
  Gantt.prototype.treeDataBound = function (args) {
    var _this = this;
    if (this.isLoad && this.undoRedoModule) {
      if (this.sortSettings.columns.length > 0) {
        this.undoRedoModule['previousSortedColumns'] = this.sortSettings.columns;
      } else if (this.searchSettings.key !== '') {
        this.undoRedoModule['searchString'] = this.searchSettings.key;
      }
    }
    this.element.getElementsByClassName('e-chart-root-container')[0]['style'].height = '100%';
    // let gridHeight: string = this.element.getElementsByClassName('e-gridcontent')[0]['style'].height;
    var gridContent = this.element.getElementsByClassName('e-gridcontent')[0].childNodes[0];
    gridContent.setAttribute('tabindex', '0');
    var treeGridrole = this.element.getElementsByClassName('e-gridcontent')[0].childNodes[0].childNodes[0];
    treeGridrole.setAttribute('role', 'treegrid');
    var timelineContainer = this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'];
    // gridHeight = 'calc(100% - ' + timelineContainer + 'px)';
    // this.element.getElementsByClassName('e-chart-scroll-container e-content')[0]['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
    var scrollContainer = this.element.getElementsByClassName('e-chart-scroll-container e-content')[0];
    scrollContainer['style'].height = 'calc(100% - ' + timelineContainer + 'px)';
    scrollContainer.setAttribute('tabindex', '0');
    if (!isNullOrUndefined(this.toolbarModule) && !isNullOrUndefined(this.toolbarModule.element)) {
      this.splitterElement.style.height = 'calc(100% - ' + this.toolbarModule.element.offsetHeight + 'px)';
    } else {
      this.splitterElement.style.height = '100%';
    }
    if (this.filterSettings && this.filterSettings['properties'] && this.filterSettings['properties'].columns) {
      this.filterSettings['properties'].columns.forEach(function (column) {
        var searchString = column.properties.field;
        var matchedObject = null;
        for (var i = 0; i < _this.treeGrid.columns.length; i++) {
          var treeColumn = _this.treeGrid.columns[i];
          if (treeColumn['field'] === searchString) {
            matchedObject = treeColumn;
            break;
          }
        }
        if (matchedObject && column.properties.uid !== matchedObject['uid']) {
          column.properties.uid = matchedObject['uid'];
        }
      });
    }
    if (this.isLoad) {
      if (this.enablePersistence) {
        this.updateTreeColumns();
      }
      this.updateCurrentViewData();
      if (!this.enableVirtualization) {
        this.updateContentHeight();
      } else if (this.virtualScrollModule && this.enableVirtualization) {
        this.ganttChartModule.virtualRender.adjustTable();
        this.ganttChartModule.scrollObject.updateTopPosition();
      }
      if (!this.isTreeGridRendered) {
        this.isTreeGridRendered = true;
        // let toolbarHeight: number = 0;
        // if (!isNullOrUndefined(this.toolbarModule) && !isNullOrUndefined(this.toolbarModule.element)) {
        //     toolbarHeight = this.toolbarModule.element.offsetHeight;
        // }
        if (this.timelineModule.isSingleTier) {
          this.element.classList.add(ganttSingleTimeline);
        } else {
          this.element.classList.remove(ganttSingleTimeline);
        }
        var timelineContainer_1 = this.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'];
        scrollContainer['style'].height = 'calc(100% - ' + timelineContainer_1 + 'px)';
        this.treeGrid.setProperties({
          height: '100%'
        }, false);
        this.notify('tree-grid-created', {});
        this.createGanttPopUpElement();
        if (!isNullOrUndefined(this.loadingIndicator) && this.loadingIndicator.indicatorType === 'Shimmer') {
          this.hideMaskRow();
        } else {
          this.hideSpinner();
        }
        setValue('isGanttCreated', true, args);
        this.renderComplete();
      }
    } else {
      this.getCurrentRecords(args);
    }
    if (this.loadChildOnDemand && this.taskFields.hasChildMapping) {
      this.updateContentHeight();
    }
    if (this.enableCriticalPath && this.criticalPathModule) {
      this.criticalPathModule.showCriticalPath(this.enableCriticalPath);
    }
    this.notify('recordsUpdated', {});
    for (var i = 0; i < document.getElementsByClassName('e-timeline-header-table-container').length; i++) {
      for (var j = 0; j < document.getElementsByClassName('e-timeline-header-table-container')[i].children[0].children[0].children.length; j++) {
        var childElement = document.getElementsByClassName('e-timeline-header-table-container')[i].children[0].children[0].children[j].children[0];
        if (childElement) {
          childElement.setAttribute('tabindex', '-1');
        }
      }
    }
    var criticalModule = this.criticalPathModule;
    if (this.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
      this.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
    }
    this.initialChartRowElements = this.ganttChartModule.getChartRows();
    this.isLoad = false;
    this.isExpandPerformed = !this.enableVirtualization ? false : this.isExpandPerformed;
    if (!this.isExpandPerformed) {
      this.previousFlatData = extend([], this.flatData, [], true);
    }
    this.trigger('dataBound', args);
  };
  /**
   * @param {object} args .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  Gantt.prototype.getCurrentRecords = function (args) {
    if (this.predecessorModule && this.taskFields.dependency) {
      this.connectorLineModule.removePreviousConnectorLines(this.currentViewData);
    }
    this.updateCurrentViewData();
    if (!this.enableVirtualization) {
      this.updateContentHeight();
    }
    // this.chartRowsModule.refreshGanttRows();
    if (this.virtualScrollModule && this.enableVirtualization) {
      this.ganttChartModule.virtualRender.adjustTable();
      this.ganttChartModule.scrollObject.updateTopPosition();
    }
  };
  /**
   * Called internally, if any of the property value changed.
   *
   * @param {GanttModel} newProp - Defines the New GanttModel.
   * @param {GanttModel} oldProp - Defines the old GanttModel.
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  Gantt.prototype.onPropertyChanged = function (newProp, oldProp) {
    var isRefresh = false;
    // eslint-disable-next-line
    for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
      var prop = _a[_i];
      switch (prop) {
        case 'allowSelection':
        case 'allowRowDragAndDrop':
        case 'allowFiltering':
        case 'showColumnMenu':
        case 'allowResizing':
        case 'allowReordering':
        case 'allowSorting':
        case 'enableImmutableMode':
          this.treeGrid[prop] = this[prop];
          this.treeGrid.dataBind();
          break;
        case 'workWeek':
          this.dataOperation.getNonWorkingDayIndex();
          this.dataOperation.reUpdateGanttData();
          this.chartRowsModule.initiateTemplates();
          if (this.taskFields.dependency) {
            this.predecessorModule.updatedRecordsDateByPredecessor();
          }
          this.chartRowsModule.refreshGanttRows();
          this.treeGrid.refreshColumns();
          this.timelineModule.refreshTimeline();
          break;
        case 'toolbar':
          this.notify('ui-toolbarupdate', {
            module: 'toolbar',
            properties: newProp
          });
          break;
        case 'columnMenuItems':
          this.treeGrid.grid.columnMenuItems = getActualProperties(this.columnMenuItems);
          break;
        case 'eventMarkers':
        case 'highlightWeekends':
          this.notify('ui-update', {
            module: 'day-markers',
            properties: newProp
          });
          break;
        case 'sortSettings':
          this.treeGrid.sortSettings = getActualProperties(this.sortSettings);
          this.treeGrid.dataBind();
          break;
        case 'timelineSettings':
          this.timelineModule.refreshTimeline();
          break;
        case 'rowHeight':
        case 'taskbarHeight':
          this.treeGrid.rowHeight = this.rowHeight;
          this.treeGrid.dataBind();
          this.chartRowsModule.initiateTemplates();
          this.timelineModule.updateChartByNewTimeline();
          if (this.taskFields.dependency) {
            this.ganttChartModule.reRenderConnectorLines();
          }
          break;
        case 'timezone':
          this.dataOperation.checkDataBinding(true);
          break;
        case 'enableCriticalPath':
          if (!isNullOrUndefined(this.loadingIndicator) && this.loadingIndicator.indicatorType === 'Shimmer') {
            this.hideMaskRow();
          } else {
            this.hideSpinner();
          }
          if (this.enableCriticalPath && this.criticalPathModule) {
            this.criticalPathModule.showCriticalPath(this.enableCriticalPath);
            var criticalModule = this.criticalPathModule;
            if (criticalModule.criticalPathCollection) {
              this.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
            }
          } else {
            this.removeCriticalPathStyles();
          }
          break;
        case 'filterSettings':
          if (isNullOrUndefined(newProp.filterSettings)) {
            this.filterSettings.columns = [];
          }
          this.treeGrid.filterSettings = getActualProperties(this.filterSettings);
          this.treeGrid.dataBind();
          break;
        case 'gridLines':
          this.treeGrid.gridLines = this.gridLines;
          this.treeGrid.dataBind();
          this.renderChartGridLines();
          break;
        case 'tooltipSettings':
          if (this.tooltipModule.toolTipObj) {
            this.tooltipModule.toolTipObj.destroy();
          }
          this.tooltipModule.createTooltip();
          break;
        case 'splitterSettings':
          this.splitterModule.updateSplitterPosition();
          break;
        case 'selectionSettings':
          this.treeGrid.selectionSettings = getActualProperties(this.selectionSettings);
          this.treeGrid.grid.selectionSettings.enableToggle = this.selectionSettings.enableToggle;
          this.treeGrid.dataBind();
          break;
        case 'searchSettings':
          if (newProp.searchSettings.key !== undefined) {
            this.treeGrid.grid.searchSettings = getActualProperties(this.searchSettings);
            this.treeGrid.grid.dataBind();
          }
          this.treeGrid.searchSettings = getActualProperties(this.searchSettings);
          this.treeGrid.dataBind();
          if (this.toolbarModule) {
            this.toolbarModule.updateSearchTextBox();
          }
          break;
        case 'labelSettings':
        case 'renderBaseline':
        case 'baselineColor':
          this.isFromRenderBaseline = true;
          this.chartRowsModule.initiateTemplates();
          this.chartRowsModule.refreshGanttRows();
          this.isFromRenderBaseline = false;
          break;
        case 'resourceIDMapping':
        case 'resourceNameMapping':
        case 'resources':
          this.dataOperation.reUpdateResources();
          this.treeGrid.refreshColumns();
          this.chartRowsModule.initiateTemplates();
          this.chartRowsModule.refreshGanttRows();
          break;
        case 'includeWeekend':
        case 'allowUnscheduledTasks':
        case 'holidays':
          this.isLoad = true;
          if (prop === 'holidays') {
            this.totalHolidayDates = this.dataOperation.getHolidayDates();
            this.notify('ui-update', {
              module: 'day-markers',
              properties: newProp
            });
          }
          this.dataOperation.reUpdateGanttData();
          this.treeGrid.refreshColumns();
          this.chartRowsModule.initiateTemplates();
          this.chartRowsModule.refreshGanttRows();
          this.isLoad = false;
          if (this.taskFields.dependency) {
            this.predecessorModule.updatedRecordsDateByPredecessor();
            this.treeGrid.refreshColumns();
            this.chartRowsModule.refreshGanttRows();
          }
          break;
        case 'dayWorkingTime':
        case 'weekWorkingTime':
          isRefresh = true;
          break;
        case 'addDialogFields':
        case 'editDialogFields':
          if (this.editModule && this.editModule.dialogModule) {
            this.editModule.dialogModule.processDialogFields();
          }
          break;
        case 'columns':
          this.treeGridModule.treeGridColumns = [];
          this.treeGridModule.validateGanttColumns();
          this.treeGrid.columns = this.treeGridModule.treeGridColumns;
          this.treeGrid.refreshColumns();
          this.chartRowsModule.initiateTemplates();
          this.timelineModule.updateChartByNewTimeline();
          break;
        case 'width':
        case 'height':
          this.reUpdateDimention(prop);
          break;
        case 'editSettings':
          this.treeGrid.editSettings.allowAdding = this.editSettings.allowAdding;
          this.treeGrid.editSettings.allowDeleting = this.editSettings.allowDeleting;
          this.treeGrid.editSettings.showDeleteConfirmDialog = this.editSettings.showDeleteConfirmDialog;
          this.treeGrid.editSettings.allowEditing = this.editSettings.allowEditing;
          this.treeGrid.editSettings.allowNextRowEdit = this.editSettings.allowNextRowEdit;
          if (!isNullOrUndefined(this.editModule)) {
            this.editModule.reUpdateEditModules();
          }
          if (!isNullOrUndefined(this.toolbarModule)) {
            this.toolbarModule.refreshToolbarItems();
          }
          break;
        case 'connectorLineBackground':
        case 'connectorLineWidth':
          if (this.taskFields.dependency) {
            this.connectorLineModule.initPublicProp();
            this.ganttChartModule.reRenderConnectorLines();
          }
          break;
        case 'treeColumnIndex':
          this.treeGrid.treeColumnIndex = this.treeColumnIndex;
          break;
        case 'projectStartDate':
        case 'projectEndDate':
          this.timelineModule.isZoomToFit = false;
          this.dataOperation.calculateProjectDates();
          this.updateProjectDates(this.cloneProjectStartDate, this.cloneProjectEndDate, this.isTimelineRoundOff);
          break;
        case 'selectedRowIndex':
          if (!isNullOrUndefined(this.selectionModule)) {
            this.selectionModule.selectRowByIndex();
          }
          break;
        case 'dataSource':
          this.closeGanttActions();
          if (this.dataSource instanceof Object && isCountRequired(this)) {
            // In order to bind the observable data at load time, hasChildMapping is necessary to be mapped.
            this.treeGrid.hasChildMapping = 'isParent';
            var count = getValue('count', this.dataSource);
            this.treeGrid.dataSource = {
              result: this.flatData,
              count: count
            };
          } else {
            this.treeGrid.hasChildMapping = null;
          }
          this.dataOperation.checkDataBinding(true);
          break;
        case 'enableContextMenu':
        case 'contextMenuItems':
          if (this.enableContextMenu || prop === 'contextMenuItems') {
            this.notify('reRender-contextMenu', {
              module: 'contextMenu',
              enable: true
            });
          } else {
            this.treeGrid.contextMenuItems = [];
          }
          this.treeGrid.dataBind();
          break;
        case 'currencyCode':
        case 'locale':
        case 'enableRtl':
        case 'readOnly':
        case 'viewType':
        case 'taskFields':
        case 'allowTaskbarDragAndDrop':
        case 'allowTaskbarOverlap':
        case 'allowParentDependency':
        case 'enableMultiTaskbar':
          if (prop === 'locale') {
            this.isLocaleChanged = true;
          }
          if (prop === 'taskFields') {
            if (!isNullOrUndefined(newProp.taskFields.child)) {
              return;
            }
          }
          if (prop !== 'allowTaskbarDragAndDrop') {
            isRefresh = true;
          }
          break;
        case 'validateManualTasksOnLinking':
          this.validateManualTasksOnLinking = newProp.validateManualTasksOnLinking;
          break;
        case 'showOverAllocation':
          this.updateOverAllocationCotainer();
          break;
      }
    }
    if (isRefresh) {
      if (this.isLoad && this.contentMaskTable) {
        this.contentMaskTable = null;
      }
      this.refresh();
    }
  };
  Gantt.prototype.updateOverAllocationCotainer = function () {
    if (this.showOverAllocation) {
      this.ganttChartModule.renderOverAllocationContainer();
    } else {
      var rangeContainer$1 = this.element.querySelector('.' + rangeContainer);
      if (rangeContainer$1) {
        rangeContainer$1.innerHTML = '';
      }
    }
  };
  /**
   * Returns the properties to be maintained in persisted state.
   *
   * @returns {string} .
   * @private
   */
  Gantt.prototype.getPersistData = function () {
    var keyEntity = ['sortSettings', 'filterSettings', 'columns', 'searchSettings', 'selectedRowIndex', 'treeColumnIndex', 'currentZoomingLevel', 'cloneProjectStartDate', 'cloneProjectEndDate', 'splitterSettings'];
    var ignoreOnPersist = {
      filterSettings: ['type', 'mode', 'showFilterBarStatus', 'immediateModeDelay', 'ignoreAccent', 'hierarchyMode'],
      searchSettings: ['fields', 'operator', 'ignoreCase'],
      sortSettings: [],
      columns: [],
      selectedRowIndex: []
    };
    var ignoreOnColumn = ['filter', 'edit', 'filterBarTemplate', 'headerTemplate', 'template', 'commandTemplate', 'commands', 'dataSource'];
    for (var i = 0; i < keyEntity.length; i++) {
      var currentObject = this[keyEntity[i]];
      for (var k = 0, val = ignoreOnPersist[keyEntity[i]]; !isNullOrUndefined(val) && k < val.length; k++) {
        var objVal = val[k];
        delete currentObject[objVal];
      }
    }
    this.ignoreInArrays(ignoreOnColumn, this.columns);
    return this.addOnPersist(keyEntity);
  };
  Gantt.prototype.ignoreInArrays = function (ignoreOnColumn, columns) {
    for (var i = 0; i < columns.length; i++) {
      this.ignoreInColumn(ignoreOnColumn, columns[i]);
    }
  };
  Gantt.prototype.ignoreInColumn = function (ignoreOnColumn, column) {
    for (var i = 0; i < ignoreOnColumn.length; i++) {
      delete column[ignoreOnColumn[i]];
      column.filter = {};
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  Gantt.prototype.destroy = function () {
    this.notify('destroy', {});
    this.unwireEvents();
    if (!isNullOrUndefined(this.validationDialogElement) && !this.validationDialogElement.isDestroyed) {
      this.validationDialogElement.destroy();
    }
    var modules = ['ganttChartModule', 'timelineModule', 'chartRowsModule', 'treeGridModule', 'ganttDataUpdatesModule', 'dateValidationModule', 'tooltipModule'];
    for (var i = 0; i < modules.length; i++) {
      if (this[modules[i]]) {
        this[modules[i]] = null;
      }
    }
    if (this.keyboardModule) {
      this.keyboardModule.destroy();
    }
    if (this.editModule && this.editModule.dialogModule) {
      this.editModule.dialogModule.destroy();
    }
    if (this.splitterModule) {
      this.splitterModule['destroy']();
    }
    if (this.toolbarModule && this.toolbarModule.toolbar) {
      this.toolbarModule.toolbar.destroy();
    }
    if (!isNullOrUndefined(this.dayMarkersModule)) {
      if (!isNullOrUndefined(this.dayMarkersModule['eventMarkerRender']) && !isNullOrUndefined(this.dayMarkersModule['eventMarkerRender'].eventMarkersContainer)) {
        this.dayMarkersModule['eventMarkerRender'].eventMarkersContainer = null;
      }
      if (!isNullOrUndefined(this.dayMarkersModule.nonworkingDayRender)) {
        if (!isNullOrUndefined(this.dayMarkersModule.nonworkingDayRender.nonworkingContainer)) {
          this.dayMarkersModule.nonworkingDayRender.nonworkingContainer = null;
        }
        if (!isNullOrUndefined(this.dayMarkersModule.nonworkingDayRender['weekendContainer'])) {
          this.dayMarkersModule.nonworkingDayRender['weekendContainer'] = null;
        }
        if (!isNullOrUndefined(this.dayMarkersModule.nonworkingDayRender['holidayContainer'])) {
          this.dayMarkersModule.nonworkingDayRender['holidayContainer'] = null;
        }
      }
    }
    if (this.connectorLineModule) {
      this.connectorLineModule.dependencyViewContainer = null;
      this.connectorLineModule.svgObject = null;
      this.connectorLineModule.renderer = null;
      this.connectorLineModule.tooltipTable = null;
    }
    this.treeGridPane = null;
    this.chartPane = null;
    this.initialChartRowElements = null;
    _super.prototype.destroy.call(this);
    this.chartVerticalLineContainer = null;
    this.element.innerHTML = '';
    removeClass([this.element], root);
    this.element.innerHTML = '';
    this.isTreeGridRendered = false;
    this.resetTemplates();
  };
  /**
   * Method to get taskbarHeight.
   *
   * @returns {number} .
   * @public
   */
  Gantt.prototype.getTaskbarHeight = function () {
    return this.chartRowsModule.taskBarHeight;
  };
  /**
   * To provide the array of modules needed for component rendering
   *
   * @returns {ModuleDeclaration[]} .
   * @hidden
   */
  Gantt.prototype.requiredModules = function () {
    var modules = [];
    if (this.isDestroyed) {
      return modules;
    }
    if (this.allowSorting) {
      modules.push({
        member: 'sort',
        args: [this]
      });
    }
    if (this.allowFiltering || this.toolbar && this.toolbar.indexOf('Search') !== -1) {
      modules.push({
        member: 'filter',
        args: [this]
      });
    }
    if (this.allowReordering) {
      modules.push({
        member: 'reorder',
        args: [this]
      });
    }
    if (this.allowExcelExport) {
      modules.push({
        member: 'excelExport',
        args: [this]
      });
    }
    if (this.allowRowDragAndDrop || this.allowTaskbarDragAndDrop) {
      modules.push({
        member: 'rowDragAndDrop',
        args: [this]
      });
    }
    if (this.enableCriticalPath) {
      modules.push({
        member: 'criticalPath',
        args: [this]
      });
    }
    if (this.enableUndoRedo) {
      modules.push({
        member: 'undoRedo',
        args: [this]
      });
    }
    if (this.allowResizing) {
      modules.push({
        member: 'resize',
        args: [this]
      });
    }
    if (this.toolbar && this.toolbar.length > 0) {
      modules.push({
        member: 'toolbar',
        args: [this]
      });
    }
    if (this.editSettings.allowAdding || this.editSettings.allowEditing || this.editSettings.allowDeleting || this.editSettings.allowTaskbarEditing || this.allowRowDragAndDrop) {
      modules.push({
        member: 'edit',
        args: [this]
      });
    }
    if (this.allowSelection) {
      modules.push({
        member: 'selection',
        args: [this]
      });
    }
    if (this.tooltipSettings.showTooltip) {
      modules.push({
        member: 'tooltip',
        args: [this]
      });
    }
    if (this.highlightWeekends || this.holidays && this.holidays.length > 0 || this.eventMarkers && this.eventMarkers.length > 0) {
      modules.push({
        member: 'dayMarkers',
        args: [this]
      });
    }
    if (this.enableContextMenu) {
      modules.push({
        member: 'contextMenu',
        args: [this]
      });
    }
    if (this.showColumnMenu) {
      modules.push({
        member: 'columnMenu',
        args: [this]
      });
    }
    if (this.allowPdfExport) {
      modules.push({
        member: 'pdfExport',
        args: [this]
      });
    }
    if (this.enableVirtualization) {
      modules.push({
        member: 'virtualScroll',
        args: [this]
      });
    }
    return modules;
  };
  /**
   * Sorts a column with the given options.
   *
   * @param {string} columnName - Defines the column name to be sorted.
   * @param {SortDirection} direction - Defines the direction of sorting field.
   * @param {boolean} isMultiSort - Specifies whether the previous sorted columns are to be maintained.
   * @returns {void} .
   */
  Gantt.prototype.sortColumn = function (columnName, direction, isMultiSort) {
    if (this.sortModule && this.allowSorting) {
      this.sortModule.sortColumn(columnName, direction, isMultiSort);
    }
  };
  Gantt.prototype.mergePersistGanttData = function () {
    if (!this.treeGrid) {
      this.treeGrid = new TreeGrid();
    }
    var persist1 = 'mergePersistGridData';
    this.treeGrid.grid[persist1].apply(this);
  };
  Gantt.prototype.mergeColumns = function (storedColumn, columns) {
    var persist2 = 'mergeColumns';
    this.treeGrid.grid[persist2].apply(this, [storedColumn, columns]);
  };
  Gantt.prototype.setFrozenCount = function () {
    var persist3 = 'setFrozenCount';
    this.treeGrid.grid["" + persist3].apply(this);
  };
  Gantt.prototype.splitFrozenCount = function (columns) {
    var persist4 = 'splitFrozenCount';
    this.treeGrid.grid["" + persist4].apply(this, [columns]);
  };
  Gantt.prototype.isFrozenGrid = function () {
    return this.treeGrid.grid.isFrozenGrid();
  };
  /**
   * Clears all the sorted columns of the Gantt.
   *
   * @returns {void} .
   */
  Gantt.prototype.clearSorting = function () {
    this.sortModule.clearSorting();
  };
  /**
   * To validate and render chart horizontal and vertical lines in the Gantt
   *
   * @returns {void} .
   * @hidden
   */
  Gantt.prototype.renderChartGridLines = function () {
    var className = 'e-chart-row-border';
    var verticalLines = this.chartVerticalLineContainer;
    var chartRowsTD = document.getElementById(this.element.id + 'GanttTaskTableBody').querySelectorAll('td');
    if (this.gridLines === 'Vertical') {
      if (isNullOrUndefined(verticalLines)) {
        this.renderChartVerticalLines();
      } else {
        if (window.getComputedStyle(verticalLines).display === 'none') {
          verticalLines.style.display = 'block';
        }
      }
      if (chartRowsTD && chartRowsTD.length > 0 && chartRowsTD[0].classList.contains(className)) {
        for (var c = 0; c < chartRowsTD.length; c++) {
          removeClass([chartRowsTD[c]], className);
        }
      }
    } else if (this.gridLines === 'Horizontal') {
      if (!isNullOrUndefined(verticalLines)) {
        verticalLines.style.display = 'none';
      }
      if (chartRowsTD && chartRowsTD.length > 0 && !chartRowsTD[0].classList.contains(className)) {
        for (var c = 0; c < chartRowsTD.length; c++) {
          addClass([chartRowsTD[c]], className);
        }
      }
    } else if (this.gridLines === 'Both') {
      if (isNullOrUndefined(verticalLines)) {
        this.renderChartVerticalLines();
      } else {
        if (window.getComputedStyle(verticalLines).display === 'none') {
          verticalLines.style.display = 'block';
        }
      }
      if (chartRowsTD && chartRowsTD.length > 0 && !chartRowsTD[0].classList.contains(className)) {
        for (var c = 0; c < chartRowsTD.length; c++) {
          addClass([chartRowsTD[c]], className);
        }
      }
    } else if (this.gridLines === 'None') {
      if (!isNullOrUndefined(verticalLines) && window.getComputedStyle(verticalLines).display !== 'none') {
        verticalLines.style.display = 'none';
      }
      if (chartRowsTD && chartRowsTD.length > 0 && chartRowsTD[0].classList.contains(className)) {
        for (var c = 0; c < chartRowsTD.length; c++) {
          removeClass([chartRowsTD[c]], className);
        }
      }
    }
  };
  /**
   * To update height of the Grid lines in the Gantt chart side.
   *
   * @returns {void} .
   * @private
   */
  Gantt.prototype.updateGridLineContainerHeight = function () {
    if (this.chartVerticalLineContainer) {
      this.chartVerticalLineContainer.style.height = formatUnit(this.getContentHeight());
    }
  };
  /**
   * To get actual height of grid lines, holidays, weekend and event markers.
   *
   * @returns {number} .
   * @private
   */
  Gantt.prototype.getContentHeight = function () {
    var scrollHeight = this.ganttChartModule.scrollElement.offsetHeight - 16; //16 is horizontal scrollbar height
    var contentHeight = this.ganttChartModule.chartBodyContent.offsetHeight;
    var height = contentHeight < scrollHeight ? contentHeight : scrollHeight;
    return height;
  };
  /**
   * To update height of the Grid lines in the Gantt chart side.
   *
   * @param {boolean} currentProp - Update current property change in the Gantt chart.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.reUpdateDimention = function (currentProp) {
    var toolbarHeight = 0;
    this.calculateDimensions();
    if (!isNullOrUndefined(this.toolbarModule) && !isNullOrUndefined(this.toolbarModule.element)) {
      this.toolbarModule.toolbar.refresh();
      this.toolbarModule.refreshToolbarItems();
      toolbarHeight = this.toolbarModule.element.offsetHeight;
    }
    this.treeGrid.height = this.ganttHeight - toolbarHeight - this.treeGrid.grid.getHeaderContent().offsetHeight;
    this.splitterModule.splitterObject.height = (this.ganttHeight - toolbarHeight).toString();
    if (!isNullOrUndefined(this.chartVerticalLineContainer)) {
      this.chartVerticalLineContainer.style.height = this.ganttHeight + 'px';
    }
    if (!isNullOrUndefined(this.dayMarkersModule)) {
      var holidayContainer = getValue('nonworkingDayRender.holidayContainer', this.dayMarkersModule);
      var weekendContainer = getValue('nonworkingDayRender.weekendContainer', this.dayMarkersModule);
      var eventMarkersContainer = getValue('eventMarkerRender.eventMarkersContainer', this.dayMarkersModule);
      if (holidayContainer) {
        holidayContainer.style.height = this.ganttHeight + 'px';
      }
      if (weekendContainer) {
        weekendContainer.style.height = this.ganttHeight + 'px';
      }
      if (eventMarkersContainer) {
        eventMarkersContainer.style.height = this.ganttHeight + 'px';
      }
    }
    if (currentProp === 'width') {
      this.splitterModule.splitterObject.width = this.ganttWidth.toString();
    }
    this.ganttChartModule.scrollObject.setHeight(this.ganttHeight - this.ganttChartModule.chartTimelineContainer.offsetHeight - toolbarHeight);
  };
  /**
   * To render vertical lines in the Gantt chart side.
   *
   * @returns {void} .
   */
  Gantt.prototype.renderChartVerticalLines = function () {
    if (!this.element.contains(this.chartVerticalLineContainer)) {
      this.chartVerticalLineContainer = createElement('div', {
        id: this.element.id + 'line-container',
        styles: 'position:absolute;height:100%;'
      });
      this.ganttChartModule.chartBodyContent.insertBefore(this.chartVerticalLineContainer, this.ganttChartModule.chartBodyContent.lastChild);
    }
    this.chartVerticalLineContainer.innerHTML = '';
    var headerTable = this.element.getElementsByClassName('e-timeline-header-table-container')[1];
    if (isNullOrUndefined(headerTable)) {
      headerTable = this.element.getElementsByClassName('e-timeline-header-table-container')[0];
    }
    var thElements = headerTable.getElementsByTagName('th');
    var thLength = thElements.length;
    var thWidth;
    var leftPos = 0;
    var containerDiv = createElement('div');
    for (var n = 0; n < thLength; n++) {
      leftPos = n === 0 ? -1 : leftPos + parseFloat(thWidth);
      thWidth = thElements[n].style.width;
      var divElement = createElement('div', {
        className: 'e-line-container-cell',
        styles: (this.enableRtl ? 'right:' + (leftPos + 1) : 'left:' + (leftPos !== -1 ? leftPos + 0.3 : leftPos)) + 'px'
      });
      containerDiv.appendChild(divElement);
    }
    this.chartVerticalLineContainer.innerHTML = containerDiv.innerHTML;
  };
  /**
   * Method to get default localized text of the Gantt.
   *
   * @returns {void} .
   * @hidden
   */
  Gantt.prototype.getDefaultLocale = function () {
    var ganttLocale = {
      emptyRecord: 'No records to display',
      id: 'ID',
      name: 'Name',
      startDate: 'Start Date',
      endDate: 'End Date',
      duration: 'Duration',
      progress: 'Progress',
      dependency: 'Dependency',
      notes: 'Notes',
      criticalPath: 'Critical Path',
      undo: 'Undo',
      redo: 'Redo',
      baselineStartDate: 'Baseline Start Date',
      baselineEndDate: 'Baseline End Date',
      taskMode: 'Task Mode',
      changeScheduleMode: 'Change Schedule Mode',
      subTasksStartDate: 'SubTasks Start Date',
      subTasksEndDate: 'SubTasks End Date',
      scheduleStartDate: 'Schedule Start Date',
      scheduleEndDate: 'Schedule End Date',
      auto: 'Auto',
      manual: 'Manual',
      type: 'Type',
      offset: 'Offset',
      resourceName: 'Resources',
      resourceID: 'Resource ID',
      day: 'day',
      hour: 'hour',
      minute: 'minute',
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      generalTab: 'General',
      customTab: 'Custom Columns',
      writeNotes: 'Write Notes',
      addDialogTitle: 'New Task',
      editDialogTitle: 'Task Information',
      add: 'Add',
      edit: 'Edit',
      update: 'Update',
      delete: 'Delete',
      cancel: 'Cancel',
      search: 'Search',
      task: ' task',
      tasks: ' tasks',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      zoomToFit: 'Zoom to fit',
      excelExport: 'Excel export',
      csvExport: 'CSV export',
      pdfExport: 'PDF export',
      expandAll: 'Expand all',
      collapseAll: 'Collapse all',
      nextTimeSpan: 'Next timespan',
      prevTimeSpan: 'Previous timespan',
      saveButton: 'Save',
      taskBeforePredecessorFS: 'You moved "{0}" to start before "{1}" finishes and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskAfterPredecessorFS: 'You moved "{0}" away from "{1}" and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskBeforePredecessorSS: 'You moved "{0}" to start before "{1}" starts and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskAfterPredecessorSS: 'You moved "{0}" to start after "{1}" starts and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskBeforePredecessorFF: 'You moved "{0}" to finish before "{1}" finishes and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskAfterPredecessorFF: 'You moved "{0}" to finish after "{1}" finishes and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskBeforePredecessorSF: 'You moved "{0}" away from "{1}" to starts and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      taskAfterPredecessorSF: 'You moved "{0}" to finish after "{1}" starts and the two tasks are linked.' + 'As the result, the links cannot be honored. Select one action below to perform',
      okText: 'Ok',
      confirmDelete: 'Are you sure you want to Delete Record?',
      from: 'From',
      to: 'To',
      taskLink: 'Task Link',
      lag: 'Lag',
      start: 'Start',
      finish: 'Finish',
      enterValue: 'Enter the value',
      taskInformation: 'Task Information',
      deleteTask: 'Delete Task',
      deleteDependency: 'Delete Dependency',
      convert: 'Convert',
      save: 'Save',
      above: 'Above',
      below: 'Below',
      child: 'Child',
      milestone: 'Milestone',
      toTask: 'To Task',
      toMilestone: 'To Milestone',
      eventMarkers: 'Event markers',
      leftTaskLabel: 'Left task label',
      rightTaskLabel: 'Right task label',
      timelineCell: 'Timeline cell',
      confirmPredecessorDelete: 'Are you sure you want to remove dependency link?',
      unit: 'Unit',
      work: 'Work',
      taskType: 'Task Type',
      unassignedTask: 'Unassigned Task',
      group: 'Group',
      indent: 'Indent',
      outdent: 'Outdent',
      segments: 'Segments',
      splitTask: 'Split Task',
      mergeTask: 'Merge Task',
      left: 'Left',
      right: 'Right',
      FF: 'FF',
      FS: 'FS',
      SF: 'SF',
      SS: 'SS'
    };
    return ganttLocale;
  };
  /**
   * To remove sorted records of particular column.
   *
   * @param {string} columnName - Defines the sorted column name.
   * @returns {void} .
   */
  Gantt.prototype.removeSortColumn = function (columnName) {
    this.sortModule.removeSortColumn(columnName);
  };
  /**
   *
   * @param {object} args -Defines the edited event args.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.actionBeginTask = function (args) {
    this.trigger('actionBegin', args);
    if (!isNullOrUndefined(this.loadingIndicator) && this.loadingIndicator.indicatorType === 'Shimmer') {
      this.showMaskRow();
    } else {
      this.showSpinner();
    }
  };
  /**
   * To move horizontal scroll bar of Gantt to specific date.
   *
   * @param  {string} date - Defines the date to which the Gantt chart should scroll.
   * @returns {void} .
   */
  Gantt.prototype.scrollToDate = function (date) {
    var tempDate = this.dateValidationModule.getDateFromFormat(date);
    var left = this.dataOperation.getTaskLeft(tempDate, false);
    this.ganttChartModule.updateScrollLeft(left);
  };
  /**
   * To move horizontal scroll bar of Gantt to specific task id.
   *
   * @param  {string} taskId - Defines the task id of data.
   * @returns {void} .
   */
  Gantt.prototype.scrollToTask = function (taskId) {
    if (this.ids.indexOf(taskId) !== -1) {
      var left = this.flatData[this.ids.indexOf(taskId)].ganttProperties.left;
      this.ganttChartModule.updateScrollLeft(left);
    }
  };
  /**
   * To update the horizontal (left) and vertical (top) scroll positions of the Gantt chart.
   *
   * @param  {number} left - Defines the scroll left value of chart side.
   * @param  {number} top - Defines the scroll top value of chart side.
   * @returns {void} .
   */
  Gantt.prototype.updateChartScrollOffset = function (left, top) {
    if (!isNullOrUndefined(left)) {
      left = this.ganttChartModule.scrollElement.scrollWidth <= left ? this.ganttChartModule.scrollElement.scrollWidth : left;
      this.ganttChartModule.scrollObject.setScrollLeft(left, this.enableRtl ? -1 : 0);
    }
    if (!isNullOrUndefined(top)) {
      top = this.ganttChartModule.scrollElement.scrollHeight <= top ? this.ganttChartModule.scrollElement.scrollHeight : top;
      this.ganttChartModule.scrollObject.setScrollTop(top);
    }
  };
  /**
   * Get parent task by clone parent item.
   *
   * @param {IParent} cloneParent - Defines the clone parent item.
   * @returns {IGanttData} .
   * @hidden
   */
  Gantt.prototype.getParentTask = function (cloneParent) {
    if (isNullOrUndefined(cloneParent)) {
      return null;
    }
    if (!this.autoCalculateDateScheduling && this.dataMap && this.dataMap.size > 0 && !this.taskFields.hasChildMapping && this.isLoad) {
      var parent_1 = this.dataMap.get(cloneParent.uniqueID);
      if (parent_1) {
        return parent_1;
      }
    } else {
      var parent_2 = this.flatData.find(function (val) {
        return cloneParent.uniqueID === val.uniqueID;
      });
      if (parent_2) {
        return parent_2;
      }
    }
    return null;
  };
  /**
   * Get parent task by clone parent item.
   *
   * @param {IGanttData} ganttRecord -Defines the Gantt record.
   * @param {number} level -Defines the selected record level.
   * @returns {IGanttData} .
   * @hidden
   */
  Gantt.prototype.getRootParent = function (ganttRecord, level) {
    if (ganttRecord.level === level) {
      return ganttRecord;
    }
    return this.getRootParent(this.getParentTask(ganttRecord.parentItem), level);
  };
  /**
   * Filters TreeGrid row by column name with the given options.
   *
   * @param  {string} fieldName - Defines the field name of the column.
   * @param  {string} filterOperator - Defines the operator to filter records.
   * @param  {string | number | Date | boolean | number[] | string[] | Date[] | boolean[]} filterValue - Defines the value
   *  used to filter records.
   * @param  {string} predicate - Defines the relationship between one filter query and another by using AND or OR predicate.
   * @param  {boolean} matchCase - If match case is set to true, TreeGrid filters the records with exact match.if false, it filters case
   * insensitive records (uppercase and lowercase letters treated the same).
   * @param  {boolean} ignoreAccent - If ignoreAccent set to true,
   * then filter ignores the diacritic characters or accents while filtering.
   * @returns {void} .
   */
  Gantt.prototype.filterByColumn = function (fieldName, filterOperator, filterValue, predicate, matchCase, ignoreAccent) {
    this.treeGrid.filterByColumn(fieldName, filterOperator, filterValue, predicate, matchCase, ignoreAccent);
  };
  /**
   * Export Gantt data to Excel file(.xlsx).
   *
   * @param  {ExcelExportProperties} excelExportProperties - Defines the export properties of the Gantt.
   * @param  {boolean} isMultipleExport - Define to enable multiple export.
   * @param  {workbook} workbook - Defines the Workbook if multiple export is enabled.
   * @param  {boolean} isBlob - If 'isBlob' set to true, then it will be returned as blob data.
   * @returns {Promise<any>} .
   */
  Gantt.prototype.excelExport = function (excelExportProperties, isMultipleExport, /* eslint-disable-next-line */
  workbook, isBlob) {
    return this.excelExportModule ? this.treeGrid.excelExport(excelExportProperties, isMultipleExport, workbook, isBlob) : null;
  };
  /**
   * Export Gantt data to CSV file.
   *
   * @param  {ExcelExportProperties} excelExportProperties - Defines the export properties of the Gantt.
   * @param  {boolean} isMultipleExport - Define to enable multiple export.
   * @param  {workbook} workbook - Defines the Workbook if multiple export is enabled.
   * @param  {boolean} isBlob - If 'isBlob' set to true, then it will be returned as blob data.
   * @returns {Promise<any>} .
   */
  Gantt.prototype.csvExport = function (excelExportProperties, /* eslint-disable-next-line */
  isMultipleExport, workbook, isBlob) {
    return this.excelExportModule ? this.treeGrid.csvExport(excelExportProperties, isMultipleExport, workbook, isBlob) : null;
  };
  /**
   * Export Gantt data to PDF document.
   *
   * @param  {PdfExportProperties} pdfExportProperties - Defines the export properties of the Gantt.
   * @param  {isMultipleExport} isMultipleExport - Define to enable multiple export.
   * @param  {pdfDoc} pdfDoc - Defined the Pdf Document if multiple export is enabled.
   * @param  {boolean} isBlob - If the 'isBlob' parameter is set to true, the method returns PDF data as a blob instead of exporting it as a down-loadable PDF file. The default value is false.
   * @returns {Promise<any>} .
   */
  Gantt.prototype.pdfExport = function (pdfExportProperties, isMultipleExport, pdfDoc, isBlob) {
    return this.pdfExportModule ? this.pdfExportModule.export(pdfExportProperties, isMultipleExport, pdfDoc, isBlob) : null;
  };
  /**
   * Clears the filtered columns in Gantt.
   *
   * Can also be used to clear filtering of a specific column in Gantt.
   *
   * @param {string[]} fields - Defines the specific column to remove filter.
   * @returns {void} .
   */
  Gantt.prototype.clearFiltering = function (fields) {
    this.treeGrid.grid.clearFiltering(fields);
  };
  /**
   * Removes filtered column by field name.
   *
   * @param  {string} field - Defines column field name to remove filter.
   * @returns {void} .
   * @hidden
   */
  Gantt.prototype.removeFilteredColsByField = function (field) {
    this.treeGrid.removeFilteredColsByField(field, false);
  };
  /**
   * Method to set holidays and non working days in date time and date picker controls
   *
   * @param {RenderDayCellEventArgs} args .
   * @returns {void} .
   * @private
   */
  Gantt.prototype.renderWorkingDayCell = function (args) {
    var includeWeekend = false;
    var isCustomManual = false;
    includeWeekend = this.taskMode !== 'Auto' && this.taskMode !== 'Custom' ? true : this.includeWeekend || !this.autoCalculateDateScheduling ? true : false;
    if (this.taskMode === 'Custom' && this.taskFields.manual) {
      var editModule = this.editModule;
      if (editModule && editModule.cellEditModule && editModule.cellEditModule.isCellEdit) {
        isCustomManual = editModule.cellEditModule.currentEditedRowData[this.taskFields.manual];
      } else if (editModule && editModule.dialogModule && editModule.dialogModule['isEdit']) {
        isCustomManual = editModule.dialogModule['editedRecord'][this.taskFields.manual];
      }
      includeWeekend = isCustomManual || this.includeWeekend || !this.autoCalculateDateScheduling;
    }
    var nonWorkingDays = !includeWeekend ? this.nonWorkingDayIndex : [];
    var holidays = this.totalHolidayDates;
    if (nonWorkingDays.length > 0 && nonWorkingDays.indexOf(args.date.getDay()) !== -1) {
      args.isDisabled = true;
    } else if (holidays.length > 0) {
      var tempDate = new Date(args.date.getTime());
      tempDate.setHours(0, 0, 0);
      if (holidays.indexOf(tempDate.getTime()) !== -1) {
        if (!this.autoCalculateDateScheduling || this.isLoad && !this.treeGrid.loadChildOnDemand && this.taskFields.hasChildMapping) {
          args.isDisabled = false;
        } else {
          args.isDisabled = true;
        }
      }
    }
  };
  /**
   * Updates the Gantt timeline to the previous time span by one unit.
   *
   * @param {string} mode - Render previous span of Timeline.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.previousTimeSpan = function (mode) {
    if (this.undoRedoModule && this['isUndoRedoItemPresent']('PreviousTimeSpan')) {
      if (this.undoRedoModule['redoEnabled']) {
        this.undoRedoModule['disableRedo']();
      }
      this.undoRedoModule['createUndoCollection']();
      var timeSpan = {};
      timeSpan['action'] = 'PreviousTimeSpan';
      timeSpan['previousTimelineStartDate'] = extend([], [], [this.timelineModule.timelineStartDate], true)[0];
      timeSpan['previousTimelineEndDate'] = extend([], [], [this.timelineModule.timelineEndDate], true)[0];
      this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1] = timeSpan;
    }
    this.timelineModule.performTimeSpanAction('prevTimeSpan', 'publicMethod', new Date(this.timelineModule.timelineStartDate.getTime()), new Date(this.timelineModule.timelineEndDate.getTime()), mode);
  };
  /**
   * To update timeline at end point with one unit.
   *
   * @param {string} mode - Render next span of Timeline.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.nextTimeSpan = function (mode) {
    if (this.undoRedoModule && this['isUndoRedoItemPresent']('NextTimeSpan')) {
      if (this.undoRedoModule['redoEnabled']) {
        this.undoRedoModule['disableRedo']();
      }
      this.undoRedoModule['createUndoCollection']();
      var timeSpan = {};
      timeSpan['action'] = 'NextTimeSpan';
      timeSpan['previousTimelineStartDate'] = extend([], [], [this.timelineModule.timelineStartDate], true)[0];
      timeSpan['previousTimelineEndDate'] = extend([], [], [this.timelineModule.timelineEndDate], true)[0];
      this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1] = timeSpan;
    }
    this.timelineModule.performTimeSpanAction('nextTimeSpan', 'publicMethod', new Date(this.timelineModule.timelineStartDate.getTime()), new Date(this.timelineModule.timelineEndDate.getTime()), mode);
  };
  /**
   * To validate project start date and end date.
   *
   * @param  {Date} startDate - Defines start date of project.
   * @param  {Date} endDate - Defines end date of project.
   * @param  {boolean} isTimelineRoundOff - Defines project start date and end date need to be round off or not.
   * @param {string} isFrom - Defines whether the call originates from a public method action or a taskbar editing action.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.updateProjectDates = function (startDate, endDate, isTimelineRoundOff, isFrom) {
    this.timelineModule.totalTimelineWidth = 0;
    this.cloneProjectStartDate = startDate;
    this.cloneProjectEndDate = endDate;
    this.isTimelineRoundOff = isTimelineRoundOff;
    this.timelineModule.refreshTimelineByTimeSpan();
    this.dataOperation.reUpdateGanttDataPosition();
    if (!this.pdfExportModule || this.pdfExportModule && !this.pdfExportModule.isPdfExport || this.pdfExportModule && this.pdfExportModule.isPdfExport && this.pdfExportModule.helper.exportProps && this.pdfExportModule.helper.exportProps.fitToWidthSettings && !this.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth) {
      this.timelineModule.updateChartByNewTimeline();
      var browserZoomLevel = window.outerWidth / window.innerWidth;
      // Handled zoomtofit horizontal scrollbar hide while performing different zooming levels in browser-Task(919516)
      if (this.timelineModule.isZoomToFit && !this.enableTimelineVirtualization && browserZoomLevel < 1) {
        var clientWidthDifference = void 0;
        if (this.enableVirtualization) {
          clientWidthDifference = Math.abs(this.timelineModule.totalTimelineWidth - this.element.getElementsByClassName('e-chart-scroll-container e-content')[0].clientWidth) + 1;
        } else {
          clientWidthDifference = Math.abs(this.timelineModule.totalTimelineWidth - this.ganttChartModule.chartBodyContent.parentElement.clientWidth);
        }
        this.ganttChartModule.chartBodyContent.style.width = formatUnit(this.timelineModule.totalTimelineWidth - clientWidthDifference);
      } else {
        this.ganttChartModule.chartBodyContent.style.width = formatUnit(this.timelineModule.totalTimelineWidth);
      }
      this.ganttChartModule.updateLastRowBottomWidth();
      if (this.taskFields.dependency) {
        this.ganttChartModule.reRenderConnectorLines();
      }
      if (isFrom !== 'beforeAdd') {
        this.notify('selectRowByIndex', {});
      }
    }
  };
  /**
   * Splits the taskbar of a specified task into segments based on the given date.
   *
   * @param  {string} taskId - Defines the id of a task to be split.
   * @param  {string} splitDate - Defines in which date the taskbar must be split up.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.splitTask = function (taskId, splitDate) {
    this.isEdit = true;
    this.chartRowsModule.splitTask(taskId, splitDate);
  };
  /**
   * Merge the split taskbar with the given segment indexes.
   *
   * @param  {string} taskId - Defines the id of a task to be split.
   * @param  {string} segmentIndexes - Defines the object array of indexes which must be merged.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.mergeTask = function (taskId, segmentIndexes) {
    this.chartRowsModule.mergeTask(taskId, segmentIndexes);
  };
  /**
   * Changes the TreeGrid column positions by field names.
   *
   * @param  {string} fromFName - Defines origin field name.
   * @param  {string} toFName - Defines destination field name.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.reorderColumns = function (fromFName, toFName) {
    this.treeGrid.reorderColumns(fromFName, toFName);
  };
  /**
   * Method to clear edited collections in gantt set edit flag value
   *
   * @param {boolean} isStart -Defines whether to initiate edit action.
   * @returns {void} .
   * @private
   */
  Gantt.prototype.initiateEditAction = function (isStart) {
    this.isOnEdit = isStart;
    this.previousRecords = {};
    this.editedRecords = [];
  };
  /**
   *
   * @param {string} field Method to update value in Gantt record and make clone record for this
   * @param {IGanttData | ITaskData} record .
   * @param {boolean} isTaskData .
   * @returns {void} .
   * @private
   */
  /* eslint-disable-next-line */
  Gantt.prototype.setRecordValue = function (field, value, record, isTaskData) {
    value = isUndefined(value) ? null : value;
    var resultIf = this.isOnEdit || this.isOnDelete ? true : false;
    if (resultIf) {
      this.makeCloneData(field, record, isTaskData);
      var ganttData = isTaskData ? record : record.ganttProperties;
      var id = ganttData.rowUniqueID;
      var task_1 = this.getRecordByID(id);
      var isValid = false;
      isValid = isNullOrUndefined(value) || !isNullOrUndefined(value) && !isNullOrUndefined(record["" + field]) && (value instanceof Date ? value.getTime() !== record["" + field].getTime() : record["" + field] !== value) ? true : isValid;
      if (task_1 && (this.editedRecords.indexOf(task_1) === -1 && isValid || this.editedRecords.length === 0)) {
        if (this.editModule['draggedRecord'] && this.editModule['draggedRecord'].ganttProperties.taskId === ganttData.taskId) {
          this.editedRecords.splice(0, 0, task_1);
        } else {
          this.editedRecords.push(task_1);
        }
        if (this.undoRedoModule) {
          if (this.undoRedoModule['changedRecords'].indexOf(task_1) === -1) {
            this.undoRedoModule['changedRecords'].push(extend({}, {}, task_1, true));
          }
          var currentAction = this.undoRedoModule['getUndoCollection'].length > 0 ? this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1]['action'] : null;
          if (this.editModule && this.editSettings.allowEditing && !this.undoRedoModule['isUndoRedoPerformed'] && !this.isOnDelete && !this.isOnAdded && currentAction !== 'outdent' && currentAction !== 'indent' && currentAction !== 'RowDragAndDrop' && currentAction !== 'TaskbarDragAndDrop') {
            var oldRecord = this.previousFlatData.filter(function (data) {
              return data.ganttProperties.taskId === task_1.ganttProperties.taskId;
            })[0];
            if (oldRecord && this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1] && this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1]['modifiedRecords']) {
              this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1]['modifiedRecords'].push(oldRecord);
            }
          }
        }
        if (this.enableImmutableMode) {
          this.modifiedRecords.push(task_1);
        }
      }
    }
    setValue(field, value, record);
  };
  Gantt.prototype.makeCloneData = function (field, record, isTaskData) {
    var cloneData;
    /* eslint-disable-next-line */
    var value = getValue(field, record);
    /* eslint-disable-next-line */
    var prevValue;
    /* eslint-disable-next-line */
    var clonedValue;
    if (isTaskData) {
      field = 'ganttProperties.' + field;
    }
    if (isNullOrUndefined(this.previousRecords[record.uniqueID])) {
      var tempData = {};
      this.previousRecords[record.uniqueID] = tempData;
    }
    /* eslint-disable-next-line */
    cloneData = this.previousRecords[record.uniqueID];
    /* eslint-disable-next-line */
    prevValue = getValue(field, cloneData);
    if (isUndefined(prevValue)) {
      if (value instanceof Date) {
        clonedValue = new Date(value.getTime());
      } else if (isObjectArray(value)) {
        clonedValue = extend([], value, [], true);
      } else if (isObject(value)) {
        clonedValue = extend({}, {}, value, true);
      } else {
        clonedValue = value;
      }
      if (!isUndefined(clonedValue)) {
        setValue(field, clonedValue, cloneData);
      } else {
        setValue(field, null, cloneData);
      }
    }
  };
  Gantt.prototype.closeGanttActions = function () {
    if (this.editModule) {
      if (this.editModule.cellEditModule && this.editModule.cellEditModule.isCellEdit) {
        this.treeGrid.closeEdit();
        this.editModule.cellEditModule.isCellEdit = false;
        if (!isNullOrUndefined(this.toolbarModule)) {
          this.toolbarModule.refreshToolbarItems();
        }
      } else if (this.editModule.dialogModule && this.editModule.dialogModule.dialogObj && this.editModule.dialogModule.dialogObj.visible) {
        this.editModule.dialogModule.dialogObj.hide();
        this.editModule.dialogModule.dialogClose();
      }
    }
  };
  /**
   * Method to get task by uniqueId value.
   *
   * @param {string} id - Defines the task id.
   * @returns {IGanttData} .
   * @isGenericType true
   */
  Gantt.prototype.getTaskByUniqueID = function (id) {
    var value = this.flatData.filter(function (val) {
      return val.uniqueID === id;
    });
    if (value.length > 0) {
      return value[0];
    } else {
      return null;
    }
  };
  /**
   * Method to get record by id value.
   *
   * @param {string} id - Defines the id of record.
   * @returns {IGanttData} .
   * @isGenericType true
   */
  Gantt.prototype.getRecordByID = function (id) {
    if (isNullOrUndefined(id)) {
      return null;
    }
    return this.flatData[this.ids.indexOf(id.toString())];
  };
  /**
   * Method to set splitter position.
   *
   * @param {string|number} value - Define value to splitter settings property.
   * @param {string} type - Defines name of internal splitter settings property.
   * @returns {void} .
   */
  Gantt.prototype.setSplitterPosition = function (value, type) {
    var tempSplitterSettings = {};
    tempSplitterSettings[type] = value;
    var splitterPosition = this.splitterModule.calculateSplitterPosition(tempSplitterSettings);
    switch (type) {
      case 'view':
        this.splitterSettings.view = tempSplitterSettings[type];
        break;
      case 'columnIndex':
        this.splitterSettings.columnIndex = tempSplitterSettings[type];
        break;
      case 'position':
        this.splitterSettings.position = tempSplitterSettings[type];
        break;
    }
    var pane1 = this.splitterModule.splitterObject.element.querySelectorAll('.e-pane')[0];
    var pane2 = this.splitterModule.splitterObject.element.querySelectorAll('.e-pane')[1];
    this.splitterModule.splitterPreviousPositionGrid = pane1.scrollWidth + 1 + 'px';
    this.splitterModule.splitterPreviousPositionChart = pane2.scrollWidth + 1 + 'px';
    this.splitterModule.splitterObject.paneSettings[0].size = splitterPosition;
    this.splitterModule.splitterObject.paneSettings[1].size = parseFloat('99.75%') - parseFloat(splitterPosition) + '%';
    this.splitterModule.triggerCustomResizedEvent();
  };
  /**
   * Expand the records by index value.
   *
   * @param {number[] | number} index - Defines the index of rows to be expand.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.expandByIndex = function (index) {
    if (typeof index === 'number') {
      var args = this.contructExpandCollapseArgs(null, index);
      this.ganttChartModule.isExpandCollapseFromChart = true;
      this.ganttChartModule.expandGanttRow(args);
    } else {
      for (var i = 0; i < index.length; i++) {
        if (typeof index[i] === 'number') {
          var ind = index[i];
          var args = this.contructExpandCollapseArgs(null, ind);
          this.ganttChartModule.isExpandCollapseFromChart = true;
          this.ganttChartModule.expandGanttRow(args);
        }
      }
    }
  };
  /**
   * Expand the record by task id.
   *
   * @param {number} id - Defines the id of task.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.expandByID = function (id) {
    if (this.enableVirtualization) {
      this.isExpandPerformed = true;
    }
    var args = this.contructExpandCollapseArgs(id);
    this.ganttChartModule.isExpandCollapseFromChart = true;
    this.ganttChartModule.expandGanttRow(args);
  };
  /**
   * Collapse the record by index value.
   *
   * @param {number} index - Defines the index of row.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.collapseByIndex = function (index) {
    var args = this.contructExpandCollapseArgs(null, index);
    this.ganttChartModule.isExpandCollapseFromChart = true;
    this.ganttChartModule.collapseGanttRow(args);
  };
  /**
   * Collapse the record by id value.
   *
   * @param {number} id - Defines the id of task.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.collapseByID = function (id) {
    var args = this.contructExpandCollapseArgs(id);
    this.ganttChartModule.isExpandCollapseFromChart = true;
    this.ganttChartModule.collapseGanttRow(args);
  };
  /**
   * Method to add record.
   *
   * @param {Object[] | IGanttData | Object} data - Defines record to add.
   * @param {RowPosition} rowPosition - Defines the position of row.
   * @param {number} rowIndex - Defines the row index.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.addRecord = function (data, rowPosition, rowIndex) {
    if (this.editModule && this.editSettings.allowAdding) {
      if (this.viewType === 'ResourceView') {
        this.editModule.addRowPosition = rowPosition;
        this.editModule.addRowIndex = rowIndex;
        var resources = void 0;
        if (!isNullOrUndefined(data)) {
          if (this.undoRedoModule && this.undoRedoModule['isUndoRedoPerformed']) {
            resources = data.ganttProperties.resourceInfo;
          } else {
            resources = data[this.taskFields.resourceInfo];
          }
        }
        var id = void 0;
        var parentTask = void 0;
        if (!isNullOrUndefined(resources) && resources.length) {
          for (var i = 0; i < resources.length; i++) {
            id = typeof resources[i] === 'object' ? resources[i][this.resourceFields.id] : resources[0];
            parentTask = this.flatData[this.getTaskIds().indexOf('R' + id)];
            if (parentTask) {
              break;
            }
          }
          if (parentTask && (parentTask.level === 0 || parentTask.childRecords.length)) {
            var dropChildRecord = parentTask.childRecords[rowIndex];
            if (this.viewType === 'ResourceView' && this.undoRedoModule && this.undoRedoModule['isUndoRedoPerformed']) {
              this.editModule.addRecord(data, rowPosition, rowIndex);
            } else if (dropChildRecord) {
              var position = rowPosition === 'Above' || rowPosition === 'Below' ? rowPosition : 'Child';
              if (position === 'Child') {
                this.editModule.addRecord(data, position, this.getTaskIds().indexOf('R' + id));
              } else {
                this.editModule.addRecord(data, position, this.flatData.indexOf(dropChildRecord));
              }
            } else {
              this.editModule.addRecord(data, 'Child', this.getTaskIds().indexOf('R' + id));
            }
          } else {
            this.editModule.addRecord(data, 'Bottom');
          }
        } else {
          if (this.undoRedoModule && this.undoRedoModule['isUndoRedoPerformed']) {
            this.editModule.addRecord(data, rowPosition, rowIndex);
          } else {
            this.editModule.addRecord(data, 'Bottom');
          }
        }
        this.editModule.addRowPosition = null;
        this.editModule.addRowIndex = null;
      } else {
        this.editModule.addRecord(data, rowPosition, rowIndex);
        if (rowPosition === 'Bottom') {
          this.selectedRowIndex = rowIndex;
        }
        if (rowPosition === 'Above' || rowPosition === 'Below' || rowPosition === 'Child') {
          this.currentSelection = !isNullOrUndefined(data) ? data : this.currentSelection;
        }
      }
    }
  };
  /**
   * Method to update record by ID.
   *
   * @param  {Object} data - Defines the data to modify.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.updateRecordByID = function (data) {
    if (this.editModule && this.editSettings.allowEditing) {
      this.updateDuration = true;
      this.editModule.updateRecordByID(data);
      this.updateDuration = false;
    }
  };
  /**
   * To update existing taskId with new unique Id.
   *
   * @param {number | string} currentId - Defines the current Id of the record.
   * @param {number | string} newId - Defines the new Id of the record.
   * @returns {void} .
   */
  Gantt.prototype.updateTaskId = function (currentId, newId) {
    if (this.editModule && this.editSettings.allowEditing) {
      this.editModule.updateTaskId(currentId, newId);
    }
  };
  /**
   * Public method to expand particular level of rows.
   *
   * @returns {void} .
   * @param {number} level .
   * @private
   */
  Gantt.prototype.expandAtLevel = function (level) {
    if (this.enableVirtualization) {
      this.isExpandCollapseLevelMethod = true;
    }
    this.ganttChartModule.expandAtLevel(level);
  };
  /**
   * To indent the level of selected task to the hierarchical Gantt task.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.indent = function () {
    if (this.editModule && this.editSettings.allowEditing) {
      this.editModule.indent();
    }
  };
  /**
   * To outdent the level of selected task from the hierarchical Gantt task.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.outdent = function () {
    if (this.editModule && this.editSettings.allowEditing) {
      this.editModule.outdent();
    }
  };
  /**
   * To retrieve the collection of previously recorded actions. This method returns an object as a collection that holds the following details.
   * `modifiedRecords` - retrieves the modified records.
   * `action` - shows the current performed action such as 'sorting','columnReorder','columnResize','progressResizing','rightResizing','leftResizing','add','delete','search','filtering','zoomIn','zoomOut','zoomToFit','columnState','previousTimeSpan','nextTimeSpan','indent','outdent','rowDragAndDrop','taskbarDragAndDrop','dialogEdit'
   *
   * @returns {Object[]} To get the collection of actions
   * @public
   */
  Gantt.prototype.getUndoActions = function () {
    if (this.undoRedoModule) {
      return this.undoRedoModule['getUndoCollection'];
    } else {
      return [];
    }
  };
  /**
   * To retrieve the collection of actions to reapply.
   * `modifiedRecords` - retrieves the modified records.
   * `action` - shows the current performed action such as 'sorting','columnReorder','columnResize','progressResizing','rightResizing','leftResizing','add','delete','search','filtering','zoomIn','zoomOut','zoomToFit','columnState','previousTimeSpan','nextTimeSpan','indent','outdent','rowDragAndDrop','taskbarDragAndDrop','dialogEdit'
   *
   * @returns {Object[]} To get the collection of actions
   *
   * @public
   */
  Gantt.prototype.getRedoActions = function () {
    if (this.undoRedoModule) {
      return this.undoRedoModule['getRedoCollection'];
    } else {
      return [];
    }
  };
  /**
   * Clears the stack collection for undo action.
   *
   * @public
   * @returns {void}
   */
  Gantt.prototype.clearUndoCollection = function () {
    if (this.undoRedoModule) {
      this.undoRedoModule['getUndoCollection'] = [];
      if (this.toolbarModule) {
        this.toolbarModule.enableItems([this.controlId + '_undo'], false);
      }
    }
  };
  /**
   * Clears the stack collection for redo action.
   *
   * @public
   * @returns {void}
   */
  Gantt.prototype.clearRedoCollection = function () {
    if (this.undoRedoModule) {
      this.undoRedoModule['getRedoCollection'] = [];
      if (this.toolbarModule) {
        this.toolbarModule.enableItems([this.controlId + '_redo'], false);
      }
    }
  };
  /**
   * Initiates an undo action to revert the most recent change performed.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.undo = function () {
    if (this.undoRedoModule) {
      this.undoRedoModule['undoAction']();
    }
  };
  /**
   * Initiates a redo action to reapply the most recent undone change performed.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.redo = function () {
    if (this.undoRedoModule) {
      this.undoRedoModule['redoAction']();
    }
  };
  /**
   * To render the critical path tasks in Gantt.
   *
   * @returns {void} .
   * @param {boolean} isCritical - To checks whether to render critical path or not .
   * @public
   */
  Gantt.prototype.showCriticalPath = function (isCritical) {
    if (this.criticalPathModule) {
      this.criticalPathModule.showCriticalPath(isCritical);
      var criticalModule = this.criticalPathModule;
      this.criticalPathModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
    }
  };
  /**
   * To get all the critical tasks in Gantt.
   *
   * @returns {IGanttData[]} .
   * @public
   */
  Gantt.prototype.getCriticalTasks = function () {
    if (!isNullOrUndefined(this.criticalPathModule) && this.enableCriticalPath) {
      return this.criticalPathModule.getCriticalTasks();
    } else {
      return null;
    }
  };
  /**
   * To perform Zoom in action on Gantt timeline.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.zoomIn = function () {
    this.timelineModule.processZooming(true);
  };
  /**
   * To perform zoom out action on Gantt timeline.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.zoomOut = function () {
    this.timelineModule.processZooming(false);
  };
  /**
   * To show all project task in available chart width.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.fitToProject = function () {
    this.timelineModule.processZoomToFit();
    this.ganttChartModule.updateScrollLeft(0);
  };
  /**
   * Reorder the rows based on given indexes and position.
   *
   * @param {number[]} fromIndexes - Defines the indexes of the dragged records.
   * @param {number} toIndex - Defines the index where the dragged rows will be dropped.
   * @param {string} position - Defines the position of the dropped row.
   * @returns {void} .
   */
  Gantt.prototype.reorderRows = function (fromIndexes, toIndex, position) {
    this.rowDragAndDropModule.reorderRows(fromIndexes, toIndex, position);
  };
  /**
   * Method to update record by Index.
   *
   * @param  {number} index - Defines the index of data to modify.
   * @param  {object} data - Defines the data to modify.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.updateRecordByIndex = function (index, data) {
    if (this.editModule && this.editSettings.allowEditing) {
      var tasks = this.taskFields;
      var record = this.updatedRecords.length > 0 ? !isNullOrUndefined(this.updatedRecords[index]) ? this.updatedRecords[index] : null : null;
      if (!isNullOrUndefined(record)) {
        data[tasks.id] = record[tasks.id];
        this.editModule.updateRecordByID(data);
      }
    }
  };
  /**
   * To add dependency for Task.
   *
   * @param  {number} id - Defines the ID of data to modify.
   * @param  {string} predecessorString - Defines the predecessor string to add.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.addPredecessor = function (id, predecessorString) {
    var ganttRecord = this.getRecordByID(id.toString());
    if (this.editModule && !isNullOrUndefined(ganttRecord) && this.editSettings.allowTaskbarEditing) {
      this.connectorLineEditModule.addPredecessor(ganttRecord, predecessorString);
    }
  };
  /**
   * To remove dependency from task.
   *
   * @param  {number} id - Defines the ID of the task from which the dependency will be removed.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.removePredecessor = function (id) {
    var ganttRecord = this.getRecordByID(id.toString());
    if (this.editModule && !isNullOrUndefined(ganttRecord) && this.editSettings.allowTaskbarEditing) {
      this.connectorLineEditModule.removePredecessor(ganttRecord);
    }
  };
  /**
   * To modify current dependency values of Task by task id.
   *
   * @param  {number} id - Defines the ID of data to modify.
   * @param  {string} predecessorString - Defines the predecessor string to update.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.updatePredecessor = function (id, predecessorString) {
    var ganttRecord = this.getRecordByID(id.toString());
    if (this.editModule && !isNullOrUndefined(ganttRecord) && this.editSettings.allowTaskbarEditing) {
      this.connectorLineEditModule.updatePredecessor(ganttRecord, predecessorString);
    }
  };
  /**
   * Method to open Add dialog.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.openAddDialog = function () {
    if (this.editModule && this.editModule.dialogModule && this.editSettings.allowAdding) {
      this.editModule.dialogModule.openAddDialog();
    }
  };
  /**
   * Method to open Edit dialog.
   *
   * @param {number } taskId - Defines the id of task.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.openEditDialog = function (taskId) {
    if (this.editModule && this.editModule.dialogModule && this.editSettings.allowEditing) {
      this.editModule.dialogModule.openEditDialog(taskId);
    }
  };
  /**
   * Changes the TreeGrid column positions by field names.
   *
   * @param {string | number} id .
   * @param {number} index .
   * @returns {void} .
   * @private
   */
  Gantt.prototype.contructExpandCollapseArgs = function (id, index) {
    var chartRow;
    var record;
    var rowIndex;
    if (isNullOrUndefined(index) && id) {
      record = this.getRecordByID(id.toString());
      chartRow = this.getRowByID(id);
      if (!isNullOrUndefined(chartRow)) {
        rowIndex = getValue('rowIndex', chartRow);
      }
    } else if (!isNullOrUndefined(index)) {
      chartRow = this.getRowByIndex(index);
      rowIndex = getValue('rowIndex', chartRow);
      record = this.currentViewData[rowIndex];
    }
    var gridRow = this.treeGrid.getRows()[rowIndex];
    return {
      data: record,
      gridRow: gridRow,
      chartRow: chartRow,
      cancel: false
    };
  };
  /**
   * Method to get chart row value by index.
   *
   * @param {number} index - Defines the index of row.
   * @returns {HTMLElement} .
   */
  Gantt.prototype.getRowByIndex = function (index) {
    try {
      var gridRows = this.element.querySelectorAll('.e-chart-row');
      if (!isNullOrUndefined(index)) {
        return gridRows[index];
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };
  /**
   * Method to get the row element by task id.
   *
   * @param {string | number} id - Defines the id of task.
   * @returns {HTMLElement} .
   */
  Gantt.prototype.getRowByID = function (id) {
    var _this = this;
    var record = this.getRecordByID(id.toString());
    var index;
    if (this.loadChildOnDemand && this.taskFields.hasChildMapping) {
      index = this.updatedRecords.map(function (item) {
        return item[_this.taskFields.id];
      }).indexOf(record.ganttProperties.taskId);
    } else {
      index = this.enableVirtualization ? this.currentViewData.indexOf(record) : this.updatedRecords.indexOf(record);
    }
    if (index !== -1) {
      return this.getRowByIndex(index);
    } else {
      return null;
    }
  };
  /**
   * Method to get class name for unscheduled tasks
   *
   * @param {ITaskData} ganttProp .
   * @returns {string} .
   * @private
   */
  Gantt.prototype.getUnscheduledTaskClass = function (ganttProp) {
    if (isNullOrUndefined(ganttProp.startDate) && isNullOrUndefined(ganttProp.endDate) && isNullOrUndefined(ganttProp.duration)) {
      return ' ' + traceUnscheduledTask;
    } else if (isNullOrUndefined(ganttProp.startDate) || isNullOrUndefined(ganttProp.endDate) || isNullOrUndefined(ganttProp.duration)) {
      return ' ' + traceUnscheduledTask;
    } else {
      return '';
    }
  };
  /**
   * Method to get class name for unscheduled tasks
   *
   * @param {ITaskData} ganttProp -Defines the Gantt propertie.
   * @returns {boolean} .
   * @private
   */
  Gantt.prototype.isUnscheduledTask = function (ganttProp) {
    if (isNullOrUndefined(ganttProp.startDate) || isNullOrUndefined(ganttProp.endDate) || isNullOrUndefined(ganttProp.duration)) {
      return true;
    } else {
      return false;
    }
  };
  Gantt.prototype.createGanttPopUpElement = function () {
    var popup = this.createElement('div', {
      className: 'e-ganttpopup',
      styles: 'display:none;'
    });
    var content = this.createElement('div', {
      className: 'e-content',
      attrs: {
        tabIndex: '-1'
      }
    });
    append([content, this.createElement('div', {
      className: 'e-uptail e-tail'
    })], popup);
    content.appendChild(this.createElement('span'));
    append([content, this.createElement('div', {
      className: 'e-downtail e-tail'
    })], popup);
    document.getElementById(this.element.id + 'GanttChart').appendChild(popup);
  };
  /**
   * Method to get predecessor value as string.
   *
   * @param {string} type .
   * @returns {HTMLElement} .
   * @private
   */
  Gantt.prototype.getPredecessorTextValue = function (type) {
    var textValue;
    switch (type) {
      case 'SS':
        textValue = this.localeObj.getConstant('start') + '-' + this.localeObj.getConstant('start');
        break;
      case 'FF':
        textValue = this.localeObj.getConstant('finish') + '-' + this.localeObj.getConstant('finish');
        break;
      case 'SF':
        textValue = this.localeObj.getConstant('start') + '-' + this.localeObj.getConstant('finish');
        break;
      case 'FS':
        textValue = this.localeObj.getConstant('finish') + '-' + this.localeObj.getConstant('start');
        break;
    }
    return textValue;
  };
  /**
   * Method to perform search action in Gantt.
   *
   * @param {string} keyVal - Defines key value to search.
   * @returns {void} .
   */
  Gantt.prototype.search = function (keyVal) {
    if (this.filterModule) {
      this.searchSettings.key = keyVal;
      this.dataBind();
    }
  };
  /**
   * Method to get offset rect value
   *
   * @param {HTMLElement} element .
   * @returns {number} .
   * @hidden
   */
  Gantt.prototype.getOffsetRect = function (element) {
    var box = element.getBoundingClientRect();
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
    var clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
    var clientLeft = document.documentElement.clientLeft || document.body.clientLeft || 0;
    var top = box.top + scrollTop - clientTop;
    var left = this.enableRtl ? box.right + scrollLeft - clientLeft : box.left + scrollLeft - clientLeft;
    return {
      top: Math.round(top),
      left: Math.round(left),
      width: box.width,
      height: box.height
    };
  };
  /**
   * Method to expand all the rows of Gantt.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.expandAll = function () {
    this.ganttChartModule.expandCollapseAll('expand');
  };
  /**
   * Method to update data source.
   *
   * @returns {void} .
   * @param {object[]} dataSource - Defines a collection of data.
   * @param {object} args - Defines the projectStartDate and projectEndDate values.
   * @public
   */
  Gantt.prototype.updateDataSource = function (dataSource, args) {
    this.isDynamicData = true;
    if (!isNullOrUndefined(args)) {
      for (var _i = 0, _a = Object.keys(args); _i < _a.length; _i++) {
        // eslint-disable-line
        var prop = _a[_i];
        switch (prop) {
          case 'projectStartDate':
            this.setProperties({
              projectStartDate: args[prop]
            }, true);
            break;
          case 'projectEndDate':
            this.setProperties({
              projectEndDate: args[prop]
            }, true);
            break;
        }
      }
    }
    this.dataSource = dataSource;
  };
  /**
   * Method to collapse all the rows of Gantt.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.collapseAll = function () {
    this.ganttChartModule.expandCollapseAll('collapse');
  };
  /**
   * Gets the columns from the TreeGrid.
   *
   * @returns {Column[]} .
   * @public
   */
  Gantt.prototype.getGridColumns = function () {
    return this.treeGrid.getColumns();
  };
  /**
   * Method to column from given column collection based on field value
   *
   * @param {string} field .
   * @param {ColumnModel[]} columns .
   * @returns {ColumnModel} .
   * @private
   */
  Gantt.prototype.getColumnByField = function (field, columns) {
    var column = columns.filter(function (value) {
      return value.field === field;
    });
    return column.length > 0 ? column[0] : null;
  };
  /**
   * Gets the Gantt columns.
   *
   * @returns {ColumnModel[]} .
   * @public
   */
  Gantt.prototype.getGanttColumns = function () {
    return this.ganttColumns;
  };
  /**
   * Shows a column by its column name.
   *
   * @param  {string|string[]} keys - Defines a single or collection of column names.
   * @param  {string} showBy - Defines the column key either as field name or header text.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.showColumn = function (keys, showBy) {
    this.treeGrid.showColumns(keys, showBy);
    this.updateTreeColumns();
  };
  /**
   * Hides one or more columns in the Gantt chart based on the specified column names or header texts.
   *
   * @param  {string|string[]} keys - Defines a single or collection of column names.
   * @param  {string} hideBy - Defines the column key either as field name or header text.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.hideColumn = function (keys, hideBy) {
    this.treeGrid.hideColumns(keys, hideBy);
    this.updateTreeColumns();
  };
  /**
   * To set scroll top for chart scroll container.
   *
   * @param {number} scrollTop - Defines scroll top value for scroll container.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.setScrollTop = function (scrollTop) {
    this.ganttChartModule.scrollObject.setScrollTop(scrollTop);
  };
  /**
   * Cancels the current edit operation and reverts the changes made during editing.
   *
   * @returns {void} .
   * @public
   */
  Gantt.prototype.cancelEdit = function () {
    this.isCancelled = true;
    this.closeGanttActions();
  };
  /**
   * Selects a cell by the given index.
   *
   * @param  {IIndex} cellIndex - Defines the row and column indexes.
   * @param  {boolean} isToggle - If set to true, then it toggles the selection.
   * @returns {void} .
   */
  Gantt.prototype.selectCell = function (cellIndex, isToggle) {
    if (this.selectionModule) {
      this.selectionModule.selectCell(cellIndex, isToggle);
    }
  };
  /**
   * Selects a collection of cells by row and column indexes.
   *
   * @param  {ISelectedCell[]} rowCellIndexes - Specifies the row and column indexes.
   * @returns {void} .
   */
  Gantt.prototype.selectCells = function (rowCellIndexes) {
    if (this.selectionModule) {
      this.selectionModule.selectCells(rowCellIndexes);
    }
  };
  /**
   * Selects a row by given index.
   *
   * @param  {number} index - Defines the row index.
   * @param  {boolean} isToggle - If set to true, then it toggles the selection.
   * @returns {void} .
   */
  Gantt.prototype.selectRow = function (index, isToggle) {
    if (this.selectionModule) {
      this.selectionModule.selectRow(index, isToggle);
    }
  };
  /**
   * Selects a collection of rows by indexes.
   *
   * @param  {number[]} records - Defines the collection of row indexes.
   * @returns {void} .
   */
  Gantt.prototype.selectRows = function (records) {
    if (this.selectionModule) {
      this.selectionModule.selectRows(records);
    }
  };
  /**
   * Method to delete record.
   *
   * @param {number | string } taskDetail - Defines the details of data to delete.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.deleteRecord = function (taskDetail) {
    if (this.editModule) {
      this.editModule.deleteRecord(taskDetail);
    }
  };
  /**
   * Enables or disables ToolBar items.
   *
   * @param {string[]} items - Defines the collection of itemID of ToolBar items.
   * @param {boolean} isEnable - Defines the items to be enabled or disabled.
   * @returns {void} .
   */
  Gantt.prototype.enableItems = function (items, isEnable) {
    if (this.toolbarModule) {
      this.toolbarModule.enableItems(items, isEnable);
    }
  };
  /**
   * Deselects the current selected rows and cells.
   *
   * @returns {void} .
   */
  Gantt.prototype.clearSelection = function () {
    if (this.selectionModule) {
      this.selectionModule.clearSelection();
    }
  };
  /**
   * @param {ITaskAddedEventArgs | IActionBeginEventArgs} args .
   * @returns {ITaskAddedEventArgs | IActionBeginEventArgs} .
   * @hidden
   */
  Gantt.prototype.updateDataArgs = function (args) {
    if (!Array.isArray(args.data)) {
      var customData = [];
      customData.push(args.data);
      setValue('data', customData, args);
    }
    return args;
  };
  /**
   * Method to convert task data to milestone data.
   *
   * @param {string} id - Defines id of record.
   * @returns {void} .
   * @public
   */
  Gantt.prototype.convertToMilestone = function (id) {
    var rowData = this.getRecordByID(id);
    if (!isNullOrUndefined(rowData)) {
      var data = extend({}, {}, rowData.taskData, true);
      var taskfields = this.taskFields;
      if (data[taskfields.startDate]) {
        this.setRecordValue(taskfields.startDate, rowData.ganttProperties.startDate, data, true);
      }
      if (!isNullOrUndefined(taskfields.duration)) {
        data[taskfields.duration] = 0;
      } else {
        data[taskfields.startDate] = new Date(rowData.ganttProperties.startDate);
        data[taskfields.endDate] = new Date(rowData.ganttProperties.endDate);
      }
      if (!isNullOrUndefined(taskfields.milestone)) {
        if (data[taskfields.milestone] === false) {
          data[taskfields.milestone] = true;
        }
      }
      if (!isNullOrUndefined(taskfields.progress)) {
        data[taskfields.progress] = 0;
      }
      if (!isNullOrUndefined(taskfields.child) && data[taskfields.child]) {
        data[taskfields.child] = [];
      }
      if (!isNullOrUndefined(taskfields.parentID) && data[taskfields.parentID]) {
        data[taskfields.parentID] = null;
      }
      if (!isNullOrUndefined(taskfields.segments)) {
        data[taskfields.segments] = null;
      }
      if (!isNullOrUndefined(this.contextMenuModule) && this.contextMenuModule.isOpen && this.contextMenuModule.item === 'Milestone') {
        if (!isNullOrUndefined(taskfields.dependency)) {
          data[taskfields.dependency] = null;
        }
        var position = this.editSettings.newRowPosition;
        this.addRecord(data, position, this.selectedRowIndex);
      } else {
        if (!rowData.hasChildRecords && !rowData.ganttProperties.isMilestone) {
          this.isConvertedMilestone = true;
          this.updateRecordByID(data);
          this.isConvertedMilestone = false;
        }
      }
    }
  };
  /**
   * To change the mode of a record.
   *
   * @param {object} data - Use to change the TaskMode either manual, auto or custom.
   * @returns {void} .
   */
  Gantt.prototype.changeTaskMode = function (data) {
    if (this.undoRedoModule && !this.undoRedoModule['isUndoRedoPerformed']) {
      if (this.undoRedoModule['redoEnabled']) {
        this.undoRedoModule['disableRedo']();
      }
      this.undoRedoModule['createUndoCollection']();
      var details = {};
      details['action'] = 'TaskMode';
      details['modifiedRecords'] = extend([], [data], [], true);
      this.undoRedoModule['getUndoCollection'][this.undoRedoModule['getUndoCollection'].length - 1] = details;
    }
    var tasks = this.taskFields;
    var ganttData = this.getRecordByID(data[tasks.id]);
    var ganttProp = ganttData.ganttProperties;
    this.isOnEdit = true;
    this.setRecordValue('isAutoSchedule', !ganttProp.isAutoSchedule, ganttProp, true);
    if (!isNullOrUndefined(this.taskFields.manual)) {
      this.setRecordValue('taskData.' + tasks.manual, !ganttProp.isAutoSchedule, ganttData);
      this.setRecordValue(tasks.manual, !ganttProp.isAutoSchedule, ganttData);
    }
    this.editModule.updateTaskScheduleModes(ganttData);
    var args = {
      data: ganttData
    };
    this.editModule.initiateUpdateAction(args);
  };
  /**
   * @returns {string[]} .
   * @private
   */
  Gantt.prototype.getTaskIds = function () {
    return this.taskIds;
  };
  /**
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  Gantt.prototype.setTaskIds = function (data) {
    if (this.viewType !== 'ProjectView') {
      var id = data.ganttProperties.taskId;
      id = data.level === 0 ? 'R' + id : 'T' + id;
      this.taskIds.push(id);
    }
  };
  /**
   * To render the react templates
   *
   * @returns {void} .
   *  @hidden
   */
  Gantt.prototype.renderTemplates = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.isReact) {
      this.renderReactTemplates();
    }
  };
  /**
   * To reset the react templates
   *
   * @returns {void} .
   *  @hidden
   */
  Gantt.prototype.resetTemplates = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.isReact) {
      this.clearTemplate();
    }
  };
  __decorate$i([Property(true)], Gantt.prototype, "allowKeyboard", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableImmutableMode", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "allowParentDependency", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "enableHtmlSanitizer", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "disableHtmlEncode", void 0);
  __decorate$i([Complex({}, LoadingIndicator)], Gantt.prototype, "loadingIndicator", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "enableVirtualMaskRow", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "loadChildOnDemand", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "updateOffsetOnTaskbarEdit", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "UpdateOffsetOnTaskbarEdit", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "autoCalculateDateScheduling", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "autoFocusTasks", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableAdaptiveUI", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "allowSelection", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowSorting", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "enablePredecessorValidation", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "showColumnMenu", void 0);
  __decorate$i([Property()], Gantt.prototype, "columnMenuItems", void 0);
  __decorate$i([Property(['Sorting', 'Add', 'ColumnReorder', 'ColumnResize', 'ColumnState', 'Delete', 'Edit', 'Filtering', 'Indent', 'Outdent', 'NextTimeSpan', 'PreviousTimeSpan', 'RowDragAndDrop', 'TaskbarDragAndDrop', 'Search', 'ZoomIn', 'ZoomOut', 'ZoomToFit'])], Gantt.prototype, "undoRedoActions", void 0);
  __decorate$i([Property()], Gantt.prototype, "timezone", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "collapseAllParentTasks", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "highlightWeekends", void 0);
  __decorate$i([Property(0)], Gantt.prototype, "treeColumnIndex", void 0);
  __decorate$i([Property([])], Gantt.prototype, "dataSource", void 0);
  __decorate$i([Property('day')], Gantt.prototype, "durationUnit", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "query", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "dateFormat", void 0);
  __decorate$i([Property('auto')], Gantt.prototype, "height", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "renderBaseline", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowTaskbarDragAndDrop", void 0);
  __decorate$i([Property(true)], Gantt.prototype, "allowTaskbarOverlap", void 0);
  __decorate$i([Property('Horizontal')], Gantt.prototype, "gridLines", void 0);
  __decorate$i([Complex({}, LabelSettings)], Gantt.prototype, "labelSettings", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "taskbarTemplate", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "parentTaskbarTemplate", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "timelineTemplate", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "milestoneTemplate", void 0);
  __decorate$i([Property()], Gantt.prototype, "baselineColor", void 0);
  __decorate$i([Property('auto')], Gantt.prototype, "width", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableVirtualization", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableTimelineVirtualization", void 0);
  __decorate$i([Property()], Gantt.prototype, "toolbar", void 0);
  __decorate$i([Property(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])], Gantt.prototype, "workWeek", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "includeWeekend", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowUnscheduledTasks", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "showInlineNotes", void 0);
  __decorate$i([Property(36)], Gantt.prototype, "rowHeight", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "taskbarHeight", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "projectStartDate", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "projectEndDate", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "resourceIDMapping", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "resourceNameMapping", void 0);
  __decorate$i([Property([])], Gantt.prototype, "resources", void 0);
  __decorate$i([Property([])], Gantt.prototype, "segmentData", void 0);
  __decorate$i([Property(null)], Gantt.prototype, "connectorLineBackground", void 0);
  __decorate$i([Property(1)], Gantt.prototype, "connectorLineWidth", void 0);
  __decorate$i([Property([])], Gantt.prototype, "columns", void 0);
  __decorate$i([Property([])], Gantt.prototype, "addDialogFields", void 0);
  __decorate$i([Property([])], Gantt.prototype, "editDialogFields", void 0);
  __decorate$i([Property(-1)], Gantt.prototype, "selectedRowIndex", void 0);
  __decorate$i([Property('hour')], Gantt.prototype, "workUnit", void 0);
  __decorate$i([Property('FixedUnit')], Gantt.prototype, "taskType", void 0);
  __decorate$i([Property('ProjectView')], Gantt.prototype, "viewType", void 0);
  __decorate$i([Collection([{
    from: 8,
    to: 12
  }, {
    from: 13,
    to: 17
  }], DayWorkingTime)], Gantt.prototype, "dayWorkingTime", void 0);
  __decorate$i([Collection([], WeekWorkingTime)], Gantt.prototype, "weekWorkingTime", void 0);
  __decorate$i([Collection([], Holiday)], Gantt.prototype, "holidays", void 0);
  __decorate$i([Collection([], EventMarker)], Gantt.prototype, "eventMarkers", void 0);
  __decorate$i([Complex({}, TaskFields)], Gantt.prototype, "taskFields", void 0);
  __decorate$i([Complex({}, ResourceFields)], Gantt.prototype, "resourceFields", void 0);
  __decorate$i([Complex({}, TimelineSettings)], Gantt.prototype, "timelineSettings", void 0);
  __decorate$i([Property([])], Gantt.prototype, "zoomingLevels", void 0);
  __decorate$i([Complex({}, SortSettings)], Gantt.prototype, "sortSettings", void 0);
  __decorate$i([Complex({}, EditSettings)], Gantt.prototype, "editSettings", void 0);
  __decorate$i([Complex({}, TooltipSettings)], Gantt.prototype, "tooltipSettings", void 0);
  __decorate$i([Complex({}, SelectionSettings)], Gantt.prototype, "selectionSettings", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowFiltering", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowExcelExport", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowRowDragAndDrop", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowReordering", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "readOnly", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowResizing", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableContextMenu", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableCriticalPath", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableUndoRedo", void 0);
  __decorate$i([Property(10)], Gantt.prototype, "undoRedoStepsCount", void 0);
  __decorate$i([Property()], Gantt.prototype, "contextMenuItems", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "allowPdfExport", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "validateManualTasksOnLinking", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "enableMultiTaskbar", void 0);
  __decorate$i([Property(false)], Gantt.prototype, "showOverAllocation", void 0);
  __decorate$i([Property('Auto')], Gantt.prototype, "taskMode", void 0);
  __decorate$i([Complex({}, FilterSettings)], Gantt.prototype, "filterSettings", void 0);
  __decorate$i([Complex({}, SearchSettings)], Gantt.prototype, "searchSettings", void 0);
  __decorate$i([Complex({}, SplitterSettings)], Gantt.prototype, "splitterSettings", void 0);
  __decorate$i([Event()], Gantt.prototype, "queryTaskbarInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "beforeExcelExport", void 0);
  __decorate$i([Event()], Gantt.prototype, "excelExportComplete", void 0);
  __decorate$i([Event()], Gantt.prototype, "excelQueryCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "excelHeaderQueryCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDrag", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDragStart", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDragStartHelper", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDrop", void 0);
  __decorate$i([Event()], Gantt.prototype, "collapsing", void 0);
  __decorate$i([Event()], Gantt.prototype, "collapsed", void 0);
  __decorate$i([Event()], Gantt.prototype, "expanding", void 0);
  __decorate$i([Event()], Gantt.prototype, "expanded", void 0);
  __decorate$i([Event()], Gantt.prototype, "actionBegin", void 0);
  __decorate$i([Event()], Gantt.prototype, "actionComplete", void 0);
  __decorate$i([Event()], Gantt.prototype, "actionFailure", void 0);
  __decorate$i([Event()], Gantt.prototype, "dataStateChange", void 0);
  __decorate$i([Event()], Gantt.prototype, "taskbarEdited", void 0);
  __decorate$i([Event()], Gantt.prototype, "endEdit", void 0);
  __decorate$i([Event()], Gantt.prototype, "cellEdit", void 0);
  __decorate$i([Event()], Gantt.prototype, "load", void 0);
  __decorate$i([Event()], Gantt.prototype, "created", void 0);
  __decorate$i([Event()], Gantt.prototype, "destroyed", void 0);
  __decorate$i([Event()], Gantt.prototype, "taskbarEditing", void 0);
  __decorate$i([Event()], Gantt.prototype, "dataBound", void 0);
  __decorate$i([Event()], Gantt.prototype, "resizeStart", void 0);
  __decorate$i([Event()], Gantt.prototype, "resizing", void 0);
  __decorate$i([Event()], Gantt.prototype, "resizeStop", void 0);
  __decorate$i([Event()], Gantt.prototype, "splitterResizeStart", void 0);
  __decorate$i([Event()], Gantt.prototype, "splitterResizing", void 0);
  __decorate$i([Event()], Gantt.prototype, "splitterResized", void 0);
  __decorate$i([Event()], Gantt.prototype, "columnDragStart", void 0);
  __decorate$i([Event()], Gantt.prototype, "columnDrag", void 0);
  __decorate$i([Event()], Gantt.prototype, "columnDrop", void 0);
  __decorate$i([Event()], Gantt.prototype, "beforeTooltipRender", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowSelecting", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowSelected", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDeselecting", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDeselected", void 0);
  __decorate$i([Event()], Gantt.prototype, "cellSelecting", void 0);
  __decorate$i([Event()], Gantt.prototype, "cellSelected", void 0);
  __decorate$i([Event()], Gantt.prototype, "cellDeselecting", void 0);
  __decorate$i([Event()], Gantt.prototype, "cellDeselected", void 0);
  __decorate$i([Event()], Gantt.prototype, "queryCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "headerCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "rowDataBound", void 0);
  __decorate$i([Event()], Gantt.prototype, "columnMenuOpen", void 0);
  __decorate$i([Event()], Gantt.prototype, "toolbarClick", void 0);
  __decorate$i([Event()], Gantt.prototype, "columnMenuClick", void 0);
  __decorate$i([Event()], Gantt.prototype, "contextMenuOpen", void 0);
  __decorate$i([Event()], Gantt.prototype, "contextMenuClick", void 0);
  __decorate$i([Event()], Gantt.prototype, "onTaskbarClick", void 0);
  __decorate$i([Event()], Gantt.prototype, "recordDoubleClick", void 0);
  __decorate$i([Event()], Gantt.prototype, "onMouseMove", void 0);
  __decorate$i([Event()], Gantt.prototype, "beforePdfExport", void 0);
  __decorate$i([Event()], Gantt.prototype, "pdfExportComplete", void 0);
  __decorate$i([Event()], Gantt.prototype, "pdfQueryCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "pdfQueryTaskbarInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "pdfQueryTimelineCellInfo", void 0);
  __decorate$i([Event()], Gantt.prototype, "pdfColumnHeaderQueryCellInfo", void 0);
  Gantt = __decorate$i([NotifyPropertyChanges], Gantt);
  return Gantt;
}(Component);

/**
 * public Enum for `PdfHorizontalOverflowType`.
 *
 * @private
 */
var PdfHorizontalOverflowType;
(function (PdfHorizontalOverflowType) {
  /**
   * Specifies the type of `NextPage`.
   *
   * @private
   */
  PdfHorizontalOverflowType[PdfHorizontalOverflowType["NextPage"] = 0] = "NextPage";
  /**
   * Specifies the type of `LastPage`.
   *
   * @private
   */
  PdfHorizontalOverflowType[PdfHorizontalOverflowType["LastPage"] = 1] = "LastPage";
})(PdfHorizontalOverflowType || (PdfHorizontalOverflowType = {}));

/**
 *  This file was to define all public and internal events
 */
/** @hidden */
var load = 'load';
/** @hidden */
var rowDataBound = 'rowDataBound';
/** @hidden */
var queryCellInfo = 'queryCellInfo';
/** @hidden */
var toolbarClick = 'toolbarClick';
/** @hidden */
var keyPressed = 'key-pressed';

/**
 * To handle cell edit action on default columns and custom columns
 */
var CellEdit = /** @__PURE__ @class */function () {
  function CellEdit(ganttObj) {
    /**
     * @private
     */
    this.isCellEdit = false;
    this.isResourceCellEdited = false;
    this.parent = ganttObj;
    this.bindTreeGridProperties();
  }
  /**
   * Bind all editing related properties from Gantt to TreeGrid
   *
   * @returns {void} .
   */
  CellEdit.prototype.bindTreeGridProperties = function () {
    this.parent.treeGrid.editSettings.allowEditing = this.parent.editSettings.allowEditing;
    this.parent.treeGrid.editSettings.allowNextRowEdit = this.parent.editSettings.allowNextRowEdit;
    this.parent.treeGrid.editSettings.mode = 'Cell';
    this.parent.treeGrid.cellEdit = this.ensureEditCell.bind(this);
    if (this.parent.editSettings.allowEditing) {
      TreeGrid.Inject(Edit$1);
    }
  };
  /**
   * Ensure current cell was editable or not
   *
   * @param {CellEditArgs} args .
   * @returns {void | Deferred} .
   */
  CellEdit.prototype.ensureEditCell = function (args) {
    var _this = this;
    var data = args.rowData;
    var field = args.columnName;
    this.editedColumn = this.parent.getColumnByField(field, this.parent.ganttColumns);
    var taskSettings = this.parent.taskFields;
    if (this.parent.readOnly) {
      args.cancel = true;
      return;
    }
    if (this.parent.editSettings.mode === 'Dialog') {
      args.cancel = true;
      return;
    }
    if (data.hasChildRecords && !this.parent.allowParentDependency && (field === taskSettings.endDate && (!isNullOrUndefined(data['isManual']) && data['isManual'] === false || this.parent.taskMode === 'Auto') || field === taskSettings.duration || field === taskSettings.dependency || field === taskSettings.progress || field === taskSettings.work || field === taskSettings.type || field === 'taskType')) {
      if (field === taskSettings.dependency && !this.parent.allowParentDependency || field !== taskSettings.dependency) {
        args.cancel = true;
      }
    } else {
      var callBackPromise_1 = new Deferred();
      var parentReference_1 = this.parent;
      this.currentEditedRowData = args.rowData;
      this.parent.trigger('cellEdit', args, function (arg) {
        if (arg.columnName === parentReference_1.taskFields.progress && arg.rowData.hasChildRecords) {
          arg.cancel = true;
        }
        if (data.level === 0 && _this.parent.viewType === 'ResourceView') {
          arg.cancel = true;
        }
        callBackPromise_1.resolve(arg);
        if (!arg.cancel) {
          if (arg.columnName === _this.parent.taskFields.notes) {
            _this.openNotesEditor(arg);
          } else {
            _this.isCellEdit = true;
            if (!isNullOrUndefined(_this.parent.toolbarModule)) {
              _this.parent.toolbarModule.refreshToolbarItems();
            }
          }
        }
      });
      return callBackPromise_1;
    }
  };
  /**
   * To render edit dialog and to focus on notes tab
   *
   * @param {CellEditArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.openNotesEditor = function (args) {
    var taskSettings = this.parent.taskFields;
    var data = args.rowData;
    var field = args.columnName;
    if (field === taskSettings.notes && !this.parent.showInlineNotes) {
      args.cancel = true;
      var columnTypes = this.parent.editModule.dialogModule.updatedEditFields.map(function (x) {
        return x.type;
      });
      var index = columnTypes.indexOf('Notes');
      if (index !== -1) {
        this.parent.editModule.dialogModule.openEditDialog(data.ganttProperties.rowUniqueID);
        var tabObj = document.getElementById(this.parent.element.id + '_Tab').ej2_instances[0];
        tabObj.selectedItem = index;
      }
    }
    if (field === taskSettings.notes && this.parent.showInlineNotes === true) {
      this.isCellEdit = true;
    }
  };
  // private isValueChange(args: Record<string, unknown>, field: string): boolean {
  //     const data: IGanttData = getValue('data', args);
  //     const editedValue: Record<string, unknown> = data[`${field}`];
  //     const previousValue: Record<string, unknown> = getValue('previousData', args);
  //     if ((isNOU(editedValue) && !isNOU(previousValue)) || (!isNOU(editedValue) && isNOU(previousValue))) {
  //         return true;
  //     } else if (!isNOU(editedValue) && !isNOU(previousValue)) {
  //         if (editedValue instanceof Date) {
  //             return editedValue.getTime() !== data.taskData[field as string].getTime() ? true : false;
  //         } else if (field === this.parent.taskFields.resourceInfo) {
  //             return editedValue !== previousValue ? true : false;
  //         } else if (editedValue !== data.taskData[field as string]) {
  //             return true;
  //         }
  //     }
  //     return false;
  // }
  /**
   * Initiate cell save action on Gantt with arguments from TreeGrid
   *
   * @param {object} args .
   * @param {object} editedObj .
   * @returns {void} .
   * @private
   */
  CellEdit.prototype.initiateCellEdit = function (args, editedObj) {
    var isValid = true;
    if (args['name'] === 'actionComplete' && args['previousData'] === args['data'][args['column'].field]) {
      isValid = false;
    }
    if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent']('Edit')) {
      if (isValid) {
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['disableRedo']();
        }
        this.parent.undoRedoModule['createUndoCollection']();
        var action = {};
        action['action'] = 'CellEditing';
        action['editedColumn'] = args['column'].field;
        action['modifiedRecords'] = [];
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = action;
      }
    }
    var column = getValue('column', args);
    var data = getValue('data', args);
    var editedArgs = {};
    editedArgs.action = 'CellEditing';
    editedArgs.data = this.parent.getTaskByUniqueID(data.uniqueID);
    var previousValue = getValue('previousData', args);
    var editedValue = this.parent.allowUnscheduledTasks ? data[column.field] : (isNullOrUndefined(data[column.field]) || data[column.field] === '') && (this.parent.taskFields.duration === column.field || this.parent.taskFields.startDate === column.field || this.parent.taskFields.endDate === column.field) ? previousValue : data[column.field];
    if (!isNullOrUndefined(data)) {
      data[column.field] = previousValue;
      editedArgs.data[column.field] = previousValue;
      this.parent.initiateEditAction(true);
      if (this.parent.weekWorkingTime.length > 0 && editedValue && (column.field === this.parent.taskFields.startDate || column.field === this.parent.taskFields.baselineStartDate)) {
        var sDate = column.field === this.parent.taskFields.startDate ? data.ganttProperties.startDate : data.ganttProperties.baselineStartDate;
        var prevDay = this.parent['getStartTime'](sDate);
        if (prevDay / 3600 === sDate.getHours()) {
          var dayStartTime = this.parent['getStartTime'](editedValue);
          this.parent.dataOperation.setTime(dayStartTime, editedValue);
          this.parent.setRecordValue(column.field, editedValue, editedArgs.data);
        } else {
          this.parent.setRecordValue(column.field, editedValue, editedArgs.data);
        }
      } else {
        this.parent.setRecordValue(column.field, editedValue, editedArgs.data);
      }
      if (column.field === this.parent.taskFields.name) {
        this.taskNameEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.startDate) {
        this.startDateEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.endDate) {
        this.endDateEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.duration) {
        this.durationEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.resourceInfo) {
        this.resourceEdited(editedArgs, editedObj, data);
      } else if (column.field === this.parent.taskFields.progress) {
        this.progressEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.baselineStartDate || column.field === this.parent.taskFields.baselineEndDate) {
        this.baselineEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.dependency) {
        this.dependencyEdited(editedArgs, previousValue);
      } else if (column.field === this.parent.taskFields.notes) {
        this.notedEdited(editedArgs);
      } else if (column.field === this.parent.taskFields.work) {
        this.workEdited(editedArgs);
      } else if ((column.field === this.parent.taskFields.type || column.field === 'taskType') && !isNullOrUndefined(this.parent.taskFields.work)) {
        this.typeEdited(editedArgs, editedObj);
      } else if (column.field === this.parent.taskFields.manual) {
        this.taskmodeEdited(editedArgs);
      } else {
        this.parent.setRecordValue('taskData.' + column.field, editedArgs.data[column.field], editedArgs.data);
        this.parent.editModule.initiateSaveAction(editedArgs);
      }
    } else {
      this.parent.editModule.endEditAction(args);
    }
    this.isCellEdit = false;
    if (!isNullOrUndefined(this.parent.editModule.cellEditModule.editedColumn)) {
      this.parent.editModule.cellEditModule.editedColumn = null;
    }
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
  };
  /**
   * To update task name cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.taskNameEdited = function (args) {
    this.parent.setRecordValue('taskData.' + this.parent.taskFields.name, args.data[this.parent.taskFields.name], args.data);
    this.parent.setRecordValue('taskName', args.data[this.parent.taskFields.name], args.data.ganttProperties, true);
    this.updateEditedRecord(args);
  };
  /**
   * To update task notes cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.notedEdited = function (args) {
    this.parent.setRecordValue('taskData.' + this.parent.taskFields.notes, args.data[this.parent.taskFields.notes], args.data);
    this.parent.setRecordValue('notes', args.data[this.parent.taskFields.notes], args.data.ganttProperties, true);
    this.updateEditedRecord(args);
  };
  /**
   * To update task schedule mode cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.taskmodeEdited = function (args) {
    this.parent.setRecordValue('isAutoSchedule', !args.data[this.parent.taskFields.manual], args.data.ganttProperties, true);
    this.parent.editModule.updateTaskScheduleModes(args.data);
    this.updateEditedRecord(args);
  };
  /**
   * To update task start date cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.startDateEdited = function (args) {
    var ganttData = args.data;
    var ganttProb = args.data.ganttProperties;
    var currentValue = args.data[this.parent.taskFields.startDate];
    currentValue = currentValue ? new Date(currentValue.getTime()) : null;
    currentValue = this.parent.dateValidationModule.checkStartDate(currentValue, ganttData.ganttProperties, ganttData.ganttProperties.isMilestone);
    if (isNullOrUndefined(currentValue)) {
      if (!ganttData.hasChildRecords) {
        this.parent.setRecordValue('startDate', null, ganttProb, true);
        if (!(ganttProb.startDate === null && ganttProb.endDate === null && ganttProb.duration !== null)) {
          this.parent.setRecordValue('duration', null, ganttProb, true);
        }
        this.parent.setRecordValue('isMilestone', false, ganttProb, true);
        // if (this.parent.allowUnscheduledTasks && isNOU(this.parent.taskFields.endDate)) {
        //     this.parent.setRecordValue('endDate', null, ganttProb, true);
        // }
      }
    } else if (ganttProb.endDate || !isNullOrUndefined(ganttProb.duration)) {
      this.parent.setRecordValue('startDate', new Date(currentValue.getTime()), ganttProb, true);
      this.parent.dateValidationModule.calculateEndDate(ganttData);
    } else if (isNullOrUndefined(ganttProb.endDate) && isNullOrUndefined(ganttProb.duration)) {
      this.parent.setRecordValue('startDate', new Date(currentValue.getTime()), ganttProb, true);
    }
    this.parent.setRecordValue('isMilestone', ganttProb.duration === 0 ? true : false, ganttProb, true);
    this.parent.dataOperation.updateWidthLeft(args.data);
    this.parent.dataOperation.updateMappingData(ganttData, 'startDate');
    this.parent.dataOperation.updateMappingData(ganttData, 'endDate');
    this.parent.dataOperation.updateMappingData(ganttData, 'duration');
    this.updateEditedRecord(args);
  };
  CellEdit.prototype.validateEndDateWithSegments = function (ganttProp) {
    var ganttSegments = [];
    var segments = ganttProp.segments;
    for (var i = 0; i < segments.length; i++) {
      var segment = segments[parseInt(i.toString(), 10)];
      var endDate = segment.endDate;
      endDate = !isNullOrUndefined(ganttProp.endDate) && endDate.getTime() < ganttProp.endDate.getTime() && i !== segments.length - 1 ? endDate : ganttProp.endDate;
      segment.duration = this.parent.dataOperation.getDuration(segment.startDate, endDate, ganttProp.durationUnit, ganttProp.isAutoSchedule, ganttProp.isMilestone);
      if (segments.length > 0 && endDate.getTime() < segment.startDate.getTime() && endDate.getTime() <= ganttProp.endDate.getTime()) {
        segments[i - 1].duration = this.parent.dataOperation.getDuration(segments[i - 1].startDate, ganttProp.endDate, ganttProp.durationUnit, ganttProp.isAutoSchedule, ganttProp.isMilestone);
        continue;
      }
      ganttSegments.push(segment);
    }
    return ganttSegments;
  };
  /**
   * To update task end date cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.endDateEdited = function (args) {
    var ganttProb = args.data.ganttProperties;
    var currentValue = args.data[this.parent.taskFields.endDate];
    currentValue = currentValue ? new Date(currentValue.getTime()) : null;
    if (isNullOrUndefined(currentValue)) {
      this.parent.setRecordValue('endDate', currentValue, ganttProb, true);
      if (!(ganttProb.startDate === null && ganttProb.endDate === null && ganttProb.duration !== null)) {
        this.parent.setRecordValue('duration', null, ganttProb, true);
      }
      this.parent.setRecordValue('isMilestone', false, ganttProb, true);
    } else {
      var dayEndTime = this.parent['getCurrentDayEndTime'](currentValue);
      if (currentValue.getHours() === 0 && dayEndTime !== 86400) {
        this.parent.dateValidationModule.setTime(dayEndTime, currentValue);
      }
      currentValue = this.parent.dateValidationModule.checkEndDate(currentValue, ganttProb, ganttProb.isMilestone);
      this.parent.setRecordValue('endDate', currentValue, ganttProb, true);
      if (!isNullOrUndefined(ganttProb.startDate) && isNullOrUndefined(ganttProb.duration)) {
        if (this.parent.dateValidationModule.compareDates(ganttProb.endDate, ganttProb.startDate) === -1) {
          this.parent.setRecordValue('endDate', new Date(ganttProb.startDate.getTime()), ganttProb, true);
          dayEndTime = this.parent['getCurrentDayEndTime'](ganttProb.endDate);
          this.parent.dateValidationModule.setTime(dayEndTime, ganttProb.endDate);
        }
      } else if (!isNullOrUndefined(ganttProb.duration) && isNullOrUndefined(ganttProb.startDate)) {
        this.parent.setRecordValue('startDate', this.parent.dateValidationModule.getStartDate(ganttProb.endDate, ganttProb.duration, ganttProb.durationUnit, ganttProb), ganttProb, true);
      }
      if (!isNullOrUndefined(ganttProb.segments)) {
        ganttProb.segments = this.validateEndDateWithSegments(ganttProb);
      }
      if (this.compareDatesFromRecord(ganttProb) <= 0) {
        this.parent.dateValidationModule.calculateDuration(args.data);
      } else {
        this.parent.editModule.revertCellEdit(args);
      }
      this.updateDates(args);
      this.parent.setRecordValue('isMilestone', ganttProb.duration === 0 ? true : false, ganttProb, true);
      if (ganttProb.isMilestone) {
        this.parent.setRecordValue('startDate', this.parent.dateValidationModule.checkStartDate(ganttProb.startDate, ganttProb), ganttProb, true);
      }
    }
    if (!isNullOrUndefined(args.data.ganttProperties.segments) && args.data.ganttProperties.segments.length > 0) {
      this.parent.setRecordValue('segments', this.parent.dataOperation.setSegmentsInfo(args.data, false), args.data.ganttProperties, true);
      this.parent.dataOperation.updateMappingData(args.data, 'segments');
    }
    this.parent.dataOperation.updateWidthLeft(args.data);
    this.parent.dataOperation.updateMappingData(args.data, 'startDate');
    this.parent.dataOperation.updateMappingData(args.data, 'endDate');
    this.parent.dataOperation.updateMappingData(args.data, 'duration');
    this.parent.editModule.updateResourceRelatedFields(args.data, 'endDate');
    this.updateEditedRecord(args);
  };
  /**
   * To update duration cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.durationEdited = function (args) {
    var regex = /^[^\d.-]+$/;
    if (regex.test(args.data[this.parent.taskFields.duration])) {
      var err = "The provided value for the " + this.parent.taskFields.duration + " field is invalid. Please ensure the " + this.parent.taskFields.duration + " field contains only valid numeric values.";
      this.parent.trigger('actionFailure', {
        error: err
      });
    }
    if (parseInt(args.data[this.parent.taskFields.duration], 10) < 0) {
      args.data[this.parent.taskFields.duration] = 0;
    }
    var ganttProb = args.data.ganttProperties;
    var durationString = args.data[this.parent.taskFields.duration];
    this.parent.dataOperation.updateDurationValue(durationString, ganttProb);
    this.updateDates(args);
    this.parent.editModule.updateResourceRelatedFields(args.data, 'duration');
    this.updateEditedRecord(args);
  };
  /**
   * To update start date, end date based on duration
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.updateDates = function (args) {
    var ganttProb = args.data.ganttProperties;
    var endDate = this.parent.dateValidationModule.getDateFromFormat(ganttProb.endDate);
    var startDate = this.parent.dateValidationModule.getDateFromFormat(ganttProb.startDate);
    var currentDuration = ganttProb.duration;
    if (isNullOrUndefined(currentDuration)) {
      this.parent.setRecordValue('isMilestone', false, ganttProb, true);
      if (args.data[this.parent.taskFields.duration] !== null) {
        this.parent.setRecordValue('endDate', null, ganttProb, true);
      }
    } else {
      if (isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)) {
        this.parent.setRecordValue('startDate', this.parent.dateValidationModule.getStartDate(endDate, currentDuration, ganttProb.durationUnit, ganttProb), ganttProb, true);
      }
      if (currentDuration !== 0 && ganttProb.isMilestone) {
        this.parent.setRecordValue('isMilestone', false, ganttProb, true);
        if (!isNullOrUndefined(this.parent.taskFields.milestone)) {
          this.parent.setRecordValue(this.parent.taskFields.milestone, false, args.data, true);
        }
        this.parent.setRecordValue('startDate', this.parent.dateValidationModule.checkStartDate(ganttProb.startDate, ganttProb), ganttProb, true);
      }
      if (!isNullOrUndefined(ganttProb.segments) && ganttProb.segments.length > 0) {
        this.parent.setRecordValue('segments', this.parent.dataOperation.setSegmentsInfo(args.data, false), ganttProb, true);
        this.parent.dataOperation.updateMappingData(args.data, 'segments');
      }
      this.parent.setRecordValue('isMilestone', ganttProb.duration === 0 ? true : false, ganttProb, true);
      this.parent.dateValidationModule.calculateEndDate(args.data);
    }
    this.parent.dataOperation.updateWidthLeft(args.data);
    this.parent.dataOperation.updateMappingData(args.data, 'endDate');
    this.parent.dataOperation.updateMappingData(args.data, 'startDate');
    this.parent.dataOperation.updateMappingData(args.data, 'duration');
  };
  /**
   * To update progress cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.progressEdited = function (args) {
    var ganttRecord = args.data;
    this.parent.setRecordValue('progress', ganttRecord[this.parent.taskFields.progress] > 100 ? 100 : ganttRecord[this.parent.taskFields.progress], ganttRecord.ganttProperties, true);
    this.parent.setRecordValue('taskData.' + this.parent.taskFields.progress, ganttRecord[this.parent.taskFields.progress] > 100 ? 100 : ganttRecord[this.parent.taskFields.progress], args.data);
    if (!ganttRecord.ganttProperties.isAutoSchedule) {
      this.parent.setRecordValue('autoWidth', this.parent.dataOperation.calculateWidth(ganttRecord, false), ganttRecord.ganttProperties, true);
    }
    if (!isNullOrUndefined(args.data.ganttProperties.segments) && args.data.ganttProperties.segments.length > 0 && !isNullOrUndefined(this.parent.editModule.taskbarEditModule)) {
      this.parent.editModule.taskbarEditModule.updateSegmentProgress(args.data.ganttProperties);
    }
    if (!args.data.hasChildRecords) {
      var width = ganttRecord.ganttProperties.isAutoSchedule ? ganttRecord.ganttProperties.width : ganttRecord.ganttProperties.autoWidth;
      this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(width, ganttRecord.ganttProperties.progress), ganttRecord.ganttProperties, true);
    }
    this.updateEditedRecord(args);
  };
  /**
   * To update baselines with new baseline start date and baseline end date
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.baselineEdited = function (args) {
    var ganttRecord = args.data.ganttProperties;
    var baseLineStartDate = args.data[this.parent.taskFields.baselineStartDate];
    var baseLineEndDate = args.data[this.parent.taskFields.baselineEndDate];
    var dayEndTime = this.parent['getCurrentDayEndTime'](baseLineEndDate);
    if (baseLineEndDate && baseLineEndDate.getHours() === 0 && dayEndTime !== 86400) {
      this.parent.dateValidationModule.setTime(dayEndTime, baseLineEndDate);
    }
    this.parent.setRecordValue('baselineStartDate', this.parent.dateValidationModule.checkBaselineStartDate(baseLineStartDate, ganttRecord), ganttRecord, true);
    this.parent.setRecordValue('baselineEndDate', this.parent.dateValidationModule.checkBaselineEndDate(baseLineEndDate), ganttRecord, true);
    if (ganttRecord.baselineStartDate && ganttRecord.baselineEndDate) {
      this.parent.setRecordValue('baselineLeft', this.parent.dataOperation.calculateBaselineLeft(ganttRecord), ganttRecord, true);
      this.parent.setRecordValue('baselineWidth', this.parent.dataOperation.calculateBaselineWidth(ganttRecord), ganttRecord, true);
    }
    this.updateEditedRecord(args);
  };
  /**
   * To update task's resource cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @param {object} editedObj .
   * @param {IGanttData} previousData .
   * @returns {void} .
   */
  CellEdit.prototype.resourceEdited = function (args, editedObj, previousData) {
    var resourceSettings = this.parent.resourceFields;
    var editedResourceId = editedObj[this.parent.taskFields.resourceInfo];
    if (editedResourceId) {
      this.isResourceCellEdited = true;
      var tempResourceInfo = this.parent.dataOperation.setResourceInfo(editedObj);
      var editedResouceLength = tempResourceInfo.length;
      var previousResource = previousData.ganttProperties.resourceInfo;
      var index = void 0;
      var editedResources = [];
      var resourceData = this.parent.resources;
      var newIndex = void 0;
      var _loop_1 = function (count) {
        if (previousResource) {
          var previousResourceLength = previousResource.length;
          for (newIndex = 0; newIndex < previousResourceLength; newIndex++) {
            if (previousResource[newIndex][resourceSettings.id] === editedResourceId[count]) {
              index = newIndex;
              break;
            } else {
              index = -1;
            }
          }
        }
        if (!isNullOrUndefined(index) && index !== -1) {
          editedResources.push(previousResource[parseInt(index.toString(), 10)]);
        } else {
          var resource = resourceData.filter(function (resourceInfo) {
            return editedResourceId[count] === resourceInfo[resourceSettings.id];
          });
          var ganttDataResource = extend({}, resource[0]);
          ganttDataResource[resourceSettings.unit] = 100;
          editedResources.push(ganttDataResource);
        }
      };
      for (var count = 0; count < editedResouceLength; count++) {
        _loop_1(count);
      }
      args.data.ganttProperties.resourceInfo = editedResources;
      this.parent.dataOperation.updateMappingData(args.data, 'resourceInfo');
      this.parent.editModule.updateResourceRelatedFields(args.data, 'resource');
      if (this.parent.viewType === 'ResourceView') {
        this.parent.editModule.dialogModule.isResourceUpdate = true;
        this.parent.editModule.dialogModule.previousResource = previousResource;
      }
      this.updateDates(args);
      this.updateEditedRecord(args);
    }
  };
  /**
   * To update task's predecessor cell with new value
   *
   * @param {ITaskbarEditedEventArgs} editedArgs .
   * @param {object} cellEditArgs .
   * @returns {void} .
   */
  CellEdit.prototype.dependencyEdited = function (editedArgs, cellEditArgs) {
    var specialCharacterPattern = /[!@#$%^&*()_=[\]{};:<>|./?-]/;
    if (specialCharacterPattern.test(editedArgs.data[this.parent.taskFields.dependency])) {
      var err = "The provided value for the " + this.parent.taskFields.dependency + " field is invalid. Please ensure that the " + this.parent.taskFields.dependency + " field does not contain any special characters.";
      this.parent.trigger('actionFailure', {
        error: err
      });
    }
    this.parent.predecessorModule.updateUnscheduledDependency(editedArgs.data);
    var FF = this.parent.localeObj.getConstant('FF');
    var FS = this.parent.localeObj.getConstant('FS');
    var SS = this.parent.localeObj.getConstant('SS');
    var SF = this.parent.localeObj.getConstant('SF');
    var value = '';
    if (!isNullOrUndefined(editedArgs.data[this.parent.taskFields.dependency])) {
      var splitString_1 = editedArgs.data[this.parent.taskFields.dependency].split(',');
      splitString_1.map(function (splitvalue, index) {
        if (splitvalue.includes(FF)) {
          value += splitvalue.replace(FF, 'FF');
          value += splitString_1.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(FS)) {
          value += splitvalue.replace(FS, 'FS');
          value += splitString_1.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(SS)) {
          value += splitvalue.replace(SS, 'SS');
          value += splitString_1.length !== index + 1 ? ',' : '';
        } else if (splitvalue.includes(SF)) {
          value += splitvalue.replace(SF, 'SF');
          value += splitString_1.length !== index + 1 ? ',' : '';
        } else {
          value += splitvalue;
          value += splitString_1.length !== index + 1 ? ',' : '';
        }
      });
    }
    if (!this.parent.connectorLineEditModule.updatePredecessor(editedArgs.data, value, editedArgs)) {
      this.parent.editModule.revertCellEdit(cellEditArgs);
    }
  };
  /**
   * To update task's work cell with new value
   *
   * @param {ITaskbarEditedEventArgs} editedArgs .
   * @returns {void} .
   */
  CellEdit.prototype.workEdited = function (editedArgs) {
    var ganttProb = editedArgs.data.ganttProperties;
    if (editedArgs.data[this.parent.taskFields.work] < 0 || isNullOrUndefined(editedArgs.data[this.parent.taskFields.work])) {
      editedArgs.data[this.parent.taskFields.work] = 0;
    }
    this.parent['updateDuration'] = true;
    var workValue = editedArgs.data[this.parent.taskFields.work];
    this.parent.setRecordValue('work', workValue, ganttProb, true);
    this.parent.editModule.updateResourceRelatedFields(editedArgs.data, 'work');
    this.updateDates(editedArgs);
    this.updateEditedRecord(editedArgs);
    this.parent['updateDuration'] = false;
  };
  /**
   * To update task type cell with new value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @param {object} editedObj .
   * @returns {void} .
   */
  CellEdit.prototype.typeEdited = function (args, editedObj) {
    var key = this.parent.taskFields.type || 'taskType';
    var ganttProb = args.data.ganttProperties;
    var taskType = editedObj["" + key];
    this.parent.setRecordValue('taskType', taskType, ganttProb, true);
    // To validate the work column as well, if duartion column value is 0, when FixedDuration type
    if (ganttProb.taskType === 'FixedDuration' && ganttProb.duration === 0) {
      this.parent.editModule.updateResourceRelatedFields(args.data, 'work');
    }
    //this.parent.dataOperation.updateMappingData(args.data, 'taskType');
    this.updateEditedRecord(args);
  };
  /**
   * To compare start date and end date from Gantt record
   *
   * @param {ITaskData} ganttRecord .
   * @returns {number} .
   */
  CellEdit.prototype.compareDatesFromRecord = function (ganttRecord) {
    var sDate = this.parent.dateValidationModule.getValidStartDate(ganttRecord);
    var eDate = this.parent.dateValidationModule.getValidEndDate(ganttRecord);
    return this.parent.dateValidationModule.compareDates(sDate, eDate);
  };
  /**
   * To start method save action with edited cell value
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   */
  CellEdit.prototype.updateEditedRecord = function (args) {
    this.parent.editModule.initiateUpdateAction(args);
  };
  /**
   * To remove all public private properties
   *
   * @returns {void} .
   * @private
   */
  CellEdit.prototype.destroy = function () {
    // Destroy Method
    this.parent.editModule.cellEditModule = undefined;
  };
  return CellEdit;
}();

/**
 * File for handling taskbar editing tooltip in Gantt.
 */
var EditTooltip = /** @__PURE__ @class */function () {
  function EditTooltip(gantt, taskbarEdit) {
    this.parent = gantt;
    this.taskbarEdit = taskbarEdit;
  }
  /**
   * To create tooltip.
   *
   * @param {string} opensOn .
   * @param {boolean} mouseTrail .
   * @param {string} target .
   * @returns {void}
   * @private
   */
  EditTooltip.prototype.createTooltip = function (opensOn, mouseTrail, target) {
    var _this = this;
    this.toolTipObj = new Tooltip$1({
      opensOn: opensOn,
      position: 'TopRight',
      enableRtl: this.parent.enableRtl,
      mouseTrail: mouseTrail,
      cssClass: ganttTooltip,
      target: target ? target : null,
      animation: {
        open: {
          effect: 'None'
        },
        close: {
          effect: 'None'
        }
      }
    });
    this.toolTipObj.target = '.e-connectorpoint-left, .e-connectorpoint-right';
    this.toolTipObj.beforeRender = function (args) {
      var argsData = {
        data: _this.taskbarEdit.taskBarEditRecord,
        args: args,
        content: _this.toolTipObj.content
      };
      _this.parent.trigger('beforeTooltipRender', argsData);
    };
    this.toolTipObj.afterOpen = function (args) {
      _this.updateTooltipPosition(args);
    };
    this.toolTipObj.isStringTemplate = true;
    this.toolTipObj.appendTo(this.parent.chartPane);
  };
  /**
   * Method to update tooltip position
   *
   * @param {TooltipEventArgs} args .
   * @returns {void} .
   */
  EditTooltip.prototype.updateTooltipPosition = function (args) {
    args.element.style.visibility = 'visible';
    var parentWithZoomStyle = this.parent.element.closest('[style*="zoom"]');
    if (isNullOrUndefined(parentWithZoomStyle)) {
      var containerPosition = this.parent.getOffsetRect(this.parent.chartPane);
      var leftEnd = containerPosition.left + this.parent.chartPane.offsetWidth;
      var tooltipPositionX = args.element.offsetLeft;
      if (leftEnd < tooltipPositionX + args.element.offsetWidth) {
        tooltipPositionX += leftEnd - (tooltipPositionX + args.element.offsetWidth);
      }
      args.element.style.left = tooltipPositionX + 'px';
      args.element.style.visibility = 'visible';
      if (args.target && this.parent.tooltipSettings.editing) {
        var top_1 = args.target.getBoundingClientRect().top + window.scrollY;
        var tooltipPositionY = void 0;
        if (args.target.classList.contains('e-connectorpoint-left') || args.target.classList.contains('e-connectorpoint-right')) {
          tooltipPositionY = top_1 - args.element.offsetHeight - 14;
        } else {
          tooltipPositionY = top_1 - args.element.offsetHeight - 8;
        }
        args.element.style.top = tooltipPositionY + 'px';
      }
    }
  };
  /**
   * To show/hide taskbar edit tooltip.
   *
   * @param {boolean} bool .
   * @param {number} segmentIndex .
   * @returns {void}
   * @private
   */
  EditTooltip.prototype.showHideTaskbarEditTooltip = function (bool, segmentIndex) {
    if (bool && this.parent.tooltipSettings.showTooltip) {
      this.createTooltip('Custom', false);
      this.parent.tooltipModule.toolTipObj.close();
      this.updateTooltip(segmentIndex);
      if (this.parent.tooltipSettings.editing) {
        this.toolTipObj.openDelay = 500;
      }
      if (this.taskbarEdit.connectorSecondAction === 'ConnectorPointLeftDrag') {
        this.toolTipObj.open(this.taskbarEdit.connectorSecondElement.querySelector('.' + connectorPointLeft));
      } else if (this.taskbarEdit.connectorSecondAction === 'ConnectorPointRightDrag') {
        this.toolTipObj.open(this.taskbarEdit.connectorSecondElement.querySelector('.' + connectorPointRight));
      } else {
        this.toolTipObj.open(this.taskbarEdit.taskBarEditElement);
      }
    } else if (!isNullOrUndefined(this.toolTipObj)) {
      this.toolTipObj.destroy();
      this.toolTipObj = null;
    }
  };
  /**
   * To update tooltip content and position.
   *
   * @param {number} segmentIndex .
   * @returns {void} .
   * @private
   */
  EditTooltip.prototype.updateTooltip = function (segmentIndex) {
    var ganttProp = this.taskbarEdit.taskBarEditRecord.ganttProperties;
    var taskWidth = isNullOrUndefined(segmentIndex) || segmentIndex === -1 ? ganttProp.width : ganttProp.segments[segmentIndex].width;
    var progressWidth = isNullOrUndefined(segmentIndex) || segmentIndex === -1 ? ganttProp.progressWidth : ganttProp.segments[segmentIndex].progressWidth;
    var left = isNullOrUndefined(segmentIndex) || segmentIndex === -1 ? ganttProp.left : ganttProp.left + ganttProp.segments[segmentIndex].left;
    var topValue;
    if (!isNullOrUndefined(this.toolTipObj)) {
      if (this.taskbarEdit.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskbarEdit.taskBarEditAction === 'ConnectorPointRightDrag') {
        this.toolTipObj.content = this.getTooltipText(segmentIndex);
        if (this.parent.tooltipSettings.editing) {
          this.toolTipObj.openDelay = 500;
        }
        this.toolTipObj.offsetY = -3;
      } else {
        if (this.parent.tooltipSettings.editing) {
          this.toolTipObj.openDelay = 500;
        }
        this.toolTipObj.content = this.getTooltipText(segmentIndex);
        if (ganttProp.segments && ganttProp.segments.length > 0 && this.taskbarEdit['mainElement'] && this.taskbarEdit.taskBarEditAction === 'ProgressResizing') {
          var segments = this.taskbarEdit['mainElement'].querySelectorAll('.e-segmented-taskbar');
          topValue = segments[segmentIndex].getBoundingClientRect().top;
          this.toolTipObj.refresh(segments[segmentIndex]);
        } else {
          topValue = this.taskbarEdit.taskBarEditElement.getBoundingClientRect().top;
          this.toolTipObj.refresh(this.taskbarEdit.taskBarEditElement);
        }
        if (this.taskbarEdit.taskBarEditAction === 'LeftResizing') {
          if (this.parent.enableRtl) {
            this.toolTipObj.offsetX = 0;
          } else {
            this.toolTipObj.offsetX = -taskWidth;
          }
        } else if (this.taskbarEdit.taskBarEditAction === 'RightResizing' || this.taskbarEdit.taskBarEditAction === 'ParentResizing') {
          if (this.parent.enableRtl) {
            this.toolTipObj.offsetX = -taskWidth;
          } else {
            this.toolTipObj.offsetX = 0;
          }
        } else if (this.taskbarEdit.taskBarEditAction === 'ProgressResizing') {
          if (this.parent.enableRtl) {
            this.toolTipObj.offsetX = -progressWidth;
          } else {
            this.toolTipObj.offsetX = -(taskWidth - progressWidth);
          }
        } else if (this.taskbarEdit.taskBarEditAction === 'MilestoneDrag') {
          this.toolTipObj.offsetX = -(this.parent.chartRowsModule.milestoneHeight / 2);
        } else if (taskWidth > 5) {
          this.toolTipObj.offsetX = -(taskWidth + left - this.taskbarEdit.tooltipPositionX);
        }
        if (topValue && this.parent.tooltipSettings.editing) {
          if (document.getElementsByClassName('e-gantt-tooltip')[0]) {
            document.getElementsByClassName('e-gantt-tooltip')[0].style.top = topValue - this.parent.element.getElementsByClassName('e-timeline-header-container')[0]['offsetHeight'] + window.scrollY + 'px';
          }
        }
      }
    }
  };
  /**
   * To get updated tooltip text.
   *
   * @param {number} segmentIndex .
   * @returns {void} .
   * @private
   */
  EditTooltip.prototype.getTooltipText = function (segmentIndex) {
    var tooltipString = '';
    var instance = this.parent.globalize;
    var editRecord = this.taskbarEdit.taskBarEditRecord.ganttProperties;
    if (!isNullOrUndefined(editRecord.segments) && editRecord.segments.length > 0 && segmentIndex !== -1 && this.taskbarEdit.taskBarEditAction !== 'ProgressResizing') {
      editRecord = editRecord.segments[segmentIndex];
    }
    if (this.parent.tooltipSettings.editing) {
      var templateNode = this.parent.tooltipModule.templateCompiler(this.parent.tooltipSettings.editing, this.parent, this.taskbarEdit.taskBarEditRecord, 'TooltipEditingTemplate');
      if (getValue('tooltipEle', this.toolTipObj)) {
        this.parent.renderTemplates();
      }
      tooltipString = templateNode[0];
    } else {
      var startDate = void 0;
      var endDate = void 0;
      var duration = void 0;
      if (!isNullOrUndefined(editRecord) && !isNullOrUndefined(editRecord.startDate)) {
        startDate = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('startDate') + '</td><td style="padding: 2px;">:</td><td class = "e-gantt-tooltip-value">' + instance.formatDate(editRecord.startDate, {
          format: this.parent.getDateFormat()
        }) + '</td></tr>';
      }
      if (!isNullOrUndefined(editRecord) && !isNullOrUndefined(editRecord.endDate)) {
        endDate = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('endDate') + '</td><td style="padding: 2px;">:</td><td class = "e-gantt-tooltip-value">' + instance.formatDate(editRecord.endDate, {
          format: this.parent.getDateFormat()
        }) + '</td></tr>';
      }
      if (!isNullOrUndefined(editRecord) && !isNullOrUndefined(editRecord.duration)) {
        duration = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('duration') + '</td><td style="padding: 2px;">:</td><td class = "e-gantt-tooltip-value">' + this.parent.getDurationString(editRecord.duration, editRecord.durationUnit) + '</td></tr>';
      }
      switch (this.taskbarEdit.taskBarEditAction) {
        case 'ProgressResizing':
          {
            var progress = '<tr><td class = "e-gantt-tooltip-label">' + this.parent.localeObj.getConstant('progress') + '</td><td style="padding: 2px;">:</td><td class = "e-gantt-tooltip-value">' + editRecord.progress + '</td></tr>';
            tooltipString = '<table class = "e-gantt-tooltiptable"><tbody>' + progress + '</tbody></table>';
            break;
          }
        case 'LeftResizing':
          {
            tooltipString = '<table class = "e-gantt-tooltiptable"><tbody>' + startDate + duration + '</tbody></table>';
            break;
          }
        case 'RightResizing':
        case 'ParentResizing':
          {
            tooltipString = '<table class = "e-gantt-tooltiptable"><tbody>' + endDate + duration + '</tbody></table>';
            break;
          }
        case 'ChildDrag':
        case 'ParentDrag':
        case 'MilestoneDrag':
        case 'ManualParentDrag':
          {
            var sDate = '';
            var eDate = '';
            if (!isNullOrUndefined(this.taskbarEdit.taskBarEditRecord.ganttProperties.startDate)) {
              sDate = startDate;
            }
            if (!isNullOrUndefined(this.taskbarEdit.taskBarEditRecord.ganttProperties.endDate)) {
              eDate = endDate;
            }
            tooltipString = '<table class = "e-gantt-tooltiptable"><tbody>' + sDate + eDate + '</tbody></table>';
            break;
          }
        case 'ConnectorPointLeftDrag':
        case 'ConnectorPointRightDrag':
          {
            tooltipString = this.parent.connectorLineModule.tooltipTable;
            if (isNullOrUndefined(this.toolTipObj)) {
              this.parent.connectorLineModule.tooltipTable.innerHTML = this.parent.connectorLineModule.getConnectorLineTooltipInnerTd(this.parent.editModule.taskbarEditModule.taskBarEditRecord.ganttProperties.taskName, this.parent.editModule.taskbarEditModule.fromPredecessorText, '', '');
            }
            break;
          }
      }
    }
    return tooltipString;
  };
  return EditTooltip;
}();
var __extends$l = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign$1 = undefined && undefined.__assign || function () {
  __assign$1 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$1.apply(this, arguments);
};
/**
 * File for handling taskbar editing operation in Gantt.
 */
var TaskbarEdit = /** @__PURE__ @class */function (_super) {
  __extends$l(TaskbarEdit, _super);
  function TaskbarEdit(ganttObj) {
    var _this = _super.call(this, ganttObj) || this;
    _this.leftValue = 0;
    _this.previousLeftValue = 0;
    _this.isMouseDragged = false;
    _this.dependencyCancel = false;
    _this.isDragged = false;
    _this.editElement = null;
    _this.elementOffsetLeft = 0;
    _this.elementOffsetTop = 0;
    _this.elementOffsetWidth = 0;
    _this.elementOffsetHeight = 0;
    _this.segmentIndex = -1;
    _this.currentItemTop = 0;
    _this.currentItemPrevTop = 0;
    _this.topValue = 0;
    _this.draggedRecordMarginTop = '0px';
    _this.isClonedElement = false;
    _this.updatePosition = false;
    _this.tooltipValue = 0;
    _this.parent = ganttObj;
    _this.initPublicProp();
    _this.wireEvents();
    _this.editTooltip = new EditTooltip(_this.parent, _this);
    return _this;
  }
  TaskbarEdit.prototype.wireEvents = function () {
    this.parent.on('chartMouseDown', this.mouseDownHandler, this);
    this.parent.on('chartMouseUp', this.mouseUpHandler, this);
    this.parent.on('chartMouseLeave', this.mouseLeaveHandler, this);
    this.parent.on('chartMouseMove', this.mouseMoveAction, this);
    this.parent.on('chartMouseClick', this.mouseClickHandler, this);
  };
  /**
   * To initialize the public property.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.initPublicProp = function () {
    this.taskBarEditElement = null;
    this.taskBarEditRecord = null;
    this.taskBarEditAction = null;
    this.connectorSecondElement = null;
    this.connectorSecondRecord = null;
    this.connectorSecondAction = null;
    this.highlightedSecondElement = null;
    this.fromPredecessorText = null;
    this.toPredecessorText = null;
    this.finalPredecessor = null;
    this.drawPredecessor = false;
    this.roundOffDuration = true;
    this.dragMouseLeave = false;
    this.isMouseDragged = false;
    this.previousItemProperty = ['left', 'progress', 'duration', 'isMilestone', 'startDate', 'endDate', 'width', 'progressWidth', 'autoLeft', 'autoDuration', 'autoStartDate', 'autoEndDate', 'autoWidth', 'segments'];
    this.tapPointOnFocus = false;
    this.touchEdit = false;
  };
  TaskbarEdit.prototype.mouseDownHandler = function (e) {
    if (this.parent.editSettings.allowTaskbarEditing && !this.parent.readOnly) {
      this.canDrag = false;
      if (this.taskBarEditElement) {
        var targetElement = this.getElementByPosition(e);
        var element = parentsUntil(targetElement, taskBarMainContainer);
        if (element && element.innerHTML === this.taskBarEditElement.innerHTML || this.taskBarEditElement.classList.contains('e-segmented-taskbar') || this.taskBarEditElement.classList.contains('collpse-parent-border')) {
          this.updateTaskBarEditElement(e);
          this.canDrag = true;
          e.preventDefault();
        }
      } else if (!this.parent.isAdaptive) {
        this.updateTaskBarEditElement(e);
      }
    }
  };
  TaskbarEdit.prototype.mouseClickHandler = function (e) {
    if (!this.parent.editSettings.allowTaskbarEditing) {
      return;
    }
    var targetElement = this.getElementByPosition(e);
    var element = parentsUntil(targetElement, taskBarMainContainer);
    if (this.parent.selectionModule && this.parent.selectionModule.enableSelectMultiTouch) {
      if (this.tapPointOnFocus) {
        this.updateTaskBarEditElement(e);
      }
      return;
    }
    if (this.tapPointOnFocus && !isNullOrUndefined(this.taskBarEditElement) && element && element.innerHTML !== this.taskBarEditElement.innerHTML) {
      this.connectorSecondRecord = this.parent.ganttChartModule.getRecordByTaskBar(element);
      this.connectorSecondAction = 'ConnectorPointLeftDrag';
      this.connectorSecondElement = element;
      this.fromPredecessorText = 'Finish';
      if (this.validateConnectorPoint()) {
        this.taskBarEditingAction(e, true);
      }
      this.showHideActivePredecessors(false);
      this.initPublicProp();
    } else if (targetElement.classList.contains(connectorPointLeftHover)) {
      this.canDrag = false;
      this.multipleSelectionEnabled();
      this.showHideTaskBarEditingElements(targetElement, this.taskBarEditElement);
      this.tapPointOnFocus = true;
      this.taskBarEditAction = 'ConnectorPointLeftDrag';
      this.connectorSecondRecord = this.taskBarEditRecord;
      this.taskBarEditingAction(e, false);
    } else if (targetElement.classList.contains(connectorPointRightHover)) {
      this.canDrag = false;
      this.multipleSelectionEnabled();
      this.showHideTaskBarEditingElements(targetElement, this.taskBarEditElement);
      this.tapPointOnFocus = true;
      this.taskBarEditAction = 'ConnectorPointRightDrag';
      this.connectorSecondRecord = this.taskBarEditRecord;
      this.taskBarEditingAction(e, false);
    } else {
      if (this.tapPointOnFocus) {
        this.showHideActivePredecessors(false);
        this.showHideTaskBarEditingElements(element, this.taskBarEditElement);
      }
      this.updateTaskBarEditElement(e);
    }
  };
  TaskbarEdit.prototype.showHideActivePredecessors = function (show) {
    var ganttProp = this.taskBarEditRecord.ganttProperties;
    var predecessors = ganttProp.predecessor;
    var id = this.parent.viewType === 'ResourceView' ? ganttProp.taskId : ganttProp.rowUniqueID;
    if (predecessors) {
      for (var i = 0; i < predecessors.length; i++) {
        var predecessor = predecessors[i];
        if (id.toString() === predecessor.from || id.toString() === predecessor.to) {
          this.applyActiveColor(predecessor.from, predecessor.to, show);
        }
      }
    }
    var chartContent = this.parent.ganttChartModule.chartBodyContainer;
    if (show) {
      addClass([this.taskBarEditElement], [activeChildTask]);
      addClass([chartContent], [touchMode]);
    } else {
      removeClass([this.taskBarEditElement], [activeChildTask]);
      removeClass([chartContent], [touchMode]);
    }
    this.touchEdit = show;
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
  };
  TaskbarEdit.prototype.applyActiveColor = function (from, to, enable) {
    var taskId = this.parent.viewType === 'ProjectView' ? this.taskBarEditRecord.ganttProperties.taskId.toString() : this.taskBarEditRecord.ganttProperties.rowUniqueID.toString();
    var ganttRecord = taskId === from ? this.parent.connectorLineModule.getRecordByID(to) : this.parent.connectorLineModule.getRecordByID(from);
    var $tr = this.parent.ganttChartModule.getChartRows()[this.parent.currentViewData.indexOf(ganttRecord)];
    if (!isNullOrUndefined($tr)) {
      var $taskbar = $tr.querySelector('.' + taskBarMainContainer);
      var $connectorElement = this.parent.element.querySelector('#ConnectorLineparent' + from + 'child' + to);
      if (enable) {
        addClass([$taskbar], [activeConnectedTask]);
        if ($connectorElement) {
          addClass([$connectorElement], [activeConnectedTask]);
        }
      } else {
        removeClass([$taskbar], [activeConnectedTask]);
        if ($connectorElement) {
          removeClass([$connectorElement], [activeConnectedTask]);
        }
      }
    }
  };
  TaskbarEdit.prototype.validateConnectorPoint = function () {
    var parentRecord = this.taskBarEditRecord.ganttProperties;
    var childRecord = this.connectorSecondRecord.ganttProperties;
    var isValid = true;
    var parentId = this.parent.viewType === 'ResourceView' ? parentRecord.taskId : parentRecord.rowUniqueID;
    var childId = this.parent.viewType === 'ResourceView' ? childRecord.taskId : childRecord.rowUniqueID;
    if (this.connectorSecondRecord.hasChildRecords) {
      isValid = false;
    } else if (childRecord.predecessor) {
      for (var i = 0; i < childRecord.predecessor.length; i++) {
        var predecessor = childRecord.predecessor[i];
        if (predecessor.from === parentId.toString() && predecessor.to === childId.toString()) {
          this.parent.connectorLineEditModule.childRecord = this.connectorSecondRecord;
          this.parent.connectorLineEditModule.predecessorIndex = i;
          this.parent.connectorLineEditModule.renderPredecessorDeleteConfirmDialog();
          isValid = false;
          break;
        } else if (predecessor.from === childId.toString() && predecessor.to === parentId.toString()) {
          this.parent.connectorLineEditModule.childRecord = this.taskBarEditRecord;
          this.parent.connectorLineEditModule.predecessorIndex = i;
          this.parent.connectorLineEditModule.renderPredecessorDeleteConfirmDialog();
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };
  // eslint-disable-next-line
  TaskbarEdit.prototype.mouseLeaveHandler = function (e) {
    if (this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'ParentDrag' || this.taskBarEditAction === 'ProgressResizing' || this.taskBarEditAction === 'LeftResizing' || this.taskBarEditAction === 'RightResizing') {
      this.dragMouseLeave = false;
    } else {
      this.dragMouseLeave = true;
    }
  };
  /**
   * To update taskbar edited elements on mouse down action.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateTaskBarEditElement = function (e) {
    var target = this.getElementByPosition(e);
    var cloneTaskBar;
    var element;
    if (target.classList.contains(manualParentRightResizer) || target.classList.contains(manualParentMainContainer) || target.classList.contains(manualParentTaskBar)) {
      element = parentsUntil(target, manualParentMainContainer);
    } else if (target.classList.contains(manualParentMilestone)) {
      element = parentsUntil(target, manualParentMilestone);
    } else {
      element = parentsUntil(target, taskBarMainContainer);
      if (!isNullOrUndefined(element) && !target.classList.contains('e-connectorpoint-left') && !target.classList.contains('e-connectorpoint-right')) {
        var currentRecord = this.parent.ganttChartModule.getRecordByTaskBar(element);
        if (!isNullOrUndefined(currentRecord.ganttProperties.segments) && currentRecord.ganttProperties.segments.length > 0) {
          if (target.classList.contains('e-progressbar-handler-after')) {
            for (var i = 0; i < currentRecord.ganttProperties.segments.length; i++) {
              if (currentRecord.ganttProperties.segments[i].showProgress) {
                element = element.querySelectorAll('.e-segmented-taskbar')[i];
                break;
              }
            }
          } else {
            element = parentsUntil(target, childTaskBarInnerDiv);
          }
        }
      }
    }
    if (this.parent.editSettings.allowTaskbarEditing && element) {
      this.showHideTaskBarEditingElements(element, this.editElement);
      this.editElement = element;
      this.realTaskbarElement = this.editElement;
      var index = this.editElement.getAttribute('data-segment-index');
      if (!isNullOrUndefined(index)) {
        this.segmentIndex = Number(index);
      } else {
        this.segmentIndex = -1;
      }
      this.taskBarEditRecord = this.parent.ganttChartModule.getRecordByTaskBar(this.editElement);
      if (e.type === 'mousedown' || e.type === 'touchstart' || e.type === 'click') {
        this.taskBarEditAction = this.getTaskBarAction(e);
        var index_1 = this.editElement.getAttribute('data-segment-index');
        var currentRecord = this.parent.ganttChartModule.getRecordByTaskBar(element);
        var ganttprop = currentRecord.ganttProperties;
        var parentleft = this.editElement.parentElement.offsetLeft;
        this.currentData = currentRecord;
        var resizeElement = createElement('div', {
          styles: 'height:100%;border-style:dashed;border-bottom:none;border-top:none;border-width:1px;position:absolute;z-index:10000'
        });
        addClass([resizeElement], 'e-taskbar-resize-div');
        resizeElement.style.setProperty('width', ganttprop.width + 'px');
        var currentindex = this.editElement.getAttribute('data-segment-index');
        if (!isNullOrUndefined(ganttprop.segments) && this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
          resizeElement.style.setProperty('left', !isNullOrUndefined(ganttprop.segments) ? parentleft + 'px' : ganttprop.left + 'px');
        } else {
          resizeElement.style.setProperty('left', !isNullOrUndefined(ganttprop.segments) && ganttprop.segments.length > 1 ? parentleft + ganttprop.segments[Number(currentindex)].left + 'px' : ganttprop.left + 'px');
        }
        var resizeTable = this.parent.createElement('table');
        var resizetableBody = this.parent.createElement('tbody');
        resizetableBody.appendChild(resizeElement);
        resizeTable.appendChild(resizetableBody);
        var Check = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-clone-taskbar');
        var createTable = null;
        if ((this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'LeftResizing') && !isNullOrUndefined(index_1) && !isNullOrUndefined(index_1) ? Number(index_1) === 0 : false) {
          cloneTaskBar = this.editElement.parentElement.cloneNode(true);
        } else {
          if (this.segmentIndex !== -1 && this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
            cloneTaskBar = this.editElement.parentElement.cloneNode(true);
          } else {
            cloneTaskBar = this.editElement.cloneNode(true);
          }
        }
        if (!Check) {
          addClass([cloneTaskBar], 'e-clone-taskbar');
          cloneTaskBar.style.setProperty('position', 'absolute');
          if (currentRecord.hasChildRecords && !currentRecord.ganttProperties.isAutoSchedule) {
            var containerPosition = this.parent.getOffsetRect(this.parent.ganttChartModule.chartBodyContainer);
            var taskbarPosition = this.parent.getOffsetRect(this.editElement);
            if (this.editElement.classList.contains('e-manualparent-main-container')) {
              cloneTaskBar.style.setProperty('left', currentRecord.ganttProperties.left - currentRecord.ganttProperties.autoLeft + 'px');
            } else {
              cloneTaskBar.style.setProperty(this.parent.enableRtl ? 'right' : 'left', Math.abs(taskbarPosition.left - containerPosition.left + Math.abs(this.parent.ganttChartModule.scrollElement.scrollLeft)) + 'px');
            }
          }
          cloneTaskBar.style.setProperty('top', 0 + 'px');
          createTable = this.parent.createElement('table');
          var tableBody = this.parent.createElement('tbody');
          tableBody.appendChild(cloneTaskBar);
          createTable.appendChild(tableBody);
        }
        if ((this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'LeftResizing') && !isNullOrUndefined(index_1) && !isNullOrUndefined(index_1) ? Number(index_1) === 0 : false) {
          var segmentedTasks = cloneTaskBar.getElementsByClassName('e-segmented-taskbar');
          this.cloneTaskbarElement = segmentedTasks[Number(index_1)];
        } else {
          this.cloneTaskbarElement = cloneTaskBar;
        }
        this.taskBarEditElement = this.cloneTaskbarElement;
        this.taskbarElement = createTable;
        this.taskbarResizer = resizeTable;
        this.currentIndex = index_1;
        this.roundOffDuration = true;
        if ((this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag') && isNullOrUndefined(this.parent.taskFields.dependency)) {
          this.taskBarEditAction = null;
        }
        this.updateMouseDownProperties(e);
        if (this.parent.viewType === 'ResourceView' || this.parent.viewType === 'ProjectView') {
          if (this.taskBarEditRecord.level === 0) {
            return;
          } else if (this.parent.enableMultiTaskbar) {
            var parentRecord = this.parent.getTaskByUniqueID(this.taskBarEditRecord.parentItem.uniqueID);
            if (!isNullOrUndefined(parentRecord) && !parentRecord.expanded) {
              this.prevZIndex = this.taskBarEditElement.style.zIndex;
              this.taskBarEditElement.style.zIndex = '1000';
              if (this.taskBarEditElement.querySelector('.e-gantt-child-taskbar')) {
                addClass([this.taskBarEditElement.querySelector('.e-gantt-child-taskbar')], 'e-collapsed-taskbar-drag');
              }
            }
          }
        }
      }
    } else {
      if (this.parent.isAdaptive) {
        if (this.taskBarEditElement) {
          this.showHideTaskBarEditingElements(element, this.editElement);
        }
        this.initPublicProp();
      } else {
        this.showHideTaskBarEditingElements(element, this.editElement);
      }
    }
  };
  /**
   * To show/hide taskbar editing elements.
   *
   * @param {Element} element .
   * @param {Element} secondElement .
   * @param {boolean} fadeConnectorLine .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.showHideTaskBarEditingElements = function (element, secondElement, fadeConnectorLine) {
    secondElement = secondElement ? secondElement : this.editElement;
    var isShowProgressResizer = this.parent.taskFields.progress ? true : false;
    var isShowConnectorPoints = true;
    if (this.parent.readOnly) {
      return;
    }
    var record = element ? this.parent.ganttChartModule.getRecordByTaskBar(element) : secondElement ? this.parent.ganttChartModule.getRecordByTaskBar(secondElement) : null;
    if (this.parent.enableMultiTaskbar && element) {
      var parentRecord = this.parent.getParentTask(record.parentItem);
      if (!isNullOrUndefined(parentRecord)) {
        if (!parentRecord.expanded) {
          isShowProgressResizer = false;
          isShowConnectorPoints = false;
        }
      }
    }
    if (element) {
      if (element.querySelector('.' + taskBarLeftResizer)) {
        addClass([element.querySelector('.' + taskBarLeftResizer)], [leftResizeGripper]);
        addClass([element.querySelector('.' + taskBarRightResizer)], [rightResizeGripper]);
        if (isShowProgressResizer) {
          var progressElement = record && record.ganttProperties.segments && record.ganttProperties.segments.length > 0 ? this.parent.getRowByIndex(this.parent.enableVirtualization ? this.parent.currentViewData.indexOf(record) : this.parent.flatData.indexOf(record)).querySelectorAll('.' + childProgressResizer)[0] : element.querySelector('.' + childProgressResizer);
          if (!isNullOrUndefined(progressElement)) {
            addClass([progressElement], [progressResizeGripper]);
            progressElement.style.top = '3px';
          }
        }
      } else if (this.parent.isAdaptive && isShowProgressResizer) {
        var record_1 = this.parent.ganttChartModule.getRecordByTaskBar(element);
        if (record_1.hasChildRecords) {
          addClass([element], [activeParentTask]);
        }
      }
      addClass(this.parent.ganttChartModule.scrollElement.querySelectorAll('.' + connectorLineContainer), [connectorLineZIndex]);
      if (!isNullOrUndefined(this.parent.taskFields.dependency) && (element.querySelector('.' + connectorPointLeft) || element.parentElement.querySelector('.' + connectorPointLeft)) && isShowConnectorPoints) {
        var connectorElement = !isNullOrUndefined(element.querySelector('.' + connectorPointLeft)) ? element : element.parentElement;
        if (!isNullOrUndefined(connectorElement.querySelector('.' + connectorPointLeft))) {
          addClass([connectorElement.querySelector('.' + connectorPointLeft)], [connectorPointLeftHover]);
        }
        if (!isNullOrUndefined(connectorElement.querySelector('.' + connectorPointRight))) {
          addClass([connectorElement.querySelector('.' + connectorPointRight)], [connectorPointRightHover]);
        }
      }
    } else if (!fadeConnectorLine) {
      removeClass(this.parent.ganttChartModule.scrollElement.querySelectorAll('.' + connectorLineContainer), [connectorLineZIndex]);
    }
    if (secondElement && element !== secondElement) {
      if (secondElement.querySelector('.' + taskBarLeftResizer)) {
        removeClass([secondElement.querySelector('.' + taskBarLeftResizer)], [leftResizeGripper]);
        removeClass([secondElement.querySelector('.' + taskBarRightResizer)], [rightResizeGripper]);
        var progressElement = record && record.ganttProperties.segments && record.ganttProperties.segments.length > 0 ? this.parent.getRowByIndex(this.parent.enableVirtualization ? this.parent.currentViewData.indexOf(record) : this.parent.flatData.indexOf(record)) : secondElement;
        if (progressElement && progressElement.querySelector('.' + childProgressResizer)) {
          removeClass([progressElement.querySelector('.' + childProgressResizer)], [progressResizeGripper]);
        }
      }
      if (!isNullOrUndefined(this.parent.taskFields.dependency) && (secondElement.querySelector('.' + connectorPointLeft) || secondElement.parentElement.querySelector('.' + connectorPointLeft))) {
        var connectorElement = !isNullOrUndefined(secondElement.querySelector('.' + connectorPointLeft)) ? secondElement : secondElement.parentElement;
        var leftConnector = connectorElement.querySelector('.' + connectorPointLeft);
        var rightConnector = connectorElement.querySelector('.' + connectorPointRight);
        if (leftConnector) {
          removeClass([leftConnector], [connectorPointLeftHover]);
        }
        if (rightConnector) {
          removeClass([rightConnector], [connectorPointRightHover]);
        }
      } else if (this.parent.isAdaptive) {
        var record_2 = this.parent.ganttChartModule.getRecordByTaskBar(secondElement);
        if (record_2 && record_2.hasChildRecords) {
          removeClass([secondElement], [activeParentTask]);
        }
      }
      this.editElement = null;
    }
  };
  /**
   * To get taskbar edit actions.
   *
   * @param {PointerEvent} e .
   * @returns {string} .
   * @private
   */
  TaskbarEdit.prototype.getTaskBarAction = function (e) {
    var mouseDownElement = this.getElementByPosition(e);
    var data = this.taskBarEditRecord;
    var action = '';
    if (mouseDownElement.classList.contains(taskBarLeftResizer)) {
      action = 'LeftResizing';
    } else if (mouseDownElement.classList.contains(taskBarRightResizer)) {
      action = 'RightResizing';
    } else if ((mouseDownElement.classList.contains(childProgressResizer) || closest(mouseDownElement, '.' + childProgressResizer)) && this.parent.taskFields.progress) {
      action = 'ProgressResizing';
    } else if (mouseDownElement.classList.contains(connectorPointLeft)) {
      action = 'ConnectorPointLeftDrag';
    } else if (mouseDownElement.classList.contains(connectorPointRight)) {
      action = 'ConnectorPointRightDrag';
    } else if (mouseDownElement.classList.contains(manualParentRightResizer)) {
      action = 'ParentResizing';
    } else if (mouseDownElement.classList.contains(manualParentTaskBar) || mouseDownElement.classList.contains(manualParentMainContainer) || mouseDownElement.classList.contains(manualParentMilestone)) {
      action = 'ManualParentDrag';
    } else if (data) {
      action = data.hasChildRecords ? data[this.parent.taskFields.manual] ? '' : 'ParentDrag' : data.ganttProperties.isMilestone ? 'MilestoneDrag' : 'ChildDrag';
    }
    return action;
  };
  /**
   * To update property while perform mouse down.
   *
   * @param {PointerEvent} event .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateMouseDownProperties = function (event) {
    var e = this.getCoordinate(event);
    var parentWithZoomStyle = this.parent.element.closest('[style*="zoom"]');
    if (parentWithZoomStyle) {
      var zoom1 = parseFloat(getComputedStyle(parentWithZoomStyle).zoom);
      e.pageX = e.pageX / zoom1;
      e.pageY = e.pageY / zoom1;
    }
    if (e.pageX || e.pageY) {
      var containerPosition = this.parent.getOffsetRect(this.parent.ganttChartModule.chartBodyContainer);
      if (this.parent.enableRtl) {
        this.mouseDownX = Math.abs(e.pageX - (containerPosition.left + Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left)));
      } else {
        this.mouseDownX = e.pageX - containerPosition.left + this.parent.ganttChartModule.scrollObject.previousScroll.left;
      }
      this.tooltipPositionX = this.mouseDownX;
      this.mouseDownY = this.dragMoveY = e.pageY - containerPosition.top + this.parent.ganttChartModule.scrollObject.previousScroll.top;
    }
    if (this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag') {
      this.fromPredecessorText = this.taskBarEditAction === 'ConnectorPointLeftDrag' ? 'start' : 'finish';
      this.parent.connectorLineModule.tooltipTable.innerHTML = this.parent.connectorLineModule.getConnectorLineTooltipInnerTd(this.taskBarEditRecord.ganttProperties.taskName, this.fromPredecessorText, '', '');
    }
  };
  TaskbarEdit.prototype.isMouseDragCheck = function () {
    if (!this.isMouseDragged && this.taskBarEditAction && (this.mouseDownX !== this.mouseMoveX && (this.mouseDownX + 3 < this.mouseMoveX || this.mouseDownX - 3 > this.mouseMoveX) || this.mouseDownY !== this.mouseMoveY && (this.mouseDownY + 3 < this.mouseMoveY || this.mouseDownY - 3 > this.mouseMoveY))) {
      this.isMouseDragged = true;
      this.parent.initiateEditAction(true);
      var item = this.taskBarEditRecord.ganttProperties;
      this.previousItem = this.parent.timelineModule.extendFunction(item, this.previousItemProperty);
      if (this.taskBarEditAction !== 'ConnectorPointLeftDrag' && this.taskBarEditAction !== 'ConnectorPointRightDrag' && !(this.parent.viewType === 'ResourceView' && this.taskBarEditAction === 'ParentDrag')) {
        this.editTooltip.showHideTaskbarEditTooltip(true, this.segmentIndex);
      }
      this.taskBarEditElement.setAttribute('aria-grabbed', 'true');
    }
  };
  /* eslint-disable-next-line */
  TaskbarEdit.prototype.removeFirstBorder = function (element) {
    var canremove = this.parent.rowDragAndDropModule['dropPosition'] === 'bottomSegment';
    if (this.parent.element.getElementsByClassName('e-firstrow-border').length > 0 && element && (element.getAttribute('data-rowindex') !== 0 || canremove)) {
      this.parent.element.getElementsByClassName('e-firstrow-border')[0].remove();
    }
  };
  TaskbarEdit.prototype.removeLastBorder = function (element) {
    var isEmptyRow = element && (element.classList.contains('e-emptyrow') || element.classList.contains('e-columnheader') || element.classList.contains('e-detailrow'));
    var islastRowIndex;
    if (this.parent.enableVirtualization) {
      islastRowIndex = element && !isEmptyRow && this.parent.treeGrid.getRows()[this.parent.treeGrid.getCurrentViewRecords().length - 1].getAttribute('data-uid') !== element.getAttribute('data-uid');
    } else {
      islastRowIndex = element && !isEmptyRow && this.parent.treeGrid.getRowByIndex(this.parent.treeGrid.getCurrentViewRecords().length - 1).getAttribute('data-uid') !== element.getAttribute('data-uid');
    }
    var canremove = islastRowIndex || this.parent.rowDragAndDropModule['dropPosition'] === 'topSegment';
    if (this.parent.element.getElementsByClassName('e-lastrow-border').length > 0 && element && (islastRowIndex || canremove)) {
      this.parent.element.getElementsByClassName('e-lastrow-border')[0].remove();
    }
  };
  TaskbarEdit.prototype.removetopOrBottomBorder = function () {
    var border = [].slice.call(this.parent.element.querySelectorAll('.e-dropbottom, .e-droptop'));
    if (border.length) {
      this.addRemoveClasses(border, false, 'e-dropbottom');
      this.addRemoveClasses(border, false, 'e-droptop');
    }
  };
  TaskbarEdit.prototype.topOrBottomBorder = function (target) {
    var rowElement;
    if (this.parent.enableVirtualization) {
      var gridElement = this.parent.treeGrid.getRows().filter(function (data) {
        return data.getAttribute('data-rowindex') === target.getAttribute('data-rowindex');
      })[0];
      var index = this.parent.treeGrid.getRows().indexOf(gridElement);
      rowElement = this.parent.getRowByIndex(index).children;
    } else {
      rowElement = this.parent.getRowByIndex(parseInt(target.getAttribute('data-rowindex'), 10)).children;
    }
    if (rowElement) {
      if (this.parent.rowDragAndDropModule['dropPosition'] === 'above') {
        this.addRemoveClasses(rowElement, true, 'e-droptop');
        if (this.parent.element.getElementsByClassName('e-lastrow-dragborder').length > 0) {
          this.parent.element.getElementsByClassName('e-lastrow-dragborder')[0].remove();
        }
      }
      if (this.parent.rowDragAndDropModule['dropPosition'] === 'below') {
        this.addRemoveClasses(rowElement, true, 'e-dropbottom');
      }
    }
  };
  TaskbarEdit.prototype.removeChildBorder = function () {
    var borderElem = [].slice.call(this.parent.element.querySelectorAll('.e-childborder'));
    if (borderElem.length > 0) {
      this.addRemoveClasses(borderElem, false, 'e-childborder');
    }
  };
  TaskbarEdit.prototype.addRemoveClasses = function (cells, add, className) {
    for (var i = 0, len = cells.length; i < len; i++) {
      if (add) {
        cells[parseInt(i.toString(), 10)].classList.add(className);
      } else {
        cells[parseInt(i.toString(), 10)].classList.remove(className);
      }
    }
  };
  TaskbarEdit.prototype.addErrorElem = function () {
    var dragelem = document.getElementsByClassName('e-clone-taskbar')[0];
    var errorelem = document.querySelectorAll('.e-errorelem').length;
    if (!errorelem) {
      var ele = document.createElement('div');
      classList(ele, ['e-errorcontainer'], []);
      classList(ele, ['e-icons', 'e-errorelem'], []);
      if (this.taskBarEditRecord.ganttProperties.segments && this.taskBarEditRecord.ganttProperties.segments.length > 0) {
        ele.style.paddingTop = this.parent.taskbarHeight + 'px';
      }
      dragelem.appendChild(ele);
    }
  };
  TaskbarEdit.prototype.removeErrorElem = function () {
    var errorelem = document.querySelector('.e-errorelem');
    if (errorelem) {
      errorelem.remove();
    }
  };
  TaskbarEdit.prototype.ensurePosition = function (draggedRecords, currentRecord) {
    var _this = this;
    draggedRecords.filter(function (draggedRecord) {
      if (draggedRecord.hasChildRecords && !isNullOrUndefined(draggedRecord.childRecords)) {
        var valid = draggedRecord.childRecords.indexOf(currentRecord);
        if (valid === -1) {
          _this.ensurePosition(draggedRecord.childRecords, currentRecord);
        } else {
          _this.parent.rowDragAndDropModule['dropPosition'] = 'Invalid';
          _this.addErrorElem();
          return;
        }
      }
    });
  };
  /**
   * To handle mouse move action in chart
   *
   * @param {PointerEvent} event .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.mouseMoveAction = function (event) {
    var _this = this;
    if (this.parent.isAdaptive) {
      if (!this.canDrag) {
        return;
      } else {
        this.multipleSelectionEnabled();
      }
    }
    var containerPosition = this.parent.getOffsetRect(this.parent.ganttChartModule.chartBodyContainer);
    var e = this.getCoordinate(event);
    if (this.parent.enableRtl) {
      this.mouseMoveX = Math.abs(e.pageX - (containerPosition.left + Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left)));
    } else {
      this.mouseMoveX = e.pageX - containerPosition.left + this.parent.ganttChartModule.scrollObject.previousScroll.left;
    }
    this.mouseMoveY = e.pageY - containerPosition.top + this.parent.ganttChartModule.scrollObject.previousScroll.top;
    this.dragMouseLeave = false;
    var cancel = false;
    if (this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'ParentDrag' || this.taskBarEditAction === 'ManualParentDrag' || this.taskBarEditAction === 'MilestoneDrag') {
      var ganttRowElement = void 0;
      if (this.parent.enableVirtualization) {
        var index = this.parent.currentViewData.indexOf(this.taskBarEditRecord);
        ganttRowElement = this.parent.getRowByIndex(index);
      } else {
        ganttRowElement = this.parent.getRowByIndex(this.taskBarEditRecord.index);
      }
      var eventArgs = {
        cancel: cancel,
        cloneElement: this.taskbarElement,
        data: this.taskBarEditRecord,
        target: event.target,
        rows: ganttRowElement
      };
      this.parent.trigger('rowDrag', eventArgs);
      cancel = eventArgs['cancel'];
    }
    if (!cancel) {
      this.isMouseDragCheck();
    }
    if (this.isMouseDragged && this.taskBarEditAction) {
      event.preventDefault();
      if (!isNullOrUndefined(this.taskbarElement) && !isNullOrUndefined(this.editElement) && this.taskBarEditAction !== 'ConnectorPointRightDrag' && this.taskBarEditAction !== 'ConnectorPointLeftDrag' && !(this.parent.viewType === 'ResourceView' && this.currentData.hasChildRecords)) {
        var currentElement = this.editElement.parentElement;
        if (this.parent.enableTimelineVirtualization && this.parent.timelineModule.wholeTimelineWidth > this.parent.element.offsetWidth * 3) {
          currentElement.style.setProperty('position', 'relative');
        } else {
          currentElement.style.setProperty('position', 'absolute');
        }
        if ((this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'LeftResizing') && !isNullOrUndefined(this.currentIndex) && !isNullOrUndefined(this.currentIndex) ? Number(this.currentIndex) === 0 : false) {
          this.taskbarElement.childNodes[0].childNodes[0].style.setProperty('top', currentElement.parentElement.offsetTop + 'px');
          if (this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
            this.parent.chartPane.getElementsByClassName('e-gantt-chart')[0].appendChild(this.taskbarElement);
          } else {
            currentElement.parentElement.appendChild(this.taskbarElement);
          }
        } else {
          if (this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
            this.parent.chartPane.getElementsByClassName('e-gantt-chart')[0].appendChild(this.taskbarElement);
          } else {
            currentElement.appendChild(this.taskbarElement);
          }
        }
        if (this.taskBarEditAction !== 'ProgressResizing') {
          if (this.parent.enableTimelineVirtualization && this.parent.timelineModule.wholeTimelineWidth > this.parent.element.offsetWidth * 3) {
            var rootElement = this.parent.ganttChartModule.chartBodyContainer.querySelectorAll('.e-chart-scroll-container');
            rootElement[0].appendChild(this.taskbarResizer);
          } else {
            var rootElement = this.parent.ganttChartModule.chartBodyContainer.querySelectorAll('.e-chart-rows-container');
            rootElement[0].appendChild(this.taskbarResizer);
          }
        }
      }
      if (this.parent.allowTaskbarDragAndDrop && (this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'ParentDrag' || this.taskBarEditAction === 'MilestoneDrag' || this.taskBarEditAction === 'ManualParentDrag')) {
        var taskbarPosition = this.parent.getOffsetRect(this.taskBarEditElement);
        var left = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 12 : 0;
        var pointerLeft = 3 + this.parent.chartRowsModule.connectorPointWidth + left;
        var xValue = void 0;
        if (this.parent.enableRtl) {
          xValue = Math.abs(taskbarPosition.left + pointerLeft + 1);
        } else {
          xValue = Math.abs(taskbarPosition.left - pointerLeft - 1);
        }
        if (!this.isClonedElement && !this.parent.enableRtl) {
          xValue = xValue - this.parent.ganttChartModule.scrollElement.scrollLeft;
          this.isClonedElement = true;
        }
        var rowPositionHeight = 0;
        this.parent.rowDragAndDropModule['dropPosition'] = undefined;
        var target = void 0;
        var yValue = void 0;
        if (event.type === 'touchmove' || event.type === 'touchstart' || event.type === 'touchend') {
          yValue = event['changedTouches'][0].pageY;
        } else {
          yValue = event.y;
        }
        if (this.parent.enableRtl) {
          target = document.elementFromPoint(this.parent.ganttChartModule.chartBodyContainer.offsetWidth, yValue);
          if (target.classList.contains('e-taskbar-resize-div')) {
            target = document.elementFromPoint(this.parent.ganttChartModule.chartBodyContainer.offsetWidth - target['offsetWidth'], yValue);
          }
        } else {
          target = document.elementFromPoint(xValue, yValue);
        }
        var draggedTreeGridRowElement = void 0;
        if (this.parent.enableVirtualization) {
          if (!this.draggedTreeGridRowElement) {
            var currentElement = this.editElement.parentElement;
            draggedTreeGridRowElement = currentElement.parentElement;
            this.draggedTreeGridRowElement = draggedTreeGridRowElement;
            this.draggedTreeGridRowHeight = this.draggedTreeGridRowElement.offsetHeight;
          }
        } else {
          draggedTreeGridRowElement = this.parent.treeGrid.getRows()[this.parent.flatData.indexOf(this.taskBarEditRecord)];
          this.draggedTreeGridRowElement = draggedTreeGridRowElement;
          if (this.parent.enableMultiTaskbar) {
            if (this.taskBarEditRecord.parentItem && !isNullOrUndefined(this.parent.getRowByID(this.taskBarEditRecord.parentItem.taskId))) {
              this.draggedTreeGridRowHeight = this.parent.getRowByID(this.taskBarEditRecord.parentItem.taskId).offsetHeight;
              if (this.parent.viewType === 'ProjectView' && this.draggedTreeGridRowHeight === 0) {
                var nestedParent = this.parent.getRecordByID(this.taskBarEditRecord.parentItem.taskId);
                while (nestedParent.parentItem) {
                  nestedParent = this.parent.getRecordByID(nestedParent.parentItem.taskId);
                  this.draggedTreeGridRowHeight = this.parent.getRowByID(nestedParent['TaskID']).offsetHeight;
                }
              }
            } else {
              var currentElement = this.editElement.parentElement;
              this.draggedTreeGridRowElement = currentElement.parentElement;
              this.draggedTreeGridRowHeight = this.draggedTreeGridRowElement.offsetHeight;
            }
          } else {
            this.draggedTreeGridRowHeight = this.draggedTreeGridRowElement.offsetHeight;
          }
        }
        var chartRowElement_1;
        if (target) {
          chartRowElement_1 = closest(target, 'tr.' + chartRow);
        }
        if (chartRowElement_1) {
          var droppedTreeGridRowElement = void 0;
          if (this.parent.enableVirtualization) {
            droppedTreeGridRowElement = this.parent.treeGrid.getRows().filter(function (data) {
              return data.getAttribute('data-rowindex') === chartRowElement_1.getAttribute('data-rowindex');
            })[0];
          } else {
            droppedTreeGridRowElement = this.parent.treeGrid.getRows()[parseInt(chartRowElement_1.getAttribute('data-rowindex'), 10)];
          }
          this.removeFirstBorder(droppedTreeGridRowElement);
          this.removeLastBorder(droppedTreeGridRowElement);
          if (!isNullOrUndefined(droppedTreeGridRowElement) && droppedTreeGridRowElement.getAttribute('data-uid') === this.draggedTreeGridRowElement.getAttribute('data-uid') || !parentsUntil(target, 'e-chart-rows-container')) {
            this.parent.rowDragAndDropModule['dropPosition'] = 'Invalid';
            this.addErrorElem();
          }
          var tObj = this.parent;
          var rowTop = 0;
          var toolHeight = tObj.toolbarModule ? this.parent.toolbarModule.element.offsetHeight : 0;
          var positionOffSet = this.parent.getOffsetRect(tObj.element);
          var contentHeight = tObj.treeGrid.getHeaderContent().offsetHeight + positionOffSet.top + toolHeight;
          var scrollTop = tObj.treeGrid.getContent().firstElementChild.scrollTop;
          if (!isNullOrUndefined(droppedTreeGridRowElement)) {
            rowPositionHeight = droppedTreeGridRowElement.offsetTop - scrollTop;
          }
          if (this.parent.enableVirtualization) {
            rowTop = this.parent.getOffsetRect(droppedTreeGridRowElement).top;
          } else {
            rowTop = rowPositionHeight + contentHeight;
          }
          var rowBottom = rowTop + this.draggedTreeGridRowHeight;
          var difference = rowBottom - rowTop;
          var divide = difference / 3;
          var topRowSegment = rowTop + divide;
          var middleRowSegment = topRowSegment + divide;
          var bottomRowSegment = middleRowSegment + divide;
          var posy = event.type === 'mousemove' ? event.pageY : !isNullOrUndefined(event['changedTouches']) ? event['changedTouches'][0].pageY : null;
          var isTopSegment = posy <= topRowSegment;
          var isMiddleRowSegment = posy > topRowSegment && posy <= middleRowSegment;
          var isBottomRowSegment = posy > middleRowSegment && posy <= bottomRowSegment;
          if (isTopSegment || isMiddleRowSegment || isBottomRowSegment) {
            var rowElement = void 0;
            if (this.parent.enableVirtualization) {
              var index = this.parent.treeGrid.getRows().indexOf(droppedTreeGridRowElement);
              rowElement = this.parent.getRowByIndex(index);
            } else {
              rowElement = this.parent.getRowByIndex(parseInt(droppedTreeGridRowElement.getAttribute('data-rowindex'), 10));
            }
            var rowIndex = getValue('rowIndex', rowElement);
            var droppedTreeGridRecord = this.parent.flatData[rowIndex];
            var isValid = true;
            if (this.parent.viewType === 'ResourceView' && !this.taskBarEditRecord.hasChildRecords && !droppedTreeGridRecord.hasChildRecords && !isNullOrUndefined(droppedTreeGridRecord.parentItem) && this.taskBarEditRecord.parentItem.taskId === droppedTreeGridRecord.parentItem.taskId) {
              isValid = false;
            }
            if (droppedTreeGridRecord) {
              this.ensurePosition([this.taskBarEditRecord], droppedTreeGridRecord);
            }
            if (isValid) {
              if (isTopSegment && this.parent.rowDragAndDropModule['dropPosition'] !== 'Invalid') {
                this.parent.rowDragAndDropModule['dropPosition'] = 'above';
                this.removeChildBorder();
                this.removeErrorElem();
                this.removetopOrBottomBorder();
                this.topOrBottomBorder(rowElement);
              }
              if (isMiddleRowSegment && this.parent.rowDragAndDropModule['dropPosition'] !== 'Invalid') {
                this.parent.rowDragAndDropModule['dropPosition'] = 'child';
                this.removetopOrBottomBorder();
                this.addRemoveClasses(rowElement.children, true, 'e-childborder');
              }
              if (isBottomRowSegment && this.parent.rowDragAndDropModule['dropPosition'] !== 'Invalid') {
                this.parent.rowDragAndDropModule['dropPosition'] = 'below';
                this.removetopOrBottomBorder();
                this.removeErrorElem();
                this.removeChildBorder();
                this.removeFirstBorder(droppedTreeGridRowElement);
                this.topOrBottomBorder(droppedTreeGridRowElement);
              }
            }
          }
        }
      }
      var args_1 = {
        cancel: false,
        requestType: 'taskbarediting',
        taskBarEditAction: this.taskBarEditAction,
        data: this.taskBarEditRecord
      };
      if (this.segmentIndex !== -1) {
        args_1.requestType = 'mergeSegment';
      }
      this.parent.trigger('actionBegin', args_1, function (arg) {
        if (args_1.taskBarEditAction === 'ConnectorPointRightDrag' || args_1.taskBarEditAction === 'ConnectorPointLeftDrag' || args_1.taskBarEditAction === 'LeftResizing' || args_1.taskBarEditAction === 'RightResizing' || args_1.taskBarEditAction === 'ProgressResizing' || args_1.taskBarEditAction === 'ChildDrag' || args_1.taskBarEditAction === 'ParentDrag' || args_1.taskBarEditAction === 'MilestoneDrag' || args_1.taskBarEditAction === 'ManualParentDrag' || args_1.taskBarEditAction === 'ParentResizing') {
          _this.parent.showIndicator = false;
          if (_this.parent.undoRedoModule && _this.parent['isUndoRedoItemPresent']('Edit')) {
            if (_this.parent.undoRedoModule['redoEnabled']) {
              _this.parent.undoRedoModule['disableRedo']();
            }
            var action = {};
            if (_this.parent.undoRedoModule['getUndoCollection'].length === 0) {
              _this.isDragged = true;
              action['modifiedRecords'] = [];
              action['action'] = args_1.taskBarEditAction;
              _this.parent.undoRedoModule['createUndoCollection']();
              _this.parent.undoRedoModule['getUndoCollection'][_this.parent.undoRedoModule['getUndoCollection'].length - 1] = action;
              _this.parent.editModule['createArray'] = false;
              _this.parent.undoRedoModule['changedRecords'] = [];
            } else if (_this.parent.editModule['createArray']) {
              _this.isDragged = true;
              action['modifiedRecords'] = [];
              action['action'] = args_1.taskBarEditAction;
              _this.parent.undoRedoModule['createUndoCollection']();
              _this.parent.undoRedoModule['getUndoCollection'][_this.parent.undoRedoModule['getUndoCollection'].length - 1] = action;
              _this.parent.editModule['createArray'] = false;
              _this.parent.undoRedoModule['changedRecords'] = [];
            }
          }
        }
        if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer' && _this.parent.showIndicator) {
          _this.parent.showMaskRow();
        } else if (_this.parent.showIndicator) {
          _this.parent.showSpinner();
        }
        if (arg.cancel === false) {
          _this.taskBarEditingAction(event, false);
        }
      });
    } else if (!this.parent.isAdaptive && !this.taskBarEditAction) {
      this.updateTaskBarEditElement(event);
    }
  };
  /**
   * Method to update taskbar editing action on mous move.
   *
   * @param {PointerEvent} e .
   * @param {boolean} isMouseClick .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.taskBarEditingAction = function (e, isMouseClick) {
    var _this = this;
    var args = {};
    var recordIndex = this.parent.ganttChartModule.getIndexByTaskBar(this.taskBarEditElement);
    if (this.taskBarEditRecord !== null) {
      args.editingFields = this.taskBarEditRecord.ganttProperties;
      args.data = this.taskBarEditRecord;
      if (this.parent.viewType === 'ResourceView' && args.data.level === 0) {
        return;
      }
      args.recordIndex = recordIndex;
      args.taskBarEditAction = this.taskBarEditAction;
      args.roundOffDuration = this.roundOffDuration;
      args.cancel = false;
      args.previousData = this.previousItem;
      args.segmentIndex = this.segmentIndex;
      this.roundOffDuration = args.roundOffDuration;
      this.targetElement = args.target = closest(e.target, '.e-gantt-child-taskbar');
      this.updateMouseMoveProperties(e);
      if (!this.oldData) {
        this.oldData = extend([], [], [this.taskBarEditRecord], true)[0];
      }
      var segmentIndex = this.segmentIndex !== -1 ? this.segmentIndex : null;
      if (this.taskBarEditAction === 'ProgressResizing') {
        this.mainElement = parentsUntil(this.taskBarEditElement, taskBarMainContainer);
        if (this.taskBarEditRecord.ganttProperties.segments && this.taskBarEditRecord.ganttProperties.segments.length > 0) {
          segmentIndex = -1;
          for (var i = 0; i < this.taskBarEditRecord.ganttProperties.segments.length; i++) {
            var resizeGripper = document.getElementsByClassName('e-child-progress-resizer e-progress-resize-gripper')[0]['style'];
            var currentWidth = this.parent.enableRtl ? parseInt(resizeGripper['right'], 10) - 8 : parseInt(resizeGripper['left'], 10) + 8;
            if (currentWidth < this.taskBarEditRecord.ganttProperties.segments[i].width && i === 0) {
              segmentIndex = i;
            }
            if (this.taskBarEditRecord.ganttProperties.segments[i + 1] && currentWidth < this.taskBarEditRecord.ganttProperties.segments[i + 1].width + this.taskBarEditRecord.ganttProperties.segments[i + 1].left && currentWidth > this.taskBarEditRecord.ganttProperties.segments[i + 1].left) {
              segmentIndex = i + 1;
            }
          }
        }
        this.performProgressResize(e, segmentIndex);
      } else if (this.taskBarEditAction === 'LeftResizing') {
        this.enableLeftResizing(e);
      } else if (this.taskBarEditAction === 'RightResizing' || this.taskBarEditAction === 'ParentResizing') {
        this.enableRightResizing(e);
      } else if (this.taskBarEditAction === 'ParentDrag' || this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'MilestoneDrag' || this.taskBarEditAction === 'ManualParentDrag') {
        this.enableDragging(e);
      } else if (this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag') {
        this.updateConnectorLineSecondProperties(e);
        this.triggerDependencyEvent(e);
        this.drawFalseLine();
      }
      if (this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
        var parentClientRect = this.parent.getOffsetRect(this.parent.chartPane);
        var coordinates = this.getCoordinate(e);
        var position = {
          top: coordinates.pageY,
          left: coordinates.pageX
        };
        var pos = {
          left: Math.abs(position.left - parentClientRect.left),
          top: position.top - parentClientRect.top
        };
        if (this.parent.viewType === 'ResourceView' && this.parent.enableMultiTaskbar) {
          var parentRecord = this.parent.getRecordByID(this.taskBarEditRecord.parentItem.taskId);
          if (!this.parent.allowTaskbarOverlap && this.taskBarEditRecord.parentItem && !parentRecord.expanded) {
            var reduceHeight = 0;
            if (parseInt(this.editElement['style'].marginTop, 10) > this.parent.rowHeight) {
              for (var i = 0; i < parentRecord.childRecords.length; i++) {
                if (parentRecord.childRecords[i].ganttProperties.taskId !== this.taskBarEditRecord.ganttProperties.taskId) {
                  reduceHeight = reduceHeight + this.parent.rowHeight;
                } else {
                  break;
                }
              }
              pos.top = pos.top - reduceHeight;
            }
          }
        }
        this.topValue = pos.top;
        var xValue = void 0;
        if (e.type === 'touchmove' || e.type === 'touchstart' || e.type === 'touchend') {
          xValue = e['changedTouches'][0].pageX;
        } else {
          xValue = e.pageX;
        }
        if (this.parent.enableRtl) {
          this.leftValue = pos.left - Math.abs(xValue - this.parent.getOffsetRect(this.taskBarEditElement).left) - (this.previousLeftValue !== 0 ? xValue - this.previousLeftValue : 0);
        } else {
          this.leftValue = pos.left - (xValue - this.parent.getOffsetRect(this.taskBarEditElement).left) + (this.previousLeftValue !== 0 ? xValue - this.previousLeftValue : 0);
        }
        if (this.previousLeftValue === 0) {
          if (!this.parent.enableRtl) {
            this.leftValue = this.leftValue - this.parent.ganttChartModule.scrollObject.previousScroll.left;
          } else {
            this.leftValue = this.leftValue - this.parent.ganttChartModule.scrollObject.previousScroll.left;
            if (this.parent.ganttChartModule.scrollObject.previousScroll.left === 0) {
              this.leftValue = this.leftValue - 60;
            }
          }
        }
        this.previousLeftValue = xValue;
      } else {
        this.leftValue = this.taskBarEditRecord.ganttProperties.left;
      }
      this.currentSegmentIndex = segmentIndex;
      this.setItemPosition();
      this.updateEditedItem();
      this.tooltipValue = e.type === 'touchmove' || e.type === 'touchstart' || e.type === 'touchend' ? e['changedTouches'][0].pageX : e.pageX;
      if (!this.taskBarEditRecord.ganttProperties.segments || segmentIndex !== -1) {
        this.editTooltip.updateTooltip(segmentIndex);
      }
      if (isMouseClick) {
        this.taskBarEditedAction(e);
      }
      this.parent.trigger('taskbarEditing', args, function (arg) {
        if (arg.cancel && _this.taskBarEditRecord !== null) {
          _this.tapPointOnFocus = false;
          merge(_this.taskBarEditRecord.ganttProperties, arg.previousData);
        }
      });
    }
  };
  /**
   * To update property while perform mouse move.
   *
   * @param {PointerEvent} event .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateMouseMoveProperties = function (event) {
    var containerPosition = this.parent.getOffsetRect(this.parent.ganttChartModule.chartBodyContainer);
    var e = this.getCoordinate(event);
    var parentWithZoomStyle = this.parent.element.closest('[style*="zoom"]');
    if (parentWithZoomStyle) {
      var zoom1 = parseFloat(getComputedStyle(parentWithZoomStyle).zoom);
      e.pageX = e.pageX / zoom1;
      e.pageY = e.pageY / zoom1;
    }
    if (e.pageX || e.pageY) {
      if (this.parent.enableRtl) {
        this.mouseMoveX = Math.abs(e.pageX - (containerPosition.left + Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left)));
      } else {
        this.mouseMoveX = e.pageX - containerPosition.left + this.parent.ganttChartModule.scrollObject.previousScroll.left;
      }
      this.tooltipPositionX = this.mouseMoveX;
      this.mouseMoveY = e.pageY - containerPosition.top + this.parent.ganttChartModule.scrollObject.previousScroll.top;
    }
    var isConnectorLineEdit = this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag' ? true : false;
    if ((this.taskBarEditRecord.ganttProperties.width > 3 || this.taskBarEditRecord.ganttProperties.isMilestone) && !(this.taskBarEditAction === 'ProgressResizing' && (this.taskBarEditRecord.ganttProperties.progress === 0 || this.taskBarEditRecord.ganttProperties.progress === 100)) || isConnectorLineEdit) {
      var mouseX = 0;
      if (this.parent.enableRtl) {
        mouseX = Math.abs(Math.abs(this.mouseMoveX) - Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left) + containerPosition.left);
      } else {
        mouseX = this.mouseMoveX - this.parent.ganttChartModule.scrollObject.previousScroll.left + containerPosition.left;
      }
      var mouseY = this.mouseMoveY - this.parent.ganttChartModule.scrollObject.previousScroll.top + containerPosition.top;
      if (mouseX + 20 > containerPosition.left + this.parent.ganttChartModule.chartBodyContainer.offsetWidth) {
        this.timerCount = this.parent.ganttChartModule.scrollObject.previousScroll.left;
        this.startScrollTimer('right');
      } else if (mouseX + 40 > containerPosition.left + this.parent.ganttChartModule.chartBodyContainer.offsetWidth && this.parent.enableRtl && this.parent.ganttChartModule.scrollObject.previousScroll.left === 0) {
        this.parent.ganttChartModule.scrollObject.previousScroll.left = -1;
        this.timerCount = this.parent.ganttChartModule.scrollObject.previousScroll.left;
        this.startScrollTimer('right');
      } else if (mouseX - 20 < containerPosition.left) {
        this.timerCount = this.parent.ganttChartModule.scrollObject.previousScroll.left;
        this.startScrollTimer('left');
      } else if (mouseY + 80 > containerPosition.top + this.parent.ganttChartModule.chartBodyContainer.offsetHeight) {
        this.timerCount = this.parent.ganttChartModule.scrollObject.previousScroll.top;
        this.startScrollTimer('bottom');
      } else if (mouseY - 20 < containerPosition.top) {
        this.timerCount = this.parent.ganttChartModule.scrollObject.previousScroll.top;
        this.startScrollTimer('top');
      } else {
        this.stopScrollTimer();
      }
    } else {
      this.stopScrollTimer();
    }
  };
  /**
   * To start the scroll timer.
   *
   * @param {string} direction .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.startScrollTimer = function (direction) {
    var _this = this;
    this.stopScrollTimer();
    var leftSign = 0;
    this.scrollTimer = window.setInterval(function () {
      if (Math.sign(_this.timerCount) === -1) {
        leftSign = -1;
        _this.timerCount = Math.abs(_this.timerCount);
      }
      if (direction === 'right') {
        var timelineWidth = _this.parent.enableTimelineVirtualization ? _this.parent.timelineModule.wholeTimelineWidth : _this.parent.timelineModule.totalTimelineWidth;
        _this.timerCount = _this.timerCount + 1 >= timelineWidth ? timelineWidth : _this.timerCount + 1;
      } else if (direction === 'bottom') {
        _this.timerCount = _this.timerCount + 1;
      } else {
        _this.timerCount = _this.timerCount - 1 < 0 ? 0 : _this.timerCount - 1;
      }
      if (direction === 'bottom' || direction === 'top') {
        _this.parent.ganttChartModule.scrollObject.setScrollTop(_this.timerCount);
      } else {
        _this.parent.ganttChartModule.scrollObject.setScrollLeft(_this.timerCount, leftSign);
      }
      if (_this.taskBarEditAction === 'ConnectorPointLeftDrag' || _this.taskBarEditAction === 'ConnectorPointRightDrag') {
        _this.drawFalseLine();
      }
    }, 0);
  };
  /**
   * To stop the scroll timer.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.stopScrollTimer = function () {
    window.clearInterval(this.scrollTimer);
    this.scrollTimer = null;
  };
  /**
   * To update left and width while perform taskbar drag operation.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  TaskbarEdit.prototype.enableDragging = function (e) {
    var item = this.taskBarEditRecord.ganttProperties;
    var timelineWidth = this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : this.parent.timelineModule.totalTimelineWidth;
    var differenceWidth = 0;
    if (this.taskBarEditElement.classList.contains('e-segmented-taskbar') && !this.taskBarEditElement.classList.contains('e-segment-first')) {
      var segments = this.taskBarEditRecord.ganttProperties.segments.map(function (e) {
        return __assign$1({}, e);
      });
      var segment = segments[this.segmentIndex];
      if (this.mouseDownX > this.mouseMoveX) {
        differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseDownX - this.mouseMoveX : this.previousMouseMove - this.mouseMoveX;
        this.previousMouseMove = this.mouseMoveX;
        segment.left = segment.left - differenceWidth;
      } else {
        differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseMoveX - this.mouseDownX : this.mouseMoveX - this.previousMouseMove;
        this.previousMouseMove = this.mouseMoveX;
        segment.left = segment.left + differenceWidth;
      }
      var previousSegment = segments[this.segmentIndex - 1];
      var nextSegment = segments[this.segmentIndex + 1];
      var left = void 0;
      if (this.taskBarEditElement.classList.contains('e-segment-inprogress')) {
        left = segment.left < previousSegment.left + previousSegment.width ? previousSegment.left + previousSegment.width : segment.width + segment.left > nextSegment.left ? nextSegment.left - segment.width : segment.left;
      } else {
        left = segment.left < previousSegment.left + previousSegment.width ? previousSegment.left + previousSegment.width : item.left + segment.width + segment.left >= timelineWidth ? timelineWidth - segment.width : segment.left;
      }
      segment.left = left;
      this.parent.setRecordValue('segments', segments, item, true);
      this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
    } else {
      if (this.mouseDownX > this.mouseMoveX) {
        differenceWidth = this.mouseDownX - this.mouseMoveX;
        if (differenceWidth > 0) {
          this.parent.setRecordValue('left', this.previousItem.left - differenceWidth, item, true);
        }
      } else {
        differenceWidth = this.mouseMoveX - this.mouseDownX;
        this.parent.setRecordValue('left', this.previousItem.left + differenceWidth, item, true);
      }
      var left = item.left < 0 ? 0 : item.left + item.width >= timelineWidth ? timelineWidth - item.width : item.left;
      this.parent.setRecordValue('left', left, item, true);
    }
  };
  // eslint-disable-next-line
  TaskbarEdit.prototype.validateProgressWidth = function (item, progressWidth, totalTaskWidth) {
    var reduceWidth = 0;
    if (this.currentSegmentIndex !== -1 && item.segments) {
      var startIndex = this.currentSegmentIndex >= this.segmentIndex ? 0 : this.currentSegmentIndex !== 0 ? this.currentSegmentIndex : -1;
      var endIndex = this.currentSegmentIndex >= this.segmentIndex ? this.currentSegmentIndex : this.segmentIndex;
      if (startIndex !== -1) {
        for (var i = startIndex; i < endIndex; i++) {
          reduceWidth = item.segments[i + 1].left - (item.segments[i].left + item.segments[i].width);
          progressWidth = progressWidth - reduceWidth;
        }
      }
    }
    if (item.segments && item.segments.length > 0) {
      totalTaskWidth = this.splitTasksDuration(item.segments) * this.parent.perDayWidth;
    }
    return {
      progressWidth: progressWidth,
      totalTaskWidth: totalTaskWidth
    };
  };
  /**
   * To update left and width while perform progress resize operation.
   *
   * @param {PointerEvent} e .
   * @param {number} segmentIndex .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.performProgressResize = function (e, segmentIndex) {
    var item = this.taskBarEditRecord.ganttProperties;
    var diffrenceWidth = 0;
    var prevProgress = item.progressWidth;
    if (this.mouseDownX > this.mouseMoveX) {
      if (this.mouseMoveX > item.left && this.mouseMoveX < item.left + item.width && item.left > 0) {
        diffrenceWidth = this.mouseMoveX - item.left;
        this.parent.setRecordValue('progressWidth', diffrenceWidth, item, true);
      } else {
        if (this.mouseMoveX >= item.left + item.width) {
          this.parent.setRecordValue('progressWidth', item.width, item, true);
        } else {
          this.parent.setRecordValue('progressWidth', 0, item, true);
        }
      }
    } else {
      if (this.mouseMoveX > item.left && this.mouseMoveX < item.left + item.width) {
        diffrenceWidth = this.mouseMoveX - item.left;
        this.parent.setRecordValue('progressWidth', diffrenceWidth, item, true);
      } else {
        if (this.mouseMoveX <= item.left) {
          this.parent.setRecordValue('progressWidth', 0, item, true);
        } else {
          this.parent.setRecordValue('progressWidth', item.width, item, true);
        }
      }
    }
    var widthValue = item.progressWidth > item.width ? item.width : item.progressWidth;
    widthValue = item.progressWidth < 0 ? 0 : item.progressWidth;
    if (segmentIndex !== -1 || !segmentIndex) {
      this.parent.setRecordValue('progressWidth', widthValue, item, true);
    } else {
      this.parent.setRecordValue('progressWidth', prevProgress, item, true);
    }
    this.progressValue = widthValue;
    var diff = item.width - item.progressWidth;
    if (diff <= 4) {
      this.progressBorderRadius = 4 - diff;
    } else {
      this.progressBorderRadius = 0;
    }
  };
  /**
   * To update left and width while perform taskbar left resize operation.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.enableLeftResizing = function (e) {
    var item = this.taskBarEditRecord.ganttProperties;
    var differenceWidth = 0;
    if (this.taskBarEditElement.classList.contains('e-segmented-taskbar') && item.segments.length > 1) {
      this.enableSplitTaskLeftResize(item);
    } else {
      if (this.mouseDownX > this.mouseMoveX) {
        if (this.mouseMoveX < item.left + item.width) {
          differenceWidth = this.mouseDownX - this.mouseMoveX;
          if (item.left > 0) {
            this.parent.setRecordValue('left', this.previousItem.left - differenceWidth, item, true);
            this.parent.setRecordValue('width', this.previousItem.width + differenceWidth, item, true);
          }
        } else {
          if (this.mouseMoveX > item.left + item.width) {
            differenceWidth = this.mouseDownX - this.mouseMoveX;
            this.parent.setRecordValue('left', this.previousItem.left - differenceWidth, item, true);
            this.parent.setRecordValue('width', 3, item, true);
          }
        }
      } else {
        if (this.mouseMoveX < item.left + item.width) {
          differenceWidth = this.mouseMoveX - this.mouseDownX;
          if (item.left < item.left + item.width && this.previousItem.left + differenceWidth <= this.previousItem.left + this.previousItem.width) {
            this.parent.setRecordValue('left', this.previousItem.left + differenceWidth, item, true);
            this.parent.setRecordValue('width', this.previousItem.width - differenceWidth, item, true);
          }
        } else {
          differenceWidth = this.mouseMoveX - this.mouseDownX;
          this.parent.setRecordValue('left', this.previousItem.left + differenceWidth, item, true);
          this.parent.setRecordValue('width', 3, item, true);
        }
      }
      if (item.segments && item.segments.length === 1) {
        item.segments[0].width = item.width;
      }
      this.updateEditPosition(e, item);
      this.parent.setRecordValue('left', this.previousItem.left + this.previousItem.width - item.width, item, true);
    }
  };
  TaskbarEdit.prototype.enableSplitTaskLeftResize = function (item) {
    var segments = this.taskBarEditRecord.ganttProperties.segments.map(function (e) {
      return __assign$1({}, e);
    });
    var segment = segments[this.segmentIndex];
    var differenceWidth = 0;
    //when decrease the left and increase the width
    if (this.mouseDownX > this.mouseMoveX) {
      if (this.mouseMoveX < item.left + segment.width + segment.left) {
        differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseDownX - this.mouseMoveX : this.previousMouseMove - this.mouseMoveX;
        this.previousMouseMove = this.mouseMoveX;
        // when resize other than 0th segment
        if (segment.left > 0) {
          segment.left = segment.left - differenceWidth;
          segment.width = segment.width + differenceWidth;
          if (this.segmentIndex !== 0) {
            var previousSegment = segments[this.segmentIndex - 1];
            if (item.left + segment.left < item.left + previousSegment.left + previousSegment.width) {
              var difference = item.left + previousSegment.left + previousSegment.width - (item.left + segment.left);
              segment.width -= difference;
              segment.left = segment.left + difference;
            }
          }
        } else if (segment.left <= 0 && this.segmentIndex === 0) {
          this.parent.setRecordValue('left', item.left - differenceWidth, item, true);
          this.parent.setRecordValue('width', item.width + differenceWidth, item, true);
          segment.width = segment.width + differenceWidth;
          for (var i = 1; i < item.segments.length; i++) {
            var segment_1 = segments[i];
            segment_1.left = segment_1.left + differenceWidth;
          }
        }
      } else {
        if (this.mouseMoveX > item.left + segment.width + segment.left) {
          differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseDownX - this.mouseMoveX : this.previousMouseMove - this.mouseMoveX;
          this.previousMouseMove = this.mouseMoveX;
          segment.left = segment.left - differenceWidth;
          segment.width = this.parent.perDayWidth;
        }
      }
    } else {
      // when increase left value and decrease width of segment
      if (this.mouseMoveX < item.left + segment.width + segment.left - this.parent.perDayWidth) {
        differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseMoveX - this.mouseDownX : this.mouseMoveX - this.previousMouseMove;
        this.previousMouseMove = this.mouseMoveX;
        // when decrease the first segment width
        if (this.segmentIndex === 0 && segment.left <= 0) {
          this.parent.setRecordValue('left', item.left + differenceWidth, item, true);
          this.parent.setRecordValue('width', item.width - differenceWidth, item, true);
          segment.width = segment.width - differenceWidth;
          for (var i = 1; i < item.segments.length; i++) {
            var segment_2 = segments[i];
            segment_2.left = segment_2.left - differenceWidth;
          }
          // when decrease remaining segments
        } else if (segment.left < segment.left + segment.width && segment.left + differenceWidth <= segment.left + segment.width) {
          segment.left = segment.left + differenceWidth;
          segment.width = segment.width - differenceWidth;
        }
        // when mouse move goes beyond one day width of task bar.
      } else {
        if (this.mouseMoveX < item.left + segment.left + segment.width) {
          if (segment.width > this.parent.perDayWidth) {
            differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseMoveX - this.mouseDownX : this.mouseMoveX - this.previousMouseMove;
            this.previousMouseMove = this.mouseMoveX;
            var singleDayDifference = segment.width - differenceWidth < this.parent.perDayWidth ? this.parent.perDayWidth > segment.width ? this.parent.perDayWidth - segment.width : segment.width - this.parent.perDayWidth : 0;
            differenceWidth -= singleDayDifference;
            if (this.segmentIndex === 0) {
              this.parent.setRecordValue('width', item.width - differenceWidth, item, true);
              this.parent.setRecordValue('left', item.left + differenceWidth, item, true);
              segment.width = segment.width - differenceWidth;
              for (var i = 1; i < item.segments.length; i++) {
                var segment_3 = segments[i];
                segment_3.left = segment_3.left - differenceWidth;
              }
            } else {
              segment.left = segment.left + differenceWidth;
              segment.width = segment.width - differenceWidth;
            }
          }
        }
      }
    }
    this.parent.setRecordValue('segments', segments, item, true);
    this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
  };
  /**
   * Update mouse position and edited item value
   *
   * @param {PointerEvent} e .
   * @param {ITaskData} item .
   * @returns {void} .
   */
  TaskbarEdit.prototype.updateEditPosition = function (e, item) {
    this.updateIsMilestone(item);
    this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(item.width, item.progress), item, true);
  };
  /**
   *  To update milestone property.
   *
   * @param {ITaskData} item .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateIsMilestone = function (item) {
    if (item.width <= 3) {
      this.parent.setRecordValue('width', 3, item, true);
      this.parent.setRecordValue('isMilestone', true, item, true);
      if (!isNullOrUndefined(this.taskBarEditRecord[this.parent.taskFields.milestone])) {
        this.parent.setRecordValue(this.parent.taskFields.milestone, true, this.taskBarEditRecord, true);
      }
    } else {
      this.parent.setRecordValue('width', item.width, item, true);
      this.parent.setRecordValue('isMilestone', false, item, true);
      if (!isNullOrUndefined(this.taskBarEditRecord[this.parent.taskFields.milestone])) {
        this.parent.setRecordValue(this.parent.taskFields.milestone, false, this.taskBarEditRecord, true);
      }
    }
  };
  /**
   * To update left and width while perform taskbar right resize operation.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.enableRightResizing = function (e) {
    var item = this.taskBarEditRecord.ganttProperties;
    var differenceWidth = 0;
    if (this.taskBarEditElement.classList.contains('e-segmented-taskbar') && item.segments.length > 1) {
      var segments = this.taskBarEditRecord.ganttProperties.segments.map(function (e) {
        return __assign$1({}, e);
      });
      var segment = segments[this.segmentIndex];
      if (this.mouseDownX > this.mouseMoveX) {
        if (this.mouseMoveX > item.left + segment.left && this.mouseDownX - this.mouseMoveX > 3) {
          differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseDownX - this.mouseMoveX : this.previousMouseMove - this.mouseMoveX;
          this.previousMouseMove = this.mouseMoveX;
          segment.width = segment.width - differenceWidth;
        } else {
          if (this.mouseMoveX < item.left + segment.left) {
            var segmentWidth = this.parent.timelineModule.isSingleTier && (this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Hour' || this.parent.timelineModule.customTimelineSettings.topTier.unit === 'Hour' || this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Minutes' || this.parent.timelineModule.customTimelineSettings.topTier.unit === 'Minutes') || this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Hour' || this.parent.timelineModule.customTimelineSettings.bottomTier.unit === 'Minutes' ? this.parent.timelineModule.customTimelineSettings.timelineUnitSize : this.parent.perDayWidth;
            segment.width = segmentWidth;
          }
        }
      } else {
        if (this.mouseMoveX > segment.left) {
          differenceWidth = isNullOrUndefined(this.previousMouseMove) ? this.mouseMoveX - this.mouseDownX : this.mouseMoveX - this.previousMouseMove;
          this.previousMouseMove = this.mouseMoveX;
          segment.width = segment.width + differenceWidth;
        }
      }
      var width = void 0;
      var nextSegment = this.segmentIndex !== segments.length - 1 ? segments[this.segmentIndex + 1] : null;
      if (!isNullOrUndefined(nextSegment)) {
        if (!this.taskBarEditElement.classList.contains('e-segment-last')) {
          width = segment.left + segment.width > nextSegment.left ? nextSegment.left - segment.left : segment.width;
        }
        segment.width = width;
      }
      if (this.segmentIndex === item.segments.length - 1) {
        if (this.segmentIndex === 0) {
          this.parent.setRecordValue('width', segment.width, item, true);
        }
      }
      this.parent.setRecordValue('segments', segments, item, true);
      this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
    } else {
      if (this.mouseDownX > this.mouseMoveX) {
        if (this.mouseMoveX > item.left && this.mouseDownX - this.mouseMoveX > 3) {
          differenceWidth = this.mouseDownX - this.mouseMoveX;
          this.parent.setRecordValue('width', this.previousItem.width - differenceWidth, item, true);
        } else {
          if (this.mouseMoveX < item.left) {
            this.parent.setRecordValue('width', 3, item, true);
          }
        }
      } else {
        if (this.mouseMoveX > item.left) {
          differenceWidth = this.mouseMoveX - this.mouseDownX;
          this.parent.setRecordValue('width', this.previousItem.width + differenceWidth, item, true);
        }
      }
      if (item.segments && item.segments.length === 1) {
        item.segments[0].width = item.width;
      }
      this.updateEditPosition(e, item);
    }
  };
  /**
   * To updated startDate and endDate while perform taskbar edit operation.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateEditedItem = function () {
    var item = this.taskBarEditRecord.ganttProperties;
    var left;
    var projectStartDate;
    var endDate;
    var startDate;
    switch (this.taskBarEditAction) {
      case 'ProgressResizing':
        {
          var progressWidth = item.progressWidth;
          var totalTaskWidth = item.width;
          var updatedValues = this.validateProgressWidth(item, progressWidth, totalTaskWidth);
          this.parent.setRecordValue('progress', this.getProgressPercent(updatedValues.totalTaskWidth, updatedValues.progressWidth), item, true);
          break;
        }
      case 'LeftResizing':
        if (this.segmentIndex === -1) {
          left = this.getRoundOffStartLeft(item, this.roundOffDuration);
          projectStartDate = this.getDateByLeft(left);
          if (isNullOrUndefined(item.endDate)) {
            endDate = this.parent.dateValidationModule.getValidEndDate(item);
            this.parent.setRecordValue('endDate', endDate, item, true);
          }
          startDate = this.parent.dateValidationModule.checkStartDate(projectStartDate, item, null);
          this.parent.setRecordValue('startDate', new Date(startDate.getTime()), item, true);
          if (this.parent.dateValidationModule.compareDates(item.startDate, item.endDate) === 0 && isNullOrUndefined(item.isMilestone) && item.isMilestone === false && item.duration === 0) {
            this.parent.setRecordValue('duration', 1, item, true);
          }
          if (item.isMilestone) {
            this.parent.setRecordValue('endDate', new Date(startDate.getTime()), item, true);
          }
          this.parent.dateValidationModule.calculateDuration(this.taskBarEditRecord);
          this.parent.editModule.updateResourceRelatedFields(this.taskBarEditRecord, 'duration');
        } else {
          this.updateSplitLeftResize(item);
        }
        break;
      case 'RightResizing':
      case 'ParentResizing':
        if (this.segmentIndex === -1) {
          left = this.getRoundOffEndLeft(item, this.roundOffDuration);
          var tempEndDate = this.getDateByLeft(left);
          if (isNullOrUndefined(item.startDate)) {
            startDate = this.parent.dateValidationModule.getValidStartDate(item);
            this.parent.setRecordValue('startDate', startDate, item, true);
          }
          var tempdate = isNullOrUndefined(item.startDate) ? startDate : item.startDate;
          endDate = item.isMilestone ? tempdate : this.parent.dateValidationModule.checkEndDate(tempEndDate, this.taskBarEditRecord.ganttProperties);
          this.parent.setRecordValue('endDate', new Date(endDate.getTime()), item, true);
          this.parent.dateValidationModule.calculateDuration(this.taskBarEditRecord);
          this.parent.editModule.updateResourceRelatedFields(this.taskBarEditRecord, 'duration');
        } else {
          this.updateSplitRightResizing(item);
        }
        break;
      case 'ParentDrag':
      case 'ChildDrag':
      case 'MilestoneDrag':
      case 'ManualParentDrag':
        if (this.segmentIndex === -1 || this.segmentIndex === 0 || this.segmentIndex !== -1 && this.parent.allowTaskbarDragAndDrop) {
          this.updateChildDrag(item);
        } else {
          this.setSplitTaskDrag(item);
        }
        break;
    }
    if (!isNullOrUndefined(this.taskBarEditRecord.ganttProperties.segments)) {
      this.parent.chartRowsModule.updateSegment(this.taskBarEditRecord.ganttProperties.segments, this.taskBarEditRecord.ganttProperties.taskId);
    }
  };
  TaskbarEdit.prototype.updateChildDrag = function (item) {
    var left = this.getRoundOffStartLeft(item, this.roundOffDuration);
    var projectStartDate = this.getDateByLeft(left, item.isMilestone, item);
    var endDate;
    if (this.segmentIndex === 0) {
      this.parent.setRecordValue('startDate', this.parent.dateValidationModule.checkStartDate(projectStartDate, item, null), item, true);
      item.segments[0].startDate = projectStartDate;
      item.segments[0].endDate = this.parent.dataOperation.getEndDate(item.segments[0].startDate, item.segments[0].duration, item.durationUnit, item, false);
      this.parent.setRecordValue('segments', item.segments, item, true);
      this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
      this.parent.chartRowsModule.incrementSegments(item.segments, 0, this.taskBarEditRecord);
      this.parent.setRecordValue('endDate', item.segments[item.segments.length - 1].endDate, item, true);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.segments, item.segments, this.taskBarEditRecord, true);
    } else {
      if (!isNullOrUndefined(item.endDate) && isNullOrUndefined(item.startDate)) {
        endDate = this.parent.dateValidationModule.checkStartDate(projectStartDate, item, null);
        endDate = this.parent.dateValidationModule.checkEndDate(endDate, this.taskBarEditRecord.ganttProperties);
        this.parent.setRecordValue('endDate', endDate, item, true);
      } else {
        this.parent.setRecordValue('startDate', this.parent.dateValidationModule.checkStartDate(projectStartDate, item, null), item, true);
        if (!isNullOrUndefined(item.duration)) {
          this.parent.dateValidationModule.calculateEndDate(this.taskBarEditRecord);
        }
      }
    }
  };
  TaskbarEdit.prototype.updateSplitLeftResize = function (item) {
    var segment = item.segments[this.segmentIndex];
    var left = this.segmentIndex === 0 ? this.getRoundOffStartLeft(item, this.roundOffDuration) : this.getRoundOffStartLeft(segment, this.roundOffDuration);
    var projectStartDate = this.getDateByLeft(left);
    var startDate = this.parent.dataOperation.checkStartDate(projectStartDate, item, false);
    var duration = this.parent.dataOperation.getDuration(startDate, segment.endDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
    segment.startDate = new Date(startDate.getTime());
    segment.duration = duration;
    this.parent.setRecordValue('duration', this.sumOfDuration(item.segments), item, true);
    if (this.segmentIndex === 0) {
      this.parent.setRecordValue('startDate', segment.startDate, item, true);
    }
    this.parent.editModule.updateResourceRelatedFields(this.taskBarEditRecord, 'duration');
    if (!isNullOrUndefined(item.segments[this.segmentIndex - 1])) {
      var segmentOffsetDuration = this.parent.dataOperation.getDuration(item.segments[this.segmentIndex - 1].endDate, item.segments[this.segmentIndex].startDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
      segment.offsetDuration = segmentOffsetDuration;
    }
    this.parent.setRecordValue('segments', item.segments, item, true);
    this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
  };
  TaskbarEdit.prototype.updateSplitRightResizing = function (item) {
    var segment = item.segments[this.segmentIndex];
    var left = this.getRoundOffEndLeft(item, this.roundOffDuration);
    var tempEndDate = this.getDateByLeft(left);
    var endDate = this.parent.dataOperation.checkEndDate(tempEndDate, item, false);
    var duration = this.parent.dataOperation.getDuration(segment.startDate, endDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
    segment.endDate = new Date(endDate.getTime());
    segment.duration = duration;
    // update next segment offset duration
    if (!isNullOrUndefined(item.segments[this.segmentIndex + 1])) {
      var nextSegment = item.segments[this.segmentIndex + 1];
      var segmentOffset = this.parent.dataOperation.getDuration(item.segments[this.segmentIndex].endDate, nextSegment.startDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
      segment.offsetDuration = segmentOffset;
    }
    this.parent.setRecordValue('segments', item.segments, item, true);
    this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
    this.parent.setRecordValue('duration', this.sumOfDuration(item.segments), item, true);
    this.parent.setRecordValue('endDate', item.segments[item.segments.length - 1].endDate, item, true);
    this.parent.editModule.updateResourceRelatedFields(this.taskBarEditRecord, 'duration');
  };
  TaskbarEdit.prototype.sumOfDuration = function (segments) {
    var duration = 0;
    for (var i = 0; i < segments.length; i++) {
      var segment = segments[i];
      duration += segment.duration;
    }
    return duration;
  };
  TaskbarEdit.prototype.setSplitTaskDrag = function (item) {
    var segment = item.segments[this.segmentIndex];
    var left = this.getRoundOffStartLeft(segment, this.roundOffDuration);
    var projectStartDate = this.getDateByLeft(left);
    projectStartDate = this.parent.dateValidationModule.checkStartDate(projectStartDate, item, null);
    segment.startDate = projectStartDate;
    var segmentDate = this.parent.dataOperation.getEndDate(segment.startDate, segment.duration, item.durationUnit, item, false);
    if (item.segments[this.segmentIndex + 1] && segmentDate.getTime() > item.segments[this.segmentIndex + 1].startDate.getTime()) {
      segment.endDate = item.segments[this.segmentIndex + 1].startDate;
    } else {
      segment.endDate = segmentDate;
    }
    segment.duration = this.parent.dataOperation.getDuration(segment.startDate, segment.endDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
    this.parent.setRecordValue('duration', this.sumOfDuration(item.segments), item, true);
    this.parent.setRecordValue('endDate', item.segments[item.segments.length - 1].endDate, item, true);
    if (!isNullOrUndefined(this.parent.taskFields.endDate)) {
      this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'endDate');
    }
    //set offset if previous record present
    if (!isNullOrUndefined(item.segments[this.segmentIndex - 1])) {
      var offsetDuration = this.parent.dataOperation.getDuration(item.segments[this.segmentIndex - 1].endDate, item.segments[this.segmentIndex].startDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
      if (segment.startDate.getDay() === 1 && offsetDuration === 0 && !this.parent.includeWeekend) {
        offsetDuration = 1;
      }
      segment.offsetDuration = offsetDuration;
    }
    //set next record  offset if present
    if (!isNullOrUndefined(item.segments[this.segmentIndex + 1])) {
      var nextSegment = item.segments[this.segmentIndex + 1];
      var offsetDuration = this.parent.dataOperation.getDuration(item.segments[this.segmentIndex].endDate, nextSegment.startDate, item.durationUnit, item.isAutoSchedule, item.isMilestone);
      if (nextSegment.startDate.getDay() === 1 && offsetDuration === 0 && !this.parent.includeWeekend) {
        offsetDuration = 1;
      }
      nextSegment.offsetDuration = offsetDuration;
    }
    this.parent.setRecordValue('segments', item.segments, item, true);
    this.parent.dataOperation.updateMappingData(this.taskBarEditRecord, 'segments');
  };
  /**
   * To get roundoff enddate.
   *
   * @param {ITaskData} ganttRecord .
   * @param {boolean} isRoundOff .
   * @returns {number} .
   * @private
   */
  TaskbarEdit.prototype.getRoundOffEndLeft = function (ganttRecord, isRoundOff) {
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    var totalLeft = ganttRecord.width + ganttRecord.left;
    if (this.segmentIndex !== -1 && ganttRecord.segments.length > 1) {
      var segment = ganttRecord.segments[this.segmentIndex];
      totalLeft = totalLeft - ganttRecord.width + segment.width + segment.left;
    }
    var remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(totalLeft), 1, 'Day') / (1000 * 60 * 60 * 24));
    var remainingLeft = this.parent.perDayWidth - this.parent.perDayWidth / remainingContribution;
    var positionValue = remainingLeft / this.parent.perDayWidth;
    if (isRoundOff === undefined) {
      isRoundOff = false;
    }
    /*Rounding the decimal value for week-month-year schedule mode*/
    if (!isRoundOff) {
      if (tierMode !== 'Hour' && tierMode !== 'Minutes') {
        if (positionValue > 0.5) {
          totalLeft = totalLeft - remainingLeft + this.parent.perDayWidth;
        } else if (positionValue < 0.5) {
          totalLeft = totalLeft - remainingLeft + this.parent.perDayWidth / 2;
        }
      }
    } else if (isRoundOff) {
      if (tierMode === 'Hour') {
        var inHour = this.parent.perDayWidth / 24;
        remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(totalLeft), 1, 'Hour') / (1000 * 60 * 60));
        remainingLeft = this.parent.perDayWidth / 24 - this.parent.perDayWidth / 24 / remainingContribution;
        if (remainingLeft !== 0) {
          totalLeft = totalLeft - remainingLeft + inHour;
        }
      } else if (tierMode === 'Minutes') {
        var inMinutes = this.parent.perDayWidth / (24 * 60);
        remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(totalLeft), 1, 'Minutes') / (1000 * 60));
        remainingLeft = this.parent.perDayWidth / (24 * 60) - this.parent.perDayWidth / (24 * 60) / remainingContribution;
        if (remainingLeft !== 0) {
          totalLeft = totalLeft - remainingLeft + inMinutes;
        }
      } else {
        if (remainingLeft !== 0) {
          totalLeft = totalLeft - remainingLeft + this.parent.perDayWidth;
        }
      }
    }
    return totalLeft;
  };
  /**
   * To get roundoff startdate.
   *
   * @param {ITaskData | ITaskSegment} ganttRecord .
   * @param {boolean} isRoundOff .
   * @returns {number} .
   * @private
   */
  TaskbarEdit.prototype.getRoundOffStartLeft = function (ganttRecord, isRoundOff) {
    var left = isNullOrUndefined(ganttRecord) ? ganttRecord.left : ganttRecord.left;
    if (this.segmentIndex !== -1 && isNullOrUndefined(ganttRecord.segments)) {
      left = ganttRecord.left + this.taskBarEditRecord.ganttProperties.left;
    }
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.bottomTier : this.parent.timelineModule.topTier;
    var remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(left, isNullOrUndefined(ganttRecord) ? ganttRecord.isMilestone : null, ganttRecord), 1, 'Day') / (1000 * 60 * 60 * 24));
    var remainDays = this.parent.perDayWidth - this.parent.perDayWidth / remainingContribution;
    var remainDaysInDecimal = remainDays / this.parent.perDayWidth;
    if (isRoundOff === undefined) {
      isRoundOff = false;
    }
    /*Rounding the decimal value for week-month-year schedule mode*/
    if (!isRoundOff) {
      if (tierMode !== 'Hour' && tierMode !== 'Minutes') {
        if (remainDaysInDecimal <= 0.5) {
          left = left - remainDays;
        } else if (remainDaysInDecimal > 0.5) {
          left = left - remainDays + this.parent.perDayWidth / 2;
        }
      }
    } else if (isRoundOff) {
      if (tierMode === 'Hour') {
        remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(left), 1, 'Hour') / (1000 * 60 * 60));
        remainDays = this.parent.perDayWidth / 24 - this.parent.perDayWidth / 24 / remainingContribution;
        left = left - remainDays;
      } else if (tierMode === 'Minutes') {
        remainingContribution = 1 / (this.parent.timelineModule.getIncrement(this.getDateByLeft(left), 1, 'Minutes') / (1000 * 60));
        remainDays = this.parent.perDayWidth / (24 * 60) - this.parent.perDayWidth / (24 * 60) / remainingContribution;
        left = left - remainDays;
      } else {
        left = left - remainDays;
      }
    }
    return left;
  };
  /**
   * To get date by left value.
   *
   * @param {number} left .
   * @param {boolean} isMilestone .
   * @param {ITaskData} property .
   * @returns {Date} .
   * @private
   */
  TaskbarEdit.prototype.getDateByLeft = function (left, isMilestone, property) {
    var pStartDate = new Date(this.parent.timelineModule.timelineStartDate.toString());
    var milliSecondsPerPixel = 24 * 60 * 60 * 1000 / this.parent.perDayWidth;
    pStartDate.setTime(pStartDate.getTime() + left * milliSecondsPerPixel);
    /* To render the milestone in proper date while editing */
    if (isMilestone && !isNullOrUndefined(property.predecessorsName) && property.predecessorsName !== '') {
      //  pStartDate.setDate(pStartDate.getDate() -1);
      var dayEndTime = this.parent['getCurrentDayEndTime'](property.isAutoSchedule ? property.autoEndDate : property.endDate);
      this.parent.dateValidationModule.setTime(dayEndTime, pStartDate);
      pStartDate = this.parent.dateValidationModule.checkStartDate(pStartDate, property, true);
    }
    var tierMode = this.parent.timelineModule.bottomTier !== 'None' ? this.parent.timelineModule.topTier : this.parent.timelineModule.bottomTier;
    if (tierMode !== 'Hour' && tierMode !== 'Minutes') {
      if (this.parent.isInDst(new Date(this.parent.timelineModule.timelineStartDate.toString())) && !this.parent.isInDst(pStartDate)) {
        pStartDate.setTime(pStartDate.getTime() + 60 * 60 * 1000);
      }
      // else if (!this.parent.isInDst(new Date(this.parent.timelineModule.timelineStartDate.toString())) && this.parent.isInDst(pStartDate)) {
      //     pStartDate.setTime(pStartDate.getTime() - (60 * 60 * 1000));
      // }
    }
    return pStartDate;
  };
  /**
   * To set item position.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.setItemPosition = function () {
    if (!isNullOrUndefined(this.editElement)) {
      var currentElement = this.editElement.parentElement;
      if (this.parent.allowTaskbarDragAndDrop && this.taskBarEditAction === 'ChildDrag') {
        currentElement.style.position = null;
      } else {
        if (this.parent.enableTimelineVirtualization && this.parent.timelineModule.wholeTimelineWidth > this.parent.element.offsetWidth * 3) {
          currentElement.style.setProperty('position', 'relative');
        } else {
          currentElement.style.setProperty('position', 'absolute');
        }
      }
    }
    var item = this.taskBarEditRecord.ganttProperties;
    var position = this.parent.enableRtl ? 'right' : 'left';
    var segment = !isNullOrUndefined(item.segments) ? item.segments[this.segmentIndex] : null;
    var width = this.taskBarEditAction === 'MilestoneDrag' || item.isMilestone ? this.parent.chartRowsModule.milestoneHeight : item.width;
    var rightResizer = this.parent.isAdaptive ? width - 2 : width - 10;
    if (!isNullOrUndefined(segment)) {
      rightResizer = this.parent.isAdaptive ? segment.width - 2 : segment.width - 10;
    }
    this.taskBarEditElement.style.setProperty('opacity', '.75');
    var taskBarMainContainer$1;
    if (this.parent.allowTaskbarDragAndDrop && !item.isAutoSchedule && this.taskBarEditRecord.hasChildRecords) {
      taskBarMainContainer$1 = this.taskBarEditElement;
    } else {
      taskBarMainContainer$1 = !this.taskBarEditElement.classList.contains(taskBarMainContainer) ? (this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'LeftResizing') && this.segmentIndex === 0 ? this.taskBarEditElement.parentElement : closest(this.taskBarEditElement, 'tr.' + chartRow).querySelector('.' + taskBarMainContainer) : this.taskBarEditElement;
    }
    var segmentedTaskBarContainer = this.taskBarEditElement.classList.contains('e-segmented-taskbar') || this.taskBarEditElement.querySelector('.e-segmented-taskbar') ? true : false;
    var traceChildProgressBar$1 = this.taskBarEditElement.querySelector('.' + traceChildProgressBar);
    var traceChildTaskBar$1 = this.taskBarEditElement.querySelector('.' + traceChildTaskBar);
    var childProgressResizer$1 = segmentedTaskBarContainer ? parentsUntil(this.taskBarEditElement, taskBarMainContainer).querySelector('.' + childProgressResizer) : this.taskBarEditElement.querySelector('.' + childProgressResizer);
    var taskBarRightResizer$1 = this.taskBarEditElement.querySelector('.' + taskBarRightResizer);
    var traceParentTaskBar$1 = this.taskBarEditElement.querySelector('.' + traceParentTaskBar);
    var traceParentProgressBar$1 = this.taskBarEditElement.querySelector('.' + traceParentProgressBar);
    var traceConnectorPointRight = this.taskBarEditElement.querySelector('.' + rightConnectorPointOuterDiv);
    var segmentConnectorPointRight = taskBarMainContainer$1.querySelector('.' + rightConnectorPointOuterDiv);
    var manualParentTaskbar = this.taskBarEditElement;
    var manualTaskbar = this.taskBarEditElement.querySelector('.' + manualParentTaskBar);
    var manualParentRight = this.taskBarEditElement.querySelector('.' + manualParentRightResizer);
    var manualParentLeft = this.taskBarEditElement.querySelector('.' + manualParentLeftResizer);
    var resizeLine = this.parent.ganttChartModule.chartBodyContainer.querySelector('.e-taskbar-resize-div');
    if (this.parent.allowTaskbarDragAndDrop && !this.updatePosition) {
      this.leftValue = this.leftValue + 30;
      this.updatePosition = true;
    }
    if (this.taskBarEditAction !== 'ConnectorPointRightDrag' && this.taskBarEditAction !== 'ConnectorPointLeftDrag') {
      if (this.taskBarEditAction !== 'ParentResizing' && this.taskBarEditAction !== 'ManualParentDrag') {
        if (segmentedTaskBarContainer && !isNullOrUndefined(item.segments) && (this.taskBarEditAction === 'RightResizing' || this.segmentIndex !== 0)) {
          if (!isNullOrUndefined(resizeLine)) {
            if (!this.parent.allowTaskbarDragAndDrop || this.parent.allowTaskbarDragAndDrop && this.parent.rowDragAndDropModule && this.taskBarEditAction !== 'ChildDrag' && this.taskBarEditAction !== 'ParentDrag') {
              resizeLine.style.width = segment.width + 'px';
            }
          }
          this.taskBarEditElement.style.width = segment.width + 'px';
          if (this.parent.enableRtl) {
            this.taskBarEditElement.style.right = segment.left + 'px';
          } else {
            this.taskBarEditElement.style.left = segment.left + 'px';
            if (!isNullOrUndefined(resizeLine)) {
              if (!this.parent.allowTaskbarDragAndDrop || this.parent.allowTaskbarDragAndDrop && this.parent.rowDragAndDropModule && this.taskBarEditAction !== 'ChildDrag' && this.taskBarEditAction !== 'ParentDrag') {
                resizeLine.style.left = segment.left + this.editElement.parentElement.offsetLeft + 'px';
              }
            }
          }
        }
        taskBarMainContainer$1.style.setProperty(position, (this.parent.allowTaskbarDragAndDrop ? this.leftValue : item.left) + 'px');
        taskBarMainContainer$1.style.width = width + 'px';
        if (segmentedTaskBarContainer && segmentConnectorPointRight) {
          segmentConnectorPointRight.style.left = (this.parent.isAdaptive ? width + 10 : width + 2) + 'px';
        }
        if (this.parent.allowTaskbarDragAndDrop && this.parent.rowDragAndDropModule && this.taskBarEditAction !== 'LeftResizing' && this.taskBarEditAction !== 'RightResizing' && this.taskBarEditAction !== 'ProgressResizing') {
          var addTop = this.parent.taskbarHeight / 2;
          taskBarMainContainer$1.style.setProperty('top', this.topValue + addTop + 'px');
          taskBarMainContainer$1.style.zIndex = '4';
        }
        if (this.taskBarEditAction === 'LeftResizing' && this.segmentIndex === 0) {
          this.taskBarEditElement.style.setProperty('opacity', '.75');
          var parent_1 = this.taskBarEditElement.parentElement;
          var segmentedTasks = parent_1.getElementsByClassName('e-segmented-taskbar');
          for (var i = 0; i < item.segments.length; i++) {
            var segment_4 = item.segments[i];
            var segmentElement = segmentedTasks[i];
            segmentElement.style.width = segment_4.width + 'px';
            if (item.segments.length === 1) {
              resizeLine.style.width = segment_4.width + 'px';
              resizeLine.style.setProperty(position, item.left + 'px');
            } else {
              if (i === 0) {
                resizeLine.style.width = segment_4.width + 'px';
                resizeLine.style.setProperty(position, segment_4.left + item.left + 'px');
              }
              if (this.parent.enableRtl) {
                segmentElement.style.right = segment_4.left + 'px';
              } else {
                segmentElement.style.left = segment_4.left + 'px';
              }
            }
          }
        }
        if (this.taskBarEditAction === 'ChildDrag' && (this.segmentIndex === 0 || this.segmentIndex !== -1 && this.parent.allowTaskbarDragAndDrop)) {
          resizeLine.style.width = width + 'px';
          resizeLine.style.setProperty(position, item.left + 'px');
          taskBarMainContainer$1.style.setProperty('opacity', '.75');
        }
      }
      if (traceConnectorPointRight) {
        if (this.parent.enableRtl) {
          traceConnectorPointRight.style.left = (this.parent.isAdaptive ? width + 10 : width - 2) + 'px';
        } else {
          traceConnectorPointRight.style.left = (this.parent.isAdaptive ? width + 10 : width + 2) + 'px';
        }
      }
      if (traceConnectorPointRight && this.taskBarEditAction === 'LeftResizing') {
        traceConnectorPointRight.style.left = width - 2 + 'px';
      }
      if (childProgressResizer$1 && traceChildProgressBar$1 && (this.taskBarEditAction === 'LeftResizing' || this.taskBarEditAction === 'RightResizing' || this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'ParentDrag')) {
        childProgressResizer$1.style.display = 'none';
        traceChildProgressBar$1.style.display = 'none';
      }
      if (traceConnectorPointRight && this.taskBarEditAction === 'ProgressResizing') {
        traceConnectorPointRight.style.display = 'none';
      }
      if (this.taskBarEditAction === 'MilestoneDrag' || item.isMilestone) {
        taskBarMainContainer$1.style.setProperty(position, (this.parent.allowTaskbarDragAndDrop ? this.leftValue : item.left - width / 2) + 'px');
        resizeLine.style.setProperty(position, item.left - width / 2 + 'px');
        resizeLine.style.width = width + 'px';
      } else if (this.taskBarEditAction === 'ProgressResizing') {
        if (this.segmentIndex === -1) {
          traceChildTaskBar$1.style.setProperty(position, item.left + item.progressWidth - 10 + 'px');
          if (!isNullOrUndefined(traceChildProgressBar$1)) {
            traceChildProgressBar$1.style.width = item.progressWidth + 'px';
            traceChildProgressBar$1.style.borderBottomRightRadius = this.progressBorderRadius + 'px';
            traceChildProgressBar$1.style.borderTopRightRadius = this.progressBorderRadius + 'px';
            var width_1 = this.parent.enableRtl ? item.progressWidth + 8 : item.progressWidth - 8;
            childProgressResizer$1.style.setProperty(position, width_1 + 'px');
          }
        } else {
          this.updateSegmentProgress(this.taskBarEditRecord.ganttProperties);
          var progressElements = taskBarMainContainer$1.querySelectorAll('.e-gantt-child-progressbar');
          for (var count = 0; count < progressElements.length; count++) {
            if (item.segments[count] && item.segments[count].progressWidth && this.segmentIndex !== count) {
              progressElements[count]['style'].display = 'block';
              progressElements[count].style.width = item.segments[count].progressWidth + 'px';
            }
          }
          traceChildProgressBar$1.style.width = item.segments[this.segmentIndex].progressWidth + 'px';
          traceChildProgressBar$1.style.borderBottomRightRadius = this.progressBorderRadius + 'px';
          traceChildProgressBar$1.style.borderTopRightRadius = this.progressBorderRadius + 'px';
          var width_2 = this.parent.enableRtl ? this.progressValue + 8 : this.progressValue - 8;
          childProgressResizer$1.style.setProperty(position, width_2 + 'px');
        }
      } else if (this.taskBarEditAction === 'RightResizing' && !isNullOrUndefined(traceChildTaskBar$1)) {
        resizeLine.style.width = width + 'px';
        traceChildTaskBar$1.style.width = width + 'px';
        if (!isNullOrUndefined(traceChildProgressBar$1)) {
          traceChildProgressBar$1.style.width = item.progressWidth + 'px';
          taskBarRightResizer$1.style.setProperty(position, rightResizer + 'px');
          if (!isNullOrUndefined(childProgressResizer$1)) {
            childProgressResizer$1.style.setProperty(position, item.progressWidth - 10 + 'px');
          }
        }
      } else if (this.taskBarEditAction === 'ParentDrag') {
        resizeLine.style.setProperty(position, item.left + 'px');
        resizeLine.style.width = width + 'px';
        resizeLine.style.width = item.width + 'px';
        if (!isNullOrUndefined(traceParentTaskBar$1)) {
          traceParentTaskBar$1.style.width = width + 'px';
          resizeLine.style.width = item.width + 'px';
        }
        if (!isNullOrUndefined(traceChildProgressBar$1)) {
          traceParentProgressBar$1.style.width = item.progressWidth + 'px';
        }
      } else if (this.taskBarEditAction === 'ParentResizing') {
        resizeLine.style.width = item.width + 'px';
        resizeLine.style.setProperty(position, item.left + 'px');
        manualParentTaskbar.style.setProperty('width', item.width + 'px');
        manualTaskbar.style.setProperty('width', item.width + 'px');
        this.editElement.parentElement.style.setProperty('width', item.width + 'px');
      } else if (this.taskBarEditAction === 'ManualParentDrag') {
        resizeLine.style.width = item.width + 'px';
        resizeLine.style.setProperty(position, item.left + 'px');
        manualParentTaskbar.style.setProperty(position, item.left - item.autoLeft + 'px');
        if (this.parent.allowTaskbarDragAndDrop) {
          manualParentTaskbar.style.setProperty(position, this.leftValue + 'px');
          manualParentTaskbar.style.setProperty('top', this.topValue + 'px');
          manualParentTaskbar.style.zIndex = '4';
        }
      } else {
        if (!isNullOrUndefined(traceChildTaskBar$1) && !segmentedTaskBarContainer) {
          traceChildTaskBar$1.style.width = item.width + 'px';
          traceChildTaskBar$1.style.left = item.left + 'px';
          this.taskBarEditElement.style.width = item.width + 'px';
          this.taskBarEditElement.style.left = (this.parent.allowTaskbarDragAndDrop ? this.leftValue : item.left) + 'px';
          resizeLine.style.setProperty(position, item.left + 'px');
          resizeLine.style.width = item.width + 'px';
        }
        if (!isNullOrUndefined(traceChildProgressBar$1)) {
          taskBarRightResizer$1.style.setProperty(position, rightResizer + 'px');
          traceChildProgressBar$1.style.width = item.progressWidth + 'px';
          if (!isNullOrUndefined(childProgressResizer$1)) {
            childProgressResizer$1.style.setProperty(position, item.progressWidth - 10 + 'px');
          }
        }
        if (segmentedTaskBarContainer) {
          taskBarRightResizer$1.style.setProperty(position, rightResizer + 'px');
          traceChildProgressBar$1.style.width = segment.width + 'px';
          if (!isNullOrUndefined(childProgressResizer$1)) {
            childProgressResizer$1.style.setProperty(position, segment.width - 10 + 'px');
          }
        }
      }
    }
    if (this.parent.allowTaskbarDragAndDrop && (this.taskBarEditAction === 'ChildDrag' || this.taskBarEditAction === 'ParentDrag' || this.taskBarEditAction === 'ManualParentDrag' || this.taskBarEditAction === 'MilestoneDrag')) {
      var resizeValue = this.parent.enableRtl ? parseInt(resizeLine.style.right, 10) : parseInt(resizeLine.style.left, 10);
      if (this.parent.enableRtl) {
        resizeLine.style.right = resizeValue - 30 + 'px';
      } else {
        resizeLine.style.left = resizeValue + 30 + 'px';
      }
    }
  };
  /**
   * To handle mouse up event in chart
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.mouseUpHandler = function (e) {
    var mouseDragged = this.isMouseDragged;
    this.previousMouseMove = null;
    this.editTooltip.showHideTaskbarEditTooltip(false, this.segmentIndex);
    if (this.taskBarEditAction && this.isMouseDragged) {
      if (!this.dragMouseLeave && this.taskBarEditedAction || this.parent.allowTaskbarDragAndDrop) {
        if (e.type === 'touchmove' || e.type === 'touchstart' || e.type === 'touchend') {
          var coordinates = this.getCoordinate(e);
          this['droppedTarget'] = document.elementFromPoint(coordinates.pageX, coordinates.pageY);
        } else {
          if (this.taskBarEditRecord.hasChildRecords && !this.taskBarEditRecord.ganttProperties.isAutoSchedule) {
            var taskbarPosition = this.parent.getOffsetRect(this.taskBarEditElement);
            var left = !isNullOrUndefined(document.body.className) && document.body.className.includes('e-bigger') ? 12 : 0;
            var pointerLeft = 3 + this.parent.chartRowsModule.connectorPointWidth + left;
            var xValue = void 0;
            if (this.parent.enableRtl) {
              xValue = Math.abs(taskbarPosition.left + pointerLeft + 1);
            } else {
              xValue = Math.abs(Math.abs(taskbarPosition.left - pointerLeft - 1) - this.parent.ganttChartModule.scrollElement.scrollLeft);
            }
            this['droppedTarget'] = document.elementFromPoint(xValue, e.y);
          } else {
            this['droppedTarget'] = document.elementFromPoint(e.x, e.y);
          }
          if (this['droppedTarget'] && this['droppedTarget'].classList.contains('e-span-label')) {
            this['droppedTarget'] = document.elementFromPoint(e.x + this['droppedTarget']['offsetWidth'], e.y);
          }
        }
        this.previousIds = this.parent.ids.slice();
        this.previousFlatData = this.parent.flatData.slice();
        this.taskBarEditedAction(e);
        this.isMouseDragged = false;
      } else {
        this.parent.isOnEdit = false;
        this.cancelTaskbarEditActionInMouseLeave();
        var criticalModule = this.parent.criticalPathModule;
        if (this.parent.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
          criticalModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
        }
      }
    }
    if (this.parent.enableMultiTaskbar && !isNullOrUndefined(this.taskBarEditElement)) {
      if (!isNullOrUndefined(this.taskBarEditElement.querySelector('.e-gantt-child-taskbar'))) {
        if (this.taskBarEditElement.querySelector('.e-gantt-child-taskbar').classList.contains('e-collapsed-taskbar-drag')) {
          removeClass([this.taskBarEditElement.querySelector('.e-gantt-child-taskbar')], 'e-collapsed-taskbar-drag');
          this.taskBarEditElement.style.zIndex = this.prevZIndex;
          this.prevZIndex = '';
        }
      }
    }
    if (!this.parent.isAdaptive || mouseDragged) {
      this.initPublicProp();
    }
    this.stopScrollTimer();
  };
  /**
   * To perform taskbar edit operation.
   *
   * @param {PointerEvent} event .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.taskBarEditedAction = function (event) {
    var args = {};
    var x1 = this.mouseDownX;
    var y1 = this.mouseDownY;
    var item = this.taskBarEditRecord;
    var recordIndex = this.parent.ganttChartModule.getIndexByTaskBar(this.taskBarEditElement);
    var x2 = this.mouseMoveX;
    var e = this.getCoordinate(event);
    var resMouseY = e.pageY - this.parent.ganttChartModule.chartBodyContainer.offsetTop;
    if ((this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag') && !this.drawPredecessor) {
      this.dependencyCancel = true;
    }
    var parentRecord = [];
    if ((this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag') && this.drawPredecessor && (!this.connectorSecondRecord.hasChildRecords || this.connectorSecondRecord.hasChildRecords && this.parent.allowParentDependency)) {
      parentRecord.push(extend([], [], [this.taskBarEditRecord], true)[0]);
      if (this.parent.undoRedoModule) {
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1]['connectedRecords'] = parentRecord;
        if (this.parent.toolbarModule) {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true);
        }
      }
      this.parent.connectorLineEditModule.updatePredecessor(this.connectorSecondRecord, this.finalPredecessor);
    } else {
      if (this.taskBarEditAction === 'ConnectorPointLeftDrag' || this.taskBarEditAction === 'ConnectorPointRightDrag' || this.oldData && JSON.stringify(item.ganttProperties) === JSON.stringify(this.oldData.ganttProperties)) {
        if (this.parent.undoRedoModule && this.parent.undoRedoModule['getUndoCollection'].length > 0) {
          this.parent['totalUndoAction']--;
          this.parent.undoRedoModule['getUndoCollection'].splice(this.parent.undoRedoModule['getUndoCollection'].length - 1, 1);
          if (this.parent.toolbarModule) {
            this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], false);
          }
        }
      } else {
        if (this.parent.undoRedoModule && this.parent.toolbarModule && this.parent.undoRedoModule['getUndoCollection'].length > 0) {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true);
        }
      }
      if (x1 !== x2 || Math.abs(y1 - resMouseY) >= (this.parent.rowHeight - this.parent.taskbarHeight) / 2) {
        if (item !== null) {
          args.editingFields = item.ganttProperties;
          args.data = item;
          args.recordIndex = recordIndex;
          args.previousData = this.previousItem;
          args.taskBarEditAction = this.taskBarEditAction;
          args.action = 'TaskbarEditing';
          args.roundOffDuration = this.roundOffDuration;
          args.target = this.targetElement;
          this.taskbarEditedArgs = args;
          this.taskbarEdited(args);
        }
      }
    }
    this.parent['isProjectDateUpdated'] = false;
  };
  /**
   * To cancel the taskbar edt action.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.cancelTaskbarEditActionInMouseLeave = function () {
    this.parent.editModule.reUpdatePreviousRecords(true);
  };
  TaskbarEdit.prototype.updateSegmentProgress = function (taskData) {
    var segments = taskData.segments;
    var fixedWidth = true;
    var totalTaskWidth = this.splitTasksDuration(segments) * this.parent.perDayWidth;
    var progress = Math.ceil(taskData.progressWidth / totalTaskWidth * 100);
    var totalProgressWidth = totalTaskWidth * progress / 100;
    var tempWidth = totalProgressWidth;
    for (var i = 0; i < segments.length; i++) {
      var segment = segments[i];
      if (i !== 0) {
        if (segment.left <= tempWidth) {
          var newWidth = tempWidth - segment.left;
          totalProgressWidth = newWidth;
        } else {
          totalProgressWidth = 0;
        }
      }
      delete segment.progressWidth;
      if (totalProgressWidth > 0 && totalProgressWidth > segment.width) {
        totalProgressWidth = totalProgressWidth - segment.width;
        segment.progressWidth = segment.width;
        segment.showProgress = false;
      } else if (fixedWidth) {
        segment.progressWidth = totalProgressWidth;
        segment.showProgress = true;
        totalProgressWidth = totalProgressWidth - segment.width;
        fixedWidth = false;
      }
    }
  };
  /**
   * To trigger taskbar edited event.
   *
   * @param {ITaskbarEditedEventArgs} arg .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.taskbarEdited = function (arg) {
    var args = extend({}, arg);
    var ganttRecord = args.data;
    var taskData = ganttRecord.ganttProperties;
    var draggedRecIndex = this.parent.flatData.indexOf(ganttRecord);
    if (this.parent.allowTaskbarDragAndDrop && this.dragMoveY > 0 && (this.parent.viewType === 'ResourceView' && !ganttRecord.hasChildRecords || this.parent.viewType === 'ProjectView')) {
      if (this.parent.rowDragAndDropModule) {
        var flatRecordCol = this.parent.currentViewData;
        if (this.taskBarEditRecord.parentItem && flatRecordCol[this.taskBarEditRecord.parentItem.index] && this.parent.editedRecords.indexOf(flatRecordCol[this.taskBarEditRecord.parentItem.index]) === -1) {
          this.parent.editedRecords.push(flatRecordCol[this.taskBarEditRecord.parentItem.index]);
        }
        this.removeChildBorder();
        this.removeErrorElem();
        this.removetopOrBottomBorder();
        var droppedRecord = void 0;
        this.updatePosition = false;
        this.draggedRecordMarginTop = this.taskBarEditElement.style.marginTop;
        var row = void 0;
        if (!isNullOrUndefined(this.droppedTarget)) {
          row = closest(this.droppedTarget, 'tr.' + chartRow);
        }
        if (row) {
          var recordIndex = parseInt(row.getAttribute('data-rowindex'), 10);
          droppedRecord = this.parent.flatData[recordIndex];
          var droppedParentRecordIndex = this.parent.getRootParent(droppedRecord, 0).index;
          var draggedParentRecordIndex = this.parent.getRootParent(this.taskBarEditRecord, 0).index;
          var e = {
            cancel: false,
            data: this.taskBarEditRecord,
            fromIndex: this.taskBarEditRecord.index,
            dropIndex: droppedRecord.index,
            dropPosition: this.parent.rowDragAndDropModule['dropPosition'],
            dropRecord: droppedRecord
          };
          this.parent.trigger('rowDrop', e);
          if (!e['cancel'] && droppedRecord && recordIndex !== draggedRecIndex && (droppedParentRecordIndex !== draggedParentRecordIndex || this.taskBarEditRecord.hasChildRecords && droppedRecord.hasChildRecords || !this.taskBarEditRecord.hasChildRecords)) {
            var droppedRecordIndex = this.parent.flatData.indexOf(droppedRecord);
            var position = void 0;
            if (this.parent.viewType === 'ProjectView' && !isNullOrUndefined(this.parent.rowDragAndDropModule['dropPosition'])) {
              position = this.parent.rowDragAndDropModule['dropPosition'];
            } else {
              position = droppedRecord.hasChildRecords || !droppedRecord.parentItem && droppedRecord.childRecords.length === 0 ? 'child' : this.parent.rowDragAndDropModule['dropPosition'];
            }
            if (this.parent.rowDragAndDropModule) {
              if (this.parent.viewType === 'ResourceView' && position === 'child' && !droppedRecord.hasChildRecords && !isNullOrUndefined(droppedRecord.parentItem)) {
                position = 'Invalid';
                this.parent.rowDragAndDropModule['dropPosition'] = 'Invalid';
              }
              if (position) {
                this.parent.rowDragAndDropModule.reorderRows([draggedRecIndex], droppedRecordIndex, position);
              }
            }
            this.dragMoveY = 0;
          }
        }
        this.isClonedElement = false;
      }
      if (this.parent.showOverAllocation) {
        this.parent.ganttChartModule.renderOverAllocationContainer();
      }
    }
    if (args.taskBarEditAction === 'ProgressResizing') {
      if (args.previousData.progress !== taskData.progress) {
        var progressWidth = args.data.ganttProperties.progressWidth;
        var totalTaskWidth = args.data.ganttProperties.width;
        /* eslint-disable-next-line */
        var updatedValues = this.validateProgressWidth(args.data.ganttProperties, progressWidth, totalTaskWidth);
        this.parent.setRecordValue('progress', this.getProgressPercent(updatedValues.totalTaskWidth, updatedValues.progressWidth), taskData, true);
        if (ganttRecord.parentItem) {
          this.parent.editModule.updateParentProgress(ganttRecord.parentItem);
        }
        if (!isNullOrUndefined(taskData.segments)) {
          this.updateSegmentProgress(taskData);
        }
      }
    } else {
      var segments = args.data.ganttProperties.segments;
      if (!isNullOrUndefined(segments) && segments.length > 0 && (this.taskBarEditAction === 'LeftResizing' && this.segmentIndex !== 0 || this.taskBarEditAction === 'ChildDrag' && this.segmentIndex !== 0 || this.taskBarEditAction === 'RightResizing')) {
        var segment = segments[this.segmentIndex];
        var ganttProp = this.taskBarEditRecord.ganttProperties;
        var previousSegment = this.segmentIndex === 0 ? null : segments[this.segmentIndex - 1];
        var nextSegment = this.segmentIndex === segments.length - 1 ? null : segments[this.segmentIndex + 1];
        var sDate = !isNullOrUndefined(nextSegment) ? new Date(nextSegment.startDate.getTime()) : this.parent.cloneProjectEndDate;
        var eDate = !isNullOrUndefined(previousSegment) ? new Date(previousSegment.endDate.getTime()) : this.parent.cloneProjectStartDate;
        var cStartDate = new Date(segment.startDate.getTime());
        var cEndDate = new Date(segment.endDate.getTime());
        if (this.parent.timelineModule.topTier === 'Day' && this.parent.timelineModule.bottomTier === 'Hour') {
          cStartDate.setHours(cStartDate.getHours() - 1);
          cEndDate.setHours(cEndDate.getHours() + 1);
        } else {
          cStartDate.setDate(cStartDate.getDate());
          cEndDate.setDate(cEndDate.getDate());
        }
        if (this.parent.includeWeekend) {
          sDate.setHours(0, 0, 0, 0);
          eDate.setHours(0, 0, 0, 0);
          cStartDate.setDate(cStartDate.getDate() - 1);
          cEndDate.setDate(cEndDate.getDate() + 1);
          cStartDate.setHours(0, 0, 0, 0);
          cEndDate.setHours(0, 0, 0, 0);
          if (cStartDate.getTime() <= eDate.getTime() && !isNullOrUndefined(previousSegment) && !isNullOrUndefined(segment)) {
            var segmentIndexes = [{
              'firstSegmentIndex': previousSegment.segmentIndex,
              'secondSegmentIndex': segment.segmentIndex
            }];
            this.parent.chartRowsModule.mergeTask(ganttProp.taskId, segmentIndexes);
          } else if (cEndDate.getTime() >= sDate.getTime() && this.segmentIndex !== segments.length - 1) {
            var segmentIndexes = [{
              'firstSegmentIndex': segment.segmentIndex,
              'secondSegmentIndex': nextSegment.segmentIndex
            }];
            this.parent.chartRowsModule.mergeTask(ganttProp.taskId, segmentIndexes);
          } else if (cEndDate.getTime() >= sDate.getTime()) {
            segment.endDate.setDate(this.parent.cloneProjectEndDate.getDate() - 1);
            segment.startDate = this.parent.dataOperation.getStartDate(segment.endDate, segment.duration, ganttProp.durationUnit, ganttProp);
            // eslint-disable-next-line
            for (var i = segments.length - 2; i >= 0; i++) {
              var segment_5 = segments[i];
              var eDate_1 = segment_5.endDate;
              eDate_1.setDate(eDate_1.getDate() - segment_5.offsetDuration);
              segment_5.endDate = eDate_1;
              segment_5.startDate = this.parent.dataOperation.getStartDate(segment_5.endDate, segment_5.duration, ganttProp.durationUnit, ganttProp);
            }
          }
        } else {
          //Hits while dragging taskbar toward right & taskbar rightside resizing:
          if (cEndDate.getTime() <= sDate.getTime() && this.segmentIndex !== segments.length - 1 && !this.parent.includeWeekend && this.parent.dataOperation.getDuration(this.parent.dataOperation.checkStartDate(cEndDate, taskData, false), sDate, taskData.durationUnit, false, false) === 0) {
            var segmentIndexes = [{
              'firstSegmentIndex': segment.segmentIndex,
              'secondSegmentIndex': nextSegment.segmentIndex
            }];
            this.parent.chartRowsModule.mergeTask(ganttProp.taskId, segmentIndexes);
          }
          //Hits while dragging taskbar toward left & taskbar leftside resizing:
          else if (cStartDate.getTime() >= eDate.getTime() && !isNullOrUndefined(previousSegment) && !isNullOrUndefined(segment) && !this.parent.includeWeekend && this.parent.dataOperation.getDuration(this.parent.dataOperation.checkEndDate(cStartDate, taskData, false), eDate, taskData.durationUnit, false, false) === 0) {
            var segmentIndexes = [{
              'firstSegmentIndex': previousSegment.segmentIndex,
              'secondSegmentIndex': segment.segmentIndex
            }];
            this.parent.chartRowsModule.mergeTask(ganttProp.taskId, segmentIndexes);
          }
        }
      }
      this.parent.dataOperation.updateWidthLeft(args.data);
    }
    this.isDragged = false;
    this.parent.dataOperation.updateTaskData(ganttRecord);
    this.parent.editModule.initiateUpdateAction(args);
  };
  /**
   * To get progress in percentage.
   *
   * @param {number} parentwidth .
   * @param {number} progresswidth .
   * @returns {number} .
   * @private
   */
  TaskbarEdit.prototype.getProgressPercent = function (parentwidth, progresswidth) {
    return Math.ceil(progresswidth / parentwidth * 100);
  };
  /**
   * false line implementation.
   *
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.drawFalseLine = function () {
    var x1 = this.mouseDownX;
    var y1 = this.mouseDownY;
    var x2 = this.mouseMoveX;
    var y2 = this.mouseMoveY;
    var length = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    var angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    var transform = 'rotate(' + (this.parent.enableRtl ? -angle : angle) + 'deg)';
    var left;
    var width = 0;
    if (!isNullOrUndefined(document.querySelectorAll('.e-chart-row')[0])) {
      width = document.querySelectorAll('.e-chart-row')[0].offsetWidth;
    }
    if (this.taskBarEditAction === 'ConnectorPointLeftDrag') {
      if (this.parent.enableRtl) {
        left = width - (this.elementOffsetLeft + this.parent.chartRowsModule.connectorPointWidth / 2) - Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left);
      } else {
        left = this.elementOffsetLeft - this.parent.chartRowsModule.connectorPointWidth / 2 - this.parent.ganttChartModule.scrollObject.previousScroll.left;
      }
    }
    if (this.taskBarEditAction === 'ConnectorPointRightDrag') {
      if (this.parent.enableRtl) {
        left = width - (this.elementOffsetLeft + this.elementOffsetWidth + this.parent.chartRowsModule.connectorPointWidth / 2) - Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left);
      } else {
        left = this.elementOffsetLeft + this.elementOffsetWidth + this.parent.chartRowsModule.connectorPointWidth / 2 - Math.abs(this.parent.ganttChartModule.scrollObject.previousScroll.left);
      }
    }
    var top = this.elementOffsetTop + this.elementOffsetHeight / 2 + this.parent.ganttChartModule.chartBodyContainer.offsetTop - this.parent.ganttChartModule.scrollObject.previousScroll.top;
    this.removeFalseLine(false);
    this.falseLine = createElement('div', {
      className: falseLine,
      id: 'ganttfalseline' + this.parent.element.id,
      styles: 'position: absolute;transform:' + transform + ';' + 'border-top-width: 1px;border-top-style: dashed;z-index: 5;width:' + (length - 3) + 'px;' + 'top:' + top + 'px;'
    });
    if (this.parent.enableRtl) {
      this.falseLine.style.left = 'auto';
      this.falseLine.style.right = left + 'px';
      this.falseLine.style.transformOrigin = '100% 0%';
    } else {
      this.falseLine.style.right = 'auto';
      this.falseLine.style.left = left + 'px';
      this.falseLine.style.transformOrigin = '0% 100%';
    }
    this.parent.ganttChartModule.chartBodyContainer.appendChild(this.falseLine);
  };
  /**
   *
   * @param {boolean} isRemoveConnectorPointDisplay .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.removeFalseLine = function (isRemoveConnectorPointDisplay) {
    if (this.falseLine) {
      remove(this.falseLine);
      this.falseLine = null;
      if (isRemoveConnectorPointDisplay) {
        this.elementOffsetLeft = 0;
        this.elementOffsetTop = 0;
        this.elementOffsetWidth = 0;
        this.elementOffsetHeight = 0;
        removeClass(this.parent.ganttChartModule.scrollElement.querySelectorAll('.' + connectorLineContainer), [connectorLineZIndex]);
      }
    }
  };
  /**
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.updateConnectorLineSecondProperties = function (e) {
    var target = this.getElementByPosition(e);
    var element = parentsUntil(target, taskBarMainContainer);
    var isBigger = document.body.className.includes('e-bigger');
    this.connectorSecondAction = null;
    var scrollTop = 0;
    if (parentsUntil(target, connectorPointLeft)) {
      this.connectorSecondAction = 'ConnectorPointLeftDrag';
      this.toPredecessorText = 'Start';
    } else if (parentsUntil(target, connectorPointRight)) {
      this.connectorSecondAction = 'ConnectorPointRightDrag';
      this.toPredecessorText = 'Finish';
    } else {
      this.connectorSecondAction = null;
      this.toPredecessorText = null;
    }
    if (this.taskBarEditElement !== element && this.taskBarEditElement !== this.highlightedSecondElement) {
      if (this.parent.virtualScrollModule && this.parent.enableVirtualization) {
        var top_1 = this.parent.virtualScrollModule.getTopPosition();
        scrollTop = top_1;
      }
      if (this.parent.virtualScrollModule && this.parent.enableVirtualization && !this.elementOffsetLeft || !this.parent.enableVirtualization) {
        if (!this.parent.allowParentDependency) {
          this.elementOffsetLeft = this.realTaskbarElement.offsetLeft - (isBigger ? 10 : 0);
          this.elementOffsetTop = this.realTaskbarElement.parentElement.offsetTop + this.realTaskbarElement.offsetHeight / 3 + scrollTop;
        } else {
          if (this.taskBarEditElement.children[0].classList.contains('e-manualparent-main-container')) {
            this.elementOffsetLeft = this.realTaskbarElement.children[0]['offsetLeft'] + this.realTaskbarElement.offsetLeft - (isBigger ? 10 : 0);
            this.elementOffsetTop = this.realTaskbarElement.parentElement.offsetTop + this.realTaskbarElement.offsetHeight / 3 - 5 + this.taskBarEditElement.children[0]['offsetTop'] + scrollTop;
          } else {
            this.elementOffsetLeft = this.realTaskbarElement.offsetLeft - (isBigger ? 10 : 0);
            this.elementOffsetTop = this.realTaskbarElement.parentElement.offsetTop + this.realTaskbarElement.offsetHeight / 3 + scrollTop;
          }
        }
        this.elementOffsetWidth = this.realTaskbarElement.offsetWidth + (isBigger ? 20 : 0);
        this.elementOffsetHeight = this.realTaskbarElement.offsetHeight;
      }
      this.showHideTaskBarEditingElements(element, this.highlightedSecondElement, true);
    }
    if (isNullOrUndefined(this.connectorSecondAction) && !isNullOrUndefined(this.connectorSecondElement) && (!this.connectorSecondRecord.hasChildRecords || this.connectorSecondRecord.hasChildRecords && this.parent.allowParentDependency)) {
      this.editTooltip.showHideTaskbarEditTooltip(false, this.segmentIndex);
      removeClass([this.connectorSecondElement.querySelector('.' + connectorPointLeft)], [connectorPointAllowBlock]);
      removeClass([this.connectorSecondElement.querySelector('.' + connectorPointRight)], [connectorPointAllowBlock]);
    }
    this.connectorSecondElement = this.connectorSecondAction ? element : null;
    this.highlightedSecondElement = element;
    this.connectorSecondRecord = isNullOrUndefined(this.connectorSecondElement) ? null : this.parent.ganttChartModule.getRecordByTaskBar(this.connectorSecondElement);
  };
  // eslint-disable-next-line
  TaskbarEdit.prototype.triggerDependencyEvent = function (e, mouseUp) {
    var parentWithZoomStyle = this.parent.element.closest('[style*="zoom"]');
    var zoomedPageY;
    if (parentWithZoomStyle) {
      var zoom1 = parseFloat(getComputedStyle(parentWithZoomStyle).zoom);
      zoomedPageY = e.pageY / zoom1;
    }
    var fromItem = this.taskBarEditRecord.ganttProperties;
    var toItem = this.connectorSecondRecord ? this.connectorSecondRecord.ganttProperties : null;
    var predecessor;
    var currentTarget;
    var target = this.getElementByPosition(e);
    var element = target;
    var uniqueId = this.parent.viewType === 'ResourceView' ? fromItem.taskId : fromItem.rowUniqueID;
    if (this.taskBarEditAction === 'ConnectorPointLeftDrag') {
      predecessor = uniqueId + (this.parent.enableRtl ? 'F' : 'S');
    } else if (this.taskBarEditAction === 'ConnectorPointRightDrag') {
      predecessor = uniqueId + (this.parent.enableRtl ? 'S' : 'F');
    }
    if (this.connectorSecondAction) {
      if (this.connectorSecondAction === 'ConnectorPointLeftDrag') {
        predecessor += this.parent.enableRtl ? 'F' : 'S';
        currentTarget = this.parent.enableRtl ? 'finish' : 'start';
      } else if (this.connectorSecondAction === 'ConnectorPointRightDrag') {
        predecessor += this.parent.enableRtl ? 'S' : 'F';
        currentTarget = this.parent.enableRtl ? 'start' : 'finish';
      }
    }
    if (isNullOrUndefined(toItem)) {
      this.drawPredecessor = false;
      return;
    }
    if (toItem.predecessorsName) {
      this.finalPredecessor = toItem.predecessorsName + ',' + predecessor;
    } else {
      this.finalPredecessor = predecessor;
    }
    var isValidLink = this.parent.connectorLineEditModule.validatePredecessorRelation(this.connectorSecondRecord, this.finalPredecessor);
    // eslint-disable-next-line
    var predecessorArray = this.parent.predecessorModule.calculatePredecessor(predecessor, this.connectorSecondRecord);
    var args = {};
    args.fromItem = fromItem;
    args.toItem = toItem;
    args.newPredecessorString = this.finalPredecessor;
    args.predecessor = predecessorArray && predecessorArray[0];
    args.isValidLink = isValidLink;
    args.requestType = 'ValidateDependency';
    this.parent.trigger('actionBegin', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer' && args.requestType !== 'ValidateDependency') {
      this.parent.showMaskRow();
    } else if (args.requestType !== 'ValidateDependency') {
      this.parent.showSpinner();
    }
    args.isValidLink = !isValidLink && args.isValidLink ? false : args.isValidLink;
    if (args.isValidLink) {
      if (!this.editTooltip.toolTipObj && !this.parent.isAdaptive) {
        this.editTooltip.showHideTaskbarEditTooltip(true, this.segmentIndex);
      }
      if (!isNullOrUndefined(this.editTooltip.toolTipObj)) {
        this.parent.connectorLineModule.tooltipTable.innerHTML = this.parent.connectorLineModule.getConnectorLineTooltipInnerTd(this.parent.editModule.taskbarEditModule.taskBarEditRecord.ganttProperties.taskName, this.parent.editModule.taskbarEditModule.fromPredecessorText, '', '');
        var table = this.parent.connectorLineModule.tooltipTable.querySelector('#toPredecessor').querySelectorAll('td');
        table[1].innerText = toItem.taskName;
        table[2].innerText = this.parent.localeObj.getConstant(currentTarget);
        if (!isNullOrUndefined(this.parent.connectorLineModule.tooltipTable.parentElement) && !isNullOrUndefined(this.parent.connectorLineModule.tooltipTable.parentElement.parentElement)) {
          var tooltipElement = this.parent.connectorLineModule.tooltipTable.parentElement.parentElement;
          if (tooltipElement.offsetTop + tooltipElement.offsetHeight > zoomedPageY) {
            tooltipElement.style.top = e.pageY - tooltipElement.offsetHeight - 20 + 'px';
          }
        }
      }
      this.drawPredecessor = true;
    } else {
      if (this.parent.isAdaptive) {
        if (target.classList.contains(connectorPointLeft) || target.classList.contains(connectorPointRight)) {
          this.showHideActivePredecessors(true);
        }
      } else {
        addClass([element], [connectorPointAllowBlock]);
      }
      this.drawPredecessor = false;
    }
  };
  // Get XY coordinates for touch and non-touch device
  TaskbarEdit.prototype.getCoordinate = function (event) {
    var coordinates = {};
    var e = event;
    coordinates.pageX = e.pageX;
    coordinates.pageY = e.pageY;
    if (event && event.type !== 'click') {
      var e_1 = event;
      if (e_1.type === 'touchmove' || e_1.type === 'touchstart' || e_1.type === 'touchend') {
        coordinates.pageX = e_1.changedTouches[0].pageX;
        coordinates.pageY = e_1.changedTouches[0].pageY;
      }
    }
    return coordinates;
  };
  // Get current target element by mouse position
  // window.pageXOffset && window.pageYOffset is used to find the accurate element position in IPad/IPhone
  TaskbarEdit.prototype.getElementByPosition = function (event) {
    if (!this.parent.isAdaptive) {
      return event.target;
    } else {
      var e = this.getCoordinate(event);
      return document.elementFromPoint(e.pageX - window.pageXOffset, e.pageY - window.pageYOffset);
    }
  };
  TaskbarEdit.prototype.multipleSelectionEnabled = function () {
    if (this.parent.selectionModule && this.parent.selectionSettings.mode !== 'Cell' && this.parent.selectionSettings.type === 'Multiple') {
      this.parent.selectionModule.hidePopUp();
    }
  };
  TaskbarEdit.prototype.unWireEvents = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('chartMouseDown', this.mouseDownHandler);
    this.parent.off('chartMouseUp', this.mouseUpHandler);
    this.parent.off('chartMouseLeave', this.mouseLeaveHandler);
    this.parent.off('chartMouseMove', this.mouseMoveAction);
    this.parent.off('chartMouseClick', this.mouseClickHandler);
  };
  /**
   * @returns {void} .
   * @private
   */
  TaskbarEdit.prototype.destroy = function () {
    this.unWireEvents();
    this.stopScrollTimer();
    this.parent.editModule.taskbarEditModule = undefined;
  };
  return TaskbarEdit;
}(DateProcessor);
var __assign$2 = undefined && undefined.__assign || function () {
  __assign$2 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$2.apply(this, arguments);
};
/**
 *
 * @hidden
 */
var DialogEdit = /** @__PURE__ @class */function () {
  /**
   * Constructor for render module
   *
   * @param {Gantt} parent .
   * @returns {void} .
   */
  function DialogEdit(parent) {
    this.isFromDialogPredecessor = false;
    this.isTriggered = false;
    this.taskFieldColumn = [];
    this.customFieldColumn = [];
    this.processedId = [];
    /**
     * @private
     */
    this.updatedEditFields = null;
    this.updatedAddFields = null;
    this.addedRecord = null;
    this.dialogEditValidationFlag = false;
    this.ganttResources = [];
    this.isValidData = true;
    this.isResourceTabUpdated = false;
    /**
     * @private
     */
    this.previousResource = [];
    /**
     * @private
     */
    this.isResourceUpdate = false;
    this.parent = parent;
    this.localeObj = this.parent.localeObj;
    this.beforeOpenArgs = {
      cancel: false
    };
    this.types = this.getPredecessorType();
    this.rowData = {};
    this.editedRecord = {};
    this.inputs = {
      booleanedit: CheckBox,
      dropdownedit: DropDownList,
      datepickeredit: DatePicker,
      datetimepickeredit: DateTimePicker,
      maskededit: MaskedTextBox,
      numericedit: NumericTextBox,
      stringedit: TextBox,
      defaultedit: TextBox
    };
    this.processDialogFields();
    this.wireEvents();
  }
  DialogEdit.prototype.wireEvents = function () {
    this.parent.on('chartDblClick', this.dblClickHandler, this);
  };
  DialogEdit.prototype.dblClickHandler = function (e) {
    var ganttData = this.parent.ganttChartModule.getRecordByTarget(e);
    if (!isNullOrUndefined(ganttData) && this.parent.editModule && this.parent.editSettings.allowEditing) {
      this.openEditDialog(ganttData);
    }
  };
  /**
   * Method to validate add and edit dialog fields property.
   *
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.processDialogFields = function () {
    if (isNullOrUndefined(this.parent.editDialogFields) || this.parent.editDialogFields && this.parent.editDialogFields.length === 0) {
      this.updatedEditFields = this.getDefaultDialogFields();
      this.updatedEditFields = this.validateDialogFields(this.updatedEditFields);
    } else {
      this.updatedEditFields = this.validateDialogFields(this.parent.editDialogFields);
    }
    if (isNullOrUndefined(this.parent.addDialogFields) || this.parent.addDialogFields && this.parent.addDialogFields.length === 0) {
      this.updatedAddFields = this.getDefaultDialogFields();
      this.updatedAddFields = this.validateDialogFields(this.updatedAddFields);
    } else {
      this.updatedAddFields = this.validateDialogFields(this.parent.addDialogFields);
    }
  };
  DialogEdit.prototype.validateDialogFields = function (dialogFields) {
    var newDialogFields = [];
    var emptyCustomColumn = 0;
    for (var i = 0; i < dialogFields.length; i++) {
      var fieldItem = getActualProperties(dialogFields[i]);
      if (fieldItem.type === 'General' && (isNullOrUndefined(fieldItem.fields) || fieldItem.fields.length === 0)) {
        fieldItem.fields = this.getGeneralColumnFields();
      }
      if (fieldItem.type === 'Dependency' && isNullOrUndefined(this.parent.taskFields.dependency) || fieldItem.type === 'Resources' && isNullOrUndefined(this.parent.taskFields.resourceInfo) || fieldItem.type === 'Notes' && isNullOrUndefined(this.parent.taskFields.notes)) {
        continue;
      }
      if (fieldItem.type === 'Custom' && (isNullOrUndefined(fieldItem.fields) || fieldItem.fields.length === 0)) {
        emptyCustomColumn += 1;
        fieldItem.fields = this.getCustomColumnFields();
      }
      if (emptyCustomColumn > 1) {
        continue;
      }
      newDialogFields.push(fieldItem);
    }
    return newDialogFields;
  };
  /**
   * Method to get general column fields
   *
   * @returns {string[]} .
   */
  DialogEdit.prototype.getGeneralColumnFields = function () {
    var fields = [];
    for (var _i = 0, _a = Object.keys(this.parent.columnMapping); _i < _a.length; _i++) {
      var key = _a[_i];
      if (key === 'dependency' || key === 'resourceInfo' || key === 'notes') {
        continue;
      }
      fields.push(this.parent.columnMapping[key]);
    }
    return fields;
  };
  /**
   * Method to get custom column fields
   *
   * @returns {void} .
   */
  DialogEdit.prototype.getCustomColumnFields = function () {
    var fields = [];
    for (var i = 0; i < this.parent.customColumns.length; i++) {
      fields.push(this.parent.customColumns[i]);
    }
    return fields;
  };
  /**
   * Get default dialog fields when fields are not defined for add and edit dialogs
   *
   * @returns {AddDialogFieldSettings} .
   */
  DialogEdit.prototype.getDefaultDialogFields = function () {
    var dialogFields = [];
    var fieldItem = {};
    var taskFields = this.parent.taskFields;
    var columnMapping = this.parent.columnMapping;
    if (Object.keys(columnMapping).length !== 0) {
      fieldItem.type = 'General';
      dialogFields.push(fieldItem);
    }
    if (!isNullOrUndefined(getValue('dependency', columnMapping))) {
      fieldItem = {};
      if (this.parent.columnByField[columnMapping.dependency.valueOf()].visible !== false) {
        fieldItem.type = 'Dependency';
      }
      dialogFields.push(fieldItem);
    }
    if (!isNullOrUndefined(getValue('resourceInfo', columnMapping))) {
      fieldItem = {};
      if (this.parent.columnByField[columnMapping.resourceInfo.valueOf()].visible !== false) {
        fieldItem.type = 'Resources';
      }
      dialogFields.push(fieldItem);
    }
    if (!isNullOrUndefined(getValue('notes', columnMapping))) {
      fieldItem = {};
      if (this.parent.columnByField[columnMapping.notes.valueOf()].visible !== false) {
        fieldItem.type = 'Notes';
      }
      dialogFields.push(fieldItem);
    }
    if (!isNullOrUndefined(getValue('segments', taskFields))) {
      fieldItem = {};
      fieldItem.type = 'Segments';
      dialogFields.push(fieldItem);
    }
    if (this.parent.customColumns.length > 0) {
      fieldItem = {};
      fieldItem.type = 'Custom';
      dialogFields.push(fieldItem);
    }
    return dialogFields;
  };
  /**
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.openAddDialog = function () {
    this.isEdit = false;
    this.editedRecord = this.composeAddRecord();
    this.isFromAddDialog = true;
    this.createDialog();
  };
  /**
   *
   * @returns {Date} .
   * @private
   */
  DialogEdit.prototype.getMinimumStartDate = function () {
    var minDate = DataUtil.aggregates.min(this.parent.flatData, 'ganttProperties.startDate');
    if (!isNullOrUndefined(minDate)) {
      minDate = new Date(minDate.getTime());
    } else {
      minDate = new Date(this.parent.timelineModule.timelineStartDate.getTime());
    }
    minDate = this.parent.dateValidationModule.checkStartDate(minDate);
    return new Date(minDate.getTime());
  };
  /**
   * @returns {IGanttData} .
   * @private
   */
  DialogEdit.prototype.composeAddRecord = function () {
    var tempData = {};
    tempData.ganttProperties = {};
    var columns = this.parent.ganttColumns;
    var taskSettings = this.parent.taskFields;
    var id = this.parent.editModule.getNewTaskId();
    for (var i = 0; i < columns.length; i++) {
      var field = columns[i].field;
      if (field === taskSettings.id) {
        tempData[field] = id;
        tempData.ganttProperties.rowUniqueID = tempData[field];
      } else if (columns[i].field === taskSettings.startDate) {
        if (isNullOrUndefined(tempData[taskSettings.endDate])) {
          tempData[field] = this.getMinimumStartDate();
        } else {
          tempData[field] = new Date(tempData[taskSettings.endDate]);
        }
        if (this.parent.timezone) {
          tempData[field] = this.parent.dateValidationModule.remove(tempData[field], this.parent.timezone);
        }
        tempData.ganttProperties.startDate = new Date(tempData[field]);
      } else if (columns[i].field === taskSettings.endDate) {
        if (isNullOrUndefined(tempData[taskSettings.startDate])) {
          tempData[field] = this.getMinimumStartDate();
        } else {
          tempData[field] = new Date(tempData[taskSettings.startDate]);
        }
        if (this.parent.timezone) {
          tempData[field] = this.parent.dateValidationModule.remove(tempData[field], this.parent.timezone);
        }
        tempData.ganttProperties.endDate = new Date(tempData[field]);
      } else if (columns[i].field === taskSettings.duration) {
        tempData[field] = 1;
        tempData.ganttProperties.duration = tempData[field];
        tempData.ganttProperties.durationUnit = this.parent.durationUnit.toLocaleLowerCase();
      } else if (columns[i].field === taskSettings.name) {
        tempData[field] = this.localeObj.getConstant('addDialogTitle') + ' ' + id;
        tempData.ganttProperties.taskName = tempData[field];
      } else if (columns[i].field === taskSettings.progress) {
        tempData[field] = 0;
        tempData.ganttProperties.progress = tempData[field];
      } else if (columns[i].field === taskSettings.work) {
        tempData[field] = 0;
        tempData.ganttProperties.work = tempData[field];
      } else if (columns[i].field === taskSettings.type) {
        tempData[field] = this.parent.taskType;
        tempData.ganttProperties.taskType = tempData[field];
      } else {
        tempData[this.parent.ganttColumns[i].field] = '';
      }
    }
    tempData.ganttProperties.isAutoSchedule = this.parent.taskMode === 'Auto' ? true : this.parent.taskMode === 'Manual' ? false : tempData[taskSettings.manual] === true ? false : true;
    return tempData;
  };
  /**
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.openToolbarEditDialog = function () {
    var gObj = this.parent;
    if (gObj.editModule && gObj.editSettings.allowEditing) {
      if (this.parent.ganttChartModule.focusedRowIndex > -1 && gObj.selectionModule) {
        gObj.selectionModule.selectRow(this.parent.ganttChartModule.focusedRowIndex, false, false);
      }
      var selectedRowId = gObj.selectionModule ? (gObj.selectionSettings.mode === 'Row' || gObj.selectionSettings.mode === 'Both') && gObj.selectionModule.selectedRowIndexes.length === 1 ? gObj.updatedRecords[gObj.selectionModule.selectedRowIndexes[0]].ganttProperties.rowUniqueID : gObj.selectionSettings.mode === 'Cell' && gObj.selectionModule.getSelectedRowCellIndexes().length === 1 ? gObj.updatedRecords[gObj.selectionModule.getSelectedRowCellIndexes()[0].rowIndex].ganttProperties.rowUniqueID : null : null;
      if (!isNullOrUndefined(selectedRowId)) {
        this.openEditDialog(selectedRowId);
      }
    }
  };
  /**
   * @param { number | string | object} taskId .
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.openEditDialog = function (taskId) {
    var ganttObj = this.parent;
    if (!isNullOrUndefined(taskId)) {
      if (!isNullOrUndefined(taskId['ganttProperties'])) {
        if (typeof taskId['ganttProperties']['taskId'] === 'string') {
          this.numericOrString = 'stringedit';
        } else {
          this.numericOrString = 'numericedit';
        }
      }
      if (isNullOrUndefined(taskId['ganttProperties']) && !isNullOrUndefined(taskId)) {
        if (isNaN(Number(taskId)) || this.parent.columnByField[this.parent.taskFields.id].editType === 'stringedit') {
          this.numericOrString = 'stringedit';
        } else {
          this.numericOrString = 'numericedit';
        }
      }
    }
    if (typeof taskId === 'object' && !isNullOrUndefined(taskId)) {
      this.rowIndex = this.parent.currentViewData.indexOf(taskId);
      if (this.rowIndex > -1) {
        this.rowData = taskId;
      }
    } else if (!isNullOrUndefined(taskId)) {
      this.rowIndex = ganttObj.ids.indexOf(taskId.toString());
      if (this.rowIndex > -1) {
        this.rowData = ganttObj.flatData[this.rowIndex];
      }
    } else if (ganttObj.selectedRowIndex > -1) {
      this.rowData = ganttObj.currentViewData[ganttObj.selectedRowIndex];
      this.rowIndex = ganttObj.selectedRowIndex;
    }
    this.isEdit = true;
    if (this.parent.viewType === 'ResourceView' && this.rowData.level === 0) {
      return;
    }
    if (Object.keys(this.rowData).length !== 0) {
      this.editedRecord = extend({}, {}, this.rowData, true);
      this.isFromEditDialog = true;
      this.createDialog();
    }
  };
  DialogEdit.prototype.createDialog = function () {
    var _this = this;
    var ganttObj = this.parent;
    var dialogModel = {};
    this.beforeOpenArgs.dialogModel = dialogModel;
    this.beforeOpenArgs.rowData = this.editedRecord;
    this.beforeOpenArgs.rowIndex = this.rowIndex;
    var dialogMaxWidth = this.parent.isAdaptive ? '' : '600px';
    var dialog = this.parent.createElement('div', {
      id: ganttObj.element.id + '_dialog',
      styles: 'max-width:' + dialogMaxWidth
    });
    dialog.classList.add('e-gantt-dialog');
    ganttObj.element.appendChild(dialog);
    dialogModel.animationSettings = {
      effect: 'None'
    };
    dialogModel.header = this.localeObj.getConstant(this.isEdit ? 'editDialogTitle' : 'addDialogTitle');
    dialogModel.isModal = true;
    dialogModel.enableRtl = this.parent.enableRtl;
    dialogModel.allowDragging = this.parent.isAdaptive || this.parent.enableAdaptiveUI ? false : true;
    dialogModel.showCloseIcon = true;
    var position = this.parent.isAdaptive ? {
      X: 'top',
      Y: 'left'
    } : {
      X: 'center',
      Y: 'center'
    };
    dialogModel.position = position;
    //dialogModel.width = '750px';
    dialogModel.height = this.parent.isAdaptive ? '100%' : 'auto';
    dialogModel.target = document.body;
    dialogModel.close = this.dialogClose.bind(this);
    dialogModel.closeOnEscape = true;
    /* eslint-disable-next-line */
    dialogModel.beforeClose = function (args) {
      if (args.closedBy === 'escape') {
        if (args.event.name === 'key-pressed' && args.event.target.nodeName === 'INPUT') {
          args.cancel = true;
        }
      }
    };
    dialogModel.open = function (args) {
      var dialogElement = getValue('element', args);
      var generalTabElement = dialogElement.querySelector('#' + _this.parent.element.id + 'GeneralTabContainer');
      if (generalTabElement && generalTabElement.scrollHeight > generalTabElement.offsetHeight) {
        generalTabElement.classList.add('e-scroll');
      }
      if (_this.tabObj.selectedItem === 0) {
        _this.tabObj.select(0);
      }
      if (_this.parent.isAdaptive) {
        dialogElement.style.maxHeight = 'none';
      }
      if (_this.parent.focusModule) {
        _this.parent.focusModule.setActiveElement(dialogElement);
      }
    };
    dialogModel.locale = this.parent.locale;
    dialogModel.buttons = [{
      buttonModel: {
        content: this.localeObj.getConstant('saveButton'),
        cssClass: 'e-primary'
      },
      click: this.buttonClick.bind(this)
    }, {
      buttonModel: {
        cssClass: 'e-flat',
        content: this.localeObj.getConstant('cancel')
      },
      click: this.buttonClick.bind(this)
    }];
    this.createTab(dialogModel, dialog);
  };
  DialogEdit.prototype.buttonClick = function (e) {
    var target = e.target;
    target.style.pointerEvents = 'none';
    if (this.localeObj.getConstant('cancel').toLowerCase() === e.target.innerText.trim().toLowerCase()) {
      if (this.dialog && !this.dialogObj.isDestroyed) {
        this.CustomformObj = null;
        this.formObj = null;
        this.storeValidTab = null;
        this.customFieldColumn = [];
        this.taskFieldColumn = [];
        this.dialogObj.hide();
        this.dialogClose();
      }
    } else {
      if (this.CustomformObj) {
        if (!this.CustomformObj.validate()) {
          target.style.pointerEvents = '';
          return;
        }
      }
      if (this.formObj) {
        /* eslint-disable-next-line */
        var formValid = this.formObj.validate();
        if (!formValid) {
          target.style.pointerEvents = '';
          return;
        }
      }
      if (this.storeDependencyTab || this.firstOccuringTab === 'Dependency') {
        /* eslint-disable-next-line */
        var dependencyTab = void 0;
        if (this.firstOccuringTab === 'Dependency') {
          var element = e.target.closest('#' + this.parent.element.id + '_dialog');
          dependencyTab = element.querySelector('.e-gridform');
        } else {
          dependencyTab = this.storeDependencyTab.querySelector('.e-gridform');
        }
        if (dependencyTab) {
          var dependencyTabValid = dependencyTab['ej2_instances'][0].validate();
          if (!dependencyTabValid) {
            target.style.pointerEvents = '';
            return;
          }
        }
      }
      if (this.storeResourceTab || this.firstOccuringTab === 'Resources') {
        /* eslint-disable-next-line */
        var resourceTab = void 0;
        if (this.firstOccuringTab === 'Resources') {
          var element = e.target.closest('#' + this.parent.element.id + '_dialog');
          resourceTab = element.querySelector('.e-gridform');
        } else {
          resourceTab = this.storeResourceTab.querySelector('.e-gridform');
        }
        if (resourceTab) {
          var resourceTabValid = resourceTab['ej2_instances'][0].validate();
          if (!resourceTabValid) {
            target.style.pointerEvents = '';
            return;
          }
        }
      }
      this.initiateDialogSave();
      this.parent['updateDuration'] = false;
      this.CustomformObj = null;
      this.formObj = null;
      this.storeValidTab = null;
      this.customFieldColumn = [];
      this.taskFieldColumn = [];
      target.style.pointerEvents = 'auto';
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.dialogClose = function () {
    if (this.dialog) {
      this.resetValues();
    }
    if (!isNullOrUndefined(this.parent.focusModule) && !isNullOrUndefined(this.parent.focusModule.getActiveElement(true))) {
      this.parent.focusModule.getActiveElement(true).focus();
    }
  };
  DialogEdit.prototype.resetValues = function () {
    this.isEdit = false;
    this.isAddNewResource = false;
    this.editedRecord = {};
    this.parent['triggeredColumnName'] = '';
    this.rowData = {};
    this.rowIndex = -1;
    this.addedRecord = null;
    this.ganttResources = [];
    this.dialogEditValidationFlag = false;
    if (this.dialog && !this.dialogObj.isDestroyed) {
      this.destroyDialogInnerElements();
      this.dialogObj.destroy();
      remove(this.dialog);
    }
  };
  DialogEdit.prototype.destroyDialogInnerElements = function () {
    var ganttObj = this.parent;
    var tabModel = this.beforeOpenArgs.tabModel;
    var items = tabModel.items;
    for (var i = 0; i < items.length; i++) {
      var element = items[i].content;
      var id = element.getAttribute('id');
      if (!isNullOrUndefined(id) || id !== '') {
        id = id.replace(ganttObj.element.id, '');
        id = id.replace('TabContainer', '');
        if (id === 'General') {
          this.destroyCustomField(element);
        } else if (id === 'Dependency') {
          var gridObj = element.ej2_instances[0];
          gridObj.destroy();
        } else if (id === 'Notes') {
          var rte = element.ej2_instances[0];
          rte.destroy();
        } else if (id === 'Resources') {
          var treeGridObj = element.ej2_instances[0];
          treeGridObj.destroy();
        } else if (id.indexOf('Custom') !== -1) {
          this.destroyCustomField(element);
        }
      }
    }
  };
  DialogEdit.prototype.destroyCustomField = function (element) {
    var childNodes = element.childNodes;
    var ganttObj = this.parent;
    for (var i = 0; i < childNodes.length; i++) {
      var div = childNodes[i];
      var inputElement = div.querySelector('input[id^="' + ganttObj.element.id + '"]');
      if (inputElement) {
        var fieldName = inputElement.id.replace(ganttObj.element.id, '');
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        var controlObj = div.querySelector('#' + ganttObj.element.id + fieldName).ej2_instances[0];
        if (!isNullOrUndefined(controlObj)) {
          var column = ganttObj.columnByField[fieldName];
          if (!isNullOrUndefined(column) && !isNullOrUndefined(column.edit) && isNullOrUndefined(column.edit.params)) {
            var destroy = column.edit.destroy;
            if (typeof destroy === 'string') {
              destroy = getObject(destroy, window);
              destroy();
            } else {
              column.edit.destroy();
            }
          } else {
            controlObj.destroy();
          }
        }
      }
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.destroy = function () {
    this.resetValues();
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('chartDblClick', this.dblClickHandler);
    this.parent.editModule.dialogModule = undefined;
  };
  /**
   * Method to get current edit dialog fields value
   *
   * @returns {AddDialogFieldSettings} .
   */
  DialogEdit.prototype.getEditFields = function () {
    if (this.isEdit) {
      return this.updatedEditFields;
    } else {
      return this.updatedAddFields;
    }
  };
  DialogEdit.prototype.createTab = function (dialogModel, dialog) {
    var _this = this;
    var ganttObj = this.parent;
    var tabModel = {};
    var tabItems = [];
    var dialogSettings = this.getEditFields();
    var tabElement;
    var tasks = ganttObj.taskFields;
    var length = dialogSettings.length;
    tabModel.items = tabItems;
    tabModel.locale = this.parent.locale;
    tabModel.enableRtl = this.parent.enableRtl;
    this.beforeOpenArgs.tabModel = tabModel;
    var index = 0;
    if (length > 0) {
      for (var i = 0; i < length; i++) {
        var dialogField = dialogSettings[i];
        var tabItem = {};
        if (dialogField.type === 'General') {
          if (Object.keys(ganttObj.columnMapping).length === 0) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('generalTab');
          }
          tabItem.content = 'General';
          this.beforeOpenArgs[tabItem.content] = this.getFieldsModel(dialogField.fields);
        } else if (dialogField.type === 'Segments') {
          if (isNullOrUndefined(tasks.segments)) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('segments');
          }
          tabItem.content = 'Segments';
          this.beforeOpenArgs[tabItem.content] = this.getSegmentsModel(dialogField.fields);
        } else if (dialogField.type === 'Dependency') {
          if (isNullOrUndefined(tasks.dependency)) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('dependency');
          }
          tabItem.content = 'Dependency';
          this.beforeOpenArgs[tabItem.content] = this.getPredecessorModel(dialogField.fields);
        } else if (dialogField.type === 'Resources') {
          if (isNullOrUndefined(tasks.resourceInfo)) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('resourceName');
          }
          tabItem.content = 'Resources';
          this.beforeOpenArgs[tabItem.content] = this.getResourcesModel(dialogField.fields);
        } else if (dialogField.type === 'Notes') {
          if (isNullOrUndefined(tasks.notes)) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('notes');
          }
          tabItem.content = 'Notes';
          this.beforeOpenArgs[tabItem.content] = this.getNotesModel(dialogField.fields);
        } else {
          if (isNullOrUndefined(dialogField.fields) || dialogField.fields.length === 0) {
            continue;
          }
          if (isNullOrUndefined(dialogField.headerText)) {
            dialogField.headerText = this.localeObj.getConstant('customTab'); // eslint-disable-next-line
          }
          tabItem.content = 'Custom' + '' + index++;
          this.beforeOpenArgs[tabItem.content] = this.getFieldsModel(dialogField.fields);
        }
        tabItem.header = {
          text: dialogField.headerText
        };
        tabItems.push(tabItem);
      }
    }
    this.beforeOpenArgs.requestType = this.isEdit ? 'beforeOpenEditDialog' : 'beforeOpenAddDialog';
    this.parent.trigger('actionBegin', this.beforeOpenArgs, function (arg) {
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
      if (!arg.cancel) {
        _this.renderTabItems();
        tabModel.selected = _this.tabSelectedEvent.bind(_this);
        tabModel.height = _this.parent.isAdaptive ? '100%' : 'auto';
        tabModel.overflowMode = 'Scrollable';
        _this.tabObj = new Tab(tabModel);
        _this.tabObj.isStringTemplate = true;
        tabElement = _this.parent.createElement('div', {
          id: ganttObj.element.id + '_Tab'
        });
        _this.tabObj.appendTo(tabElement);
        dialogModel.content = tabElement;
        _this.dialog = dialog;
        _this.dialogObj = new Dialog(dialogModel);
        _this.dialogObj.isStringTemplate = true;
        _this.dialogObj.appendTo(_this.dialog);
        var actionCompleteArgs = {
          action: 'OpenDialog',
          requestType: _this.isEdit ? 'openEditDialog' : 'openAddDialog',
          data: _this.beforeOpenArgs.rowData,
          element: _this.dialog,
          cancel: false
        };
        var columns = _this.parent.treeGrid.grid.getColumns();
        /* eslint-disable-next-line */
        var isValidateColumn = columns.some(function (obj) {
          return obj.validationRules;
        });
        if (isValidateColumn) {
          _this.CustomformObj = null;
          _this.formObj = null;
          _this.storeValidTab = null;
          _this.customFieldColumn = [];
          _this.taskFieldColumn = [];
          _this.changeFormObj(actionCompleteArgs.element);
        }
        _this.parent.trigger('actionComplete', actionCompleteArgs, function (actionCompleteArg) {
          if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
            _this.parent.hideMaskRow();
          } else {
            _this.parent.hideSpinner();
          }
          if (actionCompleteArg.cancel) {
            _this.resetValues();
          }
        });
      } else {
        arg.cancel = false;
        if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
          _this.parent.hideMaskRow();
        } else {
          _this.parent.hideSpinner();
        }
      }
    });
  };
  /* eslint-disable-next-line */
  DialogEdit.prototype.changeFormObj = function (actionCompleteArgs) {
    if (!this.storeColumn) {
      this.storeColumn = this.parent.treeGrid.grid.getColumns();
    }
    if (!this.taskfields) {
      this.taskfields = this.parent.taskFields['properties'];
    }
    if (!this.storeValidTab) {
      this.storeValidTab = this.getFilteredDialogFields();
    }
    if (this.customFieldColumn.length === 0 || this.taskFieldColumn.length === 0) {
      this.validateColumn(this.storeColumn, this.taskfields, this.storeValidTab);
    }
    if (this.isFromAddDialog && this.parent.addDialogFields && this.parent.addDialogFields.length > 0) {
      var firstFieldType = this.parent.addDialogFields[0].type;
      if (firstFieldType === 'Resources' || firstFieldType === 'Dependency') {
        this.firstOccuringTab = firstFieldType;
      }
    } else if (this.isFromEditDialog && this.parent.editDialogFields && this.parent.editDialogFields.length > 0) {
      var firstFieldType = this.parent.editDialogFields[0].type;
      if (firstFieldType === 'Resources' || firstFieldType === 'Dependency') {
        this.firstOccuringTab = firstFieldType;
      }
    }
    if (!this.CustomformObj || !this.formObj) {
      var customFieldColumns = this.customFieldColumn;
      var taskFieldColumns = this.taskFieldColumn;
      if (!this.CustomformObj && customFieldColumns && customFieldColumns.length > 0) {
        /* eslint-disable-next-line */
        var validationRulesArray = {};
        for (var i = 0; i < customFieldColumns.length; i++) {
          var customColumn = customFieldColumns[i]; // Rename the variable
          if (customColumn.visible && customColumn.validationRules) {
            validationRulesArray[customColumn.field] = customColumn.validationRules;
          }
        }
        if (Object.keys(validationRulesArray).length > 0) {
          this.CustomformObj = actionCompleteArgs.querySelector('#' + this.parent.element.id + 'Custom0TabContainer');
          if (this.CustomformObj) {
            this.CustomformObj = this.createFormObj(this.CustomformObj, validationRulesArray);
          }
        }
      }
      if (!this.formObj && taskFieldColumns && taskFieldColumns.length > 0) {
        /* eslint-disable-next-line */
        var validationRulesArray = {};
        for (var i = 0; i < taskFieldColumns.length; i++) {
          var taskColumn = taskFieldColumns[i]; // Rename the variable
          if (taskColumn.visible && taskColumn.validationRules) {
            validationRulesArray[taskColumn.field] = taskColumn.validationRules;
          }
        }
        if (Object.keys(validationRulesArray).length > 0) {
          this.formObj = actionCompleteArgs.querySelector('#' + this.parent.element.id + 'GeneralTabContainer');
          if (this.formObj) {
            this.formObj = this.createFormObj(this.formObj, validationRulesArray);
          }
        }
      }
    }
    this.isFromAddDialog = false;
    this.isFromEditDialog = false;
  };
  DialogEdit.prototype.getFilteredDialogFields = function () {
    var dialogFields = this.isFromAddDialog ? this.parent.addDialogFields : this.parent.editDialogFields;
    if (dialogFields.length !== 0) {
      return dialogFields.filter(function (obj) {
        return obj.type === 'General' || obj.type === 'Custom';
      });
    }
    return null;
  };
  /* eslint-disable-next-line */
  DialogEdit.prototype.validateColumn = function (storeColumn, taskfields, storeValidTab) {
    var _this = this;
    if (storeValidTab) {
      storeValidTab.forEach(function (element) {
        /* eslint-disable-next-line */
        var targetArray = element.type === 'General' ? _this.taskFieldColumn : _this.customFieldColumn;
        element.fields.forEach(function (field) {
          var columnValue = _this.parent.getColumnByField(field, storeColumn);
          if (columnValue !== null) {
            targetArray.push(columnValue);
          } else {
            targetArray.push(_this.parent.columnByField[field]);
          }
        });
      });
    } else {
      /* eslint-disable-next-line */
      storeColumn.forEach(function (column) {
        if (_this.parent.customColumns.indexOf(column.field) !== -1) {
          _this.customFieldColumn.push(column);
        } else {
          _this.taskFieldColumn.push(column);
        }
      });
    }
  };
  DialogEdit.prototype.createFormObj = function (form, rules) {
    var _this = this;
    return new FormValidator(form, {
      rules: rules,
      locale: this.parent.locale,
      validationComplete: function (args) {
        _this.validationComplete(args);
      },
      customPlacement: function (inputElement, error) {
        var nameAttribute = inputElement.getAttribute('name');
        if (nameAttribute) {
          var columnName = nameAttribute;
          _this.valErrorPlacement(inputElement, error, columnName);
        }
      }
    });
  };
  DialogEdit.prototype.valErrorPlacement = function (inputElement, error, columnName) {
    var id = columnName + "-tooltip";
    var elem = this.getElemTable(inputElement);
    if (!elem) {
      this.createTooltip(inputElement, error, id);
    } else {
      var tooltipContent = elem.querySelector('.e-tip-content');
      if (tooltipContent) {
        tooltipContent.innerHTML = error.outerHTML;
      }
    }
  };
  DialogEdit.prototype.createTooltip = function (inputElement, errorMessage, id, display) {
    if (display === void 0) {
      display = 'block';
    }
    var existingTooltip = document.getElementById(id);
    if (existingTooltip) {
      existingTooltip.remove();
    }
    var parentElement = inputElement.parentElement;
    if (parentElement) {
      parentElement.style.position = 'relative';
    }
    var tooltipContainer = document.createElement('div');
    tooltipContainer.className = 'e-tooltip-wrap e-lib e-control e-popup e-griderror';
    tooltipContainer.style.display = display;
    tooltipContainer.style.zIndex = '1000';
    var labelId = id + "-label";
    var tooltipLabel = document.createElement('div');
    tooltipLabel.id = labelId;
    tooltipLabel.className = 'sr-only';
    var tooltipContent = document.createElement('div');
    tooltipContent.className = 'e-tip-content';
    var errorMessageElement = document.createElement('div');
    errorMessageElement.className = 'error-message';
    errorMessageElement.appendChild(errorMessage.cloneNode(true));
    var arrow = document.createElement('div');
    arrow.className = 'e-arrow-tip e-tip-top';
    arrow.appendChild(document.createElement('div')).className = 'e-arrow-tip-outer e-tip-top';
    arrow.appendChild(document.createElement('div')).className = 'e-arrow-tip-inner e-tip-top';
    tooltipContainer.setAttribute('aria-labelledby', labelId);
    tooltipContent.appendChild(errorMessageElement);
    tooltipContainer.appendChild(tooltipContent);
    tooltipContainer.appendChild(arrow);
    tooltipContainer.style.top = '125%';
    tooltipContainer.style.left = '50%';
    tooltipContainer.style.transform = 'translateX(-50%)';
    if (parentElement) {
      parentElement.appendChild(tooltipLabel);
      parentElement.appendChild(tooltipContainer);
    }
  };
  DialogEdit.prototype.getElemTable = function (inputElement) {
    var parentElement = inputElement.parentElement;
    if (parentElement) {
      return parentElement.querySelector('.e-tooltip-wrap');
    }
    return null;
  };
  DialogEdit.prototype.validationComplete = function (args) {
    var elem = this.getElemTable(args.element);
    if (elem) {
      if (args.status === 'failure') {
        elem.style.display = '';
      } else {
        elem.style.display = 'none';
      }
    }
  };
  DialogEdit.prototype.tabSelectedEvent = function (args) {
    var ganttObj = this.parent;
    var id = args.selectedContent.childNodes[0].id;
    var dialogModule = this.parent.editModule.dialogModule;
    var dialog = dialogModule.dialog;
    var hasEditedBatchCell = dialog.getElementsByClassName('e-editedbatchcell').length > 0;
    var hasEditedOrAddedRow = dialog.getElementsByClassName('e-editedrow').length > 0 || dialog.getElementsByClassName('e-addedrow').length > 0;
    if (dialogModule.storeResourceTab && hasEditedBatchCell) {
      document.querySelector('#' + ganttObj.element.id + '' + 'Resources' + 'TabContainer_gridcontrol').ej2_instances[0].saveCell();
    } else if (dialogModule.storeDependencyTab && hasEditedOrAddedRow) {
      document.querySelector('#' + ganttObj.element.id + '' + 'Dependency' + 'TabContainer').ej2_instances[0].editModule.batchSave();
    }
    if (id === ganttObj.element.id + 'DependencyTabContainer') {
      this.storeDependencyTab = args.selectedContent;
    }
    if (id === ganttObj.element.id + 'ResourcesTabContainer') {
      this.storeResourceTab = args.selectedContent;
    }
    if (id === ganttObj.element.id + 'Custom0TabContainer') {
      var columns = this.parent.treeGrid.grid.getColumns();
      /* eslint-disable-next-line */
      var isValidateColumn = columns.some(function (obj) {
        return obj.validationRules;
      });
      if (isValidateColumn) {
        this.changeFormObj(args.selectedContent);
      }
    }
    if (id === ganttObj.element.id + 'GeneralTabContainer') {
      var columns = this.parent.treeGrid.grid.getColumns();
      /* eslint-disable-next-line */
      var isValidateColumn = columns.some(function (obj) {
        return obj.validationRules;
      });
      if (isValidateColumn) {
        this.changeFormObj(args.selectedContent);
      }
    }
    if (this.parent.isAdaptive || this.parent.enableAdaptiveUI) {
      this.responsiveTabContent(id, ganttObj);
    }
    if (id === ganttObj.element.id + 'ResourcesTabContainer') {
      this.resourceSelection(id);
    } else if (id === ganttObj.element.id + 'NotesTabContainer') {
      document.getElementById(id).ej2_instances[0].refresh();
      // const notesTabElement: HTMLElement = document.querySelector('#' + this.parent.element.id + 'NotesTabContainer') as HTMLInputElement;
    } else if (id === ganttObj.element.id + 'SegmentsTabContainer') {
      if (isNullOrUndefined(this.beforeOpenArgs.rowData.ganttProperties.startDate)) {
        document.getElementById(id).ej2_instances[0].enableToolbarItems([this.parent.element.id + 'SegmentsTabContainer' + '_add'], false);
      } else {
        document.getElementById(id).ej2_instances[0].enableToolbarItems([this.parent.element.id + 'SegmentsTabContainer' + '_add'], true);
      }
    }
  };
  DialogEdit.prototype.responsiveTabContent = function (id, ganttObj) {
    var dialogContent = document.getElementById(ganttObj.element.id + '_dialog_dialog-content');
    var dialogContentHeight = dialogContent.clientHeight;
    dialogContentHeight -= dialogContent.querySelector('.e-tab-header').offsetHeight;
    var grid = document.querySelector('#' + id);
    if (grid.classList.contains('e-grid')) {
      dialogContentHeight -= grid.ej2_instances[0].getHeaderContent().offsetHeight;
      var toolbar_1 = grid.querySelector('.e-toolbar');
      if (toolbar_1) {
        dialogContentHeight -= toolbar_1.offsetHeight;
      }
    }
    grid.parentElement.style.height = dialogContentHeight + 'px';
  };
  DialogEdit.prototype.getFieldsModel = function (fields) {
    var fieldsModel = {};
    var columnByField = this.parent.columnByField;
    for (var i = 0; i < fields.length; i++) {
      if (fields[i] === this.parent.taskFields.dependency || fields[i] === this.parent.taskFields.resourceInfo || fields[i] === this.parent.taskFields.notes) {
        continue;
      }
      if (!isNullOrUndefined(columnByField[fields[i]])) {
        var fieldName = fields[i];
        this.createInputModel(columnByField[fieldName], fieldsModel);
      }
    }
    return fieldsModel;
  };
  DialogEdit.prototype.createInputModel = function (column, fieldsModel) {
    var _this = this;
    var ganttObj = this.parent;
    var locale = this.parent.locale;
    var taskSettings = this.parent.taskFields;
    var common = {
      placeholder: column.headerText,
      floatLabelType: 'Auto'
    };
    if (!isNullOrUndefined(this.parent.taskFields.id) && !isNullOrUndefined(this.parent.columnMapping.id) && !isNullOrUndefined(this.numericOrString)) {
      if (taskSettings.id === column.field) {
        column.editType = this.numericOrString;
      }
    }
    switch (column.editType) {
      case 'booleanedit':
        {
          var checkboxModel = {
            label: column.headerText,
            locale: locale,
            enableRtl: this.parent.enableRtl
          };
          fieldsModel[column.field] = checkboxModel;
          break;
        }
      case 'defaultedit':
      case 'stringedit':
        {
          var textBox = common;
          textBox.enableRtl = this.parent.enableRtl;
          if (column.field === ganttObj.columnMapping.duration || column.field === ganttObj.columnMapping.id || column.field === ganttObj.columnMapping.startDate || column.field === ganttObj.columnMapping.endDate) {
            textBox.change = function (args) {
              if (!_this.isTriggered) {
                if ((column.field === _this.parent.taskFields.duration || column.field === _this.parent.taskFields.work) && !_this.isTriggered) {
                  _this.isTriggered = true;
                  _this.parent['triggeredColumnName'] = column.field;
                }
                _this.validateScheduleFields(args, column, ganttObj);
              }
            };
          }
          fieldsModel[column.field] = common;
          break;
        }
      case 'numericedit':
        {
          var numeric = common;
          numeric.enableRtl = this.parent.enableRtl;
          if (taskSettings.progress === column.field) {
            numeric.min = 0;
            numeric.max = 100;
          }
          numeric.change = function (args) {
            if (!_this.isTriggered) {
              if ((column.field === _this.parent.taskFields.duration || column.field === _this.parent.taskFields.work) && !_this.isTriggered) {
                _this.isTriggered = true;
                _this.parent['triggeredColumnName'] = column.field;
              }
              _this.validateScheduleFields(args, column, ganttObj);
            }
          };
          fieldsModel[column.field] = numeric;
          break;
        }
      case 'datepickeredit':
        {
          var datePickerObj = common;
          datePickerObj.format = this.parent.getDateFormat();
          datePickerObj.enableRtl = this.parent.enableRtl;
          datePickerObj.strictMode = true;
          datePickerObj.firstDayOfWeek = ganttObj.timelineModule.customTimelineSettings.weekStartDay;
          if (column.field === ganttObj.columnMapping.startDate || column.field === ganttObj.columnMapping.endDate) {
            datePickerObj.renderDayCell = this.parent.renderWorkingDayCell.bind(this.parent);
            datePickerObj.change = function (args) {
              _this.validateScheduleFields(args, column, ganttObj);
            };
          }
          fieldsModel[column.field] = datePickerObj;
          break;
        }
      case 'datetimepickeredit':
        {
          var dateTimePickerObj = common;
          dateTimePickerObj.format = this.parent.getDateFormat();
          dateTimePickerObj.enableRtl = this.parent.enableRtl;
          dateTimePickerObj.strictMode = true;
          dateTimePickerObj.firstDayOfWeek = ganttObj.timelineModule.customTimelineSettings.weekStartDay;
          if (column.field === ganttObj.columnMapping.startDate || column.field === ganttObj.columnMapping.endDate) {
            dateTimePickerObj.renderDayCell = this.parent.renderWorkingDayCell.bind(this.parent);
            dateTimePickerObj.change = function (args) {
              _this.validateScheduleFields(args, column, ganttObj);
            };
          }
          fieldsModel[column.field] = dateTimePickerObj;
          break;
        }
      case 'dropdownedit':
        if (column.field === ganttObj.columnMapping.type || column.field === ganttObj.columnMapping.manual) {
          var dataKey = 'dataSource';
          var fieldsKey = 'fields';
          var types = [{
            'ID': 1,
            'Value': 'FixedUnit'
          }, {
            'ID': 2,
            'Value': 'FixedWork'
          }, {
            'ID': 3,
            'Value': 'FixedDuration'
          }];
          common[dataKey] = types;
          common[fieldsKey] = {
            value: 'Value'
          };
          var dropDownListObj = common;
          dropDownListObj.enableRtl = this.parent.enableRtl;
          dropDownListObj.change = function (args) {
            if (column.field === taskSettings.manual) {
              _this.editedRecord.ganttProperties.isAutoSchedule = !args.value;
            }
            _this.validateScheduleFields(args, column, ganttObj);
          };
        }
        fieldsModel[column.field] = common;
        break;
      case 'maskededit':
        fieldsModel[column.field] = common;
        break;
    }
    if (!isNullOrUndefined(column.edit) && !isNullOrUndefined(column.edit.params)) {
      extend(fieldsModel[column.field], column.edit.params);
    }
    return fieldsModel;
  };
  DialogEdit.prototype.validateScheduleFields = function (args, column, ganttObj) {
    var _a;
    var dialog;
    if (!isNullOrUndefined(ganttObj.editModule.dialogModule.dialog)) {
      dialog = ganttObj.editModule.dialogModule.dialog;
    }
    var targetId = null;
    var inputElement;
    var currentData = ganttObj.editModule.dialogModule.editedRecord;
    var cellValue = null;
    var colName = null;
    var formObject;
    var ids = this.parent.viewType === 'ResourceView' ? this.parent.getTaskIds() : this.parent.ids;
    var strViewType = this.parent.viewType;
    if (!isNullOrUndefined(args.element)) {
      inputElement = args.element;
      targetId = inputElement.getAttribute('id');
    } else if (!isNullOrUndefined(args.container)) {
      inputElement = args.container;
      targetId = inputElement.querySelector('input').getAttribute('id');
      inputElement = inputElement.querySelector('#' + targetId);
    } else if (!isNullOrUndefined(args.event) && !isNullOrUndefined(args.event.path) && !isNullOrUndefined(args.event.path)[1]) {
      inputElement = args.event.path[1];
      targetId = inputElement.querySelector('input').getAttribute('id');
      inputElement = inputElement.querySelector('#' + targetId);
    }
    if (isNullOrUndefined(inputElement)) {
      cellValue = args.value;
      colName = column.field;
    } else {
      cellValue = inputElement.value;
      colName = targetId.replace(ganttObj.element.id, '');
      if (this.parent.columnByField[this.parent.taskFields.id].editType === 'stringedit') {
        var customFn = function (args) {
          if (strViewType === 'ResourceView') {
            return ids.indexOf('T' + args['value']) === -1 && ids.indexOf('R' + args['value']) === -1;
          } else {
            return ids.indexOf(args['value']) === -1;
          }
        };
        var options = {
          rules: (_a = {}, _a[this.parent.taskFields.id] = {
            required: true,
            minLength: [customFn, 'ID is already present, please enter new value']
          }, _a)
        };
        /* eslint-disable-next-line */
        formObject = new FormValidator('#' + this.parent.element.id + 'GeneralTabContainer', options);
      }
    }
    if (colName.search('Segments') === 0) {
      colName = colName.replace('SegmentsTabContainer', '');
      this.validateSegmentFields(ganttObj, colName, cellValue, args);
      this.isTriggered = false;
      return true;
    } else {
      this.validateScheduleValuesByCurrentField(colName, cellValue, this.editedRecord);
      var ganttProp = currentData.ganttProperties;
      var tasks = ganttObj.taskFields;
      if (!isNullOrUndefined(tasks.startDate) && tasks.startDate !== colName) {
        this.updateScheduleFields(dialog, ganttProp, 'startDate');
      }
      if (tasks.endDate === colName && !isNullOrUndefined(ganttProp.startDate) && !isNullOrUndefined(args.value) && ganttProp.startDate.getTime() > args.value) {
        this.updateScheduleFields(dialog, ganttProp, 'endDate');
      }
      if (!isNullOrUndefined(tasks.endDate) && tasks.endDate !== colName) {
        this.updateScheduleFields(dialog, ganttProp, 'endDate');
      }
      if (!isNullOrUndefined(tasks.duration) && tasks.duration !== colName || ganttProp.duration >= 0) {
        this.updateScheduleFields(dialog, ganttProp, 'duration');
      }
      if (!isNullOrUndefined(tasks.work) && (tasks.work !== colName || ganttProp.taskType !== 'FixedWork')) {
        this.updateScheduleFields(dialog, ganttProp, 'work');
      }
      this.dialogEditValidationFlag = false;
      this.isTriggered = false;
      return true;
    }
  };
  DialogEdit.prototype.updateScheduleFields = function (dialog, ganttProp, ganttField) {
    var ganttObj = this.parent;
    var ganttId = ganttObj.element.id;
    var columnName = getValue(ganttField, ganttObj.columnMapping);
    var col = ganttObj.columnByField[columnName];
    var tempValue;
    var taskField = this.parent.taskFields;
    if (col.editType === 'stringedit') {
      var element = dialog.querySelector('#' + ganttId + columnName);
      if (element) {
        var textBox = element.ej2_instances[0];
        if (textBox) {
          tempValue = !isNullOrUndefined(col.edit) && !isNullOrUndefined(col.edit.read) ? col.edit.read() : !isNullOrUndefined(col.valueAccessor) ? col.valueAccessor(columnName, ganttObj.editModule.dialogModule.editedRecord, col) : this.parent.dataOperation.getDurationString(ganttProp.duration, ganttProp.durationUnit);
          if (textBox.value !== tempValue.toString() && taskField.duration === columnName) {
            textBox.value = tempValue;
            textBox.dataBind();
          } else if (taskField.startDate === columnName || taskField.endDate === columnName) {
            textBox.value = taskField.startDate === columnName ? ganttProp.startDate.toString() : ganttProp.endDate.toString();
            textBox.dataBind();
          }
        }
      }
    } else if (col.editType === 'datepickeredit' || col.editType === 'datetimepickeredit') {
      var element = dialog.querySelector('#' + ganttId + columnName);
      if (element) {
        var picker = col.editType === 'datepickeredit' ? element.ej2_instances[0] : element.ej2_instances[0];
        if (picker) {
          tempValue = ganttProp[ganttField];
          if (isNullOrUndefined(picker.value) && !isNullOrUndefined(tempValue) || isNullOrUndefined(tempValue) && !isNullOrUndefined(picker.value) || picker.value !== tempValue && !isNullOrUndefined(picker.value) && !isNullOrUndefined(tempValue) && picker.value.toString() !== tempValue.toString()) {
            picker.value = tempValue;
            picker.dataBind();
          }
        }
      }
    } else if (col.editType === 'numericedit') {
      var numericTextBox = dialog.querySelector('#' + ganttId + columnName).ej2_instances[0];
      tempValue = ganttProp[ganttField];
      if (!isNullOrUndefined(tempValue) && numericTextBox.value !== tempValue) {
        numericTextBox.value = tempValue;
        numericTextBox.dataBind();
      }
    }
  };
  /**
   * @param {IGanttData} ganttData .
   * @returns {void} .
   * @private
   */
  DialogEdit.prototype.validateDuration = function (ganttData) {
    var ganttProp = ganttData.ganttProperties;
    if (!this.dialogEditValidationFlag) {
      if (!isNullOrUndefined(ganttProp.startDate) && !isScheduledTask(ganttProp) && isNullOrUndefined(ganttProp.duration)) {
        this.parent.setRecordValue('endDate', null, ganttProp, true);
        this.parent.setRecordValue('isMilestone', false, ganttProp, true);
      } else if (isScheduledTask(ganttProp) || !isNullOrUndefined(ganttProp.startDate)) {
        if (ganttData.ganttProperties.isMilestone && ganttData.ganttProperties.duration !== 0) {
          this.parent.dateValidationModule.checkStartDate(ganttProp.startDate);
        }
        this.parent.dateValidationModule.calculateEndDate(ganttData);
      } else if (!isScheduledTask(ganttProp) && !isNullOrUndefined(ganttProp.endDate)) {
        this.parent.dateValidationModule.calculateStartDate(ganttData);
      }
      var milestone = ganttProp.duration === 0 ? true : false;
      this.parent.setRecordValue('isMilestone', milestone, ganttProp, true);
      this.dialogEditValidationFlag = true;
    }
  };
  DialogEdit.prototype.validateStartDate = function (ganttData) {
    var ganttProp = ganttData.ganttProperties;
    var tasks = this.parent.taskFields;
    if (!this.dialogEditValidationFlag) {
      if (isNullOrUndefined(ganttProp.startDate)) {
        this.parent.setRecordValue('duration', null, ganttProp, true);
        this.parent.setRecordValue('isMilestone', false, ganttProp, true);
        if (this.parent.allowUnscheduledTasks && isNullOrUndefined(tasks.endDate)) {
          this.parent.setRecordValue('endDate', null, ganttProp, true);
        }
      } else if (isScheduledTask(ganttProp)) {
        if (isNullOrUndefined(tasks.duration)) {
          this.parent.dateValidationModule.calculateDuration(ganttData);
        } else if (isNullOrUndefined(tasks.endDate)) {
          this.parent.dateValidationModule.calculateEndDate(ganttData);
        } else {
          this.parent.dateValidationModule.calculateEndDate(ganttData);
        }
      } else {
        if (!isNullOrUndefined(ganttProp.endDate)) {
          this.parent.dateValidationModule.calculateDuration(ganttData);
        } else if (!isNullOrUndefined(ganttProp.duration)) {
          this.parent.dateValidationModule.calculateEndDate(ganttData);
        }
      }
      this.dialogEditValidationFlag = true;
    }
  };
  DialogEdit.prototype.validateEndDate = function (ganttData) {
    var ganttProp = ganttData.ganttProperties;
    var tasks = this.parent.taskFields;
    if (!this.dialogEditValidationFlag) {
      if (isNullOrUndefined(ganttProp.endDate)) {
        this.parent.setRecordValue('duration', null, ganttProp, true);
        this.parent.setRecordValue('isMilestone', false, ganttProp, true);
      } else if (isScheduledTask(ganttProp)) {
        if (isNullOrUndefined(tasks.duration)) {
          this.parent.dateValidationModule.calculateDuration(ganttData);
        } else if (isNullOrUndefined(ganttProp.startDate)) {
          this.parent.dateValidationModule.calculateStartDate(ganttData);
        } else {
          if (!isNullOrUndefined(ganttProp.segments) && ganttProp.segments.length > 0) {
            ganttProp.segments = this.parent.editModule.cellEditModule.validateEndDateWithSegments(ganttProp);
          }
          this.parent.dateValidationModule.calculateDuration(ganttData);
        }
      } else {
        if (!isNullOrUndefined(ganttProp.duration)) {
          this.parent.dateValidationModule.calculateStartDate(ganttData);
        } else if (!isNullOrUndefined(ganttProp.startDate)) {
          this.parent.dateValidationModule.calculateDuration(ganttData);
        }
      }
      this.dialogEditValidationFlag = true;
    }
  };
  /**
   *
   * @param {string} columnName .
   * @param {string} value .
   * @param {IGanttData} currentData .
   * @returns {boolean} .
   * @private
   */
  DialogEdit.prototype.validateScheduleValuesByCurrentField = function (columnName, value, currentData) {
    var ganttObj = this.parent;
    var ganttProp = currentData.ganttProperties;
    var taskSettings = ganttObj.taskFields;
    if (taskSettings.duration === columnName) {
      if (!isNullOrUndefined(value) && value !== '' && (parseInt(value, 10) >= 0 || parseFloat(value) >= 0)) {
        ganttObj.dataOperation.updateDurationValue(value, ganttProp);
        this.parent.setRecordValue(taskSettings.duration, value, currentData);
        this.parent.setRecordValue('taskData.' + taskSettings.duration, ganttProp.duration, currentData);
        if (ganttProp.isMilestone && !isNullOrUndefined(this.parent.editModule.cellEditModule)) {
          var editedArgs = {};
          editedArgs.data = currentData;
          this.parent.editModule.cellEditModule['updateDates'](editedArgs);
        }
        this.validateDuration(currentData);
      } else {
        if (ganttObj.allowUnscheduledTasks) {
          if (ganttProp.startDate && ganttProp.endDate && ganttProp.startDate.getTime() > ganttProp.endDate.getTime() || value.indexOf('-') !== -1) {
            this.parent.setRecordValue('duration', 0, ganttProp, true);
            if (ganttProp.endDate) {
              this.parent.setRecordValue('startDate', ganttProp.endDate, ganttProp, true);
            }
          } else {
            if (value === '') {
              this.parent.setRecordValue('duration', null, ganttProp, true);
              if (ganttProp.endDate && ganttProp.startDate) {
                this.parent.setRecordValue('endDate', null, ganttProp, true);
              }
            } else {
              var regex = /^[^\d.-]+$/;
              if (regex.test(value)) {
                var err = "The provided value for the " + taskSettings.duration + " field is invalid. Please ensure the " + taskSettings.duration + " field contains only valid numeric values.";
                this.parent.trigger('actionFailure', {
                  error: err
                });
              }
              this.parent.setRecordValue('duration', ganttProp.duration, ganttProp, true);
            }
          }
        }
      }
      this.parent.editModule.updateResourceRelatedFields(currentData, 'duration');
    }
    if (taskSettings.startDate === columnName) {
      if (value !== '') {
        var startDate = this.parent.dateValidationModule.getDateFromFormat(value);
        startDate = this.parent.dateValidationModule.checkStartDate(startDate, ganttProp);
        this.parent.setRecordValue('startDate', startDate, ganttProp, true);
        this.validateStartDate(currentData);
      } else {
        if (ganttObj.allowUnscheduledTasks && !currentData.hasChildRecords) {
          this.parent.setRecordValue('startDate', null, ganttProp, true);
          this.parent.setRecordValue('duration', null, ganttProp, true);
          this.parent.setRecordValue('isMilestone', false, ganttProp, true);
        }
      }
    }
    if (taskSettings.endDate === columnName) {
      if (value !== '') {
        var endDate = this.parent.dateValidationModule.getDateFromFormat(value);
        var dayEndTime = this.parent['getCurrentDayEndTime'](endDate);
        if (endDate.getHours() === 0 && dayEndTime !== 86400) {
          this.parent.dateValidationModule.setTime(dayEndTime, endDate);
        }
        if (!isNullOrUndefined(ganttProp.startDate) && !isNullOrUndefined(ganttProp.endDate) && !isNullOrUndefined(endDate) && ganttProp.startDate.getTime() > endDate.getTime()) {
          endDate = ganttProp.endDate;
        }
        endDate = this.parent.dateValidationModule.checkEndDate(endDate, ganttProp);
        if (isNullOrUndefined(ganttProp.startDate) || endDate.getTime() >= ganttProp.startDate.getTime()) {
          this.parent.setRecordValue('endDate', endDate, ganttProp, true);
        }
        this.validateEndDate(currentData);
        if (ganttProp.isMilestone && !isNullOrUndefined(this.parent.editModule.cellEditModule)) {
          var editedArgs = {};
          editedArgs.data = currentData;
          this.parent.editModule.cellEditModule['updateDates'](editedArgs);
        }
      } else {
        if (ganttObj.allowUnscheduledTasks) {
          this.parent.setRecordValue('endDate', null, ganttProp, true);
          this.parent.setRecordValue('duration', null, ganttProp, true);
          this.parent.setRecordValue('isMilestone', false, ganttProp, true);
        }
      }
    }
    if (taskSettings.work === columnName) {
      if (!isNullOrUndefined(value) && value !== '') {
        this.parent.setRecordValue('work', value, ganttProp, true);
        this.parent.editModule.updateResourceRelatedFields(currentData, 'work');
        this.validateDuration(currentData);
      }
    }
    if (columnName === taskSettings.type) {
      this.parent.setRecordValue('taskType', value, ganttProp, true);
      // To validate the work column as well, if duartion column value is 0, when FixedDuration type
      if (value && value === 'FixedDuration' && ganttProp.duration === 0) {
        this.parent.editModule.updateResourceRelatedFields(currentData, 'work');
      }
    }
    if (taskSettings.manual === columnName) {
      this.parent.editModule.updateTaskScheduleModes(currentData);
    }
    return true;
  };
  DialogEdit.prototype.getSegmentsModel = function (fields) {
    var _this = this;
    var taskSettings = this.parent.taskFields;
    if (isNullOrUndefined(fields) || fields.length === 0) {
      fields = [];
      if (!isNullOrUndefined(taskSettings.startDate)) {
        fields.push(this.parent.taskFields.startDate);
      }
      if (!isNullOrUndefined(taskSettings.endDate)) {
        fields.push(this.parent.taskFields.endDate);
      }
      if (!isNullOrUndefined(taskSettings.duration)) {
        fields.push(this.parent.taskFields.duration);
      }
      if (!isNullOrUndefined(taskSettings.id)) {
        fields.push(this.parent.taskFields.id);
      }
    }
    var segmentInputModel = {};
    segmentInputModel.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Normal',
      newRowPosition: 'Bottom'
    };
    segmentInputModel.locale = this.parent.locale;
    segmentInputModel.dataSource = [];
    segmentInputModel.rowHeight = this.parent.isAdaptive ? 48 : null;
    segmentInputModel.toolbar = [{
      id: this.parent.element.id + 'SegmentsTabContainer' + '_add',
      prefixIcon: 'e-add',
      tooltipText: this.localeObj.getConstant('add'),
      align: 'Right',
      text: this.parent.isAdaptive ? '' : this.localeObj.getConstant('add')
    }, {
      id: this.parent.element.id + 'SegmentsTabContainer' + '_delete',
      prefixIcon: 'e-delete',
      tooltipText: this.localeObj.getConstant('delete'),
      align: 'Right',
      text: this.parent.isAdaptive ? '' : this.localeObj.getConstant('delete')
    }];
    var gridColumns = [];
    var _loop_1 = function (i) {
      var gridColumn = {};
      var generalTabString = 'General';
      switch (fields[i]) {
        case this_1.parent.taskFields.id:
          gridColumn = {
            field: fields[i],
            visible: false,
            isPrimaryKey: true
          };
          gridColumns.push(gridColumn);
          break;
        case this_1.parent.taskFields.startDate:
        case this_1.parent.taskFields.endDate:
          gridColumn = {
            field: fields[i],
            headerText: this_1.localeObj.getConstant(fields[i] === this_1.parent.taskFields.startDate ? 'startDate' : 'endDate'),
            editType: 'stringedit',
            width: '200px',
            edit: {
              write: function (args) {
                var datePickerModel;
                if (!isNullOrUndefined(_this.beforeOpenArgs[generalTabString]) && !isNullOrUndefined(_this.beforeOpenArgs[generalTabString][fields[i]])) {
                  datePickerModel = _this.beforeOpenArgs[generalTabString][fields[i]];
                } else {
                  var columnFields = _this.getGeneralColumnFields();
                  var columnModel = _this.getFieldsModel(columnFields);
                  datePickerModel = columnModel[fields[i]];
                }
                var value = args.rowData[args.column.field];
                setValue('value', value, datePickerModel);
                var datePicker = new _this.inputs[_this.parent.columnByField[fields[i]].editType](datePickerModel);
                datePicker.appendTo(args.element);
              },
              read: function (args) {
                var ej2Instance = args.ej2_instances[0];
                return ej2Instance.value;
              }
            },
            format: this_1.parent.getDateFormat()
          };
          if (fields[i] === this_1.parent.taskFields.startDate) {
            gridColumn.validationRules = {
              required: true
            };
          }
          gridColumns.push(gridColumn);
          break;
        case this_1.parent.taskFields.duration:
          gridColumn = {
            field: fields[i],
            headerText: this_1.localeObj.getConstant(fields[i].toLocaleLowerCase()),
            editType: 'stringedit',
            width: '100px',
            edit: {
              write: function (args) {
                var inputTextModel;
                if (!isNullOrUndefined(_this.beforeOpenArgs[generalTabString]) && !isNullOrUndefined(_this.beforeOpenArgs[generalTabString][fields[i]])) {
                  inputTextModel = _this.beforeOpenArgs[generalTabString][fields[i]];
                } else {
                  var columnFields = _this.getGeneralColumnFields();
                  var columnModel = _this.getFieldsModel(columnFields);
                  inputTextModel = columnModel[fields[i]];
                }
                inputTextModel.floatLabelType = 'Never';
                var value = args.rowData[args.column.field];
                if (!isNullOrUndefined(value)) {
                  setValue('value', value, inputTextModel);
                } else {
                  setValue('value', null, inputTextModel);
                }
                setValue('value', value, inputTextModel);
                var inputModel = new TextBox(inputTextModel);
                inputModel.appendTo(args.element);
              },
              read: function (args) {
                var ej2Instance = args.ej2_instances[0];
                return ej2Instance.value.toString();
              }
            }
          };
          gridColumns.push(gridColumn);
          break;
      }
    };
    var this_1 = this;
    for (var i = 0; i < fields.length; i++) {
      _loop_1(i);
    }
    segmentInputModel.columns = gridColumns;
    segmentInputModel.height = this.parent.isAdaptive || this.parent.enableAdaptiveUI ? '100%' : '153px';
    return segmentInputModel;
  };
  DialogEdit.prototype.getGridColumnByField = function (fieldName, columns) {
    var column;
    for (var i = 0; i < columns.length; i++) {
      if (columns[i].field === fieldName) {
        column = columns[i];
      }
    }
    return column;
  };
  DialogEdit.prototype.updateSegmentField = function (columnName, args, segment) {
    var dialog = this.parent.editModule.dialogModule.dialog;
    var gridModel = getValue('Segments', this.beforeOpenArgs);
    var col = this.getGridColumnByField(columnName, gridModel.columns);
    var ganttId = this.parent.element.id;
    var tempValue = segment[columnName];
    var inputValue;
    if (col.editType === 'stringedit') {
      inputValue = dialog.querySelector('#' + ganttId + 'SegmentsTabContainer' + columnName).ej2_instances[0];
    } else if (col.editType === 'datepickeredit') {
      inputValue = dialog.querySelector('#' + ganttId + 'SegmentsTabContainer' + columnName).ej2_instances[0];
    }
    if (!isNullOrUndefined(inputValue.value) && !isNullOrUndefined(tempValue) && inputValue.value.toString() !== tempValue.toString()) {
      inputValue.value = tempValue;
      inputValue.dataBind();
    }
  };
  DialogEdit.prototype.validateSegmentFields = function (ganttObj, columnName, cellValue, args) {
    var taskSettings = this.parent.taskFields;
    if (!isNullOrUndefined(taskSettings.duration) && taskSettings.duration.toLowerCase() === columnName.toLowerCase()) {
      if (!isNullOrUndefined(cellValue) && cellValue !== '') {
        this.selectedSegment[taskSettings.duration] = Number(cellValue);
        var endDate = ganttObj.dataOperation.getEndDate(this.selectedSegment[taskSettings.startDate], Number(cellValue), this.editedRecord.ganttProperties.durationUnit, this.editedRecord.ganttProperties, false);
        endDate = ganttObj.dataOperation.checkEndDate(endDate, this.editedRecord.ganttProperties, false);
        this.selectedSegment[taskSettings.endDate] = endDate;
      }
    }
    if (!isNullOrUndefined(taskSettings.startDate) && taskSettings.startDate.toLowerCase() === columnName.toLowerCase()) {
      if (cellValue !== '') {
        var startDate = this.parent.dateValidationModule.getDateFromFormat(cellValue);
        startDate = this.parent.dateValidationModule.checkStartDate(startDate);
        this.selectedSegment[taskSettings.startDate] = startDate;
        if (!isNullOrUndefined(taskSettings.endDate)) {
          this.selectedSegment.endDate = this.parent.dataOperation.getEndDate(startDate, this.selectedSegment[taskSettings.duration], this.editedRecord.ganttProperties.durationUnit, this.editedRecord.ganttProperties, false);
        }
      }
    }
    if (!isNullOrUndefined(taskSettings.endDate) && taskSettings.endDate.toLowerCase() === columnName.toLowerCase()) {
      if (cellValue !== '') {
        var endDate = this.parent.dateValidationModule.getDateFromFormat(cellValue);
        var dayEndTime = this.parent['getCurrentDayEndTime'](endDate);
        if (endDate.getHours() === 0 && dayEndTime !== 86400) {
          this.parent.dateValidationModule.setTime(dayEndTime, endDate);
        }
        endDate = this.parent.dateValidationModule.checkEndDate(endDate, this.editedRecord.ganttProperties);
        this.selectedSegment[taskSettings.endDate] = endDate;
        this.selectedSegment[taskSettings.duration] = this.parent.dataOperation.getDuration(this.selectedSegment[taskSettings.startDate], this.selectedSegment[taskSettings.endDate], this.editedRecord.ganttProperties.durationUnit, true, false, true);
      }
    }
    if (!isNullOrUndefined(taskSettings.startDate)) {
      this.updateSegmentField(taskSettings.startDate, args, this.selectedSegment);
    }
    if (!isNullOrUndefined(taskSettings.endDate)) {
      this.updateSegmentField(taskSettings.endDate, args, this.selectedSegment);
    }
    if (!isNullOrUndefined(taskSettings.duration)) {
      this.updateSegmentField(taskSettings.duration, args, this.selectedSegment);
    }
  };
  DialogEdit.prototype.getPredecessorModel = function (fields) {
    if (isNullOrUndefined(fields) || fields.length === 0) {
      fields = ['ID', 'Name', 'Type', 'Offset', 'UniqueId'];
    }
    var inputModel = {};
    inputModel.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Normal'
    };
    inputModel.locale = this.parent.locale;
    inputModel.dataSource = [];
    inputModel.rowHeight = this.parent.isAdaptive ? 48 : null;
    inputModel.toolbar = [{
      id: this.parent.element.id + 'DependencyTabContainer' + '_add',
      prefixIcon: 'e-add',
      tooltipText: this.localeObj.getConstant('add'),
      align: 'Right',
      text: this.parent.isAdaptive ? '' : this.localeObj.getConstant('add')
    }, {
      id: this.parent.element.id + 'DependencyTabContainer' + '_delete',
      prefixIcon: 'e-delete',
      tooltipText: this.localeObj.getConstant('delete'),
      align: 'Right',
      text: this.parent.isAdaptive ? '' : this.localeObj.getConstant('delete')
    }];
    var columns = [];
    for (var i = 0; i < fields.length; i++) {
      var column = {};
      if (fields[i].toLowerCase() === 'id') {
        column = {
          field: 'id',
          headerText: this.localeObj.getConstant('id'),
          allowEditing: false,
          width: '70px'
        };
        columns.push(column);
      } else if (fields[i].toLowerCase() === 'name') {
        column = {
          field: 'name',
          headerText: this.localeObj.getConstant('name'),
          editType: 'stringedit',
          width: '250px',
          validationRules: {
            required: true
          }
        };
        columns.push(column);
      } else if (fields[i].toLowerCase() === 'type') {
        column = {
          field: 'type',
          headerText: this.localeObj.getConstant('type'),
          editType: 'dropdownedit',
          dataSource: this.types,
          foreignKeyField: 'id',
          foreignKeyValue: 'text',
          defaultValue: 'FS',
          validationRules: {
            required: true
          },
          width: '150px'
        };
        columns.push(column);
      } else if (fields[i].toLowerCase() === 'offset') {
        column = {
          field: 'offset',
          headerText: this.localeObj.getConstant('offset'),
          editType: 'stringedit',
          defaultValue: this.parent.dataOperation.getDurationString(0, this.beforeOpenArgs.rowData.ganttProperties.durationUnit),
          validationRules: {
            required: true
          },
          width: '100px'
        };
        columns.push(column);
      } else if (fields[i].toLowerCase() === 'uniqueid') {
        column = {
          field: 'uniqueId',
          isPrimaryKey: true,
          visible: false,
          defaultValue: getUid().toString()
        };
        columns.push(column);
      }
    }
    inputModel.columns = columns;
    inputModel.height = this.parent.isAdaptive || this.parent.enableAdaptiveUI ? '100%' : '153px';
    return inputModel;
  };
  DialogEdit.prototype.getResourcesModel = function (fields) {
    var ganttObj = this.parent;
    var resourceSettings = ganttObj.resourceFields;
    if (isNullOrUndefined(fields) || fields.length === 0) {
      fields = [resourceSettings.id, resourceSettings.name, resourceSettings.unit, resourceSettings.group];
    }
    var inputModel = {
      allowFiltering: true,
      treeColumnIndex: -1,
      childMapping: '',
      editSettings: {
        allowEditing: true,
        mode: 'Cell'
      },
      locale: this.parent.locale,
      allowSelection: true,
      rowHeight: this.parent.isAdaptive ? 48 : null,
      filterSettings: {
        type: 'Menu'
      },
      selectionSettings: {
        checkboxOnly: true,
        checkboxMode: 'Default',
        persistSelection: true,
        type: 'Multiple'
      }
    };
    var columns = [{
      type: 'checkbox',
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 60
    }];
    for (var i = 0; i < fields.length; i++) {
      var column = {};
      if (fields[i] === resourceSettings.id) {
        column = {
          field: resourceSettings.id,
          headerText: this.localeObj.getConstant('id'),
          isPrimaryKey: true,
          width: '100px',
          allowEditing: false
        };
        columns.push(column);
      } else if (fields[i] === resourceSettings.name) {
        column = {
          field: resourceSettings.name,
          headerText: this.localeObj.getConstant('name'),
          allowEditing: false
        };
        columns.push(column);
      } else if (fields[i] === resourceSettings.unit) {
        column = {
          field: resourceSettings.unit,
          headerText: this.localeObj.getConstant('unit'),
          editType: 'numericedit',
          edit: {
            params: {
              min: 0
            }
          }
        };
        columns.push(column);
      } else if (fields[i] === resourceSettings.group && !isNullOrUndefined(resourceSettings.group)) {
        column = {
          field: resourceSettings.group,
          headerText: this.localeObj.getConstant('group'),
          allowEditing: false
        };
        columns.push(column);
      }
    }
    inputModel.columns = columns;
    inputModel.height = this.parent.isAdaptive || this.parent.enableAdaptiveUI ? '100%' : '196px';
    return inputModel;
  };
  DialogEdit.prototype.getNotesModel = function (fields) {
    if (isNullOrUndefined(fields) || fields.length === 0) {
      fields = ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor', 'LowerCase', 'UpperCase', '|', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', '|', 'CreateTable', 'CreateLink', '|', 'ClearFormat', 'Print', '|', 'Undo', 'Redo'];
    }
    var inputModel = {
      placeholder: this.localeObj.getConstant('writeNotes'),
      toolbarSettings: {
        items: fields
      },
      height: this.parent.isAdaptive || this.parent.enableAdaptiveUI ? '100%' : 'auto',
      locale: this.parent.locale
    };
    return inputModel;
  };
  DialogEdit.prototype.createDivElement = function (className, id) {
    return createElement('div', {
      className: className,
      id: id
    });
  };
  DialogEdit.prototype.createFormElement = function (className, id) {
    return createElement('form', {
      className: className,
      id: id
    });
  };
  DialogEdit.prototype.createInputElement = function (className, id, fieldName, type) {
    return createElement(type || 'input', {
      className: className,
      attrs: {
        type: 'text',
        id: id,
        name: fieldName,
        title: fieldName
      }
    });
  };
  DialogEdit.prototype.renderTabItems = function () {
    var tabModel = this.beforeOpenArgs.tabModel;
    var isCustomTab = false;
    var items = tabModel.items;
    var index = 0;
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (item.content instanceof HTMLElement) {
        continue;
      } else if (item.content === 'General') {
        item.content = this.renderGeneralTab(item.content);
      } else if (item.content === 'Dependency') {
        if (this.editedRecord.hasChildRecords && !this.parent.allowParentDependency) {
          item.disabled = true;
        }
        item.content = this.renderPredecessorTab(item.content);
      } else if (item.content === 'Resources') {
        item.content = this.renderResourceTab(item.content);
      } else if (item.content === 'Custom' + '' + index) {
        isCustomTab = true;
        item.content = this.renderCustomTab(item.content, isCustomTab);
        index++;
      } else if (item.content === 'Notes') {
        item.content = this.renderNotesTab(item.content);
      } else if (item.content === 'Segments') {
        if (this.editedRecord.hasChildRecords) {
          item.disabled = true;
        }
        item.content = this.renderSegmentsTab(item.content);
      }
    }
  };
  DialogEdit.prototype.segmentGridActionBegin = function (args) {
    var _a, _b;
    var taskFields = this.parent.taskFields;
    var itemName = 'Segments';
    var gridModel = this.beforeOpenArgs[itemName];
    if (args.requestType === 'add' || args.requestType === 'beginEdit' || args.requestType === 'save') {
      var gridData = gridModel.dataSource;
      var selectedItem = getValue('rowData', args);
      var startDate = this.beforeOpenArgs.rowData.ganttProperties.startDate;
      if (!isNullOrUndefined(startDate)) {
        if (args.requestType === 'add') {
          var arg = {};
          var sDate = getValue(this.parent.taskFields.startDate, selectedItem);
          var eDate = this.parent.taskFields.endDate ? getValue(this.parent.taskFields.endDate, selectedItem) : null;
          var duration = void 0;
          if (!isNullOrUndefined(this.parent.taskFields.duration)) {
            duration = getValue(this.parent.taskFields.duration, selectedItem);
          }
          var startDate_1 = !isNullOrUndefined(gridData) && gridData.length > 0 ? !isNullOrUndefined(taskFields.endDate) && !isNullOrUndefined(gridData[0][taskFields.endDate]) ? new Date(getValue(taskFields.endDate, gridData[0]).getTime()) : new Date(getValue(taskFields.startDate, gridData[0]).getTime()) : !isNullOrUndefined(this.beforeOpenArgs.rowData.ganttProperties.startDate) && new Date(this.beforeOpenArgs.rowData.ganttProperties.startDate.getTime());
          startDate_1.setHours(0, 0, 0, 0);
          if (!isNullOrUndefined(gridData) && gridData.length > 0) {
            startDate_1.setDate(startDate_1.getDate() + 2);
          }
          sDate = this.parent.dataOperation.checkStartDate(startDate_1);
          eDate = this.parent.dateValidationModule.getDateFromFormat(sDate);
          var dayEndTime = this.parent['getCurrentDayEndTime'](eDate);
          if (eDate.getHours() === 0 && dayEndTime !== 86400) {
            this.parent.dateValidationModule.setTime(dayEndTime, eDate);
          }
          eDate = !isNullOrUndefined(taskFields.endDate) && !isNullOrUndefined(gridData) && gridData.length <= 0 ? this.beforeOpenArgs.rowData.ganttProperties.endDate : eDate;
          var rowData = this.beforeOpenArgs.rowData.ganttProperties;
          if (sDate.getTime() === eDate.getTime()) {
            duration = 1;
          } else {
            duration = this.parent.dataOperation.getDuration(sDate, eDate, rowData.durationUnit, true, false, true);
          }
          if (!isNullOrUndefined(taskFields['duration'])) {
            arg = (_a = {}, _a[taskFields['startDate']] = sDate, _a[taskFields['endDate']] = eDate, _a[taskFields['duration']] = duration, _a);
          } else {
            arg = (_b = {}, _b[taskFields['startDate']] = sDate, _b[taskFields['endDate']] = eDate, _b);
          }
          args.rowData = arg;
        }
      }
      if (args.requestType === 'save') {
        var dataSource = gridModel.dataSource;
        var taskIdField_1 = this.parent.taskFields.id;
        var newId_1 = dataSource.length;
        while (dataSource.some(function (item) {
          return item[taskIdField_1] === newId_1;
        })) {
          newId_1++;
        }
        if (isNullOrUndefined(args.data[taskIdField_1])) {
          args.data[taskIdField_1] = newId_1;
          args.rowData[taskIdField_1] = newId_1;
        }
      }
      this.selectedSegment = args.rowData;
      // if (args.requestType === 'save') {
      //     // let duration: string = 'duration';
      //     // let tempDuration: Object = this.parent.dataOperation.getDurationValue(args.data[duration]);
      //     // args.data[duration] = getValue('duration', tempDuration);
      //     this.selectedSegment = !isNullOrUndefined(this.editedRecord.ganttProperties.segments[args.rowIndex]) ?
      //         this.editedRecord.ganttProperties.segments[args.rowIndex] : !isNullOrUndefined(gridData[args.rowIndex]) ?
      //             gridData[args.rowIndex] : gridData;
      // }
    }
  };
  DialogEdit.prototype.getDialogTabIndex = function (tabName) {
    var indexValue;
    if (!this.isEdit) {
      this.parent.addDialogFields.map(function (item, index) {
        if (item.type === tabName) {
          indexValue = index;
        }
      });
    } else {
      this.parent.editDialogFields.map(function (item, index) {
        if (item.type === tabName) {
          indexValue = index;
        }
      });
    }
    return indexValue;
  };
  /* eslint-disable-next-line */
  DialogEdit.prototype.setInjected = function (dialogField, allProperty, Grid, toolbar, toolbarCollection, gridModel, columnCollection) {
    if (!isNullOrUndefined(dialogField) && !isNullOrUndefined(dialogField.additionalParams)) {
      allProperty = dialogField.additionalParams;
      for (var i in allProperty) {
        if (Object.prototype.hasOwnProperty.call(allProperty, i)) {
          switch (i) {
            case 'allowFiltering':
              Grid.Inject(Filter$1);
              break;
            case 'allowSorting':
              Grid.Inject(Sort$1);
              break;
            case 'allowPaging':
              Grid.Inject(Page);
              break;
            case 'allowGrouping':
              Grid.Inject(Group);
              break;
            case 'editSettings':
              Grid.Inject(Edit$2);
              break;
            case 'aggregates':
              Grid.Inject(Aggregate);
              break;
            case 'showColumnChooser':
              Grid.Inject(ColumnChooser);
              break;
            case 'showColumnMenu':
              Grid.Inject(ColumnMenu$1);
              break;
            case 'contextMenuItems':
              Grid.Inject(ContextMenu$1);
              break;
            case 'allowResizing':
              Grid.Inject(Resize$1);
              break;
            case 'allowReordering':
              Grid.Inject(Reorder$1);
              break;
            case 'detailTemplate':
              Grid.Inject(DetailRow);
              break;
            case 'allowRowDragAndDrop':
              Grid.Inject(RowDD$1);
              break;
            case 'searchSettings':
              Grid.Inject(Search);
              break;
            case 'selectionSettings':
              Grid.Inject(Selection);
              break;
            case 'enableVirtualization':
              Grid.Inject(VirtualScroll$1);
              break;
            case 'toolbar':
              toolbar = allProperty['toolbar'];
              toolbar.map(function (item) {
                switch (item) {
                  case 'Search':
                    Grid.Inject(Search);
                    break;
                  case 'Print':
                    Grid.Inject(Print);
                    break;
                  case 'PdfExport':
                    Grid.Inject(PdfExport$1);
                    break;
                  case 'ExcelExport':
                    Grid.Inject(ExcelExport$1);
                    break;
                }
              });
              toolbarCollection = gridModel.toolbar;
              Grid.Inject(Toolbar$1);
              break;
            case 'columns':
              columnCollection = gridModel.columns;
              break;
          }
        }
      }
    }
  };
  DialogEdit.prototype.renderSegmentsTab = function (itemName) {
    var _this = this;
    var ganttObj = this.parent;
    var gridModel = this.beforeOpenArgs[itemName];
    gridModel.enableAdaptiveUI = this.parent.enableAdaptiveUI;
    var ganttData = this.beforeOpenArgs.rowData;
    var preData = [];
    if (this.isEdit) {
      preData = isNullOrUndefined(ganttData.taskData[this.parent.taskFields['segments']]) ? [] : ganttData.taskData[this.parent.taskFields['segments']];
    }
    /* eslint-disable-next-line */
    preData.map(function (item, index) {
      if (isNullOrUndefined(item[_this.parent.taskFields['id']])) {
        item[_this.parent.taskFields['id']] = index;
      }
    });
    gridModel.dataSource = preData;
    gridModel.actionBegin = this.segmentGridActionBegin.bind(this);
    var tabIndex = this.getDialogTabIndex('Segments');
    var dialogField;
    if (!this.isEdit) {
      dialogField = this.parent.addDialogFields[tabIndex];
    } else if (this.isEdit) {
      dialogField = this.parent.editDialogFields[tabIndex];
    }
    var allProperty;
    var toolbarCollection = [];
    /* eslint-disable-next-line */
    var columnCollection = [];
    var toolbar;
    if (!isNullOrUndefined(dialogField) && !isNullOrUndefined(dialogField.additionalParams)) {
      allProperty = dialogField.additionalParams;
    }
    this.setInjected(dialogField, allProperty, Grid, toolbar, toolbarCollection, gridModel, columnCollection);
    Grid.Inject(Edit$2, Page, Toolbar$1, ForeignKey);
    gridModel = __assign$2({}, gridModel, allProperty);
    gridModel.toolbar = toolbarCollection.concat(gridModel.toolbar);
    var columnCollections = this.updateColumns(columnCollection, gridModel.columns);
    gridModel.columns = columnCollections;
    var gridObj = new Grid(gridModel);
    var divElement = this.createDivElement('', ganttObj.element.id + '' + itemName + 'TabContainer');
    gridObj.appendTo(divElement);
    return divElement;
  };
  DialogEdit.prototype.renderGeneralTab = function (itemName, isCustomTab) {
    var ganttObj = this.parent;
    /* eslint-disable-next-line */
    var addFields = [];
    var divElement;
    var itemModel = this.beforeOpenArgs[itemName];
    if (isCustomTab) {
      divElement = this.createDivElement('e-edit-form-row', ganttObj.element.id + '' + itemName + 'TabContainer');
    } else {
      divElement = this.createFormElement('e-edit-form-row', ganttObj.element.id + '' + itemName + 'TabContainer');
    }
    var table;
    var tbody;
    if (this.parent.enableAdaptiveUI) {
      divElement.style.height = '100%';
      table = createElement('table', {
        className: 'e-table'
      });
      table.style.width = '100%';
      tbody = createElement('tbody');
    }
    var getId = divElement.id;
    for (var _i = 0, _a = Object.keys(itemModel); _i < _a.length; _i++) {
      var key = _a[_i];
      if (this.parent.columnByField[key].visible === false) {
        continue;
      }
      var column = this.parent.columnByField[key];
      var inputModel = itemModel[key];
      if (this.parent.enableAdaptiveUI) {
        tbody.appendChild(this.renderInputElements(inputModel, column));
      } else {
        divElement.appendChild(this.renderInputElements(inputModel, column));
      }
      addFields.push(key);
    }
    if (this.parent.enableAdaptiveUI) {
      table.appendChild(tbody);
      divElement.appendChild(table);
    }
    if (getId !== divElement.id) {
      divElement.id = getId;
    }
    var tabIndex = this.getDialogTabIndex('General');
    var fields = [];
    if (!this.isEdit && !isNullOrUndefined(this.parent.addDialogFields[tabIndex]) && !isNullOrUndefined(this.parent.addDialogFields[tabIndex].fields)) {
      fields = this.parent.addDialogFields[tabIndex].fields;
    } else if (this.isEdit && !isNullOrUndefined(this.parent.editDialogFields[tabIndex]) && !isNullOrUndefined(this.parent.editDialogFields[tabIndex].fields)) {
      fields = this.parent.editDialogFields[tabIndex].fields;
    }
    if (!isNullOrUndefined(fields)) {
      var templateFields = fields.filter(function (item) {
        return !addFields.includes(item);
      });
      if (!isNullOrUndefined(templateFields)) {
        var template = templateFields;
        for (var i = 0; i <= template.length - 1; i++) {
          var scriptElement = document.getElementById(template[i]);
          if (!isNullOrUndefined(scriptElement)) {
            var templateContent = scriptElement.innerHTML;
            var div = createElement('div');
            div.innerHTML = templateContent;
            divElement.appendChild(div.children[0]);
          }
        }
      }
    }
    return divElement;
  };
  DialogEdit.prototype.isCheckIsDisabled = function (column) {
    var disabled = false;
    var stringOrNumber;
    if (column.allowEditing === false || column.isPrimaryKey || this.parent.readOnly) {
      if (this.parent.customColumns.indexOf(column.field) !== -1) {
        disabled = true;
      } else {
        if (column.field === this.parent.taskFields.baselineStartDate || column.field === this.parent.taskFields.baselineEndDate || column.field === this.parent.taskFields.work || column.field === this.parent.taskFields.type || column.field === this.parent.taskFields.id || column.field === this.parent.taskFields.name || column.field === this.parent.taskFields.duration || column.field === this.parent.taskFields.progress || column.field === this.parent.taskFields.startDate || column.field === this.parent.taskFields.endDate) {
          for (var i = 0; i < this.parent.currentViewData['length']; i++) {
            if (!isNullOrUndefined(this.parent.currentViewData[i].ganttProperties.taskId)) {
              stringOrNumber = this.parent.currentViewData[i].ganttProperties.taskId;
              break;
            }
          }
          if (typeof stringOrNumber === 'string') {
            disabled = false;
          } else {
            disabled = true;
          }
        }
      }
    }
    if (this.isEdit) {
      if (column.field === this.parent.taskFields.id) {
        disabled = true;
      }
      if (this.editedRecord.hasChildRecords) {
        if (column.field === this.parent.taskFields.endDate && (!isNullOrUndefined(this.editedRecord['isManual']) && this.editedRecord['isManual'] === false || this.parent.taskMode === 'Auto') || column.field === this.parent.taskFields.duration || column.field === this.parent.taskFields.progress || column.field === this.parent.taskFields.work || column.field === this.parent.taskFields.type) {
          disabled = true;
        }
      }
    }
    return disabled;
  };
  DialogEdit.prototype.isParentValid = function (data) {
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].uniqueID === this.beforeOpenArgs.rowData['uniqueID']) {
          this.isValidData = false;
          break;
        }
        if (data[i].hasChildRecords) {
          this.isParentValid(data[i].childRecords);
        }
        if (!this.isValidData) {
          break;
        }
      }
    }
    return this.isValidData;
  };
  DialogEdit.prototype.renderPredecessorTab = function (itemName) {
    var _this = this;
    var ganttObj = this.parent;
    var gridModel = this.beforeOpenArgs[itemName];
    var dependencyColumn = this.parent.columnByField[this.parent.taskFields.dependency];
    if (dependencyColumn.allowEditing === false || dependencyColumn.isPrimaryKey || this.parent.readOnly) {
      gridModel.editSettings.allowEditing = false;
      gridModel.editSettings.allowAdding = false;
      gridModel.editSettings.allowDeleting = false;
    }
    var ganttData = this.beforeOpenArgs.rowData;
    var preData = [];
    this.taskNameCollection();
    if (this.isEdit) {
      preData = this.predecessorEditCollection(ganttData);
      this.updatePredecessorDropDownData(ganttData);
    }
    var predecessorLength;
    if (ganttData[this.parent.taskFields.dependency]) {
      predecessorLength = ganttData[this.parent.taskFields.dependency].split(',').length;
    }
    if (this.preTableCollection.length === 0 || this.preTableCollection.length === predecessorLength) {
      gridModel.editSettings.allowAdding = false;
    }
    gridModel.actionComplete = this.gridActionComplete.bind(this);
    gridModel.dataSource = preData;
    gridModel.enableAdaptiveUI = this.parent.enableAdaptiveUI;
    gridModel.actionBegin = this.gridActionBegin.bind(this);
    var columns = gridModel.columns;
    columns[1].edit = {
      write: function (args) {
        if (getValue('requestType', args) === 'add') {
          setValue('rowData.uniqueId', getUid(), args);
        }
        var field = 'name';
        var autoObj = new ComboBox({
          dataSource: new DataManager(_this.idCollection),
          popupHeight: '180px',
          allowCustom: false,
          enableRtl: _this.parent.enableRtl,
          fields: {
            value: 'text'
          },
          value: args.rowData[field],
          change: function (arg) {
            var tr = closest(arg.element, 'tr');
            var idInput = tr.querySelector('#' + _this.parent.element.id + 'DependencyTabContainerid');
            if (idInput) {
              if (!isNullOrUndefined(arg.itemData) && !isNullOrUndefined(arg.item)) {
                idInput.value = arg.itemData.id;
              } else {
                idInput.value = '';
              }
            }
          },
          autofill: true
        });
        autoObj.appendTo(args.element);
      },
      read: function (args) {
        var ej2Instance = args.ej2_instances[0];
        return ej2Instance.value;
      }
    };
    var tabIndex = this.getDialogTabIndex('Dependency');
    var dialogField;
    if (!this.isEdit) {
      dialogField = this.parent.addDialogFields[tabIndex];
    } else if (this.isEdit) {
      dialogField = this.parent.editDialogFields[tabIndex];
    }
    var toolbarCollection = [];
    var allProperty;
    var columnCollection = [];
    var toolbar;
    if (!isNullOrUndefined(dialogField) && !isNullOrUndefined(dialogField.additionalParams)) {
      allProperty = dialogField.additionalParams;
    }
    this.setInjected(dialogField, allProperty, Grid, toolbar, toolbarCollection, gridModel, columnCollection);
    Grid.Inject(Edit$2, Page, Toolbar$1, ForeignKey);
    gridModel = __assign$2({}, gridModel, allProperty);
    gridModel.toolbar = gridModel.toolbar.concat(toolbarCollection);
    var columnCollections = this.updateColumns(columnCollection, gridModel.columns);
    gridModel.columns = columnCollections;
    var gridObj = new Grid(gridModel);
    var divElement = this.createDivElement('e-dependent-div', ganttObj.element.id + '' + itemName + 'TabContainer');
    gridObj.appendTo(divElement);
    return divElement;
  };
  DialogEdit.prototype.updateColumns = function (existingColumns, newColumns) {
    var columnMap = {};
    existingColumns.forEach(function (column) {
      if (typeof column === 'object') {
        columnMap[column.field] = column;
      }
    });
    newColumns.forEach(function (newColumn) {
      if (typeof newColumn === 'object') {
        var field = newColumn.field;
        if (columnMap[field]) {
          Object.assign(columnMap[field], newColumn);
        } else {
          existingColumns.push(newColumn);
        }
      }
    });
    return existingColumns;
  };
  DialogEdit.prototype.gridActionBegin = function (args) {
    var _this = this;
    var itemName = 'Dependency';
    var gridModel = this.beforeOpenArgs[itemName];
    if (args.requestType === 'add' || args.requestType === 'beginEdit') {
      var isEdit_1 = args.requestType === 'add' ? false : true;
      this.idCollection = extend([], [], this.preTableCollection, true);
      var gridData_1 = gridModel.dataSource;
      var _loop_2 = function (i) {
        // eslint-disable-next-line
        this_2.idCollection.forEach(function (data, index) {
          if (data.id === getValue('id', gridData_1[i])) {
            var selectedItem = getValue('rowData', args);
            if (isEdit_1 && getValue('id', selectedItem) === data.id) {
              return;
            }
            _this.idCollection.splice(_this.idCollection.indexOf(data), 1);
          }
        });
      };
      var this_2 = this;
      for (var i = 0; i <= gridData_1.length; i++) {
        _loop_2(i);
      }
    }
  };
  DialogEdit.prototype.gridActionComplete = function (args) {
    if (args.requestType === 'save') {
      var dialogElement = this.parent.editModule.dialogModule.dialog.querySelector('#' + this.parent.element.id + 'DependencyTabContainer');
      if (!isNullOrUndefined(dialogElement)) {
        if (!isNullOrUndefined(args['rows']) && args['rows'].length === this.preTableCollection.length) {
          /* eslint-disable-next-line */
          var gridObj = dialogElement['ej2_instances'][0];
          if (gridObj) {
            gridObj.editSettings.allowAdding = false;
          }
        }
      }
    }
  };
  DialogEdit.prototype.updateResourceCollection = function (args, resourceTreeGridId) {
    if (!isNullOrUndefined(args.data) && Object.keys(args.data).length) {
      var treeGridId = document.querySelector('#' + resourceTreeGridId);
      var resourceTreeGrid = treeGridId.ej2_instances[0];
      if (!isNullOrUndefined(resourceTreeGrid) && resourceTreeGrid.getSelectedRecords().length > 0) {
        var tempRecords = resourceTreeGrid.getSelectedRecords();
        var index = void 0;
        var selectedItems = [];
        for (index = 0; index < tempRecords.length; index++) {
          var record = tempRecords[index];
          if (!isNullOrUndefined(record.taskData) && !isNullOrUndefined(record.taskData[this.parent.resourceFields.unit]) && !isNullOrUndefined(args.data[this.parent.resourceFields.unit]) && record.taskData[this.parent.resourceFields.id] === args.data[this.parent.resourceFields.id]) {
            record.taskData[this.parent.resourceFields.unit] = args.data[this.parent.resourceFields.unit];
          }
          selectedItems.push(tempRecords[index].taskData);
        }
        this.ganttResources = extend([], selectedItems);
      } else {
        this.ganttResources = [];
      }
    } else {
      this.ganttResources = [];
    }
  };
  DialogEdit.prototype.renderResourceTab = function (itemName) {
    var _this = this;
    var ganttObj = this.parent;
    var resourceSettings = ganttObj.resourceFields;
    var ganttData = this.beforeOpenArgs.rowData;
    if ((this.beforeOpenArgs.requestType === 'beforeOpenEditDialog' && !isNullOrUndefined(this.editedRecord[this.parent.taskFields.resourceInfo]) || this.beforeOpenArgs.requestType === 'beforeOpenAddDialog' && !isNullOrUndefined(this.editedRecord[this.parent.taskFields.resourceInfo])) && typeof this.editedRecord[this.parent.taskFields.resourceInfo] === 'object') {
      this.parent.setRecordValue('resourceInfo', this.parent.dataOperation.setResourceInfo(this.editedRecord), ganttData.ganttProperties, true);
    }
    var rowResource = ganttData.ganttProperties.resourceInfo;
    var inputModel = this.beforeOpenArgs[itemName];
    inputModel.enableAdaptiveUI = this.parent.enableAdaptiveUI;
    var resourceTreeGridId = ganttObj.element.id + '' + itemName + 'TabContainer';
    var resourceData = [];
    if (this.parent.viewType === 'ResourceView') {
      for (var i = 0; i < ganttObj.currentViewData.length; i++) {
        for (var j = 0; j < ganttObj.resources.length; j++) {
          if (ganttObj.currentViewData[i][ganttObj.taskFields.id] === ganttObj.resources[j][resourceSettings.id] && (ganttObj.currentViewData[i].hasChildRecords || isNullOrUndefined(ganttObj.currentViewData[i].parentItem))) {
            resourceData.push(extend([], [], [ganttObj.resources[j]], true)[0]);
          }
        }
      }
    } else {
      resourceData = extend([], [], ganttObj.resources, true);
    }
    this.parent.dataOperation.updateResourceUnit(resourceData);
    if (!isNullOrUndefined(rowResource)) {
      var count = void 0;
      var rowResourceLength = rowResource.length;
      var index = void 0;
      var resourceDataLength = resourceData.length;
      for (count = 0; count < rowResourceLength; count++) {
        for (index = 0; index < resourceDataLength; index++) {
          if (rowResource[count][resourceSettings.id] === resourceData[index][resourceSettings.id]) {
            resourceData[index][resourceSettings.unit] = rowResource[count][resourceSettings.unit];
          }
        }
      }
    }
    inputModel.dataSource = resourceData;
    var resourceInfo = ganttData.ganttProperties.resourceInfo;
    if (this.isEdit && !isNullOrUndefined(resourceInfo)) {
      for (var i = 0; i < resourceInfo.length; i++) {
        this.ganttResources.push(resourceInfo[i]);
      }
    } else if (!this.isEdit && !isNullOrUndefined(resourceInfo)) {
      for (var i = 0; i < resourceInfo.length; i++) {
        this.ganttResources.push(resourceInfo[i]);
      }
    }
    /* eslint-disable-next-line */
    inputModel.actionBegin = function (args) {
      if (args.rowData && args.columnName === _this.parent.resourceFields.unit && _this.editedRecord.ganttProperties.resourceInfo) {
        for (var i = 0; i < _this.editedRecord.ganttProperties.resourceInfo.length; i++) {
          if (_this.editedRecord.ganttProperties.resourceInfo[i][_this.parent.resourceFields.id] === args.rowData[_this.parent.resourceFields.id]) {
            _this.editedRecord.ganttProperties.resourceInfo[i][_this.parent.resourceFields.unit] = args.value;
          }
        }
      }
    };
    inputModel.rowSelected = function (args) {
      _this.updateResourceCollection(args, resourceTreeGridId);
      _this.currentResources = _this.ganttResources;
    };
    inputModel.rowDeselected = function (args) {
      _this.updateResourceCollection(args, resourceTreeGridId);
      _this.currentResources = _this.ganttResources;
    };
    var divElement = this.createDivElement('e-resource-div', resourceTreeGridId);
    var tabIndex = this.getDialogTabIndex('Resources');
    var dialogField;
    if (!this.isEdit) {
      dialogField = this.parent.addDialogFields[tabIndex];
    } else if (this.isEdit) {
      dialogField = this.parent.editDialogFields[tabIndex];
    }
    var allProperty;
    /* eslint-disable-next-line */
    var columnCollection = [];
    var toolbars;
    if (!isNullOrUndefined(dialogField) && !isNullOrUndefined(dialogField.additionalParams)) {
      allProperty = dialogField.additionalParams;
      for (var i in allProperty) {
        if (Object.prototype.hasOwnProperty.call(allProperty, i)) {
          switch (i) {
            case 'allowFiltering':
              TreeGrid.Inject(Filter$2);
              break;
            case 'allowSorting':
              TreeGrid.Inject(Sort$2);
              break;
            case 'allowPaging':
              TreeGrid.Inject(Page$1);
              break;
            case 'editSettings':
              TreeGrid.Inject(Edit$1);
              break;
            case 'aggregates':
              TreeGrid.Inject(Aggregate$1);
              break;
            case 'showColumnChooser':
              TreeGrid.Inject(Aggregate$1);
              break;
            case 'showColumnMenu':
              TreeGrid.Inject(ColumnMenu$1);
              break;
            case 'contextMenuItems':
              TreeGrid.Inject(ContextMenu$1);
              break;
            case 'allowResizing':
              TreeGrid.Inject(Resize$2);
              break;
            case 'allowReordering':
              TreeGrid.Inject(Reorder$2);
              break;
            case 'detailTemplate':
              TreeGrid.Inject(DetailRow);
              break;
            case 'allowRowDragAndDrop':
              TreeGrid.Inject(RowDD$2);
              break;
            case 'searchSettings':
              TreeGrid.Inject(Search);
              break;
            case 'selectionSettings':
              TreeGrid.Inject(Selection$2);
              break;
            case 'toolbar':
              toolbars = allProperty['toolbar'];
              toolbars.map(function (item) {
                switch (item) {
                  case 'Search':
                    TreeGrid.Inject(Search);
                    break;
                  case 'Print':
                    TreeGrid.Inject(Print);
                    break;
                  case 'PdfExport':
                    TreeGrid.Inject(PdfExport$1);
                    break;
                  case 'ExcelExport':
                    TreeGrid.Inject(ExcelExport$1);
                    break;
                }
              });
              if (!isNullOrUndefined(toolbars)) {
                inputModel.toolbar = [];
                inputModel.toolbar = inputModel.toolbar.concat(toolbars);
              }
              TreeGrid.Inject(Toolbar$2);
              break;
            case 'enableVirtualization':
              TreeGrid.Inject(VirtualScroll$1);
              break;
            case 'columns':
              columnCollection = inputModel.columns;
              break;
          }
        }
      }
    }
    TreeGrid.Inject(Selection$2, Filter$2, Edit$1, VirtualScroll$1, Toolbar$2);
    inputModel = __assign$2({}, inputModel, allProperty);
    var columnCollections = this.updateColumns(columnCollection, inputModel.columns);
    inputModel.columns = columnCollections;
    var isColumnMenu = false;
    if (inputModel.showColumnMenu) {
      isColumnMenu = inputModel.showColumnMenu;
      inputModel.showColumnMenu = false;
    }
    var treeGridObj = new TreeGrid(inputModel);
    var resourceColumn = this.parent.columnByField[this.parent.taskFields.resourceInfo];
    if (resourceColumn.allowEditing === false || resourceColumn.isPrimaryKey || this.parent.readOnly) {
      treeGridObj.allowSelection = false;
      treeGridObj.allowFiltering = false;
      treeGridObj.editSettings.allowEditing = false;
    }
    treeGridObj.dataBound = function () {
      if (isColumnMenu) {
        treeGridObj.showColumnMenu = true;
      }
      if (_this.parent.editDialogFields.length >= 1 && _this.parent.editDialogFields[0].type === 'Resources') {
        var id = _this.parent.element.id + 'ResourcesTabContainer';
        _this.resourceSelection(id);
      }
    };
    treeGridObj.appendTo(divElement);
    return divElement;
  };
  DialogEdit.prototype.resourceSelection = function (id) {
    var _this = this;
    var resourceTreeGrid = document.querySelector('#' + id).ej2_instances[0];
    this.parent['triggeredColumnName'] = '';
    var currentViewData = resourceTreeGrid.getCurrentViewRecords();
    var resources = this.ganttResources;
    if (resources && resources.length > 0) {
      currentViewData.forEach(function (data, index) {
        for (var i = 0; i < resources.length; i++) {
          if (data.taskData[_this.parent.resourceFields.id] === resources[i][_this.parent.resourceFields.id] && !isNullOrUndefined(resourceTreeGrid.selectionModule) && resourceTreeGrid.getSelectedRowIndexes().indexOf(index) === -1) {
            resourceTreeGrid.selectRow(index);
          }
        }
      });
    }
    if (this.editedRecord.ganttProperties.taskType !== 'FixedUnit') {
      this.parent.dataOperation.updateUnitWithWork(this.editedRecord);
    }
  };
  DialogEdit.prototype.renderCustomTab = function (itemName, isCustomTab) {
    return this.renderGeneralTab(itemName, isCustomTab);
  };
  DialogEdit.prototype.renderNotesTab = function (itemName) {
    var ganttObj = this.parent;
    var inputModel = this.beforeOpenArgs[itemName];
    inputModel.enableHtmlSanitizer = this.parent.enableHtmlSanitizer;
    var ganttProp = this.editedRecord.ganttProperties;
    var divElement = this.createDivElement('', ganttObj.element.id + '' + itemName + 'TabContainer');
    var tabIndex = this.getDialogTabIndex('Notes');
    var dialogField;
    var toolbarCollection = [];
    if (!this.isEdit) {
      dialogField = this.parent.addDialogFields[tabIndex];
    } else if (this.isEdit) {
      dialogField = this.parent.editDialogFields[tabIndex];
    }
    var allProperty;
    if (!isNullOrUndefined(dialogField) && !isNullOrUndefined(dialogField.additionalParams)) {
      allProperty = dialogField.additionalParams;
      for (var i in allProperty) {
        if (Object.prototype.hasOwnProperty.call(allProperty, i)) {
          switch (i) {
            case 'toolbarSettings':
              for (var j = 0; j < allProperty['toolbarSettings'].items.length; j++) {
                /* eslint-disable-next-line */
                var key = allProperty['toolbarSettings'].items[j];
                if (key) {
                  switch (key) {
                    case 'Image':
                      RichTextEditor.Inject(Image);
                      break;
                    case 'CreateTable':
                      RichTextEditor.Inject(Table);
                      break;
                    case 'EmojiPicker':
                      RichTextEditor.Inject(EmojiPicker);
                      break;
                    case 'FileManager':
                      RichTextEditor.Inject(FileManager);
                      break;
                    case 'FormatPainter':
                      RichTextEditor.Inject(FormatPainter);
                      break;
                  }
                }
              }
              if (!isNullOrUndefined(allProperty['toolbarSettings'].items)) {
                toolbarCollection = inputModel.toolbarSettings.items;
              }
              RichTextEditor.Inject(Toolbar$3);
              break;
            case 'editorMode':
              RichTextEditor.Inject(MarkdownEditor);
              break;
          }
        }
      }
    }
    RichTextEditor.Inject(Toolbar$3, Link, HtmlEditor, QuickToolbar, Count, Table);
    inputModel.value = ganttProp.notes;
    var notesColumn = this.parent.columnByField[this.parent.taskFields.notes];
    if (notesColumn.allowEditing === false || notesColumn.isPrimaryKey || this.parent.readOnly) {
      inputModel.enabled = false;
    }
    inputModel = __assign$2({}, inputModel, allProperty);
    inputModel.toolbarSettings.items = toolbarCollection.concat(inputModel.toolbarSettings.items);
    var rteObj = new RichTextEditor(inputModel);
    rteObj.appendTo(divElement);
    return divElement;
  };
  DialogEdit.prototype.renderInputElements = function (inputModel, column) {
    var _this = this;
    var ganttId = this.parent.element.id;
    var ganttData = this.editedRecord;
    var divElement = this.createDivElement('e-edit-form-column');
    var inputElement;
    var tr;
    var td;
    if (this.parent.enableAdaptiveUI) {
      tr = createElement('tr');
      td = createElement('td');
      divElement.style.width = '100%';
    }
    var editArgs = {
      column: column,
      data: ganttData
    };
    if (!isNullOrUndefined(column.edit) && isNullOrUndefined(column.edit.params)) {
      var create = column.edit.create;
      if (typeof create === 'string') {
        create = getObject(create, window);
        inputElement = create(editArgs);
      } else {
        inputElement = column.edit.create(editArgs);
      }
      inputElement.className = '';
      inputElement.setAttribute('type', 'text');
      inputElement.setAttribute('id', ganttId + '' + column.field);
      inputElement.setAttribute('name', column.field);
      inputElement.setAttribute('title', column.field);
      divElement.appendChild(inputElement);
    } else {
      inputElement = this.createInputElement('', ganttId + '' + column.field, column.field);
      divElement.appendChild(inputElement);
    }
    inputModel.enabled = !isNullOrUndefined(inputModel.enabled) ? inputModel.enabled : !this.isCheckIsDisabled(column);
    if (column.field === this.parent.taskFields.duration) {
      if (!isNullOrUndefined(column.valueAccessor)) {
        if (typeof column.valueAccessor === 'string') {
          var valueAccessor = getObject(column.valueAccessor, window);
          inputModel.value = valueAccessor(column.field, ganttData, column);
        } else {
          inputModel.value = column.valueAccessor(column.field, ganttData, column);
        }
      } else if (isNullOrUndefined(column.edit)) {
        var ganttProp = ganttData.ganttProperties;
        inputModel.value = this.parent.dataOperation.getDurationString(ganttProp.duration, ganttProp.durationUnit);
      }
    } else {
      if (column.editType === 'booleanedit') {
        if (ganttData[column.field] === true) {
          inputModel.checked = true;
        } else {
          inputModel.checked = false;
        }
      } else {
        if (!this.parent.taskFields[column.field] && column.editType === 'numericedit' && (ganttData[column.field] === '' || ganttData[column.field] === 0)) {
          inputModel.value = 0;
        } else {
          inputModel.value = ganttData[column.field];
        }
      }
    }
    if (!isNullOrUndefined(column.edit) && isNullOrUndefined(column.edit.params)) {
      var write = column.edit.write;
      var inputObj = void 0;
      if (typeof write === 'string') {
        write = getObject(write, window);
        inputObj = write({
          column: column,
          rowData: ganttData,
          element: inputElement
        });
      } else {
        inputObj = column.edit.write({
          column: column,
          rowData: ganttData,
          element: inputElement
        });
      }
      if (column.field === this.parent.taskFields.duration) {
        inputObj.change = function (args) {
          _this.validateScheduleFields(args, column, _this.parent);
        };
      }
    } else {
      var inputObj = new this.inputs[column.editType](inputModel);
      inputObj.appendTo(inputElement);
    }
    if (this.parent.enableAdaptiveUI) {
      td.appendChild(divElement);
      tr.appendChild(td);
      return tr;
    } else {
      return divElement;
    }
  };
  DialogEdit.prototype.taskNameCollection = function () {
    var flatData = this.parent.flatData;
    this.preTaskIds = [];
    this.preTableCollection = [];
    for (var i = 0; i < flatData.length; i++) {
      var data = flatData[i];
      if (this.parent.allowParentDependency) {
        var currentFlatData = data;
        if (data.parentUniqueID === this.beforeOpenArgs.rowData['uniqueID']) {
          this.isValidData = false;
        } else {
          do {
            if (currentFlatData.parentItem) {
              currentFlatData = this.parent.flatData[this.parent.ids.indexOf(currentFlatData.parentItem.taskId)];
              if (currentFlatData.uniqueID === this.beforeOpenArgs.rowData['uniqueID']) {
                this.isValidData = false;
                break;
              }
            }
          } while (currentFlatData.parentItem);
        }
        if (data.hasChildRecords && this.isValidData) {
          this.isValidData = this.isParentValid(data.childRecords);
        }
        if (!this.isValidData) {
          this.isValidData = true;
          continue;
        }
      } else {
        if (data.hasChildRecords) {
          continue;
        }
      }
      var taskId = this.parent.viewType === 'ResourceView' ? data.ganttProperties.taskId.toString() : data.ganttProperties.rowUniqueID.toString();
      var tempObject = {
        id: taskId,
        text: taskId + '-' + data.ganttProperties.taskName,
        value: taskId
      };
      this.preTaskIds.push(tempObject.id);
      this.preTableCollection.push(tempObject);
    }
  };
  DialogEdit.prototype.predecessorEditCollection = function (ganttData) {
    var preDataCollection = [];
    var ganttProp = ganttData.ganttProperties;
    if (this.isEdit && !isNullOrUndefined(this.parent.taskFields.dependency) && !isNullOrUndefined(ganttData) && !isNullOrUndefined(ganttProp.predecessor)) {
      var predecessor = ganttProp.predecessor;
      var idCollection = this.preTableCollection;
      for (var i = 0; i < predecessor.length; i++) {
        var from = predecessor[i].from.toString();
        var preData = {};
        var taskID = this.parent.viewType === 'ResourceView' ? ganttProp.taskId : ganttProp.rowUniqueID;
        if (taskID.toString() !== from) {
          preData.id = from;
          for (var index = 0; index < idCollection.length; index++) {
            if (idCollection[index].value === from) {
              preData.name = idCollection[index].text;
              break;
            }
          }
          preData.type = predecessor[i].type;
          var offset = predecessor[i].offset;
          var offsetUnit = predecessor[i].offsetUnit;
          preData.offset = this.parent.dataOperation.getDurationString(offset, offsetUnit);
          preData.uniqueId = getUid();
          preDataCollection.push(preData);
        }
      }
    }
    return preDataCollection;
  };
  DialogEdit.prototype.updatePredecessorDropDownData = function (ganttData) {
    var index = -1;
    var id = this.parent.viewType === 'ResourceView' ? ganttData.ganttProperties.taskId.toString() : ganttData.ganttProperties.rowUniqueID.toString();
    index = this.preTaskIds.indexOf(id);
    this.preTableCollection.splice(index, 1);
    this.preTaskIds.splice(index, 1);
    this.validSuccessorTasks(ganttData, this.preTaskIds, this.preTableCollection);
  };
  DialogEdit.prototype.validSuccessorTasks = function (data, ids, idCollection) {
    var _this = this;
    var ganttProp = data.ganttProperties;
    if (ganttProp.predecessor && ganttProp.predecessor.length > 0) {
      var predecessor = ganttProp.predecessor;
      var fromId_1 = this.parent.viewType === 'ResourceView' ? ganttProp.taskId.toString() : ganttProp.rowUniqueID.toString();
      predecessor.forEach(function (item) {
        if (item.from.toString() === fromId_1) {
          var toId = item.to;
          var idIndex = -1;
          idIndex = ids.indexOf(toId);
          if (idIndex > -1) {
            ids.splice(idIndex, 1);
            idCollection.splice(idIndex, 1);
          }
          var ganttData = _this.parent.connectorLineModule.getRecordByID(toId);
          var isIdInclude = true;
          for (var _i = 0, _a = _this.processedId; _i < _a.length; _i++) {
            var item_1 = _a[_i];
            if (item_1.id === ganttData.ganttProperties.taskId) {
              if (Array.isArray(item_1.value) && Array.isArray(ganttData.ganttProperties.predecessor)) {
                if (item_1.value.length === ganttData.ganttProperties.predecessor.length) {
                  var arraysMatch = true;
                  for (var i = 0; i < item_1.value.length; i++) {
                    if (item_1.value[i] !== ganttData.ganttProperties.predecessor[i]) {
                      arraysMatch = false;
                      break;
                    }
                  }
                  if (arraysMatch) {
                    isIdInclude = false;
                    break;
                  }
                }
              }
            }
          }
          if (isIdInclude) {
            _this.processedId.push({
              id: ganttData.ganttProperties.taskId,
              value: ganttData.ganttProperties.predecessor
            });
            _this.validSuccessorTasks(ganttData, ids, idCollection);
          }
        }
      });
    }
  };
  DialogEdit.prototype.getPredecessorType = function () {
    var typeText = [this.parent.getPredecessorTextValue('SS'), this.parent.getPredecessorTextValue('SF'), this.parent.getPredecessorTextValue('FS'), this.parent.getPredecessorTextValue('FF')];
    var types = [{
      id: 'FS',
      text: typeText[2],
      value: typeText[2]
    }, {
      id: 'FF',
      text: typeText[3],
      value: typeText[3]
    }, {
      id: 'SS',
      text: typeText[0],
      value: typeText[0]
    }, {
      id: 'SF',
      text: typeText[1],
      value: typeText[1]
    }];
    return types;
  };
  DialogEdit.prototype.initiateDialogSave = function () {
    if (this.isEdit) {
      this.parent.initiateEditAction(true);
    } else {
      this.addedRecord = {};
    }
    if (this.currentResources) {
      this.currentResources = null;
    }
    if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent']('Edit')) {
      if (this.parent.undoRedoModule['redoEnabled']) {
        this.parent.undoRedoModule['disableRedo']();
      }
      if (this.isEdit) {
        this.parent.undoRedoModule['createUndoCollection']();
        var action = {};
        action['action'] = 'DialogEdit';
        action['modifiedRecords'] = [];
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = action;
        if (this.parent.viewType === 'ResourceView') {
          var isValid = false;
          if (!this.rowData.ganttProperties.resourceInfo) {
            if (this.ganttResources.length > 0) {
              isValid = true;
            }
          } else if (this.ganttResources.length !== this.rowData.ganttProperties.resourceInfo.length) {
            isValid = true;
          } else {
            for (var i = 0; i < this.rowData.ganttProperties.resourceInfo.length; i++) {
              if (this.ganttResources[i][this.parent.resourceFields.id] !== this.rowData.ganttProperties.resourceInfo[i][this.parent.resourceFields.id]) {
                isValid = true;
                break;
              }
            }
          }
          if (isValid) {
            var indexes = {};
            indexes['deletedIndexes'] = [];
            var id_1 = 'T' + this.rowData.ganttProperties.taskId;
            var rowItems_1 = [];
            this.parent.taskIds.reduce(function (e, i) {
              if (i === id_1) {
                if (this.ganttResources.length === 0) {
                  rowItems_1.push(this.parent.flatData[this.parent.taskIds.indexOf(i)]);
                } else {
                  var parent_1 = this.parent.getTaskByUniqueID(this.parent.flatData[this.parent.taskIds.indexOf(i)].parentUniqueID);
                  for (var j = 0; j < this.ganttResources.length; j++) {
                    if (parent_1.ganttProperties.taskId !== this.ganttResources[j][this.parent.resourceFields.id] && rowItems_1.indexOf(this.parent.flatData[this.parent.taskIds.indexOf(i)]) === -1) {
                      rowItems_1.push(this.parent.flatData[this.parent.taskIds.indexOf(i)]);
                    }
                  }
                }
              }
            }.bind(this), []);
            this.parent.undoRedoModule['findPosition'](extend([], [], rowItems_1, true), indexes, 'deletedIndexes');
            if (this['indexes']) {
              this['indexes']['deletedIndexes'][this['indexes']['deletedIndexes'].length] = indexes['deletedIndexes'][0];
            } else {
              this['indexes'] = indexes;
            }
          }
        }
      }
    }
    this.parent['updateDuration'] = true;
    var ganttObj = this.parent;
    var tabModel = this.beforeOpenArgs.tabModel;
    var items = tabModel.items;
    for (var i = 0; i < items.length; i++) {
      var element = items[i].content;
      var id = element.getAttribute('id');
      if (!isNullOrUndefined(id) || id !== '') {
        id = id.replace(ganttObj.element.id, '');
        id = id.replace('TabContainer', '');
        if (id === 'General') {
          this.updateGeneralTab(element, false);
        } else if (id === 'Dependency') {
          this.isFromDialogPredecessor = true;
          this.updatePredecessorTab(element);
          this.isFromDialogPredecessor = false;
        } else if (id === 'Notes') {
          this.updateNotesTab(element);
        } else if (id === 'Resources') {
          this.updateResourceTab(element);
        } else if (id.indexOf('Custom') !== -1) {
          this.updateCustomTab(element);
        } else if (id === 'Segments') {
          this.updateSegmentsData(element);
        }
      }
    }
    if (!this.disableUndo && this.parent.undoRedoModule) {
      this.parent.undoRedoModule['getUndoCollection'].splice(this.parent.undoRedoModule['getUndoCollection'].length - 1, 1);
      if (this.parent.toolbarModule && this.parent.undoRedoModule['getUndoCollection'].length === 0) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], false);
      }
      this.parent['totalUndoAction']--;
    }
    if (this.isEdit) {
      /**
       * If any update on edited task do it here
       */
      this.parent.editModule['editedRecord'] = this.rowData;
      this.parent.dataOperation.updateWidthLeft(this.rowData);
      var editArgs = {
        data: this.rowData,
        action: 'DialogEditing'
      };
      this.parent.editModule.initiateUpdateAction(editArgs);
    } else {
      this.parent.editModule['editedRecord'] = this.addedRecord;
      if (this.parent.viewType === 'ResourceView') {
        var newRecords = extend({}, this.addedRecord, true);
        if (newRecords[this.parent.taskFields.resourceInfo].length) {
          for (var i = 0; i < newRecords[this.parent.taskFields.resourceInfo].length; i++) {
            var id = newRecords[this.parent.taskFields.resourceInfo][i].toString();
            var parentRecordIndex = this.parent.getTaskIds().indexOf('R' + id.toString());
            if (parentRecordIndex !== -1) {
              this.parent.editModule.addRecord(this.addedRecord, 'Child', parentRecordIndex);
              break;
            }
          }
        } else {
          this.parent.editModule.addRecord(this.addedRecord, 'Bottom');
        }
      } else {
        this.parent.editModule.addRecord(this.addedRecord, this.parent.editSettings.newRowPosition, this.parent.selectedRowIndex);
      }
    }
    return true;
  };
  DialogEdit.prototype.compareObjects = function (prevSegments, currentSegments) {
    if (!isNullOrUndefined(prevSegments)) {
      return prevSegments.every(function (obj1, index) {
        var obj2 = currentSegments[index];
        var key = Object.keys(obj1);
        for (var i = 0; i < key.length; i++) {
          if (key[i] === 'startDate' || key[i] === 'endDate') {
            if (obj1[key[i]].getTime() !== obj2[key[i]].getTime()) {
              return true;
            }
          } else if (key[i] === 'duration' && obj1[key[i]] !== obj2[key[i]]) {
            return true;
          }
        }
        return false;
      });
    }
    return false;
  };
  DialogEdit.prototype.updateSegmentTaskData = function (dataSource) {
    var taskSettings = this.parent.taskFields;
    if (!this.isEdit) {
      this.addedRecord[taskSettings.segments] = dataSource;
    } else {
      var prevSegments = this.rowData.ganttProperties.segments;
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.segments, dataSource, this.rowData);
      var currentSegments = this.parent.dataOperation.setSegmentsInfo(this.rowData, true);
      if (this.parent.enableUndoRedo && prevSegments && currentSegments && prevSegments.length !== currentSegments.length || !prevSegments && currentSegments || prevSegments && !currentSegments || this.compareObjects(prevSegments, currentSegments)) {
        this.disableUndo = true;
      }
      this.parent.setRecordValue('segments', currentSegments, this.rowData.ganttProperties, true);
      this.parent.setRecordValue('segments', this.parent.dataOperation.setSegmentsInfo(this.rowData, false), this.rowData.ganttProperties, true);
      if (dataSource.length <= 0) {
        this.validateDuration(this.rowData);
      }
    }
  };
  DialogEdit.prototype.updateSegmentsData = function (segmentForm) {
    var gridObj = segmentForm.ej2_instances[0];
    var isEdit = gridObj.isEdit;
    var dataSource;
    if (gridObj.isEdit) {
      gridObj.endEdit();
    }
    if (isEdit && gridObj.currentViewData.length !== gridObj.dataSource['length']) {
      dataSource = gridObj.dataSource;
    } else {
      dataSource = gridObj.currentViewData;
    }
    this.updateSegmentTaskData(dataSource);
  };
  DialogEdit.prototype.updateGeneralTab = function (generalForm, isCustom) {
    var ganttObj = this.parent;
    var childNodes = generalForm.childNodes;
    var tasksData = {};
    if (!this.isEdit) {
      tasksData = this.addedRecord;
    }
    for (var i = 0; i < childNodes.length; i++) {
      var div = childNodes[i];
      var inputElement = div.querySelector('input[id^="' + ganttObj.element.id + '"]') || div.querySelector('textarea[id^="' + ganttObj.element.id + '"]');
      if (inputElement) {
        var fieldName = inputElement.id.replace(ganttObj.element.id, '');
        var controlObj = div.querySelector('#' + ganttObj.element.id + fieldName).ej2_instances[0];
        if (this.parent.columnByField[this.parent.taskFields.id].editType === 'stringedit' && fieldName === this.parent.taskFields.id) {
          var valueString = controlObj.value.toString();
          controlObj.value = valueString;
        }
        var column = ganttObj.columnByField[fieldName];
        if (fieldName === this.parent.taskFields.duration ? parseInt(this.rowData[fieldName], 10) !== parseInt(controlObj.value, 10) : this.rowData[fieldName] !== controlObj.value) {
          this.disableUndo = true;
        }
        if (!isNullOrUndefined(column) && !isNullOrUndefined(column.edit) && isNullOrUndefined(column.edit.params)) {
          var read = column.edit.read;
          if (typeof read === 'string') {
            read = getObject(read, window);
            tasksData[fieldName] = read(inputElement, controlObj.value);
          } else {
            tasksData[fieldName] = column.edit.read(inputElement, controlObj.value);
          }
        } else if (!isNullOrUndefined(column) && column.editType === 'booleanedit') {
          if (inputElement instanceof HTMLInputElement && inputElement.checked === true) {
            tasksData[fieldName] = true;
          } else {
            tasksData[fieldName] = false;
          }
        } else {
          if (fieldName === this.parent.taskFields.duration) {
            var numericValue = parseFloat(String(controlObj.value));
            tasksData[fieldName] = numericValue;
          } else {
            if (this.parent.weekWorkingTime.length > 0 && controlObj.value && (fieldName === this.parent.taskFields.startDate || fieldName === this.parent.taskFields.baselineStartDate)) {
              var sDate = fieldName === this.parent.taskFields.startDate ? this.beforeOpenArgs.rowData['ganttProperties'].startDate : this.beforeOpenArgs.rowData['ganttProperties'].baselineStartDate;
              var prevDay = this.parent['getStartTime'](sDate);
              if (prevDay / 3600 === sDate.getHours()) {
                var dayStartTime = this.parent['getStartTime'](controlObj.value);
                this.parent.dataOperation.setTime(dayStartTime, controlObj.value);
                tasksData[fieldName] = controlObj.value;
              } else {
                tasksData[fieldName] = controlObj.value;
              }
            } else {
              tasksData[fieldName] = controlObj.value;
            }
          }
          if (this.parent.enableHtmlSanitizer && typeof controlObj.value === 'string') {
            controlObj.value = SanitizeHtmlHelper.sanitize(controlObj.value);
            tasksData[fieldName] = controlObj.value;
          }
        }
      }
    }
    if (this.isEdit) {
      if (!isCustom) {
        this.updateScheduleProperties(this.editedRecord, this.rowData);
      }
      ganttObj.editModule.validateUpdateValues(tasksData, this.rowData, true);
    }
  };
  DialogEdit.prototype.updateScheduleProperties = function (fromRecord, toRecord) {
    this.parent.setRecordValue('startDate', fromRecord.ganttProperties.startDate, toRecord.ganttProperties, true);
    this.parent.setRecordValue('endDate', fromRecord.ganttProperties.endDate, toRecord.ganttProperties, true);
    this.parent.setRecordValue('duration', fromRecord.ganttProperties.duration, toRecord.ganttProperties, true);
    this.parent.setRecordValue('durationUnit', fromRecord.ganttProperties.durationUnit, toRecord.ganttProperties, true);
    this.parent.setRecordValue('work', fromRecord.ganttProperties.work, toRecord.ganttProperties, true);
    this.parent.setRecordValue('type', fromRecord.ganttProperties.taskType, toRecord.ganttProperties, true);
    this.parent.setRecordValue('taskType', fromRecord.ganttProperties.taskType, toRecord.ganttProperties, true);
    this.parent.setRecordValue('resourceNames', fromRecord.ganttProperties.resourceNames, toRecord.ganttProperties, true);
    this.parent.setRecordValue('resourceInfo', fromRecord.ganttProperties.resourceInfo, toRecord.ganttProperties, true);
    if (!isNullOrUndefined(this.parent.taskFields.startDate)) {
      this.parent.dataOperation.updateMappingData(toRecord, this.parent.taskFields.startDate);
    }
    if (!isNullOrUndefined(this.parent.taskFields.endDate)) {
      this.parent.dataOperation.updateMappingData(toRecord, this.parent.taskFields.endDate);
    }
    if (!isNullOrUndefined(this.parent.taskFields.duration)) {
      this.parent.dataOperation.updateMappingData(toRecord, this.parent.taskFields.duration);
      this.parent.setRecordValue('durationUnit', fromRecord.ganttProperties.durationUnit, this.rowData, true);
      if (this.rowData.ganttProperties.duration === 0) {
        this.parent.setRecordValue('isMilestone', true, toRecord.ganttProperties, true);
      } else {
        this.parent.setRecordValue('isMilestone', false, this.rowData.ganttProperties, true);
      }
    }
    if (!isNullOrUndefined(this.parent.taskFields.work)) {
      this.parent.dataOperation.updateMappingData(this.rowData, this.parent.taskFields.work);
    }
    if (!isNullOrUndefined(this.parent.taskFields.manual)) {
      this.parent.dataOperation.updateMappingData(this.rowData, this.parent.taskFields.manual);
    }
    if (!isNullOrUndefined(this.parent.taskFields.type)) {
      this.parent.dataOperation.updateMappingData(this.rowData, 'type');
    }
    if (!isNullOrUndefined(this.parent.taskFields.resourceInfo)) {
      this.parent.dataOperation.updateMappingData(this.rowData, 'resourceInfo');
    }
  };
  DialogEdit.prototype.updatePredecessorTab = function (preElement) {
    var gridObj = preElement.ej2_instances[0];
    if (gridObj.isEdit) {
      gridObj.endEdit();
    }
    var dataSource = gridObj.dataSource;
    var predecessorName = [];
    var newValues = [];
    var predecessorString = '';
    var ids = [];
    var parentRecord = [];
    for (var i = 0; i < dataSource.length; i++) {
      var preData = dataSource[i];
      var lastIndex = preData.name.lastIndexOf('-');
      var splitString = [preData.name.substring(0, lastIndex)];
      if (isNullOrUndefined(preData.id) || preData.id !== splitString[0]) {
        preData.id = splitString[0];
      }
      if (ids.indexOf(preData.id) === -1) {
        if (this.parent.viewType === 'ProjectView') {
          var currentRecord = this.parent.flatData[this.parent.ids.indexOf(preData.id)];
          if (currentRecord.hasChildRecords && parentRecord.indexOf(currentRecord) === -1) {
            parentRecord.push(currentRecord);
          }
        }
        var name_1 = preData.id + preData.type;
        if (preData.offset && preData.offset.indexOf('-') !== -1) {
          name_1 += preData.offset;
        } else {
          name_1 += '+' + preData.offset;
        }
        predecessorName.push(name_1);
        ids.push(preData.id);
      }
    }
    if (this.isEdit) {
      if (predecessorName.length > 0) {
        newValues = this.parent.predecessorModule.calculatePredecessor(predecessorName.join(','), this.rowData);
        this.parent.setRecordValue('predecessor', newValues, this.rowData.ganttProperties, true);
        predecessorString = this.parent.predecessorModule.getPredecessorStringValue(this.rowData);
      } else {
        newValues = [];
        this.parent.setRecordValue('predecessor', newValues, this.rowData.ganttProperties, true);
        predecessorString = '';
      }
      if (this.parent.undoRedoModule && this.parent.undoRedoModule['getUndoCollection'].length > 0) {
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1]['connectedRecords'] = parentRecord;
        if (predecessorString !== '' || !isNullOrUndefined(this.rowData.ganttProperties.predecessorsName) && this.rowData.ganttProperties.predecessorsName !== predecessorString && !this.disableUndo) {
          this.disableUndo = true;
        }
      }
      this.parent.setRecordValue('predecessorsName', predecessorString, this.rowData.ganttProperties, true);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, predecessorString, this.rowData);
      this.parent.setRecordValue(this.parent.taskFields.dependency, predecessorString, this.rowData);
      this.parent.predecessorModule.updateUnscheduledDependency(this.rowData);
    } else {
      this.addedRecord[this.parent.taskFields.dependency] = predecessorName.length > 0 ? predecessorName.join(',') : '';
    }
  };
  DialogEdit.prototype.updateResourceTab = function (resourceElement) {
    var treeGridObj = resourceElement.ej2_instances[0];
    if (treeGridObj) {
      treeGridObj.grid.endEdit();
    }
    var selectedItems = this.ganttResources;
    if (this.parent.viewType === 'ResourceView' && !isNullOrUndefined(this.rowData.ganttProperties)) {
      if (JSON.stringify(this.ganttResources) !== JSON.stringify(this.rowData.ganttProperties.resourceInfo)) {
        this.isResourceUpdate = true;
        this.previousResource = !isNullOrUndefined(this.rowData.ganttProperties.resourceInfo) ? this.rowData.ganttProperties.resourceInfo.slice() : [];
      } else {
        this.isResourceUpdate = false;
      }
    }
    var idArray = [];
    if (this.isEdit) {
      if (this.rowData.ganttProperties.resourceInfo && selectedItems.length !== this.rowData.ganttProperties.resourceInfo.length || isNullOrUndefined(this.rowData.ganttProperties.resourceInfo) && selectedItems.length > 0) {
        this.disableUndo = true;
      }
      if (!this.disableUndo) {
        for (var i = 0; i < selectedItems.length; i++) {
          if (JSON.stringify(selectedItems[i]) !== JSON.stringify(this.rowData.ganttProperties.resourceInfo[i])) {
            this.disableUndo = true;
            break;
          }
        }
      }
      this.parent.setRecordValue('resourceInfo', selectedItems, this.editedRecord.ganttProperties, true);
      this.parent.dataOperation.updateMappingData(this.editedRecord, 'resourceInfo');
      this.parent.editModule.updateResourceRelatedFields(this.editedRecord, 'resource');
      this.validateDuration(this.editedRecord);
      this.updateScheduleProperties(this.editedRecord, this.rowData);
    } else {
      for (var i = 0; i < selectedItems.length; i++) {
        idArray.push(selectedItems[i][this.parent.resourceFields.id]);
        this.isAddNewResource = true;
      }
      this.addedRecord[this.parent.taskFields.resourceInfo] = idArray;
    }
  };
  DialogEdit.prototype.updateNotesTab = function (notesElement) {
    var ganttObj = this.parent;
    var rte = notesElement.ej2_instances[0];
    if (this.isEdit) {
      if (!ganttObj.columnByField[ganttObj.taskFields.notes].disableHtmlEncode) {
        if (this.rowData.ganttProperties.notes !== rte.getHtml() && (this.rowData.ganttProperties.notes !== null || rte.getHtml() !== '<p><br></p>') && !this.disableUndo) {
          this.disableUndo = true;
        }
        this.parent.setRecordValue('notes', rte.getHtml(), this.rowData.ganttProperties, true);
      } else {
        if (this.rowData.ganttProperties.notes !== rte.getText() && (this.rowData.ganttProperties.notes !== null || rte.getText() !== '') && !this.disableUndo) {
          this.disableUndo = true;
        }
        if (rte.getHtml().includes('href')) {
          this.parent.setRecordValue('notes', rte.getHtml(), this.rowData.ganttProperties, true);
        } else {
          this.parent.setRecordValue('notes', rte.getText(), this.rowData.ganttProperties, true);
        }
      }
      ganttObj.dataOperation.updateMappingData(this.rowData, 'notes');
    } else {
      this.addedRecord[this.parent.taskFields.notes] = rte.getHtml();
    }
  };
  DialogEdit.prototype.updateCustomTab = function (customElement) {
    this.updateGeneralTab(customElement, true);
  };
  return DialogEdit;
}();
var __assign$3 = undefined && undefined.__assign || function () {
  __assign$3 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$3.apply(this, arguments);
};
/**
 * File for handling connector line edit operation in Gantt.
 *
 */
var ConnectorLineEdit = /** @__PURE__ @class */function () {
  function ConnectorLineEdit(ganttObj) {
    /**
     * @private
     */
    this.validationPredecessor = null;
    /** @private */
    this.confirmPredecessorDialog = null;
    /** @private */
    this.predecessorIndex = null;
    /** @private */
    this.childRecord = null;
    this.validatedId = [];
    this.validatedOffsetIds = [];
    this.isPublicDependencyDelete = false;
    this.parent = ganttObj;
    this.dateValidateModule = this.parent.dateValidationModule;
    this.parent.on('initPredessorDialog', this.initPredecessorValidationDialog, this);
  }
  /**
   * To update connector line edit element.
   *
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.updateConnectorLineEditElement = function (e) {
    var element = this.getConnectorLineHoverElement(e.target);
    if (!getValue('editModule.taskbarEditModule.taskBarEditAction', this.parent)) {
      this.highlightConnectorLineElements(element);
    }
  };
  /**
   * To get hovered connector line element.
   *
   * @param {EventTarget} target .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.getConnectorLineHoverElement = function (target) {
    var isOnLine = parentsUntil(target, connectorLineSVG);
    var isArrow = parentsUntil(target, connectorLineArrow);
    var isCriticalLine = parentsUntil(target, criticalConnectorLineSVG);
    var isCriticalArrow = parentsUntil(target, criticalConnectorArrowSVG);
    if (isOnLine || isArrow || isCriticalLine || isCriticalArrow) {
      return parentsUntil(target, connectorLineContainer);
    } else {
      return null;
    }
  };
  /**
   * To highlight connector line while hover.
   *
   * @param {Element} element .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.highlightConnectorLineElements = function (element) {
    if (element) {
      if (element !== this.connectorLineElement) {
        this.removeHighlight();
        this.addHighlight(element);
      }
    } else {
      this.removeHighlight();
    }
  };
  /**
   * To add connector line highlight class.
   *
   * @param {Element} element .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.addHighlight = function (element) {
    this.connectorLineElement = element;
    var pathElement = element.querySelector('.' + connectorLineSVG);
    if (pathElement) {
      pathElement.setAttribute('stroke-width', (this.parent.connectorLineModule['lineStroke'] + 1).toString());
    }
  };
  /**
   * To remove connector line highlight class.
   *
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.removeHighlight = function () {
    if (this.connectorLineElement) {
      var pathElement = this.connectorLineElement.querySelector('.' + connectorLineSVG);
      if (pathElement) {
        pathElement.setAttribute('stroke-width', this.parent.connectorLineModule['lineStroke'].toString());
      }
      this.connectorLineElement = null;
    }
  };
  /**
   * To remove connector line highlight class.
   *
   * @param {IGanttData[]} records .
   * @returns {string} .
   * @private
   */
  ConnectorLineEdit.prototype.getEditedConnectorLineString = function (records) {
    var ganttRecord;
    var predecessorsCollection;
    var predecessor;
    var parentGanttRecord;
    var childGanttRecord;
    var connectorObj;
    var idArray = [];
    var lineArray = [];
    var editedConnectorLineString = '';
    for (var count = 0; count < records.length; count++) {
      ganttRecord = records[count];
      predecessorsCollection = ganttRecord.ganttProperties.predecessor;
      if (predecessorsCollection) {
        for (var predecessorCount = 0; predecessorCount < predecessorsCollection.length; predecessorCount++) {
          predecessor = predecessorsCollection[predecessorCount];
          var from = 'from';
          var to = 'to';
          this.parent.connectorLineModule.removeConnectorLineById('parent' + predecessor[from] + 'child' + predecessor[to]);
          parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor["" + from]);
          childGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor["" + to]);
          if (!this.parent.allowParentDependency && parentGanttRecord && parentGanttRecord.expanded === true || childGanttRecord && childGanttRecord.expanded === true || this.parent.allowParentDependency && (parentGanttRecord || childGanttRecord)) {
            connectorObj = this.parent.predecessorModule.updateConnectorLineObject(parentGanttRecord, childGanttRecord, predecessor);
            if (!isNullOrUndefined(connectorObj)) {
              var lineIndex = idArray.indexOf(connectorObj.connectorLineId);
              var lineString = this.parent.connectorLineModule.getConnectorLineTemplate(connectorObj);
              if (lineIndex !== -1) {
                lineArray[lineIndex] = lineString;
              } else {
                idArray.push(connectorObj.connectorLineId);
                lineArray.push(lineString);
              }
            }
          }
        }
        editedConnectorLineString = lineArray.join('');
      }
    }
    return editedConnectorLineString;
  };
  /**
   * Tp refresh connector lines of edited records
   *
   * @param {IGanttData[]} editedRecord .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.refreshEditedRecordConnectorLine = function (editedRecord) {
    this.parent.connectorLineModule.removePreviousConnectorLines(this.parent.previousRecords);
    this.parent.connectorLineModule.expandedRecords = this.parent.virtualScrollModule && this.parent.enableVirtualization ? this.parent.updatedRecords : this.parent.getExpandedRecords(this.parent.updatedRecords);
    var editedConnectorLineString = this.getEditedConnectorLineString(editedRecord);
    this.parent.connectorLineModule.svgObject.innerHTML = this.parent.connectorLineModule.svgObject.innerHTML + editedConnectorLineString;
  };
  ConnectorLineEdit.prototype.idFromPredecessor = function (pre) {
    var preArray = pre.split(',');
    var preIdArray = [];
    var values = [];
    var match = [];
    for (var j = 0; j < preArray.length; j++) {
      var strArray = [];
      var firstPart = void 0;
      var isAlpha = false;
      var predecessorName = void 0;
      var isGUId = false;
      var regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      var elSplit = preArray[j].split('-');
      var id = void 0;
      if (elSplit.length === 6) {
        elSplit[4] = elSplit[4] + '-' + elSplit[5];
        elSplit.pop();
      }
      if (elSplit.length === 5 && elSplit[4].length >= 12) {
        id = preArray[j].substring(0, 36);
        if (regex.test(id)) {
          isGUId = true;
        }
      }
      if (preArray[j].includes('-')) {
        if (preArray[j].includes('-') && preArray[j].includes('days')) {
          predecessorName = preArray[j].slice(-9).toString();
        }
        if (preArray[j].includes('-') && preArray[j].includes('day')) {
          predecessorName = preArray[j].slice(-8).toString();
        } else {
          predecessorName = preArray[j].slice(-2).toString();
        }
        if (preArray[j].includes('-') && /[A-Za-z]/.test(predecessorName)) {
          var indexFS = preArray[j].indexOf(predecessorName);
          if (indexFS !== -1) {
            firstPart = preArray[j].substring(0, indexFS);
            if (firstPart.includes('-')) {
              isAlpha = true;
            }
          }
        }
      }
      if (isGUId) {
        var split = void 0;
        split = elSplit[4].split('+');
        var spliceLength = void 0;
        if (split.length === 1) {
          values[0] = preArray[j];
        } else {
          spliceLength = split[1].length;
          values[0] = preArray[j].slice(0, -(spliceLength + 1));
          values[1] = split[1];
        }
        if (elSplit[4].indexOf('-') >= 0) {
          split = elSplit[4].split('-');
          if (split.length === 1) {
            values[0] = preArray[j];
          } else {
            spliceLength = split[1].length;
            values[0] = preArray[j].slice(0, -(spliceLength + 1));
            values[1] = split[1];
          }
        }
      } else {
        if (isAlpha && firstPart.includes('-')) {
          values[0] = firstPart;
        } else {
          values = preArray[j].split('+');
          if (preArray[j].indexOf('-') >= 0) {
            values = preArray[j].split('-');
          }
        }
      }
      if (!isNullOrUndefined(values[0])) {
        var ids = this.parent.viewType === 'ResourceView' ? this.parent.getTaskIds() : this.parent.ids;
        if (ids.indexOf(values[0]) === -1) {
          if (values[0].indexOf(' ') !== -1) {
            match = values[0].split(' ');
            if (match.length === 1) {
              match = values[0].match(/(\d+|[A-z]+)/g);
            }
            strArray.push(match[0]);
          } else {
            if (values[0].length === 1 || values[0].length === 2) {
              strArray.push(values[0]);
            } else {
              strArray.push(values[0].slice(0, -2));
            }
          }
        } else {
          strArray.push(values[0]);
        }
      }
      preIdArray.push(strArray.join(''));
    }
    return preIdArray;
  };
  ConnectorLineEdit.prototype.predecessorValidation = function (predecessor, record) {
    var recordId = record.rowUniqueID;
    var currentId;
    var currentRecord;
    for (var count = 0; count < predecessor.length; count++) {
      currentId = predecessor[count];
      var visitedIdArray = [];
      var predecessorCollection = predecessor.slice(0);
      predecessorCollection.splice(count, 1);
      var _loop_1 = function () {
        var currentIdArray = [];
        if (visitedIdArray.indexOf(currentId) === -1) {
          //Predecessor id not in records collection
          if (isNullOrUndefined(this_1.parent.connectorLineModule.getRecordByID(currentId))) {
            return {
              value: false
            };
          }
          currentRecord = this_1.parent.connectorLineModule.getRecordByID(currentId).ganttProperties;
          if (!isNullOrUndefined(currentRecord.predecessor) && currentRecord.predecessor.length > 0) {
            currentRecord.predecessor.forEach(function (value) {
              if (currentRecord.rowUniqueID.toString() !== value.from) {
                currentIdArray.push(value.from.toString());
              }
            });
          }
          /* eslint-disable-next-line */
          if (recordId.toString() === currentRecord.rowUniqueID.toString() || currentIdArray.indexOf(recordId.toString()) !== -1) {
            return {
              value: false
            };
          }
          visitedIdArray.push(currentId);
          if (!isNullOrUndefined(currentRecord.predecessor) && currentRecord.predecessor.length > 0) {
            currentId = currentRecord.predecessor[0].from;
          } else {
            return "break";
          }
        } else {
          return "break";
        }
      };
      var this_1 = this;
      while (currentId !== null) {
        var state_1 = _loop_1();
        if (typeof state_1 === "object") return state_1.value;
        if (state_1 === "break") break;
      }
    }
    return true;
  };
  // Get the root parent of the record
  ConnectorLineEdit.prototype.getRootParent = function (rec) {
    var parentRec = rec;
    if (rec.parentItem) {
      parentRec = this.parent.flatData.filter(function (item) {
        return item.uniqueID === rec.parentUniqueID;
      })[0];
      if (parentRec.parentItem) {
        parentRec = this.getRootParent(parentRec);
      }
      return parentRec;
    }
    return parentRec;
  };
  // To check whether the predecessor drawn is valid for parent task
  ConnectorLineEdit.prototype.validateParentPredecessor = function (fromRecord, toRecord) {
    if (fromRecord && toRecord) {
      if (toRecord.hasChildRecords && !fromRecord.hasChildRecords) {
        if (fromRecord.parentUniqueID === toRecord.uniqueID) {
          return false;
        } else {
          do {
            if (fromRecord.parentItem) {
              fromRecord = this.parent.flatData[this.parent.ids.indexOf(fromRecord.parentItem.taskId)];
              if (fromRecord.uniqueID === toRecord.uniqueID) {
                return false;
              }
            }
          } while (fromRecord.parentItem);
        }
      } else if (!toRecord.hasChildRecords && fromRecord.hasChildRecords) {
        if (toRecord.parentUniqueID === fromRecord.uniqueID) {
          return false;
        } else {
          do {
            if (toRecord.parentItem) {
              toRecord = this.parent.flatData[this.parent.ids.indexOf(toRecord.parentItem.taskId)];
              if (toRecord.uniqueID === fromRecord.uniqueID) {
                return false;
              }
            }
          } while (toRecord.parentItem);
        }
      } else if (toRecord.hasChildRecords && fromRecord.hasChildRecords) {
        if (toRecord.parentItem && fromRecord.parentItem) {
          if (fromRecord.parentUniqueID === toRecord.uniqueID || fromRecord.uniqueID === toRecord.parentUniqueID) {
            return false;
          }
        } else {
          if (!toRecord.parentItem && fromRecord.parentItem) {
            var fromRootParent = this.parent.connectorLineEditModule.getRootParent(fromRecord);
            if (fromRootParent.uniqueID === toRecord.uniqueID) {
              return false;
            }
          } else if (toRecord.parentItem && !fromRecord.parentItem) {
            var toRootParent = this.parent.connectorLineEditModule.getRootParent(toRecord);
            if (toRootParent.uniqueID === fromRecord.uniqueID) {
              return false;
            }
          }
        }
      }
    }
    return true;
  };
  /**
   * To validate predecessor relations
   *
   * @param {IGanttData} ganttRecord .
   * @param {string} predecessorString .
   * @returns {boolean} .
   * @private
   */
  ConnectorLineEdit.prototype.validatePredecessorRelation = function (ganttRecord, predecessorString) {
    var flag = true;
    var recordId = this.parent.viewType === 'ResourceView' ? ganttRecord.ganttProperties.taskId : ganttRecord.ganttProperties.rowUniqueID;
    var predecessorIdArray;
    var currentId;
    if (!isNullOrUndefined(predecessorString) && predecessorString.length > 0) {
      predecessorIdArray = this.idFromPredecessor(predecessorString);
      var _loop_2 = function (count) {
        //Check edited item has parent item in predecessor collection
        if (!this_2.parent.allowParentDependency) {
          var checkParent = this_2.checkParentRelation(ganttRecord, predecessorIdArray);
          if (!checkParent) {
            return {
              value: false
            };
          }
        } else {
          if (parseInt(predecessorIdArray[predecessorIdArray.length - 1], 10) !== ganttRecord[this_2.parent.taskFields.id]) {
            var num = this_2.parent.ids.indexOf(predecessorIdArray[predecessorIdArray.length - 1]);
            var fromRecord = this_2.parent.currentViewData[num];
            if (fromRecord && ganttRecord) {
              flag = this_2.validateParentPredecessor(fromRecord, ganttRecord);
            }
          }
        }
        // Check if predecessor exist more then one
        var tempIdArray = predecessorIdArray.slice(0);
        var checkArray = [];
        var countFlag = true;
        tempIdArray.forEach(function (value) {
          if (checkArray.indexOf(value) === -1) {
            checkArray.push(value);
          } else {
            countFlag = false;
          }
        });
        if (!countFlag) {
          return {
            value: false
          };
        }
        //Cyclick check
        currentId = predecessorIdArray[count];
        var visitedIdArray = [];
        var predecessorCollection = predecessorIdArray.slice(0);
        predecessorCollection.splice(count, 1);
        var _loop_3 = function () {
          var currentIdArray = [];
          var currentIdIndex;
          var currentRecord;
          if (visitedIdArray.indexOf(currentId) === -1) {
            //Predecessor id not in records collection
            if (isNullOrUndefined(this_2.parent.connectorLineModule.getRecordByID(currentId.toString()))) {
              return {
                value: false
              };
            }
            currentRecord = this_2.parent.connectorLineModule.getRecordByID(currentId.toString()).ganttProperties;
            //  let currentPredecessor='';
            if (!isNullOrUndefined(currentRecord.predecessor) && currentRecord.predecessor.length > 0) {
              currentRecord.predecessor.forEach(function (value, index) {
                if (currentRecord.rowUniqueID.toString() !== value.from) {
                  currentIdArray.push(value.from.toString());
                  currentIdIndex = index;
                }
              });
              //    currentPredecessor=currentRecord.predecessor[0].from
            }
            if (recordId.toString() === currentRecord.rowUniqueID.toString() || currentIdArray.indexOf(recordId.toString()) !== -1) {
              return {
                value: false
              };
            }
            visitedIdArray.push(currentId);
            if (!isNullOrUndefined(currentRecord.predecessor) && currentRecord.predecessor.length > 0) {
              var result = void 0;
              if (currentIdArray.length > 1) {
                result = this_2.predecessorValidation(currentIdArray, ganttRecord.ganttProperties);
              } else if (currentIdArray.length === 1) {
                currentId = currentRecord.predecessor[currentIdIndex].from;
              }
              if (result === false) {
                return {
                  value: false
                };
              }
            } else {
              return "break";
            }
          } else {
            return "break";
          }
        };
        while (currentId !== null) {
          var state_3 = _loop_3();
          if (typeof state_3 === "object") return state_3;
          if (state_3 === "break") break;
        }
      };
      var this_2 = this;
      for (var count = 0; count < predecessorIdArray.length; count++) {
        var state_2 = _loop_2(count);
        if (typeof state_2 === "object") return state_2.value;
      }
    }
    return flag;
  };
  /**
   * To add dependency for Task
   *
   * @param {IGanttData} ganttRecord .
   * @param {string} predecessorString .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.addPredecessor = function (ganttRecord, predecessorString) {
    var tempPredecessorString = isNullOrUndefined(ganttRecord.ganttProperties.predecessorsName) || ganttRecord.ganttProperties.predecessorsName === '' ? predecessorString : ganttRecord.ganttProperties.predecessorsName + ',' + predecessorString;
    this.updatePredecessorHelper(ganttRecord, tempPredecessorString);
  };
  /**
   * To remove dependency from task
   *
   * @param {IGanttData} ganttRecord .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.removePredecessor = function (ganttRecord) {
    this.isPublicDependencyDelete = true;
    this.updatePredecessorHelper(ganttRecord, null);
  };
  /**
   * To modify current dependency values of Task
   *
   * @param {IGanttData} ganttRecord .
   * @param {string} predecessorString .
   * @param {ITaskbarEditedEventArgs} editedArgs .
   * @returns {boolean} .
   * @private
   */
  ConnectorLineEdit.prototype.updatePredecessor = function (ganttRecord, predecessorString, editedArgs) {
    return this.updatePredecessorHelper(ganttRecord, predecessorString, editedArgs);
  };
  ConnectorLineEdit.prototype.updatePredecessorHelper = function (ganttRecord, predecessorString, editedArgs) {
    if (isUndefined(predecessorString) || this.validatePredecessorRelation(ganttRecord, predecessorString)) {
      this.parent.isOnEdit = true;
      var predecessorCollection = [];
      if (!isNullOrUndefined(predecessorString) && predecessorString !== '') {
        predecessorCollection = this.parent.predecessorModule.calculatePredecessor(predecessorString, ganttRecord);
      }
      this.parent.setRecordValue('predecessor', predecessorCollection, ganttRecord.ganttProperties, true);
      var stringValue = this.parent.predecessorModule.getPredecessorStringValue(ganttRecord);
      this.parent.setRecordValue('predecessorsName', stringValue, ganttRecord.ganttProperties, true);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, stringValue, ganttRecord);
      this.parent.setRecordValue(this.parent.taskFields.dependency, stringValue, ganttRecord);
      var args = {};
      args.action = editedArgs && editedArgs.action && editedArgs.action === 'CellEditing' ? editedArgs.action : this.parent.contextMenuModule && this.parent.contextMenuModule['isCntxtMenuDependencyDelete'] || this.isPublicDependencyDelete ? 'DeleteConnectorLine' : 'DrawConnectorLine';
      args.data = ganttRecord;
      this.parent.editModule.initiateUpdateAction(args);
      return true;
    } else {
      if (ganttRecord.taskData[this.parent.taskFields.dependency]) {
        ganttRecord.taskData[this.parent.taskFields.dependency] = null;
      }
      var err = predecessorString + " is an invalid relation for task " + this.parent.taskFields.id + ". Kindly ensure the " + this.parent.taskFields.dependency + " field contains only valid predecessor relations.";
      this.parent.trigger('actionFailure', {
        error: err
      });
      return false;
    }
  };
  ConnectorLineEdit.prototype.checkParentRelation = function (ganttRecord, predecessorIdArray) {
    var editingData = ganttRecord;
    var checkParent = true;
    if (editingData && editingData.parentItem) {
      if (predecessorIdArray.indexOf(editingData.parentItem.taskId.toString()) !== -1) {
        return false;
      }
    }
    var _loop_4 = function (p) {
      var record = this_3.parent.currentViewData.filter(function (item) {
        return item && item.ganttProperties.rowUniqueID.toString() === predecessorIdArray[p].toString();
      });
      if (record[0] && record[0].hasChildRecords) {
        return {
          value: false
        };
      }
    };
    var this_3 = this;
    for (var p = 0; p < predecessorIdArray.length; p++) {
      var state_4 = _loop_4(p);
      if (typeof state_4 === "object") return state_4.value;
    }
    return checkParent;
  };
  ConnectorLineEdit.prototype.initPredecessorValidationDialog = function () {
    if (this.parent.taskFields.dependency && this.parent.isInPredecessorValidation) {
      var dialogElement = createElement('div', {
        id: this.parent.element.id + '_dialogValidationRule'
      });
      this.parent.element.appendChild(dialogElement);
      this.renderValidationDialog();
    }
  };
  /**
   * To render validation dialog
   *
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.renderValidationDialog = function () {
    var validationDialog = new Dialog({
      header: 'Validate Editing',
      isModal: true,
      enableRtl: this.parent.enableRtl,
      visible: false,
      width: '50%',
      showCloseIcon: true,
      close: this.validationDialogClose.bind(this),
      content: '',
      buttons: [{
        click: this.validationDialogOkButton.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('okText'),
          isPrimary: true
        }
      }, {
        click: this.validationDialogCancelButton.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('cancel')
        }
      }],
      target: this.parent.element,
      animationSettings: {
        effect: 'None'
      }
    });
    document.getElementById(this.parent.element.id + '_dialogValidationRule').innerHTML = '';
    validationDialog.isStringTemplate = true;
    validationDialog.appendTo('#' + this.parent.element.id + '_dialogValidationRule');
    this.parent.validationDialogElement = validationDialog;
  };
  ConnectorLineEdit.prototype.validationDialogOkButton = function () {
    var currentArgs = this.parent.currentEditedArgs;
    currentArgs.validateMode.preserveLinkWithEditing = document.getElementById(this.parent.element.id + '_ValidationAddlineOffset').checked;
    currentArgs.validateMode.removeLink = document.getElementById(this.parent.element.id + '_ValidationRemoveline').checked;
    currentArgs.validateMode.respectLink = document.getElementById(this.parent.element.id + '_ValidationCancel').checked;
    this.applyPredecessorOption();
    this.parent.validationDialogElement.hide();
  };
  ConnectorLineEdit.prototype.validationDialogCancelButton = function () {
    this.parent.currentEditedArgs.validateMode.respectLink = true;
    this.applyPredecessorOption();
    this.parent.validationDialogElement.hide();
  };
  ConnectorLineEdit.prototype.validationDialogClose = function (e) {
    if (getValue('isInteraction', e)) {
      this.parent.currentEditedArgs.validateMode.respectLink = true;
      this.applyPredecessorOption();
    }
  };
  /**
   * Validate and apply the predecessor option from validation dialog
   *
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.applyPredecessorOption = function () {
    var args = this.parent.currentEditedArgs;
    var ganttRecord = args.data;
    if (args.validateMode.respectLink) {
      this.parent.editModule.reUpdatePreviousRecords();
      this.parent.chartRowsModule.refreshRecords([args.data]);
    } else if (args.validateMode.removeLink) {
      this.checkChildRecords(ganttRecord);
      this.parent.editModule.updateEditedTask(args.editEventArgs);
    } else if (args.validateMode.preserveLinkWithEditing) {
      var connectedTaskId_1;
      if (this.parent.updateOffsetOnTaskbarEdit) {
        var taskId_1 = ganttRecord.ganttProperties.taskId;
        if (ganttRecord.ganttProperties.predecessor) {
          ganttRecord.ganttProperties.predecessor.forEach(function (predecessor) {
            if (taskId_1 === predecessor.from) {
              connectedTaskId_1 = predecessor.to;
              return;
            }
          });
        }
      }
      this.parent.editModule.updateEditedTask(args.editEventArgs);
      this.processPredecessors(connectedTaskId_1);
    }
  };
  ConnectorLineEdit.prototype.compareArrays = function (arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    var str1 = JSON.stringify(arr1);
    var str2 = JSON.stringify(arr2);
    return str1 === str2;
  };
  ConnectorLineEdit.prototype.processPredecessors = function (parentId) {
    var _this = this;
    if (parentId) {
      var record_1 = this.parent.getRecordByID(parentId);
      if (record_1 && record_1.ganttProperties && record_1.ganttProperties.predecessor) {
        this.parent.connectorLineEditModule['validatedOffsetIds'] = [];
        this.calculateOffset(record_1);
        var isIdInclude_1 = true;
        /* eslint-disable-next-line */
        var matchedObject = this.validatedId.find(function (item) {
          return item.id === record_1.ganttProperties.taskId;
        });
        if (matchedObject) {
          var predecessorArray = matchedObject.value;
          var areArraysEqual = this.compareArrays(predecessorArray, record_1.ganttProperties.predecessor);
          if (areArraysEqual) {
            isIdInclude_1 = false;
          }
        }
        var predecessors = record_1.ganttProperties.predecessor;
        predecessors.forEach(function (predecessor) {
          if (record_1.ganttProperties.taskId === predecessor.from && isIdInclude_1) {
            _this.processPredecessors(predecessor.to);
          }
        });
      }
    }
  };
  ConnectorLineEdit.prototype.checkChildRecords = function (ganttRecord) {
    this.validationPredecessor = ganttRecord.ganttProperties.predecessor;
    if (!isNullOrUndefined(this.validationPredecessor)) {
      this.removePredecessors(ganttRecord, this.validationPredecessor);
    }
    if (ganttRecord.childRecords.length > 0) {
      for (var i = 0; i < ganttRecord.childRecords.length; i++) {
        var childRecord = ganttRecord.childRecords[i];
        this.validationPredecessor = childRecord.ganttProperties.predecessor;
        if (!isNullOrUndefined(this.validationPredecessor)) {
          this.removePredecessors(childRecord, this.validationPredecessor);
        }
        if (childRecord.childRecords.length > 0) {
          this.checkChildRecords(childRecord);
        }
      }
    } else if (!isNullOrUndefined(ganttRecord.parentItem)) {
      var parentRecord = this.parent.getRecordByID(ganttRecord.parentItem.taskId);
      this.validationPredecessor = parentRecord.ganttProperties.predecessor;
      this.removePredecessors(parentRecord, this.validationPredecessor);
    }
  };
  ConnectorLineEdit.prototype.compareObjects = function (obj1, obj2) {
    var keys1 = Object.keys(obj1).filter(function (key) {
      return key !== 'offset';
    });
    var keys2 = Object.keys(obj2).filter(function (key) {
      return key !== 'offset';
    });
    if (keys1.length !== keys2.length) {
      return false;
    }
    return keys1.every(function (key) {
      return obj1[key] === obj2[key];
    });
  };
  ConnectorLineEdit.prototype.calculateOffset = function (record, isRecursive) {
    var _this = this;
    if (!this.parent.autoCalculateDateScheduling || this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      return;
    }
    if (record && isScheduledTask(record.ganttProperties) !== null) {
      var prevPredecessor = extend([], record.ganttProperties.predecessor, [], true);
      var validPredecessor_1 = this.parent.predecessorModule.getValidPredecessor(record);
      if (validPredecessor_1.length > 0) {
        this.cumulativePredecessorChanges = prevPredecessor;
        var _loop_5 = function (i) {
          var predecessor = validPredecessor_1[parseInt(i.toString(), 10)];
          var parentTask = this_4.parent.connectorLineModule.getRecordByID(predecessor.from);
          if (this_4.parent.undoRedoModule && this_4.parent.undoRedoModule['isUndoRedoPerformed'] && this_4.parent.viewType === 'ProjectView') {
            var isPresent = parentTask.ganttProperties.predecessor.filter(function (pred) {
              return pred.from === validPredecessor_1[i].from && pred.to === validPredecessor_1[i].to;
            });
            if (isPresent.length === 0) {
              parentTask.ganttProperties.predecessor.push(validPredecessor_1[i]);
            }
          }
          var offset = void 0;
          if ((parentTask.ganttProperties.startDate || parentTask.ganttProperties.endDate) && (record.ganttProperties.startDate || record.ganttProperties.endDate)) {
            var tempStartDate = void 0;
            var tempEndDate = void 0;
            var tempDuration = void 0;
            var isNegativeOffset = void 0;
            switch (predecessor.type) {
              case 'FS':
                tempStartDate = new Date((parentTask.ganttProperties.endDate || parentTask.ganttProperties.startDate).getTime());
                tempEndDate = new Date((record.ganttProperties.startDate || record.ganttProperties.endDate).getTime());
                break;
              case 'SS':
                tempStartDate = new Date((parentTask.ganttProperties.startDate || parentTask.ganttProperties.endDate).getTime());
                tempEndDate = new Date((record.ganttProperties.startDate || record.ganttProperties.endDate).getTime());
                break;
              case 'SF':
                tempStartDate = new Date((parentTask.ganttProperties.startDate || parentTask.ganttProperties.endDate).getTime());
                tempEndDate = new Date((record.ganttProperties.endDate || record.ganttProperties.startDate).getTime());
                break;
              case 'FF':
                tempStartDate = new Date((parentTask.ganttProperties.endDate || parentTask.ganttProperties.startDate).getTime());
                tempEndDate = new Date((record.ganttProperties.endDate || record.ganttProperties.startDate).getTime());
                break;
            }
            if (tempStartDate.getTime() < tempEndDate.getTime()) {
              tempStartDate = this_4.dateValidateModule.checkStartDate(tempStartDate);
              tempEndDate = this_4.dateValidateModule.checkEndDate(tempEndDate, null);
              isNegativeOffset = false;
            } else {
              var tempDate = new Date(tempStartDate.getTime());
              tempStartDate = this_4.dateValidateModule.checkStartDate(tempEndDate);
              tempEndDate = this_4.dateValidateModule.checkEndDate(tempDate, null);
              isNegativeOffset = true;
            }
            if (tempStartDate.getTime() < tempEndDate.getTime()) {
              tempDuration = this_4.dateValidateModule.getDuration(tempStartDate, tempEndDate, predecessor.offsetUnit, true, false);
              if (this_4.parent.durationUnit === predecessor.offsetUnit && (parentTask.ganttProperties.startDate && isNullOrUndefined(parentTask.ganttProperties.endDate) || isNullOrUndefined(parentTask.ganttProperties.startDate) && parentTask.ganttProperties.endDate)) {
                tempDuration = tempDuration - 1;
              }
              offset = isNegativeOffset ? tempDuration * -1 : tempDuration;
            } else {
              offset = 0;
            }
          } else {
            offset = 0;
          }
          var preIndex = getIndex(predecessor, 'from', prevPredecessor, 'to');
          if (preIndex !== -1) {
            prevPredecessor[preIndex].offset = offset;
          }
          // Update predecessor in predecessor task
          var parentPredecessors = extend([], parentTask.ganttProperties.predecessor, [], true);
          var parentPreIndex = getIndex(predecessor, 'from', parentPredecessors, 'to');
          if (parentPreIndex !== -1) {
            parentPredecessors[parentPreIndex].offset = offset;
          }
          this_4.parent.setRecordValue('predecessor', parentPredecessors, parentTask.ganttProperties, true);
        };
        var this_4 = this;
        for (var i = 0; i < validPredecessor_1.length; i++) {
          _loop_5(i);
        }
      } else {
        var validPredecessor_2 = record.ganttProperties.predecessor;
        if (validPredecessor_2) {
          if (validPredecessor_2.length > 0) {
            validPredecessor_2.forEach(function (element) {
              if (_this.validatedOffsetIds.indexOf(element.to) === -1) {
                _this.calculateOffset(_this.parent.getRecordByID(element.to), true);
              }
            });
          }
        }
      }
      if (validPredecessor_1.length === 0) {
        this.cumulativePredecessorChanges = [];
      }
      if (!isRecursive && this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
        if (prevPredecessor && prevPredecessor.length > 0 && this.cumulativePredecessorChanges && this.cumulativePredecessorChanges.length > 0) {
          var matchingObjects = prevPredecessor.map(function (objectToCompare) {
            var matchedObject = _this.cumulativePredecessorChanges.find(function (obj) {
              return _this.compareObjects(obj, objectToCompare);
            });
            return matchedObject ? __assign$3({}, matchedObject) : null;
          }).filter(function (matchedObject) {
            return matchedObject !== null;
          });
          this.parent.setRecordValue('predecessor', matchingObjects, record.ganttProperties, true);
        }
      } else {
        this.parent.setRecordValue('predecessor', prevPredecessor, record.ganttProperties, true);
      }
      var predecessorString = this.parent.predecessorModule.getPredecessorStringValue(record);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, predecessorString, record);
      this.parent.setRecordValue(this.parent.taskFields.dependency, predecessorString, record);
      this.parent.setRecordValue('predecessorsName', predecessorString, record.ganttProperties, true);
      if (this.validatedOffsetIds.indexOf(record.ganttProperties.taskId.toString()) === -1) {
        this.validatedOffsetIds.push(record.ganttProperties.taskId.toString());
      }
      if (record.hasChildRecords) {
        for (var i = 0; i < record.childRecords.length; i++) {
          if (this.validatedOffsetIds.indexOf(record.childRecords[i].ganttProperties.taskId.toString()) === -1 && record.childRecords[i].ganttProperties.predecessor && record.childRecords[i].ganttProperties.predecessor.length > 0) {
            this.calculateOffset(record.childRecords[i]);
          }
        }
      } else if (record.parentItem) {
        var parentItem = this.parent.getRecordByID(record.parentItem.taskId);
        if (this.validatedOffsetIds.indexOf(parentItem.ganttProperties.taskId.toString()) === -1 && parentItem.ganttProperties.predecessor && parentItem.ganttProperties.predecessor.length > 0) {
          this.calculateOffset(parentItem);
        }
      }
    }
  };
  /**
   * Update predecessor value with user selection option in predecessor validation dialog
   *
   * @param {IGanttData} ganttRecord .
   * @param {IPredecessor[]} predecessor .
   * @returns {void} .
   */
  ConnectorLineEdit.prototype.removePredecessors = function (ganttRecord, predecessor) {
    var prevPredecessor = extend([], [], ganttRecord.ganttProperties.predecessor, true);
    if (isNullOrUndefined(predecessor)) {
      return;
    }
    var preLength = predecessor.length;
    for (var i = 0; i < preLength; i++) {
      var parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor[i].from);
      var parentPredecessor = extend([], [], parentGanttRecord.ganttProperties.predecessor, true);
      var index = getIndex(predecessor[i], 'from', prevPredecessor, 'to');
      prevPredecessor.splice(index, 1);
      var parentIndex = getIndex(predecessor[parseInt(i.toString(), 10)], 'from', parentPredecessor, 'to');
      parentPredecessor.splice(parentIndex, 1);
      this.parent.setRecordValue('predecessor', parentPredecessor, parentGanttRecord.ganttProperties, true);
    }
    if (prevPredecessor.length !== ganttRecord.ganttProperties.predecessor.length) {
      this.parent.setRecordValue('predecessor', prevPredecessor, ganttRecord.ganttProperties, true);
      var predecessorString = this.parent.predecessorModule.getPredecessorStringValue(ganttRecord);
      this.parent.setRecordValue('predecessorsName', predecessorString, ganttRecord.ganttProperties, true);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, predecessorString, ganttRecord);
      this.parent.setRecordValue(this.parent.taskFields.dependency, predecessorString, ganttRecord);
    }
  };
  /**
   * To open predecessor validation dialog
   *
   * @param {object} args .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.openValidationDialog = function (args) {
    var contentTemplate = this.validationDialogTemplate(args);
    this.parent.validationDialogElement.setProperties({
      content: contentTemplate
    });
    this.parent.validationDialogElement.show();
  };
  /**
   * Predecessor link validation dialog template
   *
   * @param {object} args .
   * @returns {HTMLElement} .
   * @private
   */
  ConnectorLineEdit.prototype.validationDialogTemplate = function (args) {
    var ganttId = this.parent.element.id;
    var contentdiv = createElement('div', {
      className: 'e-ValidationContent'
    });
    var taskData = getValue('task', args);
    var parenttaskData = getValue('parentTask', args);
    var violationType = getValue('violationType', args);
    var recordName = taskData.ganttProperties.taskName;
    var recordNewStartDate = this.parent.getFormatedDate(taskData.ganttProperties.startDate, this.parent.dateFormat);
    var parentName = parenttaskData.ganttProperties.taskName;
    var recordArgs = [recordName, parentName];
    var topContentText;
    if (violationType === 'taskBeforePredecessor_FS') {
      topContentText = this.parent.localeObj.getConstant('taskBeforePredecessor_FS');
    } else if (violationType === 'taskAfterPredecessor_FS') {
      topContentText = this.parent.localeObj.getConstant('taskAfterPredecessor_FS');
    } else if (violationType === 'taskBeforePredecessor_SS') {
      topContentText = this.parent.localeObj.getConstant('taskBeforePredecessor_SS');
    } else if (violationType === 'taskAfterPredecessor_SS') {
      topContentText = this.parent.localeObj.getConstant('taskAfterPredecessor_SS');
    } else if (violationType === 'taskBeforePredecessor_FF') {
      topContentText = this.parent.localeObj.getConstant('taskBeforePredecessor_FF');
    } else if (violationType === 'taskAfterPredecessor_FF') {
      topContentText = this.parent.localeObj.getConstant('taskAfterPredecessor_FF');
    } else if (violationType === 'taskBeforePredecessor_SF') {
      topContentText = this.parent.localeObj.getConstant('taskBeforePredecessor_SF');
    } else if (violationType === 'taskAfterPredecessor_SF') {
      topContentText = this.parent.localeObj.getConstant('taskAfterPredecessor_SF');
    }
    topContentText = formatString(topContentText, recordArgs);
    var topContent = '<div id="' + ganttId + '_ValidationText">' + topContentText + '<div>';
    var innerTable = '<table>' + '<tr><td><input type="radio" id="' + ganttId + '_ValidationCancel" name="ValidationRule" checked/><label for="' + ganttId + '_ValidationCancel" id= "' + ganttId + '_cancelLink">Cancel, keep the existing link</label></td></tr>' + '<tr><td><input type="radio" id="' + ganttId + '_ValidationRemoveline" name="ValidationRule"/><label for="' + ganttId + '_ValidationRemoveline" id="' + ganttId + '_removeLink">Remove the link and move <b>' + recordName + '</b> to start on <b>' + recordNewStartDate + '</b>.</label></td></tr>' + '<tr><td><input type="radio" id="' + ganttId + '_ValidationAddlineOffset" name="ValidationRule"/><label for="' + ganttId + '_ValidationAddlineOffset" id="' + ganttId + '_preserveLink">Move the <b>' + recordName + '</b> to start on <b>' + recordNewStartDate + '</b> and keep the link.</label></td></tr></table>';
    contentdiv.innerHTML = topContent + innerTable;
    return contentdiv;
  };
  /**
   * To validate the types while editing the taskbar
   *
   * @param {IGanttData} ganttRecord .
   * @param {any} data .
   * @returns {boolean} .
   * @private
   */
  ConnectorLineEdit.prototype.validateTypes = function (ganttRecord, data) {
    var predecessor = this.parent.predecessorModule.getValidPredecessor(ganttRecord);
    var parentGanttRecord;
    this.validationPredecessor = [];
    var violatedParent;
    var ganttTaskData;
    var violateType;
    var startDate = this.parent.predecessorModule.getPredecessorDate(ganttRecord, predecessor);
    if (data) {
      ganttTaskData = data.ganttProperties;
    } else {
      ganttTaskData = ganttRecord.ganttProperties;
    }
    var endDate = this.parent.allowUnscheduledTasks && isNullOrUndefined(startDate) ? ganttTaskData.endDate : this.dateValidateModule.getEndDate(startDate, ganttTaskData.duration, ganttTaskData.durationUnit, ganttTaskData, false);
    for (var i = 0; i < predecessor.length; i++) {
      parentGanttRecord = this.parent.connectorLineModule.getRecordByID(predecessor[i].from);
      var violationType = null;
      if (predecessor[i].type === 'FS') {
        if (this.parent.dateValidationModule.getDuration(startDate, ganttTaskData.startDate, this.parent.durationUnit, ganttTaskData.isAutoSchedule, ganttTaskData.isMilestone, true) !== 0) {
          if (ganttTaskData.startDate < startDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskBeforePredecessor_FS';
          } else if (ganttTaskData.startDate > startDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskAfterPredecessor_FS';
          }
        }
      } else if (predecessor[i].type === 'SS') {
        var endDateOlny = new Date(ganttTaskData.endDate);
        var startDateOlny = new Date(startDate);
        if (ganttTaskData.startDate < startDate) {
          this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
          violationType = 'taskBeforePredecessor_SS';
        } else if (ganttTaskData.startDate > startDate) {
          this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
          violationType = 'taskAfterPredecessor_SS';
        } else if (this.parent.allowUnscheduledTasks && isNullOrUndefined(ganttTaskData.startDate) && isNullOrUndefined(ganttTaskData.duration) && endDateOlny.setHours(0, 0, 0, 0) < startDateOlny.setHours(0, 0, 0, 0)) {
          this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
          violationType = 'taskBeforePredecessor_SS';
        } else if (this.parent.allowUnscheduledTasks && isNullOrUndefined(ganttTaskData.startDate) && isNullOrUndefined(ganttTaskData.duration) && endDateOlny.setHours(0, 0, 0, 0) > startDateOlny.setHours(0, 0, 0, 0)) {
          this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
          violationType = 'taskAfterPredecessor_SS';
        }
      } else if (predecessor[i].type === 'FF') {
        if (this.parent.dateValidationModule.getDuration(startDate, parentGanttRecord.ganttProperties.endDate, this.parent.durationUnit, parentGanttRecord.ganttProperties.isAutoSchedule, parentGanttRecord.ganttProperties.isMilestone, true) !== 0) {
          if (endDate <= parentGanttRecord.ganttProperties.endDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskBeforePredecessor_FF';
          } else if (endDate > parentGanttRecord.ganttProperties.endDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskAfterPredecessor_FF';
          }
        }
      } else if (predecessor[i].type === 'SF') {
        if (this.parent.dateValidationModule.getDuration(parentGanttRecord.ganttProperties.startDate, endDate, this.parent.durationUnit, parentGanttRecord.ganttProperties.isAutoSchedule, parentGanttRecord.ganttProperties.isMilestone, true) !== 0) {
          if (endDate < parentGanttRecord.ganttProperties.startDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskBeforePredecessor_SF';
          } else if (endDate >= parentGanttRecord.ganttProperties.startDate) {
            this.validationPredecessor.push(predecessor[parseInt(i.toString(), 10)]);
            violationType = 'taskAfterPredecessor_SF';
          }
        }
      }
      if (!isNullOrUndefined(violationType) && isNullOrUndefined(violateType)) {
        violatedParent = parentGanttRecord;
        violateType = violationType;
      }
    }
    var validateArgs = {
      parentTask: violatedParent,
      task: ganttRecord,
      violationType: violateType
    };
    return validateArgs;
  };
  /**
   * Method to remove and update new predecessor collection in successor record
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.addRemovePredecessor = function (data) {
    var prevData = this.parent.previousRecords[data.uniqueID];
    var newPredecessor = data.ganttProperties.predecessor.slice();
    // eslint-disable-next-line
    if (prevData && prevData.ganttProperties && prevData.ganttProperties.hasOwnProperty('predecessor')) {
      var prevPredecessor_1 = prevData.ganttProperties.predecessor;
      if (!isNullOrUndefined(prevPredecessor_1)) {
        var _loop_6 = function (p) {
          var parentGanttRecord = this_5.parent.connectorLineModule.getRecordByID(prevPredecessor_1[parseInt(p.toString(), 10)].from);
          if (parentGanttRecord === data) {
            var isValid = data.ganttProperties.predecessor.filter(function (pred) {
              return prevPredecessor_1[p].from === pred.from && prevPredecessor_1[p].to === pred.to;
            });
            if (isValid.length === 0) {
              if (data.parentItem && this_5.parent.taskFields.dependency && data.ganttProperties.predecessor && this_5.parent.allowParentDependency) {
                if (prevPredecessor_1[p].from !== data.parentItem.taskId && prevPredecessor_1[p].to !== data.parentItem.taskId) {
                  data.ganttProperties.predecessor.push(prevPredecessor_1[parseInt(p.toString(), 10)]);
                }
              } else {
                data.ganttProperties.predecessor.push(prevPredecessor_1[parseInt(p.toString(), 10)]);
              }
            }
          } else {
            var parentPredecessor = extend([], [], parentGanttRecord.ganttProperties.predecessor, true);
            var parentIndex = getIndex(prevPredecessor_1[parseInt(p.toString(), 10)], 'from', parentPredecessor, 'to');
            if (parentIndex !== -1) {
              parentPredecessor.splice(parentIndex, 1);
              this_5.parent.setRecordValue('predecessor', parentPredecessor, parentGanttRecord.ganttProperties, true);
            }
          }
        };
        var this_5 = this;
        for (var p = 0; p < prevPredecessor_1.length; p++) {
          _loop_6(p);
        }
      }
      if (!isNullOrUndefined(newPredecessor)) {
        var _loop_7 = function (n) {
          var parentGanttRecord = this_6.parent.connectorLineModule.getRecordByID(newPredecessor[parseInt(n.toString(), 10)].from);
          var parentPredecessor = extend([], [], parentGanttRecord.ganttProperties.predecessor, true);
          var isValid = parentPredecessor.filter(function (pred) {
            return newPredecessor[n].from === pred.from && newPredecessor[n].to === pred.to;
          });
          if (isValid.length === 0) {
            parentPredecessor.push(newPredecessor[parseInt(n.toString(), 10)]);
            this_6.parent.setRecordValue('predecessor', parentPredecessor, parentGanttRecord.ganttProperties, true);
          }
        };
        var this_6 = this;
        for (var n = 0; n < newPredecessor.length; n++) {
          _loop_7(n);
        }
      }
    }
  };
  /**
   * Method to remove a predecessor from a record.
   *
   * @param {IGanttData} childRecord .
   * @param {number} index .
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.removePredecessorByIndex = function (childRecord, index) {
    if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
      if (this.parent.undoRedoModule['redoEnabled']) {
        this.parent.undoRedoModule['disableRedo']();
      }
      this.parent.undoRedoModule['createUndoCollection']();
      var details = {};
      details['action'] = 'DeleteDependency';
      details['modifiedRecords'] = extend([], [childRecord], [], true);
      this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = details;
    }
    var childPredecessor = childRecord.ganttProperties.predecessor;
    var predecessor = childPredecessor.splice(index, 1);
    var parentRecord = this.parent.connectorLineModule.getRecordByID(predecessor[0].from);
    var parentPredecessor = parentRecord.ganttProperties.predecessor;
    var parentIndex = getIndex(predecessor[0], 'from', parentPredecessor, 'to');
    parentPredecessor.splice(parentIndex, 1);
    var predecessorString = this.parent.predecessorModule.getPredecessorStringValue(childRecord);
    childPredecessor.push(predecessor[0]);
    this.parent.connectorLineEditModule.updatePredecessor(childRecord, predecessorString);
  };
  /**
   * To render predecessor delete confirmation dialog
   *
   * @returns {void} .
   * @private
   */
  ConnectorLineEdit.prototype.renderPredecessorDeleteConfirmDialog = function () {
    this.confirmPredecessorDialog = new Dialog({
      width: '320px',
      isModal: true,
      enableRtl: this.parent.enableRtl,
      content: this.parent.localeObj.getConstant('confirmPredecessorDelete'),
      buttons: [{
        click: this.confirmOkDeleteButton.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('okText'),
          isPrimary: true
        }
      }, {
        click: this.confirmCloseDialog.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('cancel')
        }
      }],
      target: this.parent.element,
      animationSettings: {
        effect: 'None'
      }
    });
    var confirmDialog = createElement('div', {
      id: this.parent.element.id + '_deletePredecessorConfirmDialog'
    });
    this.parent.element.appendChild(confirmDialog);
    this.confirmPredecessorDialog.isStringTemplate = true;
    this.confirmPredecessorDialog.appendTo(confirmDialog);
  };
  ConnectorLineEdit.prototype.confirmCloseDialog = function () {
    this.confirmPredecessorDialog.destroy();
  };
  ConnectorLineEdit.prototype.confirmOkDeleteButton = function () {
    this.removePredecessorByIndex(this.childRecord, this.predecessorIndex);
    this.confirmPredecessorDialog.destroy();
  };
  return ConnectorLineEdit;
}();
var __assign$4 = undefined && undefined.__assign || function () {
  __assign$4 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$4.apply(this, arguments);
};
/**
 * The Edit Module is used to handle editing actions.
 *
 */
var Edit = /** @__PURE__ @class */function () {
  function Edit(parent) {
    this.isFromDeleteMethod = false;
    this.targetedRecords = [];
    this.isNewRecordAdded = false;
    this.isValidatedEditedRecord = false;
    this.createArray = true;
    this.deletedRecord = [];
    this.canReset = false;
    /** @hidden */
    this.updateParentRecords = [];
    /** @hidden */
    this.isaddtoBottom = false;
    this.confirmDialog = null;
    this.taskbarMoved = false;
    this.predecessorUpdated = false;
    this.isBreakLoop = false;
    /**
     * @private
     */
    this.deletedTaskDetails = [];
    this.parent = parent;
    this.parent.predecessorModule.validatedChildItems = [];
    if (this.parent.editSettings.allowEditing && this.parent.editSettings.mode === 'Auto') {
      this.cellEditModule = new CellEdit(this.parent);
    }
    if (this.parent.taskFields.dependency) {
      this.parent.connectorLineEditModule = new ConnectorLineEdit(this.parent);
    }
    if (this.parent.editSettings.allowAdding || this.parent.editSettings.allowEditing && (this.parent.editSettings.mode === 'Dialog' || this.parent.editSettings.mode === 'Auto')) {
      this.dialogModule = new DialogEdit(this.parent);
    }
    if (this.parent.editSettings.allowTaskbarEditing) {
      this.taskbarEditModule = new TaskbarEdit(this.parent);
    }
    if (this.parent.editSettings.allowDeleting) {
      var confirmDialog = createElement('div', {
        id: this.parent.element.id + '_deleteConfirmDialog'
      });
      this.parent.element.appendChild(confirmDialog);
      this.renderDeleteConfirmDialog();
    }
    this.parent.treeGrid.recordDoubleClick = this.recordDoubleClick.bind(this);
    this.parent.treeGrid.editSettings.allowAdding = this.parent.editSettings.allowAdding;
    this.parent.treeGrid.editSettings.allowDeleting = this.parent.editSettings.allowDeleting;
    this.parent.treeGrid.editSettings.showDeleteConfirmDialog = this.parent.editSettings.showDeleteConfirmDialog;
    this.parent.treeGrid.editSettings.allowNextRowEdit = this.parent.editSettings.allowNextRowEdit;
    if (this.parent.editSettings.mode === 'Dialog') {
      TreeGrid.Inject(Edit$1);
    }
    this.updateDefaultColumnEditors();
  }
  Edit.prototype.getModuleName = function () {
    return 'edit';
  };
  /**
   * Method to update default edit params and editors for Gantt
   *
   * @returns {void} .
   */
  Edit.prototype.updateDefaultColumnEditors = function () {
    var customEditorColumns = [this.parent.taskFields.id, this.parent.taskFields.progress, this.parent.taskFields.resourceInfo, this.parent.taskFields.type, 'taskType'];
    for (var i = 0; i < customEditorColumns.length; i++) {
      if (!isNullOrUndefined(customEditorColumns[i]) && customEditorColumns[i].length > 0) {
        var column = this.parent.getColumnByField(customEditorColumns[parseInt(i.toString(), 10)], this.parent.treeGridModule.treeGridColumns);
        if (column) {
          if (column.field === this.parent.taskFields.id) {
            this.updateIDColumnEditParams(column);
          } else if (column.field === this.parent.taskFields.progress && isNullOrUndefined(column.edit)) {
            this.updateProgessColumnEditParams(column);
          } else if (column.field === this.parent.taskFields.resourceInfo) {
            this.updateResourceColumnEditor(column);
          } else if (column.field === this.parent.taskFields.type || column.field === 'taskType') {
            this.updateTaskTypeColumnEditor(column);
          }
        }
      }
    }
  };
  /**
   * Method to update editors for id column in Gantt
   *
   * @param {ColumnModel} column .
   * @returns {void} .
   */
  Edit.prototype.updateIDColumnEditParams = function (column) {
    var editParam = {
      min: 0,
      decimals: 0,
      enableRtl: this.parent.enableRtl,
      validateDecimalOnType: true,
      format: 'n0',
      showSpinButton: false
    };
    this.updateEditParams(column, editParam);
  };
  /**
   * Method to update edit params of default progress column
   *
   * @param {ColumnModel} column .
   * @returns {void} .
   */
  Edit.prototype.updateProgessColumnEditParams = function (column) {
    var editParam = {
      min: 0,
      enableRtl: this.parent.enableRtl,
      decimals: 0,
      validateDecimalOnType: true,
      max: 100,
      format: 'n0'
    };
    this.updateEditParams(column, editParam);
  };
  /**
   * Assign edit params for id and progress columns
   *
   * @param {ColumnModel} column .
   * @param {object} editParam .
   * @returns {void} .
   */
  Edit.prototype.updateEditParams = function (column, editParam) {
    if (isNullOrUndefined(column.edit)) {
      column.edit = {};
      column.edit.params = {};
    } else if (isNullOrUndefined(column.edit.params)) {
      column.edit.params = {};
    }
    extend(editParam, column.edit.params);
    column.edit.params = editParam;
    var ganttColumn = this.parent.getColumnByField(column.field, this.parent.ganttColumns);
    ganttColumn.edit = column.edit;
  };
  /**
   * Method to update resource column editor for default resource column
   *
   * @param {ColumnModel} column .
   * @returns {void} .
   */
  Edit.prototype.updateResourceColumnEditor = function (column) {
    if (this.parent.editSettings.allowEditing && isNullOrUndefined(column.edit) && this.parent.editSettings.mode === 'Auto') {
      column.editType = 'dropdownedit';
      column.edit = this.getResourceEditor();
      var ganttColumn = this.parent.getColumnByField(column.field, this.parent.ganttColumns);
      ganttColumn.editType = 'dropdownedit';
      ganttColumn.edit = column.edit;
    }
  };
  /**
   * Method to create resource custom editor
   *
   * @returns {IEditCell} .
   */
  Edit.prototype.getResourceEditor = function () {
    var _this = this;
    var resourceSettings = this.parent.resourceFields;
    var editObject = {};
    var editor;
    MultiSelect.Inject(CheckBoxSelection);
    editObject.write = function (args) {
      _this.parent.treeGridModule.currentEditRow = {};
      editor = new MultiSelect({
        dataSource: new DataManager(_this.parent.resources),
        fields: {
          text: resourceSettings.name,
          value: resourceSettings.id
        },
        enableRtl: _this.parent.enableRtl,
        mode: 'CheckBox',
        showDropDownIcon: true,
        popupHeight: '350px',
        delimiterChar: ',',
        value: _this.parent.treeGridModule.getResourceIds(args.rowData)
      });
      editor.appendTo(args.element);
    };
    editObject.read = function (element) {
      var value = element.ej2_instances[0].value;
      var resourcesName = [];
      if (isNullOrUndefined(value)) {
        value = [];
      }
      for (var i = 0; i < value.length; i++) {
        for (var j = 0; j < _this.parent.resources.length; j++) {
          if (_this.parent.resources[j][resourceSettings.id] === value[i]) {
            resourcesName.push(_this.parent.resources[j][resourceSettings.name]);
            break;
          }
        }
      }
      _this.parent.treeGridModule.currentEditRow[_this.parent.taskFields.resourceInfo] = value;
      return resourcesName.join(',');
    };
    editObject.destroy = function () {
      if (editor) {
        editor.destroy();
      }
    };
    return editObject;
  };
  /**
   * Method to update task type column editor for task type
   *
   * @param {ColumnModel} column .
   * @returns {void} .
   */
  Edit.prototype.updateTaskTypeColumnEditor = function (column) {
    if (this.parent.editSettings.allowEditing && isNullOrUndefined(column.edit) && this.parent.editSettings.mode === 'Auto') {
      column.editType = 'dropdownedit';
      column.edit = this.getTaskTypeEditor();
      var ganttColumn = this.parent.getColumnByField(column.field, this.parent.ganttColumns);
      ganttColumn.editType = 'dropdownedit';
      ganttColumn.edit = column.edit;
    }
  };
  /**
   * Method to create task type custom editor
   *
   * @returns {IEditCell} .
   */
  Edit.prototype.getTaskTypeEditor = function () {
    var _this = this;
    var editObject = {};
    var editor;
    var types = [{
      'ID': 1,
      'Value': 'FixedUnit'
    }, {
      'ID': 2,
      'Value': 'FixedWork'
    }, {
      'ID': 3,
      'Value': 'FixedDuration'
    }];
    editObject.write = function (args) {
      _this.parent.treeGridModule.currentEditRow = {};
      editor = new DropDownList({
        dataSource: new DataManager(types),
        enableRtl: _this.parent.enableRtl,
        fields: {
          value: 'Value'
        },
        popupHeight: '350px',
        value: getValue('taskType', args.rowData.ganttProperties)
      });
      editor.appendTo(args.element);
    };
    editObject.read = function (element) {
      var value = element.ej2_instances[0].value;
      var key = _this.parent.taskFields.type || 'taskType';
      _this.parent.treeGridModule.currentEditRow[key] = value;
      return value;
    };
    editObject.destroy = function () {
      if (editor) {
        editor.destroy();
      }
    };
    return editObject;
  };
  /**
   * @returns {void} .
   * @private
   */
  Edit.prototype.reUpdateEditModules = function () {
    var editSettings = this.parent.editSettings;
    if (editSettings.allowEditing) {
      if (this.parent.editModule.cellEditModule && editSettings.mode === 'Dialog') {
        this.cellEditModule.destroy();
        this.parent.treeGrid.recordDoubleClick = this.recordDoubleClick.bind(this);
      } else if (isNullOrUndefined(this.parent.editModule.cellEditModule) && editSettings.mode === 'Auto') {
        this.cellEditModule = new CellEdit(this.parent);
      }
      if (this.parent.editModule.dialogModule && editSettings.mode === 'Auto') {
        this.parent.treeGrid.recordDoubleClick = undefined;
      } else if (isNullOrUndefined(this.parent.editModule.dialogModule)) {
        this.dialogModule = new DialogEdit(this.parent);
      }
    } else {
      if (this.cellEditModule) {
        this.cellEditModule.destroy();
      }
      if (this.dialogModule) {
        this.dialogModule.destroy();
      }
    }
    if (editSettings.allowDeleting && editSettings.showDeleteConfirmDialog) {
      if (isNullOrUndefined(this.confirmDialog)) {
        var confirmDialog = createElement('div', {
          id: this.parent.element.id + '_deleteConfirmDialog'
        });
        this.parent.element.appendChild(confirmDialog);
        this.renderDeleteConfirmDialog();
      }
    } else if (!editSettings.allowDeleting || !editSettings.showDeleteConfirmDialog) {
      if (this.confirmDialog && !this.confirmDialog.isDestroyed) {
        this.confirmDialog.destroy();
      }
    }
    if (editSettings.allowTaskbarEditing) {
      if (isNullOrUndefined(this.parent.editModule.taskbarEditModule)) {
        this.taskbarEditModule = new TaskbarEdit(this.parent);
      }
    } else {
      if (this.taskbarEditModule) {
        this.taskbarEditModule.destroy();
      }
    }
  };
  Edit.prototype.recordDoubleClick = function (args) {
    if (this.parent.editSettings.allowEditing && this.parent.editSettings.mode === 'Dialog') {
      var ganttData = void 0;
      if (args.row) {
        var rowIndex = getValue('rowIndex', args.row);
        ganttData = this.parent.currentViewData[rowIndex];
      }
      if (!isNullOrUndefined(ganttData)) {
        this.dialogModule.openEditDialog(ganttData);
      }
    }
    this.parent.ganttChartModule.recordDoubleClick(args);
  };
  /**
   * @returns {void} .
   * @private
   */
  Edit.prototype.destroy = function () {
    if (this.cellEditModule) {
      this.cellEditModule.destroy();
    }
    if (this.taskbarEditModule) {
      this.taskbarEditModule.destroy();
    }
    if (this.dialogModule) {
      this.dialogModule.destroy();
    }
    if (this.confirmDialog && !this.confirmDialog.isDestroyed) {
      this.confirmDialog.destroy();
    }
  };
  /**
   * Method to update record with new values.
   *
   * @param {Object} data - Defines new data to update.
   * @returns {void} .
   */
  Edit.prototype.updateRecordByID = function (data) {
    var _this = this;
    if (this.parent.enableImmutableMode && this.parent.editSettings.allowEditing && this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0) {
      this.parent.treeGrid.endEdit();
    }
    if (!this.parent.readOnly) {
      var tasks = this.parent.taskFields;
      if (isNullOrUndefined(data) || isNullOrUndefined(data[tasks.id])) {
        return;
      }
      var ganttData = this.parent.viewType === 'ResourceView' ? this.parent.flatData[this.parent.getTaskIds().indexOf('T' + data[tasks.id])] : this.parent.getRecordByID(data[tasks.id]);
      if (!isNullOrUndefined(ganttData[tasks.milestone])) {
        if (ganttData[tasks.milestone] === true) {
          ganttData[tasks.milestone] = false;
        }
      }
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent['isUndoRedoItemPresent']('Edit') && ganttData) {
        this.parent.undoRedoModule['createUndoCollection']();
        var details = {};
        details['requestType'] = this.parent.contextMenuModule && this.parent.contextMenuModule.item ? this.parent.contextMenuModule.item : 'methodUpdate';
        details['modifiedRecords'] = extend([], [ganttData], [], true);
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = details;
      }
      if (!isNullOrUndefined(this.parent.editModule) && ganttData) {
        this.parent.isOnEdit = true;
        this.validateUpdateValues(data, ganttData, true);
        if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
          if (this.parent.viewType === 'ProjectView' && data['ganttProperties'].predecessor) {
            var _loop_1 = function (i) {
              var isValid = ganttData.ganttProperties.predecessor.filter(function (pred) {
                if (pred.from === data['ganttProperties'].predecessor[i].from && pred.to === data['ganttProperties'].predecessor[i].to && data['ganttProperties'].predecessor[i].offset !== pred.offset) {
                  var record = _this.parent.flatData[_this.parent.ids.indexOf(pred.to)];
                  var changeValue = record.ganttProperties.predecessor.filter(function (pred) {
                    return pred.from === data['ganttProperties'].predecessor[i].from && pred.to === data['ganttProperties'].predecessor[i].to;
                  });
                  changeValue[0].offset = data['ganttProperties'].predecessor[i].offset;
                }
                return pred.from !== data['ganttProperties'].predecessor[i].from && pred.from !== data['ganttProperties'].predecessor[i].to;
              });
              if (isValid.length > 0) {
                for (var j = 0; j < isValid.length; j++) {
                  var record = this_1.parent.flatData[this_1.parent.ids.indexOf(isValid[j].from)];
                  for (var k = 0; k < record.ganttProperties.predecessor.length; k++) {
                    if (record.ganttProperties.predecessor[k].from === isValid[j].from && record.ganttProperties.predecessor[k].to === isValid[j].to) {
                      record.ganttProperties.predecessor.splice(k, 1);
                      break;
                    }
                  }
                }
                return "break";
              }
            };
            var this_1 = this;
            for (var i = 0; i < data['ganttProperties'].predecessor.length; i++) {
              var state_1 = _loop_1(i);
              if (state_1 === "break") break;
            }
          } else if (!data['ganttProperties'].predecessor && ganttData.ganttProperties.predecessor) {
            for (var i = 0; i < ganttData.ganttProperties.predecessor.length; i++) {
              var id = void 0;
              if (ganttData.ganttProperties.taskId.toString() === ganttData.ganttProperties.predecessor[i].from) {
                id = ganttData.ganttProperties.predecessor[i].to;
              } else {
                id = ganttData.ganttProperties.predecessor[i].from;
              }
              var parentRec = this.parent.flatData[this.parent.ids.indexOf(id)];
              for (var j = 0; j < parentRec.ganttProperties.predecessor.length; j++) {
                if (parentRec.ganttProperties.predecessor[j].from === ganttData.ganttProperties.predecessor[i].from && parentRec.ganttProperties.predecessor[j].to === ganttData.ganttProperties.predecessor[i].to) {
                  parentRec.ganttProperties.predecessor.splice(j, 1);
                }
              }
            }
          }
          ganttData.ganttProperties.resourceInfo = data['ganttProperties'].resourceInfo;
        }
        if (data[this.parent.taskFields.resourceInfo]) {
          if (ganttData.ganttProperties.duration === 0 && (ganttData.ganttProperties.taskType !== 'FixedWork' || this.parent['isConvertedMilestone'])) {
            this.parent.dataOperation.updateWorkWithDuration(ganttData);
          }
          if (!this.parent.undoRedoModule || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
            this.updateResourceRelatedFields(ganttData, 'resource');
          }
          this.parent.dateValidationModule.calculateEndDate(ganttData);
        }
        var keys = Object.keys(data);
        if (keys.indexOf(tasks.startDate) !== -1 || keys.indexOf(tasks.endDate) !== -1 || keys.indexOf(tasks.duration) !== -1) {
          this.parent.dataOperation.calculateScheduledValues(ganttData, ganttData.taskData, false);
        }
        this.parent.dataOperation.updateWidthLeft(ganttData);
        if (!isUndefined(data[this.parent.taskFields.dependency]) && data[this.parent.taskFields.dependency] !== ganttData.ganttProperties.predecessorsName) {
          this.parent.connectorLineEditModule.updatePredecessor(ganttData, data[this.parent.taskFields.dependency]);
        } else {
          var args = {};
          args.data = ganttData;
          if (this.parent.viewType === 'ResourceView') {
            args.action = 'methodUpdate';
          }
          this.parent.editModule.initiateUpdateAction(args);
        }
      }
    }
  };
  /**
   *
   * @param {object} data .
   * @param {IGanttData} ganttData .
   * @param {boolean} isFromDialog .
   * @returns {void} .
   * @private
   */
  Edit.prototype.validateUpdateValues = function (data, ganttData, isFromDialog) {
    var ganttObj = this.parent;
    var tasks = ganttObj.taskFields;
    var ganttPropByMapping = getSwapKey(ganttObj.columnMapping);
    var scheduleFieldNames = [];
    var isScheduleValueUpdated = false;
    for (var _i = 0, _b = Object.keys(data); _i < _b.length; _i++) {
      var key = _b[_i];
      if ([tasks.startDate, tasks.endDate, tasks.duration].indexOf(key) !== -1) {
        if (isNullOrUndefined(data["" + key]) && !ganttObj.allowUnscheduledTasks) {
          continue;
        }
        if (isFromDialog) {
          if (tasks.duration === key) {
            ganttObj.dataOperation.updateDurationValue(data[key], ganttData.ganttProperties);
            if (ganttData.ganttProperties.duration > 0 && ganttData.ganttProperties.isMilestone) {
              this.parent.setRecordValue('isMilestone', false, ganttData.ganttProperties, true);
            }
            ganttObj.dataOperation.updateMappingData(ganttData, ganttPropByMapping[key]);
          } else {
            var tempDate = typeof data[key] === 'string' ? new Date(data[key]) : data[key];
            if (key === tasks.endDate && isNullOrUndefined(ganttData.ganttProperties.startDate) && (isNullOrUndefined(data[tasks.duration]) || data[tasks.duration] === '' || Number.isNaN(data[tasks.duration]))) {
              tempDate = ganttData.ganttProperties.endDate;
            }
            ganttObj.setRecordValue(ganttPropByMapping[key], tempDate, ganttData.ganttProperties, true);
            ganttObj.dataOperation.updateMappingData(ganttData, ganttPropByMapping[key]);
          }
        } else {
          scheduleFieldNames.push(key);
          isScheduleValueUpdated = true;
        }
      } else if (tasks.resourceInfo === key) {
        var resourceData = ganttObj.dataOperation.setResourceInfo(data);
        if (this.parent.viewType === 'ResourceView') {
          if (JSON.stringify(resourceData) !== JSON.stringify(ganttData.ganttProperties.resourceInfo)) {
            this.parent.editModule.dialogModule.isResourceUpdate = true;
            this.parent.editModule.dialogModule.previousResource = !isNullOrUndefined(ganttData.ganttProperties.resourceInfo) ? ganttData.ganttProperties.resourceInfo.slice() : [];
          } else {
            this.parent.editModule.dialogModule.isResourceUpdate = false;
          }
        }
        if (!this.parent.undoRedoModule || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
          ganttData.ganttProperties.resourceInfo = resourceData;
        }
        ganttObj.dataOperation.updateMappingData(ganttData, 'resourceInfo');
      } else if (tasks.dependency === key) ;else if ([tasks.progress, tasks.notes, tasks.durationUnit, tasks.expandState, tasks.milestone, tasks.name, tasks.baselineStartDate, tasks.baselineEndDate, tasks.id, tasks.segments, tasks.cssClass].indexOf(key) !== -1) {
        var column = ganttObj.columnByField[key];
        /* eslint-disable-next-line */
        var value = data[key];
        if (!isNullOrUndefined(column) && (column.editType === 'datepickeredit' || column.editType === 'datetimepickeredit')) {
          value = ganttObj.dataOperation.getDateFromFormat(value);
        }
        var ganttPropKey = ganttPropByMapping[key];
        if (key === tasks.id) {
          ganttPropKey = 'taskId';
        } else if (key === tasks.name) {
          ganttPropKey = 'taskName';
        } else if (key === tasks.cssClass) {
          ganttPropKey = 'cssClass';
        } else if (key === tasks.milestone) {
          ganttPropKey = 'isMilestone';
          if (!isNullOrUndefined(tasks.duration)) {
            var ganttProp = ganttData.ganttProperties;
            var durationValue = data[tasks.duration];
            if (value) {
              durationValue = 0;
            } else {
              durationValue = durationValue <= 0 ? 1 : durationValue;
            }
            ganttObj.setRecordValue(tasks.duration, durationValue, ganttData, true);
            ganttObj.setRecordValue('duration', durationValue, ganttProp, true);
            ganttObj.setRecordValue('taskData.' + tasks.duration, durationValue, ganttData);
          }
        } else if (key === tasks.segments && !isNullOrUndefined(ganttData.ganttProperties.segments)) {
          ganttPropKey = 'segments';
          /* eslint-disable-next-line */
          if (data && !isNullOrUndefined(data[this.parent.taskFields.segments]) && data[this.parent.taskFields.segments].length > 0) {
            if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
              ganttData.ganttProperties.segments = data['ganttProperties'].segments;
            }
            var totDuration = 0;
            for (var i = 0; i < ganttData.ganttProperties.segments.length; i++) {
              totDuration = totDuration + ganttData.ganttProperties.segments[i].duration;
            }
            var sdate = ganttData.ganttProperties.startDate;
            /* eslint-disable-next-line */
            var edate = this.parent.dataOperation.getEndDate(sdate, totDuration, ganttData.ganttProperties.durationUnit, ganttData.ganttProperties, false);
            ganttObj.setRecordValue('endDate', ganttObj.dataOperation.getDateFromFormat(edate), ganttData.ganttProperties, true);
          }
        }
        if (!isNullOrUndefined(ganttPropKey)) {
          var seg = [];
          if (ganttPropKey === 'segments' && value && value.length > 0 && !isNullOrUndefined(ganttData.ganttProperties.segments)) {
            for (var i = 0; i < value.length; i++) {
              var segment = {};
              if (value[i][this.parent.taskFields.startDate]) {
                segment['startDate'] = value[i][this.parent.taskFields.startDate];
              }
              if (value[i][this.parent.taskFields.endDate]) {
                segment['endDate'] = value[i][this.parent.taskFields.endDate];
              }
              if (value[i][this.parent.taskFields.duration]) {
                segment['duration'] = parseFloat(value[i][this.parent.taskFields.duration]);
              }
              if (value[i][this.parent.taskFields.id]) {
                segment['id'] = value[i][this.parent.taskFields.id];
              }
              seg.push(segment);
            }
            ganttObj.setRecordValue(ganttPropKey, seg, ganttData.ganttProperties, true);
          } else {
            if (ganttPropKey !== 'segments' || ganttPropKey === 'segments' && isNullOrUndefined(value)) {
              ganttObj.setRecordValue(ganttPropKey, value, ganttData.ganttProperties, true);
            }
          }
        }
        if ((key === tasks.baselineStartDate || key === tasks.baselineEndDate) && ganttData.ganttProperties.baselineStartDate && ganttData.ganttProperties.baselineEndDate) {
          ganttObj.setRecordValue('baselineStartDate', ganttObj.dataOperation.checkBaselineStartDate(ganttData.ganttProperties.baselineStartDate, ganttData.ganttProperties), ganttData.ganttProperties, true);
          var dayEndTime = this.parent['getCurrentDayEndTime'](ganttData.ganttProperties.baselineEndDate);
          if (ganttData.ganttProperties.baselineEndDate && ganttData.ganttProperties.baselineEndDate.getHours() === 0 && dayEndTime !== 86400) {
            ganttObj.dataOperation.setTime(dayEndTime, ganttData.ganttProperties.baselineEndDate);
          }
          if (ganttData.ganttProperties.baselineStartDate && ganttData.ganttProperties.baselineEndDate && ganttData.ganttProperties.baselineStartDate.getTime() > ganttData.ganttProperties.baselineEndDate.getTime() || !isNullOrUndefined(ganttData.ganttProperties.baselineStartDate) && !isNullOrUndefined(ganttData.ganttProperties.startDate) && ganttData.ganttProperties.baselineStartDate.getTime() === ganttData.ganttProperties.startDate.getTime() && !isNullOrUndefined(ganttData.ganttProperties.baselineEndDate) && !isNullOrUndefined(ganttData.ganttProperties.endDate) && ganttData.ganttProperties.baselineEndDate.toLocaleDateString() === ganttData.ganttProperties.endDate.toLocaleDateString() && ganttData.ganttProperties.isMilestone) {
            ganttData.ganttProperties.baselineEndDate = ganttData.ganttProperties.baselineStartDate;
          }
          ganttObj.setRecordValue('baselineEndDate', ganttObj.dataOperation.checkBaselineEndDate(ganttData.ganttProperties.baselineEndDate), ganttData.ganttProperties, true);
          ganttObj.setRecordValue('baselineLeft', ganttObj.dataOperation.calculateBaselineLeft(ganttData.ganttProperties), ganttData.ganttProperties, true);
          ganttObj.setRecordValue('baselineWidth', ganttObj.dataOperation.calculateBaselineWidth(ganttData.ganttProperties), ganttData.ganttProperties, true);
        }
        ganttObj.setRecordValue('taskData.' + key, value, ganttData);
        /* eslint-disable-next-line */
        if (key === tasks.segments && data && !isNullOrUndefined(data[this.parent.taskFields.segments]) && data[this.parent.taskFields.segments].length > 0) {
          if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
            ganttData.ganttProperties.segments = data['ganttProperties'].segments;
          }
          ganttObj.dataOperation.setSegmentsInfo(ganttData, true);
        }
        ganttObj.setRecordValue(key, value, ganttData);
      } else if (tasks.indicators === key) {
        var value = data[key];
        ganttObj.setRecordValue('indicators', value, ganttData.ganttProperties, true);
        ganttObj.setRecordValue('taskData.' + key, value, ganttData);
        ganttObj.setRecordValue(key, value, ganttData);
      } else if (tasks.work === key) {
        ganttObj.setRecordValue('work', data[key], ganttData.ganttProperties, true);
        this.parent.dataOperation.updateMappingData(ganttData, 'work');
        this.parent.dataOperation.updateMappingData(ganttData, 'duration');
        this.parent.dataOperation.updateMappingData(ganttData, 'endDate');
      } else if (key === tasks.type) {
        ganttObj.setRecordValue('taskType', data[key], ganttData.ganttProperties, true);
        //this.parent.dataOperation.updateMappingData(ganttData, 'taskType');
      } else if (ganttObj.customColumns.indexOf(key) !== -1) {
        var column = ganttObj.columnByField[key];
        /* eslint-disable-next-line */
        var value = data[key];
        if (isNullOrUndefined(column.edit)) {
          if (column.editType === 'datepickeredit' || column.editType === 'datetimepickeredit') {
            value = ganttObj.dataOperation.getDateFromFormat(value);
          }
        }
        ganttObj.setRecordValue('taskData.' + key, value, ganttData);
        ganttObj.setRecordValue(key, value, ganttData);
      } else if (tasks.manual === key) {
        ganttObj.setRecordValue('isAutoSchedule', !data[key], ganttData.ganttProperties, true);
        this.parent.setRecordValue(key, data[key], ganttData);
        this.updateTaskScheduleModes(ganttData);
      }
    }
    if (isScheduleValueUpdated) {
      this.validateScheduleValues(scheduleFieldNames, ganttData, data);
    }
  };
  /**
   * To update duration, work, resource unit
   *
   * @param {IGanttData} currentData .
   * @param {string} column .
   * @returns {void} .
   */
  Edit.prototype.updateResourceRelatedFields = function (currentData, column) {
    var ganttProp = currentData.ganttProperties;
    var taskType = ganttProp.taskType ? ganttProp.taskType : this.parent.taskType;
    var isEffectDriven;
    var isAutoSchedule = ganttProp.isAutoSchedule;
    var resources = this.parent.editModule.dialogModule && this.parent.editModule.dialogModule['currentResources'] ? this.parent.editModule.dialogModule['currentResources'] : currentData.ganttProperties.resourceInfo;
    if (!isNullOrUndefined(resources)) {
      switch (taskType) {
        case 'FixedUnit':
          if (resources.length === 0) {
            return;
          } else if (isAutoSchedule && resources.length) {
            if (column === 'resource') {
              this.parent.dataOperation.updateWorkWithDuration(currentData);
            } else if (column === 'work') {
              this.parent.dataOperation.updateDurationWithWork(currentData);
            } else if (column === 'duration' || column === 'endDate') {
              this.parent.dataOperation.updateWorkWithDuration(currentData);
              if (ganttProp.duration === 0) {
                this.parent.setRecordValue('isMilestone', true, ganttProp, true);
              }
            }
          } else if (!isAutoSchedule && column === 'work') {
            this.parent.dataOperation.updateUnitWithWork(currentData);
          } else {
            this.parent.dataOperation.updateWorkWithDuration(currentData);
          }
          break;
        case 'FixedWork':
          if (resources.length === 0) {
            return;
          } else if (isAutoSchedule) {
            if (column === 'duration' || column === 'endDate') {
              this.parent.dataOperation.updateUnitWithWork(currentData);
              this.parent.dataOperation.updateDurationWithWork(currentData);
              if (ganttProp.duration === 0) {
                this.parent.setRecordValue('isMilestone', true, ganttProp, true);
              }
            } else {
              this.parent.dataOperation.updateDurationWithWork(currentData);
            }
          } else {
            if (column === 'work') {
              this.parent.dataOperation.updateUnitWithWork(currentData);
            } else {
              this.parent.dataOperation.updateWorkWithDuration(currentData);
            }
          }
          break;
        case 'FixedDuration':
          if (resources.length === 0) {
            // To validate the work column, if set duration as 0 via celledit action, where resource colection is 0
            this.parent.dataOperation.updateWorkWithDuration(currentData);
            return;
          }
          if (resources.length && (column === 'work' || isAutoSchedule && isEffectDriven && column === 'resource')) {
            if (column === 'work') {
              this.parent.dataOperation.updateUnitWithWork(currentData);
            } else {
              this.parent.dataOperation.updateWorkWithDuration(currentData);
              this.parent.dataOperation.updateUnitWithWork(currentData);
            }
          } else {
            this.parent.dataOperation.updateWorkWithDuration(currentData);
          }
          break;
      }
    }
    // To validate the work colum if set duration as 0, while resource is null/undefined
    else if (isNullOrUndefined(resources) && taskType === 'FixedDuration' && ganttProp.duration === 0) {
      this.parent.dataOperation.updateWorkWithDuration(currentData);
    }
  };
  Edit.prototype.validateScheduleValues = function (fieldNames, ganttData, data) {
    var ganttObj = this.parent;
    if (fieldNames.length > 2) {
      ganttObj.dataOperation.calculateScheduledValues(ganttData, data, false);
    } else if (fieldNames.length > 1) {
      this.validateScheduleByTwoValues(data, fieldNames, ganttData);
    } else {
      this.dialogModule.validateScheduleValuesByCurrentField(fieldNames[0], data[fieldNames[0]], ganttData);
    }
  };
  Edit.prototype.validateScheduleByTwoValues = function (data, fieldNames, ganttData) {
    var ganttObj = this.parent;
    var startDate;
    var endDate;
    var duration;
    var tasks = ganttObj.taskFields;
    var ganttProp = ganttData.ganttProperties;
    var isUnscheduledTask = ganttObj.allowUnscheduledTasks;
    if (fieldNames.indexOf(tasks.startDate) !== -1) {
      startDate = data[tasks.startDate];
    }
    if (fieldNames.indexOf(tasks.endDate) !== -1) {
      endDate = data[tasks.endDate];
    }
    if (fieldNames.indexOf(tasks.duration) !== -1) {
      duration = data[tasks.duration];
    }
    if (startDate && endDate || isUnscheduledTask && fieldNames.indexOf(tasks.startDate) !== -1 && fieldNames.indexOf(tasks.endDate) !== -1) {
      ganttObj.setRecordValue('startDate', ganttObj.dataOperation.getDateFromFormat(startDate), ganttProp, true);
      ganttObj.setRecordValue('endDate', ganttObj.dataOperation.getDateFromFormat(endDate), ganttProp, true);
      ganttObj.dataOperation.calculateDuration(ganttData);
    } else if (endDate && duration || isUnscheduledTask && fieldNames.indexOf(tasks.endDate) !== -1 && fieldNames.indexOf(tasks.duration) !== -1) {
      ganttObj.setRecordValue('endDate', ganttObj.dataOperation.getDateFromFormat(endDate), ganttProp, true);
      ganttObj.dataOperation.updateDurationValue(duration, ganttProp);
    } else if (startDate && duration || isUnscheduledTask && fieldNames.indexOf(tasks.startDate) !== -1 && fieldNames.indexOf(tasks.duration) !== -1) {
      ganttObj.setRecordValue('startDate', ganttObj.dataOperation.getDateFromFormat(startDate), ganttProp, true);
      ganttObj.dataOperation.updateDurationValue(duration, ganttProp);
    }
  };
  Edit.prototype.isTaskbarMoved = function (data) {
    var isMoved = false;
    var taskData = data.ganttProperties;
    var prevData = this.parent.previousRecords && this.parent.previousRecords[data.uniqueID];
    if (prevData && prevData.ganttProperties) {
      var prevStart = getValue('ganttProperties.startDate', prevData);
      var prevEnd = getValue('ganttProperties.endDate', prevData);
      var prevDuration = getValue('ganttProperties.duration', prevData);
      var prevDurationUnit = getValue('ganttProperties.durationUnit', prevData);
      var keys = Object.keys(prevData.ganttProperties);
      if (keys.indexOf('startDate') !== -1 || keys.indexOf('endDate') !== -1 || keys.indexOf('duration') !== -1 || keys.indexOf('durationUnit') !== -1) {
        if (isNullOrUndefined(prevStart) && !isNullOrUndefined(taskData.startDate) || isNullOrUndefined(prevEnd) && !isNullOrUndefined(taskData.endDate) || isNullOrUndefined(taskData.startDate) && !isNullOrUndefined(prevStart) || isNullOrUndefined(taskData.endDate) && !isNullOrUndefined(prevEnd) || prevStart && prevStart.getTime() !== taskData.startDate.getTime() || prevEnd && prevEnd.getTime() !== taskData.endDate.getTime() || !isNullOrUndefined(prevDuration) && prevDuration !== taskData.duration || !isNullOrUndefined(prevDuration) && prevDuration === taskData.duration && prevDurationUnit !== taskData.durationUnit) {
          isMoved = true;
        }
      }
    }
    return isMoved;
  };
  Edit.prototype.isPredecessorUpdated = function (data) {
    var isPredecessorUpdated = false;
    var prevData = this.parent.previousRecords[data.uniqueID];
    // eslint-disable-next-line
    if (prevData && prevData.ganttProperties && prevData.ganttProperties.hasOwnProperty('predecessor')) {
      if (data.ganttProperties.predecessorsName !== prevData.ganttProperties.predecessorsName && !(data.ganttProperties.predecessorsName === '' && isNullOrUndefined(prevData.ganttProperties.predecessorsName))) {
        isPredecessorUpdated = true;
      } else {
        this.parent.setRecordValue('predecessor', prevData.ganttProperties.predecessor, data.ganttProperties, true);
      }
    }
    return isPredecessorUpdated;
  };
  /**
   * Method to check need to open predecessor validate dialog
   *
   * @param {IGanttData} data .
   * @returns {boolean} .
   */
  Edit.prototype.isCheckPredecessor = function (data) {
    var isValidatePredecessor = false;
    var prevData = this.parent.previousRecords[data.uniqueID];
    if (prevData && this.parent.taskFields.dependency && this.parent.isInPredecessorValidation && this.parent.predecessorModule.getValidPredecessor(data).length > 0) {
      if (this.isTaskbarMoved(data)) {
        isValidatePredecessor = true;
      }
    }
    return isValidatePredecessor;
  };
  /**
   * Method to copy the ganttProperties values
   *
   * @param {IGanttData} data .
   * @param {IGanttData} updateData .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateGanttProperties = function (data, updateData) {
    var skipProperty = ['taskId', 'uniqueID', 'rowUniqueID', 'parentId'];
    Object.keys(data.ganttProperties).forEach(function (property) {
      if (skipProperty.indexOf(property) === -1) {
        updateData.ganttProperties[property] = data.ganttProperties[property];
      }
    });
  };
  /**
   * Method to update all dependent record on edit action
   *
   * @param {ITaskAddedEventArgs} args .
   * @returns {void} .
   * @private
   */
  Edit.prototype.initiateUpdateAction = function (args) {
    var isValidatePredecessor = this.isCheckPredecessor(args.data);
    var parentData;
    var childRecordIndex;
    if (!isNullOrUndefined(args.data.parentItem) && !isValidatePredecessor) {
      parentData = this.parent.getRecordByID(args.data.parentItem.taskId);
      if (this.isTaskbarMoved(args.data) && this.parent.predecessorModule.getValidPredecessor(parentData).length > 0 && this.parent.isInPredecessorValidation) {
        isValidatePredecessor = true;
      } else {
        isValidatePredecessor = false;
      }
    } else if (args.data.childRecords.length > 0 && !isValidatePredecessor) {
      isValidatePredecessor = this.isCheckPredecessor(args.data);
      if (!isValidatePredecessor && this.isTaskbarMoved(args.data)) {
        for (var i = 0; i < args.data.childRecords.length; i++) {
          if (this.parent.predecessorModule.getValidPredecessor(args.data.childRecords[i]).length > 0) {
            childRecordIndex = i;
            isValidatePredecessor = true;
          }
        }
      }
    }
    if (!this.parent.undoRedoModule || this.parent.undoRedoModule && !this.parent.undoRedoModule['currentAction'] || this.parent.undoRedoModule['currentAction'] && this.parent.undoRedoModule['currentAction']['action'] !== 'indent' && this.parent.undoRedoModule['currentAction']['action'] !== 'outdent') {
      this.taskbarMoved = this.isTaskbarMoved(args.data);
    }
    this.predecessorUpdated = this.isPredecessorUpdated(args.data);
    if (this.predecessorUpdated) {
      this.parent.isConnectorLineUpdate = true;
      this.parent.connectorLineEditModule.addRemovePredecessor(args.data);
    }
    var validateObject = {};
    if (isValidatePredecessor && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      if (!isNullOrUndefined(parentData)) {
        validateObject = this.parent.connectorLineEditModule.validateTypes(parentData, args.data);
      } else if (!isNullOrUndefined(childRecordIndex)) {
        validateObject = this.parent.connectorLineEditModule.validateTypes(args.data.childRecords[childRecordIndex], args.data);
      } else {
        validateObject = this.parent.connectorLineEditModule.validateTypes(args.data);
      }
      this.parent.isConnectorLineUpdate = true;
      if (!isNullOrUndefined(getValue('violationType', validateObject))) {
        var newArgs = this.validateTaskEvent(args);
        if (newArgs.validateMode.preserveLinkWithEditing === false && newArgs.validateMode.removeLink === false && newArgs.validateMode.respectLink === false) {
          this.parent.connectorLineEditModule.openValidationDialog(validateObject);
        } else {
          if (this.parent.editModule && this.parent.editModule.dialogModule && this.parent.editModule.dialogModule['isEdit'] && this.predecessorUpdated) {
            this.isValidatedEditedRecord = true;
            this.parent.predecessorModule.validatePredecessor(args.data, [], '');
          }
          this.parent.connectorLineEditModule.applyPredecessorOption();
        }
      } else {
        this.updateEditedTask(args);
      }
    } else {
      if (this.taskbarMoved) {
        this.parent.isConnectorLineUpdate = true;
      }
      this.updateEditedTask(args);
    }
  };
  /**
   *
   * @param {ITaskbarEditedEventArgs} editedEventArgs method to trigger validate predecessor link by dialog
   * @returns {IValidateArgs} .
   */
  Edit.prototype.validateTaskEvent = function (editedEventArgs) {
    var newArgs = {};
    this.resetValidateArgs();
    this.parent.currentEditedArgs = newArgs;
    newArgs.cancel = false;
    newArgs.data = editedEventArgs.data;
    newArgs.requestType = 'validateLinkedTask';
    newArgs.validateMode = this.parent.dialogValidateMode;
    newArgs.editEventArgs = editedEventArgs;
    this.parent.actionBeginTask(newArgs);
    return newArgs;
  };
  Edit.prototype.resetValidateArgs = function () {
    this.parent.dialogValidateMode.preserveLinkWithEditing = true;
    this.parent.dialogValidateMode.removeLink = false;
    this.parent.dialogValidateMode.respectLink = false;
  };
  Edit.prototype.validateChildPredecessors = function () {
    for (var i = 0; i < this.parent.predecessorModule.validatedChildItems.length; i++) {
      var child = this.parent.predecessorModule.validatedChildItems[i];
      if (child.ganttProperties.predecessor && child.ganttProperties.predecessor.length > 0) {
        this.parent.editedTaskBarItem = child;
        if (!this.isValidatedEditedRecord) {
          this.isFirstCall = true;
        }
        this.parent.predecessorModule.validatePredecessor(child, [], '');
      }
    }
  };
  /**
   *
   * @param {ITaskAddedEventArgs} args - Edited event args like taskbar editing, dialog editing, cell editing
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateEditedTask = function (args) {
    var ganttRecord = args.data;
    this.editedRecord = ganttRecord;
    if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      this.updateParentChildRecord(ganttRecord);
    }
    if (this.parent.predecessorModule) {
      this.parent.predecessorModule.isValidatedParentTaskID = '';
    }
    if ((this.parent.isConnectorLineUpdate || this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) && this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
      /* validating predecessor for updated child items */
      this.validateChildPredecessors();
      /** validating predecessor for current edited records */
      if (ganttRecord.ganttProperties.predecessor) {
        this.parent.isMileStoneEdited = ganttRecord.ganttProperties.isMilestone;
        if (this.taskbarMoved) {
          this.parent.editedTaskBarItem = ganttRecord;
        }
        if (!this.isValidatedEditedRecord) {
          this.isFirstCall = true;
          this.parent.predecessorModule.validatePredecessor(ganttRecord, [], '');
          this.parent.predecessorModule.isChildRecordValidated = [];
        }
        this.isValidatedEditedRecord = false;
      }
      this.parent.predecessorModule.isValidatedParentTaskID = '';
      if (this.parent.allowParentDependency && this.parent.predecessorModule.isValidatedParentTaskID !== ganttRecord.ganttProperties.taskId && ganttRecord.hasChildRecords && this.parent.previousRecords[ganttRecord.uniqueID].ganttProperties.startDate && args.action !== 'TaskbarEditing' && (!ganttRecord.hasChildRecords || ganttRecord.hasChildRecords && ganttRecord.ganttProperties.isAutoSchedule)) {
        this.parent.predecessorModule['updateChildItems'](ganttRecord);
        this.validateChildPredecessors();
      }
      this.parent.predecessorModule.isValidatedParentTaskID = '';
      if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
        for (var i = 0; i < ganttRecord.childRecords.length; i++) {
          if (ganttRecord.childRecords[i].ganttProperties.predecessor) {
            this.parent.predecessorModule.validatePredecessor(ganttRecord.childRecords[i], [], '');
          }
        }
      }
      this.updateParentItemOnEditing();
      this.parent.dataOperation.updateParentItems(ganttRecord, true);
    }
    /** Update parent up-to zeroth level */
    if (ganttRecord.parentItem) {
      if (this.parent.autoCalculateDateScheduling && !(this.parent.isLoad && this.parent.treeGrid.loadChildOnDemand && this.parent.taskFields.hasChildMapping)) {
        this.parent.dataOperation.updateParentItems(ganttRecord, true);
      }
      var parentData = this.parent.getRecordByID(ganttRecord.parentItem.taskId);
      if (!isNullOrUndefined(parentData)) {
        if (!parentData.ganttProperties.predecessorsName) {
          this.isFirstCall = true;
          this.parent.predecessorModule.validatePredecessor(parentData, [], '');
          this.updateParentItemOnEditing();
        }
      }
    }
    if (this.parent.updateOffsetOnTaskbarEdit && this.parent.connectorLineEditModule && args.data) {
      this.parent.connectorLineEditModule['validatedOffsetIds'] = [];
      this.parent.connectorLineEditModule['calculateOffset'](args.data);
    }
    this.parent.predecessorModule['validatedParentIds'] = [];
    if (isNullOrUndefined(this.dialogModule) || this.dialogModule && !this.dialogModule['isFromDialogPredecessor']) {
      this.initiateSaveAction(args);
    }
  };
  Edit.prototype.updateParentItemOnEditing = function () {
    var _this = this;
    var childRecord = getValue('parentRecord', this.parent.predecessorModule);
    for (var i = 0; i < childRecord.length; i++) {
      this.parent.dataOperation.updateParentItems(childRecord[i]);
    }
    if (this.parent.editModule['updateParentRecords'] && this.parent.editModule['updateParentRecords'].length > 0) {
      this.parent.editModule['updateParentRecords'].forEach(function (record) {
        if (record.ganttProperties.predecessor && record.ganttProperties.predecessor.length > 0) {
          _this.parent.predecessorModule.validatePredecessor(record, [], '');
        }
      });
    }
    for (var i = 0; i < childRecord.length; i++) {
      this.parent.dataOperation.updateParentItems(childRecord[i]);
    }
    setValue('parentRecord', [], this.parent.predecessorModule);
    setValue('parentIds', [], this.parent.predecessorModule);
  };
  /**
   * To update parent records while perform drag action.
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateParentChildRecord = function (data) {
    var ganttRecord = data;
    if (ganttRecord.hasChildRecords && this.taskbarMoved && (ganttRecord[this.parent.taskFields.manual] === false || this.parent.taskMode === 'Auto') && !isNullOrUndefined(this.parent.editModule.cellEditModule) && !this.parent.editModule.cellEditModule.isResourceCellEdited) {
      this.parent.predecessorModule['updateChildItems'](ganttRecord);
    }
    if (!isNullOrUndefined(this.parent.editModule.cellEditModule)) {
      this.parent.editModule.cellEditModule.isResourceCellEdited = false;
    }
  };
  /**
   * To update records while changing schedule mode.
   *
   * @param {IGanttData} data .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateTaskScheduleModes = function (data) {
    var currentValue = data[this.parent.taskFields.startDate];
    var ganttProp = data.ganttProperties;
    if (this.parent.taskFields.manual) {
      this.parent.setRecordValue(this.parent.taskFields.manual, !data.ganttProperties.isAutoSchedule, data);
    }
    if (data.hasChildRecords && ganttProp.isAutoSchedule) {
      this.parent.setRecordValue('startDate', ganttProp.autoStartDate, ganttProp, true);
      this.parent.setRecordValue('endDate', ganttProp.autoEndDate, ganttProp, true);
      this.parent.setRecordValue('StartDate', ganttProp.autoStartDate, data, true);
      this.parent.setRecordValue('EndDate', ganttProp.autoEndDate, data, true);
      this.parent.setRecordValue('taskData.StartDate', ganttProp.autoStartDate, data, true);
      this.parent.setRecordValue('taskData.EndDate', ganttProp.autoEndDate, data, true);
      this.parent.setRecordValue('width', this.parent.dataOperation.calculateWidth(data, true), ganttProp, true);
      this.parent.setRecordValue('left', this.parent.dataOperation.calculateLeft(ganttProp, true), ganttProp, true);
      this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(ganttProp.width, ganttProp.progress), ganttProp, true);
      this.parent.dataOperation.calculateDuration(data);
    } else if (data.hasChildRecords && !ganttProp.isAutoSchedule) {
      this.parent.dataOperation.updateWidthLeft(data);
      this.parent.dataOperation.calculateDuration(data);
      this.parent.setRecordValue('autoStartDate', ganttProp.autoStartDate, ganttProp, true);
      this.parent.setRecordValue('autoEndDate', ganttProp.autoEndDate, ganttProp, true);
      this.parent.setRecordValue('autoDuration', this.parent.dataOperation.calculateAutoDuration(data), ganttProp, true);
      this.parent.dataOperation.updateAutoWidthLeft(data);
    } else {
      var startDate = this.parent.dateValidationModule.checkStartDate(currentValue, data.ganttProperties);
      this.parent.setRecordValue('startDate', startDate, data.ganttProperties, true);
      this.parent.dataOperation.updateMappingData(data, 'startDate');
      this.parent.dateValidationModule.calculateEndDate(data);
      this.parent.setRecordValue('taskData.' + this.parent.taskFields.manual, data[this.parent.taskFields.manual], data);
      this.parent.dataOperation.updateWidthLeft(data);
    }
  };
  /**
   * To update progress value of parent tasks
   *
   * @param {IParent} cloneParent .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateParentProgress = function (cloneParent) {
    var parentProgress = 0;
    var parent = this.parent.getParentTask(cloneParent);
    var childRecords = parent.childRecords;
    var childCount = childRecords ? childRecords.length : 0;
    var totalProgress = 0;
    var milesStoneCount = 0;
    var taskCount = 0;
    var totalDuration = 0;
    var progressValues = {};
    if (childRecords) {
      for (var i = 0; i < childCount; i++) {
        if ((!childRecords[i].ganttProperties.isMilestone || childRecords[i].hasChildRecords) && isScheduledTask(childRecords[i].ganttProperties)) {
          progressValues = this.parent.dataOperation.getParentProgress(childRecords[i]);
          totalProgress += getValue('totalProgress', progressValues);
          totalDuration += getValue('totalDuration', progressValues);
        } else {
          milesStoneCount += 1;
        }
      }
      taskCount = childCount - milesStoneCount;
      parentProgress = taskCount > 0 ? Math.round(totalProgress / totalDuration) : 0;
      if (isNaN(parentProgress)) {
        parentProgress = 0;
      }
      this.parent.setRecordValue('progressWidth', this.parent.dataOperation.getProgressWidth(parent.ganttProperties.isAutoSchedule ? parent.ganttProperties.width : parent.ganttProperties.autoWidth, parentProgress), parent.ganttProperties, true);
      this.parent.setRecordValue('progress', Math.floor(parentProgress), parent.ganttProperties, true);
      this.parent.setRecordValue('totalProgress', totalProgress, parent.ganttProperties, true);
      this.parent.setRecordValue('totalDuration', totalDuration, parent.ganttProperties, true);
    }
    this.parent.dataOperation.updateTaskData(parent);
    if (parent.parentItem) {
      this.updateParentProgress(parent.parentItem);
    }
  };
  /**
   * Method to revert cell edit action
   *
   * @param {object} args .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  Edit.prototype.revertCellEdit = function (args) {
    this.parent.editModule.reUpdatePreviousRecords(false, true);
    this.resetEditProperties();
  };
  /**
   * @param {boolean} isRefreshChart .
   * @param {boolean} isRefreshGrid .
   * @returns {void} .
   * @private
   */
  Edit.prototype.reUpdatePreviousRecords = function (isRefreshChart, isRefreshGrid) {
    var collection = this.parent.previousRecords;
    var keys = Object.keys(collection);
    for (var i = 0; i < keys.length; i++) {
      var uniqueId = keys[i];
      var prevTask = collection[uniqueId];
      var originalData = this.parent.getTaskByUniqueID(uniqueId);
      this.copyTaskData(originalData.taskData, prevTask.taskData);
      delete prevTask.taskData;
      this.copyTaskData(originalData.ganttProperties, prevTask.ganttProperties);
      delete prevTask.ganttProperties;
      this.copyTaskData(originalData, prevTask);
      var rowIndex = this.parent.currentViewData.indexOf(originalData);
      if (isRefreshChart) {
        this.parent.chartRowsModule.refreshRow(rowIndex);
      }
      if (isRefreshGrid) {
        var dataId = this.parent.viewType === 'ProjectView' ? originalData.ganttProperties.taskId : originalData.ganttProperties.rowUniqueID;
        this.parent.treeGrid.grid.setRowData(dataId, originalData);
        var row = this.parent.treeGrid.grid.getRowObjectFromUID(this.parent.treeGrid.grid.getDataRows()[rowIndex].getAttribute('data-uid'));
        row.data = originalData;
      }
    }
  };
  /**
   * Copy previous task data value to edited task data
   *
   * @param {object} existing .
   * @param {object} newValue .
   * @returns {void} .
   */
  Edit.prototype.copyTaskData = function (existing, newValue) {
    if (!isNullOrUndefined(newValue)) {
      extend(existing, newValue);
    }
  };
  /**
   * To update schedule date on editing.
   *
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  Edit.prototype.updateScheduleDatesOnEditing = function (args) {
    //..
  };
  /**
   * @param {ITaskbarEditedEventArgs} args .
   * @returns {void} .
   * @private
   */
  Edit.prototype.initiateSaveAction = function (args) {
    var _this = this;
    var eventArgs = {};
    eventArgs.requestType = 'beforeSave';
    eventArgs.data = args.data;
    eventArgs.cancel = false;
    this.createArray = true;
    if (this.parent.toolbarModule && this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.undoRedoModule['getUndoCollection'].length > 0) {
      this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true); // enable toolbar items.
    }
    eventArgs.modifiedRecords = this.parent.editedRecords;
    if (!isNullOrUndefined(args.target)) {
      eventArgs.target = args.target;
    }
    eventArgs.modifiedTaskData = getTaskData(this.parent.editedRecords, true);
    if (args.action === 'DrawConnectorLine' || args.action === 'DeleteConnectorLine') {
      eventArgs.action = args.action;
      if (args.action === 'DeleteConnectorLine') {
        this.parent.connectorLineEditModule['isPublicDependencyDelete'] = false;
        if (this.parent.contextMenuModule) {
          this.parent.contextMenuModule['isCntxtMenuDependencyDelete'] = false;
        }
      }
    }
    var ganttObj = this.parent;
    var currentBaselineStart = __assign$4({}, eventArgs.data.ganttProperties.baselineStartDate);
    var currentBaselineEnd = __assign$4({}, eventArgs.data.ganttProperties.baselineEndDate);
    var currentProgress = eventArgs.data.ganttProperties.progress;
    /* eslint-disable-next-line */
    var unModifiedData = JSON.parse(JSON.stringify(eventArgs.data.ganttProperties));
    this.parent.trigger('actionBegin', eventArgs, function (eventArg) {
      if (currentBaselineStart !== eventArg.data['ganttProperties'].baselineStartDate || currentBaselineEnd !== eventArg.data['ganttProperties'].baselineEndDate) {
        ganttObj.setRecordValue('baselineLeft', ganttObj.dataOperation.calculateBaselineLeft(eventArg.data['ganttProperties']), eventArg.data['ganttProperties'], true);
        ganttObj.setRecordValue('baselineWidth', ganttObj.dataOperation.calculateBaselineWidth(eventArg.data['ganttProperties']), eventArg.data['ganttProperties'], true);
      }
      if (_this.parent.autoCalculateDateScheduling && unModifiedData !== eventArg.data['ganttProperties'] && !isNullOrUndefined(eventArg.data['parentItem'])) {
        _this.updateParentItemOnEditing();
        _this.parent.dataOperation.updateParentItems(eventArg.data, true);
      }
      var ganttProps = eventArg.data['ganttProperties'];
      var startDate = ganttProps.startDate,
        endDate = ganttProps.endDate,
        segments = ganttProps.segments,
        durationUnit = ganttProps.durationUnit,
        isAutoSchedule = ganttProps.isAutoSchedule,
        isMilestone = ganttProps.isMilestone;
      if (startDate && endDate && !segments) {
        var duration = _this.parent.dateValidationModule.getDuration(startDate, endDate, durationUnit, isAutoSchedule, isMilestone, true);
        if (duration !== 0) {
          ganttProps.isMilestone = false;
          _this.parent.dateValidationModule.calculateDuration(eventArg.data);
        }
      }
      _this.parent.dataOperation.updateWidthLeft(eventArg.data);
      if (!isNullOrUndefined(_this.parent.taskFields.progress) && currentProgress !== eventArg.data['ganttProperties'].progress) {
        var width = eventArg.data['ganttProperties'].isAutoSchedule ? eventArg.data['ganttProperties'].width : eventArg.data['ganttProperties'].autoWidth;
        _this.parent.setRecordValue('progressWidth', _this.parent.dataOperation.getProgressWidth(width, eventArg.data['ganttProperties'].progress), eventArg.data['ganttProperties'], true);
      }
      _this.parent.chartRowsModule.updateSegment(eventArg.data['ganttProperties'].segments, eventArg.data['ganttProperties'].taskId);
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
      if (eventArg.cancel) {
        _this.reUpdatePreviousRecords();
        _this.parent.chartRowsModule.refreshRecords([args.data]);
        _this.resetEditProperties(eventArgs);
        // Trigger action complete event with save canceled request type
      } else {
        // To update task data for modified records.
        if (eventArg.modifiedRecords) {
          for (var i = 0; i < eventArg.modifiedRecords.length; i++) {
            _this.parent.dataOperation.updateTaskData(eventArg.modifiedRecords[i]);
          }
        }
        eventArg.modifiedTaskData = getTaskData(eventArg.modifiedRecords, null, null, _this.parent);
        if (isRemoteData(_this.parent.dataSource)) {
          var data = _this.parent.dataSource;
          var updatedData = {
            changedRecords: eventArg.modifiedTaskData
          };
          var query = _this.parent.query instanceof Query ? _this.parent.query : new Query();
          var crud = null;
          var dataAdaptor = data.adaptor;
          if (!(dataAdaptor instanceof WebApiAdaptor && dataAdaptor instanceof ODataAdaptor) || data.dataSource.batchUrl) {
            crud = data.saveChanges(updatedData, _this.parent.taskFields.id, null, query);
          } else {
            var changedRecords = 'changedRecords';
            crud = data.update(_this.parent.taskFields.id, updatedData[changedRecords], null, query);
          }
          crud.then(function (e) {
            return _this.dmSuccess(e, args);
          }).catch(function (e) {
            return _this.dmFailure(e, args);
          });
        } else {
          _this.saveSuccess(args);
        }
        if (_this.parent.enableVirtualization && _this.parent.enableTimelineVirtualization) {
          _this.parent.ganttChartModule['setVirtualHeight']();
        }
        if (_this.parent.undoRedoModule) {
          _this.parent.previousFlatData = extend([], _this.parent.flatData, [], true);
        }
      }
    });
    this.parent.ganttChartModule.reRenderConnectorLines();
  };
  /* eslint-disable-next-line */
  Edit.prototype.updateEditedFields = function (e) {
    var eLength;
    var rec;
    if (e.changedRecords) {
      eLength = e.changedRecords['length'];
    } else {
      eLength = e['length'];
    }
    for (var i = 0; i < eLength; i++) {
      if (e.changedRecords) {
        rec = e.changedRecords[parseInt(i.toString(), 10)];
      } else {
        rec = e[parseInt(i.toString(), 10)];
      }
      var _aLength = Object.keys(rec).length;
      for (var j = 0, _a = Object.keys(rec); j < _aLength; j++) {
        var key = _a[parseInt(j.toString(), 10)];
        this.parent.editedRecords[parseInt(i.toString(), 10)]["" + key] = rec["" + key];
        this.parent.editedRecords[parseInt(i.toString(), 10)].taskData["" + key] = rec["" + key];
      }
      this.updateEditedRecordFields(rec, this.parent.editedRecords[parseInt(i.toString(), 10)]);
    }
  };
  /* eslint-disable-next-line */
  Edit.prototype.dmSuccess = function (e, args) {
    this.updateEditedFields(e);
    this.saveSuccess(args);
  };
  Edit.prototype.updateEditedRecordFields = function (rec, editedRecord) {
    var fields = this.parent.taskFields;
    var _aLength = Object.keys(rec).length;
    for (var j = 0, _a = Object.keys(rec); j < _aLength; j++) {
      var key = _a[parseInt(j.toString(), 10)];
      editedRecord["" + key] = rec["" + key];
    }
    if (fields.id !== null) {
      editedRecord.ganttProperties['taskId'] = rec[fields.id];
    }
    if (fields.name !== null) {
      editedRecord.ganttProperties['taskName'] = rec[fields.name];
    }
    if (fields.startDate !== null) {
      editedRecord.ganttProperties['startDate'] = rec[fields.startDate];
    }
    if (fields.endDate !== null) {
      editedRecord.ganttProperties['endDate'] = rec[fields.endDate];
    }
    if (fields.duration !== null) {
      editedRecord.ganttProperties['duration'] = parseFloat(rec[fields.duration]);
    }
    if (fields.durationUnit !== null) {
      editedRecord.ganttProperties['durationUnit'] = rec[fields.durationUnit];
    }
    if (fields.progress !== null) {
      editedRecord.ganttProperties['progress'] = rec[fields.progress];
    }
    if (fields.dependency !== null) {
      editedRecord.ganttProperties['dependency'] = rec[fields.dependency];
    }
    if (fields.parentID !== null) {
      editedRecord.ganttProperties['parentID'] = rec[fields.parentID];
    }
    if (fields.baselineEndDate !== null) {
      editedRecord.ganttProperties['baselineEndDate'] = rec[fields.baselineEndDate];
    }
    if (fields.baselineStartDate !== null) {
      editedRecord.ganttProperties['baselineStartDate'] = rec[fields.baselineStartDate];
    }
    if (fields.resourceInfo !== null) {
      editedRecord.ganttProperties['resources'] = rec[fields.resourceInfo];
    }
  };
  Edit.prototype.dmFailure = function (e, args) {
    if (this.deletedTaskDetails.length) {
      var deleteRecords = this.deletedTaskDetails;
      for (var d = 0; d < deleteRecords.length; d++) {
        deleteRecords[d].isDelete = false;
      }
      this.deletedTaskDetails = [];
    }
    this.reUpdatePreviousRecords(true, true);
    this.resetEditProperties();
    this.parent.trigger('actionFailure', {
      error: e
    });
  };
  Edit.prototype.updateSharedTask = function (data) {
    var ids = data.ganttProperties.sharedTaskUniqueIds;
    for (var i = 0; i < ids.length; i++) {
      var editRecord = this.parent.flatData[this.parent.ids.indexOf(ids[i].toString())];
      if (editRecord && editRecord.uniqueID !== data.uniqueID) {
        this.updateGanttProperties(data, editRecord);
        this.parent.setRecordValue('taskData', data.taskData, editRecord, true);
        this.parent.dataOperation.updateTaskData(editRecord);
        this.parent.dataOperation.updateResourceName(editRecord);
        if (!isNullOrUndefined(editRecord.parentItem)) {
          this.parent.dataOperation.updateParentItems(editRecord.parentItem);
        }
      }
    }
  };
  /**
   * Method for save action success for local and remote data
   *
   * @param {ITaskAddedEventArgs} args .
   * @returns {void} .
   */
  Edit.prototype.saveSuccess = function (args) {
    var eventArgs = {};
    if (this.parent.timelineSettings.updateTimescaleView) {
      var tempArray = this.parent.editedRecords;
      this.parent.timelineModule.updateTimeLineOnEditing([tempArray], args.action);
    }
    if (this.parent.viewType === 'ResourceView') {
      if (args.action === 'TaskbarEditing' || args.action === 'DrawConnectorLine' || args.action === 'DeleteConnectorLine') {
        this.updateSharedTask(args.data);
      } else if (args.action === 'DialogEditing' || args.action === 'CellEditing' || args.action === 'methodUpdate') {
        if (this.parent.editModule.dialogModule.isResourceUpdate) {
          /* eslint-disable-next-line */
          this.updateResoures(this.parent.editModule.dialogModule.previousResource, args.data.ganttProperties.resourceInfo, args.data);
          this.updateSharedTask(args.data);
          this.isTreeGridRefresh = true;
        } else {
          this.updateSharedTask(args.data);
        }
      }
      // method to update the edited parent records
      for (var k = 0; k < this.updateParentRecords.length; k++) {
        this.parent.dataOperation.updateParentItems(this.updateParentRecords[k]);
      }
      this.updateParentRecords = [];
      this.parent.editModule.dialogModule.isResourceUpdate = false;
      this.parent.editModule.dialogModule.previousResource = [];
    }
    var criticalModule = this.parent.criticalPathModule;
    if (this.parent.enableCriticalPath && criticalModule && criticalModule.criticalPathCollection) {
      criticalModule.showCriticalPath(true);
      criticalModule.criticalConnectorLine(criticalModule.criticalPathCollection, criticalModule.detailPredecessorCollection, true, criticalModule.predecessorCollectionTaskIds);
    }
    if (!this.isTreeGridRefresh) {
      if (this.parent.editSettings.allowEditing && this.parent.treeGrid.element.getElementsByClassName('e-editedbatchcell').length > 0) {
        if (!this.parent.treeGrid.grid.element.querySelector('form').ej2_instances[0].validate()) {
          setValue('isEdit', false, this.parent.treeGrid.grid);
          this.parent.editModule.cellEditModule.isCellEdit = false;
        }
        this.parent.treeGrid.endEdit();
      }
      this.parent.chartRowsModule.refreshRecords(this.parent.editedRecords);
      if (!this.parent.allowTaskbarOverlap && this.parent.showOverAllocation) {
        this.parent.contentHeight = this.parent['element'].getElementsByClassName('e-content')[0].children[0]['offsetHeight'];
        this.parent.ganttChartModule.chartBodyContent.style.height = this.parent.contentHeight + 'px';
        this.parent.ganttChartModule.renderRangeContainer(this.parent.currentViewData);
        if (this.parent.taskFields.dependency) {
          this.parent.ganttChartModule.reRenderConnectorLines();
        }
      }
      if ((this.parent.isConnectorLineUpdate || this.parent.undoRedoModule && this.parent.undoRedoModule['currentAction'] && this.parent.undoRedoModule['currentAction']['connectedRecords']) && !isNullOrUndefined(this.parent.connectorLineEditModule)) {
        this.parent.updatedConnectorLineCollection = [];
        this.parent.connectorLineIds = [];
        this.parent.connectorLineEditModule.refreshEditedRecordConnectorLine(this.parent.editedRecords);
        this.updateScheduleDatesOnEditing(args);
      }
    }
    if (!this.parent.editSettings.allowTaskbarEditing || this.parent.editSettings.allowTaskbarEditing && !this.taskbarEditModule.dependencyCancel) {
      eventArgs.requestType = 'save';
      eventArgs.data = args.data;
      eventArgs.modifiedRecords = this.parent.editedRecords;
      eventArgs.modifiedTaskData = getTaskData(this.parent.editedRecords, null, null, this.parent);
      this.updateRowIndex();
      if (!isNullOrUndefined(args.action)) {
        setValue('action', args.action, eventArgs);
      }
      if (args.action === 'TaskbarEditing') {
        eventArgs.taskBarEditAction = args.taskBarEditAction;
      }
      this.endEditAction(args);
      this.parent.trigger('actionComplete', eventArgs);
      if (this.parent.allowTaskbarDragAndDrop && this.parent.rowDragAndDropModule && this.parent.rowDragAndDropModule['draggedRecord']) {
        this.parent.rowDragAndDropModule['draggedRecord'] = null;
      }
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.hideMaskRow();
      } else {
        this.parent.hideSpinner();
      }
    } else {
      this.taskbarEditModule.dependencyCancel = false;
      this.resetEditProperties();
    }
    if (this.parent.viewType === 'ResourceView' && this.isTreeGridRefresh) {
      this.parent.treeGrid.parentData = [];
      this.parent.updatedConnectorLineCollection = [];
      this.parent.connectorLineIds = [];
      this.parent.predecessorModule.createConnectorLinesCollection(this.parent.flatData);
      this.parent.treeGrid.refresh();
      this.isTreeGridRefresh = false;
    }
    this.parent.editedRecords = [];
  };
  Edit.prototype.updateResoures = function (prevResource, currentResource, updateRecord) {
    var flatRecords = this.parent.flatData;
    var currentLength = currentResource ? currentResource.length : 0;
    var previousLength = prevResource ? prevResource.length : 0;
    if (currentLength === 0 && previousLength === 0) {
      return;
    }
    for (var index = 0; index < currentLength; index++) {
      var recordIndex = [];
      var resourceID = parseInt(currentResource[index][this.parent.resourceFields.id], 10).toString();
      if (resourceID === 'NaN') {
        resourceID = currentResource[index][this.parent.resourceFields.id];
      }
      for (var i = 0; i < prevResource.length; i++) {
        var prevResourceID = parseInt(prevResource[i][this.parent.resourceFields.id], 10).toString();
        if (prevResourceID === 'NaN') {
          prevResourceID = prevResource[i][this.parent.resourceFields.id];
        }
        if (prevResourceID === resourceID) {
          recordIndex.push(i);
          break;
        }
      }
      if (recordIndex.length === 0) {
        var parentRecord = flatRecords[this.parent.getTaskIds().indexOf('R' + resourceID)];
        if (parentRecord) {
          this.addNewRecord(updateRecord, parentRecord);
        }
      } else {
        var record1 = parseInt(recordIndex[0].toString(), 10);
        if (record1.toString() === 'NaN') {
          record1 = recordIndex[0].toString();
        }
        prevResource.splice(record1, 1);
      }
    }
    var prevLength = prevResource ? prevResource.length : 0;
    for (var index = 0; index < prevLength; index++) {
      var taskID = updateRecord.ganttProperties.taskId;
      var resourceID = prevResource[index][this.parent.resourceFields.id];
      var record = flatRecords[this.parent.getTaskIds().indexOf('R' + resourceID)];
      if (!isNullOrUndefined(record)) {
        for (var j = 0; j < record.childRecords.length; j++) {
          if (record.childRecords[j].ganttProperties.taskId === taskID) {
            this.removeChildRecord(record.childRecords[j]);
          }
        }
      }
    }
    if (currentLength > 0) {
      var parentTask = this.parent.getParentTask(updateRecord.parentItem);
      if (parentTask) {
        if (parentTask.ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask')) {
          this.removeChildRecord(updateRecord);
        }
      }
    }
    //Assign resource to unassigned task
    if (currentLength === 0) {
      this.checkWithUnassignedTask(updateRecord);
    }
    if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.flatData[this.parent.flatData.length - 1].ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask')) {
      this.parent.flatData.splice(this.parent.flatData.length - 1, 1);
      this.parent.currentViewData.splice(this.parent.currentViewData.length - 1, 1);
      this.parent.taskIds.splice(this.parent.flatData.length - 1, 1);
      this.parent.ids.splice(this.parent.flatData.length - 1, 1);
    }
  };
  /**
   * @param {IGanttData} updateRecord .
   * @returns {void} .
   * @private
   */
  Edit.prototype.checkWithUnassignedTask = function (updateRecord) {
    var unassignedTasks = null;
    // Block for check the unassigned task.
    for (var i = 0; i < this.parent.flatData.length; i++) {
      if (this.parent.flatData[i].ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask')) {
        unassignedTasks = this.parent.flatData[i];
      }
    }
    if (!isNullOrUndefined(unassignedTasks)) {
      this.addNewRecord(updateRecord, unassignedTasks);
    } else {
      // Block for create the unassigned task.
      var unassignTaskObj = {};
      unassignTaskObj[this.parent.taskFields.id] = 0;
      unassignTaskObj[this.parent.taskFields.name] = this.parent.localeObj.getConstant('unassignedTask');
      var beforeEditStatus = this.parent.isOnEdit;
      this.parent.isOnEdit = false;
      var cAddedRecord = this.parent.dataOperation.createRecord(unassignTaskObj, 0);
      this.parent.isOnEdit = beforeEditStatus;
      this.addRecordAsBottom(cAddedRecord);
      var parentRecord = this.parent.flatData[this.parent.flatData.length - 1];
      var index = this.parent.flatData.indexOf(parentRecord);
      var childIndex = index + 1 + parentRecord.childRecords.length;
      updateRecord.index = childIndex;
      updateRecord.level = parentRecord.level + 1;
      this.addNewRecord(updateRecord, parentRecord);
      /* eslint-disable-next-line */
      var source = this.parent.dataSource;
      source.push(updateRecord.taskData);
    }
    var updatedData = this.parent.currentViewData.filter(function (data) {
      return data.ganttProperties.taskId === updateRecord.ganttProperties.taskId && data.hasChildRecords === updateRecord.hasChildRecords;
    })[0];
    updateRecord.parentItem = updatedData.parentItem;
    updateRecord.parentUniqueID = updatedData.parentUniqueID;
  };
  Edit.prototype.addRecordAsBottom = function (cAddedRecord) {
    var recordIndex1 = this.parent.flatData.length;
    this.parent.currentViewData.splice(recordIndex1 + 1, 0, cAddedRecord);
    this.parent.flatData.splice(recordIndex1 + 1, 0, cAddedRecord);
    this.parent.ids.splice(recordIndex1 + 1, 0, cAddedRecord.ganttProperties.rowUniqueID.toString());
    var taskId = cAddedRecord.level === 0 ? 'R' + cAddedRecord.ganttProperties.taskId : 'T' + cAddedRecord.ganttProperties.taskId;
    this.parent.getTaskIds().splice(recordIndex1 + 1, 0, taskId);
    this.updateTreeGridUniqueID(cAddedRecord, 'add');
  };
  Edit.prototype.addNewRecord = function (updateRecord, parentRecord) {
    var cAddedRecord = null;
    cAddedRecord = extend({}, {}, updateRecord, true);
    this.parent.setRecordValue('uniqueID', getUid$1(this.parent.element.id + '_data_'), cAddedRecord);
    this.parent.setRecordValue('uniqueID', cAddedRecord.uniqueID, cAddedRecord.ganttProperties, true);
    var uniqueId = cAddedRecord.uniqueID.replace(this.parent.element.id + '_data_', '');
    this.parent.setRecordValue('rowUniqueID', uniqueId, cAddedRecord);
    this.parent.setRecordValue('rowUniqueID', uniqueId, cAddedRecord.ganttProperties, true);
    this.parent.setRecordValue('level', 1, cAddedRecord);
    if (this.parent.taskFields.parentID) {
      this.parent.setRecordValue('parentId', parentRecord.ganttProperties.taskId, cAddedRecord.ganttProperties, true);
    }
    this.parent.setRecordValue('parentItem', this.parent.dataOperation.getCloneParent(parentRecord), cAddedRecord);
    var parentUniqId = cAddedRecord.parentItem ? cAddedRecord.parentItem.uniqueID : null;
    this.parent.setRecordValue('parentUniqueID', parentUniqId, cAddedRecord);
    updateRecord.ganttProperties.sharedTaskUniqueIds.push(uniqueId);
    cAddedRecord.ganttProperties.sharedTaskUniqueIds = updateRecord.ganttProperties.sharedTaskUniqueIds;
    this.addRecordAsChild(parentRecord, cAddedRecord);
  };
  Edit.prototype.removeChildRecord = function (record) {
    var gObj = this.parent;
    var data = [];
    if (this.parent.dataSource instanceof DataManager && this.parent.dataSource.dataSource.json.length > 0) {
      data = this.parent.dataSource.dataSource.json;
    } else {
      data = this.parent.currentViewData;
    }
    var dataSource = this.parent.dataSource;
    var deletedRow = record;
    var flatParentData = this.parent.getParentTask(deletedRow.parentItem);
    if (deletedRow) {
      if (deletedRow.parentItem) {
        var deleteChildRecords = flatParentData ? flatParentData.childRecords : [];
        var childIndex = 0;
        if (deleteChildRecords && deleteChildRecords.length > 0) {
          if (deleteChildRecords.length === 1) {
            //For updating the parent record which has no child reords.
            this.parent.isOnDelete = true;
            deleteChildRecords[0].isDelete = true;
            this.parent.dataOperation.updateParentItems(flatParentData);
            this.parent.isOnDelete = false;
            deleteChildRecords[0].isDelete = false;
          }
          childIndex = deleteChildRecords.indexOf(deletedRow);
          flatParentData.childRecords.splice(childIndex, 1);
          // collection for updating parent record
          this.updateParentRecords.push(flatParentData);
        }
      }
      if (deletedRow.ganttProperties.sharedTaskUniqueIds.length) {
        var uniqueIDIndex = deletedRow.ganttProperties.sharedTaskUniqueIds.indexOf(deletedRow.ganttProperties.rowUniqueID);
        deletedRow.ganttProperties.sharedTaskUniqueIds.splice(uniqueIDIndex, 1);
      }
      this.updateTreeGridUniqueID(deletedRow, 'delete');
      //method to delete the record from datasource collection
      if (!this.parent.taskFields.parentID) {
        var deleteRecordIDs = [];
        deleteRecordIDs.push(deletedRow.ganttProperties.rowUniqueID.toString());
        if (this.parent.viewType === 'ProjectView') {
          this.parent.editModule.removeFromDataSource(deleteRecordIDs);
        }
      }
      var flatRecordIndex = this.parent.flatData.indexOf(deletedRow);
      if (gObj.taskFields.parentID) {
        var idx = void 0;
        var ganttData = this.parent.currentViewData;
        for (var i = 0; i < ganttData.length; i++) {
          if (ganttData[i].ganttProperties.rowUniqueID === deletedRow.ganttProperties.rowUniqueID) {
            idx = i;
          }
        }
        if (idx !== -1) {
          if (dataSource.length > 0) {
            dataSource.splice(idx, 1);
          }
          data.splice(idx, 1);
          this.parent.flatData.splice(flatRecordIndex, 1);
          this.parent.ids.splice(flatRecordIndex, 1);
          this.parent.getTaskIds().splice(flatRecordIndex, 1);
        }
      }
      var recordIndex = data.indexOf(deletedRow);
      if (!gObj.taskFields.parentID) {
        var deletedRecordCount = this.parent.editModule.getChildCount(deletedRow, 0);
        data.splice(recordIndex, deletedRecordCount + 1);
        this.parent.flatData.splice(flatRecordIndex, deletedRecordCount + 1);
        this.parent.ids.splice(flatRecordIndex, deletedRecordCount + 1);
        this.parent.getTaskIds().splice(flatRecordIndex, deletedRecordCount + 1);
      }
      if (deletedRow.parentItem && flatParentData && flatParentData.childRecords && !flatParentData.childRecords.length) {
        this.parent.setRecordValue('expanded', false, flatParentData);
        this.parent.setRecordValue('hasChildRecords', false, flatParentData);
      }
    }
  };
  // Method to add new record after resource edit
  Edit.prototype.addRecordAsChild = function (droppedRecord, draggedRecord) {
    var gObj = this.parent;
    var recordIndex1 = this.parent.flatData.indexOf(droppedRecord);
    var childRecords = this.parent.editModule.getChildCount(droppedRecord, 0);
    var childRecordsLength;
    var spliceIndex;
    var parentTask = this.parent.getTaskByUniqueID(draggedRecord.parentItem.uniqueID);
    if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.dialogModule['indexes'] && this.dialogModule['indexes'].deletedIndexes && this.dialogModule['indexes'].deletedIndexes.length > 0) {
      if (parentTask.ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask')) {
        childRecordsLength = this.parent.taskIds.length + 1;
      } else {
        for (var j = 0; j < this.dialogModule['indexes'].deletedIndexes.length; j++) {
          if (this.dialogModule['indexes'].deletedIndexes[j].data.parentUniqueID === draggedRecord.parentUniqueID && draggedRecord.ganttProperties.taskId === this.dialogModule['indexes'].deletedIndexes[j].data.ganttProperties.taskId) {
            var toIndex = this.dialogModule['indexes'].deletedIndexes[j].index;
            if (this.dialogModule['indexes'].deletedIndexes[j].position === 'above') {
              childRecordsLength = toIndex;
            } else {
              childRecordsLength = toIndex + 1;
            }
            for (var i = 0; i < droppedRecord.childRecords.length; i++) {
              if ('T' + droppedRecord.childRecords[i].ganttProperties.taskId === this.dialogModule['indexes'].deletedIndexes[j].id) {
                if (this.dialogModule['indexes'].deletedIndexes[j].position === 'above') {
                  spliceIndex = i;
                } else {
                  spliceIndex = i + 1;
                }
                break;
              }
            }
            break;
          } else if (this.dialogModule['indexes'].deletedIndexes[j].data.parentUniqueID !== draggedRecord.parentUniqueID && draggedRecord.ganttProperties.taskId === this.dialogModule['indexes'].deletedIndexes[j].data.ganttProperties.taskId) {
            var draggedParent = this.parent.getTaskByUniqueID(draggedRecord.parentItem.uniqueID);
            childRecordsLength = draggedParent.index + draggedParent.childRecords.length + 1;
            break;
          }
        }
      }
    } else if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && parentTask.ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask') && this.parent.undoRedoModule['currentAction']['deletedRecordsDetails']) {
      for (var i = 0; i < this.parent.undoRedoModule['currentAction']['deletedRecordsDetails'].length; i++) {
        if (this.parent.undoRedoModule['currentAction']['deletedRecordsDetails'][i].data.ganttProperties.taskId === draggedRecord.ganttProperties.taskId) {
          if (parentTask.index) {
            childRecordsLength = this.parent.undoRedoModule['currentAction']['deletedRecordsDetails'][i].data.index;
            spliceIndex = childRecordsLength - parentTask.index - 1;
          } else {
            childRecordsLength = isNullOrUndefined(childRecords) || childRecords === 0 ? recordIndex1 + 1 : childRecords + recordIndex1 + 1;
            spliceIndex = 0;
          }
          break;
        }
      }
    } else if (!isNullOrUndefined(this.addRowIndex) && this.addRowPosition && droppedRecord.childRecords && this.addRowPosition !== 'Child') {
      var dropChildRecord = droppedRecord.childRecords[this.addRowIndex];
      var position = this.addRowPosition === 'Above' || this.addRowPosition === 'Below' ? this.addRowPosition : 'Child';
      childRecordsLength = dropChildRecord ? this.addRowIndex + recordIndex1 + 1 : childRecords + recordIndex1 + 1;
      childRecordsLength = position === 'Above' ? childRecordsLength : childRecordsLength + 1;
    } else {
      childRecordsLength = isNullOrUndefined(childRecords) || childRecords === 0 ? recordIndex1 + 1 : childRecords + recordIndex1 + 1;
    }
    //this.ganttData.splice(childRecordsLength, 0, this.draggedRecord);
    this.parent.currentViewData.splice(childRecordsLength, 0, draggedRecord);
    this.parent.flatData.splice(childRecordsLength, 0, draggedRecord);
    this.parent.ids.splice(childRecordsLength, 0, draggedRecord.ganttProperties.rowUniqueID.toString());
    this.updateTreeGridUniqueID(draggedRecord, 'add');
    var recordId = draggedRecord.level === 0 ? 'R' + draggedRecord.ganttProperties.taskId : 'T' + draggedRecord.ganttProperties.taskId;
    this.parent.getTaskIds().splice(childRecordsLength, 0, recordId);
    if (!droppedRecord.hasChildRecords) {
      this.parent.setRecordValue('hasChildRecords', true, droppedRecord);
      this.parent.setRecordValue('expanded', true, droppedRecord);
      if (!droppedRecord.childRecords.length) {
        droppedRecord.childRecords = [];
        if (!gObj.taskFields.parentID && isNullOrUndefined(droppedRecord.taskData[this.parent.taskFields.child])) {
          droppedRecord.taskData[this.parent.taskFields.child] = [];
        }
      }
    }
    if (spliceIndex >= 0) {
      droppedRecord.childRecords.splice(spliceIndex, 0, draggedRecord);
    } else {
      droppedRecord.childRecords.splice(droppedRecord.childRecords.length, 0, draggedRecord);
    }
    if (!isNullOrUndefined(draggedRecord) && !this.parent.taskFields.parentID && !isNullOrUndefined(droppedRecord.taskData[this.parent.taskFields.child])) {
      droppedRecord.taskData[this.parent.taskFields.child].splice(droppedRecord.childRecords.length, 0, draggedRecord.taskData);
    }
    if (!isNullOrUndefined(draggedRecord.parentItem)) {
      //collection to update the parent records
      this.updateParentRecords.push(droppedRecord);
    }
  };
  Edit.prototype.resetEditProperties = function (args) {
    this.parent.currentEditedArgs = {};
    this.resetValidateArgs();
    this.parent.editedTaskBarItem = null;
    this.parent.isOnEdit = false;
    this.parent.predecessorModule.validatedChildItems = [];
    this.parent.isConnectorLineUpdate = false;
    this.parent.editedTaskBarItem = null;
    this.taskbarMoved = false;
    this.predecessorUpdated = false;
    if (!isNullOrUndefined(this.dialogModule) && (isNullOrUndefined(args) || !isNullOrUndefined(args) && args['requestType'] === 'beforeSave' && !args['cancel'])) {
      if (this.dialogModule.dialog && !this.dialogModule.dialogObj.isDestroyed) {
        this.dialogModule.dialogObj.hide();
      }
      this.dialogModule.dialogClose();
    }
    this.parent.hideSpinner();
    this.parent.initiateEditAction(false);
  };
  /**
   * @param {ITaskAddedEventArgs} args .
   * @returns {void} .
   * @private
   */
  Edit.prototype.endEditAction = function (args) {
    this.resetEditProperties();
    if (args.action === 'TaskbarEditing') {
      this.parent.trigger('taskbarEdited', args);
    } else if (args.action === 'CellEditing') {
      this.parent.trigger('endEdit', args);
    } else if (args.action === 'DialogEditing') {
      if (this.dialogModule.dialog && !this.dialogModule.dialogObj.isDestroyed) {
        this.dialogModule.dialogObj.hide();
      }
      this.dialogModule.dialogClose();
    }
  };
  // eslint-disable-next-line
  Edit.prototype.saveFailed = function (args) {
    this.reUpdatePreviousRecords();
    this.parent.hideSpinner();
    //action failure event trigger
  };
  /**
   * To render delete confirmation dialog
   *
   * @returns {void} .
   */
  Edit.prototype.renderDeleteConfirmDialog = function () {
    var dialogObj = new Dialog({
      width: '320px',
      isModal: true,
      visible: false,
      enableRtl: this.parent.enableRtl,
      content: this.parent.localeObj.getConstant('confirmDelete'),
      buttons: [{
        click: this.confirmDeleteOkButton.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('okText'),
          isPrimary: true
        }
      }, {
        click: this.closeConfirmDialog.bind(this),
        buttonModel: {
          content: this.parent.localeObj.getConstant('cancel')
        }
      }],
      target: this.parent.element,
      animationSettings: {
        effect: 'None'
      }
    });
    dialogObj.appendTo('#' + this.parent.element.id + '_deleteConfirmDialog');
    this.confirmDialog = dialogObj;
  };
  Edit.prototype.closeConfirmDialog = function () {
    this.confirmDialog.hide();
  };
  Edit.prototype.confirmDeleteOkButton = function () {
    this.deleteSelectedItems();
    this.confirmDialog.hide();
    var focussedElement = this.parent.element.querySelector('.e-treegrid');
    focussedElement.focus();
  };
  /**
   * @returns {void} .
   * @private
   */
  Edit.prototype.startDeleteAction = function () {
    if (this.parent.editSettings.allowDeleting && !this.parent.readOnly) {
      if (this.parent.editSettings.showDeleteConfirmDialog) {
        this.confirmDialog.show();
      } else {
        this.deleteSelectedItems();
        var focusingElement = this.parent.element.querySelector('.e-treegrid');
        focusingElement.focus();
      }
    }
  };
  /**
   *
   * @param {IGanttData[]} selectedRecords - Defines the deleted records
   * @returns {void} .
   * Method to delete the records from resource view Gantt.
   */
  Edit.prototype.deleteResourceRecords = function (selectedRecords) {
    var _this = this;
    var deleteRecords = [];
    var _loop_2 = function (i) {
      if (selectedRecords[i].parentItem) {
        if (selectedRecords[i].ganttProperties.sharedTaskUniqueIds.length === 1) {
          var data = selectedRecords[i];
          var ids = data.ganttProperties.sharedTaskUniqueIds;
          for (var j = 0; j < ids.length; j++) {
            if (this_2.parent.ids.indexOf(ids[j].toString()) !== -1) {
              deleteRecords.push(this_2.parent.flatData[this_2.parent.ids.indexOf(ids[j].toString())]);
            } else if (this_2.parent.undoRedoModule && this_2.parent.undoRedoModule['isUndoRedoPerformed'] && this_2.parent.undoRedoModule['currentAction'] && this_2.parent.undoRedoModule['currentAction']['action'] === 'Delete') {
              deleteRecords.push(this_2.parent.flatData[this_2.parent.taskIds.indexOf('T' + selectedRecords[i].ganttProperties.taskId)]);
            }
          }
          if (this_2.parent.ids.indexOf(data.ganttProperties.rowUniqueID) !== -1 && deleteRecords.indexOf(this_2.parent.flatData[this_2.parent.ids.indexOf(data.ganttProperties.rowUniqueID)]) === -1) {
            deleteRecords.push(this_2.parent.flatData[this_2.parent.ids.indexOf(data.ganttProperties.rowUniqueID)]);
          }
        } else if (selectedRecords[i].ganttProperties.sharedTaskUniqueIds.length > 1) {
          selectedRecords.forEach(function (record) {
            record.ganttProperties.sharedTaskUniqueIds.forEach(function (uniqueId) {
              deleteRecords.push(_this.parent.getTaskByUniqueID(_this.parent.element.id + '_data_' + uniqueId));
            });
          });
        }
      } else {
        var resourceParent = this_2.parent.flatData.filter(function (data) {
          return data.ganttProperties.taskId === selectedRecords[i].ganttProperties.taskId && data.hasChildRecords;
        })[0];
        deleteRecords.push(resourceParent);
      }
    };
    var this_2 = this;
    for (var i = 0; i < selectedRecords.length; i++) {
      _loop_2(i);
    }
    this.deleteRow(deleteRecords);
  };
  Edit.prototype.add = function (record, totalRecords) {
    totalRecords.push(record);
    if (record.hasChildRecords) {
      var child = record.childRecords;
      for (var i = 0; i < child.length; i++) {
        this.add(child[i], totalRecords);
      }
    } else {
      if (totalRecords.indexOf(record) === -1) {
        totalRecords.push(record);
      }
    }
  };
  Edit.prototype.deleteSelectedItems = function () {
    if (!this.isFromDeleteMethod) {
      var selectedRecords = [];
      if (this.parent.selectionSettings.mode !== 'Cell') {
        selectedRecords = this.parent.selectionModule.getSelectedRecords();
      } else if (this.parent.selectionSettings.mode === 'Cell') {
        selectedRecords = this.parent.selectionModule.getCellSelectedRecords();
      }
      if (this.parent.viewType === 'ResourceView') {
        this.deleteResourceRecords(selectedRecords);
      } else {
        this.deleteRow(selectedRecords);
      }
    } else {
      if (this.targetedRecords.length) {
        if (this.parent.viewType === 'ResourceView') {
          this.deleteResourceRecords(this.targetedRecords);
        } else {
          this.deleteRow(this.targetedRecords);
        }
      }
      this.isFromDeleteMethod = false;
    }
  };
  /**
   * Method to delete record.
   *
   * @param {number | string | number[] | string[] | IGanttData | IGanttData[]} taskDetail - Defines the details of data to delete.
   * @returns {void} .
   * @public
   */
  Edit.prototype.deleteRecord = function (taskDetail) {
    this.isFromDeleteMethod = true;
    var variableType = typeof taskDetail;
    this.targetedRecords = [];
    switch (variableType) {
      case 'number':
      case 'string':
        {
          var taskId = taskDetail.toString();
          if (this.parent.viewType === 'ResourceView') {
            if (!isNullOrUndefined(taskId) && this.parent.getTaskIds().indexOf('T' + taskId) !== -1) {
              this.targetedRecords.push(this.parent.flatData[this.parent.getTaskIds().indexOf('T' + taskId)]);
            }
          } else {
            if (!isNullOrUndefined(taskId) && this.parent.ids.indexOf(taskId) !== -1) {
              this.targetedRecords.push(this.parent.getRecordByID(taskId));
            }
          }
          break;
        }
      case 'object':
        if (!Array.isArray(taskDetail)) {
          this.targetedRecords.push(taskDetail.valueOf());
        } else {
          this.updateTargetedRecords(taskDetail);
        }
        break;
    }
    this.startDeleteAction();
  };
  /**
   * To update 'targetedRecords collection' from given array collection
   *
   * @param {object[]} taskDetailArray .
   * @returns {void} .
   */
  Edit.prototype.updateTargetedRecords = function (taskDetailArray) {
    if (taskDetailArray.length) {
      var variableType = typeof taskDetailArray[0];
      if (variableType === 'object') {
        this.targetedRecords = taskDetailArray;
      } else {
        // Get record from array of task ids
        for (var i = 0; i < taskDetailArray.length; i++) {
          var id = taskDetailArray[i].toString();
          if (this.parent.viewType === 'ResourceView') {
            if (!isNullOrUndefined(id) && this.parent.getTaskIds().indexOf('T' + id) !== -1) {
              this.targetedRecords.push(this.parent.flatData[this.parent.getTaskIds().indexOf('T' + id)]);
            }
          } else if (!isNullOrUndefined(id) && this.parent.ids.indexOf(id) !== -1) {
            this.targetedRecords.push(this.parent.getRecordByID(id));
          }
        }
      }
    }
  };
  Edit.prototype.deleteRow = function (tasks) {
    var rowItems = tasks && tasks.length ? tasks : this.parent.selectionModule.getSelectedRecords();
    this.parent.addDeleteRecord = true;
    if (rowItems.length) {
      this.parent.isOnDelete = true;
      rowItems.forEach(function (item) {
        item.isDelete = true;
      });
      if (this.parent.viewType === 'ResourceView' && !tasks.length) {
        rowItems = [];
      }
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent['isUndoRedoItemPresent']('Delete')) {
        if (this.parent.undoRedoModule['redoEnabled'] && !this.parent.undoRedoModule['isUndoRedoPerformed']) {
          this.parent.undoRedoModule['disableRedo']();
          this.parent.undoRedoModule['getUndoCollection'][0] = [];
        }
        if (!this.parent.undoRedoModule['isUndoRedoPerformed']) {
          this.parent.undoRedoModule['createUndoCollection']();
        }
        var records = {};
        records['action'] = 'Delete';
        records['deletedRecordsDetails'] = [];
        this.parent.undoRedoModule['findPosition'](extend([], [], rowItems, true), records, 'deletedRecordsDetails');
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = records;
      }
      var _loop_3 = function (i) {
        var deleteRecord = rowItems[i];
        if (this_3.deletedTaskDetails.indexOf(deleteRecord) !== -1) {
          return "continue";
        }
        var deleteItems = [deleteRecord];
        if (this_3.parent.viewType === 'ResourceView' && this_3.parent.undoRedoModule && this_3.parent.undoRedoModule['isUndoRedoPerformed'] && rowItems[i].ganttProperties.resourceInfo && rowItems[i].ganttProperties.resourceInfo.length > 1) {
          deleteItems = [];
          if (!rowItems[i].hasChildRecords) {
            var id_1 = 'T' + rowItems[i].ganttProperties.taskId;
            this_3.parent.taskIds.reduce(function (e, j) {
              if (e === id_1) {
                deleteItems.push(this.parent.flatData[j]);
              }
            }.bind(this_3), []);
          }
        }
        for (var j = 0; j < deleteItems.length; j++) {
          var parentTask = this_3.parent.getParentTask(deleteItems[j].parentItem);
          if (deleteItems[j].parentItem) {
            var childRecord = void 0;
            if (!isNullOrUndefined(parentTask)) {
              childRecord = parentTask.childRecords;
              var filteredRecord = childRecord.length === 1 ? childRecord : childRecord.filter(function (data) {
                return !data.isDelete;
              });
              if (filteredRecord.length > 0) {
                this_3.parent.dataOperation.updateParentItems(deleteItems[j].parentItem);
              }
            }
          }
          var predecessor = deleteItems[j].ganttProperties.predecessor;
          var canDeletePredecessor = true;
          if (this_3.parent.viewType === 'ResourceView' && parentTask && parentTask.ganttProperties.taskName !== this_3.parent.localeObj.getConstant('unassignedTask')) {
            canDeletePredecessor = false;
          }
          if (predecessor && predecessor.length && canDeletePredecessor) {
            this_3.removePredecessorOnDelete(deleteItems[j]);
          }
          this_3.deletedTaskDetails.push(deleteItems[j]);
          if (deleteItems[j].hasChildRecords) {
            this_3.deleteChildRecords(deleteItems[j]);
          }
        }
      };
      var this_3 = this;
      for (var i = 0; i < rowItems.length; i++) {
        _loop_3(i);
      }
      if (this.parent.selectionModule && this.parent.allowSelection) {
        // clear selection
        this.parent.selectionModule.clearSelection();
      }
      var delereArgs = {};
      delereArgs.deletedRecordCollection = this.deletedTaskDetails;
      delereArgs.updatedRecordCollection = this.parent.editedRecords;
      delereArgs.cancel = false;
      delereArgs.action = 'delete';
      this.initiateDeleteAction(delereArgs);
      this.parent.isOnDelete = false;
    }
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
  };
  Edit.prototype.removePredecessorOnDelete = function (record) {
    var predecessors = record.ganttProperties.predecessor;
    for (var i = 0; i < predecessors.length; i++) {
      var predecessor = predecessors[i];
      var recordId = this.parent.viewType === 'ResourceView' ? record.ganttProperties.taskId : record.ganttProperties.rowUniqueID;
      if (predecessor.from.toString() === recordId.toString()) {
        var toRecord = this.parent.connectorLineModule.getRecordByID(predecessor.to.toString());
        if (!isNullOrUndefined(toRecord)) {
          var toRecordPredcessor = extend([], [], toRecord.ganttProperties.predecessor, true);
          var index = void 0;
          for (var t = 0; t < toRecordPredcessor.length; t++) {
            var toId = this.parent.viewType === 'ResourceView' ? toRecord.ganttProperties.taskId : toRecord.ganttProperties.rowUniqueID;
            if (toRecordPredcessor[t].to.toString() === toId.toString() && toRecordPredcessor[t].from.toString() === recordId.toString()) {
              index = t;
              break;
            }
          }
          toRecordPredcessor.splice(index, 1);
          this.updatePredecessorValues(toRecord, toRecordPredcessor);
        }
      } else if (predecessor.to.toString() === recordId.toString()) {
        var fromRecord = this.parent.connectorLineModule.getRecordByID(predecessor.from.toString());
        if (!isNullOrUndefined(fromRecord)) {
          var fromRecordPredcessor = extend([], [], fromRecord.ganttProperties.predecessor, true);
          var index = void 0;
          for (var t = 0; t < fromRecordPredcessor.length; t++) {
            var fromId = this.parent.viewType === 'ResourceView' ? fromRecord.ganttProperties.taskId : fromRecord.ganttProperties.rowUniqueID;
            if (fromRecordPredcessor[t].from.toString() === fromId.toString() && fromRecordPredcessor[t].to.toString() === recordId.toString()) {
              index = t;
              break;
            }
          }
          if (record.uniqueID === fromRecord.parentUniqueID || record.parentUniqueID === fromRecord.uniqueID || this.parent.isOnDelete) {
            fromRecordPredcessor.splice(index, 1);
            this.updatePredecessorValues(fromRecord, fromRecordPredcessor);
          }
        }
      }
    }
  };
  Edit.prototype.updatePredecessorValues = function (record, predcessorArray) {
    this.parent.setRecordValue('predecessor', predcessorArray, record.ganttProperties, true);
    var predecessorString = this.parent.predecessorModule.getPredecessorStringValue(record);
    this.parent.setRecordValue('predecessorsName', predecessorString, record.ganttProperties, true);
    this.parent.setRecordValue('taskData.' + this.parent.taskFields.dependency, predecessorString, record);
    this.parent.setRecordValue(this.parent.taskFields.dependency, predecessorString, record);
  };
  /**
   * Method to update TaskID of a gantt record
   *
   * @param {string | number} currentId .
   * @param {number | string} newId .
   * @returns {void} .
   */
  Edit.prototype.updateTaskId = function (currentId, newId) {
    if (!this.parent.readOnly) {
      var cId = typeof currentId === 'number' ? currentId.toString() : currentId;
      var nId = typeof newId === 'number' ? newId.toString() : newId;
      var ids = this.parent.ids;
      if (!isNullOrUndefined(cId) && !isNullOrUndefined(nId)) {
        var cIndex = ids.indexOf(cId);
        var nIndex = ids.indexOf(nId);
        // return false for invalid taskID
        if (cIndex === -1 || nIndex > -1) {
          return;
        }
        var thisRecord = this.parent.flatData[cIndex];
        if (!isNullOrUndefined(thisRecord)) {
          this.parent.setRecordValue('taskId', newId, thisRecord.ganttProperties, true);
          this.parent.setRecordValue('rowUniqueID', nId, thisRecord.ganttProperties, true);
          this.parent.setRecordValue(this.parent.taskFields.id, newId, thisRecord, true);
          this.parent.setRecordValue(this.parent.taskFields.id, newId, thisRecord.taskData, true);
        }
        ids[cIndex] = nId;
        if (thisRecord.hasChildRecords && this.parent.taskFields.parentID) {
          var childRecords = thisRecord.childRecords;
          for (var count = 0; count < childRecords.length; count++) {
            var childRecord = childRecords[count];
            childRecord[this.parent.taskFields.parentID] = newId;
            this.parent.chartRowsModule.refreshRecords([childRecord]);
          }
        }
        if (this.parent.taskFields.dependency && !isNullOrUndefined(thisRecord.ganttProperties.predecessor)) {
          var predecessors = thisRecord.ganttProperties.predecessor;
          var currentGanttRecord = void 0;
          for (var i = 0; i < predecessors.length; i++) {
            var predecessor = predecessors[i];
            if (predecessor.to === cId) {
              currentGanttRecord = this.parent.flatData[ids.indexOf(predecessor.from)];
            } else if (predecessor.from === cId) {
              currentGanttRecord = this.parent.flatData[ids.indexOf(predecessor.to)];
            }
            this.updatePredecessorOnUpdateId(currentGanttRecord, cId, nId);
          }
        }
        this.parent.treeGrid.parentData = [];
        this.parent.treeGrid.refresh();
      }
    }
  };
  Edit.prototype.updatePredecessorOnUpdateId = function (currentGanttRecord, cId, nId) {
    if (this.parent.flatData.indexOf(currentGanttRecord) > -1) {
      var pred = currentGanttRecord.ganttProperties.predecessor;
      for (var j = 0; j < pred.length; j++) {
        var pre = pred[j];
        if (pre.to === cId) {
          pre.to = nId;
        } else if (pre.from === cId) {
          pre.from = nId;
        }
      }
    }
    this.updatePredecessorValues(currentGanttRecord, currentGanttRecord.ganttProperties.predecessor);
  };
  Edit.prototype.deleteChildRecords = function (record) {
    var _this = this;
    var childRecords = record.childRecords;
    for (var c = 0; c < childRecords.length; c++) {
      var childRecord = childRecords[c];
      if (this.deletedTaskDetails.indexOf(childRecord) !== -1) {
        continue;
      }
      var predecessor = childRecord.ganttProperties.predecessor;
      var canDeletePredecessor = true;
      var parentTask = this.parent.getParentTask(childRecord.parentItem);
      if (this.parent.viewType === 'ResourceView' && parentTask && parentTask.ganttProperties.taskName !== this.parent.localeObj.getConstant('unassignedTask')) {
        canDeletePredecessor = false;
      }
      if (predecessor && predecessor.length && canDeletePredecessor) {
        this.removePredecessorOnDelete(childRecord);
      }
      if (!isNullOrUndefined(childRecord.ganttProperties.sharedTaskUniqueIds) && childRecord.ganttProperties.sharedTaskUniqueIds.length > 1) {
        childRecord.ganttProperties.sharedTaskUniqueIds.forEach(function (uniqueId) {
          _this.deletedTaskDetails.push(_this.parent.getTaskByUniqueID(_this.parent.element.id + '_data_' + uniqueId));
        });
      } else {
        this.deletedTaskDetails.push(childRecord);
      }
      if (childRecord.hasChildRecords) {
        this.deleteChildRecords(childRecord);
      }
    }
  };
  Edit.prototype.removeFromDataSource = function (deleteRecordIDs) {
    var dataSource;
    if (this.parent.dataSource instanceof DataManager) {
      dataSource = this.parent.dataSource.dataSource.json;
    } else {
      dataSource = this.parent.dataSource;
    }
    this.removeData(dataSource, deleteRecordIDs);
    this.isBreakLoop = false;
  };
  Edit.prototype.removeData = function (dataCollection, record) {
    for (var i = 0; i < dataCollection.length; i++) {
      if (this.isBreakLoop) {
        break;
      }
      if (record.indexOf(getValue(this.parent.taskFields.id, dataCollection[i]).toString()) !== -1) {
        if (dataCollection[i][this.parent.taskFields.child]) {
          var childRecords = dataCollection[i][this.parent.taskFields.child];
          this.removeData(childRecords, record);
        }
        record.splice(record.indexOf(getValue(this.parent.taskFields.id, dataCollection[i]).toString()), 1);
        dataCollection.splice(i, 1);
        if (record.length === 0) {
          this.isBreakLoop = true;
          break;
        }
      } else if (dataCollection[i][this.parent.taskFields.child]) {
        var childRecords = dataCollection[i][this.parent.taskFields.child];
        this.removeData(childRecords, record);
      }
    }
  };
  Edit.prototype.initiateDeleteAction = function (args) {
    var _this = this;
    this.parent.showSpinner();
    var eventArgs = {};
    eventArgs.requestType = 'beforeDelete';
    eventArgs.data = args.deletedRecordCollection;
    eventArgs.modifiedRecords = args.updatedRecordCollection;
    eventArgs.modifiedTaskData = getTaskData(args.updatedRecordCollection, null, null, this.parent);
    this.parent.trigger('actionBegin', eventArgs, function (eventArg) {
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
      if (eventArg.cancel) {
        var deleteRecords = _this.deletedTaskDetails;
        for (var d = 0; d < deleteRecords.length; d++) {
          deleteRecords[d].isDelete = false;
        }
        _this.deletedTaskDetails = [];
        _this.reUpdatePreviousRecords();
        _this.parent.initiateEditAction(false);
        _this.parent.hideSpinner();
      } else {
        if (isRemoteData(_this.parent.dataSource)) {
          var data_1 = _this.parent.dataSource;
          if (_this.parent.timezone) {
            eventArg.modifiedRecords.forEach(function (modifiedRecord) {
              updateDates(modifiedRecord, _this.parent);
            });
          }
          var updatedData_1 = {
            deletedRecords: getTaskData(eventArg.data, null, null, _this.parent),
            changedRecords: eventArg.modifiedTaskData
          };
          var adaptor = data_1.adaptor;
          var query_1 = _this.parent.query instanceof Query ? _this.parent.query : new Query();
          if (!(adaptor instanceof WebApiAdaptor && adaptor instanceof ODataAdaptor) || data_1.dataSource.batchUrl) {
            var crud = data_1.saveChanges(updatedData_1, _this.parent.taskFields.id, null, query_1);
            crud.then(function () {
              return _this.deleteSuccess(args);
            }).catch(function (e) {
              return _this.dmFailure(e, args);
            });
          } else {
            var deletedRecords = 'deletedRecords';
            var deleteCrud = null;
            for (var i = 0; i < updatedData_1[deletedRecords].length; i++) {
              deleteCrud = data_1.remove(_this.parent.taskFields.id, updatedData_1[deletedRecords][i], null, query_1);
            }
            deleteCrud.then(function () {
              var changedRecords = 'changedRecords';
              var _loop_4 = function (i) {
                if (updatedData_1['deletedRecords'].some(function (record) {
                  return record[_this.parent.taskFields.id] === updatedData_1[changedRecords][i].taskId;
                })) {
                  updatedData_1[changedRecords].splice(i, 1);
                }
              };
              for (var i = updatedData_1[changedRecords].length - 1; i >= 0; i--) {
                _loop_4(i);
              }
              var updateCrud = data_1.update(_this.parent.taskFields.id, updatedData_1[changedRecords], null, query_1);
              updateCrud.then(function () {
                return _this.deleteSuccess(args);
              }).catch(function (e) {
                return _this.dmFailure(e, args);
              });
            }).catch(function (e) {
              return _this.dmFailure(e, args);
            });
          }
        } else {
          _this.deleteSuccess(args);
        }
      }
    });
  };
  Edit.prototype.deleteSuccess = function (args) {
    var _this = this;
    var flatData = this.parent.flatData;
    var currentData = this.parent.currentViewData;
    var deletedRecords = [];
    for (var i = 0; i < args.deletedRecordCollection.length; i++) {
      if (this.parent.viewType === 'ProjectView') {
        var record = this.parent.getRecordByID(args.deletedRecordCollection[i].ganttProperties.taskId);
        if (!isNullOrUndefined(record)) {
          deletedRecords.push(record);
        }
      } else {
        var id = void 0;
        var hasChildRecords = args.deletedRecordCollection[i].hasChildRecords;
        var isUnassignedTask = args.deletedRecordCollection[i].ganttProperties.taskName === this.parent.localeObj.getConstant('unassignedTask');
        var isCollapsed = !args.deletedRecordCollection[i].expanded;
        if (hasChildRecords && isUnassignedTask && isCollapsed) {
          if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
            this.parent.hideMaskRow();
          } else {
            this.parent.hideSpinner();
          }
          this.deletedTaskDetails = [];
          this.parent.initiateEditAction(false);
          return;
        }
        if (args.deletedRecordCollection[i].hasChildRecords) {
          id = 'R' + args.deletedRecordCollection[i].ganttProperties.taskId;
        } else {
          id = 'T' + args.deletedRecordCollection[i].ganttProperties.taskId;
        }
        deletedRecords.push(this.parent.getTaskByUniqueID(args.deletedRecordCollection[i].uniqueID));
      }
    }
    var deleteRecordIDs = [];
    if (deletedRecords.length > 0) {
      var recordIndex = void 0;
      var record = void 0;
      if (this.parent.enableVirtualization) {
        recordIndex = this.parent.flatData.indexOf(deletedRecords[deletedRecords.length - 1]);
        this.parent.staticSelectedRowIndex = this.parent.selectedRowIndex = recordIndex;
        record = this.parent.flatData[this.parent.selectedRowIndex + 1];
        // perform toolbar CollapseAll later delete action issue handle
        if (!this.parent.isCollapseAll) {
          // Random parent record delete action, maintaining proper record selection
          this.parent.staticSelectedRowIndex = recordIndex - (deletedRecords.length - 1);
        }
      } else {
        recordIndex = this.parent.currentViewData.indexOf(deletedRecords[deletedRecords.length - 1]);
        this.parent.staticSelectedRowIndex = this.parent.selectedRowIndex = recordIndex;
        record = this.parent.currentViewData[this.parent.selectedRowIndex + 1];
      }
      if (!isNullOrUndefined(record)) {
        this.parent.currentSelection = record;
      } else {
        var prevRecordIndex = this.parent.selectedRowIndex - 1;
        if (prevRecordIndex >= 0) {
          this.parent.currentSelection = this.parent.enableVirtualization ? this.parent.flatData[prevRecordIndex] : this.parent.currentViewData[prevRecordIndex];
        }
      }
    }
    this.deletedRecord = deletedRecords;
    for (var i = 0; i < deletedRecords.length; i++) {
      var deleteRecord = deletedRecords[i];
      var currentIndex = void 0;
      var flatIndex = void 0;
      if (this.parent.viewType === 'ResourceView') {
        if (deleteRecord.hasChildRecords) {
          currentIndex = this.parent.taskIds.indexOf('R' + deleteRecord.ganttProperties.taskId.toString());
          flatIndex = this.parent.taskIds.indexOf('R' + deleteRecord.ganttProperties.taskId.toString());
        } else {
          currentIndex = this.parent.taskIds.indexOf('T' + deleteRecord.ganttProperties.taskId.toString());
          flatIndex = this.parent.taskIds.indexOf('T' + deleteRecord.ganttProperties.taskId.toString());
        }
      } else {
        currentIndex = currentData.indexOf(deleteRecord);
        flatIndex = flatData.indexOf(deleteRecord);
      }
      var treeGridParentIndex = this.parent.treeGrid.parentData.indexOf(deleteRecord);
      var tempData = getValue('dataOperation.dataArray', this.parent);
      var dataIndex = tempData.indexOf(deleteRecord.taskData);
      var childIndex = void 0;
      if (currentIndex !== -1) {
        currentData.splice(currentIndex, 1);
      }
      if (flatIndex !== -1) {
        flatData.splice(flatIndex, 1);
      }
      if (dataIndex !== -1) {
        tempData.splice(dataIndex, 1);
      }
      if (!isNullOrUndefined(deleteRecord)) {
        deleteRecordIDs.push(deleteRecord.ganttProperties.taskId.toString());
        if (flatIndex !== -1) {
          this.parent.ids.splice(flatIndex, 1);
          if (this.parent.viewType === 'ResourceView') {
            this.parent.getTaskIds().splice(flatIndex, 1);
            if (!deleteRecord.hasChildRecords) {
              deleteRecord.ganttProperties.resourceInfo = null;
              delete deleteRecord.ganttProperties.resourceNames;
              deleteRecord[this.parent.taskFields.resourceInfo] = null;
              deleteRecord.ganttProperties.sharedTaskUniqueIds = [];
              delete deleteRecord.taskData[this.parent.taskFields.resourceInfo];
            }
          }
        }
        if (deleteRecord.level === 0 && treeGridParentIndex !== -1) {
          this.parent.treeGrid.parentData.splice(treeGridParentIndex, 1);
        }
        if (deleteRecord.parentItem) {
          var parentItem = this.parent.getParentTask(deleteRecord.parentItem);
          if (parentItem) {
            var childRecords = parentItem.childRecords;
            childIndex = childRecords.indexOf(deleteRecord);
            if (childIndex !== -1) {
              childRecords.splice(childIndex, 1);
            }
            if (!childRecords.length) {
              this.parent.setRecordValue('hasChildRecords', false, parentItem);
            }
          }
        }
        this.updateTreeGridUniqueID(deleteRecord, 'delete');
      }
    }
    if (deleteRecordIDs.length > 0) {
      this.removeFromDataSource(deleteRecordIDs);
    }
    this.updateRowIndex();
    var eventArgs = {};
    this.parent.updatedConnectorLineCollection = [];
    this.parent.connectorLineIds = [];
    this.parent.predecessorModule.createConnectorLinesCollection(this.parent.flatData);
    this.parent.treeGrid.parentData = [];
    this.isAdded = false;
    if (this.parent.treeGrid.editModule) {
      this.parent.treeGrid.editModule['isOnBatch'] = false;
    }
    this.parent.treeGrid.refresh();
    if (this.parent.enableImmutableMode) {
      this.refreshRecordInImmutableMode();
    }
    // Trigger actioncomplete event for delete action
    eventArgs.requestType = 'delete';
    eventArgs.data = args.deletedRecordCollection;
    eventArgs.modifiedRecords = args.updatedRecordCollection;
    eventArgs.modifiedTaskData = getTaskData(args.updatedRecordCollection, null, null, this.parent);
    setValue('action', args.action, eventArgs);
    this.parent.isOnDelete = false;
    if (this.parent.viewType === 'ResourceView' && (!this.parent.undoRedoModule || this.parent.undoRedoModule && (!this.parent.undoRedoModule['isUndoRedoPerformed'] || this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.undoRedoModule['currentAction']['action'] === 'Delete'))) {
      var updateUnAssignedResources_1 = eventArgs.data.filter(function (data) {
        return !data.hasChildRecords;
      });
      var _loop_5 = function (i) {
        var unassignedTask = this_4.parent.flatData.filter(function (data) {
          return data.ganttProperties.taskName === _this.parent.localeObj.getConstant('unassignedTask');
        })[0];
        var isDuplicate = [];
        if (unassignedTask) {
          isDuplicate = unassignedTask.childRecords.filter(function (data) {
            return data.ganttProperties.taskId === updateUnAssignedResources_1[i].ganttProperties.taskId;
          });
        }
        var parentTask = this_4.parent.getParentTask(updateUnAssignedResources_1[i].parentItem);
        if (parentTask && parentTask.ganttProperties.taskName !== this_4.parent.localeObj.getConstant('unassignedTask') && isDuplicate.length === 0) {
          this_4.checkWithUnassignedTask(updateUnAssignedResources_1[i]);
        } else if (!parentTask && (!isDuplicate || isDuplicate.length === 0)) {
          this_4.checkWithUnassignedTask(updateUnAssignedResources_1[i]);
        }
        unassignedTask = this_4.parent.flatData.filter(function (data) {
          return data.ganttProperties.taskName === _this.parent.localeObj.getConstant('unassignedTask');
        })[0];
        var parentItem = this_4.parent.currentViewData.filter(function (data) {
          if (data.ganttProperties.taskId === updateUnAssignedResources_1[i].ganttProperties.taskId && !data.hasChildRecords && data.parentItem && unassignedTask.uniqueID === data.parentItem.uniqueID) {
            return data;
          } else {
            return null;
          }
        });
        if (parentItem[0]) {
          this_4.parent.dataOperation.updateParentItems(parentItem[0]);
        }
      };
      var this_4 = this;
      for (var i = 0; i < updateUnAssignedResources_1.length; i++) {
        _loop_5(i);
      }
    }
    this.parent.trigger('actionComplete', eventArgs);
    this.deletedTaskDetails = [];
    this.parent.initiateEditAction(false);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
  };
  /**
   *
   * @returns {number | string} .
   * @private
   */
  Edit.prototype.getNewTaskId = function () {
    var ids = this.parent.viewType === 'ResourceView' ? this.parent.getTaskIds() : this.parent.ids;
    var maxId = ids.length;
    var newTaskId = maxId + 1;
    if (this.parent.viewType === 'ResourceView') {
      if (ids.indexOf('T' + newTaskId) !== -1 || ids.indexOf('R' + newTaskId) !== -1) {
        newTaskId = newTaskId + 1;
        if (ids.indexOf('T' + newTaskId) !== -1 || ids.indexOf('R' + newTaskId) !== -1) {
          do {
            newTaskId = newTaskId + 1;
          } while (ids.indexOf('T' + newTaskId) !== -1 || ids.indexOf('R' + newTaskId) !== -1);
        }
      }
    } else {
      if (ids.indexOf(newTaskId.toString()) !== -1) {
        newTaskId = newTaskId + 1;
        if (ids.indexOf(newTaskId.toString()) !== -1) {
          do {
            newTaskId = newTaskId + 1;
          } while (ids.indexOf(newTaskId.toString()) !== -1);
        }
      }
    }
    if (this.parent.columnByField[this.parent.taskFields.id].editType === 'stringedit') {
      return newTaskId = newTaskId.toString();
    } else {
      return newTaskId;
    }
  };
  /**
   * @param {object} obj .
   * @param {RowPosition} rowPosition .
   * @returns {void} .
   * @private
   */
  // eslint-disable-next-line
  Edit.prototype.prepareNewlyAddedData = function (obj, rowPosition) {
    var taskModel = this.parent.taskFields;
    var id;
    var newTaskIDmd;
    var ids = this.parent.ids;
    /*Validate Task Id of data*/
    if (obj[taskModel.id]) {
      if (ids.indexOf(obj[taskModel.id].toString()) !== -1) {
        obj[taskModel.id] = null;
      } else {
        if (typeof obj[taskModel.id] === 'string') {
          newTaskIDmd = obj[taskModel.id];
        } else {
          newTaskIDmd = parseInt(obj[taskModel.id], 10);
        }
        obj[taskModel.id] = isNullOrUndefined(newTaskIDmd) ? null : newTaskIDmd;
      }
    }
    if (!obj[taskModel.id]) {
      id = this.getNewTaskId();
      obj[taskModel.id] = id;
    }
    if (!this.parent.allowUnscheduledTasks && !obj[taskModel.startDate]) {
      obj[taskModel.startDate] = this.parent.projectStartDate;
    }
    if (!this.parent.allowUnscheduledTasks && taskModel.duration && isNullOrUndefined(obj[taskModel.duration])) {
      if (!obj[taskModel.endDate]) {
        obj[taskModel.duration] = '5';
      }
    }
    if (taskModel.progress) {
      obj[taskModel.progress] = obj[taskModel.progress] ? obj[taskModel.progress] > 100 ? 100 : obj[taskModel.progress] : 0;
    }
    if (!this.parent.allowUnscheduledTasks && !obj[taskModel.endDate] && taskModel.endDate) {
      if (!obj[taskModel.duration]) {
        var startDate = this.parent.dataOperation.getDateFromFormat(this.parent.projectStartDate);
        if (!isNullOrUndefined(startDate)) {
          startDate.setDate(startDate.getDate() + 4);
          obj[taskModel.endDate] = this.parent.getFormatedDate(startDate, this.parent.getDateFormat());
        }
      }
    }
  };
  /**
   * @param {object} obj .
   * @param {number} level .
   * @param {RowPosition} rowPosition .
   * @param {IGanttData} parentItem .
   * @param {number} rowIndex .
   * @returns {IGanttData} .
   * @private
   */
  Edit.prototype.updateNewlyAddedDataBeforeAjax = function (obj, level, rowPosition, parentItem, rowIndex) {
    var cAddedRecord = this.parent.dataOperation.createRecord(obj, level);
    switch (rowPosition) {
      case 'Above':
        cAddedRecord.index = rowIndex;
        break;
      case 'Below':
      case 'Child':
        cAddedRecord.index = rowIndex + 1;
        break;
      case 'Bottom':
        cAddedRecord.index = this.parent.enableVirtualization ? this.parent.flatData.length : this.parent.currentViewData.length;
        break;
      case 'Top':
        cAddedRecord.index = 0;
        break;
    }
    if (!isNullOrUndefined(parentItem)) {
      this.parent.setRecordValue('parentItem', this.parent.dataOperation.getCloneParent(parentItem), cAddedRecord);
      var pIndex = cAddedRecord.parentItem ? cAddedRecord.parentItem.index : null;
      this.parent.setRecordValue('parentIndex', pIndex, cAddedRecord);
      var parentUniqId = cAddedRecord.parentItem ? cAddedRecord.parentItem.uniqueID : null;
      this.parent.setRecordValue('parentUniqueID', parentUniqId, cAddedRecord);
      if (!isNullOrUndefined(this.parent.taskFields.id) && !isNullOrUndefined(this.parent.taskFields.parentID) && cAddedRecord.parentItem) {
        if (this.parent.viewType === 'ProjectView') {
          this.parent.setRecordValue(this.parent.taskFields.parentID, cAddedRecord.parentItem.taskId, cAddedRecord.taskData, true);
        }
        this.parent.setRecordValue('parentId', cAddedRecord.parentItem.taskId, cAddedRecord.ganttProperties, true);
        this.parent.setRecordValue(this.parent.taskFields.parentID, cAddedRecord.parentItem.taskId, cAddedRecord, true);
      }
    }
    this.parent.isOnEdit = true;
    if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
      if (obj['hasChildRecords']) {
        var totalRecords = [];
        var currentAction = this.parent.undoRedoModule['currentAction']['deletedRecordsDetails'];
        for (var i = 0; i < obj['childRecords'].length; i++) {
          this.add(obj['childRecords'][i], totalRecords);
        }
        for (var j = 0; j < currentAction.length; j++) {
          if (obj['ganttProperties'].taskId === currentAction[j]['data'].ganttProperties.taskId) {
            if (currentAction[j]['position'] === 'child') {
              rowPosition = 'Child';
            } else if (currentAction[j]['position'] === 'below') {
              rowPosition = 'Below';
            } else if (currentAction[j]['position'] === 'above') {
              rowPosition = 'Above';
            }
            this.backUpAndPushNewlyAddedRecord(cAddedRecord, rowPosition, parentItem);
            for (var i = 0; i < totalRecords.length; i++) {
              if (this.parent.viewType === 'ProjectView') {
                if (totalRecords[i].parentItem.taskId === cAddedRecord.ganttProperties.taskId) {
                  totalRecords[i].parentItem.index = cAddedRecord.index;
                  totalRecords[i].parentItem.taskId = cAddedRecord.ganttProperties.taskId;
                  totalRecords[i].parentItem.uniqueID = cAddedRecord.ganttProperties.uniqueID;
                  totalRecords[i].parentUniqueID = cAddedRecord.ganttProperties.uniqueID;
                }
              } else {
                totalRecords[i].parentItem.index = cAddedRecord.index;
                totalRecords[i].parentItem.taskId = cAddedRecord.ganttProperties.rowUniqueID;
                totalRecords[i].parentItem.uniqueID = cAddedRecord.ganttProperties.uniqueID;
                totalRecords[i].parentUniqueID = cAddedRecord.ganttProperties.uniqueID;
              }
            }
            break;
          }
        }
        this.addRowSelectedItem = cAddedRecord;
        cAddedRecord.taskData[this.parent.taskFields.child] = [];
        for (var k = 0; k < totalRecords.length; k++) {
          this.parent.isOnEdit = false;
          var newRecord = this.parent.dataOperation.createRecord(totalRecords[k], totalRecords[k].level);
          if (newRecord.childRecords.length === 0 && newRecord.taskData[this.parent.taskFields.child]) {
            newRecord.taskData[this.parent.taskFields.child] = [];
          }
          parentItem = this.parent.getRecordByID(totalRecords[k].parentItem.taskId);
          if (!isNullOrUndefined(parentItem)) {
            this.parent.setRecordValue('parentItem', this.parent.dataOperation.getCloneParent(parentItem), newRecord);
            var pIndex = newRecord.parentItem ? newRecord.parentItem.index : null;
            this.parent.setRecordValue('parentIndex', pIndex, newRecord);
            var parentUniqId = newRecord.parentItem ? newRecord.parentItem.uniqueID : null;
            this.parent.setRecordValue('parentUniqueID', parentUniqId, newRecord);
            if (!isNullOrUndefined(this.parent.taskFields.id) && !isNullOrUndefined(this.parent.taskFields.parentID) && newRecord.parentItem) {
              if (this.parent.viewType === 'ProjectView') {
                this.parent.setRecordValue(this.parent.taskFields.parentID, newRecord.parentItem.taskId, newRecord.taskData, true);
              }
              this.parent.setRecordValue('parentId', newRecord.parentItem.taskId, newRecord.ganttProperties, true);
              this.parent.setRecordValue(this.parent.taskFields.parentID, newRecord.parentItem.taskId, newRecord, true);
            }
          }
          this.parent.isOnEdit = true;
          if (newRecord.parentItem) {
            if (parentItem.childRecords.length === 0) {
              rowPosition = 'Child';
            } else {
              rowPosition = 'Below';
            }
          }
          if (this.parent.getParentTask(newRecord.parentItem).childRecords.length > 0) {
            this.addRowSelectedItem = this.parent.getParentTask(newRecord.parentItem).childRecords[this.parent.getParentTask(newRecord.parentItem).childRecords.length - 1];
          }
          this.backUpAndPushNewlyAddedRecord(newRecord, rowPosition, parentItem);
          for (var i = 0; i < totalRecords.length; i++) {
            if (this.parent.viewType === 'ProjectView') {
              if (totalRecords[i].parentItem.taskId === newRecord.ganttProperties.taskId) {
                totalRecords[i].parentItem.index = newRecord.index;
                totalRecords[i].parentItem.taskId = newRecord.ganttProperties.taskId;
                totalRecords[i].parentItem.uniqueID = newRecord.ganttProperties.uniqueID;
                totalRecords[i].parentUniqueID = newRecord.ganttProperties.uniqueID;
              }
            } else {
              totalRecords[i].parentItem.index = cAddedRecord.index;
              totalRecords[i].parentItem.taskId = cAddedRecord.ganttProperties.rowUniqueID;
              totalRecords[i].parentItem.uniqueID = cAddedRecord.ganttProperties.uniqueID;
              totalRecords[i].parentUniqueID = cAddedRecord.ganttProperties.uniqueID;
            }
          }
        }
      } else {
        this.backUpAndPushNewlyAddedRecord(cAddedRecord, rowPosition, parentItem);
      }
    } else {
      this.backUpAndPushNewlyAddedRecord(cAddedRecord, rowPosition, parentItem);
    }
    // need to push in dataSource also.
    if (this.parent.taskFields.dependency && cAddedRecord.ganttProperties.predecessorsName) {
      if (!this.parent.undoRedoModule || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
        this.parent.predecessorModule.ensurePredecessorCollectionHelper(cAddedRecord, cAddedRecord.ganttProperties);
      }
      this.parent.predecessorModule.updatePredecessorHelper(cAddedRecord);
      this.parent.predecessorModule.validatePredecessorDates(cAddedRecord);
    }
    if (cAddedRecord.parentItem && this.parent.getParentTask(cAddedRecord.parentItem).ganttProperties.isAutoSchedule) {
      this.parent.dataOperation.updateParentItems(cAddedRecord.parentItem);
    }
    this.parent.isOnEdit = false;
    return cAddedRecord;
  };
  /**
   * @param {IGanttData} record .
   * @param {number} count .
   * @returns {number} .
   * @private
   */
  Edit.prototype.getChildCount = function (record, count) {
    var currentRecord;
    if (!record.hasChildRecords) {
      return 0;
    }
    for (var i = 0; i < record.childRecords.length; i++) {
      currentRecord = record.childRecords[i];
      count++;
      if (currentRecord.hasChildRecords) {
        count = this.getChildCount(currentRecord, count);
      }
    }
    return count;
  };
  /**
   * @param {IGanttData} data .
   * @param {number} count .
   * @param {IGanttData[]} collection .
   * @returns {number} .
   * @private
   */
  Edit.prototype.getVisibleChildRecordCount = function (data, count, collection) {
    var childRecords;
    var length;
    if (data.hasChildRecords) {
      childRecords = data.childRecords;
      length = childRecords.length;
      for (var i = 0; i < length; i++) {
        if (collection.indexOf(childRecords[i]) !== -1) {
          count++;
        }
        if (childRecords[i].hasChildRecords) {
          count = this.getVisibleChildRecordCount(childRecords[i], count, collection);
        }
      }
    } else {
      if (collection.indexOf(data) !== -1) {
        count++;
      }
    }
    return count;
  };
  /**
   * @param {IGanttData} parentRecord .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updatePredecessorOnIndentOutdent = function (parentRecord) {
    var len = parentRecord.ganttProperties.predecessor.length;
    var parentRecordTaskData = parentRecord.ganttProperties;
    var predecessorCollection = parentRecordTaskData.predecessor;
    var childRecord;
    var predecessorIndex;
    var updatedPredecessor = [];
    var validPredecessor;
    for (var count = 0; count < len; count++) {
      var fromRecord = this.parent.getRecordByID(predecessorCollection[count].from);
      var toRecord = this.parent.getRecordByID(predecessorCollection[count].to);
      validPredecessor = this.parent.connectorLineEditModule.validateParentPredecessor(fromRecord, toRecord);
      if (!validPredecessor || !this.parent.allowParentDependency) {
        if (predecessorCollection[count].to === parentRecordTaskData.rowUniqueID.toString()) {
          childRecord = this.parent.getRecordByID(predecessorCollection[count].from);
          predecessorIndex = getIndex(predecessorCollection[count], 'from', childRecord.ganttProperties.predecessor, 'to');
          // eslint-disable-next-line
          var predecessorCollections = extend([], childRecord.ganttProperties.predecessor, [], true);
          predecessorCollections.splice(predecessorIndex, 1);
          this.parent.setRecordValue('predecessor', predecessorCollections, childRecord.ganttProperties, true);
        } else if (predecessorCollection[count].from === parentRecordTaskData.rowUniqueID.toString()) {
          childRecord = this.parent.getRecordByID(predecessorCollection[count].to);
          var prdcList = childRecord.ganttProperties.predecessorsName.toString().split(',');
          var str = predecessorCollection[count].from + predecessorCollection[count].type;
          var ind = prdcList.indexOf(str);
          prdcList.splice(ind, 1);
          this.parent.setRecordValue('predecessorsName', prdcList.join(','), childRecord.ganttProperties, true);
          this.parent.setRecordValue(this.parent.taskFields.dependency, prdcList.join(','), childRecord);
          predecessorIndex = getIndex(predecessorCollection[count], 'from', childRecord.ganttProperties.predecessor, 'to');
          // eslint-disable-next-line
          var temppredecessorCollection = extend([], childRecord.ganttProperties.predecessor, [], true);
          temppredecessorCollection.splice(predecessorIndex, 1);
          this.parent.setRecordValue('predecessor', temppredecessorCollection, childRecord.ganttProperties, true);
          this.parent.predecessorModule.validatePredecessorDates(childRecord);
        }
      }
    }
    if (!validPredecessor || !this.parent.allowParentDependency) {
      this.parent.setRecordValue('predecessor', updatedPredecessor, parentRecord.ganttProperties, true);
      this.parent.setRecordValue('predecessorsName', '', parentRecord.ganttProperties, true);
    }
  };
  /**
   * @param {IGanttData} record .
   * @param {RowPosition} rowPosition .
   * @param {IGanttData} parentItem .
   * @returns {void} .
   * @private
   */
  Edit.prototype.backUpAndPushNewlyAddedRecord = function (record, rowPosition, parentItem) {
    var flatRecords = this.parent.flatData;
    var currentViewData = this.parent.currentViewData;
    var ids = this.parent.ids;
    var currentItemIndex;
    var recordIndex;
    var updatedCollectionIndex;
    var childIndex;
    switch (rowPosition) {
      case 'Top':
        flatRecords.splice(0, 0, record);
        currentViewData.splice(0, 0, record);
        ids.splice(0, 0, record.ganttProperties.rowUniqueID.toString()); // need to check NAN
        break;
      case 'Bottom':
        flatRecords.push(record);
        currentViewData.push(record);
        ids.push(record.ganttProperties.rowUniqueID.toString()); // need to check NAN
        if (this.parent.viewType === 'ResourceView') {
          var taskId = record.level === 0 ? 'R' + record.ganttProperties.taskId : 'T' + record.ganttProperties.taskId;
          this.parent.getTaskIds().push(taskId);
        }
        break;
      case 'Above':
        /*Record Updates*/
        recordIndex = flatRecords.indexOf(this.addRowSelectedItem);
        updatedCollectionIndex = currentViewData.indexOf(this.addRowSelectedItem);
        this.recordCollectionUpdate(childIndex, recordIndex, updatedCollectionIndex, record, parentItem, rowPosition);
        break;
      case 'Below':
        currentItemIndex = flatRecords.indexOf(this.addRowSelectedItem);
        if (this.addRowSelectedItem.hasChildRecords) {
          var dataChildCount = this.getChildCount(this.addRowSelectedItem, 0);
          recordIndex = currentItemIndex + dataChildCount + 1;
          updatedCollectionIndex = currentViewData.indexOf(this.addRowSelectedItem) + this.getVisibleChildRecordCount(this.addRowSelectedItem, 0, currentViewData) + 1;
        } else {
          recordIndex = currentItemIndex + 1;
          updatedCollectionIndex = currentViewData.indexOf(this.addRowSelectedItem) + 1;
        }
        this.recordCollectionUpdate(childIndex + 1, recordIndex, updatedCollectionIndex, record, parentItem, rowPosition);
        break;
      case 'Child':
        currentItemIndex = flatRecords.indexOf(this.addRowSelectedItem);
        if (this.addRowSelectedItem.hasChildRecords) {
          var dataChildCount = this.getChildCount(this.addRowSelectedItem, 0);
          recordIndex = currentItemIndex + dataChildCount + 1;
          //Expand Add record's parent item for project view
          if (!this.addRowSelectedItem.expanded && !this.parent.enableMultiTaskbar) {
            this.parent.expandByID(this.addRowSelectedItem.ganttProperties.rowUniqueID);
          }
          updatedCollectionIndex = currentViewData.indexOf(this.addRowSelectedItem) + this.getVisibleChildRecordCount(this.addRowSelectedItem, 0, currentViewData) + 1;
        } else {
          this.parent.setRecordValue('hasChildRecords', true, this.addRowSelectedItem);
          this.parent.setRecordValue('isMilestone', false, this.addRowSelectedItem.ganttProperties, true);
          this.parent.setRecordValue('expanded', true, this.addRowSelectedItem);
          this.parent.setRecordValue('childRecords', [], this.addRowSelectedItem);
          recordIndex = currentItemIndex + 1;
          updatedCollectionIndex = currentViewData.indexOf(this.addRowSelectedItem) + 1;
          if (this.addRowSelectedItem.ganttProperties.predecessor) {
            this.updatePredecessorOnIndentOutdent(this.addRowSelectedItem);
          }
          if (!isNullOrUndefined(this.addRowSelectedItem.ganttProperties.segments)) {
            this.addRowSelectedItem.ganttProperties.segments = null;
          }
        }
        this.recordCollectionUpdate(childIndex + 1, recordIndex, updatedCollectionIndex, record, parentItem, rowPosition);
        break;
    }
    this.newlyAddedRecordBackup = record;
  };
  /**
   * @param {number} childIndex .
   * @param {number} recordIndex .
   * @param {number} updatedCollectionIndex .
   * @param {IGanttData} record .
   * @param {IGanttData} parentItem .
   * @param {RowPosition} rowPosition .
   * @returns {void} .
   * @private
   */
  Edit.prototype.recordCollectionUpdate = function (childIndex, recordIndex, updatedCollectionIndex, record, parentItem, rowPosition) {
    var flatRecords = this.parent.flatData;
    var currentViewData = this.parent.currentViewData;
    var ids = this.parent.ids;
    /* Record collection update */
    flatRecords.splice(recordIndex, 0, record);
    currentViewData.splice(updatedCollectionIndex, 0, record);
    ids.splice(recordIndex, 0, record.ganttProperties.rowUniqueID.toString());
    if (this.parent.viewType === 'ResourceView') {
      var taskId = record.level === 0 ? 'R' + record.ganttProperties.taskId : 'T' + record.ganttProperties.taskId;
      this.parent.getTaskIds().splice(recordIndex, 0, taskId);
    }
    /* data Source update */
    if (!isNullOrUndefined(parentItem)) {
      if (rowPosition === 'Above') {
        childIndex = parentItem.childRecords.indexOf(this.addRowSelectedItem);
      } else if (rowPosition === 'Below') {
        childIndex = parentItem.childRecords.indexOf(this.addRowSelectedItem) + 1;
      } else {
        childIndex = parentItem.childRecords.length;
      }
      /*Child collection update*/
      parentItem.childRecords.splice(childIndex, 0, record);
      if (!this.parent.taskFields.child) {
        this.parent.taskFields.child = 'Children';
      }
      if (this.parent.dataSource instanceof DataManager && isNullOrUndefined(parentItem.taskData[this.parent.taskFields.parentID]) || !isNullOrUndefined(this.parent.dataSource)) {
        var child = this.parent.taskFields.child;
        if (parentItem.taskData[child] && parentItem.taskData[child].length > 0) {
          if (rowPosition === 'Above' || rowPosition === 'Below') {
            parentItem.taskData[child].splice(childIndex, 0, record.taskData);
          } else {
            parentItem.taskData[child].push(record.taskData);
          }
        } else {
          parentItem.taskData[child] = [];
          parentItem.taskData[child].push(record.taskData);
        }
        this.isNewRecordAdded = true;
      }
    }
  };
  /**
   * @param {IGanttData} cAddedRecord .
   * @param {IGanttData} modifiedRecords .
   * @param {string} event .
   * @returns {ITaskAddedEventArgs} .
   * @private
   */
  Edit.prototype.constructTaskAddedEventArgs = function (cAddedRecord, modifiedRecords, event) {
    var eventArgs = {};
    eventArgs.action = eventArgs.requestType = event;
    if (cAddedRecord.length > 1) {
      eventArgs.data = [];
      eventArgs.newTaskData = [];
      eventArgs.recordIndex = [];
      for (var i = 0; i < cAddedRecord.length; i++) {
        eventArgs.data[i] = cAddedRecord[i];
        eventArgs.newTaskData[i] = getTaskData([cAddedRecord[i]], eventArgs.data[i], eventArgs, this.parent);
        eventArgs.recordIndex[i] = cAddedRecord[i].index;
      }
    } else if (cAddedRecord.length === 1) {
      for (var i = 0; i < cAddedRecord.length; i++) {
        eventArgs.data = cAddedRecord[i];
        eventArgs.newTaskData = getTaskData([cAddedRecord[i]], eventArgs.data, eventArgs, this.parent);
        eventArgs.recordIndex = cAddedRecord[i].index;
      }
    }
    eventArgs.modifiedRecords = modifiedRecords;
    eventArgs.modifiedTaskData = getTaskData(modifiedRecords, null, null, this.parent);
    return eventArgs;
  };
  /**
   * @param {ITaskAddedEventArgs} args .
   * @returns {void} .
   * @private
   */
  Edit.prototype.addSuccess = function (args) {
    // let addedRecords: IGanttData = args.addedRecord;
    // let eventArgs: IActionBeginEventArgs = {};
    this.parent.updatedConnectorLineCollection = [];
    this.parent.connectorLineIds = [];
    this.parent.predecessorModule.createConnectorLinesCollection(this.parent.flatData);
    this.parent.treeGrid.parentData = [];
    this.parent.addDeleteRecord = true;
    this.parent.treeGrid['isAddedFromGantt'] = true;
    this.isAdded = true;
    if (this.parent.treeGrid.editModule) {
      this.parent.treeGrid.editModule['isOnBatch'] = false;
    }
    this.parent.treeGrid.refresh();
    if (this.parent.enableImmutableMode) {
      this.parent.modifiedRecords = args.modifiedRecords;
      this.parent.modifiedRecords.push(args.data);
      this.refreshRecordInImmutableMode();
    }
  };
  Edit.prototype.refreshRecordInImmutableMode = function (data, dragged) {
    if (!dragged && !isNullOrUndefined(data)) {
      for (var i = data[0].index + 1; i < this.parent.currentViewData.length; i++) {
        if (data[0].level < this.parent.currentViewData[i].level) {
          this.parent.modifiedRecords.push(this.parent.currentViewData[i]);
        } else {
          break;
        }
      }
    }
    var _loop_6 = function (i) {
      var originalData = this_5.parent.modifiedRecords[i];
      var treeIndex = this_5.parent.rowDragAndDropModule && this_5.parent.allowRowDragAndDrop ? 1 : 0;
      var uniqueTaskID = this_5.parent.taskFields.id;
      var originalIndex = this_5.parent.currentViewData.findIndex(function (data) {
        return data[uniqueTaskID] === originalData[uniqueTaskID];
      });
      if (this_5.parent.treeGrid.getRows()[originalIndex]) {
        var row = this_5.parent.treeGrid.grid.getRowObjectFromUID(this_5.parent.treeGrid.grid.getDataRows()[originalIndex].getAttribute('data-uid'));
        this_5.parent.treeGrid.renderModule.cellRender({
          data: row.data,
          cell: this_5.parent.treeGrid.getRows()[originalIndex].cells[this_5.parent.treeColumnIndex + treeIndex],
          column: this_5.parent.treeGrid.grid.getColumns()[this_5.parent.treeColumnIndex],
          requestType: 'rowDragAndDrop'
        });
        this_5.parent.treeGrid.renderModule.RowModifier({
          data: originalData,
          row: this_5.parent.treeGrid.getRows()[originalIndex],
          rowHeight: this_5.parent.rowHeight
        });
      }
    };
    var this_5 = this;
    for (var i = 0; i < this.parent.modifiedRecords.length; i++) {
      _loop_6(i);
    }
  };
  /**
   * @param {IGanttData} addedRecord .
   * @param {RowPosition} rowPosition .
   * @returns {void} .
   * @private
   */
  Edit.prototype.updateRealDataSource = function (addedRecord, rowPosition) {
    var taskFields = this.parent.taskFields;
    var dataSource = isCountRequired(this.parent) ? getValue('result', this.parent.dataSource) : this.parent.dataSource;
    if (this.parent.dataSource instanceof DataManager) {
      dataSource = this.parent.dataSource.dataSource.json;
    }
    for (var i = 0; i < addedRecord.length; i++) {
      if (isNullOrUndefined(rowPosition) || isNullOrUndefined(this.addRowSelectedItem)) {
        rowPosition = rowPosition === 'Bottom' ? 'Bottom' : 'Top';
      }
      if (rowPosition === 'Top') {
        dataSource.splice(0, 0, addedRecord[i].taskData);
      } else if (rowPosition === 'Bottom') {
        dataSource.push(addedRecord[i].taskData);
      } else {
        if (!isNullOrUndefined(taskFields.id) && !isNullOrUndefined(taskFields.parentID) && rowPosition === 'Child') {
          dataSource.push(addedRecord[i].taskData);
        } else {
          if (!this.isNewRecordAdded) {
            this.addDataInRealDataSource(dataSource, addedRecord[i].taskData, rowPosition);
          }
          this.isNewRecordAdded = false;
        }
      }
      this.isBreakLoop = false;
    }
  };
  /**
   * @param {object[]} dataCollection .
   * @param {IGanttData} record .
   * @param {RowPosition} rowPosition .
   * @returns {void} .
   * @private
   */
  Edit.prototype.addDataInRealDataSource = function (dataCollection, record, rowPosition) {
    for (var i = 0; i < dataCollection.length; i++) {
      var child = this.parent.taskFields.child;
      if (this.isBreakLoop) {
        break;
      }
      if (getValue(this.parent.taskFields.id, dataCollection[i]).toString() === this.addRowSelectedItem.ganttProperties.rowUniqueID.toString()) {
        var index = void 0;
        if (this.parent.rowDragAndDropModule && this.parent.rowDragAndDropModule['droppedRecord'] && this.parent.viewType === 'ResourceView') {
          for (var i_1 = 0; i_1 < dataCollection.length; i_1++) {
            if (dataCollection[i_1][this.parent.taskFields.id] === this.parent.rowDragAndDropModule['droppedRecord'].ganttProperties.taskId) {
              index = i_1;
              break;
            }
          }
        }
        if (rowPosition === 'Above') {
          if (index) {
            dataCollection.splice(index, 0, record);
          } else {
            dataCollection.splice(i, 0, record);
          }
        } else if (rowPosition === 'Below') {
          if (index) {
            dataCollection.splice(index + 1, 0, record);
          } else {
            dataCollection.splice(i + 1, 0, record);
          }
        } else if (rowPosition === 'Child') {
          if (dataCollection[i][child] && dataCollection[i][child].length > 0) {
            dataCollection[i][child].push(record);
          } else {
            dataCollection[i][child] = [];
            dataCollection[i][child].push(record);
          }
        }
        this.isBreakLoop = true;
        break;
      } else if (dataCollection[i][child]) {
        var childRecords = dataCollection[i][child];
        this.addDataInRealDataSource(childRecords, record, rowPosition);
      }
    }
  };
  /**
   * Method to update the values to client side from server side.
   *
   * @param {Object} e - Defines the new modified data from the server.
   * @param {Object[]} e.addedRecords .
   * @param {Object[]} e.changedRecords .
   * @param {ITaskAddedEventArgs} args - Defines the client side data.
   * @returns {void} .
   */
  Edit.prototype.updateClientDataFromServer = function (e, args) {
    var serverReturnedValue = e.addedRecords[0];
    var _aLength = Object.keys(serverReturnedValue).length;
    for (var j = 0, _a = Object.keys(serverReturnedValue); j < _aLength; j++) {
      var key = _a[parseInt(j.toString(), 10)];
      args.data["" + key] = serverReturnedValue["" + key];
    }
    if (this.parent.taskFields.id !== null) {
      args.data.ganttProperties['taskId'] = serverReturnedValue[this.parent.taskFields.id];
    }
    if (this.parent.taskFields.name !== null) {
      args.data.ganttProperties['taskName'] = serverReturnedValue[this.parent.taskFields.name];
    }
    if (this.parent.taskFields.startDate !== null) {
      args.data.ganttProperties['startDate'] = serverReturnedValue[this.parent.taskFields.startDate];
    }
    if (this.parent.taskFields.endDate !== null) {
      args.data.ganttProperties['endDate'] = serverReturnedValue[this.parent.taskFields.endDate];
    }
    if (this.parent.taskFields.duration !== null) {
      args.data.ganttProperties['duration'] = parseFloat(serverReturnedValue[this.parent.taskFields.duration]);
    }
    if (this.parent.taskFields.durationUnit !== null) {
      args.data.ganttProperties['durationUnit'] = serverReturnedValue[this.parent.taskFields.durationUnit];
    }
    if (this.parent.taskFields.progress !== null) {
      args.data.ganttProperties['progress'] = serverReturnedValue[this.parent.taskFields.progress];
    }
    if (this.parent.taskFields.dependency !== null) {
      args.data.ganttProperties['dependency'] = serverReturnedValue[this.parent.taskFields.dependency];
    }
    if (this.parent.taskFields.parentID !== null) {
      args.data.ganttProperties['parentID'] = serverReturnedValue[this.parent.taskFields.parentID];
    }
    if (this.parent.taskFields.baselineEndDate !== null) {
      args.data.ganttProperties['baselineEndDate'] = serverReturnedValue[this.parent.taskFields.baselineEndDate];
    }
    if (this.parent.taskFields.baselineStartDate !== null) {
      args.data.ganttProperties['baselineStartDate'] = serverReturnedValue[this.parent.taskFields.baselineStartDate];
    }
    if (this.parent.taskFields.resourceInfo !== null) {
      args.data.ganttProperties['resources'] = serverReturnedValue[this.parent.taskFields.resourceInfo];
    }
  };
  Edit.prototype.addNewUndoCollection = function (record, args) {
    if (this.parent.undoRedoModule && (!this.parent.undoRedoModule['isUndoRedoPerformed'] || this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.undoRedoModule['currentAction']['action'] === 'Add' && this.parent.viewType === 'ResourceView') && this.parent['isUndoRedoItemPresent']('Add')) {
      record['action'] = 'Add';
      var tempArray_1 = args.data.length > 0 ? extend([], [], args.data, true) : [args.data];
      var addedRec = [];
      var _loop_7 = function (i) {
        addedRec = this_6.parent.flatData.filter(function (data) {
          return tempArray_1[i].index === data.index && tempArray_1[i]['ganttProperties'].taskId === data.ganttProperties.taskId;
        });
      };
      var this_6 = this;
      for (var i = 0; i < tempArray_1.length; i++) {
        _loop_7(i);
      }
      record['addedRecords'] = extend([], [], addedRec, true);
      this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
    }
  };
  /**
   * Method to add new record.
   *
   * @param {Object[] | Object} data - Defines the new data to add.
   * @param {RowPosition} rowPosition - Defines the position of row.
   * @param {number} rowIndex - Defines the row index.
   * @returns {void} .
   * @private
   */
  Edit.prototype.addRecord = function (data, rowPosition, rowIndex) {
    var _this = this;
    var tempTaskID = this.parent.taskFields.id;
    if (this.parent.editModule && this.parent.editSettings.allowAdding) {
      this.parent.isDynamicData = true;
      this.parent.treeGrid['isAddedFromGantt'] = true;
      this.parent.isOnAdded = true;
      var cAddedRecord_1 = [];
      if (isNullOrUndefined(data)) {
        this.validateTaskPosition(data, rowPosition, rowIndex, cAddedRecord_1);
      } else if (data instanceof Array) {
        if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
          var addData = data;
          var addIndex = rowIndex;
          if (this.parent.viewType === 'ResourceView') {
            if (data[0]['position'] === 'below') {
              rowPosition = 'Below';
            } else if (data[0]['position'] === 'above') {
              rowPosition = 'Above';
            } else if (data[0]['position'] === 'child') {
              rowPosition = 'Child';
            }
            addIndex = this.parent.taskIds.indexOf(data[0]['id'].toString());
            addData = data[0]['data'];
          }
          this.validateTaskPosition(addData, rowPosition, addIndex, cAddedRecord_1);
        } else {
          for (var i = 0; i < data.length; i++) {
            this.validateTaskPosition(data[i], rowPosition, rowIndex, cAddedRecord_1);
          }
        }
      } else if (typeof data === 'object') {
        this.validateTaskPosition(data, rowPosition, rowIndex, cAddedRecord_1);
      } else {
        return;
      }
      var args = {};
      args = this.constructTaskAddedEventArgs(cAddedRecord_1, this.parent.editedRecords, 'beforeAdd');
      if (!isNullOrUndefined(rowPosition)) {
        args.rowPosition = rowPosition;
      }
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent['isUndoRedoItemPresent']('Add')) {
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['disableRedo']();
        }
        this.parent.undoRedoModule['createUndoCollection']();
      }
      this.parent.trigger('actionBegin', args, function (args) {
        _this.parent.previousRecords = {};
        if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
          _this.parent.showMaskRow();
        } else {
          _this.parent.showSpinner();
        }
        var tasks = _this.parent.taskFields;
        var ganttData;
        if (_this.parent.viewType === 'ResourceView') {
          if (args.data['childRecords'].length > 0) {
            ganttData = _this.parent.flatData[_this.parent.getTaskIds().indexOf('R' + args.data[tasks.id])];
          } else {
            ganttData = _this.parent.flatData[_this.parent.getTaskIds().indexOf('T' + args.data[tasks.id])];
          }
        } else {
          ganttData = _this.parent.getRecordByID(args.data[tasks.id]);
        }
        if (!isNullOrUndefined(ganttData)) {
          _this.validateUpdateValues(args.newTaskData, ganttData, true);
          _this.parent.dateValidationModule.calculateEndDate(ganttData);
          _this.parent.dataOperation.updateWidthLeft(ganttData);
          _this.parent.dataOperation.updateParentItems(ganttData);
        }
        if (!isNullOrUndefined(args.data["" + tempTaskID])) {
          if (args.data[tempTaskID] !== args.data['ganttProperties']['taskId']) {
            for (var _i = 0, _b = Object.keys(_this.parent.ids); _i < _b.length; _i++) {
              var key = _b[_i];
              if (_this.parent.ids[key] === args.data['ganttProperties']['taskId'].toString()) {
                _this.parent.ids[key] = args.data[tempTaskID].toString();
                break;
              }
            }
            args.data['ganttProperties']['taskId'] = args.data[tempTaskID];
            args.newTaskData[tempTaskID] = args.data[tempTaskID];
            args.data['ganttProperties']['rowUniqueID'] = args.data[tempTaskID].toString();
          }
        }
        if (!args.cancel) {
          var record = {};
          if (isRemoteData(_this.parent.dataSource)) {
            var data_2 = _this.parent.dataSource;
            var updatedData_2 = {
              addedRecords: [args.newTaskData],
              changedRecords: args.modifiedTaskData
            };
            /* tslint:disable-next-line */
            var query = _this.parent.query instanceof Query ? _this.parent.query : new Query();
            var adaptor = data_2.adaptor;
            if (!(adaptor instanceof WebApiAdaptor || adaptor instanceof ODataAdaptor || adaptor instanceof ODataV4Adaptor) || data_2.dataSource.batchUrl) {
              /* tslint:disable-next-line */
              var crud = data_2.saveChanges(updatedData_2, _this.parent.taskFields.id, null, query);
              crud.then(function (e) {
                if (e.addedRecords[0][_this.parent.taskFields.id].toString() !== args.data['ganttProperties']['taskId']) {
                  args.data['ganttProperties']['taskId'] = e.addedRecords[0][_this.parent.taskFields.id].toString();
                  args.newTaskData[tempTaskID] = e.addedRecords[0][_this.parent.taskFields.id].toString();
                  args.data['ganttProperties']['rowUniqueID'] = e.addedRecords[0][_this.parent.taskFields.id].toString();
                  _this.parent.ids.push(e.addedRecords[0][_this.parent.taskFields.id].toString());
                }
                var prevID = args.data.ganttProperties.taskId.toString();
                if (_this.parent.taskFields.id && !isNullOrUndefined(e.addedRecords[0][_this.parent.taskFields.id]) && e.addedRecords[0][_this.parent.taskFields.id].toString() === prevID) {
                  _this.parent.setRecordValue('taskId', e.addedRecords[0][_this.parent.taskFields.id], args.data.ganttProperties, true);
                  _this.parent.setRecordValue('taskData', e.addedRecords[0], args.data);
                  _this.parent.setRecordValue(_this.parent.taskFields.id, e.addedRecords[0][_this.parent.taskFields.id], args.data);
                  _this.parent.setRecordValue('rowUniqueID', e.addedRecords[0][_this.parent.taskFields.id].toString(), args.data.ganttProperties, true);
                  _this.updateClientDataFromServer(e, args);
                  var idsIndex = _this.parent.ids.indexOf(prevID);
                  if (idsIndex !== -1) {
                    _this.parent.ids[idsIndex] = e.addedRecords[0][_this.parent.taskFields.id].toString();
                  }
                }
                _this.updateNewRecord(cAddedRecord_1, args);
              }).catch(function (e) {
                _this.removeAddedRecord();
                _this.dmFailure(e, args);
                _this._resetProperties();
              });
            } else {
              var addedRecords = 'addedRecords';
              var insertCrud = data_2.insert(updatedData_2[addedRecords], null, query);
              insertCrud.then(function (e) {
                var addedRecords;
                if (!isNullOrUndefined(e[0])) {
                  addedRecords = e[0];
                } else {
                  addedRecords = updatedData_2['addedRecords'][0];
                }
                cAddedRecord_1.forEach(function (record) {
                  if (!isNullOrUndefined(record)) {
                    _this.updateEditedRecordFields(addedRecords, record);
                    _this.parent.dataOperation.updateTaskData(record);
                  }
                });
                _this.updateNewRecord(cAddedRecord_1, args);
              }).catch(function (e) {
                _this.removeAddedRecord();
                _this.dmFailure(e, args);
                _this._resetProperties();
              });
            }
          } else {
            if (_this.parent.viewType === 'ProjectView') {
              if (rowPosition === 'Top' || rowPosition === 'Bottom' || rowPosition === 'Above' || rowPosition === 'Below' || rowPosition === 'Child' || isNullOrUndefined(rowPosition) && !args.data.parentItem) {
                if (args.data instanceof Array) {
                  _this.updateRealDataSource(args.data, rowPosition);
                } else {
                  var data_3 = [];
                  data_3.push(args.data);
                  _this.updateRealDataSource(data_3, rowPosition);
                  _this.parent.currentSelection = cAddedRecord_1[0];
                }
              }
            } else {
              var dataSource = isCountRequired(_this.parent) ? getValue('result', _this.parent.dataSource) : _this.parent.dataSource; // eslint-disable-line
              dataSource.push(args.data.taskData);
            }
            if (cAddedRecord_1.level === 0) {
              _this.parent.treeGrid.parentData.splice(0, 0, cAddedRecord_1);
            }
            _this.updateTreeGridUniqueID(cAddedRecord_1, 'add');
            if (_this.parent.viewType === 'ResourceView' && _this.parent.undoRedoModule && _this.parent.undoRedoModule['currentAction']) {
              var canDelete = false;
              if (args.data['hasChildRecords']) {
                canDelete = true;
              } else {
                if (args.data['parentItem']) {
                  var parentTask = _this.parent.getTaskByUniqueID(args.data['parentItem'].uniqueID);
                  if (parentTask.ganttProperties.taskName !== _this.parent.localeObj.getConstant('unassignedTask')) {
                    canDelete = true;
                  }
                }
              }
              if (_this.parent.taskIds.indexOf('R0') !== -1 && _this.parent.undoRedoModule && _this.parent.undoRedoModule['isUndoRedoPerformed'] && _this.parent.undoRedoModule['currentAction']['action'] === 'Delete' && canDelete) {
                var unassignedTask = _this.parent.flatData[_this.parent.taskIds.indexOf('R0')];
                var isPresent = unassignedTask.childRecords.filter(function (data) {
                  return data.ganttProperties.taskId === args.data['ganttProperties'].taskId;
                });
                if (args.data['hasChildRecords']) {
                  isPresent = args.data['childRecords'];
                }
                var _loop_8 = function (j) {
                  if (unassignedTask.childRecords.length === 1) {
                    _this.parent.flatData.splice(_this.parent.taskIds.indexOf('R0'), 2);
                    _this.parent.ids.splice(_this.parent.taskIds.indexOf('R0'), 2);
                    _this.parent.taskIds.splice(_this.parent.taskIds.indexOf('R0'), 2);
                  } else {
                    var index = _this.parent.taskIds.indexOf('T' + isPresent[j].ganttProperties.taskId);
                    var id_2 = 'T' + isPresent[j].ganttProperties.taskId;
                    var indexes = _this.parent.taskIds.reduce(function (a, e, i) {
                      if (e === id_2) {
                        a.push(i);
                      }
                      return a;
                    }, []);
                    index = indexes[indexes.length - 1];
                    _this.parent.taskIds.splice(index, 1);
                    _this.parent.flatData.splice(index, 1);
                    _this.parent.ids.splice(index, 1);
                  }
                };
                for (var j = 0; j < isPresent.length; j++) {
                  _loop_8(j);
                }
                if (unassignedTask && _this.parent.viewType === 'ResourceView') {
                  var isValid = _this.parent.flatData[_this.parent.taskIds.indexOf('R0') + 1];
                  if (!isValid) {
                    _this.parent.flatData.splice(_this.parent.taskIds.indexOf('R0'), 1);
                    _this.parent.ids.splice(_this.parent.taskIds.indexOf('R0'), 1);
                    _this.parent.taskIds.splice(_this.parent.taskIds.indexOf('R0'), 1);
                  }
                }
              }
            }
            _this.refreshNewlyAddedRecord(args, cAddedRecord_1);
            if (_this.parent.viewType === 'ResourceView' && _this.parent.taskFields.work && ganttData) {
              _this.parent.dataOperation.updateParentItems(ganttData, true);
            }
            _this.addNewUndoCollection(record, args);
            _this._resetProperties();
          }
          _this.parent.isOnAdded = false;
        } else {
          _this.removeAddedRecord();
          _this.reUpdatePreviousRecords();
          _this._resetProperties();
        }
      });
    }
  };
  Edit.prototype.createNewRecord = function () {
    var tempRecord = {};
    var ganttColumns = this.parent.ganttColumns;
    var taskSettingsFields = this.parent.taskFields;
    var taskId = this.parent.editModule.getNewTaskId();
    for (var i = 0; i < ganttColumns.length; i++) {
      var fieldName = ganttColumns[i].field;
      if (fieldName === taskSettingsFields.id) {
        tempRecord[fieldName] = taskId;
      } else if (ganttColumns[i].field === taskSettingsFields.startDate) {
        if (isNullOrUndefined(tempRecord[taskSettingsFields.endDate])) {
          tempRecord[fieldName] = this.parent.editModule.dialogModule.getMinimumStartDate();
        } else {
          tempRecord[fieldName] = new Date(tempRecord[taskSettingsFields.endDate]);
        }
        if (this.parent.timezone) {
          tempRecord[fieldName] = this.parent.dateValidationModule.remove(tempRecord[fieldName], this.parent.timezone);
        }
      } else if (ganttColumns[i].field === taskSettingsFields.endDate) {
        if (isNullOrUndefined(tempRecord[taskSettingsFields.startDate])) {
          tempRecord[fieldName] = this.parent.editModule.dialogModule.getMinimumStartDate();
        } else {
          tempRecord[fieldName] = new Date(tempRecord[taskSettingsFields.startDate]);
        }
        if (this.parent.timezone) {
          tempRecord[fieldName] = this.parent.dateValidationModule.remove(tempRecord[fieldName], this.parent.timezone);
        }
      } else if (ganttColumns[i].field === taskSettingsFields.duration) {
        tempRecord[fieldName] = 1;
      } else if (ganttColumns[i].field === taskSettingsFields.name) {
        tempRecord[fieldName] = this.parent.editModule.dialogModule['localeObj'].getConstant('addDialogTitle') + ' ' + taskId;
      } else if (ganttColumns[i].field === taskSettingsFields.progress) {
        tempRecord[fieldName] = 0;
      } else if (ganttColumns[i].field === taskSettingsFields.work) {
        tempRecord[fieldName] = 0;
      } else if (ganttColumns[i].field === taskSettingsFields.type || ganttColumns[i].field === 'taskType') {
        tempRecord[fieldName] = this.parent.taskType;
      } else if (ganttColumns[i].field === taskSettingsFields.milestone) {
        tempRecord[fieldName] = null;
      } else {
        tempRecord[this.parent.ganttColumns[i].field] = '';
      }
    }
    return tempRecord;
  };
  /**
   * Method to validateTaskPosition.
   *
   * @param {Object | object[] } data - Defines the new data to add.
   * @param {RowPosition} rowPosition - Defines the position of row.
   * @param {number} rowIndex - Defines the row index.
   * @param {IGanttData} cAddedRecord - Defines the single data to validate.
   * @returns {void} .
   * @private
   */
  Edit.prototype.validateTaskPosition = function (data, rowPosition, rowIndex, cAddedRecord) {
    var selectedRowIndex = isNullOrUndefined(rowIndex) || isNaN(parseInt(rowIndex.toString(), 10)) ? this.parent.selectionModule ? (this.parent.selectionSettings.mode === 'Row' || this.parent.selectionSettings.mode === 'Both') && this.parent.selectionModule.selectedRowIndexes.length === 1 ? this.parent.selectionModule.selectedRowIndexes[0] : this.parent.selectionSettings.mode === 'Cell' && this.parent.selectionModule.getSelectedRowCellIndexes().length === 1 ? this.parent.selectionModule.getSelectedRowCellIndexes()[0].rowIndex : null : null : rowIndex;
    this.addRowSelectedItem = isNullOrUndefined(selectedRowIndex) ? null : this.parent.updatedRecords[selectedRowIndex];
    rowPosition = isNullOrUndefined(rowPosition) ? this.parent.editSettings.newRowPosition : rowPosition;
    data = isNullOrUndefined(data) ? this.createNewRecord() : data;
    if ((isNullOrUndefined(selectedRowIndex) || selectedRowIndex < 0 || isNullOrUndefined(this.addRowSelectedItem)) && (rowPosition === 'Above' || rowPosition === 'Below' || rowPosition === 'Child') || !rowPosition || rowPosition !== 'Above' && rowPosition !== 'Below' && rowPosition !== 'Child' && rowPosition !== 'Top' && rowPosition !== 'Bottom') {
      rowPosition = 'Top';
    }
    var level = 0;
    var parentItem;
    switch (rowPosition) {
      case 'Top':
      case 'Bottom':
        if (this.parent.viewType === 'ResourceView') {
          level = 1;
        } else {
          level = 0;
        }
        break;
      case 'Above':
      case 'Below':
        level = this.addRowSelectedItem.level;
        parentItem = this.parent.getParentTask(this.addRowSelectedItem.parentItem);
        break;
      case 'Child':
        level = this.addRowSelectedItem.level + 1;
        parentItem = this.addRowSelectedItem;
        break;
    }
    if (!this.parent.undoRedoModule || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
      this.prepareNewlyAddedData(data, rowPosition);
    }
    var AddRecord = this.updateNewlyAddedDataBeforeAjax(data, level, rowPosition, parentItem, rowIndex);
    cAddedRecord.push(AddRecord);
  };
  Edit.prototype.updateRowIndex = function () {
    var currentViewData = this.parent.currentViewData;
    var rowCount = currentViewData.length;
    for (var index = 0; index < rowCount; index++) {
      var rowIndex = index;
      currentViewData[index].index = rowIndex;
      if (currentViewData[index].parentItem && currentViewData[index].parentItem.taskId) {
        var parentRecord = this.parent.getRecordByID(currentViewData[index].parentItem.taskId);
        if (!isNullOrUndefined(parentRecord) && !isNullOrUndefined(parentRecord.index) && !isNullOrUndefined(parentRecord.level)) {
          currentViewData[index].parentItem.index = parentRecord.index;
          currentViewData[index].parentItem.level = parentRecord.level;
        }
      }
    }
  };
  Edit.prototype.updateNewRecord = function (cAddedRecord, args) {
    var _this = this;
    cAddedRecord.forEach(function (record) {
      if (record.level === 0) {
        _this.parent.treeGrid.parentData.splice(0, 0, record);
        var tempData = getValue('dataOperation.dataArray', _this.parent);
        tempData.splice(0, 0, record.taskData);
      }
      _this.updateTreeGridUniqueID(record, 'add');
    });
    this.updateTreeGridUniqueID(cAddedRecord, 'add');
    this.refreshNewlyAddedRecord(args, cAddedRecord);
    var objCollection = {};
    this.addNewUndoCollection(objCollection, args);
    this._resetProperties();
  };
  /**
   * Method to reset the flag after adding new record
   *
   * @returns {void} .
   */
  Edit.prototype._resetProperties = function () {
    this.parent.isOnEdit = false;
    this.parent.hideSpinner();
    this.addRowSelectedItem = null;
    this.newlyAddedRecordBackup = null;
    this.isBreakLoop = false;
    this.parent.element.tabIndex = 0;
    this.parent.initiateEditAction(false);
  };
  /**
   * Method to update unique id collection in TreeGrid
   *
   * @param {IGanttData} data .
   * @param {string} action .
   * @returns {void} .
   */
  Edit.prototype.updateTreeGridUniqueID = function (data, action) {
    if (action === 'add') {
      setValue('uniqueIDCollection.' + data.uniqueID, data, this.parent.treeGrid);
    } else if (action === 'delete') {
      deleteObject(getValue('uniqueIDCollection', this.parent.treeGrid), data.uniqueID);
    }
  };
  Edit.prototype.refreshNewlyAddedRecord = function (args, cAddedRecord) {
    var _this = this;
    if (this.parent.selectionModule && this.parent.allowSelection && (this.parent.selectionSettings.mode === 'Row' || this.parent.selectionSettings.mode === 'Both')) {
      this.parent.staticSelectedRowIndex = this.parent.currentViewData.indexOf(args.data);
    }
    if (this.parent.timelineSettings.updateTimescaleView) {
      var tempArray = [];
      if (args.modifiedRecords.length > 0) {
        tempArray = args.data.length > 0 ? extend([], [], args.data, true) : [args.data]; // eslint-disable-next-line
        tempArray.push.apply(tempArray, args.modifiedRecords);
      } else {
        tempArray = args.data.length > 0 ? extend([], [], args.data, true) : [args.data];
      }
      this.parent.timelineModule.updateTimeLineOnEditing([tempArray], args.action);
    }
    this.addSuccess(args);
    args = this.constructTaskAddedEventArgs(cAddedRecord, args.modifiedRecords, 'add');
    if (this.dialogModule.isAddNewResource && !this.parent.isEdit && this.parent.taskFields.work && this.parent.taskType !== 'FixedWork') {
      this.parent.dataOperation.updateWorkWithDuration(cAddedRecord[0]);
    }
    this.updateRowIndex();
    this.parent.trigger('actionComplete', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
    if (this.dialogModule.dialog && !this.dialogModule.dialogObj.isDestroyed) {
      this.dialogModule.dialogObj.hide();
    }
    this.dialogModule.dialogClose();
    if (this.parent.viewType === 'ResourceView') {
      if (cAddedRecord.length > 1) {
        for (var i = 0; i < cAddedRecord.length; i++) {
          args.data[i].ganttProperties.sharedTaskUniqueIds.push(args.data[i].ganttProperties.rowUniqueID);
          if (args.data[i].ganttProperties.resourceInfo) {
            // if ((args.data[i] as IGanttData).ganttProperties.resourceInfo.length > 1) {
            var resources = extend([], [], args.data[i].ganttProperties.resourceInfo, true);
            resources.splice(0, 1);
            this.updateResoures([], resources, args.data[i]);
            // }
          } else {
            if (!this.parent.undoRedoModule || this.parent.undoRedoModule && !args.data[i]['hasChildRecords'] && this.parent.undoRedoModule['isUndoRedoPerformed'] || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
              this.removeChildRecord(args.data[i]);
              this.parent.editModule.checkWithUnassignedTask(args.data[i]);
            }
          }
          for (var k = 0; k < this.updateParentRecords.length; k++) {
            this.parent.dataOperation.updateParentItems(this.updateParentRecords[k]);
          }
          this.updateParentRecords = [];
        }
      } else {
        args.data.ganttProperties.sharedTaskUniqueIds.push(args.data.ganttProperties.rowUniqueID);
        // eslint-disable-next-line
        var ganttData = args.data;
        var ganttProperties = ganttData.ganttProperties;
        var childRecords = ganttData.childRecords;
        var undoRedoModule_1 = this.parent.undoRedoModule;
        var handleResourceUpdate = function (resourceInfo, targetData) {
          if (resourceInfo && resourceInfo.length > 1) {
            var resources = extend([], [], resourceInfo, true);
            resources.splice(0, 1);
            if (undoRedoModule_1 && undoRedoModule_1['isUndoRedoPerformed']) {
              _this.parent.editModule.addRowIndex = undefined;
            }
            _this.updateResoures([], resources, targetData);
          }
        };
        // eslint-disable-next-line
        if (ganttProperties.resourceInfo && ganttProperties.resourceInfo.length) {
          handleResourceUpdate(ganttProperties.resourceInfo, ganttData);
        } else if (childRecords.length > 0 && childRecords[0].ganttProperties.resourceInfo && childRecords[0].ganttProperties.resourceInfo.length) {
          handleResourceUpdate(childRecords[0].ganttProperties.resourceInfo, args.data.childRecords[0]);
        } else {
          if (!this.parent.undoRedoModule || this.parent.undoRedoModule && !args.data['hasChildRecords'] && this.parent.undoRedoModule['isUndoRedoPerformed'] || !this.parent.undoRedoModule['isUndoRedoPerformed']) {
            this.removeChildRecord(args.data);
            this.parent.editModule.checkWithUnassignedTask(args.data);
          }
        }
        for (var k = 0; k < this.updateParentRecords.length; k++) {
          this.parent.dataOperation.updateParentItems(this.updateParentRecords[k]);
        }
        this.updateParentRecords = [];
      }
    }
  };
  /**
   *
   * @returns {void} .
   * @private
   */
  Edit.prototype.removeAddedRecord = function () {
    var flatRecords = this.parent.flatData;
    var currentViewData = this.parent.currentViewData;
    var ids = this.parent.ids;
    var flatRecordsIndex = flatRecords.indexOf(this.newlyAddedRecordBackup);
    var currentViewDataIndex = currentViewData.indexOf(this.newlyAddedRecordBackup);
    var idsIndex = ids.indexOf(this.newlyAddedRecordBackup.ganttProperties.rowUniqueID.toString());
    deleteObject(this.parent.previousRecords, flatRecords[flatRecordsIndex].uniqueID);
    if (this.newlyAddedRecordBackup.parentItem) {
      var parentItem = this.parent.getParentTask(this.newlyAddedRecordBackup.parentItem);
      var parentIndex = parentItem.childRecords.indexOf(this.newlyAddedRecordBackup);
      parentItem.childRecords.splice(parentIndex, 1);
    }
    flatRecords.splice(flatRecordsIndex, 1);
    currentViewData.splice(currentViewDataIndex, 1);
    ids.splice(idsIndex, 1);
  };
  Edit.prototype.getPrevRecordIndex = function () {
    var prevRecord = this.parent.updatedRecords[this.parent.selectionModule.getSelectedRowIndexes()[0] - 1];
    var selectedRecord = this.parent.selectionModule.getSelectedRecords()[0];
    var parent = this.parent.getRootParent(prevRecord, selectedRecord.level);
    var prevIndex = this.parent.updatedRecords.indexOf(parent);
    return prevIndex;
  };
  /**
   * indent a selected record
   *
   * @returns {void} .
   */
  Edit.prototype.indent = function () {
    var index = this.parent.selectedRowIndex;
    var isSelected = this.parent.selectionModule ? this.parent.selectionModule.selectedRowIndexes.length === 1 || this.parent.selectionModule.getSelectedRowCellIndexes().length === 1 ? true : false : false;
    var dropIndex;
    var prevRecord = this.parent.updatedRecords[this.parent.selectionModule.getSelectedRowIndexes()[0] - 1];
    var selectedRecord = this.parent.selectionModule.getSelectedRecords()[0];
    if (!this.parent.editSettings.allowEditing || index === 0 || index === -1 || !isSelected || this.parent.viewType === 'ResourceView' || !isNullOrUndefined(this.parent.updatedRecords[index]) && this.parent.updatedRecords[index].level - prevRecord.level === 1) {
      return;
    } else {
      if (prevRecord.level - selectedRecord.level === 0) {
        dropIndex = this.parent.selectionModule.getSelectedRowIndexes()[0] - 1;
      } else {
        dropIndex = this.getPrevRecordIndex();
      }
      this.indentOutdentRow([this.parent.selectionModule.getSelectedRowIndexes()[0]], dropIndex, 'child');
    }
  };
  /**
   * To perform outdent operation for selected row
   *
   * @returns {void} .
   */
  Edit.prototype.outdent = function () {
    var index = this.parent.selectionModule.getSelectedRowIndexes()[0];
    var dropIndex;
    var isSelected = this.parent.selectionModule ? this.parent.selectionModule.selectedRowIndexes.length === 1 || this.parent.selectionModule.getSelectedRowCellIndexes().length === 1 ? true : false : false;
    if (!this.parent.editSettings.allowEditing || index === -1 || index === 0 || !isSelected || this.parent.viewType === 'ResourceView' || this.parent.updatedRecords[index].level === 0) {
      return;
    } else {
      var thisParent = this.parent.getTaskByUniqueID(this.parent.selectionModule.getSelectedRecords()[0].parentItem.uniqueID);
      dropIndex = this.parent.updatedRecords.indexOf(thisParent);
      this.indentOutdentRow([index], dropIndex, 'below');
    }
  };
  Edit.prototype.indentOutdentRow = function (fromIndexes, toIndex, pos) {
    var _this = this;
    // eslint-disable-next-line
    if (fromIndexes[0] !== toIndex && pos === 'above' || 'below' || 'child') {
      if (pos === 'above') {
        this.dropPosition = 'topSegment';
      }
      if (pos === 'below') {
        this.dropPosition = 'bottomSegment';
      }
      if (pos === 'child') {
        this.dropPosition = 'middleSegment';
      }
      var action = void 0;
      var record = [];
      for (var i = 0; i < fromIndexes.length; i++) {
        if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.undoRedoModule['currentAction']) {
          if (this.parent.undoRedoModule['currentAction']['modifiedRecord']) {
            record[i] = this.parent.undoRedoModule['currentAction']['modifiedRecord'][i].data;
          } else {
            record[i] = this.parent.undoRedoModule['currentAction']['data'][i];
          }
        } else {
          record[i] = this.parent.updatedRecords[fromIndexes[i]];
        }
      }
      var isByMethod_1 = true;
      var args_1 = {
        data: record,
        dropIndex: toIndex,
        dropPosition: this.dropPosition
      };
      if (this.dropPosition === 'middleSegment') {
        action = 'indenting';
      } else if (this.dropPosition === 'bottomSegment') {
        action = 'outdenting';
      }
      var actionArgs = {
        action: action,
        data: record[0],
        cancel: false
      };
      this.parent.trigger('actionBegin', actionArgs, function (actionArg) {
        if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
          _this.parent.showMaskRow();
        } else {
          _this.parent.showSpinner();
        }
        if (!actionArg.cancel) {
          _this.reArrangeRows(args_1, isByMethod_1);
        } else {
          return;
        }
      });
    } else {
      return;
    }
  };
  Edit.prototype.reArrangeRows = function (args, isByMethod) {
    var _this = this;
    this.dropPosition = args.dropPosition;
    this.parent['oldRecords'] = [];
    this.parent['oldRecords'] = extend([], [], args.data, true);
    if (args.dropPosition !== 'Invalid' && this.parent.editModule) {
      var obj = this.parent;
      var draggedRec = void 0;
      this.droppedRecord = obj.updatedRecords[args.dropIndex];
      var action = args.dropPosition === 'middleSegment' ? 'Indent' : 'Outdent';
      if (this.parent.undoRedoModule && !this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent['isUndoRedoItemPresent'](action)) {
        var record = {};
        record['action'] = action;
        record['modifiedRecord'] = [];
        record['selectedRowIndexes'] = extend([], [], this.parent.selectionModule.selectedRowIndexes, true);
        this.parent.undoRedoModule['findPosition'](extend([], [], [args.data], true)[0], record, 'modifiedRecord');
        record['droppedRecord'] = extend([], [], this.droppedRecord, true);
        if (this.parent.undoRedoModule['redoEnabled']) {
          this.parent.undoRedoModule['redoEnabled'] = false;
          this.parent.undoRedoModule['getUndoCollection'] = [];
          this.parent.undoRedoModule['getRedoCollection'] = [];
          if (this.parent.toolbarModule) {
            this.parent.toolbarModule.enableItems([this.parent.controlId + '_redo'], false);
          }
          this.parent.undoRedoModule['getUndoCollection'][0] = [];
        }
        this.parent.undoRedoModule['createUndoCollection']();
        this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = record;
      }
      var dragRecords = [];
      var droppedRec = this.droppedRecord;
      if (!args.data[0]) {
        dragRecords.push(args.data);
      } else {
        dragRecords = args.data;
      }
      var c = 0;
      var dLength = dragRecords.length;
      for (var i = 0; i < dLength; i++) {
        this.parent.isOnEdit = true;
        draggedRec = dragRecords[i];
        this.draggedRecord = draggedRec;
        if (this.dropPosition !== 'Invalid') {
          if (isByMethod) {
            this.deleteDragRow();
          }
          var recordIndex1 = this.treeGridData.indexOf(droppedRec);
          if (this.dropPosition === 'bottomSegment') {
            if (!droppedRec.hasChildRecords) {
              if (this.parent.taskFields.parentID && this.ganttData.length > 0) {
                this.ganttData.splice(recordIndex1 + 1, 0, this.draggedRecord.taskData);
              }
              this.treeGridData.splice(recordIndex1 + 1, 0, this.draggedRecord);
              this.parent.ids.splice(recordIndex1 + 1, 0, this.draggedRecord.ganttProperties.rowUniqueID.toString());
            } else {
              c = this.parent.editModule.getChildCount(droppedRec, 0);
              if (this.parent.taskFields.parentID && this.ganttData.length > 0) {
                this.ganttData.splice(recordIndex1 + c + 1, 0, this.draggedRecord.taskData);
              }
              this.treeGridData.splice(recordIndex1 + c + 1, 0, this.draggedRecord);
              this.parent.ids.splice(recordIndex1 + c + 1, 0, this.draggedRecord.ganttProperties.rowUniqueID.toString());
              var idIndex = this.parent.ids.indexOf(this.draggedRecord[this.parent.taskFields.id].toString());
              if (idIndex !== recordIndex1 + c + 1) {
                this.parent.ids.splice(idIndex, 1);
                this.parent.ids.splice(recordIndex1 + c + 1, 0, this.draggedRecord[this.parent.taskFields.id].toString());
              }
            }
            this.parent.setRecordValue('parentItem', this.treeGridData[recordIndex1].parentItem, draggedRec);
            this.parent.setRecordValue('parentUniqueID', this.treeGridData[recordIndex1].parentUniqueID, draggedRec);
            this.parent.setRecordValue('level', this.treeGridData[recordIndex1].level, draggedRec);
            if (draggedRec.hasChildRecords) {
              var level = 1;
              this.updateChildRecordLevel(draggedRec, level);
              this.updateChildRecord(draggedRec, recordIndex1 + c + 1);
            }
            if (droppedRec.parentItem) {
              var record = this.parent.getParentTask(droppedRec.parentItem).childRecords;
              var childRecords = record;
              var droppedRecordIndex = childRecords.indexOf(droppedRec) + 1;
              childRecords.splice(droppedRecordIndex, 0, draggedRec);
            }
          }
          if (this.dropPosition === 'middleSegment') {
            this.dropMiddle(recordIndex1);
          }
          if (!isNullOrUndefined(draggedRec.parentItem && this.updateParentRecords.indexOf(draggedRec.parentItem) !== -1)) {
            this.updateParentRecords.push(this.parent.getTaskByUniqueID(draggedRec.parentItem.uniqueID));
          }
        }
        if (isNullOrUndefined(draggedRec.parentItem)) {
          var parentRecords = this.parent.treeGrid.parentData;
          var newParentIndex = parentRecords.indexOf(this.droppedRecord);
          if (this.dropPosition === 'bottomSegment') {
            parentRecords.splice(newParentIndex + 1, 0, draggedRec);
          }
        }
        this.refreshDataSource();
      }
      if (this.dropPosition === 'middleSegment') {
        if (!isNullOrUndefined(droppedRec.ganttProperties.predecessor)) {
          var len = droppedRec.ganttProperties.predecessor.length;
          for (var count = len - 1; count >= 0; count--) {
            if (!isNullOrUndefined(droppedRec.ganttProperties.predecessor)) {
              var fromRecord = this.parent.getRecordByID(droppedRec.ganttProperties.predecessor[count].from);
              var toRecord = this.parent.getRecordByID(droppedRec.ganttProperties.predecessor[count].to);
              var validPredecessor = this.parent.connectorLineEditModule.validateParentPredecessor(fromRecord, toRecord);
              if (droppedRec.ganttProperties.predecessor && (!validPredecessor || !this.parent.allowParentDependency)) {
                this.parent.editModule.removePredecessorOnDelete(droppedRec);
                droppedRec.ganttProperties.predecessor.splice(count, 1);
                droppedRec.ganttProperties.predecessorsName = null;
                droppedRec[this.parent.taskFields.dependency] = null;
                droppedRec.taskData[this.parent.taskFields.dependency] = null;
              }
            }
          }
        }
        if (droppedRec.ganttProperties.isMilestone) {
          this.parent.setRecordValue('isMilestone', false, droppedRec.ganttProperties, true);
          if (!isNullOrUndefined(droppedRec.taskData[this.parent.taskFields.milestone])) {
            if (droppedRec.taskData[this.parent.taskFields.milestone] === true) {
              droppedRec.taskData[this.parent.taskFields.milestone] = false;
            }
          }
        }
      }
      for (var k = 0; k < this.updateParentRecords.length; k++) {
        this.parent.dataOperation.updateParentItems(this.updateParentRecords[k]);
      }
      this.parent.editedRecords.forEach(function (record) {
        _this.isFirstCall = true;
        _this.parent.predecessorModule.validatePredecessor(record, [], '');
      });
      for (var k = 0; k < this.updateParentRecords.length; k++) {
        this.parent.dataOperation.updateParentItems(this.updateParentRecords[k]);
      }
      this.updateParentRecords = [];
      this.parent.isOnEdit = false;
    }
    this.canReset = true;
    this.refreshRecord(args);
  };
  /**
   * @returns {void} .
   * @param {RowDropEventArgs} args .
   * @param {boolean} isDrag .
   * @private
   */
  Edit.prototype.refreshRecord = function (args, isDrag) {
    var _this = this;
    if (isRemoteData(this.parent.dataSource)) {
      var data = this.parent.dataSource;
      var updatedData = {
        changedRecords: getTaskData(this.parent.editedRecords, null, null, this.parent)
      };
      var queryValue = this.parent.query instanceof Query ? this.parent.query : new Query();
      var crud = null;
      var adaptor = data.adaptor;
      if (!(adaptor instanceof WebApiAdaptor && adaptor instanceof ODataAdaptor) || data.dataSource.batchUrl) {
        crud = data.saveChanges(updatedData, this.parent.taskFields.id, null, queryValue);
      } else {
        var changedRecords = 'changedRecords';
        crud = data.update(this.parent.taskFields.id, updatedData[changedRecords], null, queryValue);
      }
      crud.then(function (e) {
        return _this.indentSuccess(e, args, isDrag);
      }).catch(function (e) {
        return _this.indentFailure(e);
      });
    } else {
      this.indentOutdentSuccess(args, isDrag);
    }
  };
  Edit.prototype.indentSuccess = function (e, args, isDrag) {
    this.updateEditedFields(e);
    this.indentOutdentSuccess(args, isDrag);
  };
  Edit.prototype.indentFailure = function (e) {
    this.parent.trigger('actionFailure', {
      error: e
    });
  };
  Edit.prototype.indentOutdentSuccess = function (args, isDrag) {
    this.parent.treeGrid.parentData = [];
    if (this.parent.treeGrid.editModule) {
      this.parent.treeGrid.editModule['isOnBatch'] = false;
    }
    this.parent.treeGrid.refresh();
    if (this.parent.enableImmutableMode) {
      this.refreshRecordInImmutableMode(args.data, isDrag);
      this.parent.chartRowsModule.refreshRecords(this.parent.editedRecords);
    }
    if (isDrag) {
      args.requestType = 'rowDropped';
    } else {
      if (this.dropPosition === 'middleSegment') {
        args.requestType = 'indented';
      } else if (this.dropPosition === 'bottomSegment') {
        args.requestType = 'outdented';
      }
    }
    args.modifiedRecords = this.parent.editedRecords;
    if (this.parent.timezone) {
      for (var i = 0; i < args.modifiedRecords.length; i++) {
        updateDates(args.modifiedRecords[i], this.parent);
      }
    }
    if (this.canReset) {
      this.parent.previousRecords = {};
    }
    this.canReset = false;
    this.parent.trigger('actionComplete', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.hideMaskRow();
    } else {
      this.parent.hideSpinner();
    }
    if (this.parent.rowDragAndDropModule) {
      this.parent.rowDragAndDropModule['draggedRecord'] = null;
    }
  };
  Edit.prototype.refreshDataSource = function () {
    var draggedRec = this.draggedRecord;
    var droppedRec = this.droppedRecord;
    var proxy = this.parent;
    var tempData;
    var indx;
    if (this.parent.dataSource instanceof DataManager) {
      tempData = getValue('dataOperation.dataArray', this.parent);
    } else {
      tempData = proxy.dataSource;
    }
    if (tempData.length > 0 && !isNullOrUndefined(droppedRec) && !droppedRec.parentItem) {
      for (var i = 0; i < Object.keys(tempData).length; i++) {
        if (tempData[i][this.parent.taskFields.child] === droppedRec.taskData[this.parent.taskFields.child]) {
          indx = i;
        }
      }
      if (this.dropPosition === 'topSegment') {
        if (!this.parent.taskFields.parentID) {
          tempData.splice(indx, 0, draggedRec.taskData);
        }
      } else if (this.dropPosition === 'bottomSegment') {
        if (!this.parent.taskFields.parentID) {
          tempData.splice(indx + 1, 0, draggedRec.taskData);
        }
      }
    } else if (!this.parent.taskFields.parentID && !isNullOrUndefined(droppedRec) && droppedRec.parentItem) {
      if (this.dropPosition === 'topSegment' || this.dropPosition === 'bottomSegment') {
        var rowPos = this.dropPosition === 'topSegment' ? 'Above' : 'Below';
        this.parent.editModule.addRowSelectedItem = droppedRec;
        var dragRecord = [];
        if (!Array.isArray(draggedRec)) {
          dragRecord[0] = draggedRec;
        } else {
          dragRecord = draggedRec;
        }
        this.parent.editModule.updateRealDataSource(dragRecord, rowPos);
        delete this.parent.editModule.addRowSelectedItem;
      }
    }
    if (this.parent.taskFields.parentID) {
      if (draggedRec.parentItem) {
        if (this.dropPosition === 'topSegment' || this.dropPosition === 'bottomSegment') {
          draggedRec[this.parent.taskFields.parentID] = droppedRec[this.parent.taskFields.parentID];
          draggedRec.taskData[this.parent.taskFields.parentID] = droppedRec[this.parent.taskFields.parentID];
          draggedRec.ganttProperties['parentId'] = droppedRec[this.parent.taskFields.parentID];
        } else {
          draggedRec[this.parent.taskFields.parentID] = droppedRec[this.parent.taskFields.id];
          draggedRec.taskData[this.parent.taskFields.parentID] = droppedRec[this.parent.taskFields.id];
          draggedRec.ganttProperties['parentId'] = droppedRec[this.parent.taskFields.id];
        }
      } else {
        draggedRec[this.parent.taskFields.parentID] = null;
        draggedRec.taskData[this.parent.taskFields.parentID] = null;
        draggedRec.ganttProperties['parentId'] = null;
      }
    }
  };
  Edit.prototype.deleteDragRow = function () {
    if (this.parent.dataSource instanceof DataManager) {
      this.ganttData = getValue('dataOperation.dataArray', this.parent);
    } else {
      this.ganttData = isCountRequired(this.parent) ? getValue('result', this.parent.dataSource) : this.parent.dataSource;
    }
    this.treeGridData = isCountRequired(this.parent) ? getValue('result', this.parent.treeGrid.dataSource) : this.parent.treeGrid.dataSource;
    var delRow = this.parent.getTaskByUniqueID(this.draggedRecord.uniqueID);
    this.removeRecords(delRow);
  };
  Edit.prototype.updateIndentedChildRecords = function (indentedRecord) {
    var createParentItem = {
      uniqueID: indentedRecord.uniqueID,
      expanded: indentedRecord.expanded,
      level: indentedRecord.level,
      index: indentedRecord.index,
      taskId: indentedRecord.ganttProperties.rowUniqueID
    };
    for (var i = 0; i < indentedRecord.childRecords.length; i++) {
      this.parent.setRecordValue('parentItem', createParentItem, indentedRecord.childRecords[i]);
      this.parent.setRecordValue('parentUniqueID', indentedRecord.uniqueID, indentedRecord.childRecords[i]);
    }
    if (indentedRecord.hasChildRecords) {
      indentedRecord = indentedRecord.childRecords;
      for (var j = 0; j < indentedRecord['length']; j++) {
        this.updateIndentedChildRecords(indentedRecord[j]);
      }
    }
  };
  Edit.prototype.dropMiddle = function (recordIndex1) {
    var obj = this.parent;
    var childRec;
    var childRecordsLength;
    if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.undoRedoModule['currentAction']['modifiedRecord'] && this.parent.undoRedoModule['currentAction']['modifiedRecord'][0].position !== 'child') {
      if (this.parent.undoRedoModule['currentAction']['modifiedRecord'][0].position === 'above') {
        childRecordsLength = this.parent.ids.indexOf(this.parent.undoRedoModule['currentAction']['modifiedRecord'][0].id.toString());
      } else if (this.parent.undoRedoModule['currentAction']['modifiedRecord'][0].position === 'below') {
        childRecordsLength = this.parent.ids.indexOf(this.parent.undoRedoModule['currentAction']['modifiedRecord'][0].id.toString()) + 1;
      }
    } else {
      childRec = this.parent.editModule.getChildCount(this.droppedRecord, 0);
      childRecordsLength = isNullOrUndefined(childRec) || childRec === 0 ? recordIndex1 + 1 : childRec + recordIndex1 + 1;
    }
    if (this.dropPosition === 'middleSegment') {
      if (this.droppedRecord.ganttProperties.isAutoSchedule && !isNullOrUndefined(this.droppedRecord.ganttProperties.predecessorsName) && this.droppedRecord.ganttProperties.predecessorsName !== '') {
        var startDate = this.droppedRecord.ganttProperties.startDate;
        this.parent.setRecordValue('startDate', startDate, this.draggedRecord.ganttProperties, true);
        this.parent.dateValidationModule.calculateEndDate(this.draggedRecord);
        this.parent.dataOperation.updateTaskData(this.draggedRecord);
        this.parent.dataOperation['updateTaskLeftWidth'](this.draggedRecord);
      }
      if (obj.taskFields.parentID && this.ganttData.length > 0) {
        this.ganttData.splice(childRecordsLength, 0, this.draggedRecord.taskData);
      }
      this.treeGridData.splice(childRecordsLength, 0, this.draggedRecord);
      this.parent.ids.splice(childRecordsLength, 0, this.draggedRecord[this.parent.taskFields.id].toString());
      this.recordLevel();
      if (this.draggedRecord.hasChildRecords) {
        this.updateChildRecord(this.draggedRecord, childRecordsLength, this.droppedRecord.expanded);
        if (this.parent.enableImmutableMode) {
          var indentedRecord = this.draggedRecord;
          this.updateIndentedChildRecords(indentedRecord);
        }
      }
      if (isNullOrUndefined(this.draggedRecord.parentItem && this.updateParentRecords.indexOf(this.draggedRecord.parentItem) !== -1)) {
        this.updateParentRecords.push(this.draggedRecord.parentItem);
      }
    }
  };
  Edit.prototype.updateChildRecordLevel = function (record, levl) {
    var length = 0;
    var currentRec;
    levl++;
    if (!record.hasChildRecords) {
      return 0;
    }
    length = record.childRecords.length;
    for (var j = 0; j < length; j++) {
      currentRec = record.childRecords[j];
      var parentData = void 0;
      if (record.parentItem) {
        var id = 'uniqueIDCollection';
        parentData = this.parent.treeGrid[id][record.parentItem.uniqueID];
      }
      currentRec.level = record.parentItem ? parentData.level + levl : record.level + 1;
      if (currentRec.hasChildRecords) {
        levl--;
        levl = this.updateChildRecordLevel(currentRec, levl);
      }
    }
    return levl;
  };
  /* eslint-disable-next-line */
  Edit.prototype.updateChildRecord = function (record, count, expanded) {
    var currentRec;
    var obj = this.parent;
    var length = 0;
    if (!record.hasChildRecords) {
      return 0;
    }
    length = record.childRecords.length;
    for (var i = 0; i < length; i++) {
      currentRec = record.childRecords[i];
      count++;
      obj.flatData.splice(count, 0, currentRec);
      this.parent.ids.splice(count, 0, currentRec.ganttProperties.rowUniqueID.toString());
      if (obj.taskFields.parentID && this.ganttData.length > 0) {
        this.ganttData.splice(count, 0, currentRec.taskData);
      }
      if (currentRec.hasChildRecords) {
        count = this.updateChildRecord(currentRec, count);
      }
    }
    return count;
  };
  Edit.prototype.removeRecords = function (record) {
    var obj = this.parent;
    var dataSource;
    if (this.parent.dataSource instanceof DataManager) {
      dataSource = getValue('dataOperation.dataArray', this.parent);
    } else {
      dataSource = this.parent.dataSource;
    }
    var delRow = record;
    if (delRow) {
      var flatParent = this.parent.getParentTask(delRow.parentItem);
      if (delRow.parentItem) {
        var childRecords = flatParent ? flatParent.childRecords : [];
        var childIndex = 0;
        if (childRecords && childRecords.length > 0) {
          childIndex = childRecords.indexOf(delRow);
          flatParent.childRecords.splice(childIndex, 1);
          if (!this.parent.taskFields.parentID) {
            flatParent.taskData[this.parent.taskFields.child].splice(childIndex, 1);
          }
          // collection for updating parent record
          this.updateParentRecords.push(flatParent);
        }
      }
      if (obj.taskFields.parentID) {
        if (delRow.hasChildRecords && delRow.childRecords.length > 0) {
          this.removeChildItem(delRow);
        }
        var indx = void 0;
        var ganttData = dataSource.length > 0 ? dataSource : this.parent.currentViewData;
        for (var i = 0; i < ganttData.length; i++) {
          if (ganttData[i][this.parent.taskFields.id] === delRow.taskData[this.parent.taskFields.id]) {
            indx = i;
          }
        }
        if (indx !== -1) {
          if (dataSource.length > 0) {
            dataSource.splice(indx, 1);
          }
          var gridIndx = void 0;
          for (var i = 0; i < this.treeGridData.length; i++) {
            if (this.treeGridData[i][this.parent.taskFields.id] === delRow.taskData[this.parent.taskFields.id]) {
              gridIndx = i;
            }
          }
          this.treeGridData.splice(gridIndx, 1);
          this.parent.ids.splice(gridIndx, 1);
          if (this.parent.treeGrid.parentData.indexOf(delRow) !== -1) {
            this.parent.treeGrid.parentData.splice(this.parent.treeGrid.parentData.indexOf(delRow), 1);
          }
        }
      }
      var recordIdx = this.treeGridData.indexOf(delRow);
      if (!obj.taskFields.parentID) {
        var deletedRecordCount = this.getChildCount(delRow, 0);
        this.treeGridData.splice(recordIdx, deletedRecordCount + 1);
        this.parent.ids.splice(recordIdx, deletedRecordCount + 1);
        var parentIndex = this.ganttData.indexOf(delRow.taskData);
        if (parentIndex !== -1) {
          this.ganttData.splice(parentIndex, 1);
          this.parent.treeGrid.parentData.splice(parentIndex, 1);
        }
      }
      if (delRow.parentItem && flatParent && flatParent.childRecords && !flatParent.childRecords.length) {
        this.parent.setRecordValue('expanded', false, flatParent);
        this.parent.setRecordValue('hasChildRecords', false, flatParent);
      }
    }
  };
  Edit.prototype.removeChildItem = function (record) {
    var currentRec;
    var indx;
    for (var i = 0; i < record.childRecords.length; i++) {
      currentRec = record.childRecords[i];
      var data = void 0;
      if (this.parent.dataSource instanceof DataManager) {
        data = getValue('dataOperation.dataArray', this.parent);
      } else {
        data = this.parent.dataSource;
      }
      for (var j = 0; j < data.length; j++) {
        if (data[j][this.parent.taskFields.id] === currentRec.taskData[this.parent.taskFields.id]) {
          indx = j;
        }
      }
      if (indx !== -1) {
        if (data.length > 0) {
          data.splice(indx, 1);
        }
        var gridIndx = void 0;
        for (var i_2 = 0; i_2 < this.treeGridData.length; i_2++) {
          if (this.treeGridData[i_2][this.parent.taskFields.id] === currentRec.taskData[this.parent.taskFields.id]) {
            gridIndx = i_2;
          }
        }
        this.treeGridData.splice(gridIndx, 1);
        this.parent.ids.splice(gridIndx, 1);
      }
      if (currentRec.hasChildRecords) {
        this.removeChildItem(currentRec);
      }
    }
  };
  Edit.prototype.recordLevel = function () {
    var obj = this.parent;
    var draggedRec = this.draggedRecord;
    var droppedRec = this.droppedRecord;
    var childItem = obj.taskFields.child;
    if (!droppedRec.hasChildRecords) {
      droppedRec.hasChildRecords = true;
      if (!droppedRec.childRecords.length) {
        droppedRec.childRecords = [];
        if (!obj.taskFields.parentID && isNullOrUndefined(droppedRec.taskData[childItem])) {
          droppedRec.taskData[childItem] = [];
        }
      }
    }
    if (this.dropPosition === 'middleSegment') {
      var parentItem = extend({}, droppedRec);
      delete parentItem.childRecords;
      var createParentItem = {
        uniqueID: parentItem.uniqueID,
        expanded: parentItem.expanded,
        level: parentItem.level,
        index: parentItem.index,
        taskId: parentItem.ganttProperties.rowUniqueID
      };
      this.parent.setRecordValue('parentItem', createParentItem, draggedRec);
      this.parent.setRecordValue('parentUniqueID', droppedRec.uniqueID, draggedRec);
      droppedRec.childRecords.splice(droppedRec.childRecords.length, 0, draggedRec);
      if (!isNullOrUndefined(draggedRec) && !obj.taskFields.parentID && !isNullOrUndefined(droppedRec.taskData[childItem])) {
        droppedRec.taskData[obj.taskFields.child].splice(droppedRec.childRecords.length, 0, draggedRec.taskData);
      }
      if (!isNullOrUndefined(droppedRec.ganttProperties.segments) && droppedRec.ganttProperties.segments.length > 0) {
        droppedRec.ganttProperties.segments = null;
        droppedRec.taskData[obj.taskFields.segments] = null;
      }
      if (!draggedRec.hasChildRecords) {
        draggedRec.level = droppedRec.level + 1;
      } else {
        var level = 1;
        draggedRec.level = droppedRec.level + 1;
        this.updateChildRecordLevel(draggedRec, level);
      }
      droppedRec.expanded = true;
    }
  };
  return Edit;
}();

/**
 * To handle column reorder action from TreeGrid
 */
var Reorder = /** @__PURE__ @class */function () {
  function Reorder(gantt) {
    this.parent = gantt;
    TreeGrid.Inject(Reorder$2);
    this.parent.treeGrid.allowReordering = this.parent.allowReordering;
    this.bindEvents();
  }
  /**
   * Get module name
   *
   * @returns {string} .
   */
  Reorder.prototype.getModuleName = function () {
    return 'reorder';
  };
  /**
   * To bind reorder events.
   *
   * @returns {void} .
   * @private
   */
  Reorder.prototype.bindEvents = function () {
    var _this = this;
    this.parent.treeGrid.columnDragStart = function (args) {
      _this.parent.trigger('columnDragStart', args);
    };
    this.parent.treeGrid.columnDrag = function (args) {
      _this.parent.trigger('columnDrag', args);
    };
    this.parent.treeGrid.columnDrop = function (args) {
      _this.parent.trigger('columnDrop', args);
    };
  };
  /**
   * To destroy the column-reorder.
   *
   * @returns {void} .
   * @private
   */
  Reorder.prototype.destroy = function () {
    // Destroy Method
  };
  return Reorder;
}();
var __assign$5 = undefined && undefined.__assign || function () {
  __assign$5 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$5.apply(this, arguments);
};
/**
 * Column resize action related code goes here
 */
var Resize = /** @__PURE__ @class */function () {
  function Resize(gantt) {
    this.parent = gantt;
    TreeGrid.Inject(Resize$2);
    this.parent.treeGrid.allowResizing = this.parent.allowResizing;
    this.bindEvents();
  }
  /**
   * Get module name
   *
   * @returns {void} .
   */
  Resize.prototype.getModuleName = function () {
    return 'resize';
  };
  /**
   * To bind resize events.
   *
   * @returns {void} .
   * @private
   */
  Resize.prototype.bindEvents = function () {
    var _this = this;
    this.parent.treeGrid.resizeStart = function (args) {
      if (_this.parent.undoRedoModule && _this.parent['isUndoRedoItemPresent']('ColumnResize')) {
        _this.parent.undoRedoModule['createUndoCollection']();
        var details = {};
        details['action'] = 'ColumnResize';
        details['resizedColumn'] = __assign$5({}, args.column);
        _this.parent.undoRedoModule['getUndoCollection'][_this.parent.undoRedoModule['getUndoCollection'].length - 1] = details;
      }
      _this.parent.trigger('resizeStart', args);
    };
    this.parent.treeGrid.resizing = function (args) {
      _this.parent.trigger('resizing', args);
    };
    this.parent.treeGrid.resizeStop = function (args) {
      _this.parent.trigger('resizeStop', args);
    };
  };
  /**
   * To destroy the column-resizer.
   *
   * @returns {void} .
   * @private
   */
  Resize.prototype.destroy = function () {
    // Destroy Method
  };
  return Resize;
}();

/**
 * The Filter module is used to handle filter action.
 */
var Filter = /** @__PURE__ @class */function () {
  function Filter(gantt) {
    this.parent = gantt;
    TreeGrid.Inject(Filter$2);
    this.parent.treeGrid.allowFiltering = this.parent.allowFiltering;
    this.updateCustomFilters();
    this.parent.treeGrid.filterSettings = getActualProperties(this.parent.filterSettings);
    this.addEventListener();
  }
  Filter.prototype.getModuleName = function () {
    return 'filter';
  };
  /**
   * Update custom filter for default Gantt columns
   *
   * @returns {void} .
   */
  Filter.prototype.updateCustomFilters = function () {
    var settings = this.parent.taskFields;
    for (var i = 0; i < this.parent.ganttColumns.length; i++) {
      var column = this.parent.ganttColumns[i];
      if ((column.editType === 'datepickeredit' || column.editType === 'datetimepickeredit') && (column.field === settings.startDate || column.field === settings.endDate || column.field === settings.baselineStartDate || column.field === settings.baselineEndDate) || column.field === settings.duration && column.editType === 'stringedit') {
        this.initiateFiltering(this.parent.ganttColumns[i]);
      }
    }
  };
  Filter.prototype.updateModel = function () {
    this.parent.filterSettings = this.parent.treeGrid.filterSettings;
  };
  Filter.prototype.addEventListener = function () {
    this.parent.on('updateModel', this.updateModel, this);
    this.parent.on('actionBegin', this.actionBegin, this);
    this.parent.on('actionComplete', this.actionComplete, this);
    this.parent.on('columnMenuOpen', this.columnMenuOpen, this);
  };
  Filter.prototype.wireEvents = function (a) {
    EventHandler.add(document.getElementById(a), 'click', this.mouseClickHandler, this);
  };
  Filter.prototype.initiateFiltering = function (column) {
    var treeColumn = this.parent.getColumnByField(column.field, this.parent.treeGridModule.treeGridColumns);
    column.allowFiltering = column.allowFiltering === false ? false : true;
    if (column.allowFiltering && (this.parent.filterSettings.type === 'Menu' || this.parent.filterSettings.type === 'Excel') && !column.filter) {
      column.filter = {
        ui: this.getCustomFilterUi(column)
      };
    }
    if (treeColumn) {
      treeColumn.allowFiltering = column.allowFiltering;
      treeColumn.filter = column.allowFiltering ? column.filter : {};
    }
  };
  /**
   * To get filter menu UI
   *
   * @param {ColumnModel} column .
   * @returns {IFilterMUI} .
   */
  Filter.prototype.getCustomFilterUi = function (column) {
    var settings = this.parent.taskFields;
    var filterUI = {};
    if (column.editType === 'datepickeredit' && (column.field === settings.startDate || column.field === settings.endDate || column.field === settings.baselineStartDate || column.field === settings.baselineEndDate)) {
      filterUI = this.getDatePickerFilter(column.field);
    } else if (column.editType === 'datetimepickeredit' && (column.field === settings.startDate || column.field === settings.endDate || column.field === settings.baselineStartDate || column.field === settings.baselineEndDate)) {
      filterUI = this.getDateTimePickerFilter();
    } else if (column.field === settings.duration && column.editType === 'stringedit') {
      filterUI = this.getDurationFilter();
    }
    return filterUI;
  };
  Filter.prototype.mouseClickHandler = function (e) {
    if (closest(e.target, '.e-excelfilter')) {
      this.parent.treeGrid.grid.notify('click', e);
    }
  };
  Filter.prototype.unWireEvents = function () {
    EventHandler.remove(this.parent.element, 'click', this.mouseClickHandler);
  };
  Filter.prototype.getDatePickerFilter = function (columnName) {
    var _this = this;
    var parent = this.parent;
    var timeValue = columnName === parent.taskFields.startDate || columnName === parent.taskFields.baselineStartDate ? parent.defaultStartTime : parent.defaultEndTime;
    var dropDateInstance;
    var filterDateUI = {
      create: function (args) {
        var format = getCustomDateFormat(args.column.format, args.column.type);
        var flValInput = createElement('input', {
          className: 'flm-input'
        });
        args.target.appendChild(flValInput);
        dropDateInstance = new DatePicker({
          placeholder: _this.parent.localeObj.getConstant('enterValue'),
          format: format
        });
        dropDateInstance.enableRtl = _this.parent.enableRtl;
        dropDateInstance.appendTo(flValInput);
      },
      write: function (args) {
        dropDateInstance.value = args.filteredValue;
      },
      read: function (args) {
        if (dropDateInstance.value) {
          dropDateInstance.value.setSeconds(timeValue);
        }
        args.fltrObj.filterByColumn(args.column.field, args.operator, dropDateInstance.value);
      }
    };
    return filterDateUI;
  };
  Filter.prototype.getDateTimePickerFilter = function () {
    var _this = this;
    var dropInstance;
    var filterDateTimeUI = {
      create: function (args) {
        var format = getCustomDateFormat(args.column.format, args.column.type);
        var flValInput = createElement('input', {
          className: 'flm-input'
        });
        args.target.appendChild(flValInput);
        dropInstance = new DateTimePicker({
          placeholder: _this.parent.localeObj.getConstant('enterValue'),
          format: format
        });
        dropInstance.enableRtl = _this.parent.enableRtl;
        dropInstance.appendTo(flValInput);
      },
      write: function (args) {
        dropInstance.value = args.filteredValue;
      },
      read: function (args) {
        args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
      }
    };
    return filterDateTimeUI;
  };
  Filter.prototype.getDurationFilter = function () {
    var _this = this;
    var parent = this.parent;
    var textBoxInstance;
    var textValue = '';
    var filterDurationUI = {
      create: function (args) {
        var flValInput = createElement('input', {
          className: 'e-input'
        });
        flValInput.setAttribute('placeholder', _this.parent.localeObj.getConstant('enterValue'));
        args.target.appendChild(flValInput);
        textBoxInstance = new TextBox();
        textBoxInstance.enableRtl = _this.parent.enableRtl;
        textBoxInstance.appendTo(flValInput);
      },
      write: function (args) {
        textBoxInstance.value = args.filteredValue ? textValue : '';
      },
      read: function (args) {
        var durationObj = _this.parent.dataOperation.getDurationValue(textBoxInstance.value);
        var intVal = getValue('duration', durationObj);
        var unit = getValue('durationUnit', durationObj);
        if (intVal >= 0) {
          var dayVal = void 0;
          if (unit === 'minute') {
            dayVal = intVal * 60 / parent.secondsPerDay;
          } else if (unit === 'hour') {
            dayVal = intVal * 60 * 60 / parent.secondsPerDay;
          } else {
            //Consider it as day unit
            dayVal = intVal;
            unit = 'day';
          }
          args.fltrObj.filterByColumn(args.column.field, args.operator, dayVal);
          textValue = _this.parent.dataOperation.getDurationString(intVal, unit);
        } else {
          args.fltrObj.filterByColumn(args.column.field, args.operator, null);
          textValue = null;
        }
      }
    };
    return filterDurationUI;
  };
  /**
   * Remove filter menu while opening column chooser menu
   *
   * @param {ColumnMenuOpenEventArgs} args .
   * @returns {void} .
   */
  Filter.prototype.columnMenuOpen = function (args) {
    if (this.filterMenuElement && document.body.contains(this.filterMenuElement)) {
      remove(this.filterMenuElement);
    }
    this.filterMenuElement = null;
  };
  // eslint-disable-next-line
  Filter.prototype.actionBegin = function (args) {
    // ...
  };
  Filter.prototype.closeFilterOnContextClick = function (element) {
    var datePickerElement = document.querySelector('body > div.e-datepicker');
    var dateTimePickerElement = document.querySelector('body > div.e-datetimepicker');
    if (this.filterMenuElement && document.body.contains(this.filterMenuElement)) {
      var ganttElement = closest(element, '#' + this.parent.element.id) || element.querySelector('#' + this.parent.element.id);
      if (!this.filterMenuElement.contains(element) && !isNullOrUndefined(ganttElement) || !this.filterMenuElement.contains(element) && isNullOrUndefined(datePickerElement) && isNullOrUndefined(dateTimePickerElement) && (element.nodeName === 'DIV' || element.nodeName === 'HTML' || element.nodeName === 'SPAN' || element.nodeName === 'BUTTON') && !element.classList.contains('e-dropdownbase')) {
        remove(this.filterMenuElement);
        this.parent.treeGrid.grid.notify('filter-menu-close', {
          isOpen: false
        });
        this.filterMenuElement = null;
      }
    }
  };
  Filter.prototype.actionComplete = function (args) {
    if (!isNullOrUndefined(args['filterModel'])) {
      if (!isNullOrUndefined(args['filterModel']['dialogObj'])) {
        if (!isNullOrUndefined(args['filterModel']['dialogObj']['element'])) {
          if (this.parent.filterSettings.type === 'Excel') {
            this.wireEvents(args['filterModel']['dialogObj']['element'].id);
          }
        }
      }
    }
    if (args.requestType === filterAfterOpen) {
      if (this.parent.treeGrid.filterSettings.type === 'Menu') {
        this.filterMenuElement = getValue('filterModel.dlgObj.element', args);
      } else {
        this.filterMenuElement = getValue('filterModel.dialogObj.element', args);
      }
      this.updateFilterMenuPosition(this.filterMenuElement, args);
      // To set default values as 'contains' in filter dialog
      var taskID = this.parent.taskFields.id;
      var predecessor = this.parent.taskFields.dependency;
      var resource = this.parent.taskFields.resourceInfo;
      var filterObj = this.parent.treeGrid.grid.filterModule;
      var filterValues = getValue('values', filterObj);
      if (args.columnName === predecessor && isNullOrUndefined(getValue(predecessor, filterValues)) || args.columnName === resource && isNullOrUndefined(getValue(resource, filterValues))) {
        var element = this.filterMenuElement.querySelector('.e-dropdownlist');
        var instanceObj = void 0;
        if (!isNullOrUndefined(element)) {
          instanceObj = getValue('ej2_instances[0]', element);
          instanceObj.index = 2;
          instanceObj.dataBind();
        }
      } else if (args.columnName === taskID && isNullOrUndefined(getValue(taskID, filterValues)) && this.parent.treeGrid.filterSettings.type === 'Menu') {
        var element = this.filterMenuElement.querySelector('.e-flmenu-input');
        var instanceObj = getValue('ej2_instances[0]', element);
        if (!isNullOrUndefined(instanceObj) && isNullOrUndefined(this.parent.columnByField[args.columnName].format)) {
          instanceObj.format = 'n';
        }
      }
    }
  };
  Filter.prototype.setPosition = function (li, ul) {
    /* eslint-disable-next-line */
    var gridPos = this.parent.element.getBoundingClientRect();
    var gridPosTop = gridPos.top + window.scrollY;
    var gridPosLeft = gridPos.left;
    /* eslint-disable-next-line */
    var parentNode;
    var parentNodeTop;
    var parentNodeLeft;
    var paddingTop;
    var paddingLeft;
    var marginTop;
    var isValid = true;
    var marginLeft;
    var isNonBodyTag = false;
    if (!isNullOrUndefined(this.parent.element.parentNode) && this.parent.element.parentNode['tagName'] !== 'BODY') {
      isNonBodyTag = true;
      parentNode = this.parent.element.parentNode;
      var topValue = Math.abs(parentNode.getBoundingClientRect().top);
      parentNodeTop = topValue > window.scrollY ? topValue - window.scrollY : window.scrollY - topValue;
      marginTop = parentNode.style.marginTop;
      while (isValid) {
        if (Math.abs(gridPosTop) > Math.abs(parentNodeTop)) {
          paddingTop = gridPosTop - parentNodeTop;
          break;
        }
        if (!isNullOrUndefined(this.parent.element.parentNode)) {
          if (parentNode.parentNode instanceof HTMLDocument) {
            break;
          }
          parentNode = parentNode.parentNode;
          if (parentNode.parentNode && parentNode.parentNode.style) {
            marginTop = parentNode.parentNode.style.marginTop;
          }
        }
        parentNodeTop = parentNode.getBoundingClientRect().top;
      }
      parentNodeLeft = parentNode.getBoundingClientRect().left;
      marginLeft = parentNode.style.marginLeft;
      while (isValid) {
        if (Math.abs(gridPosLeft) > Math.abs(parentNodeLeft)) {
          paddingLeft = gridPosLeft - parentNodeLeft;
          break;
        }
        if (!isNullOrUndefined(this.parent.element.parentNode)) {
          if (parentNode.parentNode instanceof HTMLDocument) {
            break;
          }
          parentNode = parentNode.parentNode;
          marginLeft = parentNode.style.marginLeft;
        }
        parentNodeLeft = parentNode.getBoundingClientRect().left;
      }
    }
    /* eslint-disable-next-line */
    var liPos = li.getBoundingClientRect();
    var left = liPos.right + window.scrollX;
    var top = isNonBodyTag ? liPos.top - gridPosTop : liPos.top + window.scrollY;
    if (gridPos.right < left + ul.offsetWidth) {
      if (liPos.left - ul.offsetWidth > gridPos.left) {
        left = liPos.left - ul.offsetWidth;
      } else {
        left -= left + ul.offsetWidth - gridPos.right;
      }
    } else {
      if ((!isNullOrUndefined(paddingTop) || !isNullOrUndefined(paddingLeft)) && isNonBodyTag) {
        left = Math.abs(liPos.right - gridPos.left);
        top = Math.abs(liPos.top - gridPos.top);
      }
    }
    if (!isNullOrUndefined(paddingTop) && !isNullOrUndefined(paddingLeft)) {
      ul.style.top = typeof parseInt(marginTop, 10) === 'string' ? top + paddingTop + parseInt(marginTop, 10) + 'px' : top + paddingTop + 'px';
      ul.style.left = typeof parseInt(marginLeft, 10) === 'string' ? left + paddingLeft + parseInt(marginLeft, 10) + 8 + 'px' : left + paddingLeft + 'px';
    } else {
      ul.style.top = top + 'px';
      ul.style.left = left + 'px';
    }
  };
  Filter.prototype.updateFilterMenuPosition = function (element, args) {
    addClass([element], 'e-gantt');
    if (!this.parent.enableAdaptiveUI) {
      document.querySelector('#' + this.parent.controlId).appendChild(element);
    }
    var targetElement;
    if (this.parent.showColumnMenu) {
      targetElement = document.querySelector('#treeGrid' + this.parent.controlId + '_gridcontrol_colmenu_Filter');
      element.style.zIndex = targetElement.parentElement.style.zIndex;
      if (this.parent.treeGrid.filterSettings.type === 'Menu') {
        this.setPosition(targetElement, getValue('filterModel.dlgObj.element', args));
      } else {
        this.setPosition(targetElement, getValue('filterModel.dialogObj.element', args));
      }
    } else {
      targetElement = this.parent.treeGrid.grid.getColumnHeaderByField(args.columnName).querySelector('.e-filtermenudiv');
      if (this.parent.treeGrid.filterSettings.type === 'Menu') {
        getFilterMenuPostion(targetElement, getValue('filterModel.dlgObj', args));
      } else {
        getFilterMenuPostion(targetElement, getValue('filterModel.dialogObj', args));
      }
    }
    if (this.parent.treeGrid.filterSettings.type === 'Menu') {
      element.querySelector('.e-valid-input').focus();
    }
    if (this.parent.treeGrid.filterSettings.type === 'Excel') {
      var inputElement = document.querySelector('.e-searchinput');
      inputElement.focus();
    }
  };
  Filter.prototype.removeEventListener = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('updateModel', this.updateModel);
    this.parent.off('actionBegin', this.actionBegin);
    this.parent.off('actionComplete', this.actionComplete);
    this.parent.off('columnMenuOpen', this.columnMenuOpen);
  };
  /**
   * This method is used to destroy the filter module. When called, it performs any necessary cleanup operations related to the filter module.
   *
   * @returns {void} .
   */
  Filter.prototype.destroy = function () {
    this.removeEventListener();
    this.unWireEvents();
  };
  return Filter;
}();

/**
 * The Sort module is used to handle sorting action.
 */
var Sort = /** @__PURE__ @class */function () {
  function Sort(gantt) {
    this.parent = gantt;
    TreeGrid.Inject(Sort$2);
    this.parent.treeGrid.allowSorting = this.parent.allowSorting;
    this.parent.treeGrid.sortSettings = getActualProperties(this.parent.sortSettings);
    this.addEventListener();
  }
  /**
   * For internal use only - Get the module name.
   *
   * @returns {string} .
   * @private
   */
  Sort.prototype.getModuleName = function () {
    return 'sort';
  };
  /**
   * @returns {void} .
   * @private
   */
  Sort.prototype.addEventListener = function () {
    this.parent.on('updateModel', this.updateModel, this);
  };
  /**
   *
   * @returns {void} .
   * @hidden
   */
  Sort.prototype.removeEventListener = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('updateModel', this.updateModel);
  };
  /**
   * Destroys the Sorting of TreeGrid.
   *
   * @returns {void} .
   * @private
   */
  Sort.prototype.destroy = function () {
    this.removeEventListener();
  };
  /**
   * Sort a column with given options.
   *
   * @param {string} columnName - Defines the column name to sort.
   * @param {SortDirection} direction - Defines the direction of sort.
   * @param {boolean} isMultiSort - Defines whether the previously sorted columns are to be maintained.
   * @returns {void} .
   */
  Sort.prototype.sortColumn = function (columnName, direction, isMultiSort) {
    this.parent.treeGrid.sortByColumn(columnName, direction, isMultiSort);
  };
  /**
   * Method to clear all sorted columns.
   *
   * @returns {void} .
   */
  Sort.prototype.clearSorting = function () {
    this.parent.treeGrid.clearSorting();
  };
  /**
   * The function used to update sortSettings of TreeGrid.
   *
   * @returns {void} .
   * @hidden
   */
  Sort.prototype.updateModel = function () {
    this.parent.sortSettings = this.parent.treeGrid.sortSettings;
  };
  /**
   * To clear sorting for specific column.
   *
   * @param {string} columnName - Defines the sorted column name to remove.
   * @returns {void} .
   */
  Sort.prototype.removeSortColumn = function (columnName) {
    this.parent.treeGrid.grid.removeSortColumn(columnName);
  };
  return Sort;
}();

/**
 * The Selection module is used to handle cell and row selection.
 */
var Selection$1 = /** @__PURE__ @class */function () {
  function Selection(gantt) {
    this.isSelectionFromChart = false;
    this.isFromChart = false;
    this.multipleIndexes = [];
    this.selectedRowIndexes = [];
    this.enableSelectMultiTouch = false;
    this.openPopup = false;
    this.parent = gantt;
    this.bindEvents();
    this.parent.treeGrid.selectedRowIndex = this.parent.selectedRowIndex;
    this.parent.treeGrid.allowSelection = this.parent.allowSelection;
    this.parent.treeGrid.grid.selectionSettings.enableToggle = this.parent.selectionSettings.enableToggle;
    this.parent.treeGrid.selectionSettings = getActualProperties(this.parent.selectionSettings);
    this.wireEvents();
  }
  /**
   * Get module
   *
   * @returns {string} .
   */
  Selection.prototype.getModuleName = function () {
    return 'selection';
  };
  Selection.prototype.wireEvents = function () {
    this.parent.on('selectRowByIndex', this.selectRowByIndex, this);
    if (this.parent.isAdaptive) {
      this.parent.on('chartMouseClick', this.mouseUpHandler, this);
      this.parent.on('treeGridClick', this.popUpClickHandler, this);
    } else {
      this.parent.on('chartMouseUp', this.mouseUpHandler, this);
    }
  };
  /**
   * To update selected index.
   *
   * @returns {void} .
   * @private
   */
  Selection.prototype.selectRowByIndex = function () {
    if (this.parent.selectedRowIndex !== -1 || this.parent.staticSelectedRowIndex !== -1) {
      this.selectRow(this.parent.staticSelectedRowIndex !== -1 ? this.parent.staticSelectedRowIndex : this.parent.selectedRowIndex);
      this.parent.staticSelectedRowIndex = -1;
    }
  };
  /**
   * To bind selection events.
   *
   * @returns {void} .
   * @private
   */
  Selection.prototype.bindEvents = function () {
    this.parent.treeGrid.rowSelecting = this.rowSelecting.bind(this);
    this.parent.treeGrid.rowSelected = this.rowSelected.bind(this);
    this.parent.treeGrid.rowDeselecting = this.rowDeselecting.bind(this);
    this.parent.treeGrid.rowDeselected = this.rowDeselected.bind(this);
    this.parent.treeGrid.cellSelecting = this.cellSelecting.bind(this);
    this.parent.treeGrid.cellSelected = this.cellSelected.bind(this);
    this.parent.treeGrid.cellDeselecting = this.cellDeselecting.bind(this);
    this.parent.treeGrid.cellDeselected = this.cellDeselected.bind(this);
  };
  Selection.prototype.rowSelecting = function (args) {
    if (!this.parent.isGanttChartRendered) {
      args.cancel = true;
      return;
    }
    args.isCtrlPressed = this.isMultiCtrlRequest || args.isCtrlPressed;
    args.isShiftPressed = this.isMultiShiftRequest || args.isShiftPressed;
    args.target = this.actualTarget;
    if (!isNullOrUndefined(args.foreignKeyData) && Object.keys(args.foreignKeyData).length === 0) {
      delete args.foreignKeyData;
    }
    if (this.parent.selectionSettings && this.parent.selectionSettings.persistSelection) {
      this.parent.treeGrid.grid.selectionModule['checkSelectAllClicked'] = true;
    }
    this.parent.trigger('rowSelecting', args);
    if (this.isMultiShiftRequest || this.isMultiCtrlRequest) {
      this.isMultiShiftRequest = this.isMultiCtrlRequest = false;
    }
  };
  Selection.prototype.rowSelected = function (args) {
    var rowIndexes = 'rowIndexes';
    var index = this.parent.selectionSettings.type === 'Multiple' && !isNullOrUndefined(args[rowIndexes]) ? args[rowIndexes] : [args.rowIndex];
    this.addRemoveClass(index, args['name']);
    this.selectedRowIndexes = extend([], this.getSelectedRowIndexes(), [], true);
    this.parent.setProperties({
      selectedRowIndex: this.parent.treeGrid.grid.selectedRowIndex
    }, true);
    if (this.isMultiShiftRequest) {
      this.selectedRowIndexes = index;
    }
    if (this.parent.autoFocusTasks) {
      if (this.parent.enableTimelineVirtualization) {
        this.parent['isRowSelected'] = true;
      }
      if (args.data && !isNullOrUndefined(args.data['length'])) {
        for (var i = 0; i < args.data['length']; i++) {
          this.parent.ganttChartModule.updateScrollLeft(args.data[i].ganttProperties.left);
        }
      } else {
        this.parent.ganttChartModule.updateScrollLeft(getValue('data.ganttProperties.left', args));
      }
    }
    args.target = this.actualTarget;
    if (!isNullOrUndefined(args.foreignKeyData) && Object.keys(args.foreignKeyData).length === 0) {
      delete args.foreignKeyData;
    }
    this.prevRowIndex = args.rowIndex;
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems(args);
    }
    if (!isNullOrUndefined(this.parent.focusModule['previousActiveElement'])) {
      var previousSelection = this.parent.focusModule['previousActiveElement'];
      removeClass([previousSelection], 'e-focused');
      removeClass([previousSelection], 'e-focus');
    }
    this.parent.trigger('rowSelected', args);
  };
  Selection.prototype.rowDeselecting = function (args) {
    args.target = this.actualTarget;
    args.isInteracted = this.isInteracted;
    this.parent.trigger('rowDeselecting', args);
  };
  Selection.prototype.rowDeselected = function (args) {
    var index;
    var isContains;
    if (this.multipleIndexes.length !== 0) {
      index = this.multipleIndexes;
    } else {
      if (!isNullOrUndefined(args.rowIndexes)) {
        for (var i = 0; i < args.rowIndexes.length; i++) {
          if (args.rowIndexes[i] === args.rowIndex) {
            isContains = true;
          }
        }
        if (isContains) {
          index = args.rowIndexes;
        } else {
          index = [args.rowIndex];
        }
      } else {
        index = [args.rowIndex];
      }
    }
    this.addRemoveClass(index);
    this.selectedRowIndexes = extend([], this.getSelectedRowIndexes(), [], true);
    this.parent.setProperties({
      selectedRowIndex: -1
    }, true);
    if (this.selectedRowIndexes.length === 1) {
      this.parent.setProperties({
        selectedRowIndex: this.selectedRowIndexes[0]
      }, true);
    }
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
    if (this.parent.selectionSettings.type === 'Multiple' && this.parent.isAdaptive && this.selectedRowIndexes.length === 0) {
      this.hidePopUp();
    }
    args.target = this.actualTarget;
    args.isInteracted = this.isInteracted;
    this.parent.trigger('rowDeselected', args);
    this.isInteracted = false;
    this.multipleIndexes = [];
  };
  Selection.prototype.cellSelecting = function (args) {
    var callBackPromise = new Deferred();
    this.parent.trigger('cellSelecting', args, function (cellselectingArgs) {
      callBackPromise.resolve(cellselectingArgs);
    });
    return callBackPromise;
  };
  Selection.prototype.cellSelected = function (args) {
    this.parent.trigger('cellSelected', args);
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
  };
  Selection.prototype.cellDeselecting = function (args) {
    this.parent.trigger('cellDeselecting', args);
  };
  Selection.prototype.cellDeselected = function (args) {
    this.parent.trigger('cellDeselected', args);
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
  };
  /**
   * Selects a cell by given index.
   *
   * @param  {IIndex} cellIndex - Defines the row and column indexes.
   * @param  {boolean} isToggle - If set to true, then it toggles the selection.
   * @returns {void} .
   */
  Selection.prototype.selectCell = function (cellIndex, isToggle) {
    this.parent.treeGrid.selectCell(cellIndex, isToggle);
  };
  /**
   * Selects a collection of cells by row and column indexes.
   *
   * @param  {ISelectedCell[]} rowCellIndexes - Specifies the row and column indexes.
   * @returns {void} .
   */
  Selection.prototype.selectCells = function (rowCellIndexes) {
    this.parent.treeGrid.grid.selectCells(rowCellIndexes);
  };
  /**
   * Selects a row by given index.
   *
   * @param  {number} index - Defines the row index.
   * @param  {boolean} isToggle - If set to true, then it toggles the selection.
   * @param {boolean} isPreventFocus .
   * @returns {void} .
   */
  Selection.prototype.selectRow = function (index, isToggle, isPreventFocus) {
    var ganttRow = [].slice.call(this.parent.ganttChartModule.chartBodyContent.querySelector('tbody').children);
    if (this.parent.enableVirtualization && (this.parent.treeGridModule.addedRecord || this.parent.editModule && this.parent.editModule.isAdded)) {
      index = this.parent.getExpandedRecords(this.parent.flatData).indexOf(this.parent.flatData[index]);
      this.parent.treeGridModule.addedRecord = false;
      if (this.parent.editModule) {
        this.parent.editModule.isAdded = false;
      }
    }
    var selectedRow = ganttRow.filter(function (e) {
      return parseInt(e.getAttribute('data-rowindex'), 10) === index;
    })[0];
    var condition;
    if (index === -1 || isNullOrUndefined(selectedRow) && !this.parent.enableVirtualization || this.parent.selectionSettings.mode === 'Cell') {
      return;
    }
    if (this.parent.showActiveElement && !isNullOrUndefined(isPreventFocus) && !isPreventFocus || this.isFromChart) {
      if (this.isFromChart) {
        this.isFromChart = false;
      }
      this.parent.treeGrid.grid.selectionModule.preventFocus = true;
    } else {
      this.parent.treeGrid.grid.selectionModule.preventFocus = false;
    }
    if (!isNullOrUndefined(this.selectedClass) && this.selectedClass === selectedRow && !isToggle) {
      condition = true;
    }
    if (condition !== true) {
      this.parent.treeGrid.selectRow(index, isToggle);
    }
    this.parent.treeGrid.grid.selectionModule.preventFocus = this.parent.treeGrid.grid.selectionModule.preventFocus === true ? false : this.parent.treeGrid.grid.selectionModule.preventFocus;
    this.prevRowIndex = index;
    this.selectedClass = selectedRow;
  };
  /**
   * Selects a collection of rows by indexes.
   *
   * @param  {number[]} records - Defines the collection of row indexes.
   * @returns {void} .
   */
  Selection.prototype.selectRows = function (records) {
    if (!isNullOrUndefined(records) && records.length > 0) {
      this.parent.treeGrid.selectRows(records);
    }
  };
  /**
   * Gets the collection of selected row indexes.
   *
   * @returns {number[]} .
   */
  Selection.prototype.getSelectedRowIndexes = function () {
    return this.parent.treeGrid.getSelectedRowIndexes();
  };
  /**
   * Gets the collection of selected row and cell indexes.
   *
   * @returns {number[]} .
   */
  Selection.prototype.getSelectedRowCellIndexes = function () {
    return this.parent.treeGrid.getSelectedRowCellIndexes();
  };
  /**
   * Gets the collection of selected records.
   *
   * @returns {Object[]} .
   */
  Selection.prototype.getSelectedRecords = function () {
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      var selectedRows = [];
      var selectedIndexes_1 = this.parent.selectionModule.getSelectedRowIndexes();
      var _loop_1 = function (i) {
        var rec = this_1.parent.currentViewData.filter(function (data) {
          return data.index === selectedIndexes_1[i];
        })[0];
        selectedRows.push(rec);
      };
      var this_1 = this;
      for (var i = 0; i < selectedIndexes_1.length; i++) {
        _loop_1(i);
      }
      return selectedRows;
    } else {
      return this.parent.treeGrid.getSelectedRecords();
    }
  };
  /**
   * Get the selected records for cell selection.
   *
   * @returns {IGanttData[]} .
   */
  Selection.prototype.getCellSelectedRecords = function () {
    var cellDetails = this.parent.selectionModule.getSelectedRowCellIndexes();
    var cellSelectedRecords = [];
    for (var i = 0; i < cellDetails.length; i++) {
      cellSelectedRecords.push(this.parent.currentViewData[cellDetails[i].rowIndex]);
    }
    return cellSelectedRecords;
  };
  /**
   * Gets the collection of selected rows.
   *
   * @returns {Element[]} .
   */
  Selection.prototype.getSelectedRows = function () {
    return this.parent.treeGrid.getSelectedRows();
  };
  /**
   * Deselects the current selected rows and cells.
   *
   * @returns {void} .
   */
  Selection.prototype.clearSelection = function () {
    this.addRemoveClass(this.selectedRowIndexes);
    this.parent.treeGrid.clearSelection();
    this.parent.selectedRowIndex = -1;
    this.selectedRowIndexes = [];
    this.selectedClass = null;
    if (!isNullOrUndefined(this.parent.toolbarModule)) {
      this.parent.toolbarModule.refreshToolbarItems();
    }
    this.isInteracted = false;
  };
  Selection.prototype.highlightSelectedRows = function (e, fromChart) {
    this.isMultiCtrlRequest = e.ctrlKey || this.enableSelectMultiTouch;
    this.isMultiShiftRequest = e.shiftKey;
    this.actualTarget = e.target;
    this.isInteracted = true;
    this.isSelectionFromChart = fromChart;
    if (fromChart) {
      var selectedRow = closest(e.target, 'tr.e-chart-row');
      var rIndex = parseInt(selectedRow.getAttribute('data-rowindex'), 10);
      var isToggle = this.parent.selectionSettings.enableToggle;
      if (this.parent.selectionSettings.type === 'Single' || !this.isMultiCtrlRequest && !this.isMultiShiftRequest) {
        if (this.parent.selectionSettings.persistSelection) {
          this.addRemoveClass(this.selectedRowIndexes, e['name']);
        }
        if (!this.parent.allowTaskbarDragAndDrop || this.parent.allowTaskbarDragAndDrop && this.parent.rowDragAndDropModule && !this.parent.rowDragAndDropModule['draggedRecord']) {
          this.isFromChart = true;
          this.selectRow(rIndex, isToggle);
        }
      } else {
        if (this.isMultiShiftRequest) {
          this.selectRowsByRange(isNullOrUndefined(this.prevRowIndex) ? rIndex : this.prevRowIndex, rIndex);
        } else {
          setValue('isMultiCtrlRequest', true, this.parent.treeGrid.grid.selectionModule);
          this.parent.treeGrid.grid.selectionModule.addRowsToSelection([rIndex]);
          var isUnSelected = this.selectedRowIndexes.indexOf(rIndex) > -1;
          if (isUnSelected) {
            this.addRemoveClass([rIndex], e['name']);
          }
        }
      }
    }
  };
  Selection.prototype.getselectedrowsIndex = function (startIndex, endIndex) {
    var indexes = [];
    // eslint-disable-next-line
    var _a = startIndex < endIndex ? {
        i: startIndex,
        max: endIndex
      } : {
        i: endIndex,
        max: startIndex
      },
      i = _a.i,
      max = _a.max;
    for (; i <= max; i++) {
      indexes.push(i);
    }
    if (startIndex > endIndex) {
      indexes.reverse();
    }
    this.selectedRowIndexes = indexes;
  };
  /**
   * Selects a range of rows from start and end row indexes.
   *
   * @param  {number} startIndex - Defines the start row index.
   * @param  {number} endIndex - Defines the end row index.
   * @returns {void} .
   */
  Selection.prototype.selectRowsByRange = function (startIndex, endIndex) {
    this.isSelectionFromChart = true;
    this.getselectedrowsIndex(startIndex, endIndex);
    this.selectRows(this.selectedRowIndexes);
  };
  Selection.prototype.addRemoveClass = function (records, request) {
    if (typeof records == 'number') {
      records = [records];
    }
    var ganttRow = [].slice.call(this.parent.ganttChartModule.chartBodyContent.querySelector('tbody').children);
    var _loop_2 = function (i) {
      var selectedRow = ganttRow.filter(function (e) {
        return parseInt(e.getAttribute('data-rowindex'), 10) === records[parseInt(i.toString(), 10)];
      })[0];
      if (!isNullOrUndefined(selectedRow)) {
        var persist = false;
        var index = this_2.getSelectedRowIndexes().indexOf(records[parseInt(i.toString(), 10)]);
        var selectedRecordLen = this_2.getSelectedRecords().length;
        if (this_2.parent.selectionSettings.persistSelection && this_2.parent.selectionSettings.enableToggle && !isNullOrUndefined(request) && this_2.parent.selectionSettings.type !== 'Multiple' && selectedRecordLen > 0) {
          persist = true;
        }
        if (this_2.parent.selectionSettings.enableToggle && this_2.parent.selectionSettings.persistSelection && index > -1 && this_2.parent.selectionSettings.type === 'Single' && persist || index > -1 && !isNullOrUndefined(request) && this_2.parent.selectionSettings.type === 'Multiple') {
          this_2.addClass(selectedRow);
        } else if (isNullOrUndefined(request)) {
          this_2.removeClass(selectedRow);
        } else if (index > -1) {
          this_2.addClass(selectedRow);
        }
        if (this_2.parent.selectionSettings.enableToggle && index > -1 && (this_2.parent.selectionSettings.type === 'Single' || !this_2.isMultiCtrlRequest && !this_2.isMultiShiftRequest) && this_2.parent.selectionSettings.persistSelection && request === 'chartMouseUp' && this_2.isSelectionFromChart) {
          this_2.removeClass(selectedRow);
        }
      }
    };
    var this_2 = this;
    for (var i = 0; i < records.length; i++) {
      _loop_2(i);
    }
  };
  Selection.prototype.addClass = function (selectedRow) {
    addClass([selectedRow], 'e-active');
    selectedRow.setAttribute('aria-selected', 'true');
  };
  Selection.prototype.removeClass = function (selectedRow) {
    removeClass([selectedRow], 'e-active');
    selectedRow.removeAttribute('aria-selected');
  };
  Selection.prototype.showPopup = function (e) {
    if (this.isSelectionFromChart) {
      setCssInGridPopUp(this.parent.element.querySelector('.e-ganttpopup'), e, 'e-rowselect e-icons e-icon-rowselect' + (this.enableSelectMultiTouch && (this.getSelectedRecords().length > 1 || this.getSelectedRowCellIndexes().length > 1) ? ' e-spanclicked' : ''));
      document.getElementsByClassName('e-gridpopup')[0].style.display = 'none';
      this.openPopup = true;
    } else if (this.selectedRowIndexes.length === 0) {
      this.hidePopUp();
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  Selection.prototype.hidePopUp = function () {
    if (this.openPopup) {
      document.getElementsByClassName('e-ganttpopup')[0].style.display = 'none';
      this.openPopup = false;
    } else {
      document.getElementsByClassName('e-gridpopup')[0].style.display = 'none';
    }
  };
  Selection.prototype.popUpClickHandler = function (e) {
    var target = e.target;
    var grid = this.parent.treeGrid.grid;
    var $popUpElemet = closest(target, '.e-ganttpopup') ? closest(target, '.e-ganttpopup') : closest(target, '.e-gridpopup');
    if ($popUpElemet) {
      var spanElement = $popUpElemet.querySelector('.' + 'e-rowselect');
      if (closest(target, '.e-ganttpopup') && !spanElement.classList.contains('e-spanclicked')) {
        this.enableSelectMultiTouch = true;
        spanElement.classList.add('e-spanclicked');
      } else if (closest(target, '.e-gridpopup') && spanElement.classList.contains('e-spanclicked')) {
        this.openPopup = true;
        this.enableSelectMultiTouch = true;
      } else {
        this.hidePopUp();
        this.enableSelectMultiTouch = false;
        if (closest(target, '.e-ganttpopup')) {
          spanElement.classList.remove('e-spanclicked');
        }
      }
    } else if (this.parent.selectionSettings.type === 'Multiple' && this.parent.isAdaptive) {
      var $tr = closest(target, '.e-rowcell');
      if ($tr && this.selectedRowIndexes.length === 0) {
        this.hidePopUp();
      }
    }
    if (grid) {
      setValue('enableSelectMultiTouch', this.enableSelectMultiTouch, grid.selectionModule);
    }
  };
  /**
   * @param {PointerEvent} e .
   * @returns {void} .
   * @private
   */
  Selection.prototype.mouseUpHandler = function (e) {
    var isTaskbarEdited = false;
    var elements = document.querySelectorAll('.e-drag-item');
    var targetElement = null;
    if (e.target.closest('.e-rowcell')) {
      targetElement = e.target;
    } else if (e.target.closest('.e-chart-row')) {
      targetElement = e.target.closest('.e-left-label-container') || e.target.closest('.e-taskbar-main-container') || e.target.closest('.e-right-label-container');
    }
    if (this.parent.focusModule) {
      this.parent.focusModule.setActiveElement(targetElement);
    }
    if (this.parent.editModule && this.parent.editSettings.allowTaskbarEditing && this.parent.editModule.taskbarEditModule) {
      var taskbarEdit = this.parent.editModule.taskbarEditModule;
      if (taskbarEdit.isMouseDragged || taskbarEdit.tapPointOnFocus) {
        isTaskbarEdited = true;
      }
    }
    if (!isTaskbarEdited && this.parent.element.contains(e.target) && !(elements.length === 1)) {
      var parent_1 = parentsUntil$1(e.target, 'e-chart-row');
      var isSelected = e.target.classList.contains('e-rowcell') || e.target.classList.contains('e-row') || e.target.classList.contains('e-treegridexpand') || e.target.classList.contains('e-treegridcollapse') || !isNullOrUndefined(parent_1);
      this.popUpClickHandler(e);
      if (this.parent.selectionSettings.mode !== 'Cell' && isSelected) {
        if (closest(e.target, 'tr.e-chart-row')) {
          if (this.parent.enableVirtualization) {
            this.parent.treeGrid.grid.selectionModule.isInteracted = true;
          }
          this.parent.treeGrid['isFromChartSide'] = true;
          this.highlightSelectedRows(e, true);
        } else {
          this.parent.treeGrid['isFromChartSide'] = false;
          this.highlightSelectedRows(e, false);
        }
        if (this.parent.selectionSettings.type === 'Multiple' && this.parent.isAdaptive) {
          if (this.selectedRowIndexes.length > 0) {
            this.showPopup(e);
          } else {
            this.hidePopUp();
          }
        }
      } else {
        this.isSelectionFromChart = false;
      }
    }
  };
  /**
   * To add class for selected records in virtualization mode.
   *
   * @param {number} i .
   * @returns {void} .
   * @hidden
   */
  Selection.prototype.maintainSelectedRecords = function (i) {
    var index = this.parent.selectionModule.getSelectedRowIndexes().indexOf(i);
    if (index > -1) {
      this.addRemoveClass([i]);
    }
  };
  /**
   * To destroy the selection module.
   *
   * @returns {void} .
   * @private
   */
  Selection.prototype.destroy = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('selectRowByIndex', this.selectRowByIndex);
    if (this.parent.isAdaptive) {
      this.parent.off('chartMouseClick', this.mouseUpHandler);
      this.parent.off('treeGridClick', this.popUpClickHandler);
    } else {
      this.parent.off('chartMouseUp', this.mouseUpHandler);
    }
  };
  return Selection;
}();
var Toolbar = /** @__PURE__ @class */function () {
  function Toolbar(parent) {
    this.predefinedItems = {};
    this.items = ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'Search', 'PrevTimeSpan', 'NextTimeSpan', 'ZoomIn', 'ZoomOut', 'ZoomToFit', 'ExcelExport', 'CsvExport', 'PdfExport', 'Indent', 'Outdent', 'CriticalPath'];
    this.parent = parent;
    this.id = this.parent.element.id;
    this.parent.on('ui-toolbarupdate', this.propertyChanged, this);
  }
  Toolbar.prototype.getModuleName = function () {
    return 'toolbar';
  };
  /**
   * @returns {void} .
   * @private
   */
  Toolbar.prototype.renderToolbar = function () {
    var toolbarItems = this.parent.toolbar || [];
    if (toolbarItems.length > 0) {
      this.element = createElement('div', {
        id: this.parent.controlId + '_Gantt_Toolbar',
        className: toolbar
      });
      if (this.parent.treeGrid.grid.headerModule) {
        this.parent.element.insertBefore(this.element, this.parent.treeGridPane.offsetParent);
      } else {
        this.parent.element.appendChild(this.element);
      }
      var preItems = ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'PrevTimeSpan', 'NextTimeSpan', 'ZoomIn', 'ZoomOut', 'ZoomToFit', 'ExcelExport', 'CsvExport', 'PdfExport', 'Indent', 'Outdent', 'CriticalPath', 'Undo', 'Redo'];
      for (var _i = 0, preItems_1 = preItems; _i < preItems_1.length; _i++) {
        var item = preItems_1[_i];
        var itemStr = void 0;
        var localeName = void 0;
        if (item === 'CriticalPath') {
          itemStr = 'critical-path';
          localeName = 'criticalPath';
        } else {
          itemStr = item.toLowerCase();
          localeName = item[0].toLowerCase() + item.slice(1);
        }
        this.predefinedItems[item] = {
          id: this.parent.element.id + '_' + itemStr,
          prefixIcon: 'e-' + itemStr,
          text: this.parent.isAdaptive ? '' : this.parent.localeObj.getConstant(localeName),
          tooltipText: this.parent.localeObj.getConstant(localeName) + (localeName === 'add' || localeName === 'edit' || localeName === 'delete' ? this.parent.localeObj.getConstant('task') : localeName === 'expandAll' || localeName === 'collapseAll' ? this.parent.localeObj.getConstant('tasks') : ''),
          align: this.parent.isAdaptive ? 'Right' : 'Left'
        };
        if (this.parent.enableRtl) {
          if (item === 'PrevTimeSpan') {
            this.predefinedItems[item].prefixIcon = 'e-nexttimespan';
          }
          if (item === 'NextTimeSpan') {
            this.predefinedItems[item].prefixIcon = 'e-prevtimespan';
          }
        }
      }
      var searchLocalText = this.parent.localeObj.getConstant('search');
      if (this.parent.isAdaptive) {
        this.predefinedItems.Search = {
          id: this.id + '_searchbutton',
          prefixIcon: 'e-search-icon',
          tooltipText: searchLocalText,
          align: 'Right'
        };
      } else {
        this.predefinedItems.Search = {
          id: this.id + '_search',
          template: '<div class="e-input-group e-search" role="search">' + '<input id="' + this.id + '_searchbar" class="e-input" name="input" type="search"' +
          // eslint-disable-next-line
          'placeholder= \"' + searchLocalText + '\"/>' + '<span id="' + this.id + '_searchbutton" class="e-input-group-icon e-search-icon e-icons"' + 'tabindex="-1" title="' + searchLocalText + '" aria-label= "search" role="button"></span>' + '</div>',
          tooltipText: searchLocalText,
          align: 'Right',
          cssClass: 'e-search-wrapper'
        };
      }
      this.createToolbar();
    }
  };
  Toolbar.prototype.addReactToolbarPortals = function (args) {
    if (this.parent.isReact && args) {
      this.parent.portals = this.parent.portals.concat(args);
      this.parent.renderTemplates();
    }
  };
  Toolbar.prototype.createToolbar = function () {
    var items = this.getItems();
    this.toolbar = new Toolbar$4({
      items: items,
      enableRtl: this.parent.enableRtl,
      clicked: this.toolbarClickHandler.bind(this),
      height: this.parent.isAdaptive ? 48 : 'auto'
    });
    this.toolbar.isStringTemplate = true;
    this.toolbar.isReact = this.parent.isReact;
    this.toolbar.on('render-react-toolbar-template', this.addReactToolbarPortals, this);
    this.toolbar.appendTo(this.element);
    if (this.parent.treeGrid.grid && this.parent.isReact) {
      this.parent.treeGrid.grid.portals = this.parent.portals;
    }
    var cancelItem = this.element.querySelector('#' + this.parent.element.id + '_cancel');
    var updateItem = this.element.querySelector('#' + this.parent.element.id + '_update');
    if (cancelItem) {
      addClass([cancelItem], focusCell);
    }
    if (updateItem) {
      addClass([updateItem], focusCell);
    }
    var template = false;
    this.parent.toolbar.map(function (e) {
      if (e === 'Search') {
        template = true;
      }
    });
    if (this.parent.isAdaptive && template) {
      this.element.insertBefore(this.getSearchBarElement(), this.element.childNodes[0]);
      this.searchElement = this.element.querySelector('#' + this.parent.element.id + '_searchbar');
      var textObj = new TextBox({
        placeholder: this.parent.localeObj.getConstant('search'),
        enableRtl: this.parent.enableRtl,
        floatLabelType: 'Never',
        showClearButton: true
      });
      textObj.appendTo(this.searchElement);
    } else {
      this.searchElement = this.element.querySelector('#' + this.parent.element.id + '_searchbar');
    }
    if (this.parent.filterModule) {
      this.wireEvent();
      if (this.parent.searchSettings) {
        this.updateSearchTextBox();
      }
    }
    this.enableItems([this.parent.controlId + '_redo', this.parent.controlId + '_undo'], false); // disable toolbar items.
    if (this.parent.readOnly) {
      this.enableItems([this.parent.element.id + '_add', this.parent.element.id + '_update', this.parent.element.id + '_delete', this.parent.element.id + '_cancel', this.parent.element.id + '_indent', this.parent.element.id + '_outdent'], false);
    }
  };
  Toolbar.prototype.getSearchBarElement = function () {
    var _this = this;
    var div = createElement('div', {
      className: 'e-adaptive-searchbar',
      styles: 'display: none'
    });
    var textbox = createElement('input', {
      attrs: {
        type: 'text'
      },
      id: this.parent.element.id + '_searchbar'
    });
    var span = createElement('span', {
      className: 'e-backarrowspan e-icons'
    });
    span.onclick = function () {
      div.style.display = 'none';
      _this.element.childNodes[1].style.display = 'block';
    };
    div.appendChild(span);
    div.appendChild(textbox);
    return div;
  };
  Toolbar.prototype.wireEvent = function () {
    if (this.searchElement) {
      EventHandler.add(this.searchElement, 'keyup', this.keyUpHandler, this);
      EventHandler.add(this.searchElement, 'focus', this.focusHandler, this);
      EventHandler.add(this.searchElement, 'blur', this.blurHandler, this);
    }
  };
  Toolbar.prototype.propertyChanged = function (property) {
    var module = getValue('module', property);
    if (module !== this.getModuleName() || !this.parent.toolbar) {
      return;
    }
    if (this.element && this.element.parentNode) {
      remove(this.element);
    }
    this.renderToolbar();
    this.refreshToolbarItems();
  };
  Toolbar.prototype.unWireEvent = function () {
    if (this.searchElement) {
      EventHandler.remove(this.searchElement, 'keyup', this.keyUpHandler);
      EventHandler.remove(this.searchElement, 'focus', this.focusHandler);
      EventHandler.remove(this.searchElement, 'blur', this.blurHandler);
      this.searchElement = null;
    }
    if (!this.parent.isDestroyed) {
      this.parent.off('ui-toolbarupdate', this.propertyChanged);
    }
  };
  Toolbar.prototype.keyUpHandler = function (e) {
    if (e.keyCode === 13 && this.parent.searchSettings.key !== this.searchElement.value) {
      this.parent.searchSettings.key = this.searchElement.value;
      this.parent.dataBind();
    }
  };
  Toolbar.prototype.focusHandler = function (e) {
    e.target.parentElement.classList.add('e-input-focus');
  };
  Toolbar.prototype.blurHandler = function (e) {
    e.target.parentElement.classList.remove('e-input-focus');
  };
  /**
   * Method to set value for search input box
   *
   * @returns {void} .
   * @hidden
   */
  Toolbar.prototype.updateSearchTextBox = function () {
    if (this.searchElement && this.searchElement.value !== this.parent.searchSettings.key) {
      this.searchElement.value = this.parent.searchSettings.key;
    }
  };
  Toolbar.prototype.getItems = function () {
    var items = [];
    var toolbarItems = this.parent.toolbar;
    var searchIndex = -1;
    toolbarItems.forEach(function (item, index) {
      if (typeof item === 'string' && item === 'Search' || typeof item === 'object' && item.text === 'Search') {
        searchIndex = index;
      }
    });
    if (searchIndex > -1) {
      var searchItem = toolbarItems.splice(searchIndex, 1);
      toolbarItems.push(searchItem[0]);
    }
    for (var _i = 0, toolbarItems_1 = toolbarItems; _i < toolbarItems_1.length; _i++) {
      var item = toolbarItems_1[_i];
      if (typeof item === 'string') {
        items.push(this.getItemObject(item));
      } else {
        items.push(this.getItem(item));
      }
    }
    return items;
  };
  Toolbar.prototype.getItem = function (itemObject) {
    var item = this.predefinedItems[itemObject.text];
    return item ? extend(item, item, itemObject) : itemObject;
  };
  Toolbar.prototype.getItemObject = function (itemName) {
    return this.predefinedItems[itemName] || {
      text: itemName,
      id: this.id + '_' + itemName
    };
  };
  Toolbar.prototype.toolbarClickHandler = function (arg) {
    var _this = this;
    var gObj = this.parent;
    var gID = this.id;
    this.parent.isToolBarClick = false;
    extend(arg, {
      cancel: false
    });
    if (arg.item['properties'].id === this.parent.element.id + '_pdfexport' || arg.item['properties'].id === this.parent.element.id + '_critical-path') {
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.showMaskRow();
      } else {
        this.parent.showSpinner();
      }
    }
    gObj.trigger(toolbarClick, arg, function (args) {
      if (args.cancel) {
        return;
      } else {
        if (_this.parent.isAdaptive === true) {
          if (args.item.id === gID + '_edit' || args.item.id === gID + '_add' || args.item.id === gID + '_delete' || args.item.id === gID + '_searchbutton' || args.item.id === gID + '_expandall' || args.item.id === gID + '_collapseall') {
            if (_this.parent.selectionModule && _this.parent.selectionSettings.type === 'Multiple') {
              _this.parent.selectionModule.hidePopUp();
              document.getElementsByClassName('e-gridpopup')[0].style.display = 'none';
            }
          }
        }
        switch (!isNullOrUndefined(args.item) && args.item.id) {
          case gID + '_edit':
            if (gObj.editModule && gObj.editSettings.allowEditing) {
              gObj.editModule.dialogModule.openToolbarEditDialog();
            }
            break;
          case gID + '_indent':
            if (gObj.editModule && gObj.selectionModule.getSelectedRecords().length) {
              gObj.indent();
            }
            break;
          case gID + '_critical-path':
            if (gObj.enableCriticalPath) {
              gObj.enableCriticalPath = false;
            } else {
              gObj.enableCriticalPath = true;
            }
            break;
          case gID + '_outdent':
            if (gObj.editModule && gObj.selectionModule.getSelectedRecords().length) {
              gObj.outdent();
            }
            break;
          case gID + '_update':
            gObj.editModule.cellEditModule.isCellEdit = false;
            gObj.treeGrid.grid.saveCell();
            break;
          case gID + '_cancel':
            gObj.cancelEdit();
            break;
          case gID + '_add':
            if (gObj.editModule && gObj.editSettings.allowAdding) {
              gObj.editModule.dialogModule.openAddDialog();
            }
            break;
          case gID + '_delete':
            if (_this.parent.selectionModule && _this.parent.editModule) {
              if (_this.parent.selectionSettings.mode !== 'Cell' && _this.parent.selectionModule.selectedRowIndexes.length || _this.parent.selectionSettings.mode === 'Cell' && _this.parent.selectionModule.getSelectedRowCellIndexes().length) {
                _this.parent.editModule.startDeleteAction();
              }
            }
            break;
          case gID + '_search':
            {
              var searchButtonId = getValue('originalEvent.target.id', args);
              if (searchButtonId === _this.parent.element.id + '_searchbutton' && _this.parent.filterModule) {
                var keyVal = _this.element.querySelector('#' + _this.parent.element.id + '_searchbar').value;
                if (_this.parent.searchSettings.key !== keyVal) {
                  _this.parent.searchSettings.key = keyVal;
                  _this.parent.dataBind();
                }
              }
              break;
            }
          case gID + '_searchbutton':
            {
              var adaptiveSearchbar = _this.element.querySelector('.e-adaptive-searchbar');
              adaptiveSearchbar.parentElement.childNodes[1].style.display = 'none';
              adaptiveSearchbar.style.display = 'block';
              break;
            }
          case gID + '_expandall':
            _this.parent.ganttChartModule.expandCollapseAll('expand');
            _this.parent.isCollapseAll = false;
            break;
          case gID + '_collapseall':
            _this.parent.ganttChartModule.expandCollapseAll('collapse');
            _this.parent.isCollapseAll = true;
            break;
          case gID + '_prevtimespan':
            _this.parent.previousTimeSpan();
            break;
          case gID + '_nexttimespan':
            _this.parent.nextTimeSpan();
            break;
          case gID + '_zoomin':
            _this.zoomIn();
            break;
          case gID + '_zoomout':
            _this.zoomOut();
            break;
          case gID + '_zoomtofit':
            _this.zoomToFit();
            break;
          case gID + '_undo':
            _this.parent.undo();
            break;
          case gID + '_redo':
            _this.parent.redo();
            break;
        }
      }
    });
  };
  /**
   *
   * @returns {void} .
   * @private
   */
  Toolbar.prototype.zoomIn = function () {
    this.parent.timelineModule.processZooming(true);
    this.parent.timelineModule.isZooming = false;
  };
  /**
   *
   * @returns {void} .
   * @private
   */
  Toolbar.prototype.zoomToFit = function () {
    if (this.parent.timelineModule.isZoomIn) {
      this.parent.timelineModule.isZoomIn = false;
    }
    this.parent.timelineModule.processZoomToFit();
    this.parent.ganttChartModule.updateScrollLeft(0);
    this.parent.timelineModule.isZoomToFit = false;
  };
  /**
   *
   * @returns {void} .
   * @private
   */
  Toolbar.prototype.zoomOut = function () {
    this.parent.timelineModule.processZooming(false);
    this.parent.timelineModule.isZooming = false;
  };
  /**
   * To refresh toolbar items bases current state of tasks
   *
   * @param {RowSelectEventArgs} args .
   * @returns {void} .
   */
  Toolbar.prototype.refreshToolbarItems = function (args) {
    var gObj = this.parent;
    var enableItems = [];
    var disableItems = [];
    var edit = gObj.editSettings;
    var gID = this.id;
    var ind = gObj.selectedRowIndex;
    if (this.parent.loadChildOnDemand && this.parent.taskFields.hasChildMapping) {
      for (var i = 0; i < gObj.updatedRecords.length; i++) {
        if (gObj.updatedRecords[i].index === ind) {
          ind = i;
          break;
        }
      }
    }
    var previousGanttRecord;
    var isSelected = false;
    if (gObj.selectionModule) {
      if (gObj.selectionModule.getSelectedRecords().length === 1 || gObj.selectionModule.getSelectedRowCellIndexes().length === 1) {
        isSelected = true;
      }
    }
    if (gObj.selectionModule && gObj.selectionSettings.type === 'Multiple' && gObj.selectionModule.selectedRowIndexes.length > 1) {
      isSelected = false;
    }
    var isPersist = false;
    if (gObj.selectionModule && gObj.selectionSettings.persistSelection) {
      var selectedRecords = gObj.selectionModule.getSelectedRecords().length;
      if (gObj.selectionSettings.persistSelection && selectedRecords === 1) {
        for (var i = 0; i < selectedRecords; i++) {
          var index = gObj.currentViewData.indexOf(gObj.selectionModule.getSelectedRecords()[i]);
          if (index === -1) {
            isPersist = true;
          }
        }
      }
      if (isPersist) {
        isSelected = false;
      }
    }
    var toolbarItems = this.toolbar ? this.toolbar.items : [];
    var toolbarDefaultItems = [gID + '_add', gID + '_edit', gID + '_delete', gID + '_update', gID + '_cancel', gID + '_indent', gID + '_outdent'];
    var isResouceParent = this.parent.viewType === 'ResourceView' && getValue('data.level', args) !== 0 || this.parent.viewType === 'ProjectView';
    if (!isNullOrUndefined(this.parent.editModule)) {
      var touchEdit = gObj.editModule.taskbarEditModule ? gObj.editModule.taskbarEditModule.touchEdit : false;
      var hasData = gObj.flatData && gObj.flatData.length;
      // eslint-disable-next-line
      edit.allowAdding && !touchEdit ? enableItems.push(gID + '_add') : disableItems.push(gID + '_add');
      // eslint-disable-next-line
      edit.allowEditing && isResouceParent && hasData && isSelected && !touchEdit ? enableItems.push(gID + '_edit') : disableItems.push(gID + '_edit');
      if (!edit.allowEditing || ind === 0 || ind === -1 || !hasData || !isSelected || this.parent.viewType === 'ResourceView') {
        disableItems.push(gID + '_indent');
        disableItems.push(gID + '_outdent');
      } else {
        if (!isNullOrUndefined(gObj.updatedRecords[ind]) && gObj.updatedRecords[ind].level === 0 && hasData && !touchEdit) {
          enableItems.push(gID + '_indent');
          disableItems.push(gID + '_outdent');
        } else {
          previousGanttRecord = gObj.updatedRecords[ind - 1];
          if (!isNullOrUndefined(gObj.updatedRecords[ind]) && gObj.updatedRecords[ind].level - previousGanttRecord.level === 1 && ind !== -1) {
            disableItems.push(gID + '_indent');
            enableItems.push(gID + '_outdent');
          } else if (ind !== -1) {
            enableItems.push(gID + '_indent');
            enableItems.push(gID + '_outdent');
          }
        }
      }
      var isDeleteSelected = gObj.selectionModule ? gObj.selectionModule.getSelectedRecords().length > 0 || gObj.selectionModule.getSelectedRowCellIndexes().length > 0 ? true : false : false;
      if (gObj.selectionModule && gObj.selectionSettings.persistSelection) {
        if (isPersist) {
          isDeleteSelected = false;
        }
        var selectedRecords = gObj.selectionModule.getSelectedRecords();
        var selectedRecordsCount = selectedRecords.length;
        if (gObj.selectionSettings.persistSelection && selectedRecordsCount > 1) {
          for (var i = 0; i < selectedRecordsCount; i++) {
            var index = gObj.currentViewData.indexOf(selectedRecords[i]);
            if (index > -1) {
              isDeleteSelected = true;
              break;
            } else {
              isDeleteSelected = false;
            }
          }
        }
      }
      if (edit.allowDeleting && hasData && isDeleteSelected && !touchEdit) {
        enableItems.push(gID + '_delete');
      } else {
        disableItems.push(gID + '_delete');
      }
      if (gObj.editSettings.mode === 'Auto' && !isNullOrUndefined(gObj.editModule.cellEditModule) && gObj.editModule.cellEditModule.isCellEdit) {
        // New initialization for enableItems and disableItems during isCellEdit
        enableItems = [];
        enableItems.push(gID + '_update', gID + '_cancel');
        disableItems = [];
        for (var t = 0; t < toolbarItems.length; t++) {
          if (toolbarItems[t].id !== gID + '_update' && toolbarItems[t].id !== gID + '_cancel' && toolbarDefaultItems.indexOf(toolbarItems[t].id) !== -1) {
            disableItems.push(toolbarItems[t].id);
          }
        }
      } else {
        disableItems.push(gID + '_update', gID + '_cancel');
        for (var t = 0; t < toolbarItems.length; t++) {
          if (enableItems.indexOf(toolbarItems[t].id) === -1 && disableItems.indexOf(toolbarItems[t].id) === -1) {
            enableItems.push(toolbarItems[t].id);
          }
        }
      }
    } else {
      disableItems.push(gID + '_delete');
      disableItems.push(gID + '_add');
      disableItems.push(gID + '_edit');
      disableItems.push(gID + '_update');
      disableItems.push(gID + '_cancel');
      disableItems.push(gID + '_indent');
      disableItems.push(gID + '_outdent');
    }
    for (var e = 0; e < enableItems.length; e++) {
      var index = void 0;
      for (var t = 0; t < toolbarItems.length; t++) {
        if (toolbarItems[t].id === enableItems[e]) {
          index = t;
          break;
        }
      }
      if (toolbarItems.length > 0) {
        this.toolbar.hideItem(index, false);
      }
    }
    for (var d = 0; d < disableItems.length; d++) {
      var index = void 0;
      for (var t = 0; t < toolbarItems.length; t++) {
        if (toolbarItems[t].id === disableItems[d]) {
          index = t;
          break;
        }
      }
      if (toolbarItems.length > 0) {
        this.toolbar.hideItem(index, true);
      }
    }
  };
  /**
   * Enables or disables ToolBar items.
   *
   * @param {string[]} items - Defines the collection of itemID of ToolBar items.
   * @param {boolean} isEnable - Defines the items to be enabled or disabled.
   * @returns {void} .
   * @hidden
   */
  Toolbar.prototype.enableItems = function (items, isEnable) {
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
      var item = items_1[_i];
      var element = this.element.querySelector('#' + item);
      if (element) {
        this.toolbar.enableItems(element.parentElement, isEnable);
      }
    }
  };
  /**
   * Destroys the Sorting of TreeGrid.
   *
   * @function destroy
   * @returns {void} .
   * @private
   */
  Toolbar.prototype.destroy = function () {
    if (this.parent.filterModule) {
      this.unWireEvent();
    }
    if (this.parent.isDestroyed) {
      return;
    }
    if (this.toolbar) {
      this.toolbar.off('render-react-toolbar-template', this.addReactToolbarPortals);
    }
    this.toolbar.destroy();
    remove(this.element);
  };
  return Toolbar;
}();
var NonWorkingDay = /** @__PURE__ @class */function () {
  function NonWorkingDay(gantt) {
    this.weekendWidthUpdated = false;
    this.parent = gantt;
    this.nonworkingContainer = null;
    this.holidayContainer = null;
    this.weekendContainer = null;
  }
  /**
   * Method append nonworking container
   *
   * @returns {void} .
   */
  NonWorkingDay.prototype.createNonworkingContainer = function () {
    if (!this.parent.ganttChartModule.chartBodyContent.contains(this.nonworkingContainer)) {
      this.nonworkingContainer = createElement('div', {
        className: nonworkingContainer
      });
      if (this.parent.enableTimelineVirtualization) {
        this.nonworkingContainer.style.height = '100%';
      }
      this.parent.ganttChartModule.chartBodyContent.insertBefore(this.nonworkingContainer, this.parent.ganttChartModule.chartBodyContent.firstChild);
    }
  };
  /**
   * calculation for holidays rendering.
   *
   * @returns {void} .
   * @private
   */
  NonWorkingDay.prototype.renderHolidays = function () {
    if (this.parent.holidays && this.parent.holidays.length > 0) {
      this.createNonworkingContainer();
      if (!this.nonworkingContainer.contains(this.holidayContainer)) {
        this.holidayContainer = createElement('div', {
          className: holidayContainer
        });
        if (this.parent.enableTimelineVirtualization) {
          this.holidayContainer.style.height = '100%';
          this.holidayContainer.style.zIndex = '-1';
        }
        this.nonworkingContainer.appendChild(this.holidayContainer);
      }
      this.holidayContainer.innerHTML = this.getHolidaysElement().innerHTML;
    } else if (this.holidayContainer && this.holidayContainer.parentNode) {
      remove(this.holidayContainer);
      if (this.nonworkingContainer && this.nonworkingContainer.childNodes.length === 0) {
        remove(this.nonworkingContainer);
      }
    }
  };
  /**
   * Method to return holidays as html string
   *
   * @returns {HTMLElement} .
   */
  NonWorkingDay.prototype.getHolidaysElement = function () {
    var fromDate;
    var toDate;
    var container = createElement('div');
    var height = this.parent.contentHeight;
    var toolbarHeight = 0;
    if (!isNullOrUndefined(this.parent.toolbarModule) && !isNullOrUndefined(this.parent.toolbarModule.element)) {
      toolbarHeight = this.parent.toolbarModule.element.offsetHeight;
    }
    var viewportHeight = this.parent.ganttHeight - toolbarHeight - this.parent.ganttChartModule.chartTimelineContainer.offsetHeight;
    for (var i = 0; i < this.parent.holidays.length; i++) {
      if (this.parent.holidays[i].from && this.parent.holidays[i].to) {
        fromDate = this.parent.dateValidationModule.getDateFromFormat(this.parent.holidays[i].from);
        toDate = this.parent.dateValidationModule.getDateFromFormat(this.parent.holidays[i].to);
        toDate.setDate(toDate.getDate() + 1);
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);
      } else if (this.parent.holidays[i].from) {
        fromDate = this.parent.dateValidationModule.getDateFromFormat(this.parent.holidays[i].from);
        fromDate.setHours(0, 0, 0, 0);
      } else if (this.parent.holidays[i].to) {
        fromDate = this.parent.dateValidationModule.getDateFromFormat(this.parent.holidays[i].to);
        fromDate.setHours(0, 0, 0, 0);
      }
      var width = this.parent.holidays[i].from && this.parent.holidays[i].to ? this.parent.dataOperation.getTaskWidth(fromDate, toDate) : this.parent.perDayWidth;
      var left = this.parent.dataOperation.getTaskLeft(fromDate, false, true);
      var align = this.parent.enableRtl ? "right:" + left + "px;" : "left:" + left + "px;";
      var holidayDiv = createElement('div', {
        className: holidayElement,
        styles: align + " width:" + width + "px; height:100%;"
      });
      var spanTop = viewportHeight < height ? viewportHeight / 2 : height / 2;
      var spanElement = createElement('span', {
        className: holidayLabel,
        styles: "top:" + spanTop + "px;left:" + width / 2 + "px;"
      });
      var property = this.parent.disableHtmlEncode ? 'textContent' : 'innerHTML';
      spanElement[property] = this.parent.holidays[i].label ? this.parent.holidays[i].label : '';
      if (this.parent.enableHtmlSanitizer && typeof spanElement[property] === 'string') {
        spanElement[property] = SanitizeHtmlHelper.sanitize(spanElement[property]);
      }
      holidayDiv.appendChild(spanElement);
      if (this.parent.holidays[i].cssClass) {
        holidayDiv.classList.add(this.parent.holidays[i].cssClass);
      }
      container.appendChild(holidayDiv);
    }
    return container;
  };
  /**
   * @returns {void} .
   * @private
   */
  NonWorkingDay.prototype.renderWeekends = function () {
    if (this.parent.highlightWeekends) {
      this.createNonworkingContainer();
      if (!this.nonworkingContainer.contains(this.weekendContainer)) {
        this.weekendContainer = createElement('div', {
          className: weekendContainer
        });
        if (this.parent.enableTimelineVirtualization) {
          this.weekendContainer.style.height = '100%';
          this.weekendContainer.style.zIndex = '-1';
        }
        this.nonworkingContainer.appendChild(this.weekendContainer);
      }
      this.weekendContainer.innerHTML = this.getWeekendElements().innerHTML;
    } else if (this.weekendContainer) {
      remove(this.weekendContainer);
      if (this.nonworkingContainer && this.nonworkingContainer.childNodes.length === 0) {
        remove(this.nonworkingContainer);
      }
    }
  };
  /**
   * Method to get weekend html string
   *
   * @returns {HTMLElement} .
   */
  NonWorkingDay.prototype.getWeekendElements = function () {
    var container = createElement('div');
    var leftValueForStartDate = this.parent.enableTimelineVirtualization && this.parent.ganttChartModule.scrollObject.element.scrollLeft !== 0 ? this.parent.ganttChartModule.scrollObject.getTimelineLeft() : null;
    var startDate = this.parent.enableTimelineVirtualization && !isNullOrUndefined(leftValueForStartDate) ? new Date(this.parent.timelineModule['dateByLeftValue'](leftValueForStartDate).getTime()) : new Date(this.parent.timelineModule.timelineStartDate.getTime());
    var endDate = this.parent.enableTimelineVirtualization ? new Date(this.parent.timelineModule.weekendEndDate.getTime()) : new Date(this.parent.timelineModule.timelineEndDate.getTime());
    var nonWorkingIndex = this.parent.nonWorkingDayIndex;
    var isFirstCell = true;
    var isFirstExecution = true;
    this.weekendWidthUpdated = false;
    do {
      if (nonWorkingIndex.indexOf(startDate.getDay()) !== -1) {
        var left = this.parent.dataOperation.getTaskLeft(startDate, false, true);
        var width = this.parent.perDayWidth;
        if (isFirstCell) {
          var start = new Date(startDate.getTime());
          var tempEnd = new Date(start.getTime());
          tempEnd.setDate(tempEnd.getDate() + 1);
          tempEnd.setHours(0, 0, 0, 0);
          width = this.parent.dataOperation.getTaskWidth(start, tempEnd);
          isFirstCell = false;
        }
        var sDate = new Date(startDate);
        var dubDate = new Date(startDate);
        sDate.setDate(sDate.getDate() + 1);
        var sDateOffset = sDate.getTimezoneOffset();
        var dubDateOffset = dubDate.getTimezoneOffset();
        if (!isFirstExecution) {
          var isHourTimeline = this.parent.timelineModule.bottomTier === 'Hour' && this.parent.timelineModule.customTimelineSettings.bottomTier.count === 1 || this.parent.timelineModule.topTier === 'Hour' && this.parent.timelineModule.customTimelineSettings.topTier.count === 1 && this.parent.timelineModule.bottomTier === 'Minutes' && (this.parent.timelineModule.customTimelineSettings.bottomTier.count === 30 || this.parent.timelineModule.customTimelineSettings.bottomTier.count === 15);
          if (sDateOffset !== this.parent.timelineModule.timelineStartDate.getTimezoneOffset() && !this.weekendWidthUpdated) {
            if (isHourTimeline && sDateOffset < dubDateOffset) {
              width = width - this.parent.perDayWidth / 24;
              this.weekendWidthUpdated = true;
            }
          }
        } else {
          isFirstExecution = false;
        }
        var align = this.parent.enableRtl ? "right:" + left + "px;" : "left:" + left + "px;";
        var weekendDiv = createElement('div', {
          className: weekend,
          styles: align + " width:" + width + "px;height:100%;"
        });
        container.appendChild(weekendDiv);
      }
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(0, 0, 0, 0);
    } while (startDate < endDate);
    return container;
  };
  NonWorkingDay.prototype.updateHolidayLabelHeight = function () {
    var height = this.parent.getContentHeight();
    var gantttable = document.getElementById(this.parent.element.id);
    var toolbarHeight = 0;
    if (!isNullOrUndefined(this.parent.toolbarModule) && !isNullOrUndefined(this.parent.toolbarModule.element)) {
      toolbarHeight = this.parent.toolbarModule.element.offsetHeight;
    }
    var viewportHeight = this.parent.height === 'auto' ? gantttable.offsetHeight - toolbarHeight - this.parent.ganttChartModule.chartTimelineContainer.offsetHeight : this.parent.ganttHeight - toolbarHeight - this.parent.ganttChartModule.chartTimelineContainer.offsetHeight;
    var top = viewportHeight < height ? viewportHeight / 2 : height / 2;
    var labels = this.holidayContainer.querySelectorAll('.' + holidayLabel);
    for (var i = 0; i < labels.length; i++) {
      labels[i].style.top = formatUnit(top);
    }
  };
  /**
   * Method to update height for all internal containers
   *
   * @returns {void} .
   * @private
   */
  NonWorkingDay.prototype.updateContainerHeight = function () {
    var height = this.parent.getContentHeight();
    if (this.holidayContainer) {
      this.holidayContainer.style.height = formatUnit(height);
      this.updateHolidayLabelHeight();
    }
    if (this.weekendContainer) {
      this.weekendContainer.style.height = formatUnit(height);
    }
  };
  /**
   * Method to remove containers of holiday and weekend
   *
   * @returns {void} .
   */
  NonWorkingDay.prototype.removeContainers = function () {
    if (this.holidayContainer) {
      remove(this.holidayContainer);
    }
    if (this.weekendContainer) {
      remove(this.weekendContainer);
    }
    if (this.nonworkingContainer) {
      remove(this.nonworkingContainer);
    }
  };
  return NonWorkingDay;
}();
var EventMarker$1 = /** @__PURE__ @class */function () {
  function EventMarker(gantt) {
    this.parent = gantt;
    this.eventMarkersContainer = null;
  }
  /**
   * @returns {void} .
   * @private
   */
  EventMarker.prototype.renderEventMarkers = function () {
    if (this.parent.eventMarkers && this.parent.eventMarkers.length > 0) {
      if (!this.parent.ganttChartModule.chartBodyContent.contains(this.eventMarkersContainer)) {
        this.eventMarkersContainer = createElement('div', {
          className: eventMarkersContainer
        });
        this.eventMarkersContainer.setAttribute('role', 'term');
        this.parent.ganttChartModule.chartBodyContent.appendChild(this.eventMarkersContainer);
      }
      this.eventMarkersContainer.innerHTML = '';
      this.getEventMarkersElements(this.eventMarkersContainer);
    } else {
      this.removeContainer();
    }
  };
  /**
   * @returns {void} .
   * @private
   */
  EventMarker.prototype.removeContainer = function () {
    if (this.eventMarkersContainer) {
      remove(this.eventMarkersContainer);
      this.eventMarkersContainer = null;
    }
  };
  /**
   * Method to get event markers as html string
   *
   * @param {HTMLElement} container .
   * @returns {void} .
   */
  EventMarker.prototype.getEventMarkersElements = function (container) {
    var left;
    var eventMarkerElement;
    var spanElement;
    var rightArrow;
    var eventMarkerCollection = [];
    for (var i = 0; i < this.parent.eventMarkers.length; i++) {
      if (!isNullOrUndefined(this.parent.eventMarkers[i].day)) {
        left = this.parent.dataOperation.getTaskLeft(this.parent.dateValidationModule.getDateFromFormat(this.parent.eventMarkers[i].day, true), false, true);
        eventMarkerCollection.push({
          id: i,
          left: left,
          label: this.parent.eventMarkers[i].label,
          date: this.parent.dateValidationModule.getDateFromFormat(this.parent.eventMarkers[i].day, true)
        });
        var align = void 0;
        if (this.parent.enableRtl) {
          align = "right:" + left + "px;";
        } else {
          align = "left:" + left + "px;";
        }
        eventMarkerElement = createElement('div', {
          className: eventMarkersChild,
          styles: align + "  height:100%;",
          id: 'stripline' + i
        });
        if (this.parent.eventMarkers[i].label) {
          spanElement = createElement('div', {
            className: eventMarkersSpan
          });
          var property = this.parent.disableHtmlEncode ? 'textContent' : 'innerHTML';
          spanElement[property] = this.parent.eventMarkers[i].label;
          if (this.parent.enableHtmlSanitizer && typeof spanElement[property] === 'string') {
            spanElement[property] = SanitizeHtmlHelper.sanitize(spanElement[property]);
          }
          if (this.parent.enableRtl) {
            spanElement.style.right = '5px';
          } else {
            spanElement.style.left = '5px';
          }
          eventMarkerElement.appendChild(spanElement);
          rightArrow = createElement('div', {
            className: 'e-gantt-right-arrow'
          });
          eventMarkerElement.appendChild(rightArrow);
        }
        if (this.parent.eventMarkers[i].cssClass) {
          eventMarkerElement.classList.add(this.parent.eventMarkers[i].cssClass);
        }
        eventMarkerElement.setAttribute('tabindex', '-1');
        eventMarkerElement.setAttribute('aria-label', this.parent.localeObj.getConstant('eventMarkers') + ' ' + (typeof this.parent.eventMarkers[i].day === 'string' ? this.parent.eventMarkers[i].day : this.parent.getFormatedDate(this.parent.eventMarkers[i].day)) + ' ' + this.parent.eventMarkers[i].label);
        container.appendChild(eventMarkerElement);
      }
    }
    this.parent.eventMarkerColloction = eventMarkerCollection;
  };
  /**
   * @returns {void} .
   * @private
   */
  EventMarker.prototype.updateContainerHeight = function () {
    if (this.eventMarkersContainer) {
      this.eventMarkersContainer.style.height = formatUnit(this.parent.getContentHeight());
    }
  };
  return EventMarker;
}();

/**
 * DayMarkers module is used to render event markers, holidays and to highlight the weekend days.
 */
var DayMarkers = /** @__PURE__ @class */function () {
  function DayMarkers(parent) {
    this.parent = parent;
    this.nonworkingDayRender = new NonWorkingDay(this.parent);
    this.eventMarkerRender = new EventMarker$1(this.parent);
    this.wireEvents();
  }
  DayMarkers.prototype.wireEvents = function () {
    this.parent.on('refreshDayMarkers', this.refreshMarkers, this);
    this.parent.on('updateHeight', this.updateHeight, this);
    this.parent.on('ui-update', this.propertyChanged, this);
  };
  DayMarkers.prototype.propertyChanged = function (property) {
    var keys = Object.keys(getValue('properties', property));
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      switch (key) {
        case 'eventMarkers':
          this.eventMarkerRender.renderEventMarkers();
          this.updateHeight();
          break;
        case 'highlightWeekends':
          this.nonworkingDayRender.renderWeekends();
          this.updateHeight();
          break;
        case 'holidays':
          this.nonworkingDayRender.renderHolidays();
          this.updateHeight();
          break;
      }
    }
  };
  DayMarkers.prototype.refreshMarkers = function () {
    this.nonworkingDayRender.renderWeekends();
    this.nonworkingDayRender.renderHolidays();
    if (this.parent.gridLines === 'Vertical' || this.parent.gridLines === 'Both') {
      this.parent['renderChartVerticalLines']();
    }
    this.eventMarkerRender.renderEventMarkers();
  };
  DayMarkers.prototype.updateHeight = function () {
    this.nonworkingDayRender.updateContainerHeight();
    this.eventMarkerRender.updateContainerHeight();
  };
  /**
   * To get module name
   *
   * @returns {string} .
   */
  DayMarkers.prototype.getModuleName = function () {
    return 'dayMarkers';
  };
  /**
   * @returns {void} .
   * @private
   */
  DayMarkers.prototype.destroy = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.nonworkingDayRender.removeContainers();
    this.eventMarkerRender.removeContainer();
    this.parent.off('refreshDayMarkers', this.refreshMarkers);
    this.parent.off('updateHeight', this.updateHeight);
    this.parent.off('ui-update', this.propertyChanged);
  };
  return DayMarkers;
}();

/** @hidden */
var CriticalPath = /** @__PURE__ @class */function () {
  function CriticalPath(parent) {
    this.validatedids = [];
    this.resourceCollectionIds = [];
    this.criticalTasks = [];
    this.parent = parent;
  }
  CriticalPath.prototype.getCriticalTasks = function () {
    return this.criticalTasks;
  };
  CriticalPath.prototype.showCriticalPath = function (isCritical) {
    var modelIds = this.parent.ids;
    var totalRecords = this.parent.flatData;
    if (isCritical && this.parent.flatData.length > 0) {
      this.parent.enableCriticalPath = true;
      var parentRecords = this.parent.treeGrid.parentData;
      var checkEndDate = parentRecords[0].ganttProperties.endDate;
      var dateDifference = 0;
      /* eslint-disable-next-line */
      var checkBeyondEnddate_1 = [];
      var totalPredecessorsCollection = [];
      var totalPredecessorsCollectionId = [];
      var predecessorIndex = 0;
      var taskBeyondEnddate = [];
      var predecessorTaskBeyondEnddate = [];
      var collection = [];
      var collectionTaskId = [];
      var fromDataObject = [];
      var criticalPathIds = [];
      this.criticalTasks = [];
      /* eslint-disable-next-line */
      if (parentRecords[0].ganttProperties.autoEndDate > parentRecords[0].ganttProperties.endDate && !parentRecords[0].ganttProperties.isAutoSchedule) {
        checkEndDate = parentRecords[0].ganttProperties.autoEndDate;
      }
      // Find the total project endDate
      for (var i = 1; i < parentRecords.length; i++) {
        if (parentRecords[i].ganttProperties.endDate >= checkEndDate) {
          checkEndDate = parentRecords[i].ganttProperties.endDate;
        }
        if (!parentRecords[i].ganttProperties.isAutoSchedule) {
          if (parentRecords[i].ganttProperties.autoEndDate >= checkEndDate) {
            checkEndDate = parentRecords[i].ganttProperties.autoEndDate;
          }
        }
      }
      this.maxEndDate = checkEndDate;
      // find the tasks that ends on total project end date that stored in checkBeyondEnddate
      // find the tasks with predecessor that stored in totalPredecessorsCollectionId.
      for (var j = 0; j < totalRecords.length; j++) {
        totalRecords[j].isCritical = false;
        totalRecords[j].ganttProperties.isCritical = false;
        /* eslint-disable-next-line */
        dateDifference = this.parent.dataOperation.getDuration(totalRecords[j].ganttProperties.endDate, checkEndDate, totalRecords[j].ganttProperties.durationUnit, totalRecords[j].ganttProperties.isAutoSchedule, totalRecords[j].ganttProperties.isMilestone);
        totalRecords[j].slack = dateDifference + ' ' + totalRecords[j].ganttProperties.durationUnit;
        totalRecords[j].ganttProperties.slack = dateDifference + ' ' + totalRecords[j].ganttProperties.durationUnit;
        if (totalRecords[j].ganttProperties.endDate >= checkEndDate) {
          checkBeyondEnddate_1.push(totalRecords[j].ganttProperties.taskId);
        }
        if (totalRecords[j].ganttProperties.predecessor) {
          if (totalRecords[j].ganttProperties.predecessor.length !== 0) {
            totalPredecessorsCollection.push(totalRecords[j]);
            totalPredecessorsCollectionId.push(totalRecords[j].ganttProperties.taskId);
          }
        }
      }
      if (this.parent.viewType === 'ResourceView') {
        for (var i = 0; i < this.parent.taskIds.length; i++) {
          this.resourceCollectionIds[i] = this.parent.taskIds[i].slice(1);
        }
      }
      var _loop_1 = function (k) {
        if (totalPredecessorsCollectionId.indexOf(checkBeyondEnddate_1[k]) === -1) {
          if (this_1.parent.viewType === 'ProjectView') {
            predecessorIndex = modelIds.indexOf(checkBeyondEnddate_1[k].toString());
          } else {
            var currentRecords = this_1.parent.currentViewData.filter(function (data) {
              return parseInt(data.ganttProperties.taskId, 10) === checkBeyondEnddate_1[k];
            });
            for (var i = 0; i < currentRecords.length; i++) {
              if (!currentRecords[i].hasChildRecords && currentRecords[i].ganttProperties.endDate >= this_1.maxEndDate) {
                predecessorIndex = currentRecords[i].index;
              }
            }
          }
          if (totalRecords[predecessorIndex].ganttProperties.progress < 100) {
            totalRecords[predecessorIndex].isCritical = true;
            totalRecords[predecessorIndex].ganttProperties.isCritical = true;
          }
          totalRecords[predecessorIndex]['slack'] = 0 + ' ' + totalRecords[predecessorIndex].ganttProperties.durationUnit;
          taskBeyondEnddate.push(checkBeyondEnddate_1[k]);
        } else {
          predecessorTaskBeyondEnddate.push(checkBeyondEnddate_1[k]);
        }
      };
      var this_1 = this;
      // seperate the predecessor connected taskes from the individual taskes that ends on total project end date
      for (var k = 0; k < checkBeyondEnddate_1.length; k++) {
        _loop_1(k);
      }
      var predecessorLength = totalPredecessorsCollection.length;
      var endTask = [];
      // find the detail collection of predecessor for each taskes that stored in collection.
      for (var x = 0; x < predecessorLength; x++) {
        var to = -1;
        var from = -1;
        var toPredecessor = -1;
        var fromPredecessor = -1;
        var tempTaskId = void 0;
        var currentIndex = x;
        var predecessor = totalPredecessorsCollection[x].ganttProperties.predecessor;
        var individualPredecessorLength = totalPredecessorsCollection[x].ganttProperties.predecessor.length;
        /* eslint-disable-next-line */
        var taskid = totalPredecessorsCollection[x].ganttProperties.taskId;
        for (var y = 0; y < individualPredecessorLength; y++) {
          if (!isNaN(Number(predecessor[y].from)) && typeof taskid !== 'string') {
            tempTaskId = parseInt(predecessor[y].from, 10);
          } else if (!isNaN(Number(predecessor[y].from)) && typeof taskid === 'string') {
            tempTaskId = predecessor[y].from;
          } else {
            tempTaskId = predecessor[y].from;
          }
          if (tempTaskId === taskid) {
            if (to === -1) {
              if (!predecessor[y].offset) {
                to = predecessor[y].to;
                toPredecessor = predecessor[y].type;
              } else {
                to = predecessor[y].to + ':' + predecessor[y].offset + predecessor[y].offsetUnit;
                toPredecessor = predecessor[y].type;
              }
            } else {
              if (!predecessor[y].offset) {
                to = to + ',' + predecessor[y].to;
                toPredecessor = toPredecessor + ',' + predecessor[y].type;
              } else {
                to = to + ',' + predecessor[y].to + ':' + predecessor[y].offset + predecessor[y].offsetUnit;
                toPredecessor = toPredecessor + ',' + predecessor[y].type;
              }
            }
          }
          if (!isNaN(Number(predecessor[y].to)) && typeof taskid !== 'string') {
            tempTaskId = parseInt(predecessor[y].to, 10);
          } else if (!isNaN(Number(predecessor[y].to)) && typeof taskid === 'string') {
            tempTaskId = predecessor[y].to;
          } else {
            tempTaskId = predecessor[y].to;
          }
          if (tempTaskId === taskid) {
            if (from === -1) {
              if (!predecessor[y].offset) {
                from = predecessor[y].from;
                fromPredecessor = predecessor[y].type;
              } else {
                from = predecessor[y].from + ':' + predecessor[y].offset + predecessor[y].offsetUnit;
                fromPredecessor = predecessor[y].type;
              }
            } else {
              if (!predecessor[y].offset) {
                from = from + ',' + predecessor[y].from;
                fromPredecessor = fromPredecessor + ',' + predecessor[y].type;
              } else {
                from = from + ',' + predecessor[y].from + ':' + predecessor[y].offset + predecessor[y].offsetUnit;
                fromPredecessor = fromPredecessor + ',' + predecessor[y].type;
              }
            }
          }
        }
        if (from === -1) {
          from = null;
          fromPredecessor = null;
        }
        if (to === -1) {
          to = null;
          toPredecessor = null;
        }
        /* eslint-disable-next-line */
        collection.push({
          from: from,
          fromPredecessor: fromPredecessor,
          taskid: taskid,
          to: to,
          toPredecessor: toPredecessor,
          currentIndex: currentIndex,
          slack: null,
          enddate: null
        });
        collectionTaskId.push(taskid);
      }
      var collectionLength = collection.length;
      var indexEnddate = 0;
      var num = void 0;
      // find the predecessors connected taskes that does not contains any successor.
      for (var z = 0; z < collectionLength; z++) {
        if (!collection[z]['to']) {
          num = collection[z]['taskid'];
          if (this.parent.viewType === 'ProjectView') {
            indexEnddate = modelIds.indexOf(num.toString());
          } else {
            indexEnddate = this.resourceCollectionIds.indexOf(num.toString());
          }
          var flatData = totalRecords[indexEnddate].ganttProperties;
          dateDifference = this.parent.dataOperation.getDuration(flatData.endDate, checkEndDate, 'minute', flatData.isAutoSchedule, flatData.isMilestone);
          collection[z]['slack'] = dateDifference;
          collection[z]['fs'] = -1;
          collection[z]['enddate'] = flatData.endDate;
          endTask.push({
            fromdata: collection[z]['from'],
            todateID: collection[z]['taskid'],
            fromDataPredecessor: collection[z]['fromPredecessor']
          });
        }
      }
      for (var k = 0; k < endTask.length; k++) {
        fromDataObject.push(endTask[k]);
        this.slackCalculation(fromDataObject, collection, collectionTaskId, checkEndDate, totalRecords, modelIds);
      }
      criticalPathIds = this.finalCriticalPath(collection, taskBeyondEnddate, totalRecords, modelIds, checkEndDate);
      this.validatedids = [];
      this.criticalPathCollection = criticalPathIds;
      this.detailPredecessorCollection = collection;
      this.predecessorCollectionTaskIds = collectionTaskId;
    }
    if (isCritical === false && this.parent.flatData.length > 0) {
      var pathIndex = void 0;
      this.parent.enableCriticalPath = false;
      for (var z = 0; z < this.criticalPathCollection.length; z++) {
        pathIndex = modelIds.indexOf(this.criticalPathCollection[z].toString());
        totalRecords[pathIndex].isCritical = false;
      }
      this.criticalPathCollection = [];
      this.detailPredecessorCollection = [];
      this.predecessorCollectionTaskIds = [];
    }
  };
  /* eslint-disable-next-line */
  CriticalPath.prototype.slackCalculation = function (fromDataObject, collection, collectionTaskId, checkEndDate, flatRecords, modelRecordIds) {
    var _this = this;
    var fromDateArray = fromDataObject[0]['fromdata'].split(',');
    var fromDataPredecessor = fromDataObject[0]['fromDataPredecessor'].split(',');
    collectionTaskId = collectionTaskId.toString();
    collectionTaskId = collectionTaskId.split(',');
    var fromDateArray1 = [];
    var fromTaskIdIndex;
    var indexFromTaskId;
    var indexToTaskId;
    var totaskId;
    var dateDifference;
    var prevTaskEnddate;
    var offsetInMillSec;
    var ffslack;
    for (var i = 0; i < fromDateArray.length; i++) {
      fromDateArray1 = fromDateArray[i].split(':');
      fromTaskIdIndex = collectionTaskId.indexOf(fromDateArray1[0].toString());
      totaskId = collectionTaskId.indexOf(fromDataObject[0]['todateID'].toString());
      if (this.parent.viewType === 'ProjectView') {
        indexFromTaskId = modelRecordIds.indexOf(fromDateArray1[0].toString());
        indexToTaskId = modelRecordIds.indexOf(fromDataObject[0]['todateID'].toString());
      } else {
        indexFromTaskId = this.resourceCollectionIds.indexOf(fromDateArray1[0].toString());
        indexToTaskId = this.resourceCollectionIds.indexOf(fromDataObject[0]['todateID'].toString());
      }
      var fromIdFlatData = void 0;
      if (indexFromTaskId !== -1) {
        fromIdFlatData = flatRecords[indexFromTaskId].ganttProperties;
      }
      var toIdFlatData = flatRecords[indexToTaskId].ganttProperties;
      if (fromDateArray1.length > 1) {
        if (fromDateArray1[1].indexOf('hour') !== -1) {
          offsetInMillSec = parseFloat(fromDateArray1[1]) * 60;
        } else if (fromDateArray1[1].indexOf('day') !== -1) {
          offsetInMillSec = parseFloat(fromDateArray1[1]) * (this.parent.secondsPerDay / 3600) * 60;
        } else {
          offsetInMillSec = parseFloat(fromDateArray1[1]);
        }
      }
      if (fromIdFlatData) {
        // calculate slack value for the task contains predecessor connection in "finish to start".
        if (fromDataPredecessor[i] === 'FS') {
          if (fromIdFlatData.endDate > toIdFlatData.startDate) {
            dateDifference = -this.parent.dataOperation.getDuration(toIdFlatData.startDate, fromIdFlatData.endDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
          } else {
            dateDifference = this.parent.dataOperation.getDuration(fromIdFlatData.endDate, toIdFlatData.startDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
          }
          // execute if the slack value is not set initially.
          if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
            // execute if the offset value is not given.
            if (fromDateArray1.length <= 1) {
              if (collection[totaskId]['slack'] + dateDifference <= 0) {
                collection[fromTaskIdIndex]['slack'] = 0;
              } else {
                collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
              }
            }
          }
          // execute if the current calculated slack value is less than the previous slack value.
          else if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
            // execute if the offset value is not given.
            if (fromDateArray1.length <= 1) {
              if (collection[totaskId]['slack'] + dateDifference <= 0) {
                collection[fromTaskIdIndex]['slack'] = 0;
              } else {
                collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
              }
            }
          }
          // execute if the offset value is given.
          if (fromDateArray1.length > 1) {
            collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
            collection[fromTaskIdIndex]['slack'] = collection[fromTaskIdIndex]['slack'] - offsetInMillSec;
            if (collection[fromTaskIdIndex]['slack'] <= 0) {
              collection[fromTaskIdIndex]['slack'] = 0;
            }
          }
          collection[fromTaskIdIndex]['fs'] = 1;
          collection[fromTaskIdIndex]['fsslack'] = collection[fromTaskIdIndex]['slack'];
          collection[fromTaskIdIndex]['enddate'] = fromIdFlatData.startDate;
          if (fromIdFlatData.endDate >= checkEndDate) {
            collection[fromTaskIdIndex]['slack'] = 0;
          }
        }
        //  calculate slack value for the task contains predecessor connection in "start to start".
        if (fromDataPredecessor[i] === 'SS') {
          // It execute if the task is in auto mode.
          if (fromIdFlatData.isAutoSchedule) {
            if (fromIdFlatData.startDate > toIdFlatData.startDate) {
              dateDifference = -this.parent.dataOperation.getDuration(toIdFlatData.endDate, fromIdFlatData.startDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
            } else {
              dateDifference = this.parent.dataOperation.getDuration(fromIdFlatData.startDate, toIdFlatData.startDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
            }
            // It execute while the slack value is not set to the corresponding task.
            if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
              if (fromDateArray1.length <= 1) {
                if (collection[totaskId]['slack'] + dateDifference <= 0) {
                  collection[fromTaskIdIndex]['slack'] = 0;
                } else {
                  collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                }
              }
            }
            //It execute while already the slack value is set and it is higher than the datedifference.
            else if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
              if (fromDateArray1.length <= 1) {
                if (collection[totaskId]['slack'] + dateDifference <= 0) {
                  collection[fromTaskIdIndex]['slack'] = 0;
                } else {
                  collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                }
              }
            }
            // execute if the offset value is given.
            if (fromDateArray1.length > 1) {
              collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
              collection[fromTaskIdIndex]['slack'] = collection[fromTaskIdIndex]['slack'] - offsetInMillSec;
              if (collection[fromTaskIdIndex]['slack'] <= 0) {
                collection[fromTaskIdIndex]['slack'] = 0;
              }
            }
            collection[fromTaskIdIndex]['fs'] = 1;
            collection[fromTaskIdIndex]['fsslack'] = collection[fromTaskIdIndex]['slack'];
            collection[fromTaskIdIndex]['enddate'] = fromIdFlatData.startDate;
          }
          // It execute if the task is in not an auto mode task.
          else if (!fromIdFlatData.isAutoSchedule) {
            dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
            if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
              collection[fromTaskIdIndex]['slack'] = dateDifference;
            } else if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
              collection[fromTaskIdIndex]['slack'] = dateDifference;
            }
          }
          if (fromIdFlatData.endDate >= checkEndDate && fromIdFlatData.endDate <= checkEndDate) {
            collection[fromTaskIdIndex]['slack'] = 0;
          }
        }
        //  calculate slack value for the task contains predecessor connection in "finish to finish".
        if (fromDataPredecessor[i] === 'FF') {
          // execute if the previous task is from finish to start or finish to finish state.
          if (collection[totaskId]['fs'] === 1 || collection[totaskId]['ff'] === 1 || collection[totaskId]['fs'] === -1) {
            if (collection[totaskId]['fs'] === 1 || collection[totaskId]['ff'] === 1) {
              prevTaskEnddate = toIdFlatData.endDate;
              ffslack = collection[totaskId]['slack'];
            }
            if (collection[totaskId]['fs'] === -1) {
              prevTaskEnddate = collection[totaskId]['enddate'];
              ffslack = collection[totaskId]['slack'];
            }
            if (prevTaskEnddate > fromIdFlatData.endDate) {
              dateDifference = -this.getSlackDuration(fromIdFlatData.endDate, prevTaskEnddate, 'minute', flatRecords[indexFromTaskId]);
            } else {
              dateDifference = this.getSlackDuration(prevTaskEnddate, fromIdFlatData.endDate, 'minute', flatRecords[indexFromTaskId]);
            }
            // set the slack value if the slack value is not set initially.
            if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
              // execute if the offset value is not given.
              if (fromDateArray1.length <= 1) {
                if (ffslack - dateDifference < 0) {
                  collection[fromTaskIdIndex]['slack'] = 0;
                } else {
                  collection[fromTaskIdIndex]['slack'] = ffslack - dateDifference;
                }
              }
            }
            // overright the slack value if the current calculated slack value is less than the previous slack value.
            else if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
              // execute if the offset value is not given.
              if (fromDateArray1.length <= 1) {
                if (ffslack - dateDifference < 0) {
                  collection[fromTaskIdIndex]['slack'] = 0;
                } else {
                  collection[fromTaskIdIndex]['slack'] = ffslack - dateDifference;
                }
              }
            }
            // execute if the offset value is given.
            if (fromDateArray1.length > 1) {
              collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] - dateDifference;
              collection[fromTaskIdIndex]['slack'] = collection[fromTaskIdIndex]['slack'] - offsetInMillSec;
              if (collection[fromTaskIdIndex]['slack'] <= 0) {
                collection[fromTaskIdIndex]['slack'] = 0;
              }
            }
            collection[fromTaskIdIndex]['ff'] = 1;
            collection[fromTaskIdIndex]['enddate'] = prevTaskEnddate;
            collection[fromTaskIdIndex]['fsslack'] = ffslack;
          }
          if (fromIdFlatData.endDate >= checkEndDate && fromIdFlatData.endDate <= checkEndDate) {
            collection[fromTaskIdIndex]['slack'] = 0;
          }
        }
        //  calculate slack value for the task contains predecessor connection in "start to finish".
        if (fromDataPredecessor[i] === 'SF') {
          //It execute if the task is an auto mode task.
          if (fromIdFlatData.isAutoSchedule) {
            //execute if the slack value is not set initially.
            if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
              // execute if the offset value is not given.
              if (fromDateArray1.length <= 1) {
                // execute if the previous task does no has sucessor.
                if (isNullOrUndefined(collection[totaskId]['to'])) {
                  dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
                  collection[fromTaskIdIndex]['slack'] = dateDifference;
                }
                // execute if the previous task has sucessor.
                else if (!isNullOrUndefined(collection[totaskId]['to'])) {
                  if (toIdFlatData.endDate > fromIdFlatData.startDate) {
                    dateDifference = -this.parent.dataOperation.getDuration(fromIdFlatData.startDate, toIdFlatData.endDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
                  } else {
                    dateDifference = this.getSlackDuration(toIdFlatData.endDate, fromIdFlatData.startDate, 'minute', flatRecords[indexFromTaskId]);
                  }
                  if (collection[totaskId]['slack'] + dateDifference <= 0) {
                    collection[fromTaskIdIndex]['slack'] = 0;
                  } else {
                    collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                  }
                }
              }
              // execute if the offset value is given.
              else if (fromDateArray1.length > 1) {
                if (toIdFlatData.endDate >= fromIdFlatData.endDate) {
                  if (fromIdFlatData.startDate > toIdFlatData.endDate) {
                    dateDifference = -this.getSlackDuration(toIdFlatData.endDate, fromIdFlatData.startDate, 'minute', flatRecords[indexFromTaskId]);
                  } else {
                    dateDifference = this.parent.dataOperation.getDuration(fromIdFlatData.startDate, toIdFlatData.endDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
                  }
                } else {
                  dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
                }
                collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                collection[fromTaskIdIndex]['slack'] = collection[fromTaskIdIndex]['slack'] - offsetInMillSec;
                if (collection[fromTaskIdIndex]['slack'] <= 0) {
                  collection[fromTaskIdIndex]['slack'] = 0;
                }
              }
              collection[fromTaskIdIndex]['fs'] = 1;
              collection[fromTaskIdIndex]['fsslack'] = collection[fromTaskIdIndex]['slack'];
              collection[fromTaskIdIndex]['enddate'] = fromIdFlatData.startDate;
            } else {
              if (fromDateArray1.length <= 1) {
                if (isNullOrUndefined(collection[totaskId]['to'])) {
                  dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
                } else if (!isNullOrUndefined(collection[totaskId]['to'])) {
                  if (toIdFlatData.endDate > fromIdFlatData.startDate) {
                    dateDifference = -this.parent.dataOperation.getDuration(fromIdFlatData.startDate, toIdFlatData.endDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
                  } else {
                    dateDifference = this.getSlackDuration(toIdFlatData.endDate, fromIdFlatData.startDate, 'minute', flatRecords[indexFromTaskId]);
                  }
                }
                // execute if the current calculated slack value is less than the previous slack value.
                if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
                  if (isNullOrUndefined(collection[totaskId]['to'])) {
                    collection[fromTaskIdIndex]['slack'] = dateDifference;
                  } else if (!isNullOrUndefined(collection[totaskId]['to'])) {
                    if (collection[totaskId]['slack'] + dateDifference <= 0) {
                      collection[fromTaskIdIndex]['slack'] = 0;
                    } else {
                      collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                    }
                  }
                }
              } else if (fromDateArray1.length > 1) {
                if (toIdFlatData.endDate > fromIdFlatData.endDate) {
                  if (fromIdFlatData.startDate > toIdFlatData.endDate) {
                    dateDifference = -this.getSlackDuration(toIdFlatData.endDate, fromIdFlatData.startDate, 'minute', flatRecords[indexFromTaskId]);
                  } else {
                    dateDifference = this.parent.dataOperation.getDuration(fromIdFlatData.startDate, toIdFlatData.endDate, 'minute', fromIdFlatData.isAutoSchedule, fromIdFlatData.isMilestone);
                  }
                } else {
                  dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
                }
                // execute if the current calculated slack value is less than the previous slack value.
                if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
                  collection[fromTaskIdIndex]['slack'] = collection[totaskId]['slack'] + dateDifference;
                  collection[fromTaskIdIndex]['slack'] = collection[fromTaskIdIndex]['slack'] - offsetInMillSec;
                  if (collection[fromTaskIdIndex]['slack'] <= 0) {
                    collection[fromTaskIdIndex]['slack'] = 0;
                  }
                }
              }
              collection[fromTaskIdIndex]['fs'] = 1;
              collection[fromTaskIdIndex]['fsslack'] = collection[fromTaskIdIndex]['slack'];
              collection[fromTaskIdIndex]['enddate'] = fromIdFlatData.startDate;
            }
          }
          //It execute if the task is an auto mode task.
          else if (!fromIdFlatData.isAutoSchedule) {
            dateDifference = this.getSlackDuration(fromIdFlatData.endDate, checkEndDate, 'minute', flatRecords[indexFromTaskId]);
            if (isNullOrUndefined(collection[fromTaskIdIndex]['slack'])) {
              collection[fromTaskIdIndex]['slack'] = dateDifference;
            } else if (collection[fromTaskIdIndex]['slack'] > dateDifference && collection[fromTaskIdIndex]['slack'] !== 0) {
              collection[fromTaskIdIndex]['slack'] = dateDifference;
            }
          }
          if (fromIdFlatData.endDate >= checkEndDate && fromIdFlatData.endDate <= checkEndDate) {
            collection[fromTaskIdIndex]['slack'] = 0;
          }
        }
        if (collection[fromTaskIdIndex]['from']) {
          fromDataObject.push({
            fromdata: collection[fromTaskIdIndex]['from'],
            todateID: collection[fromTaskIdIndex]['taskid'],
            fromDataPredecessor: collection[fromTaskIdIndex]['fromPredecessor']
          });
        }
      }
    }
    if (fromDataObject) {
      fromDataObject.splice(0, 1);
      if (fromDataObject.length > 0) {
        fromDataObject.forEach(function (item) {
          _this.slackCalculation([item], collection, collectionTaskId, checkEndDate, flatRecords, modelRecordIds);
        });
      }
    }
  };
  CriticalPath.prototype.getSlackDuration = function (sDate, eDate, durationUnit, record) {
    var startDate = this.parent.dateValidationModule.checkStartDate(new Date(sDate));
    var endDate = this.parent.dateValidationModule.checkEndDate(new Date(eDate));
    if (this.parent.dataOperation['getTimeDifference'](startDate, endDate, true) <= 0) {
      return 0;
    } else {
      return this.parent.dataOperation.getDuration(startDate, endDate, durationUnit, record.ganttProperties.isAutoSchedule, true);
    }
  };
  /* eslint-disable-next-line */
  CriticalPath.prototype.updateCriticalTasks = function (record, criticalPathIds) {
    for (var i = 0; i < record.ganttProperties.predecessor.length; i++) {
      var fromRecord = void 0;
      if (this.parent.viewType === 'ProjectView') {
        fromRecord = this.parent.getRecordByID(record.ganttProperties.predecessor[i].from);
      } else {
        var resourceIndex = this.resourceCollectionIds.indexOf(record.ganttProperties.predecessor[i].from.toString());
        fromRecord = this.parent.flatData[resourceIndex];
      }
      var durationDiff = void 0;
      if (record.ganttProperties.endDate.getTime() >= this.maxEndDate.getTime()) {
        record.ganttProperties.slack = record.slack = 0 + ' ' + record.ganttProperties.durationUnit;
        if (record.ganttProperties.progress < 100) {
          record.isCritical = true;
          record.ganttProperties.isCritical = true;
          if (criticalPathIds.indexOf(record.ganttProperties.taskId) === -1) {
            criticalPathIds.push(record.ganttProperties.taskId);
          }
        }
      }
      if (fromRecord) {
        if (record.ganttProperties.predecessor[i].type === 'FS') {
          durationDiff = this.parent.dataOperation.getDuration(fromRecord.ganttProperties.endDate, record.ganttProperties.startDate, fromRecord.ganttProperties.durationUnit, fromRecord.ganttProperties.isAutoSchedule, true);
        } else if (record.ganttProperties.predecessor[i].type === 'SS') {
          durationDiff = this.parent.dataOperation.getDuration(fromRecord.ganttProperties.startDate, record.ganttProperties.startDate, fromRecord.ganttProperties.durationUnit, fromRecord.ganttProperties.isAutoSchedule, true);
        } else if (record.ganttProperties.predecessor[i].type === 'FF') {
          durationDiff = this.parent.dataOperation.getDuration(fromRecord.ganttProperties.endDate, record.ganttProperties.endDate, fromRecord.ganttProperties.durationUnit, fromRecord.ganttProperties.isAutoSchedule, true);
        } else if (record.ganttProperties.predecessor[i].type === 'SF') {
          durationDiff = this.parent.dataOperation.getDuration(record.ganttProperties.endDate, fromRecord.ganttProperties.startDate, fromRecord.ganttProperties.durationUnit, fromRecord.ganttProperties.isAutoSchedule, true);
        }
        if (durationDiff >= 0 && this.validatedids.indexOf(parseInt(fromRecord.ganttProperties.taskId, 10)) === -1 && fromRecord.ganttProperties.taskId !== record.ganttProperties.taskId) {
          fromRecord.ganttProperties.slack = record.ganttProperties.slack;
          fromRecord.slack = record.slack;
          fromRecord.isCritical = record.ganttProperties.isCritical;
          fromRecord.ganttProperties.isCritical = record.ganttProperties.isCritical;
          if (criticalPathIds.indexOf(fromRecord.ganttProperties.taskId) === -1 && fromRecord.ganttProperties.isCritical && fromRecord.ganttProperties.progress < 100) {
            criticalPathIds.push(fromRecord.ganttProperties.taskId);
            this.validatedids.push(parseInt(fromRecord.ganttProperties.taskId, 10));
            if (this.criticalTasks.indexOf(fromRecord) === -1) {
              this.criticalTasks.push(fromRecord);
            }
          }
          if (fromRecord.ganttProperties.predecessorsName) {
            this.updateCriticalTasks(fromRecord, criticalPathIds);
          }
        }
      }
    }
  };
  /* eslint-disable-next-line */
  CriticalPath.prototype.finalCriticalPath = function (collection, taskBeyondEnddate, flatRecords, modelRecordIds, checkEndDate) {
    /* eslint-disable-next-line */
    var criticalPathIds = [];
    var index;
    var predecessorFrom;
    for (var x = collection.length - 1; x >= 0; x--) {
      if (this.parent.viewType === 'ProjectView') {
        index = modelRecordIds.indexOf(collection[x]['taskid'].toString());
      } else {
        index = this.resourceCollectionIds.indexOf(collection[x]['taskid'].toString());
      }
      var predecessorLength = flatRecords[index].ganttProperties.predecessor;
      var noSlackValue = 0 + ' ' + flatRecords[index].ganttProperties.durationUnit;
      if (predecessorLength && predecessorLength.length > 0) {
        for (var i = predecessorLength.length - 1; i >= 0; i--) {
          var toID = void 0;
          if (this.parent.viewType === 'ProjectView') {
            toID = this.parent.ids.indexOf(predecessorLength[i].to);
          } else {
            toID = this.resourceCollectionIds.indexOf(predecessorLength[i].to);
          }
          var dateDifference = void 0;
          var currentData = flatRecords[index].ganttProperties;
          if (toID !== -1) {
            if (predecessorLength[i].type === 'FS') {
              if (predecessorLength[i].to !== currentData.taskId.toString()) {
                if (predecessorLength[i].to !== currentData.taskId.toString() || this.parent.viewType === 'ResourceView') {
                  /* eslint-disable-next-line */
                  dateDifference = this.parent.dataOperation.getDuration(currentData.endDate, flatRecords[toID].ganttProperties.startDate, currentData.durationUnit, currentData.isAutoSchedule, currentData.isMilestone);
                } else {
                  toID = this.parent.ids.indexOf(predecessorLength[i].from);
                  if (toID !== -1) {
                    dateDifference = this.parent.dataOperation.getDuration(flatRecords[toID].ganttProperties.endDate, currentData.startDate, currentData.durationUnit, currentData.isAutoSchedule, currentData.isMilestone);
                    if (dateDifference === 0 && index !== toID && flatRecords[index].slack === noSlackValue) {
                      flatRecords[toID].slack = flatRecords[index].slack;
                      flatRecords[toID].ganttProperties.slack = flatRecords[index].slack;
                    }
                  }
                }
                if (toID !== -1) {
                  if (dateDifference === 0 && index !== toID && flatRecords[index].slack !== noSlackValue) {
                    flatRecords[index].slack = flatRecords[toID].slack;
                    flatRecords[index].ganttProperties.slack = flatRecords[toID].slack;
                  } else if (dateDifference !== 0 && index !== toID && flatRecords[toID].isCritical) {
                    flatRecords[index].slack = dateDifference + ' ' + flatRecords[index].ganttProperties.durationUnit;
                    flatRecords[index].ganttProperties.slack = dateDifference + ' ' + flatRecords[index].ganttProperties.durationUnit;
                  }
                }
              }
            } else if (predecessorLength[i].type === 'SF') {
              /* eslint-disable-next-line */
              dateDifference = this.parent.dataOperation.getDuration(currentData.startDate, flatRecords[toID].ganttProperties.endDate, currentData.durationUnit, currentData.isAutoSchedule, currentData.isMilestone);
            } else if (predecessorLength[i].type === 'SS') {
              /* eslint-disable-next-line */
              dateDifference = this.parent.dataOperation.getDuration(currentData.startDate, flatRecords[toID].ganttProperties.startDate, currentData.durationUnit, currentData.isAutoSchedule, currentData.isMilestone);
            } else {
              /* eslint-disable-next-line */
              dateDifference = this.parent.dataOperation.getDuration(currentData.endDate, flatRecords[toID].ganttProperties.endDate, currentData.durationUnit, currentData.isAutoSchedule, currentData.isMilestone);
            }
            if (typeof flatRecords[index][this.parent.taskFields.id] === 'number') {
              predecessorFrom = parseInt(predecessorLength[i].from, 10);
            } else {
              predecessorFrom = predecessorLength[i].from;
            }
            if (predecessorFrom === flatRecords[index][this.parent.taskFields.id] && flatRecords[toID].slack === noSlackValue && dateDifference <= 0) {
              flatRecords[index].slack = noSlackValue;
              flatRecords[index].ganttProperties.slack = noSlackValue;
            }
          }
        }
      }
      if (flatRecords[index].slack === noSlackValue) {
        if (flatRecords[index].ganttProperties.progress < 100) {
          flatRecords[index].isCritical = true;
          flatRecords[index].ganttProperties.isCritical = true;
          if (this.criticalTasks.indexOf(flatRecords[index]) === -1) {
            this.criticalTasks.push(flatRecords[index]);
          }
          if (criticalPathIds.indexOf(collection[x]['taskid']) === -1) {
            criticalPathIds.push(collection[x]['taskid']);
          }
        }
      }
      if (flatRecords[index].ganttProperties.predecessor && flatRecords[index].ganttProperties.predecessor.length > 0) {
        this.updateCriticalTasks(flatRecords[index], criticalPathIds);
      }
    }
    if (taskBeyondEnddate.length > 0) {
      for (var i = 0; i < taskBeyondEnddate.length; i++) {
        if (this.parent.viewType === 'ProjectView') {
          index = modelRecordIds.indexOf(taskBeyondEnddate[i].toString());
        } else {
          index = this.resourceCollectionIds.indexOf(taskBeyondEnddate[i].toString());
        }
        if (index !== -1 && flatRecords[index].ganttProperties.progress < 100) {
          this.criticalTasks.push(flatRecords[index]);
          if (criticalPathIds.indexOf(taskBeyondEnddate[i]) === -1) {
            criticalPathIds = criticalPathIds.concat(taskBeyondEnddate[i]);
          }
        }
      }
    }
    return criticalPathIds;
  };
  /* eslint-disable-next-line */
  CriticalPath.prototype.criticalConnectorLine = function (criticalPathIds, collection, condition, collectionTaskId) {
    this.parent.removeCriticalPathStyles();
    var _loop_2 = function (i) {
      var criticalData;
      if (this_2.parent.viewType === 'ProjectView') {
        criticalData = this_2.parent.flatData[this_2.parent.ids.indexOf(criticalPathIds[i].toString())];
      } else {
        var currentRecords = this_2.parent.flatData.filter(function (data) {
          return data.ganttProperties.taskId.toString() === criticalPathIds[i].toString();
        });
        for (var i_1 = 0; i_1 < currentRecords.length; i_1++) {
          if (currentRecords[i_1].ganttProperties.isCritical || currentRecords[i_1].ganttProperties.endDate >= this_2.maxEndDate) {
            criticalData = currentRecords[i_1];
          }
        }
      }
      var index = this_2.parent.currentViewData.indexOf(criticalData);
      var element = this_2.parent.getRowByIndex(index);
      var taskClass = void 0;
      var columnFields = this_2.parent.taskFields;
      if (criticalData && criticalData.parentItem) {
        var parentRecord = this_2.parent.currentViewData.filter(function (data) {
          return criticalData.parentItem.uniqueID === data.uniqueID;
        });
        var parentIndex = this_2.parent.flatData.indexOf(parentRecord[0]);
        var parentElement = this_2.parent.getRowByIndex(parentIndex);
        if (parentElement) {
          var parentTaskbarElement = parentElement.querySelectorAll('.e-taskbar-main-container');
          for (var i_2 = 0; i_2 < parentTaskbarElement.length; i_2++) {
            if (parentTaskbarElement[i_2].getAttribute('rowuniqueid') === criticalData['rowUniqueID']) {
              addClass(parentTaskbarElement[i_2].querySelectorAll('.e-gantt-child-taskbar-inner-div'), criticalChildTaskBarInnerDiv);
            }
          }
        }
      }
      /* eslint-disable-next-line */
      if (this_2.parent.allowUnscheduledTasks && criticalData && !criticalData[columnFields.startDate] && !criticalData[columnFields.endDate] && criticalData[columnFields.duration]) {
        taskClass = criticalUnscheduledTask;
      } else {
        taskClass = criticalChildProgressBarInnerDiv;
      }
      if (element && (this_2.parent.viewType === 'ProjectView' || this_2.parent.viewType === 'ResourceView' && !criticalData.hasChildRecords)) {
        if (element.getElementsByClassName('e-gantt-milestone')[0]) {
          addClass(element.querySelectorAll('.e-gantt-milestone'), criticalMilestone);
        }
        if (element.getElementsByClassName('e-gantt-child-taskbar-inner-div').length > 0) {
          addClass(element.querySelectorAll('.e-gantt-child-taskbar-inner-div'), criticalChildTaskBarInnerDiv);
        }
        if (element.getElementsByClassName('e-gantt-child-progressbar-inner-div').length > 0) {
          addClass(element.querySelectorAll('.e-gantt-child-progressbar-inner-div'), taskClass);
        }
      }
    };
    var this_2 = this;
    for (var i = 0; i < criticalPathIds.length; i++) {
      _loop_2(i);
    }
    if (collection.length !== 0) {
      var index = 0;
      var currentdata = void 0;
      var checking = [];
      /* eslint-disable-next-line */
      var checkint = void 0;
      var values = void 0;
      for (var i = 0; i < this.criticalPathCollection.length; i++) {
        index = collectionTaskId.indexOf(this.criticalPathCollection[i]);
        currentdata = collection[index];
        if (index !== -1 && currentdata['to']) {
          checking = currentdata['to'].split(',');
          for (var j = 0; j < checking.length; j++) {
            values = checking[j].split('+');
            if (checking[j].indexOf('-') >= 0) {
              values = checking[j].split('-');
            }
            checkint = values[0].replace(':', '');
            if (typeof criticalPathIds[j] === 'number') {
              checkint = parseInt(values[0], 10);
            }
            if (criticalPathIds.indexOf(checkint) !== -1) {
              var taskIdString = String(currentdata['taskid']);
              var checkintString = String(checkint);
              var lineElement = this.parent.element.querySelectorAll('#ConnectorLineparent' + taskIdString.replace(/([.])/g, '\\$1') + 'child' + checkintString.replace(/([.])/g, '\\$1'));
              if (lineElement.length > 0) {
                addClass(this.parent.element.querySelectorAll('#ConnectorLineparent' + taskIdString.replace(/([.])/g, '\\$1') + 'child' + checkintString.replace(/([.])/g, '\\$1'))[0].querySelectorAll('.e-connector-line'), criticalConnectorLineSVG);
                addClass(this.parent.element.querySelectorAll('#ConnectorLineparent' + taskIdString.replace(/([.])/g, '\\$1') + 'child' + checkintString.replace(/([.])/g, '\\$1'))[0].querySelectorAll('.e-connector-line-arrow'), criticalConnectorArrowSVG);
              }
            }
          }
        }
      }
    }
  };
  CriticalPath.prototype.getModuleName = function () {
    return 'criticalPath';
  };
  /**
   * Destroys the Critical Path of Gantt.
   *
   * @returns {void} .
   * @private
   */
  CriticalPath.prototype.destroy = function () {
    if (!this.parent.enableCriticalPath && this.parent.criticalPathModule) {
      this.parent.criticalPathModule = undefined;
    }
  };
  return CriticalPath;
}();
var __assign$6 = undefined && undefined.__assign || function () {
  __assign$6 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$6.apply(this, arguments);
};
var UndoRedo = /** @__PURE__ @class */function () {
  function UndoRedo(parent) {
    this.isUndoRedoPerformed = false;
    this.changedRecords = [];
    this.getRedoCollection = [];
    this.getUndoCollection = [];
    this.redoEnabled = false;
    this.previousSortedColumns = [];
    this.searchString = '';
    this.isFromUndoRedo = false;
    this.canUpdateIndex = true;
    this.sortedColumnsLength = 0;
    this.parent = parent;
  }
  /**
   *Initiates an undo action to revert the most recent change performed.
   *
   * @returns {void}
   * @public
   */
  UndoRedo.prototype.undoAction = function () {
    var _this = this;
    if (this.getUndoCollection.length > 0) {
      var updateAction_1 = this.getUndoCollection[this.getUndoCollection.length - 1];
      var previousActions = {};
      if (updateAction_1['action'] === 'ZoomIn' || updateAction_1['action'] === 'ZoomOut' || updateAction_1['action'] === 'ZoomToFit') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['previousZoomingLevel'] = extend({}, {}, this.parent.currentZoomingLevel, true);
      } else if (updateAction_1['action'] === 'NextTimeSpan' || updateAction_1['action'] === 'PreviousTimeSpan') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['previousTimelineStartDate'] = extend([], [this.parent.timelineModule.timelineStartDate], [], true)[0];
        previousActions['previousTimelineEndDate'] = extend([], [this.parent.timelineModule.timelineEndDate], [], true)[0];
      } else if (updateAction_1['action'] === 'Sorting') {
        previousActions['action'] = 'Sorting';
        previousActions['sortColumns'] = this.previousSortedColumns;
      } else if (updateAction_1['action'] === 'Filtering') {
        previousActions['action'] = 'Filtering';
        previousActions['filteredColumns'] = updateAction_1['filteredColumns'];
      } else if (updateAction_1['action'] === 'ColumnReorder') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['toColumn'] = updateAction_1['fromColumn'];
        previousActions['fromColumn'] = updateAction_1['toColumn'];
        previousActions['fromIndex'] = updateAction_1['toIndex'];
        previousActions['toIndex'] = updateAction_1['fromIndex'];
      } else if (updateAction_1['action'] === 'Search') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['searchString'] = this.searchString;
      } else if (updateAction_1['action'] === 'ColumnState') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['showhideColumns'] = updateAction_1['showhideColumns'];
      } else if (updateAction_1['action'] === 'ColumnResize') {
        previousActions['action'] = updateAction_1['action'];
        previousActions['resizedColumn'] = __assign$6({}, this.parent.treeGrid.columns[updateAction_1['resizedColumn'].index]);
      } else if (updateAction_1['action'] === 'RowDragAndDrop' || updateAction_1['action'] === 'TaskbarDragAndDrop') {
        var rowItems = [];
        for (var i = 0; i < updateAction_1['beforeDrop'].length; i++) {
          if (this.parent.viewType === 'ProjectView') {
            rowItems.push(this.parent.getRecordByID(updateAction_1['beforeDrop'][i]['data'].ganttProperties.taskId));
          } else {
            rowItems.push(this.parent.flatData[this.parent.taskIds.indexOf('T' + updateAction_1['beforeDrop'][i]['data'].ganttProperties.taskId.toString())]);
          }
        }
        previousActions['action'] = updateAction_1['action'];
        previousActions['beforeDrop'] = [];
        var previousDetails = {};
        var dropRecord = extend([], [], [this.parent.getRecordByID(updateAction_1['afterDrop'].dropRecord.ganttProperties.taskId)], true)[0];
        previousDetails['data'] = [];
        for (var i = 0; i < updateAction_1['afterDrop'].data.length; i++) {
          if (this.parent.viewType === 'ProjectView') {
            previousDetails['data'].push(extend([], [], [this.parent.getRecordByID(updateAction_1['afterDrop'].data[i].ganttProperties.taskId)], true)[0]);
          } else {
            previousDetails['data'].push(extend([], [], this.parent.flatData[this.parent.taskIds.indexOf('T' + updateAction_1['afterDrop'].data[i].ganttProperties.taskId)], true)[0]);
          }
        }
        previousDetails['dropRecord'] = extend([], [], [dropRecord], true)[0];
        previousDetails['dropPosition'] = updateAction_1['afterDrop'].dropPosition;
        previousActions['afterDrop'] = previousDetails;
        this['findPosition'](rowItems, previousActions, 'beforeDrop');
      } else if (updateAction_1['action'] === 'Indent' || updateAction_1['action'] === 'Outdent') {
        previousActions['selectedRowIndexes'] = updateAction_1['selectedRowIndexes'];
        previousActions['droppedRecord'] = extend([], [], [this.parent.flatData[this.parent.ids.indexOf(updateAction_1['droppedRecord'].ganttProperties.taskId.toString())]], true)[0];
        previousActions['modifiedRecord'] = [];
        this.findPosition(extend([], [], [this.parent.flatData[this.parent.ids.indexOf(updateAction_1['modifiedRecord'][0].data.ganttProperties.taskId.toString())]], true), previousActions, 'modifiedRecord');
        previousActions['action'] = updateAction_1['action'];
      } else if (updateAction_1['action'] === 'Delete') {
        previousActions['action'] = 'Delete';
        previousActions['deleteRecords'] = [];
        for (var i = 0; i < updateAction_1['deletedRecordsDetails'].length; i++) {
          previousActions['deleteRecords'].push(updateAction_1['deletedRecordsDetails'][i].data);
        }
      } else if (updateAction_1['action'] === 'Add') {
        previousActions['action'] = 'Add';
        previousActions['deletedRecordsDetails'] = [];
        var rowItems = updateAction_1['addedRecords'];
        this.findPosition(rowItems, previousActions, 'deletedRecordsDetails');
      } else {
        previousActions['action'] = updateAction_1['action'];
        previousActions['modifiedRecords'] = [];
        for (var i = 0; i < updateAction_1['modifiedRecords'].length; i++) {
          var index = void 0;
          if (this.parent.viewType === 'ProjectView') {
            index = this.parent.ids.indexOf(updateAction_1['modifiedRecords'][i].ganttProperties.taskId.toString());
          } else {
            if (updateAction_1['modifiedRecords'][i].hasChildRecords) {
              index = this.parent.taskIds.indexOf('R' + updateAction_1['modifiedRecords'][i].ganttProperties.taskId);
            } else {
              index = this.parent.taskIds.indexOf('T' + updateAction_1['modifiedRecords'][i].ganttProperties.taskId);
            }
          }
          previousActions['modifiedRecords'].push(extend([], [this.parent.flatData[index]], [], true)[0]);
        }
      }
      this.getRedoCollection.push(previousActions);
      this.isUndoRedoPerformed = true;
      this.changedRecords = [];
      this.currentAction = updateAction_1;
      if (updateAction_1['action'] === 'ZoomIn' || updateAction_1['action'] === 'ZoomOut' || updateAction_1['action'] === 'ZoomToFit') {
        this.parent.timelineSettings.timelineViewMode = updateAction_1['previousZoomingLevel'].timelineViewMode;
        this.parent.timelineSettings.timelineUnitSize = updateAction_1['previousZoomingLevel'].timelineUnitSize;
        this.parent.timelineSettings.updateTimescaleView = updateAction_1['previousZoomingLevel'].updateTimescaleView;
        this.parent.timelineSettings.topTier.unit = updateAction_1['previousZoomingLevel'].topTier.unit;
        this.parent.timelineSettings.topTier.count = updateAction_1['previousZoomingLevel'].topTier.count;
        this.parent.timelineSettings.topTier.format = updateAction_1['previousZoomingLevel'].topTier.format;
        this.parent.timelineSettings.bottomTier.unit = updateAction_1['previousZoomingLevel'].bottomTier.unit;
        this.parent.timelineSettings.bottomTier.count = updateAction_1['previousZoomingLevel'].bottomTier.count;
        this.parent.timelineSettings.bottomTier.format = updateAction_1['previousZoomingLevel'].bottomTier.format;
        this.parent.timelineSettings.weekStartDay = updateAction_1['previousZoomingLevel'].weekStartDay;
        this.parent.timelineSettings.weekendBackground = updateAction_1['previousZoomingLevel'].weekendBackground;
        if (updateAction_1['action'] === 'ZoomToFit') {
          this.parent.timelineModule.refreshTimeline();
        }
      } else if (updateAction_1['action'] === 'NextTimeSpan' || updateAction_1['action'] === 'PreviousTimeSpan') {
        this.parent.updateProjectDates(updateAction_1['previousTimelineStartDate'], updateAction_1['previousTimelineEndDate'], false);
      } else if (updateAction_1['action'] === 'Sorting') {
        this.isFromUndoRedo = true;
        this.sortedColumnsLength = 0;
        if (updateAction_1['sortColumns'].length > 0) {
          for (var i = 0; i < updateAction_1['sortColumns'].length; i++) {
            this.parent.treeGrid.sortByColumn(updateAction_1['sortColumns'][i]['field'], updateAction_1['sortColumns'][i]['direction']);
          }
        } else {
          this.parent.clearSorting();
        }
      } else if (updateAction_1['action'] === 'Filtering') {
        this.isFromUndoRedo = true;
        for (var i = this.getUndoCollection.length - 1; i >= 0; i--) {
          if (this.getUndoCollection[i]['filteredColumns']) {
            var columnsArray = [];
            for (var j = 0; j < this.getUndoCollection[i]['filteredColumns'].length; j++) {
              columnsArray.push(this.getUndoCollection[i]['filteredColumns'][j].field);
            }
            this.parent.clearFiltering(columnsArray);
          }
        }
      } else if (updateAction_1['action'] === 'ColumnReorder') {
        this.isFromUndoRedo = true;
        var fromColumn = this.parent.treeGrid.columns[updateAction_1['fromIndex']]['field'];
        var toColumn = this.parent.treeGrid.columns[updateAction_1['toIndex']]['field'];
        this.parent.reorderColumns(fromColumn, toColumn);
      } else if (updateAction_1['action'] === 'Search') {
        this.isFromUndoRedo = true;
        this.parent.search(updateAction_1['searchString']);
      } else if (updateAction_1['action'] === 'ColumnState') {
        this.isFromUndoRedo = true;
        for (var i = 0; i < updateAction_1['showhideColumns'].length; i++) {
          if (updateAction_1['showhideColumns'][i].visible) {
            this.parent.hideColumn(updateAction_1['showhideColumns'][i].field, 'field');
          } else {
            this.parent.showColumn(updateAction_1['showhideColumns'][i].field, 'field');
          }
        }
      } else if (updateAction_1['action'] === 'ColumnResize') {
        this.parent.treeGrid.columns[updateAction_1['resizedColumn'].index]['width'] = updateAction_1['resizedColumn'].width;
        this.parent.treeGrid.refreshColumns();
      } else if (updateAction_1['action'] === 'RowDragAndDrop' || updateAction_1['action'] === 'TaskbarDragAndDrop') {
        for (var i = 0; i < updateAction_1['beforeDrop'].length; i++) {
          var fromIndex = void 0;
          var toIndex = void 0;
          if (this.parent.viewType === 'ProjectView') {
            fromIndex = this.parent.ids.indexOf(updateAction_1['beforeDrop'][i].data.ganttProperties.taskId.toString());
            toIndex = this.parent.ids.indexOf(updateAction_1['beforeDrop'][i]['id'].toString());
          } else {
            fromIndex = this.parent.ids.indexOf('T' + updateAction_1['beforeDrop'][i].data.ganttProperties.taskId.toString());
            toIndex = this.parent.taskIds.indexOf(updateAction_1['beforeDrop'][i]['id'].toString());
          }
          this.parent.reorderRows([fromIndex], toIndex, updateAction_1['beforeDrop'][i].position);
          this.parent.updateRecordByID(updateAction_1['beforeDrop'][i].data);
          if (updateAction_1['afterDrop'].dropPosition === 'child') {
            this.parent.updateRecordByID(updateAction_1['afterDrop'].dropRecord);
          }
        }
      } else if (updateAction_1['action'] === 'Indent' || updateAction_1['action'] === 'Outdent') {
        this.parent.selectRow(this.parent.ids.indexOf(updateAction_1['modifiedRecord'][0].data.ganttProperties.taskId.toString()));
        if (updateAction_1['action'] === 'Indent') {
          this.parent.outdent();
        } else {
          this.parent.indent();
        }
        setTimeout(function () {
          _this.isUndoRedoPerformed = true;
          _this.parent.updateRecordByID(updateAction_1['droppedRecord']);
          _this.isUndoRedoPerformed = false;
        });
      } else if (updateAction_1['action'] === 'Delete') {
        var rowIndex = void 0;
        for (var i = 0; i < updateAction_1['deletedRecordsDetails'].length; i++) {
          if (this.parent.viewType === 'ProjectView') {
            rowIndex = this.parent.ids.indexOf(updateAction_1['deletedRecordsDetails'][i].id.toString());
          } else {
            rowIndex = this.parent.taskIds.indexOf(updateAction_1['deletedRecordsDetails'][i].id.toString());
          }
          var position = void 0;
          if (updateAction_1['deletedRecordsDetails'][i].position === 'above') {
            position = 'Above';
          }
          if (updateAction_1['deletedRecordsDetails'][i].position === 'below') {
            position = 'Below';
          }
          if (updateAction_1['deletedRecordsDetails'][i].position === 'child') {
            position = 'Child';
          }
          if (isNullOrUndefined(position)) {
            position = updateAction_1['deletedRecordsDetails'][i].position.replace(/^./, function (char) {
              return char.toUpperCase();
            });
          }
          this.parent.addRecord(updateAction_1['deletedRecordsDetails'][i].data, position, rowIndex);
        }
      } else if (updateAction_1['action'] === 'Add') {
        var isShowDeleteConfirmDialog = extend([], [this.parent.editSettings.showDeleteConfirmDialog], [], true)[0];
        this.parent.editSettings.showDeleteConfirmDialog = false;
        var deleteRec = updateAction_1['addedRecords'];
        if (this.parent.viewType === 'ResourceView' && updateAction_1['addedRecords'].length === 1 && updateAction_1['addedRecords'][0].parentItem) {
          var parentRec = this.parent.getTaskByUniqueID(updateAction_1['addedRecords'][0].parentItem.uniqueID);
          if (parentRec.childRecords.length === 1 && parentRec.ganttProperties.taskName === 'Unassigned Task') {
            deleteRec = parentRec;
          }
        }
        this.parent.deleteRecord(deleteRec);
        this.parent.editSettings.showDeleteConfirmDialog = isShowDeleteConfirmDialog;
      } else {
        this.parent.updateRecordByID(this.getUndoCollection[this.getUndoCollection.length - 1]['modifiedRecords'][0]);
        if (updateAction_1['connectedRecords'] && this.parent.viewType === 'ProjectView') {
          for (var i = 0; i < updateAction_1['connectedRecords'].length; i++) {
            this.parent.updateRecordByID(updateAction_1['connectedRecords'][i]);
          }
        }
      }
      var args = {};
      args = extend([], [], [this.getUndoCollection[this.getUndoCollection.length - 1]], true)[0];
      args['requestType'] = 'afterUndoAction';
      this.parent.trigger('onAfterUndo', args);
      this.isUndoRedoPerformed = false;
      if (this.getRedoCollection.length > 0) {
        if (this.parent.toolbarModule) {
          this.parent.toolbarModule.enableItems([this.parent.controlId + '_redo'], true);
        }
        this.redoEnabled = true;
      }
      this.getUndoCollection.splice(this.getUndoCollection.length - 1, 1);
      if (this.getUndoCollection.length === 0 && this.parent.toolbarModule) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], false);
      }
    } else {
      this.getUndoCollection.splice(this.getUndoCollection.length - 1, 1);
      if (this.getUndoCollection.length === 0 && this.parent.toolbarModule) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], false);
      }
    }
  };
  /**
   *Initiates an redo action to reapply the most recent undone change performed.
   *
   * @returns {void}
   * @public
   */
  UndoRedo.prototype.redoAction = function () {
    if (this.getRedoCollection.length > 0) {
      var updateAction = this.getRedoCollection[this.getRedoCollection.length - 1];
      var previousActions = {};
      if (updateAction['action'] === 'ZoomIn' || updateAction['action'] === 'ZoomOut' || updateAction['action'] === 'ZoomToFit') {
        previousActions['action'] = updateAction['action'];
        previousActions['previousZoomingLevel'] = extend({}, {}, this.parent.currentZoomingLevel, true);
      } else if (updateAction['action'] === 'NextTimeSpan' || updateAction['action'] === 'PreviousTimeSpan') {
        previousActions['action'] = updateAction['action'];
        previousActions['previousTimelineStartDate'] = extend([], [this.parent.timelineModule.timelineStartDate], [], true)[0];
        previousActions['previousTimelineEndDate'] = extend([], [this.parent.timelineModule.timelineEndDate], [], true)[0];
      } else if (updateAction['action'] === 'Sorting') {
        previousActions['action'] = 'Sorting';
        previousActions['sortColumns'] = extend([], this.parent.sortSettings.columns, [], true);
      } else if (updateAction['action'] === 'Filtering') {
        previousActions['action'] = 'Filtering';
        previousActions['filteredColumns'] = updateAction['filteredColumns'];
      } else if (updateAction['action'] === 'ColumnReorder') {
        previousActions['action'] = updateAction['action'];
        previousActions['toColumn'] = updateAction['fromColumn'];
        previousActions['fromColumn'] = updateAction['toColumn'];
        previousActions['fromIndex'] = updateAction['toIndex'];
        previousActions['toIndex'] = updateAction['fromIndex'];
      } else if (updateAction['action'] === 'Search') {
        previousActions['action'] = updateAction['action'];
        previousActions['searchString'] = extend([], [this.parent.searchSettings.key], [], true)[0];
      } else if (updateAction['action'] === 'ColumnState') {
        previousActions['action'] = updateAction['action'];
        previousActions['showhideColumns'] = updateAction['showhideColumns'];
      } else if (updateAction['action'] === 'ColumnResize') {
        previousActions['action'] = updateAction['action'];
        previousActions['resizedColumn'] = __assign$6({}, this.parent.treeGrid.columns[updateAction['resizedColumn'].index]);
      } else if (updateAction['action'] === 'RowDragAndDrop' || updateAction['action'] === 'TaskbarDragAndDrop') {
        var rowItems = [];
        for (var i = 0; i < updateAction['beforeDrop'].length; i++) {
          if (this.parent.viewType === 'ProjectView') {
            rowItems.push(this.parent.getRecordByID(updateAction['beforeDrop'][i]['data'].ganttProperties.taskId));
          } else {
            rowItems.push(this.parent.flatData[this.parent.taskIds.indexOf('T' + updateAction['beforeDrop'][i]['data'].ganttProperties.taskId.toString())]);
          }
        }
        previousActions['action'] = updateAction['action'];
        previousActions['beforeDrop'] = [];
        var previousDetails = {};
        var dropRecord = void 0;
        if (updateAction['afterDrop'].dropRecord) {
          dropRecord = extend([], [], [this.parent.getRecordByID(updateAction['afterDrop'].dropRecord.ganttProperties.taskId)], true)[0];
        }
        previousDetails['data'] = [];
        for (var i = 0; i < updateAction['afterDrop'].data.length; i++) {
          if (this.parent.viewType === 'ProjectView') {
            previousDetails['data'].push(extend([], [], [this.parent.getRecordByID(updateAction['afterDrop'].data[i].ganttProperties.taskId)], true)[0]);
          } else {
            if (updateAction['afterDrop'].data[i] !== undefined) {
              previousDetails['data'].push(extend([], [], this.parent.flatData[this.parent.taskIds.indexOf('T' + updateAction['afterDrop'].data[i].ganttProperties.taskId)], true)[0]);
            }
          }
        }
        previousDetails['dropRecord'] = extend([], [], [dropRecord], true)[0];
        previousDetails['dropPosition'] = updateAction['afterDrop'].dropPosition;
        previousActions['afterDrop'] = previousDetails;
        this['findPosition'](rowItems, previousActions, 'beforeDrop');
      } else if (updateAction['action'] === 'Indent' || updateAction['action'] === 'Outdent') {
        previousActions['selectedRowIndexes'] = updateAction['selectedRowIndexes'];
        previousActions['droppedRecord'] = extend([], [], [this.parent.flatData[this.parent.ids.indexOf(updateAction['droppedRecord'].ganttProperties.taskId.toString())]], true)[0];
        previousActions['modifiedRecord'] = [];
        this.findPosition(extend([], [], [this.parent.flatData[this.parent.ids.indexOf(updateAction['modifiedRecord'][0].data.ganttProperties.taskId.toString())]], true), previousActions, 'modifiedRecord');
        previousActions['action'] = updateAction['action'];
      } else if (updateAction['action'] === 'Delete') {
        previousActions['action'] = 'Delete';
        previousActions['deletedRecordsDetails'] = [];
        this['findPosition'](extend([], [], updateAction['deleteRecords'], true), previousActions, 'deletedRecordsDetails');
      } else if (updateAction['action'] === 'Add') {
        previousActions['action'] = 'Add';
        previousActions['addedRecords'] = [updateAction['deletedRecordsDetails'][0].data];
      } else {
        previousActions['action'] = updateAction['action'];
        previousActions['modifiedRecords'] = [];
        for (var i = 0; i < updateAction['modifiedRecords'].length; i++) {
          var index = void 0;
          if (this.parent.viewType === 'ProjectView') {
            index = this.parent.ids.indexOf(updateAction['modifiedRecords'][i].ganttProperties.taskId.toString());
          } else {
            if (updateAction['modifiedRecords'][i].hasChildRecords) {
              index = this.parent.taskIds.indexOf('R' + updateAction['modifiedRecords'][i].ganttProperties.taskId);
            } else {
              index = this.parent.taskIds.indexOf('T' + updateAction['modifiedRecords'][i].ganttProperties.taskId);
            }
          }
          previousActions['modifiedRecords'].push(extend([], [this.parent.flatData[index]], [], true)[0]);
        }
      }
      this.getUndoCollection.push(previousActions);
      this.isUndoRedoPerformed = true;
      this.currentAction = updateAction;
      if (updateAction['action'] === 'ZoomIn' || updateAction['action'] === 'ZoomOut' || updateAction['action'] === 'ZoomToFit') {
        if (updateAction['action'] === 'ZoomToFit') {
          this.parent.fitToProject();
        } else {
          this.parent.timelineSettings.timelineViewMode = updateAction['previousZoomingLevel'].timelineViewMode;
          this.parent.timelineSettings.timelineUnitSize = updateAction['previousZoomingLevel'].timelineUnitSize;
          this.parent.timelineSettings.updateTimescaleView = updateAction['previousZoomingLevel'].updateTimescaleView;
          this.parent.timelineSettings.topTier.unit = updateAction['previousZoomingLevel'].topTier.unit;
          this.parent.timelineSettings.topTier.count = updateAction['previousZoomingLevel'].topTier.count;
          this.parent.timelineSettings.topTier.format = updateAction['previousZoomingLevel'].topTier.format;
          this.parent.timelineSettings.bottomTier.unit = updateAction['previousZoomingLevel'].bottomTier.unit;
          this.parent.timelineSettings.bottomTier.count = updateAction['previousZoomingLevel'].bottomTier.count;
          this.parent.timelineSettings.bottomTier.format = updateAction['previousZoomingLevel'].bottomTier.format;
          this.parent.timelineSettings.weekStartDay = updateAction['previousZoomingLevel'].weekStartDay;
          this.parent.timelineSettings.weekendBackground = updateAction['previousZoomingLevel'].weekendBackground;
        }
      } else if (updateAction['action'] === 'NextTimeSpan' || updateAction['action'] === 'PreviousTimeSpan') {
        this.parent.updateProjectDates(updateAction['previousTimelineStartDate'], updateAction['previousTimelineEndDate'], false);
      } else if (updateAction['action'] === 'Sorting') {
        this.isFromUndoRedo = true;
        this.sortedColumnsLength = 0;
        if (updateAction['sortColumns'].length === 0) {
          this.parent.clearSorting();
        } else {
          for (var i = 0; i < updateAction['sortColumns'].length; i++) {
            this.parent.treeGrid.sortByColumn(updateAction['sortColumns'][i]['field'], updateAction['sortColumns'][i]['direction'], updateAction['sortColumns'].length > 1 ? true : false);
          }
        }
      } else if (updateAction['action'] === 'ColumnState') {
        this.isFromUndoRedo = true;
        for (var i = 0; i < updateAction['showhideColumns'].length; i++) {
          if (updateAction['showhideColumns'][i].visible) {
            this.parent.hideColumn(updateAction['showhideColumns'][i].field, 'field');
          } else {
            this.parent.showColumn(updateAction['showhideColumns'][i].field, 'field');
          }
        }
      } else if (updateAction['action'] === 'Filtering') {
        for (var j = 0; j < updateAction['filteredColumns'].length; j++) {
          this.isFromUndoRedo = true;
          this.parent.filterByColumn(updateAction['filteredColumns'][j].field, updateAction['filteredColumns'][j].operator, updateAction['filteredColumns'][j].value, updateAction['filteredColumns'][j].predicate, updateAction['filteredColumns'][j].matchCase, updateAction['filteredColumns'][j].ignoreAccent);
        }
      } else if (updateAction['action'] === 'ColumnReorder') {
        this.isFromUndoRedo = true;
        var fromColumn = this.parent.treeGrid.columns[updateAction['fromIndex']]['field'];
        var toColumn = this.parent.treeGrid.columns[updateAction['toIndex']]['field'];
        this.parent.reorderColumns(fromColumn, toColumn);
      } else if (updateAction['action'] === 'Search') {
        this.isFromUndoRedo = true;
        this.parent.search(updateAction['searchString']);
      } else if (updateAction['action'] === 'ColumnResize') {
        this.parent.treeGrid.columns[updateAction['resizedColumn'].index]['width'] = updateAction['resizedColumn'].width;
        this.parent.treeGrid.refreshColumns();
      } else if (updateAction['action'] === 'RowDragAndDrop' || updateAction['action'] === 'TaskbarDragAndDrop') {
        for (var i = 0; i < updateAction['beforeDrop'].length; i++) {
          var fromIndex = void 0;
          var toIndex = void 0;
          if (this.parent.viewType === 'ProjectView') {
            fromIndex = this.parent.ids.indexOf(updateAction['beforeDrop'][i].data.ganttProperties.taskId.toString());
            toIndex = this.parent.ids.indexOf(updateAction['beforeDrop'][i]['id'].toString());
          } else {
            fromIndex = this.parent.ids.indexOf('T' + updateAction['beforeDrop'][i].data.ganttProperties.taskId.toString());
            toIndex = this.parent.taskIds.indexOf(updateAction['beforeDrop'][i]['id'].toString());
          }
          this.parent.reorderRows([fromIndex], toIndex, updateAction['beforeDrop'][i].position);
        }
      } else if (updateAction['action'] === 'Indent' || updateAction['action'] === 'Outdent') {
        this.parent.selectRow(updateAction['selectedRowIndexes'][0]);
        if (updateAction['action'] === 'Indent') {
          this.parent.indent();
        }
        if (updateAction['action'] === 'Outdent') {
          this.parent.outdent();
        }
      } else if (updateAction['action'] === 'Delete') {
        var isShowDeleteConfirmDialog = extend([], [this.parent.editSettings.showDeleteConfirmDialog], [], true)[0];
        this.parent.editSettings.showDeleteConfirmDialog = false;
        this.parent.deleteRecord(updateAction['deleteRecords']);
        this.parent.editSettings.showDeleteConfirmDialog = isShowDeleteConfirmDialog;
      } else if (updateAction['action'] === 'Add') {
        if (this.parent.viewType === 'ResourceView' && updateAction['deletedRecordsDetails'].length > 1) {
          this.parent.editModule.addRecord(extend([], [], updateAction['deletedRecordsDetails'], true));
        } else {
          var rowIndex = void 0;
          for (var i = 0; i < updateAction['deletedRecordsDetails'].length; i++) {
            if (this.parent.viewType === 'ProjectView') {
              rowIndex = this.parent.ids.indexOf(updateAction['deletedRecordsDetails'][i].id.toString());
            } else {
              rowIndex = this.parent.taskIds.indexOf(updateAction['deletedRecordsDetails'][i].id.toString());
            }
            var position = void 0;
            if (updateAction['deletedRecordsDetails'][i].position === 'above') {
              position = 'Above';
            }
            if (updateAction['deletedRecordsDetails'][i].position === 'below') {
              position = 'Below';
            }
            if (updateAction['deletedRecordsDetails'][i].position === 'child') {
              position = 'Child';
            }
            if (updateAction['deletedRecordsDetails'][i].position === 'bottom') {
              position = 'Bottom';
            }
            this.parent.editModule.addRecord(updateAction['deletedRecordsDetails'][i].data, position, rowIndex);
          }
        }
      } else {
        this.parent.updateRecordByID(updateAction['modifiedRecords'][0]);
      }
      this.isUndoRedoPerformed = false;
      if (this.getUndoCollection.length > 0 && this.parent.toolbarModule) {
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true);
      }
      this.getRedoCollection.splice(this.getRedoCollection.length - 1, 1);
      if (this.getRedoCollection.length === 0 && this.parent.toolbarModule) {
        this.redoEnabled = false;
        this.parent.toolbarModule.enableItems([this.parent.controlId + '_redo'], false);
      }
    }
  };
  UndoRedo.prototype.createUndoCollection = function () {
    if (this.parent['totalUndoAction'] + 1 > this.parent.undoRedoStepsCount && this.getUndoCollection.length === this.parent['totalUndoAction']) {
      this.getUndoCollection.splice(0, 1);
      this.disableRedo();
    }
    if (this.parent.toolbarModule) {
      this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true);
    }
    if (this.getUndoCollection.length === 0) {
      this.getUndoCollection[0] = [];
      if (this.parent['totalUndoAction'] + 1 <= this.parent.undoRedoStepsCount) {
        this.parent['totalUndoAction']++;
      }
    } else if (Object.keys(this['getUndoCollection'][this['getUndoCollection'].length - 1])['length'] > 0) {
      this.getUndoCollection[this.getUndoCollection.length] = [];
      if (this.parent['totalUndoAction'] + 1 <= this.parent.undoRedoStepsCount) {
        this.parent['totalUndoAction']++;
      }
    }
  };
  UndoRedo.prototype.disableRedo = function () {
    this.redoEnabled = false;
    this.getRedoCollection = [];
    if (this.parent.toolbarModule) {
      this.parent.toolbarModule.enableItems([this.parent.controlId + '_redo'], false);
    }
  };
  UndoRedo.prototype.findPosition = function (rowItems, records, detail) {
    var _loop_1 = function (i) {
      var record = {};
      record['data'] = rowItems[i];
      if (this_1.parent.viewType === 'ProjectView') {
        if (!rowItems[i].hasChildRecords && rowItems[i].parentItem) {
          var parentItem = this_1.parent.getRecordByID(rowItems[i].parentItem.taskId);
          if (parentItem.childRecords.length > 1) {
            var recIndex = this_1.parent.ids.indexOf(rowItems[i].ganttProperties.taskId.toString());
            var previousRecord = this_1.parent.flatData[recIndex - 1];
            if (previousRecord.parentItem && previousRecord.parentItem.taskId.toString() === parentItem.ganttProperties.taskId.toString()) {
              record['position'] = 'below';
              record['id'] = extend([], [this_1.parent.flatData[this_1.parent.ids.indexOf(rowItems[i].ganttProperties.taskId.toString()) - 1].ganttProperties.taskId], [], true)[0];
            } else {
              record['position'] = 'above';
              var currentData = this_1.parent.flatData[this_1.parent.ids.indexOf(rowItems[i].ganttProperties.taskId.toString()) + 1];
              var index = this_1.parent.ids.indexOf(rowItems[i].ganttProperties.taskId.toString()) + 1;
              var rowIndex = i;
              do {
                var rowData = rowItems[rowIndex + 1];
                if (!rowData || rowData && currentData.ganttProperties.taskId.toString() !== rowData.ganttProperties.taskId.toString()) {
                  if (currentData && currentData.parentItem) {
                    record['id'] = extend([], [currentData.ganttProperties.taskId], [], true)[0];
                  } else {
                    record['id'] = extend([], [rowItems[i].parentItem.taskId], [], true)[0];
                    record['position'] = 'child';
                  }
                } else {
                  rowIndex++;
                  index++;
                  currentData = this_1.parent.flatData[index];
                }
              } while (!record['id']);
            }
          } else {
            record['position'] = 'child';
            record['id'] = extend([], [parentItem.ganttProperties.taskId], [], true)[0];
          }
        } else if (!rowItems[i].hasChildRecords && !rowItems[i].parentItem) {
          if (this_1.parent.ids.indexOf(rowItems[i].ganttProperties.taskId.toString()) === this_1.parent.ids.length - 1) {
            record['position'] = 'below';
          } else {
            record['position'] = 'above';
          }
          var parentIndex = void 0;
          var currentData = void 0;
          var prevInd = void 0;
          for (var k = 0; k < this_1.parent.treeGrid.parentData.length; k++) {
            if (this_1.parent.treeGrid.parentData[k]['ganttProperties'].taskId.toString() === rowItems[i].ganttProperties.taskId.toString()) {
              parentIndex = k;
              currentData = this_1.parent.treeGrid.parentData[k + 1];
              prevInd = k + 1;
              break;
            }
          }
          var rowIndex = i;
          do {
            var rowData = rowItems[rowIndex + 1];
            if (!rowData || rowData && currentData['ganttProperties'].taskId.toString() !== rowData.ganttProperties.taskId.toString()) {
              if (currentData) {
                record['id'] = extend([], [currentData['ganttProperties'].taskId], [], true)[0];
              } else {
                currentData = this_1.parent.treeGrid.parentData[parentIndex - 1];
                if (!currentData) {
                  currentData = this_1.parent.treeGrid.parentData[parentIndex];
                }
                record['id'] = extend([], [currentData['ganttProperties'].taskId], [], true)[0];
              }
            } else {
              rowIndex++;
              prevInd++;
              currentData = this_1.parent.treeGrid.parentData[prevInd];
            }
          } while (!record['id']);
        } else if (rowItems[i].hasChildRecords && !rowItems[i].parentItem) {
          var parentIndex = void 0;
          var currentData = void 0;
          var prevInd = void 0;
          for (var k = 0; k < this_1.parent.treeGrid.parentData.length; k++) {
            if (this_1.parent.treeGrid.parentData[k]['ganttProperties'].taskId.toString() === rowItems[i].ganttProperties.taskId.toString()) {
              parentIndex = k;
              currentData = this_1.parent.treeGrid.parentData[k + 1];
              prevInd = k + 1;
              break;
            }
          }
          if (parentIndex !== -1) {
            if (parentIndex === 0) {
              record['position'] = 'above';
              var rowIndex = i;
              do {
                var rowData = rowItems[rowIndex + 1];
                if (!rowData || rowData && currentData['ganttProperties'].taskId.toString() !== rowData.ganttProperties.taskId.toString()) {
                  if (!currentData) {
                    currentData = this_1.parent.treeGrid.parentData[parentIndex];
                  }
                  record['id'] = extend([], [currentData['ganttProperties'].taskId], [], true)[0];
                } else {
                  rowIndex++;
                  prevInd++;
                  currentData = this_1.parent.treeGrid.parentData[prevInd];
                }
              } while (!record['id']);
            } else {
              record['position'] = 'below';
              record['id'] = extend([], [this_1.parent.treeGrid.parentData[parentIndex - 1].ganttProperties.taskId], [], true)[0];
            }
          }
        } else if (rowItems[i].hasChildRecords && rowItems[i].parentItem) {
          var parentItem = this_1.parent.getRecordByID(rowItems[i].parentItem.taskId);
          if (parentItem.childRecords.length === 1) {
            record['position'] = 'child';
            record['id'] = extend([], [rowItems[i].parentItem.taskId], [], true)[0];
          } else {
            var childIndex = void 0;
            for (var k = 0; k < parentItem.childRecords.length; k++) {
              if (parentItem.childRecords[i].ganttProperties.taskId.toString() === rowItems[i].ganttProperties.taskId.toString()) {
                childIndex = k;
                break;
              }
            }
            if (!isNullOrUndefined(childIndex)) {
              if (childIndex === 0) {
                record['position'] = 'above';
                record['id'] = extend([], [parentItem.childRecords[childIndex + 1].ganttProperties.taskId], [], true)[0];
              } else {
                record['position'] = 'below';
                record['id'] = extend([], [parentItem.childRecords[childIndex - 1].ganttProperties.taskId], [], true)[0];
              }
            }
          }
        }
      } else {
        if (rowItems[i].parentItem) {
          var parentRecord = void 0;
          if (rowItems[i].parentItem.index) {
            parentRecord = this_1.parent.flatData[rowItems[i].parentItem.index];
          } else {
            parentRecord = this_1.parent.flatData[this_1.parent.ids.indexOf(rowItems[i].parentItem.taskId)];
          }
          if (parentRecord.childRecords.length === 1) {
            record['position'] = 'bottom';
            record['id'] = 'R' + parentRecord.ganttProperties.taskId;
            if (detail === 'deletedIndexes') {
              record['position'] = 'child';
              record['index'] = parentRecord.index;
            }
          } else {
            var currentIndex = void 0;
            for (var j = 0; j < parentRecord.childRecords.length; j++) {
              if (parentRecord.childRecords[j].ganttProperties.taskId.toString() === rowItems[i].ganttProperties.taskId.toString()) {
                currentIndex = j;
                break;
              }
            }
            var previousRecord = parentRecord.childRecords[currentIndex - 1];
            if (previousRecord && previousRecord.parentItem && previousRecord.parentUniqueID === parentRecord.uniqueID) {
              record['position'] = 'below';
              record['id'] = extend([], ['T' + this_1.parent.flatData[this_1.parent.taskIds.indexOf('T' + rowItems[i].ganttProperties.taskId.toString()) - 1].ganttProperties.taskId], [], true)[0];
              if (detail === 'deletedIndexes') {
                record['index'] = this_1.parent.taskIds.indexOf('T' + rowItems[i].ganttProperties.taskId.toString()) - 1;
              }
            } else {
              var index = void 0;
              if (currentIndex !== parentRecord.childRecords.length - 1) {
                record['position'] = 'above';
                if (currentIndex === 0 && parentRecord.childRecords[1]) {
                  index = parentRecord.childRecords[1].index;
                } else if (parentRecord.childRecords[currentIndex - 1]) {
                  index = parentRecord.childRecords[currentIndex - 1].index;
                }
                var currentData = this_1.parent.flatData[index];
                var recIndex = index;
                var rowIndex = i;
                do {
                  var rowData = rowItems[rowIndex + 1];
                  if (!rowData || rowData && currentData.ganttProperties.taskId.toString() !== rowData.ganttProperties.taskId.toString()) {
                    if (currentData.parentItem) {
                      record['id'] = extend([], ['T' + currentData.ganttProperties.taskId], [], true)[0];
                      if (detail === 'deletedIndexes') {
                        record['index'] = extend([], [currentData.index], [], true)[0];
                        if (currentIndex === 0 && parentRecord.childRecords[1].ganttProperties.taskId === currentData.ganttProperties.taskId) {
                          record['index'] = record['index'] - 1;
                        }
                      }
                    } else {
                      record['id'] = extend([], ['R' + rowItems[i].parentItem.taskId], [], true)[0];
                      record['position'] = 'child';
                      if (detail === 'deletedIndexes') {
                        record['index'] = rowItems[i].parentItem.index;
                      }
                    }
                  } else {
                    rowIndex++;
                    recIndex++;
                    currentData = this_1.parent.flatData[recIndex];
                  }
                } while (!record['id']);
              } else {
                if (currentIndex === parentRecord.childRecords.length - 1) {
                  record['position'] = 'below';
                } else {
                  record['position'] = 'above';
                }
                record['id'] = 'T' + parentRecord.childRecords[currentIndex - 1].ganttProperties.taskId;
                if (detail === 'deletedIndexes') {
                  record['index'] = parentRecord.childRecords[currentIndex - 1].index;
                }
              }
            }
          }
        } else {
          var parentData = this_1.parent.treeGrid.parentData.filter(function (parentData) {
            return parentData.ganttProperties.taskId === rowItems[i].ganttProperties.taskId;
          })[0];
          var parentDataIndex = this_1.parent.treeGrid.parentData.indexOf(parentData);
          if (parentDataIndex === 0) {
            record['position'] = 'above';
            var currentData = void 0;
            var prevInd = void 0;
            for (var k = 0; k < this_1.parent.treeGrid.parentData.length; k++) {
              if (this_1.parent.treeGrid.parentData[k]['ganttProperties'].taskId === rowItems[i].ganttProperties.taskId) {
                currentData = this_1.parent.treeGrid.parentData[k + 1];
                prevInd = k + 1;
                break;
              }
            }
            var rowIndex = i;
            do {
              var rowData = rowItems[rowIndex + 1];
              if (!rowData || rowData && currentData['ganttProperties'].taskId !== rowData.ganttProperties.taskId) {
                record['id'] = 'R' + currentData['ganttProperties'].taskId;
                if (detail === 'deletedIndexes') {
                  record['index'] = currentData['index'];
                }
              } else {
                rowIndex++;
                prevInd++;
                currentData = this_1.parent.treeGrid.parentData[prevInd];
              }
            } while (!record['id']);
          } else {
            record['position'] = 'below';
            record['id'] = 'R' + this_1.parent.treeGrid.parentData[parentDataIndex - 1]['ganttProperties']['taskId'];
            if (detail === 'deletedIndexes') {
              record['index'] = this_1.parent.treeGrid.parentData[parentDataIndex - 1]['index'];
            }
          }
        }
      }
      if (detail === 'deletedIndexes') {
        var parent_1 = this_1.parent.getTaskByUniqueID(record['data'].parentUniqueID);
        if (this_1.parent.editModule.dialogModule.ganttResources.length === 0 && records[detail].indexOf(record) === -1) {
          records[detail].push(record);
        } else {
          for (var j = 0; j < this_1.parent.editModule.dialogModule.ganttResources.length; j++) {
            if (this_1.parent.editModule.dialogModule.ganttResources[j][this_1.parent.resourceFields.id] !== parent_1.ganttProperties.taskId && records[detail].indexOf(record) === -1) {
              records[detail].push(record);
            }
          }
        }
      } else {
        if (!records[detail].some(function (existingRecord) {
          return existingRecord.data.ganttProperties.taskId === record['data'].ganttProperties.taskId;
        })) {
          records[detail].push(record);
        }
      }
    };
    var this_1 = this;
    for (var i = 0; i < rowItems.length; i++) {
      _loop_1(i);
    }
  };
  UndoRedo.prototype.getModuleName = function () {
    return 'undoRedo';
  };
  /**
   * Destroys the UndoRedo of Gantt.
   *
   * @returns {void} .
   * @private
   */
  UndoRedo.prototype.destroy = function () {
    if (!this.parent.enableUndoRedo && this.parent.undoRedoModule) {
      this.parent.undoRedoModule = undefined;
    }
  };
  return UndoRedo;
}();

// eslint-disable-next-line
/**
 * The ContextMenu module is used to handle the context menu items & sub-menu items.
 *
 * @returns {void} .
 */
var ContextMenu = /** @__PURE__ @class */function () {
  function ContextMenu(parent) {
    var _this = this;
    this.segmentIndex = -1;
    this.isCntxtMenuDependencyDelete = false;
    this.headerContextMenuClick = function (args) {
      var gridRow = closest(args.event.target, '.e-row');
      var chartRow = closest(args.event.target, '.e-chart-row');
      if (isNullOrUndefined(gridRow) && isNullOrUndefined(chartRow)) {
        args.type = 'Header';
        _this.parent.trigger('contextMenuClick', args);
      }
    };
    this.headerContextMenuOpen = function (args) {
      var gridRow = closest(args.event.target, '.e-row');
      var chartRow = closest(args.event.target, '.e-chart-row');
      if (isNullOrUndefined(gridRow) && isNullOrUndefined(chartRow)) {
        args.type = 'Header';
        _this.parent.trigger('contextMenuOpen', args);
      } else {
        args.cancel = true;
      }
    };
    this.parent = parent;
    this.ganttID = parent.element.id;
    TreeGrid.Inject(ContextMenu$3);
    this.parent.treeGrid.contextMenuClick = this.headerContextMenuClick.bind(this);
    this.parent.treeGrid.contextMenuOpen = this.headerContextMenuOpen.bind(this);
    this.addEventListener();
    this.resetItems();
  }
  ContextMenu.prototype.addEventListener = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.on('initiate-contextMenu', this.render, this);
    this.parent.on('reRender-contextMenu', this.reRenderContextMenu, this);
    this.parent.on('contextMenuClick', this.contextMenuItemClick, this);
    this.parent.on('contextMenuOpen', this.contextMenuBeforeOpen, this);
  };
  ContextMenu.prototype.reRenderContextMenu = function (e) {
    if (e.module === this.getModuleName() && e.enable) {
      if (this.contextMenu) {
        this.contextMenu.destroy();
        remove(this.element);
      }
      this.resetItems();
      this.render();
    }
  };
  ContextMenu.prototype.render = function () {
    this.element = this.parent.createElement('ul', {
      id: this.ganttID + '_contextmenu',
      className: focusCell
    });
    this.parent.element.appendChild(this.element);
    var target = '#' + this.ganttID;
    this.contextMenu = new ContextMenu$2({
      items: this.getMenuItems(),
      locale: this.parent.locale,
      enableRtl: this.parent.enableRtl,
      target: target,
      animationSettings: {
        effect: 'None'
      },
      select: this.contextMenuItemClick.bind(this),
      beforeOpen: this.contextMenuBeforeOpen.bind(this),
      onOpen: this.contextMenuOpen.bind(this),
      onClose: this.contextMenuOnClose.bind(this),
      cssClass: 'e-gantt'
    });
    this.contextMenu.appendTo(this.element);
    this.parent.treeGrid.contextMenuItems = this.headerMenuItems;
  };
  ContextMenu.prototype.contextMenuItemClick = function (args) {
    this.item = this.getKeyFromId(args.item.id);
    var position;
    var data;
    var taskfields;
    var parentItem = getValue('parentObj', args.item);
    var index = -1;
    args.type = 'Content';
    args.rowData = this.rowData;
    this.parent.trigger('contextMenuClick', args);
    if (parentItem && !isNullOrUndefined(parentItem.id) && this.getKeyFromId(parentItem.id) === 'DeleteDependency') {
      index = parentItem.items.indexOf(args.item);
    }
    if (this.parent.isAdaptive) {
      if (this.item === 'TaskInformation' || this.item === 'Above' || this.item === 'Below' || this.item === 'Child' || this.item === 'DeleteTask') {
        if (this.parent.selectionModule && this.parent.selectionSettings.type === 'Multiple') {
          this.parent.selectionModule.hidePopUp();
          document.getElementsByClassName('e-gridpopup')[0].style.display = 'none';
        }
      }
    }
    switch (this.item) {
      case 'TaskInformation':
        if (!isNullOrUndefined(this.rowData)) {
          if (typeof this.rowData.ganttProperties.taskId === 'string') {
            this.parent.openEditDialog(this.rowData.ganttProperties.rowUniqueID);
          } else {
            this.parent.openEditDialog(Number(this.rowData.ganttProperties.rowUniqueID));
          }
        }
        break;
      case 'Above':
      case 'Below':
      case 'Child':
        if (!isNullOrUndefined(this.rowData)) {
          position = this.item;
          data = extend({}, {}, this.rowData.taskData, true);
          taskfields = this.parent.taskFields;
          if (data[taskfields.startDate]) {
            this.parent.setRecordValue(taskfields.startDate, this.rowData.ganttProperties.startDate, data, true);
          }
          if (data[taskfields.endDate]) {
            this.parent.setRecordValue(taskfields.endDate, this.rowData.ganttProperties.endDate, data, true);
          }
          if (!isNullOrUndefined(taskfields.dependency)) {
            data[taskfields.dependency] = null;
          }
          if (!isNullOrUndefined(taskfields.child) && data[taskfields.child]) {
            delete data[taskfields.child];
          }
          if (!isNullOrUndefined(taskfields.parentID) && data[taskfields.parentID]) {
            data[taskfields.parentID] = null;
          }
          if (this.rowData) {
            var rowIndex = this.parent.updatedRecords.indexOf(this.rowData);
            this.parent.addRecord(data, position, rowIndex);
          }
        } else if (this.parent.flatData.length === 0) {
          this.parent.addRecord();
        }
        break;
      case 'Milestone':
      case 'ToMilestone':
        if (!isNullOrUndefined(this.rowData)) {
          this.parent.convertToMilestone(this.rowData.ganttProperties.rowUniqueID);
        } else if (this.parent.flatData.length === 0 && this.item === 'Milestone') {
          var data_1 = this.parent.editModule.createNewRecord();
          var taskSettings = this.parent.taskFields;
          if (this.parent.taskFields['duration']) {
            data_1[taskSettings['duration']] = 0;
          }
          if (this.parent.taskFields['milestone']) {
            data_1[taskSettings['milestone']] = true;
          }
          this.parent.addRecord(data_1);
        }
        break;
      case 'DeleteTask':
        if (this.parent.selectionSettings.mode !== 'Cell' && this.parent.selectionModule.selectedRowIndexes.length > 1 || this.parent.selectionSettings.mode === 'Cell' && this.parent.selectionModule.getSelectedRowCellIndexes().length) {
          this.parent.editModule.startDeleteAction();
        } else {
          this.parent.editModule.deleteRecord(this.rowData);
        }
        break;
      case 'ToTask':
        if (!isNullOrUndefined(this.rowData)) {
          data = extend({}, {}, this.rowData.taskData, true);
          taskfields = this.parent.taskFields;
          if (!isNullOrUndefined(taskfields.duration)) {
            data[taskfields.duration] = parseInt(data[taskfields.duration], 10) <= 0 ? 1 : data[taskfields.duration];
          } else {
            data[taskfields.startDate] = new Date(this.rowData.taskData[taskfields.startDate]);
            var endDate = new Date(this.rowData.taskData[taskfields.startDate]);
            endDate.setDate(endDate.getDate() + 1);
            data[taskfields.endDate] = endDate;
          }
          if (!isNullOrUndefined(data[taskfields.milestone])) {
            if (data[taskfields.milestone] === true) {
              data[taskfields.milestone] = false;
            }
          }
          if (data[taskfields.startDate]) {
            this.parent.setRecordValue(taskfields.startDate, this.rowData.ganttProperties.startDate, data, true);
          }
          this.parent.updateRecordByID(data);
        }
        break;
      case 'Cancel':
        this.parent.cancelEdit();
        break;
      case 'Save':
        this.parent.editModule.cellEditModule.isCellEdit = false;
        this.parent.treeGrid.grid.saveCell();
        break;
      case 'Dependency' + index:
        this.isCntxtMenuDependencyDelete = true;
        this.parent.connectorLineEditModule.removePredecessorByIndex(this.rowData, index);
        break;
      case 'Auto':
      case 'Manual':
        this.parent.changeTaskMode(this.rowData);
        break;
      case 'Indent':
        this.parent.indent();
        break;
      case 'Outdent':
        this.parent.outdent();
        break;
      case 'Left':
      case 'Right':
        this.mergeCall(this.item);
        break;
      case 'SplitTask':
        this.splitTaskCall(args);
        break;
    }
  };
  ContextMenu.prototype.splitTaskCall = function (args) {
    var _this = this;
    this.isEdit = true;
    var taskSettings = this.parent.taskFields;
    var currentClickedDate = this.getClickedDate(args.element);
    if (isNullOrUndefined(this.parent.timelineSettings.bottomTier) && this.parent.timelineSettings.bottomTier.unit !== 'Hour') {
      currentClickedDate.setHours(0, 0, 0, 0);
    }
    var eventArgs = {
      rowData: this.rowData,
      requestType: 'splitTaskbar',
      splitDate: currentClickedDate,
      cancel: false,
      target: this.targetElement
    };
    // eslint-disable-next-line
    this.parent.trigger('actionBegin', eventArgs, function (eventArgs) {
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
      _this.parent.chartRowsModule.splitTask(_this.rowData[taskSettings.id], currentClickedDate);
      _this.parent.chartRowsModule.updateSegment(_this.rowData.ganttProperties.segments, _this.rowData.ganttProperties.taskId);
    });
  };
  ContextMenu.prototype.mergeCall = function (item) {
    var _this = this;
    this.isEdit = true;
    var taskSettings = this.parent.taskFields;
    var segments = this.rowData.ganttProperties.segments;
    var firstSegment = item === 'Right' ? this.segmentIndex : segments[this.segmentIndex - 1].segmentIndex;
    var secondSegment = item === 'Left' ? this.segmentIndex : segments[this.segmentIndex + 1].segmentIndex;
    var segmentIndexes = [{
      'firstSegmentIndex': firstSegment,
      'secondSegmentIndex': secondSegment
    }];
    var eventArgs = {
      rowData: this.rowData,
      mergeSegmentIndexes: segmentIndexes,
      requestType: 'mergeSegment',
      cancel: false,
      target: this.targetElement
    };
    this.parent.trigger('actionBegin', eventArgs, function (eventArgs) {
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
      if (eventArgs.cancel === false) {
        _this.parent.chartRowsModule.mergeTask(_this.rowData[taskSettings.id], segmentIndexes);
      }
    });
  };
  // eslint-disable-next-line
  ContextMenu.prototype.getClickedDate = function (element) {
    // context menu click position
    var ganttElementPositionLeft;
    // task left position
    if (this.parent.enableRtl) {
      var box = this.parent.element.getBoundingClientRect();
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
      var clientLeft = document.documentElement.clientLeft || document.body.clientLeft || 0;
      ganttElementPositionLeft = box.left + scrollLeft - clientLeft;
    } else {
      ganttElementPositionLeft = this.parent.getOffsetRect(this.parent.element).left;
    }
    var pageLeft;
    var currentTaskDifference;
    if (this.parent.enableRtl) {
      pageLeft = Math.abs(ganttElementPositionLeft + this.parent.ganttChartModule.chartElement.offsetWidth - this.rowData.ganttProperties.left - this.parent.ganttChartModule.scrollElement.scrollLeft);
      currentTaskDifference = Math.abs(this.clickedPosition - pageLeft);
    } else {
      pageLeft = ganttElementPositionLeft + this.parent.ganttChartModule.chartElement.offsetLeft + this.rowData.ganttProperties.left - this.parent.ganttChartModule.scrollElement.scrollLeft;
      currentTaskDifference = this.clickedPosition - pageLeft;
    }
    var splitTaskDuration = Math.ceil(currentTaskDifference / this.parent.perDayWidth);
    var startDate = this.rowData.ganttProperties.startDate;
    if (!isNullOrUndefined(this.parent.timelineSettings.bottomTier) && this.parent.timelineSettings.bottomTier.unit === 'Hour') {
      splitTaskDuration = Math.ceil(currentTaskDifference / this.parent.timelineSettings.timelineUnitSize);
      splitTaskDuration -= 1;
    }
    var contextMenuClickDate;
    if (!isNullOrUndefined(this.parent.timelineSettings.bottomTier) && (this.parent.timelineSettings.bottomTier.unit === 'Minutes' || this.parent.timelineSettings.bottomTier.unit === 'Hour')) {
      splitTaskDuration = Math.ceil(currentTaskDifference / this.parent.timelineSettings.timelineUnitSize);
      splitTaskDuration -= 1;
      contextMenuClickDate = this.parent.dataOperation.getEndDate(startDate, splitTaskDuration, this.parent.timelineSettings.bottomTier.unit.toLocaleLowerCase(), this.rowData, false);
    } else {
      contextMenuClickDate = this.parent.dataOperation.getEndDate(startDate, splitTaskDuration, this.rowData.ganttProperties.duration > 1 ? this.rowData.ganttProperties.durationUnit : this.parent.timelineSettings.bottomTier.unit !== 'None' ? this.parent.timelineSettings.bottomTier.unit.toLocaleLowerCase() : this.parent.timelineSettings.topTier.unit.toLocaleLowerCase(), this.rowData, false);
    }
    return contextMenuClickDate;
  };
  ContextMenu.prototype.contextMenuBeforeOpen = function (args) {
    var _this = this;
    var target = args.event ? args.event.target : !this.parent.focusModule ? this.parent.focusModule.getActiveElement() : this.parent.ganttChartModule.targetElement;
    // Closed edited cell before opening context menu
    if (!isNullOrUndefined(this.parent.editModule) && this.parent.editModule.cellEditModule && this.parent.editModule.cellEditModule.isCellEdit && target.parentElement.classList.contains('e-row') || target.parentElement.classList.contains('e-treecolumn-container')) {
      this.parent.treeGrid.closeEdit();
    }
    if (!isNullOrUndefined(args.element) && args.element.id === this.parent.element.id + '_contextmenu') {
      this.clickedPosition = getValue('event', args).clientX;
    }
    var targetElement = closest(target, '.e-gantt-child-taskbar');
    if (targetElement) {
      this.targetElement = args.target = targetElement;
    }
    args.gridRow = closest(target, '.e-row');
    args.chartRow = closest(target, '.e-chart-row');
    var menuElement = closest(target, '.e-gantt');
    var editForm$1 = closest(target, editForm);
    if (!editForm$1 && this.parent.editModule && this.parent.editModule.cellEditModule && this.parent.editModule.cellEditModule.isCellEdit && this.parent.editModule.dialogModule.dialogObj && !this.parent.editModule.dialogModule.dialogObj.open) {
      this.parent.treeGrid.grid.saveCell();
      this.parent.editModule.cellEditModule.isCellEdit = false;
    }
    if (this.parent.readOnly) {
      this.contextMenu.enableItems(['Add', 'Save', 'Convert', 'Delete Dependency', 'Delete Task', 'TaskMode', 'Indent', 'Outdent', 'SplitTask', 'MergeTask'], false);
    }
    if (isNullOrUndefined(args.gridRow) && isNullOrUndefined(args.chartRow) || this.contentMenuItems.length === 0) {
      if (!isNullOrUndefined(args.parentItem) && !isNullOrUndefined(menuElement) || !isNullOrUndefined(closest(target, '.e-content'))) {
        args.cancel = false;
      } else {
        args.cancel = true;
      }
    }
    if (!args.cancel) {
      var rowIndex = -1;
      if (args.gridRow) {
        // eslint-disable-next-line
        rowIndex = parseInt(args.gridRow.getAttribute('data-rowindex'), 0);
      } else if (args.chartRow) {
        // eslint-disable-next-line
        rowIndex = parseInt(args.chartRow.getAttribute('data-rowindex'), 0);
      }
      if (this.parent.selectionModule && this.parent.allowSelection && !args.parentItem && !isNullOrUndefined(args.chartRow)) {
        this.parent.selectionModule.selectRow(rowIndex);
      }
      if (!args.parentItem) {
        this.rowData = this.parent.updatedRecords[parseInt(rowIndex.toString(), 10)];
      }
      for (var _i = 0, _a = args.items; _i < _a.length; _i++) {
        var item = _a[_i];
        // let target: EventTarget = target;
        if (!item.separator) {
          if (target.classList.contains('e-gantt-unscheduled-taskbar') && (item.text === this.getLocale('splitTask') || item.text === this.getLocale('mergeTask'))) {
            this.hideItems.push(item.text);
          } else {
            this.updateItemStatus(item, target, rowIndex);
          }
        }
      }
      args.rowData = this.rowData;
      args.type = 'Content';
      args.disableItems = this.disableItems;
      args.hideItems = this.hideItems;
      args.hideChildItems = [];
      if (!isNullOrUndefined(args.rowData) && args.rowData.level === 0 && this.parent.viewType === 'ResourceView') {
        args.cancel = true;
        return;
      }
      var callBackPromise_1 = new Deferred();
      this.parent.trigger('contextMenuOpen', args, function (arg) {
        callBackPromise_1.resolve(arg);
        _this.hideItems = arg.hideItems;
        _this.disableItems = arg.disableItems;
        if (!arg.parentItem && arg.hideItems.length === arg.items.length) {
          _this.revertItemStatus();
          arg.cancel = true;
        }
        if (_this.hideItems.length > 0) {
          _this.contextMenu.hideItems(_this.hideItems);
        }
        if (_this.disableItems.length > 0) {
          _this.contextMenu.enableItems(_this.disableItems, false);
        }
        if (args.hideChildItems.length > 0) {
          _this.contextMenu.hideItems(args.hideChildItems);
        }
      });
      return callBackPromise_1;
    }
  };
  ContextMenu.prototype.updateItemStatus = function (item, target, rowIndex) {
    var key = this.getKeyFromId(item.id);
    var editForm$1 = closest(target, editForm);
    var subMenu = [];
    var taskbarElement = closest(target, '.e-gantt-child-taskbar') || closest(target, 'e-taskbar-right-resizer') || closest(target, 'e-taskbar-left-resizer');
    if (editForm$1) {
      if (!(key === 'Save' || key === 'Cancel')) {
        this.hideItems.push(item.text);
      }
    } else {
      switch (key) {
        case 'TaskInformation':
          if (!this.parent.editSettings.allowEditing || !this.parent.editModule) {
            this.updateItemVisibility(item.text);
          }
          if (this.parent.flatData.length === 0) {
            this.hideItems.push(item.text);
          }
          break;
        case 'Add':
          if (!this.parent.editSettings.allowAdding || !this.parent.editModule) {
            this.updateItemVisibility(item.text);
          }
          break;
        case 'Save':
        case 'Cancel':
          this.hideItems.push(item.text);
          break;
        case 'Convert':
          if (!isNullOrUndefined(this.rowData) && this.rowData.hasChildRecords) {
            this.hideItems.push(item.text);
          } else if (!this.parent.editSettings.allowEditing || !this.parent.editModule) {
            this.updateItemVisibility(item.text);
          } else {
            if (!isNullOrUndefined(this.rowData) && !this.rowData.ganttProperties.isMilestone) {
              subMenu.push(this.createItemModel(content, 'ToMilestone', this.getLocale('toMilestone')));
            } else {
              subMenu.push(this.createItemModel(content, 'ToTask', this.getLocale('toTask')));
            }
            item.items = subMenu;
          }
          if (this.parent.flatData.length === 0) {
            this.hideItems.push(item.text);
          }
          break;
        case 'DeleteDependency':
          {
            var items = this.getPredecessorsItems();
            if (!isNullOrUndefined(this.rowData) && this.rowData.hasChildRecords && !this.parent.allowParentDependency) {
              this.hideItems.push(item.text);
            } else if (!this.parent.editSettings.allowDeleting || items.length === 0 || !this.parent.editModule) {
              this.updateItemVisibility(item.text);
            } else if (items.length > 0) {
              item.items = items;
            }
            break;
          }
        case 'DeleteTask':
          if (!this.parent.editSettings.allowDeleting || !this.parent.editModule) {
            this.updateItemVisibility(item.text);
          }
          if (this.parent.flatData.length === 0) {
            this.hideItems.push(item.text);
          }
          break;
        case 'TaskMode':
          if (this.parent.taskMode !== 'Custom') {
            this.updateItemVisibility(item.text);
          } else {
            if (this.rowData.ganttProperties.isAutoSchedule) {
              subMenu.push(this.createItemModel(content, 'Manual', this.getLocale('manual')));
            } else {
              subMenu.push(this.createItemModel(content, 'Auto', this.getLocale('auto')));
            }
            item.items = subMenu;
          }
          break;
        case 'Indent':
          {
            if (!this.parent.allowSelection || !this.parent.editModule || !this.parent.editSettings) {
              this.hideItems.push(item.text);
            } else {
              var index = this.parent.selectedRowIndex;
              var isSelected = this.parent.selectionModule ? this.parent.selectionModule.selectedRowIndexes.length === 1 || this.parent.selectionModule.getSelectedRowCellIndexes().length === 1 ? true : false : false;
              var prevRecord = this.parent.updatedRecords[this.parent.selectionModule.getSelectedRowIndexes()[0] - 1];
              if (!this.parent.editSettings.allowEditing || index === 0 || index === -1 || !isSelected || this.parent.viewType === 'ResourceView' || this.parent.updatedRecords[parseInt(index.toString(), 10)].level - prevRecord.level === 1) {
                this.updateItemVisibility(item.text);
              }
            }
            break;
          }
        case 'Outdent':
          {
            if (!this.parent.allowSelection || !this.parent.editModule || !this.parent.editSettings) {
              this.hideItems.push(item.text);
            } else {
              var ind = this.parent.selectionModule.getSelectedRowIndexes()[0];
              var isSelect = this.parent.selectionModule ? this.parent.selectionModule.selectedRowIndexes.length === 1 || this.parent.selectionModule.getSelectedRowCellIndexes().length === 1 ? true : false : false;
              if (!this.parent.editSettings.allowEditing || ind === -1 || ind === 0 || !isSelect || this.parent.viewType === 'ResourceView' || this.parent.updatedRecords[parseInt(ind.toString(), 10)].level === 0) {
                this.updateItemVisibility(item.text);
              }
            }
            break;
          }
        case 'SplitTask':
          {
            var isBottomTierMinute = false;
            var isBottomTierHour = false;
            var taskSettings = this.parent.taskFields;
            if (!isNullOrUndefined(this.parent.timelineSettings.bottomTier)) {
              isBottomTierMinute = this.parent.timelineSettings.bottomTier.unit === 'Minutes';
              isBottomTierHour = this.parent.timelineSettings.bottomTier.unit === 'Hour';
            }
            if (this.parent.readOnly || !taskbarElement || isNullOrUndefined(taskSettings.segments) || this.parent.currentViewData[parseInt(rowIndex.toString(), 10)].hasChildRecords || this.parent.currentViewData[parseInt(rowIndex.toString(), 10)].ganttProperties.duration < 2 && !(isBottomTierMinute || isBottomTierHour)) {
              this.updateItemVisibility(item.text);
            }
            break;
          }
        case 'MergeTask':
          if (this.parent.readOnly || !taskbarElement) {
            this.updateItemVisibility(item.text);
          } else {
            this.mergeItemVisiblity(target, item);
          }
          break;
      }
    }
  };
  ContextMenu.prototype.mergeItemVisiblity = function (target, item) {
    var subMenu = [];
    var taskfields = this.parent.taskFields;
    var currentClickedDate = this.getClickedDate(target);
    this.segmentIndex = this.parent.chartRowsModule.getSegmentIndex(currentClickedDate, this.rowData);
    var segments = this.rowData.ganttProperties.segments;
    if (!isNullOrUndefined(segments) && segments.length > 0) {
      if (isNullOrUndefined(taskfields.segments) && this.segmentIndex === -1) {
        this.updateItemVisibility(item.text);
      } else {
        if (this.segmentIndex === 0) {
          subMenu.push(this.createItemModel(content, 'Right', this.getLocale('right')));
        } else if (this.segmentIndex === segments.length - 1) {
          subMenu.push(this.createItemModel(content, 'Left', this.getLocale('left')));
        } else {
          subMenu.push(this.createItemModel(content, 'Right', this.getLocale('right')));
          subMenu.push(this.createItemModel(content, 'Left', this.getLocale('left')));
        }
        item.items = subMenu;
      }
    } else {
      this.hideItems.push(item.text);
    }
  };
  ContextMenu.prototype.updateItemVisibility = function (text) {
    var isDefaultItem = !isNullOrUndefined(this.parent.contextMenuItems) ? false : true;
    if (isDefaultItem) {
      this.hideItems.push(text);
    } else {
      this.disableItems.push(text);
    }
  };
  ContextMenu.prototype.contextMenuOpen = function (args) {
    this.isOpen = true;
    var firstMenuItem = this.parent['args'] = args.element.querySelectorAll('li:not(.e-menu-hide):not(.e-disabled)')[0];
    if (!isNullOrUndefined(firstMenuItem)) {
      addClass([firstMenuItem], 'e-focused');
    }
  };
  ContextMenu.prototype.getMenuItems = function () {
    var menuItems = !isNullOrUndefined(this.parent.contextMenuItems) ? this.parent.contextMenuItems : this.getDefaultItems();
    for (var _i = 0, menuItems_1 = menuItems; _i < menuItems_1.length; _i++) {
      var item = menuItems_1[_i];
      if (typeof item === 'string' && this.getDefaultItems().indexOf(item) !== -1) {
        this.buildDefaultItems(item);
      } else if (typeof item !== 'string') {
        if (this.getDefaultItems().indexOf(item.text) !== -1) {
          this.buildDefaultItems(item.text, item.iconCss);
        } else if (item.target === columnHeader) {
          this.headerMenuItems.push(item);
        } else {
          this.contentMenuItems.push(item);
        }
      }
    }
    return this.contentMenuItems;
  };
  ContextMenu.prototype.createItemModel = function (target, item, text, iconCss) {
    var itemModel = {
      text: text,
      id: this.generateID(item),
      target: target,
      iconCss: iconCss ? 'e-icons ' + iconCss : null
    };
    return itemModel;
  };
  ContextMenu.prototype.getLocale = function (text) {
    var localeText = this.parent.localeObj.getConstant(text);
    return localeText;
  };
  ContextMenu.prototype.buildDefaultItems = function (item, iconCSS) {
    var contentMenuItem;
    switch (item) {
      case 'AutoFitAll':
      case 'AutoFit':
      case 'SortAscending':
      case 'SortDescending':
        this.headerMenuItems.push(item);
        break;
      case 'TaskInformation':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('taskInformation'), this.getIconCSS(editIcon, iconCSS));
        break;
      case 'Indent':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('indent'), this.getIconCSS(indentIcon, iconCSS));
        break;
      case 'Outdent':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('outdent'), this.getIconCSS(outdentIcon, iconCSS));
        break;
      case 'Save':
        contentMenuItem = this.createItemModel(editIcon, item, this.getLocale('save'), this.getIconCSS(saveIcon, iconCSS));
        break;
      case 'Cancel':
        contentMenuItem = this.createItemModel(editIcon, item, this.getLocale('cancel'), this.getIconCSS(cancelIcon, iconCSS));
        break;
      case 'Add':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('add'), this.getIconCSS(addIcon, iconCSS));
        //Sub item menu
        contentMenuItem.items = [];
        contentMenuItem.items.push(this.createItemModel(content, 'Above', this.getLocale('above'), this.getIconCSS(addAboveIcon, iconCSS)));
        contentMenuItem.items.push(this.createItemModel(content, 'Below', this.getLocale('below'), this.getIconCSS(addBelowIcon, iconCSS)));
        if (this.parent.viewType !== 'ResourceView') {
          contentMenuItem.items.push(this.createItemModel(content, 'Child', this.getLocale('child')));
        }
        contentMenuItem.items.push(this.createItemModel(content, 'Milestone', this.getLocale('milestone')));
        break;
      case 'DeleteTask':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('deleteTask'), this.getIconCSS(deleteIcon, iconCSS));
        break;
      case 'DeleteDependency':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('deleteDependency'));
        contentMenuItem.items = [];
        contentMenuItem.items.push({});
        break;
      case 'Convert':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('convert'));
        contentMenuItem.items = [];
        contentMenuItem.items.push({});
        break;
      case 'TaskMode':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('changeScheduleMode'));
        contentMenuItem.items = [];
        contentMenuItem.items.push({});
        break;
      case 'SplitTask':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('splitTask'));
        break;
      case 'MergeTask':
        contentMenuItem = this.createItemModel(content, item, this.getLocale('mergeTask'));
        contentMenuItem.items = [];
        contentMenuItem.items.push({});
    }
    if (contentMenuItem) {
      this.contentMenuItems.push(contentMenuItem);
    }
  };
  ContextMenu.prototype.getIconCSS = function (menuClass, iconString) {
    return isNullOrUndefined(iconString) ? menuClass : iconString;
  };
  ContextMenu.prototype.getPredecessorsItems = function () {
    this.predecessors = this.parent.predecessorModule.getValidPredecessor(this.rowData);
    var items = [];
    var itemModel;
    var increment = 0;
    for (var _i = 0, _a = this.predecessors; _i < _a.length; _i++) {
      var predecessor = _a[_i];
      var ganttData = this.parent.getRecordByID(predecessor.from);
      var ganttProp = ganttData.ganttProperties;
      var text = ganttProp.rowUniqueID + ' - ' + ganttProp.taskName;
      var id = 'Dependency' + increment++;
      itemModel = this.createItemModel(content, id, text);
      items.push(itemModel);
    }
    return items;
  };
  ContextMenu.prototype.getDefaultItems = function () {
    return ['AutoFitAll', 'AutoFit', 'TaskInformation', 'DeleteTask', 'Save', 'Cancel', 'SortAscending', 'SortDescending', 'Add', 'DeleteDependency', 'Convert', 'TaskMode', 'Indent', 'Outdent', 'SplitTask', 'MergeTask'];
  };
  /**
   * To get ContextMenu module name.
   *
   * @returns {string} .
   */
  ContextMenu.prototype.getModuleName = function () {
    return 'contextMenu';
  };
  ContextMenu.prototype.removeEventListener = function () {
    if (this.parent.isDestroyed) {
      return;
    }
    this.parent.off('initiate-contextMenu', this.render);
    this.parent.off('reRender-contextMenu', this.reRenderContextMenu);
    this.parent.off('contextMenuClick', this.contextMenuItemClick);
    this.parent.off('contextMenuOpen', this.contextMenuOpen);
  };
  ContextMenu.prototype.contextMenuOnClose = function (args) {
    var parent = 'parentObj';
    if (args.items.length > 0 && args.items[0]["" + parent] instanceof ContextMenu$2) {
      this.revertItemStatus();
    }
  };
  ContextMenu.prototype.revertItemStatus = function () {
    this.contextMenu.showItems(this.hideItems);
    this.contextMenu.enableItems(this.disableItems);
    this.hideItems = [];
    this.disableItems = [];
    this.isOpen = false;
  };
  ContextMenu.prototype.resetItems = function () {
    this.hideItems = [];
    this.disableItems = [];
    this.headerMenuItems = [];
    this.contentMenuItems = [];
    this.item = null;
  };
  ContextMenu.prototype.generateID = function (item) {
    return this.ganttID + '_contextMenu_' + item;
  };
  ContextMenu.prototype.getKeyFromId = function (id) {
    var idPrefix = this.ganttID + '_contextMenu_';
    if (id.indexOf(idPrefix) > -1) {
      return id.replace(idPrefix, '');
    } else {
      return 'Custom';
    }
  };
  /**
   * To destroy the contextmenu module.
   *
   * @returns {void} .
   * @private
   */
  ContextMenu.prototype.destroy = function () {
    this.contextMenu.destroy();
    remove(this.element);
    this.removeEventListener();
    this.contextMenu = null;
    this.element = null;
  };
  return ContextMenu;
}();

/**
 * Gantt Excel Export module
 *
 * @hidden
 */
var ExcelExport = /** @__PURE__ @class */function () {
  /**
   * Constructor for Excel Export module
   *
   * @param {Gantt} gantt .
   */
  function ExcelExport(gantt) {
    this.parent = gantt;
    TreeGrid.Inject(ExcelExport$2);
    this.parent.treeGrid.allowExcelExport = this.parent.allowExcelExport;
    this.bindEvents();
  }
  /**
   * For internal use only - Get the module name.
   *
   * @returns {string} .
   * @private
   */
  ExcelExport.prototype.getModuleName = function () {
    return 'excelExport';
  };
  /**
   * To destroy excel export module.
   *
   * @returns {void} .
   * @private
   */
  ExcelExport.prototype.destroy = function () {
    // Destroy Method
  };
  /**
   * To bind excel exporting events.
   *
   * @returns {void} .
   * @private
   */
  ExcelExport.prototype.bindEvents = function () {
    var _this = this;
    this.parent.treeGrid.beforeExcelExport = function (args) {
      _this.parent.trigger('beforeExcelExport', args);
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.showMaskRow();
      } else {
        _this.parent.showSpinner();
      }
    };
    this.parent.treeGrid.excelQueryCellInfo = function (args) {
      _this.parent.trigger('excelQueryCellInfo', args);
    };
    this.parent.treeGrid.excelHeaderQueryCellInfo = function (args) {
      _this.parent.trigger('excelHeaderQueryCellInfo', args);
    };
    this.parent.treeGrid.excelExportComplete = function (args) {
      _this.parent.trigger('excelExportComplete', args);
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.hideMaskRow();
      } else {
        _this.parent.hideSpinner();
      }
    };
  };
  return ExcelExport;
}();

/**
 * Configures columnMenu collection in Gantt.
 *
 * @hidden
 */
var ColumnMenu = /** @__PURE__ @class */function () {
  function ColumnMenu(parent) {
    TreeGrid.Inject(ColumnMenu$2);
    this.parent = parent;
  }
  /**
   * @returns {HTMLAllCollection} .
   * To get column menu collection.
   */
  ColumnMenu.prototype.getColumnMenu = function () {
    return this.parent.treeGrid.columnMenuModule.getColumnMenu();
  };
  ColumnMenu.prototype.destroy = function () {
    // column menu destroy module
  };
  /**
   * For internal use only - Get the module name.
   *
   * @returns {string} .
   * @private
   */
  ColumnMenu.prototype.getModuleName = function () {
    return 'columnMenu';
  };
  return ColumnMenu;
}();

/**
 * Gantt Excel Export module
 */
var RowDD = /** @__PURE__ @class */function () {
  /**
   * Constructor for Excel Export module
   *
   * @param {Gantt} gantt .
   */
  function RowDD(gantt) {
    this.isTest = false;
    /** @hidden */
    this.updateParentRecords = [];
    /** @hidden */
    this.isaddtoBottom = false;
    this.updateCollection = {};
    /** @hidden */
    this.isSharedTask = false;
    /** @hidden */
    this.canDrop = true;
    this.parent = gantt;
    TreeGrid.Inject(RowDD$2);
    this.parent.treeGrid.allowRowDragAndDrop = this.parent.allowRowDragAndDrop;
    this.bindEvents();
  }
  /**
   * For internal use only - Get the module name.
   *
   * @returns {string} .
   * @private
   */
  RowDD.prototype.getModuleName = function () {
    return 'rowDragAndDrop';
  };
  /**
   * To destroy excel export module.
   *
   * @returns {void} .
   * @private
   */
  RowDD.prototype.destroy = function () {
    // Destroy Method
  };
  /**
   * To bind excel exporting events.
   *
   * @returns {void} .
   * @private
   */
  RowDD.prototype.bindEvents = function () {
    this.parent.treeGrid.rowDragStart = this.rowDragStart.bind(this);
    this.parent.treeGrid.rowDragStartHelper = this.rowDragStartHelper.bind(this);
    this.parent.treeGrid.rowDrag = this.rowDrag.bind(this);
    this.parent.treeGrid.rowDrop = this.rowDrop.bind(this);
  };
  RowDD.prototype.rowDragStart = function (args) {
    this.parent.trigger('rowDragStart', args);
    this.parent.element.style.position = 'relative'; // for positioning the drag element properly
  };
  RowDD.prototype.addErrorElem = function () {
    var dragelem = document.getElementsByClassName('e-ganttdrag')[0];
    var errorelem = dragelem.querySelectorAll('.e-errorelem').length;
    if (!errorelem) {
      var ele = document.createElement('div');
      classList(ele, ['e-errorcontainer'], []);
      classList(ele, ['e-icons', 'e-errorelem'], []);
      var errorVal = dragelem.querySelector('.errorValue');
      var content = dragelem.querySelector('.e-rowcell').innerHTML;
      if (errorVal) {
        content = errorVal.innerHTML;
        errorVal.parentNode.removeChild(errorVal);
      }
      dragelem.querySelector('.e-rowcell').innerHTML = '';
      var spanContent = document.createElement('span');
      spanContent.className = 'errorValue';
      spanContent.style.paddingLeft = '16px';
      spanContent.innerHTML = content;
      dragelem.querySelector('.e-rowcell').appendChild(ele);
      dragelem.querySelector('.e-rowcell').appendChild(spanContent);
    }
  };
  RowDD.prototype.removeErrorElem = function () {
    var errorelem = document.querySelector('.e-errorelem');
    if (errorelem) {
      errorelem.remove();
    }
  };
  RowDD.prototype.rowDrag = function (args) {
    var cloneElement = this.parent.element.querySelector('.e-cloneproperties');
    cloneElement.style.display = 'none';
    var ganttDragElement = cloneElement.cloneNode(true);
    ganttDragElement.classList.add('e-ganttdrag');
    ganttDragElement.style.display = '';
    if (this.parent.element.querySelectorAll('.e-cloneproperties').length <= 1) {
      this.parent.element.appendChild(ganttDragElement);
    } else {
      if (document.getElementsByClassName('e-cloneproperties')[0].querySelectorAll('.e-errorelem').length) {
        this.addErrorElem();
      } else {
        this.removeErrorElem();
      }
    }
    if (this.parent.gridLines === 'Both') {
      addClass(this.parent.element.querySelectorAll('.e-ganttdrag .e-rowcell'), ['e-bothganttlines']);
    }
    var dragElement = this.parent.element.querySelector('.e-ganttdrag');
    var ganttTop = this.parent.element.getClientRects()[0].top;
    var ganttLeft = this.parent.element.getClientRects()[0].left;
    var left;
    var top;
    if (this.parent.isAdaptive) {
      /* eslint-disable-next-line */
      left = args.originalEvent.event.touches[0].clientX - ganttLeft;
      /* eslint-disable-next-line */
      top = args.originalEvent.event.touches[0].clientY - ganttTop;
    } else {
      left = getValue('event', args.originalEvent).clientX - ganttLeft;
      top = getValue('event', args.originalEvent).clientY - ganttTop;
    }
    dragElement.style.left = left + 20 + 'px';
    dragElement.style.top = top + 20 + 'px';
    this.parent.trigger('rowDrag', args);
  };
  RowDD.prototype.rowDragStartHelper = function (args) {
    this.parent.trigger('rowDragStartHelper', args);
    if (this.parent.readOnly) {
      args.cancel = true;
    }
    if (this.parent.viewType === 'ResourceView' && getValue('level', args.data[0]) === 0) {
      args.cancel = true;
    }
  };
  RowDD.prototype.rowDrop = function (args) {
    // const ganttDragelem: Element = document.querySelector('.e-ganttdrag');
    // if (ganttDragelem) {
    //     ganttDragelem.remove();
    // }
    var gridRow = closest(args.target, '.e-row');
    this.parent['oldRecords'] = extend([], [], args.data, true);
    var dropIndex = gridRow ? parseInt(gridRow.getAttribute('data-rowindex'), 10) : args.dropIndex;
    args.dropIndex = dropIndex;
    args.dropRecord = this.parent.updatedRecords[args.dropIndex];
    this.parent.trigger('rowDrop', args);
    if (this.parent.viewType === 'ResourceView') {
      if (args.dropPosition === 'middleSegment') {
        if (args.dropRecord.level === 1 || args.dropRecord.uniqueID === getValue('parentItem', args.data[0]).uniqueID) {
          args.cancel = true; // preventing to drop the record as child to any child records
        }
      }
      if (args.dropPosition !== 'middleSegment') {
        if (args.dropRecord.level === 0 || getValue('parentItem', args.data[0]).uniqueID === args.dropRecord.parentItem.uniqueID) {
          args.cancel = true;
        }
      }
    }
    if (!args.cancel) {
      args.requestType = 'beforeDrop';
      this.parent.trigger('actionBegin', args);
      if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        this.parent.showMaskRow();
      } else {
        this.parent.showSpinner();
      }
      if (!args.cancel) {
        this.dropRows(args, true); // method to update the data collections based on drop action
        args.cancel = true;
      }
    }
  };
  RowDD.prototype.dropRows = function (args, isByMethod) {
    if (!this.parent.readOnly) {
      this.dropPosition = args.dropPosition;
      if (args.dropPosition !== 'Invalid' && this.parent.editModule) {
        var gObj = this.parent;
        this.droppedRecord = gObj.updatedRecords[args.dropIndex];
        if (this.parent.undoRedoModule && this.parent['isUndoRedoItemPresent']('RowDragAndDrop')) {
          if (!this.parent.undoRedoModule['isUndoRedoPerformed']) {
            if (this.parent.undoRedoModule['redoEnabled']) {
              this.parent.undoRedoModule['disableRedo']();
              this.parent.undoRedoModule['createUndoCollection']();
            }
            if (!args.target && this.parent.editModule && this.parent.editModule.taskbarEditModule && this.parent.editModule.taskbarEditModule.taskBarEditAction) {
              this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = [];
            } else {
              this.parent.undoRedoModule['createUndoCollection']();
            }
            var datas = [];
            var _loop_1 = function (i) {
              datas.push(this_1.parent.flatData.filter(function (data) {
                return args.data[i]['ganttProperties'].taskId === data.ganttProperties.taskId;
              })[0]);
            };
            var this_1 = this;
            for (var i = 0; i < args.data.length; i++) {
              _loop_1(i);
            }
            var rowItems = extend([], [], datas, true);
            var records = {};
            if (args['name']) {
              records['action'] = 'RowDragAndDrop';
            } else {
              records['action'] = 'TaskbarDragAndDrop';
            }
            records['beforeDrop'] = [];
            var previousDetails = {};
            previousDetails['dropRecord'] = extend([], [], [args.dropRecord ? args.dropRecord : this.droppedRecord], true)[0];
            previousDetails['data'] = extend([], [], args.data, true);
            if (args.dropPosition === 'middleSegment') {
              previousDetails['dropPosition'] = 'child';
            } else if (args.dropPosition === 'topSegment') {
              previousDetails['dropPosition'] = 'above';
            } else if (args.dropPosition === 'bottomSegment') {
              previousDetails['dropPosition'] = 'below';
            }
            records['afterDrop'] = previousDetails;
            this.parent.undoRedoModule['findPosition'](rowItems, records, 'beforeDrop');
            this.parent.undoRedoModule['getUndoCollection'][this.parent.undoRedoModule['getUndoCollection'].length - 1] = records;
          }
          if (this.parent.toolbarModule) {
            this.parent.toolbarModule.enableItems([this.parent.controlId + '_undo'], true);
          }
        }
        var draggedRecord = void 0;
        var dragRecords = [];
        var droppedRecord = this.droppedRecord;
        if (!args.data[0]) {
          dragRecords.push(args.data);
        } else {
          dragRecords = args.data;
        }
        var count = 0;
        var dragLength = dragRecords.length;
        var _loop_2 = function (i) {
          this_2.parent.isOnEdit = true;
          draggedRecord = dragRecords[i];
          this_2.draggedRecord = draggedRecord;
          if (this_2.dropPosition !== 'Invalid') {
            if (this_2.parent.viewType === 'ResourceView') {
              this_2.checkisSharedTask();
              if (this_2.draggedRecord.level !== 0) {
                this_2.previousParent = this_2.draggedRecord.parentItem.uniqueID;
              }
            }
            if (this_2.isSharedTask) {
              return {
                value: void 0
              };
            }
            if (isByMethod) {
              this_2.deleteDragRow();
            }
            var recordIndex1 = this_2.treeGridData.indexOf(droppedRecord);
            if (this_2.dropPosition === 'topSegment') {
              this_2.dropAtTop(recordIndex1);
            }
            if (this_2.dropPosition === 'bottomSegment') {
              if (!this_2.isSharedTask) {
                if (!droppedRecord.hasChildRecords) {
                  if (this_2.parent.taskFields.parentID && this_2.ganttData.length > 0) {
                    this_2.ganttData.splice(recordIndex1 + 1, 0, this_2.draggedRecord.taskData);
                  }
                  this_2.treeGridData.splice(recordIndex1 + 1, 0, this_2.draggedRecord);
                  this_2.parent.ids.splice(recordIndex1 + 1, 0, this_2.draggedRecord.ganttProperties.rowUniqueID.toString());
                  if (this_2.parent.viewType === 'ResourceView') {
                    var taskId = this_2.draggedRecord.level === 0 ? 'R' + this_2.draggedRecord.ganttProperties.taskId : 'T' + this_2.draggedRecord.ganttProperties.taskId;
                    this_2.parent.getTaskIds().splice(recordIndex1 + 1, 0, taskId);
                  }
                } else {
                  count = this_2.parent.editModule.getChildCount(droppedRecord, 0);
                  if (this_2.parent.taskFields.parentID && this_2.ganttData.length > 0) {
                    this_2.ganttData.splice(recordIndex1 + count + 1, 0, this_2.draggedRecord.taskData);
                  }
                  this_2.treeGridData.splice(recordIndex1 + count + 1, 0, this_2.draggedRecord);
                  /* eslint-disable-next-line */
                  this_2.parent.ids.splice(recordIndex1 + count + 1, 0, this_2.draggedRecord.ganttProperties.rowUniqueID.toString());
                  if (this_2.parent.viewType === 'ResourceView') {
                    var spliceId = this_2.draggedRecord.level === 0 ? 'R' + this_2.draggedRecord.ganttProperties.taskId : 'T' + this_2.draggedRecord.ganttProperties.taskId;
                    this_2.parent.getTaskIds().splice(recordIndex1 + count + 1, 0, spliceId);
                  }
                }
                this_2.parent.setRecordValue('parentItem', this_2.treeGridData[recordIndex1].parentItem, draggedRecord);
                this_2.parent.setRecordValue('parentUniqueID', this_2.treeGridData[recordIndex1].parentUniqueID, draggedRecord);
                this_2.parent.setRecordValue('level', this_2.treeGridData[recordIndex1].level, draggedRecord);
                if (draggedRecord.hasChildRecords) {
                  var level = 1;
                  this_2.updateChildRecordLevel(draggedRecord, level);
                  this_2.updateChildRecord(draggedRecord, recordIndex1 + count + 1);
                }
                if (droppedRecord.parentItem) {
                  var rec = this_2.parent.getParentTask(droppedRecord.parentItem).childRecords;
                  var childRecords = rec;
                  var droppedRecordIndex = childRecords.indexOf(droppedRecord) + 1;
                  childRecords.splice(droppedRecordIndex, 0, draggedRecord);
                }
              }
            }
            if (this_2.dropPosition === 'middleSegment') {
              this_2.dropMiddle(recordIndex1);
              if (droppedRecord.childRecords.length > 0) {
                delete droppedRecord.ganttProperties.segments;
                delete droppedRecord.taskData[this_2.parent.taskFields.segments];
              }
            }
            if (this_2.treeGridData.length !== 0) {
              for (var i_1 = 0; i_1 < this_2.treeGridData.length; i_1++) {
                this_2.treeGridData[parseInt(i_1.toString(), 10)].index = i_1;
                if (!isNullOrUndefined(this_2.treeGridData[parseInt(i_1.toString(), 10)].parentItem)) {
                  var updatedParent = getValue('uniqueIDCollection.' + this_2.treeGridData[parseInt(i_1.toString(), 10)].parentUniqueID, this_2.parent.treeGrid);
                  this_2.treeGridData[parseInt(i_1.toString(), 10)].parentItem.index = updatedParent.index;
                }
              }
            }
            // eslint-disable-next-line
            if (!isNullOrUndefined(draggedRecord.parentItem && this_2.updateParentRecords.indexOf(draggedRecord.parentItem) !== -1)) {
              this_2.updateParentRecords.push(draggedRecord.parentItem);
            }
          }
          if (!this_2.parent.enableVirtualization) {
            var data_1 = gObj.flatData;
            var startIndex = void 0;
            var endIndex = void 0;
            var ganttData_1 = this_2.parent.dataSource;
            var uniqueTaskID_1 = this_2.parent.taskFields.id;
            if (draggedRecord.index < droppedRecord.index) {
              startIndex = draggedRecord.index;
              var _loop_4 = function (i_2) {
                var currentData = this_2.parent.currentViewData.filter(function (e) {
                  return e[uniqueTaskID_1] === ganttData_1[i_2][uniqueTaskID_1];
                })[0];
                if (currentData && currentData.index > droppedRecord.index) {
                  endIndex = currentData.index;
                  return "break";
                }
              };
              for (var i_2 = 0; i_2 < ganttData_1.length; i_2++) {
                var state_2 = _loop_4(i_2);
                if (state_2 === "break") break;
              }
            } else {
              startIndex = droppedRecord.index;
              var _loop_5 = function (i_3) {
                var currentData = this_2.parent.currentViewData.filter(function (e) {
                  return e[uniqueTaskID_1] === ganttData_1[i_3][uniqueTaskID_1];
                })[0];
                if (currentData && currentData.index > draggedRecord.index) {
                  endIndex = currentData.index;
                  return "break";
                }
              };
              for (var i_3 = 0; i_3 < ganttData_1.length; i_3++) {
                var state_3 = _loop_5(i_3);
                if (state_3 === "break") break;
              }
            }
            var _loop_6 = function (i_4) {
              if (!isNullOrUndefined(data_1[i_4])) {
                data_1[i_4].index = i_4;
                if (!isNullOrUndefined(data_1[i_4].parentItem)) {
                  var updatedParent = data_1.filter(function (e) {
                    return e.uniqueID === data_1[i_4].parentUniqueID;
                  })[0];
                  data_1[i_4].parentItem.index = updatedParent.index;
                }
              }
            };
            for (var i_4 = startIndex; i_4 <= endIndex; i_4++) {
              _loop_6(i_4);
            }
          }
          gObj.rowDragAndDropModule.refreshDataSource();
        };
        var this_2 = this;
        for (var i = 0; i < dragLength; i++) {
          var state_1 = _loop_2(i);
          if (typeof state_1 === "object") return state_1.value;
        }
        if (this.dropPosition === 'middleSegment') {
          if (droppedRecord.ganttProperties.predecessor) {
            var len = droppedRecord.ganttProperties.predecessor.length;
            for (var count_1 = 0; count_1 < len; count_1++) {
              if (droppedRecord.ganttProperties.predecessor && droppedRecord.ganttProperties.predecessor[count_1]) {
                var fromRecord = this.parent.getRecordByID(droppedRecord.ganttProperties.predecessor[count_1].from);
                var toRecord = this.parent.getRecordByID(droppedRecord.ganttProperties.predecessor[count_1].to);
                var validPredecessor = this.parent.connectorLineEditModule.validateParentPredecessor(fromRecord, toRecord);
                if (droppedRecord.ganttProperties.predecessor && (!validPredecessor || !this.parent.allowParentDependency)) {
                  this.parent.editModule.removePredecessorOnDelete(droppedRecord);
                  droppedRecord.ganttProperties.predecessor.splice(0, 1);
                  if (droppedRecord.ganttProperties.predecessorsName) {
                    var splittedName = droppedRecord.ganttProperties.predecessorsName.split(',');
                    for (var i = 0; i < splittedName.length; i++) {
                      if (splittedName[i].indexOf(draggedRecord.ganttProperties.taskId + '') !== -1) {
                        splittedName.splice(i, 1);
                      }
                    }
                    var validatedPredecessorNames = splittedName.join();
                    droppedRecord.ganttProperties.predecessorsName = validatedPredecessorNames;
                    droppedRecord[this.parent.taskFields.dependency] = validatedPredecessorNames;
                    droppedRecord.taskData[this.parent.taskFields.dependency] = validatedPredecessorNames;
                  }
                }
              }
            }
          }
          if (droppedRecord.ganttProperties.isMilestone) {
            this.parent.setRecordValue('isMilestone', false, droppedRecord.ganttProperties, true);
            if (!isNullOrUndefined(droppedRecord.taskData[this.parent.taskFields.milestone])) {
              if (droppedRecord.taskData[this.parent.taskFields.milestone] === true) {
                droppedRecord.taskData[this.parent.taskFields.milestone] = false;
              }
            }
          }
        }
        if (this.parent.viewType === 'ResourceView' && !this.isSharedTask) {
          var parentUniqueID = void 0;
          if (this.dropPosition === 'middleSegment') {
            parentUniqueID = this.droppedRecord.uniqueID;
          } else {
            parentUniqueID = this.droppedRecord.parentItem ? this.droppedRecord.parentItem.uniqueID : this.droppedRecord.uniqueID;
          }
          var droppedParentItem = this.parent.getTaskByUniqueID(parentUniqueID);
          var editedObj = {};
          editedObj[this.parent.taskFields.resourceInfo] = [];
          editedObj[this.parent.taskFields.resourceInfo].push(droppedParentItem.ganttProperties.taskId);
          this.removeExistingResources();
          var tempResourceInfo = this.parent.dataOperation.setResourceInfo(editedObj);
          var currentTask = this.draggedRecord;
          if (isNullOrUndefined(currentTask.ganttProperties.resourceInfo)) {
            currentTask.ganttProperties.resourceInfo = [];
          }
          if (droppedParentItem.ganttProperties.taskName === 'Unassigned Task') {
            currentTask.ganttProperties.resourceInfo = [];
            currentTask.ganttProperties.sharedTaskUniqueIds = [currentTask.ganttProperties.rowUniqueID];
          } else {
            currentTask.ganttProperties.resourceInfo.push(tempResourceInfo[0]);
          }
          this.updateCurrentTask(currentTask);
          if (droppedParentItem.ganttProperties.taskName === 'Unassigned Task') {
            this.deleteSharedResourceTask();
          } else {
            this.updateSharedResourceTask();
          }
        }
        if (this.parent.taskFields.dependency && this.parent.allowParentDependency) {
          var isValidPredecessor = true;
          var draggedParent = void 0;
          var toParent_1;
          if (draggedRecord.parentItem) {
            draggedParent = this.parent.flatData[this.parent.ids.indexOf(draggedRecord.parentItem.taskId)];
          } else {
            draggedParent = draggedRecord;
          }
          if (droppedRecord.parentItem) {
            toParent_1 = this.parent.flatData[this.parent.ids.indexOf(droppedRecord.parentItem.taskId)];
          } else {
            toParent_1 = droppedRecord;
          }
          var validateRecords = void 0;
          if (toParent_1.uniqueID === draggedParent.uniqueID || draggedParent.parentItem && toParent_1.uniqueID === this.parent.flatData[this.parent.ids.indexOf(draggedParent.parentItem.taskId)].uniqueID) {
            validateRecords = this.parent.currentViewData.filter(function (data) {
              if (data.ganttProperties.predecessor && data.ganttProperties.predecessor.length > 0) {
                for (var i = 0; i < data.ganttProperties.predecessor.length; i++) {
                  return parseInt(data.ganttProperties.predecessor[i].to, 10) === parseInt(toParent_1.ganttProperties.taskId, 10) || parseInt(data.ganttProperties.predecessor[i].from, 10) === parseInt(toParent_1.ganttProperties.taskId, 10);
                }
              }
              return null;
            });
            var predName = [];
            for (var i = 0; i < validateRecords.length; i++) {
              predName = [];
              if (validateRecords[i].ganttProperties.predecessor) {
                for (var k = 0; k < validateRecords[i].ganttProperties.predecessor.length; k++) {
                  if (parseInt(validateRecords[i].ganttProperties.taskId, 10) !== parseInt(validateRecords[i].ganttProperties.predecessor[k].from, 10)) {
                    predName.push(validateRecords[i].ganttProperties.predecessor[k].from);
                  } else {
                    predName.push(validateRecords[i].ganttProperties.predecessor[k].to);
                  }
                }
              }
              var _loop_3 = function (j) {
                var name_1 = predName[j].replace(/\D/g, '');
                var toRec = this_3.parent.currentViewData.filter(function (data) {
                  return parseInt(data.ganttProperties.taskId, 10) === parseInt(name_1, 10);
                });
                isValidPredecessor = this_3.parent.connectorLineEditModule.validateParentPredecessor(validateRecords[i], toRec[0]);
                if (!isValidPredecessor) {
                  this_3.parent.dataOperation['resetDependency'](validateRecords[i]);
                  this_3.parent.dataOperation['resetDependency'](toRec[0]);
                }
              };
              var this_3 = this;
              for (var j = 0; j < predName.length; j++) {
                _loop_3(j);
              }
            }
          }
        }
        // method to update the edited parent records
        for (var j = 0; j < this.updateParentRecords.length; j++) {
          this.parent.dataOperation.updateParentItems(this.updateParentRecords[j]);
        }
        if (this.parent.viewType === 'ProjectView' && this.parent.editModule && this.parent.editModule.taskbarEditModule && this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.predecessorModule && this.parent.undoRedoModule['currentAction']['action'] === 'TaskbarDragAndDrop' && this.parent.undoRedoModule['currentAction']['beforeDrop'][0].position !== 'child') {
          var dropRec = this.parent.flatData[this.parent.ids.indexOf(this.parent.undoRedoModule['currentAction']['afterDrop'].dropRecord.ganttProperties.taskId.toString())];
          this.parent.editModule.taskbarEditModule.taskBarEditRecord = dropRec;
          this.parent.editModule.taskbarEditModule.taskBarEditAction = 'ChildDrag';
          this.parent.predecessorModule.validatePredecessor(dropRec, [], '');
          this.parent.editModule.taskbarEditModule.taskBarEditAction = null;
        }
        this.updateParentRecords = [];
        this.parent.isOnEdit = false;
      }
      if (!isNullOrUndefined(this.parent.editModule)) {
        this.parent.editModule.refreshRecord(args, true);
      }
      if (!this.parent.undoRedoModule || this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
        this.parent.previousFlatData = extend([], [], this.parent.flatData, true);
      }
    }
    this.parent['oldRecords'] = [];
  };
  RowDD.prototype.updateCurrentTask = function (currentTask) {
    this.parent.dataOperation.updateMappingData(currentTask, 'resourceInfo');
    this.parent.editModule.updateResourceRelatedFields(currentTask, 'resource');
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.parent.editModule.dialogModule.dialogEditValidationFlag = false;
    this.parent.editModule.dialogModule.validateDuration(currentTask);
    this.parent.dataOperation.updateWidthLeft(currentTask);
    this.parent.dataOperation.updateTaskData(currentTask);
  };
  RowDD.prototype.deleteSharedResourceTask = function () {
    for (var i = 0; i < this.parent.getTaskIds().length; i++) {
      if (this.parent.getTaskIds()[i] === 'T' + this.draggedRecord.ganttProperties.taskId) {
        if (this.parent.getTaskByUniqueID(this.parent.flatData[i].parentItem.uniqueID).ganttProperties.taskName !== 'Unassigned Task') {
          this.removeRecords(this.parent.flatData[i]);
        }
        if (!isNullOrUndefined(this.parent.flatData[parseInt(i.toString(), 10)].parentItem && this.updateParentRecords.indexOf(this.parent.flatData[parseInt(i.toString(), 10)].parentItem) !== -1)) {
          this.updateParentRecords.push(this.parent.flatData[i].parentItem);
        }
      }
    }
  };
  RowDD.prototype.removeExistingResources = function () {
    var preParentRecord = this.parent.getTaskByUniqueID(this.previousParent);
    if (this.draggedRecord.ganttProperties.resourceInfo) {
      for (var count = 0; count < this.draggedRecord.ganttProperties.resourceInfo.length; count++) {
        if (this.draggedRecord.ganttProperties.resourceInfo[parseInt(count.toString(), 10)][this.parent.resourceFields.id] === preParentRecord.ganttProperties.taskId) {
          this.draggedRecord.ganttProperties.resourceInfo.splice(count, 1);
          break;
        }
      }
    }
  };
  /*update the record for the same task which are shared with other tasks*/
  RowDD.prototype.updateSharedResourceTask = function () {
    for (var i = 0; i < this.parent.getTaskIds().length; i++) {
      if (this.parent.getTaskIds()[i] === 'T' + this.draggedRecord.ganttProperties.taskId) {
        this.parent.editModule.updateGanttProperties(this.draggedRecord, this.parent.flatData[i]);
        this.parent.dataOperation.updateTaskData(this.parent.flatData[i]);
        if (!isNullOrUndefined(this.parent.flatData[parseInt(i.toString(), 10)].parentItem && this.updateParentRecords.indexOf(this.parent.flatData[parseInt(i.toString(), 10)].parentItem) !== -1)) {
          this.updateParentRecords.push(this.parent.flatData[i].parentItem);
        }
      }
    }
  };
  RowDD.prototype._getExistingTaskWithID = function (record) {
    var existingTasks = [];
    for (var i = 0; i < this.parent.getTaskIds().length; i++) {
      if (this.parent.getTaskIds()[i] === 'T' + record.ganttProperties.taskId) {
        existingTasks.push(this.parent.flatData[i]);
      }
    }
    return existingTasks;
  };
  /*Method to remove resource from resource Info collection and update reosurce related fields*/
  RowDD.prototype.removeResourceInfo = function (record) {
    var droppedParentItem = this.parent.getTaskByUniqueID(this.draggedRecord.parentItem.uniqueID);
    if (record.ganttProperties.resourceInfo && record.ganttProperties.resourceInfo.length > 1) {
      var sameIdTasks = this._getExistingTaskWithID(record);
      var currentTask = void 0;
      if (sameIdTasks === null) {
        return;
      }
      for (var i = 0; i < sameIdTasks.length; i++) {
        currentTask = sameIdTasks[i];
        var resources = currentTask.ganttProperties.resourceInfo;
        for (var count = 0; count < resources.length; count++) {
          if (resources[count][this.parent.resourceFields.id] === droppedParentItem.ganttProperties.taskId) {
            resources.splice(count, 1);
            this.parent.setRecordValue('resourceInfo', resources, currentTask.ganttProperties, true);
            this.updateCurrentTask(currentTask);
            if (!isNullOrUndefined(currentTask.parentItem && this.updateParentRecords.indexOf(currentTask.parentItem) !== -1)) {
              this.updateParentRecords.push(currentTask.parentItem);
            }
            break;
          }
        }
      }
    }
  };
  RowDD.prototype.refreshDataSource = function () {
    var draggedRecord = this.draggedRecord;
    var droppedRecord = this.droppedRecord;
    var proxy = this.parent;
    var tempDataSource;
    var idx;
    var ganttFields = this.parent.taskFields;
    if (this.parent.dataSource instanceof DataManager) {
      tempDataSource = getValue('dataOperation.dataArray', this.parent);
    } else {
      tempDataSource = proxy.dataSource;
    }
    if (tempDataSource.length > 0 && !isNullOrUndefined(droppedRecord) && !droppedRecord.parentItem) {
      for (var i = 0; i < Object.keys(tempDataSource).length; i++) {
        if (!isNullOrUndefined(droppedRecord.taskData[ganttFields.child]) && tempDataSource[i][ganttFields.child] === droppedRecord.taskData[ganttFields.child]) {
          idx = i;
        } else if (isNullOrUndefined(droppedRecord.taskData[ganttFields.child]) && droppedRecord.taskData[ganttFields.id] === tempDataSource[i][ganttFields.id]) {
          idx = i;
        }
      }
      if (this.dropPosition === 'topSegment') {
        if (!this.parent.taskFields.parentID) {
          tempDataSource.splice(idx, 0, draggedRecord.taskData);
        }
      } else if (this.dropPosition === 'bottomSegment') {
        if (!this.parent.taskFields.parentID) {
          tempDataSource.splice(idx + 1, 0, draggedRecord.taskData);
        }
      }
    } else if (!this.parent.taskFields.parentID && !isNullOrUndefined(droppedRecord) && droppedRecord.parentItem) {
      if (this.dropPosition === 'topSegment' || this.dropPosition === 'bottomSegment') {
        var rowPosition = this.dropPosition === 'topSegment' ? 'Above' : 'Below';
        this.parent.editModule.addRowSelectedItem = droppedRecord;
        this.parent.editModule.updateRealDataSource([draggedRecord], rowPosition);
        delete this.parent.editModule.addRowSelectedItem;
      }
    }
    if (this.parent.taskFields.parentID) {
      if (draggedRecord.parentItem) {
        var droppedId = this.dropPosition === 'middleSegment' ? this.parent.taskFields.id : this.parent.taskFields.parentID;
        draggedRecord[this.parent.taskFields.parentID] = droppedRecord[droppedId];
        draggedRecord.ganttProperties.parentId = droppedRecord[droppedId];
        if (this.parent.viewType === 'ResourceView' && !(this.dropPosition === 'middleSegment') || this.parent.viewType === 'ProjectView') {
          draggedRecord.taskData[this.parent.taskFields.parentID] = droppedRecord.taskData[droppedId];
        }
      } else {
        draggedRecord[this.parent.taskFields.parentID] = null;
        draggedRecord.taskData[this.parent.taskFields.parentID] = null;
        draggedRecord.ganttProperties.parentId = null;
      }
    }
    if (this.parent.viewType === 'ProjectView' && this.parent.editModule && this.parent.editModule.taskbarEditModule && this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && this.parent.predecessorModule && this.parent.undoRedoModule['currentAction']['action'] === 'TaskbarDragAndDrop') {
      var dragRec = this.parent.flatData[this.parent.ids.indexOf(this.parent.undoRedoModule['currentAction']['afterDrop'].data[0].ganttProperties.taskId.toString())];
      this.parent.editModule.taskbarEditModule.taskBarEditRecord = dragRec;
      this.parent.editModule.taskbarEditModule.taskBarEditAction = 'ChildDrag';
      this.parent.predecessorModule.validatePredecessor(dragRec, [], '');
      this.parent.editModule.taskbarEditModule.taskBarEditAction = null;
    }
  };
  RowDD.prototype.dropMiddle = function (recordIndex1) {
    var gObj = this.parent;
    var childRecords = this.parent.editModule.getChildCount(this.droppedRecord, 0);
    var childRecordsLength = isNullOrUndefined(childRecords) || childRecords === 0 ? recordIndex1 + 1 : childRecords + recordIndex1 + 1;
    if (this.dropPosition === 'middleSegment' && !this.isSharedTask) {
      if (gObj.taskFields.parentID && this.ganttData.length > 0) {
        this.ganttData.splice(childRecordsLength, 0, this.draggedRecord.taskData);
      }
      this.treeGridData.splice(childRecordsLength, 0, this.draggedRecord);
      this.parent.ids.splice(childRecordsLength, 0, this.draggedRecord.ganttProperties.rowUniqueID.toString());
      if (this.parent.viewType === 'ResourceView') {
        var recordId = this.draggedRecord.level === 0 ? 'R' + this.draggedRecord.ganttProperties.taskId : 'T' + this.draggedRecord.ganttProperties.taskId;
        this.parent.getTaskIds().splice(childRecordsLength, 0, recordId);
      }
      if (this.draggedRecord.hasChildRecords) {
        this.updateChildRecord(this.draggedRecord, childRecordsLength, this.droppedRecord.expanded);
      }
      this.recordLevel();
      if (isNullOrUndefined(this.draggedRecord.parentItem && this.updateParentRecords.indexOf(this.draggedRecord.parentItem) !== -1)) {
        this.updateParentRecords.push(this.draggedRecord.parentItem);
      }
    }
  };
  RowDD.prototype.recordLevel = function () {
    var gObj = this.parent;
    var draggedRecord = this.draggedRecord;
    var droppedRecord = this.droppedRecord;
    var childItem = gObj.taskFields.child;
    if (!droppedRecord.hasChildRecords) {
      droppedRecord.hasChildRecords = true;
      if (!droppedRecord.childRecords.length) {
        droppedRecord.childRecords = [];
        if (!gObj.taskFields.parentID && isNullOrUndefined(droppedRecord.taskData[childItem])) {
          droppedRecord.taskData[childItem] = [];
        }
      }
    }
    if (this.dropPosition === 'middleSegment') {
      var parentItem = extend({}, droppedRecord);
      delete parentItem.childRecords;
      var createParentItem = {
        uniqueID: parentItem.uniqueID,
        expanded: parentItem.expanded,
        level: parentItem.level,
        index: parentItem.index,
        taskId: parentItem.ganttProperties.rowUniqueID
      };
      this.parent.setRecordValue('parentItem', createParentItem, draggedRecord);
      this.parent.setRecordValue('parentUniqueID', droppedRecord.uniqueID, draggedRecord);
      droppedRecord.childRecords.splice(droppedRecord.childRecords.length, 0, draggedRecord);
      if (!isNullOrUndefined(draggedRecord) && !gObj.taskFields.parentID && !isNullOrUndefined(droppedRecord.taskData[childItem])) {
        droppedRecord.taskData[gObj.taskFields.child].splice(droppedRecord.childRecords.length, 0, draggedRecord.taskData);
      }
      if (!draggedRecord.hasChildRecords) {
        draggedRecord.level = droppedRecord.level + 1;
      } else {
        var level = 1;
        draggedRecord.level = droppedRecord.level + 1;
        this.parent.setRecordValue('level', this.draggedRecord.level, this.draggedRecord);
        this.updateChildRecordLevel(draggedRecord, level);
      }
      droppedRecord.expanded = true;
    }
  };
  RowDD.prototype.deleteDragRow = function () {
    this.treeGridData = isCountRequired(this.parent) ? getValue('result', this.parent.treeGrid.dataSource) : this.parent.treeGrid.dataSource;
    if (this.parent.dataSource instanceof DataManager) {
      this.ganttData = getValue('dataOperation.dataArray', this.parent);
    } else {
      this.ganttData = isCountRequired(this.parent) ? getValue('result', this.parent.dataSource) : this.parent.dataSource;
    }
    var deletedRow;
    if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed']) {
      if (this.parent.viewType === 'ProjectView') {
        deletedRow = this.parent.flatData[this.parent.ids.indexOf(this.draggedRecord.ganttProperties.taskId.toString())];
      } else {
        if (this.draggedRecord.hasChildRecords) {
          deletedRow = this.parent.flatData['R' + this.parent.taskIds.indexOf(this.draggedRecord.ganttProperties.taskId)];
        } else {
          deletedRow = this.parent.flatData['T' + this.parent.taskIds.indexOf(this.draggedRecord.ganttProperties.taskId)];
        }
      }
    } else {
      deletedRow = this.parent.getTaskByUniqueID(this.draggedRecord.uniqueID);
    }
    this.removeRecords(deletedRow);
  };
  //method to check the dropped record has already present in the child collection
  RowDD.prototype.checkisSharedTask = function () {
    this.isSharedTask = false;
    var sharedTask = null;
    // eslint-disable-next-line
    var parentUniqueID = this.droppedRecord.level === 0 ? this.droppedRecord.uniqueID : this.droppedRecord.parentItem.uniqueID;
    var droppedParentItem = this.parent.getTaskByUniqueID(parentUniqueID);
    var childTasks = droppedParentItem.childRecords;
    for (var i = 0; i < childTasks.length; i++) {
      if ('T' + this.draggedRecord.ganttProperties.taskId === 'T' + childTasks[i].ganttProperties.taskId) {
        this.isSharedTask = true;
        sharedTask = childTasks[i];
      }
    }
    if (this.isSharedTask) {
      this.removeResourceInfo(sharedTask);
    }
  };
  RowDD.prototype.dropAtTop = function (recordIndex1) {
    var gObj = this.parent;
    if (!this.isSharedTask) {
      if (gObj.taskFields.parentID && this.ganttData.length > 0) {
        this.ganttData.splice(recordIndex1, 0, this.draggedRecord.taskData);
      }
      this.treeGridData.splice(recordIndex1, 0, this.draggedRecord);
      this.parent.ids.splice(recordIndex1, 0, this.draggedRecord.ganttProperties.rowUniqueID.toString());
      this.parent.setRecordValue('parentItem', this.droppedRecord.parentItem, this.draggedRecord);
      this.parent.setRecordValue('parentUniqueID', this.droppedRecord.parentUniqueID, this.draggedRecord);
      this.parent.setRecordValue('level', this.droppedRecord.level, this.draggedRecord);
      if (this.parent.viewType === 'ResourceView') {
        var id = this.draggedRecord.level === 0 ? 'R' + this.draggedRecord.ganttProperties.taskId : 'T' + this.draggedRecord.ganttProperties.taskId;
        this.parent.getTaskIds().splice(recordIndex1, 0, id);
      }
      if (this.draggedRecord.hasChildRecords) {
        var level = 1;
        this.updateChildRecord(this.draggedRecord, recordIndex1);
        this.updateChildRecordLevel(this.draggedRecord, level);
      }
      if (this.droppedRecord.parentItem) {
        var rec = this.parent.getParentTask(this.droppedRecord.parentItem).childRecords;
        var childRecords = rec;
        var droppedRecordIndex = childRecords.indexOf(this.droppedRecord);
        childRecords.splice(droppedRecordIndex, 0, this.draggedRecord);
      }
      /* eslint-disable-next-line */
      if (!isNullOrUndefined(this.draggedRecord.parentItem && this.updateParentRecords.indexOf(this.draggedRecord.parentItem) !== -1)) {
        this.updateParentRecords.push(this.draggedRecord.parentItem);
      }
    }
  };
  RowDD.prototype.updateChildRecordLevel = function (record, level) {
    var length = 0;
    var currentRecord;
    level++;
    if (!record.hasChildRecords) {
      return 0;
    }
    length = record.childRecords.length;
    for (var i = 0; i < length; i++) {
      currentRecord = record.childRecords[i];
      var parentData = void 0;
      if (record.parentItem) {
        var id = 'uniqueIDCollection';
        parentData = this.parent.treeGrid[id][record.parentItem.uniqueID];
      }
      currentRecord.level = record.parentItem ? parentData.level + level : record.level + 1;
      this.parent.setRecordValue('level', currentRecord.level, currentRecord);
      if (currentRecord.hasChildRecords) {
        level--;
        level = this.updateChildRecordLevel(currentRecord, level);
      }
    }
    return level;
  };
  // eslint-disable-next-line
  RowDD.prototype.updateChildRecord = function (record, count, expanded) {
    var currentRecord;
    var gObj = this.parent;
    var length = 0;
    if (!record.hasChildRecords) {
      return 0;
    }
    length = record.childRecords.length;
    for (var i = 0; i < length; i++) {
      currentRecord = record.childRecords[i];
      count++;
      gObj.flatData.splice(count, 0, currentRecord);
      this.parent.ids.splice(count, 0, currentRecord.ganttProperties.rowUniqueID.toString());
      if (gObj.taskFields.parentID && gObj.dataSource.length > 0) {
        this.ganttData.splice(count, 0, currentRecord.taskData);
      }
      if (currentRecord.hasChildRecords) {
        count = this.updateChildRecord(currentRecord, count);
      }
    }
    return count;
  };
  RowDD.prototype.removeRecords = function (record) {
    var gObj = this.parent;
    var dataSource;
    if (this.parent.dataSource instanceof DataManager) {
      dataSource = getValue('dataOperation.dataArray', this.parent);
    } else {
      dataSource = this.parent.dataSource;
    }
    var deletedRow = record;
    var flatParentData = deletedRow && deletedRow.parentItem ? this.parent.getParentTask(deletedRow.parentItem) : null;
    if (deletedRow) {
      if (deletedRow.parentItem) {
        var childRecords = [];
        if (flatParentData) {
          childRecords = flatParentData.childRecords;
        }
        var childIndex = 0;
        if (childRecords && childRecords.length > 0) {
          if (this.parent.viewType === 'ResourceView' && childRecords.length === 1) {
            //For updating the parent record which has zero parent reords.
            this.parent.isOnDelete = true;
            childRecords[0].isDelete = true;
            this.parent.dataOperation.updateParentItems(flatParentData);
            this.parent.isOnDelete = false;
            childRecords[0].isDelete = false;
          }
          childIndex = childRecords.indexOf(deletedRow);
          flatParentData.childRecords.splice(childIndex, 1);
          if (!this.parent.taskFields.parentID && flatParentData.taskData[this.parent.taskFields.child]) {
            flatParentData.taskData[this.parent.taskFields.child].splice(childIndex, 1);
          }
          // collection for updating parent record
          this.updateParentRecords.push(flatParentData);
        }
      }
      //method to delete the record from datasource collection
      if (!this.parent.taskFields.parentID) {
        var deleteRecordIDs = [];
        deleteRecordIDs.push(deletedRow.ganttProperties.taskId.toString());
        if (this.parent.viewType === 'ProjectView' || this.parent.viewType === 'ResourceView' && this.dropPosition !== 'middleSegment') {
          this.parent.editModule.removeFromDataSource(deleteRecordIDs);
        }
      }
      if (gObj.taskFields.parentID) {
        if (deletedRow.hasChildRecords && deletedRow.childRecords.length > 0) {
          this.removeChildItem(deletedRow);
        }
        var idx = void 0;
        var ganttData = dataSource.length > 0 && this.parent.viewType !== 'ResourceView' ? dataSource : this.parent.updatedRecords;
        for (var i = 0; i < ganttData.length; i++) {
          if (this.parent.viewType === 'ResourceView') {
            if (ganttData[i].ganttProperties.rowUniqueID === deletedRow.ganttProperties.rowUniqueID) {
              idx = i;
            }
          } else {
            if (ganttData[i][this.parent.taskFields.id] === deletedRow.taskData[this.parent.taskFields.id]) {
              idx = i;
            }
          }
        }
        if (idx !== -1) {
          if (dataSource.length > 0) {
            dataSource.splice(idx, 1);
          }
          var tempIndex = this.treeGridData.indexOf(deletedRow);
          this.treeGridData.splice(tempIndex, 1);
          this.parent.ids.splice(tempIndex, 1);
          if (this.parent.treeGrid.parentData.indexOf(deletedRow) !== -1) {
            this.parent.treeGrid.parentData.splice(this.parent.treeGrid.parentData.indexOf(deletedRow), 1);
          }
          if (this.parent.viewType === 'ResourceView') {
            this.parent.getTaskIds().splice(idx, 1);
          }
        }
      }
      var recordIndex = this.treeGridData.indexOf(deletedRow);
      if (!gObj.taskFields.parentID) {
        var deletedRecordCount = this.parent.editModule.getChildCount(deletedRow, 0);
        this.treeGridData.splice(recordIndex, deletedRecordCount + 1);
        this.parent.ids.splice(recordIndex, deletedRecordCount + 1);
        var parentIndex = this.ganttData.indexOf(deletedRow.taskData);
        if (parentIndex !== -1) {
          this.ganttData.splice(parentIndex, 1);
          this.parent.treeGrid.parentData.splice(parentIndex, 1);
        }
        if (this.parent.viewType === 'ResourceView') {
          this.parent.getTaskIds().splice(recordIndex, deletedRecordCount + 1);
        }
      }
      if (deletedRow.parentItem && flatParentData && flatParentData.childRecords && !flatParentData.childRecords.length) {
        flatParentData.expanded = false;
        flatParentData.hasChildRecords = false;
      }
    }
  };
  RowDD.prototype.removeChildItem = function (record) {
    var currentRecord;
    var idx;
    for (var i = 0; i < record.childRecords.length; i++) {
      currentRecord = record.childRecords[i];
      var ganttData = void 0;
      if (this.parent.dataSource instanceof DataManager) {
        ganttData = getValue('dataOperation.dataArray', this.parent);
      } else {
        ganttData = this.parent.dataSource;
      }
      for (var j = 0; j < ganttData.length; j++) {
        if (ganttData[j][this.parent.taskFields.id] === currentRecord.taskData[this.parent.taskFields.id]) {
          idx = j;
        }
      }
      if (idx !== -1) {
        if (ganttData.length > 0) {
          ganttData.splice(idx, 1);
        }
        var tempIndex = this.treeGridData.indexOf(currentRecord);
        this.treeGridData.splice(tempIndex, 1);
        this.parent.ids.splice(tempIndex, 1);
        if (this.parent.viewType === 'ResourceView') {
          this.parent.getTaskIds().splice(idx, 1);
        }
      }
      if (currentRecord.hasChildRecords) {
        this.removeChildItem(currentRecord);
      }
    }
  };
  /**
   * Reorder the rows based on given indexes and position
   *
   * @param {number[]} fromIndexes .
   * @param {number} toIndex .
   * @param {string} position .
   * @returns {void} .
   */
  RowDD.prototype.reorderRows = function (fromIndexes, toIndex, position) {
    if (!this.parent.readOnly) {
      if (fromIndexes[0] !== toIndex && (position === 'above' || position === 'below' || position === 'child')) {
        if (position === 'above') {
          this.dropPosition = 'topSegment';
        }
        if (position === 'below') {
          this.dropPosition = 'bottomSegment';
        }
        if (position === 'child') {
          this.dropPosition = 'middleSegment';
        }
        var prevData = void 0;
        if (this.parent.undoRedoModule) {
          prevData = extend([], [], [this.parent.undoRedoModule['currentAction']], true)[0];
        }
        var data = [];
        for (var i = 0; i < fromIndexes.length; i++) {
          if (this.parent.undoRedoModule && this.parent.undoRedoModule['isUndoRedoPerformed'] && (prevData['action'] === 'RowDragAndDrop' || prevData['action'] === 'TaskbarDragAndDrop')) {
            data[i] = prevData['beforeDrop'][i].data;
          } else {
            data[i] = this.parent.flatData[fromIndexes[i]];
          }
        }
        var isByMethod = true;
        var args = {
          data: data,
          dropIndex: toIndex,
          dropPosition: this.dropPosition
        };
        this.dropRows(args, isByMethod);
      } else {
        return;
      }
    }
  };
  return RowDD;
}();

/**
 * @hidden
 */
var PdfGanttTheme = /** @__PURE__ @class */function () {
  function PdfGanttTheme(theme) {
    this.theme = theme;
    this.ganttStyle = {};
    this.setTheme(this.ganttStyle, this.theme);
  }
  Object.defineProperty(PdfGanttTheme.prototype, "style", {
    get: function () {
      return this.ganttStyle;
    },
    enumerable: true,
    configurable: true
  });
  PdfGanttTheme.prototype.setTheme = function (ganttStyle, theme) {
    this.initStyles(ganttStyle);
    ganttStyle.columnHeader.fontSize = 9.5;
    ganttStyle.columnHeader.fontColor = new PdfColor(0, 0, 0);
    ganttStyle.columnHeader.fontColor.gray = 0.2;
    ganttStyle.columnHeader.backgroundColor = new PdfColor(255, 255, 255);
    ganttStyle.columnHeader.borderColor = new PdfColor(234, 234, 234);
    ganttStyle.columnHeader.format.lineAlignment = 1; //Centre
    ganttStyle.columnHeader.format.alignment = 0; //Left
    ganttStyle.fontFamily = PdfFontFamily.Helvetica;
    ganttStyle.cell.fontSize = 9.5;
    ganttStyle.cell.backgroundColor = new PdfColor(255, 255, 255);
    ganttStyle.cell.borderColor = new PdfColor(234, 234, 234);
    ganttStyle.cell.fontColor = new PdfColor(0, 0, 0);
    ganttStyle.cell.fontColor.gray = 0.2;
    ganttStyle.cell.format.lineAlignment = 1; // Centre
    ganttStyle.cell.format.alignment = 0; // Left
    ganttStyle.footer.fontSize = 9.5;
    ganttStyle.footer.fontStyle = 0;
    ganttStyle.footer.format.alignment = 1; //Centre
    ganttStyle.footer.format.lineAlignment = 1; // Middle
    ganttStyle.footer.borderColor = new PdfColor(206, 206, 206);
    ganttStyle.timeline.fontSize = 9.5;
    ganttStyle.timeline.fontStyle = 0;
    ganttStyle.timeline.backgroundColor = new PdfColor(252, 252, 252);
    ganttStyle.timeline.fontColor = new PdfColor(40, 40, 39);
    ganttStyle.chartGridLineColor = new PdfColor(206, 206, 206);
    ganttStyle.timeline.borderColor = new PdfColor(206, 206, 206);
    switch (theme) {
      case 'Bootstrap':
        //chart side theme
        ganttStyle.taskbar.taskColor = new PdfColor(49, 122, 185);
        ganttStyle.taskbar.progressColor = new PdfColor(33, 82, 125);
        ganttStyle.taskbar.criticalTaskColor = new PdfColor(255, 139, 139);
        ganttStyle.taskbar.criticalProgressColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.baselineColor = new PdfColor(240, 173, 78);
        ganttStyle.taskbar.baselineBorderColor = new PdfColor(240, 173, 78);
        ganttStyle.criticalConnectorLineColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.criticalTaskBorderColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.parentTaskColor = new PdfColor(119, 119, 119);
        ganttStyle.taskbar.parentProgressColor = new PdfColor(85, 85, 85);
        ganttStyle.taskbar.taskBorderColor = new PdfColor(33, 82, 125);
        ganttStyle.taskbar.parentTaskBorderColor = new PdfColor(85, 85, 85);
        ganttStyle.taskbar.milestoneColor = new PdfColor(85, 85, 85);
        ganttStyle.footer.fontColor = new PdfColor(0, 0, 0);
        ganttStyle.footer.fontColor.gray = 0.2;
        ganttStyle.connectorLineColor = new PdfColor(33, 82, 125);
        ganttStyle.footer.backgroundColor = new PdfColor(255, 255, 255);
        ganttStyle.taskbar.progressFontColor = new PdfColor(255, 255, 255);
        ganttStyle.label.fontColor = new PdfColor(0, 0, 0);
        ganttStyle.taskbar.splitLineBackground = new PdfColor(51, 51, 51);
        ganttStyle.taskbar.unscheduledTaskBarColor = new PdfColor(49, 122, 185);
        ganttStyle.taskbar.manualParentBackground = new PdfColor(119, 119, 119);
        ganttStyle.taskbar.manualParentProgress = new PdfColor(85, 85, 85);
        ganttStyle.taskbar.manualChildBackground = new PdfColor(117, 56, 201);
        ganttStyle.taskbar.manualChildProgress = new PdfColor(87, 42, 150);
        ganttStyle.taskbar.manualLineColor = new PdfColor(152, 154, 156);
        ganttStyle.taskbar.manualParentBorder = new PdfColor(85, 85, 85);
        ganttStyle.taskbar.manualChildBorder = new PdfColor(87, 42, 150);
        break;
      case 'Bootstrap 4':
        //chart side theme
        ganttStyle.taskbar.taskColor = new PdfColor(0, 123, 255);
        ganttStyle.taskbar.progressColor = new PdfColor(0, 86, 179);
        ganttStyle.taskbar.criticalTaskColor = new PdfColor(255, 139, 139);
        ganttStyle.taskbar.criticalProgressColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.baselineColor = new PdfColor(255, 193, 7);
        ganttStyle.taskbar.baselineBorderColor = new PdfColor(255, 193, 7);
        ganttStyle.criticalConnectorLineColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.criticalTaskBorderColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.parentTaskColor = new PdfColor(108, 117, 125);
        ganttStyle.taskbar.parentProgressColor = new PdfColor(73, 80, 87);
        ganttStyle.taskbar.taskBorderColor = new PdfColor(0, 86, 179);
        ganttStyle.taskbar.parentTaskBorderColor = new PdfColor(73, 80, 87);
        ganttStyle.taskbar.milestoneColor = new PdfColor(73, 80, 87);
        ganttStyle.footer.fontColor = new PdfColor(0, 0, 0);
        ganttStyle.footer.fontColor.gray = 0.2;
        ganttStyle.connectorLineColor = new PdfColor(0, 86, 179);
        ganttStyle.footer.backgroundColor = new PdfColor(255, 255, 255);
        ganttStyle.taskbar.progressFontColor = new PdfColor(255, 255, 255);
        ganttStyle.label.fontColor = new PdfColor(33, 37, 41);
        ganttStyle.taskbar.splitLineBackground = new PdfColor(0, 0, 0);
        ganttStyle.taskbar.unscheduledTaskBarColor = new PdfColor(0, 123, 255);
        ganttStyle.taskbar.manualParentBackground = new PdfColor(108, 117, 125);
        ganttStyle.taskbar.manualParentProgress = new PdfColor(73, 80, 87);
        ganttStyle.taskbar.manualChildBackground = new PdfColor(133, 83, 241);
        ganttStyle.taskbar.manualChildProgress = new PdfColor(102, 40, 238);
        ganttStyle.taskbar.manualLineColor = new PdfColor(152, 154, 156);
        ganttStyle.taskbar.manualParentBorder = new PdfColor(73, 80, 87);
        ganttStyle.taskbar.manualChildBorder = new PdfColor(102, 40, 238);
        break;
      case 'Fabric':
        ganttStyle.columnHeader.fontColor = new PdfColor(102, 102, 102);
        ganttStyle.cell.fontColor = new PdfColor(51, 51, 51);
        //chart side theme
        ganttStyle.taskbar.taskColor = new PdfColor(0, 120, 214);
        ganttStyle.taskbar.progressColor = new PdfColor(0, 91, 163);
        ganttStyle.taskbar.criticalTaskColor = new PdfColor(255, 139, 139);
        ganttStyle.taskbar.criticalProgressColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.baselineColor = new PdfColor(216, 59, 1);
        ganttStyle.taskbar.baselineBorderColor = new PdfColor(216, 59, 1);
        ganttStyle.criticalConnectorLineColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.criticalTaskBorderColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.parentTaskColor = new PdfColor(118, 118, 118);
        ganttStyle.taskbar.parentProgressColor = new PdfColor(80, 80, 80);
        ganttStyle.taskbar.taskBorderColor = new PdfColor(0, 91, 163);
        ganttStyle.taskbar.parentTaskBorderColor = new PdfColor(80, 80, 80);
        ganttStyle.taskbar.milestoneColor = new PdfColor(80, 80, 80);
        ganttStyle.footer.fontColor = new PdfColor(51, 51, 51);
        ganttStyle.footer.fontColor.gray = 0.2;
        ganttStyle.connectorLineColor = new PdfColor(0, 69, 122);
        ganttStyle.footer.backgroundColor = new PdfColor(255, 255, 255);
        ganttStyle.taskbar.progressFontColor = new PdfColor(255, 255, 255);
        ganttStyle.label.fontColor = new PdfColor(51, 51, 51);
        ganttStyle.taskbar.splitLineBackground = new PdfColor(0, 0, 0);
        ganttStyle.taskbar.unscheduledTaskBarColor = new PdfColor(0, 120, 214);
        ganttStyle.taskbar.manualParentBackground = new PdfColor(118, 118, 118);
        ganttStyle.taskbar.manualParentProgress = new PdfColor(80, 80, 80);
        ganttStyle.taskbar.manualChildBackground = new PdfColor(154, 56, 186);
        ganttStyle.taskbar.manualChildProgress = new PdfColor(109, 24, 136);
        ganttStyle.taskbar.manualLineColor = new PdfColor(152, 154, 156);
        ganttStyle.taskbar.manualParentBorder = new PdfColor(80, 80, 80);
        ganttStyle.taskbar.manualChildBorder = new PdfColor(109, 24, 136);
        break;
      default:
        //chart side theme
        ganttStyle.taskbar.taskColor = new PdfColor(88, 105, 197);
        ganttStyle.taskbar.progressColor = new PdfColor(63, 81, 181);
        ganttStyle.taskbar.criticalTaskColor = new PdfColor(255, 139, 139);
        ganttStyle.taskbar.criticalProgressColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.baselineColor = new PdfColor(193, 87, 0);
        ganttStyle.taskbar.baselineBorderColor = new PdfColor(193, 87, 0);
        ganttStyle.criticalConnectorLineColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.criticalTaskBorderColor = new PdfColor(255, 85, 85);
        ganttStyle.taskbar.parentTaskColor = new PdfColor(132, 132, 132);
        ganttStyle.taskbar.parentProgressColor = new PdfColor(97, 97, 97);
        ganttStyle.taskbar.taskBorderColor = new PdfColor(63, 81, 181);
        ganttStyle.taskbar.parentTaskBorderColor = new PdfColor(51, 51, 51);
        ganttStyle.taskbar.milestoneColor = new PdfColor(97, 97, 97);
        ganttStyle.footer.fontColor = new PdfColor(0, 0, 0);
        ganttStyle.footer.fontColor.gray = 0.2;
        ganttStyle.connectorLineColor = new PdfColor(63, 81, 181);
        ganttStyle.footer.backgroundColor = new PdfColor(255, 255, 255);
        ganttStyle.taskbar.progressFontColor = new PdfColor(255, 255, 255);
        ganttStyle.label.fontColor = new PdfColor(51, 51, 51);
        ganttStyle.taskbar.splitLineBackground = new PdfColor(0, 0, 0);
        ganttStyle.taskbar.unscheduledTaskBarColor = new PdfColor(63, 81, 181);
        ganttStyle.taskbar.manualParentBackground = new PdfColor(97, 97, 97);
        ganttStyle.taskbar.manualParentProgress = new PdfColor(97, 97, 97);
        ganttStyle.taskbar.manualChildBackground = new PdfColor(0, 135, 134);
        ganttStyle.taskbar.manualChildProgress = new PdfColor(0, 135, 134);
        ganttStyle.taskbar.manualLineColor = new PdfColor(152, 154, 156);
        ganttStyle.taskbar.manualParentBorder = new PdfColor(97, 97, 97);
        ganttStyle.taskbar.manualChildBorder = new PdfColor(0, 135, 134);
        break;
    }
  };
  PdfGanttTheme.prototype.initStyles = function (ganttStyle) {
    ganttStyle.columnHeader = {};
    ganttStyle.columnHeader.format = new PdfStringFormat();
    ganttStyle.cell = {};
    ganttStyle.cell.format = new PdfStringFormat();
    ganttStyle.timeline = {};
    ganttStyle.footer = {};
    ganttStyle.footer.format = new PdfStringFormat();
    ganttStyle.label = {};
    ganttStyle.taskbar = {};
  };
  return PdfGanttTheme;
}();

/**
 * Dictionary class
 *
 * @private
 * @hidden
 */
var TemporaryDictionary = /** @__PURE__ @class */function () {
  function TemporaryDictionary() {
    /**
     * @hidden
     * @private
     */
    this.mKeys = [];
    /**
     * @hidden
     * @private
     */
    this.mValues = [];
  }
  /**
   * @returns {number} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.size = function () {
    return this.mKeys.length;
  };
  /**
   * @template K
   * @template V
   * @param {K} key .
   * @param {V} value .
   * @returns {void} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.add = function (key, value) {
    if (key === undefined || key === null || value === undefined || value === null) {
      throw new ReferenceError('Provided key or value is not valid.');
    }
    var index = this.mKeys.indexOf(key);
    if (index < 0) {
      this.mKeys.push(key);
      this.mValues.push(value);
      return 1;
    } else {
      throw new RangeError('An item with the same key has already been added.');
    }
  };
  /**
   * @template K
   * @returns {K[]} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.keys = function () {
    return this.mKeys;
  };
  /**
   * @template V
   * @returns {V[]} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.values = function () {
    return this.mValues;
  };
  /**
   * @template K
   * @template V
   * @param {K} key .
   * @returns {V} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.getValue = function (key) {
    if (key === undefined || key === null) {
      throw new ReferenceError('Provided key is not valid.');
    }
    var index = this.mKeys.indexOf(key);
    if (index < 0) {
      throw new RangeError('No item with the specified key has been added.');
    } else {
      return this.mValues[index];
    }
  };
  /**
   * @template K
   * @template V
   * @param {K} key .
   * @param {V} value .
   * @returns {void} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.setValue = function (key, value) {
    if (key === undefined || key === null) {
      throw new ReferenceError('Provided key is not valid.');
    }
    var index = this.mKeys.indexOf(key);
    if (index < 0) {
      this.mKeys.push(key);
      this.mValues.push(value);
    } else {
      this.mValues[index] = value;
    }
  };
  /**
   * @template K
   * @param {K} key .
   * @returns {boolean} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.remove = function (key) {
    if (key === undefined || key === null) {
      throw new ReferenceError('Provided key is not valid.');
    }
    var index = this.mKeys.indexOf(key);
    if (index < 0) {
      throw new RangeError('No item with the specified key has been added.');
    } else {
      this.mKeys.splice(index, 1);
      this.mValues.splice(index, 1);
      return true;
    }
  };
  /**
   * @template K
   * @param {K} key .
   * @returns {boolean} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.containsKey = function (key) {
    if (key === undefined || key === null) {
      throw new ReferenceError('Provided key is not valid.');
    }
    var index = this.mKeys.indexOf(key);
    if (index < 0) {
      return false;
    }
    return true;
  };
  /**
   * @returns {void} .
   * @hidden
   * @private
   */
  TemporaryDictionary.prototype.clear = function () {
    this.mKeys = [];
    this.mValues = [];
  };
  return TemporaryDictionary;
}();

/**
 * PdfBorders.ts class for EJ2-PDF
 */
/**
 * `PdfBorders` class used represents the cell border of the PDF grid.
 *
 * @hidden
 */
var PdfBorders = /** @__PURE__ @class */function () {
  // Constructor
  /**
   * Create a new instance for `PdfBorders` class.
   *
   * @private
   */
  function PdfBorders() {
    var defaultBorderPenLeft = new PdfPen(new PdfColor(0, 0, 0));
    defaultBorderPenLeft.dashStyle = PdfDashStyle.Solid;
    var defaultBorderPenRight = new PdfPen(new PdfColor(0, 0, 0));
    defaultBorderPenRight.dashStyle = PdfDashStyle.Solid;
    var defaultBorderPenTop = new PdfPen(new PdfColor(0, 0, 0));
    defaultBorderPenTop.dashStyle = PdfDashStyle.Solid;
    var defaultBorderPenBottom = new PdfPen(new PdfColor(0, 0, 0));
    defaultBorderPenBottom.dashStyle = PdfDashStyle.Solid;
    this.leftPen = defaultBorderPenLeft;
    this.rightPen = defaultBorderPenRight;
    this.topPen = defaultBorderPenTop;
    this.bottomPen = defaultBorderPenBottom;
  }
  Object.defineProperty(PdfBorders.prototype, "left", {
    // Properties
    /**
     * Gets or sets the `Left`.
     *
     * @returns {PdfPen} .
     * @private
     */
    get: function () {
      return this.leftPen;
    },
    set: function (value) {
      this.leftPen = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders.prototype, "right", {
    /**
     * Gets or sets the `Right`.
     *
     * @returns {PdfPen} .
     * @private
     */
    get: function () {
      return this.rightPen;
    },
    set: function (value) {
      this.rightPen = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders.prototype, "top", {
    /**
     * Gets or sets the `Top`.
     *
     * @returns {PdfPen} .
     * @private
     */
    get: function () {
      return this.topPen;
    },
    set: function (value) {
      this.topPen = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders.prototype, "bottom", {
    /**
     * Gets or sets the `Bottom`.
     *
     * @returns {PdfPen} .
     * @private
     */
    get: function () {
      return this.bottomPen;
    },
    set: function (value) {
      this.bottomPen = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders.prototype, "all", {
    /**
     * sets the `All`.
     *
     * @param {PdfPen} value .
     * @private
     */
    set: function (value) {
      this.leftPen = this.rightPen = this.topPen = this.bottomPen = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders.prototype, "isAll", {
    /**
     * Gets a value indicating whether this instance `is all`.
     *
     * @returns {boolean} .
     * @private
     */
    get: function () {
      return this.leftPen === this.rightPen && this.leftPen === this.topPen && this.leftPen === this.bottomPen;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfBorders, "default", {
    /**
     * Gets the `default`.
     *
     * @returns {PdfBorders} .
     * @private
     */
    get: function () {
      return new PdfBorders();
    },
    enumerable: true,
    configurable: true
  });
  return PdfBorders;
}();
/** @hidden */
var PdfPaddings = /** @__PURE__ @class */function () {
  function PdfPaddings(left, right, top, bottom) {
    /**
     * The 'left' border padding set.
     *
     * @private
     */
    this.hasLeftPad = false;
    /**
     * The 'right' border padding set.
     *
     * @private
     */
    this.hasRightPad = false;
    /**
     * The 'top' border padding set.
     *
     * @private
     */
    this.hasTopPad = false;
    /**
     * The 'bottom' border padding set.
     *
     * @private
     */
    this.hasBottomPad = false;
    if (typeof left === 'undefined') {
      //5.76 and 0 are taken from ms-word default table margins.
      this.leftPad = this.rightPad = 5.76;
      //0.5 is set for top and bottom by default.
      this.bottomPad = this.topPad = 0.5;
    } else {
      this.leftPad = left;
      this.rightPad = right;
      this.topPad = top;
      this.bottomPad = bottom;
      this.hasLeftPad = true;
      this.hasRightPad = true;
      this.hasTopPad = true;
      this.hasBottomPad = true;
    }
  }
  Object.defineProperty(PdfPaddings.prototype, "left", {
    // Properties
    /**
     * Gets or sets the `left` value of the edge
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.leftPad;
    },
    set: function (value) {
      this.leftPad = value;
      this.hasLeftPad = true;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfPaddings.prototype, "right", {
    /**
     * Gets or sets the `right` value of the edge.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.rightPad;
    },
    set: function (value) {
      this.rightPad = value;
      this.hasRightPad = true;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfPaddings.prototype, "top", {
    /**
     * Gets or sets the `top` value of the edge
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.topPad;
    },
    set: function (value) {
      this.topPad = value;
      this.hasTopPad = true;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfPaddings.prototype, "bottom", {
    /**
     * Gets or sets the `bottom` value of the edge.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.bottomPad;
    },
    set: function (value) {
      this.bottomPad = value;
      this.hasBottomPad = true;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfPaddings.prototype, "all", {
    /**
     * Sets value to all sides `left,right,top and bottom`.s
     *
     * @param {number} value .
     * @private
     */
    set: function (value) {
      this.leftPad = this.rightPad = this.topPad = this.bottomPad = value;
      this.hasLeftPad = true;
      this.hasRightPad = true;
      this.hasTopPad = true;
      this.hasBottomPad = true;
    },
    enumerable: true,
    configurable: true
  });
  return PdfPaddings;
}();

/**
 * PdfGridStyleBase.ts class for EJ2-PDF
 */
/**
 * Base class for the `treegrid style`,
 */
var PdfTreeGridStyleBase = /** @__PURE__ @class */function () {
  function PdfTreeGridStyleBase() {}
  return PdfTreeGridStyleBase;
}();
/**
 * `PdfTreeGridStyle` class provides customization of the appearance for the 'PdfGrid'.
 *
 */
var PdfTreeGridStyle = /** @__PURE__ @class */function () {
  //constructor
  /**
   * Initialize a new instance for `PdfGridStyle` class.
   *
   * @private
   */
  function PdfTreeGridStyle() {
    this.cellSpacing = 0;
    this.borderOverlapStyle = PdfBorderOverlapStyle.Overlap;
    this.allowHorizontalOverflow = false;
    this.horizontalOverflowType = PdfHorizontalOverflowType.LastPage;
    this.cellPadding = new PdfPaddings();
  }
  return PdfTreeGridStyle;
}();
var __extends$m = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 *
 */
var PdfTreeGridLayouter = /** @__PURE__ @class */function (_super) {
  __extends$m(PdfTreeGridLayouter, _super);
  function PdfTreeGridLayouter(baseFormat) {
    var _this = _super.call(this, baseFormat) || this;
    _this.columnRanges = [];
    _this.repeatRowIndex = -1;
    _this.currentBounds = new RectangleF(0, 0, 0, 0);
    return _this;
  }
  Object.defineProperty(PdfTreeGridLayouter.prototype, "treegrid", {
    get: function () {
      return this.elements;
    },
    enumerable: true,
    configurable: true
  });
  PdfTreeGridLayouter.prototype.layoutInternal = function (param) {
    if (isNullOrUndefined(param)) {
      throw Error('Argument Null Expection');
    }
    this.currentPage = param.page;
    var format = param.format;
    if (this.currentPage !== null) {
      this.currentPageBounds = this.currentPage.getClientSize();
    }
    this.currentGraphics = this.currentPage.graphics;
    if (format !== null && format.break === PdfLayoutBreakType.FitColumnsToPage) {
      /* eslint-disable-next-line */
      this.currentBounds = new RectangleF(new PointF(param.bounds.x, param.bounds.y), new SizeF(this.treegrid.columns.width, this.currentGraphics.clientSize.height));
    } else {
      this.currentBounds = new RectangleF(new PointF(param.bounds.x, param.bounds.y), this.currentGraphics.clientSize);
    }
    if (this.treegrid.rows.count !== 0) {
      this.currentBounds.width = param.bounds.width > 0 ? param.bounds.width : this.currentBounds.width - this.treegrid.rows.getRow(0).cells.getCell(0).style.borders.left.width / 2;
    } else {
      throw Error('Please add row or header into grid');
    }
    this.startLocation = new PointF(param.bounds.x, param.bounds.y);
    if (param.bounds.height > 0) {
      this.currentBounds.height = param.bounds.height;
    }
    if (!this.treegrid.style.allowHorizontalOverflow && !this.treegrid.isFitToWidth) {
      this.treegrid.measureColumnsWidth();
      this.determineColumnDrawRanges();
    } else {
      this.treegrid.measureColumnsWidth(this.currentBounds);
      this.columnRanges.push([0, this.treegrid.columns.count - 1]);
    }
    return this.layoutOnPage(param);
  };
  /**
   * `Determines the column draw ranges`.
   *
   * @returns {void} .
   * @private
   */
  PdfTreeGridLayouter.prototype.determineColumnDrawRanges = function () {
    var startColumn = 0;
    var endColumn = 0;
    var cellWidths = 0;
    var availableWidth = this.treegrid['taskbar'].isAutoFit() ? pointToPixel(this.currentGraphics.clientSize.width) - this.currentBounds.x : this.currentGraphics.clientSize.width - this.currentBounds.x;
    for (var i = 0; i < this.treegrid.columns.count; i++) {
      cellWidths += this.treegrid.columns.getColumn(i).width;
      if (cellWidths >= availableWidth) {
        var subWidths = 0;
        for (var j = startColumn; j <= i; j++) {
          subWidths += this.treegrid.columns.getColumn(j).width;
          if (subWidths > availableWidth) {
            break;
          }
          endColumn = j;
        }
        this.columnRanges.push([startColumn, endColumn]);
        startColumn = endColumn + 1;
        //endColumn = startColumn;
        cellWidths = endColumn <= i ? this.treegrid.columns.getColumn(i).width : 0;
      }
    }
    this.columnRanges.push([startColumn, this.treegrid.columns.count - 1]);
  };
  PdfTreeGridLayouter.prototype.getFormat = function (format) {
    var f = format;
    return f;
  };
  PdfTreeGridLayouter.prototype.layoutOnPage = function (param) {
    var format = this.getFormat(param.format);
    var result = null;
    var layoutedPages = new TemporaryDictionary();
    var startPage = param.page;
    for (var index = 0; index < this.columnRanges.length; index++) {
      var range = this.columnRanges[index];
      this.cellStartIndex = range[0];
      this.cellEndIndex = range[1];
      var rowsCount = this.treegrid.rows.count;
      var i = 0;
      var repeatRow = false;
      //Draw row by row with the specified cell range.
      for (var j = 0; j < rowsCount; j++) {
        var row = this.treegrid.rows.getRow(j);
        i++;
        var originalHeight = this.currentBounds.y;
        if (this.currentPage !== null && !layoutedPages.containsKey(this.currentPage)) {
          layoutedPages.add(this.currentPage, range);
        }
        var rowResult = this.drawRow(row);
        //if height remains same, it is understood that row is not draw in the page.
        if (originalHeight === this.currentBounds.y) {
          repeatRow = true;
          this.repeatRowIndex = this.treegrid.rows.rowCollection.indexOf(row);
        } else {
          repeatRow = false;
          this.repeatRowIndex = -1;
        }
        while (!rowResult.isFinish && startPage !== null) {
          if (this.treegrid.allowRowBreakAcrossPages) {
            //If there is no space in the current page, add new page and then draw the remaining row.
            this.currentPage = this.getNextPageFormat(format);
            if (this.treegrid.enableHeader) {
              this.drawHeader();
            }
            this.checkBounds(format);
            rowResult = this.drawRow(row);
          } else if (!this.treegrid.allowRowBreakAcrossPages && i < length) {
            this.currentPage = this.getNextPageFormat(format);
            if (this.treegrid.enableHeader) {
              this.drawHeader();
            }
            break;
          } else if (i >= length) {
            break;
          }
        }
        if (!rowResult.isFinish && startPage !== null && format.layout !== PdfLayoutType.OnePage && repeatRow) {
          this.startLocation.x = this.currentBounds.x;
          this.currentPage = this.getNextPageFormat(format);
          if (this.treegrid.enableHeader) {
            this.drawHeader();
          }
          this.startLocation.y = this.currentBounds.y;
          if (format.paginateBounds === new RectangleF(0, 0, 0, 0)) {
            this.currentBounds.x += this.startLocation.x;
          }
          if (this.currentBounds.x === PdfBorders.default.left.width / 2) {
            this.currentBounds.y += this.startLocation.x;
          }
          this.drawRow(row);
          if (this.currentPage !== null && !layoutedPages.containsKey(this.currentPage)) {
            layoutedPages.add(this.currentPage, range);
          }
        }
      }
      if (this.columnRanges.indexOf(range) < this.columnRanges.length - 1 && startPage !== null && format.layout !== PdfLayoutType.OnePage) {
        this.currentPage = this.getNextPageFormat(format);
        this.checkBounds(format);
      }
    }
    result = this.getLayoutResult();
    if (this.treegrid.style.allowHorizontalOverflow && this.treegrid.style.horizontalOverflowType === PdfHorizontalOverflowType.NextPage) {
      this.reArrangePages(layoutedPages);
    }
    return result;
  };
  PdfTreeGridLayouter.prototype.checkBounds = function (format) {
    var location = new PointF(PdfBorders.default.right.width / 2, PdfBorders.default.top.width / 2);
    if (format.paginateBounds === new RectangleF(0, 0, 0, 0) && this.startLocation === location) {
      this.currentBounds.x += this.startLocation.x;
      this.currentBounds.y += this.startLocation.y;
    }
  };
  PdfTreeGridLayouter.prototype.drawHeader = function () {
    this.drawRow(this.treegrid.rows.getRow(0));
  };
  PdfTreeGridLayouter.prototype.reArrangePages = function (layoutPages) {
    var document = this.currentPage.document;
    var pages = [];
    var keys = layoutPages.keys();
    for (var i = 0; i < keys.length; i++) {
      var page = keys[i];
      page.section = null;
      pages.push(page);
      document.pages.remove(page);
    }
    for (var i = 0; i < layoutPages.size(); i++) {
      var count = layoutPages.size() / this.columnRanges.length;
      for (var j = i; j < layoutPages.size(); j += count) {
        var page = pages[j];
        if (document.pages.indexOf(page) === -1) {
          document.pages.add(page);
        }
      }
    }
  };
  // eslint-disable-next-line
  PdfTreeGridLayouter.prototype.getNextPageFormat = function (format) {
    var section = this.currentPage.section;
    var nextPage = null;
    var index = section.indexOf(this.currentPage);
    if (index === section.count - 1) {
      nextPage = section.add();
    } else {
      nextPage = section.getPages()[index + 1];
    }
    this.currentGraphics = nextPage.graphics;
    this.currentBounds = new RectangleF(new PointF(0, 0), nextPage.getClientSize());
    return nextPage;
  };
  PdfTreeGridLayouter.prototype.getLayoutResult = function () {
    var bounds = new RectangleF(this.startLocation, new SizeF(this.currentBounds.width, this.currentBounds.y - this.startLocation.y));
    return new PdfTreeGridLayoutResult(this.currentPage, bounds);
  };
  PdfTreeGridLayouter.prototype.checkIfDefaultFormat = function (format) {
    var defaultFormat = new PdfStringFormat();
    return format.alignment === defaultFormat.alignment && format.characterSpacing === defaultFormat.characterSpacing && format.clipPath === defaultFormat.clipPath && format.firstLineIndent === defaultFormat.firstLineIndent && format.horizontalScalingFactor === defaultFormat.horizontalScalingFactor && format.lineAlignment === defaultFormat.lineAlignment && format.lineLimit === defaultFormat.lineLimit && format.lineSpacing === defaultFormat.lineSpacing && format.measureTrailingSpaces === defaultFormat.measureTrailingSpaces && format.noClip === defaultFormat.noClip && format.paragraphIndent === defaultFormat.paragraphIndent && format.rightToLeft === defaultFormat.rightToLeft && format.subSuperScript === defaultFormat.subSuperScript && format.wordSpacing === defaultFormat.wordSpacing && format.wordWrap === defaultFormat.wordWrap;
  };
  PdfTreeGridLayouter.prototype.drawRow = function (row, layoutResult, height) {
    //.. Check if required space available.
    //.....If the row conains spans which  falls through more than one page, then draw the row to next page.
    if (isNullOrUndefined(layoutResult)) {
      var result = new RowLayoutResult();
      height = row.rowBreakHeight > 0 ? row.rowBreakHeight : row.height;
      if (height > this.currentPageBounds.height) {
        if (this.treegrid.allowRowBreakAcrossPages) {
          result.isFinish = true;
          this.drawRowWithBreak(result, row, height);
        } else {
          // If AllowRowBreakAcrossPages is not true, draw the row till it fits the page.
          result.isFinish = false;
          this.drawRow(row, result, height);
        }
      } else if (this.currentBounds.y + height > this.currentPageBounds.height || this.currentBounds.y + height > this.currentBounds.height) {
        if (this.repeatRowIndex > -1 && this.repeatRowIndex === row.rowIndex) {
          if (this.treegrid.allowRowBreakAcrossPages) {
            result.isFinish = true;
            this.drawRowWithBreak(result, row, height);
          } else {
            result.isFinish = false;
            this.drawRow(row, result, height);
          }
        } else {
          result.isFinish = false;
        }
      } else {
        result.isFinish = true;
        this.drawRow(row, result, height);
      }
      return result;
    } else {
      var location_1 = new PointF(this.currentBounds.x, this.currentBounds.y);
      layoutResult.bounds = new RectangleF(location_1, new SizeF(0, 0));
      var leftAdjustment = 0;
      height = this.reCalculateHeight(row, height);
      for (var i = this.cellStartIndex; i <= this.cellEndIndex; i++) {
        var cell = row.cells.getCell(i);
        var column = this.treegrid.columns.getColumn(i);
        if (column.isTreeColumn) {
          leftAdjustment = row.level * 10;
        }
        var cancelSpans = cell.columnSpan > 1 && i > this.cellEndIndex + 1;
        if (!cancelSpans) {
          for (var j = 1; j < cell.columnSpan; j++) {
            row.cells.getCell(i + j).isCellMergeContinue = true;
          }
        }
        var size = new SizeF(column.width, height);
        if (cell.columnSpan > 1) {
          size = new SizeF(cell.width, height);
          i += cell.columnSpan;
        }
        if (!this.checkIfDefaultFormat(column.format) && this.checkIfDefaultFormat(cell.style.format)) {
          cell.style.format = column.format;
        }
        cell.draw(this.currentGraphics, new RectangleF(location_1, size), cancelSpans, leftAdjustment);
        /* eslint-disable-next-line */
        if (row.treegrid.style.allowHorizontalOverflow && (cell.columnSpan > this.cellEndIndex || i + cell.columnSpan > this.cellEndIndex + 1) && this.cellEndIndex < row.cells.count - 1) {
          row.rowOverflowIndex = this.cellEndIndex;
        }
        location_1.x += column.width;
        leftAdjustment = 0;
      }
      this.currentBounds.y += height;
      /* eslint-disable-next-line */
      layoutResult.bounds = new RectangleF(new PointF(layoutResult.bounds.x, layoutResult.bounds.y), new SizeF(location_1.x, location_1.y));
      return null;
    }
  };
  /**
   * @param {RowLayoutResult} result .
   * @param {PdfTreeGridRow} row .
   * @param {number} height .
   * @returns {void} .
   */
  PdfTreeGridLayouter.prototype.drawRowWithBreak = function (result, row, height) {
    var location = new PointF(this.currentBounds.x, this.currentBounds.y);
    result.bounds = new RectangleF(location, new SizeF(0, 0));
    var leftAdjustment = 0;
    this.treegridHeight = this.currentBounds.height;
    // Calculate the remaining height.
    row.rowBreakHeight = this.currentBounds.y + height - this.currentBounds.height;
    // No need to explicit break if the row height is equal to treegrid height.
    for (var c = 0; c < row.cells.count; c++) {
      var cell = row.cells.getCell(c);
      var cellHeight = cell.measureHeight();
      if (cellHeight === height && cell.value === null) {
        row.rowBreakHeight = this.currentBounds.y + height - this.currentBounds.height;
      }
    }
    for (var i = this.cellStartIndex; i <= this.cellEndIndex; i++) {
      var column = this.treegrid.columns.getColumn(i);
      if (column.isTreeColumn) {
        leftAdjustment = row.level * 10;
      }
      var cell = row.cells.getCell(i);
      var cancelSpans = cell.columnSpan + i > this.cellEndIndex + 1 && cell.columnSpan > 1;
      if (!cancelSpans) {
        for (var j = 1; j < cell.columnSpan; j++) {
          row.cells.getCell(i + j).isCellMergeContinue = true;
        }
      }
      var tHeight = this.treegridHeight > 0 ? this.treegridHeight : this.currentBounds.height;
      var size = new SizeF(column.width, tHeight);
      if (!this.checkIfDefaultFormat(column.format) && this.checkIfDefaultFormat(cell.style.format)) {
        cell.style.format = column.format;
      }
      cell.draw(this.currentGraphics, new RectangleF(location, size), cancelSpans, leftAdjustment);
      result.isFinish = !result.isFinish ? result.isFinish : cell.finishedDrawingCell;
      location.x += column.width;
      leftAdjustment = 0;
      this.currentBounds.y += this.treegridHeight > 0 ? this.treegridHeight : height;
      result.bounds = new RectangleF(new PointF(result.bounds.x, result.bounds.y), new SizeF(location.x, location.y));
    }
  };
  /**
   * `Recalculate row height` for the split cell to be drawn.
   *
   * @param {PdfTreeGridRow} row .
   * @param {number} height .
   * @returns {void} .
   * @private
   */
  PdfTreeGridLayouter.prototype.reCalculateHeight = function (row, height) {
    var newHeight = 0;
    for (var i = this.cellStartIndex; i <= this.cellEndIndex; i++) {
      if (!isNullOrUndefined(row.cells.getCell(i).remainingString) || row.cells.getCell(i).remainingString === '') {
        newHeight = Math.max(newHeight, row.cells.getCell(i).measureHeight());
      }
    }
    return Math.max(height, newHeight);
  };
  return PdfTreeGridLayouter;
}(ElementLayouter);
var PdfTreeGridLayoutResult = /** @__PURE__ @class */function (_super) {
  __extends$m(PdfTreeGridLayoutResult, _super);
  /**
   * Constructor
   *
   * @param {PdfPage} page .
   * @param {RectangleF} bounds .
   * @private
   */
  function PdfTreeGridLayoutResult(page, bounds) {
    return _super.call(this, page, bounds) || this;
  }
  return PdfTreeGridLayoutResult;
}(PdfLayoutResult);
/**
 * `PdfGridLayoutFormat` class represents a flexible grid that consists of columns and rows.
 */
var PdfTreeGridLayoutFormat = /** @__PURE__ @class */function (_super) {
  __extends$m(PdfTreeGridLayoutFormat, _super);
  /**
   * Initializes a new instance of the `PdfGridLayoutFormat` class.
   *
   * @param {PdfLayoutFormat} baseFormat .
   * @private
   */
  function PdfTreeGridLayoutFormat(baseFormat) {
    var _this = this;
    if (typeof baseFormat === 'undefined') {
      _this = _super.call(this) || this;
    } else {
      _this = _super.call(this, baseFormat) || this;
    }
    return _this;
  }
  return PdfTreeGridLayoutFormat;
}(PdfLayoutFormat);
var __extends$n = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * PdfTreeGrid Class for EJ2-PDF
 */
var PdfTreeGrid = /** @__PURE__ @class */function (_super) {
  __extends$n(PdfTreeGrid, _super);
  function PdfTreeGrid() {
    var _this = _super.call(this) || this;
    _this.treeGridSize = new SizeF(0, 0);
    _this.treeColumnIndex = 0;
    _this.allowRowBreakAcrossPages = true;
    _this.enableHeader = true;
    _this.isFitToWidth = false;
    _this.columns = new PdfTreeGridColumnCollection(_this);
    _this.rows = new PdfTreeGridRowCollection(_this);
    _this.headers = new PdfTreeGridHeaderCollection(_this);
    _this.style = new PdfTreeGridStyle();
    _this.rowHeight = 0;
    return _this;
  }
  Object.defineProperty(PdfTreeGrid.prototype, "size", {
    //Properties
    /**
     * Gets a value indicating whether the `start cell layout event` should be raised.
     *
     * @returns {boolean} .
     * @private
     */
    // public get raiseBeginCellDraw(): boolean {
    //     // eslint-disable-next-line
    //     return (typeof this.beginCellDraw !== 'undefined' && typeof this.beginCellDraw !== null);
    // }
    /**
     * Gets a value indicating whether the `end cell layout event` should be raised.
     *
     * @returns {boolean} .
     * @private
     */
    // public get raiseEndCellDraw(): boolean {
    //     // eslint-disable-next-line
    //     return (typeof this.endCellDraw !== 'undefined' && typeof this.endCellDraw !== null);
    // }
    get: function () {
      if (this.treeGridSize.width === 0 && this.treeGridSize.height === 0) {
        this.treeGridSize = this.calculateTreeGridSize();
      }
      return this.treeGridSize;
    },
    set: function (value) {
      this.treeGridSize = value;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * `Draws` the element on the page with the specified page, 'RectangleF' class and layout format
   *
   * @private
   */
  /* eslint-disable-next-line */
  PdfTreeGrid.prototype.draw = function (arg1, arg2, arg3, arg4) {
    if (arg2 instanceof PointF && typeof arg2.width === 'undefined' && typeof arg3 === 'undefined') {
      return this.drawHelper(arg1, arg2.x, arg2.y);
    } else if (typeof arg2 === 'number' && typeof arg3 === 'number' && typeof arg4 === 'undefined') {
      return this.drawHelper(arg1, arg2, arg3, null);
    } else if (arg2 instanceof RectangleF && typeof arg2.width !== 'undefined' && typeof arg3 === 'undefined') {
      return this.drawHelper(arg1, arg2, null);
    } else if (arg2 instanceof PointF && typeof arg2.width === 'undefined' && arg3 instanceof PdfLayoutFormat) {
      return this.drawHelper(arg1, arg2.x, arg2.y, arg3);
    } else if (typeof arg2 === 'number' && typeof arg3 === 'number' && (arg4 instanceof PdfLayoutFormat || arg4 === null)) {
      var width = arg1.graphics.clientSize.width - arg2;
      var layoutRectangle = new RectangleF(arg2, arg3, width, 0);
      return this.drawHelper(arg1, layoutRectangle, arg4);
    } else if (arg2 instanceof RectangleF && typeof arg2.width !== 'undefined' && typeof arg3 === 'boolean') {
      return this.drawHelper(arg1, arg2, null);
    } else {
      return this.drawHelper(arg1, arg2, arg3);
    }
  };
  PdfTreeGrid.prototype.measureColumnsWidth = function (bounds) {
    if (typeof bounds !== 'undefined') {
      var widths = this.columns.getDefaultWidths(bounds.width - bounds.x);
      for (var i = 0; i < this.columns.count; i++) {
        if (this.columns.getColumn(i).width < 0) {
          this.columns.getColumn(i).width = widths[i];
        }
      }
    } else {
      var widths = [];
      var cellWidth = 0;
      var rowLevel = 0;
      // if(this.headers.count > 0){
      //     let colCount: number = this.headers.getHeader(0).cells.count;
      //     for(let i: number = 0; i < colCount; i++){
      //         let rowCount: number = this.headers.count;
      //         for(let j: number = 0; j < rowCount; j++){
      //             let tempWidth: number = this.headers.getHeader(j).cells.getCell(i).width;
      //             let rowWidth: number = this.initialWidth > 0 ? Math.min(this.initialWidth, tempWidth) :
      //                 tempWidth;
      //             cellWidth = Math.max(cellWidth, rowWidth);
      //         }
      //         widths.push(cellWidth);
      //     }
      // }
      var colCount = this.columns.count;
      for (var i = 0; i < colCount; i++) {
        var rowCount = this.rows.count;
        for (var j = 0; j < rowCount; j++) {
          var tempWidth = this.rows.getRow(j).cells.getCell(i).width;
          var rowWidth = this.initialWidth > 0 ? Math.min(this.initialWidth, tempWidth) : tempWidth;
          cellWidth = Math.max(cellWidth, rowWidth);
          cellWidth = Math.max(this.columns.getColumn(i).width, cellWidth);
          if (this.columns.getColumn(i).isTreeColumn) {
            rowLevel = Math.max(rowLevel, this.rows.getRow(j).level);
          }
        }
        if (this.columns.getColumn(i).isTreeColumn) {
          widths.push(cellWidth + rowLevel * 10);
        } else {
          widths.push(cellWidth);
        }
        cellWidth = 0;
      }
      for (var i = 0; i < this.columns.count; i++) {
        if (this.columns.getColumn(i).width < 0) {
          this.columns.getColumn(i).width = widths[i];
        }
      }
    }
  };
  PdfTreeGrid.prototype.calculateTreeGridSize = function () {
    var height = 0;
    var width = this.columns.width;
    for (var i = 0; i < this.headers.count; i++) {
      var row = this.headers.getHeader(i);
      height += row.height;
    }
    for (var i = 0; i < this.rows.count; i++) {
      var row = this.rows.getRow(i);
      height += row.height;
    }
    return new SizeF(width, height);
  };
  PdfTreeGrid.prototype.drawGrid = function (page, x, y, format) {
    this.initialWidth = page.graphics.clientSize.width;
    var layout = new RectangleF(0, 0, page.getClientSize().height, 0);
    return this.draw(page, layout, format);
  };
  PdfTreeGrid.prototype.layout = function (param) {
    if (this.rows.count !== 0) {
      var style = this.rows.getRow(0).cells.count !== 0 ? this.rows.getRow(0).cells.getCell(0).style : null;
      if (!isNullOrUndefined(style) && style.borders.left.width !== 1) {
        var x = style.borders.left.width / 2;
        var y = style.borders.top.width / 2;
        if (param.bounds.x === PdfBorders.default.right.width / 2 && param.bounds.y === PdfBorders.default.right.width / 2) {
          var newBound = new RectangleF(new PointF(x, y), new SizeF(this.size.width, this.size.height));
          param.bounds = newBound;
        }
      }
    }
    //  this.setSpan();
    this.layouter = new PdfTreeGridLayouter(this);
    var result = this.layouter.layoutInternal(param);
    return result;
  };
  return PdfTreeGrid;
}(PdfLayoutElement);

/**@hidden*/
var PdfTreeGridCell = /** @__PURE__ @class */function () {
  function PdfTreeGridCell(row) {
    this.cellWidth = 0;
    this.cellHeight = 0;
    /** @private */
    this.finishedDrawingCell = true;
    if (isNullOrUndefined(row)) {
      this.rowSpan = 1;
      this.columnSpan = 1;
    } else {
      this.row = row;
    }
    this.style = {};
  }
  Object.defineProperty(PdfTreeGridCell.prototype, "height", {
    /**
     * Gets the `height` of the PdfTreeGrid cell.[Read-Only].
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (this.cellHeight === 0) {
        this.cellHeight = this.measureHeight();
      }
      return this.cellHeight;
    },
    set: function (value) {
      this.cellHeight = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridCell.prototype, "width", {
    /**
     * Gets the `width` of the PdfTreeGrid cell.[Read-Only].
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (this.cellWidth === 0) {
        this.cellWidth = this.measureWidth();
      }
      return Math.round(this.cellWidth);
    },
    set: function (value) {
      this.cellWidth = value;
    },
    enumerable: true,
    configurable: true
  });
  PdfTreeGridCell.prototype.measureWidth = function () {
    var width = 0;
    var layouter = new PdfStringLayouter();
    if (typeof this.value === 'string') {
      /* eslint-disable-next-line */
      var font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, this.style.fontStyle);
      if (this.row.isParentRow) {
        font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, PdfFontStyle.Bold);
      } else {
        font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, this.style.fontStyle);
      }
      if (this.row.treegrid.ganttStyle.font) {
        font = this.row.treegrid.ganttStyle.font;
      }
      /* eslint-disable-next-line */
      var slr = layouter.layout(this.value, font, this.style.format, new SizeF(Number.MAX_VALUE, Number.MAX_VALUE), false, new SizeF(0, 0));
      width += slr.actualSize.width;
      width += (this.style.borders.left.width + this.style.borders.right.width) * 2;
    }
    if (typeof this.row.treegrid.style.cellPadding.left !== 'undefined' && this.row.treegrid.style.cellPadding.hasLeftPad) {
      width += this.row.treegrid.style.cellPadding.left;
    }
    if (typeof this.row.treegrid.style.cellPadding.right !== 'undefined' && this.row.treegrid.style.cellPadding.hasRightPad) {
      width += this.row.treegrid.style.cellPadding.right;
    }
    width += this.row.treegrid.style.cellSpacing;
    return width;
  };
  /**
   * @returns {number} .
   * @private
   */
  /* eslint-disable */
  PdfTreeGridCell.prototype.measureHeight = function () {
    var rowHeight = this.row.treegrid.rowHeight;
    var height = 0;
    var width = this.calculateWidth();
    width -= this.row.treegrid.style.cellPadding.right + this.row.treegrid.style.cellPadding.left;
    width -= this.style.borders ? this.style.borders.left.width + this.style.borders.right.width : 0;
    var layouter = new PdfStringLayouter();
    if (typeof this.value === 'string' || typeof this.remainingString === 'string') {
      var currentValue = this.value;
      if (!this.finishedDrawingCell) {
        currentValue = !(isNullOrUndefined(this.remainingString) || this.remainingString === '') ? this.remainingString : this.value;
      }
      var font = null;
      if (this.row.isParentRow) {
        font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, PdfFontStyle.Bold);
      } else {
        font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, this.style.fontStyle);
      }
      if (this.row.treegrid.ganttStyle.font) {
        font = this.row.treegrid.ganttStyle.font;
      }
      /* eslint-disable */
      var slr = layouter.layout(currentValue, font, this.style.format, new SizeF(width - 10, 0), false, new SizeF(0, 0));
      var value = Math.max(slr.lineHeight, slr.actualSize.height);
      if (currentValue.length > 80) {
        height += value + 20;
      } else {
        height += value + slr.size.height / 2;
      }
      height += (this.style.borders.top.width + this.style.borders.bottom.width) * 2;
    }
    height += this.row.treegrid.style.cellPadding.top + this.row.treegrid.style.cellPadding.bottom;
    height += this.row.treegrid.style.cellSpacing;
    return height > rowHeight ? height : rowHeight;
  };
  /* eslint-enable */
  PdfTreeGridCell.prototype.calculateWidth = function () {
    var cellIndex = this.row.cells.indexOf(this);
    var columnSpan = this.columnSpan;
    var width = 0;
    for (var i = 0; i < columnSpan; i++) {
      width += this.row.treegrid.columns.getColumn(cellIndex + i) ? this.row.treegrid.columns.getColumn(cellIndex + i).width : 0;
    }
    if (this.row.treegrid.columns.getColumn(cellIndex) && this.row.treegrid.columns.getColumn(cellIndex).isTreeColumn) {
      width -= this.row.level * 10;
    }
    return width;
  };
  /**
   * `Draws` the specified graphics.
   *
   * @param {PdfGraphics} graphics .
   * @param {RectangleF} bounds .
   * @param {boolean} cancelSubsequentSpans .
   * @param {number} leftAdjustment .
   * @returns {PdfStringLayoutResult} .
   * @private
   */
  PdfTreeGridCell.prototype.draw = function (graphics, bounds, cancelSubsequentSpans, leftAdjustment) {
    var result = null;
    var padding = 10;
    // if (cancelSubsequentSpans) {
    //     // Cancel all subsequent cell spans, if no space exists.
    //     const currentCellIndex: number = this.row.cells.indexOf(this);
    //     for (let i: number = currentCellIndex + 1; i <= currentCellIndex + this.columnSpan; i++) {
    //         this.row.cells.getCell(i).isCellMergeContinue = false;
    //         this.row.cells.getCell(i).isRowMergeContinue = false;
    //     }
    //     this.columnSpan = 1;
    // }
    // Skip cells which were already covered by span map.
    // if (this.isCellMergeContinue || this.isRowMergeContinue) {
    //     if (this.isCellMergeContinue && this.row.treegrid.style.allowHorizontalOverflow) {
    //         if ((this.row.rowOverflowIndex > 0 && (this.row.cells.indexOf(this) !== this.row.rowOverflowIndex + 1)) ||
    //             (this.row.rowOverflowIndex === 0 && this.isCellMergeContinue)) {
    //             return result;
    //         } else {
    //             return result;
    //         }
    //     }
    // }
    //bounds = this.adjustContentLayoutArea(bounds);
    this.drawCellBackground(graphics, bounds);
    var textPen = null;
    var textBrush = new PdfSolidBrush(this.style.fontColor);
    var font = null;
    if (this.row.isParentRow) {
      var fontStyle = PdfFontStyle.Bold;
      if (this.style.fontStyle) {
        fontStyle = this.style.fontStyle;
      }
      font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, fontStyle);
    } else {
      font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, this.style.fontStyle);
    }
    if (this.row.treegrid.ganttStyle.font) {
      font = this.row.treegrid.ganttStyle.font;
    }
    var innerLayoutArea = bounds;
    if (!this.isHeaderCell) {
      /* eslint-disable-next-line */
      innerLayoutArea.x = innerLayoutArea.x;
      /* eslint-disable-next-line */
      innerLayoutArea.width = innerLayoutArea.width;
    }
    if (innerLayoutArea.height >= graphics.clientSize.height) {
      // To break row to next page
      if (this.row.treegrid.allowRowBreakAcrossPages) {
        innerLayoutArea.height -= innerLayoutArea.y;
        bounds.height -= bounds.y;
      } else {
        innerLayoutArea.height = graphics.clientSize.height;
        bounds.height = graphics.clientSize.height;
      }
    }
    innerLayoutArea = this.adjustContentLayoutArea(innerLayoutArea);
    if (this.image && this.value) {
      var imageBounds = void 0;
      if (this.image.width <= innerLayoutArea.width) {
        imageBounds = new RectangleF(innerLayoutArea.x, innerLayoutArea.y, this.image.width, this.image.height);
      } else {
        imageBounds = innerLayoutArea;
      }
      graphics.drawImage(this.image, imageBounds.x, imageBounds.y - 5, imageBounds.width, imageBounds.height);
      var temp = null;
      // font = new PdfStandardFont(this.row.treegrid.ganttStyle.fontFamily, this.style.fontSize, this.style.fontStyle);
      var customisedFont = void 0;
      var fontStyles = this.fontStyle;
      if (!fontStyles) {
        fontStyles = this.style;
      }
      var newFont = new PdfStandardFont(fontStyles.fontFamily, fontStyles.fontSize, fontStyles.fontStyle);
      if (fontStyles.fontFamily) {
        customisedFont = newFont;
      } else {
        customisedFont = font;
      }
      var customisedBrush = textBrush;
      if (this.row.treegrid.ganttStyle.cell.fontBrush) {
        customisedBrush = new PdfSolidBrush(this.row.treegrid.ganttStyle.cell.fontBrush);
      }
      if (this.row.treegrid.ganttStyle.cell.format.alignment && !this.isHeaderCell) {
        this.style.format.alignment = this.row.treegrid.ganttStyle.cell.format.alignment;
      }
      if (this.finishedDrawingCell) {
        temp = this.remainingString === '' ? this.remainingString : this.value;
        /* eslint-disable-next-line */
        graphics.drawString(temp, customisedFont, textPen, customisedBrush, innerLayoutArea.x + leftAdjustment + (!isNullOrUndefined(this.image) ? this.image.width : 0), this.isHeaderCell ? innerLayoutArea.y - 16 : innerLayoutArea.y, innerLayoutArea.width - leftAdjustment - padding, innerLayoutArea.height - padding, this.style.format);
      } else {
        /* eslint-disable-next-line */
        graphics.drawString(this.remainingString, customisedFont, textPen, customisedBrush, innerLayoutArea.x + leftAdjustment, this.isHeaderCell ? innerLayoutArea.y - 16 : innerLayoutArea.y, this.style.format);
      }
      result = graphics.stringLayoutResult;
    } else if (typeof this.value === 'string' || typeof this.remainingString === 'string') {
      var temp = null;
      var customisedFont = void 0;
      var newFont = new PdfStandardFont(this.style.fontFamily, this.style.fontSize, this.style.fontStyle);
      if (this.style.fontFamily) {
        customisedFont = newFont;
      } else {
        customisedFont = font;
      }
      var customisedBrush = textBrush;
      if (this.row.treegrid.ganttStyle.cell.fontBrush) {
        customisedBrush = new PdfSolidBrush(this.row.treegrid.ganttStyle.cell.fontBrush);
      }
      if (this.row.treegrid.ganttStyle.cell.format.alignment && !this.isHeaderCell) {
        this.style.format.alignment = this.row.treegrid.ganttStyle.cell.format.alignment;
      }
      if (this.finishedDrawingCell) {
        temp = this.remainingString === '' ? this.remainingString : this.value;
        /* eslint-disable-next-line */
        graphics.drawString(temp, customisedFont, textPen, customisedBrush, innerLayoutArea.x + leftAdjustment, this.isHeaderCell ? innerLayoutArea.y - 16 : innerLayoutArea.y - 3, innerLayoutArea.width - leftAdjustment - padding, innerLayoutArea.height - padding, this.style.format);
      } else {
        /* eslint-disable-next-line */
        graphics.drawString(this.remainingString, customisedFont, textPen, customisedBrush, innerLayoutArea.x + leftAdjustment, this.isHeaderCell ? innerLayoutArea.y - 16 : innerLayoutArea.y, this.style.format);
      }
      result = graphics.stringLayoutResult;
    } else if (this.value instanceof PdfImage || this.value instanceof PdfBitmap) {
      var imageBounds = void 0;
      if (this.value.width <= innerLayoutArea.width) {
        imageBounds = new RectangleF(innerLayoutArea.x, innerLayoutArea.y, this.value.width, innerLayoutArea.height);
      } else {
        imageBounds = innerLayoutArea;
      }
      graphics.drawImage(this.value, imageBounds.x, imageBounds.y - 10, imageBounds.width, imageBounds.height);
    } else if (this.value instanceof PdfTextWebLink) {
      this.value.draw(graphics.currentPage, innerLayoutArea);
    }
    if (this.style.borders !== null) {
      this.drawCellBorder(graphics, bounds);
    }
    return result;
  };
  /**
   * Draw the `cell background`.
   *
   * @param {PdfGraphics} graphics .
   * @param {RectangleF} bounds .
   * @returns {void} .
   * @private
   */
  PdfTreeGridCell.prototype.drawCellBackground = function (graphics, bounds) {
    var backgroundBrush = new PdfSolidBrush(this.style.backgroundColor);
    if (backgroundBrush !== null) {
      graphics.save();
      graphics.drawRectangle(backgroundBrush, bounds.x, bounds.y, bounds.width, bounds.height);
      graphics.restore();
    }
    // if (this.style.backgroundImage !== null) {
    //     let image: PdfImage = this.getBackgroundImage();
    //     graphics.drawImage(this.style.backgroundImage, bounds.x, bounds.y, bounds.width, bounds.height);
    // }
  };
  /**
   * `Adjusts the text layout area`.
   *
   * @param {RectangleF} bounds .
   * @returns {RectangleF} .
   * @private
   */
  PdfTreeGridCell.prototype.adjustContentLayoutArea = function (bounds) {
    //Add Padding value to its Cell Bounds
    var returnBounds = new RectangleF(new PointF(bounds.x, bounds.y), new SizeF(bounds.width, bounds.height));
    var cellPadding = this.style.padding;
    if (this.value instanceof PdfTreeGrid) {
      var size = this.value.size;
      if (this.style.format.alignment === PdfTextAlignment.Center) {
        returnBounds.x += cellPadding.left + (returnBounds.width - size.width) / 2;
        returnBounds.y += cellPadding.top + (returnBounds.height - size.height) / 2;
      } else if (this.style.format.alignment === PdfTextAlignment.Left) {
        returnBounds.x += cellPadding.left;
        returnBounds.y += cellPadding.top;
      } else if (this.style.format.alignment === PdfTextAlignment.Right) {
        returnBounds.x += cellPadding.left + (returnBounds.width - size.width);
        returnBounds.y += cellPadding.top;
      }
    } else {
      returnBounds.x += cellPadding.left;
      returnBounds.y += cellPadding.top;
    }
    return returnBounds;
  };
  /**
   * @param {PdfGraphics} graphics .
   * @param {RectangleF} bounds .
   * @returns {void} .
   * @private
   */
  PdfTreeGridCell.prototype.drawCellBorder = function (graphics, bounds) {
    if (this.row.treegrid.style.borderOverlapStyle === PdfBorderOverlapStyle.Inside) {
      bounds.x += this.style.borders.left.width;
      bounds.y += this.style.borders.top.width;
      bounds.width -= this.style.borders.right.width;
      bounds.height -= this.style.borders.bottom.width;
    }
    if (this.style.borders.isAll && this.isHeaderCell) {
      graphics.drawRectangle(this.style.borders.left, bounds.x, bounds.y, bounds.width, bounds.height);
      graphics.restore();
      return;
    } else {
      var p1 = new PointF(bounds.x, bounds.y + bounds.height);
      var p2 = new PointF(bounds.x, bounds.y);
      var pen = this.style.borders.left;
      pen.lineCap = this.style.borders.left.dashStyle === PdfDashStyle.Solid ? PdfLineCap.Square : pen.lineCap;
      graphics.drawLine(pen, p1, p2);
      graphics.restore();
      p1 = new PointF(bounds.x + bounds.width, bounds.y);
      p2 = new PointF(bounds.x + bounds.width, bounds.y + bounds.height);
      pen = this.style.borders.right;
      if (bounds.x + bounds.width > graphics.clientSize.width - pen.width / 2) {
        p1 = new PointF(graphics.clientSize.width - pen.width / 2, bounds.y);
        p2 = new PointF(graphics.clientSize.width - pen.width / 2, bounds.y + bounds.height);
      }
      pen.lineCap = this.style.borders.right.dashStyle === PdfDashStyle.Solid ? PdfLineCap.Square : pen.lineCap;
      graphics.drawLine(pen, p1, p2);
      graphics.restore();
      p1 = new PointF(bounds.x, bounds.y);
      p2 = new PointF(bounds.x + bounds.width, bounds.y);
      pen = this.style.borders.top;
      pen.lineCap = this.style.borders.top.dashStyle === PdfDashStyle.Solid ? PdfLineCap.Square : pen.lineCap;
      graphics.drawLine(pen, p1, p2);
      graphics.restore();
      p1 = new PointF(bounds.x + bounds.width, bounds.y + bounds.height);
      p2 = new PointF(bounds.x, bounds.y + bounds.height);
      pen = this.style.borders.bottom;
      if (bounds.y + bounds.height > graphics.clientSize.height - pen.width / 2) {
        p1 = new PointF(bounds.x + bounds.width, graphics.clientSize.height - pen.width / 2);
        p2 = new PointF(bounds.x, graphics.clientSize.height - pen.width / 2);
      }
      pen.lineCap = this.style.borders.bottom.dashStyle === PdfDashStyle.Solid ? PdfLineCap.Square : pen.lineCap;
      graphics.drawLine(pen, p1, p2);
      graphics.restore();
    }
  };
  return PdfTreeGridCell;
}();
/**
 * `PdfTreeGridCellCollection` class provides access to an ordered,
 * strongly typed collection of 'PdfTreeGridCell' objects.
 *
 * @private
 */
var PdfTreeGridCellCollection = /** @__PURE__ @class */function () {
  //Constructor
  /**
   * Initializes a new instance of the `PdfGridCellCollection` class with the row.
   *
   * @param { PdfTreeGridRow} row .
   * @private
   */
  function PdfTreeGridCellCollection(row) {
    this.treegridRow = row;
    this.cells = [];
  }
  //Properties
  /**
   * Gets the current `cell`.
   *
   * @param {number} index .
   * @returns {PdfTreeGridCell} .
   * @private
   */
  PdfTreeGridCellCollection.prototype.getCell = function (index) {
    if (index < 0 || index >= this.count) {
      throw new Error('IndexOutOfRangeException');
    }
    return this.cells[index];
  };
  Object.defineProperty(PdfTreeGridCellCollection.prototype, "count", {
    /**
     * Gets the cells `count`.[Read-Only].
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.cells.length;
    },
    enumerable: true,
    configurable: true
  });
  //Implementation
  /**
   * `Adds` this instance.
   *
   * @param {PdfTreeGridCell} cell .
   * @returns {PdfTreeGridCell | void} .
   * @private
   */
  PdfTreeGridCellCollection.prototype.add = function (cell) {
    if (typeof cell === 'undefined') {
      var tempcell = new PdfTreeGridCell();
      this.add(tempcell);
      return cell;
    } else {
      cell.row = this.treegridRow;
      this.cells.push(cell);
    }
  };
  /**
   * Returns the `index of` a particular cell in the collection.
   *
   * @param {PdfTreeGridCell} cell .
   * @returns {number} .
   * @private
   */
  PdfTreeGridCellCollection.prototype.indexOf = function (cell) {
    return this.cells.indexOf(cell);
  };
  return PdfTreeGridCellCollection;
}();
/**
 *
 */
var PdfTreeGridRow = /** @__PURE__ @class */function () {
  function PdfTreeGridRow(treegrid) {
    this.treegridRowOverflowIndex = 0;
    this.rowHeight = 0;
    this.rowWidth = 0;
    /* eslint-disable-next-line */
    this._isParentRow = false;
    this.intendLevel = 0;
    this.pdfTreeGrid = treegrid;
  }
  Object.defineProperty(PdfTreeGridRow.prototype, "cells", {
    get: function () {
      if (isNullOrUndefined(this.treegridCells)) {
        this.treegridCells = new PdfTreeGridCellCollection(this);
      }
      return this.treegridCells;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "isParentRow", {
    get: function () {
      return this._isParentRow;
    },
    set: function (value) {
      this._isParentRow = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "treegrid", {
    get: function () {
      return this.pdfTreeGrid;
    },
    set: function (value) {
      this.pdfTreeGrid = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "rowBreakHeight", {
    /**
     * `Height` of the row yet to be drawn after split.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (typeof this.treegridRowBreakHeight === 'undefined') {
        this.treegridRowBreakHeight = 0;
      }
      return this.treegridRowBreakHeight;
    },
    set: function (value) {
      this.treegridRowBreakHeight = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "rowOverflowIndex", {
    /**
     * `over flow index` of the row.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.treegridRowOverflowIndex;
    },
    set: function (value) {
      this.treegridRowOverflowIndex = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "level", {
    get: function () {
      return this.intendLevel;
    },
    set: function (value) {
      this.intendLevel = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "height", {
    /**
     * Gets or sets the `height` of the row.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (this.rowHeight === 0) {
        this.rowHeight = this.measureHeight();
      }
      return this.rowHeight;
    },
    set: function (value) {
      this.rowHeight = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "width", {
    /**
     * Gets or sets the `width` of the row.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (this.rowWidth === 0) {
        this.rowWidth = this.measureWidth();
      }
      return this.rowWidth;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRow.prototype, "rowIndex", {
    get: function () {
      return this.treegrid.rows.rowCollection.indexOf(this);
    },
    enumerable: true,
    configurable: true
  });
  PdfTreeGridRow.prototype.measureWidth = function () {
    var columns = this.treegrid.columns.columns;
    var totalWidth = 0;
    for (var i = 0; i < columns.length; i++) {
      var column = columns[i];
      totalWidth += column.width;
    }
    return totalWidth;
  };
  PdfTreeGridRow.prototype.measureHeight = function () {
    var rowHeight = this.cells.count !== 0 && !isNullOrUndefined(this.cells.getCell(0).height) ? this.cells.getCell(0).height : 33;
    for (var i = 0; i < this.cells.count; i++) {
      var cell = this.cells.getCell(i);
      if (cell.columnSpan === 1 || cell.rowSpan === 1) {
        rowHeight = Math.max(rowHeight, cell.height);
      } else {
        rowHeight = Math.min(rowHeight, cell.height);
      }
      cell.height = rowHeight;
    }
    return rowHeight;
  };
  return PdfTreeGridRow;
}();
/**
 * `PdfTreeGridRowCollection` class provides access to an ordered, strongly typed collection of 'PdfTreeGridRow' objects.
 *
 * @private
 */
var PdfTreeGridRowCollection = /** @__PURE__ @class */function () {
  // Constructor
  /**
   * Initializes a new instance of the `PdfTreeGridRowCollection` class with the parent grid.
   *
   * @param {PdfTreeGrid} treegrid .
   * @private
   */
  function PdfTreeGridRowCollection(treegrid) {
    this.rows = [];
    this.treegrid = treegrid;
  }
  Object.defineProperty(PdfTreeGridRowCollection.prototype, "count", {
    //Properties
    /**
     * Gets the number of header in the `PdfTreeGrid`.[Read-Only].
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.rows.length;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridRowCollection.prototype, "rowCollection", {
    //Implementation
    /**
     * Return the row collection of the `treegrid`.
     *
     * @returns {PdfTreeGridRow[]} .
     * @private
     */
    get: function () {
      return this.rows;
    },
    enumerable: true,
    configurable: true
  });
  PdfTreeGridRowCollection.prototype.addRow = function (row) {
    if (typeof row === 'undefined') {
      var row_1 = new PdfTreeGridRow(this.treegrid);
      this.addRow(row_1);
      return row_1;
    } else {
      if (row.cells.count === 0) {
        for (var i = 0; i < this.treegrid.columns.count; i++) {
          row.cells.add(new PdfTreeGridCell());
        }
      }
      this.rows.push(row);
    }
  };
  /**
   * Return the row by index.
   *
   * @param {number} index .
   * @returns {PdfTreeGridRow} .
   * @private
   */
  PdfTreeGridRowCollection.prototype.getRow = function (index) {
    return this.rows[index];
  };
  return PdfTreeGridRowCollection;
}();
/**
 * `PdfTreeGridHeaderCollection` class provides customization of the settings for the header.
 *
 * @private
 */
var PdfTreeGridHeaderCollection = /** @__PURE__ @class */function () {
  //constructor
  /**
   * Initializes a new instance of the `PdfTreeGridHeaderCollection` class with the parent grid.
   *
   * @param {PdfTreeGrid} treegrid .
   * @private
   */
  function PdfTreeGridHeaderCollection(treegrid) {
    /**
     * The array to store the `rows` of the grid header.
     *
     * @returns {PdfTreeGridRow[]} .
     * @private
     */
    this.rows = [];
    this.treegrid = treegrid;
    this.rows = [];
  }
  //Properties
  /**
   * Gets a 'PdfTreeGridRow' object that represents the `header` row in a 'PdfGridHeaderCollection' control.[Read-Only].
   *
   * @param {number} index .
   * @returns {PdfTreeGridRow} .
   * @private
   */
  PdfTreeGridHeaderCollection.prototype.getHeader = function (index) {
    return this.rows[index];
  };
  Object.defineProperty(PdfTreeGridHeaderCollection.prototype, "count", {
    /**
     * Gets the `number of header` in the 'PdfGrid'.[Read-Only]
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.rows.length;
    },
    enumerable: true,
    configurable: true
  });
  //Implementation
  /**
   * `Adds` the specified row.
   *
   * @param {PdfTreeGridRow} row .
   * @returns {void} .
   * @private
   */
  PdfTreeGridHeaderCollection.prototype.add = function (row) {
    this.rows.push(row);
  };
  PdfTreeGridHeaderCollection.prototype.indexOf = function (row) {
    return this.rows.indexOf(row);
  };
  return PdfTreeGridHeaderCollection;
}();
var PdfTreeGridColumn = /** @__PURE__ @class */function () {
  function PdfTreeGridColumn(treegrid) {
    this.columnWidth = 0;
    this.treeColumnIndex = false;
    this._headerText = '';
    this._field = '';
    this.treegrid = treegrid;
  }
  Object.defineProperty(PdfTreeGridColumn.prototype, "headerText", {
    get: function () {
      return this._headerText;
    },
    set: function (value) {
      this._headerText = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumn.prototype, "field", {
    get: function () {
      return this._field;
    },
    set: function (value) {
      this._field = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumn.prototype, "width", {
    get: function () {
      return this.columnWidth;
    },
    set: function (value) {
      this.columnWidth = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumn.prototype, "isTreeColumn", {
    get: function () {
      return this.treeColumnIndex;
    },
    set: function (value) {
      this.treeColumnIndex = value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumn.prototype, "format", {
    /**
     * Gets or sets the information about the text `formatting`.
     *
     * @returns {PdfStringFormat} .
     * @private
     */
    get: function () {
      if (isNullOrUndefined(this.stringFormat)) {
        this.stringFormat = new PdfStringFormat(); //GetDefaultFormat();
      }
      return this.stringFormat;
    },
    set: function (value) {
      this.stringFormat = value;
    },
    enumerable: true,
    configurable: true
  });
  return PdfTreeGridColumn;
}();
/**
 * `PdfTreeGridColumnCollection` class provides access to an ordered,
 * strongly typed collection of 'PdfTreeGridColumn' objects.
 *
 * @private
 */
var PdfTreeGridColumnCollection = /** @__PURE__ @class */function () {
  //properties
  //Constructors
  /**
   * Initializes a new instance of the `PdfTreeGridColumnCollection` class with the parent grid.
   *
   * @param { PdfTreeGrid} treegrid .
   * @private
   */
  function PdfTreeGridColumnCollection(treegrid) {
    /**
     * @private
     */
    this.internalColumns = [];
    /**
     * @private
     */
    this.columnWidth = 0;
    this.treegrid = treegrid;
    this.internalColumns = [];
  }
  //Implementation
  /**
   * `Add` a new column to the 'PdfGrid'.
   *
   * @param {number} count .
   * @returns {void} .
   * @private
   */
  PdfTreeGridColumnCollection.prototype.add = function (count) {
    // public add(column : PdfGridColumn) : void
    // public add(arg : number|PdfGridColumn) : void {
    // if (typeof arg === 'number') {
    for (var i = 0; i < count; i++) {
      this.internalColumns.push(new PdfTreeGridColumn(this.treegrid));
      for (var index = 0; index < this.treegrid.rows.count; index++) {
        var row = this.treegrid.rows.getRow(index);
        var cell = new PdfTreeGridCell();
        cell.value = '';
        row.cells.add(cell);
      }
    }
    // } else {
    //     let column : PdfGridColumn = new PdfGridColumn(this.grid);
    //     this.columns.push(column);
    //     return column;
    // }
  };
  Object.defineProperty(PdfTreeGridColumnCollection.prototype, "count", {
    /**
     * Gets the `number of columns` in the 'PdfGrid'.[Read-Only].
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      return this.internalColumns.length;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumnCollection.prototype, "width", {
    /**
     * Gets the `widths`.
     *
     * @returns {number} .
     * @private
     */
    get: function () {
      if (this.columnWidth === 0) {
        this.columnWidth = this.measureColumnsWidth();
      }
      return this.columnWidth;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PdfTreeGridColumnCollection.prototype, "columns", {
    /**
     * Gets the `array of PdfGridColumn`.[Read-Only]
     *
     * @returns {PdfTreeGridColumn[]} .
     * @private
     */
    get: function () {
      return this.internalColumns;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * Gets the `PdfTreeGridColumn` from the specified index.[Read-Only]
   *
   * @param {number} index .
   * @returns {PdfTreeGridColumn} .
   * @private
   */
  PdfTreeGridColumnCollection.prototype.getColumn = function (index) {
    if (index >= 0 && index <= this.columns.length) {
      return this.columns[index];
    } else {
      throw Error('can not get the column from the index: ' + index);
    }
  };
  //Implementation
  /**
   * `Calculates the column widths`.
   *
   * @returns {number} .
   * @private
   */
  PdfTreeGridColumnCollection.prototype.measureColumnsWidth = function () {
    var totalWidth = 0;
    this.treegrid.measureColumnsWidth();
    for (var i = 0, count = this.internalColumns.length; i < count; i++) {
      totalWidth += this.internalColumns[i].width;
    }
    return totalWidth;
  };
  /**
   * Gets the `widths of the columns`.
   *
   * @param {number} totalWidth .
   * @returns {number} .
   * @private
   */
  PdfTreeGridColumnCollection.prototype.getDefaultWidths = function (totalWidth) {
    var widths = [];
    var subFactor = this.count;
    for (var i = 0; i < this.count; i++) {
      widths[i] = this.internalColumns[i].width;
      if (this.internalColumns[i].width > 0) {
        totalWidth -= this.internalColumns[i].width;
        subFactor--;
      } else {
        widths[i] = 0;
      }
    }
    for (var i = 0; i < this.count; i++) {
      var width = totalWidth / subFactor;
      if (widths[i] <= 0) {
        widths[i] = width;
      }
    }
    return widths;
  };
  return PdfTreeGridColumnCollection;
}();
var __assign$7 = undefined && undefined.__assign || function () {
  __assign$7 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$7.apply(this, arguments);
};
/**
 * @hidden
 * `ExportHelper` for `PdfExport` & `ExcelExport`
 */
var ExportHelper = /** @__PURE__ @class */function () {
  function ExportHelper(parent) {
    this.totalColumnWidth = 0;
    this.beforeSinglePageExport = {};
    this.baselineHeight = 8;
    this.parent = parent;
  }
  ExportHelper.prototype.processToFit = function () {
    this.beforeSinglePageExport['zoomingProjectStartDate'] = this.parent.zoomingProjectStartDate;
    this.beforeSinglePageExport['zoomingProjectEndDate'] = this.parent.zoomingProjectEndDate;
    this.beforeSinglePageExport['cloneProjectStartDate'] = this.parent.cloneProjectStartDate;
    this.beforeSinglePageExport['cloneProjectEndDate'] = this.parent.cloneProjectEndDate;
    this.beforeSinglePageExport['customTimelineSettings'] = extend({}, this.parent.timelineModule.customTimelineSettings, null, true);
    this.beforeSinglePageExport['isTimelineRoundOff'] = this.parent.isTimelineRoundOff;
    this.beforeSinglePageExport['topTier'] = this.parent.timelineModule.topTier;
    this.beforeSinglePageExport['topTierCellWidth'] = this.parent.timelineModule.topTierCellWidth;
    this.beforeSinglePageExport['topTierCollection'] = this.parent.timelineModule.topTierCollection;
    this.beforeSinglePageExport['bottomTier'] = this.parent.timelineModule.bottomTier;
    this.beforeSinglePageExport['bottomTierCellWidth'] = this.parent.timelineModule.bottomTierCellWidth;
    this.beforeSinglePageExport['bottomTierCollection'] = this.parent.timelineModule.bottomTierCollection;
    this.beforeSinglePageExport['totalTimelineWidth'] = this.parent.timelineModule.totalTimelineWidth;
    this.beforeSinglePageExport['timelineStartDate'] = this.parent.timelineModule.timelineStartDate;
    this.beforeSinglePageExport['timelineEndDate'] = this.parent.timelineModule.timelineEndDate;
    this.beforeSinglePageExport['timelineRoundOffEndDate'] = this.parent.timelineModule.timelineRoundOffEndDate;
    this.beforeSinglePageExport['perDayWidth'] = this.parent.perDayWidth;
    this.beforeSinglePageExport['updatedConnectorLineCollection'] = extend([], this.parent.updatedConnectorLineCollection, null, true);
    this.parent.timelineModule.isZoomToFit = true;
    this.parent.timelineModule.isZooming = false;
    if (!this.parent.zoomingProjectStartDate) {
      this.parent.zoomingProjectStartDate = this.parent.cloneProjectStartDate;
      this.parent.zoomingProjectEndDate = this.parent.cloneProjectEndDate;
    }
    if (this.parent.zoomingProjectStartDate > this.parent.cloneProjectStartDate) {
      this.parent.cloneProjectStartDate = new Date(this.parent.allowUnscheduledTasks ? this.parent.zoomingProjectStartDate : this.parent.cloneProjectStartDate);
    }
    this.parent.dataOperation.calculateProjectDates();
    var timeDifference = this.parent.cloneProjectEndDate.getTime() - this.parent.cloneProjectStartDate.getTime();
    var totalDays = timeDifference / (1000 * 3600 * 24);
    var chartsideWidth;
    var gridWidth;
    if (this.exportProps.fitToWidthSettings.gridWidth) {
      gridWidth = parseInt(this.exportProps.fitToWidthSettings.gridWidth.split('%')[0], 10);
    }
    if (this.exportProps.fitToWidthSettings.chartWidth) {
      chartsideWidth = parseInt(this.exportProps.fitToWidthSettings.chartWidth.split('%')[0], 10);
    } else {
      if (this.exportProps.fitToWidthSettings.gridWidth) {
        chartsideWidth = 100 - gridWidth;
      } else {
        chartsideWidth = 70;
      }
    }
    var pdfwidth = this.parent.pdfExportModule['pdfPageDimensions'].width * chartsideWidth / 100;
    var chartWidth = pdfwidth;
    var perDayWidth = chartWidth / totalDays;
    var zoomingLevel;
    var firstValue;
    var secondValue;
    var zoomingCollections = this.parent.zoomingLevels.slice();
    var sortedCollectons = zoomingCollections.sort(function (a, b) {
      return !a.perDayWidth && !b.perDayWidth ? 0 : a.perDayWidth < b.perDayWidth ? 1 : -1;
    });
    if (perDayWidth === 0) {
      // return when the Gantt chart is not in viewable state.
      return;
    }
    for (var i = 0; i < sortedCollectons.length; i++) {
      firstValue = sortedCollectons[i];
      if (i === sortedCollectons.length - 1) {
        zoomingLevel = sortedCollectons[i];
        break;
      } else {
        secondValue = sortedCollectons[i + 1];
      }
      if (perDayWidth >= firstValue.perDayWidth) {
        zoomingLevel = sortedCollectons[i];
        break;
      }
      if (perDayWidth < firstValue.perDayWidth && perDayWidth > secondValue.perDayWidth) {
        zoomingLevel = sortedCollectons[i + 1];
        break;
      }
    }
    var newTimeline = extend({}, {}, zoomingLevel, true);
    this.parent.timelineModule['roundOffDateToZoom'](this.parent.cloneProjectStartDate, true, perDayWidth, newTimeline.bottomTier.unit, zoomingLevel);
    this.parent.timelineModule['roundOffDateToZoom'](this.parent.cloneProjectEndDate, false, perDayWidth, newTimeline.bottomTier.unit, zoomingLevel);
    var numberOfCells = this.parent.timelineModule['calculateNumberOfTimelineCells'](newTimeline);
    var scrollHeight = this.parent.pdfExportModule['pdfPageDimensions'].height; //17 is horizontal scrollbar width
    var emptySpace = scrollHeight <= 0 ? 0 : 17;
    newTimeline.timelineUnitSize = Math.abs(chartWidth - emptySpace) / numberOfCells;
    this.parent.timelineModule['changeTimelineSettings'](newTimeline);
    this.parent.timelineModule.isZoomToFit = false;
    this.parent.timelineModule.isZooming = false;
  };
  /**
   * @param {IGanttData[]} data .
   * @param {PdfGantt} gantt .
   * @param {PdfExportProperties} props .
   * @returns {void} .
   * @private
   */
  ExportHelper.prototype.processGridExport = function (data, gantt, props) {
    this.flatData = data;
    this.gantt = gantt;
    this.exportValueFormatter = new ExportValueFormatter(this.parent.locale);
    this.exportProps = props;
    this.rowIndex = 0;
    this.colIndex = 0;
    this.columns = this.parent.treeGrid.columns;
    this.gantt.treeColumnIndex = this.parent.treeColumnIndex;
    this.gantt.rowHeight = pixelToPoint(this.parent.rowHeight);
    this.gantt.style.cellPadding.left = 0;
    this.gantt.style.cellPadding.right = 0;
    this.ganttStyle = this.gantt.ganttStyle;
    this.gantt.borderColor = this.ganttStyle.chartGridLineColor;
    this.parent.pdfExportModule.isPdfExport = true;
    if (this.exportProps.fitToWidthSettings && this.exportProps.fitToWidthSettings.isFitToWidth) {
      this.processToFit();
    }
    this.processHeaderContent();
    this.processGanttContent();
    this.processTimeline();
    this.processTaskbar();
    this.processPredecessor();
    this.parent.pdfExportModule.isPdfExport = false;
  };
  ExportHelper.prototype.processHeaderContent = function () {
    var _this = this;
    this.rowIndex++;
    this.row = this.gantt.rows.addRow();
    var index = 0;
    this.columns.forEach(function (column) {
      if (_this.isColumnVisible(column)) {
        _this.processColumnHeader(column, index);
        index++;
      }
    });
  };
  ExportHelper.prototype.processColumnHeader = function (column, index) {
    this.gantt.columns.add(1);
    var pdfColumn = this.gantt.columns.getColumn(index);
    if (this.parent.treeColumnIndex === index) {
      pdfColumn.isTreeColumn = true;
    }
    var width = parseInt(column.width, 10);
    pdfColumn.width = pixelToPoint(width);
    this.totalColumnWidth += pdfColumn.width;
    pdfColumn.headerText = column.headerText;
    pdfColumn.field = column.field;
    var cell = this.row.cells.getCell(index);
    cell.value = column.headerText;
    cell.isHeaderCell = true;
    if (this.ganttStyle && this.ganttStyle.columnHeader && !isNullOrUndefined(this.ganttStyle.columnHeader.fontBrush)) {
      cell.style.fontBrush = new PdfColor(this.ganttStyle.columnHeader.fontBrush);
    }
    var treeGridHeaderHeight = this.parent.timelineModule.isSingleTier ? 45 : 60;
    this.copyStyles(this.ganttStyle.columnHeader, cell, false);
    this.row.height = pixelToPoint(treeGridHeaderHeight);
    if (column.headerTextAlign) {
      cell.style.format.alignment = PdfTextAlignment[column.headerTextAlign];
    }
    var template = {
      image: null,
      value: null,
      fontStyle: {
        fontBrush: null
      }
    };
    cell.fontStyle = {
      fontSize: 9
    };
    var args = {
      cell: cell,
      style: cell.style,
      value: cell.value,
      column: column,
      image: null,
      headerTemplate: template
    };
    if (this.parent.pdfColumnHeaderQueryCellInfo) {
      this.parent.trigger('pdfColumnHeaderQueryCellInfo', args);
    }
    if (args.headerTemplate.image && args.headerTemplate.value) {
      args.image = new PdfBitmap(args.headerTemplate.image[0].base64);
      args.image.height = args.headerTemplate.image[0].height || args.image.height;
      args.image.width = args.headerTemplate.image[0].width || args.image.width;
      cell.image = args.image;
      cell.value = args.headerTemplate.value;
      cell.fontStyle.fontSize = args.headerTemplate.fontStyle.fontSize;
      cell.fontStyle.fontFamily = args.headerTemplate.fontStyle.fontFamily;
      cell.fontStyle.fontBrush = args.headerTemplate.fontStyle.fontColor;
    } else {
      cell.value = args.value;
    }
  };
  ExportHelper.prototype.isColumnVisible = function (column) {
    var visibleColumn = column.visible || this.exportProps.includeHiddenColumn;
    return visibleColumn;
  };
  ExportHelper.prototype.processGanttContent = function () {
    var _this = this;
    if (this.flatData.length === 0) {
      this.renderEmptyGantt();
    } else {
      var flatData = this.flatData;
      flatData.forEach(function (data) {
        _this.row = _this.gantt.rows.addRow();
        if (data.hasChildRecords) {
          _this.gantt.rows.getRow(_this.rowIndex).isParentRow = true;
          _this.processRecordRow(data);
        } else {
          _this.processRecordRow(data);
        }
        _this.rowIndex++;
      });
    }
  };
  /**
   * Method for processing the timeline details
   *
   * @returns {void} .
   */
  ExportHelper.prototype.processTimeline = function () {
    if (this.parent.enableTimelineVirtualization) {
      this.parent.timelineModule.pdfExportTopTierCollection = [];
      this.parent.timelineModule.pdfExportBottomTierCollection = [];
      this.parent.timelineModule.createTimelineSeries();
    }
    var timelineSettings = this.parent.timelineModule;
    this.gantt.chartHeader.topTierHeight = this.gantt.chartHeader.bottomTierHeight = this.parent.timelineModule.isSingleTier ? 45 : 60 / 2;
    this.gantt.chartHeader.topTierCellWidth = timelineSettings.topTierCellWidth;
    this.gantt.chartHeader.bottomTierCellWidth = timelineSettings.bottomTierCellWidth;
    this.gantt.chartHeader.topTier = extend([], [], this.parent.enableTimelineVirtualization ? timelineSettings.pdfExportTopTierCollection : timelineSettings.topTierCollection, true);
    this.gantt.chartHeader.bottomTier = extend([], [], this.parent.enableTimelineVirtualization ? timelineSettings.pdfExportBottomTierCollection : timelineSettings.bottomTierCollection, true);
    if (this.exportProps && this.exportProps.fitToWidthSettings && this.exportProps.fitToWidthSettings.isFitToWidth && this.parent.enableTimelineVirtualization) {
      var tier = timelineSettings.topTier === 'None' ? 'bottomTier' : 'topTier';
      this.gantt.chartHeader.width = timelineSettings['calculateWidthBetweenTwoDate'](tier, timelineSettings.timelineStartDate, timelineSettings.timelineEndDate);
    } else {
      this.gantt.chartHeader.width = this.parent.enableTimelineVirtualization ? this.parent.timelineModule.wholeTimelineWidth : timelineSettings.totalTimelineWidth;
    }
    this.gantt.chartHeader.height = !isNullOrUndefined(this.gantt.rows.getRow(0).height) ? this.gantt.rows.getRow(0).height : 65;
    this.gantt.timelineStartDate = new Date(timelineSettings.timelineStartDate.getTime());
  };
  /**
   * Method for create the predecessor collection for rendering
   *
   * @returns {void} .
   */
  ExportHelper.prototype.processPredecessor = function () {
    var _this = this;
    if (isNullOrUndefined(this.exportProps.showPredecessorLines) || this.exportProps.showPredecessorLines) {
      this.parent.pdfExportModule.isPdfExport = true;
      this.parent.predecessorModule.createConnectorLinesCollection();
      this.parent.updatedConnectorLineCollection.forEach(function (data) {
        var predecessor = _this.gantt.predecessor.add();
        predecessor.parentLeft = data.parentLeft;
        predecessor.childLeft = data.childLeft;
        predecessor.parentWidth = data.parentWidth;
        predecessor.childWidth = data.childWidth;
        predecessor.parentIndex = data.parentIndex;
        predecessor.childIndex = data.childIndex;
        predecessor.rowHeight = data.rowHeight;
        predecessor.type = data.type;
        predecessor.milestoneParent = data.milestoneParent;
        predecessor.milestoneChild = data.milestoneChild;
        predecessor.parentEndPoint = data.parentEndPoint;
        predecessor.lineWidth = _this.parent.connectorLineWidth > 5 ? pixelToPoint(5) : pixelToPoint(_this.parent.connectorLineWidth);
        if (data.isCritical) {
          predecessor.connectorLineColor = _this.ganttStyle.criticalConnectorLineColor;
        } else {
          predecessor.connectorLineColor = _this.ganttStyle.connectorLineColor;
        }
        _this.gantt.predecessorCollection.push(predecessor);
      });
      this.parent.pdfExportModule.isPdfExport = false;
    }
  };
  ExportHelper.prototype.processRecordRow = function (data) {
    var _this = this;
    this.colIndex = 0;
    this.row.level = data.level;
    this.columns.forEach(function (column) {
      if (_this.isColumnVisible(column)) {
        _this.processRecordCell(data, column, _this.row);
        _this.colIndex++;
      }
    });
  };
  ExportHelper.prototype.processRecordCell = function (data, column, row) {
    var cell = row.cells.getCell(this.colIndex);
    var taskFields = this.parent.taskFields;
    var ganttProps = data.ganttProperties;
    if (column.editType === 'datepickeredit' || column.editType === 'datetimepickeredit') {
      cell.value = data[column.field];
    } else if (column.field === taskFields.duration) {
      cell.value = this.parent.getDurationString(ganttProps.duration, ganttProps.durationUnit);
    } else if (column.field === taskFields.resourceInfo) {
      cell.value = ganttProps.resourceNames;
    } else if (column.field === taskFields.work) {
      cell.value = this.parent.getWorkString(ganttProps.work, ganttProps.workUnit);
    } else {
      cell.value = !isNullOrUndefined(data[column.field]) ? data[column.field].toString() : '';
    }
    var cellValueString = !isNullOrUndefined(cell.value) ? cell.value.toString() : '';
    var cellValue = cellValueString;
    var value = !isNullOrUndefined(cellValue) ? cellValue : '';
    cell.isHeaderCell = false;
    cell.style.padding = new PdfPaddings();
    this.copyStyles(this.ganttStyle.cell, cell, row.isParentRow);
    if (column['index'] !== this.parent.treeColumnIndex) {
      cell.style.format.alignment = PdfTextAlignment[column.textAlign];
    } else {
      cell.style.format.paragraphIndent = cell.row.level * 10;
    }
    var args = {
      data: data,
      value: value,
      column: column,
      style: cell.style,
      cell: cell
    };
    args.value = this.exportValueFormatter.formatCellValue(args);
    if (this.parent.pdfQueryCellInfo) {
      this.parent.trigger('pdfQueryCellInfo', args);
      if (args.style.backgroundColor) {
        cell.style.backgroundColor = args.style.backgroundColor;
      }
      if (args.style.borderColor) {
        cell.style.borderColor = args.style.borderColor;
      }
      if (args.style.fontBrush) {
        cell.style.fontBrush = args.style.fontBrush;
      }
      if (args.style.fontColor) {
        cell.style.fontColor = args.style.fontColor;
      }
      if (args.style.fontFamily) {
        var font = args.style.fontFamily;
        var fontFamily = this.getFontFamily(font);
        cell.style.fontFamily = fontFamily;
      } else {
        cell.style.fontFamily = this.ganttStyle.fontFamily;
      }
      if (args.style.fontSize) {
        cell.style.fontSize = args.style.fontSize;
      }
      if (args.style.fontStyle) {
        var style = args.style.fontStyle;
        var fontStyle = this.getFontStyle(style);
        cell.style.fontStyle = fontStyle;
      } else {
        cell.style.fontStyle = this.ganttStyle.footer.fontStyle;
      }
      if (args.style.format) {
        cell.style.format = args.style.format;
      }
      if (args.style.padding) {
        cell.style.padding = args.style.padding;
      }
    }
    if (!isNullOrUndefined(args.image) && !isNullOrUndefined(args.image.base64)) {
      var dimension = extend({}, args.image, null, true);
      args.image = new PdfBitmap(args.image.base64);
      args.image.height = dimension['height'] ? dimension['height'] : args.image.height;
      args.image.width = dimension['width'] ? dimension['width'] : args.image.width;
      cell.image = args.image;
    }
    cell.value = args.value;
    if (!isNullOrUndefined(args.hyperLink) && (!isNullOrUndefined(args.hyperLink.displayText) || !isNullOrUndefined(args.hyperLink.target))) {
      cell.value = this.setHyperLink(args);
    }
  };
  ExportHelper.prototype.setHyperLink = function (args) {
    // create the Text Web Link
    var textLink = new PdfTextWebLink();
    // set the hyperlink
    textLink.url = args.hyperLink.target;
    // set the link text
    textLink.text = args.hyperLink.displayText || args.hyperLink.target;
    // set the font
    textLink.font = new PdfStandardFont(PdfFontFamily.Helvetica, 9.75);
    // set the brush and pen for the text color
    textLink.brush = new PdfSolidBrush(new PdfColor(51, 102, 187));
    return textLink;
  };
  /**
   * Method for create the taskbar collection for rendering
   *
   * @returns {void} .
   */
  ExportHelper.prototype.processTaskbar = function () {
    var _this = this;
    var flatData = this.flatData;
    flatData.forEach(function (data) {
      var taskbar = _this.gantt.taskbar.add();
      var ganttProp = data.ganttProperties;
      taskbar.left = ganttProp.left;
      taskbar.width = ganttProp.width;
      if (taskbar.left < 0) {
        taskbar.width = taskbar.width + taskbar.left;
        taskbar.left = 0;
      }
      taskbar.progress = ganttProp.progress;
      taskbar.isScheduledTask = isScheduledTask(ganttProp);
      if (isScheduledTask) {
        if (isNullOrUndefined(ganttProp.endDate) && isNullOrUndefined(ganttProp.duration)) {
          taskbar.unscheduledTaskBy = 'startDate';
        } else if (isNullOrUndefined(ganttProp.startDate) && isNullOrUndefined(ganttProp.duration)) {
          taskbar.unscheduledTaskBy = 'endDate';
        } else {
          taskbar.unscheduledTaskBy = 'duration';
          taskbar.unscheduleStarteDate = _this.parent.dateValidationModule.getValidStartDate(data.ganttProperties);
          taskbar.unscheduleEndDate = _this.parent.dateValidationModule.getValidEndDate(data.ganttProperties);
        }
      }
      // else {
      //     taskbar.unscheduleStarteDate = null;
      //     taskbar.unscheduleEndDate = null;
      // }
      taskbar.startDate = ganttProp.startDate;
      taskbar.endDate = ganttProp.endDate;
      taskbar.height = _this.parent.chartRowsModule.taskBarHeight;
      // if (this.parent.renderBaseline) {
      //     let height: number;
      //     if ((taskbar.height + this.baselineHeight) <= this.parent.rowHeight) {
      //         height = taskbar.height;
      //     } else {
      //         height = taskbar.height - (this.baselineHeight + 1);
      //     }
      //     taskbar.height = height;
      // }
      taskbar.indicators = ganttProp.indicators;
      taskbar.autoStartDate = ganttProp.autoStartDate;
      taskbar.autoEndDate = ganttProp.autoEndDate;
      taskbar.isAutoSchedule = ganttProp.isAutoSchedule;
      taskbar.autoWidth = ganttProp.autoWidth;
      taskbar.autoLeft = ganttProp.autoLeft;
      taskbar.segment = ganttProp.segments;
      taskbar.isSpliterTask = isNullOrUndefined(ganttProp.segments) || ganttProp.segments.length === 0 ? false : true;
      if (taskbar.isSpliterTask) {
        taskbar.segmentCollection = taskbar.segment.map(function (obj) {
          return __assign$7({}, obj);
        });
      }
      taskbar.baselineTop = _this.parent.chartRowsModule.baselineTop;
      taskbar.isMilestone = ganttProp.isMilestone;
      taskbar.baselineStartDate = ganttProp.baselineStartDate;
      taskbar.baselineEndDate = ganttProp.baselineEndDate;
      taskbar.baselineLeft = ganttProp.baselineLeft;
      taskbar.baselineWidth = ganttProp.baselineWidth;
      if (taskbar.baselineLeft < 0) {
        taskbar.baselineWidth = taskbar.baselineWidth + taskbar.baselineLeft;
        taskbar.baselineLeft = 0;
      }
      taskbar.milestoneColor = new PdfColor(_this.ganttStyle.taskbar.milestoneColor);
      taskbar.isParentTask = data.hasChildRecords;
      if (ganttProp.isMilestone) {
        taskbar.height = ganttProp.width;
      }
      if (data[_this.parent.labelSettings.leftLabel]) {
        taskbar.leftTaskLabel.value = data[_this.parent.labelSettings.leftLabel].toString();
      }
      if (data[_this.parent.labelSettings.rightLabel]) {
        taskbar.rightTaskLabel.value = data[_this.parent.labelSettings.rightLabel].toString();
      }
      if (!isNullOrUndefined(data[_this.parent.labelSettings.taskLabel])) {
        taskbar.taskLabel = data[_this.parent.labelSettings.taskLabel].toString();
      }
      var reduceLeft = ganttProp.isMilestone ? Math.floor(_this.parent.chartRowsModule.taskBarHeight / 2) + 33 : 33; // 33 indicates default timeline cell width
      taskbar.rightTaskLabel.left = ganttProp.left + ganttProp.width + reduceLeft; // right label left value
      taskbar.fontFamily = _this.ganttStyle.fontFamily;
      taskbar.progressWidth = ganttProp.progressWidth;
      taskbar.labelColor = new PdfColor(_this.ganttStyle.label.fontColor);
      taskbar.progressFontColor = new PdfColor(_this.ganttStyle.taskbar.progressFontColor);
      if (taskbar.isParentTask) {
        taskbar.taskColor = new PdfColor(_this.ganttStyle.taskbar.parentTaskColor);
        taskbar.taskBorderColor = new PdfColor(_this.ganttStyle.taskbar.parentTaskBorderColor);
        taskbar.progressColor = new PdfColor(_this.ganttStyle.taskbar.parentProgressColor);
      } else {
        if (data.isCritical) {
          taskbar.taskColor = new PdfColor(_this.ganttStyle.taskbar.criticalTaskColor);
          taskbar.progressColor = new PdfColor(_this.ganttStyle.taskbar.criticalProgressColor);
          taskbar.taskBorderColor = new PdfColor(_this.ganttStyle.taskbar.criticalTaskBorderColor);
          taskbar.milestoneColor = new PdfColor(_this.ganttStyle.taskbar.criticalTaskColor);
        } else {
          taskbar.taskColor = new PdfColor(_this.ganttStyle.taskbar.taskColor);
          taskbar.progressColor = new PdfColor(_this.ganttStyle.taskbar.progressColor);
          taskbar.taskBorderColor = new PdfColor(_this.ganttStyle.taskbar.taskBorderColor);
        }
      }
      taskbar.manualParentBorder = new PdfColor(_this.ganttStyle.taskbar.manualParentBorder);
      taskbar.manualChildBorder = new PdfColor(_this.ganttStyle.taskbar.manualChildBorder);
      taskbar.manuallineColor = new PdfColor(_this.ganttStyle.taskbar.manualLineColor);
      taskbar.unscheduledTaskBarColor = new PdfColor(_this.ganttStyle.taskbar.unscheduledTaskBarColor);
      taskbar.manualParentBackground = new PdfColor(_this.ganttStyle.taskbar.manualParentBackground);
      taskbar.manualParentProgress = new PdfColor(_this.ganttStyle.taskbar.manualParentProgress);
      taskbar.manualChildBackground = new PdfColor(_this.ganttStyle.taskbar.manualChildBackground);
      taskbar.manualChildProgress = new PdfColor(_this.ganttStyle.taskbar.manualChildProgress);
      taskbar.splitLineBackground = new PdfColor(_this.ganttStyle.taskbar.splitLineBackground);
      taskbar.baselineColor = new PdfColor(_this.ganttStyle.taskbar.baselineColor);
      taskbar.baselineBorderColor = new PdfColor(_this.ganttStyle.taskbar.baselineBorderColor);
      taskbar.gridLineColor = new PdfColor(_this.ganttStyle.chartGridLineColor);
      var labelTemplateStyle = {};
      labelTemplateStyle.leftLabel = {
        value: null,
        image: null,
        fontStyle: {
          fontBrush: null
        }
      };
      labelTemplateStyle.rightLabel = {
        value: null,
        image: null,
        fontStyle: {
          fontBrush: null
        }
      };
      labelTemplateStyle.taskLabel = {
        value: null,
        image: null,
        fontStyle: {
          fontBrush: null
        }
      };
      taskbar.labelSettings = labelTemplateStyle;
      var taskbarTemplate = {
        value: null,
        image: null,
        fontStyle: {
          fontBrush: null
        }
      };
      taskbar.taskbarTemplate = taskbarTemplate;
      _this.gantt.taskbarCollection.push(taskbar);
      var taskStyle = {};
      taskStyle.progressFontColor = taskbar.progressFontColor;
      taskStyle.taskColor = taskbar.taskColor;
      taskStyle.taskBorderColor = taskbar.taskBorderColor;
      taskStyle.progressColor = taskbar.progressColor;
      taskStyle.milestoneColor = taskbar.milestoneColor;
      taskStyle.baselineColor = taskbar.baselineColor;
      taskStyle.baselineBorderColor = taskbar.baselineBorderColor;
      var args = {
        taskbar: taskStyle,
        data: data,
        indicators: data.ganttProperties.indicators,
        labelSettings: labelTemplateStyle,
        taskbarTemplate: taskbarTemplate
      };
      if (_this.parent.pdfQueryTaskbarInfo) {
        var segmentCollection = [];
        args.taskbar.taskSegmentStyles = null;
        if (args.data.ganttProperties.segments) {
          for (var i = 0; i < args.data.ganttProperties.segments.length; i++) {
            var segmentTaskbar = {
              taskColor: args.taskbar.taskColor,
              taskBorderColor: args.taskbar.taskBorderColor,
              progressColor: args.taskbar.progressColor
            };
            segmentCollection.push(segmentTaskbar);
          }
          args.taskbar.taskSegmentStyles = segmentCollection;
        }
        _this.parent.trigger('pdfQueryTaskbarInfo', args);
        taskbar.taskSegmentStyles = args.taskbar.taskSegmentStyles;
        taskbar.progressFontColor = args.taskbar.progressFontColor;
        taskbar.taskColor = args.taskbar.taskColor;
        taskbar.taskBorderColor = args.taskbar.taskBorderColor;
        taskbar.progressColor = args.taskbar.progressColor;
        taskbar.milestoneColor = args.taskbar.milestoneColor;
        taskbar.baselineColor = args.taskbar.baselineColor;
        taskbar.baselineBorderColor = args.taskbar.baselineBorderColor;
        taskbar.indicators = args.indicators;
        taskbar.labelSettings.leftLabel.value = args.labelSettings.leftLabel.value;
        var leftImages = args.labelSettings.leftLabel.image;
        taskbar.labelSettings.rightLabel.value = args.labelSettings.rightLabel.value;
        var rightImage = args.labelSettings.rightLabel.image;
        if (!isNullOrUndefined(args.labelSettings.taskLabel.value)) {
          taskbar.taskLabel = args.labelSettings.taskLabel.value;
        }
        if (!isNullOrUndefined(args.labelSettings.leftLabel.image) && Array.isArray(args.labelSettings.leftLabel.image[0].base64) && args.labelSettings.leftLabel.image[0].base64.length > 0) {
          var baseCount = args.labelSettings.leftLabel.image[0].base64.length;
          taskbar.labelSettings.leftLabel.image = [];
          for (var i = 0; i < baseCount; i++) {
            // Create separate objects for each element of the base64 array
            taskbar.labelSettings.leftLabel.image.push({
              base64: leftImages[0].base64[i],
              width: leftImages[0].width,
              height: leftImages[0].height
            });
          }
        } else if (!isNullOrUndefined(args.labelSettings.leftLabel.image)) {
          taskbar.labelSettings.leftLabel.image = args.labelSettings.leftLabel.image;
        }
        if (!isNullOrUndefined(args.labelSettings.rightLabel.image) && Array.isArray(args.labelSettings.rightLabel.image[0].base64) && args.labelSettings.rightLabel.image[0].base64.length > 0) {
          var baseCount = args.labelSettings.rightLabel.image[0].base64.length;
          taskbar.labelSettings.rightLabel.image = [];
          for (var i = 0; i < baseCount; i++) {
            // Create separate objects for each element of the base64 array
            taskbar.labelSettings.rightLabel.image.push({
              base64: rightImage[0].base64[i],
              width: rightImage[0].width,
              height: rightImage[0].height
            });
          }
        } else if (!isNullOrUndefined(args.labelSettings.rightLabel.image)) {
          taskbar.labelSettings.rightLabel.image = args.labelSettings.rightLabel.image;
        }
        /* eslint-disable-next-line */
        var applyTemplate = function (target, source) {
          target.progressFontColor = source.taskbar.progressFontColor;
          target.taskColor = new PdfColor(source.taskbar.taskColor);
          target.taskBorderColor = source.taskbar.taskBorderColor;
          target.progressColor = source.taskbar.progressColor;
          target.milestoneColor = source.taskbar.milestoneColor;
          if (!isNullOrUndefined(source.taskbarTemplate.image) && !isNullOrUndefined(source.taskbarTemplate.image[0].base64)) {
            var width = source.taskbarTemplate.image[0].width;
            var milestoneHeight = taskbar.isMilestone ? source.taskbarTemplate.image[0].height < _this.parent.chartRowsModule.taskBarHeight * 0.7 ? source.taskbarTemplate.image[0].height : _this.parent.chartRowsModule.taskBarHeight * 0.7 - 2 : _this.parent.chartRowsModule.taskBarHeight * 0.7 - 2;
            var taskbarHeight = !isNullOrUndefined(source.taskbarTemplate.image[0].height) ? source.taskbarTemplate.image[0].height < taskbar.height ? source.taskbarTemplate.image[0].height : taskbar.height - 2 : taskbar.height - 2;
            var height = taskbar.isMilestone ? milestoneHeight : taskbarHeight;
            target.taskbarTemplate.image = source.taskbarTemplate.image;
            target.taskbarTemplate.image[0].width = width;
            target.taskbarTemplate.image[0].height = height;
          }
          if (!isNullOrUndefined(source.taskbarTemplate.value)) {
            target.taskbarTemplate.value = source.taskbarTemplate.value;
            target.taskbarTemplate.value = source.taskbarTemplate.value;
            target.taskbarTemplate.fontStyle.fontColor = source.taskbarTemplate.fontStyle.fontColor;
            target.taskbarTemplate.fontStyle.fontSize = source.taskbarTemplate.fontStyle.fontSize;
            target.taskbarTemplate.fontStyle.fontFamily = source.taskbarTemplate.fontStyle.fontFamily;
            target.taskbarTemplate.fontStyle.fontStyle = source.taskbarTemplate.fontStyle.fontStyle;
            target.taskbarTemplate.fontStyle.fontBrush = source.taskbarTemplate.fontStyle.fontBrush;
          }
        };
        if (!args.data.hasChildRecords && args.data.ganttProperties.duration !== 0) {
          applyTemplate(taskbar, args);
        } else if (args.data.hasChildRecords && args.data.ganttProperties.duration !== 0) {
          applyTemplate(taskbar, args);
        } else if (args.data.ganttProperties.duration === 0) {
          applyTemplate(taskbar, args);
        }
      }
    });
  };
  /**
   * set text alignment of each columns in exporting grid
   *
   * @param {string} textAlign .
   * @param {PdfStringFormat} format .
   * @returns {PdfStringFormat} .
   * @private
   */
  ExportHelper.prototype.getHorizontalAlignment = function (textAlign, format) {
    if (format === undefined) {
      format = new PdfStringFormat();
    }
    switch (textAlign) {
      case 'Right':
        format.alignment = PdfTextAlignment.Right;
        break;
      case 'Center':
        format.alignment = PdfTextAlignment.Center;
        break;
      case 'Justify':
        format.alignment = PdfTextAlignment.Justify;
        break;
      case 'Left':
        format.alignment = PdfTextAlignment.Left;
        break;
    }
    return format;
  };
  /**
   * set vertical alignment of each columns in exporting grid
   *
   * @param {string} verticalAlign .
   * @param {PdfStringFormat} format .
   * @param {string} textAlign .
   * @returns {PdfStringFormat} .
   * @private
   */
  ExportHelper.prototype.getVerticalAlignment = function (verticalAlign, format, textAlign) {
    if (format === undefined) {
      format = new PdfStringFormat();
      format = this.getHorizontalAlignment(textAlign, format);
    }
    switch (verticalAlign) {
      case 'Bottom':
        format.lineAlignment = PdfVerticalAlignment.Bottom;
        break;
      case 'Middle':
        format.lineAlignment = PdfVerticalAlignment.Middle;
        break;
      case 'Top':
        format.lineAlignment = PdfVerticalAlignment.Top;
        break;
    }
    return format;
  };
  ExportHelper.prototype.getFontFamily = function (fontFamily) {
    switch (fontFamily) {
      case 'TimesRoman':
        return 2;
      case 'Courier':
        return 1;
      case 'Symbol':
        return 3;
      case 'ZapfDingbats':
        return 4;
      default:
        return 0;
    }
  };
  ExportHelper.prototype.getFontStyle = function (fontStyle) {
    switch (fontStyle) {
      case 'Strikeout':
        return 8;
      case 'Underline':
        return 4;
      case 'Italic':
        return 2;
      case 'Bold':
        return 1;
      default:
        return 0;
    }
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ExportHelper.prototype.getFont = function (content) {
    if (content.font) {
      return content.font;
    }
    var defaultFontFamily = this.exportProps.ganttStyle && this.exportProps.ganttStyle.fontFamily ? this.exportProps.ganttStyle.fontFamily : PdfFontFamily.TimesRoman;
    var fontSize = !isNullOrUndefined(content.style.fontSize) ? content.style.fontSize * 0.75 : 9.75;
    var fontFamily = !isNullOrUndefined(content.style.fontFamily) ? this.getFontFamily(content.style.fontFamily) : defaultFontFamily;
    var fontStyle = PdfFontStyle.Regular;
    // if (!isNullOrUndefined(content.style.bold) && content.style.bold) {
    //     fontStyle |= PdfFontStyle.Bold;
    // }
    // if (!isNullOrUndefined(content.style.italic) && content.style.italic) {
    //     fontStyle |= PdfFontStyle.Italic;
    // }
    // if (!isNullOrUndefined(content.style.underline) && content.style.underline) {
    //     fontStyle |= PdfFontStyle.Underline;
    // }
    // if (!isNullOrUndefined(content.style.strikeout) && content.style.strikeout) {
    //     fontStyle |= PdfFontStyle.Strikeout;
    // }
    return new PdfStandardFont(fontFamily, fontSize, fontStyle);
  };
  ExportHelper.prototype.renderEmptyGantt = function () {
    var row = this.gantt.rows.addRow();
    row.cells.getCell(0).isHeaderCell = false;
    row.height = pixelToPoint(this.parent.rowHeight);
    this.copyStyles(this.ganttStyle.columnHeader, row.cells.getCell(0), row.isParentRow);
    var count = this.columns.length;
    row.cells.getCell(0).value = this.parent.localeObj.getConstant('emptyRecord');
    this.mergeCells(1, 0, count);
  };
  ExportHelper.prototype.mergeCells = function (rowIndex, colIndex, lastColIndex) {
    this.gantt.rows.getRow(rowIndex).cells.getCell(colIndex).columnSpan = lastColIndex;
  };
  /* eslint-disable-next-line */
  ExportHelper.prototype.copyStyles = function (style, cell, isParentRow) {
    cell.style.fontColor = new PdfColor(style.fontColor);
    cell.style.backgroundColor = new PdfColor(style.backgroundColor);
    cell.style.borderColor = new PdfColor(style.borderColor);
    cell.style.fontSize = style.fontSize;
    cell.style.fontStyle = style.fontStyle;
    /* eslint-disable-next-line */
    cell.style.format = Object.assign(new PdfStringFormat(), style.format);
    cell.style.borders = new PdfBorders();
    cell.style.borders.all = new PdfPen(cell.style.borderColor);
    cell.style.padding = new PdfPaddings();
    var padding = 0;
    if (cell.isHeaderCell) {
      padding = this.parent.timelineModule.isSingleTier ? 45 / 2 : 60 / 2;
    } else {
      padding = this.parent.rowHeight / 2;
    }
    cell.style.padding.top = padding - style.fontSize > 0 ? padding - style.fontSize : padding;
    cell.style.padding.bottom = padding - style.fontSize > 0 ? padding - style.fontSize : 0;
    cell.style.padding.left = 10;
    cell.style.padding.right = 10;
    if (style.padding) {
      cell.style.padding = style.padding;
    }
    if (style.borders) {
      cell.style.borders = style.borders;
    }
  };
  /**
   * @param {PdfDocument} pdfDoc .
   * @returns {void} .
   * @private
   */
  ExportHelper.prototype.initializePdf = function (pdfDoc) {
    this.pdfDoc = pdfDoc;
    var widths = [];
    var treeColumnIndex = 0;
    var tWidth = this.pdfDoc.pageSettings.width - 82;
    if (this.exportProps && this.exportProps.fitToWidthSettings && this.exportProps.fitToWidthSettings.isFitToWidth) {
      var gridWidth = void 0;
      if (this.exportProps.fitToWidthSettings.gridWidth) {
        gridWidth = parseInt(this.exportProps.fitToWidthSettings.gridWidth.split('%')[0], 10);
      } else {
        if (this.exportProps.fitToWidthSettings.chartWidth) {
          var chartWidth = parseInt(this.exportProps.fitToWidthSettings.chartWidth.split('%')[0], 10);
          gridWidth = 100 - chartWidth;
        } else {
          gridWidth = 30;
        }
      }
      var pdfwidth = this.parent.pdfExportModule['pdfPageDimensions'].width * gridWidth / 100;
      var perColumnWidth = pdfwidth / this.gantt.columns.columns.length;
      for (var i = 0; i < this.gantt.columns.columns.length; i++) {
        this.gantt.columns.getColumn(i).width = perColumnWidth;
      }
    }
    /* eslint-disable-next-line */
    var PdfPage = this.parent.pdfExportModule.pdfPage;
    if (this.totalColumnWidth > this.pdfDoc.pageSettings.width - 82 && this.totalColumnWidth < PdfPage.getClientSize().width) {
      this.gantt.style.allowHorizontalOverflow = true;
    } else if (tWidth / this.columns.length < widths[treeColumnIndex]) {
      this.gantt.columns.getColumn(treeColumnIndex).width = widths[treeColumnIndex];
    }
    if (this.exportProps.enableFooter || isNullOrUndefined(this.exportProps.enableFooter)) {
      //code for draw the footer content
      var pageSize_1 = this.parent.pdfExportModule.getPageSize(this.exportProps.pageSize);
      var bounds = new RectangleF(0, 0, pageSize_1.width, 35);
      var pen = new PdfPen(this.ganttStyle.footer.borderColor);
      var footer = new PdfPageTemplateElement(bounds);
      var footerBrush = new PdfSolidBrush(this.ganttStyle.footer.backgroundColor);
      footer.graphics.drawRectangle(pen, footerBrush, 0, 0, pageSize_1.width, 35);
      /* eslint-disable-next-line */
      var font = new PdfStandardFont(this.ganttStyle.fontFamily, this.ganttStyle.footer.fontSize, this.ganttStyle.footer.fontStyle);
      if (this.ganttStyle.font) {
        font = this.ganttStyle.font;
      }
      var brush = new PdfSolidBrush(this.ganttStyle.footer.fontColor);
      var pageNumber = new PdfPageNumberField(font);
      var count = new PdfPageCountField(font, brush);
      var compositeField = new PdfCompositeField(font, brush, 'Page {0}', pageNumber, count);
      compositeField.stringFormat = this.ganttStyle.footer.format;
      compositeField.bounds = bounds;
      compositeField.draw(footer.graphics, new PointF(0, 0));
      pdfDoc.template.bottom = footer;
    }
    /* eslint-disable-next-line */
    var pageSize = PdfPage.size;
    var clientSize = !isNullOrUndefined(pageSize) ? pageSize : this.pdfDoc.pageSettings.size;
    // code for draw header content
    if (!isNullOrUndefined(this.exportProps.header)) {
      var headerProp = this.exportProps.header;
      var position = new PointF(0, headerProp.fromTop);
      var size = new SizeF(clientSize.width * 1.1, headerProp && headerProp.height ? headerProp.height * 0.75 : 50);
      var bounds = new RectangleF(position, size);
      pdfDoc.template.top = this.drawPageTemplate(new PdfPageTemplateElement(bounds), headerProp);
    }
    // code for customization of footer
    if (!this.exportProps.enableFooter && !isNullOrUndefined(this.exportProps.footer)) {
      var footer = this.exportProps.footer;
      var position = new PointF(0, clientSize.width - 80 - (footer && footer.fromBottom ? footer.fromBottom * 0.75 : 0));
      var size = new SizeF(clientSize.width * 1.1, footer && footer.height ? footer.height * 0.75 : 50);
      var bounds = new RectangleF(position, size);
      this.pdfDoc.template.bottom = this.drawPageTemplate(new PdfPageTemplateElement(bounds), footer);
    }
  };
  ExportHelper.prototype.drawPageTemplate = function (template, element) {
    for (var _i = 0, _a = element.contents; _i < _a.length; _i++) {
      var content = _a[_i];
      switch (content.type) {
        case 'Text':
          if (content.value === '' || content.value === undefined || content.value === null || typeof content.value !== 'string') {
            throw new Error('please enter the valid input value in text content...');
          }
          this.drawText(template, content);
          break;
        case 'PageNumber':
          this.drawPageNumber(template, content);
          break;
        case 'Image':
          if (content.src === undefined || content.src === null || content.src === '') {
            throw new Error('please enter the valid base64 string in image content...');
          }
          this.drawImage(template, content);
          break;
        case 'Line':
          this.drawLine(template, content);
          break;
        default:
          throw new Error('Please set valid content type...');
      }
    }
    return template;
  };
  // code for draw text
  /* eslint-disable-next-line */
  ExportHelper.prototype.drawText = function (pageTemplate, content) {
    var font = this.getFont(content);
    if (this.ganttStyle.font) {
      font = this.ganttStyle.font;
    }
    var brush = this.getBrushFromContent(content);
    var pen = null;
    if (!isNullOrUndefined(content.style.textPenColor)) {
      var penColor = this.hexToRgb(content.style.textPenColor);
      pen = new PdfPen(new PdfColor(penColor.r, penColor.g, penColor.b));
    }
    if (brush === null && pen === null) {
      brush = new PdfSolidBrush(new PdfColor(0, 0, 0));
    }
    var value = content.value.toString();
    var x = content.position.x * 0.75;
    var y = content.position.y * 0.75;
    var format = new PdfStringFormat();
    if (!isNullOrUndefined(content.style.stringFormat)) {
      format.alignment = content.style.stringFormat.alignment;
    }
    var result = this.setContentFormat(content, format);
    if (result !== null && !isNullOrUndefined(result.format) && !isNullOrUndefined(result.size)) {
      pageTemplate.graphics.drawString(value, font, pen, brush, x, y, result.size.width, result.size.height, result.format);
    } else {
      pageTemplate.graphics.drawString(value, font, pen, brush, x, y, format);
    }
  };
  // code for draw pagenumber
  /* eslint-disable-next-line */
  ExportHelper.prototype.drawPageNumber = function (documentHeader, content) {
    var font = this.getFont(content);
    var brush = null;
    if (!isNullOrUndefined(content.style.textBrushColor)) {
      var brushColor = this.hexToRgb(content.style.textBrushColor);
      brush = new PdfSolidBrush(new PdfColor(brushColor.r, brushColor.g, brushColor.b));
    } else {
      brush = new PdfSolidBrush(new PdfColor(0, 0, 0));
    }
    var pageNumber = new PdfPageNumberField(font, brush);
    pageNumber.numberStyle = this.getPageNumberStyle(content.pageNumberType);
    var compositeField;
    var format;
    if (!isNullOrUndefined(content.format)) {
      var total = '$total';
      var current = '$current';
      if (content.format.indexOf(total) !== -1 && content.format.indexOf(current) !== -1) {
        var pageCount = new PdfPageCountField(font);
        pageCount.numberStyle = this.getPageNumberStyle(content.pageNumberType);
        if (content.format.indexOf(total) > content.format.indexOf(current)) {
          format = content.format.replace(current, '0');
          format = format.replace(total, '1');
        } else {
          format = content.format.replace(current, '1');
          format = format.replace(total, '0');
        }
        compositeField = new PdfCompositeField(font, brush, format, pageNumber, pageCount);
      } else if (content.format.indexOf(current) !== -1 && content.format.indexOf(total) === -1) {
        format = content.format.replace(current, '0');
        compositeField = new PdfCompositeField(font, brush, format, pageNumber);
      } else {
        var pageCount = new PdfPageCountField(font);
        format = content.format.replace(total, '0');
        compositeField = new PdfCompositeField(font, brush, format, pageCount);
      }
    } else {
      format = '{0}';
      compositeField = new PdfCompositeField(font, brush, format, pageNumber);
    }
    var x = content.position.x * 0.75;
    var y = content.position.y * 0.75;
    var result = this.setContentFormat(content, compositeField.stringFormat);
    if (result !== null && !isNullOrUndefined(result.format) && !isNullOrUndefined(result.size)) {
      compositeField.stringFormat = result.format;
      compositeField.bounds = new RectangleF(x, y, result.size.width, result.size.height);
    }
    compositeField.draw(documentHeader.graphics, x, y);
  };
  // code for draw image
  /* eslint-disable-next-line */
  ExportHelper.prototype.drawImage = function (documentHeader, content) {
    var x = content.position.x * 0.75;
    var y = content.position.y * 0.75;
    var width = !isNullOrUndefined(content.size) && !isNullOrUndefined(content.size.width) ? content.size.width * 0.50 : undefined;
    var height = !isNullOrUndefined(content.size) && !isNullOrUndefined(content.size.height) ? content.size.height * 0.75 : undefined;
    var image = new PdfBitmap(content.src);
    if (!isNullOrUndefined(width)) {
      documentHeader.graphics.drawImage(image, x, y, width, height);
    } else {
      documentHeader.graphics.drawImage(image, x, y);
    }
  };
  // code for draw line
  /* eslint-disable-next-line */
  ExportHelper.prototype.drawLine = function (documentHeader, content) {
    var x1 = content.points.x1 * 0.75;
    var y1 = content.points.y1 * 0.75;
    var x2 = content.points.x2 * 0.75;
    var y2 = content.points.y2 * 0.75;
    var pen = this.getPenFromContent(content);
    if (!isNullOrUndefined(content.style)) {
      if (!isNullOrUndefined(content.style.penSize) && typeof content.style.penSize === 'number') {
        pen.width = content.style.penSize * 0.75;
      }
      pen.dashStyle = this.getDashStyle(content.style.dashStyle);
    }
    documentHeader.graphics.drawLine(pen, x1, y1, x2, y2);
  };
  ExportHelper.prototype.getPenFromContent = function (content) {
    var pen = new PdfPen(new PdfColor(0, 0, 0));
    if (!isNullOrUndefined(content.style) && content.style !== null && !isNullOrUndefined(content.style.penColor)) {
      var penColor = this.hexToRgb(content.style.penColor);
      pen = new PdfPen(new PdfColor(penColor.r, penColor.g, penColor.b));
    }
    return pen;
  };
  ExportHelper.prototype.getDashStyle = function (dashStyle) {
    switch (dashStyle) {
      case 'Dash':
        return 1;
      case 'Dot':
        return 2;
      case 'DashDot':
        return 3;
      case 'DashDotDot':
        return 4;
      default:
        return 0;
    }
  };
  ExportHelper.prototype.getBrushFromContent = function (content) {
    var brush = null;
    if (!isNullOrUndefined(content.style.textBrushColor)) {
      /* tslint:disable-next-line:max-line-length */
      var brushColor = this.hexToRgb(content.style.textBrushColor);
      brush = new PdfSolidBrush(new PdfColor(brushColor.r, brushColor.g, brushColor.b));
    }
    return brush;
  };
  ExportHelper.prototype.hexToRgb = function (hex) {
    if (hex === null || hex === '' || hex.length !== 7) {
      throw new Error('please set valid hex value for color...');
    }
    hex = hex.substring(1);
    var bigint = parseInt(hex, 16);
    var r = bigint >> 16 & 255;
    var g = bigint >> 8 & 255;
    var b = bigint & 255;
    return {
      r: r,
      g: g,
      b: b
    };
  };
  ExportHelper.prototype.setContentFormat = function (content, format) {
    var width = content.size ? content.size.width * 0.75 : this.pdfDoc.pageSettings.size.width;
    var height = content.size ? content.size.height * 0.75 : !isNullOrUndefined(this.exportProps.footer) ? this.exportProps.footer.height * 0.50 : 0;
    format = new PdfStringFormat(PdfTextAlignment.Left, PdfVerticalAlignment.Middle);
    if (!isNullOrUndefined(content.style.hAlign)) {
      switch (content.style.hAlign) {
        case 'Right':
          format.alignment = PdfTextAlignment.Right;
          break;
        case 'Center':
          format.alignment = PdfTextAlignment.Center;
          break;
        case 'Justify':
          format.alignment = PdfTextAlignment.Justify;
          break;
        default:
          format.alignment = PdfTextAlignment.Left;
      }
    }
    if (!isNullOrUndefined(content.style.vAlign)) {
      format = this.getVerticalAlignment(content.style.vAlign, format);
    }
    return {
      format: format,
      size: new SizeF(width, height)
    };
  };
  ExportHelper.prototype.getPageNumberStyle = function (pageNumberType) {
    switch (pageNumberType) {
      case 'LowerLatin':
        return 2;
      case 'LowerRoman':
        return 3;
      case 'UpperLatin':
        return 4;
      case 'UpperRoman':
        return 5;
      default:
        return 1;
    }
  };
  return ExportHelper;
}();
/**
 * @hidden
 * `ExportValueFormatter` for `PdfExport` & `ExcelExport`
 */
var ExportValueFormatter = /** @__PURE__ @class */function () {
  function ExportValueFormatter(culture) {
    this.valueFormatter = new ValueFormatter(culture);
    this.internationalization = new Internationalization(culture);
  }
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ExportValueFormatter.prototype.returnFormattedValue = function (args, customFormat) {
    if (!isNullOrUndefined(args.value) && args.value) {
      return this.valueFormatter.getFormatFunction(customFormat)(args.value);
    } else {
      return '';
    }
  };
  /**
   * @private
   */
  /* eslint-disable-next-line  */
  ExportValueFormatter.prototype.formatCellValue = function (args) {
    if (args.isForeignKey) {
      args.value = getValue(args.column.foreignKeyValue, getForeignData(args.column, {}, args.value)[0]);
    }
    if (args.column.type === 'number' && args.column.format !== undefined && args.column.format !== '') {
      return args.value ? this.internationalization.getNumberFormat({
        format: args.column.format
      })(args.value) : '';
    } else if (args.column.type === 'boolean') {
      return args.value ? 'true' : 'false';
    } else if ((args.column.type === 'date' || args.column.type === 'datetime' || args.column.type === 'time') && args.column.format !== undefined) {
      if (typeof args.value === 'string') {
        args.value = new Date(args.value);
      }
      if (typeof args.column.format === 'string') {
        var format = void 0;
        if (args.column.type === 'date') {
          format = {
            type: 'date',
            format: args.column.format
          };
        } else if (args.column.type === 'time') {
          format = {
            type: 'time',
            format: args.column.format
          };
        } else {
          format = {
            type: 'dateTime',
            format: args.column.format
          };
        }
        return this.returnFormattedValue(args, format);
      } else {
        if (args.column.format instanceof Object && args.column.format.type === undefined) {
          return args.value.toString();
        } else {
          var customFormat = void 0;
          if (args.column.type === 'date') {
            /* eslint-disable-next-line max-len */
            customFormat = {
              type: args.column.format.type,
              format: args.column.format.format,
              skeleton: args.column.format.skeleton
            };
          } else if (args.column.type === 'time') {
            customFormat = {
              type: 'time',
              format: args.column.format.format,
              skeleton: args.column.format.skeleton
            };
          } else {
            customFormat = {
              type: 'dateTime',
              format: args.column.format.format,
              skeleton: args.column.format.skeleton
            };
          }
          return this.returnFormattedValue(args, customFormat);
        }
      }
    } else {
      if (!isNullOrUndefined(args.column.type) && !isNullOrUndefined(args.value) || !isNullOrUndefined(args.value)) {
        return args.value.toString();
      } else {
        return '';
      }
    }
  };
  return ExportValueFormatter;
}();
var __assign$8 = undefined && undefined.__assign || function () {
  __assign$8 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$8.apply(this, arguments);
};
/**
 * @hidden
 */
var PdfGanttTaskbarCollection = /** @__PURE__ @class */function () {
  function PdfGanttTaskbarCollection(parent) {
    /** Defines the task baselineHeight . */
    this.baselineHeight = 8;
    this.segment = [];
    this.segmentCollection = [];
    /**
     * @private
     */
    this.leftTaskLabel = {};
    /**
     * @private
     */
    this.rightTaskLabel = {};
    this.startPage = -1;
    this.endPage = -1;
    this.parent = parent;
  }
  PdfGanttTaskbarCollection.prototype.add = function () {
    return new PdfGanttTaskbarCollection(this.parent);
  };
  /**
   * @param {PdfPage} page .
   * @returns {PdfPage} .
   * Get the next PDF page
   */
  PdfGanttTaskbarCollection.prototype.GetNextPage = function (page) {
    var section = page.section;
    var index = section.indexOf(page);
    var nextPage = null;
    if (index === section.count - 1) {
      nextPage = section.add();
    } else {
      nextPage = section.getPages()[index + 1];
    }
    return nextPage;
  };
  PdfGanttTaskbarCollection.prototype.isAutoFit = function () {
    return this.parent.pdfExportModule && this.parent.pdfExportModule.helper.exportProps && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings && this.parent.pdfExportModule.helper.exportProps.fitToWidthSettings.isFitToWidth || this.parent.timelineModule.isZoomedToFit ? true : false;
  };
  /**
   * Draw the taskbar, chart back ground
   *
   * @private
   */
  /* eslint-disable */
  PdfGanttTaskbarCollection.prototype.drawTaskbar = function (page, startPoint, detail, cumulativeWidth, rowHeight, taskbar, lineWidth) {
    var _this = this;
    var taskGraphics = page.graphics;
    var isNextPage = false;
    var pageSize = page.getClientSize();
    var yPoint = startPoint.y + rowHeight;
    //code for while current pdf page is exceed
    if (yPoint > pageSize.height) {
      page = this.GetNextPage(page);
      page['contentWidth'] = this.isAutoFit() ? pointToPixel(detail.endPoint - detail.startPoint) : detail.endPoint - detail.startPoint;
      taskGraphics = page.graphics;
      startPoint.y = 0;
      if (this.parent.pdfExportModule.gantt.enableHeader) {
        this.parent.pdfExportModule.gantt.chartHeader.drawPageTimeline(page, startPoint, detail);
        startPoint.y = pixelToPoint(this.parent.timelineModule.isSingleTier ? 45 : 60);
      }
      isNextPage = true;
      var graphics = page.graphics;
      var pen = new PdfPen(new PdfColor(206, 206, 206));
      if (page['contentWidth'] && (this.parent.gridLines === "Both" || this.parent.gridLines === "Horizontal")) {
        graphics.drawRectangle(pen, startPoint.x, startPoint.y, this.isAutoFit() && this.parent.timelineModule.bottomTier !== "Day" ? page['contentWidth'] + 0.5 : lineWidth, rowHeight);
      }
    }
    this.drawLeftLabel(page, startPoint, detail, cumulativeWidth, taskbar);
    //Draw Taskbar
    var font = new PdfStandardFont(this.fontFamily, 9, PdfFontStyle.Regular);
    var fontColor = null;
    var fontBrush = new PdfSolidBrush(this.progressFontColor);
    var customizedFont;
    var customizedFontBrush;
    var customizedFontColor;
    customizedFont = !isNullOrUndefined(taskbar.taskbarTemplate.value) && taskbar.taskbarTemplate.fontStyle && taskbar.taskbarTemplate.fontStyle.fontFamily && taskbar.taskbarTemplate.fontStyle.fontSize ? new PdfStandardFont(taskbar.taskbarTemplate.fontStyle.fontFamily, taskbar.taskbarTemplate.fontStyle.fontSize, taskbar.taskbarTemplate.fontStyle.fontStyle) : font;
    customizedFontBrush = !isNullOrUndefined(taskbar.taskbarTemplate.value) && taskbar.taskbarTemplate.fontStyle && taskbar.taskbarTemplate.fontStyle.fontColor ? new PdfSolidBrush(taskbar.taskbarTemplate.fontStyle.fontColor) : fontBrush;
    customizedFontColor = !isNullOrUndefined(taskbar.taskbarTemplate.value) && taskbar.taskbarTemplate.fontStyle && taskbar.taskbarTemplate.fontStyle.fontBrush ? new PdfPen(taskbar.taskbarTemplate.fontStyle.fontBrush) : fontColor;
    if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
      font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
    }
    var taskLabelFont;
    var taskLabelFontBrush;
    var ganttStyle = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle;
    if (taskbar.labelSettings.taskLabel.fontStyle.fontSize) {
      var taskFont = new PdfStandardFont(taskbar.labelSettings.taskLabel.fontStyle.fontFamily, taskbar.labelSettings.taskLabel.fontStyle.fontSize, taskbar.labelSettings.taskLabel.fontStyle.fontStyle);
      taskLabelFont = taskFont;
    } else if (ganttStyle && ganttStyle.label && ganttStyle.label.fontBrush) {
      taskLabelFontBrush = new PdfSolidBrush(ganttStyle.label.fontBrush);
    } else {
      taskLabelFont = font;
    }
    if (taskbar.labelSettings.taskLabel.fontStyle.fontColor) {
      taskLabelFontBrush = new PdfSolidBrush(taskbar.labelSettings.taskLabel.fontStyle.fontColor);
    } else {
      taskLabelFontBrush = fontBrush;
    }
    var progressFormat = new PdfStringFormat();
    progressFormat.lineAlignment = PdfVerticalAlignment.Middle;
    progressFormat.alignment = PdfTextAlignment.Right;
    var isLabelString = false;
    var updatedWidth;
    if (!isNullOrUndefined(this.taskLabel) && /^[a-zA-Z0-9]/.test(this.taskLabel)) {
      if (this.taskLabel === '0' || this.taskLabel === '0%') {
        updatedWidth = this.width;
        progressFormat.alignment = PdfTextAlignment.Left;
      }
    }
    if (!isNullOrUndefined(this.taskLabel) && /^[a-zA-Z]/.test(this.taskLabel) || !isNullOrUndefined(taskbar.taskbarTemplate.value)) {
      isLabelString = true;
      progressFormat.alignment = PdfTextAlignment.Left;
    }
    var pageIndex = -1;
    var baselinePen = new PdfPen(taskbar.baselineBorderColor);
    var baselineBrush = new PdfSolidBrush(taskbar.baselineColor);
    var template = taskbar.taskbarTemplate;
    if (!taskbar.isMilestone) {
      var taskbarPen_1 = new PdfPen(taskbar.taskBorderColor);
      var taskBrush_1 = new PdfSolidBrush(taskbar.taskColor);
      var manualParentBorderPen = new PdfPen(taskbar.manualParentBorder);
      var manualChildBorderPen = new PdfPen(taskbar.manualChildBorder);
      var manualTaskbarPen = new PdfPen(taskbar.manuallineColor);
      var manualParentPen = new PdfPen(taskbar.manualParentProgress);
      var manualline = new PdfPen(taskbar.manuallineColor);
      var manuallineBrush = new PdfSolidBrush(taskbar.manuallineColor);
      var splitline = new PdfPen(taskbar.splitLineBackground);
      var manualBrush = new PdfSolidBrush(taskbar.manualParentBackground);
      var manualChildBrush = new PdfSolidBrush(taskbar.manualChildBackground);
      var manualChildProgressBrush = new PdfSolidBrush(taskbar.manualChildProgress);
      var manualProgressBrush = new PdfSolidBrush(taskbar.manualParentProgress);
      var progressPen_1 = new PdfPen(taskbar.progressColor);
      var progressBrush_1 = new PdfSolidBrush(taskbar.progressColor);
      var adjustHeightforTaskbar = pixelToPoint((this.parent.rowHeight - this.height) / 2.0);
      var adjustHeightforBaseline = pixelToPoint((this.parent.rowHeight - this.height) / 4.5);
      var adjustHeight_1 = this.parent.renderBaseline ? adjustHeightforBaseline : adjustHeightforTaskbar;
      pageIndex = page.section.indexOf(page);
      var startDate = isNullOrUndefined(this.unscheduleStarteDate) ? this.startDate : this.unscheduleStarteDate;
      var endDate = isNullOrUndefined(this.unscheduleEndDate) ? this.endDate : this.unscheduleEndDate;
      var imageSize_1 = 10;
      //Task start and end date both are in the range of header split up start and end date
      if (detail.startDate <= startDate && endDate <= detail.endDate) {
        if (!this.isStartPoint) {
          this.taskStartPoint = __assign$8({}, startPoint);
          this.isStartPoint = true;
        }
        if (!this.isScheduledTask && this.unscheduledTaskBy === 'duration') {
          var brush1 = void 0;
          var brush2 = void 0;
          if (this.isAutoFit()) {
            brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5 + taskbar.width / 2, 0), new PointF(startPoint.x + (this.left - cumulativeWidth), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
            taskGraphics.drawRectangle(brush1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.width, pixelToPoint(taskbar.height));
            brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5 + taskbar.width, 0), new PointF(startPoint.x + (this.left - cumulativeWidth) + taskbar.width / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
            //Draw rectangle to fill linear gradient color
            taskGraphics.drawRectangle(brush2, startPoint.x + (this.left - cumulativeWidth) + 0.5 + taskbar.width / 2, startPoint.y + adjustHeight_1, taskbar.width / 2, pixelToPoint(taskbar.height));
            if (template.value || template.image) {
              var imageWidth = void 0;
              if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                imageWidth = taskbar.taskbarTemplate.image[0].width;
                var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                taskGraphics.drawImage(image, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
              } else {
                imageWidth = 0;
              }
              !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : '';
            }
          } else {
            brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(taskbar.width) / 2, 0), new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
            taskGraphics.drawRectangle(brush1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
            brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(taskbar.width), 0), new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + pixelToPoint(taskbar.width) / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
            //Draw rectangle to fill linear gradient color
            taskGraphics.drawRectangle(brush2, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(taskbar.width) / 2, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width) / 2, pixelToPoint(taskbar.height));
            if (template.value || template.image) {
              var imageWidth = void 0;
              if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                imageWidth = taskbar.taskbarTemplate.image[0].width;
                var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                taskGraphics.drawImage(image, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
              } else {
                imageWidth = 0;
              }
              !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : '';
            }
          }
        } else if (!this.isScheduledTask && this.unscheduledTaskBy === "endDate") {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else if (!this.isScheduledTask && this.unscheduledTaskBy !== 'duration') {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else {
          if (taskbar.isSpliterTask) {
            splitline.dashStyle = PdfDashStyle.Dot;
            if (this.isAutoFit()) {
              taskGraphics.drawLine(splitline, new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)), new PointF(taskbar.width + startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)));
            } else {
              taskGraphics.drawLine(splitline, new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)), new PointF(pixelToPoint(taskbar.width) + startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)));
            }
            taskbar.segment.map(function (data, index) {
              var segmenttaskbarPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].taskBorderColor) : taskbarPen_1;
              var segmenttaskBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].taskColor) : taskBrush_1;
              if (_this.isAutoFit()) {
                taskGraphics.drawRectangle(segmenttaskbarPen, segmenttaskBrush, startPoint.x + (_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, data.width, pixelToPoint(taskbar.height));
                if (template.value || template.image) {
                  var imageWidth = void 0;
                  if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                    imageWidth = taskbar.taskbarTemplate.image[0].width;
                    var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                    taskGraphics.drawImage(image, startPoint.x + (_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                  } else {
                    imageWidth = 0;
                  }
                  !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (_this.left + data.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height), progressFormat) : '';
                }
              } else {
                taskGraphics.drawRectangle(segmenttaskbarPen, segmenttaskBrush, startPoint.x + pixelToPoint(_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height));
                if (template.value || template.image) {
                  var imageWidth = void 0;
                  if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                    imageWidth = taskbar.taskbarTemplate.image[0].width;
                    var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                    taskGraphics.drawImage(image, startPoint.x + pixelToPoint(_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                  } else {
                    imageWidth = 0;
                  }
                  !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(_this.left + data.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height), progressFormat) : '';
                }
              }
            });
          } else if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
            taskGraphics.save();
            var path = new PdfPath();
            path.addEllipse(0, 0, 5, 5);
            if (this.isAutoFit()) {
              taskGraphics.translateTransform(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 - 2);
            } else {
              taskGraphics.translateTransform(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 - 2);
            }
            taskGraphics.drawPath(manualTaskbarPen, manuallineBrush, path);
            taskGraphics.restore();
            var path1 = new PdfPath();
            path1.addEllipse(0, 0, 5, 5);
            taskGraphics.save();
            if (this.isAutoFit()) {
              taskGraphics.translateTransform(startPoint.x + (this.left - cumulativeWidth) + 0.5 + this.width, startPoint.y + adjustHeight_1 - 2);
            } else {
              taskGraphics.translateTransform(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(this.width), startPoint.y + adjustHeight_1 - 2);
            }
            taskGraphics.drawPath(manualTaskbarPen, manuallineBrush, path1);
            taskGraphics.restore();
            manualline.dashStyle = PdfDashStyle.Solid;
            if (this.isAutoFit()) {
              taskGraphics.drawLine(manualline, new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1), new PointF(taskbar.width + startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1));
            } else {
              taskGraphics.drawLine(manualline, new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1), new PointF(pixelToPoint(taskbar.width) + startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1));
            }
          } else if (!taskbar.isAutoSchedule && !taskbar.isParentTask) {
            taskGraphics.save();
            taskGraphics.setTransparency(0.87);
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(manualChildBorderPen, manualChildBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.width, pixelToPoint(taskbar.height));
            } else {
              taskGraphics.drawRectangle(manualChildBorderPen, manualChildBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
            }
            taskGraphics.restore();
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.progressWidth, pixelToPoint(this.height));
            } else {
              taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.progressWidth), pixelToPoint(this.height));
            }
          } else {
            if (this.isAutoFit()) {
              if (isNullOrUndefined(template.value) && isNullOrUndefined(template.image)) {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.width, pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.width, pixelToPoint(taskbar.height));
                var imageWidth = void 0;
                if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                  imageWidth = taskbar.taskbarTemplate.image[0].width / 2.0;
                  var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                  taskGraphics.drawImage(image, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + (taskbar.height - taskbar.taskbarTemplate.image[0].height) / 2.0, taskbar.taskbarTemplate.image[0].width / 2.0, taskbar.taskbarTemplate.image[0].height / 2.0);
                } else {
                  imageWidth = 0;
                }
                if (taskbar.taskbarTemplate.value) {
                  if (isLabelString) {
                    updatedWidth = this.width;
                  }
                  taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (this.left - cumulativeWidth) + imageWidth, startPoint.y + adjustHeight_1, updatedWidth, pixelToPoint(this.height), progressFormat);
                }
              }
            } else {
              if (isNullOrUndefined(template.value) && isNullOrUndefined(template.image)) {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
                var imageWidth = void 0;
                if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                  imageWidth = taskbar.taskbarTemplate.image[0].width;
                  var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                  taskGraphics.drawImage(image, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 1, startPoint.y + adjustHeight_1 + 1 + pixelToPoint((taskbar.height - taskbar.taskbarTemplate.image[0].height) / 2.0), pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                } else {
                  imageWidth = 0;
                }
                !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat) : '';
              }
            }
          }
          if (this.isScheduledTask && taskbar.isAutoSchedule && !taskbar.isSpliterTask) {
            if (isNullOrUndefined(template.image) && isNullOrUndefined(template.value)) {
              if (this.isAutoFit()) {
                taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, taskbar.progressWidth, pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.progressWidth), pixelToPoint(taskbar.height));
              }
              if (!isNullOrUndefined(this.parent.labelSettings.taskLabel) && !isNullOrUndefined(this.taskLabel)) {
                if (this.taskLabel !== '0' && this.taskLabel !== '0%') {
                  updatedWidth = this.progressWidth;
                }
                if (isLabelString) {
                  updatedWidth = this.width;
                }
                if (this.isAutoFit()) {
                  taskGraphics.drawString(this.taskLabel.toString(), taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + (this.left - cumulativeWidth), startPoint.y + adjustHeight_1, updatedWidth, pixelToPoint(this.height), progressFormat);
                } else {
                  taskGraphics.drawString(this.taskLabel.toString(), taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                }
              }
            }
          } else if (taskbar.isSpliterTask) {
            taskbar.segment.map(function (data, index) {
              var segmentprogressPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].progressColor) : progressPen_1;
              var segmentprogressBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].progressColor) : progressBrush_1;
              if (_this.isAutoFit()) {
                taskGraphics.drawRectangle(segmentprogressPen, segmentprogressBrush, startPoint.x + (_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, data.progressWidth, pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(segmentprogressPen, segmentprogressBrush, startPoint.x + pixelToPoint(_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(data.progressWidth), pixelToPoint(taskbar.height));
              }
            });
          }
        }
        this.isCompleted = true;
        this.startPage = pageIndex;
        this.endPage = pageIndex;
      }
      //Task start date is in the range of header split up start and end date
      else if (detail.startDate <= startDate && detail.endDate >= startDate && endDate >= detail.endDate) {
        if (!this.isStartPoint) {
          this.taskStartPoint = __assign$8({}, startPoint);
          this.isStartPoint = true;
        }
        var width = this.width;
        var renderWidth_1 = 0;
        var progressWidth = 0;
        var splitRenderwidth_1 = 0;
        this.width = this.width - (detail.totalWidth - (this.left - cumulativeWidth));
        renderWidth_1 = detail.totalWidth - (this.left - cumulativeWidth);
        progressWidth = detail.totalWidth - (this.left - cumulativeWidth);
        if (width < renderWidth_1) {
          renderWidth_1 = width;
        }
        splitRenderwidth_1 = renderWidth_1;
        if (!this.isScheduledTask && this.unscheduledTaskBy === 'duration') {
          var brush1 = void 0;
          var brush2 = void 0;
          if (this.isAutoFit()) {
            brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5 + renderWidth_1 / 2, 0), new PointF(startPoint.x + (this.left - cumulativeWidth), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
            taskGraphics.drawRectangle(brush1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, renderWidth_1, pixelToPoint(taskbar.height));
            brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5 + renderWidth_1, 0), new PointF(startPoint.x + (this.left - cumulativeWidth) + renderWidth_1 / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
            taskGraphics.drawRectangle(brush2, startPoint.x + (this.left - cumulativeWidth) + 0.5 + renderWidth_1 / 2, startPoint.y + adjustHeight_1, renderWidth_1 / 2, pixelToPoint(taskbar.height));
            if (template.value || template.image) {
              var imageWidth = void 0;
              if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                imageWidth = taskbar.taskbarTemplate.image[0].width;
                var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                taskGraphics.drawImage(image, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
              } else {
                imageWidth = 0;
              }
              !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : '';
            }
          } else {
            brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(renderWidth_1) / 2, 0), new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
            taskGraphics.drawRectangle(brush1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1), pixelToPoint(taskbar.height));
            brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(renderWidth_1), 0), new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + pixelToPoint(renderWidth_1) / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
            taskGraphics.drawRectangle(brush2, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + pixelToPoint(renderWidth_1) / 2, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1) / 2, pixelToPoint(taskbar.height));
            if (template.value || template.image) {
              var imageWidth = void 0;
              if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                imageWidth = taskbar.taskbarTemplate.image[0].width;
                var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                taskGraphics.drawImage(image, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
              } else {
                imageWidth = 0;
              }
              !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5 + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : '';
            }
          }
        } else if (!this.isScheduledTask && this.unscheduledTaskBy !== 'duration') {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
          taskGraphics.save();
          var path = new PdfPath();
          path.addEllipse(0, 0, 5, 5);
          if (this.isAutoFit()) {
            taskGraphics.translateTransform(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 - 2);
          } else {
            taskGraphics.translateTransform(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 - 2);
          }
          taskGraphics.drawPath(manualTaskbarPen, manuallineBrush, path);
          taskGraphics.restore();
          manualline.dashStyle = PdfDashStyle.Solid;
          if (this.isAutoFit()) {
            taskGraphics.drawLine(manualline, new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1), new PointF(renderWidth_1 + startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1));
          } else {
            taskGraphics.drawLine(manualline, new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1), new PointF(pixelToPoint(renderWidth_1) + startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1));
          }
        } else if (!taskbar.isAutoSchedule && !taskbar.isParentTask) {
          if (this.isAutoFit()) {
            taskGraphics.drawRectangle(manualChildBorderPen, null, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, renderWidth_1, pixelToPoint(taskbar.height));
          } else {
            taskGraphics.drawRectangle(manualChildBorderPen, null, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1), pixelToPoint(taskbar.height));
          }
          taskGraphics.save();
          taskGraphics.setTransparency(0.87);
          if (this.isAutoFit()) {
            taskGraphics.drawRectangle(null, manualChildBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, renderWidth_1, pixelToPoint(taskbar.height));
          } else {
            taskGraphics.drawRectangle(null, manualChildBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1), pixelToPoint(taskbar.height));
          }
          taskGraphics.restore();
          if (this.isAutoFit()) {
            taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, progressWidth, pixelToPoint(taskbar.height));
          } else {
            taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(progressWidth), pixelToPoint(taskbar.height));
          }
          taskbar.progressWidth = taskbar.progressWidth - progressWidth;
        } else if (!this.isScheduledTask && this.unscheduledTaskBy !== 'duration') {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else if (!this.isScheduledTask && this.unscheduledTaskBy === "endDate") {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else {
          if (taskbar.isSpliterTask) {
            var pervwidth_1 = 0;
            var valueChangeBlocker_1 = true;
            var lineWidth_1 = 0;
            taskbar.segment.map(function (item) {
              lineWidth_1 = item.left + item.width;
            });
            splitline.dashStyle = PdfDashStyle.Dot;
            if (this.isAutoFit()) {
              taskGraphics.drawLine(splitline, new PointF(startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)), new PointF(lineWidth_1 + (this.left - cumulativeWidth) + startPoint.x, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)));
            } else {
              taskGraphics.drawLine(splitline, new PointF(startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)), new PointF(pixelToPoint(lineWidth_1) + pixelToPoint(this.left - cumulativeWidth) + startPoint.x, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)));
            }
            taskbar.segment.map(function (data, index) {
              var segmenttaskbarPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].taskBorderColor) : taskbarPen_1;
              var segmenttaskBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].taskColor) : taskBrush_1;
              if (_this.isAutoFit()) {
                taskGraphics.drawRectangle(segmenttaskbarPen, segmenttaskBrush, startPoint.x + (_this.left - cumulativeWidth) + 0.5 + data.left, startPoint.y + adjustHeight_1, data.width, pixelToPoint(taskbar.height));
                if (template.value || template.image) {
                  var imageWidth = void 0;
                  if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                    imageWidth = taskbar.taskbarTemplate.image[0].width;
                    var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                    taskGraphics.drawImage(image, startPoint.x + (_this.left - cumulativeWidth) + 0.5 + data.left, startPoint.y + adjustHeight_1, taskbar.taskbarTemplate.image[0].width, taskbar.taskbarTemplate.image[0].height);
                  } else {
                    imageWidth = 0;
                  }
                  !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (_this.left - cumulativeWidth) + 0.5 + data.left + imageWidth, startPoint.y + adjustHeight_1, data.width, taskbar.height, progressFormat) : '';
                }
              } else {
                taskGraphics.drawRectangle(segmenttaskbarPen, segmenttaskBrush, startPoint.x + pixelToPoint(_this.left - cumulativeWidth) + 0.5 + pixelToPoint(data.left), startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height));
                if (template.value || template.image) {
                  var imageWidth = void 0;
                  if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                    imageWidth = taskbar.taskbarTemplate.image[0].width;
                    var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                    taskGraphics.drawImage(image, startPoint.x + pixelToPoint(_this.left - cumulativeWidth) + 0.5 + pixelToPoint(data.left), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                  } else {
                    imageWidth = 0;
                  }
                  !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(_this.left - cumulativeWidth) + 0.5 + pixelToPoint(data.left) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height), progressFormat) : '';
                }
              }
              pervwidth_1 = data.left + data.width;
              if (renderWidth_1 >= pervwidth_1) {
                _this.segmentCollection[parseInt(index.toString(), 10)].width = 0;
                _this.segmentCollection[parseInt(index.toString(), 10)].left = 0;
              } else {
                if (renderWidth_1 >= pervwidth_1 - data.width) {
                  _this.segmentCollection[parseInt(index.toString(), 10)].left = 0;
                } else {
                  _this.segmentCollection[parseInt(index.toString(), 10)].left = splitRenderwidth_1 - data.left;
                }
                if (renderWidth_1 >= pervwidth_1) {
                  _this.segmentCollection[parseInt(index.toString(), 10)].width = 0;
                } else {
                  if (valueChangeBlocker_1) {
                    _this.segmentCollection[parseInt(index.toString(), 10)].width = pervwidth_1 - renderWidth_1;
                    valueChangeBlocker_1 = false;
                  }
                }
              }
              splitRenderwidth_1 = splitRenderwidth_1 + data.width + data.left;
            });
          } else {
            if (this.isAutoFit()) {
              if (isNullOrUndefined(template.value) && isNullOrUndefined(template.image)) {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, renderWidth_1, pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, renderWidth_1, pixelToPoint(taskbar.height));
                if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                  var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                  taskGraphics.drawImage(image, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint((taskbar.height - taskbar.taskbarTemplate.image[0].height) / 0.5), taskbar.taskbarTemplate.image[0].width / 2.0, taskbar.taskbarTemplate.image[0].height / 2.0);
                }
                if (!isNullOrUndefined(taskbar.taskbarTemplate.value)) {
                  var imageWidth = !isNullOrUndefined(taskbar.taskbarTemplate.image) ? taskbar.taskbarTemplate.image[0].width : 0;
                  taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + (this.left - cumulativeWidth) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                }
              }
            } else {
              if (isNullOrUndefined(template.value) && isNullOrUndefined(template.image)) {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1), pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(renderWidth_1), pixelToPoint(taskbar.height));
                var imageWidth = void 0;
                if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                  imageWidth = taskbar.taskbarTemplate.image[0].width;
                  var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                  taskGraphics.drawImage(image, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 1, startPoint.y + adjustHeight_1 + 1 + pixelToPoint((taskbar.height - taskbar.taskbarTemplate.image[0].height) / 2.0), pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                } else {
                  imageWidth = 0;
                }
                if (!isNullOrUndefined(taskbar.taskbarTemplate.value)) {
                  this.stringLeft = pixelToPoint(this.left);
                  var result_1 = this.getWidth(taskbar.taskbarTemplate.value, detail.endPoint - this.stringLeft, 15);
                  taskGraphics.drawString(result_1.lines[0].text, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                  if (!isNullOrUndefined(result_1.remainder)) {
                    this.remainString = result_1.remainder;
                    this.stringLeft = detail.endPoint;
                    // this.rightTaskLabel.isLeftCalculated = true;
                  }
                }
              }
            }
            if (taskbar.isAutoSchedule && !taskbar.isSpliterTask) {
              var progressBoundsWidth = 0;
              if (this.progressWidth <= renderWidth_1) {
                progressBoundsWidth = this.progressWidth;
              } else {
                progressBoundsWidth = renderWidth_1;
              }
              if (isNullOrUndefined(template.image) && isNullOrUndefined(template.value)) {
                if (this.isAutoFit()) {
                  taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + (this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, progressBoundsWidth, pixelToPoint(taskbar.height));
                } else {
                  taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + pixelToPoint(this.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(progressBoundsWidth), pixelToPoint(taskbar.height));
                }
              }
              this.progressWidth -= progressBoundsWidth;
              if (this.parent.labelSettings.taskLabel && !isNullOrUndefined(this.taskLabel)) {
                updatedWidth = progressBoundsWidth;
                if (isLabelString) {
                  updatedWidth = renderWidth_1;
                }
                this.stringLeft = this.left;
                if (isNullOrUndefined(taskbar.taskbarTemplate.value)) {
                  var result_2 = this.getWidth(this.taskLabel.toString(), detail.endPoint - this.stringLeft, 15);
                  taskGraphics.drawString(result_2.lines[0].text, taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                  if (!isNullOrUndefined(result_2.remainder)) {
                    this.remainString = result_2.remainder;
                    this.stringLeft = detail.endPoint;
                    // this.rightTaskLabel.isLeftCalculated = true;
                  }
                } else {
                  if (this.isAutoFit()) {
                    taskGraphics.drawString(this.taskLabel.toString(), taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + (this.left - cumulativeWidth), startPoint.y + adjustHeight_1, updatedWidth, pixelToPoint(this.height), progressFormat);
                  } else {
                    taskGraphics.drawString(this.taskLabel.toString(), taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                  }
                }
              }
            }
          }
          if (taskbar.isSpliterTask) {
            taskbar.segment.map(function (data, index) {
              var segmentprogressPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].progressColor) : progressPen_1;
              var segmentprogressBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].progressColor) : progressBrush_1;
              if (_this.isAutoFit()) {
                taskGraphics.drawRectangle(segmentprogressPen, segmentprogressBrush, startPoint.x + (_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, data.progressWidth, pixelToPoint(taskbar.height));
              } else {
                taskGraphics.drawRectangle(segmentprogressPen, segmentprogressBrush, startPoint.x + pixelToPoint(_this.left + data.left - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(data.progressWidth), pixelToPoint(taskbar.height));
              }
              if (_this.segmentCollection[parseInt(index.toString(), 10)].width === 0) {
                _this.segmentCollection[parseInt(index.toString(), 10)].progressWidth = 0;
              } else {
                if (data.width - _this.segmentCollection[parseInt(index.toString(), 10)].width < data.progressWidth && data.width !== _this.segmentCollection[index].width) {
                  _this.segmentCollection[parseInt(index.toString(), 10)].progressWidth = data.progressWidth - (data.width - _this.segmentCollection[index].width);
                }
              }
            });
          }
        }
        this.left = 0;
        this.isCompleted = false;
        this.startPage = pageIndex;
      }
      //Task end date is in the range of header split up start and end date
      else if (endDate <= detail.endDate && detail.startDate <= endDate && !this.isCompleted) {
        if (!this.isStartPoint) {
          this.taskStartPoint = __assign$8({}, startPoint);
          this.isStartPoint = true;
        }
        if (!this.isScheduledTask && this.unscheduledTaskBy === 'duration') {
          var brush1 = void 0;
          var brush2 = void 0;
          brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(taskbar.left + 0.5) + pixelToPoint(taskbar.width) / 2, 0), new PointF(startPoint.x + pixelToPoint(taskbar.left + 0.5), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
          taskGraphics.drawRectangle(brush1, startPoint.x + pixelToPoint(taskbar.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
          brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(taskbar.left + 0.5) + pixelToPoint(taskbar.width), 0), new PointF(startPoint.x + pixelToPoint(taskbar.left + 0.5) + pixelToPoint(taskbar.width) / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
          taskGraphics.drawRectangle(brush2, startPoint.x + pixelToPoint(taskbar.left + 0.5) + pixelToPoint(taskbar.width) / 2, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width) / 2, pixelToPoint(taskbar.height));
          if (template.value || template.image) {
            var imageWidth = void 0;
            if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
              imageWidth = taskbar.taskbarTemplate.image[0].width;
              var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
              taskGraphics.drawImage(image, startPoint.x + pixelToPoint(taskbar.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
            } else {
              imageWidth = 0;
            }
            !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(taskbar.left + 0.5) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : '';
          }
        } else if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
          var path1 = new PdfPath();
          path1.addEllipse(0, 0, 5, 5); // Circle with radius 5
          taskGraphics.save(); // Save the current graphics state  
          var lineStartX = startPoint.x + pixelToPoint(this.left + 0.5); // Calculate the endpoint of the line
          var lineEndX = pixelToPoint(taskbar.width) + lineStartX - 5;
          var lineY = startPoint.y + adjustHeight_1;
          taskGraphics.drawLine(manualline, lineStartX, lineY, lineEndX, lineY); // Draw the line first     
          taskGraphics.translateTransform(lineEndX, lineY - 2); // Now move the origin to the line's end point to draw the circle        
          taskGraphics.drawPath(manualTaskbarPen, manuallineBrush, path1); // Draw the circle at the end of the line  
          taskGraphics.restore(); // Restore the graphics state
          manualline.dashStyle = PdfDashStyle.Solid; // Ensure the line has a solid style
        } else if (!taskbar.isAutoSchedule && !taskbar.isParentTask) {
          taskGraphics.drawRectangle(manualChildBorderPen, null, startPoint.x + pixelToPoint(this.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
          taskGraphics.save();
          taskGraphics.setTransparency(0.87);
          taskGraphics.drawRectangle(null, manualChildBrush, startPoint.x + pixelToPoint(this.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
          taskGraphics.restore();
          taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + pixelToPoint(this.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.progressWidth), pixelToPoint(taskbar.height));
        } else if (!this.isScheduledTask && this.unscheduledTaskBy === "endDate") {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else if (taskbar.isSpliterTask) {
          splitline.dashStyle = PdfDashStyle.Dot;
          taskGraphics.drawLine(splitline, new PointF(startPoint.x + pixelToPoint(this.left) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)), new PointF(pixelToPoint(taskbar.width) + startPoint.x + pixelToPoint(this.left), startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height / 2)));
          taskbar.segmentCollection.map(function (data, index) {
            var segmenttaskbarPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].taskBorderColor) : taskbarPen_1;
            var segmenttaskBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].taskColor) : taskBrush_1;
            if (data.width !== 0) {
              taskGraphics.drawRectangle(segmenttaskbarPen, segmenttaskBrush, startPoint.x + pixelToPoint(taskbar.left + 0.5 + data.left), startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height));
              if (template.value || template.image) {
                var imageWidth = void 0;
                if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
                  imageWidth = taskbar.taskbarTemplate.image[0].width;
                  var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
                  taskGraphics.drawImage(image, startPoint.x + pixelToPoint(taskbar.left + 0.5 + data.left), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
                } else {
                  imageWidth = 0;
                }
                !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(taskbar.left + 0.5 + data.left) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(data.width), pixelToPoint(taskbar.height), progressFormat) : '';
              }
            }
          });
        } else {
          taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(taskbar.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(taskbar.height));
          if (!isNullOrUndefined(this.remainString)) {
            var result = this.getWidth(this.remainString, taskbar.width - taskbar.left, 15);
            taskGraphics.drawString(result.lines[0].text, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(taskbar.left), startPoint.y + adjustHeight_1, result.actualSize.width, pixelToPoint(this.height), progressFormat);
          }
        }
        if (this.isScheduledTask && taskbar.isAutoSchedule && !taskbar.isSpliterTask) {
          if (isNullOrUndefined(template.image) && isNullOrUndefined(template.value)) {
            taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + pixelToPoint(taskbar.left + 0.5), startPoint.y + adjustHeight_1, pixelToPoint(taskbar.progressWidth), pixelToPoint(taskbar.height));
          }
          if (!isNullOrUndefined(this.taskLabel)) {
            updatedWidth = this.progressWidth;
            if (isLabelString) {
              updatedWidth = this.width;
            }
            if (isNullOrUndefined(taskbar.taskbarTemplate.value)) {
              if (isNullOrUndefined(taskbar.taskbarTemplate.value)) {
                if (!isNullOrUndefined(this.remainString)) {
                  var result_3 = this.getWidth(this.remainString, detail.endPoint - this.stringLeft, 15);
                  taskGraphics.drawString(result_3.lines[0].text, font, fontColor, fontBrush, startPoint.x + pixelToPoint(this.left), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
                }
              }
            }
            if (!isNullOrUndefined(this.taskLabel)) {
              updatedWidth = this.progressWidth;
              if (isLabelString) {
                updatedWidth = this.width;
              }
              taskGraphics.drawString(this.taskLabel.toString(), font, fontColor, fontBrush, startPoint.x + pixelToPoint(this.left), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
            }
          }
        } else if (taskbar.isSpliterTask) {
          taskbar.segmentCollection.map(function (data, index) {
            var segmentprogressPen = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfPen(_this.taskSegmentStyles[index].progressColor) : progressPen_1;
            var segmentprogressBrush = !isNullOrUndefined(_this.taskSegmentStyles) ? new PdfSolidBrush(_this.taskSegmentStyles[index].progressColor) : progressBrush_1;
            taskGraphics.drawRectangle(segmentprogressPen, segmentprogressBrush, startPoint.x + pixelToPoint(taskbar.left + 0.5 + data.left), startPoint.y + adjustHeight_1, pixelToPoint(data.progressWidth), pixelToPoint(taskbar.height));
          });
        }
        this.isCompleted = true;
        this.endPage = pageIndex;
      }
      //Header splitup start and end date with in the task start and end date.
      //So the task is takes entire width of page.
      else if (startDate < detail.startDate && endDate > detail.endDate) {
        if (!this.isStartPoint) {
          this.taskStartPoint = __assign$8({}, startPoint);
          this.isStartPoint = true;
        }
        if (!this.isScheduledTask && this.unscheduledTaskBy === 'duration') {
          var brush1 = void 0;
          var brush2 = void 0;
          brush1 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(taskbar.left) + 0.5 + pixelToPoint(detail.totalWidth) / 2, 0), new PointF(startPoint.x + pixelToPoint(taskbar.left), 0), new PdfColor(taskbar.unscheduledTaskBarColor), new PdfColor(255, 255, 255));
          taskGraphics.drawRectangle(brush1, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), pixelToPoint(taskbar.height));
          brush2 = new PdfLinearGradientBrush(new PointF(startPoint.x + pixelToPoint(taskbar.left) + 0.5 + pixelToPoint(detail.totalWidth), 0), new PointF(startPoint.x + pixelToPoint(taskbar.left) + pixelToPoint(detail.totalWidth) / 2, 0), new PdfColor(255, 255, 255), new PdfColor(taskbar.unscheduledTaskBarColor));
          taskGraphics.drawRectangle(brush2, startPoint.x + pixelToPoint(taskbar.left) + 0.5 + pixelToPoint(detail.totalWidth) / 2, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth) / 2, pixelToPoint(taskbar.height));
          if (template.value || template.image) {
            var imageWidth = void 0;
            if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
              imageWidth = taskbar.taskbarTemplate.image[0].width;
              var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
              taskGraphics.drawImage(image, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height));
            } else {
              imageWidth = 0;
            }
            !isNullOrUndefined(taskbar.taskbarTemplate.value) ? taskGraphics.drawString(taskbar.taskbarTemplate.value, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(taskbar.left + 0.5) + imageWidth, startPoint.y + adjustHeight_1, pixelToPoint(taskbar.width), pixelToPoint(this.height), progressFormat) : 0;
          }
        } else if (!this.isScheduledTask && this.unscheduledTaskBy === "endDate") {
          this.drawUnscheduledTask(taskGraphics, startPoint, cumulativeWidth, adjustHeight_1);
        } else if (!taskbar.isAutoSchedule && !taskbar.isParentTask) {
          taskGraphics.drawRectangle(manualChildBorderPen, null, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), pixelToPoint(taskbar.height));
          taskGraphics.save();
          taskGraphics.setTransparency(0.87);
          taskGraphics.drawRectangle(null, manualChildBrush, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), pixelToPoint(taskbar.height));
          taskGraphics.restore();
          var progressBoundsWidth = 0;
          if (this.progressWidth <= detail.totalWidth) {
            progressBoundsWidth = this.progressWidth;
          } else {
            progressBoundsWidth = detail.totalWidth;
          }
          if (isNullOrUndefined(template.image) && isNullOrUndefined(template.value)) {
            taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(progressBoundsWidth), pixelToPoint(taskbar.height));
          }
          this.progressWidth -= progressBoundsWidth;
          taskGraphics.drawRectangle(null, manualChildProgressBrush, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(progressBoundsWidth), pixelToPoint(taskbar.height));
        } else if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
          manualline.dashStyle = PdfDashStyle.Solid;
          taskGraphics.drawLine(manualline, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), startPoint.y + adjustHeight_1);
        } else {
          taskGraphics.drawRectangle(taskbarPen_1, taskBrush_1, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), pixelToPoint(taskbar.height));
          if (!isNullOrUndefined(taskbar.taskbarTemplate.value)) {
            if (!isNullOrUndefined(this.remainString)) {
              var result_4 = this.getWidth(this.remainString, detail.endPoint - this.stringLeft, 15);
              taskGraphics.drawString(result_4.lines[0].text, customizedFont, customizedFontColor, customizedFontBrush, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(detail.totalWidth), pixelToPoint(this.height), progressFormat);
            }
          }
          if (this.isScheduledTask && !taskbar.isParentTask) {
            var progressBoundsWidth = 0;
            if (this.progressWidth <= detail.totalWidth) {
              progressBoundsWidth = this.progressWidth;
            } else {
              progressBoundsWidth = detail.totalWidth;
            }
            if (isNullOrUndefined(template.image) && isNullOrUndefined(template.value)) {
              taskGraphics.drawRectangle(progressPen_1, progressBrush_1, startPoint.x + pixelToPoint(taskbar.left) + 0.5, startPoint.y + adjustHeight_1, pixelToPoint(progressBoundsWidth), pixelToPoint(taskbar.height));
            }
            this.progressWidth -= progressBoundsWidth;
            if (!isNullOrUndefined(this.taskLabel)) {
              updatedWidth = progressBoundsWidth;
              if (isLabelString) {
                updatedWidth = this.width;
              }
              taskGraphics.drawString(this.taskLabel.toString(), taskLabelFont, fontColor, taskLabelFontBrush, startPoint.x + pixelToPoint(this.left), startPoint.y + adjustHeight_1, pixelToPoint(updatedWidth), pixelToPoint(this.height), progressFormat);
            }
          }
        }
        this.isCompleted = false;
        this.width -= detail.totalWidth;
      }
      if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
        if (detail.startDate <= taskbar.baselineStartDate && taskbar.baselineEndDate <= detail.endDate) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          }
          if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + (taskbar.baselineLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), taskbar.baselineWidth, pixelToPoint(this.baselineHeight));
            } else {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + pixelToPoint(taskbar.baselineLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), pixelToPoint(taskbar.baselineWidth), pixelToPoint(this.baselineHeight));
            }
          }
          this.isCompletedBaseline = true;
          this.startPage = pageIndex;
          this.endPage = pageIndex;
        } else if (detail.startDate <= taskbar.baselineStartDate && detail.endDate >= taskbar.baselineStartDate && taskbar.baselineEndDate >= detail.endDate) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          }
          var width = this.baselineWidth;
          var renderWidth = 0;
          renderWidth = detail.totalWidth - (taskbar.baselineLeft - cumulativeWidth);
          if (width < renderWidth) {
            renderWidth = width;
          }
          if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + (taskbar.baselineLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), renderWidth, pixelToPoint(this.baselineHeight));
            } else {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + pixelToPoint(taskbar.baselineLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), pixelToPoint(renderWidth), pixelToPoint(this.baselineHeight));
            }
          }
          taskbar.baselineWidth = taskbar.baselineWidth - renderWidth;
          this.baselineLeft = 0;
          this.isCompletedBaseline = false;
          this.startPage = pageIndex;
        } else if (taskbar.baselineEndDate <= detail.endDate && detail.startDate <= taskbar.baselineEndDate && !this.isCompletedBaseline) {
          if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + (taskbar.baselineLeft + 0.5), startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), taskbar.baselineWidth, pixelToPoint(this.baselineHeight));
            } else {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + pixelToPoint(taskbar.baselineLeft + 0.5), startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), pixelToPoint(taskbar.baselineWidth), pixelToPoint(this.baselineHeight));
            }
          }
          this.isCompletedBaseline = true;
          this.endPage = pageIndex;
        } else if (taskbar.baselineStartDate < detail.startDate && taskbar.baselineEndDate > detail.endDate) {
          if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + taskbar.baselineLeft + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), detail.totalWidth, pixelToPoint(taskbar.baselineHeight));
            } else {
              taskGraphics.drawRectangle(baselinePen, baselineBrush, startPoint.x + pixelToPoint(taskbar.baselineLeft) + 0.5, startPoint.y + adjustHeight_1 + pixelToPoint(taskbar.height + 3), pixelToPoint(detail.totalWidth), pixelToPoint(taskbar.baselineHeight));
            }
          }
          this.isCompletedBaseline = false;
          this.baselineWidth -= detail.totalWidth;
        }
      }
      if (!this.isAutoSchedule && taskbar.isParentTask) {
        if (detail.startDate <= taskbar.autoStartDate && taskbar.autoEndDate <= detail.endDate) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          }
          if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, this.autoWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(this.autoWidth), pixelToPoint(12));
            }
            taskGraphics.save();
            taskGraphics.setTransparency(0.87);
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, this.autoWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(this.autoWidth), pixelToPoint(12));
            }
            taskGraphics.restore();
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, taskbar.progressWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(taskbar.progressWidth), pixelToPoint(12));
            }
          }
          this.isCompletedAutotask = true;
          this.startPage = pageIndex;
          this.endPage = pageIndex;
        } else if (detail.startDate <= taskbar.autoStartDate && detail.endDate >= taskbar.autoStartDate && taskbar.autoEndDate >= detail.endDate) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          }
          var renderWidth = 0;
          var progressWidth = 0;
          renderWidth = detail.totalWidth - (this.autoLeft - cumulativeWidth);
          progressWidth = detail.totalWidth - (this.autoLeft - cumulativeWidth);
          if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, renderWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(renderWidth), pixelToPoint(12));
            }
            taskGraphics.save();
            taskGraphics.setTransparency(0.87);
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, renderWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(renderWidth), pixelToPoint(12));
            }
            taskGraphics.restore();
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, taskbar.progressWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + pixelToPoint(this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(progressWidth), pixelToPoint(12));
            }
            taskbar.autoWidth = taskbar.autoWidth - renderWidth;
            taskbar.progressWidth = taskbar.progressWidth - progressWidth;
          }
          this.autoLeft = 0;
          this.isCompletedAutotask = false;
          this.startPage = pageIndex;
        } else if (taskbar.autoEndDate <= detail.endDate && detail.startDate <= taskbar.autoEndDate && !this.isCompletedAutotask) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          } else if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
            taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + pixelToPoint(this.autoLeft + 0.5), startPoint.y + adjustHeight_1 + 10, pixelToPoint(taskbar.autoWidth), pixelToPoint(12));
            taskGraphics.save();
            taskGraphics.setTransparency(0.87);
            taskGraphics.drawRectangle(manualBrush, startPoint.x + pixelToPoint(this.autoLeft + 0.5), startPoint.y + adjustHeight_1 + 10, pixelToPoint(taskbar.autoWidth), pixelToPoint(12));
            taskGraphics.restore();
            taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + pixelToPoint(this.autoLeft + 0.5), startPoint.y + adjustHeight_1 + 10, pixelToPoint(taskbar.progressWidth), pixelToPoint(12));
          }
          this.isCompletedAutotask = true;
          this.endPage = pageIndex;
        } else if (taskbar.autoStartDate < detail.startDate && taskbar.autoEndDate > detail.endDate) {
          if (!this.isStartPoint) {
            this.taskStartPoint = __assign$8({}, startPoint);
            this.isStartPoint = true;
          }
          if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
            var progressBoundsWidth = 0;
            if (this.progressWidth <= detail.totalWidth) {
              progressBoundsWidth = this.progressWidth;
            } else {
              progressBoundsWidth = detail.totalWidth;
            }
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + (this.autoLeft + 0.5), startPoint.y + adjustHeight_1 + 10, progressBoundsWidth, pixelToPoint(12));
            } else {
              if (taskbar.progressWidth !== 0) {
                taskGraphics.drawRectangle(null, manualProgressBrush, startPoint.x + pixelToPoint(this.autoLeft + 0.5), startPoint.y + adjustHeight_1 + 10, pixelToPoint(progressBoundsWidth), pixelToPoint(12));
              }
            }
            this.progressWidth -= progressBoundsWidth;
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + this.autoLeft + 0.5, startPoint.y + adjustHeight_1 + 10, detail.totalWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(manualParentBorderPen, null, startPoint.x + pixelToPoint(this.autoLeft) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(detail.totalWidth), pixelToPoint(12));
            }
            taskGraphics.save();
            taskGraphics.setTransparency(0.87);
            if (this.isAutoFit()) {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + (this.autoLeft - cumulativeWidth) + 0.5, startPoint.y + adjustHeight_1 + 10, detail.totalWidth, pixelToPoint(12));
            } else {
              taskGraphics.drawRectangle(null, manualBrush, startPoint.x + pixelToPoint(this.autoLeft) + 0.5, startPoint.y + adjustHeight_1 + 10, pixelToPoint(detail.totalWidth), pixelToPoint(12));
            }
            taskGraphics.restore();
          }
          this.isCompletedAutotask = false;
          this.autoWidth -= detail.totalWidth;
        }
      }
      if (!isNullOrUndefined(taskbar.indicators) && taskbar.indicators.length > 0) {
        taskbar.indicators.map(function (items, index) {
          var currendate = _this.parent.dateValidationModule.getDateFromFormat(items.date, true);
          if (detail.startDate <= currendate && currendate <= detail.endDate) {
            var leftValue = _this.parent.chartRowsModule.getIndicatorleft(items.date);
            if (!isNullOrUndefined(items.base64)) {
              var image = new PdfBitmap(items.base64);
              if (_this.isAutoFit()) {
                taskGraphics.drawImage(image, startPoint.x + (leftValue - cumulativeWidth) + 0.5 + 10 - _this.parent.perDayWidth / 2, startPoint.y + adjustHeight_1, imageSize_1, imageSize_1);
                var state = taskGraphics.save();
                taskGraphics.setClip(new RectangleF(startPoint.x, startPoint.y, page['contentWidth'], rowHeight));
                taskGraphics.drawString(items.name, font, null, PdfBrushes.Black, startPoint.x + (leftValue - cumulativeWidth) + 0.5 + 15 + imageSize_1 - _this.parent.perDayWidth / 2, startPoint.y + adjustHeight_1, null);
                taskGraphics.restore(state);
              } else {
                taskGraphics.drawImage(image, startPoint.x + pixelToPoint(leftValue - cumulativeWidth) + 0.5 + 10, startPoint.y + adjustHeight_1, imageSize_1, imageSize_1);
                var state = taskGraphics.save();
                taskGraphics.setClip(new RectangleF(startPoint.x, startPoint.y, page['contentWidth'], rowHeight));
                taskGraphics.drawString(items.name, font, null, PdfBrushes.Black, startPoint.x + pixelToPoint(leftValue - cumulativeWidth) + 0.5 + 15 + imageSize_1, startPoint.y + adjustHeight_1, null);
                taskGraphics.restore(state);
              }
            }
          }
        });
      }
    } else {
      this.drawMilestone(page, startPoint, detail, cumulativeWidth, taskbar, false);
      if (this.parent.renderBaseline && taskbar.baselineStartDate && taskbar.baselineEndDate) {
        this.drawMilestone(page, startPoint, detail, cumulativeWidth, taskbar, true);
      }
    }
    this.drawRightLabel(page, startPoint, detail, cumulativeWidth);
    return isNextPage;
  };
  /* eslint-enable */
  /**
   * @param {IGanttStyle} ganttStyle .
   * @returns {PdfFont}
   * Customizes the font based on the Gantt style.
   */
  PdfGanttTaskbarCollection.prototype.getPdfFont = function (ganttStyle) {
    var font;
    if (ganttStyle && ganttStyle.label && (ganttStyle.label.fontSize || ganttStyle.label.fontStyle || ganttStyle.label.fontFamily)) {
      var fontSize = ganttStyle.label.fontSize ? ganttStyle.label.fontSize : 9;
      var fontFamilyValue = ganttStyle.label.fontFamily;
      var fontFamily = ganttStyle.label.fontFamily ? fontFamilyValue : this.fontFamily;
      var fontStyleValue = ganttStyle.label.fontStyle;
      var fontStyle = ganttStyle.label.fontStyle ? this.parent.pdfExportModule.helper['getFontStyle'](fontStyleValue) : PdfFontStyle.Regular;
      font = new PdfStandardFont(fontFamily, fontSize, fontStyle);
    }
    return font;
  };
  /* eslint-enable */
  /**
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {TimelineDetails} detail .
   * @param {number} cumulativeWidth .
   * @returns {void}
   * Draw task right side label
   */
  PdfGanttTaskbarCollection.prototype.drawRightLabel = function (page, startPoint, detail, cumulativeWidth) {
    var left;
    var graphics = page.graphics;
    if (!isNullOrUndefined(this.rightTaskLabel.value)) {
      if (this.rightTaskLabel.isLeftCalculated) {
        left = this.rightTaskLabel.left;
      } else {
        if (this.isAutoFit()) {
          left = this.rightTaskLabel.left;
        } else {
          left = pixelToPoint(this.rightTaskLabel.left);
        }
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - cumulativeWidth + startPoint.x;
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(this.rightTaskLabel.value) && !this.rightTaskLabel.isCompleted) {
        var result = this.getWidth(this.rightTaskLabel.value, detail.endPoint - left, 15);
        var font = new PdfStandardFont(this.fontFamily, 9);
        var customizedFont = void 0;
        var ganttStyle = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle;
        if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && (!isNullOrUndefined(ganttStyle.label.fontSize) || !isNullOrUndefined(ganttStyle.label.fontStyle) || !isNullOrUndefined(ganttStyle.label.fontFamily))) {
          customizedFont = this.getPdfFont(ganttStyle);
        }
        if (!isNullOrUndefined(customizedFont)) {
          font = customizedFont;
        }
        var padding = {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        };
        if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && !isNullOrUndefined(ganttStyle.label.padding)) {
          padding = ganttStyle.label.padding;
        }
        if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
          font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
        }
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.actualSize.height) / 2;
        var point = new PointF(actualLeft + padding.left, startPoint.y + adjustHeight + padding.top);
        var size = new SizeF(page['contentWidth'] - actualLeft - (padding.left + padding.right), result.actualSize.height - (padding.top + padding.bottom));
        var labelBounds = new RectangleF(point, size);
        var labelFormat = new PdfStringFormat();
        labelFormat.alignment = PdfTextAlignment.Left;
        labelFormat.lineAlignment = PdfVerticalAlignment.Middle;
        if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && !isNullOrUndefined(ganttStyle.label.format) && !isNullOrUndefined(ganttStyle.label.format.alignment)) {
          labelFormat.alignment = ganttStyle.label.format.alignment;
        }
        if (result.actualSize.width > 0) {
          var fontColor = null;
          var ganttStyle_1 = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle;
          var fontBrush = new PdfSolidBrush(ganttStyle_1 && ganttStyle_1.label && ganttStyle_1.label.fontBrush ? ganttStyle_1.label.fontBrush : this.labelColor);
          /* eslint-disable-next-line */
          var labelBrush = null;
          if (!isNullOrUndefined(ganttStyle_1) && !isNullOrUndefined(ganttStyle_1.label) && !isNullOrUndefined(ganttStyle_1.label.backgroundColor)) {
            labelBrush = new PdfSolidBrush(ganttStyle_1.label.backgroundColor);
          }
          var lablePen = null;
          if (!isNullOrUndefined(ganttStyle_1) && !isNullOrUndefined(ganttStyle_1.label) && !isNullOrUndefined(ganttStyle_1.label.borderColor)) {
            lablePen = new PdfPen(ganttStyle_1.label.borderColor);
          }
          var strSize = font.measureString(result.lines[0].text);
          graphics.drawRectangle(lablePen, labelBrush, labelBounds.x - 3, labelBounds.y, strSize.width + 6, strSize.height);
          graphics.drawString(result.lines[0].text, font, fontColor, fontBrush, labelBounds.x, labelBounds.y, size.width, size.height, labelFormat);
          if (result.remainder !== null) {
            this.rightTaskLabel.value = result.remainder;
            this.rightTaskLabel.left = detail.endPoint;
            this.rightTaskLabel.isLeftCalculated = true;
          } else {
            this.rightTaskLabel.isCompleted = true;
          }
        } else {
          this.rightTaskLabel.left = detail.endPoint;
        }
      }
    } else {
      if (!isNullOrUndefined(this.labelSettings.rightLabel.image)) {
        this.previousWidthofRightImage = 0;
        this.previousWidthofRightValue = 0;
        for (var i = 0; i < this.labelSettings.rightLabel.image.length; i++) {
          this.drawRigthlabelImage(page, startPoint, detail, cumulativeWidth, this.labelSettings.rightLabel.image[i]);
          if (this.labelSettings.rightLabel.value) {
            var value = this.labelSettings.rightLabel.value.split(',');
            if (value) {
              this.rightTaskLabel.isCompleted = false;
              this.drawRightLabelValue(page, startPoint, detail, cumulativeWidth, value[i]);
            }
          }
        }
      } else if (this.labelSettings.rightLabel.value) {
        this.drawRightLabelValue(page, startPoint, detail, cumulativeWidth, this.labelSettings.rightLabel.value);
      }
    }
  };
  PdfGanttTaskbarCollection.prototype.drawRigthlabelImage = function (page, startPoint, detail, cumulativeWidth, rightImage) {
    var left;
    var graphics = page.graphics;
    var labelBounds;
    if (!isNullOrUndefined(this.labelSettings.rightLabel) && !isNullOrUndefined(this.labelSettings.rightLabel.image)) {
      if (this.labelSettings.isLeftCalculated) {
        left = this.rightTaskLabel.left;
      } else {
        if (this.isAutoFit()) {
          if (this.labelSettings.rightLabel.image.length > 1) {
            left = this.rightTaskLabel.left + +this.previousWidthofRightValue;
            this.previousWidthofRightImage = rightImage.width;
          } else {
            left = this.rightTaskLabel.left;
          }
        } else {
          var value = void 0;
          if (!isNullOrUndefined(this.labelSettings.rightLabel.value)) {
            value = this.labelSettings.rightLabel.value.split(',');
          }
          if (this.labelSettings.rightLabel.image.length > 1 && value.length > 1) {
            left = pixelToPoint(this.rightTaskLabel.left) + this.previousWidthofRightValue;
            this.previousWidthofRightImage = rightImage.width;
          } else if (this.labelSettings.rightLabel.image.length > 1) {
            left = pixelToPoint(this.rightTaskLabel.left) + this.previousWidthofRightImage;
            this.previousWidthofRightImage = rightImage.width;
          } else {
            left = pixelToPoint(this.rightTaskLabel.left);
          }
        }
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - cumulativeWidth + startPoint.x;
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(this.labelSettings.rightLabel) && !this.rightTaskLabel.isCompleted) {
        var result = new SizeF(rightImage.width, rightImage.height);
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.height) / 2;
        var point = new PointF(actualLeft, startPoint.y + adjustHeight);
        var size = new SizeF(result.width, result.height);
        labelBounds = new RectangleF(point, size);
        var image = new PdfBitmap(rightImage.base64);
        if (result.width > 0) {
          graphics.drawImage(image, labelBounds.x, labelBounds.y, result.width, result.height);
          if (this.labelSettings.rightLabel.value !== null) {
            this.rightTaskLabel.isLeftCalculated = true;
          } else {
            if (isNullOrUndefined(this.labelSettings.rightLabel.value)) {
              this.rightTaskLabel.isCompleted = true;
            }
          }
        } else {
          this.rightTaskLabel.left = detail.endPoint;
        }
      }
    }
  };
  PdfGanttTaskbarCollection.prototype.drawRightLabelValue = function (page, startPoint, detail, cumulativeWidth, rightString) {
    var left;
    var graphics = page.graphics;
    if (!isNullOrUndefined(this.labelSettings.rightLabel.value)) {
      if (this.labelSettings.isLeftCalculated) {
        left = this.rightTaskLabel.left;
      } else {
        if (this.isAutoFit()) {
          if (this.labelSettings.rightLabel.image) {
            if (this.labelSettings.rightLabel.image.length > 1) {
              left = this.rightTaskLabel.left + this.previousWidthofRightImage + this.previousWidthofRightValue;
              if (!isNullOrUndefined(rightString)) {
                var result = this.getWidthofrightLabel(rightString, detail.endPoint - left, 15);
                this.previousWidthofRightValue += result.actualSize.width + this.previousWidthofRightImage;
              }
            } else {
              left = this.rightTaskLabel.left + this.labelSettings.rightLabel.image[0].width;
            }
          } else {
            left = this.rightTaskLabel.left;
          }
        } else {
          if (this.labelSettings.rightLabel.image) {
            if (this.labelSettings.rightLabel.image.length > 1) {
              left = pixelToPoint(this.rightTaskLabel.left) + this.previousWidthofRightImage + this.previousWidthofRightValue;
              if (!isNullOrUndefined(rightString)) {
                var result = this.getWidthofrightLabel(rightString, detail.endPoint - left, 15);
                this.previousWidthofRightValue += result.actualSize.width + this.previousWidthofRightImage;
              }
            } else {
              left = pixelToPoint(this.rightTaskLabel.left) + this.labelSettings.rightLabel.image[0].width;
            }
          } else {
            left = pixelToPoint(this.rightTaskLabel.left);
          }
        }
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - cumulativeWidth + startPoint.x;
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(rightString) && !this.rightTaskLabel.isCompleted) {
        var result = this.getWidthofrightLabel(rightString, detail.endPoint - left, 15);
        var font = new PdfStandardFont(this.fontFamily, 9);
        if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
          font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
        }
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.actualSize.height) / 2;
        var point = new PointF(actualLeft, startPoint.y + adjustHeight);
        var size = new SizeF(result.actualSize.width, result.actualSize.height);
        var labelBound = new RectangleF(point, size);
        var labelFormat = new PdfStringFormat();
        labelFormat.alignment = PdfTextAlignment.Right;
        labelFormat.lineAlignment = PdfVerticalAlignment.Middle;
        if (result.actualSize.width > 0) {
          var fontColor = null;
          var fontBrush = new PdfSolidBrush(this.labelColor);
          var newFont = this.labelSettings.rightLabel.fontStyle.fontSize ? new PdfStandardFont(this.labelSettings.rightLabel.fontStyle.fontFamily, this.labelSettings.rightLabel.fontStyle.fontSize, this.labelSettings.rightLabel.fontStyle.fontStyle) : font;
          var newFontBrush = this.labelSettings.rightLabel.fontStyle.fontColor ? new PdfSolidBrush(this.labelSettings.rightLabel.fontStyle.fontColor) : fontBrush;
          /* eslint-disable-next-line */
          graphics.drawString(rightString, newFont, fontColor, newFontBrush, labelBound.x, labelBound.y, result.actualSize.width, result.actualSize.height, labelFormat);
          if (!isNullOrUndefined(result.remainder) && result.remainder !== null) {
            this.rightTaskLabel.value = result.remainder;
            this.rightTaskLabel.left = detail.endPoint;
            this.rightTaskLabel.isLeftCalculated = true;
          } else {
            if (isNullOrUndefined(this.labelSettings.rightLabel.value)) {
              this.rightTaskLabel.isCompleted = true;
            }
          }
        } else {
          this.rightTaskLabel.left = detail.endPoint;
        }
      }
    }
  };
  /**
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {TimelineDetails} detail .
   * @param {number} cumulativeWidth .
   * @param {PdfGanttTaskbarCollection} taskbar .
   * @returns {void}
   * Draw task left task label
   */
  PdfGanttTaskbarCollection.prototype.drawLeftLabel = function (page, startPoint, detail, cumulativeWidth, taskbar) {
    var graphics = page.graphics;
    var left;
    if (!isNullOrUndefined(this.leftTaskLabel.value)) {
      var labelLeft = 0;
      labelLeft = this.left;
      if (!this.leftTaskLabel.isLeftCalculated) {
        var result = this.getWidth(this.leftTaskLabel.value, Number.MAX_VALUE, 15);
        var reduceLeft = this.isMilestone ? Math.floor(this.parent.chartRowsModule.taskBarHeight / 2) + 33 : 33; // 33 indicates default timeline cell width
        if (this.isAutoFit()) {
          left = labelLeft - reduceLeft - result.actualSize.width;
        } else {
          left = pixelToPoint(labelLeft - reduceLeft) - result.actualSize.width;
        }
        this.leftTaskLabel.left = left;
        this.leftTaskLabel.isLeftCalculated = true;
      } else {
        left = this.leftTaskLabel.left;
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
        if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
          var leftValue = taskbar.left - taskbar.autoLeft;
          if (taskbar.left < taskbar.autoLeft) {
            actualLeft = left - cumulativeWidth + startPoint.x;
          } else {
            actualLeft = left - cumulativeWidth + startPoint.x - leftValue;
          }
        }
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
        if (!taskbar.isAutoSchedule && taskbar.isParentTask) {
          var leftValue = pixelToPoint(taskbar.left) - pixelToPoint(taskbar.autoLeft);
          if (taskbar.left < taskbar.autoLeft) {
            actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
          } else {
            actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x - leftValue;
          }
        }
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(this.leftTaskLabel.value) && !this.leftTaskLabel.isCompleted) {
        var result = this.getWidth(this.leftTaskLabel.value, detail.endPoint - left, 15);
        var font = new PdfStandardFont(this.fontFamily, 9);
        var customizedFont = void 0;
        var ganttStyle = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle;
        if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && (!isNullOrUndefined(ganttStyle.label.fontSize) || !isNullOrUndefined(ganttStyle.label.fontStyle) || !isNullOrUndefined(ganttStyle.label.fontFamily))) {
          customizedFont = this.getPdfFont(ganttStyle);
        }
        if (!isNullOrUndefined(customizedFont)) {
          font = customizedFont;
        }
        if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
          font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
        }
        var padding = {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        };
        if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && !isNullOrUndefined(ganttStyle.label.padding)) {
          padding = ganttStyle.label.padding;
        }
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.actualSize.height) / 2;
        var rightLabelpoint = new PointF(actualLeft + padding.left, startPoint.y + adjustHeight + padding.top);
        var rightLabelSize = new SizeF(result.actualSize.width - (padding.left + padding.right), result.actualSize.height - (padding.top + padding.bottom));
        var rightLabelBounds = new RectangleF(rightLabelpoint, rightLabelSize);
        var rightLabelFormat = new PdfStringFormat();
        rightLabelFormat.alignment = PdfTextAlignment.Right;
        rightLabelFormat.lineAlignment = PdfVerticalAlignment.Middle;
        if (result.actualSize.width > 0) {
          var fontColor = null;
          /* eslint-disable-next-line */
          var fontBrush = new PdfSolidBrush(ganttStyle && ganttStyle.label && ganttStyle.label.fontBrush ? ganttStyle.label.fontBrush : this.labelColor);
          /* eslint-disable-next-line */
          var labelBrush = null;
          if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && !isNullOrUndefined(ganttStyle.label.backgroundColor)) {
            labelBrush = new PdfSolidBrush(ganttStyle.label.backgroundColor);
          }
          var lablePen = null;
          if (!isNullOrUndefined(ganttStyle) && !isNullOrUndefined(ganttStyle.label) && !isNullOrUndefined(ganttStyle.label.borderColor)) {
            lablePen = new PdfPen(ganttStyle.label.borderColor);
          }
          var strSize = font.measureString(result.lines[0].text);
          graphics.drawRectangle(lablePen, labelBrush, rightLabelBounds.x - 3, rightLabelBounds.y, strSize.width + 6, strSize.height);
          graphics.drawString(result.lines[0].text, font, fontColor, fontBrush, rightLabelBounds.x, rightLabelBounds.y, result.actualSize.width, result.actualSize.height, rightLabelFormat);
          if (result.remainder !== null) {
            this.leftTaskLabel.value = result.remainder;
            this.leftTaskLabel.left = detail.endPoint;
          } else {
            this.leftTaskLabel.isCompleted = true;
          }
        } else {
          this.leftTaskLabel.left = detail.endPoint;
        }
      }
    } else {
      if (!isNullOrUndefined(this.labelSettings.leftLabel) && !isNullOrUndefined(this.labelSettings.leftLabel.image)) {
        this.previousWidthofLeftImage = 0;
        this.previousWidthofLeftValue = 0;
        this.totalLeftWidth = 0;
        for (var i = 0; i < this.labelSettings.leftLabel.image.length; i++) {
          var value = void 0;
          var result = void 0;
          if (!isNullOrUndefined(this.labelSettings.leftLabel.value)) {
            value = this.labelSettings.leftLabel.value.split(',');
            result = this.getWidth(value[i], Number.MAX_VALUE, 15);
          }
          var totalstringWidth = !isNullOrUndefined(result) ? result.actualSize.width : 0;
          this.totalLeftWidth += this.labelSettings.leftLabel.image[i].width + totalstringWidth;
        }
        for (var i = 0; i < this.labelSettings.leftLabel.image.length; i++) {
          this.drawLeftLabelImage(page, startPoint, detail, cumulativeWidth, this.labelSettings.leftLabel.image[i]);
          if (this.labelSettings.leftLabel.value) {
            var value = this.labelSettings.leftLabel.value.split(',');
            if (value) {
              this.drawLeftLabelValue(page, startPoint, detail, cumulativeWidth, value[i]);
            }
          }
        }
      } else if (!isNullOrUndefined(this.labelSettings.leftLabel) && !isNullOrUndefined(this.labelSettings.leftLabel.value)) {
        this.drawLeftLabelValue(page, startPoint, detail, cumulativeWidth, this.labelSettings.leftLabel.value);
      }
    }
  };
  PdfGanttTaskbarCollection.prototype.drawLeftLabelImage = function (page, startPoint, detail, cumulativeWidth, leftLabelImage) {
    var graphics = page.graphics;
    var left;
    var labelLeft = 0;
    labelLeft = this.left;
    if (!isNullOrUndefined(this.labelSettings.leftLabel) && !isNullOrUndefined(this.labelSettings.leftLabel.image)) {
      if (!this.leftTaskLabel.isLeftCalculated) {
        var result = new SizeF(leftLabelImage.width, leftLabelImage.height);
        var reduceLeft = this.isMilestone ? Math.floor(this.parent.chartRowsModule.taskBarHeight / 2) + 33 : 33; // 33 indicates default timeline cell width
        var value = this.labelSettings.leftLabel.value.split(',');
        if (this.isAutoFit()) {
          if (this.labelSettings.leftLabel.image.length > 0 && value.length > 0) {
            left = labelLeft - this.totalLeftWidth - result.width;
            this.previousWidthofLeftImage += result.width;
          } else {
            left = labelLeft - reduceLeft - result.width;
          }
        } else {
          if (this.labelSettings.leftLabel.image.length > 0 && value.length > 0) {
            left = pixelToPoint(labelLeft) - this.totalLeftWidth - result.width;
            this.previousWidthofLeftImage += result.width;
          } else {
            left = pixelToPoint(labelLeft - reduceLeft) - result.width;
          }
        }
        this.leftTaskLabel.left = left;
        this.leftTaskLabel.isLeftCalculated = true;
      } else {
        if (this.labelSettings.leftLabel.image.length > 1) {
          left = this.leftTaskLabel.left + this.previousWidthofLeftValue;
        } else {
          left = this.leftTaskLabel.left;
        }
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(leftLabelImage) && !this.leftTaskLabel.isCompleted) {
        var result = new SizeF(leftLabelImage.width, leftLabelImage.height);
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.height) / 2;
        var rightLabelpoint = new PointF(actualLeft, startPoint.y + adjustHeight);
        var rightLabelSize = new SizeF(result.width, result.height);
        var rightLabelBounds = new RectangleF(rightLabelpoint, rightLabelSize);
        var image = new PdfBitmap(leftLabelImage.base64);
        if (result.width > 0) {
          graphics.drawImage(image, rightLabelBounds.x, rightLabelBounds.y, result.width, result.height);
          this.totalLeftWidth = this.totalLeftWidth - result.width;
        } else {
          this.leftTaskLabel.left = detail.endPoint;
        }
      }
    }
  };
  PdfGanttTaskbarCollection.prototype.drawLeftLabelValue = function (page, startPoint, detail, cumulativeWidth, leftLabelValue) {
    var graphics = page.graphics;
    var left;
    if (!isNullOrUndefined(leftLabelValue)) {
      var labelLeft = 0;
      labelLeft = this.left;
      if (!this.leftTaskLabel.isLeftCalculated) {
        var result = this.getWidthofLeftLabel(leftLabelValue, Number.MAX_VALUE, 15);
        var reduceLeft = this.isMilestone ? Math.floor(this.parent.chartRowsModule.taskBarHeight / 2) + 33 : 33; // 33 indicates default timeline cell width
        var value = this.labelSettings.leftLabel.value.split(',');
        if (this.isAutoFit()) {
          var isLeftImageExist = !isNullOrUndefined(this.labelSettings.leftLabel.image) ? true : false;
          var imageLength = isLeftImageExist ? this.labelSettings.leftLabel.image.length : 0;
          if (value.length === 1 && isLeftImageExist && imageLength === 1) {
            left = this.leftTaskLabel.left + this.previousWidthofLeftImage;
            this.labelSettings.left = left;
            var result_5 = this.getWidthofLeftLabel(leftLabelValue, Number.MAX_VALUE, 15);
            this.previousWidthofLeftValue += this.previousWidthofLeftImage + result_5.actualSize.width;
          } else if (value.length > 1 && isLeftImageExist && imageLength > 1) {
            var totalWidth = this.previousWidthofLeftValue !== 0 ? this.previousWidthofLeftValue + this.previousWidthofLeftImage : this.previousWidthofLeftImage;
            left = this.leftTaskLabel.left + totalWidth;
            this.labelSettings.left = left;
            var result_6 = this.getWidthofLeftLabel(leftLabelValue, Number.MAX_VALUE, 15);
            this.previousWidthofLeftValue += this.previousWidthofLeftImage + result_6.actualSize.width;
          } else {
            left = labelLeft - reduceLeft - result.actualSize.width;
          }
        } else {
          left = pixelToPoint(labelLeft - reduceLeft) - result.actualSize.width;
        }
        this.leftTaskLabel.left = left;
        this.leftTaskLabel.isLeftCalculated = true;
      } else {
        var value = this.labelSettings.leftLabel.value.split(',');
        if (value.length === 1) {
          left = this.leftTaskLabel.left + this.previousWidthofLeftImage;
          this.labelSettings.left = left;
          var result = this.getWidthofLeftLabel(leftLabelValue, Number.MAX_VALUE, 15);
          this.previousWidthofLeftValue += this.previousWidthofLeftImage + result.actualSize.width;
        } else if (value.length > 1) {
          var totalWidth = this.previousWidthofLeftValue !== 0 ? this.previousWidthofLeftValue + this.previousWidthofLeftImage : this.previousWidthofLeftImage;
          left = this.leftTaskLabel.left + totalWidth;
          this.labelSettings.left = left;
          var result = this.getWidthofLeftLabel(leftLabelValue, Number.MAX_VALUE, 15);
          this.previousWidthofLeftValue += this.previousWidthofLeftImage + result.actualSize.width;
        } else {
          left = this.leftTaskLabel.left;
        }
      }
      var actualLeft = void 0;
      if (this.isAutoFit()) {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      } else {
        actualLeft = left - pixelToPoint(cumulativeWidth) + startPoint.x;
      }
      if (detail.startPoint <= left && left < detail.endPoint && !isNullOrUndefined(leftLabelValue) && !this.leftTaskLabel.isCompleted) {
        var result = this.getWidthofLeftLabel(leftLabelValue, detail.endPoint - left, 15);
        var font = new PdfStandardFont(this.fontFamily, 9);
        if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
          font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
        }
        var adjustHeight = (pixelToPoint(this.parent.rowHeight) - result.actualSize.height) / 2;
        var rightLabelpoint = new PointF(actualLeft, startPoint.y + adjustHeight);
        var rightLabelSize = new SizeF(result.actualSize.width, result.actualSize.height);
        var rightLabelBounds = new RectangleF(rightLabelpoint, rightLabelSize);
        var rightLabelFormat = new PdfStringFormat();
        rightLabelFormat.alignment = PdfTextAlignment.Right;
        rightLabelFormat.lineAlignment = PdfVerticalAlignment.Middle;
        if (result.actualSize.width > 0) {
          var fontColor = null;
          var fontBrush = new PdfSolidBrush(this.labelColor);
          var newFont = this.labelSettings.leftLabel.fontStyle.fontSize ? new PdfStandardFont(this.labelSettings.leftLabel.fontStyle.fontFamily, this.labelSettings.leftLabel.fontStyle.fontSize, this.labelSettings.leftLabel.fontStyle.fontStyle) : font;
          var newFontBrush = this.labelSettings.leftLabel.fontStyle.fontColor ? new PdfSolidBrush(this.labelSettings.leftLabel.fontStyle.fontColor) : fontBrush;
          graphics.drawString(leftLabelValue, newFont, fontColor, newFontBrush, rightLabelBounds.x, rightLabelBounds.y, result.actualSize.width, result.actualSize.height, rightLabelFormat);
          var value = this.labelSettings.leftLabel.value.split(',');
          if (!isNullOrUndefined(result.remainder) && result.remainder !== null) {
            this.leftTaskLabel.value = result.remainder;
            this.leftTaskLabel.left = detail.endPoint;
          } else if (!isNullOrUndefined(this.labelSettings.leftLabel.image) && this.labelSettings.leftLabel.image.length === 1 && value.length === 1) {
            this.leftTaskLabel.isCompleted = true;
          }
        } else {
          this.leftTaskLabel.left = detail.endPoint;
        }
      }
    }
  };
  PdfGanttTaskbarCollection.prototype.getWidth = function (value, width, height) {
    var font;
    font = new PdfStandardFont(this.fontFamily, 9);
    var ganttStyle = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle;
    if (ganttStyle && ganttStyle.label && ganttStyle.label.fontSize) {
      font = new PdfStandardFont(this.fontFamily, ganttStyle.label.fontSize);
      height = font.height;
    }
    if (!isNullOrUndefined(ganttStyle) && ganttStyle.font) {
      font = ganttStyle.font;
      height = font.height;
    }
    var layouter = new PdfStringLayouter();
    var progressFormat = new PdfStringFormat();
    progressFormat.alignment = PdfTextAlignment.Left;
    progressFormat.wordWrap = PdfWordWrapType.Character;
    progressFormat.lineAlignment = PdfVerticalAlignment.Middle;
    /* eslint-disable-next-line */
    var result = layouter.layout(value, font, progressFormat, new SizeF(width, height), false, new SizeF(width, height));
    return result;
  };
  PdfGanttTaskbarCollection.prototype.getWidthofLeftLabel = function (value, width, height) {
    var newFont = new PdfStandardFont(this.labelSettings.leftLabel.fontStyle.fontFamily, this.labelSettings.leftLabel.fontStyle.fontSize);
    var font;
    if (this.labelSettings.leftLabel.fontStyle.fontSize) {
      font = newFont;
    } else {
      font = new PdfStandardFont(this.fontFamily, 9);
    }
    if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
      font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
    }
    var layouter = new PdfStringLayouter();
    var progressFormat = new PdfStringFormat();
    progressFormat.alignment = PdfTextAlignment.Left;
    progressFormat.wordWrap = PdfWordWrapType.Character;
    progressFormat.lineAlignment = PdfVerticalAlignment.Middle;
    /* eslint-disable-next-line */
    var result = layouter.layout(value, font, progressFormat, new SizeF(width, height), false, new SizeF(width, height));
    return result;
  };
  PdfGanttTaskbarCollection.prototype.getWidthofrightLabel = function (value, width, height) {
    var newFont = new PdfStandardFont(this.labelSettings.rightLabel.fontStyle.fontFamily, this.labelSettings.rightLabel.fontStyle.fontSize);
    var font;
    if (this.labelSettings.rightLabel.fontStyle.fontSize) {
      font = newFont;
    } else {
      font = new PdfStandardFont(this.fontFamily, 9);
    }
    if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
      font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
    }
    var layouter = new PdfStringLayouter();
    var progressFormat = new PdfStringFormat();
    progressFormat.alignment = PdfTextAlignment.Left;
    progressFormat.wordWrap = PdfWordWrapType.Character;
    progressFormat.lineAlignment = PdfVerticalAlignment.Middle;
    /* eslint-disable-next-line */
    var result = layouter.layout(value, font, progressFormat, new SizeF(width, height), false, new SizeF(width, height));
    return result;
  };
  /**
   * @param {PdfGraphics} taskGraphics .
   * @param {PointF} startPoint .
   * @param {number} cumulativeWidth .
   * @param {number} adjustHeight .
   * @returns {void}
   * Draw Unscheduled Task
   */
  PdfGanttTaskbarCollection.prototype.drawUnscheduledTask = function (taskGraphics, startPoint, cumulativeWidth, adjustHeight) {
    var taskBrush = new PdfSolidBrush(this.taskColor);
    if (this.isAutoFit()) {
      taskGraphics.drawRectangle(taskBrush, startPoint.x + (this.left - cumulativeWidth), startPoint.y + adjustHeight, pixelToPoint(3), pixelToPoint(this.height));
    } else {
      taskGraphics.drawRectangle(taskBrush, startPoint.x + pixelToPoint(this.left - cumulativeWidth), startPoint.y + adjustHeight, pixelToPoint(3), pixelToPoint(this.height));
    }
  };
  /**
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {TimelineDetails} detail .
   * @param {number} cumulativeWidth .
   * @param {PdfGanttTaskbarCollection} taskbar .
   * @param {boolean} isBaseline .
   * @returns {void}
  Draw milestone task
   */
  PdfGanttTaskbarCollection.prototype.drawMilestone = function (page, startPoint, detail, cumulativeWidth, taskbar, isBaseline) {
    if (detail.startDate <= this.startDate && this.startDate <= detail.endDate) {
      var taskGraphics = page.graphics;
      var pageIndex = page.section.indexOf(page);
      this.taskStartPoint = __assign$8({}, startPoint);
      var milestonePen = new PdfPen(this.milestoneColor);
      var adjustHeightforBaselineMilesone = pixelToPoint((this.parent.rowHeight - this.height) / 3.0);
      var adjustHeightforMilesone = pixelToPoint((this.parent.rowHeight - this.height) / 2.0);
      var adjustHeight = this.parent.renderBaseline ? adjustHeightforBaselineMilesone : adjustHeightforMilesone;
      var milestoneBrush = new PdfSolidBrush(this.milestoneColor);
      var baselinePen = new PdfPen(this.baselineBorderColor);
      var baselineBrush = new PdfSolidBrush(this.baselineColor);
      taskGraphics.save(); //saving graphics state
      var height = Math.floor(this.parent.chartRowsModule.taskBarHeight * 0.8);
      if (!isBaseline) {
        if (this.isAutoFit()) {
          taskGraphics.translateTransform(startPoint.x + (this.left - cumulativeWidth), startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2);
        } else {
          taskGraphics.translateTransform(startPoint.x + pixelToPoint(this.left - cumulativeWidth), startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2);
        }
      }
      if (isBaseline) {
        if (this.isAutoFit()) {
          taskGraphics.translateTransform(startPoint.x + (taskbar.baselineLeft - cumulativeWidth), startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2);
        } else {
          taskGraphics.translateTransform(startPoint.x + pixelToPoint(taskbar.baselineLeft - cumulativeWidth), startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2);
        }
      }
      taskGraphics.rotateTransform(45); //apply rotation
      if (this.parent.renderBaseline && this.baselineStartDate && this.baselineEndDate && isBaseline) {
        taskGraphics.drawRectangle(baselinePen, baselineBrush, 2, 2, pixelToPoint(height), pixelToPoint(height));
      }
      if (!isBaseline) {
        taskGraphics.drawRectangle(milestonePen, milestoneBrush, 0, 0, pixelToPoint(height), pixelToPoint(height));
      }
      taskGraphics.restore(); //restoring graphics state
      if (this.isAutoFit()) {
        if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
          var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
          var x = startPoint.x + (this.left - cumulativeWidth) - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2 + (this.parent.chartRowsModule.taskBarHeight * 0.7 - taskbar.taskbarTemplate.image[0].height) / 1.0;
          var width = taskbar.taskbarTemplate.image[0].width / 2.0;
          var height_1 = taskbar.taskbarTemplate.image[0].height / 2.0;
          taskGraphics.drawImage(image, x, startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2 + (this.parent.chartRowsModule.taskBarHeight * 0.7 - taskbar.taskbarTemplate.image[0].height) / 0.5, width, height_1);
        }
      } else {
        if (!isNullOrUndefined(taskbar.taskbarTemplate.image)) {
          var image = new PdfBitmap(taskbar.taskbarTemplate.image[0].base64);
          var x = startPoint.x + pixelToPoint(this.left - cumulativeWidth) - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2 + pixelToPoint(this.parent.chartRowsModule.taskBarHeight * 0.7 - taskbar.taskbarTemplate.image[0].height) / 1.0;
          taskGraphics.drawImage(image, x, startPoint.y + adjustHeight - this.parent.chartRowsModule.taskBarHeight * 0.7 / 2 + pixelToPoint((this.parent.chartRowsModule.taskBarHeight * 0.7 - taskbar.taskbarTemplate.image[0].height) / 0.5), pixelToPoint(taskbar.taskbarTemplate.image[0].width), pixelToPoint(taskbar.taskbarTemplate.image[0].height - 2));
        }
      }
      this.endPage = this.startPage = pageIndex;
    }
  };
  return PdfGanttTaskbarCollection;
}();

/**
 */
var PdfTimeline = /** @__PURE__ @class */function () {
  function PdfTimeline(gantt) {
    this.holidayCompleted = false;
    this.fitHolidayCompleted = false;
    this.timelineWidth = 0;
    this.lastWidth = 0;
    this.width = 0;
    this.gantt = gantt;
    this.parent = gantt.parent;
    this.topTierPoint = new PointF();
    this.bottomTierPoint = new PointF();
    this.topTierIndex = 0;
    this.bottomTierIndex = 0;
    this.prevTopTierIndex = 0;
    this.prevBottomTierIndex = 0;
  }
  /**
   * @private
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {TimelineDetails} detail .
   * @returns {void}
   */
  PdfTimeline.prototype.drawTimeline = function (page, startPoint, detail) {
    this.detailsTimeline = detail;
    var remainWidth = this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? pointToPixel(Math.floor(detail.totalWidth)) : Math.round(detail.totalWidth);
    var renderWidth = 0;
    this.topTierPoint.x = startPoint.x;
    this.topTierPoint.y = startPoint.y;
    this.prevTopTierIndex = this.topTierIndex;
    this.prevBottomTierIndex = this.bottomTierIndex;
    while (remainWidth > 0) {
      var pHeader = this.topTier[this.topTierIndex];
      if (this.topTier.length > this.topTierIndex) {
        var isCompleted = false;
        if (!this.topTier[this.topTierIndex].isFinished) {
          if (remainWidth >= pHeader.width) {
            renderWidth = pHeader.width;
            pHeader.isFinished = true;
            pHeader.completedWidth = renderWidth;
            isCompleted = true;
          } else {
            renderWidth = remainWidth;
            isCompleted = false;
            pHeader.isFinished = false;
            pHeader.width = pHeader.width - remainWidth;
            pHeader.completedWidth = renderWidth;
          }
        }
        //Primary header Event Arguments
        var reWidth = this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? renderWidth : pixelToPoint(renderWidth);
        /* eslint-disable-next-line */
        this.triggerQueryTimelinecell(page, this.topTierPoint.x, this.topTierPoint.y, this.topTierHeight, reWidth, pHeader.value, true, this.parent.timelineModule.isSingleTier && this.parent.timelineSettings.topTier.unit === 'Day' ? pHeader.startDate : null);
        this.topTierPoint.x += reWidth;
        remainWidth -= renderWidth;
        if (isCompleted) {
          this.topTierIndex++;
        }
      } else {
        remainWidth = 0;
      }
    }
    remainWidth = Math.round(detail.totalWidth);
    var height = this.parent.timelineModule.isSingleTier ? 0 : this.topTierHeight;
    this.bottomTierPoint = new PointF(startPoint.x, pixelToPoint(startPoint.y + height));
    while (remainWidth > 0) {
      var secondHeader = this.bottomTier[this.bottomTierIndex];
      if (this.bottomTier.length > this.bottomTierIndex) {
        var isCompleted = true;
        var width = secondHeader.width;
        if (remainWidth < width) {
          width = remainWidth;
          /* eslint-disable-next-line */
          isCompleted = false;
          secondHeader.completedWidth = width;
        }
        //Secondary header Event Arguments
        this.triggerQueryTimelinecell(page, this.bottomTierPoint.x, this.bottomTierPoint.y, this.bottomTierHeight, this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? width : pixelToPoint(width), secondHeader.value, false, secondHeader.startDate);
        this.bottomTierPoint.x = this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? this.bottomTierPoint.x + width : this.bottomTierPoint.x + pixelToPoint(width);
        remainWidth -= width;
        secondHeader.completedWidth = width;
        // if (isCompleted) {
        this.bottomTierIndex++;
        // }
        if (remainWidth > 0 && remainWidth < width) {
          remainWidth = secondHeader.width - 1;
        }
      } else {
        remainWidth = 0;
      }
    }
    this.timelineWidth = this.lastWidth;
  };
  /**
   *
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {TimelineDetails}  detail .
   * @returns {void} .
   * Draw the specific gantt chart side header when the taskbar exceeds the page
   * @private
   */
  /* eslint-disable-next-line */
  PdfTimeline.prototype.drawPageTimeline = function (page, startPoint, detail) {
    this.topTierPoint = extend({}, {}, startPoint, true);
    for (var index = this.prevTopTierIndex; index <= this.topTierIndex; index++) {
      if (this.topTier.length > index) {
        var pHeader = this.topTier[index];
        if (pHeader.completedWidth > 0) {
          //Primary header Event Arguments
          /* eslint-disable-next-line */
          this.triggerQueryTimelinecell(page, this.topTierPoint.x, this.topTierPoint.y, this.topTierHeight, this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? pHeader.completedWidth : pixelToPoint(pHeader.completedWidth), pHeader.value, true, this.parent.timelineModule.isSingleTier && this.parent.timelineSettings.topTier.unit === 'Day' ? pHeader.startDate : null);
          this.topTierPoint.x += this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? pHeader.completedWidth : pixelToPoint(pHeader.completedWidth);
        }
      }
    }
    this.bottomTierPoint.x = startPoint.x;
    this.bottomTierPoint.y = pixelToPoint(startPoint.y + this.topTierHeight);
    for (var index = this.prevBottomTierIndex; index <= this.bottomTierIndex; index++) {
      if (this.bottomTier.length > index) {
        var secondHeader = this.bottomTier[index];
        if (secondHeader.completedWidth > 0) {
          //Secondary header Event Arguments
          /* eslint-disable-next-line */
          this.triggerQueryTimelinecell(page, this.bottomTierPoint.x, this.bottomTierPoint.y, this.bottomTierHeight, this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? secondHeader.width : pixelToPoint(secondHeader.width), secondHeader.value, false, secondHeader.startDate);
          this.bottomTierPoint.x = this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? this.bottomTierPoint.x + secondHeader.width : this.bottomTierPoint.x + pixelToPoint(secondHeader.width);
        }
      }
    }
  };
  /**
   * Method to trigger pdf query timelinecell event
   */
  /* eslint-disable-next-line */
  PdfTimeline.prototype.triggerQueryTimelinecell = function (page, x, y, height, width, value, isTopTier, currentDate) {
    var _this = this;
    var days = new Date(currentDate).getDay();
    var graphics = page.graphics;
    var timelineStyle = {};
    var ganttStyle = this.gantt.ganttStyle;
    timelineStyle.borderColor = new PdfColor(ganttStyle.timeline.borderColor);
    timelineStyle.fontColor = new PdfColor(ganttStyle.timeline.fontColor);
    timelineStyle.fontSize = ganttStyle.timeline.fontSize;
    timelineStyle.fontStyle = ganttStyle.timeline.fontStyle;
    timelineStyle.backgroundColor = new PdfColor(ganttStyle.timeline.backgroundColor);
    if (ganttStyle.timeline.padding) {
      timelineStyle.padding = ganttStyle.timeline.padding;
    }
    var format = new PdfStringFormat();
    if (isNullOrUndefined(ganttStyle.timeline.format)) {
      if (isTopTier) {
        format.lineAlignment = PdfVerticalAlignment.Middle;
        format.alignment = PdfTextAlignment.Left;
      } else {
        format.lineAlignment = PdfVerticalAlignment.Middle;
        format.alignment = PdfTextAlignment.Center;
        format.wordWrap = PdfWordWrapType.Character;
      }
    } else {
      format = ganttStyle.timeline.format;
    }
    timelineStyle.format = format;
    var eventArgs = {
      timelineCell: timelineStyle,
      value: value
    };
    if (this.parent.pdfQueryTimelineCellInfo) {
      this.parent.trigger('pdfQueryTimelineCellInfo', eventArgs);
    }
    var e = eventArgs.timelineCell;
    var rectPen;
    var rectBrush = new PdfSolidBrush(eventArgs.timelineCell.backgroundColor);
    var nonWorkingDays = this.parent.nonWorkingDayIndex;
    if (this.parent.highlightWeekends && nonWorkingDays.indexOf(days) !== -1 && (this.parent.timelineModule.bottomTier === 'Day' || this.parent.timelineModule.bottomTier === 'None' && this.parent.timelineModule.topTier === 'Day')) {
      rectBrush = new PdfSolidBrush(new PdfColor(238, 238, 238));
    }
    this.parent.holidays.map(function (item) {
      var fromDate = new Date(item.from);
      var toDate = new Date(item.to);
      var timelinedate = new Date(currentDate);
      if (fromDate <= timelinedate && toDate >= timelinedate && (_this.parent.timelineModule.bottomTier === 'Day' || _this.parent.timelineModule.bottomTier === 'None' && _this.parent.timelineModule.topTier === 'Day')) {
        rectBrush = new PdfSolidBrush(new PdfColor(238, 238, 238));
        if (fromDate.getTime() === timelinedate.getTime()) {
          _this.holidayWidth = x;
        }
        if (toDate.getTime() === timelinedate.getTime()) {
          _this.holidayLabel = item.label;
          var changeDate = new Date(item.to);
          changeDate.setDate(changeDate.getDate() + 1);
          var day = _this.parent.dataOperation.getTaskWidth(fromDate, changeDate);
          _this.holidayNumberOfDays = day / width;
          _this.holidayCompleted = true;
        }
      } else if (_this.parent.timelineModule.bottomTier !== 'Day') {
        if (_this.detailsTimeline.startDate <= fromDate && _this.detailsTimeline.endDate >= fromDate) {
          _this.parent.timelineModule.bottomTierCollection.map(function (items) {
            if (items.startDate <= fromDate && items.endDate >= fromDate) {
              if (items.startDate === currentDate) {
                _this.fitHolidayCompleted = true;
                _this.fromDataHoliday = item.from;
                _this.holidayLabel = item.label;
              }
            }
          });
        }
      }
    });
    var rectPen1 = new PdfPen(eventArgs.timelineCell.borderColor);
    if (!this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
      this.lastWidth = x + width;
    }
    graphics.drawRectangle(rectPen1, rectBrush, x, y, width, pixelToPoint(height));
    if (!isTopTier && (this.parent.gridLines === 'Both' || this.parent.gridLines === 'Vertical')) {
      rectPen = new PdfPen(ganttStyle.chartGridLineColor);
    } else {
      rectPen = null;
    }
    graphics.drawRectangle(rectPen, rectBrush, x, y + pixelToPoint(height), width, page.getClientSize().height);
    if (this.holidayCompleted) {
      var state_1 = graphics.save();
      var font1 = new PdfStandardFont(ganttStyle.fontFamily, e.fontSize, e.fontStyle);
      if (ganttStyle.font) {
        font1 = ganttStyle.font;
      }
      var fontHieght = font1.height;
      var fontSize = font1.size;
      graphics.translateTransform(this.holidayWidth + width - fontSize / 2 * this.holidayNumberOfDays - fontHieght + fontHieght / 2 + width * this.holidayNumberOfDays / 2, 40);
      graphics.rotateTransform(-90);
      graphics.translateTransform(-(page.getClientSize().height / 2), -40);
      graphics.drawString(this.holidayLabel, font1, null, PdfBrushes.Black, 10, 10, null);
      graphics.restore(state_1);
      this.holidayCompleted = false;
    }
    if (this.fitHolidayCompleted) {
      var holidayBrush = new PdfSolidBrush(new PdfColor(238, 238, 238));
      var font1 = new PdfStandardFont(ganttStyle.fontFamily, e.fontSize, e.fontStyle);
      if (ganttStyle.font) {
        font1 = ganttStyle.font;
      }
      var fontSize = font1.size;
      graphics.drawRectangle(null, holidayBrush, x + width / 2 - fontSize, y + pixelToPoint(height), fontSize, page.getClientSize().height);
      var state_2 = graphics.save();
      graphics.translateTransform(x + width + width / 2 - fontSize, 40);
      graphics.rotateTransform(-90);
      graphics.translateTransform(-(page.getClientSize().height / 2), -40);
      graphics.drawString(this.holidayLabel, font1, null, PdfBrushes.Black, 10, 10, null);
      graphics.restore(state_2);
      this.fitHolidayCompleted = false;
    }
    var font = new PdfStandardFont(ganttStyle.fontFamily, e.fontSize, e.fontStyle);
    if (ganttStyle.font) {
      font = ganttStyle.font;
    }
    var textBrush = new PdfSolidBrush(!isNullOrUndefined(ganttStyle.timeline.fontBrush) ? ganttStyle.timeline.fontBrush : eventArgs.timelineCell.fontColor);
    var pLeft = ganttStyle.timeline.padding ? eventArgs.timelineCell.padding.left : 0;
    var pTop = ganttStyle.timeline.padding ? eventArgs.timelineCell.padding.top : 0;
    /* eslint-disable-next-line */
    var state = graphics.save();
    graphics.setClip(new RectangleF(x, y, width, pixelToPoint(height)));
    if (isTopTier) {
      x = x + pLeft + 4;
    } else {
      x = x + pLeft;
    }
    graphics.drawString(eventArgs.value, font, null, textBrush, x, y + pTop, pixelToPoint(width), pixelToPoint(height), e.format);
    graphics.restore(state);
  };
  return PdfTimeline;
}();
var __assign$9 = undefined && undefined.__assign || function () {
  __assign$9 = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$9.apply(this, arguments);
};
/**
 * @hidden
 */
var PdfGanttPredecessor = /** @__PURE__ @class */function () {
  function PdfGanttPredecessor(parent, pdfGantt) {
    this.parent = parent;
    this.pdfGantt = pdfGantt;
  }
  /**
   * @returns {PdfGanttPredecessor} .
   * @hidden
   */
  PdfGanttPredecessor.prototype.add = function () {
    return new PdfGanttPredecessor(this.parent);
  };
  PdfGanttPredecessor.prototype.findindex = function (num) {
    var dataindex;
    if (!this.parent.enableVirtualization) {
      this.parent.currentViewData.map(function (data, index) {
        if (data.index === num) {
          dataindex = index;
        }
      });
      return dataindex;
    } else {
      this.parent.flatData.map(function (data, index) {
        if (data.index === num) {
          dataindex = index;
        }
      });
      return dataindex;
    }
  };
  /**
   * Calculate the predecesor line point and draw the predecessor
   *
   * @param {PdfGantt} pdfGantt .
   * @returns {void}
   * @private
   */
  PdfGanttPredecessor.prototype.drawPredecessor = function (pdfGantt) {
    this.pdfGantt = pdfGantt;
    var pages = pdfGantt.result.page.section.getPages();
    var parentTask = pdfGantt.taskbarCollection[this.findindex(this.parentIndex)];
    var childTask = pdfGantt.taskbarCollection[this.findindex(this.childIndex)];
    var startPage = new PdfPage();
    var endPage = new PdfPage();
    var predecessorType = '';
    var parentPageData;
    var childPageData;
    var parentY = 0;
    var childY = 0;
    switch (this.type) {
      case 'FS':
        if (childTask.startPage > -1 && parentTask.endPage > -1) {
          startPage = pages[parentTask.endPage];
          endPage = pages[childTask.startPage];
          parentPageData = pdfGantt.pdfPageDetail[parentTask.endPage - pdfGantt.chartPageIndex];
          childPageData = pdfGantt.pdfPageDetail[childTask.startPage - pdfGantt.chartPageIndex];
          if (this.parentIndex < this.childIndex) {
            if (this.parentLeft < this.childLeft && this.childLeft > this.parentLeft + this.parentWidth + 25) {
              predecessorType = 'FSType1';
            } else {
              predecessorType = 'FSType2';
            }
          } else {
            if (this.parentLeft < this.childLeft && this.childLeft > this.parentLeft + this.parentWidth + 25) {
              predecessorType = 'FSType3';
            } else {
              predecessorType = 'FSType4';
            }
          }
        } else {
          return;
        }
        break;
      case 'SF':
        if (childTask.endPage > -1 && parentTask.startPage > -1) {
          startPage = pages[parentTask.startPage];
          endPage = pages[childTask.endPage];
          parentPageData = pdfGantt.pdfPageDetail[parentTask.endPage - pdfGantt.chartPageIndex];
          childPageData = pdfGantt.pdfPageDetail[childTask.startPage - pdfGantt.chartPageIndex];
          if (this.parentIndex < this.childIndex) {
            if (this.parentLeft > this.childLeft + this.childWidth) {
              predecessorType = 'SFType1';
            } else {
              predecessorType = 'SFType2';
            }
          } else {
            if (this.parentLeft > this.childLeft + this.childWidth) {
              predecessorType = 'SFType3';
            } else {
              predecessorType = 'SFType4';
            }
          }
        } else {
          return;
        }
        break;
      case 'FF':
        if (childTask.endPage > -1 && parentTask.endPage > -1) {
          startPage = pages[parentTask.endPage];
          endPage = pages[childTask.endPage];
          parentPageData = pdfGantt.pdfPageDetail[parentTask.endPage - pdfGantt.chartPageIndex];
          childPageData = pdfGantt.pdfPageDetail[childTask.endPage - pdfGantt.chartPageIndex];
          if (this.parentIndex < this.childIndex) {
            if (this.childLeft + this.childWidth >= this.parentLeft + this.parentWidth) {
              predecessorType = 'FFType1';
            } else {
              predecessorType = 'FFType2';
            }
          } else {
            if (this.childLeft + this.childWidth >= this.parentLeft + this.parentWidth) {
              predecessorType = 'FFType3';
            } else {
              predecessorType = 'FFType4';
            }
          }
        } else {
          return;
        }
        break;
      case 'SS':
        if (childTask.startPage > -1 && parentTask.startPage > -1) {
          startPage = pages[parentTask.startPage];
          endPage = pages[childTask.startPage];
          parentPageData = pdfGantt.pdfPageDetail[parentTask.startPage - pdfGantt.chartPageIndex];
          childPageData = pdfGantt.pdfPageDetail[childTask.startPage - pdfGantt.chartPageIndex];
          if (this.parentIndex < this.childIndex) {
            if (this.parentLeft >= this.childLeft) {
              predecessorType = 'SSType1';
            } else {
              predecessorType = 'SSType2';
            }
          } else {
            if (this.parentLeft >= this.childLeft) {
              predecessorType = 'SSType3';
            } else {
              predecessorType = 'SSType4';
            }
          }
        } else {
          return;
        }
        break;
    }
    var midPointManualparent = Math.round((this.parent.rowHeight - 15) / 2.0);
    var midPointforTaskbar = Math.round((this.parent.rowHeight - 1) / 2.0);
    var midPointforBaseline = Math.round((this.parent.rowHeight - 10) / 2.0);
    var midPoint = this.parent.renderBaseline ? midPointforBaseline : !parentTask.isAutoSchedule && parentTask.isParentTask || !childTask.isAutoSchedule && childTask.isParentTask ? midPointManualparent : midPointforTaskbar;
    midPoint = pixelToPoint(midPoint);
    /* eslint-disable-next-line */
    var point1, point2, point3, point4, point5, point6;
    point1 = point2 = point3 = point4 = point5 = point6 = new PointF();
    var parentTaskpoint = __assign$9({}, parentTask.taskStartPoint);
    var childTaskpoint = __assign$9({}, childTask.taskStartPoint);
    parentY = parentTaskpoint.y + parentPageData.startPoint.y;
    childY = childTaskpoint.y + childPageData.startPoint.y;
    var ffpoint1;
    var sspoint1;
    var ffpoint3;
    var ffpoint4;
    var sspoint4;
    if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
      ffpoint1 = new PointF(this.parentLeft + this.parentWidth, parentY + midPoint);
      sspoint1 = new PointF(this.parentLeft - 1, parentY + midPoint);
      ffpoint3 = new PointF(this.childLeft - 20, childY + midPoint);
      ffpoint4 = new PointF(this.childLeft - 6 - this.lineWidth - 1, childY + midPoint);
      sspoint4 = new PointF(this.childLeft + this.childWidth + 6 + this.lineWidth + 1, childY + midPoint);
    } else {
      ffpoint1 = new PointF(pixelToPoint(this.parentLeft + this.parentWidth), parentY + midPoint);
      sspoint1 = new PointF(pixelToPoint(this.parentLeft) - 1, parentY + midPoint);
      ffpoint3 = new PointF(pixelToPoint(this.childLeft - 20), childY + midPoint);
      ffpoint4 = new PointF(pixelToPoint(this.childLeft - 6 - this.lineWidth) - 1, childY + midPoint);
      sspoint4 = new PointF(pixelToPoint(this.childLeft + this.childWidth + 6 + this.lineWidth) + 1, childY + midPoint);
    }
    switch (predecessorType) {
      case 'FSType1':
      case 'FSType3':
        point1 = ffpoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.childLeft - 20, parentY + midPoint);
        } else {
          point2 = new PointF(pixelToPoint(this.childLeft - 20), parentY + midPoint);
        }
        point3 = ffpoint3;
        point4 = ffpoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'FSType2':
        point1 = ffpoint1;
        point2 = new PointF(point1.x + 10, parentY + midPoint);
        point3 = new PointF(point1.x + 10, childY + 2);
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point4 = new PointF(this.childLeft - 20, childY + 2);
        } else {
          point4 = new PointF(pixelToPoint(this.childLeft - 20), childY + 2);
        }
        point5 = ffpoint3;
        point6 = ffpoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint, point5, point6);
        break;
      case 'FSType4':
        point1 = ffpoint1;
        point2 = new PointF(point1.x + 10, parentY + midPoint);
        point3 = new PointF(point1.x + 10, parentY + 2);
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point4 = new PointF(this.childLeft - 20, parentY + 2);
        } else {
          point4 = new PointF(pixelToPoint(this.childLeft - 20), parentY + 2);
        }
        point5 = ffpoint3;
        point6 = ffpoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint, point5, point6);
        break;
      case 'FFType1':
      case 'FFType3':
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point1 = new PointF(this.parentLeft + this.parentWidth + 1, parentY + midPoint);
          point2 = new PointF(this.childLeft + this.childWidth + 20, parentY + midPoint);
        } else {
          point1 = new PointF(pixelToPoint(this.parentLeft + this.parentWidth) + 1, parentY + midPoint);
          point2 = new PointF(pixelToPoint(this.childLeft + this.childWidth + 20), parentY + midPoint);
        }
        point3 = new PointF(point2.x, childY + midPoint);
        point4 = sspoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'FFType2':
      case 'FFType4':
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point1 = new PointF(this.parentLeft + this.parentWidth + 1, parentY + midPoint);
          point2 = new PointF(this.parentLeft + this.parentWidth + 20, parentY + midPoint);
        } else {
          point1 = new PointF(pixelToPoint(this.parentLeft + this.parentWidth) + 1, parentY + midPoint);
          point2 = new PointF(pixelToPoint(this.parentLeft + this.parentWidth + 20), parentY + midPoint);
        }
        point3 = new PointF(point2.x, childY + midPoint);
        point4 = sspoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'SSType1':
      case 'SSType3':
        point1 = sspoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.childLeft - 20, parentY + midPoint);
        } else {
          point2 = new PointF(pixelToPoint(this.childLeft - 20), parentY + midPoint);
        }
        point3 = new PointF(point2.x, childY + midPoint);
        point4 = ffpoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'SSType2':
      case 'SSType4':
        point1 = sspoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.parentLeft - 20, parentY + midPoint);
        } else {
          point2 = new PointF(pixelToPoint(this.parentLeft - 20), parentY + midPoint);
        }
        point3 = new PointF(point2.x, childY + midPoint);
        point4 = ffpoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'SFType1':
      case 'SFType3':
        point1 = sspoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.childLeft + this.childWidth + 20, parentY + midPoint);
        } else {
          point2 = new PointF(pixelToPoint(this.childLeft + this.childWidth + 20), parentY + midPoint);
        }
        point3 = new PointF(point2.x, childY + midPoint);
        point4 = sspoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint);
        break;
      case 'SFType2':
        point1 = sspoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.parentLeft - 20, parentY + midPoint);
          point4 = new PointF(this.childLeft + this.childWidth + 20, childY + 2);
        } else {
          point2 = new PointF(pixelToPoint(this.parentLeft - 20), parentY + midPoint);
          point4 = new PointF(pixelToPoint(this.childLeft + this.childWidth + 20), childY + 2);
        }
        point3 = new PointF(point2.x, childY + 2);
        point5 = new PointF(point4.x, childY + midPoint);
        point6 = sspoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint, point5, point6);
        break;
      case 'SFType4':
        point1 = sspoint1;
        if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
          point2 = new PointF(this.parentLeft - 20, parentY + midPoint);
          point4 = new PointF(this.childLeft + this.childWidth + 20, parentY + 2);
        } else {
          point2 = new PointF(pixelToPoint(this.parentLeft - 20), parentY + midPoint);
          point4 = new PointF(pixelToPoint(this.childLeft + this.childWidth + 20), parentY + 2);
        }
        point3 = new PointF(point2.x, parentY + 2);
        point5 = new PointF(point4.x, childY + midPoint);
        point6 = sspoint4;
        this.connectLines(startPage, endPage, point1, point2, point3, point4, childTask, midPoint, point5, point6);
        break;
    }
  };
  /**
   * Method to draw the predecessor lines with calculated connector points
   *
   * @private
   */
  /* eslint-disable-next-line */
  PdfGanttPredecessor.prototype.connectLines = function (startPage, endPage, point1, point2, point3, point4, childTask, midPoint, point5, point6) {
    this.drawLine(startPage, point1, point2);
    this.drawLine(startPage, point2, point3);
    this.drawLine(startPage, point3, point4);
    if (!isNullOrUndefined(point5) && !isNullOrUndefined(point6)) {
      this.drawLine(startPage, point4, point5);
      this.drawLine(startPage, point5, point6);
    }
    this.drawArrow(endPage, childTask, midPoint);
  };
  /**
   * Method to check the predecessor line  occurs within the page
   *
   * @param {RectangleF} rect .
   * @param {number} x .
   * @param {number} y .
   * @returns {boolean} .
   * @private
   */
  PdfGanttPredecessor.prototype.contains = function (rect, x, y) {
    return rect.x <= x && x < rect.x + rect.width && rect.y <= y && y < rect.y + rect.height;
  };
  /**
   * Find the PDF page index of given point
   *
   * @param {PointF} point .
   * @returns {number} .
   * @private
   */
  PdfGanttPredecessor.prototype.findPageIndex = function (point) {
    var pageIndex = -1;
    for (var index = 0; index < this.pdfGantt.pdfPageDetail.length; index++) {
      var pageData = this.pdfGantt.pdfPageDetail[index];
      var pageRect = new RectangleF(pageData.startPoint.x, pageData.startPoint.y, pageData.width, pageData.height);
      if (this.contains(pageRect, point.x, point.y)) {
        pageIndex = index;
        break;
      }
    }
    return pageIndex;
  };
  /**
   * Draw predecessor line
   *
   * @param {PdfPage} page .
   * @param {PointF} startPoint .
   * @param {PointF} endPoint .
   * @returns {void} .
   * @private
   */
  PdfGanttPredecessor.prototype.drawLine = function (page, startPoint, endPoint) {
    var pdfPages = this.pdfGantt.result.page.section.getPages();
    var graphics = page.graphics;
    var newEndPoint = __assign$9({}, endPoint);
    var newStartPoint = __assign$9({}, endPoint);
    var checkStartPoint = __assign$9({}, endPoint);
    var pageData = this.pdfGantt.pdfPageDetail[page.section.indexOf(page) - this.pdfGantt.chartPageIndex];
    var pageRect = new RectangleF(pageData.startPoint.x, pageData.startPoint.y, pageData.width, pageData.height);
    var startPointCheck = this.contains(pageRect, startPoint.x, startPoint.y);
    var endPointCheck = this.contains(pageRect, endPoint.x, endPoint.y);
    var pageIndex = -1;
    startPoint = new PointF(startPoint.x, startPoint.y);
    endPoint = new PointF(endPoint.x, endPoint.y);
    if (!startPointCheck && !endPointCheck || endPointCheck && !startPointCheck) {
      pageIndex = this.findPageIndex(startPoint);
      if (pageIndex > -1) {
        pageData = this.pdfGantt.pdfPageDetail[pageIndex];
        newStartPoint = startPoint;
        newEndPoint = endPoint;
        this.drawLine(pdfPages[pageIndex + this.pdfGantt.chartPageIndex], newStartPoint, newEndPoint);
      }
    } else if (!endPointCheck && startPointCheck) {
      var pageRectLeft = pageRect.x;
      var pageRectRight = pageRect.x + pageRect.width;
      var pageRectBottom = pageRect.y + pageRect.height;
      var pageRectTop = pageRect.y;
      if (pageRectLeft > endPoint.x) {
        checkStartPoint.x = endPoint.x = pageRectLeft - 1;
        newStartPoint.x = pageRectLeft - 1;
      } else if (pageRectRight < endPoint.x) {
        checkStartPoint.x = endPoint.x = pageRectRight;
        newStartPoint.x = pageRectRight;
        checkStartPoint.x += 1;
      } else if (pageRectBottom < endPoint.y) {
        checkStartPoint.y = endPoint.y = pageRectBottom;
        newStartPoint.y = pageRectBottom;
        checkStartPoint.y += 1;
        if (this.pdfGantt.enableHeader) {
          newStartPoint.y += this.parent.timelineModule.isSingleTier ? pixelToPoint(45) : pixelToPoint(62);
        }
      } else if (pageRectTop > endPoint.y) {
        newStartPoint.y = checkStartPoint.y = pageRectTop - 1;
        endPoint.y = pageRectTop;
        if (this.pdfGantt.enableHeader) {
          checkStartPoint.y += this.parent.timelineModule.isSingleTier ? pixelToPoint(45) : pixelToPoint(62);
          endPoint.y += this.parent.timelineModule.isSingleTier ? pixelToPoint(45) : pixelToPoint(62);
        }
      }
      pageIndex = this.findPageIndex(checkStartPoint);
      if (pageIndex !== -1) {
        this.drawLine(pdfPages[pageIndex + this.pdfGantt.chartPageIndex], newStartPoint, newEndPoint);
      }
    }
    if (startPointCheck) {
      startPoint = new PointF(startPoint.x, startPoint.y);
      endPoint = new PointF(endPoint.x, endPoint.y);
      startPoint.x = startPoint.x + pageData.pageStartX - pageData.startPoint.x;
      startPoint.y = startPoint.y - pageData.startPoint.y;
      endPoint.x = endPoint.x + pageData.pageStartX - pageData.startPoint.x;
      endPoint.y = endPoint.y - pageData.startPoint.y;
      var brush = new PdfSolidBrush(this.connectorLineColor);
      var predecessorPen = new PdfPen(brush, pixelToPoint(this.lineWidth));
      graphics.drawLine(predecessorPen, startPoint, endPoint);
    }
  };
  /**
   * Draw predecessor arrow
   *
   * @param {PdfPage} page .
   * @param {PdfGanttTaskbarCollection} childTask .
   * @param {number} midPoint .
   * @returns {void} .
   * @private
   */
  PdfGanttPredecessor.prototype.drawArrow = function (page, childTask, midPoint) {
    var pageData = this.pdfGantt.pdfPageDetail[page.section.indexOf(page) - this.pdfGantt.chartPageIndex];
    /* eslint-disable-next-line */
    var pageRect = new RectangleF(new PointF(pageData.startPoint.x, pageData.startPoint.y), new SizeF(pageData.width, pageData.height));
    var startPoint = new PointF();
    var pdfPages = page.section.getPages();
    var width = 6 + this.lineWidth;
    var point2;
    if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
      if (this.type === 'FS' || this.type === 'SS') {
        startPoint = new PointF(this.childLeft - 1, childTask.taskStartPoint.y + pageData.startPoint.y);
      } else {
        startPoint = new PointF(this.childLeft + this.childWidth + 1, childTask.taskStartPoint.y + pageData.startPoint.y);
      }
    } else {
      if (this.type === 'FS' || this.type === 'SS') {
        startPoint = new PointF(pixelToPoint(this.childLeft) - 1, childTask.taskStartPoint.y + pageData.startPoint.y);
      } else {
        startPoint = new PointF(pixelToPoint(this.childLeft + this.childWidth) + 1, childTask.taskStartPoint.y + pageData.startPoint.y);
      }
    }
    var startPointCheck = this.contains(pageRect, startPoint.x, startPoint.y);
    if (!startPointCheck) {
      var pageIndex = this.findPageIndex(startPoint);
      if (pageIndex > -1) {
        pageData = this.pdfGantt.pdfPageDetail[pageIndex];
        page = pdfPages[pageIndex + this.pdfGantt.chartPageIndex];
      }
    }
    var graphics = page.graphics;
    startPoint.x = startPoint.x - pageData.startPoint.x + pageData.pageStartX;
    startPoint.y = startPoint.y - pageData.startPoint.y;
    var point1 = new PointF(startPoint.x, startPoint.y + midPoint);
    if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
      if (this.type === 'FS' || this.type === 'SS') {
        point2 = new PointF(point1.x - width, point1.y - width);
      } else {
        point2 = new PointF(point1.x + width, point1.y - width);
      }
    } else {
      if (this.type === 'FS' || this.type === 'SS') {
        point2 = new PointF(point1.x - pixelToPoint(width), point1.y - pixelToPoint(width));
      } else {
        point2 = new PointF(point1.x + pixelToPoint(width), point1.y - pixelToPoint(width));
      }
    }
    var brush = new PdfSolidBrush(this.connectorLineColor);
    var point3;
    var predecessorPen;
    if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
      point3 = new PointF(point2.x, point2.y + 2 * width);
      predecessorPen = new PdfPen(brush, this.lineWidth);
    } else {
      point3 = new PointF(point2.x, point2.y + pixelToPoint(2 * width));
      predecessorPen = new PdfPen(brush, pixelToPoint(this.lineWidth));
    }
    graphics.drawLine(predecessorPen, point1, point2);
    graphics.drawLine(predecessorPen, point2, point3);
    graphics.drawLine(predecessorPen, point3, point1);
  };
  return PdfGanttPredecessor;
}();
var EventMarker$2 = /** @__PURE__ @class */function () {
  function EventMarker(parent) {
    this.parent = parent;
  }
  EventMarker.prototype.drawEventMarker = function (page, startPoint, cumulativeWidth, detail, eventMarker, cumulativeHeight) {
    var taskGraphics = page.graphics;
    var pageSize = page.getClientSize();
    var font = new PdfStandardFont(PdfFontFamily.Helvetica, 10);
    if (!isNullOrUndefined(this.parent.pdfExportModule['helper']['exportProps'].ganttStyle) && this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font) {
      font = this.parent.pdfExportModule['helper']['exportProps'].ganttStyle.font;
    }
    /* eslint-disable-next-line */
    var strSize;
    if (!isNullOrUndefined(eventMarker.label)) {
      strSize = font.measureString(eventMarker.label);
    }
    var triangle = 8;
    var eventLine = new PdfPen(new PdfColor(0, 0, 0));
    eventLine.dashStyle = PdfDashStyle.Dash;
    if (detail.startDate <= eventMarker.date && eventMarker.date <= detail.endDate) {
      var enventFormat = new PdfStringFormat();
      enventFormat.alignment = PdfTextAlignment.Center;
      var eventBrush = new PdfSolidBrush(new PdfColor(253, 191, 100));
      var left = this.parent.dataOperation.getTaskLeft(this.parent.dateValidationModule.getDateFromFormat(eventMarker.date, true), false, true);
      var diff = 10;
      if (this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
        taskGraphics.drawLine(eventLine, new PointF(startPoint.x + (left - cumulativeWidth) + diff, cumulativeHeight), new PointF(startPoint.x + (left - cumulativeWidth) + diff, pageSize.height));
        if (!isNullOrUndefined(eventMarker.label) && eventMarker.label.length > 0) {
          taskGraphics.save();
          taskGraphics.translateTransform(startPoint.x + (left - cumulativeWidth) + 7 + diff, cumulativeHeight + pixelToPoint(50) + strSize.height / 2);
          taskGraphics.rotateTransform(45);
          taskGraphics.drawRectangle(null, eventBrush, 0, 0, triangle, triangle);
          taskGraphics.restore();
          taskGraphics.drawRectangle(null, eventBrush, startPoint.x + (left - cumulativeWidth) + 7 + diff, cumulativeHeight + pixelToPoint(50), strSize.width + 10, strSize.height * 2);
          taskGraphics.drawString(eventMarker.label, font, null, PdfBrushes.Black, startPoint.x + (left - cumulativeWidth) + 12 + diff, cumulativeHeight + pixelToPoint(50) + pixelToPoint(strSize.height / 2), strSize.width, strSize.height, enventFormat);
        }
      } else {
        taskGraphics.drawLine(eventLine, new PointF(startPoint.x + pixelToPoint(left - cumulativeWidth) + diff, cumulativeHeight), new PointF(startPoint.x + pixelToPoint(left - cumulativeWidth) + diff, pageSize.height));
        if (!isNullOrUndefined(eventMarker.label) && eventMarker.label.length > 0) {
          taskGraphics.save();
          taskGraphics.translateTransform(startPoint.x + pixelToPoint(left - cumulativeWidth) + 7 + diff, cumulativeHeight + pixelToPoint(50) + strSize.height / 2);
          taskGraphics.rotateTransform(45);
          taskGraphics.drawRectangle(null, eventBrush, 0, 0, triangle, triangle);
          taskGraphics.restore();
          taskGraphics.drawRectangle(null, eventBrush, startPoint.x + pixelToPoint(left - cumulativeWidth) + 7 + diff, cumulativeHeight + pixelToPoint(50), strSize.width + 10, strSize.height * 2);
          taskGraphics.drawString(eventMarker.label, font, null, PdfBrushes.Black, startPoint.x + pixelToPoint(left - cumulativeWidth) + 12 + diff, cumulativeHeight + pixelToPoint(50) + pixelToPoint(strSize.height / 2), strSize.width, strSize.height, enventFormat);
        }
      }
    }
  };
  return EventMarker;
}();
var __extends$o = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign$a = undefined && undefined.__assign || function () {
  __assign$a = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign$a.apply(this, arguments);
};
/**
 *
 */
var PdfGantt = /** @__PURE__ @class */function (_super) {
  __extends$o(PdfGantt, _super);
  function PdfGantt(parent) {
    var _this = _super.call(this) || this;
    _this.exportProps = {};
    _this.changeCloneProjectDates = false;
    _this.parent = parent;
    _this.chartHeader = new PdfTimeline(_this);
    _this.eventMarker = new EventMarker$2(parent);
    _this.predecessor = new PdfGanttPredecessor(parent, _this);
    _this.headerDetails = [];
    _this.pdfPageDetail = [];
    _this.taskbarCollection = [];
    _this.predecessorCollection = [];
    return _this;
  }
  Object.defineProperty(PdfGantt.prototype, "taskbar", {
    get: function () {
      if (isNullOrUndefined(this.taskbars)) {
        this.taskbars = new PdfGanttTaskbarCollection(this.parent);
      }
      return this.taskbars;
    },
    enumerable: true,
    configurable: true
  });
  PdfGantt.prototype.drawChart = function (result) {
    this.result = result;
    this.totalPages = this.result.page.section.count;
    this.perColumnPages = this.totalPages / this.layouter.columnRanges.length;
    this.calculateRange();
    this.drawGantttChart();
    this.drawPageBorder();
  };
  //Calcualte the header range for each pdf page based on schedule start and end date.
  PdfGantt.prototype.calculateRange = function () {
    var lastColumnRange = this.layouter.columnRanges[this.layouter.columnRanges.length - 1];
    var totalColumnWidth = 0;
    var isPageFinished = true;
    var pageWidth = 0;
    var remainWidth = 0;
    var point = 0;
    var headerWidth = pixelToPoint(this.chartHeader.width);
    var timelineSettings = this.parent.timelineModule;
    for (var index = lastColumnRange[0]; index <= lastColumnRange[1]; index++) {
      totalColumnWidth += this.layouter.treegrid.columns.getColumn(index).width;
    }
    totalColumnWidth += 0.5;
    if (totalColumnWidth + 100 < this.result.page.getClientSize().width) {
      remainWidth = this.result.page.getClientSize().width - totalColumnWidth;
      this.chartPageIndex = this.startPageIndex = this.totalPages - this.perColumnPages;
      isPageFinished = false;
      this.startPoint = new PointF(totalColumnWidth, 0);
    } else {
      this.result.page.section.add();
      this.chartPageIndex = this.startPageIndex = this.totalPages;
      isPageFinished = true;
      this.startPoint = new PointF(point, 0);
    }
    while (Math.round(point) < Math.round(headerWidth)) {
      if (isPageFinished) {
        pageWidth = this.result.page.getClientSize().width;
      } else {
        pageWidth = remainWidth;
        isPageFinished = true;
      }
      var detail = {};
      var range = [];
      var convertedWidth = pixelToPoint(this.chartHeader.bottomTierCellWidth);
      var width = 0;
      if (this.chartHeader.bottomTierCellWidth !== 0) {
        width = Math.floor(pageWidth / convertedWidth) * convertedWidth;
      }
      range[0] = point;
      if (headerWidth - point <= width) {
        range[1] = headerWidth;
        detail.totalWidth = pointToPixel(headerWidth - point);
      } else {
        range[1] = point + width;
        detail.totalWidth = pointToPixel(width);
      }
      detail.startPoint = range[0];
      detail.endPoint = range[1];
      if (this.parent.cloneProjectStartDate.getHours() === 0 && this.parent.cloneProjectStartDate.getMinutes() === 0 && this.parent.cloneProjectStartDate.getSeconds() === 0) {
        this.changeCloneProjectDates = true;
        this.parent.cloneProjectStartDate.setHours(8);
      }
      var timelineStartDate = this.parent.dataOperation.getDateFromFormat(this.parent.timelineModule.timelineStartDate);
      var count = isNullOrUndefined(timelineSettings.customTimelineSettings.bottomTier.count) ? timelineSettings.customTimelineSettings.topTier.count : timelineSettings.customTimelineSettings.bottomTier.count;
      var scheduleType = timelineSettings.customTimelineSettings.bottomTier.unit === 'None' ? timelineSettings.customTimelineSettings.topTier.unit : timelineSettings.customTimelineSettings.bottomTier.unit;
      switch (scheduleType) {
        case 'Minutes':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var sDays = Math.floor(pointToPixel(detail.startPoint) / this.chartHeader.bottomTierCellWidth);
            detail.startDate.setMinutes(detail.startDate.getMinutes() + sDays * count);
            detail.startDate.setSeconds(detail.startDate.getSeconds() + 1);
            detail.endDate = new Date(detail.startDate.getTime());
            var eDays = Math.floor(pointToPixel(detail.endPoint - detail.startPoint) / this.chartHeader.bottomTierCellWidth);
            detail.endDate.setMinutes(detail.endDate.getMinutes() + eDays * count);
            break;
          }
        case 'Hour':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var sDays1 = Math.floor(pointToPixel(detail.startPoint) / this.chartHeader.bottomTierCellWidth);
            detail.startDate.setHours(detail.startDate.getHours() + sDays1 * count);
            detail.startDate.setMinutes(detail.startDate.getMinutes() + 1);
            detail.endDate = new Date(detail.startDate.getTime());
            var eDays1 = Math.floor(pointToPixel(detail.endPoint - detail.startPoint) / this.chartHeader.bottomTierCellWidth);
            detail.endDate.setHours(detail.endDate.getHours() + eDays1 * count);
            break;
          }
        case 'Day':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var startDays = Math.round(detail.startPoint / pixelToPoint(this.chartHeader.bottomTierCellWidth));
            detail.startDate.setDate(detail.startDate.getDate() + startDays * count);
            var endDays = Math.round((detail.endPoint - detail.startPoint) / pixelToPoint(this.chartHeader.bottomTierCellWidth)) - 1;
            var startdate = detail.startDate;
            startdate.setHours(0, 0, 0, 0);
            var secondsToAdd = this.parent.workingTimeRanges[0].to * 1000;
            detail.endDate = new Date(startdate.getTime());
            detail.endDate.setDate(detail.startDate.getDate() + endDays * count);
            detail.endDate.setTime(detail.endDate.getTime() + secondsToAdd);
            break;
          }
        case 'Week':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var startDays1 = detail.startPoint / pixelToPoint(this.chartHeader.bottomTierCellWidth) * 7;
            detail.startDate.setDate(detail.startDate.getDate() + startDays1 * count);
            var endDays1 = Math.round((detail.endPoint - detail.startPoint) / pixelToPoint(this.chartHeader.bottomTierCellWidth)) * 7 - 1;
            detail.endDate = new Date(detail.startDate.getTime());
            detail.endDate.setDate(detail.startDate.getDate() + endDays1 * count);
            break;
          }
        case 'Month':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var startDays2 = detail.startPoint / pixelToPoint(this.chartHeader.bottomTierCellWidth) * 31;
            detail.startDate.setDate(detail.startDate.getDate() + startDays2 * count);
            var endDays2 = Math.round((detail.endPoint - detail.startPoint) / pixelToPoint(this.chartHeader.bottomTierCellWidth)) * 31 - 1;
            detail.endDate = new Date(detail.startDate.getTime());
            detail.endDate.setDate(detail.startDate.getDate() + endDays2 * count);
            break;
          }
        case 'Year':
          {
            detail.startDate = new Date(timelineStartDate.getTime());
            var startDays3 = detail.startPoint / pixelToPoint(this.chartHeader.bottomTierCellWidth) * 365;
            detail.startDate.setDate(detail.startDate.getDate() + startDays3 * count);
            var endDays3 = Math.round((detail.endPoint - detail.startPoint) / pixelToPoint(this.chartHeader.bottomTierCellWidth)) * 365 - 1;
            detail.endDate = new Date(detail.startDate.getTime());
            detail.endDate.setDate(detail.startDate.getDate() + endDays3 * count);
            break;
          }
      }
      this.headerDetails.push(detail);
      point += width;
    }
  };
  PdfGantt.prototype.drawPageBorder = function () {
    var pages = this.result.page.section.getPages();
    for (var index = 0; index < pages.length; index++) {
      var page = pages[index];
      var graphics = page.graphics;
      var pageSize = page.getClientSize();
      var pen = new PdfPen(new PdfColor(this.ganttStyle.chartGridLineColor));
      graphics.drawRectangle(pen, 0, 0, pageSize.width, pageSize.height);
    }
  };
  //Draw the gantt chart side
  PdfGantt.prototype.drawGantttChart = function () {
    var _this = this;
    var taskbarPoint = this.startPoint;
    var pagePoint = new PointF();
    var pageStartX = 0;
    var cumulativeWidth = 0;
    var cumulativeHeight = 0;
    var pageData;
    this.headerDetails.forEach(function (detail, index) {
      var page = _this.result.page.section.getPages()[_this.startPageIndex];
      page['contentWidth'] = _this.parent.pdfExportModule.gantt.taskbar.isAutoFit() ? pointToPixel(_this.headerDetails[index].endPoint - _this.headerDetails[index].startPoint) : _this.headerDetails[index].endPoint - _this.headerDetails[index].startPoint;
      _this.chartHeader.drawTimeline(page, _this.startPoint, detail);
      taskbarPoint.y = taskbarPoint.y + pixelToPoint(_this.parent.timelineModule.isSingleTier ? 45 : 60); // headerHeight
      pageStartX = taskbarPoint.x;
      cumulativeHeight = pixelToPoint(_this.parent.timelineModule.isSingleTier ? 45 : 60); // headerHeight
      _this.headerDetails[_this.headerDetails.indexOf(detail)].startIndex = _this.startPageIndex;
      _this.headerDetails[_this.headerDetails.indexOf(detail)].pageStartPoint = taskbarPoint;
      _this.parent.eventMarkerColloction.map(function (eventMarker) {
        var timelimeHeight = pixelToPoint(_this.parent.timelineModule.isSingleTier ? 45 : 60);
        var pdfPage = _this.result.page.section.getPages()[_this.startPageIndex];
        _this.eventMarker.drawEventMarker(pdfPage, taskbarPoint, cumulativeWidth, detail, eventMarker, timelimeHeight);
      });
      for (var i = 0; i < _this.taskbarCollection.length; i++) {
        var task = _this.taskbarCollection[i];
        var rowHeight = _this.rows.getRow(i + 1).height;
        var pdfPage = _this.result.page.section.getPages()[_this.startPageIndex];
        var graphics = pdfPage.graphics;
        var pen = new PdfPen(new PdfColor(_this.ganttStyle.chartGridLineColor));
        var lineWidth = void 0;
        if (page['contentWidth'] && (_this.parent.gridLines === 'Both' || _this.parent.gridLines === 'Horizontal')) {
          lineWidth = _this.chartHeader.timelineWidth;
          graphics.drawRectangle(pen, pageStartX, taskbarPoint.y, page['contentWidth'] + 0.5, rowHeight);
        }
        var isNextPage = task.drawTaskbar(pdfPage, taskbarPoint, detail, cumulativeWidth, rowHeight, _this.taskbarCollection[parseInt(i.toString(), 10)], lineWidth);
        if (isNextPage) {
          if (_this.enableHeader) {
            taskbarPoint.y = pixelToPoint(_this.parent.timelineModule.isSingleTier ? 45 : 60);
          } else {
            taskbarPoint.y = 0;
          }
          _this.startPageIndex++;
          pageData = {};
          pageData.height = cumulativeHeight;
          pageData.pageStartX = pageStartX;
          pageData.startPoint = __assign$a({}, pagePoint);
          if (_this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
            pageData.width = detail.totalWidth;
          } else {
            pageData.width = detail.totalWidth;
          }
          _this.pdfPageDetail.push(pageData);
          pagePoint.y += pageData.height;
          if (_this.enableHeader) {
            cumulativeHeight = _this.chartHeader.height;
          } else {
            taskbarPoint.y = 0;
            cumulativeHeight = 0;
          }
        }
        taskbarPoint.y += rowHeight;
        cumulativeHeight += rowHeight;
        _this.parent.eventMarkerColloction.map(function (eventMarker) {
          var timelimeHeight = pixelToPoint(_this.parent.timelineModule.isSingleTier ? 45 : 60);
          var pdfPage = _this.result.page.section.getPages()[_this.startPageIndex];
          _this.eventMarker.drawEventMarker(pdfPage, taskbarPoint, cumulativeWidth, detail, eventMarker, timelimeHeight);
        });
      }
      _this.headerDetails[index].endIndex = _this.startPageIndex;
      cumulativeWidth += detail.totalWidth;
      pageData = {};
      pageData.height = cumulativeHeight;
      pageData.pageStartX = pageStartX;
      pageData.startPoint = __assign$a({}, pagePoint);
      if (_this.parent.pdfExportModule.gantt.taskbar.isAutoFit()) {
        pageData.width = detail.totalWidth;
      } else {
        pageData.width = pixelToPoint(detail.totalWidth);
      }
      _this.pdfPageDetail.push(pageData);
      pagePoint.x += pageData.width;
      pagePoint.y = 0;
      if (_this.enableHeader) {
        cumulativeHeight = _this.chartHeader.height;
      } else {
        taskbarPoint.y = 0;
      }
      if (_this.headerDetails.indexOf(detail) !== _this.headerDetails.length - 1) {
        _this.result.page.section.add();
        _this.startPageIndex = _this.result.page.section.count - 1;
        taskbarPoint = _this.startPoint = new PointF(0, 0);
      }
    });
    // Draw predecessor line.
    for (var i = 0; i < this.predecessorCollection.length; i++) {
      var predecessor = this.predecessorCollection[i];
      predecessor.drawPredecessor(this);
    }
  };
  return PdfGantt;
}(PdfTreeGrid);

/**
 *
 * @hidden
 */
var PdfExport = /** @__PURE__ @class */function () {
  /**
   * @param {Gantt} parent .
   * @hidden
   */
  function PdfExport(parent) {
    this.isPdfExport = false;
    this.parent = parent;
    this.helper = new ExportHelper(this.parent);
    this.pdfDocument = undefined;
  }
  /**
   * @returns {string} .
   */
  PdfExport.prototype.getModuleName = function () {
    return 'pdfExport';
  };
  /**
   * To destroy Pdf export module.
   *
   * @returns {void} .
   * @private
   */
  PdfExport.prototype.destroy = function () {
    // Destroy Method
  };
  PdfExport.prototype.initGantt = function () {
    this.pdfDocument = undefined;
    this.gantt = new PdfGantt(this.parent);
    // this.gantt.exportValueFormatter = new ExportValueFormatter(this.parent.locale);
  };
  /**
   * @param {PdfExportProperties} pdfExportProperties .
   * @param {boolean} isMultipleExport .
   * @param {object} pdfDoc .
   * @param {boolean} isBlob .
   * @returns {Promise<Object>} .
   */
  PdfExport.prototype.export = function (pdfExportProperties, isMultipleExport, pdfDoc, isBlob) {
    var _this = this;
    this.isBlob = isBlob;
    var args = {
      requestType: 'beforePdfExport',
      ganttObject: this.parent,
      cancel: false
    };
    this.parent.trigger('beforePdfExport', args);
    if (!isNullOrUndefined(this.parent.loadingIndicator) && this.parent.loadingIndicator.indicatorType === 'Shimmer') {
      this.parent.showMaskRow();
    } else {
      this.parent.showSpinner();
    }
    if (getValue('cancel', args)) {
      /* eslint-disable-next-line */
      return new Promise(function (resolve, reject) {
        return resolve();
      });
    }
    /* eslint-disable-next-line */
    return new Promise(function (resolve, reject) {
      _this.exportWithData(pdfDoc, resolve, pdfExportProperties, isMultipleExport);
    });
  };
  PdfExport.prototype.exportWithData = function (pdfDoc, resolve, pdfExportProperties, isMultipleExport) {
    var _this = this;
    var data = [];
    if (isNullOrUndefined(pdfExportProperties)) {
      pdfExportProperties = {};
    }
    if (pdfExportProperties.fitToWidthSettings && pdfExportProperties.fitToWidthSettings.isFitToWidth) {
      if (pdfExportProperties.exportType === 'CurrentViewData') {
        this.helper.beforeSinglePageExport['cloneFlatData'] = extend([], this.parent.currentViewData, null, true);
      } else if (pdfExportProperties.exportType === 'AllData') {
        this.helper.beforeSinglePageExport['cloneFlatData'] = extend([], this.parent.flatData, null, true);
      } else {
        this.helper.beforeSinglePageExport['cloneFlatData'] = extend([], this.parent.updatedRecords, null, true);
      }
      this.helper.beforeSinglePageExport['cloneCurrentViewData'] = extend([], this.parent.currentViewData, null, true);
      data = this.helper.beforeSinglePageExport['cloneFlatData'];
    } else {
      if (!isNullOrUndefined(pdfExportProperties.exportType)) {
        if (pdfExportProperties.exportType === 'CurrentViewData') {
          data = this.parent.currentViewData;
        } else {
          data = this.parent.flatData;
        }
      } else {
        data = this.parent.updatedRecords;
      }
    }
    this.initGantt();
    if (!isNullOrUndefined(pdfDoc)) {
      this.pdfDocument = pdfDoc;
    } else {
      this.pdfDocument = new PdfDocument();
    }
    this.processExport(data, pdfExportProperties, isMultipleExport).then(function () {
      _this.parent.trigger('pdfExportComplete', _this.isBlob ? {
        promise: _this.blobPromise
      } : {});
      if (!isNullOrUndefined(_this.parent.loadingIndicator) && _this.parent.loadingIndicator.indicatorType === 'Shimmer') {
        _this.parent.hideMaskRow();
      } else {
        _this.parent.hideSpinner();
      }
      resolve(_this.pdfDocument);
    });
  };
  PdfExport.prototype.processExport = function (data, pdfExportProperties, isMultipleExport) {
    var _this = this;
    var section = this.pdfDocument.sections.add();
    this.processSectionExportProperties(section, pdfExportProperties);
    this.pdfPage = section.pages.add();
    this.pdfPageDimensions = this.pdfPage.getClientSize();
    /* eslint-disable-next-line */
    return new Promise(function (resolve, reject) {
      _this.helper.processGridExport(data, _this.gantt, pdfExportProperties);
      _this.helper.initializePdf(_this.pdfDocument);
      resolve();
    }).then(function () {
      var format = new PdfTreeGridLayoutFormat();
      format.break = PdfLayoutBreakType.FitElement;
      var layouter = _this.gantt.drawGrid(_this.pdfPage, 0, 0, format);
      _this.gantt.drawChart(layouter);
      if (_this.helper.exportProps && _this.helper.exportProps.fitToWidthSettings && _this.helper.exportProps.fitToWidthSettings.isFitToWidth) {
        _this.parent.zoomingProjectStartDate = _this.helper.beforeSinglePageExport['zoomingProjectStartDate'];
        _this.parent.zoomingProjectEndDate = _this.helper.beforeSinglePageExport['zoomingProjectEndDate'];
        _this.parent.cloneProjectStartDate = _this.helper.beforeSinglePageExport['cloneProjectStartDate'];
        _this.parent.cloneProjectEndDate = _this.helper.beforeSinglePageExport['cloneProjectEndDate'];
        _this.parent.timelineModule.customTimelineSettings = _this.helper.beforeSinglePageExport['customTimelineSettings'];
        _this.parent.isTimelineRoundOff = _this.helper.beforeSinglePageExport['isTimelineRoundOff'];
        _this.parent.timelineModule.topTier = _this.helper.beforeSinglePageExport['topTier'];
        _this.parent.timelineModule.topTierCellWidth = _this.helper.beforeSinglePageExport['topTierCellWidth'];
        _this.parent.timelineModule.topTierCollection = _this.helper.beforeSinglePageExport['topTierCollection'];
        _this.parent.timelineModule.bottomTier = _this.helper.beforeSinglePageExport['bottomTier'];
        _this.parent.timelineModule.bottomTierCellWidth = _this.helper.beforeSinglePageExport['bottomTierCellWidth'];
        _this.parent.timelineModule.bottomTierCollection = _this.helper.beforeSinglePageExport['bottomTierCollection'];
        _this.parent.timelineModule.totalTimelineWidth = _this.helper.beforeSinglePageExport['totalTimelineWidth'];
        _this.parent.timelineModule.timelineStartDate = _this.helper.beforeSinglePageExport['timelineStartDate'];
        _this.parent.timelineModule.timelineEndDate = _this.helper.beforeSinglePageExport['timelineEndDate'];
        _this.parent.timelineModule.timelineRoundOffEndDate = _this.helper.beforeSinglePageExport['timelineRoundOffEndDate'];
        _this.parent.perDayWidth = _this.helper.beforeSinglePageExport['perDayWidth'];
        _this.parent.updatedConnectorLineCollection = _this.helper.beforeSinglePageExport['updatedConnectorLineCollection'];
      }
      if (_this.gantt.changeCloneProjectDates) {
        _this.parent.cloneProjectStartDate.setHours(0);
        _this.gantt.changeCloneProjectDates = false;
      }
      if (!isMultipleExport) {
        if (!_this.isBlob) {
          // save the PDF
          if (!isNullOrUndefined(pdfExportProperties) && pdfExportProperties.fileName) {
            _this.pdfDocument.save(pdfExportProperties.fileName);
          } else {
            _this.pdfDocument.save('Export.pdf');
          }
        } else {
          _this.blobPromise = _this.pdfDocument.save();
        }
        _this.pdfDocument.destroy();
      }
      return _this.pdfDocument;
    });
  };
  PdfExport.prototype.processSectionExportProperties = function (section, pdfExportProperties) {
    //To set section page size and page orientation.
    if (!isNullOrUndefined(pdfExportProperties)) {
      var pdfPageSettings = new PdfPageSettings();
      if (!isNullOrUndefined(pdfExportProperties.pageOrientation) && pdfExportProperties.pageOrientation === 'Portrait') {
        pdfPageSettings.orientation = PdfPageOrientation.Portrait;
      } else {
        pdfPageSettings.orientation = PdfPageOrientation.Landscape;
      }
      if (!isNullOrUndefined(pdfExportProperties.pageSize)) {
        pdfPageSettings.size = this.getPageSize(pdfExportProperties.pageSize);
      }
      section.setPageSettings(pdfPageSettings);
      if (!isNullOrUndefined(pdfExportProperties.ganttStyle)) {
        var defaultGanttTheme = new PdfGanttTheme(pdfExportProperties.theme).style;
        this.gantt.ganttStyle = extend({}, defaultGanttTheme, pdfExportProperties.ganttStyle, true);
      } else {
        this.gantt.ganttStyle = new PdfGanttTheme(pdfExportProperties.theme).style;
      }
    } else {
      this.gantt.ganttStyle = new PdfGanttTheme(pdfExportProperties.theme).style;
    }
  };
  PdfExport.prototype.getPageSize = function (pageSize) {
    switch (pageSize) {
      case 'Letter':
        return new SizeF(612, 792);
      case 'Note':
        return new SizeF(540, 720);
      case 'Legal':
        return new SizeF(612, 1008);
      case 'A0':
        return new SizeF(2380, 3368);
      case 'A1':
        return new SizeF(1684, 2380);
      case 'A2':
        return new SizeF(1190, 1684);
      case 'A3':
        return new SizeF(842, 1190);
      case 'A5':
        return new SizeF(421, 595);
      case 'A6':
        return new SizeF(297, 421);
      case 'A7':
        return new SizeF(210, 297);
      case 'A8':
        return new SizeF(148, 210);
      case 'A9':
        return new SizeF(105, 148);
      // case 'A10':
      //     return new SizeF(74, 105);
      case 'B0':
        return new SizeF(2836, 4008);
      case 'B1':
        return new SizeF(2004, 2836);
      case 'B2':
        return new SizeF(1418, 2004);
      case 'B3':
        return new SizeF(1002, 1418);
      case 'B4':
        return new SizeF(709, 1002);
      case 'B5':
        return new SizeF(501, 709);
      case 'Archa':
        return new SizeF(648, 864);
      case 'Archb':
        return new SizeF(864, 1296);
      case 'Archc':
        return new SizeF(1296, 1728);
      case 'Archd':
        return new SizeF(1728, 2592);
      case 'Arche':
        return new SizeF(2592, 3456);
      case 'Flsa':
        return new SizeF(612, 936);
      case 'HalfLetter':
        return new SizeF(396, 612);
      case 'Letter11x17':
        return new SizeF(792, 1224);
      case 'Ledger':
        return new SizeF(1224, 792);
      default:
        return new SizeF(595, 842);
    }
  };
  return PdfExport;
}();

/**
 * Gantt Virtual Scroll module will handle Virtualization
 *
 * @hidden
 */
var VirtualScroll = /** @__PURE__ @class */function () {
  function VirtualScroll(parent) {
    this.parent = parent;
    this.bindTreeGridProperties();
  }
  /**
   * Get module name
   *
   * @returns {void} .
   */
  VirtualScroll.prototype.getModuleName = function () {
    return 'virtualScroll';
  };
  /**
   * Bind virtual-scroll related properties from Gantt to TreeGrid
   *
   * @returns {void} .
   */
  VirtualScroll.prototype.bindTreeGridProperties = function () {
    this.parent.treeGrid.enableVirtualization = this.parent.enableVirtualization;
    TreeGrid.Inject(VirtualScroll$1);
  };
  /**
   * @returns {number} .
   * @private
   */
  VirtualScroll.prototype.getTopPosition = function () {
    var virtualTable = this.parent.ganttChartModule.scrollElement.querySelector('.e-virtualtable');
    var translates = virtualTable.style.transform.split(',');
    var top;
    if (translates.length > 1) {
      top = translates[1].trim().split(')')[0];
    } else {
      var transformString = virtualTable.style.transform;
      top = transformString.substring(transformString.lastIndexOf('(') + 1, transformString.lastIndexOf(')'));
    }
    return parseFloat(top);
  };
  /**
   * To destroy the virtual scroll module.
   *
   * @returns {void} .
   * @private
   */
  VirtualScroll.prototype.destroy = function () {
    // destroy module
  };
  return VirtualScroll;
}();
export { AddDialogFieldSettings, Column, ColumnMenu, ContextMenu, CriticalPath, DayMarkers, DayWorkingTime, Dependency, Edit, EditDialogFieldSettings, EditSettings, EventMarker, ExcelExport, Filter, FilterSettings, Gantt, Holiday, LabelSettings, LoadingIndicator, PdfBorders, PdfExport, PdfGanttTheme, PdfHorizontalOverflowType, PdfPaddings, PdfTreeGridCell, PdfTreeGridCellCollection, PdfTreeGridColumn, PdfTreeGridColumnCollection, PdfTreeGridHeaderCollection, PdfTreeGridLayoutFormat, PdfTreeGridLayoutResult, PdfTreeGridLayouter, PdfTreeGridRow, PdfTreeGridRowCollection, PdfTreeGridStyle, PdfTreeGridStyleBase, Reorder, Resize, ResourceFields, RowDD, SearchSettings, Selection$1 as Selection, SelectionSettings, Sort, SortDescriptor, SortSettings, SplitterSettings, TaskFields, TemporaryDictionary, TimelineSettings, TimelineTierSettings, Toolbar, TooltipSettings, UndoRedo, VirtualScroll, WeekWorkingTime, formatString, getIndex, getSwapKey, getTaskData, getUid, isCountRequired, isRemoteData, isScheduledTask, keyPressed, load, parentsUntil, pixelToPoint, pointToPixel, queryCellInfo, rowDataBound, toolbarClick, updateDates };
